import React,{ useEffect,useState } from "react";
import { connect } from "react-redux";
import { Grid,makeStyles,Typography,useTheme,useMediaQuery,Select,Menu,MenuItem,Button,CircularProgress,Dialog,IconButton,Backdrop } from '@material-ui/core';
import { useTranslation } from "react-i18next";
import PersonAddIcon from '@material-ui/icons/PersonAdd';
import MoreVertIcon from '@material-ui/icons/MoreVert';
import { Pagination,PaginationItem } from '@material-ui/lab';
import ExpandLessIcon from "@material-ui/icons/ExpandLess";
import ExpandMoreIcon from "@material-ui/icons/ExpandMore";
import SortIcon from "@material-ui/icons/Sort";
import BootstrapInput from '../template/assets/customComponents/BootstrapInput';
import SVGIcon from '../template/assets/SVGIcons';
import UserModals from "../template/components/UserModal";
import ConfirmDeleteBrandDialog from '../template/components/ConfirmDeleteUserDialog';
import placeholderInfluencer from "../assets/placeholder_influenceur.png"
import { removeUserByUid } from '../../../store/actions/usersActions';


const useStyles = makeStyles((theme) => ({
    userContent: {
        flexGrow: 1,
        display: 'flex',
        flexDirection: 'column',
        backgroundColor: "#eff9fe",
    },
    tableHeader: {
        fontFamily: 'Poppins',
        cursor: 'pointer',
        marginTop: '10px',
        marginBottom: '10px'
    },
    tableRow: {
        backgroundColor: 'white',
        height: '90px',
        borderRadius: '27px',
        boxShadow: '0 7px 30px 0 rgba(88, 165, 211, 0.16)',
        marginTop: '10px',
        marginBottom: '10px'
    },
    tableCell: {
        fontFamily: 'Poppins',
    },
    placeholderStyles: {
        fontFamily: 'Poppins',
        color: '#1B4C61'
    },
    addButton: {
        padding: '6px 8px',
        minWidth: "110px",
        minHeight: "35px",
        color: '#fff',
        fontFamily: 'Poppins',
        textTransform: "capitalize",
        boxShadow: "0px 0px 0px 0px #f58fb5",
        whiteSpace: "nowrap",
        marginLeft: "100px",
        backgroundColor: '#f58fb5',
        '&:hover': {
            backgroundColor: '#f58fb5'
        }
    },
    paginationClass: {
        backgroundColor: '#ffffff !important',
        boxShadow: '0 7px 30px 0 rgba(88, 165, 211, 0.16)',
        borderRadius: '8px',
        fontFamily: 'Poppins',
        height: '40px',
        width: '40px',
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center'
    },
    selectedPaginationItem: {
        backgroundColor: '#3eadef !important',
        boxShadow: '0 7px 30px 0 rgba(88, 165, 211, 0.16)',
        borderRadius: '8px',
        fontFamily: 'Poppins',
        height: '40px',
        width: '40px',
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        color: '#ffffff'
    },
    paginationStyles: {
        fontFamily: 'Poppins'
    },
    menu: {
        boxShadow: '0 7px 7px 0 #d0e6f130',
    },
    overlay: {
        zIndex: theme.zIndex.drawer + 1
    },
}))

const Users = (props) => {
    const { t } = useTranslation('common');
    const classes = useStyles();
    const theme = useTheme();
    const xsDown = useMediaQuery(theme.breakpoints.down("xs"));

    const {
        offerId,
        usersPerOfferdId,
        loadedUserPerOfferId,
        removeUserByUid,
        loadingRemoveUser,
    } = props;
    const [loading,setLoading] = useState(false);
    const [overlayState,setOverlayState] = useState(false);
    const [anchorEl,setAnchorEl] = useState(null);
    const [userToOperateOn,setUserToOperateOn] = useState(null);
    const [deleteDialogState,setDeleteDialogState] = useState(false);
    const [sortHelper,setSortHelper] = useState([0,1,0,0]);
    const [page,setPage] = useState(1);
    const [count,setCount] = useState(0);
    const [size,setSize] = useState(10);
    const [sortEvent,setSortEvent] = useState(0);
    const [openAddUser,setOpenAddUser] = useState(false);
    const [users,setUsers] = useState([]);
    const [usersToRender,setUsersToRender] = useState([]);

    useEffect(() => {
        setLoading(true);
        if (loadedUserPerOfferId) {
            setUsers(usersPerOfferdId);
            setLoading(false);
        }
    },[usersPerOfferdId,loadedUserPerOfferId]);


    useEffect(() => {
        let indexAsc = sortHelper.indexOf(1);
        let indexDesc = sortHelper.indexOf(-1);
        if (indexAsc !== -1) {
            const sortTypeAsc = (indexAsc,tableToWorkOn) => {
                switch (indexAsc) {
                    case 0:
                        tableToWorkOn.sort((a,b) => {
                            if (a.firstName > b.firstName) return -1;
                            if (a.firstName < b.firstName) return 1;
                            return 0;
                        });
                        break;
                    case 1:
                        tableToWorkOn.sort((a,b) => {
                            if (a.lastName > b.lastName) return -1;
                            if (a.lastName < b.lastName) return 1;
                            return 0;
                        });
                        break;
                    case 2:
                        tableToWorkOn.sort((a,b) => {
                            if (a.email > b.email) return -1;
                            if (a.email < b.email) return 1;
                            return 0;
                        });
                        break;
                    case 3:
                        tableToWorkOn.sort((a,b) => {
                            if (a.role > b.role) return -1;
                            if (a.role < b.role) return 1;
                            return 0;
                        });
                        break;
                    default:
                        break;
                }
            };
            sortTypeAsc(indexAsc,users);
        } else {
            const sortTypeDesc = (indexDesc,tableToWorkOn) => {
                switch (indexDesc) {
                    case 0:
                        tableToWorkOn.sort((a,b) => {
                            if (a.firstName > b.firstName) return 1;
                            if (a.firstName < b.firstName) return -1;
                            return 0;
                        });
                        break;
                    case 1:
                        tableToWorkOn.sort((a,b) => {
                            if (a.lastName > b.lastName) return 1;
                            if (a.lastName < b.lastName) return -1;
                            return 0;
                        });
                        break;
                    case 2:
                        tableToWorkOn.sort((a,b) => {
                            if (a.email > b.email) return 1;
                            if (a.email < b.email) return -1;
                            return 0;
                        });
                        break;
                    case 3:
                        tableToWorkOn.sort((a,b) => {
                            if (a.role > b.role) return 1;
                            if (a.role < b.role) return -1;
                            return 0;
                        });
                        break;
                    default:
                        break;
                }
            };
            sortTypeDesc(indexDesc,users);
        }
        setSortEvent((s) => s + 1);
    },[users,sortHelper]);

    useEffect(() => {
        if (users) {
            setUsersToRender(users.slice((page - 1) * size,page * size));
            setCount(
                users.length % size === 0
                    ? Math.trunc(users.length / size)
                    : Math.trunc(users.length / size) + 1
            );
        }
    },[users,size,page,sortEvent]);

    const handlePageChange = (event,value) => {
        setPage(value);
    };

    const handlePageSizeChange = (event) => {
        setSize(event.target.value);
        setPage(1);
    };

    const handleOpenAddUser = () => {
        setOpenAddUser(true);
    }
    const handleCloseAddBrand = () => {
        setOpenAddUser(false);
    }

    const handleOpenMenu = (event,userToOperateOn) => {
        setAnchorEl(event.currentTarget);
        setUserToOperateOn(userToOperateOn);
    };

    const handleCloseMenu = () => {
        setAnchorEl(null);
    };

    const handleRemoveBrand = () => {
        setAnchorEl(null);
        removeUserByUid(userToOperateOn.uid,userToOperateOn.offerId);
    }
    const handleDialogConfirmation = (confirmation) => {
        if (confirmation) {
            handleRemoveBrand();
        }
        setAnchorEl(null);
        setDeleteDialogState(false);
    }

    const handleOpenDialogDelete = () => {
        setDeleteDialogState(true);
    }

    useEffect(() => {
        (loadingRemoveUser) ? setOverlayState(true) : setOverlayState(false);
    },[loadingRemoveUser]);

    return (

        <Grid className={ classes.userContent }>
            <Backdrop className={ classes.overlay } open={ overlayState }>
                <CircularProgress color='secondary' />
            </Backdrop>
            <Grid container item direction='column' xs={ 12 } justify='flex-start' alignItems='center' style={ { flexGrow: 1 } }>
                { (loading)
                    ? <div style={ { display: 'flex',flexDirection: 'column',flexGrow: 1,width: '100%',justifyContent: 'center',alignItems: 'center' } }>
                        <CircularProgress size={ 36 } color='secondary' />
                    </div>
                    : openAddUser ?
                        <UserModals closeModal={ handleCloseAddBrand } offerId={ offerId } />
                        :
                        <>
                            <Dialog open={ deleteDialogState }>
                                <ConfirmDeleteBrandDialog onClose={ (confirmation) => handleDialogConfirmation(confirmation) } />
                            </Dialog>
                            <Grid container item direction='row' xs={ 11 } alignItems='center' justify='center'>
                                <Grid container item xs={ 12 } justify='flex-end' direction="row" alignItems='flex-end' style={ (xsDown) ? { marginTop: "30px",marginBottom: "20px" } : { marginTop: "70px",marginBottom: "20px" } }>
                                    <Button className={ classes.addButton } onClick={ handleOpenAddUser }><PersonAddIcon fontSize='small' style={ { color: "#ffffff" } } />{ t("TREND.VIEWS.USERS.BUTTON_ADD") }</Button>
                                </Grid>
                                { usersToRender.length === 0 ?
                                    <>
                                        <Grid container item direction='row' xs={ 11 } justify='center' alignItems='center' style={ { marginTop: '20px' } }>
                                            <img
                                                alt="No Influencers"
                                                src={ placeholderInfluencer }
                                                height="150px"
                                                width="150px"
                                            />
                                        </Grid>
                                        <Grid container item direction='row' xs={ 11 } justify='center' alignItems='center' style={ { marginTop: '10px',marginBottom: ' 30px' } }>
                                            <Typography className={ classes.placeholderStyles }>
                                                { t("TREND.VIEWS.USERS.PLACEHOLDER_USERS") }
                                            </Typography>
                                        </Grid>
                                    </>
                                    :
                                    <div style={ { overflowX: 'auto',width: "100%" } }>
                                        <Grid container item style={ { minWidth: '1050px' } } direction='row' xs={ 12 }>
                                            <Grid container item direction='row' xs={ 12 }>
                                                <Grid container item xs={ 11 }>
                                                    <Grid container item justify='center' alignItems='center' style={ { width: "30%" } } onClick={ () => {
                                                        sortHelper[0] === 0 ? setSortHelper([1,0,0,0]) : setSortHelper([-sortHelper[0],0,0,0]);
                                                    } }>
                                                        <Typography className={ classes.tableHeader }> { t("TREND.VIEWS.USERS.USERS_TABLE.FIRSTNAME_USER") } </Typography>
                                                        { sortHelper[0] === 1 ? (
                                                            <ExpandLessIcon className={ classes.tableHeaderIcon } />
                                                        ) : sortHelper[0] === -1 ? (
                                                            <ExpandMoreIcon className={ classes.tableHeaderIcon } />
                                                        ) : (
                                                            <SortIcon className={ classes.tableHeaderIcon } />
                                                        ) }
                                                    </Grid>
                                                    <Grid container justify="center" alignItems="center" style={ { width: "30%" } } onClick={ () => {
                                                        sortHelper[1] === 0
                                                            ? setSortHelper([0,1,0,0])
                                                            : setSortHelper([0,-sortHelper[1],0,0]);
                                                    } }>
                                                        <Typography className={ classes.tableHeader }>{ t("TREND.VIEWS.USERS.USERS_TABLE.LASTNAME_USER") }</Typography>
                                                        { sortHelper[1] === 1 ? (
                                                            <ExpandLessIcon className={ classes.tableHeaderIcon } />
                                                        ) : sortHelper[1] === -1 ? (
                                                            <ExpandMoreIcon className={ classes.tableHeaderIcon } />
                                                        ) : (
                                                            <SortIcon className={ classes.tableHeaderIcon } />
                                                        ) }
                                                    </Grid>
                                                    <Grid container justify="center" alignItems="center" style={ { width: "25%" } } onClick={ () => {
                                                        sortHelper[2] === 0
                                                            ? setSortHelper([0,0,1,0])
                                                            : setSortHelper([0,0,-sortHelper[2],0]);
                                                    } }>
                                                        <Typography className={ classes.tableHeader }> { t("TREND.VIEWS.USERS.USERS_TABLE.EMAIL_USER") }</Typography>
                                                        { sortHelper[2] === 1 ? (
                                                            <ExpandLessIcon className={ classes.tableHeaderIcon } />
                                                        ) : sortHelper[2] === -1 ? (
                                                            <ExpandMoreIcon className={ classes.tableHeaderIcon } />
                                                        ) : (
                                                            <SortIcon className={ classes.tableHeaderIcon } />
                                                        ) }
                                                    </Grid>
                                                    <Grid container justify="center" alignItems="center" style={ { width: "15%" } } onClick={ () => {
                                                        sortHelper[3] === 0
                                                            ? setSortHelper([0,0,0,1])
                                                            : setSortHelper([0,0,0,-sortHelper[3]]);
                                                    } }>
                                                        <Typography className={ classes.tableHeader }> Role </Typography>
                                                        { sortHelper[3] === 1 ? (
                                                            <ExpandLessIcon className={ classes.tableHeaderIcon } />
                                                        ) : sortHelper[3] === -1 ? (
                                                            <ExpandMoreIcon className={ classes.tableHeaderIcon } />
                                                        ) : (
                                                            <SortIcon className={ classes.tableHeaderIcon } />
                                                        ) }
                                                    </Grid>
                                                </Grid>
                                            </Grid>
                                            { usersToRender.map((user,key) => {
                                                return (
                                                    <Grid container item xs={ 12 } direction='row' className={ classes.tableRow } key={ key }>
                                                        <Grid container item xs={ 11 }>
                                                            <Grid container item justify='center' alignItems='center' style={ { width: '30%' } }>
                                                                <Typography className={ classes.tableCell }>{ user.firstName }</Typography>
                                                            </Grid>
                                                            <Grid container item justify='center' alignItems='center' style={ { width: '30%' } }>
                                                                <Typography className={ classes.tableCell }>{ user.lastName }</Typography>
                                                            </Grid>
                                                            <Grid container item justify='center' alignItems='center' style={ { width: '25%' } }>
                                                                <Typography className={ classes.tableCell }>{ user.email }</Typography>
                                                            </Grid>
                                                            <Grid container item justify='center' alignItems='center' style={ { width: '15%' } }>
                                                                <Typography className={ classes.tableCell }>{ user.role }</Typography>
                                                            </Grid>
                                                        </Grid>
                                                        <Grid container item justify='center' alignItems='center' xs={ 1 } >
                                                            <IconButton onClick={ (e) => handleOpenMenu(e,user) }>
                                                                <MoreVertIcon />
                                                            </IconButton>
                                                            <Menu
                                                                classes={ { paper: classes.menu } }
                                                                anchorEl={ anchorEl }
                                                                open={ Boolean(anchorEl) }
                                                                onClose={ handleCloseMenu }
                                                            >
                                                                <MenuItem
                                                                    className={ classes.tableHeader }
                                                                    onClick={ () => handleOpenDialogDelete() }>
                                                                    { t("TREND.VIEWS.USERS.DELETE_USER.BUTTON_DELETE") }
                                                                </MenuItem>
                                                            </Menu>
                                                        </Grid>
                                                    </Grid>
                                                )
                                            }) }

                                        </Grid>
                                    </div>
                                }
                            </Grid>
                        </>
                }
                { usersToRender.length !== 0 ?
                    <Grid container item direction='row' xs={ 11 } justify="center" alignItems="center" style={ { marginBottom: '20px',marginTop: '20px' } }>
                        <Grid container alignItems='center' justify='flex-end'>
                            <Select
                                value={ size }
                                onChange={ handlePageSizeChange }
                                input={ <BootstrapInput /> }
                                IconComponent={ () => (
                                    <SVGIcon name='miniArrow' />
                                ) }
                                classes={ { root: classes.paginationStyles } }
                            >
                                <MenuItem value={ 10 } style={ { fontFamily: 'Poppins' } }>10</MenuItem>
                                <MenuItem value={ 25 } style={ { fontFamily: 'Poppins' } }>25</MenuItem>
                                <MenuItem value={ 50 } style={ { fontFamily: 'Poppins' } }>50</MenuItem>
                            </Select>
                            <Typography className={ classes.tableHeader } style={ { marginRight: '10px',marginLeft: '10px' } }>

                                { t('ADMIN.VIEWS.PARTNERS.BRANDS.PAGINATION_PLACEHOLDER') } { users.length }
                            </Typography>
                            <Pagination
                                count={ count }
                                page={ page }
                                onChange={ handlePageChange }
                                renderItem={ (item) => (
                                    <PaginationItem classes={ { root: classes.paginationClass,selected: classes.selectedPaginationItem } } { ...item } />
                                ) } />
                        </Grid>
                    </Grid>
                    :
                    <></> }
            </Grid>
        </Grid>

    )
}

const mapStateToProps = (state) => {
    const usersPerOfferdId = state.usersState.usersPerOfferdId;
    const loadedUserPerOfferId = state.usersState.loadedUserPerOfferId;
    const loadingRemoveUser = state.usersState.loadingRemoveUser;
    return {
        usersPerOfferdId,
        loadedUserPerOfferId,
        loadingRemoveUser
    }
}
const mapDispatchToProps = (dispatch) => {
    return {
        removeUserByUid: (uid,offerId) =>
            dispatch(removeUserByUid(uid,offerId)),
    };
};


export default connect(mapStateToProps,mapDispatchToProps)(Users);