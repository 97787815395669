import React, { useEffect, useState } from "react";
import { connect } from "react-redux";
import { useRouteMatch, Route, Switch, Redirect } from "react-router-dom";
import Brands from "./views/Brands";
import Statistics from "./views/Statistics";
import { auth } from "../managers/index";
import { fetchbrandsByOfferId } from "../../store/actions/brandsAgentActions";
import { fetchAllConversions } from "../../store/actions/conversionsActions";

const Router = (props) => {
  const { url } = useRouteMatch();

  const [brandAgent, setBrandAgent] = useState(null);
  const [userClaims, setUserClaims] = useState(null);
  const [brandsInfos, setBrandsInfos] = useState([]);
  const [brandsLoading, setBrandsLoading] = useState(true);
  const [lengthBrandAgent, setLengthBrandAgent] = useState(0);
  const {
    fetchbrandsByOfferId,
    fetchAllConversions,
    clearBrandsAgent,
    brandsArray,
  } = props;

  useEffect(() => {
    setBrandAgent(props.brandAgent);
  }, [props.brandAgent]);

  useEffect(() => {
    setUserClaims(props.userClaims);
  }, [props.userClaims]);

  useEffect(() => {
    fetchAllConversions();
  }, [fetchAllConversions]);

  useEffect(() => {
    if (brandAgent && userClaims) {
      for (let i = 0; i < userClaims.brandsOfferIds.length; i++) {
        setLengthBrandAgent(userClaims.brandsOfferIds.length);
        fetchbrandsByOfferId(userClaims.brandsOfferIds[i]);
      }
    }
    setBrandsLoading(false);
  }, [brandAgent, userClaims, fetchbrandsByOfferId]);

  useEffect(() => {
    if (brandsLoading === false) {
      if (brandsArray.length === lengthBrandAgent) {
        setBrandsInfos(brandsArray);
      }
    }
  }, [brandsArray, brandsLoading, lengthBrandAgent]);
  return (
    <Switch>
      <Route
        path={`${url}/logout`}
        exact
        render={() => {
          auth.signOut();
          clearBrandsAgent();
          return <Redirect to="/" />;
        }}
      ></Route>
      <Route path={`${url}/brands`} exact>
        <Brands {...props} brandsInfos={brandsInfos} />
      </Route>
      <Route path={`${url}/influencers`} exact>
        <></>
      </Route>
      <Route path={`${url}/statistiques`}>
        <Statistics {...props} brandsInfos={brandsInfos} />
      </Route>
      <Route path={`${url}`}>
        <Redirect to={`${url}/brands`} />
      </Route>
    </Switch>
  );
};
const mapStateToProps = (state) => {
  const brandsArray = state.brandsAgentState.brandsArray;
  return {
    brandsArray,
  };
};
const mapDispatchToProps = (dispatch) => {
  return {
    fetchbrandsByOfferId: (brandOfferId) =>
      dispatch(fetchbrandsByOfferId(brandOfferId)),
    fetchAllConversions: () => dispatch(fetchAllConversions()),
    clearBrandsAgent: () => dispatch({ type: "CLEAR_BRAND_AGENT" }),
  };
};
export default connect(mapStateToProps, mapDispatchToProps)(Router);
