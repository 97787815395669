const initState = {
    brandTableSortHelper: [0, 0, 0, 0, -1, 0],
    brandTablePageNumber: 1,
    brandTablePageSize: 25,
    brandToValidateTableSortHelper: [1, 0],
    brandToValidateTablePageNumber: 1,
    brandToValidateTablePageSize: 25,
    suggestedBrandTableSortHelper: [1, 0, 0, 0],
    suggestedBrandTablePageNumber: 1,
    suggestedBrandTablePageSize: 25
};

const adminPartnerBrandReducer = (state = initState, action) => {
    switch (action.type) {
        case 'UPDATE_ADMIN_PARTNER_BRAND_TABLE_SORT_HELPER':
            return {
                ...state,
                brandTableSortHelper: action.sortHelper
            };
        case 'UPDATE_ADMIN_PARTNER_BRAND_TABLE_PAGE_NUMBER':
            return {
                ...state,
                brandTablePageNumber: action.pageNumber
            };
        case 'UPDATE_ADMIN_PARTNER_BRAND_TABLE_PAGE_SIZE':
            return {
                ...state,
                brandTablePageSize: action.pageSize
            };
        case 'UPDATE_ADMIN_PARTNER_BRAND_TO_VALIDATE_TABLE_SORT_HELPER':
            return {
                ...state,
                brandToValidateTableSortHelper: action.sortHelper
            };
        case 'UPDATE_ADMIN_PARTNER_BRAND_TO_VALIDATE_TABLE_PAGE_NUMBER':
            return {
                ...state,
                brandToValidateTablePageNumber: action.pageNumber
            };
        case 'UPDATE_ADMIN_PARTNER_BRAND_TO_VALIDATE_TABLE_PAGE_SIZE':
            return {
                ...state,
                brandToValidateTablePageSize: action.pageSize
            };
        case 'UPDATE_ADMIN_PARTNER_SUGGESTED_BRAND_TABLE_SORT_HELPER':
            return {
                ...state,
                suggestedBrandTableSortHelper: action.sortHelper
            };
        case 'UPDATE_ADMIN_PARTNER_SUGGESTED_BRAND_TABLE_PAGE_NUMBER':
            return {
                ...state,
                suggestedBrandTablePageNumber: action.pageNumber
            };
        case 'UPDATE_ADMIN_PARTNER_SUGGESTED_BRAND_TABLE_PAGE_SIZE':
            return {
                ...state,
                suggestedBrandTablePageSize: action.pageSize
            };
        default:
            return state;
    };
};

export default adminPartnerBrandReducer;