import React,{ useCallback,useState,useEffect } from 'react';
import { Grid,makeStyles,Typography,IconButton,FormControl,InputBase,useTheme,useMediaQuery,Button,Dialog,Backdrop,CircularProgress,Select,MenuItem } from "@material-ui/core";
import WarningDialog from "./WarningDialog";
import CloseIcon from '@material-ui/icons/Close';
import { useTranslation } from "react-i18next";
import * as utils from "../../../utils";
import { connect } from 'react-redux';
import { addNewUser } from "../../../../store/actions/usersActions";

const useStyles = makeStyles((theme) => ({
    overallBox: {
        backgroundColor: '#fff',
        borderRadius: '35px',
        boxShadow: '0 7px 30px 0 rgba(88, 165, 211, 0.16)',
        margin: '15px'
    },
    formBox: {
        marginTop: '10px',
        marginBottom: '10px'
    },
    formTitle: {
        fontFamily: 'Poppins',
        fontSize: '22px',
        margin: '10px',
        marginTop: '-50px'
    },
    formControlRow: {
        marginTop: '5px',
        marginBottom: '5px'
    },
    formLabel: {
        fontFamily: 'Poppins',
        marginLeft: '20px'
    },
    formControl: {
        width: '100%'
    },
    formInput: {
        fontFamily: 'Poppins',
        borderRadius: '35px',
        boxShadow: '0 7px 30px 0 rgba(69, 128, 163, 0.16)',
        height: '50px',
        width: '100%',
        paddingTop: '10px',
        paddingBottom: '10px',
        paddingLeft: '20px',
        margin: '5px'
    },
    switchLabel: {
        fontFamily: 'Poppins',
    },
    cancelEditButton: {
        width: '200px',
        height: '60px',
        fontFamily: 'Poppins',
        margin: '5px',
        borderRadius: '30px',
        boxShadow: '0 7px 30px 0 rgba(88, 165, 211, 0.16)',
    },
    validateEditButton: {
        width: '200px',
        height: '60px',
        fontFamily: 'Poppins',
        margin: '5px',
        borderRadius: '30px',
        boxShadow: '0 7px 30px 0 rgba(88, 165, 211, 0.16)',
        color: '#fff',
        backgroundColor: '#d96e98',
        '&:hover': {
            backgroundColor: '#d96e98'
        }
    },
    profilePicturePlaceholder: {
        fontFamily: 'Poppins',
        color: '#717171',
        width: 'calc(100% - 150px)',
        paddingLeft: '15px'
    },
    activeProfilePicturePlaceholder: {
        fontFamily: 'Poppins',
        width: 'calc(100% - 150px)',
        paddingLeft: '15px'
    },
    profilePicturePlaceholderMobile: {
        fontFamily: 'Poppins',
        color: '#717171',
        width: '70%',
        margin: 5,
        textAlign: 'center',
    },
    activeProfilePicturePlaceholderMobile: {
        fontFamily: 'Poppins',
        width: '70%',
        margin: 5,
        textAlign: 'center',
    },
    profileImageLabel: {
        fontFamily: 'Poppins',
        width: '150px',
        height: '40px',
        textTransform: 'none',
        backgroundColor: '#58a5d3',
        color: '#fff',
        borderRadius: '35px',
        '&:hover': {
            cursor: 'pointer',
            backgroundColor: '#58a5d3',
        },
        '&:focus': {
            outlineColor: 'rgba(69, 128, 163, 0)'
        },
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        position: 'relative',
    },
    errorMessage: {
        fontFamily: "Poppins",
        color: "red",
    },
    brandImage: {
        width: "100px",
        height: "100px",
        objectFit: "fill",
        borderRadius: "20px"
    },
    AllBrandBox: {
        borderRadius: "41px",
        boxShadow: "0 2px 30px 0 rgba(58, 111, 142, 0.21)",
        marginTop: "15px",
        marginBottom: "30px",
    },
    brandBox: {
        margin: "5px",
        borderRadius: "20px",
        boxShadow: "0 2px 30px 0 rgba(58, 111, 142, 0.21)",
        width: "110px",
        height: "110px",
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
    },
    overlay: {
        zIndex: theme.zIndex.drawer + 1
    },
    refuseButton: {
        width: '200px',
        height: '40px',
        fontFamily: 'Poppins',
        margin: '5px',
        borderRadius: '30px',
        backgroundColor: 'red',
        color: '#fff',
        boxShadow: '0 7px 30px 0 rgba(88, 165, 211, 0.16)',
        '&:hover': {
            backgroundColor: 'red'
        }
    },
    validateButton: {
        width: '200px',
        height: '40px',
        fontFamily: 'Poppins',
        margin: '5px',
        borderRadius: '30px',
        boxShadow: '0 7px 30px 0 rgba(88, 165, 211, 0.16)',
        color: '#fff',
        backgroundColor: '#d96e98',
        '&:hover': {
            backgroundColor: '#d96e98'
        }
    },
    placeholderStyles: {
        fontFamily: 'Poppins',
        color: '#1B4C61'
    },
    selectClass: {
        fontFamily: 'Poppins',
        paddingRight: '0px !important',
        '&:focus': {
            backgroundColor: 'white'
        },
        display: 'flex',
        flexShrink: 1,
    },
    select: {
        marginTop: '10px',
        marginBottom: '10px',
    },
    selectedMenuItems: {
        backgroundColor: '#50bef3 !important',
        color: '#fff'
    },
})
);


const UserModals = (props) => {
    const classes = useStyles();
    const { t } = useTranslation("common");
    const theme = useTheme();
    const xsDown = useMediaQuery(theme.breakpoints.down('xs'));
    const {
        closeModal,
        offerId,
        loadingAddNewUser,
        addNewUser,
    } = props

    const [nameInputValue,setNameInputValue] = useState('');
    const [prenomInputValue,setPrenomInputValue] = useState('');
    const [emailInputValue,setEmailInputValue] = useState('');
    const [passwordInputValue,setPasswordInputValue] = useState('');
    const [confirmPasswordInputValue,setConfirmPasswordInputValue] = useState('');
    const [unvalidatedFields,setUnvalidatedFields] = useState([]);
    const [validatingWarningState,setValidatingWarningState] = useState(false);
    const [updateClicked,setUpdateClicked] = useState(false);
    const [overlayState,setOverlayState] = useState(false);
    const [selectRole,setSelectRole] = useState("");

    console.log(selectRole);

    const handleCloseModal = useCallback(() => {
        closeModal(true);
    },[closeModal]);

    const handleValueChange = id => event => {
        if (id === 'nameUser') setNameInputValue(event.target.value);
        if (id === 'prenomUser') setPrenomInputValue(event.target.value);
        if (id === 'emailUser') setEmailInputValue(event.target.value);
        if (id === 'passwordUser') setPasswordInputValue(event.target.value)
        if (id === 'confirmPassword') setConfirmPasswordInputValue(event.target.value)
        if (id === "select-role") setSelectRole(event.target.value);
    }

    const handleCloseWarningDialog = () => {
        setValidatingWarningState(false);
        setUnvalidatedFields([]);
    };


    const handleSubmit = async () => {
        let validatingArray = [];
        if (nameInputValue === '') validatingArray.push(t("TREND.VIEWS.USERS.USERS_MODAL.ERROR_FIRSTNAME"));
        if (prenomInputValue === '') validatingArray.push(t("TREND.VIEWS.USERS.USERS_MODAL.ERROR_LASTNAME"));
        if (emailInputValue === '') validatingArray.push(t("TREND.VIEWS.USERS.USERS_MODAL.ERROR_EMAIL_VIDE"));
        if (emailInputValue !== "" && !utils.emailValidation(emailInputValue)) validatingArray.push(t("TREND.VIEWS.USERS.USERS_MODAL.ERROR_EMAIL_NOT_VALID"));
        if (passwordInputValue < 6) validatingArray.push(t("TREND.VIEWS.USERS.USERS_MODAL.ERROR_PASSWORD_LENGTH"));
        if (passwordInputValue !== confirmPasswordInputValue) validatingArray.push(t("TREND.VIEWS.USERS.USERS_MODAL.ERROR_PASSWORD_IDENTICAL"));
        if (selectRole === "") validatingArray.push(t("TREND.VIEWS.USERS.USERS_MODAL.ERROR_SELECT"));

        if (validatingArray.length !== 0) {
            setValidatingWarningState(true);
            setUnvalidatedFields(validatingArray);
        } else {
            let user = {
                email: emailInputValue,
                firstName: nameInputValue,
                lastName: prenomInputValue,
                offerId: offerId,
                role: selectRole
            }
            let userCredentials = {
                email: emailInputValue,
                password: passwordInputValue,
            }
            setUpdateClicked(true);
            addNewUser(user,userCredentials)

        }
    }

    useEffect(() => {
        if (loadingAddNewUser) {
            setOverlayState(true);
        }
        if (updateClicked && !loadingAddNewUser) {
            setOverlayState(false);
            setUpdateClicked(false);
            handleCloseModal();
        }
    },[updateClicked,handleCloseModal,loadingAddNewUser])

    return (
        <>
            <Backdrop className={ classes.overlay } open={ overlayState }>
                <CircularProgress color='secondary' />
            </Backdrop>
            <Dialog className={ classes.warningDialog } open={ validatingWarningState } onClose={ handleCloseWarningDialog }>
                <WarningDialog unvalidatedFields={ unvalidatedFields } />
            </Dialog>
            <Grid container item direction='row' xs={ 12 } justify='center' alignItems='center'>
                <Grid container item direction='row' xs={ 11 } justify='center' alignItems='center' className={ classes.overallBox }>
                    <Grid container item direction='row' alignItems='center' justify='flex-end' xs={ 12 }>
                        <IconButton style={ { marginTop: '10px',marginRight: '10px',color: '#000' } } onClick={ handleCloseModal }>
                            <CloseIcon fontSize='large' />
                        </IconButton>
                    </Grid>
                    <Grid container item direction='row' xs={ 11 } className={ classes.formBox }>
                        <Grid container item direction='column' justify='flex-start' alignItems='flex-start' className={ classes.formControlRow } xs={ 12 } >
                            <Typography className={ classes.formLabel }>
                                { t("TREND.VIEWS.USERS.USERS_MODAL.INPUT_USER_MODAL.FIRSTNAME") }
                            </Typography>
                            <FormControl className={ classes.formControl }>
                                <InputBase
                                    className={ classes.formInput }
                                    placeholder="First Name"
                                    id='nameUser'
                                    type='text'
                                    value={ nameInputValue }
                                    onChange={ handleValueChange('nameUser') } />
                            </FormControl>
                        </Grid>
                        <Grid container item direction='column' justify='flex-start' alignItems='flex-start' className={ classes.formControlRow } xs={ 12 } >
                            <Typography className={ classes.formLabel }>
                                { t("TREND.VIEWS.USERS.USERS_MODAL.INPUT_USER_MODAL.LASTNAME") }
                            </Typography>
                            <FormControl className={ classes.formControl }>
                                <InputBase
                                    className={ classes.formInput }
                                    placeholder="Last Name"
                                    id='prenomUser'
                                    type='text'
                                    value={ prenomInputValue }
                                    onChange={ handleValueChange('prenomUser') } />
                            </FormControl>
                        </Grid>
                        <Grid container item direction='column' justify='flex-start' alignItems='flex-start' className={ classes.formControlRow } xs={ 12 } >
                            <Typography className={ classes.formLabel }>
                                { t("TREND.VIEWS.USERS.USERS_MODAL.INPUT_USER_MODAL.EMAIL") }
                            </Typography>
                            <FormControl className={ classes.formControl }>
                                <InputBase
                                    className={ classes.formInput }
                                    placeholder="Email"
                                    id='emailUser'
                                    type='text'
                                    value={ emailInputValue }
                                    onChange={ handleValueChange('emailUser') } />
                            </FormControl>
                        </Grid>
                        <Grid container item direction='column' justify='flex-start' alignItems='flex-start' className={ classes.formControlRow } xs={ 12 } >
                            <Typography className={ classes.formLabel }>
                                Role
                            </Typography>
                            <FormControl className={ classes.formControl }>
                                <Select
                                    displayEmpty
                                    id="select-role"
                                    onChange={ handleValueChange('select-role') }
                                    value={ selectRole }
                                    classes={ { root: classes.selectClass } }
                                    input={ <InputBase className={ classes.formInput } style={ { marginTop: '10px',marginBottom: '10px' } } /> }
                                >
                                    <MenuItem value="">
                                        <Typography style={ { fontFamily: 'Poppins',color: '#717171' } }>
                                            Select role
                                        </Typography>
                                    </MenuItem>
                                    <MenuItem value="admin" style={ { fontFamily: 'Poppins' } } classes={ { selected: classes.selectedMenuItems } }>Admin</MenuItem>
                                    <MenuItem value="manager" style={ { fontFamily: 'Poppins' } } classes={ { selected: classes.selectedMenuItems } }>Manager</MenuItem>
                                </Select>
                            </FormControl>
                        </Grid>
                        <Grid container item direction='column' justify='flex-start' alignItems='flex-start' className={ classes.formControlRow } xs={ 12 } >
                            <Typography className={ classes.formLabel }>
                                { t("TREND.VIEWS.USERS.USERS_MODAL.INPUT_USER_MODAL.OFFERID") }
                            </Typography>
                            <FormControl className={ classes.formControl }>
                                <InputBase
                                    className={ classes.formInput }
                                    placeholder="OfferId"
                                    type='Number'
                                    value={ offerId }
                                    disabled={ true }
                                />
                            </FormControl>
                        </Grid>
                        <Grid container item direction='column' justify='flex-start' alignItems='flex-start' className={ classes.formControlRow } xs={ 12 } >
                            <Typography className={ classes.formLabel }>
                                { t("TREND.VIEWS.USERS.USERS_MODAL.INPUT_USER_MODAL.PASSWORD") }
                            </Typography>
                            <FormControl className={ classes.formControl }>
                                <InputBase
                                    className={ classes.formInput }
                                    placeholder="Password"
                                    id='passwordUser'
                                    type='password'
                                    value={ passwordInputValue }
                                    onChange={ handleValueChange('passwordUser') } />
                            </FormControl>
                        </Grid>
                        <Grid container item direction='column' justify='flex-start' alignItems='flex-start' className={ classes.formControlRow } xs={ 12 } >
                            <Typography className={ classes.formLabel }>
                                { t("TREND.VIEWS.USERS.USERS_MODAL.INPUT_USER_MODAL.CONFIRM_PASSWORD") }
                            </Typography>
                            <FormControl className={ classes.formControl }>
                                <InputBase
                                    className={ classes.formInput }
                                    placeholder="confirm Password"
                                    id='confirmPassword'
                                    type='password'
                                    value={ confirmPasswordInputValue }
                                    onChange={ handleValueChange('confirmPassword') } />
                            </FormControl>
                        </Grid>
                    </Grid>
                    <Grid container item direction='row' xs={ 11 } className={ classes.formBox } justify={ (xsDown) ? 'center' : 'space-between' }>
                        <Button className={ classes.cancelEditButton } onClick={ handleCloseModal }>
                            { t("TREND.VIEWS.USERS.USERS_MODAL.INPUT_USER_MODAL.BUTTON_CANCEL") }
                        </Button>
                        <Button className={ classes.validateEditButton } onClick={ handleSubmit }>
                            { t("TREND.VIEWS.USERS.USERS_MODAL.INPUT_USER_MODAL.BUTTON_ADD_USER") }
                        </Button>
                    </Grid>
                </Grid>
            </Grid>
        </>
    )
}

const mapStateToProps = (state) => {
    const loadingAddNewUser = state.usersState.loadingAddNewUser;
    return {
        loadingAddNewUser
    }
}
const mapDispatchToProps = (dispatch) => {
    return {
        addNewUser: (user,userCredentials) => dispatch(addNewUser(user,userCredentials)),
    }
}
export default connect(mapStateToProps,mapDispatchToProps)(UserModals);