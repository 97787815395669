import React, { useState } from "react";
import { firebase } from "../../managers";
import Form from "./Form";
import {
  makeStyles,
  Grid,
  CircularProgress,
  Button,
  Dialog,
} from "@material-ui/core";

const useStyles = makeStyles((theme) => ({
  buttonContainer: {
    marginTop: theme.spacing(2),
    height: "38px",
  },
}));

const Credentials = () => {
  const [loading, setLoading] = useState(false);
  const [errors, setErrors] = useState({});

  const classes = useStyles();

  const signInSubmit = ({ login, password }) => {
    setLoading(true);
    let newErrors;
    firebase
      .auth()
      .signInWithEmailAndPassword(login, password)
      .then((user) => {
        console.log(user)
        setLoading(false);
      })
      .catch((err) => {
        if (err.code === "auth/invalid-email")
          newErrors = {
            ...newErrors,
            login: "Format de l'email incorrect",
          };
        else if (
          err.code === "auth/wrong-password"
        )
          newErrors = {
            ...newErrors,
            login:
              "Email ou mot de passe incorrect",
            password:
              "Mot de passe ou email incorrect",
          };
        else if (
          err.code === "auth/user-not-found"
        )
          newErrors = {
            ...newErrors,
            login:
              "Email ou mot de passe incorrect",
            password:
              "Mot de passe ou email incorrect",
          };
        else {
          console.log(err);
        }
        setLoading(false);
        return setErrors({ ...newErrors });
      });
  };

  const signInFormFields = [
    {
      id: "login",
      type: "login",
      name: "login",
      required: true,
    },
    {
      id: "password",
      type: "password",
      name: "Mot de passe",
      required: true,
    },
  ];

  console.log(errors);

  return (
    <Dialog
      aria-labelledby='simple-dialog-title'
      open={true}
    >
      <Form
        name='signIn'
        variant='signIn'
        onSubmit={signInSubmit}
        fields={signInFormFields}
        credErrors={errors}
        container
        direction='column'
        style={{
          height: "100%",
          width: "100%",
          alignContent: "center",
          justifyContent: "center",
          padding: 20,
        }}
      >
        <Grid
          container
          justify='center'
          alignItems='center'
          className={classes.buttonContainer}
        >
          {loading ? (
            <CircularProgress size={35} />
          ) : (
            <Button
              fullWidth
              type='submit'
              variant='contained'
              color='primary'
            >
              connexion
            </Button>
          )}
        </Grid>
      </Form>
    </Dialog>
  );
};

export default Credentials;
