import React, { useEffect, useState } from "react";
import { connect } from "react-redux";
import { useRouteMatch, Route, Switch, Redirect } from "react-router-dom";
import { auth } from "../managers/index";
import Influencers from "./views/Influencers";
import Statistics from "./views/Statistics";
import { fetchAllInfluencers } from "../../store/actions/influencersActions";
import { fetchAllConversions } from "../../store/actions/conversionsActions";
import { fetchAllBrands } from "../../store/actions/brandsActions";
import { fetchAllInfluencerAgents } from "../../store/actions/influencerAgentsActions";

const Router = (props) => {
  const { url } = useRouteMatch();

  const {
    fetchAllInfluencers,
    fetchAllConversions,
    fetchAllBrands,
    clearConversions,
    loadedInfluencers,
    loadedBrands,
    fetchAllInfluencerAgents,
    loadedInfluencerAgents,

  } = props;

  const [influencerAgent, setInfluencerAgent] = useState(null);
  const [influencerToView, setInfluencerToView] = useState(null);

  useEffect(() => {
    if (!loadedInfluencers) fetchAllInfluencers();
  }, [loadedInfluencers, fetchAllInfluencers]);

  useEffect(() => {
    if (!loadedBrands) fetchAllBrands();
  }, [loadedBrands, fetchAllBrands]);

  useEffect(() => {
    if (!loadedInfluencerAgents) fetchAllInfluencerAgents();
  }, [loadedInfluencerAgents, fetchAllInfluencerAgents]);

  useEffect(() => {
    fetchAllConversions();
  }, [fetchAllConversions]);

  useEffect(() => {
    setInfluencerAgent(props.influencerAgent);
  }, [props.influencerAgent]);

  useEffect(() => {
    setInfluencerToView(props.influencerSearch);
  }, [props.influencerSearch]);

  return (
    <Switch>
      <Route
        path={`${url}/logout`}
        exact
        render={() => {
          clearConversions();
          auth.signOut();
          return <Redirect to="/" />;
        }}
      ></Route>
      <Route path={`${url}/influencers`} exact>
        <Influencers
          influencerAgent={influencerAgent}
          influencerSearch={influencerToView}
        />
      </Route>
      <Route path={`${url}/statistiques`} exact>
        <Statistics influencerAgent={influencerAgent} />
      </Route>
      <Route path={`${url}`}>
        <Redirect to={`${url}/influencers`} />
      </Route>
    </Switch>
  );
};

const mapStateToProps = (state, ownProps) => {
  const loadedInfluencers = state.influencersState.loadedInfluencers;
  const loadedBrands = state.brandsState.loadedBrands;
  const loadedInfluencerAgents = state.influencerAgentsState.loadedInfluencerAgents;

  return {
    loadedInfluencers,
    loadedBrands,
    loadedInfluencerAgents,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    fetchAllBrands: () => dispatch(fetchAllBrands()),
    fetchAllInfluencers: () => dispatch(fetchAllInfluencers()),
    fetchAllConversions: () => dispatch(fetchAllConversions()),
    fetchAllInfluencerAgents: () => dispatch(fetchAllInfluencerAgents()),
    clearConversions: () => dispatch({ type: "CLEAR_CONVERSIONS" }),
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(Router);
