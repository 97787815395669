const initState = {
    agencyTableSortHelper: [0, 0, 0, 0, -1],
    agencyTablePageNumber: 1,
    agencyTablePageSize: 25,
    agencyToValidateTableSortHelper: [1, 0],
    agencyToValidateTablePageNumber: 1,
    agencyToValidateTablePageSize: 25
};

const adminPartnerAgencyReducer = (state = initState, action) => {
    switch (action.type) {
        case 'UPDATE_ADMIN_PARTNER_AGENCY_TABLE_SORT_HELPER':
            return {
                ...state,
                agencyTableSortHelper: action.sortHelper
            };
        case 'UPDATE_ADMIN_PARTNER_AGENCY_TABLE_PAGE_NUMBER':
            return {
                ...state,
                agencyTablePageNumber: action.pageNumber
            };

        case 'UPDATE_ADMIN_PARTNER_AGENCY_TABLE_PAGE_SIZE':
            return {
                ...state,
                agencyTablePageSize: action.pageSize
            };
        case 'UPDATE_ADMIN_PARTNER_AGENCY_TO_VALIDATE_TABLE_SORT_HELPER':
            return {
                ...state,
                agencyToValidateTableSortHelper: action.sortHelper
            };
        case 'UPDATE_ADMIN_PARTNER_AGENCY_TO_VALIDATE_TABLE_PAGE_NUMBER':
            return {
                ...state,
                agencyToValidateTablePageNumber: action.pageNumber
            };
        case 'UPDATE_ADMIN_PARTNER_AGENCY_TO_VALIDATE_TABLE_PAGE_SIZE':
            return {
                ...state,
                agencyToValidateTablePageSize: action.pageSize
            };
        default:
            return state;
    };
};

export default adminPartnerAgencyReducer;