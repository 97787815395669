import React from "react";
import currentDevice from "current-device";
import { Redirect } from "react-router-dom";

// UI Scope
import { DualRing, Scene } from "../../../../ui";

// Relative Dependencies
import { Vector2D } from "../../../../utils";

import "./Wishlists.css";

class Wishlists extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      redirect: null,
      loading: true,
      influencerToViewWishlists: null
    };
    this.scene = null;
    this.layout = null;
  }
  componentDidMount() {
    const wishWrapper = document.querySelector(
      ".wishWrapper"
    );
    let scene = new Scene(
      wishWrapper,
      currentDevice.type === "mobile",
      true,
      null
    );

    window.addEventListener(
      "resize",
      this._refreshScene
    );

    this.scene = scene;
    scene.init().then(() => {
      scene.imagesPerRow(this._layout());

      this.props.influencers.map((influencer) => {
        return scene.addMaterial(
          influencer.banner,
          false,
          this._createContent(influencer)
        );
      });
      scene.load().then((res) => {
        this.setState({ loading: false });
        scene.startAnimation();
      });
    });
  }

  componentWillUnmount() {
    window.removeEventListener(
      "resize",
      this._refreshScene
    );
    if (this.scene) {
      this.scene.deleteScene();
    }
  }

  _layout = () => {
    if (
      window.innerWidth <= 425 &&
      this.layout !== "Mobile"
    ) {
      this.layout = "Mobile";
      return 2;
    } else if (
      window.innerWidth > 425 &&
      window.innerWidth < 768 &&
      this.layout !== "Mobile-Tablet"
    ) {
      this.layout = "Mobile-Tablet";
      return 2.5;
    } else if (
      window.innerWidth >= 768 &&
      window.innerWidth < 1024 &&
      this.layout !== "Tablet"
    ) {
      this.layout = "Tablet";
      return 3;
    } else if (
      window.innerWidth >= 1024 &&
      this.layout !== "Laptop"
    ) {
      this.layout = "Laptop";
      return 3.5;
    }
  };

  _refreshScene = (event) => {
    if (this.scene) {
      const row = this._layout();

      if (row) this.scene.imagesPerRow(row);
    }
  };

  _createContent = (influencer) => {
    let click = new Vector2D(0, 0);
    let clicked = false;
    const mdown = (event) => {
      const pageX = event.changedTouches
        ? event.changedTouches[0].pageX
        : event.pageX;
      const pageY = event.changedTouches
        ? event.changedTouches[0].pageY
        : event.pageY;

      click.set(pageX, pageY);
      if (
        event.button === 0 ||
        event.type === "touchstart"
      )
        clicked = true;
    };
    const mup = (event) => {
      const pageX = event.changedTouches
        ? event.changedTouches[0].pageX
        : event.pageX;
      const pageY = event.changedTouches
        ? event.changedTouches[0].pageY
        : event.pageY;

      if (
        clicked &&
        click.x === pageX &&
        click.y === pageY
      ) {
        this.setState({ redirect: '', influencerToViewWishlists: influencer })
      }

      clicked = false;
    };

    return {
      events: {
        mousedown: mdown,
        mouseup: mup,
        touchstart: mdown,
        touchend: mup,
      },
      element: (
        <div className={"content cursorTrigger"}>
          <div className={"title"}>
            {influencer.name.toUpperCase()}
          </div>
        </div>
      ),
    };
  };

  renderRedirect = () => {
    if (this.state.influencerToViewWishlists) {
      return (
        <Redirect push to={{
          pathname: `/influencer/wishlists/${this.state.influencerToViewWishlists.affiliate_id}`,
          state: {
            influencer: this.state.influencerToViewWishlists
          }
        }} />
      );
      
    };
  };

  render() {
    const { loading } = this.state;
    return (
      <div
        style={{
          position: "absolute",
          top: 0,
          bottom: 0,
          left: 0,
          right: 0,
          zIndex: "1",
        }}
      >
        <div className='wishWrapper'>
          {this.renderRedirect()}
          {
            loading ? (
              <DualRing />
            ) : null // <Filters />
          }
        </div>
      </div>
    );
  }
}

export default Wishlists;

//To go back : Props history -> goBack function
