const initState = {
  wishlists: [],
  wishlistsPerInfluencer: {},
  wishlistsNames: {},
  wishlistsPerBrandInfluencers: {},
  wishlistsError: null,
  wishlistsIdsErrors: false,
  loadedWishlists: false,
  loadedWishlist: false,
  loadedWishlistsPerInfluencer: false,
  loadedAddNewWishlist: false,
  loadedUpdateWishlist: false,
  loadedDeleteWishlist: false,
  loadedWishlistsNames: false
};

const wishlistsReducer = (state = initState, action) => {
  switch (action.type) {
    case "FETCH_WISHLISTS_SUCCESS":
      return {
        ...state,
        wishlists: action.wishlists,
        loadedWishlists: true,
      };

    case "FETCH_WISHLISTS_ERROR":
      return {
        ...state,
        influencersError: action.error,
      };
    case "ADD_WISHLIST_REQUEST":
      return {
        ...state,
        wishlistsError: null,
        loadedAddNewWishlist: true
      };
    case "ADD_WISHLIST_SUCCESS":
      const newWishlists = [...state.wishlists, action.payload.wishlist];
      const newUserWishlists = (state.wishlistsPerInfluencer[action.payload.wishlist.uid]) ? [...state.wishlistsPerInfluencer[action.payload.wishlist.uid], action.payload.wishlist] : [];
      let newWishlistsPerInfluencer = {
        ...state.wishlistsPerInfluencer
      };
      newWishlistsPerInfluencer[action.payload.wishlist.uid] = newUserWishlists;
      newWishlists.push(action.payload.wishlist);
      return {
        ...state,
        wishlists: newWishlists,
        wishlistsPerInfluencer: newWishlistsPerInfluencer,
        loadedAddNewWishlist: false
      };
    case "ADD_WISHLIST_ERROR":
      return {
        ...state,
        wishlistsError: action.error,
        loadedAddNewWishlist: false
      };
    case "UPDATE_WISHLIST_BY_ID_REQUEST":
      return {
        ...state,
        loadedUpdateWishlist: true
      }
    case "UPDATE_WISHLIST_BY_ID_SUCCESS":
      {
        let newWishlistsUpdates = state.wishlists.map((wishlist) => {
          if (wishlist.key === action.payload.wishlist.id) {
            let updatedWishlist = {
              ...wishlist,
            };
            Object.entries(action.payload.updates).forEach(
              ([keyToUpdate, newValue]) => {
                if (keyToUpdate.indexOf("/") !== -1) {
                  let subObjectToUpdate = keyToUpdate.split("/");
                  if (updatedWishlist[subObjectToUpdate[0]] === undefined)
                    updatedWishlist[subObjectToUpdate[0]] = {};
                  updatedWishlist[subObjectToUpdate[0]][
                    subObjectToUpdate[1]
                  ] = newValue;
                } else {
                  updatedWishlist[keyToUpdate] = newValue;
                }
              }
            );
            return updatedWishlist;
          } else {
            return wishlist;
          }
        });
        let newWishlistsPerInfluencer = { ...state.wishlistsPerInfluencer };
        newWishlistsPerInfluencer[action.payload.wishlist.uid] = newWishlistsPerInfluencer[action.payload.wishlist.uid].map(wishlist => {
          if (wishlist.key === action.payload.wishlist.id) {
            let updatedWishlist = {
              ...wishlist,
            };
            Object.entries(action.payload.updates).forEach(
              ([keyToUpdate, newValue]) => {
                if (keyToUpdate.indexOf("/") !== -1) {
                  let subObjectToUpdate = keyToUpdate.split("/");
                  if (updatedWishlist[subObjectToUpdate[0]] === undefined)
                    updatedWishlist[subObjectToUpdate[0]] = {};
                  updatedWishlist[subObjectToUpdate[0]][
                    subObjectToUpdate[1]
                  ] = newValue;
                } else {
                  updatedWishlist[keyToUpdate] = newValue;
                }
              }
            );
            return updatedWishlist;
          } else {
            return wishlist;
          }
        })
        return {
          ...state,
          wishlists: newWishlistsUpdates,
          wishlistsPerInfluencer: newWishlistsPerInfluencer,
          loadedUpdateWishlist: false,
        };
      };
    case "UPDATE_WISHLIST_BY_ID_ERROR":
      return {
        ...state,
        wishlistsError: action.error,
        loadedUpdateWishlist: false
      };
    case "DELETE_WISHLIST_BY_ID_REQUEST":
      return {
        ...state,
        wishlistsError: null,
        loadedDeleteWishlist: true,
      };
    case "DELETE_WISHLIST_BY_ID_SUCCESS":
      {
        const newWishlistsAfterDelete = [...state.wishlists.filter(wish => wish.id !== action.payload.wishlist.key)];
        const userWishlists = state.wishlistsPerInfluencer[action.payload.wishlist.uid];
        let newWishlistsPerInfluencer = {
          ...state.wishlistsPerInfluencer
        };
        newWishlistsPerInfluencer[action.payload.wishlist.uid] = [...userWishlists.filter(wish => action.payload.wishlist.key !== wish.id)];
        return {
          ...state,
          wishlists: newWishlistsAfterDelete,
          wishlistsPerInfluencer: newWishlistsPerInfluencer,
          loadedDeleteWishlist: false,
        };
      };
    case "DELETE_WISHLIST_BY_ID_ERROR":
      return {
        ...state,
        wishlistsError: action.error,
        loadedDeleteWishlist: false,
      };

    case "FETCH_WICHLISTS_PER_INFLUENCER_REQUEST":
      return {
        ...state,
        loadedWishlistsPerInfluencer: false
      };

    case "FETCH_WISHLISTS_PER_INFLUENCER_SUCCESS":
      if (action.payload.wishlistsPerInfluencer) {
        let newWishlistsPerInfluencer = {
          ...state.wishlistsPerInfluencer
        };
        newWishlistsPerInfluencer[action.payload.influencerId] = [];
        if (action.payload.wishlistsPerInfluencer)
          Object.values(action.payload.wishlistsPerInfluencer).forEach((wishlist, i) => {
            let obj = {
              ...wishlist,
              key: (Array.isArray(action.payload.wishlistsPerInfluencer)) ? action.payload.wishlistsPerInfluencer[i].key : Object.keys(action.payload.wishlistsPerInfluencer)[i]
            }
            newWishlistsPerInfluencer[action.payload.influencerId].push(obj);
          });

        return {
          ...state,
          loadedWishlistsPerInfluencer: true,
          wishlistsPerInfluencer: newWishlistsPerInfluencer
        }
      } else {
        let newWishlistsPerInfluencer = {
          ...state.wishlistsPerInfluencer
        };
        newWishlistsPerInfluencer[action.payload.influencerId] = [];
        return {
          ...state,
          loadedWishlistsPerInfluencer: true,
          wishlistsPerInfluencer: newWishlistsPerInfluencer
        }
      };

    case "FETCH_WISHLISTS_NAMES_REQUEST":
      return {
        ...state,
        loadedWishlistsNames: false
      };

    case "FETCH_WISHLISTS_NAMES_SUCCESS":
      return {
        ...state,
        loadedWishlistsNames: true,
        wishlistsNames: action.payload.wishlistsNames
      };

    case "FETCH_WISHLISTS_NAMES_WITH_ERROR":
      return {
        ...state,
        loadedWishlistsNames: true,
        wishlistsIdsErrors: action.payload.wishlistsIdsErrors,
        wishlistsNames: action.payload.wishlistsNames
      };

    case "SET_WISHLISTS_PER_BRAND_INFLUENCERS":
      return {
        ...state,
        wishlistsPerBrandInfluencers: action.payload.wishlists,
      };

    case "CLEAR_BRAND_WISHLISTS":
      return {
        ...state,
        loadedWishlistsNames: false,
        wishlistsIdsErrors: null,
        wishlistsNames: {},
        wishlistsPerBrandInfluencers: {}
      };

    case "FETCH_WISHLIST_BY_ID_REQUEST":
      return {
        ...state,
        loadedWishlist: false
      };

    case "FETCH_WISHLIST_BY_ID_SUCCESS":
      {
        const newWishlists = [...state.wishlists, action.payload.wishlist]
        return {
          ...state,
          wishlists: newWishlists,
          loadedWishlist: true
        };
      };

    case "FETCH_WISHLIST_BY_ID_ERROR":
      return {
        ...state,
        loadedWishlist: true,
        wishlistsError: action.error
      };

    case "FETCH_WISHLIST_BY_ID_NOT_FOUND":
      return {
        ...state,
        wishlistsError: {
          status: 404,
          message: 'Wishlist Not Found'
        },
        loadedWishlist: true,
      };

    case "SET_LOAD_WISHLISTS_PER_INFLUENCER":
      return {
        ...state,
        loadedWishlistsPerInfluencer: false,
      };

    default:
      return state;
  }
};

export default wishlistsReducer;
