import React,{ useEffect,useState,useCallback } from "react";
import { makeStyles,useTheme } from "@material-ui/styles";
import { Typography,Grid,FormControl,FormHelperText,InputBase,useMediaQuery } from "@material-ui/core";
import CheckCircleIcon from "@material-ui/icons/CheckCircle";
import backgroundImage from "../assets/background.png";
import axios from "axios";
// UI Scope
import { Button } from "../../ui";
import TrendLoading from "../../ui/trendLoading/TrendLoading";
import { useHistory } from "react-router-dom";
import publicIp from 'public-ip';
import ipLocation from 'iplocation';
import { useTranslation } from "react-i18next";


// import ContactForm from "./Forms/ContactForm";

const useStyles = makeStyles(theme => ({
  contactContainer: {
    minHeight: `calc(100vh - ${theme.appBar.height})`,
    width: "100%",
    backgroundImage: `url(${backgroundImage})`,
    boxShadow: "inset 0 0 0 100vh rgba(236, 247, 253, 0.5)",
    textAlign: "center"
  },
  registerBox: {
    backgroundColor: "white",
    borderRadius: "40px",
    boxShadow: "0 7px 30px 0 rgba(88, 165, 211, 0.16)",
    //marginTop: '-70px'
  },
  registerPlaceholder: {
    fontFamily: "Poppins",
    fontSize: "22px",
    marginTop: "30px",
    marginBottom: "30px",
    textAlign: "center",
  },
  formContainer: {
    width: "100%",
    display: "flex",
    flexWrap: "wrap",
  },
  textField: {
    width: "85%",
    minHeight: "70px",
    marginLeft: theme.spacing(1),
    marginRight: "25px",
  },
  textContactezNous: {
    fontFamily: "poppins",
    color: "#30bef3",
    marginTop: "25px",
    fontSize: "40px"
  },
  secondPlaceholderRejoint: {
    fontSize: "20px",
    marginTop: "25px",
    //whiteSpace: "nowrap",
    fontFamily: "poppins"
  },
  submitButton: {
    width: "200px",
    height: "50px",
    margin: "10px",
    borderRadius: "35px",
    fontFamily: "Poppins",
    color: "white",
    backgroundColor: "#ed659f",
    textTransform: "none",
    "&:hover": {
      backgroundColor: "#ed659f",
    },
    boxShadow: "0 7px 30px 0 rgba(88, 165, 211, 0.16)",
  },
  inputField: {
    fontFamily: "Poppins",
    borderRadius: "35px",
    boxShadow: "0 7px 30px 0 rgba(69, 128, 163, 0.16)",
    height: "50px",
    margin: "10px",
    paddingTop: "10px",
    paddingBottom: "10px",
    paddingLeft: "20px",
  },
  inputField2: {
    fontFamily: "Poppins",
    borderRadius: "35px",
    boxShadow: "0 7px 30px 0 rgba(69, 128, 163, 0.16)",
    margin: "10px",
    paddingTop: "10px",
    paddingBottom: "10px",
    paddingLeft: "20px",
    border: 'hidden',
    outline: "#fff",
    resize: "none",
    font: "inherit",
    "textarea::placeholder": {
      color: 'red'
    }
  },
  placeholderError: {
    marginLeft: "20px",
    fontFamily: "Poppins"
  },
  messageSentPlaceholder: {
    textAlign: "center",
    fontSize: "18px",
    fontFamily: "Poppins",
  },
}));

// State initiaux des champs
const initialValuesState = {
  email: "",
  nom: "",
  societe: "",
  telephone: "",
  sujet: "",
  message: "",
};


function Contact() {
  const { t } = useTranslation('common');
  const [loading,setLoading] = useState(false);
  const [messageSent,setMessageSent] = useState(false);
  const [errors,setErrors] = useState({});
  const [values,setValues] = useState(initialValuesState);

  const theme = useTheme();
  const smDown = useMediaQuery(theme.breakpoints.down('sm'));
  const lgDown = useMediaQuery(theme.breakpoints.down('lg'));
  const [ip,setIp] = useState(null);
  const [information,setInformation] = useState(null);
  const [countryCode,setCountryCode] = useState(null);

  const history = useHistory();
  const env = process.env.REACT_APP_STAGE;
  const apiUrl = (env === 'prod') ? "https://us-central1-shopmyinfluens.cloudfunctions.net" : "http://localhost:5001/shopmyinfluens/us-central1";

  const routeChange = () => {
    let path = `/home`;
    history.push(path);
  }

  const adressIP = useCallback(async () => {
    return await publicIp.v4();
  },[]);

  useEffect(() => {
    adressIP().then((value) => {
      setIp(value);
    })
  },[adressIP])

  const InformationAboutIpAdress = useCallback(async () => {
    if (ip === null) return null;
    return ipLocation(ip);
  },[ip]);

  useEffect(() => {
    InformationAboutIpAdress().then((value) => {
      setInformation(value);
    })
  },[InformationAboutIpAdress]);

  useEffect(() => {
    if (information === null) {
    } else {
      setCountryCode(information.country.code);
    }
  },[information]);

  const sendContactMail = async () => {
    const data = {
      data: {
        ...values,
      }
    }
    return axios.post(apiUrl + "/sendMail/contact",data);
  };

  const handleValueChange = name => event => {
    setValues({ ...values,[name]: event.target.value,countryCode,date: new Date().toLocaleDateString() });
  };
  // TODO : revoir les validateurs pour le format du telephone et de l'email
  const validForm = () => {
    if (values.email && values.nom) return true;
    return false;
  };
  const submitForm = event => {
    event.preventDefault();
    if (validForm()) {
      setLoading(true);
      sendContactMail({ ...values }).then(
        setLoading(false),
        setMessageSent(true)
      );
      clearFields();
      setErrors({});
    } else {
      let newErrors = {};
      if (!values.email) {
        newErrors = { ...newErrors,email: t('CONTACT_PAGE.FORM.ERROR_CHAMP') };
      }
      if (!values.nom) {
        newErrors = { ...newErrors,nom: t('CONTACT_PAGE.FORM.ERROR_CHAMP') };
      }
      setErrors(newErrors);
    }
  };
  const clearFields = () => {
    setValues(initialValuesState);
  };
  const styles = useStyles();
  return (
    <Grid container item justify="center" alignItems="center" className={ styles.contactContainer }>
      <Grid container item justify="center" lg={ (messageSent) ? 5 : 7 } md={ (messageSent) ? 7 : 9 } xs={ (messageSent) ? 7 : 10 } direction="row">

        {
          loading ?
            <TrendLoading />
            :
            (
              <>

                <Grid
                  className={ styles.registerBox }
                  container
                  item
                  justify="center"
                  alignItems="flex-start"
                  direction="row"
                  style={ (smDown) ? { marginTop: '30px',marginBottom: '30px' } : (lgDown) ? { marginTop: '30px',marginBottom: '30px' } : {} }>

                  { messageSent ?
                    (<>
                      <Grid container item direction="row" xs={ 12 } alignItems="center" justify="center">
                        <Grid container item direction="row" xs={ 9 } alignItems="center" justify="center">
                          <CheckCircleIcon
                            style={ { width: 72,height: 72,margin: 15,color: "#20be80" } }
                          />
                        </Grid>
                        <Grid container item direction="row" xs={ 9 } alignItems="center" justify="center">
                          <Typography className={ styles.messageSentPlaceholder }>
                            { t('CONTACT_PAGE.SUCCESS_MESSAGE.SUCCESS') }
                          </Typography>
                          <br />
                          <Typography className={ styles.messageSentPlaceholder } style={ { marginBottom: "10px" } }>
                            { t('CONTACT_PAGE.SUCCESS_MESSAGE.TEAM') }
                          </Typography>
                          <Button className={ styles.submitButton } style={ { marginBottom: "10px" } } onClick={ routeChange }>{ t('CONTACT_PAGE.SUCCESS_MESSAGE.RETURN_BUTTON') }</Button>
                        </Grid>
                      </Grid>
                    </>)
                    :
                    <>
                      <Grid container item justify="center" alignItems="flex-start" xs={ 12 }>
                        <Grid xs={ 10 } sm={ 8 } md={ 6 } item container alignContent='center' direction='column'>
                          <Typography className={ styles.textContactezNous } style={ (smDown) ? { fontSize: '29px' } : { fontSize: '40px' } } >
                            { t('CONTACT_PAGE.TITLE') }
                          </Typography>
                          <Typography className={ styles.secondPlaceholderRejoint } display='block'>
                            { t('CONTACT_PAGE.JOIN_DESC') }
                            <strong>
                              { t('CONTACT_PAGE.INFLUENCERS') }
                            </strong>{ " " }
                            ?
                          </Typography>
                          <Typography className={ styles.secondPlaceholderRejoint } display='block'>
                            { t('CONTACT_PAGE.OU').toUpperCase() }
                          </Typography>
                          <Typography className={ styles.secondPlaceholderRejoint } display='block' style={ { marginBottom: "25px" } }>
                            { t('CONTACT_PAGE.SHOPSOCIAL_DESC') }
                            <strong>
                              { t('CONTACT_PAGE.SHOPSOCIAL') }
                            </strong>{ " " }
                            ?
                          </Typography>
                        </Grid>
                      </Grid>
                      <form className={ styles.formContainer } onSubmit={ submitForm } style={ { marginTop: "15px" } }>
                        <Grid md={ 6 } item container direction='column' alignContent='center'>
                          <FormControl className={ styles.textField } error={ errors.email ? true : false }>
                            <InputBase id='email' placeholder="Email" value={ values.email } className={ styles.inputField } onChange={ handleValueChange("email") } aria-describedby='email-error' style={ { width: "100%" } } />

                            { errors.email && (
                              <FormHelperText id='email-error' className={ styles.placeholderError }>{ errors.email }</FormHelperText>
                            ) }
                          </FormControl>
                          <FormControl className={ styles.textField } error={ errors.nom ? true : false }>
                            <InputBase id='nom' placeholder="Nom" className={ styles.inputField } value={ values.nom } onChange={ handleValueChange("nom") } aria-describedby='nom-error' style={ { width: "100%" } } />
                            { errors.nom && (
                              <FormHelperText id='nom-error' className={ styles.placeholderError }>{ errors.nom }</FormHelperText>
                            ) }
                          </FormControl>
                          <FormControl className={ styles.textField } error={ errors.societe ? true : false }>
                            <InputBase id='societe' placeholder="Société" className={ styles.inputField } value={ values.societe } onChange={ handleValueChange("societe") } aria-describedby='societe-error' style={ { width: "100%" } } />
                            { errors.societe && (
                              <FormHelperText id='societe-error' className={ styles.placeholderError }>
                                { errors.societe }
                              </FormHelperText>
                            ) }
                          </FormControl>
                          <FormControl className={ styles.textField } error={ errors.telephone ? true : false }>
                            <InputBase id='telephone' placeholder="Téléphone" className={ styles.inputField } value={ values.telephone } onChange={ handleValueChange("telephone") } aria-describedby='telephone-error' style={ { width: "100%" } } />
                            { errors.telephone && (
                              <FormHelperText id='telephone-error' className={ styles.placeholderError }>
                                { errors.telephone }
                              </FormHelperText>
                            ) }
                          </FormControl>
                        </Grid>
                        <Grid md={ 6 } item container direction='column' alignContent='center'>
                          <FormControl className={ styles.textField } error={ errors.sujet ? true : false }>
                            <InputBase id='sujet' placeholder="Sujet" className={ styles.inputField } value={ values.sujet } onChange={ handleValueChange("sujet") } aria-describedby='sujet-error' style={ { width: "100%" } } />
                            { errors.sujet && (
                              <FormHelperText id='sujet-error' className={ styles.placeholderError }>{ errors.sujet }</FormHelperText>
                            ) }
                          </FormControl>
                          <FormControl className={ styles.textField } error={ errors.message ? true : false }>
                            <textarea placeholder="Message" id='message' className={ styles.inputField2 } value={ values.message } onChange={ handleValueChange("message") } aria-describedby='message-error' style={ { width: "100%",margin: "10px",height: "193px",'--placeholderColor': "#dcdcdc" } } />
                            { errors.message && (
                              <FormHelperText id='message-error' className={ styles.placeholderError }>
                                { errors.message }
                              </FormHelperText>
                            ) }
                          </FormControl>
                        </Grid>
                        <Grid item container justify='center' alignContent='center' direction='column' className={ styles.submitContainer }>
                          <Button className={ styles.submitButton } style={ { marginBottom: "10px" } } type='submit'>
                            { t('CONTACT_PAGE.FORM.SUBMIT') }
                          </Button>
                        </Grid>
                      </form>
                    </>
                  }
                </Grid>

              </>
            ) }

      </Grid>
    </Grid>
  );
}

export default Contact;
