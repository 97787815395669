import React from 'react';
import { Grid, Typography, FormControl, InputBase, Button } from '@material-ui/core';
import { makeStyles } from '@material-ui/core';

const useStyles = makeStyles(theme => {
    return {
        pageContent: {
            flexGrow: 1,
            display: 'flex',
            flexDirection: 'column',
            backgroundColor: "#eff9fe",
            marginTop: '15px',
        },
        pageTitle: {
            fontFamily: 'Poppins',
            fontSize: '60px',
            fontWeight: 'bold',
        },
        inputField: {
            width: '100%',
            borderRadius: '35px',
            boxShadow: '0 7px 30px 0 rgba(69, 128, 163, 0.16)',
            marginTop: '10px',
            marginBottom: '20px',
            paddingTop: '5px',
            paddingBottom: '5px',
            paddingLeft: '20px',
            backgroundColor: 'white',
            fontFamily: 'Poppins'
        },
        inputLabel: {
            fontFamily: 'Poppins',
            paddingLeft: '5px',
            marginTop: '5px'
        },
        submitButton: {
            width: '100%',
            height: '50px',
            color: '#ffffff',
            borderRadius: '35px',
            fontSize: '20px',
            backgroundColor: '#3eadef',
            marginTop:'15px',
            textTransform: 'none',
            fontFamily: 'Poppins',
            boxShadow: '0 7px 30px 0 rgba(88, 165, 211, 0.16)',
            '&:hover': {
                backgroundColor: '#3eadef',
            },
            fontWeight: 600,
        }
    }
});

const Parametres = (props) => {
    const classes = useStyles();

    return (
        <Grid className={classes.pageContent}>
            <Grid container item direction='row' justify='center' alignItems='center' xs={12}>
                <Grid container item direction='row' xs={11}>
                    <Grid container item direction='row' xs={12}>
                        <Typography className={classes.pageTitle}>Paramètres</Typography>
                    </Grid>
                    <Grid container item direction='row' xs={12}>
                        <Typography className={classes.inputLabel}>Addresse</Typography>
                    </Grid>
                    <Grid container item direction='row' xs={12}>
                        <FormControl style={{ width: '100%' }}>
                            <InputBase
                                id='addresse'
                                placeholder='Addresse'
                                type='text'
                                className={classes.inputField} />
                        </FormControl>
                    </Grid>
                    <Grid container item direction='row' xs={12}>
                        <Typography className={classes.inputLabel}>Pays</Typography>
                    </Grid>
                    <Grid container item direction='row' xs={12}>
                        <FormControl style={{ width: '100%' }}>
                            <InputBase
                                id='commission'
                                placeholder='Commission'
                                type='text'
                                className={classes.inputField} />
                        </FormControl>
                    </Grid>
                    <Grid container item direction='row' xs={12}>
                        <Typography className={classes.inputLabel}>Nom du contact</Typography>
                    </Grid>
                    <Grid container item direction='row' xs={12}>
                        <FormControl style={{ width: '100%' }}>
                            <InputBase
                                id='contactName'
                                placeholder='Nom du Contact'
                                type='text'
                                className={classes.inputField} />
                        </FormControl>
                    </Grid>
                    <Grid container item direction='row' xs={12}>
                        <Typography className={classes.inputLabel}>Addresse mail de contact</Typography>
                    </Grid>
                    <Grid container item direction='row' xs={12}>
                        <FormControl style={{ width: '100%' }}>
                            <InputBase
                                id='contactEmail'
                                placeholder='Addresse mail de contact'
                                type='text'
                                className={classes.inputField} />
                        </FormControl>
                    </Grid>
                    <Grid container item direction='row' xs={12}>
                        <Typography className={classes.inputLabel}>Numéro de téléphone</Typography>
                    </Grid>
                    <Grid container item direction='row' xs={12}>
                        <FormControl style={{ width: '100%' }}>
                            <InputBase
                                id='phone'
                                placeholder='Numéro de téléphone'
                                type='text'
                                className={classes.inputField} />
                        </FormControl>
                    </Grid>
                    <Grid container item direction='row-reverse' xs={12}>
                        <Grid container item xs={5}>
                            <Button className={classes.submitButton}> Mettre à jour les informations </Button>
                        </Grid>
                    </Grid>
                </Grid>
            </Grid>
        </Grid>
    );
};

export default Parametres;