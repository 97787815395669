import React,{ useState,useEffect } from "react";
import { connect } from "react-redux";
import * as authActions from "../../store/actions/authActions";
import backgroundImage from "./assets/background.png";
import { makeStyles } from "@material-ui/styles";
import {
  Grid,
  Typography,
  Button,
  useTheme,
  useMediaQuery,
} from "@material-ui/core";
import Stepone from "./steps/step1/Stepone";
import SteptwoInfluencer from "./steps/step2/SteptwoInfluencer";
import SteptwoBrand from "./steps/step2/SteptwoBrand";
import SteptwoInfluencerAgent from "./steps/step2/SteptwoInfluencerAgent";
import SteptwoBrandAgent from "./steps/step2/SteptwoBrandAgent";
import StepthreeInfluencer from "./steps/step3/StepthreeInfluencer";
import StepthreeBrand from "./steps/step3/StepthreeBrand";
import StepthreeInfluencerAgent from "./steps/step3/StepthreeInfluencerAgent";
import StepthreeBrandAgent from "./steps/step3/StepthreeBrandAgent";
import { useTranslation } from "react-i18next";
import TrendLoading from "../ui/trendLoading/TrendLoading";
import { fetchNewCategories } from "../../store/actions/categoriesActions";
import { fetchAllBrands } from "../../store/actions/brandsActions";
import { fetchAllCategories } from "../../store/actions/categoriesActions";

const useStyle = makeStyles(() => ({
  registerContent: {
    minHeight: "100vh",
    width: "100vw",
    backgroundImage: `url(${backgroundImage})`,
    boxShadow: "inset 0 0 0 100vh rgba(236, 247, 253, 0.5)",
  },
  logoImage: {
    marginTop: "10px",
    marginBottom: "50px",
    width: "240px",
    height: "120px",
    cursor: 'pointer',
  },
  registerBox: {
    backgroundColor: "white",
    borderRadius: "40px",
    boxShadow: "0 7px 30px 0 rgba(88, 165, 211, 0.16)",
    margin: "50px",
  },
  registerPlaceholder: {
    fontFamily: "Poppins",
    fontSize: "22px",
    marginTop: "30px",
    marginBottom: "30px",
    textAlign: "center",
  },
  notePlaceholder: {
    fontFamily: "Poppins",
    fontSize: "14px",
    paddingRight: "10px",
  },
  nextButton: {
    width: "200px",
    height: "50px",
    margin: "10px",
    borderRadius: "35px",
    fontFamily: "Poppins",
    color: "white",
    backgroundColor: "#ed659f",
    textTransform: "none",
    "&:hover": {
      backgroundColor: "#ed659f",
    },
    boxShadow: "0 7px 30px 0 rgba(88, 165, 211, 0.16)",
  },
  prevButton: {
    width: "200px",
    height: "50px",
    margin: "10px",
    borderRadius: "35px",
    fontFamily: "Poppins",
    textTransform: "none",
    boxShadow: "0 7px 30px 0 rgba(88, 165, 211, 0.16)",
  },
}));

const Register = (props) => {
  const { t,i18n } = useTranslation("common");
  const {
    registrationComplete,
    registrationResponseErrors,
    loading,
    activeStepReducer,
    fetchNewCategories,
    loadedNewCategories,
    fetchAllBrands,
    loadedBrands,
    fetchAllCategories,
    allbrands,
    loadedCategories,
    allCategories,
  } = props;
  const classes = useStyle();
  const [activeStep,setActiveStep] = useState(activeStepReducer);
  const [checkValid,setCheckValid] = useState(false);
  const [userType,setUserType] = useState("influencer");
  const [formValues,setFormValues] = useState({});
  const [brands,setBrands] = useState([]);
  const [categories,setCategories] = useState({});
  const [complete,setComplete] = useState(registrationComplete);
  const [erreur,setErreur] = useState(false);

  useEffect(() => {
    if (!loadedBrands) fetchAllBrands();
  },[loadedBrands,fetchAllBrands]);

  useEffect(() => {
    if (loadedBrands) {
      setBrands(allbrands);
    }
  },[loadedBrands,allbrands]);

  useEffect(() => {
    if (!loadedCategories) fetchAllCategories();
  },[loadedCategories,fetchAllCategories]);

  useEffect(() => {
    if (loadedCategories) {
      setCategories(allCategories);
    }
  },[loadedCategories,allCategories])

  useEffect(() => {
    // let array = ["iHyC1yabrmgowKX2MCSjuiVsKJA3", "WcOBfigwtAb3lZRs5XtxrTXE7vi1"];
    // userManager.addArrayClaim('jffSblAqpTcTCWO1gnc45ueryE03', 'brandsIds', []).then(res => console.log(res));
    // userManager.getClaims('2NFFPYuPxzeFCPZp5wjNJynTh9E3').then((res) => console.log(res))
    // userManager.addBrandTestUser({
    //     userId: 'jffSblAqpTcTCWO1gnc45ueryE03',
    //     email: 'onfluence@influens.fr',
    //     name: 'Onfluence',
    //     image: '',
    // });
    // userManager.addInfluencerAgentTestUser({
    //     userId: 'jffSblAqpTcTCWO1gnc45ueryE03',
    //     email: 'onfluence.influens.fr',
    //     name: 'Onfluence',
    //     image: 'influencerAgent.image',
    //     influencersIds: [],
    // })
    // let array = [
    //   'g3LOBw8cPfeTIweg6AFmbks92qL2',
    //   'w1y40f33vFN03HsF7gAKIXLSLHA2',
    //   'QJT2ThhN4dWaAu7tyAh38tzpdMt1',
    //   'oFEDxxwQOjOVSW3LHwlDiV0XUba2',
    //   '4tYCkK9APsNYvPsWBK0TTdc7Iuv2',
    //   '1Rt3DTzD1qfGk6hueD8Q56R9yZQ2',
    //   'EVdVjaQj0sb8307lvG2Ge96R44J2',
    //   'x4omowz2Zgf2LdFw4XRDkmMgke23',
    //   'L5cuWq0UcbT79KSODKgeOjA6BSp1',
    //   'SJTYrexjaieMzATUwLjZ3JIla6R2',
    //   '3lex2VvcA3hpJYFYlq8SI9cpdxv1',
    //   'DzyDx49OSQM6qRJxBvGWhHskDFY2',
    //   'nqMFFkHuJEdhRfP18OyUZ8ZTFJd2'
    // ];
    // userManager.addClaimWithValue('jffSblAqpTcTCWO1gnc45ueryE03', 'influencersIds', ['g3LOBw8cPfeTIweg6AFmbks92qL2', 'w1y40f33vFN03HsF7gAKIXLSLHA2']).then(res => console.log(res));
    //userManager.addClaimWithValue('YwEWOBL0m7Vn8rOWZ0ZbF0hYVNs2', 'admin', true).then(res => console.log(res));
    //userManager.addClaimWithValue('Yk8ntKIp3aSerQgw29poEH9DjX42', 'brand', true).then(res => console.log(res));
    //userManager.addClaimWithValue('Yk8ntKIp3aSerQgw29poEH9DjX42', 'brandOfferId', 1399).then(res => console.log(res));
    // console.log(userManager.getClaims('jffSblAqpTcTCWO1gnc45ueryE03'));
    // let arrayInfluencers = [
    //   'g3LOBw8cPfeTIweg6AFmbks92qL2',
    //   'w1y40f33vFN03HsF7gAKIXLSLHA2',
    //   '1Rt3DTzD1qfGk6hueD8Q56R9yZQ2',
    //   'EVdVjaQj0sb8307lvG2Ge96R44J2',
    //   'x4omowz2Zgf2LdFw4XRDkmMgke23',
    //   'L5cuWq0UcbT79KSODKgeOjA6BSp1',
    //   'SJTYrexjaieMzATUwLjZ3JIla6R2',
    //   '3lex2VvcA3hpJYFYlq8SI9cpdxv1',
    //   'DzyDx49OSQM6qRJxBvGWhHskDFY2',
    //   'nqMFFkHuJEdhRfP18OyUZ8ZTFJd2',
    //   'QJT2ThhN4dWaAu7tyAh38tzpdMt1',
    //   'oFEDxxwQOjOVSW3LHwlDiV0XUba2',
    //   '4tYCkK9APsNYvPsWBK0TTdc7Iuv2'
    // ];
    // userManager.addClaimWithValue('jffSblAqpTcTCWO1gnc45ueryE03', 'influencersIds', arrayInfluencers).then(res => console.log(res))
    /*     dbManager.fetchAllBrandUsers().then(users => {
          const usersToLoop = Object.entries(users);
          for (let i = 0; i < usersToLoop.length; i++) {
            let [keybrand,values] = usersToLoop[i];
            //claimsFunction(values.uid);
            console.log(values.uid);
          }
    
    
        }); */

  },[]);

  // ["iHyC1yabrmgowKX2MCSjuiVsKJA3", "WcOBfigwtAb3lZRs5XtxrTXE7vi1"]
  const theme = useTheme();
  const xsDown = useMediaQuery(theme.breakpoints.down("xs"));

  const handleActiveUser = (userType) => {
    setUserType(userType);
  };

  const handleNext = () => {
    setActiveStep((prevActiveStep) => prevActiveStep + 1);
  };

  const handleBack = () => {
    setActiveStep((prevActiveStep) => prevActiveStep - 1);
  };

  const validateStep = () => {
    setCheckValid(true);
  };

  const goToHome = () => {
    props.history.push('/');
  }

  useEffect(() => {
    if (registrationResponseErrors !== null) {
      setErreur(true);
      setActiveStep(1);
    } else {
      setErreur(false);
    }
  },[registrationResponseErrors]);

  useEffect(() => {
    if (registrationComplete) {
      setComplete(registrationComplete);
    }
  },[registrationComplete]);

  useEffect(() => {
    if (complete) {
      if (userType === "influencer") {
        if (props.location.state == null) {
          props.history.push("/");
        } else {
          props.history.push(props.location.state.previousRoute);
        }
      }
    }
  },[complete,userType,props.history,props.location.state]);

  useEffect(() => {
    if (!loadedNewCategories) fetchNewCategories();
  },[loadedNewCategories,fetchNewCategories]);

  const handleValidation = (valid) => {
    setCheckValid(false);
    console.log('VALIDATE')
    if (valid) {
      if (activeStep === 2) {
        props.register(userType,formValues,i18n.language);
      } else {
        handleNext();
      }
    }
  };

  const handleStepValues = (step,stepValues) => {
    let newValues = formValues;
    newValues[step] = stepValues;
    setFormValues(formValues);
  };

  const getStepContent = (stepIndex,userType) => {
    switch (stepIndex) {
      case 0:
        return (
          <Stepone
            activeUser={ (userType) => {
              handleActiveUser(userType);
            } }
            userType={ userType }
            validStep={ handleValidation }
            checkValid={ checkValid }
          />
        );
      case 1: {
        switch (userType) {
          case "influencer":
            return (
              <SteptwoInfluencer
                stepValues={ (values) =>
                  handleStepValues("stepTwoInfluencer",values)
                }
                valuesToPopulate={ formValues.stepTwoInfluencer }
                validStep={ handleValidation }
                checkValid={ checkValid }
              />
            );
          case "brand":
            return (
              <SteptwoBrand
                stepValues={ (values) =>
                  handleStepValues("stepTwoBrand",values)
                }
                valuesToPopulate={ formValues.stepTwoBrand }
                validStep={ handleValidation }
                checkValid={ checkValid }
              />
            );
          case "influencerAgent":
            return (
              <SteptwoInfluencerAgent
                registrationResponseErrors={ erreur }
                stepValues={ (values) =>
                  handleStepValues("stepTwoInfluencerAgent",values)
                }
                valuesToPopulate={ formValues.stepTwoInfluencerAgent }
                validStep={ handleValidation }
                checkValid={ checkValid }
              />
            );
          case "brandAgent":
            return (
              <SteptwoBrandAgent
                registrationResponseErrors={ erreur }
                stepValues={ (values) =>
                  handleStepValues("stepTwoBrandAgent",values)
                }
                valuesToPopulate={ formValues.stepTwoBrandAgent }
                validStep={ handleValidation }
                checkValid={ checkValid }
              />
            );
          default:
            return null;
        }
      }
      case 2: {
        switch (userType) {
          case "influencer":
            return (
              <StepthreeInfluencer
                registrationComplete={ complete }
                categories={ categories }
                brands={ brands }
                stepValues={ (values) =>
                  handleStepValues("stepThreeInfluencer",values)
                }
                valuesToPopulate={ formValues.stepThreeInfluencer }
                validStep={ handleValidation }
                checkValid={ checkValid }
              />
            );
          case "brand":
            return (
              <StepthreeBrand
                registrationComplete={ complete }
                categories={ categories }
                stepValues={ (values) =>
                  handleStepValues("stepThreeBrand",values)
                }
                valuesToPopulate={ formValues.stepThreeBrand }
                validStep={ handleValidation }
                checkValid={ checkValid }
              />
            );
          case "influencerAgent":
            return (
              <StepthreeInfluencerAgent
                registrationComplete={ complete }
                stepValues={ (values) =>
                  handleStepValues("stepThreeInfluencerAgent",values)
                }
                valuesToPopulate={ formValues.stepThreeInfluencerAgent }
                validStep={ handleValidation }
                checkValid={ checkValid }
              />
            );
          case "brandAgent":
            return (
              <StepthreeBrandAgent
                registrationComplete={ complete }
                stepValues={ (values) =>
                  handleStepValues("stepThreeBrandAgent",values)
                }
                valuesToPopulate={ formValues.stepThreeBrandAgent }
                validStep={ handleValidation }
                checkValid={ checkValid }
              />
            );
          default:
            return null;
        }
      }
      default:
        return null;
    }
  };

  const continueToWebsite = () => {
    props.history.push(props.location.state.previousRoute);
  };

  const stepContent = getStepContent(activeStep,userType);

  return (
    <Grid
      container
      item
      justify="center"
      alignItems="center"
      className={ classes.registerContent }
    >
      <Grid
        container
        item
        justify="center"
        lg={ 7 }
        md={ 9 }
        xs={ 12 }
        direction="row"
      >
        { loading ? (
          <TrendLoading />
        ) : (
          <>
            <img
              src="https://firebasestorage.googleapis.com/v0/b/shopmyinfluens.appspot.com/o/shopmyinfluens%2FApp%20Data%2Flogo_shopmyinfluence-01.png?alt=media"
              alt="influence"
              className={ classes.logoImage }
              onClick={ goToHome }
            />
            <Grid
              container
              item
              justify="center"
              alignItems="flex-start"
              className={ classes.registerBox }
              direction="row"
            >
              <Grid
                container
                item
                justify="center"
                alignItems="flex-start"
                xs={ 12 }
              >
                <Typography className={ classes.registerPlaceholder }>
                  { t("REGISTER.REGISTER_TITLE") }
                </Typography>
              </Grid>
              <Grid container item justify="center" xs={ 10 }>
                { stepContent }
                <Grid
                  container
                  item
                  justify={ xsDown || complete ? "center" : "space-between" }
                  alignItems="center"
                  style={ { marginTop: "30px",marginBottom: "30px" } }
                >
                  { complete ? (
                    <>
                      <Button
                        className={ classes.nextButton }
                        onClick={ continueToWebsite }
                      >
                        { t("REGISTER.CONTINUE_TO_WEBSITE_MESSAGE") }
                      </Button>
                    </>
                  ) : (
                    <>
                      { activeStep === 0 ? (
                        <Typography className={ classes.notePlaceholder }>
                          { t("REGISTER.INFLUENCER_AGENT_INDICATOR") }
                        </Typography>
                      ) : (
                        <Button
                          className={ classes.prevButton }
                          onClick={ handleBack }
                        >
                          { t("REGISTER.PREVIOUS_STEP") }
                        </Button>
                      ) }
                      { activeStep === 2 ? (
                        <Button
                          className={ classes.nextButton }
                          onClick={ validateStep }
                        >
                          { t("REGISTER.CONFIRM_SIGNUP") }
                        </Button>
                      ) : (
                        <Button
                          className={ classes.nextButton }
                          onClick={ validateStep }
                        >
                          { t("REGISTER.NEXT_STEP") }
                        </Button>
                      ) }
                    </>
                  ) }
                </Grid>
              </Grid>
            </Grid>
          </>
        ) }
      </Grid>
    </Grid>
  );
};

const mapStateToProps = (state) => {
  const registrationComplete = state.authState.registrationComplete;
  const registrationResponseErrors = state.authState.registrationResponseErrors;
  const loading = state.authState.loading;
  const activeStepReducer = state.authState.activeStep;
  const loadedNewCategories = state.categoriesState.loadedNewCategories;
  const loadedBrands = state.brandsState.loadedBrands;
  const allbrands = state.brandsState.allBrands;
  const loadedCategories = state.categoriesState.loadedCategories;
  const allCategories = state.categoriesState.categories;
  return {
    registrationComplete,
    registrationResponseErrors,
    loading,
    activeStepReducer,
    loadedNewCategories,
    loadedBrands,
    allbrands,
    loadedCategories,
    allCategories,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    register: (userType,formValues,language) =>
      dispatch(authActions.Register(userType,formValues,language)),
    fetchNewCategories: () => dispatch(fetchNewCategories()),
    fetchAllBrands: () => dispatch(fetchAllBrands()),
    fetchAllCategories: () => dispatch(fetchAllCategories()),
  };
};
export default connect(mapStateToProps,mapDispatchToProps)(Register);
