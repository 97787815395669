import { Grid, makeStyles, Typography, Select, MenuItem, Button, IconButton, withStyles, CircularProgress, Backdrop, Snackbar } from '@material-ui/core';
import React, { useEffect, useState } from 'react';
import CheckCircleIcon from '@material-ui/icons/CheckCircle';
import Alert from '@material-ui/lab/Alert';
import { Pagination, PaginationItem } from "@material-ui/lab";
import SVGIcon from "../../template/assets/SVGIcons/index";
import BootstrapInput from "../../template/assets/customComponents/BootstrapInput";
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import clsx from "clsx";
import { connect } from 'react-redux';
import { updateSoldArticleById } from '../../../../store/actions/articlesActions';
import { useTranslation } from "react-i18next";

const useStyle = makeStyles(theme => ({
    titles: {
        fontFamily: "Poppins",
        fontWeight: 500,
    },
    buttonDateStyle: {
        width: "170px",
        height: "40px",
        margin: "5px",
        overflow: "hidden",
        boxShadow: "0 7px 30px 0 rgb(88 165 211 / 16%)",
        textAlign: "center",
        textTransform: "none",
        borderRadius: "24px",
        padding: "10",
        backgroundColor: "#3eadef",
        "&:hover": {
            backgroundColor: "#3eadef",
        },
    },
    textButtonDate: {
        textAlign: "center",
        fontFamily: "Poppins",
        fontWeight: 500,
        color: "#fff",
    },
    selectClass: {
        fontFamily: "Poppins",
        borderRadius: "25px",
        width: "130px",
        "&:focus": {
            borderRadius: "25px",
            backgroundColor: "#ffffff",
        },
        boxShadow: "0 7px 30px 0 rgba(88, 165, 211, 0.16)"
    },
    spacement: {
        marginTop: '20px',
        marginBottom: '20px'
    },
    categTableHeader: {
        fontFamily: 'Poppins',
        padding: '10px'
    },
    categTableRow: {
        minHeight: '100px',
        backgroundColor: '#fff',
        boxShadow: '0 7px 30px 0 rgba(88, 165, 211, 0.16)',
        borderRadius: '27px',
        marginTop: '15px',
        marginBottom: '15px'
    },
    categTableCell: {
        fontFamily: 'Poppins',
        padding: '10px'
    },
    productImage: {
        width: '100px',
        height: '100px',
        objectFit: 'contain',
        borderTopLeftRadius: '27px',
        borderBottomLeftRadius: '27px'
    },
    selectCategoriesClass: {
        width: '90%'
    },
    selectCategoriesRootClass: {
        borderRadius: '15px',
        border: 'solid 1px #70707040',
        paddingLeft: '10px',
        fontFamily: 'Poppins',
        color: '#000',
        "&:focus": {
            borderRadius: "15px",
            backgroundColor: "#ffffff",

        },
    },
    colorSquare: {
        border: 'solid #c0c0c0 0.5px',
        width: '15px',
        height: '15px',
        borderRadius: '25px',
        margin: '10px'
    },
    tableTypeButtonStyle: {
        width: '220px',
        fontFamily: 'Poppins',
        backgroundColor: '#ffffff',
        margin: '10px',
        borderRadius: '27px',
        textTransform: 'none',
        '&:hover': {
            backgroundColor: '#ffffff',
        }
    },
    activeTableTypeButtonStyle: {
        width: '220px',
        color: '#ffffff',
        fontFamily: 'Poppins',
        backgroundColor: '#ff82b0cf',
        margin: '10px',
        borderRadius: '27px',
        textTransform: 'none',
        '&:hover': {
            backgroundColor: '#ff82b0cf',
        }
    },
    paginationClass: {
        backgroundColor: "#ffffff !important",
        boxShadow: "0 7px 30px 0 rgba(88, 165, 211, 0.16)",
        borderRadius: "8px",
        fontFamily: "Poppins",
        height: "40px",
        width: "40px",
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
    },
    selectedPaginationItem: {
        backgroundColor: "#3eadef !important",
        boxShadow: "0 7px 30px 0 rgba(88, 165, 211, 0.16)",
        borderRadius: "8px",
        fontFamily: "Poppins",
        height: "40px",
        width: "40px",
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
        color: "#ffffff",
    },
    overlay: {
        zIndex: theme.zIndex.drawer + 1
    },
}));

const iconStyles = {
    selectIcon: {
        color: "black"
    },
    loaderStyles: {
        color: 'red'
    }
};

const CustomExpandMore = withStyles(iconStyles)(
    ({ className, classes, ...rest }) => {
        return (
            <ExpandMoreIcon
                {...rest}
                className={clsx(className, classes.selectIcon)}
            />
        );
    }
);

const SoldProductSubView = (props) => {

    const { t, i18n } = useTranslation("common");
    const classes = useStyle();
    const [tableType, setTableType] = useState('allProducts');
    const [categoriesSelectValue, setCategoriesSelectValue] = useState('placeholder');
    const [subCategoriesSelectValue, setSubCategoriesSelectValue] = useState('placeholder');
    const [colorsSelectValue, setColorsSelectValue] = useState('placeholder');
    const [articlesToRender, setArticlesToRender] = useState([]);
    const [tableToRender, setTableToRender] = useState([]);
    const [page, setPage] = useState(1);
    const [count, setCount] = useState(1);
    const [size, setSize] = useState(10);
    const [loading, setLoading] = useState(false);
    const [snackBarUpdateSuccessState, setSnackBarUpdateSuccessState] = useState(false);
    const [snackBarUpdateErrorState, setSnackBarUpdateErrorState] = useState(false);
    const [requestUpdateArticleMode, setRequestUpdateArticleMode] = useState(false);

    const {
        categories,
        subCategories,
        colors,
        articles,
        allBrands,
        loadedNewCategories,
        loadedSubCategories,
        loadedColors,
        loadedArticles,
        loadedBrands,
        loadedUpdateArticle,
        updateArticleError,
        updateSoldArticleById
    } = props;

    const handleInputsValueChange = (id, productId) => e => {
        if (id === 'categories') {
            let obj = {};
            let obj2 = {};
            if (categoriesSelectValue === 'placeholder') {
                obj[productId] = e.target.value;
            } else {
                obj = {
                    ...categoriesSelectValue
                };
                obj[productId] = e.target.value
                obj2 = {
                    ...subCategoriesSelectValue
                };
                obj2[productId] = 'placeholder'
            }
            setCategoriesSelectValue(obj);
            setSubCategoriesSelectValue(obj2)
        };
        if (id === 'sub-categories') {
            let obj = {};
            if (subCategoriesSelectValue === 'placeholder') {
                obj[productId] = e.target.value;
            } else {
                obj = {
                    ...subCategoriesSelectValue
                };
                obj[productId] = e.target.value;
            }
            setSubCategoriesSelectValue(obj);
        }
        if (id === 'colors') {
            let obj = {};
            if (colorsSelectValue === 'placeholder') {
                obj[productId] = e.target.value;
            } else {
                obj = {
                    ...colorsSelectValue
                };
                obj[productId] = e.target.value
            }
            setColorsSelectValue(obj);
        }
    };

    const handleTableToRenderType = (type) => {
        setTableType(type);
        setPage(1);
    };

    const handlePageSizeChange = (event) => {
        setSize(event.target.value);
        setPage(1);
    };

    const handlePageChange = (event, value) => {
        setPage(value);
    };

    const updateProduct = (product) => {
        let updates = {};
        if (categoriesSelectValue[product.key] !== 'placeholder') updates['categ'] = categoriesSelectValue[product.key].key;
        if (subCategoriesSelectValue[product.key] !== 'placeholder') updates['subCateg'] = subCategoriesSelectValue[product.key].key;
        if (colorsSelectValue[product.key] !== 'placeholder') updates['mainColor'] = colorsSelectValue[product.key].key;
        setRequestUpdateArticleMode(true);
        updateSoldArticleById(product.key, updates);
    };

    // Filtering products to render
    useEffect(() => {
        setLoading(true);
        if (loadedArticles && loadedBrands && loadedNewCategories && loadedSubCategories && loadedColors) {
            let categoriesSelectValue = {};
            let subCategoriesSelectValue = {};
            let colorsSelectValue = {};
            articles.forEach(article => {
                (article.categ)
                    ? (categories.filter(category => category.key === article.categ)[0])
                        ? categoriesSelectValue[article.key] = categories.filter(category => category.key === article.categ)[0]
                        : categoriesSelectValue[article.key] = 'placeholder'
                    : categoriesSelectValue[article.key] = 'placeholder';
                (article.subCateg)
                    ? (subCategories.filter(subCategory => subCategory.key === article.subCateg)[0])
                        ? subCategoriesSelectValue[article.key] = subCategories.filter(subCategory => subCategory.key === article.subCateg)[0]
                        : subCategoriesSelectValue[article.key] = 'placeholder'
                    : subCategoriesSelectValue[article.key] = 'placeholder';
                (article.mainColor)
                    ? (colors.filter(color => color.key === article.mainColor)[0])
                        ? colorsSelectValue[article.key] = colors.filter(color => color.key === article.mainColor)[0]
                        : colorsSelectValue[article.key] = 'placeholder'
                    : colorsSelectValue[article.key] = 'placeholder';
            });
            setCategoriesSelectValue(categoriesSelectValue);
            setSubCategoriesSelectValue(subCategoriesSelectValue);
            setColorsSelectValue(colorsSelectValue);
            switch (tableType) {
                case 'allProducts':
                    setArticlesToRender(articles.map(article => {
                        let brand = allBrands.filter(brand => brand.offerId === article.offerId)[0];
                        article['brandName'] = (brand && brand.displayName) ? brand.displayName : 'Unavailable';
                        return article;
                    }));
                    break;
                case 'categorized':
                    {
                        let filteredArticles = articles.filter(article => (article.categ !== undefined));
                        setArticlesToRender(filteredArticles.map(article => {
                            let brand = allBrands.filter(brand => brand.offerId === article.offerId)[0];
                            article['brandName'] = (brand && brand.displayName) ? brand.displayName : 'Unavailable';
                            return article;
                        }));
                    };
                    break;
                case 'uncategorized':
                    {
                        let filteredArticles = articles.filter(article => (article.categ === undefined));
                        setArticlesToRender(filteredArticles.map(article => {
                            let brand = allBrands.filter(brand => brand.offerId === article.offerId)[0];
                            article['brandName'] = (brand && brand.displayName) ? brand.displayName : 'Unavailable';
                            return article;
                        }));
                    };
                    break;
                default:
                    setArticlesToRender(articles);
                    break;
            }
            setLoading(false);
        };
    }, [loadedArticles, tableType, articles, loadedBrands, allBrands, categories, subCategories, colors, loadedNewCategories, loadedSubCategories, loadedColors])

    useEffect(() => {
        setTableToRender(
            articlesToRender.slice((page - 1) * size, page * size)
        );
        setCount(
            articlesToRender.length % size === 0
                ? Math.trunc(articlesToRender.length / size)
                : Math.trunc(articlesToRender.length / size) + 1
        );
    }, [articlesToRender, size, page]);

    useEffect(() => {
        if (!loadedUpdateArticle && requestUpdateArticleMode) {
            if (!updateArticleError) {
                setSnackBarUpdateSuccessState(true);
                setRequestUpdateArticleMode(false);
            } else {
                setRequestUpdateArticleMode(false);
                setSnackBarUpdateErrorState(true);
            }
        }
    }, [loadedUpdateArticle, updateArticleError, requestUpdateArticleMode]);

    return (
        (loadedNewCategories && loadedSubCategories && loadedColors && !loading)
            ?
            <Grid container item direction='column'>
                <Backdrop className={classes.overlay} open={loadedUpdateArticle}>
                    <CircularProgress color='secondary' />
                </Backdrop>
                <Snackbar open={snackBarUpdateSuccessState} onClose={() => setSnackBarUpdateSuccessState(false)} autoHideDuration={2000}>
                    <Alert severity="success" variant='filled' style={{ fontFamily: 'Poppins' }}>
                        {t('ADMIN.VIEWS.CATEGORIES.SOLD_PRODUCTS_TAB_CONTENT.SNACKBARS.UPDATE_SUCCESS')}
                    </Alert>
                </Snackbar>
                <Snackbar open={snackBarUpdateErrorState} onClose={() => setSnackBarUpdateErrorState(false)} autoHideDuration={2000}>
                    <Alert severity="error" variant='filled' style={{ fontFamily: 'Poppins' }}>
                        {t('ADMIN.VIEWS.CATEGORIES.SOLD_PRODUCTS_TAB_CONTENT.SNACKBARS.UPDATE_ERROR')}
                    </Alert>
                </Snackbar>
                <Grid container item direction='row' wrap='wrap' xs={12}>
                    <Button className={(tableType === 'allProducts') ? classes.activeTableTypeButtonStyle : classes.tableTypeButtonStyle} onClick={() => handleTableToRenderType('allProducts')}>
                        {t('ADMIN.VIEWS.CATEGORIES.SOLD_PRODUCTS_TAB_CONTENT.ALL_PRODUCTS_BUTTON')}
                    </Button>
                    <Button className={(tableType === 'categorized') ? classes.activeTableTypeButtonStyle : classes.tableTypeButtonStyle} onClick={() => handleTableToRenderType('categorized')}>
                        {t('ADMIN.VIEWS.CATEGORIES.SOLD_PRODUCTS_TAB_CONTENT.CATEGORIZED_PRODUCTS')}
                    </Button>
                    <Button className={(tableType === 'uncategorized') ? classes.activeTableTypeButtonStyle : classes.tableTypeButtonStyle} onClick={() => handleTableToRenderType('uncategorized')}>
                        {t('ADMIN.VIEWS.CATEGORIES.SOLD_PRODUCTS_TAB_CONTENT.UNCATEGORIZED_PRODUCTS')}
                    </Button>
                </Grid>
                <Grid container item direction='row' xs={12} style={{ overflowX: "auto" }}>
                    <div style={{ width: '100%', minWidth: '1050px' }}>
                        <Grid container item direction='row' alignItems='center' className={classes.spacement}>
                            <Grid container item direction='row' alignItems='center' justify='center' style={{ width: '100px' }}>
                                <Typography className={classes.categTableHeader}>
                                    {t('ADMIN.VIEWS.CATEGORIES.SOLD_PRODUCTS_TAB_CONTENT.TABLE_HEADERS.PRODUCT')}
                                </Typography>
                            </Grid>
                            <Grid container item direction='row' alignItems='center' style={{ flexGrow: 1, width: 'calc(100% - 150px)' }}>
                                <Grid container item direction='row' alignItems='center' justify='center' style={{ width: '25%' }}>
                                    <Typography className={classes.categTableHeader}>
                                        {t('ADMIN.VIEWS.CATEGORIES.SOLD_PRODUCTS_TAB_CONTENT.TABLE_HEADERS.BRAND')}
                                    </Typography>
                                </Grid>
                                <Grid container item direction='row' alignItems='center' justify='center' style={{ width: '25%' }}>
                                    <Typography className={classes.categTableHeader}>
                                        {t('ADMIN.VIEWS.CATEGORIES.SOLD_PRODUCTS_TAB_CONTENT.TABLE_HEADERS.CATEGORY')}
                                    </Typography>
                                </Grid>
                                <Grid container item direction='row' alignItems='center' justify='center' style={{ width: '25%' }}>
                                    <Typography className={classes.categTableHeader}>
                                        {t('ADMIN.VIEWS.CATEGORIES.SOLD_PRODUCTS_TAB_CONTENT.TABLE_HEADERS.SUB_CATEGORY')}
                                    </Typography>
                                </Grid>
                                <Grid container item direction='row' alignItems='center' justify='center' style={{ width: '25%' }}>
                                    <Typography className={classes.categTableHeader}>
                                        {t('ADMIN.VIEWS.CATEGORIES.SOLD_PRODUCTS_TAB_CONTENT.TABLE_HEADERS.MAIN_COLOR')}
                                    </Typography>
                                </Grid>
                            </Grid>
                            <Grid container item direction='row' style={{ width: '50px' }}>

                            </Grid>
                        </Grid>
                        {
                            tableToRender.map(article => (
                                <Grid container item direction='row' alignItems='center' className={classes.categTableRow} key={article.key}>
                                    <Grid container item direction='row' style={{ width: '100px' }}>
                                        <a href={article.url} target='_blank' rel='noopener noreferrer' style={{ height: '100px' }}>
                                            <img src={article.image} alt='' className={classes.productImage} />
                                        </a>
                                    </Grid>
                                    <Grid container item direction='row' alignItems='center' style={{ flexGrow: 1, width: 'calc(100% - 150px)' }}>
                                        <Grid container item direction='row' alignItems='center' justify='center' style={{ width: '25%' }}>
                                            <Typography className={classes.categTableCell}>{article.brandName}</Typography>
                                        </Grid>
                                        <Grid container item direction='row' alignItems='center' justify='center' style={{ width: '25%' }}>
                                            <Select
                                                disableUnderline
                                                className={classes.selectCategoriesClass}
                                                classes={{ root: classes.selectCategoriesRootClass }}
                                                value={(categoriesSelectValue[article.key] ? categoriesSelectValue[article.key] : 'placeholder')}
                                                onChange={handleInputsValueChange('categories', article.key)}
                                                renderValue={(selected) => {
                                                    if (selected === 'placeholder') {
                                                        return <Typography style={{ fontFamily: 'Poppins', color: '#717171', paddingLeft: '10px' }}>
                                                            {t('ADMIN.VIEWS.CATEGORIES.SOLD_PRODUCTS_TAB_CONTENT.CATEGORY_PLACEHOLDER')}
                                                        </Typography>;
                                                    }
                                                    return selected['text-fr'];
                                                }}
                                                IconComponent={CustomExpandMore}>
                                                <MenuItem value='placeholder' style={{ display: 'none' }}></MenuItem>
                                                {
                                                    categories.map(category => (
                                                        <MenuItem key={category.key} value={category} style={{ fontFamily: 'Poppins' }}>
                                                            {(i18n.language === 'fr-FR' || i18n.language === 'fr') ? category['text-fr'] : category['text-en']}
                                                        </MenuItem>
                                                    ))
                                                }
                                            </Select>
                                        </Grid>
                                        <Grid container item direction='row' alignItems='center' justify='center' style={{ width: '25%' }}>
                                            <Select
                                                disableUnderline
                                                className={classes.selectCategoriesClass}
                                                classes={{ root: classes.selectCategoriesRootClass }}
                                                value={(subCategoriesSelectValue[article.key] ? subCategoriesSelectValue[article.key] : 'placeholder')}
                                                disabled={subCategories.filter(subCategory => {
                                                    if (subCategory.categ) {
                                                        return (subCategory.categ.indexOf(categoriesSelectValue[article.key].key) !== -1)
                                                    } else {
                                                        return false
                                                    }
                                                }).length === 0}
                                                onChange={handleInputsValueChange('sub-categories', article.key)}
                                                renderValue={(selected) => {
                                                    if (selected === 'placeholder') {
                                                        if (
                                                            subCategories.filter(subCategory => {
                                                                if (subCategory.categ) {
                                                                    return (subCategory.categ.indexOf(categoriesSelectValue[article.key].key) !== -1)
                                                                } else {
                                                                    return false
                                                                }
                                                            }).length === 0
                                                            && (categoriesSelectValue !== 'placeholder' && categoriesSelectValue[article.key] !== 'placeholder')
                                                        ) {

                                                            return <Typography style={{ fontFamily: 'Poppins', color: '#717171', paddingLeft: '10px' }}>
                                                                {t('ADMIN.VIEWS.CATEGORIES.SOLD_PRODUCTS_TAB_CONTENT.NO_OPTION_PLACEHOLDER')}
                                                            </Typography>;
                                                        } else {
                                                            return <Typography style={{ fontFamily: 'Poppins', color: '#717171', paddingLeft: '10px' }}>
                                                                {t('ADMIN.VIEWS.CATEGORIES.SOLD_PRODUCTS_TAB_CONTENT.SUB_CATEGORY_PLACEHOLDER')}
                                                            </Typography>;
                                                        }
                                                    }
                                                    return (i18n.language === 'fr-FR' || i18n.language === 'fr') ? selected['text-fr'] : selected['text-en'];
                                                }}
                                                IconComponent={CustomExpandMore}>
                                                <MenuItem value='placeholder' style={{ display: 'none' }}></MenuItem>
                                                {
                                                    subCategories.filter(subCategory => {
                                                        if (subCategory.categ) {
                                                            return (subCategory.categ.indexOf(categoriesSelectValue[article.key].key) !== -1)
                                                        } else {
                                                            return false
                                                        }
                                                    }).map(subCategory => (
                                                        <MenuItem key={subCategory.key} value={subCategory} style={{ fontFamily: 'Poppins' }}>
                                                            {(i18n.language === 'fr-FR' || i18n.language === 'fr') ? subCategory['text-fr'] : subCategory['text-en']}
                                                        </MenuItem>
                                                    ))
                                                }
                                            </Select>
                                        </Grid>
                                        <Grid container item direction='row' alignItems='center' justify='center' style={{ width: '25%' }}>
                                            <Select
                                                disableUnderline
                                                className={classes.selectCategoriesClass}
                                                style={{ width: '70%' }}
                                                classes={{ root: classes.selectCategoriesRootClass }}
                                                value={(colorsSelectValue[article.key] ? colorsSelectValue[article.key] : 'placeholder')}
                                                onChange={handleInputsValueChange('colors', article.key)}
                                                renderValue={(selected) => {
                                                    if (selected === 'placeholder') {
                                                        return <Typography style={{ fontFamily: 'Poppins', color: '#717171', paddingLeft: '10px' }}>
                                                            {t('ADMIN.VIEWS.CATEGORIES.SOLD_PRODUCTS_TAB_CONTENT.MAIN_COLOR_PLACEHOLDER')}
                                                        </Typography>;
                                                    }
                                                    return <Typography style={{ fontFamily: 'Poppins' }}>
                                                        {(i18n.language === 'fr-FR' || i18n.language === 'fr') ? selected.name_fr : selected.name_en}
                                                    </Typography>
                                                }}
                                                IconComponent={CustomExpandMore}>
                                                <MenuItem value='placeholder' style={{ display: 'none' }}></MenuItem>
                                                {
                                                    colors.map(color => (
                                                        <MenuItem key={color.key} value={color} style={{ fontFamily: 'Poppins' }}>
                                                            <span className={classes.colorSquare} style={{ backgroundColor: color['code'] }}></span>
                                                            {(i18n.language === 'fr-FR' || i18n.language === 'fr') ? color['name_fr'] : color['name_en']}
                                                        </MenuItem>
                                                    ))
                                                }
                                            </Select>
                                        </Grid>
                                    </Grid>
                                    <Grid container item direction='row' justify='center' style={{ width: '50px' }}>
                                        <IconButton
                                            disabled={(
                                                ((article.categ === categoriesSelectValue[article.key].key) &&
                                                    (article.subCateg === subCategoriesSelectValue[article.key].key) &&
                                                    (article.mainColor === colorsSelectValue[article.key].key)) ||
                                                ((categoriesSelectValue[article.key].key === undefined) &&
                                                    (subCategoriesSelectValue[article.key].key === undefined) &&
                                                    (colorsSelectValue[article.key].key === undefined))
                                            )}
                                            onClick={() => updateProduct(article)}>
                                            <CheckCircleIcon style={
                                                (
                                                    article.categ !== categoriesSelectValue[article.key].key ||
                                                    article.subCateg !== subCategoriesSelectValue[article.key].key ||
                                                    article.mainColor !== colorsSelectValue[article.key].key
                                                )
                                                    ? { color: '#52b552' }
                                                    : {}
                                            } />
                                        </IconButton>
                                    </Grid>
                                </Grid>
                            ))
                        }
                    </div>
                </Grid>

                <Grid container item direction="row" xs={12} justify='center' alignItems="center" style={{ marginTop: "10px", marginBottom: "10px" }}>
                    <Grid container alignItems="center" justify="flex-end" style={{ width: '95%' }}>
                        <Select value={size} onChange={handlePageSizeChange} input={<BootstrapInput />} IconComponent={() => <SVGIcon name="miniArrow" />} classes={{ root: classes.paginationStyles }}>
                            <MenuItem value={5} style={{ fontFamily: "Poppins" }}>
                                5
                        </MenuItem>
                            <MenuItem value={10} style={{ fontFamily: "Poppins" }}>
                                10
                        </MenuItem>
                            <MenuItem value={20} style={{ fontFamily: "Poppins" }}>
                                20
                        </MenuItem>
                        </Select>
                        <Typography className={classes.categTableHeader} style={{ marginRight: "10px", marginLeft: "10px" }}>
                            {t('ADMIN.VIEWS.CATEGORIES.SOLD_PRODUCTS_TAB_CONTENT.PAGINATION_OF_PLACEHOLDER')} {articlesToRender.length}
                        </Typography>
                        <Pagination count={count} page={page} onChange={handlePageChange}
                            renderItem={(item) => (
                                <PaginationItem
                                    classes={{
                                        root: classes.paginationClass,
                                        selected: classes.selectedPaginationItem,
                                    }}
                                    {...item}
                                />
                            )}
                        />
                    </Grid>
                </Grid>
            </Grid>
            :
            <Grid container item direction='column' justify='center' alignItems='center' style={{ flexGrow: 1 }}>
                <CircularProgress color='secondary' />
            </Grid>
    )
};

const mapStateToProps = (state, ownProps) => {
    const categories = state.categoriesState.newCategories;
    const subCategories = state.categoriesState.subCategories;
    const colors = state.colorsState.colors;
    const articles = state.articlesState.articles;
    const allBrands = state.brandsState.allBrands;
    const loadedNewCategories = state.categoriesState.loadedNewCategories;
    const loadedSubCategories = state.categoriesState.loadedSubCategories;
    const loadedColors = state.colorsState.loadedColors;
    const loadedArticles = state.articlesState.loadedArticles;
    const loadedBrands = state.brandsState.loadedBrands;
    const loadedUpdateArticle = state.articlesState.loadedUpdateArticle;
    const updateArticleError = state.articlesState.updateArticleError;
    return {
        categories,
        subCategories,
        colors,
        articles,
        allBrands,
        loadedArticles,
        loadedNewCategories,
        loadedSubCategories,
        loadedColors,
        loadedBrands,
        loadedUpdateArticle,
        updateArticleError
    };
};

const mapDispatchToProps = (dispatch) => {
    return {
        updateSoldArticleById: (articleId, updates) => {
            dispatch({ type: 'UPDATE_SOLD_ARTICLE_BY_ID_REQUEST' });
            dispatch(updateSoldArticleById(articleId, updates));
        }
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(SoldProductSubView);