import React from "react";
import PropTypes from "prop-types";

import {
  FormControl,
  FormHelperText,
  Input,
  InputLabel,
} from "@material-ui/core";

const Field = ({ id, type, name, error, value = "", handleValueChange }) => (
  <FormControl error={error ? true : false} style={{ height: "70px" }}>
    <InputLabel htmlFor={id}>{name}</InputLabel>
    <Input
      id={id}
      value={value}
      onChange={handleValueChange(id)}
      aria-describedby={`${id}-error`}
      type={type}
    />
    {error && <FormHelperText id={`${id}-error`}>{error}</FormHelperText>}
  </FormControl>
);
Field.propTypes = {
  id: PropTypes.string.isRequired,
  type: PropTypes.string.isRequired,
  handleValueChange: PropTypes.func.isRequired,
  value: PropTypes.string,
  name: PropTypes.string,
  error: PropTypes.string,
};

export default Field;
