import React from "react";
import clsx from "clsx";
// import PropTypes from 'prop-types';
import { useHoverStyles } from "../styles";
import { useState } from "react";

const HoverEffect = (props) => {
  /* Props */
  const { children, visible, classes } = props;

  /* State */
  const [hover, setHover] = useState(false);

  const defaultClasses = useHoverStyles();

  const handleMouseEnter = () => {
    setHover(true);
  };
  const handleMouseLeave = () => {
    setHover(false);
  };

  return (
    <div
      className={clsx(
        defaultClasses.root,
        classes && classes.root
      )}
      onMouseEnter={handleMouseEnter}
      onMouseLeave={handleMouseLeave}
    >
      <div
        className={clsx(
          defaultClasses.effect,
          (hover || visible) &&
            defaultClasses.effectHover,
          classes && classes.effect
        )}
      >
        {children}
      </div>
    </div>
  );
};

HoverEffect.propTypes = {};

export default HoverEffect;
