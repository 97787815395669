import React, { useState } from "react";

function Overlay({ children, color, visible, handleElementTouched, id }) {
  const [hover, setHover] = useState(false);
  const styles = {
    container: {
      position: "absolute",
      top: 0,
      right: 0,
      bottom: 0,
      left: 0,
    },
    content: {
      position: "absolute",
      top: visible || hover ? 0 : "50%",
      right: visible || hover ? 0 : "50%",
      bottom: visible || hover ? 0 : "50%",
      left: visible || hover ? 0 : "50%",
      overflow: "hidden",
      backgroundColor: color,
      opacity: visible || hover ? 1 : 0,
      transition:
        visible || hover
          ? "top 0.3s, right 0.3s, bottom 0.3s, left 0.3s, opacity 0.4s"
          : "top 0.4s, right 0.4s, bottom 0.4s, left 0.4s, opacity 0.4s",
    },
    delete: {
      position: "absolute",
      top: 5,
      right: 5,
    },
  };
  return (
    <div
      style={styles.container}
      onMouseEnter={() => setHover(true)}
      onMouseLeave={() => setHover(false)}
      onTouchEnd={() => handleElementTouched(id)}
    >
      <div style={styles.content}>{children}</div>
    </div>
  );
}

export default Overlay;
