import React, { useState, useEffect, Children, cloneElement } from "react";
import { Drawer, Hidden, useTheme, useMediaQuery, makeStyles } from "@material-ui/core";
import SideBar from './components/SideBar';
import { useRouteMatch } from "react-router-dom";
import Header from "./components/Header";

const useStyles = makeStyles((theme) => {
  const drawerWidth = '280px';
  return {
    drawer: {
      width: drawerWidth,
      flexShrink: 0,
      height: '100%',
      borderStyle: 'none'
    },
    drawerPaper: {
      width: drawerWidth,
      justifyContent: 'flex-start',
      borderStyle: 'none !important'
    },
    content: {
      minHeight: '100vh',
      width: '100%',
      backgroundColor: "#eff9fe",
      display: 'flex',
      flexDirection: 'column',
    },
    influencerContent: {
      minHeight: '100vh',
      width: '100%',
      backgroundColor: "#eff9fe",
      display: 'flex',
      flexDirection: 'column',
    }
  }
});

const Template = (props) => {
  const classes = useStyles();
  const { url } = useRouteMatch();
  const theme = useTheme();
  const xsDown = useMediaQuery(theme.breakpoints.down("xs"));
  const smUp = useMediaQuery(theme.breakpoints.up('sm'));
  const [mobileMode, setMobileMode] = useState(false);
  const [openDrawer, setOpenDrawer] = useState(false);
  const [influencerSearch, setInfluencerSearch] = useState(null);
  const [partnerTypeToLoad, setPartnerTypeToLoad] = useState('brands');
  const [filterValue, setFilterValue] = useState('');
  // let location = useLocation();
  // const actualTab = location.pathname.split('/').pop();
  useEffect(() => {
    if (xsDown) {
      setMobileMode(true);
      setOpenDrawer(false);
    }
    if (smUp) setMobileMode(false);
  }, [xsDown, smUp]);

  const handleCloseDrawer = () => {
    setOpenDrawer(false);
  }

  const navigationChange = () => {
    setOpenDrawer(false);
  }

  const handleInfluencerPopupRedirection = (influencer) => {
    setInfluencerSearch(influencer);
  }

  const handleInfluencerTableSearch = (searchValue) => {
    setFilterValue(searchValue)
  }

  const handlePartnerTypeToLoad = (partnerType) => {
    setPartnerTypeToLoad(partnerType)
  }

  const childrenWithProps = Children.map(props.children, (child, index) => {
    return cloneElement(child, { influencerSearch, filterValue, partnerTypeToLoad });
  });

  return (
    <>
      <Hidden smUp={smUp}>
        <Drawer
          className={classes.drawer}
          variant='temporary'
          open={openDrawer}
          classes={{ paper: classes.drawerPaper }}
          anchor='left'
          onClose={handleCloseDrawer}
        >
          <SideBar mobileMode={mobileMode} {...props} navEvent={navigationChange} />
        </Drawer>
      </Hidden>
      <Hidden xsDown={xsDown}>
        <Drawer
          className={classes.drawer}
          variant='permanent'
          classes={{ paper: classes.drawerPaper }}
          anchor='left'
          open={openDrawer}
        >
          <SideBar mobileMode={mobileMode} {...props} navEvent={navigationChange} partnerTypeToLoad={handlePartnerTypeToLoad} />
        </Drawer>
      </Hidden>
      <main className={classes.content}>
        <Header
          opendrawer={() => { setOpenDrawer(true) }}
          {...{ ...props, mobileMode, relativeUrl: url }}
          openInfluencerInfos={(influencer) => handleInfluencerPopupRedirection(influencer)}
          searchInfluencer={(searchValue) => handleInfluencerTableSearch(searchValue)}
        />
        <>{childrenWithProps}</>
      </main>
    </>
  );
};

export default Template;
