import React, { useCallback, useEffect, useState } from "react";
import { connect } from "react-redux";
import { Pagination, PaginationItem } from '@material-ui/lab';
import { Grid, makeStyles, Typography, IconButton, Select, MenuItem, Menu, CircularProgress } from '@material-ui/core';
import MoreVertIcon from '@material-ui/icons/MoreVert';
import ExpandLessIcon from '@material-ui/icons/ExpandLess';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import SortIcon from '@material-ui/icons/Sort';
import SVGIcon from '../../assets/SVGIcons'
import BootstrapInput from '../../assets/customComponents/BootstrapInput';
import { useTranslation } from "react-i18next";
import unavailableImage from '../../assets/img/unavailable-image.png';
import placeholderBrand from '../../assets/img/brand.png';


const useStyles = makeStyles(() => ({
    tableHeader: {
        fontFamily: 'Poppins',
        cursor: 'pointer',
        marginTop: '10px',
        marginBottom: '10px'
    },
    tableRow: {
        backgroundColor: 'white',
        height: '130px',
        borderRadius: '27px',
        boxShadow: '0 7px 30px 0 rgba(88, 165, 211, 0.16)',
        marginTop: '10px',
        marginBottom: '10px'
    },
    brandImage: {
        height: '130px',
        borderTopLeftRadius: '27px',
        borderBottomLeftRadius: '27px',
        width: '130px',
    },
    tableCell: {
        fontFamily: 'Poppins',
    },
    paginationClass: {
        backgroundColor: '#ffffff !important',
        boxShadow: '0 7px 30px 0 rgba(88, 165, 211, 0.16)',
        borderRadius: '8px',
        fontFamily: 'Poppins',
        height: '40px',
        width: '40px',
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center'
    },
    selectedPaginationItem: {
        backgroundColor: '#3eadef !important',
        boxShadow: '0 7px 30px 0 rgba(88, 165, 211, 0.16)',
        borderRadius: '8px',
        fontFamily: 'Poppins',
        height: '40px',
        width: '40px',
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        color: '#ffffff'
    },
    paginationStyles: {
        fontFamily: 'Poppins'
    },
    menu: {
        boxShadow: '0 7px 7px 0 #d0e6f130',
    },
    placeholderStyles: {
        fontFamily: 'Poppins',
        color: '#1B4C61'
    }
}));

const AgencyTable = (props) => {

    const { t } = useTranslation('common');
    const classes = useStyles();

    const [anchorEl, setAnchorEl] = useState(null);
    const [page, setPage] = useState(1);
    const [count, setCount] = useState(0);
    const [size, setSize] = useState(10);
    const [sortHelper, setSortHelper] = useState([0, 0, 0, 0, -1])
    const [sortEvent, setSortEvent] = useState(0);
    const [loading, setLoading] = useState(true);
    const [brandAgentArray, setBrandAgentArray] = useState([]);
    const [agentTableToRender, setAgentTableToRender] = useState([]);
    const [tableBeforeRender, setTableBeforeRender] = useState([]);
    const [agentToOperateOn, setAgentToOperateOn] = useState(null);

    const {
        searchValue,
        allBrandAgent,
        allConversions,
        brands,
        loadedBrands,
        loadedConversions,
        loadedAllBrandAgent,
        appStateSortHelper,
        appStatePageNumber,
        appStatePageSize,
        changePageNumber,
        changeSortHelper,
        changePageSize
    } = props;


    const newFilterBrandAgent = useCallback(async () => {
        let object = {};
        let newArray = [];
        if (loadedAllBrandAgent && loadedBrands && loadedConversions) {

            for (let i = 0; i < allBrandAgent.length; i++) {
                if (allBrandAgent[i].brandsIds !== undefined) {

                    for (let j = 0; j < allBrandAgent[i].brandsIds.length; j++) {

                        brands.filter(element => element.offerId === allBrandAgent[i].brandsIds[j]).map((filteredBrand) => {
                            let found = false;
                            Object.keys(object).forEach((key) => {
                                if (key === allBrandAgent[i].key) {
                                    object[key][filteredBrand.offerId] = filteredBrand
                                    found = true;
                                }
                            });
                            if (!found) {
                                object[allBrandAgent[i].key] = {};
                                object[allBrandAgent[i].key][filteredBrand.offerId] = {
                                    ...filteredBrand
                                };
                                object[allBrandAgent[i].key]['brandAgentData'] = {
                                    ...allBrandAgent[i]
                                }
                            };
                            return null;
                        });

                    }
                }
            }
            for (let i = 0; i < allBrandAgent.length; i++) {
                if (allBrandAgent[i].brandsIds === undefined || allBrandAgent[i].brandsIds.length === 0) {
                    object[allBrandAgent[i].key] = { ...allBrandAgent[i] };
                }
            }

            Object.values(object).forEach((brandAgentArray) => {
                let amount = 0;
                let commission = 0;
                let brand = 0;
                let commissionSMI = 0;
                let commissionAffiliate = 0;
                let name = "";
                let image = "";

                if (brandAgentArray.brandAgentData !== undefined) {
                    name = brandAgentArray.brandAgentData.name;
                    image = brandAgentArray.brandAgentData.image;
                    brand = (Object.keys(brandAgentArray).length - 1);

                    Object.values(brandAgentArray).forEach((conversion) => {
                        if (conversion.offerId !== undefined) {
                            allConversions.filter(element => element.offerId === conversion.offerId).map((brand) => {
                                amount += brand.amount;
                                commission += Number(brand.commission);
                                commissionAffiliate += Number(brand.commissionAffiliate);
                                return null;
                            });
                        }
                    });
                    commissionSMI = commission - commissionAffiliate;
                    newArray.push({
                        amount,
                        commission,
                        brand,
                        commissionSMI,
                        name,
                        image,
                        ...brandAgentArray
                    })

                } else {
                    name = brandAgentArray.name;
                    image = brandAgentArray.image
                    brand = 0;
                    newArray.push({
                        amount,
                        commission,
                        brand,
                        commissionSMI,
                        name,
                        image,
                        ...brandAgentArray
                    })
                }

            });

        }
        return newArray;
    }, [loadedAllBrandAgent, allBrandAgent, allConversions, brands, loadedBrands, loadedConversions])

    const filter = useCallback(async () => {
        let brandAgentIds = [];
        await newFilterBrandAgent().then((result) => {
            brandAgentIds = result;
        });

        brandAgentIds.sort((a, b) => {
            if (a.amount > b.amount) return -1;
            if (a.amount < b.amount) return 1;
            return 0;
        });
        setBrandAgentArray(brandAgentIds);

    }, [newFilterBrandAgent]);

    useEffect(() => {
        filter().then(() => {
            setLoading(false);
        });
    }, [filter]);

    useEffect(() => {
        setSortHelper(appStateSortHelper);
    }, [appStateSortHelper]);

    useEffect(() => {
        setPage(appStatePageNumber);
    }, [appStatePageNumber]);

    useEffect(() => {
        setSize(appStatePageSize);
    }, [appStatePageSize]);

    useEffect(() => {
        let tableAfterSearch = (searchValue !== '')
            ? brandAgentArray.filter(agent => agent.name.toLowerCase().includes(searchValue.toLowerCase()))
            : brandAgentArray;
        setAgentTableToRender(tableAfterSearch.slice((page - 1) * size, page * size));
        setTableBeforeRender(tableAfterSearch);
        setCount(
            (tableAfterSearch.length % size === 0)
                ? Math.trunc(tableAfterSearch.length / size)
                : Math.trunc(tableAfterSearch.length / size) + 1
        );
    }, [brandAgentArray, size, page, sortEvent, searchValue]);

    useEffect(() => {
        let indexAsc = sortHelper.indexOf(1);
        let indexDesc = sortHelper.indexOf(-1);
        if (indexAsc !== -1) {
            const sortTypeAsc = (indexAsc, tableToWorkOn) => {
                switch (indexAsc) {
                    case 0:
                        tableToWorkOn.sort((a, b) => {
                            if (a.amount > b.amount) return -1;
                            if (a.amount < b.amount) return 1;
                            return 0;
                        });
                        break;
                    case 1:
                        tableToWorkOn.sort((a, b) => {
                            if (a.brand > b.brand) return -1;
                            if (a.brand < b.brand) return 1;
                            return 0;
                        });
                        break;
                    case 2:
                        tableToWorkOn.sort((a, b) => {
                            if (a.commission > b.commission) return -1;
                            if (a.commission < b.commission) return 1;
                            return 0;
                        });
                        break;
                    case 3:
                        tableToWorkOn.sort((a, b) => {
                            if (a.commissionSMI > b.commissionSMI) return -1;
                            if (a.commissionSMI < b.commissionSMI) return 1;
                            return 0;
                        });
                        break;
                    case 4:
                        tableToWorkOn.sort((a, b) => {
                            if (a.displayName > b.displayName) return -1;
                            if (a.displayName < b.displayName) return 1;
                            return 0;
                        });
                        break;
                    default:
                        break;
                }
            }
            sortTypeAsc(indexAsc, brandAgentArray);
        } else {
            const sortTypeDesc = (indexDesc, tableToWorkOn) => {
                switch (indexDesc) {
                    case 0:
                        tableToWorkOn.sort((a, b) => {
                            if (a.amount > b.amount) return 1;
                            if (a.amount < b.amount) return -1;
                            return 0;
                        });
                        break;
                    case 1:
                        tableToWorkOn.sort((a, b) => {
                            if (a.brand > b.brand) return 1;
                            if (a.brand < b.brand) return -1;
                            return 0;
                        });
                        break;
                    case 2:
                        tableToWorkOn.sort((a, b) => {
                            if (a.commission > b.commission) return 1;
                            if (a.commission < b.commission) return -1;
                            return 0;
                        });
                        break;
                    case 3:
                        tableToWorkOn.sort((a, b) => {
                            if (a.commissionSMI > b.commissionSMI) return 1;
                            if (a.commissionSMI < b.commissionSMI) return -1;
                            return 0;
                        });
                        break;
                    case 4:
                        tableToWorkOn.sort((a, b) => {
                            if (a.displayName > b.displayName) return 1;
                            if (a.displayName < b.displayName) return -1;
                            return 0;
                        });
                        break;
                    default:
                        break;
                }
            };
            sortTypeDesc(indexDesc, brandAgentArray);
        }
        setSortEvent(s => s + 1);
    }, [brandAgentArray, sortHelper]);

    const handleOpenMenu = (event, agentToOperateOn) => {
        setAnchorEl(event.currentTarget);
        setAgentToOperateOn(agentToOperateOn);
    };

    const handleCloseMenu = () => {
        setAnchorEl(null);
    };

    const handleEditAgent = (e) => {
        setAnchorEl(null);
        props.openEditAgent(agentToOperateOn);
    }

    const handlePageChange = (event, value) => {
        changePageNumber(value);
    };

    const handlePageSizeChange = (event) => {
        changePageSize(event.target.value);
        changePageNumber(1);
    };



    return (
        (loading)
            ? <div style={{ display: 'flex', flexDirection: 'column', flexGrow: 1, width: '100%', justifyContent: 'center', alignItems: 'center' }}>
                <CircularProgress size={36} color='secondary' />
            </div>
            :
            (agentTableToRender.length === 0)
                ? <>
                    <Grid container item direction='row' xs={11} justify='center' alignItems='center' style={{ marginTop: '100px' }}>
                        <img
                            alt="No BrandAgents"
                            src={placeholderBrand}
                            height="75px"
                            width="75px"
                        />
                    </Grid>
                    <Grid container item direction='row' xs={11} justify='center' alignItems='center' style={{ marginTop: '10px' }}>
                        <Typography className={classes.placeholderStyles}>
                            {t('ADMIN.VIEWS.PARTNERS.AGENCES.NO_BRANDS_AGENT_PLACEHOLDER')}
                        </Typography>
                    </Grid>
                </>
                :
                <>
                    <Grid container item direction='row' xs={11} alignItems='center' justify='center'>
                        <div style={{ overflowX: 'auto', width: '100%' }}>
                            <Grid container item style={{ minWidth: '1050px' }} direction='row' xs={12}>
                                <Grid container item direction='row' xs={12}>
                                    <Grid container item xs={11}>
                                        <Grid container justify='center' alignItems='center' style={{ width: '35%' }} onClick={() => {
                                            (sortHelper[4] === 0) ? changeSortHelper([0, 0, 0, 0, 1]) : changeSortHelper([0, 0, 0, 0, -sortHelper[4]]);
                                        }}>
                                            <Typography className={classes.tableHeader}>
                                                {t('ADMIN.VIEWS.PARTNERS.AGENCES.AGENCE_TABLE_HEADERS.BRAND_AGENT_NAME')}
                                            </Typography>
                                            {(sortHelper[4] === 1)
                                                ? <ExpandLessIcon className={classes.tableHeaderIcon} />
                                                : ((sortHelper[4] === -1)
                                                    ? <ExpandMoreIcon className={classes.tableHeaderIcon} />
                                                    : <SortIcon className={classes.tableHeaderIcon} />)}
                                        </Grid>
                                        <Grid container justify='center' alignItems='center' style={{ width: '14%' }} onClick={() => {
                                            (sortHelper[0] === 0) ? changeSortHelper([1, 0, 0, 0, 0]) : changeSortHelper([-sortHelper[0], 0, 0, 0, 0]);
                                        }}>
                                            <Typography className={classes.tableHeader}>
                                                {t('ADMIN.VIEWS.PARTNERS.AGENCES.AGENCE_TABLE_HEADERS.TURNOVER')}
                                            </Typography>
                                            {(sortHelper[0] === 1)
                                                ? <ExpandLessIcon className={classes.tableHeaderIcon} />
                                                : ((sortHelper[0] === -1)
                                                    ? <ExpandMoreIcon className={classes.tableHeaderIcon} />
                                                    : <SortIcon className={classes.tableHeaderIcon} />)}
                                        </Grid>
                                        <Grid container justify='center' alignItems='center' style={{ width: '17%' }} onClick={() => {
                                            (sortHelper[1] === 0) ? changeSortHelper([0, 1, 0, 0, 0]) : changeSortHelper([0, -sortHelper[1], 0, 0, 0]);
                                        }}>
                                            <Typography className={classes.tableHeader}>
                                                {t('ADMIN.VIEWS.PARTNERS.AGENCES.AGENCE_TABLE_HEADERS.BRANDS')}
                                            </Typography>
                                            {(sortHelper[1] === 1)
                                                ? <ExpandLessIcon className={classes.tableHeaderIcon} />
                                                : ((sortHelper[1] === -1)
                                                    ? <ExpandMoreIcon className={classes.tableHeaderIcon} />
                                                    : <SortIcon className={classes.tableHeaderIcon} />)}
                                        </Grid>
                                        <Grid container justify='center' alignItems='center' style={{ width: '17%' }} onClick={() => {
                                            (sortHelper[2] === 0) ? changeSortHelper([0, 0, 1, 0, 0]) : changeSortHelper([0, 0, -sortHelper[2], 0, 0]);
                                        }}>
                                            <Typography className={classes.tableHeader}>
                                                {t('ADMIN.VIEWS.PARTNERS.AGENCES.AGENCE_TABLE_HEADERS.COMMISSIONS')}
                                            </Typography>
                                            {(sortHelper[2] === 1)
                                                ? <ExpandLessIcon className={classes.tableHeaderIcon} />
                                                : ((sortHelper[2] === -1)
                                                    ? <ExpandMoreIcon className={classes.tableHeaderIcon} />
                                                    : <SortIcon className={classes.tableHeaderIcon} />)}
                                        </Grid>
                                        <Grid container justify='center' alignItems='center' style={{ width: '17%' }} onClick={() => {
                                            (sortHelper[3] === 0) ? changeSortHelper([0, 0, 0, 1, 0]) : changeSortHelper([0, 0, 0, -sortHelper[3], 0]);
                                        }}>
                                            <Typography className={classes.tableHeader}>
                                                {t('ADMIN.VIEWS.PARTNERS.AGENCES.AGENCE_TABLE_HEADERS.SMI_COMMISSIONS')}
                                            </Typography>
                                            {(sortHelper[3] === 1)
                                                ? <ExpandLessIcon className={classes.tableHeaderIcon} />
                                                : ((sortHelper[3] === -1)
                                                    ? <ExpandMoreIcon className={classes.tableHeaderIcon} />
                                                    : <SortIcon className={classes.tableHeaderIcon} />)}
                                        </Grid>
                                    </Grid>
                                </Grid>
                                {agentTableToRender.map((brandAgent, key) => {
                                    return (
                                        <Grid container item xs={12} direction='row' className={classes.tableRow} key={key}>
                                            <Grid container item xs={11}>
                                                <Grid container item alignItems='center' style={{ width: '35%' }}>
                                                    {brandAgent.image === undefined || brandAgent.image === "" ?
                                                        <img src={unavailableImage} alt={brandAgent.name} className={classes.brandImage} /> :
                                                        <img src={brandAgent.image} alt={brandAgent.name} className={classes.brandImage} />
                                                    }

                                                    <Typography className={classes.tableCell} style={{ marginLeft: '30px' }}>{brandAgent.name}</Typography>
                                                </Grid>
                                                <Grid container item justify='center' alignItems='center' style={{ width: '14%' }}>
                                                    <Typography className={classes.tableCell}>{Math.round(brandAgent.amount * 100) / 100} €</Typography>
                                                </Grid>
                                                <Grid container item justify='center' alignItems='center' style={{ width: '17%' }}>
                                                    <Typography className={classes.tableCell}>{brandAgent.brand}</Typography>
                                                </Grid>
                                                <Grid container item justify='center' alignItems='center' style={{ width: '17%' }}>
                                                    <Typography className={classes.tableCell}>{Math.round(brandAgent.commission * 100) / 100} €</Typography>
                                                </Grid>
                                                <Grid container item justify='center' alignItems='center' style={{ width: '17%' }}>
                                                    <Typography className={classes.tableCell}>{Math.round(brandAgent.commissionSMI * 100) / 100} €</Typography>
                                                </Grid>
                                            </Grid>
                                            <Grid container item justify='center' alignItems='center' xs={1}>
                                                <IconButton onClick={(e) => handleOpenMenu(e, brandAgent)}>
                                                    <MoreVertIcon />
                                                </IconButton>
                                                <Menu
                                                    classes={{ paper: classes.menu }}
                                                    anchorEl={anchorEl}
                                                    open={Boolean(anchorEl)}
                                                    onClose={handleCloseMenu}

                                                >
                                                    <MenuItem
                                                        className={classes.tableHeader}
                                                        onClick={() => handleEditAgent()}>
                                                        {t('ADMIN.VIEWS.PARTNERS.AGENCES.BRAND_AGENT_MENU.EDIT_ITEM')}
                                                    </MenuItem>
                                                </Menu>
                                            </Grid>
                                        </Grid>
                                    )
                                })}
                            </Grid>
                        </div>

                    </Grid>
                    <Grid container item direction='row' xs={11} alignItems='center' style={{ marginBottom: '20px' }}>
                        <Grid container alignItems='center' justify='flex-end'>
                            <Select
                                value={size}
                                onChange={handlePageSizeChange}
                                input={<BootstrapInput />}
                                IconComponent={() => (
                                    <SVGIcon name='miniArrow' />
                                )}
                                classes={{ root: classes.paginationStyles }}
                            >
                                <MenuItem value={10} style={{ fontFamily: 'Poppins' }}>10</MenuItem>
                                <MenuItem value={25} style={{ fontFamily: 'Poppins' }}>25</MenuItem>
                                <MenuItem value={50} style={{ fontFamily: 'Poppins' }}>50</MenuItem>
                            </Select>
                            <Typography className={classes.tableHeader} style={{ marginRight: '10px', marginLeft: '10px' }}>
                                {t('ADMIN.VIEWS.PARTNERS.AGENCES.PAGINATION_PLACEHOLDER')}  {tableBeforeRender.length}
                            </Typography>
                            <Pagination
                                count={count}
                                page={page}
                                onChange={handlePageChange}
                                renderItem={(item) => (
                                    <PaginationItem classes={{ root: classes.paginationClass, selected: classes.selectedPaginationItem }} {...item} />
                                )} />
                        </Grid>
                    </Grid>
                </>
    )
}

const mapStateToProps = (state) => {
    const allBrandAgent = state.brandsAgentState.allBrandAgent;
    const loadedAllBrandAgent = state.brandsAgentState.loadedAllBrandAgent;
    const brands = state.brandsState.allBrands;
    const loadedBrands = state.brandsState.loadedBrands;
    const loadedConversions = state.conversionsState.loadedAllConversions;
    const allConversions = state.conversionsState.allConversions;
    const appStateSortHelper = state.appState.adminPartnerAgencyState.agencyTableSortHelper;
    const appStatePageNumber = state.appState.adminPartnerAgencyState.agencyTablePageNumber;
    const appStatePageSize = state.appState.adminPartnerAgencyState.agencyTablePageSize;
    return {
        allBrandAgent,
        allConversions,
        brands,
        loadedBrands,
        loadedConversions,
        loadedAllBrandAgent,
        appStateSortHelper,
        appStatePageNumber,
        appStatePageSize
    }
};

const mapDispatchToProps = (dispatch) => {
    return {
        changeSortHelper: (sortHelper) =>
            dispatch({ type: 'UPDATE_ADMIN_PARTNER_AGENCY_TABLE_SORT_HELPER', sortHelper }),
        changePageNumber: (pageNumber) =>
            dispatch({ type: 'UPDATE_ADMIN_PARTNER_AGENCY_TABLE_PAGE_NUMBER', pageNumber }),
        changePageSize: (pageSize) =>
            dispatch({ type: 'UPDATE_ADMIN_PARTNER_AGENCY_TABLE_PAGE_SIZE', pageSize })
    }
};

export default connect(mapStateToProps, mapDispatchToProps)(AgencyTable);
