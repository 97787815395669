import { database } from "./firebase";

const dbManager = {
  /**
   * @desc Fetch a ref from realtime database
   * @param {string} ref - Ref to fetch from realtime database
   * @return {array}
   */
  fetchRef: (ref,addKey = false) => {
    return database
      .ref(ref)
      .once("value")
      .then((snap) => {
        const data = snap.val();
        return data
          ? Object.values(data).map((d,i) =>
            addKey
              ? {
                ...d,
                key: Object.keys(data)[i],
              }
              : d
          )
          : [];
      });
  },
  /**
   * @desc Fetch brand from realtime database
   * @param {string}
   * @return {object}
   */
  fetchBrand: (brandName) => {
    return database
      .ref("brands")
      .orderByChild("name")
      .equalTo(brandName)
      .once("value")
      .then((snap) => {
        const data = snap.val();
        let brand = {};
        Object.values(data).forEach(
          (d,i) =>
          (brand = {
            ...d,
            key: Object.keys(data)[i],
          })
        );
        return brand;
      });
  },

  fetchBrandByOfferId: async (offerId) => {
    return database
      .ref("brands")
      .orderByChild("offerId")
      .equalTo(offerId)
      .once("value")
      .then((snap) => {
        const data = snap.val();
        let brand = {};
        data &&
          Object.values(data).forEach(
            (d,i) =>
            (brand = {
              ...d,
              key: Object.keys(data)[i],
            })
          );
        return brand;
      });
  },

  fetchAllBrands: async () => {
    return database
      .ref("brands")
      .once("value")
      .then((snap) => {
        const data = snap.val();
        let brands = [];
        data &&
          Object.values(data).forEach((d,i) => {
            brands.push({
              ...d,
              key: Object.keys(data)[i],
            });
          });
        return brands;
      });
  },

  fetchAllInfluencers: async () => {
    return database
      .ref("influencers")
      .once("value")
      .then((snap) => {
        const data = snap.val();
        let influencers = [];
        data &&
          Object.values(data).forEach((d,i) => {
            influencers.push({
              ...d,
              key: Object.keys(data)[i],
            });
          });
        return influencers;
      });
  },

  fetchAllCategories: async () => {
    return database
      .ref("Categories")
      .once("value")
      .then((snap) => {
        const data = snap.val();
        return data;
      });
  },

  fetchInfluencerById: async (id) => {
    return database
      .ref("influencers/" + id)
      .once("value")
      .then((snap) => {
        const data = snap.val();
        return data && { ...data,key: id };
      });
  },

  fetchArticleById: async (id) => {
    return database
      .ref("articles/" + id)
      .once("value")
      .then((snap) => {
        const data = snap.val();
        return data && { ...data };
      });
  },
  fetchSoldArticleById: async (id) => {
    return database
      .ref("soldArticles/" + id)
      .once("value")
      .then((snap) => {
        const data = snap.val();
        return (
          data && {
            ...data,
            key: id,
          }
        );
      });
  },
  /**
   * @desc Fetch influencer from realtime database
   * @param {string} uid - Influencer Id
   * @return {object}
   */
  fetchInfluencer: (uid) => {
    return database
      .ref("Influencers")
      .child(uid)
      .once("value")
      .then((snap) => {
        const data = snap.val();
        return data && { ...data,uid: uid };
      });
  },
  /**
   * @desc Fetch first influencer with Profil children corresponding to search from realtime database
   * @param {string} param Search parameter
   * @param {string} value Search value
   * @return {object} Influencer
   * @return {null} If no influencers found with asked search
   */
  fetchInfluencerBy: (param,value) => {
    return database
      .ref("influencers")
      .orderByChild(`${param}`)
      .equalTo(value)
      .once("value")
      .then((snap) => {
        const data = snap.val();
        if (data) {
          return {
            ...Object.values(data)[0],
            uid: Object.keys(data)[0],
          };
        } else {
          return null;
        }
      });
  },
  /**
   * @desc Fetch influencers from realtime database
   * @return {array} - Influencers
   */
  fetchInfluencers: async (infsUids) => {
    return infsUids
      ? Promise.all(infsUids.map((uid) => dbManager.fetchInfluencer(uid)))
      : database
        .ref("Influencers")
        .once("value")
        .then((snap) => {
          if (snap.val()) {
            const influencersUids = Object.keys(snap.val());
            const influencers = Object.values(snap.val()).map(
              (profil,i) => ({
                ...profil,
                socialMedias: profil.SocialMedias,
                uid: influencersUids[i],
              })
            );
            return influencers;
          }
        });
  },
  /**
   * @desc Fetch all the wishlists of an influencer
   * @param {string} influencerUid Influencer UID
   * @return {array} Array of wishlist objects
   */
  fetchInfluencerWishlists: (influencerUid) => {
    return database
      .ref(`Articles/${influencerUid}`)
      .once("value")
      .then((snap) => {
        const data = snap.val();
        return data
          ? Object.values(data)
            .filter((wish) => wish.articles)
            .map((wish) => ({
              influencer: influencerUid,
              name: wish.name,
              articles: Object.values(wish.articles).map((art,i) => ({
                ...art,
                key: Object.keys(wish.articles)[i],
              })),
            }))
          : [];
      });
  },
  /**
   * @desc Fetch a wishlist from realtime database
   * @param {string} influencer - Influencer UID
   * @param {string} wishlist - Wishlist name
   * @return {object} - Wishlist
   */
  fetchWishlist: (influencer,wishlist) => {
    return database
      .ref(`Articles/${influencer}/${wishlist}`)
      .once("value")
      .then((snap) => {
        const data = snap.val();
        return data ? data : {};
      });
  },
  /**
   * @desc Fetch the conversions from an influencer during a period of time
   * @param {string} uid - Influencer's uid
   * @param {integer} from - From date as timestamp in milliseconds
   * @param {integer} to - To date as timestamp in milliseconds
   * @return {array} - Array of conversion objects
   */
  fetchConversions: (from,to) => {
    return database
      .ref("conversions/purchase")
      .orderByChild("createdAt")
      .startAt(Math.floor(from / 1000))
      .endAt(Math.floor(to / 1000))
      .once("value")
      .then((snap) => {
        const data = snap.val();
        return data
          ? Object.values(data).map((d,i) => ({
            ...d,
            key: Object.keys(data)[i],
          }))
          : [];
      });
  },
  fetchAllConversions: () => {
    return database
      .ref("conversions/purchase")
      .once("value")
      .then((snap) => {
        const data = snap.val();
        let conversions = [];
        data &&
          Object.values(data).forEach((d,i) => {
            conversions.push({
              ...d,
              key: Object.keys(data)[i],
            });
          });
        return conversions;
      });
  },

  fetchAllBrandConversions: (offerId) => {
    if (offerId !== undefined)
      return database
        .ref("conversions/purchase")
        .orderByChild("offerId")
        .equalTo(offerId)
        .once("value")
        .then((snap) => {
          const data = snap.val();
          let brandConversions = [];
          data &&
            Object.values(data).forEach((d,i) => {
              brandConversions.push({
                ...d,
                key: Object.keys(data)[i],
              });
            });
          return brandConversions;
        });
    else return null;
  },
  fetchArticleByKey: (key) => {
    return database
      .ref("articles")
      .orderByKey()
      .equalTo(key)
      .once("value")
      .then((snap) => {
        const data = snap.val();
        let article;
        article = Object.values(data)[0];
        return article;
      });
  },
  fetchWishlitsByInfluencer: (influencerId) => {
    return database
      .ref("wishlists")
      .orderByChild("uid")
      .equalTo(influencerId)
      .once("value")
      .then((snap) => {
        const data = snap.val();
        let wishlists = [];
        data &&
          Object.values(data).forEach((d,i) => {
            wishlists.push({
              ...d,
              key: Object.keys(data)[i],
            });
          });
        return wishlists;
      });
  },

  fetchBrandAgentById: (uid) => {
    return database
      .ref("brandAgents")
      .orderByChild("userId")
      .equalTo(uid)
      .once("value")
      .then((snap) => {
        const data = snap.val();
        return data && Object.values(data)[0];
      });
  },

  fetchInfluencerAgentById: (uid) => {
    return database
      .ref("influencerAgents")
      .orderByChild("userId")
      .equalTo(uid)
      .once("value")
      .then((snap) => {
        const data = snap.val();
        return data && Object.values(data)[0];
      });
  },

  fetchAllWishlists: () => {
    return database
      .ref("wishlists")
      .once("value")
      .then((snap) => {
        const data = snap.val();
        let wishlists = [];
        data &&
          Object.values(data).forEach((wishlist,index) => {
            wishlists.push({
              ...wishlist,
              key: Object.keys(data)[index],
            });
          });
        return wishlists;
      });
  },

  updateInfluencerById: (influencerId,updates) => {
    let influencerRef = database.ref("influencers/" + influencerId);
    return influencerRef.update(updates);
  },

  updateBrandbyId: async (brandId,updates) => {
    let brandRef = database.ref("brands/" + brandId);
    let errorToReturn = null;
    await brandRef.update(updates,(error) => {
      if (error) {
        errorToReturn = error
      }
    });
    return new Promise((resolve,reject) => {
      if (errorToReturn) {
        reject(errorToReturn)
      } else {
        resolve(updates);
      };
    })
  },

  updateBrandToValidateById: async (brandId,updates) => {
    let brandRef = await database.ref('unverifiedAccounts/brands/' + brandId);
    return brandRef.update(updates);
  },

  fetchAllBrandsToValidate: () => {
    return database
      .ref("unverifiedAccounts/brands")
      .once("value")
      .then((snap) => {
        let data = snap.val();
        let brandsToValidate = [];
        data &&
          Object.values(data).forEach((d,i) => {
            brandsToValidate.push({
              ...d,
              key: Object.keys(data)[i],
            });
          });
        return brandsToValidate;
      });
  },
  fetchBillsByBrand: (offerId) => {
    return database
      .ref("brandsBills")
      .once("value")
      .then((snap) => {
        const data = snap.val();
        let facturePaid = [];
        let factureUnpaid = [];
        data &&
          Object.values(data).forEach((d,i) => {
            if (d.offerId === offerId && d.status === "paid") {
              facturePaid.push({
                ...d,
                key: Object.keys(data)[i],
              });
            } else if (d.offerId === offerId && d.status === "unpaid") {
              factureUnpaid.push({
                ...d,
                key: Object.keys(data)[i],
              });
            }
          });
        return [facturePaid,factureUnpaid];
      });
  },

  fetchAllSuggestedBrands: () => {
    return database.ref('brandsSuggestions').once('value').then((snap) => {
      let data = snap.val();
      let suggestedBrands = [];
      data && Object.values(data).forEach((d,i) => {
        suggestedBrands.push({
          ...d,
          key: Object.keys(data)[i]
        });
      });
      return suggestedBrands;
    })
  },

  fetchNewCategories: () => {
    return database.ref('categories').once('value').then((snap) => {
      let data = snap.val();
      let categories = [];
      data && Object.values(data).forEach((d,i) => {
        categories.push({
          ...d,
          key: Object.keys(data)[i]
        });
      });
      return categories;
    })
  },

  addNewBrand: async (brandToAdd) => {
    let newRef = await database.ref('brands').push();
    let brandToReturn = null;
    let errorToReturn = null;
    await newRef.set(brandToAdd,(error) => {
      if (error) {
        errorToReturn = error
      } else {
        brandToReturn = {
          ...brandToAdd,
          key: newRef.key
        }
      }
    });
    return new Promise((resolve,reject) => {
      if (errorToReturn) {
        reject(errorToReturn)
      } else {
        resolve(brandToReturn)
      };
    })
  },

  addNewBrandUser: async (brandOfferId,userId,userData) => {
    let newRef = await database.ref('Users').child('BrandUsers').child(brandOfferId).child(userId);
    let errorToReturn = null;
    let userToReturn = null;
    await newRef.set(userData,(error) => {
      if (error !== null) {
        errorToReturn = error;
      } else {
        userToReturn = {
          ...userData,
          key: newRef.key
        }
      }
    });
    return new Promise((resolve,reject) => {
      if (errorToReturn) {
        reject(errorToReturn);
      } else {
        resolve(userToReturn);
      };
    });
  },

  removeUnverifiedBrand: async (brandIdToRemove) => {
    let errorToReturn = null;
    await database.ref('unverifiedAccounts/brands/' + brandIdToRemove).set(null,error => {
      errorToReturn = error;
    });
    return new Promise((resolve,reject) => {
      if (errorToReturn === null) {
        resolve();
      } else {
        reject(errorToReturn)
      }
    })
  },
  fetchAllAgency: () => {
    return database
      .ref("brandAgents")
      .once("value")
      .then((snap) => {
        let data = snap.val();
        let agency = [];
        data &&
          Object.values(data).forEach((d,i) => {
            agency.push({
              ...d,
              key: Object.keys(data)[i],
            });
          });
        return agency;
      });
  },
  fetchAllAgencyToValidate: () => {
    return database
      .ref("unverifiedAccounts/brandAgents")
      .once("value")
      .then((snap) => {
        let data = snap.val();
        let agencyToValidate = [];
        data &&
          Object.values(data).forEach((d,i) => {
            agencyToValidate.push({
              ...d,
              key: Object.keys(data)[i],
            });
          });
        return agencyToValidate;
      });
  },
  updateBrandAgentbyId: async (brandAgentId,updates) => {
    let brandRef = database.ref("brandAgents/" + brandAgentId);
    let errorToReturn = null;
    await brandRef.update(updates,(error) => {
      if (error) {
        errorToReturn = error
      }
    });
    return new Promise((resolve,reject) => {
      if (errorToReturn) {
        reject(errorToReturn)
      } else {
        resolve(updates);
      };
    })
  },
  removeUnverifiedBrandAgent: async (brandAgentIdToRemove) => {
    let errorToReturn = null;
    await database.ref('unverifiedAccounts/brandAgents/' + brandAgentIdToRemove).set(null,error => {
      errorToReturn = error;
    });
    return new Promise((resolve,reject) => {
      if (errorToReturn === null) {
        resolve();
      } else {
        reject(errorToReturn)
      }
    })
  },
  addNewBrandAgent: async (agentToAdd) => {
    let newRef = await database.ref('brandAgents').push();
    let brandToReturn = null;
    let errorToReturn = null;
    await newRef.set(agentToAdd,(error) => {
      if (error) {
        errorToReturn = error
      } else {
        brandToReturn = {
          ...agentToAdd,
          key: newRef.key
        }
      }
    });
    return new Promise((resolve,reject) => {
      if (errorToReturn) {
        reject(errorToReturn)
      } else {
        resolve(brandToReturn)
      };
    })
  },
  addNewBrandAgentUser: async (userId,userDataId,userData) => {
    let newRef = await database.ref('Users').child('BrandAgentsUsers').child(userDataId.key).child(userId);;
    let errorToReturn = null;
    let userToReturn = null;
    await newRef.set(userData,(error) => {
      if (error !== null) {
        errorToReturn = error;
      } else {
        userToReturn = {
          ...userData,
          key: newRef.key
        }
      }
    });
    return new Promise((resolve,reject) => {
      if (errorToReturn) {
        reject(errorToReturn);
      } else {
        resolve(userToReturn);
      };
    });
  },
  updateBrandAgentUsers: async (userData,key,userId) => {
    let brandRef = database.ref("Users/BrandAgentsUsers").child(key).child(userId);
    let errorToReturn = null;
    await brandRef.update(userData,(error) => {
      if (error) {
        errorToReturn = error
      }
    });
    return new Promise((resolve,reject) => {
      if (errorToReturn) {
        reject(errorToReturn)
      } else {
        resolve(userData);
      };
    })
  },
  fetchAllInfluencerAgents: async () => {
    return database.ref('influencerAgents').once('value').then((snap) => {
      let data = snap.val();
      let influencerAgents = [];
      data && Object.values(data).forEach((d,i) => {
        influencerAgents.push({
          ...d,
          key: Object.keys(data)[i]
        });
      });
      return influencerAgents;
    });
  },
  fetchInfluencerAgentsToValidate: () => {
    return database
      .ref("unverifiedAccounts/influencerAgents")
      .once("value")
      .then((snap) => {
        let data = snap.val();
        let agentToValidate = [];
        data &&
          Object.values(data).forEach((d,i) => {
            agentToValidate.push({
              ...d,
              key: Object.keys(data)[i],
            });
          });
        return agentToValidate;
      });
  },
  removeUnverifiedInfluencerAgent: async (influencerAgentIdToRemove) => {
    let errorToReturn = null;
    await database.ref('unverifiedAccounts/influencerAgents/' + influencerAgentIdToRemove).set(null,error => {
      errorToReturn = error;
    });
    return new Promise((resolve,reject) => {
      if (errorToReturn === null) {
        resolve();
      } else {
        reject(errorToReturn)
      }
    })
  },
  addNewInfluencerAgent: async (agentToAdd) => {
    let newRef = await database.ref('influencerAgents').push();
    let agentToReturn = null;
    let errorToReturn = null;
    await newRef.set(agentToAdd,(error) => {
      if (error) {
        errorToReturn = error
      } else {
        agentToReturn = {
          ...agentToAdd,
          key: newRef.key
        }
      }
    });
    return new Promise((resolve,reject) => {
      if (errorToReturn) {
        reject(errorToReturn)
      } else {
        resolve(agentToReturn)
      };
    });
  },
  addNewInfluencerAgentUser: async (userId,userDataId,userData) => {
    let newRef = await database.ref('Users').child('InfluencerAgentsUsers').child(userDataId.key).child(userId);
    let errorToReturn = null;
    let userToReturn = null;
    await newRef.set(userData,(error) => {
      if (error !== null) {
        errorToReturn = error;
      } else {
        userToReturn = {
          ...userData,
          key: newRef.key
        }
      }
    });
    return new Promise((resolve,reject) => {
      if (errorToReturn) {
        reject(errorToReturn);
      } else {
        resolve(userToReturn);
      };
    });
  },
  updateInfluencerAgentbyId: async (influencerAgentId,updates) => {
    let agentRef = database.ref("influencerAgents/" + influencerAgentId);
    let errorToReturn = null;
    await agentRef.update(updates,(error) => {
      if (error) {
        errorToReturn = error
      }
    });
    return new Promise((resolve,reject) => {
      if (errorToReturn) {
        reject(errorToReturn)
      } else {
        resolve(updates);
      };
    })
  },

  updateClaimsInfluencerAgent: async (influencerAgentId,updates) => {
    let claimAgentRef = database.ref("influencerAgents/" + influencerAgentId);
    let errorToReturn = null;
    await claimAgentRef.update(updates,(error) => {
      if (error) {
        errorToReturn = error
      }
    });
    return new Promise((resolve,reject) => {
      if (errorToReturn) {
        reject(errorToReturn)
      } else {
        resolve(updates);
      };
    })
  },

  updateInfluencerAgentUsers: async (userData,key,userId) => {
    let infRef = database.ref("Users/InfluencerAgentsUsers").child(key).child(userId);
    let errorToReturn = null;
    await infRef.update(userData,(error) => {
      if (error) {
        errorToReturn = error
      }
    });
    return new Promise((resolve,reject) => {
      if (errorToReturn) {
        reject(errorToReturn)
      } else {
        resolve(userData);
      };
    })
  },

  addNewCategory: async (categoryToAdd) => {
    let newRef = database.ref('categories').push();
    let categoryToReturn = null;
    let errorToReturn = null;
    await newRef.set(categoryToAdd,(error) => {
      if (error) {
        categoryToReturn = error
      } else {
        categoryToReturn = {
          ...categoryToAdd,
          key: newRef.key
        }
      }
    });
    return new Promise((resolve,reject) => {
      if (errorToReturn) {
        reject(errorToReturn)
      } else {
        resolve(categoryToReturn)
      };
    })
  },

  updateCategory: async (category,updates) => {
    let categoryRef = database.ref("categories/" + category.key);
    let errorToReturn = null;
    await categoryRef.update(updates,(error) => {
      if (error) {
        errorToReturn = error
      }
    });
    return new Promise((resolve,reject) => {
      if (errorToReturn) {
        reject(errorToReturn)
      } else {
        resolve(updates);
      };
    })
  },

  removeCategory: async (category) => {
    let categoryRef = database.ref('categories/' + category.key);
    let errorToReturn = null;
    if (category.subCateg) {
      for (let i = 0; i < category.subCateg.length; i++) {
        let childSubCategoryRef = await database.ref('subCategories/' + category.subCateg[i]);
        let categoriesToUpdate = [];

        // GET EXISTING CATEGORIES FROM CHILD
        await database.ref('subCategories/' + category.subCateg[i] + '/categ').once('value').then(snap => {
          let data = snap.val();
          categoriesToUpdate = (data) ? Object.values(data) : [];
        });
        categoriesToUpdate = categoriesToUpdate.filter(item => item !== category.key);

        if (categoriesToUpdate.length === 0) {
          // REMOVE CHILD IF ONLY DEPENDENCE
          // eslint-disable-next-line
          await childSubCategoryRef.set(null,(error) => {
            if (error) {
              errorToReturn = error;
              errorToReturn['removeFromChildError'] = true;
            }
          })
        } else {
          // UPDATE CHILD WITH NEW CATEGORIES
          // eslint-disable-next-line
          await childSubCategoryRef.child('categ').set(categoriesToUpdate,(error) => {
            if (error) {
              errorToReturn = error;
              errorToReturn['removeFromChildError'] = true;
            }
          });
        }
      }
    }

    await categoryRef.set(null,error => {
      if (error) {
        errorToReturn = error;
        errorToReturn['removalError'] = true;
      }
    });

    return new Promise((resolve,reject) => {
      if (errorToReturn !== null) {
        if (errorToReturn['removalError']) reject(errorToReturn);
        if (errorToReturn['removeFromChildError'])
          resolve({
            status: 400,
            removeFromChildError: true
          });
      } else {
        resolve({
          status: 200,
          removeFromChildError: false
        });
      }
    });
  },

  fetchAllSubCategories: async () => {
    return database.ref('subCategories').once('value').then((snap) => {
      let data = snap.val();
      let subCategories = [];
      data && Object.values(data).forEach((d,i) => {
        subCategories.push({
          ...d,
          key: Object.keys(data)[i]
        });
      });
      return subCategories;
    })
  },

  addSubCategory: async (subCategory,parentCategoriesIds) => {
    let subCategoryRef = database.ref('subCategories').push();
    let errorToReturn = null;
    let subCategoryToReturn = null;

    for (let i = 0; i < parentCategoriesIds.length; i++) {
      let parentCategoryRef = await database.ref('categories/' + parentCategoriesIds[i]);
      let subCategoriesToUpdate = [];

      // GET EXISTING SUB CATEGORIES FROM PARENT
      await database.ref('categories/' + parentCategoriesIds[i] + '/subCateg').once('value').then(snap => {
        let data = snap.val();
        subCategoriesToUpdate = (data) ? Object.values(data) : [];
      });
      subCategoriesToUpdate.push(subCategoryRef.key);

      // UPDATE PARENT WITH NEW SUB CATEGORY
      // eslint-disable-next-line
      await parentCategoryRef.child('subCateg').set(subCategoriesToUpdate,(error) => {
        if (error !== null) {
          errorToReturn['affectingError'] = true
        }
      });
    }

    // SAVE NEW SUB CATEGORY
    await subCategoryRef.set(subCategory,(error) => {
      if (error !== null) {
        errorToReturn = error;
        errorToReturn['addingError'] = true;
      } else {
        subCategoryToReturn = {
          ...subCategory,
          key: subCategoryRef.key
        }
      }
    });

    return new Promise((resolve,reject) => {
      if (errorToReturn && errorToReturn['addingError']) {
        reject(errorToReturn);
      } else {
        if (errorToReturn && errorToReturn['affectingError']) {
          resolve({
            subCategoryToReturn,
            affectingError: true
          });
        } else {
          resolve({
            subCategoryToReturn,
            affectingError: false
          });
        }
      };
    });
  },

  updateSubCategory: async (subCategory,updates) => {

    let subCategoryRef = database.ref('subCategories/' + subCategory.key);
    let errorToReturn = null;

    // Remove affectation from categories
    if (updates.removedParents.length !== 0) {
      for (let i = 0; i < updates.removedParents.length; i++) {
        let parentCategoryRef = await database.ref('categories/' + updates.removedParents[i]);
        let subCategoriesToUpdate = [];

        // GET EXISTING SUB CATEGORIES FROM PARENT
        await database.ref('categories/' + updates.removedParents[i] + '/subCateg').once('value').then(snap => {
          let data = snap.val();
          subCategoriesToUpdate = (data) ? Object.values(data) : [];
        });
        subCategoriesToUpdate = subCategoriesToUpdate.filter(item => item !== subCategory.key);

        // UPDATE PARENT WITH NEW SUB CATEGORY
        // eslint-disable-next-line
        await parentCategoryRef.child('subCateg').set(subCategoriesToUpdate,(error) => {
          if (error !== null) {
            errorToReturn = error;
            errorToReturn['affectingError'] = true
          }
        });
      }
    };

    // Add new affectations to categories
    if (updates.newAddedParents.length !== 0) {
      for (let i = 0; i < updates.newAddedParents.length; i++) {
        let parentCategoryRef = await database.ref('categories/' + updates.newAddedParents[i]);
        let subCategoriesToUpdate = [];

        // GET EXISTING SUB CATEGORIES FROM PARENT
        await database.ref('categories/' + updates.newAddedParents[i] + '/subCateg').once('value').then(snap => {
          let data = snap.val();
          subCategoriesToUpdate = (data) ? Object.values(data) : [];
        });
        subCategoriesToUpdate.push(subCategory.key);

        // UPDATE PARENT WITH NEW SUB CATEGORY
        // eslint-disable-next-line
        await parentCategoryRef.child('subCateg').set(subCategoriesToUpdate,(error) => {
          if (error !== null) {
            errorToReturn = error;
            errorToReturn['affectingError'] = true
          }
        });
      }
    };

    let updatesWithoutCateg = {
      ...updates
    };
    delete updatesWithoutCateg.removedParents;
    delete updatesWithoutCateg.newAddedParents;

    // Update subCategory
    await subCategoryRef.update(updatesWithoutCateg,error => {
      if (error) {
        errorToReturn = error;
        errorToReturn['updatingError'] = true;
      }
    });

    return new Promise((resolve,reject) => {
      if (errorToReturn && errorToReturn['updatingError']) {
        reject(errorToReturn);
      } else {
        if (errorToReturn && errorToReturn['affectingError']) {
          resolve({
            updatesWithoutCateg,
            affectingError: true
          });
        } else {
          resolve({
            updates,
            affectingError: false
          });
        }
      };
    });

  },

  removeSubCategory: async (subCategory) => {
    let subCategoryRef = database.ref('subCategories/' + subCategory.key);
    let errorToReturn = null;

    for (let i = 0; i < subCategory.categ.length; i++) {
      let parentCategoryRef = await database.ref('categories/' + subCategory.categ[i]);
      let subCategoriesToUpdate = [];

      // GET EXISTING SUB CATEGORIES FROM PARENT
      await database.ref('categories/' + subCategory.categ[i] + '/subCateg').once('value').then(snap => {
        let data = snap.val();
        subCategoriesToUpdate = (data) ? Object.values(data) : [];
      });
      subCategoriesToUpdate = subCategoriesToUpdate.filter(item => item !== subCategory.key);
      subCategoriesToUpdate = (subCategoriesToUpdate.length === 0) ? null : subCategoriesToUpdate;

      // UPDATE PARENT WITH NEW SUB CATEGORY
      // eslint-disable-next-line
      await parentCategoryRef.child('subCateg').set(subCategoriesToUpdate,(error) => {
        if (error) {
          errorToReturn = error;
          errorToReturn['removeFromParentError'] = true
        }
      });
    };

    await subCategoryRef.set(null,error => {
      if (error) {
        errorToReturn = error;
        errorToReturn['removalError'] = true;
      }
    });

    return new Promise((resolve,reject) => {
      if (errorToReturn !== null) {
        if (errorToReturn['removalError']) reject(errorToReturn);
        if (errorToReturn['removeFromParentError'])
          resolve({
            status: 400,
            removeFromParentError: true
          });
      } else {
        resolve({
          status: 200,
          removeFromParentError: false
        });
      }
    });
  },

  fetchAllColors: async () => {
    return database.ref('Colors').once('value').then(snap => {
      let data = snap.val();
      let colors = [];
      data && Object.values(data).forEach((d,i) => {
        colors.push({
          ...d,
          key: Object.keys(data)[i]
        });
      });
      return colors;
    });
  },

  fetchAllSoldArticle: async () => {
    return database.ref('soldArticles').once('value').then(snap => {
      let data = snap.val();
      let soldArticles = [];
      data && Object.values(data).forEach((d,i) => {
        soldArticles.push({
          ...d,
          key: Object.keys(data)[i]
        });
      });
      return soldArticles;
    });
  },

  updateSoldArticleById: async (articleId,updates) => {
    let errorToReturn = null;
    await database.ref('soldArticles/' + articleId).update(updates,error => {
      if (error) {
        errorToReturn = error;
        errorToReturn['message'] = 'Error updating object';
      };
    });

    return new Promise((resolve,reject) => {
      if (errorToReturn) {
        reject(errorToReturn)
      } else {
        resolve(updates);
      }
    });
  },
  getInfluencersBills: async () => {
    return database.ref("Bills").once("value").then((snap) => {
      let data = snap.val();
      let influencerBills = [];
      data &&
        Object.values(data).forEach((d,i) => {
          let obj = {
            ...d
          };
          let finalArray = [];
          Object.entries(obj).forEach(([key,value]) => {
            finalArray.push({
              ...value,
              id: key
            });
          });
          influencerBills.push({
            key: Object.keys(data)[i],
            ...finalArray
          });
        });
      return influencerBills;
    });
  },
  getBrandsBills: async () => {
    return database.ref("brandsBills").once("value").then((snap) => {
      let data = snap.val();

      let brandBills = [];
      data &&
        Object.values(data).forEach((d,i) => {
          brandBills.push({
            ...d,
            key: Object.keys(data)[i],
          });
        });
      return brandBills;
    });
  },
  updateInfluencersBillsbyId: async (influencerId,key,updates) => {
    let brandRef = database.ref("Bills").child(influencerId).child(key);
    let errorToReturn = null;
    await brandRef.update(updates,(error) => {
      if (error) {
        errorToReturn = error
      }
    });
    return new Promise((resolve,reject) => {
      if (errorToReturn) {
        reject(errorToReturn)
      } else {
        resolve(updates);
      };
    })
  },
  deleteSuggestedBrand: async (brandIdToRemove) => {
    let errorToReturn = null;
    await database.ref('brandsSuggestions/' + brandIdToRemove).set(null,error => {
      errorToReturn = error;
    });
    return new Promise((resolve,reject) => {
      if (errorToReturn === null) {
        resolve();
      } else {
        reject(errorToReturn)
      }
    })
  },
  acceeToDeleteInfluencer: async () => {
    return database
      .ref("App-Data")
      .once("value")
      .then((snap) => {
        const data = snap.val();
        const adminPassword = data.dashboardAdminPassword;
        return adminPassword;
      });
  },
  fetchBrandConversionsByOfferId: async (offerId) => {
    return database
      .ref("conversions/purchasesPerBrands/" + offerId)
      .once("value")
      .then((snap) => {
        const data = snap.val();
        let brandConversions = [];
        data &&
          Object.values(data).forEach((d,i) => {
            brandConversions.push({
              ...d,
              key: Object.keys(data)[i],
            });
          });
        return brandConversions;
      });
  },
  addWishlist: async (wishlistName,userId) => {
    let newRefWishlist = await database.ref('wishlists').push();
    const wishlist = {
      uid: userId,
      name: wishlistName,
      id: newRefWishlist.key,
      createdAt: Math.round(Date.now() / 1000).toString()
    };
    let wishlistToReturn = null;
    let errorToReturn = null;
    await newRefWishlist.set(wishlist,(error) => {
      if (error) {
        errorToReturn = error
      } else {
        wishlistToReturn = {
          ...wishlist,
          key: newRefWishlist.key
        }
      }
    });
    if (errorToReturn !== null) {
      await database.ref('wishlistsPerInfluencer/' + userId + `/${wishlist.id}`).set(wishlist,(error) => {
        if (error) {
          errorToReturn = error
        };
      });
    };
    return new Promise((resolve,reject) => {
      if (errorToReturn) {
        reject(errorToReturn)
      } else {
        resolve(wishlistToReturn)
      };
    })
  },
  deleteWishlist: async (wishlistToDelete) => {
    let errorToReturn = null;
    let updates = {};
    if (wishlistToDelete) {
      if (wishlistToDelete.articles) {
        Object.keys(wishlistToDelete.articles).forEach(articleId => {
          updates[`articles/${articleId}`] = null;
        });
      };
      updates[`wishlists/${wishlistToDelete.key}`] = null;
    }
    await database.ref().update(updates,error => {
      if (error) {
        errorToReturn = error;
      }
    });
    return new Promise((resolve,reject) => {
      if (errorToReturn === null) {
        resolve(wishlistToDelete);
      } else {
        reject(errorToReturn)
      }
    })
  },
  updateWishlist: async (wishlist,updates) => {
    let errorToReturn = null;
    let allUpdates = {};
    if (updates.name) {
      allUpdates[`wishlists/${wishlist.id}/name`] = updates.name;
      allUpdates[`wishlistsPerInfluencer/${wishlist.uid}/${wishlist.id}/name`] = updates.name;
    };
    if (updates.articles) {
      allUpdates[`wishlists/${wishlist.id}/articles`] = updates.articles;
      allUpdates[`wishlistsPerInfluencer/${wishlist.uid}/${wishlist.id}/articles`] = updates.articles;
    };
    await database.ref().update(allUpdates,(error) => {
      if (error) {
        errorToReturn = error
      }
    });
    return new Promise((resolve,reject) => {
      if (errorToReturn) {
        reject(errorToReturn)
      } else {
        resolve(updates);
      };
    })
  },

  fetchWishlistsByInfluencer: async (influencerId) => {
    let errorToReturn = null;
    let wishlistsPerInfluencer = null;
    await database.ref('wishlistsPerInfluencer/' + influencerId).once('value').then(snap => {
      wishlistsPerInfluencer = snap.val();
    },(error) => {
      errorToReturn = error
    });
    return new Promise((resolve,reject) => {
      if (errorToReturn) {
        reject(errorToReturn);
      } else {
        resolve(wishlistsPerInfluencer);
      }
    })
  },

  fetchInfluencerByCode33: async (code33) => {
    let errorToReturn = null;
    let influencer = null;
    await database.ref('influencers').orderByChild('code33').equalTo(code33).once('value').then(snap => {
      let data = snap.val();
      if (data) {
        influencer = {
          ...Object.values(data)[0],
          key: Object.keys(data)[0]
        };
      };
    },error => {
      errorToReturn = error;
    });
    return new Promise((resolve,reject) => {
      if (errorToReturn) {
        reject(errorToReturn)
      } else {
        resolve(influencer);
      }
    });
  },

  fetchInfluencerByName: async (name) => {
    let errorToReturn = null;
    let influencer = null;
    await database.ref('influencers').orderByChild('name').equalTo(name).once('value').then(snap => {
      let data = snap.val();
      if (data) {
        influencer = {
          ...Object.values(data)[0],
          key: Object.keys(data)[0]
        };
      };
    },error => {
      errorToReturn = error;
    });
    return new Promise((resolve,reject) => {
      if (errorToReturn) {
        reject(errorToReturn);
      } else {
        resolve(influencer);
      }
    });
  },

  fetchNonPrivateInfluencers: async () => {
    let errorToReturn = null;
    let nonPrivateInfluencers = [];
    await database.ref('influencers').orderByChild('private').equalTo(false).once("value").then(snap => {
      let data = snap.val();
      if (data) {
        Object.values(data).forEach((influencer,i) => {
          nonPrivateInfluencers.push({
            ...influencer,
            key: Object.keys(data)[i]
          });
        });
      }
    },error => {
      errorToReturn = error;
    });
    return new Promise((resolve,reject) => {
      if (errorToReturn) {
        reject(errorToReturn);
      } else {
        resolve(nonPrivateInfluencers);
      }
    })
  },
  getInfluencerByIdEshop: async (uid) => {
    let errorToReturn = null;
    let influencerById = [];
    await database.ref('influencers/' + uid).once("value").then(snap => {
      let data = snap.val();
      if (data) {
        influencerById.push({ ...data });
      }
    },error => {
      errorToReturn = error;
    });
    return new Promise((resolve,reject) => {
      if (errorToReturn) {
        reject(errorToReturn);
      } else {
        resolve(influencerById);
      }
    })
  },

  fetchArticlesPerBrandOfferId: async (offerId) => {
    let errorToReturn = null;
    let articlesPerBrandOfferId = [];
    await database.ref('articles').orderByChild('offerId').equalTo(offerId).once('value').then(snap => {
      let data = snap.val();
      if (data) {
        Object.values(data).forEach((article,i) => {
          articlesPerBrandOfferId.push({
            ...article,
            key: Object.keys(data)[i]
          });
        });
      }
    },error => {
      errorToReturn = error;
    });
    return new Promise((resolve,reject) => {
      (errorToReturn) ? reject(errorToReturn) : resolve(articlesPerBrandOfferId);
    });
  },


  getArticleByBrandEshop: async () => {
    let errorToReturn = null;
    let article = [];
    await database.ref("Eshop").once("value").then(snap => {
      let data = snap.val();
      if (data) {
        Object.values(data).forEach((articleEshop,i) => {
          article.push({
            ...articleEshop,
            key: Object.keys(data)[i]
          });
        });
      }
    },error => {
      errorToReturn = error;
    });
    return new Promise((resolve,reject) => {
      if (errorToReturn) {
        reject(errorToReturn);
      } else {
        resolve(article);
      }
    })
  },

  fetchWishlistName: async (wishlistId) => {
    let errorToReturn = null;
    let wishlistName;
    await database.ref(`wishlists/${wishlistId}/name`).once('value').then(snap => {
      let data = snap.val();
      wishlistName = data;
    },error => {
      errorToReturn = error
    });
    return new Promise((resolve,reject) => {
      (errorToReturn) ? reject(errorToReturn) : resolve(wishlistName);
    })
  },

  fetchInfluencerByAffiliateId: async (affiliateId) => {
    let errorToReturn = null;
    let influencer = null;
    await database.ref('influencers').orderByChild('affiliate_id').equalTo(affiliateId).once('value').then(snap => {
      let data = snap.val();
      if (data) {
        influencer = {
          ...Object.values(data)[0],
          key: Object.keys(data)[0]
        };
      };
    },error => {
      errorToReturn = error;
    });
    return new Promise((resolve,reject) => {
      if (errorToReturn) {
        reject(errorToReturn);
      } else {
        resolve(influencer);
      }
    });
  },

  fetchWishlistById: async (wishlistId) => {
    let errorToReturn = null;
    let wishlist = null;
    await database.ref('wishlists').orderByKey().equalTo(wishlistId).once('value').then(snap => {
      let data = snap.val();
      if (data) {
        wishlist = {
          ...Object.values(data)[0],
          key: Object.keys(data)[0]
        };
      };
    },error => {
      errorToReturn = error;
    });
    return new Promise((resolve,reject) => {
      if (errorToReturn) {
        reject(errorToReturn);
      } else {
        resolve(wishlist)
      }
    })
  },

  deleteArticleById: async (articleId) => {
    let errorToReturn = null;
    await database.ref('articles/' + articleId).set(null,error => {
      if (error) {
        errorToReturn = error
      };
    });
    return new Promise((resolve,reject) => {
      if (errorToReturn) {
        reject(errorToReturn);
      } else {
        resolve(articleId);
      }
    })
  },

  fetchShortlinksPerInfluencer: async (influencerAffiliateId) => {
    let errorToReturn = null;
    let shortlinks = [];
    await database.ref('shortlinksPerInfluencers/' + influencerAffiliateId).once('value').then(snap => {
      let data = snap.val();
      data && Object.values(data).forEach((shortlink,i) => {
        shortlinks.push({
          ...shortlink,
          key: Object.keys(data)[i]
        });
      });
    },error => {
      errorToReturn = error;
    });
    return new Promise((resolve,reject) => {
      if (errorToReturn) {
        reject(errorToReturn);
      } else {
        resolve(shortlinks);
      }
    });
  },

  addShortlink: async function (shortlink) {
    let errorToReturn;
    let shortlinkRef = await database.ref('shortlinks').push();
    let shortlinkToSet = {
      ...shortlink
    };
    shortlinkToSet['newUrl'] = shortlinkToSet['newUrl'] + shortlinkRef.key;
    await database.ref('shortlinks').child(shortlinkRef.key).set(shortlinkToSet,error => {
      if (error) {
        errorToReturn = error;
      }
    });
    return new Promise((resolve,reject) => {
      if (errorToReturn) {
        reject(errorToReturn);
      } else {
        resolve({
          ...shortlinkToSet,
          key: shortlinkRef.key
        });
      };
    });
  },

  addMultipleShortlinks: async function (shortlinks) {
    let addedShortlinks = [];
    let errorToReturn;
    for (let i = 0; i < shortlinks.length; i++) {
      await this.addShortlink(shortlinks[i]).then(shortlink => {
        addedShortlinks.push(shortlink);
        // eslint-disable-next-line
      },error => {
        errorToReturn = error;
      });
    };
    return new Promise((resolve,reject) => {
      if (errorToReturn) {
        reject({
          error: errorToReturn,
          addedShortlinks
        });
      } else {
        resolve(addedShortlinks);
      }
    })
  },
  fetchUsersByOfferId: async (offerId) => {
    let errorToReturn = null;
    let users = [];
    await database.ref('Users/BrandUsers/' + offerId).once('value').then(snap => {
      let data = snap.val();
      if (data) {
        Object.values(data).forEach((user,i) => {
          users.push({
            ...user,
            key: Object.keys(data)[i]
          });
        });
      }
    },error => {
      errorToReturn = error;
    });
    return new Promise((resolve,reject) => {
      (errorToReturn) ? reject(errorToReturn) : resolve(users);
    });
  },
  fetchAllBrandUsers: async () => {
    let errorToReturn = null;
    let users = {};
    await database.ref('Users/BrandUsers').once('value').then(snap => {
      let data = snap.val();
      if (data) {
        Object.values(data).forEach(
          (d,i) =>
            Object.assign(users,{ ...d })
        );
      }
    },error => {
      errorToReturn = error;
    });
    return new Promise((resolve,reject) => {
      (errorToReturn) ? reject(errorToReturn) : resolve(users);
    });
  },
};

export default dbManager;
