import { Chip, Grid, IconButton, makeStyles, Typography } from '@material-ui/core';
import React, { useEffect, useState } from 'react';
import { useHistory } from 'react-router-dom';
import AddIcon from '@material-ui/icons/Add';

const useStyle = makeStyles(theme => {
    return {
        influencerInfos: {
            width: '100%',
        },
        influencerImage: {
            width: "100%",
            paddingLeft: "10px",
            paddingRight: '10px'
        },
        influencerImageMobile: {
            width: 240,
            height: 120,
            borderRadius: '25px',
            marginTop: '20px',
            marginBottom: '20px'
        },
        influencerDescription: {
            fontFamily: 'Poppins',
            fontWeight: 200,
            marginBottom: 5,
            paddingLeft: 10,
            paddingRight: 10,
        },
        wishlistsPlaceholder: {
            height: '30px',
            fontFamily: 'Poppins',
            color: '#ee3889',
            fontSize: '20px',
            marginLeft: '15px',
            fontWeight: '500',
            marginBottom: 5
        },
        wishlistChipsContainer: {
            flexGrow: 1,
            position: 'relative',
            overflow: 'auto',
            paddingLeft: '5px'
        },
        wishlistChip: {
            color: '#00000082',
            // border: '1px solid #00ADEF',
            margin: '5px',
            fontFamily: 'Poppins',
            fontSize: '15px'
        },
        activeWishlistChip: {
            margin: '5px',
            fontFamily: 'Poppins',
            fontSize: '15px'
        },
        addWishlistChip: {

        },
        influencerName: {
            fontFamily: 'Poppins',
            height: '50px',
            display: 'flex',
            alignItems: 'center',
            color: '#00ADEF',
            fontWeight: 600,
            fontSize: '24px',
            paddingLeft: '10px'
        },
        logoContainer: {
            height: 100,
            position: 'relative',
            bottom: 0,
            marginBottom: 20,
        },
        shopmyinfluenceLogo: {
            width: 150,
            height: 75,
            cursor: 'pointer'
        },
        shopmyinfluenceMobileLogo: {
            width: 150,
            height: 75
        }
    }
});

const Sidebar = (props) => {

    const {
        user,
        userWishlists,
        influencer,
        handleWishlistChange,
        selectedWishlist
    } = props;

    const history = useHistory();
    const classes = useStyle();
    const [activeWishlist, setActiveWishlist] = useState(null);
    // eslint-disable-next-line
    const [connectedUserMode, setConnectedUserMode] = useState(false);

    useEffect(() => {
        if (selectedWishlist) setActiveWishlist(selectedWishlist)
    }, [selectedWishlist]);

    useEffect(() => {
        if (!selectedWishlist && activeWishlist === null && userWishlists && userWishlists.length !== 0) {
            setActiveWishlist(userWishlists[0]);
            handleWishlistChange(userWishlists[0]);
        }
    }, [userWishlists, activeWishlist, handleWishlistChange, selectedWishlist]);

    useEffect(() => {
        if (user) setConnectedUserMode(true);
    }, [user]);

    const handleChangeActiveWishlist = (wishlist) => {
        setActiveWishlist(wishlist);
        props.handleWishlistChange(wishlist);
    };

    const goToHome = () => {
        history.push('/home');
    };

    const addNewWishlist = () => {
        props.addWishlist();
    };

    return (
        <>
            <Grid container item direction='column' className={classes.influencerInfos}>
                <Grid container item direction='row' justify='center' alignItems='center'>
                    <img className={classes.influencerImage} src={influencer.banner} alt={influencer.name} />
                </Grid>
                <Grid container item direction='row' justify='flex-start' alignItems='center'>
                    <Typography className={classes.influencerName}>{influencer.name}</Typography>
                </Grid>
                <Grid container item direction='row' justify='flex-start' alignItems='center'>
                    <Typography className={classes.influencerDescription}><i>{influencer.description}</i></Typography>
                </Grid>
                <Grid container item direction='row' justify='flex-start' alignItems='center'>
                    <Typography className={classes.wishlistsPlaceholder}>Wishlists</Typography>
                </Grid>
            </Grid>
            <Grid className={classes.wishlistChipsContainer}>
                {
                    userWishlists.map(userWishlist => (
                        <Chip
                            variant={(activeWishlist && userWishlist && activeWishlist.key === userWishlist.key) ? 'default' : 'outlined'}
                            className={(activeWishlist && userWishlist && activeWishlist.key === userWishlist.key) ? classes.activeWishlistChip : classes.wishlistChip}
                            label={userWishlist.name}
                            color={(activeWishlist && userWishlist && activeWishlist.key === userWishlist.key) ? 'primary' : 'default'}
                            key={userWishlist.key}
                            onClick={() => handleChangeActiveWishlist(userWishlist)} />
                    ))
                }
                {
                    connectedUserMode
                        ? <IconButton color='secondary' style={{ border: '1px solid #ee3889', padding: '3px', margin: 5 }} onClick={() => addNewWishlist()}>
                            <AddIcon />
                        </IconButton>
                        : <></>
                }


            </Grid>
            <Grid container item direction='row' justify='center' alignItems='center' className={classes.logoContainer}>
                <img
                    className={classes.shopmyinfluenceLogo}
                    src='https://firebasestorage.googleapis.com/v0/b/shopmyinfluens.appspot.com/o/shopmyinfluens%2FApp%20Data%2Flogo_shopmyinfluence-01.png?alt=media'
                    alt='influenceLogo'
                    onClick={goToHome} />
            </Grid>
        </>
    );
};

export default Sidebar;