import { combineReducers } from "redux";
import articlesReducer from "./reducers/articlesReducer";
import brandsReducer from "./reducers/brandsReducer";
import conversionsReducer from "./reducers/conversionsReducer";
import influencersReducer from "./reducers/influencersReducer";
import wishlistsReducer from "./reducers/wishlistsReducer";
import authReducer from "./reducers/authReducer";
import categoriesReducer from "./reducers/categoriesReducer";
import billsReducer from "./reducers/billsReducer";
import brandsAgentReducer from "./reducers/brandsAgentReducer";
import influencerAgentsReducer from "./reducers/influencerAgentsReducer";
import colorsReducer from "./reducers/colorsReducer";
import adminStatisticsReducer from "./reducers/appState/adminStatisticsReducer";
import adminInfluencerReducer from "./reducers/appState/adminInfluencerReducer";
import adminPartnerBrandReducer from "./reducers/appState/adminPartnerBrandReducer";
import adminPartnerAgencyReducer from "./reducers/appState/adminPartnerAgencyReducer";
import adminPartnerAgentReducer from "./reducers/appState/adminPartnerAgentReducer";
import trendStatisticsReducer from "./reducers/appState/trendStatisticsReducer";
import trendInfluencerReducer from "./reducers/appState/trendInfluencerReducer";
import agencyStatisticsReducer from "./reducers/appState/agencyStatisticsReducer";
import shortlinksReducer from "./reducers/shortlinksReducer";
import eshopReducer from "./reducers/eshopReducer";
import usersReducer from "./reducers/usersReducer";


const rootReducer = combineReducers({
  influencersState: influencersReducer,
  wishlistsState: wishlistsReducer,
  brandsState: brandsReducer,
  conversionsState: conversionsReducer,
  articlesState: articlesReducer,
  authState: authReducer,
  categoriesState: categoriesReducer,
  billsState: billsReducer,
  brandsAgentState: brandsAgentReducer,
  influencerAgentsState: influencerAgentsReducer,
  colorsState: colorsReducer,
  shortlinksState: shortlinksReducer,
  eshopState: eshopReducer,
  usersState: usersReducer,
  appState: combineReducers({
    adminStatisticsState: adminStatisticsReducer,
    adminInfluencerState: adminInfluencerReducer,
    adminPartnerBrandState: adminPartnerBrandReducer,
    adminPartnerAgencyState: adminPartnerAgencyReducer,
    adminPartnerAgentState: adminPartnerAgentReducer,
    trendStatisticsState: trendStatisticsReducer,
    trendInfluencerState: trendInfluencerReducer,
    agencyStatisticsState: agencyStatisticsReducer,
  }),
});

export default rootReducer;
