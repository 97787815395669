import React, { useEffect, useState } from "react";
import { clicksManager } from "../managers";

import { firebase } from "../managers";

const Router = () => {
  const [route, setRoute] = useState(null);
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    firebase
      .database()
      .ref("shortlinks")
      .orderByChild("newUrl")
      .equalTo(window.location.href.split('?')[0])
      .once("value")
      .then(snap => {
        const data = snap.val();
        data && setRoute({ ...Object.values(data)[0] });
      })
      .then(() => setLoading(false));
  }, []);

  const renderer = () => {
    if (!loading) {
      if (route && route.trackingUrl) {
        var temp = route.trackingUrl.substr(route.trackingUrl.indexOf('offer_id=') + 9);
        var offer_id = Number(temp.substr(0, temp.indexOf('&')));
        clicksManager
          .addClick(route.createdBy, route.productId, offer_id)
          .then(
            ref => (window.location = `${route.trackingUrl}&sub6=${ref.key}`)
          );
        return <></>;
      } else {
        window.location = "https://www.shopmyinfluence.fr";
        return <></>;
      }
    } else return <></>;
  };

  return renderer();
};

export default Router;
