import React from "react";
// import PropTypes from "prop-types";
import logoBig from "../../assets/images/burgbadLogoBig.png";
/* AW Frame */
import {
  WishlistPanel,
  TextBanner,
  Panel,
  LeadForm,
} from "../../aw";
/* Material UI */
import {
  Grid,
  ThemeProvider,
  Hidden,
} from "@material-ui/core";
import { makeStyles } from "@material-ui/styles";
/* Component Scope */
import { theme } from ".";
import { database } from "../managers";
import background from "../../assets/images/IRRESISTIBLE_GRIS.jpg";

const useStyles = makeStyles((theme) => ({
  leadLogo: {
    width: "100%",
    "& img": {
      maxWidth: "100%",
      maxHeight: "100%",
    },
  },
  background: {
    flex: 1,
    background: `url("${background}") top center/cover`,
  },
  bottomPic: {
    height: "50vh",
    background: `url("${background}") top center/cover`,
  },
}));

const Burgbad = (props) => {
  const classes = useStyles();

  const handleLeadSubmit = (lead) => {
    database.ref("eshop/burgbad/emails").push({
      ...lead,
      brandId: "-M65c6m9aO7CWcJ3lp1e",
      brandName: "Burgbad",
      date: Date.now(),
    });
  };

  return (
    <ThemeProvider theme={theme}>
      <Grid component='main' item container>
        <Hidden smDown>
          <Panel>
            <Grid
              item
              xs={12}
              container
              justify='flex-end'
            >
              <Grid
                className={classes.background}
                item
              />
              <Grid
                item
                xs={12}
                sm={12}
                md={4}
                lg={3}
                xl={2}
                container
                justify='center'
                alignItems='center'
                alignContent='center'
              >
                <Grid
                  className={classes.leadLogo}
                  item
                  // container
                >
                  <img
                    src={logoBig}
                    alt='Brand logo'
                  />
                </Grid>
                <Grid
                  item
                  // container
                  alignItems='center'
                >
                  <LeadForm
                    onSubmit={handleLeadSubmit}
                  />
                </Grid>
              </Grid>
            </Grid>
          </Panel>
        </Hidden>
        <Hidden mdUp>
          <Grid item container>
            <Grid
              className={classes.leadLogo}
              item
              container
              justify='center'
              alignItems='center'
            >
              <img
                src={logoBig}
                alt='Brand logo'
              />
            </Grid>
          </Grid>
        </Hidden>
        <TextBanner text='Retrouvez notre sélection de produits chez nos partenaires' />
        <WishlistPanel
          fullHeight={false}
          wishlist={{
            url:
              "https://shopmyinfluence.fr/embed/wishlist/5UP95yFHagYb839AVwFrucuskDY2/BURGBAD",
          }}
        />
        <Hidden mdUp>
          <Grid
            item
            container
            direction='column'
            justify='center'
            alignItems='center'
            wrap='nowrap'
          >
            <LeadForm
              onSubmit={handleLeadSubmit}
            />
            <img
              style={{ width: "100%" }}
              src={background}
              alt='example'
            />
          </Grid>
        </Hidden>
      </Grid>
    </ThemeProvider>
  );
};

Burgbad.propTypes = {};

export default Burgbad;
