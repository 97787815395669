import React from "react";

import BrandsIcon from "./brandsIcon";
import MiniArrow from "./select_arrow";
import Ventes from "./ventes";
import CommissionPaye from "./commissions_payee";
import Commission from "./commission";
const SVGIcon = (props) => {
  switch (props.name) {
    case "commission":
      return <Commission {...props} />;
    case "brandsIcon":
      return <BrandsIcon {...props} />;
    case "miniArrow":
      return <MiniArrow {...props} />;
    case "ventes":
      return <Ventes {...props} />;
    case "commissionPaye":
      return <CommissionPaye {...props} />;
    default:
      return;
  }
};

export default SVGIcon;
