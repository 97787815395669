const initState = {
  bills: [],
  errors: null,
  influencersBills: [],
  brandsBills: [],
  loadedInfluencersBills: false,
  loadedBrandBills: false,
  loadingUpdateInfluencerBills: false
};

const BillsReducer = (state = initState, action) => {
  switch (action.type) {
    case "FETCH_BILLS":
      return {
        ...state,
        bills: action.bills,
      };
    case "FETCH_BILLS_ERROR":
      return {
        ...state,
        errors: action.error,
      };
    case "FETCH_INFLUENCERS_BILLS":
      return {
        ...state,
        influencersBills: action.bills,
        loadedInfluencersBills: true,
      }
    case "FETCH_INFLUENCERS_BILLS_ERROR":
      return {
        ...state,
        errors: action.error,
        loadedInfluencersBills: false,
      }
    case "FETCH_BRANDS_BILLS":
      return {
        ...state,
        brandsBills: action.bills,
        loadedBrandBills: true,
      }
    case "FETCH_BRANDS_BILLS_ERROR":
      return {
        ...state,
        errors: action.error,
        loadedBrandBills: false,
      }
    case "UPDATE_INFLUENCER_BILLS_BY_ID_REQUEST":
      return {
        ...state,
        loadingUpdateInfluencerBills: true,
      };
    case "UPDATE_INFLUENCERS_BILLS_BY_ID_SUCCESS": {
      let newBills = state.influencersBills.map((bills) => {
        if (bills.key === action.payload.id) {
          let updatedBills = {
            ...bills,
          };
          Object.entries(action.payload.updates).forEach(
            ([keyToUpdate, newValue]) => {
              if (keyToUpdate.indexOf("/") !== -1) {
                let subObjectToUpdate = keyToUpdate.split("/");
                if (updatedBills[subObjectToUpdate[0]] === undefined)
                  updatedBills[subObjectToUpdate[0]] = {};
                updatedBills[subObjectToUpdate[0]][
                  subObjectToUpdate[1]
                ] = newValue;
              } else {
                updatedBills[keyToUpdate] = newValue;
              }
            }
          );
          return updatedBills;
        } else {
          return bills;
        }
      });
      return {
        ...state,
        influencersBills: newBills,
        loadingUpdateInfluencerBills: false,
      };
    }
    case "UPDATE_INFLUENCERS_BILLS_BY_ID_ERROR":
      return {
        ...state,
        errors: action.error,
        loadingUpdateInfluencerBills: false,
      };
    default:
      return state;
  }
};

export default BillsReducer;
