const initState = {
    influencerTableSortHelper: [1, 0, 0, 0, 0, 0],
    influencerTablePageNumber: 1,
    influencerTablePageSize: 25,
    brandTableSortHelper: [1, 0, 0, 0, 0, 0],
    brandTablePageNumber: 1,
    brandTablePageSize: 25,
    influencerAgentTableSortHelper: [1, 0, 0, 0, 0, 0],
    influencerAgentTablePageNumber: 1,
    influencerAgentTablePageSize: 25
}

const adminStatisticsReducer = (state = initState, action) => {
    switch (action.type) {
        case 'UPDATE_ADMIN_STATS_INFLUENCER_TABLE_SORT_HELPER':
            return {
                ...state,
                influencerTableSortHelper: action.sortHelper
            };
        case 'UPDATE_ADMIN_STATS_BRAND_TABLE_SORT_HELPER':
            return {
                ...state,
                brandTableSortHelper: action.sortHelper
            };
        case 'UPDATE_ADMIN_STATS_INFLUENCER_AGENT_TABLE_SORT_HELPER':
            return {
                ...state,
                influencerAgentTableSortHelper: action.sortHelper
            };
        case 'UPDATE_ADMIN_STATS_INFLUENCER_TABLE_PAGE_NUMBER':
            return {
                ...state,
                influencerTablePageNumber: action.pageNumber
            };
        case 'UPDATE_ADMIN_STATS_BRAND_TABLE_PAGE_NUMBER':
            return {
                ...state,
                brandTablePageNumber: action.pageNumber
            };
        case 'UPDATE_ADMIN_STATS_INFLUENCER_AGENT_TABLE_PAGE_NUMBER':
            return {
                ...state,
                influencerAgentTablePageNumber: action.pageNumber
            };
        case 'UPDATE_ADMIN_STATS_INFLUENCER_TABLE_PAGE_SIZE':
            return {
                ...state,
                influencerTablePageSize: action.pageSize
            };
        case 'UPDATE_ADMIN_STATS_BRAND_TABLE_PAGE_SIZE':
            return {
                ...state,
                brandTablePageSize: action.pageSize
            };
        case 'UPDATE_ADMIN_STATS_INFLUENCER_AGENT_TABLE_PAGE_SIZE':
            return {
                ...state,
                influencerAgentTablePageSize: action.pageSize
            };
        default:
            return state
    }
};

export default adminStatisticsReducer;