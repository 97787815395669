import React, { useEffect, useState } from "react";
import { Grid, makeStyles, useTheme, useMediaQuery, Typography } from "@material-ui/core";
import { connect } from "react-redux";

const useStyles = makeStyles(() => ({
  body: {
    minHeight: "100vh",
    backgroundSize: "100vw 100%",
    minWidth: "100%"
  },
  textContainer: {
    minHeight: "100%",
  },
  textBox: {
    height: "100%",
    marginTop: "50px"
  },
  logoImage: {
    marginTop: '10px',
    marginBottom: '30px'
  },
  description: {
    textAlign: 'center',
    fontFamily: 'Poppins',
    fontWeight: 550,
  },
  brandImage: {
    height: '130px',
    borderRadius: '20px',
    width: '130px',
    margin: "10px 10px 10px 10px",
  },
}));
const Brands = (props) => {

  const classes = useStyles();
  const theme = useTheme();
  const xsDown = useMediaQuery(theme.breakpoints.down("xs"));
  const [brandNotPrivate, setBrandNotPrivate] = useState([]);
  const [finalBrandToRedner, setFinalBrandToredner] = useState([]);

  const { brands, loadedBrands } = props;

  useEffect(() => {
    if (loadedBrands) {
      setBrandNotPrivate(brands.filter(element => element.private === false && (element.localisation === "FR" || element.localisation === "worldwide")));
    }
  }, [loadedBrands, brands])

  useEffect(() => {
    brandNotPrivate.sort((a, b) => {
      if (a.name.toLowerCase() > b.name.toLowerCase()) {
        return 1;
      }
      if (b.name.toLowerCase() > a.name.toLowerCase()) {
        return -1
      }
      return 0;
    });
    setFinalBrandToredner([...brandNotPrivate]);
  }, [brandNotPrivate])

  return (
    <div className={classes.body}>
      <Grid container item xs={12} direction="column" justify="center" alignItems="center">
        <Grid container item xs={10} direction="row" justify="center" alignItems="center" className={classes.textContainer}>
          <Grid container item xs={12} direction="column" alignItems="center" justify="center" className={classes.textBox} >
            <Typography className={classes.title} style={{ fontSize: xsDown ? 20 : 26, fontWeight: "bold" }}
            >
              Nos Marques Partenaires
            </Typography>
            <div style={{ marginBottom: "15px", marginTop: "50px", width: "100%" }}>
              <Grid container item direction="row" justify="center" alignItems="center" style={{ width: "100%", display: "flex" }}>
                <Grid container item justify='center' alignItems='center'>
                  {finalBrandToRedner.map((brand) => {
                    return (
                      <a href={brand.href} target="_blank" rel="noopener noreferrer" key={brand.offerId} >
                        <img src={brand.pic} alt={brand.name} className={classes.brandImage} />
                      </a>
                    )
                  })
                  }
                </Grid>
              </Grid>
            </div>
          </Grid>
        </Grid>
      </Grid>
    </div>
  )

}
const mapStateToProps = (state) => {
  const brands = state.brandsState.allBrands;
  const loadedBrands = state.brandsState.loadedBrands;
  return {
    brands,
    loadedBrands,
  }
}
export default connect(mapStateToProps, null)(Brands);
