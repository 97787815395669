import React, { useState } from "react";
import clsx from "clsx";
import { Redirect } from "react-router-dom";
/* Framework */
import {
  makeStyles,
  useTheme,
  Drawer,
  AppBar,
  Toolbar,
  List,
  Typography,
  Divider,
  IconButton,
  ListItem,
  ListItemIcon,
  ListItemText,
} from "@material-ui/core";
/* Icons */
import DashboardIcon from "@material-ui/icons/Dashboard";
import ChevronLeftIcon from "@material-ui/icons/ChevronLeft";
import MenuIcon from "@material-ui/icons/Menu";
import ChevronRightIcon from "@material-ui/icons/ChevronRight";

const drawerWidth = 240;

const useStyles = makeStyles((theme) => ({
  appBar: {
    zIndex: theme.zIndex.drawer + 1,
    transition: theme.transitions.create(
      ["width", "margin"],
      {
        easing: theme.transitions.easing.sharp,
        duration:
          theme.transitions.duration
            .leavingScreen,
      }
    ),
  },
  appBarShift: {
    marginLeft: drawerWidth,
    width: `calc(100% - ${drawerWidth}px)`,
    transition: theme.transitions.create(
      ["width", "margin"],
      {
        easing: theme.transitions.easing.sharp,
        duration:
          theme.transitions.duration
            .enteringScreen,
      }
    ),
  },
  menuButton: {
    marginRight: 36,
  },
  hide: {
    display: "none",
  },
  drawer: {
    width: drawerWidth,
    flexShrink: 0,
    whiteSpace: "nowrap",
  },
  drawerOpen: {
    width: drawerWidth,
    transition: theme.transitions.create(
      "width",
      {
        easing: theme.transitions.easing.sharp,
        duration:
          theme.transitions.duration
            .enteringScreen,
      }
    ),
  },
  drawerClose: {
    transition: theme.transitions.create(
      "width",
      {
        easing: theme.transitions.easing.sharp,
        duration:
          theme.transitions.duration
            .leavingScreen,
      }
    ),
    overflowX: "hidden",
    width: theme.spacing(7) + 1,
    [theme.breakpoints.up("sm")]: {
      width: theme.spacing(9) + 1,
    },
  },
  toolbar: {
    display: "flex",
    alignItems: "center",
    justifyContent: "flex-end",
    padding: theme.spacing(0, 1),
    ...theme.mixins.toolbar,
  },
  content: {
    flexGrow: 1,
  },
}));

const Template = (props) => {
  const { 
    children,
    // brandUrl
  } = props;

  const [redirect, setRedirect] = useState(false);
  const [open, setOpen] = useState(false);

  const classes = useStyles();
  const theme = useTheme();

  const handleDrawerOpen = () => {
    setOpen(true);
  };

  const handleDrawerClose = () => {
    setOpen(false);
  };

  const menuOptions = [
    {
      name: "Dashboard",
      link: `/eshop/admin`,
      icon: <DashboardIcon />,
    },
    // { name: "Smartlinks", link: "/admin/smartlinks", icon: <LinkIcon /> },
    // { name: "Influenceurs", link: "/admin/influencers", icon: <GroupIcon /> },
    // { name: "Marques", link: "/admin/brands", icon: <StorefrontIcon /> },
  ];

  return (
    <>
      {redirect && (
        <Redirect push to={redirect} />
      )}
      <AppBar
        position='fixed'
        className={clsx(classes.appBar, {
          [classes.appBarShift]: open,
        })}
      >
        <Toolbar>
          <IconButton
            color='inherit'
            aria-label='open drawer'
            onClick={handleDrawerOpen}
            edge='start'
            className={clsx(classes.menuButton, {
              [classes.hide]: open,
            })}
          >
            <MenuIcon />
          </IconButton>
          <Typography variant='h6' noWrap>
            Admin
          </Typography>
        </Toolbar>
      </AppBar>
      <Drawer
        variant='permanent'
        className={clsx(classes.drawer, {
          [classes.drawerOpen]: open,
          [classes.drawerClose]: !open,
        })}
        classes={{
          paper: clsx({
            [classes.drawerOpen]: open,
            [classes.drawerClose]: !open,
          }),
        }}
      >
        <div className={classes.toolbar}>
          <IconButton onClick={handleDrawerClose}>
            {theme.direction === "rtl" ? (
              <ChevronRightIcon />
            ) : (
              <ChevronLeftIcon />
            )}
          </IconButton>
        </div>
        <Divider />
        <List>
          {menuOptions.map((opt, i) => (
            <ListItem
              button
              key={i}
              onClick={() =>
                setRedirect(opt.link)
              }
            >
              <ListItemIcon>
                {opt.icon}
              </ListItemIcon>
              <ListItemText primary={opt.name} />
            </ListItem>
          ))}
        </List>
      </Drawer>
      <main className={classes.content}>
        <div className={classes.toolbar} />
        {children}
      </main>
    </>
  );
};

export default Template;
