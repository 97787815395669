import { makeStyles, Grid, Typography, Chip } from '@material-ui/core';
import React, { useState, useEffect } from 'react';
import HeaderBar from './HeaderBar';

const useStyle = makeStyles((theme) => ({
    barContent: {
        backgroundColor: '#fff'
    },
    influencerImage: {
        width: '90%',
        borderRadius: '25px',
        objectFit: 'contain',
        marginTop: 15,
        marginBottom: 5
    },
    influencerName: {
        fontFamily: 'Poppins',
        marginTop: 5,
        marginBottom: 5
    },
    influencerDescription: {
        fontFamily: 'Poppins',
        fontWeight: 200,
        marginBottom: 5
    },
    wishlistsPlaceholder: {
        fontFamily: 'Poppins',
        color: '#ee3889',
        fontSize: '18px',
        fontWeight: '500'
    },
    wishlistChip: {
        color: '#00ADEF',
        border: '1px solid #00ADEF',
        margin: '5px',
        fontFamily: 'Poppins',
        fontSize: '15px'
    },
    activeWishlistChip: {
        margin: '5px',
        fontFamily: 'Poppins',
        fontSize: '15px'
    },
}));

const InfluencerInfosBar = (props) => {

    const classes = useStyle();

    const {
        influencer,
        userWishlists,
        selectedWishlist
    } = props;

    const [activeWishlist, setActiveWishlist] = useState(null);

    useEffect(() => {
        if (selectedWishlist) setActiveWishlist(selectedWishlist)
    }, [selectedWishlist]);


    const handleChangeActiveWishlist = (wishlist) => {
        setActiveWishlist(wishlist);
        props.handleWishlistChange(wishlist);
    };

    return (
        <React.Fragment>
            <Grid container item direction='row' xs={12} justify='center' className={classes.barContent}>
                <HeaderBar />
                <Grid container item direction='row' xs={11} justify='center'>
                    <img src={influencer.banner} alt={influencer.name} className={classes.influencerImage} />
                </Grid>
                <Grid container item direction='row' xs={11} justify='center' alignItems='center'>
                    <Typography className={classes.influencerName}>{influencer.name}</Typography>
                </Grid>
                <Grid container item direction='row' xs={11} justify='flex-start' alignItems='center'>
                    <Typography className={classes.influencerDescription}>{influencer.description}</Typography>
                </Grid>
                <Grid container item direction='row' xs={11} justify='flex-start' alignItems='center'>
                    <Typography className={classes.wishlistsPlaceholder}>Wishlists</Typography>
                </Grid>
                <Grid container item direction='row' xs={11} justify='flex-start' alignItems='center' style={{ marginBottom: 5 }}>
                    {
                        userWishlists.map(userWishlist => (
                            <Chip
                                variant={(activeWishlist && userWishlist && activeWishlist.key === userWishlist.key) ? 'default' : 'outlined'}
                                className={(activeWishlist && userWishlist && activeWishlist.key === userWishlist.key) ? classes.activeWishlistChip : classes.wishlistChip}
                                label={userWishlist.name}
                                color={(activeWishlist && userWishlist && activeWishlist.key === userWishlist.key) ? 'primary' : 'default'}
                                key={userWishlist.key}
                                onClick={() => handleChangeActiveWishlist(userWishlist)} />
                        ))
                    }
                </Grid>
            </Grid>
        </React.Fragment>
    );
};

export default InfluencerInfosBar;