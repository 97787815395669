import React, { useState, useEffect, Fragment } from "react";
import { Grid, Avatar, Typography, TextField, Select, MenuItem, CircularProgress, InputBase } from "@material-ui/core";
import Autocomplete from '@material-ui/lab/Autocomplete';
import { makeStyles, withStyles } from "@material-ui/core/styles";
import Paper from "@material-ui/core/Paper";
import IconButton from "@material-ui/core/IconButton";
import SearchIcon from "@material-ui/icons/Search";
import MenuIcon from "@material-ui/icons/Menu";
import { useHistory } from "react-router-dom";
import { useTranslation } from "react-i18next";
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import clsx from "clsx";
import { connect } from "react-redux";

const useStyles = makeStyles((theme) => ({
    headerContainer: {
        marginBottom: '10px',
        flexBasis: 'auto'
    },
    root: {
        display: "flex",
        alignItems: "center",
        width: '80%',
        borderRadius: '40px',
        boxShadow: '0 7px 30px 0 rgba(88, 165, 211, 0.16)',
    },
    input: {
        marginLeft: theme.spacing(1),
        flex: 1,
        height: '100%'
    },
    iconButton: {
        padding: 10
    },
    divider: {
        height: 28,
        margin: 4
    },
    avatarContainer: {
        marginTop: '20px',
    },
    avatarSize: {
        width: theme.spacing(10),
        height: theme.spacing(10),
        marginRight: '20px',
    },
    brandName: {
        fontFamily: 'Montserrat',
        fontWeight: 600,
        color: '#000000'
    },
    smallIcon: {
        paddingRight: 10,
        fontFamily: 'Poppins'
    },
    popupSearch: {
        marginTop: '3px',
        width: '100%'
    },
    selectClass: {
        backgroundColor: 'transparent',
        fontFamily: 'Poppins',
        marginTop: '10px',
        fontSize: '24px',
        '&:focus': {
            backgroundColor: 'transparent',
        },

    },
    searchInputInfluenceTab: {
        width: '100%',
        fontFamily: 'Poppins'
    },
}));

const iconStyles = {
    selectIcon: {
        color: "black"
    }
};
const CustomExpandMore = withStyles(iconStyles)(
    ({ className, classes, ...rest }) => {
        return (
            <ExpandMoreIcon
                {...rest}
                className={clsx(className, classes.selectIcon)}
            />
        );
    }
);

const Header = (props) => {
    const { t, i18n } = useTranslation('common');
    const classes = useStyles();
    const brand = props.brand;
    const url = props.relativeUrl;
    const history = useHistory();
    const [searchValue, setSearchValue] = useState(null);
    const [inputValue, setInputValue] = useState('');
    const [lang, setLang] = useState('en');
    const searchInfluencer = props.searchInfluencer;
    const openInfluencerInfos = props.openInfluencerInfos;

    const {
        influencers,
        loadedInfluencers,
        mobileMode,
        influencerActiveButton,
    } = props;

    useEffect(() => {
        if (searchValue !== null) {
            openInfluencerInfos(searchValue);
        }
    }, [searchValue, openInfluencerInfos]);

    useEffect(() => {
        i18n.changeLanguage(lang);
    }, [lang, i18n]);

    useEffect(() => {
        setLang((i18n.language !== 'fr') ? 'en' : 'fr')
    }, [i18n.language]);

    const toggleMenu = () => {
        props.opendrawer();
    }


    useEffect(() => {
        searchInfluencer(inputValue);
    }, [inputValue, searchInfluencer]);

    return (
        <Grid container item xs={12} direction='row' justify='space-around' className={classes.headerContainer}>
            <Grid container item xs={2} alignItems='center'>
                {mobileMode
                    ? <IconButton onClick={() => toggleMenu()}>
                        <MenuIcon fontSize='large' />
                    </IconButton>
                    : <></>}
            </Grid>
            <Grid container item xs={7} justify='space-around' alignItems='center'>
                <Paper component="form" className={classes.root}>
                    {influencerActiveButton === "normal" ?
                        <>
                            <IconButton
                                className={classes.iconButton}
                                aria-label="search"
                            >
                                <SearchIcon />
                            </IconButton>
                            <Autocomplete
                                value={searchValue}
                                onChange={(event, newValue) => {
                                    setSearchValue(newValue);
                                    history.push(`${url}/influencers`);
                                }}
                                inputValue={inputValue}
                                onInputChange={(event, newInputValue) => {
                                    setInputValue(newInputValue);
                                }}
                                options={influencers.filter(item => item.name !== undefined && item.name !== '')}
                                loading={!loadedInfluencers}
                                getOptionLabel={(option) => option.name}
                                renderOption={(option) => (
                                    <Grid container item md={12} justify='center'>
                                        <Grid container item md={2} xs={12}>
                                            <img alt={option.name} src={option.banner} height='32px' width='64px' style={{ borderRadius: '26px' }} />
                                        </Grid>
                                        <Grid container item md={10} xs={12} alignItems='center'>
                                            <Typography style={{ fontFamily: 'Poppins' }}>{option.name}</Typography>
                                        </Grid>
                                    </Grid>
                                )}
                                className={classes.input}
                                classes={{
                                    popper: classes.popupSearch,
                                }}

                                renderInput={(params) => (
                                    <TextField
                                        placeholder={t('TREND.HEADER.SEARCH_PLACEHOLDER')}
                                        {...params}
                                        InputProps={{
                                            ...params.InputProps,
                                            type: 'search',
                                            disableUnderline: true,
                                            className: classes.smallIcon,
                                            endAdornment: (
                                                <Fragment>
                                                    {
                                                        (loadedInfluencers)
                                                            ? false
                                                            : <CircularProgress size={20} />
                                                    }
                                                </Fragment>
                                            )
                                        }}
                                    />
                                )}
                            />
                        </>
                        :
                        <>
                            <IconButton
                                className={classes.iconButton}
                                aria-label="search"
                            >
                                <SearchIcon />
                            </IconButton>
                            <InputBase
                                className={classes.searchInputInfluenceTab}
                                type='text'
                                placeholder={t('ADMIN.HEADER.SEARCH_PLACEHOLDER')}
                                value={inputValue}
                                onChange={(event) => setInputValue(event.target.value)} />

                        </>
                    }
                </Paper>
                {
                    mobileMode
                        ? <></>
                        : <Select
                            value={lang}
                            disableUnderline
                            onChange={(e) => setLang(e.target.value)}
                            classes={{ root: classes.selectClass }}
                            IconComponent={CustomExpandMore}>
                            <MenuItem value='fr' style={{ fontFamily: 'Poppins' }}>FR</MenuItem>
                            <MenuItem value='en' style={{ fontFamily: 'Poppins' }}>EN</MenuItem>
                        </Select>
                }
            </Grid>
            <Grid container direction='row' item xs={3} className={classes.avatarContainer} alignItems='center' justify='center'>
                <Avatar className={classes.avatarSize} alt={brand.name} src={brand.pic} />
                <Typography className={classes.brandName}>{brand.displayName}</Typography>
            </Grid>
        </Grid>
    );
}

const mapStateToProps = (state, ownProps) => {
    const influencers = state.influencersState.influencers;
    const loadedInfluencers = state.influencersState.loadedInfluencers;
    return {
        influencers,
        loadedInfluencers
    }
}

export default connect(mapStateToProps)(Header);