import React from "react";
import { makeStyles } from "@material-ui/styles";

// UI Dependencies
import { Credentials } from "../../ui";

// Component Scope
import {
  Header,
  Menu,
  SearchBox,
} from "./components";
import { Grid } from "@material-ui/core";

const useStyles = makeStyles((theme) => {
  return {
    content: {
      flexGrow: 1,
      paddingTop: theme.appBar.height,
    },
    wrapper: {
      position: "relative",
      minHeight: "100%",
    },
    back: {
      width: "30px",
      position: "absolute",
      top: "10px",
      right: "0px",
      color: "black",
    },
    masonryContainer: {
      height: "100%",
      width: "100%",
    },
  };
});

const Template = (props) => {

  const children = props.children;
  const credProps = props.credProps;
  const headerProps = props.headerProps;
  const menuProps = props.menuProps;
  const searchProps = props.searchProps;

  const classes = useStyles();
  const handleInfluencerSearch = (searchValue) => {
    props.incluencerSearch(searchValue);
  }

  const handleFilterType = (filterType) => {
    props.filterType(filterType)
  }
  return (
    <Grid container>
      { credProps.visible && (
        <Credentials
          { ...credProps }
        />
      ) }
      { searchProps.open && (
        <SearchBox { ...searchProps } />
      ) }
      <Menu { ...menuProps } />
      <Header
        filterType={ handleFilterType }
        influencerSearch={ handleInfluencerSearch }
        { ...headerProps }
      />
      <Grid
        component='main'
        className={ classes.content }
        container
      >
        { children }
      </Grid>
    </Grid>
  );
};

export default Template;
