import {
  Grid,
  makeStyles,
  useTheme,
  useMediaQuery,
  MenuItem,
  Select,
  Typography,
  Box,
  Switch,
  Menu,
  IconButton,
  Backdrop,
  CircularProgress,
  Dialog,
  Button,
  FormHelperText,
  InputBase
} from "@material-ui/core";
import { Pagination, PaginationItem } from "@material-ui/lab";
import SVGIcon from "../template/assets/SVGIcons";
import BootstrapInput from "../template/assets/customComponents/BootstrapInput";
import React, { useCallback, useEffect, useState } from "react";
import ExpandLessIcon from "@material-ui/icons/ExpandLess";
import ExpandMoreIcon from "@material-ui/icons/ExpandMore";
import SortIcon from "@material-ui/icons/Sort";
import InfluencerModal from "../template/components/influencerModal";
import ConfirmDeleteDialog from "../template/components/confirmDeleteDialog";
import { dbManager } from "../../managers";
import TrendLoading from "../../ui/trendLoading/TrendLoading";
import MoreVertIcon from "@material-ui/icons/MoreVert";
import { useTranslation } from "react-i18next";
import { connect } from "react-redux";
import { deleteInfluencerById, updateInfluencerById } from "../../../store/actions/influencersActions";
import { fetchWishlistsPerInfluencer } from "../../../store/actions/wishlistsActions";
const useStyles = makeStyles((theme) => ({
  influencersContent: {
    flexGrow: 1,
    display: "flex",
    flexDirection: "column",
    backgroundColor: "#eff9fe",
  },
  selectClass: {
    backgroundColor: "#3eadef",
    color: "#fff",
    fontFamily: "Poppins",
    width: "200px",
    height: "40px",
    borderRadius: "35px",
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    paddingRight: "20px",
    "&:focus": {
      backgroundColor: "#3eadef",
      borderRadius: "35px",
    },
  },
  selectBox: {
    margin: "10px",
  },
  arraySection: {
    marginTop: "20px",
    marginBottom: "20px",
  },
  tableHeader: {
    fontFamily: "Poppins",
  },
  clickableTableHeader: {
    fontFamily: "Poppins",
    "&:hover": {
      cursor: "pointer",
    },
  },
  influencerBoxContainer: {
    marginTop: "10px",
    marginBottom: "10px",
    minWidth: "1050px",
  },
  influencerBox: {
    display: "flex",
    width: "100%",
    backgroundColor: "white",
    height: "80px",
    borderRadius: "27px",
    boxShadow: "0 7px 30px 0 rgba(88, 165, 211, 0.16)",
  },
  influencerImage: {
    width: "160px",
    height: "80px",
    borderTopLeftRadius: "27px",
    borderBottomLeftRadius: "27px",
    objectFit: "fill",
  },
  paginationClass: {
    backgroundColor: "#ffffff !important",
    boxShadow: "0 7px 30px 0 rgba(88, 165, 211, 0.16)",
    borderRadius: "8px",
    fontFamily: "Poppins",
    height: "40px",
    width: "40px",
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
  },
  selectedPaginationItem: {
    backgroundColor: "#3eadef !important",
    boxShadow: "0 7px 30px 0 rgba(88, 165, 211, 0.16)",
    borderRadius: "8px",
    fontFamily: "Poppins",
    height: "40px",
    width: "40px",
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    color: "#ffffff",
  },
  paginationStyles: {
    fontFamily: "Poppins",
  },
  influencerName: {
    fontFamily: "Poppins",
    textDecoration: "underline",
    "&:hover": {
      cursor: "pointer",
    },
  },
  influencerEmail: {
    fontFamily: "Poppins",
    fontSize: "14px",
    color: "#4e4d4dc9",
  },
  tableRowContent: {
    fontFamily: "Poppins",
  },
  menu: {
    boxShadow: "0 7px 7px 0 #d0e6f130",
  },
  overlay: {
    zIndex: theme.zIndex.drawer + 1
  },
  menuDialog: {
    width: "600px",
    height: "280px",
    borderRadius: "20px",
  },
  dialogTitle: {
    fontFamily: 'Poppins',
    fontSize: '21px',
    textAlign: 'center'
  },
  formControlRow: {
    marginTop: '5px',
    marginBottom: '5px'
  },
  formControl: {
    width: '100%'
  },
  formInput: {
    fontFamily: 'Poppins',
    borderRadius: '35px',
    boxShadow: '0 7px 30px 0 rgba(69, 128, 163, 0.16)',
    height: '50px',
    width: '100%',
    paddingTop: '10px',
    paddingBottom: '10px',
    paddingLeft: '20px',
    margin: '5px'
  },
  cancelButton: {
    marginTop: '15px',
    padding: '6px 8px',
    width: "200px",
    height: "40px",
    color: '#50c7f4',
    fontFamily: 'Poppins',
    fontSize: "16px",
    textTransform: "capitalize",
    borderRadius: "20px",
    boxShadow: "0px 1px 5px 1px #50c7f4",
    backgroundColor: '#fff',
    '&:hover': {
      backgroundColor: '#fff'
    }
  },
  deleteButton: {
    marginTop: '15px',
    padding: '6px 8px',
    width: "200px",
    height: "40px",
    color: '#fff',
    fontFamily: 'Poppins',
    fontSize: "16px",
    textTransform: "capitalize",
    boxShadow: "0px 0px 0px 0px #00aeef",
    borderRadius: "20px",
    backgroundColor: '#00aeef',
    '&:hover': {
      backgroundColor: '#00aeef'
    }
  },
  errorMessage: {
    fontFamily: "Poppins",
    color: "red",
    fontSize: "16px",
    height: "10px"
  },
  exportButton: {
    marginTop: '30px',
    padding: '6px 8px',
    minWidth: "110px",
    minHeight: "35px",
    color: '#fff',
    fontFamily: 'Poppins',
    textTransform: "capitalize",
    boxShadow: "0px 0px 0px 0px #77bb41",
    whiteSpace: "nowrap",
    marginLeft: "100px",
    backgroundColor: '#77bb41',
    '&:hover': {
      backgroundColor: '#77bb41'
    }
  },
}));

const Influenceurs = (props) => {
  const { t } = useTranslation("common");
  const theme = useTheme();
  const xsDown = useMediaQuery(theme.breakpoints.down('xs'));

  const classes = useStyles();
  const influencerSearch = props.influencerSearch;
  const [influencersToRender, setInfluencersToRender] = useState([]);
  const [loading, setLoading] = useState(true);
  const [activeInfluencer, setActiveInfluencer] = useState(null);
  const [openPopover, setOpenPopover] = useState(false);
  const [page, setPage] = useState(1);
  const [count, setCount] = useState(0);
  const [size, setSize] = useState(50);
  const [sortHelper, setSortHelper] = useState([0, 1]);
  const [sortEvent, setSortEvent] = useState(0);
  const [anchorEl, setAnchorEl] = useState(null);
  const [influencerToDelete, setInfluencerToDelete] = useState(null);
  const [filterValue, setFilterValue] = useState("");
  const [influencersBeforeRender, setInfluencersBeforeRender] = useState([]);
  const [
    deleteInfluencerDialogState,
    setDeleteInfluencerDialogState,
  ] = useState(false);
  const [openPasswordDialog, setOpenPasswordDialog] = useState(false);
  const [passwordInput, setPasswordInput] = useState("");
  const [validationError, setValidationError] = useState(false);
  const [passwordValidationError, setPasswordValidationError] = useState("");
  //const [scrollPosition, setScrollPosition] = useState(Number(0));
  //const [confirmationPasswordDialog, setConfirmationPasswordDialog] = useState(false);

  const {
    allConversions,
    loadedAllConversions,
    influencers,
    loadedInfluencers,
    updateInfluencerById,
    sortInfluencers,
    setInfluencersForRender,
    influencersPreparedToRender,
    calculatedInfluencers,
    loadingDeleteInfluencer,
    deleteInfluencerById,
    appStateSortHelper,
    appStatePageNumber,
    appStatePageSize,
    changeSortHelper,
    changePage,
    changePageSize,
    fetchWishlistsPerInfluencer,
    wishlistsPerInfluencerByIdRequest,

  } = props;


  /* const handleScroll = useCallback(() => {
     if (!openPopover) {
       let position = window.pageYOffset;
       setScrollPosition(position);
     }
   }, [openPopover]);
 
   useEffect(() => {
     window.addEventListener('scroll', handleScroll);
 
     return () => {
       window.removeEventListener('scroll', handleScroll);
     };
   }, [handleScroll]);
 
   useEffect(() => {
     if (!openPopover) {
 
       sessionStorage.setItem('position', scrollPosition);
       //localStorage.removeItem('position');
       //localStorage.setItem('position', scrollPosition);
       console.log(sessionStorage);
     }
   }, [openPopover, scrollPosition]);*/

  const handlePageChange = (event, value) => {
    changePage(value);
  };

  const handlePageSizeChange = (event) => {
    changePageSize(event.target.value);
    changePage(1);
  };

  const handlePrivateChange = (influencer, index) => {
    let updates = {
      private: !influencer.private,
    };
    updateInfluencerById(influencer.key, updates);
  };

  const handleOpenPopover = useCallback(
    (influencer) => {
      wishlistsPerInfluencerByIdRequest();
      fetchWishlistsPerInfluencer(influencer.key);
      let influencerToView = influencersPreparedToRender.filter(
        (item) => item.key === influencer.key
      )[0];
      setActiveInfluencer({
        ...influencerToView,
        influencerId: influencer.key,
        showStats: true,
      });
      setOpenPopover(true);
    },
    [influencersPreparedToRender, wishlistsPerInfluencerByIdRequest, fetchWishlistsPerInfluencer]
  );

  const handleClosePopover = () => {
    setOpenPopover(false);
    setActiveInfluencer(null);
    //window.scrollTo(0, parseInt(sessionStorage.position));
    //sessionStorage.removeItem("position");

  };

  const handleOpenMenu = (event, influencer) => {
    setAnchorEl(event.currentTarget);
    setInfluencerToDelete(influencer);
  };

  const handleCloseMenu = () => {
    setAnchorEl(null);
  };

  const handleValueChange = id => event => {
    if (id === 'password') setPasswordInput(event.target.value);
  };

  const handleOpenConfirmDeleteDialog = () => {
    setAnchorEl(null);
    setOpenPasswordDialog(true);
  };
  const handleClosePasswordDialog = () => {
    setAnchorEl(null);
    setOpenPasswordDialog(false);
    setPasswordValidationError("");
    setValidationError(false);
  }
  const verificationPassword = async () => {
    dbManager.acceeToDeleteInfluencer().then((result) => {
      if (passwordInput !== result) {
        setPasswordValidationError(t("ADMIN.VIEWS.INFLUENCERS.DIALOG_ERROR_PASSWORD"));
        setValidationError(true);
      } else {
        setDeleteInfluencerDialogState(true);
        setOpenPasswordDialog(false);
      }
    });
  };

  const deleteInfluencer = (data) => {
    setDeleteInfluencerDialogState(false);
    if (data) {
      if (influencerToDelete) {
        const url = influencerToDelete.banner;
        const index = url.indexOf('?');
        const extension = url.slice(index - 4, index);
        deleteInfluencerById(influencerToDelete, extension);
      }
      setDeleteInfluencerDialogState(false);
    } else {
      setDeleteInfluencerDialogState(false);
    }

  };

  useEffect(() => {
    setSortHelper(appStateSortHelper);
  }, [appStateSortHelper]);

  useEffect(() => {
    setPage(appStatePageNumber);
  }, [appStatePageNumber]);

  useEffect(() => {
    setSize(appStatePageSize);
  }, [appStatePageSize]);

  useEffect(() => {
    setFilterValue(props.filterValue);
  }, [props.filterValue]);

  useEffect(() => {
    if (loadedInfluencers && loadedAllConversions && !calculatedInfluencers) {
      let influencersAfterCalculations = [];
      influencers.forEach((influencer) => {
        const influencerConversions = allConversions.filter(
          (conversion) => conversion.influencer === influencer.key
        );
        let influencerAmount = 0;
        let influencerCommissions = 0;
        let influencerCommissionAffiliate = 0;
        influencerConversions.forEach((conversion) => {
          influencerAmount += conversion.amount;
          influencerCommissions += Number(conversion.commission);
          if (conversion.commissionAffiliate !== undefined) {
            influencerCommissionAffiliate += Number(conversion.commissionAffiliate);
          }
        });
        influencersAfterCalculations.push({
          ...influencer,
          amount: influencerAmount,
          salesNumber: influencerConversions.length,
          commissions: influencerCommissions,
          commissionAffiliate: influencerCommissionAffiliate,
        });
      });
      setInfluencersForRender(influencersAfterCalculations);
    }
  }, [
    influencers,
    allConversions,
    loadedAllConversions,
    loadedInfluencers,
    setInfluencersForRender,
    calculatedInfluencers,
  ]);

  useEffect(() => {
    if (loadedInfluencers && loadedAllConversions) {
      let temp;
      if (filterValue === "") {
        temp = influencersPreparedToRender.filter(
          (influencer) => influencer.name !== ""
        );
        setInfluencersBeforeRender(temp);
      } else {
        temp = influencersPreparedToRender.filter((influencer) => {
          if (
            influencer.name !== undefined &&
            influencer.email !== undefined &&
            influencer.affiliate_id !== undefined
          )
            return (
              influencer.name.toLowerCase().includes(filterValue.toLowerCase()) ||
              influencer.email.toLowerCase().includes(filterValue.toLowerCase()) ||
              influencer.affiliate_id.toString().toLowerCase().includes(filterValue.toLowerCase())
            );
          return false;
        });
        setInfluencersBeforeRender(temp);
      }
      setInfluencersToRender(temp.slice((page - 1) * size, page * size));
      setCount(
        temp.length % size === 0
          ? Math.trunc(temp.length / size)
          : Math.trunc(temp.length / size) + 1
      );
      setLoading(false);
    }
  }, [
    influencersPreparedToRender,
    size,
    page,
    loadedAllConversions,
    loadedInfluencers,
    filterValue,
    sortEvent,
  ]);

  useEffect(() => {
    if (calculatedInfluencers) {
      let indexAsc = sortHelper.indexOf(1);
      let indexDesc = sortHelper.indexOf(-1);
      if (indexAsc !== -1) {
        const sortTypeAsc = (indexAsc, tableToWorkOn) => {
          switch (indexAsc) {
            case 1:
              tableToWorkOn.sort((a, b) => {
                if (a.amount > b.amount) return -1;
                if (a.amount < b.amount) return 1;
                return 0;
              });
              break;
            case 0:
              tableToWorkOn.sort((a, b) => {
                if (Number(a.createdAt) > Number(b.createdAt)) return -1;
                if (Number(a.createdAt) < Number(b.createdAt)) return 1;
                return 0;
              });
              break;
            default:
              break;
          }
        };
        sortTypeAsc(indexAsc, influencersPreparedToRender);
      } else {
        const sortTypeDesc = (indexDesc, tableToWorkOn) => {
          switch (indexDesc) {
            case 1:
              tableToWorkOn.sort((a, b) => {
                if (a.amount > b.amount) return 1;
                if (a.amount < b.amount) return -1;
                return 0;
              });
              break;
            case 0:
              tableToWorkOn.sort((a, b) => {
                if (Number(a.createdAt) > Number(b.createdAt)) return 1;
                if (Number(a.createdAt) < Number(b.createdAt)) return -1;
                return 0;
              });
              break;
            default:
              break;
          }
        };
        sortTypeDesc(indexDesc, influencersPreparedToRender);
      }
      setSortEvent((s) => s + 1);
      sortInfluencers(influencersPreparedToRender);
    }
  }, [
    sortHelper,
    influencersPreparedToRender,
    sortInfluencers,
    calculatedInfluencers,
  ]);

  useEffect(() => {
    if (influencerSearch) {
      handleOpenPopover(influencerSearch);
    } else {
      handleClosePopover();
    }
  }, [influencerSearch, influencersPreparedToRender, handleOpenPopover]);

  const handleExport = () => {
    window.open(`influencers/export`, '_blank');
  }

  return (
    <Grid className={classes.influencersContent}>
      {loading ? (
        <TrendLoading />
      ) : openPopover ? (
        <InfluencerModal {...{ activeInfluencer }} close={handleClosePopover} />
      ) : (
        <>

          <Backdrop className={classes.overlay} open={loadingDeleteInfluencer}>
            <CircularProgress color='secondary' />
          </Backdrop>
          <ConfirmDeleteDialog
            openState={deleteInfluencerDialogState}
            onClose={deleteInfluencer}
          />

          <Dialog classes={{ paper: classes.menuDialog }} open={openPasswordDialog} onClose={handleClosePasswordDialog} aria-labelledby="form-dialog-title">
            <Grid container item direction='column' alignItems='center' justify="center" style={{ marginTop: '50px' }}>
              <Grid container item direction='column' xs={12} alignItems='center' style={{ marginBottom: '10px' }}>
                <Typography className={classes.dialogTitle}>{t("ADMIN.VIEWS.INFLUENCERS.DIALOG_PASSWORD_TITLE")}</Typography>
              </Grid>
              <Grid container item direction='column' justify='center' alignItems='center' className={classes.formControlRow} xs={11} style={{ marginBottom: '10px' }}>
                <InputBase
                  className={classes.formInput}
                  placeholder="Password"
                  id='password'
                  type='password'
                  onChange={handleValueChange('password')} />
                <Grid container alignItems="flex-start" justify='flex-start' style={{ marginLeft: '25px' }}>
                  {validationError && (
                    <FormHelperText className={classes.errorMessage}>
                      {passwordValidationError}
                    </FormHelperText>
                  )}
                </Grid>
              </Grid>
              <Grid container item direction='row' xs={11} justify='center' >
                <Button className={classes.cancelButton} onClick={handleClosePasswordDialog} style={{ marginRight: (xsDown) ? '0px' : '15px' }} >{t("ADMIN.VIEWS.INFLUENCERS.DIALOG_BUTTON_CANCEL")}</Button>
                <Button className={classes.deleteButton} onClick={verificationPassword} style={(xsDown) ? { marginBottom: '10px' } : { marginBottom: '0' }}>{t("ADMIN.VIEWS.INFLUENCERS.DIALOG_BUTTON_VALIDATE")}</Button>
              </Grid>
            </Grid>
          </Dialog>
          <Grid container justify='flex-end' alignItems='flex-end' style={{ width: '95%' }}>
            <Button className={classes.exportButton} onClick={handleExport}>{t("ADMIN.VIEWS.INFLUENCERS.EXPORT_INFLUECNER.BUTTON_EXPORT")}</Button>
          </Grid>
          <Grid
            container
            item
            direction="row"
            justify="center"
            xs={12}
            className={classes.arraySection}
          >
            <Grid container item direction="row" xs={11}>
              <div style={{ overflowX: "auto", width: "100%" }}>
                <Grid container item style={{ minWidth: "1050px" }} xs={12}>
                  <Grid container item direction="row-reverse" xs={11}>
                    <Grid
                      container
                      item
                      xs={2}
                      justify="center"
                      alignItems="center"
                      className={classes.clickableTableHeader}
                      onClick={() => {
                        sortHelper[0] === 0
                          ? changeSortHelper([1, 0])
                          : changeSortHelper([-sortHelper[0], 0]);
                      }}
                    >
                      <Typography className={classes.tableHeader}>
                        {t("ADMIN.VIEWS.INFLUENCERS.TABLE_HEADER.SIGNUP_DATE")}
                      </Typography>
                      {sortHelper[0] === 1 ? (
                        <ExpandLessIcon className={classes.tableHeaderIcon} />
                      ) : sortHelper[0] === -1 ? (
                        <ExpandMoreIcon className={classes.tableHeaderIcon} />
                      ) : (
                        <SortIcon className={classes.tableHeaderIcon} />
                      )}
                    </Grid>
                    <Grid
                      container
                      item
                      xs={2}
                      justify="center"
                      alignItems="center"
                    >
                      <Typography className={classes.tableHeader}>
                        {t("ADMIN.VIEWS.INFLUENCERS.TABLE_HEADER.AFFILIATE_ID")}
                      </Typography>
                    </Grid>
                    <Grid
                      container
                      item
                      xs={2}
                      justify="center"
                      alignItems="center"
                    >
                      <Typography className={classes.tableHeader}>
                        {t("ADMIN.VIEWS.INFLUENCERS.TABLE_HEADER.PRIVATE")}
                      </Typography>
                    </Grid>
                    <Grid
                      container
                      item
                      xs={2}
                      justify="center"
                      alignItems="center"
                      className={classes.clickableTableHeader}
                      onClick={() => {
                        sortHelper[1] === 0
                          ? changeSortHelper([0, 1])
                          : changeSortHelper([0, -sortHelper[1]]);
                      }}
                    >
                      <Typography className={classes.tableHeader}>
                        {t("ADMIN.VIEWS.INFLUENCERS.TABLE_HEADER.TURNOVER")}
                      </Typography>
                      {sortHelper[1] === 1 ? (
                        <ExpandLessIcon className={classes.tableHeaderIcon} />
                      ) : sortHelper[1] === -1 ? (
                        <ExpandMoreIcon className={classes.tableHeaderIcon} />
                      ) : (
                        <SortIcon className={classes.tableHeaderIcon} />
                      )}
                    </Grid>
                  </Grid>
                  <Grid
                    container
                    item
                    direction="row"
                    xs={1}
                    style={{ visibility: "hidden" }}
                  >
                    zdi
                  </Grid>
                </Grid>
                {influencersToRender.map((influencer, i) => (
                  <Grid
                    container
                    item
                    direction="row"
                    justify="center"
                    className={classes.influencerBoxContainer}
                    key={influencer.key}
                  >
                    <Box className={classes.influencerBox}>
                      <Grid container item direction="row" xs={11}>
                        <Grid
                          container
                          item
                          justify="flex-start"
                          alignItems="center"
                          xs={2}
                        >
                          <img
                            src={influencer.banner}
                            alt=""
                            className={classes.influencerImage}
                          />
                        </Grid>
                        <Grid
                          container
                          item
                          justify="center"
                          alignItems="flex-start"
                          direction="column"
                          xs={2}
                          style={{ paddingLeft: "5px" }}
                        >
                          <Typography
                            className={classes.influencerName}
                            onClick={() => handleOpenPopover(influencer)}
                          >
                            {influencer.name}
                          </Typography>
                          <Typography className={classes.influencerEmail}>
                            {influencer.email}
                          </Typography>
                        </Grid>
                        <Grid
                          container
                          item
                          justify="center"
                          alignItems="center"
                          xs={2}
                        >
                          <Typography className={classes.tableRowContent}>
                            {Math.round(influencer.amount * 100) / 100} €
                          </Typography>
                        </Grid>
                        <Grid
                          container
                          item
                          justify="center"
                          alignItems="center"
                          xs={2}
                        >
                          <Switch
                            checked={influencer.private}
                            onChange={() => handlePrivateChange(influencer, i)}
                            name="private"
                          />
                        </Grid>
                        <Grid
                          container
                          item
                          justify="center"
                          alignItems="center"
                          xs={2}
                        >
                          <Typography className={classes.tableRowContent}>
                            {influencer.affiliate_id}
                          </Typography>
                        </Grid>
                        <Grid
                          container
                          item
                          justify="center"
                          alignItems="center"
                          xs={2}
                        >
                          <Typography className={classes.tableRowContent}>
                            {new Date(
                              influencer.createdAt * 1000
                            ).toLocaleDateString()}
                          </Typography>
                        </Grid>
                      </Grid>
                      <Grid
                        container
                        item
                        justify="center"
                        alignItems="center"
                        xs={1}
                      >
                        <IconButton
                          onClick={(e) => handleOpenMenu(e, influencer)}
                        >
                          <MoreVertIcon />
                        </IconButton>
                        <Menu
                          key={influencer.key}
                          classes={{ paper: classes.menu }}
                          anchorEl={anchorEl}
                          open={Boolean(anchorEl)}
                          onClose={handleCloseMenu}
                        >
                          <MenuItem
                            className={classes.tableHeader}
                            onClick={() => handleOpenConfirmDeleteDialog()}
                          >
                            {t("ADMIN.VIEWS.INFLUENCERS.DELETE_PLACEHOLDER")}
                          </MenuItem>
                        </Menu>
                      </Grid>
                    </Box>
                  </Grid>
                ))}
              </div>
              <Grid
                container
                item
                direction="row-reverse"
                className={classes.paginationRow}
              >
                <Grid
                  container
                  alignItems="center"
                  justify="flex-end"
                  style={{ display: "flex", width: "95%" }}
                >
                  <Select
                    value={size}
                    onChange={handlePageSizeChange}
                    input={<BootstrapInput />}
                    IconComponent={() => <SVGIcon name="miniArrow" />}
                    classes={{ root: classes.paginationStyles }}
                  >
                    <MenuItem value={10} style={{ fontFamily: "Poppins" }}>
                      10
                    </MenuItem>
                    <MenuItem value={25} style={{ fontFamily: "Poppins" }}>
                      25
                    </MenuItem>
                    <MenuItem value={50} style={{ fontFamily: "Poppins" }}>
                      50
                    </MenuItem>
                  </Select>
                  <Typography
                    className={classes.tableHeader}
                    style={{ marginRight: "10px", marginLeft: "10px" }}
                  >
                    {t("ADMIN.VIEWS.INFLUENCERS.PAGINATION_PLACEHOLDER")}{" "}
                    {influencersBeforeRender.length}
                  </Typography>
                  <Pagination
                    count={count}
                    page={page}
                    onChange={handlePageChange}
                    renderItem={(item) => (
                      <PaginationItem
                        classes={{
                          root: classes.paginationClass,
                          selected: classes.selectedPaginationItem,
                        }}
                        {...item}
                      />
                    )}
                  />
                </Grid>
              </Grid>
            </Grid>
          </Grid>
        </>
      )}
    </Grid>
  );
};

const mapStateToProps = (state, ownProps) => {
  const influencers = state.influencersState.influencers;
  const allConversions = state.conversionsState.allConversions;
  const loadedInfluencers = state.influencersState.loadedInfluencers;
  const loadedAllConversions = state.conversionsState.loadedAllConversions;
  const influencersPreparedToRender = state.influencersState.influencersPreparedToRender;
  const calculatedInfluencers = state.influencersState.calculatedInfluencers;
  const loadingDeleteInfluencer = state.influencersState.loadingDeleteInfluencer;
  const appStateSortHelper = state.appState.adminInfluencerState.influencerTableSortHelper;
  const appStatePageNumber = state.appState.adminInfluencerState.influencerTablePageNumber;
  const appStatePageSize = state.appState.adminInfluencerState.influencerTablePageSize;
  return {
    influencers,
    allConversions,
    loadedInfluencers,
    loadedAllConversions,
    influencersPreparedToRender,
    calculatedInfluencers,
    loadingDeleteInfluencer,
    appStateSortHelper,
    appStatePageNumber,
    appStatePageSize
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    updateInfluencerById: (influencerId, updates) =>
      dispatch(updateInfluencerById(influencerId, updates)),
    setInfluencersForRender: (preparedInfluencers) =>
      dispatch({ type: "SET_INFLUENCERS_FOR_RENDER", preparedInfluencers }),
    wishlistsPerInfluencerByIdRequest: () => dispatch({ type: 'FETCH_WICHLISTS_PER_INFLUENCER_REQUEST' }),
    fetchWishlistsPerInfluencer: (influencerId) => dispatch(fetchWishlistsPerInfluencer(influencerId)),
    sortInfluencers: (sortedInfluencers) =>
      dispatch({ type: "SORT_INFLUENCERS", sortedInfluencers }),
    deleteInfluencerById: (influencer, extension) =>
      dispatch(deleteInfluencerById(influencer, extension)),
    changeSortHelper: (sortHelper) =>
      dispatch({ type: 'UPDATE_ADMIN_INFLUENCER_TABLE_SORT_HELPER', sortHelper }),
    changePage: (pageNumber) =>
      dispatch({ type: 'UPDATE_ADMIN_INFLUENCER_TABLE_PAGE_NUMBER', pageNumber }),
    changePageSize: (pageSize) =>
      dispatch({ type: 'UPDATE_ADMIN_INFLUENCER_TABLE_PAGE_SIZE', pageSize }),
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(Influenceurs);
