import React, { useState, useEffect } from "react";
import { Grid, Typography, TextField, Select, MenuItem, InputBase } from "@material-ui/core";
import Autocomplete, { createFilterOptions } from '@material-ui/lab/Autocomplete';
import { makeStyles, withStyles } from "@material-ui/core/styles";
import Paper from "@material-ui/core/Paper";
import IconButton from "@material-ui/core/IconButton";
import SearchIcon from "@material-ui/icons/Search";
import MenuIcon from "@material-ui/icons/Menu";
import { useHistory, useLocation } from "react-router-dom";
import { useTranslation } from "react-i18next";
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import clsx from "clsx";
import { connect } from "react-redux";
import { fetchAllInfluencers } from "../../../../store/actions/influencersActions";

const useStyles = makeStyles((theme) => ({
    headerContainer: {
        marginBottom: '10px',
        flexBasis: 'auto',
        minHeight: 100,
    },
    root: {
        // padding: "2px 4px",
        display: "flex",
        alignItems: "center",
        width: '80%',
        borderRadius: '40px',
        boxShadow: '0 7px 30px 0 rgba(88, 165, 211, 0.16)',
    },
    input: {
        marginLeft: theme.spacing(1),
        flex: 1,
        height: '100%'
    },
    iconButton: {
        padding: 10
    },
    divider: {
        height: 28,
        margin: 4
    },
    avatarContainer: {
        marginTop: '20px',
    },
    avatarSize: {
        width: theme.spacing(10),
        height: theme.spacing(10),
        marginRight: '20px',
    },
    brandName: {
        fontFamily: 'Montserrat',
        fontWeight: 600,
        color: '#000000'
    },
    smallIcon: {
        paddingRight: 10,
        fontFamily: 'Poppins'
    },
    popupSearch: {
        marginTop: '3px',
        width: '100%'
    },
    selectClass: {
        backgroundColor: 'transparent',
        fontFamily: 'Poppins',
        marginTop: '10px',
        fontSize: '24px',
        '&:focus': {
            backgroundColor: 'transparent',
        },
    },
    searchInputInfluenceTab: {
        width: '100%',
        fontFamily: 'Poppins'
    }
}));

const iconStyles = {
    selectIcon: {
        color: "black"
    }
};
const CustomExpandMore = withStyles(iconStyles)(
    ({ className, classes, ...rest }) => {
        return (
            <ExpandMoreIcon
                {...rest}
                className={clsx(className, classes.selectIcon)}
            />
        );
    }
);

const Header = (props) => {
    const { t, i18n } = useTranslation('common');
    const classes = useStyles();
    // const brand = props.brand;
    const url = props.relativeUrl;
    const history = useHistory();
    const location = useLocation();
    const [lang, setLang] = useState(i18n.language);
    const [mobileMode, setMobileMode] = useState(false);
    const [influencers, setInfluencers] = useState([]);
    const [searchValue, setSearchValue] = useState(null);
    const [inputValue, setInputValue] = useState('');
    const openInfluencerInfos = props.openInfluencerInfos;
    const searchInfluencer = props.searchInfluencer;

    const { loadedInfluencers, fetchAllInfluencers, allInfluencers } = props;

    useEffect(() => {
        if (!loadedInfluencers) fetchAllInfluencers();
    }, [loadedInfluencers, fetchAllInfluencers]);

    useEffect(() => {
        if (loadedInfluencers) {
            setInfluencers(allInfluencers.filter(element => element.name !== undefined && element.banner !== "" && element.name !== ""));
        }
    }, [loadedInfluencers, allInfluencers]);

    useEffect(() => {
        setMobileMode(props.mobileMode);
    }, [props.mobileMode]);

    useEffect(() => {
        i18n.changeLanguage(lang);
    }, [lang, i18n]);

    useEffect(() => {
        setLang((i18n.language !== 'fr') ? 'en' : 'fr');
    }, [i18n.language]);


    useEffect(() => {
        if (searchValue !== null) {
            openInfluencerInfos(searchValue);
        }
    }, [searchValue, openInfluencerInfos]);

    const toggleMenu = () => {
        props.opendrawer();
    }

    const filterOptions = createFilterOptions({
        stringify: (option) => option.name + option.email + option.affiliate_id
    });

    useEffect(() => {
        searchInfluencer(inputValue);
    }, [inputValue, searchInfluencer]);

    return (
        <Grid container item xs={12} direction='row' justify='space-around' alignItems='center' className={classes.headerContainer}>
            <Grid container item xs={2} alignItems='center'>
                {mobileMode
                    ? <IconButton onClick={() => toggleMenu()}>
                        <MenuIcon fontSize='large' />
                    </IconButton>
                    : <></>}
            </Grid>
            <Grid container item xs={10} justify='space-around' alignItems='center'>
                <Paper component="form" className={classes.root}>
                    <IconButton
                        className={classes.iconButton}
                        aria-label="search"
                    >
                        <SearchIcon />
                    </IconButton>
                    {
                        (location.pathname === '/admin/influencers')
                            ? <InputBase
                                className={classes.searchInputInfluenceTab}
                                type='text'
                                placeholder={t('ADMIN.HEADER.SEARCH_PLACEHOLDER')}
                                value={inputValue}
                                onChange={(event) => setInputValue(event.target.value)} />
                            : (location.pathname === '/admin/factures') ?
                                <InputBase
                                    className={classes.searchInputInfluenceTab}
                                    type='text'
                                    placeholder={t('ADMIN.HEADER.SEARCH_PLACEHOLDER')}
                                    value={inputValue}
                                    onChange={(event) => setInputValue(event.target.value)} />
                                : (location.pathname === '/admin/partners')
                                    ? <InputBase
                                        className={classes.searchInputInfluenceTab}
                                        type='text'
                                        placeholder={t('ADMIN.HEADER.SEARCH_PLACEHOLDER')}
                                        value={inputValue}
                                        onChange={(event) => setInputValue(event.target.value)} />
                                    :
                                    <Autocomplete
                                        value={searchValue}
                                        onChange={(event, newValue) => {
                                            setSearchValue(newValue);
                                            history.push(`${url}/influencers`);
                                        }}
                                        inputValue={inputValue}
                                        onInputChange={(event, newInputValue) => {
                                            setInputValue(newInputValue);
                                        }}
                                        filterOptions={filterOptions}
                                        options={influencers}
                                        getOptionLabel={(option) => option.name}
                                        renderOption={(option) => (
                                            <Grid container item md={12} justify='center'>
                                                <Grid container item md={2} xs={12}>
                                                    <img alt={option.name} src={option.banner} height='32px' width='64px' style={{ borderRadius: '26px' }} />
                                                </Grid>
                                                <Grid container item md={10} xs={12} alignItems='center'>
                                                    <Typography style={{ fontFamily: 'Poppins' }}>{option.name}</Typography>
                                                </Grid>
                                            </Grid>
                                        )}
                                        className={classes.input}
                                        classes={{
                                            popper: classes.popupSearch,
                                        }}
                                        renderInput={(params) => (
                                            <TextField
                                                placeholder={t('ADMIN.HEADER.SEARCH_PLACEHOLDER')}
                                                {...params}
                                                InputProps={{ ...params.InputProps, type: 'search', disableUnderline: true, endAdornment: false, className: classes.smallIcon }}
                                            />
                                        )}
                                    />
                    }

                </Paper>
                {
                    mobileMode
                        ? <></>
                        : <Select
                            value={lang}
                            disableUnderline
                            onChange={(e) => setLang(e.target.value)}
                            classes={{ root: classes.selectClass }}
                            IconComponent={CustomExpandMore}>
                            <MenuItem value='fr' style={{ fontFamily: 'Poppins' }}>FR</MenuItem>
                            <MenuItem value='en' style={{ fontFamily: 'Poppins' }}>EN</MenuItem>
                        </Select>
                }
            </Grid>
            {/* <Grid container direction='row' item xs={3} className={classes.avatarContainer} alignItems='center' justify='center'>
                <Avatar className={classes.avatarSize} alt={(influencerAgent) ? influencerAgent.name: '' } src={(influencerAgent) ? influencerAgent.image : ''} />
                <Typography className={classes.brandName}>{(influencerAgent) ? influencerAgent.name : ''}</Typography>
            </Grid> */}
        </Grid>
    );
}

const mapStateToProps = (state, ownProps) => {
    const loadedInfluencers = state.influencersState.loadedInfluencers;
    const allInfluencers = state.influencersState.influencers;
    return {
        loadedInfluencers,
        allInfluencers
    }
}
const mapDispatchToProps = (dispatch) => {
    return {
        fetchAllInfluencers: () => dispatch(fetchAllInfluencers()),
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(Header);