import axios from "axios";

// Relativer Dependencies
import { urlMaker } from "../utils";

// Component Scope
import { auth,database } from "./firebase";
import { dbManager } from ".";

const env = process.env.REACT_APP_STAGE;
const apiUrl = (env === 'prod') ? "https://us-central1-shopmyinfluens.cloudfunctions.net" : "http://localhost:5001/shopmyinfluens/us-central1"

const userManager = {
  // Firebase userManager function url
  url: urlMaker("userManager"),
  /**
   * @desc Get user object from firebase auth
   * @param {string} uid User UID
   */
  getUser: (uid) => {
    return auth.currentUser
      .getIdToken(true)
      .then((idToken) =>
        axios.post(`${userManager.url}/getUser`,{
          user: uid,
          idToken: idToken,
        })
      )
      .then((res) => res.data)
      .catch((err) => console.log("getClaims error : ",err));
  },
  /**
   * @desc Get claims from userRecord on server
   * @param {string} uid User ID
   * @return {object} User claims
   */
  getClaims: (uid) => {
    return axios
      .post(`${userManager.url}/getClaims`,{
        uid: uid,
      })
      .then((res) => res.data.customClaims)
      .catch((err) => console.log("getClaims error : ",err));
  },
  /**
   * @desc Get claims from userObject
   * @param {object} user User object from firebase auth
   * @return {object} User claims
   */
  getUserClaims: (user) => {
    return user
      .getIdTokenResult()
      .then((idTokenResult) => idTokenResult.claims)
      .catch((err) => console.log(err));
  },
  /**
   * @desc Add a claim to a user
   * @param {string} uid User UID
   * @param {string} claim Claim to add to user
   */
  addClaim: (uid,claim) => {
    return auth.currentUser
      .getIdToken(true)
      .then((idToken) =>
        axios.post(`${userManager.url}/addClaim`,{
          idToken: idToken,
          uid: uid,
          claim: claim,
        })
      )
      .catch((err) => console.log("addClaim error : ",err));
  },
  addArrayClaim: (uid,arrayClaimName,arrayClaim) => {
    return auth.currentUser.getIdToken(true).then((idToken) => {
      axios.post(`${userManager.url}/addArrayClaim`,{
        idToken: idToken,
        uid: uid,
        arrayClaimName: arrayClaimName,
        arrayClaim: arrayClaim,
      });
    });
  },
  addClaimWithValue: (uid,claim,claimValue) => {
    return auth.currentUser.getIdToken(true).then((idToken) => {
      return axios.post(`${userManager.url}/addClaimWithValue`,{
        idToken: idToken,
        uid: uid,
        claim: claim,
        claimValue: claimValue,
      });
    });
  },
  /**
   * @desc Remove a claim from user
   * @param {string} uid User UID
   * @param {string} claim Claim to remove from user
   */
  removeClaim: (uid,claim) => {
    return auth.currentUser
      .getIdToken(true)
      .then((idToken) =>
        axios.post(`${userManager.url}/removeClaim`,{
          idToken: idToken,
          uid: uid,
          claim: claim,
        })
      )
      .catch((err) => console.log("removeClaim error : ",err));
  },
  /**
   * @desc Remove all claims from user
   * @param {string} uid User UID
   */
  removeAllClaims: (uid) => {
    auth.currentUser
      .getIdToken(true)
      .then((idToken) =>
        axios.post(`${userManager.url}/removeAllClaims`,{
          idToken: idToken,
          uid: uid,
        })
      )
      .catch((err) => console.log("removeClaim error : ",err));
  },
  /**
   * @desc Valid user mail
   * @param {array} uids Array of user UIDs
   */
  validMails: (uids) => {
    return auth.currentUser
      .getIdToken(true)
      .then((idToken) =>
        axios.post(`${userManager.url}/validMails`,{
          idToken: idToken,
          uids: uids,
        })
      )
      .then((res) => res.data)
      .catch((err) => console.log("removeClaim error : ",err));
  },
  /**
   * @desc Create user from influencer
   * @param {string} uid User id
   */
  influencerToUser: (uid) => {
    dbManager.fetchInfluencer(uid).then((inf) => {
      database.ref(`/users/${uid}`).update({
        email: inf.Profil.email,
      });
    });
  },

  createBrandAgentTestUser: (brandAgent) => {
    auth
      .createUserWithEmailAndPassword(brandAgent.email,brandAgent.password)
      .then((authUser) => {
        return database
          .ref("brandsAgent/" + authUser.user.uid)
          .push({
            userId: authUser.user.uid,
            email: brandAgent.email,
            name: brandAgent.name,
            image: brandAgent.image,
            brandsIds: brandAgent.brandsIds,
            verified: false,
            createdAt: Math.round(new Date().now() / 1000),
          })
          .then((res) => console.log(res))
          .catch((err) => console.log(err));
      })
      .catch((err) => {
        console.log(err);
      });
  },

  addBrandTestUser: (brandAgent) => {
    let date = new Date();
    let newRef = database.ref("brandAgents").push();
    return newRef
      .set({
        userId: brandAgent.userId,
        email: brandAgent.email,
        name: brandAgent.name,
        image: brandAgent.image,
        verified: false,
        createdAt: Math.round(date.now() / 1000),
      })
      .then((res) => console.log(res))
      .catch((err) => console.log(err));
  },

  addInfluencerAgentTestUser: (influencerAgent) => {
    let date = Date;
    let newRef = database.ref("influencerAgents").push();
    return newRef.set({
      userId: influencerAgent.userId,
      email: influencerAgent.email,
      name: influencerAgent.name,
      image: influencerAgent.image,
      verified: false,
      createdAt: Math.round(date.now() / 1000),
    });
  },

  createBrandAgentUser: async (userInformations,userCredentials) => {
    return await auth
      .createUserWithEmailAndPassword(
        userCredentials.email,
        userCredentials.password
      )
      .then(async (user) => {
        if (userInformations.picture !== "") {
          // Cloudinary image saving
          const cloudinaryPostRequestBody = {
            image: userInformations.picture,
            userType: "brandAgent",
            public_id: userInformations.email,
          };
          await axios
            .post(
              "https://us-central1-shopmyinfluens.cloudfunctions.net/firebaseStorage/storage/upload",
              cloudinaryPostRequestBody
            )
            .then(async (cloudinaryResult) => {
              // Firebse Data saving
              let date = new Date();
              const createdAt = Math.round(date.getTime() / 1000);
              let dataToSave = {
                banner: cloudinaryResult.data.url,
                email: userInformations.email,
                name: userInformations.name,
                website: userInformations.website,
                agencyName: userInformations.agencyName,
                uid: user.user.uid,
                createdAt: createdAt.toString(),
                verfied: false,
              };
              let newRef = await database
                .ref("unverifiedAccounts/brandAgents")
                .child(user.user.uid);
              return await newRef.set(dataToSave);
            })
            .catch((error) => {
              // UPLOAD IMAGE ERROR
              console.log("imageUploadError",error);
            });
        } else {
          // Firebse Data saving
          let date = new Date();
          const createdAt = Math.round(date.getTime() / 1000);
          let dataToSave = {
            email: userInformations.email,
            name: userInformations.name,
            website: userInformations.website,
            agencyName: userInformations.agencyName,
            createdAt: createdAt.toString(),
            verfied: false,
          };
          let newRef = await database
            .ref("unverifiedAccounts/brandAgents")
            .child(user.user.uid);
          return await newRef.set(dataToSave);
        }
      });
  },
  createInfluencerAgentUser: async (userInformations,userCredentials) => {
    return await auth
      .createUserWithEmailAndPassword(
        userCredentials.email,
        userCredentials.password
      )
      .then(async (user) => {
        if (userInformations.picture !== "") {
          // Cloudinary image saving
          const cloudinaryPostRequestBody = {
            image: userInformations.picture,
            userType: "influencerAgent",
            public_id: userInformations.email,
          };
          await axios
            .post(
              "https://us-central1-shopmyinfluens.cloudfunctions.net/firebaseStorage/storage/upload",
              cloudinaryPostRequestBody
            )
            .then(async (cloudinaryResult) => {
              // Firebse Data saving
              let date = new Date();
              const createdAt = Math.round(date.getTime() / 1000);
              let dataToSave = {
                banner: cloudinaryResult.data.url,
                email: userInformations.email,
                name: userInformations.name,
                website: userInformations.website,
                agencyName: userInformations.agencyName,
                createdAt: createdAt.toString(),
                uid: user.user.uid,
                verfied: false,
              };
              if (userInformations.address !== "")
                dataToSave["address"] = userInformations.address;
              if (userInformations.siret !== "")
                dataToSave["siret"] = userInformations.siret;
              let newRef = await database
                .ref("unverifiedAccounts/influencerAgents")
                .child(user.user.uid);
              return await newRef.set(dataToSave);
            })
            .catch((error) => {
              // UPLOAD IMAGE ERROR
              console.log("imageUploadError",error);
            });
        } else {
          // Firebse Data saving
          let date = new Date();
          const createdAt = Math.round(date.getTime() / 1000);
          let dataToSave = {
            email: userInformations.email,
            name: userInformations.name,
            website: userInformations.website,
            agencyName: userInformations.agencyName,
            createdAt: createdAt.toString(),
            verfied: false,
          };
          if (userInformations.address !== "")
            dataToSave["address"] = userInformations.address;
          if (userInformations.siret !== "")
            dataToSave["siret"] = userInformations.siret;
          let newRef = await database
            .ref("unverifiedAccounts/influencerAgents")
            .child(user.user.uid);
          return await newRef.set(dataToSave);
        }
      });
  },
  createInfluencerUser: async (userInformations,userCredentials) => {
    // Firebase User Creation
    return await auth
      .createUserWithEmailAndPassword(
        userCredentials.email,
        userCredentials.password
      )
      .then(async (user) => {
        // Affise profile creation
        const affiliatePostRequestBody = {
          email: userInformations.email,
          status: "active",
          name: userInformations.username,
        };
        await axios
          .post(
            "https://us-central1-shopmyinfluens.cloudfunctions.net/globalApi/affiliate/create",
            affiliatePostRequestBody
          )
          .then(async (affiseAffiliateResult) => {
            const postbackPostRequestBody = {
              pid: affiseAffiliateResult.data.partner.id,
              url: "https://shopmyinfluence.fr/",
              status: "confirmed",
            };
            await axios.post(
              "https://us-central1-shopmyinfluens.cloudfunctions.net/globalApi/affiliate/postback",
              postbackPostRequestBody
            );
            // Cloudinary image saving
            const cloudinaryPostRequestBody = {
              image: userInformations.picture,
              userType: "influencer",
              public_id: userInformations.email,
            };
            await axios
              .post(
                "https://us-central1-shopmyinfluens.cloudfunctions.net/firebaseStorage/storage/upload",
                cloudinaryPostRequestBody
              )
              .then(async (cloudinaryResult) => {
                // Firebse Data saving
                let date = new Date();
                const createdAt = Math.round(date.getTime() / 1000);
                const code33 =
                  user.user.uid.substr(0,3) + parseInt(createdAt % 1000);
                let dataToSave = {
                  affiliate_id: affiseAffiliateResult.data.partner.id,
                  banner: cloudinaryResult.data.url,
                  email: userInformations.email,
                  realName: userInformations.name,
                  name: userInformations.username,
                  country: userInformations.country,
                  private: true,
                  score: 0,
                  createdAt: createdAt.toString(),
                  code33: code33,
                  verfied: true,
                };
                if (userInformations.favBrands.length !== 0)
                  dataToSave["favouriteBrands"] = userInformations.favBrands.map(brand => brand.offerId);
                if (userInformations.favBrands.length !== 0)
                  dataToSave["univers"] = userInformations.univers;
                if (Object.keys(userInformations.socialNetworks).length !== 0)
                  dataToSave["socialMedias"] = {};
                Object.entries(userInformations.socialNetworks).forEach(
                  ([key,value]) => {
                    dataToSave.socialMedias[key] = value;
                  }
                );
                let newRef = await database.ref("influencers").child(user.user.uid);
                return await newRef.set(dataToSave);
              })
              .catch((error) => {
                //Affiliate creation ERROR
                console.log("affiliateError",error);
              });
          })
          .catch((error) => {
            // IMAGE SAVING ERROR
          });
      })
      .then(() => {
        auth.currentUser
          .sendEmailVerification()
          .then(() => { })
          .catch((error) => { });
      });
  },
  createBrandUser: async (brandInfos,userInformations,userCredentials,lang) => {
    return await auth
      .createUserWithEmailAndPassword(
        userCredentials.email,
        userCredentials.password
      )
      .then(async (user) => {
        let brandRef = await database.ref("brands").push();
        let tempBrandRef = await database
          .ref("unverifiedAccounts/brands")
          .child(brandRef.key);
        let date = new Date();
        const createdAt = Math.round(date.getTime() / 1000);
        let dataToSave = {
          website: brandInfos.website,
          name: brandInfos.brandName,
          categorie: brandInfos.categ,
          siret: brandInfos.siret,
          createdAt: createdAt.toString(),
          verfied: false,
          private: true,
          conditionsAndTerms: true,
          brandUser: {
            firstName: userInformations.firstName,
            lastName: userInformations.lastName,
            email: userInformations.email,
            brandId: brandRef.key,
            uid: user.user.uid,
          },
        };
        lang.includes("fr")
          ? (dataToSave["description"] = brandInfos.desc)
          : (dataToSave["description-en"] = brandInfos.desc);
        const deliveryCountry = brandInfos.deliveryCountry
          .map((item) => item.code)
          .join(" ");
        dataToSave["deliveryCountry"] = deliveryCountry;
        if (brandInfos.hasAffProg === true) {
          dataToSave["hasAffProg"] = true;
          dataToSave["platform"] = brandInfos.platform;
        }
        if (brandInfos.address !== "")
          dataToSave["address"] = brandInfos.address;
        await tempBrandRef.set(dataToSave);
      });
  },
  createUser: (userType,userInformation,lang) => {
    let userInfos;
    let userCredentials;
    let responsibleUserInfos;
    switch (userType) {
      case "brandAgent": {
        userInfos = {
          name:
            userInformation.stepTwoBrandAgent.nom +
            " " +
            userInformation.stepTwoBrandAgent.prenom,
          agencyName: userInformation.stepTwoBrandAgent.agencyName,
          email: userInformation.stepTwoBrandAgent.email,
          picture: userInformation.stepThreeBrandAgent.base64Logo,
          website: userInformation.stepThreeBrandAgent.website,
        };
        userCredentials = {
          email: userInformation.stepTwoBrandAgent.email,
          password: userInformation.stepTwoBrandAgent.password,
        };
        return userManager.createBrandAgentUser(userInfos,userCredentials);
      }
      case "influencerAgent": {
        userInfos = {
          name:
            userInformation.stepTwoInfluencerAgent.nom +
            " " +
            userInformation.stepTwoInfluencerAgent.prenom,
          agencyName: userInformation.stepTwoInfluencerAgent.agencyName,
          email: userInformation.stepTwoInfluencerAgent.email,
          picture: userInformation.stepThreeInfluencerAgent.base64Logo,
          website: userInformation.stepThreeInfluencerAgent.website,
          siret: userInformation.stepThreeInfluencerAgent.siret,
          address: userInformation.stepThreeInfluencerAgent.address,
        };
        userCredentials = {
          email: userInformation.stepTwoInfluencerAgent.email,
          password: userInformation.stepTwoInfluencerAgent.password,
        };
        return userManager.createInfluencerAgentUser(
          userInfos,
          userCredentials
        );
      }
      case "influencer": {
        userInfos = {
          name:
            userInformation.stepTwoInfluencer.nom +
            " " +
            userInformation.stepTwoInfluencer.prenom,
          username: userInformation.stepTwoInfluencer.username,
          email: userInformation.stepTwoInfluencer.email,
          country: userInformation.stepTwoInfluencer.country,
          picture: userInformation.stepThreeInfluencer.base64Logo,
          favBrands: userInformation.stepThreeInfluencer.favBrands,
          univers: userInformation.stepThreeInfluencer.univers,
        };
        userInfos["socialNetworks"] = {};
        userInformation.stepThreeInfluencer.socialNetworks.forEach(
          (socialNetwork) => {
            userInfos.socialNetworks[socialNetwork.socialNetworkType] =
              socialNetwork.socialNetworkValue;
          }
        );
        userCredentials = {
          email: userInformation.stepTwoInfluencer.email,
          password: userInformation.stepTwoInfluencer.password,
        };
        return userManager.createInfluencerUser(userInfos,userCredentials);
      }
      case "brand": {
        userInfos = {
          brandName: userInformation.stepTwoBrand.brandName,
          website: userInformation.stepThreeBrand.website,
          desc: userInformation.stepThreeBrand.description,
          deliveryCountry: userInformation.stepThreeBrand.countryDelivery,
          categ: userInformation.stepThreeBrand.categories,
          siret: userInformation.stepThreeBrand.siret,
          address: userInformation.stepThreeBrand.address,
          hasAffProg: (userInformation.stepThreeBrand.isAffProg === "") ? false : userInformation.stepThreeBrand.isAffProg,
        };
        if (userInfos.hasAffProg) {
          userInfos["platform"] = userInformation.stepThreeBrand.platform;
        }
        responsibleUserInfos = {
          lastName: userInformation.stepTwoBrand.nom,
          firstName: userInformation.stepTwoBrand.prenom,
          email: userInformation.stepTwoBrand.email,
        };
        userCredentials = {
          email: userInformation.stepTwoBrand.email,
          password: userInformation.stepTwoBrand.password,
        };
        return userManager.createBrandUser(
          userInfos,
          responsibleUserInfos,
          userCredentials,
          lang
        );
      }
      default:
        return null;
    }
  },
  deleteInfluencer: (influencer,extension) => {
    return axios.post(
      `${apiUrl}/userManager/deleteInfluencer`,
      {
        pid: influencer.affiliate_id,
        uid: influencer.key,
        email: influencer.email + extension,
      }
    );
  },
  deleteBrand: (brandId,brandName,uid,extension) => {
    return axios.post(apiUrl + '/userManager/deleteBrand',{
      name: brandName + extension,
      brandId: brandId,
      uid: uid
    });
  },
  deleteUser: (uid,offerId) => {
    return axios.post(apiUrl + '/userManager/deleteUser',{
      offerId: offerId,
      uid: uid
    });
  },
  createBrandUserFromAdmin: (brandToAdd,userCredentials) => {
    return axios.post(apiUrl + '/brands/create',userCredentials).then(async (user) => {
      let newRef = await database.ref('brands').push();
      let brandToReturn = null;
      let errorToReturn = null;
      let finalBrand = Object.assign(brandToAdd,{ 'creatorUser': user.data.user.uid });
      await newRef.set(finalBrand,(error) => {
        if (error) {
          errorToReturn = error
        } else {
          brandToReturn = {
            ...finalBrand,
            key: newRef.key,
            creatorUser: user.data.user.uid,
          }
        }
      });
      if (errorToReturn) {
        return errorToReturn;
      } else {
        return brandToReturn;
      };
    });
  },
  getAllStatsOfInfluencers: async () => {
    return await axios.get(apiUrl + '/influencerStats/stats/influencers/exportation').then(async (result) => {
      return result.data;
    })
  },
  getAllStatsOfBrands: async () => {
    return await axios.get(apiUrl + '/brandsStats/stats/brands/exportation').then(async (result) => {
      return result.data;
    })
  },
  createUserForBrand: (userCredentials) => {
    return axios.post(apiUrl + '/brands/create',userCredentials).then(async (user) => {
      let errorToReturn = null;
      if (errorToReturn) {
        return errorToReturn;
      } else {
        return user.data.user.uid;
      };
    });
  },
};

export default userManager;
