import React, { useState } from 'react';
import { Grid, makeStyles, Typography } from "@material-ui/core";
import AgentsTable from "../../template/components/AgentTables/AgentsTable";
import AgentToValidateTable from "../../template/components/AgentTables/AgentToValidateTable";
import EditAgentModal from "../../template/components/EditAgentModal";
import { useTranslation } from "react-i18next";

const useStyles = makeStyles((theme) => ({
    agentsContent: {
        flex: '1 1 auto',
        backgroundColor: "#eff9fe",
    },
    agentSelectorBox: {
        width: '300px',
        padding: '20px',
        backgroundColor: '#ffffff',
        margin: '15px',
        boxShadow: '0 7px 30px 0 rgba(88, 165, 211, 0.16)',
        borderRadius: '35px',
        cursor: 'pointer'
    },
    agentSelectorText: {
        fontFamily: 'Poppins',
        fontWeight: 'bold'
    },
    activeAgentCategoryBox: {
        width: '300px',
        padding: '20px',
        backgroundColor: '#3eadef',
        margin: '15px',
        boxShadow: '0 7px 30px 0 rgba(88, 165, 211, 0.16)',
        borderRadius: '35px',
        cursor: 'pointer'
    },
    activeAgentSelectorText: {
        color: '#fff',
        fontFamily: 'Poppins',
        fontWeight: 'bold'
    },
    overlay: {
        zIndex: theme.zIndex.drawer + 1
    },

}));

const Agents = (props) => {
    const classes = useStyles();
    const { t } = useTranslation("common");
    const { filterValue } = props;

    const [selectedCategory, setSelectedCategory] = useState("agent");
    const [editMode, setEditMode] = useState(false);
    const [agentToEdit, setAgentToEdit] = useState(null);
    const [agentToValidate, setAgentToValidate] = useState(null);

    const handleCategory = (categoryType) => {
        setSelectedCategory(categoryType);
    }
    const handleEditAgent = (brand) => {
        setAgentToValidate(null);
        setAgentToEdit(brand);
        setEditMode(true);
    }

    const handleValidateBrandAgent = (agent) => {
        setAgentToEdit(null);
        setAgentToValidate(agent);
        setEditMode(true);
    }


    const handleCloseModal = () => {
        setAgentToValidate(null);
        setAgentToEdit(null);
        setEditMode(false);
    }
    const getTable = (selectedCategory) => {
        switch (selectedCategory) {
            case "agent":
                return <AgentsTable searchValue={filterValue} openEditAgent={(agent) => handleEditAgent(agent)} />;
            case "agentToValidate":
                return <AgentToValidateTable searchValue={filterValue} openValidateAgent={(agent) => handleValidateBrandAgent(agent)} />;
            default:
                return <></>
        }
    }
    const tableToRender = getTable(selectedCategory);
    return (
        <Grid className={classes.agentsContent}>
            <Grid container item direction='row' xs={12} justify='center' alignItems='flex-start' style={{ height: '100%' }}>
                <Grid container item direction='row' xs={11} alignItems='center' justify='flex-start'>
                    <Grid
                        className={(selectedCategory === 'agent') ? classes.activeAgentCategoryBox : classes.agentSelectorBox}
                        container
                        justify='center'
                        onClick={() => handleCategory('agent')}>
                        <Typography className={(selectedCategory === 'agent') ? classes.activeAgentSelectorText : classes.agentSelectorText}>
                            {t('ADMIN.VIEWS.PARTNERS.AGENTS.AGENT_BOX_TITLE')}
                        </Typography>
                    </Grid>
                    <Grid
                        className={(selectedCategory === 'agentToValidate') ? classes.activeAgentCategoryBox : classes.agentSelectorBox}
                        container
                        justify='center'
                        onClick={() => handleCategory('agentToValidate')}>
                        <Typography className={(selectedCategory === 'agentToValidate') ? classes.activeAgentSelectorText : classes.agentSelectorText}>
                            {t('ADMIN.VIEWS.PARTNERS.AGENTS.AGENT_TO_VALIDATE_BOX_TITLE')}
                        </Typography>
                    </Grid>
                </Grid>

                {
                    (editMode)
                        ? <EditAgentModal agentToEdit={agentToEdit} agentToValidate={agentToValidate} closeModal={handleCloseModal} />
                        : tableToRender
                }

            </Grid>
        </Grid>
    )
}

export default Agents;