import React, { useEffect, useState, useRef } from "react";
import { Grid, makeStyles, withStyles, useTheme, useMediaQuery, Typography, Button, CircularProgress, Input, Select, MenuItem } from "@material-ui/core";
import Table from "@material-ui/core/Table";
import TableBody from "@material-ui/core/TableBody";
import TableCell from "@material-ui/core/TableCell";
import TableHead from "@material-ui/core/TableHead";
import TableRow from "@material-ui/core/TableRow";
import TablePagination from '@material-ui/core/TablePagination';
import Paper from '@material-ui/core/Paper';
import TableContainer from '@material-ui/core/TableContainer';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import { useTranslation } from "react-i18next";
import { userManager } from "../../managers";
import { LoadingScreen } from "../../ui";
import countriesDB from '../template/assets/utils/countries';
import { CSVLink } from "react-csv";
import clsx from "clsx";

const useStyles = makeStyles((theme) => ({
    body: {
        flexGrow: 1,
        minHeight: "100vh",
        backgroundSize: "100vw 100%",
        minWidth: "100%",
        backgroundColor: "#eff9fe",

    },
    logo: {
        display: 'flex',
        alignItems: 'center',
        width: "250px",
        height: "100px"
    },
    espace: {
        marginTop: "50px"
    },
    formatDate: {
        fontFamily: 'Poppins',
    },
    exportButton: {
        marginTop: '25px',
        padding: '6px 8px',
        minWidth: "110px",
        minHeight: "35px",
        color: '#fff',
        fontFamily: 'Poppins',
        textTransform: "inherit",
        boxShadow: "0px 0px 0px 0px #77bb41",
        whiteSpace: "nowrap",
        backgroundColor: '#77bb41',
        '&:hover': {
            backgroundColor: '#77bb41'
        }
    },
    arraySection: {
        marginTop: "50px",
        marginBottom: "20px",
    },
    root: {
        width: '100%',
    },
    container: {
        maxHeight: 700,
    },
    tableCell: {
        minWidth: 50,
        fontFamily: "poppins"
    },
    overlay: {
        zIndex: theme.zIndex.drawer + 1
    },
    caption: {
        fontFamily: "poppins"
    },
    rootPagination: {
        fontFamily: "poppins",
        overflowX: 'hidden'
    },
    selectClass: {
        backgroundColor: 'transparent',
        fontFamily: 'Poppins',
        marginTop: '10px',
        paddingLeft: "25px",
        fontSize: '24px',
        '&:focus': {
            backgroundColor: 'transparent',
        },
    },
}));


const useSearchInputStyles = makeStyles(theme => ({
    root: {
        overflow: "hidden",
        borderRadius: 24,
        backgroundColor: "#fff",
        //margin: 5,
        //marginTop: 25,
        width: '95%',
        maxWidth: '600px',
        boxShadow: '0 7px 30px 0 rgba(88, 165, 211, 0.16)',
        "&:hover": {
            backgroundColor: "#fff"
        },
        "&$focused": {
            backgroundColor: "#fff",
            borderColor: "#ff005f"
        }
    },
    input: {
        fontFamily: 'Poppins',
        fontWeight: 500,
        paddingLeft: 15
    },
    focused: {}
}));

const iconStyles = {
    selectIcon: {
        color: "black"
    }
};

const CustomExpandMore = withStyles(iconStyles)(
    ({ className, classes, ...rest }) => {
        return (
            <ExpandMoreIcon
                {...rest}
                className={clsx(className, classes.selectIcon)}
            />
        );
    }
);

const ExporterInfluenceurs = (props) => {
    const { t, i18n } = useTranslation('common');
    const classes = useStyles();
    const theme = useTheme();
    const searchInputClasses = useSearchInputStyles();
    const xsDown = useMediaQuery(theme.breakpoints.down("xs"));
    const mdDown = useMediaQuery(theme.breakpoints.down("md"));
    const date = new Date().toLocaleDateString();
    const headers = [
        { label: "Nom", key: "name" },
        { label: "Email", key: "email" },
        { label: "Date de l'inscription ", key: "date" },
        { label: "Nombre clicks", key: "totalClicks" },
        { label: "Amount", key: "amount" },
        { label: "Ventes", key: "SalesNumber" },
        { label: "Commissions", key: "commissions" },
        { label: "Wishlists Nombre", key: "wishlistNumber" },
        { label: "Product Nombre", key: "ProductsNbr" },
        { label: "Country", key: "country" },
    ];
    const csvLinkEl = useRef();

    const [loading, setLoading] = useState(false);
    const [loadingArray, setLoadingArray] = useState(false);
    const [page, setPage] = useState(0);
    const [size, setSize] = useState(50);
    const [filterValue, setFilterValue] = useState("");
    const [statsInfluencersApi, setStatsInfluencersApi] = useState([]);
    const [influencersToRender, setInfluencersToRender] = useState([]);
    const [lang, setLang] = useState(i18n.language);

    useEffect(() => {
        setLang((i18n.language !== 'fr') ? 'en' : 'fr');
    }, [i18n.language]);

    useEffect(() => {
        i18n.changeLanguage(lang);
    }, [lang, i18n]);


    useEffect(() => {
        setLoading(true)
        userManager.getAllStatsOfInfluencers().then((result) => {
            setStatsInfluencersApi(result);
            setLoading(false);
        })
    }, []);


    const handlePageChange = (event, value) => {
        setPage(value);
    };

    const handlePageSizeChange = (event) => {
        setSize(+event.target.value);
        setPage(0);
    };

    const downloadReport = async () => {
        csvLinkEl.current.link.click();
    }

    useEffect(() => {
        if (statsInfluencersApi) {
            setLoadingArray(true)
            let temp;
            if (filterValue === "") {
                temp = statsInfluencersApi.filter(
                    (influencer) => influencer.name !== ""
                );
                setInfluencersToRender(temp);
            } else {
                temp = statsInfluencersApi.filter((influencer) => {
                    if (
                        influencer.name !== undefined &&
                        influencer.email !== undefined
                    )
                        return (
                            influencer.name.toLowerCase().includes(filterValue.toLowerCase()) ||
                            influencer.email.toLowerCase().includes(filterValue.toLowerCase())
                        );
                    return false;
                });
                setInfluencersToRender(temp);
            }
            temp.sort((a, b) => {
                if (a.amount > b.amount) return -1;
                if (a.amount < b.amount) return 1;
                return 0;
            });
            setInfluencersToRender(temp.slice(page * size, page * size + size));
            setLoadingArray(false)
        }
    }, [statsInfluencersApi, page, size, filterValue]);

    const inputChange = (event) => {
        setFilterValue(event.target.value);
    }

    return (
        <>
            {loading ?
                <LoadingScreen />
                :
                <Grid className={classes.body}>
                    <Grid container item xs={12} direction="row" justify="center" alignItems="center" className={classes.espace}>
                        <Grid container item alignItems="center" justify="space-evenly" xs={11}>
                            <Grid container item lg={2} xs={12} direction="column" justify={xsDown ? "center" : mdDown ? "center" : "flex-start"} alignItems={xsDown ? "center" : mdDown ? "center" : "flex-start"}>
                                <img alt="logo" className={classes.logo} src="https://firebasestorage.googleapis.com/v0/b/shopmyinfluens.appspot.com/o/shopmyinfluens%2FApp%20Data%2Flogo_shopmyinfluence-01.png?alt=media" />
                                <Typography className={classes.formatDate} style={(xsDown) ? {} : { marginLeft: "6px" }}>Date : {date}</Typography>
                            </Grid>
                            <Grid container item lg={8} xs={12} justify="center" alignItems="center" style={{ marginTop: "25px" }}>
                                <Input classes={searchInputClasses} disableUnderline placeholder={t("ADMIN.VIEWS.INFLUENCERS.EXPORT_INFLUECNER.PLACEHOLDER_SEARCH")} onChange={inputChange} style={(xsDown) ? { maxWidth: '300px' } : { maxWidth: '600px' }} />
                                <Select
                                    value={lang}
                                    disableUnderline
                                    onChange={(e) => setLang(e.target.value)}
                                    classes={{ root: classes.selectClass }}
                                    IconComponent={CustomExpandMore}>
                                    <MenuItem value='fr' style={{ fontFamily: 'Poppins' }}>FR</MenuItem>
                                    <MenuItem value='en' style={{ fontFamily: 'Poppins' }}>EN</MenuItem>
                                </Select>
                            </Grid>
                            <Grid container item lg={2} xs={12} justify={xsDown ? "center" : mdDown ? "center" : "flex-end"} alignItems={xsDown ? "center" : mdDown ? "center" : "flex-end"}>
                                <Button className={classes.exportButton} onClick={downloadReport} >{t("ADMIN.VIEWS.INFLUENCERS.EXPORT_INFLUECNER.BUTTON_DOWNLOAD_CSV")}</Button>
                                <CSVLink
                                    headers={headers}
                                    filename="statsInfluencer.csv"
                                    data={statsInfluencersApi}
                                    ref={csvLinkEl}
                                    separator={","}
                                />
                            </Grid>
                        </Grid>
                        {loadingArray ?
                            <CircularProgress color='secondary' className={classes.overlay} />
                            :
                            <Grid container item alignItems="center" justify="center" xs={11} className={classes.arraySection}>
                                <div style={{ overflowX: "auto", width: "100%" }}>
                                    <Grid container item style={{ minWidth: "1050px" }} xs={12}>
                                        <Paper className={classes.root}>
                                            <TableContainer className={classes.container}>
                                                <Table stickyHeader>
                                                    <TableHead>
                                                        <TableRow>
                                                            <TableCell align="center" className={classes.tableCell}>{t("ADMIN.VIEWS.INFLUENCERS.EXPORT_INFLUECNER.TABLE_HEADER_EXPORT.NAME")}</TableCell>
                                                            <TableCell align="center" className={classes.tableCell}>{t("ADMIN.VIEWS.INFLUENCERS.EXPORT_INFLUECNER.TABLE_HEADER_EXPORT.Email")}</TableCell>
                                                            <TableCell align="center" className={classes.tableCell}>{t("ADMIN.VIEWS.INFLUENCERS.EXPORT_INFLUECNER.TABLE_HEADER_EXPORT.DATE")}</TableCell>
                                                            <TableCell align="center" className={classes.tableCell}>{t("ADMIN.VIEWS.INFLUENCERS.EXPORT_INFLUECNER.TABLE_HEADER_EXPORT.CLICKS")}</TableCell>
                                                            <TableCell align="center" className={classes.tableCell}>{t("ADMIN.VIEWS.INFLUENCERS.EXPORT_INFLUECNER.TABLE_HEADER_EXPORT.AMOUNT")}</TableCell>
                                                            <TableCell align="center" className={classes.tableCell}>{t("ADMIN.VIEWS.INFLUENCERS.EXPORT_INFLUECNER.TABLE_HEADER_EXPORT.SALES_NUMBER")}</TableCell>
                                                            <TableCell align="center" className={classes.tableCell}>{t("ADMIN.VIEWS.INFLUENCERS.EXPORT_INFLUECNER.TABLE_HEADER_EXPORT.COMMISSIONS")}</TableCell>
                                                            <TableCell align="center" className={classes.tableCell}>{t("ADMIN.VIEWS.INFLUENCERS.EXPORT_INFLUECNER.TABLE_HEADER_EXPORT.WISHLIST")}</TableCell>
                                                            <TableCell align="center" className={classes.tableCell}>{t("ADMIN.VIEWS.INFLUENCERS.EXPORT_INFLUECNER.TABLE_HEADER_EXPORT.PRODUCT")}</TableCell>
                                                            <TableCell align="center" className={classes.tableCell}>{t("ADMIN.VIEWS.INFLUENCERS.EXPORT_INFLUECNER.TABLE_HEADER_EXPORT.COUNTRY")}</TableCell>
                                                        </TableRow>
                                                    </TableHead>
                                                    <TableBody >
                                                        {influencersToRender.map((influencer, key) => {
                                                            return (
                                                                <TableRow hover role="checkbox" tabIndex={-1} key={key}>

                                                                    <TableCell align="center" className={classes.tableCell}>{influencer.name}</TableCell>
                                                                    <TableCell align="center" className={classes.tableCell}>{influencer.email}</TableCell>
                                                                    <TableCell align="center" className={classes.tableCell}>{influencer.date}</TableCell>
                                                                    <TableCell align="center" className={classes.tableCell}>{influencer.totalClicks}</TableCell>
                                                                    <TableCell align="center" className={classes.tableCell}>{influencer.amount}</TableCell>
                                                                    <TableCell align="center" className={classes.tableCell}>{influencer.SalesNumber}</TableCell>
                                                                    <TableCell align="center" className={classes.tableCell}>{influencer.commissions}</TableCell>
                                                                    <TableCell align="center" className={classes.tableCell}>{influencer.wishlistNumber}</TableCell>
                                                                    <TableCell align="center" className={classes.tableCell}>{influencer.ProductsNbr}</TableCell>
                                                                    <TableCell align="center" className={classes.tableCell}>
                                                                        {
                                                                            countriesDB.filter(element => influencer.country === element.code)[0] === undefined ?
                                                                                '' :
                                                                                countriesDB.filter(element => influencer.country === element.code)[0].nameFr
                                                                        }
                                                                    </TableCell>
                                                                </TableRow>
                                                            )
                                                        })}

                                                    </TableBody>
                                                </Table>
                                            </TableContainer>
                                            <TablePagination
                                                classes={{
                                                    root: classes.rootPagination,
                                                    caption: classes.caption,
                                                    select: classes.caption
                                                }}
                                                rowsPerPageOptions={[50, 100, 150]}
                                                component="div"
                                                count={statsInfluencersApi.length}
                                                rowsPerPage={size}
                                                labelRowsPerPage={t("ADMIN.VIEWS.INFLUENCERS.EXPORT_INFLUECNER.TABLE_HEADER_EXPORT.ROWS_PER_PAGE")}
                                                page={page}
                                                onChangePage={handlePageChange}
                                                onChangeRowsPerPage={handlePageSizeChange}
                                            />
                                        </Paper>
                                    </Grid>
                                </div>
                            </Grid>
                        }
                    </Grid>
                </Grid>
            }
        </>
    )

}
export default ExporterInfluenceurs;