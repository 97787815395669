import React, { useState, useEffect, useCallback } from "react";
import { makeStyles } from "@material-ui/styles";
import { database, dbManager } from "../../managers";
import { Grid, Typography, useTheme, useMediaQuery } from "@material-ui/core";
import { useTranslation } from "react-i18next";
import SVGIcon from "../template/assets/SVGIcons/index";
import EuroSymbolIcon from "@material-ui/icons/EuroSymbol";
import TrendLoading from "../../ui/trendLoading/TrendLoading";
import { useTransition, animated } from "react-spring";
import NotificationDialog from "../template/components/notificationDialog";
import ThreeDots from "../template/assets/contentLoader/ThreeDots";
import countriesDB from "../template/assets/utils/countries";
import { connect } from "react-redux";

const useStyles = makeStyles((theme) => {
  return {
    dashboardContent: {
      width: "100%",
      flexGrow: 1,
      display: "flex",
      flexDirection: "column",
    },
    smallBox: {
      height: "150px",
      width: "260px",
      borderRadius: "20px",
      display: "flex",
      justifyContent: "space-around",
      alignItems: "center",
      paddingTop: "30px",
      paddingBottom: "30px",
      boxShadow: "0 7px 30px 0 rgba(88, 165, 211, 0.16)",
      margin: 10,
      backgroundColor: "#fff",
    },
    boxTitle: {
      fontFamily: "Poppins",
      fontSize: "16px",
      fontWeight: 500,
      overflow: "hidden",
      textOverflow: "ellipsis",
    },
    boxText: {
      fontFamily: "Poppins",
      fontSize: "16px",
      fontWeight: 900,
      overflow: "hidden",
      textOverflow: "ellipsis",
    },
    twoSectionRow: {
      marginTop: "30px",
      marginBottom: "30px",
    },
    sectionTitle: {
      fontFamily: "Poppins",
      fontWeight: 500,
      marginTop: "10px",
      marginBottom: "10px",
    },
    influencerRow: {
      borderRadius: "41px",
      backgroundColor: "#fff",
      boxShadow: "0 7px 30px 0 rgba(88, 165, 211, 0.16)",
      marginRight: 20,
      // marginLeft: 20,
      marginTop: 10,
      marginBottom: 10,
      height: "120px",
      minWidth: "500px",
      width: "100%",
    },
    tableRow: {
      borderRadius: "41px",
      backgroundColor: "#fff",
      boxShadow: "0 7px 30px 0 rgba(88, 165, 211, 0.16)",
      marginRight: 20,
      marginLeft: 20,
      marginTop: 10,
      marginBottom: 10,
      height: "50px",
      minWidth: "500px",
    },
    influencerPicture: {
      height: 120,
      width: 240,
      objectFit: "fit",
      borderTopLeftRadius: "41px",
      borderBottomLeftRadius: "41px",
    },
    influencerLinkBox: {
      display: "flex",
      flexGrow: 1,
      height: "100%",
      justifyContent: "center",
      alignItems: "center",
    },
    influencerLink: {
      fontFamily: "Poppins",
      // textDecoration: 'underline',
      // '&:hover': {
      //     cursor: 'pointer'
      // }
    },
    tableHeader: {
      marginLeft: 20,
      marginRight: 20,
    },
    tableColOne: {
      fontFamily: "Poppins",
      paddingLeft: "20px",
    },
    tableColTwo: {
      fontFamily: "Poppins",
    },
    tableColThree: {
      fontFamily: "Poppins",
      paddingRight: "40px",
    },
  };
});

const Dashboard = (props) => {
  const { t, i18n } = useTranslation("common");

  const { brands, loadedBrands } = props;

  const classes = useStyles();
  const theme = useTheme();
  const xsDown = useMediaQuery(theme.breakpoints.down("xs"));
  const [allConversions, setAllConversions] = useState([]);
  const [allInfluencers, setAllInfluencers] = useState([]);
  const [
    lastFiveRegistredInfluencers,
    setLastFiveRegisteredInfluencers,
  ] = useState([]);
  const [topTenInfluencers, setTopTenInfluencers] = useState([]);
  const [topFiveBrands, setTopFiveBrands] = useState([]);
  const [topFiveCountries, setTopFiveCountries] = useState([]);
  const [totalAmount, setTotalAmount] = useState(0);
  const [totalCommissions, setTotalCommissions] = useState(0);
  const [allConversionsLoaded, setAllConversionsLoaded] = useState(false);
  const [allInfluencersLoaded, setAllInfluencersLoaded] = useState(false);
  const [initialDataLoaded, setInitialDataLoaded] = useState(false);
  const [allDataLoaded, setAllDataLoaded] = useState(false);
  const [newConversion, setNewConversion] = useState(null);
  const [notificationInfos, setNotificationInfos] = useState(null);
  const [totalCommissionsSmi, setTotalCommissionsSmi] = useState(0);

  const height = 0;

  const transitions = useTransition(
    lastFiveRegistredInfluencers.map((data, i) => ({ ...data, y: i * height })),
    (d) => d.key,
    {
      from: { position: "relative", opacity: 0 },
      leave: { height: 0, opacity: 0 },
      enter: ({ y }) => ({ y, opacity: 1 }),
      update: ({ y }) => ({ y }),
    }
  );

  const fetchNewConversionDetails = async (conversion) => {
    const influencerInfos = await dbManager.fetchInfluencerById(
      conversion.influencer
    );
    let brandInfos = {};
    await dbManager.fetchBrandByOfferId(conversion.offerId).then((brand) => {
      brandInfos = brand;
    });
    setNotificationInfos({
      ...conversion,
      brandInfos,
      influencerInfos,
    });
  };

  const filterInfluencer = useCallback(
    (conversion, index, influencersIds) => {
      if (index === 0) {
        const data = allInfluencers.filter(
          (influencer) => influencer.key === conversion.influencer
        )[0];
        influencersIds.push({
          influencerId: conversion.influencer,
          amount: conversion.amount,
          salesNumber: 1,
          products: [conversion.articleId],
          commission: Number(conversion.commission),
          ...data,
        });
      } else {
        let incremented = false;
        for (let j = 0; j < influencersIds.length; j++) {
          if (influencersIds[j].influencerId === conversion.influencer) {
            influencersIds[j].amount += conversion.amount;
            influencersIds[j].commission += Number(conversion.commission);
            influencersIds[j].salesNumber++;
            if (influencersIds[j].products.indexOf(conversion.articleId) === -1)
              influencersIds[j].products.push(conversion.articleId);
            incremented = true;
            break;
          }
        }
        if (!incremented) {
          const data = allInfluencers.filter(
            (influencer) => influencer.key === conversion.influencer
          )[0];
          influencersIds.push({
            influencerId: conversion.influencer,
            amount: conversion.amount,
            commission: Number(conversion.commission),
            products: [conversion.articleId],
            salesNumber: 1,
            product: 0,
            ...data,
          });
        }
      }
    },
    [allInfluencers]
  );

  const filterBrand = useCallback(
    async (conversion, index, brandIds) => {
      if (conversion.offerId !== undefined) {
        if (index === 0) {
          let data = brands.filter(
            (brand) => brand.offerId === conversion.offerId
          )[0];
          brandIds.push({
            amount: conversion.amount,
            commissionsAmount: Number(conversion.commission),
            purchaseNumber: 1,
            offerId: conversion.offerId,
            ...data,
          });
        } else {
          let incremented = false;
          for (let j = 0; j < brandIds.length; j++) {
            if (brandIds[j].offerId === conversion.offerId) {
              brandIds[j].purchaseNumber++;
              brandIds[j].amount += conversion.amount;
              brandIds[j].commissionsAmount += Number(conversion.commission);
              incremented = true;
              break;
            }
          }
          if (!incremented) {
            let data = brands.filter(
              (brand) => brand.offerId === conversion.offerId
            )[0];
            brandIds.push({
              amount: conversion.amount,
              commissionsAmount: Number(conversion.commission),
              purchaseNumber: 1,
              offerId: conversion.offerId,
              ...data,
            });
          }
        }
      }
    },
    [brands]
  );

  const filterCountry = (conversion, index, countryIds) => {
    if (index === 0) {
      countryIds.push({
        countryCode: conversion.countryCode,
        articleIds: [conversion.articleId],
        amount: conversion.amount,
        commissions: Number(conversion.commission),
        purchaseNumber: 1,
      });
    } else {
      let incremented = false;
      for (let j = 0; j < countryIds.length; j++) {
        if (countryIds[j].countryCode === conversion.countryCode) {
          countryIds[j].purchaseNumber++;
          countryIds[j].amount += Number(conversion.amount);
          countryIds[j].commissions += Number(conversion.commission);
          if (countryIds[j].articleIds.indexOf(conversion.articleId) === -1)
            countryIds[j].articleIds.push(conversion.articleId);
          incremented = true;
          break;
        }
      }
      if (!incremented) {
        countryIds.push({
          countryCode: conversion.countryCode,
          articleIds: [conversion.articleId],
          amount: conversion.amount,
          commissions: Number(conversion.commission),
          purchaseNumber: 1,
        });
      }
    }
  };

  const filter = useCallback(async () => {
    if (loadedBrands) {
      let influencersIds = [];
      let countryIds = [];
      let brandIds = [];

      for (let i = 0; i < allConversions.length; i++) {
        filterInfluencer(allConversions[i], i, influencersIds);
        filterCountry(allConversions[i], i, countryIds);
        await filterBrand(allConversions[i], i, brandIds);
      }

      influencersIds.sort((a, b) => {
        if (a.amount > b.amount) return -1;
        if (a.amount < b.amount) return 1;
        return 0;
      });

      countryIds.sort((a, b) => {
        let aAmountNumber = Number(a.amount);
        let bAmountNumber = Number(b.amount);
        if (aAmountNumber > bAmountNumber) return -1;
        if (aAmountNumber < bAmountNumber) return 1;
        return 0;
      });

      brandIds.sort((a, b) => {
        let aAmountNumber = Number(a.amount);
        let bAmountNumber = Number(b.amount);
        if (aAmountNumber > bAmountNumber) return -1;
        if (aAmountNumber < bAmountNumber) return 1;
        return 0;
      });

      setTopTenInfluencers(
        influencersIds
          .slice(0, 10)
          .filter(
            (element) =>
              element.name !== undefined &&
              element.name !== null &&
              element.name !== ""
          )
      );
      setTopFiveCountries(countryIds.slice(0, 5));
      setTopFiveBrands(brandIds.slice(0, 5));
    }
  }, [allConversions, filterInfluencer, loadedBrands, filterBrand]);

  useEffect(() => {
    database.ref("conversions/purchase").on("value", (snap) => {
      const data = snap.val();
      let conversions = [];
      data &&
        Object.values(data).forEach((d, i) => {
          conversions.push({
            ...d,
            key: Object.keys(data)[i],
          });
        });
      conversions.sort((a, b) => {
        let aCreation = Number(a.createdAt);
        let bCreation = Number(b.createdAt);
        if (aCreation > bCreation) return -1;
        if (aCreation < bCreation) return 1;
        return 0;
      });
      setAllConversions((prevState) => {
        let temp = Object.values(prevState);
        if (prevState.length !== 0) {
          temp = temp.filter((item) => item.key === conversions[0].key);
          if (temp.length === 0) setNewConversion(conversions[0]);
        }
        return conversions;
      });
      setAllConversionsLoaded(true);
    });
    database.ref("influencers").on("value", (snap) => {
      const data = snap.val();
      let influencers = [];
      data &&
        Object.values(data).forEach((d, i) => {
          influencers.push({
            ...d,
            key: Object.keys(data)[i],
          });
        });
      influencers.sort((a, b) => {
        let aCreation = Number(a.createdAt);
        let bCreation = Number(b.createdAt);
        if (aCreation > bCreation) return -1;
        if (aCreation < bCreation) return 1;
        return 0;
      });
      setAllInfluencers(influencers);
      setAllInfluencersLoaded(true);
    });
    return () => {
      database.ref("conversions/purchase").off();
      database.ref("influencers").off();
    };
  }, []);

  useEffect(() => {
    if (allConversionsLoaded && allInfluencersLoaded) {
      setInitialDataLoaded(true);
    }
  }, [allConversionsLoaded, allInfluencersLoaded]);

  useEffect(() => {
    if (initialDataLoaded) {
      let amount = 0;
      let commission = 0;
      let commissionAffiliate = 0;
      let commissionSmi = 0;
      allConversions.forEach((conversion) => {
        amount += Number(conversion.amount);
        if (conversion.commission !== undefined && conversion.commissionAffiliate !== undefined) {
          commission += Number(conversion.commission);
          commissionAffiliate += Number(conversion.commissionAffiliate);
        }
      });

      commissionSmi = commission - commissionAffiliate;
      setTotalAmount(amount);
      setTotalCommissions(commission);
      setTotalCommissionsSmi(commissionSmi);
      setLastFiveRegisteredInfluencers(allInfluencers.slice(0, 5));

      filter().then(() => {
        setAllDataLoaded(true);
      });
    }
  }, [initialDataLoaded, allConversions, allInfluencers, filter]);

  useEffect(() => {
    if (newConversion) {
      fetchNewConversionDetails(newConversion);
    }
  }, [newConversion]);

  return (
    <Grid className={classes.dashboardContent}>
      <NotificationDialog notificationInfos={notificationInfos} />
      <Grid
        container
        item
        direction="row"
        wrap="wrap"
        xs={12}
        justify="space-around"
        alignItems="center"
      >
        <Grid container className={classes.smallBox}>
          {initialDataLoaded ? (
            <>
              <Grid container item xs={4} justify="center">
                <EuroSymbolIcon
                  style={{ fontSize: "60px", color: "#ef7e3e" }}
                />
              </Grid>
              <Grid container item xs={7} justify="center" alignItems="center">
                <Grid item xs={12}>
                  <Typography className={classes.boxTitle}>
                    {t("ADMIN.VIEWS.DASHBOARD.TURNOVER_BOX_TITLE")}
                  </Typography>
                </Grid>
                <Grid item xs={12}>
                  <Typography className={classes.boxText}>
                    {Math.round(parseFloat(totalAmount) * 100) / 100} €
                  </Typography>
                </Grid>
              </Grid>
            </>
          ) : (
            <TrendLoading />
          )}
        </Grid>
        <Grid container className={classes.smallBox}>
          {initialDataLoaded ? (
            <>
              <Grid container item xs={4} justify="center">
                <SVGIcon name="ventes" width={"60px"} height={"60px"} />
              </Grid>
              <Grid container item xs={7} justify="center" alignItems="center">
                <Grid item xs={12}>
                  <Typography className={classes.boxTitle}>
                    {t("ADMIN.VIEWS.DASHBOARD.SALES_BOX_TITLE")}
                  </Typography>
                </Grid>
                <Grid item xs={12}>
                  <Typography className={classes.boxText}>
                    {allConversions.length}
                  </Typography>
                </Grid>
              </Grid>
            </>
          ) : (
            <TrendLoading />
          )}
        </Grid>
        <Grid container className={classes.smallBox}>
          {initialDataLoaded ? (
            <>
              <Grid container item xs={4} justify="center">
                <SVGIcon
                  name="nombreInfluenceurs"
                  width={"55px"}
                  height={"55px"}
                />
              </Grid>
              <Grid container item xs={7} justify="center" alignItems="center">
                <Grid item xs={12}>
                  <Typography className={classes.boxTitle}>
                    {t("ADMIN.VIEWS.DASHBOARD.INFLUENCERS_BOX_TITLE")}
                  </Typography>
                </Grid>
                <Grid item xs={12}>
                  <Typography className={classes.boxText}>
                    {allInfluencers.length}
                  </Typography>
                </Grid>
              </Grid>
            </>
          ) : (
            <TrendLoading />
          )}
        </Grid>
        <Grid container className={classes.smallBox}>
          {initialDataLoaded ? (
            <>
              <Grid container item xs={4} justify="center" alignItems="center">
                <SVGIcon name="commissionPaye" width={"55px"} height={"55px"} />
              </Grid>
              <Grid container item xs={7} justify="center" alignItems="center">
                <Grid item xs={12}>
                  <Typography className={classes.boxTitle}>
                    {t("ADMIN.VIEWS.DASHBOARD.PAID_COMMISSIONS_BOX_TITLE")}
                  </Typography>
                </Grid>
                <Grid item xs={12}>
                  <Typography className={classes.boxText}>0 €</Typography>
                </Grid>
              </Grid>
            </>
          ) : (
            <TrendLoading />
          )}
        </Grid>

        <Grid container className={classes.smallBox}>
          {initialDataLoaded ? (
            <>
              <Grid container item xs={4} justify="center" alignItems="center">
                <SVGIcon name="commission" width={"55px"} height={"55px"} />
              </Grid>
              <Grid container item xs={7} justify="center" alignItems="center">
                <Grid item xs={12}>
                  <Typography className={classes.boxTitle}>
                    {t("ADMIN.VIEWS.DASHBOARD.UNPAID_COMMISSIONS_BOX_TITLE")}
                  </Typography>
                </Grid>
                <Grid item xs={12}>
                  <Typography className={classes.boxText}>
                    {Math.round(totalCommissions * 100) / 100} €
                  </Typography>
                </Grid>
              </Grid>
            </>
          ) : (
            <TrendLoading />
          )}
        </Grid>

        <Grid container className={classes.smallBox}>
          {initialDataLoaded ? (
            <>
              <Grid container item xs={4} justify="center" alignItems="center">
                <SVGIcon name="commission" width={"55px"} height={"55px"} />
              </Grid>
              <Grid container item xs={7} justify="center" alignItems="center">
                <Grid item xs={12}>
                  <Typography className={classes.boxTitle}>
                    {t("ADMIN.VIEWS.DASHBOARD.COMMISSIONS_SMI_BOX_TITLE")}
                  </Typography>
                </Grid>
                <Grid item xs={12}>
                  <Typography className={classes.boxText}>
                    {Math.round(totalCommissionsSmi * 100) / 100} €
                  </Typography>
                </Grid>
              </Grid>
            </>
          ) : (
            <TrendLoading />
          )}
        </Grid>
      </Grid>
      <Grid
        container
        item
        direction="row"
        xs={12}
        className={classes.twoSectionRow}
      >
        <Grid container item direction="row" justify="center" md={6} sm={12}>
          <Typography
            className={classes.sectionTitle}
            style={{
              width: "100%",
              marginLeft: 40,
              fontWeight: "bold",
              fontSize: "20px",
            }}
          >
            {t(
              "ADMIN.VIEWS.DASHBOARD.LAST_FIVE_REGISTERED_INFLUENCERS_SECTION.SECTION_HEADER"
            )}
          </Typography>
          {allInfluencersLoaded ? (
            <Grid
              container
              style={{ width: "100%", overflowX: "auto", overflowY: "hidden" }}
            >
              {transitions.map(
                ({ item, props: { y, ...rest }, key }, index) => (
                  <animated.div
                    key={item.key}
                    style={{
                      transform: y.interpolate(
                        (y) => `translate3d(0, ${y}px, 0)`
                      ),
                      width: "inherit",
                      marginLeft: "20px",
                      ...rest,
                    }}
                  >
                    <Grid
                      container
                      item
                      direction="row"
                      justify="center"
                      alignItems="center"
                      className={classes.influencerRow}
                      key={item.key}
                    >
                      <img
                        alt={item.name}
                        src={item.banner}
                        className={classes.influencerPicture}
                      />
                      <div className={classes.influencerLinkBox}>
                        <Typography className={classes.influencerLink}>
                          {item.name}
                        </Typography>
                      </div>
                    </Grid>
                  </animated.div>
                )
              )}
            </Grid>
          ) : (
            <TrendLoading />
          )}
        </Grid>
        <Grid container item direction="row" justify="center" md={6} sm={12}>
          <Grid container alignItems="center" item xs={12}>
            <Typography
              className={classes.sectionTitle}
              style={{
                marginLeft: 40,
                marginBottom: 0,
                fontWeight: "bold",
                fontSize: "20px",
              }}
            >
              {t(
                "ADMIN.VIEWS.DASHBOARD.TOP_TEN_INFLUENCERS_SECTION.TOP_TEN_INFLUENCERS"
              )}
            </Typography>
          </Grid>
          <Grid container style={{ width: "100%", overflowX: "auto" }}>
            <Grid container style={{ minWidth: "500px" }}>
              <Grid
                container
                item
                xs={12}
                direction="row-reverse"
                className={classes.tableHeader}
                style={xsDown ? { marginRight: 0 } : {}}
              >
                <Grid
                  container
                  alignItems="center"
                  justify="center"
                  item
                  xs={3}
                >
                  <Typography
                    className={classes.sectionTitle}
                    style={{ marginTop: 0, marginBottom: 0 }}
                  >
                    {t(
                      "ADMIN.VIEWS.DASHBOARD.TOP_TEN_INFLUENCERS_SECTION.SECTION_TABLE_HEADERS.SALES"
                    )}
                  </Typography>
                </Grid>
                <Grid
                  container
                  alignItems="center"
                  justify="center"
                  item
                  xs={3}
                >
                  <Typography
                    className={classes.sectionTitle}
                    style={{ marginTop: 0, marginBottom: 0 }}
                  >
                    {t(
                      "ADMIN.VIEWS.DASHBOARD.TOP_TEN_INFLUENCERS_SECTION.SECTION_TABLE_HEADERS.TURNOVER"
                    )}
                  </Typography>
                </Grid>
              </Grid>
              {allDataLoaded ? (
                topTenInfluencers.map((influencer) => (
                  <Grid
                    container
                    item
                    direction="row"
                    alignItems="center"
                    className={classes.tableRow}
                    key={influencer.key}
                  >
                    <Grid container item xs={6}>
                      <Typography className={classes.tableColOne}>
                        {influencer.name}
                      </Typography>
                    </Grid>
                    <Grid container item xs={3} justify="center">
                      <Typography className={classes.tableColTwo}>
                        {Math.round(influencer.amount * 100) / 100} €
                      </Typography>
                    </Grid>
                    <Grid container item xs={3} justify="flex-end">
                      <Typography className={classes.tableColThree}>
                        {influencer.salesNumber}
                      </Typography>
                    </Grid>
                  </Grid>
                ))
              ) : (
                <>
                  <Grid
                    container
                    item
                    direction="row"
                    alignItems="center"
                    className={classes.tableRow}
                  >
                    <ThreeDots style={{ height: "inherit", width: "100%" }} />
                  </Grid>
                  <Grid
                    container
                    item
                    direction="row"
                    alignItems="center"
                    className={classes.tableRow}
                  >
                    <ThreeDots style={{ height: "inherit", width: "100%" }} />
                  </Grid>
                  <Grid
                    container
                    item
                    direction="row"
                    alignItems="center"
                    className={classes.tableRow}
                  >
                    <ThreeDots style={{ height: "inherit", width: "100%" }} />
                  </Grid>
                  <Grid
                    container
                    item
                    direction="row"
                    alignItems="center"
                    className={classes.tableRow}
                  >
                    <ThreeDots style={{ height: "inherit", width: "100%" }} />
                  </Grid>
                  <Grid
                    container
                    item
                    direction="row"
                    alignItems="center"
                    className={classes.tableRow}
                  >
                    <ThreeDots style={{ height: "inherit", width: "100%" }} />
                  </Grid>
                  <Grid
                    container
                    item
                    direction="row"
                    alignItems="center"
                    className={classes.tableRow}
                  >
                    <ThreeDots style={{ height: "inherit", width: "100%" }} />
                  </Grid>
                  <Grid
                    container
                    item
                    direction="row"
                    alignItems="center"
                    className={classes.tableRow}
                  >
                    <ThreeDots style={{ height: "inherit", width: "100%" }} />
                  </Grid>
                  <Grid
                    container
                    item
                    direction="row"
                    alignItems="center"
                    className={classes.tableRow}
                  >
                    <ThreeDots style={{ height: "inherit", width: "100%" }} />
                  </Grid>
                  <Grid
                    container
                    item
                    direction="row"
                    alignItems="center"
                    className={classes.tableRow}
                  >
                    <ThreeDots style={{ height: "inherit", width: "100%" }} />
                  </Grid>
                  <Grid
                    container
                    item
                    direction="row"
                    alignItems="center"
                    className={classes.tableRow}
                  >
                    <ThreeDots style={{ height: "inherit", width: "100%" }} />
                  </Grid>
                </>
              )}
            </Grid>
          </Grid>
        </Grid>
      </Grid>
      <Grid
        container
        item
        direction="row"
        xs={12}
        className={classes.twoSectionRow}
      >
        <Grid container item direction="row" justify="center" md={6} sm={12}>
          <Grid container alignItems="center" item xs={12}>
            <Typography
              className={classes.sectionTitle}
              style={{
                marginLeft: 40,
                marginBottom: 0,
                fontWeight: "bold",
                fontSize: "20px",
              }}
            >
              {t(
                "ADMIN.VIEWS.DASHBOARD.TOP_FIVE_BRANDS_SECTION.TOP_FIVE_BRANDS"
              )}
            </Typography>
          </Grid>
          <Grid container style={{ width: "100%", overflowX: "auto" }}>
            <Grid container style={{ minWidth: "500px" }}>
              <Grid
                container
                item
                direction="row-reverse"
                xs={12}
                className={classes.tableHeader}
                style={xsDown ? { marginRight: 0 } : {}}
              >
                <Grid
                  container
                  alignItems="center"
                  justify="center"
                  item
                  xs={3}
                >
                  <Typography
                    className={classes.sectionTitle}
                    style={{ marginTop: 0, marginBottom: 0 }}
                  >
                    {t(
                      "ADMIN.VIEWS.DASHBOARD.TOP_FIVE_BRANDS_SECTION.SECTION_TABLE_HEADERS.SALES"
                    )}
                  </Typography>
                </Grid>
                <Grid
                  container
                  alignItems="center"
                  justify="center"
                  item
                  xs={3}
                >
                  <Typography
                    className={classes.sectionTitle}
                    style={{ marginTop: 0, marginBottom: 0 }}
                  >
                    {t(
                      "ADMIN.VIEWS.DASHBOARD.TOP_FIVE_BRANDS_SECTION.SECTION_TABLE_HEADERS.TURNOVER"
                    )}
                  </Typography>
                </Grid>
              </Grid>
              {allDataLoaded ? (
                topFiveBrands.map((brand) => (
                  <Grid
                    container
                    item
                    direction="row"
                    alignItems="center"
                    className={classes.tableRow}
                    key={brand.key}
                  >
                    <Grid container item xs={6}>
                      <Typography className={classes.tableColOne}>
                        {brand.displayName}
                      </Typography>
                    </Grid>
                    <Grid container item xs={3} justify="center">
                      <Typography className={classes.tableColTwo}>
                        {Math.round(brand.amount * 100) / 100} €
                      </Typography>
                    </Grid>
                    <Grid container item xs={3} justify="flex-end">
                      <Typography className={classes.tableColThree}>
                        {brand.purchaseNumber}
                      </Typography>
                    </Grid>
                  </Grid>
                ))
              ) : (
                <>
                  <Grid
                    container
                    item
                    direction="row"
                    alignItems="center"
                    className={classes.tableRow}
                  >
                    <ThreeDots style={{ height: "inherit", width: "100%" }} />
                  </Grid>
                  <Grid
                    container
                    item
                    direction="row"
                    alignItems="center"
                    className={classes.tableRow}
                  >
                    <ThreeDots style={{ height: "inherit", width: "100%" }} />
                  </Grid>
                  <Grid
                    container
                    item
                    direction="row"
                    alignItems="center"
                    className={classes.tableRow}
                  >
                    <ThreeDots style={{ height: "inherit", width: "100%" }} />
                  </Grid>
                  <Grid
                    container
                    item
                    direction="row"
                    alignItems="center"
                    className={classes.tableRow}
                  >
                    <ThreeDots style={{ height: "inherit", width: "100%" }} />
                  </Grid>
                  <Grid
                    container
                    item
                    direction="row"
                    alignItems="center"
                    className={classes.tableRow}
                  >
                    <ThreeDots style={{ height: "inherit", width: "100%" }} />
                  </Grid>
                </>
              )}
            </Grid>
          </Grid>
        </Grid>
        <Grid container item direction="row" justify="center" md={6} sm={12}>
          <Grid container alignItems="center" item xs={12}>
            <Typography
              className={classes.sectionTitle}
              style={{
                marginLeft: 40,
                marginBottom: 0,
                fontWeight: "bold",
                fontSize: "20px",
              }}
            >
              {t(
                "ADMIN.VIEWS.DASHBOARD.TOP_FIVE_COUNTRIES_SECTION.TOP_FIVE_COUNTRIES"
              )}
            </Typography>
          </Grid>
          <Grid container style={{ width: "100%", overflowX: "auto" }}>
            <Grid container style={{ minWidth: "500px" }}>
              <Grid
                container
                item
                direction="row-reverse"
                xs={12}
                className={classes.tableHeader}
                style={xsDown ? { marginRight: 0 } : {}}
              >
                <Grid
                  container
                  alignItems="center"
                  justify="center"
                  item
                  xs={3}
                >
                  <Typography
                    className={classes.sectionTitle}
                    style={{ marginTop: 0, marginBottom: 0 }}
                  >
                    {t(
                      "ADMIN.VIEWS.DASHBOARD.TOP_FIVE_COUNTRIES_SECTION.SECTION_TABLE_HEADERS.SALES"
                    )}
                  </Typography>
                </Grid>
                <Grid
                  container
                  alignItems="center"
                  justify="center"
                  item
                  xs={3}
                >
                  <Typography
                    className={classes.sectionTitle}
                    style={{ marginTop: 0, marginBottom: 0 }}
                  >
                    {t(
                      "ADMIN.VIEWS.DASHBOARD.TOP_FIVE_COUNTRIES_SECTION.SECTION_TABLE_HEADERS.TURNOVER"
                    )}
                  </Typography>
                </Grid>
              </Grid>
              {allDataLoaded ? (
                topFiveCountries.map((country) => (
                  <Grid
                    container
                    item
                    direction="row"
                    alignItems="center"
                    className={classes.tableRow}
                    key={country.countryCode}
                  >
                    <Grid container item xs={6}>
                      <Typography className={classes.tableColOne}>
                        {
                          (i18n.language === 'fr-FR' || i18n.language === 'fr')
                            ? countriesDB.filter((element) => country.countryCode === element.code)[0].nameFr
                            : countriesDB.filter((element) => country.countryCode === element.code)[0].name
                        }
                      </Typography>
                    </Grid>
                    <Grid container item xs={3} justify="center">
                      <Typography className={classes.tableColTwo}>
                        {Math.round(country.amount * 100) / 100} €
                      </Typography>
                    </Grid>
                    <Grid container item xs={3} justify="flex-end">
                      <Typography className={classes.tableColThree}>
                        {country.purchaseNumber}
                      </Typography>
                    </Grid>
                  </Grid>
                ))
              ) : (
                <>
                  <Grid
                    container
                    item
                    direction="row"
                    alignItems="center"
                    className={classes.tableRow}
                  >
                    <ThreeDots style={{ height: "inherit", width: "100%" }} />
                  </Grid>
                  <Grid
                    container
                    item
                    direction="row"
                    alignItems="center"
                    className={classes.tableRow}
                  >
                    <ThreeDots style={{ height: "inherit", width: "100%" }} />
                  </Grid>
                  <Grid
                    container
                    item
                    direction="row"
                    alignItems="center"
                    className={classes.tableRow}
                  >
                    <ThreeDots style={{ height: "inherit", width: "100%" }} />
                  </Grid>
                  <Grid
                    container
                    item
                    direction="row"
                    alignItems="center"
                    className={classes.tableRow}
                  >
                    <ThreeDots style={{ height: "inherit", width: "100%" }} />
                  </Grid>
                  <Grid
                    container
                    item
                    direction="row"
                    alignItems="center"
                    className={classes.tableRow}
                  >
                    <ThreeDots style={{ height: "inherit", width: "100%" }} />
                  </Grid>
                </>
              )}
            </Grid>
          </Grid>
        </Grid>
      </Grid>
    </Grid>
  );
};

const mapStateToProps = (state, ownProps) => {
  const brands = state.brandsState.allBrands;
  const loadedBrands = state.brandsState.loadedBrands;
  return {
    brands,
    loadedBrands,
  };
};

export default connect(mapStateToProps)(Dashboard);
