import { Grid, makeStyles, Typography, IconButton, Select, Menu, MenuItem, CircularProgress, Dialog } from '@material-ui/core';
import { Pagination, PaginationItem } from '@material-ui/lab';
import MoreVertIcon from '@material-ui/icons/MoreVert';
import SVGIcon from '../../assets/SVGIcons'
import BootstrapInput from '../../assets/customComponents/BootstrapInput';
import React, { useEffect, useState } from 'react';
import { useTranslation } from "react-i18next";
import { connect } from 'react-redux';
import ExpandLessIcon from '@material-ui/icons/ExpandLess';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import SortIcon from '@material-ui/icons/Sort';
import ConfirmDeleteBrandDialog from '../ConfirmDeleteBrandDialog';
import placeholderBrand from '../../assets/img/brand.png';

const useStyles = makeStyles(() => ({
    tableHeader: {
        fontFamily: 'Poppins',
        cursor: 'pointer',
        marginTop: '10px',
        marginBottom: '10px'
    },
    tableRow: {
        backgroundColor: 'white',
        height: '130px',
        borderRadius: '27px',
        boxShadow: '0 7px 30px 0 rgba(88, 165, 211, 0.16)',
        marginTop: '10px',
        marginBottom: '10px'
    },
    brandImage: {
        height: '130px',
        borderTopLeftRadius: '27px',
        borderBottomLeftRadius: '27px',
        width: '130px',
    },
    tableCell: {
        fontFamily: 'Poppins',
    },
    paginationClass: {
        backgroundColor: '#ffffff !important',
        boxShadow: '0 7px 30px 0 rgba(88, 165, 211, 0.16)',
        borderRadius: '8px',
        fontFamily: 'Poppins',
        height: '40px',
        width: '40px',
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center'
    },
    selectedPaginationItem: {
        backgroundColor: '#3eadef !important',
        boxShadow: '0 7px 30px 0 rgba(88, 165, 211, 0.16)',
        borderRadius: '8px',
        fontFamily: 'Poppins',
        height: '40px',
        width: '40px',
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        color: '#ffffff'
    },
    paginationStyles: {
        fontFamily: 'Poppins'
    },
    menu: {
        boxShadow: '0 7px 7px 0 #d0e6f130',
    },
    placeholderStyles: {
        fontFamily: 'Poppins',
        color: '#1B4C61'
    }
}));

const PrivateCampaign = (props) => {

    const { t } = useTranslation('common');
    const classes = useStyles();

    const {
        brands,
        loadedBrands,
        conversions,
        loadedConversions,
        searchValue,
        appStatePageNumber,
        appStateSortHelper,
        appStatePageSize,
        changeSortHelper,
        changePageNumber,
        changePageSize
    } = props;

    const [anchorEl, setAnchorEl] = useState(null);
    const [brandsInfos, setBrandsInfos] = useState([]);
    const [page, setPage] = useState(1);
    const [count, setCount] = useState(0);
    const [size, setSize] = useState(10);
    const [sortHelper, setSortHelper] = useState([0, 0, 0, 0, -1])
    const [sortEvent, setSortEvent] = useState(0);
    const [loading, setLoading] = useState(true);
    const [brandsTableToRender, setBrandsTableToRender] = useState([]);
    const [tableBeforeRender, setTableBeforeRender] = useState([]);
    const [brandToOperateOn, setBrandToOperateOn] = useState(null);
    const [deleteDialogState, setDeleteDialogState] = useState(false);
    const [influencersToOperateOn, setInfluencersToOperateOn] = useState(null);

    useEffect(() => {
        setSortHelper(appStateSortHelper);
    }, [appStateSortHelper]);

    useEffect(() => {
        setPage(appStatePageNumber);
    }, [appStatePageNumber]);

    useEffect(() => {
        setSize(appStatePageSize);
    }, [appStatePageSize]);

    useEffect(() => {
        setLoading(true);
        if (loadedConversions && loadedBrands) {
            let brandsToRender = [];
            let brandsPrivateCampaign = brands.filter(brand => brand.isPrivateCampaign);
            brandsPrivateCampaign.forEach(brand => {
                let brandConversions = conversions.filter(conversion => conversion.offerId === brand.offerId);
                let brandAmount = 0;
                let brandCommission = 0;
                let brandCommissionSMI = 0;
                let influencersIds = [];
                brandConversions.forEach(brandConversion => {
                    brandAmount += Number(brandConversion.amount);
                    brandCommission += Number(brandConversion.commission);
                    brandCommissionSMI += Number(brandConversion.commission) - Number(brandConversion.commissionAffiliate);
                    if (brandConversion.influencer) {
                        if (influencersIds.indexOf(brandConversion.influencer) === -1) influencersIds.push(brandConversion.influencer);
                    }
                });
                brandsToRender.push({
                    ...brand,
                    amount: brandAmount,
                    commission: brandCommission,
                    commissionSMI: brandCommissionSMI,
                    influencersIds
                })
            });
            setLoading(false);
            setBrandsInfos(brandsToRender);
        }
    }, [loadedConversions, loadedBrands, conversions, brands]);

    useEffect(() => {
        let tableAfterSearch = (searchValue !== '')
            ? brandsInfos.filter(brand => brand.displayName.toLowerCase().includes(searchValue.toLowerCase()) || brand.name.toLowerCase().includes(searchValue.toLowerCase()))
            : brandsInfos;
        setBrandsTableToRender(tableAfterSearch.slice((page - 1) * size, page * size));
        setTableBeforeRender(tableAfterSearch);
        setCount(
            (tableAfterSearch.length % size === 0)
                ? Math.trunc(tableAfterSearch.length / size)
                : Math.trunc(tableAfterSearch.length / size) + 1
        );
    }, [brandsInfos, size, page, sortEvent, searchValue]);

    useEffect(() => {
        let indexAsc = sortHelper.indexOf(1);
        let indexDesc = sortHelper.indexOf(-1);
        if (indexAsc !== -1) {
            const sortTypeAsc = (indexAsc, tableToWorkOn) => {
                switch (indexAsc) {
                    case 0:
                        tableToWorkOn.sort((a, b) => {
                            if (a.amount > b.amount) return -1;
                            if (a.amount < b.amount) return 1;
                            return 0;
                        });
                        break;
                    case 1:
                        tableToWorkOn.sort((a, b) => {
                            if (a.influencersIds.length > b.influencersIds.length) return -1;
                            if (a.influencersIds.length < b.influencersIds.length) return 1;
                            return 0;
                        });
                        break;
                    case 2:
                        tableToWorkOn.sort((a, b) => {
                            if (a.commission > b.commission) return -1;
                            if (a.commission < b.commission) return 1;
                            return 0;
                        });
                        break;
                    case 3:
                        tableToWorkOn.sort((a, b) => {
                            if (a.commissionSMI > b.commissionSMI) return -1;
                            if (a.commissionSMI < b.commissionSMI) return 1;
                            return 0;
                        });
                        break;
                    case 4:
                        tableToWorkOn.sort((a, b) => {
                            if (a.displayName > b.displayName) return -1;
                            if (a.displayName < b.displayName) return 1;
                            return 0;
                        });
                        break;
                    case 5:
                        tableToWorkOn.sort((a, b) => {
                            if (Number(a.createdAt) > Number(b.createdAt)) return -1;
                            if (Number(a.createdAt) < Number(b.createdAt)) return 1;
                            return 0;
                        });
                        break;
                    default:
                        break;
                }
            }
            sortTypeAsc(indexAsc, brandsInfos);
        } else {
            const sortTypeDesc = (indexDesc, tableToWorkOn) => {
                switch (indexDesc) {
                    case 0:
                        tableToWorkOn.sort((a, b) => {
                            if (a.amount > b.amount) return 1;
                            if (a.amount < b.amount) return -1;
                            return 0;
                        });
                        break;
                    case 1:
                        tableToWorkOn.sort((a, b) => {
                            if (a.influencersIds.length > b.influencersIds.length) return 1;
                            if (a.influencersIds.length < b.influencersIds.length) return -1;
                            return 0;
                        });
                        break;
                    case 2:
                        tableToWorkOn.sort((a, b) => {
                            if (a.commission > b.commission) return 1;
                            if (a.commission < b.commission) return -1;
                            return 0;
                        });
                        break;
                    case 3:
                        tableToWorkOn.sort((a, b) => {
                            if (a.commissionSMI > b.commissionSMI) return 1;
                            if (a.commissionSMI < b.commissionSMI) return -1;
                            return 0;
                        });
                        break;
                    case 4:
                        tableToWorkOn.sort((a, b) => {
                            if (a.displayName > b.displayName) return 1;
                            if (a.displayName < b.displayName) return -1;
                            return 0;
                        });
                        break;
                    case 5:
                        tableToWorkOn.sort((a, b) => {
                            if (Number(a.createdAt) > Number(b.createdAt)) return 1;
                            if (Number(a.createdAt) < Number(b.createdAt)) return -1;
                            return 0;
                        });
                        break;
                    default:
                        break;
                }
            };
            sortTypeDesc(indexDesc, brandsInfos);
        }
        setSortEvent(s => s + 1);
    }, [brandsInfos, sortHelper]);

    const handlePageChange = (event, value) => {
        changePageNumber(value);
    };

    const handlePageSizeChange = (event) => {
        changePageSize(event.target.value);
        changePageNumber(1);
    };

    const handleOpenMenu = (event, brandToOperateOn) => {
        setAnchorEl(event.currentTarget);
        setBrandToOperateOn(brandToOperateOn);
        setInfluencersToOperateOn(brandToOperateOn)
    };

    const handleCloseMenu = () => {
        setAnchorEl(null);
    };

    const handleEditBrand = (e) => {
        setAnchorEl(null);
        props.openEditBrand(brandToOperateOn);
    }

    const handleRemoveBrand = () => {
        setAnchorEl(null);
        props.deleteBrand(brandToOperateOn);
    }

    const handleDialogConfirmation = (confirmation) => {
        if (confirmation) {
            handleRemoveBrand();
        }
        setAnchorEl(null);
        setDeleteDialogState(false);
    }

    const handleAssociateInfluencerToBrand = (e) => {
        setAnchorEl(null);
        props.openAssociateInfluencerToBrand(influencersToOperateOn);

    }

    return (
        (loading)
            ? <div style={{ display: 'flex', flexDirection: 'column', flexGrow: 1, width: '100%', justifyContent: 'center', alignItems: 'center' }}>
                <CircularProgress size={36} color='secondary' />
            </div>
            : (brandsTableToRender.length === 0)
                ? <>
                    <Grid container item direction='row' xs={11} justify='center' alignItems='center' style={{ marginTop: '100px' }}>
                        <img
                            alt="No Brands"
                            src={placeholderBrand}
                            height="75px"
                            width="75px"
                        />
                    </Grid>
                    <Grid container item direction='row' xs={11} justify='center' alignItems='center' style={{ marginTop: '10px' }}>
                        <Typography className={classes.placeholderStyles}>
                            {t('ADMIN.VIEWS.PARTNERS.BRANDS.NO_BRANDS_PLACEHOLDER')}
                        </Typography>
                    </Grid>
                </>
                : <>
                    <Dialog open={deleteDialogState}>
                        <ConfirmDeleteBrandDialog onClose={(confirmation) => handleDialogConfirmation(confirmation)} />
                    </Dialog>
                    <Grid container item direction='row' xs={11} alignItems='center' justify='center'>
                        <div style={{ overflowX: 'auto', width: '100%' }}>
                            <Grid container item style={{ minWidth: '1050px' }} direction='row' xs={12}>
                                <Grid container item direction='row' xs={12}>
                                    <Grid container item xs={11}>
                                        <Grid container justify='center' alignItems='center' style={{ width: '38%' }} onClick={() => {
                                            (sortHelper[4] === 0) ? changeSortHelper([0, 0, 0, 0, 1, 0]) : changeSortHelper([0, 0, 0, 0, -sortHelper[4], 0]);
                                        }}>
                                            <Typography className={classes.tableHeader}>
                                                {t('ADMIN.VIEWS.PARTNERS.BRANDS.PRIVATE_CAMPAIGN_TABLE_HEADERS.PRIVATE_CAMPAIGN_BRAND_NAME')}
                                            </Typography>
                                            {(sortHelper[4] === 1)
                                                ? <ExpandLessIcon className={classes.tableHeaderIcon} />
                                                : ((sortHelper[4] === -1)
                                                    ? <ExpandMoreIcon className={classes.tableHeaderIcon} />
                                                    : <SortIcon className={classes.tableHeaderIcon} />)}
                                        </Grid>
                                        <Grid container justify='center' alignItems='center' style={{ width: '13%' }} onClick={() => {
                                            (sortHelper[5] === 0) ? changeSortHelper([0, 0, 0, 0, 0, 1]) : changeSortHelper([0, 0, 0, 0, 0, -sortHelper[5]]);
                                        }}>
                                            <Typography className={classes.tableHeader}>
                                                {t('ADMIN.VIEWS.PARTNERS.BRANDS.PRIVATE_CAMPAIGN_TABLE_HEADERS.REGISTRATION_DATE')}
                                            </Typography>
                                            {(sortHelper[5] === 1)
                                                ? <ExpandLessIcon className={classes.tableHeaderIcon} />
                                                : ((sortHelper[5] === -1)
                                                    ? <ExpandMoreIcon className={classes.tableHeaderIcon} />
                                                    : <SortIcon className={classes.tableHeaderIcon} />)}
                                        </Grid>
                                        <Grid container justify='center' alignItems='center' style={{ width: '13%' }} onClick={() => {
                                            (sortHelper[0] === 0) ? changeSortHelper([1, 0, 0, 0, 0, 0]) : changeSortHelper([-sortHelper[0], 0, 0, 0, 0, 0]);
                                        }}>
                                            <Typography className={classes.tableHeader}>
                                                {t('ADMIN.VIEWS.PARTNERS.BRANDS.PRIVATE_CAMPAIGN_TABLE_HEADERS.TURNOVER')}
                                            </Typography>
                                            {(sortHelper[0] === 1)
                                                ? <ExpandLessIcon className={classes.tableHeaderIcon} />
                                                : ((sortHelper[0] === -1)
                                                    ? <ExpandMoreIcon className={classes.tableHeaderIcon} />
                                                    : <SortIcon className={classes.tableHeaderIcon} />)}
                                        </Grid>
                                        <Grid container justify='center' alignItems='center' style={{ width: '11%' }} onClick={() => {
                                            (sortHelper[1] === 0) ? changeSortHelper([0, 1, 0, 0, 0, 0]) : changeSortHelper([0, -sortHelper[1], 0, 0, 0, 0]);
                                        }}>
                                            <Typography className={classes.tableHeader}>
                                                {t('ADMIN.VIEWS.PARTNERS.BRANDS.PRIVATE_CAMPAIGN_TABLE_HEADERS.INFLUENCERS')}
                                            </Typography>
                                            {(sortHelper[1] === 1)
                                                ? <ExpandLessIcon className={classes.tableHeaderIcon} />
                                                : ((sortHelper[1] === -1)
                                                    ? <ExpandMoreIcon className={classes.tableHeaderIcon} />
                                                    : <SortIcon className={classes.tableHeaderIcon} />)}
                                        </Grid>
                                        <Grid container justify='center' alignItems='center' style={{ width: '13%' }} onClick={() => {
                                            (sortHelper[2] === 0) ? changeSortHelper([0, 0, 1, 0, 0, 0]) : changeSortHelper([0, 0, -sortHelper[2], 0, 0, 0]);
                                        }}>
                                            <Typography className={classes.tableHeader}>
                                                {t('ADMIN.VIEWS.PARTNERS.BRANDS.PRIVATE_CAMPAIGN_TABLE_HEADERS.COMMISSIONS')}
                                            </Typography>
                                            {(sortHelper[2] === 1)
                                                ? <ExpandLessIcon className={classes.tableHeaderIcon} />
                                                : ((sortHelper[2] === -1)
                                                    ? <ExpandMoreIcon className={classes.tableHeaderIcon} />
                                                    : <SortIcon className={classes.tableHeaderIcon} />)}
                                        </Grid>
                                        <Grid container justify='center' alignItems='center' style={{ width: '12%' }} onClick={() => {
                                            (sortHelper[3] === 0) ? changeSortHelper([0, 0, 0, 1, 0, 0]) : changeSortHelper([0, 0, 0, -sortHelper[3], 0, 0]);
                                        }}>
                                            <Typography className={classes.tableHeader}>
                                                {t('ADMIN.VIEWS.PARTNERS.BRANDS.PRIVATE_CAMPAIGN_TABLE_HEADERS.SMI_COMMISSIONS')}
                                            </Typography>
                                            {(sortHelper[3] === 1)
                                                ? <ExpandLessIcon className={classes.tableHeaderIcon} />
                                                : ((sortHelper[3] === -1)
                                                    ? <ExpandMoreIcon className={classes.tableHeaderIcon} />
                                                    : <SortIcon className={classes.tableHeaderIcon} />)}
                                        </Grid>
                                    </Grid>
                                </Grid>
                                {
                                    brandsTableToRender.map((brand, i) => (
                                        <Grid container item xs={12} direction='row' className={classes.tableRow} key={brand.offerId}>
                                            <Grid container item xs={11}>
                                                <Grid container item alignItems='center' style={{ width: '38%' }}>
                                                    <img src={brand.pic} alt='126475' className={classes.brandImage} />
                                                    <Typography className={classes.tableCell} style={{ marginLeft: '30px' }}>{brand.displayName}</Typography>
                                                </Grid>
                                                <Grid container item justify='center' alignItems='center' style={{ width: '13%' }}>
                                                    <Typography className={classes.tableCell}>
                                                        {new Date(Number(brand.createdAt) * 1000).toLocaleDateString()}
                                                    </Typography>
                                                </Grid>
                                                <Grid container item justify='center' alignItems='center' style={{ width: '13%' }}>
                                                    <Typography className={classes.tableCell}>{Math.round(brand.amount * 100) / 100} €</Typography>
                                                </Grid>
                                                <Grid container item justify='center' alignItems='center' style={{ width: '11%' }}>
                                                    <Typography className={classes.tableCell}>{brand.influencers.length}</Typography>
                                                </Grid>
                                                <Grid container item justify='center' alignItems='center' style={{ width: '13%' }}>
                                                    <Typography className={classes.tableCell}>{Math.round(brand.commission * 100) / 100} €</Typography>
                                                </Grid>
                                                <Grid container item justify='center' alignItems='center' style={{ width: '12%' }}>
                                                    <Typography className={classes.tableCell}>{Math.round(brand.commissionSMI * 100) / 100} €</Typography>
                                                </Grid>
                                            </Grid>
                                            <Grid container item justify='center' alignItems='center' xs={1}>
                                                <IconButton onClick={(e) => handleOpenMenu(e, brand)}>
                                                    <MoreVertIcon />
                                                </IconButton>
                                                <Menu
                                                    classes={{ paper: classes.menu }}
                                                    anchorEl={anchorEl}
                                                    open={Boolean(anchorEl)}
                                                    onClose={handleCloseMenu}
                                                >
                                                    <MenuItem
                                                        className={classes.tableHeader}
                                                        onClick={() => handleEditBrand()}>
                                                        {t('ADMIN.VIEWS.PARTNERS.BRANDS.PRIVATE_CAMPAIGN_MENU.EDIT_ITEM')}
                                                    </MenuItem>
                                                    {
                                                        (brandToOperateOn && brandToOperateOn.isPrivateCampaign)
                                                            ? <MenuItem
                                                                className={classes.tableHeader}
                                                                onClick={() => handleAssociateInfluencerToBrand()}
                                                            >
                                                                {t('ADMIN.VIEWS.PARTNERS.BRANDS.PRIVATE_CAMPAIGN_MENU.ASSOCIATE_ITEM')}
                                                            </MenuItem>
                                                            : []
                                                    }

                                                </Menu>
                                            </Grid>
                                        </Grid>
                                    ))
                                }
                            </Grid>
                        </div>

                    </Grid>
                    <Grid container item direction='row' xs={11} alignItems='center' style={{ marginBottom: '20px' }}>
                        <Grid container alignItems='center' justify='flex-end'>
                            <Select
                                value={size}
                                onChange={handlePageSizeChange}
                                input={<BootstrapInput />}
                                IconComponent={() => (
                                    <SVGIcon name='miniArrow' />
                                )}
                                classes={{ root: classes.paginationStyles }}
                            >
                                <MenuItem value={10} style={{ fontFamily: 'Poppins' }}>10</MenuItem>
                                <MenuItem value={25} style={{ fontFamily: 'Poppins' }}>25</MenuItem>
                                <MenuItem value={50} style={{ fontFamily: 'Poppins' }}>50</MenuItem>
                            </Select>
                            <Typography className={classes.tableHeader} style={{ marginRight: '10px', marginLeft: '10px' }}>
                                {/* {t('ADMIN.VIEWS.INFLUENCERS.PAGINATION_PLACEHOLDER')}  */}
                                {t('ADMIN.VIEWS.PARTNERS.BRANDS.PAGINATION_PLACEHOLDER')} {tableBeforeRender.length}
                            </Typography>
                            <Pagination
                                count={count}
                                page={page}
                                onChange={handlePageChange}
                                renderItem={(item) => (
                                    <PaginationItem classes={{ root: classes.paginationClass, selected: classes.selectedPaginationItem }} {...item} />
                                )} />
                        </Grid>
                    </Grid>
                </>
    );
}

const mapStateToProps = (state, ownProps) => {
    const brands = state.brandsState.allBrands;
    const loadedBrands = state.brandsState.loadedBrands;
    const conversions = state.conversionsState.allConversions;
    const loadedConversions = state.conversionsState.loadedAllConversions;
    const appStateSortHelper = state.appState.adminPartnerBrandState.brandTableSortHelper;
    const appStatePageNumber = state.appState.adminPartnerBrandState.brandTablePageNumber;
    const appStatePageSize = state.appState.adminPartnerBrandState.brandTablePageSize;
    return {
        brands,
        loadedBrands,
        conversions,
        loadedConversions,
        appStateSortHelper,
        appStatePageNumber,
        appStatePageSize
    }
};

const mapDispatchToProps = (dispatch) => {
    return {
        changeSortHelper: (sortHelper) =>
            dispatch({ type: 'UPDATE_ADMIN_PARTNER_BRAND_TABLE_SORT_HELPER', sortHelper }),
        changePageNumber: (pageNumber) =>
            dispatch({ type: 'UPDATE_ADMIN_PARTNER_BRAND_TABLE_PAGE_NUMBER', pageNumber }),
        changePageSize: (pageSize) =>
            dispatch({ type: 'UPDATE_ADMIN_PARTNER_BRAND_TABLE_PAGE_SIZE', pageSize })
    }
};

export default connect(mapStateToProps, mapDispatchToProps)(PrivateCampaign);
