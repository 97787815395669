var defaultState = {
  loading: false,
  registrationComplete: false,
  registrationResponseErrors: null,
  activeStep: 0,
};

const auth = (state = defaultState, action) => {
  switch (action.type) {
    case "REGISTRATION_REQUEST":
      return {
        ...state,
        loading: true
      };
    case "REGISTRATION_USER_COMPLETE":
      return {
        ...state,
        loading: false,
        registrationComplete: true,
      };
    case "REGISTRATION_USER_ERROR":
      return {
        ...state,
        loading: false,
        registrationResponseErrors: action.error,
        activeStep: 1,
      };
    case "CLEAR_AUTH":
      return {
        ...state,
        ...defaultState,
      };
    default:
      return state;
  }
};
export default auth;
