import React from "react";

const SVG = ({
    height = '15px',
    width = "15px",
}) => (
        <svg style={{
            top: 15,
            color: 'rgba(0, 0, 0, 0.54)',
            right: '4px',
            position: 'absolute',
            pointerEvents: 'none'}} xmlns="http://www.w3.org/2000/svg" width={width} height={height} viewBox="0 0 23.698 23.698">
            <g>
                <path fill="none" d="M0 0h23.7v23.7H0z" transform="rotate(90 11.849 11.849)" />
                <path d="M10.968 1.766L9.3 0 0 9.875l9.3 9.875 1.664-1.766-7.637-8.109z" transform="rotate(90 11.849 11.849) translate(0 1.974)" />
            </g>
        </svg>


    );

export default SVG;
