import { dbManager } from "../../components/managers";
import axios from "axios";

const env = process.env.REACT_APP_STAGE;
const apiUrl = (env === 'prod') ? "https://us-central1-shopmyinfluens.cloudfunctions.net" : "http://localhost:5001/shopmyinfluens/us-central1"

export const fetchAllCategories = () => {
    return (dispatch, getState) => {
        dbManager.fetchAllCategories().then(categories => {
            dispatch({ type: 'FETCH_ALL_CATEGORIES_SUCCESS', categories: Object.keys(categories) })
        }).catch(error => {
            dispatch({ type: 'FETCH_ALL_CATEGORIES_ERROR', error });
        })
    }
};

export const fetchNewCategories = () => {
    return (dispatch, getState) => {
        dbManager.fetchNewCategories().then(categories => {
            dispatch({ type: 'FETCH_NEW_CATEGORIES_SUCCESS', categories: categories });
        }).catch(error => {
            dispatch({ type: 'FETCH_NEW_CATEGORIES_ERROR', error });
        })
    }
};

export const addNewCategory = (category) => {
    return (dispatch, getState) => {
        dbManager.addNewCategory(category).then(addedCategory => {
            dispatch({ type: 'ADD_NEW_CATEGORY_SUCCESS', payload: { category: addedCategory } });
        }, error => {
            dispatch({ type: 'ADD_NEW_CATEGORY_ERROR', error });
        });
    }
};

export const updateCategoryById = (category, updates) => {
    return (dispatch, getState) => {
        dbManager.updateCategory(category, updates).then(updates => {
            dispatch({ type: 'UPDATE_CATEGORY_SUCCESS', payload: { updates, categoryId: category.key } })
        }, error => {
            dispatch({ type: 'UPDATE_CATEGORY_ERROR', error });
        })
    }
}

export const removeNewCategory = (category) => {
    return (dispatch, getState) => {
        axios.delete(apiUrl + '/api/category', {
            data: {
                category
            }
        }).then(result => {
            if (result.status !== 200) {
                if (result.removeFromChildError || result.updateNodesError) {
                    dispatch({ type: 'REMOVE_CATEGORY_SUCCESS_WITHOUT_DEPENDENCE', payload: { removedCategory: category } });
                }
            } else {
                dispatch({
                    type: 'REMOVE_CATEGORY_SUCCESS_WITH_DEPENDENCE',
                    payload: {
                        removedCategory: category
                    }
                });
                dispatch({
                    type: 'UPDATE_BRANDS_CATEG_PROPERTY',
                    payload: {
                        removedCategory: category,
                        updatedBrandsIds: result.data.updatedBrandIds,
                        updatedBrandsToValidateIds: result.data.updatedBrandsToValidateIds
                    }
                });
                dispatch({
                    type: 'UPDATE_SOLD_ARTICLES_CATEG_PROPERTY',
                    payload: {
                        removedCategory: category,
                        updatedArticlesIds: result.data.updatedArticlesIds
                    }
                })
            }
        }, error => {
            dispatch({ type: 'REMOVE_CATEGORY_ERROR', error });
        });
    }
}

export const fetchAllSubCategories = () => {
    return (dispatch, getState) => {
        dbManager.fetchAllSubCategories().then(subCategories => {
            dispatch({ type: 'FETCH_SUB_CATEGORIES_SUCCESS', payload: { subCategories } });
        }, error => {
            dispatch({ type: 'FETCH_SUB_CATEGORIES_ERROR', error });
        });
    }
}

export const addSubCategory = (category, parentCategoriesIds) => {
    return (dispatch, getState) => {
        dbManager.addSubCategory(category, parentCategoriesIds).then(result => {
            if (result.affectingError) {
                dispatch({ type: 'ADD_SUB_CATEGORY_SUCCESS_WITHOUT_AFFECTATION', payload: { subCategory: result.subCategoryToReturn } })
            } else {
                dispatch({ type: 'ADD_SUB_CATEGORY_SUCCESS_WITH_AFFECTATION', payload: { subCategory: result.subCategoryToReturn, parentCategoriesIds } })
            }
        }, error => {
            dispatch({ type: 'ADD_SUB_CATEGORY_ERROR', error });
        });
    }
};

export const updateSubCategory = (subCategory, updates) => {
    return (dispatch, getState) => {
        dbManager.updateSubCategory(subCategory, updates).then(result => {
            if (result.affectingError) {
                dispatch({ type: 'UPDATE_SUB_CATEGORY_SUCCESS_WITHOUT_AFFECTATION', payload: { updates: result.updatesWithoutCateg, subCategoryId: subCategory.key } });
            } else {
                dispatch({ type: 'UPDATE_SUB_CATEGORY_SUCCESS_WITH_AFFECTATION', payload: { updates: result.updates, subCategoryId: subCategory.key } });
            }
        }, error => {
            dispatch({ type: 'UPDATE_SUB_CATEGORY_ERROR', error });
        });
    }
};

export const removeSubCategory = (subCategory) => {
    return (dispatch, getState) => {
        axios.delete(apiUrl + '/api/subCategory', {
            data: {
                subCategory
            }
        }).then(result => {
            if (result.status !== 200) {
                if (result.data.removeFromParentError || result.data.updateNodesError) {
                    dispatch({ type: 'REMOVE_SUB_CATEGORY_SUCCESS_WITHOUT_DEPENDENCE', payload: { removedSubCategory: subCategory } });
                }
            } else {
                dispatch({
                    type: 'REMOVE_SUB_CATEGORY_SUCCESS_WITH_DEPENDENCE',
                    payload: {
                        removedSubCategory: subCategory
                    }
                });
                dispatch({
                    type: 'UPDATE_SOLD_ARTICLES_SUB_CATEG_PROPERTY',
                    payload: {
                        removedSubCategory: subCategory,
                        updatedArticlesIds: result.data.updatedArticlesIds
                    }
                });
            }
        }, error => {
            dispatch({ type: 'REMOVE_SUB_CATEGORY_ERROR', error });
        });
    }
}