import React from "react";
import clsx from "clsx";
import { Link } from "react-router-dom";

import { makeStyles } from "@material-ui/styles";
import { Grid, Typography } from "@material-ui/core";

import { menuOptions } from "../../../../config/config"; // TODO

const useStyles = makeStyles(theme => ({
  menu: {
    position: "fixed",
    zIndex: "100",
    height: "100%",
    width: "100%",
    top: "0",
    left: "0",
    backgroundColor: theme.background.transparent,
    transition: "left .5s",
    "& ul": {
      padding: "0",
      margin: "0",
      listStyleType: "none",
      verticalAglign: "center",
      "& li": {
        margin: "20px 0",
        textAlign: "center",
        "& a": {
          textDecoration: "none",
          color: "black",
          "&:hover": {
            color: theme.palette.primary.main,
          },
        },
      },
    },
  },
  hidden: {
    top: "0",
    left: "-100%",
    transition: "left .5s",
  },
}));

const Menu = ({ user, menuVisible, toggleMenu }) => {
  const classes = useStyles();
  return (
    <div className={clsx(classes.menu, !menuVisible && classes.hidden)}>
      <Grid
        container
        justify="center"
        alignItems="center"
        wrap="nowrap"
        style={{ height: "100%" }}
      >
        <ul>
          {menuOptions.map((option, i) => {
            if (option.cred === "all") {
              return (
                <li key={i}>
                  <Typography variant="h4">
                    <Link onClick={toggleMenu} to={option.link}>
                      {option.name}
                    </Link>
                  </Typography>
                </li>
              );
            } else if (user && option.cred === "user") {
              return (
                <li key={i}>
                  <Typography variant="h4">
                    <Link onClick={toggleMenu} to={option.link}>
                      {option.name}
                    </Link>
                  </Typography>
                </li>
              );
            } else {
              return null;
            }
          })}
        </ul>
      </Grid>
    </div>
  );
};

export default Menu;
