import React,{ useState,useEffect } from "react";
import { connect } from "react-redux";

// Component Scope
import { Welcome,Wishlists } from "./components";

const Home = ({ influencers,welcome,setLoadInfluencer,setLoadWishlistsPerInfluencer }) => {
  const [welcomeScreen,setWelcomeScreen] = useState(false);

  useEffect(() => {
    setWelcomeScreen(welcome);
  },[welcome]);

  useEffect(() => {
    setLoadInfluencer();
  },[setLoadInfluencer]);

  useEffect(() => {
    setLoadWishlistsPerInfluencer();
  },[setLoadWishlistsPerInfluencer])

  const handleHideWelcome = () => {
    setWelcomeScreen(false);
  };

  return (
    <>
      { welcomeScreen && (
        <Welcome hide={ handleHideWelcome } />
      ) }
      <Wishlists influencers={ influencers } />
    </>
  );
};

const mapDispatchToState = (dispatch) => {
  return {
    setLoadInfluencer: () => dispatch({ type: 'SET_LOAD_INFLUENCER' }),
    setLoadWishlistsPerInfluencer: () => dispatch({ type: 'SET_LOAD_WISHLISTS_PER_INFLUENCER' })
  };
}

export default connect(null,mapDispatchToState)(Home);
