import React, { useLayoutEffect } from "react";
import * as am4core from "@amcharts/amcharts4/core";
import * as am4charts from "@amcharts/amcharts4/charts";
import am4themes_animated from "@amcharts/amcharts4/themes/animated";

am4core.useTheme(am4themes_animated);

const ConversionsChart = (props) => {

    const currentMonthConversions = props.currentMonthConversions;
    const previousMonthConversions = props.previousMonthConversions;
    const customDurationConversions = props.customDurationConversions;

    const formatData = (currentConversions, previousConversions, customDurationConversions) => {
        if (customDurationConversions) {
            let array = [];
            customDurationConversions.forEach(conv => {
                array.push({
                    date: new Date(conv.createdAt * 1000),
                    amount: conv.amount
                });
            });
            return array
        } else {
            let array = [];
            for (let i = 0; i < 30; i++) {
                array[i] = {
                    currentAmount: 0,
                    previousAmount: 0,
                    day: i + 1
                }
            }
            currentConversions.forEach(conv => {
                const date = new Date(conv.createdAt * 1000);
                array[date.getDate() - 1].currentAmount += conv.amount;
            });
            previousConversions.forEach(conv => {
                const date = new Date(conv.createdAt * 1000);
                array[date.getDate() - 1].previousAmount += conv.amount;
            });
            return array;
        }
    }

    useLayoutEffect(() => {

        let chart = am4core.create("chartdiv", am4charts.XYChart);

        let data = formatData(currentMonthConversions, previousMonthConversions, customDurationConversions);

        chart.data = data;

        chart.events.on("beforedatavalidated", function (ev) {
            chart.data.sort(function (a, b) {
                return (new Date(a.date)) - (new Date(b.date));
            });
        });

        if (customDurationConversions) {
            var dateAxis = chart.xAxes.push(new am4charts.DateAxis());
            dateAxis.renderer.grid.template.location = 0;
            dateAxis.renderer.minGridDistance = 50;

            var valueAxis = chart.yAxes.push(new am4charts.ValueAxis());
            valueAxis.logarithmic = true;
            valueAxis.renderer.minGridDistance = 30;

            var series = chart.series.push(new am4charts.LineSeries());
            series.dataFields.dateX = "date";
            //series.name = currentMonth;
            series.dataFields.valueY = "amount";
            series.tooltipText = "[#000]{valueY.value} €[/]";
            series.tooltip.background.fill = am4core.color("#FFF");
            series.tooltip.getStrokeFromObject = true;
            series.tooltip.background.strokeWidth = 3;
            series.tooltip.getFillFromObject = false;
            series.strokeWidth = 3;

            let bullet = series.bullets.push(new am4charts.CircleBullet());
            bullet.circle.fill = am4core.color("#fff");
            bullet.circle.strokeWidth = 2;

            chart.cursor = new am4charts.XYCursor();
            chart.cursor.xAxis = dateAxis;
            chart.cursor.xAxis.cursorTooltipEnabled = false;
            valueAxis.cursorTooltipEnabled = false;
        } else {
            if (currentMonthConversions.length !== 0 || previousMonthConversions !== 0) {
                chart.dateFormatter.inputDateFormat = "yyyy";
                var dateAxis2 = chart.xAxes.push(new am4charts.ValueAxis());
                dateAxis2.renderer.minGridDistance = 30;
                dateAxis2.startLocation = 0.5;
                dateAxis2.endLocation = 0.5;

                chart.yAxes.push(new am4charts.ValueAxis());

                var lineSeries = chart.series.push(new am4charts.LineSeries());
                lineSeries.dataFields.valueX = "day";
                //lineSeries.name = currentMonth;
                lineSeries.dataFields.valueY = "currentAmount";
                lineSeries.tooltipText = "[#000]{valueY.value}[/]";
                lineSeries.tooltip.background.fill = am4core.color("#FFF");
                lineSeries.tooltip.getStrokeFromObject = true;
                lineSeries.tooltip.background.strokeWidth = 3;
                lineSeries.tooltip.getFillFromObject = false;
                lineSeries.fillOpacity = 0.6;
                lineSeries.strokeWidth = 1;;

                var series2 = chart.series.push(new am4charts.LineSeries());
                //series2.name = previousMonth;
                series2.dataFields.valueX = "day";
                series2.dataFields.valueY = "previousAmount";
                series2.tooltipText = "[#000]{valueY.value}[/]";
                series2.tooltip.background.fill = am4core.color("#FFF");
                series2.tooltip.getFillFromObject = false;
                series2.tooltip.getStrokeFromObject = true;
                series2.tooltip.background.strokeWidth = 3;
                series2.sequencedInterpolation = true;
                series2.fillOpacity = 0.6;
                series2.strokeWidth = 1;

                chart.cursor = new am4charts.XYCursor();
                chart.cursor.xAxis = dateAxis;

                // Add a legend
                chart.legend = new am4charts.Legend();
                chart.legend.position = "top";
            }
        }

        return () => {
            chart.dispose();
        };


    }, [currentMonthConversions, previousMonthConversions, customDurationConversions]);

    return (
        <div id="chartdiv" style={{ width: "95%", height: "290px" }}></div>
    );
};

export default ConversionsChart;
