import { dbManager,userManager } from "../../components/managers"

export const fetchUsersByOfferId = (offerId) => {
    return (dispatch,getState) => {
        dispatch({ type: 'FETCH_USERS_BY_OFFERID_REQUEST' });
        dbManager.fetchUsersByOfferId(offerId).then(users => {
            dispatch({ type: 'FETCH_USERS_BY_OFFERDID_SUCCESS',payload: { users: users } });
        }).catch(error => {
            dispatch({ type: 'FETCH_USERS_BY_OFFERDID_ERROR',error })
        })
    };
}

export const addNewUser = (user,userCredentials) => {
    return async (dispatch,getState) => {
        dispatch({ type: 'ADD_USER_BY_ID_REQUEST' })
        await userManager.createUserForBrand(userCredentials).then((uid) => {
            let userData = Object.assign(user,{ 'uid': uid });
            userManager.addClaimWithValue(uid,'brand',true).then(result => {
                if (result.data.status === 'success') {
                    userManager.addClaimWithValue(uid,'brandOfferId',userData.offerId).then(result => {
                        if (result.data.status === 'success') {
                            if (userData.role === "admin") {
                                userManager.addClaimWithValue(uid,'brandAdmin',true).then(result => {
                                    if (result.data.status === 'success') {
                                        dbManager.addNewBrandUser(userData.offerId,uid,userData).then((result) => {
                                            dispatch({ type: 'ADD_USER_BY_ID_SUCCESS',payload: { user: result } });
                                        },(error) => {
                                            dispatch({ type: 'ADD_USER_BY_ID_ERROR',error })
                                        });
                                    } else {
                                        dispatch({ type: 'ADD_USER_BY_ID_ERROR',error: 'Unable to set brandAdmin claim' });
                                    }

                                });
                            } else {
                                dbManager.addNewBrandUser(userData.offerId,uid,userData).then((result) => {
                                    dispatch({ type: 'ADD_USER_BY_ID_SUCCESS',payload: { user: result } });
                                },(error) => {
                                    dispatch({ type: 'ADD_USER_BY_ID_ERROR',error })
                                });
                            }
                        } else {
                            dispatch({ type: 'ADD_USER_BY_ID_ERROR',error: 'Unable to set brandOfferId claim' });
                        }
                    });
                } else {
                    dispatch({ type: 'ADD_USER_BY_ID_ERROR',error: 'Unable to set claim for this user' });
                }
            });
        },error => {
            dispatch({ type: 'ADD_USER_BY_ID_ERROR',error });
        });
    }
}
export const removeUserByUid = (uid,offerId) => {
    return (dispatch,getState) => {
        dispatch({ type: 'REMOVE_USER_BY_ID_REQUEST' });
        userManager.deleteUser(uid,offerId).then(() => {
            dispatch({ type: 'REMOVE_USER_BY_ID_SUCCESS',payload: { uid: uid } });
        },error => {
            dispatch({ type: 'REMOVE_USER_BY_ID_ERROR',error });
        });
    }
}