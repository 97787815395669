import { Dialog, DialogContent, Grid, makeStyles, Typography, IconButton } from '@material-ui/core';
import React from 'react';
import { useTranslation } from "react-i18next";
import CloseIcon from '@material-ui/icons/Close';
import {
    FacebookMessengerShareButton,
    FacebookShareButton,
    LinkedinShareButton,
    // PinterestShareButton,
    TwitterShareButton,
    WhatsappShareButton,
    FacebookIcon,
    FacebookMessengerIcon,
    LinkedinIcon,
    // PinterestIcon,
    TwitterIcon,
    WhatsappIcon,
} from "react-share";

const useStyle = makeStyles(theme => ({
    dialogTitle: {
        display: 'flex',
        flexDirection: 'row',
        justifyContent: 'space-between',
        alignItems: 'center',
        width: '100%',
        padding: 10
    },
    shareTitle: {
        fontFamily: 'Poppins',
        paddingLeft: '15px',
        fontSize: '18px',
        marginTop: '15px'
    },
    socialMediaIcon: {
        borderRadius: '50%'
    },
    socialMediaButton: {
        margin: '5px'
    },
}));

const ShareBox = (props) => {

    const { t } = useTranslation("common");
    const classes = useStyle();

    const {
        open
    } = props;

    const handleCloseDialog = () => {
        props.closeShareBox();
    };

    return (
        <Dialog open={open}>
            <Grid container item direction='row' xs={12} justify='space-between' alignItems='center'>
                <Typography className={classes.shareTitle}>{t('INFLUENCER.SHARE_BOX.SHARE_BOX_TITLE')}</Typography>
                <IconButton size='small' onClick={handleCloseDialog} style={{ marginRight: '5px'}}>
                    <CloseIcon />
                </IconButton>
            </Grid>
            <DialogContent style={{ height: '150px', display: 'flex', justifyContent: 'center', alignItems:'center'}}>
                <FacebookShareButton className={classes.socialMediaButton} url={window.location.href}>
                    <FacebookIcon size={48} className={classes.socialMediaIcon} />
                </FacebookShareButton>
                <FacebookMessengerShareButton className={classes.socialMediaButton} url={window.location.href}>
                    <FacebookMessengerIcon size={48} className={classes.socialMediaIcon} />
                </FacebookMessengerShareButton>
                <TwitterShareButton className={classes.socialMediaButton} url={window.location.href}>
                    <TwitterIcon size={48} className={classes.socialMediaIcon} />
                </TwitterShareButton>
                <WhatsappShareButton className={classes.socialMediaButton} url={window.location.href}>
                    <WhatsappIcon size={48} className={classes.socialMediaIcon} />
                </WhatsappShareButton>
                <LinkedinShareButton className={classes.socialMediaButton} url={window.location.href}>
                    <LinkedinIcon size={48} className={classes.socialMediaIcon} />
                </LinkedinShareButton>
            </DialogContent>
        </Dialog>
    );


};

export default ShareBox