const initState = {
    eshopArticles: [],
    loadedEshopArticles: false,
}

const eshopReducer = (state = initState, action) => {
    switch (action.type) {
        case "GET_ARTICLE_BY_BRAND_ESHOP_SUCCESS":
            return {
                eshopArticles: action.payload.eshop,
                loadedEshopArticles: true,
            }
        case "GET_ARTICLE_BY_BRAND_ESHOP_ERROR":
            return {
                ...state,
                loadedEshopArticles: false
            }
        default:
            return state;
    }
}

export default eshopReducer;