import React, { useState } from "react";
import PropTypes from "prop-types";
import { Button as MUIButton } from "@material-ui/core";
import { makeStyles, useTheme } from "@material-ui/styles";

const useStyles = makeStyles((theme) => ({
  root: {
    borderRadius: "unset",
    boxShadow: "unset",
  },
  contained: {
    "&:hover": {
      boxShadow: "unset",
    },
  },
}));

const SharpFlatButton = (props) => {
  const classes = useStyles();
  const theme = useTheme();
  const {
    children,
    style,
    background = theme.palette.primary.main,
    backgroundHover = theme.palette.primary.dark,
    textColor = theme.palette.primary.contrastText,
    ...buttonProps
  } = props;
  const [hover, setHover] = useState(false);
  return (
    <MUIButton
      style={{
        color: textColor,
        backgroundColor: !hover ? background : backgroundHover,
        borderRadius: "none",
        ...style,
      }}
      classes={{ root: classes.root, contained: classes.contained }}
      variant='contained'
      disableRipple
      disableFocusRipple
      disableTouchRipple
      onMouseEnter={() => backgroundHover && setHover(true)}
      onMouseLeave={() => backgroundHover && setHover(false)}
      {...buttonProps}
    >
      {children}
    </MUIButton>
  );
};

SharpFlatButton.propTypes = {
  style: PropTypes.object,
  background: PropTypes.string,
  backgroundHover: PropTypes.string,
  textColor: PropTypes.string,
};

export default SharpFlatButton;
