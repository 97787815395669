import React, { useState, useEffect } from "react";
import { makeStyles, useTheme, useMediaQuery, Grid, Box, Typography, Button, IconButton, Backdrop, CircularProgress, Dialog } from "@material-ui/core";
import EuroSymbolIcon from '@material-ui/icons/EuroSymbol';
import { updateClaimsInfluencerAgent } from '../../../../store/actions/influencerAgentsActions';
import SVGIcon from './../assets/SVGIcons/index';
import TrendLoading from "../../../ui/trendLoading/TrendLoading";
import CloseIcon from '@material-ui/icons/Close';
import { useTranslation } from "react-i18next";
import { connect } from "react-redux";

const useStyles = makeStyles(theme => {
    return {
        overallBoxContainer: {
            width: '95%',
            display: 'flex',
            justifyContent: 'center',
            marginBottom: '30px',
            marginTop: '30px'
        },
        overallBoxStyles: {
            backgroundColor: 'white',
            borderRadius: '35px',
            boxShadow: '0 7px 30px 0 rgba(88, 165, 211, 0.16)',
            width: '100%',
        },
        influencerImageBox: {
            width: '500px',
            margin: '10px',
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center'
        },
        influencerInfosBox: {
            height: '200px',
        },
        influencerImage: {
            objectFit: 'cover',
            width: '90%',
            height: "200px",
            margin: "10px",
            borderRadius: '35px',
            boxShadow: '0 7px 30px 0 rgba(88, 165, 211, 0.16)',
        },
        smallBox: {
            boxShadow: '0 7px 30px 0 rgba(58, 111, 142, 0.25)',
            borderRadius: '41px',
            width: '300px',
            height: '200px',
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center',
            margin: 10,
        },
        chipButton: {
            // width: 220,
            height: 40,
            marginRight: 5,
            marginLeft: 5,
            marginTop: 5,
            marginBottom: 5,
            paddingRight: 30,
            paddingLeft: 30,
            color: '#000',
            borderRadius: '35px',
            backgroundColor: '#ffffff',
            fontSize: '16px',
            textTransform: 'none',
            fontFamily: 'Poppins',
            boxShadow: '0 7px 30px 0 rgba(58, 111, 142, 0.16)',
        },
        chipActiveButton: {
            // width: 220,
            height: 40,
            marginRight: 5,
            marginLeft: 5,
            marginTop: 5,
            marginBottom: 5,
            paddingRight: 30,
            paddingLeft: 30,
            color: '#ffffff',
            borderRadius: '35px',
            backgroundColor: '#3eadef',
            fontSize: '16px',
            textTransform: 'none',
            fontFamily: 'Poppins',
            '&:hover': {
                backgroundColor: '#3eadef',
            },
        },
        wishlistsBox: {
            height: '100%',
            borderRadius: '41px',
            boxShadow: '0 2px 30px 0 rgba(58, 111, 142, 0.21)',
            marginTop: '15px',
            marginBottom: '30px',
        },
        articleBox: {
            margin: '15px',
            borderRadius: '41px',
            boxShadow: '0 2px 30px 0 rgba(58, 111, 142, 0.21)',
            width: '170px',
            height: '260px',
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center'
        },
        articleImage: {
            objectFit: 'contain',
            width: '150px',
            height: '220px',
        },
        articlesContainer: {
            display: 'inline-flex',
            flexWrap: 'wrap',
            marginTop: '20px',
            marginBottom: '20px',
            marginLeft: '-15px',
            marginRight: '-15px',
        },
        influencerName: {
            fontFamily: 'Poppins',
            fontSize: '25px',
            fontWeight: 'bold',
            color: '#000000',
            wordBreak: 'break-word',
        },
        influencerEmail: {
            fontFamily: 'Poppins',
            fontSize: '14px',
            fontWeight: 200,
            color: '#000000',
        },
        influencerInstagram: {
            fontFamily: 'Poppins',
            fontSize: '25px',
            color: '#000000',
            textDecoration: 'none',
        },
        influencerStatsTitles: {
            fontFamily: 'Poppins',
            fontSize: '18px',
            fontWeight: 500,
            color: '#000000'
        },
        influencerStats: {
            fontFamily: 'Poppins',
            fontSize: '20px',
            fontWeight: 900,
            color: '#000000'
        },
        spacement: {
            marginTop: '15px',
            marginBottom: '15px',
        },
        buttonEnAttente: {
            marginTop: '15px',
            padding: '6px 8px',
            width: "200px",
            height: "40px",
            color: '#50c7f4',
            fontFamily: 'Poppins',
            fontSize: "16px",
            textTransform: "capitalize",
            boxShadow: "0px 1px 5px 1px #50c7f4",
            borderRadius: "20px",

            backgroundColor: '#fff',
            '&:hover': {
                backgroundColor: '#fff'
            }
        },
        buttonAssocier: {
            marginTop: '15px',
            padding: '6px 8px',
            width: "180px",
            height: "40px",
            color: '#fff',
            fontFamily: 'Poppins',
            fontSize: "16px",
            textTransform: "capitalize",
            boxShadow: "0px 0px 0px 0px #00aeef",
            borderRadius: "20px",
            backgroundColor: '#00aeef',
            '&:hover': {
                backgroundColor: '#00aeef'
            }
        },
        buttonNotAssocier: {
            marginTop: '15px',
            padding: '6px 8px',
            width: "auto",
            height: "40px",
            color: '#fff',
            fontFamily: 'Poppins',
            fontSize: "16px",
            textTransform: "capitalize",
            boxShadow: "0px 0px 0px 0px #00aeef",
            borderRadius: "20px",
            backgroundColor: '#00aeef',
            '&:hover': {
                backgroundColor: '#00aeef'
            }
        },
        menu: {
            width: "500px",
            height: "170px",
            borderRadius: "20px",
        },
        dialogTitle: {
            fontFamily: 'Poppins',
            fontSize: '21px',
            textAlign: 'center'
        }
    }
});



const InfluencerModal = (props) => {
    const { t } = useTranslation('common');
    const theme = useTheme();
    const xsDown = useMediaQuery(theme.breakpoints.down('xs'));

    const influencer = props.activeInfluencer;

    const {
        influencerAgent,
        loadedInfluencerAgents,
        allInfluencerAgents,
        updateClaimsInfluencerAgent,
        loadingUpdateInfluencerAgentClaims,
        loadedWishlistsPerInfluencer,
        wishlistsPerInfluencer,
    } = props;

    const classes = useStyles();

    const [influencerWishlists, setInfluencerWishlists] = useState([]);
    const [activeButtonId, setActiveButtonId] = useState(null);
    const [activeArticles, setActiveArticles] = useState([]);
    const [overlayState, setOverlayState] = useState(false);
    const [open, setOpen] = useState(false);
    const [agentToWorkOn, setAgentToWorkOn] = useState([]);
    const [influencersKeys, setInfluencersKeys] = useState([]);
    const [influencersIds, setInfluencersIds] = useState([]);
    const [status, setStatus] = useState([]);
    const [selectedStatus, setSelectedStatus] = useState("");
    const [updateClicked, setUpdateClicked] = useState(false);


    useEffect(() => {
        if (influencer && loadedWishlistsPerInfluencer) {
            const wishlistInfluencer = wishlistsPerInfluencer[influencer.key];
            setInfluencerWishlists(wishlistInfluencer);
        }
    }, [influencer, loadedWishlistsPerInfluencer, wishlistsPerInfluencer]);

    useEffect(() => {
        if (influencerWishlists.length !== 0) {
            setActiveButtonId(influencerWishlists[0].id);
            if (influencerWishlists[0].articles !== null && influencerWishlists[0].articles !== undefined) {
                setActiveArticles(Object.values(influencerWishlists[0].articles));
            }
        };
    }, [influencerWishlists])

    const handleWishlistClick = (wishlist) => {
        setActiveButtonId(wishlist.id);
        (wishlist.articles !== undefined)
            ? setActiveArticles(Object.values(wishlist.articles))
            : setActiveArticles([]);
    };

    const closePopup = () => {
        props.close(true);
    };

    useEffect(() => {
        let arrayInfluencersIds = [];
        let acceptedStatus = [];
        let influencersIds = [];
        if (loadedInfluencerAgents) {
            let agentToWorkOn = allInfluencerAgents.filter(element => element.userId === influencerAgent.userId);
            setAgentToWorkOn(...agentToWorkOn);
            if (agentToWorkOn.length !== 0) {
                Object.values(agentToWorkOn).forEach(e => {
                    e.influencersIds.forEach(ele => {
                        arrayInfluencersIds.push(ele.uid);
                        acceptedStatus.push(ele.acceptedStatus);
                        influencersIds.push(ele)
                    })
                });
            }
        }
        setInfluencersIds(influencersIds)
        setInfluencersKeys(arrayInfluencersIds);
        setStatus(acceptedStatus)
    }, [loadedInfluencerAgents, allInfluencerAgents, influencerAgent]);

    useEffect(() => {
        if (influencersKeys.length !== 0) {
            let index = influencersKeys.indexOf(influencer.key);
            if (index !== -1) {
                if (status[index] === true) {
                    setSelectedStatus('associate');
                } else if (status[index] === false) {
                    setSelectedStatus('enAttente');
                }
            } else {
                setSelectedStatus('not associate');
            }
        }
    }, [influencersKeys, influencer, status]);

    const handleOpenDialog = () => {
        setOpen(true);
    };
    const handleCloseDialog = () => {
        setOpen(false);
    };

    const handleDemandeAssociation = (e) => {
        e.preventDefault();
        let updates = {};
        updates['influencersIds'] = influencersIds.map(influencer => {
            return {
                ...influencer
            }
        }).concat({
            acceptedStatus: false,
            uid: influencer.key
        })

        setUpdateClicked(true);
        updateClaimsInfluencerAgent(agentToWorkOn, updates);
    };

    const handleAnnulerDemande = () => {
        let updates = {};
        let index = influencersKeys.indexOf(influencer.key);
        influencersIds.splice(index, 1);
        updates['influencersIds'] = influencersIds.map(influencer => {
            return {
                ...influencer
            }
        })
        setUpdateClicked(true);
        updateClaimsInfluencerAgent(agentToWorkOn, updates);
    };

    const handleDeleteAssociation = () => {
        let updates = {};
        let index = influencersKeys.indexOf(influencer.key);
        influencersIds.splice(index, 1);
        updates['influencersIds'] = influencersIds.map(influencer => {
            return {
                ...influencer
            }
        })
        setUpdateClicked(true);
        updateClaimsInfluencerAgent(agentToWorkOn, updates);
        setOpen(false);
        props.close(true);
    };

    const getButton = (selectedStatus) => {
        switch (selectedStatus) {
            case "associate":
                return <Button className={classes.buttonAssocier} style={{ visibility: "hidden" }} onClick={handleOpenDialog}>{t('INFLUENCER_AGENT.VIEWS.INFLUENCERS.POPUP_INFLUENCER.INFLUENCER_ASSOCIATE')}</Button>;
            case "enAttente":
                return <Button className={classes.buttonEnAttente} style={{ visibility: "hidden" }} disableRipple onClick={handleAnnulerDemande}>{t('INFLUENCER_AGENT.VIEWS.INFLUENCERS.POPUP_INFLUENCER.INFLUENCER_EN_ATTENTE')}</Button>;
            case "not associate":
                return <Button className={classes.buttonNotAssocier} style={{ visibility: "hidden" }} onClick={(e) => { handleDemandeAssociation(e); }}>{t('INFLUENCER_AGENT.VIEWS.INFLUENCERS.POPUP_INFLUENCER.INFLUENCER_NOT_ASSOCIATE')}</Button>;
            default:
                return <></>
        }
    }


    useEffect(() => {
        if (loadingUpdateInfluencerAgentClaims) {
            setOverlayState(true);
        }
        if (!loadingUpdateInfluencerAgentClaims && updateClicked) {
            setOverlayState(false);
            setUpdateClicked(false);
        }
    }, [loadingUpdateInfluencerAgentClaims, updateClicked]);

    const buttonToRender = getButton(selectedStatus);

    return (
        <Grid container item direction='row' justify='center'>
            <Backdrop className={classes.overlay} open={overlayState}>
                <CircularProgress color='secondary' />
            </Backdrop>
            <Dialog classes={{ paper: classes.menu }} open={open} onClose={handleCloseDialog} aria-labelledby="form-dialog-title">
                <Grid container item direction='column' xs={12} alignItems='center' style={{ marginTop: '20px' }}>
                    <Typography className={classes.dialogTitle}>{t('INFLUENCER_AGENT.VIEWS.INFLUENCERS.POPUP_INFLUENCER.INFLUENCER_DIALOG.DIALOG_TITLE')}</Typography>
                </Grid>
                <Grid container item direction='row' xs={12} justify='center'>
                    <Button className={classes.buttonEnAttente} style={{ marginRight: (xsDown) ? '0px' : '15px' }} onClick={handleCloseDialog}>{t('INFLUENCER_AGENT.VIEWS.INFLUENCERS.POPUP_INFLUENCER.INFLUENCER_DIALOG.DIALOG_BUTTON_CANCEL')}</Button>
                    <Button className={classes.buttonAssocier} onClick={handleDeleteAssociation} style={(xsDown) ? { marginBottom: '10px' } : { marginBottom: '0' }}>{t('INFLUENCER_AGENT.VIEWS.INFLUENCERS.POPUP_INFLUENCER.INFLUENCER_DIALOG.DIALOG_BUTTON_DELETE')}</Button>
                </Grid>
            </Dialog>
            <Grid container item direction='row' className={classes.overallBoxContainer}>
                <Box className={classes.overallBoxStyles}>
                    <Grid container direction="column" alignItems="center" justify="center" style={{ display: "flex" }}>
                        <Grid container item direction='row-reverse' sm={12}>
                            <IconButton onClick={closePopup} style={{ marginTop: '30px', marginRight: '30px', color: '#000' }}>
                                <CloseIcon fontSize='large' />
                            </IconButton>
                        </Grid>
                        <Grid container item direction='row' justify='center' className={classes.spacement}>
                            <Grid container item direction='row' sm={11} justify='center'>
                                <Grid container item direction='row' justify='center' wrap='wrap' alignItems='center'>
                                    {
                                        (influencer.showStats)
                                            ? <>
                                                <Grid container item lg={4} md={8} sm={12}>
                                                    {/* <Box className={classes.influencerImageBox}> */}
                                                    <img src={influencer.banner} alt={influencer.name} className={classes.influencerImage} />
                                                    {/* </Box> */}
                                                </Grid>
                                                <Grid container item lg={2} md={4} sm={12} justify='center' alignItems='center' direction='column' className={classes.influencerInfosBox}>
                                                    <Typography className={classes.influencerName}>{influencer.name}</Typography>
                                                    {(influencer.socialMedias !== undefined && influencer.socialMedias.instagram !== undefined)
                                                        ? <Typography>
                                                            <a
                                                                className={classes.influencerInstagram}
                                                                href={influencer.socialMedias.instagram}
                                                                target='_blank'
                                                                rel='noopener noreferrer'>@instagram</a>
                                                        </Typography> : <></>}
                                                    {
                                                        buttonToRender
                                                    }
                                                </Grid>
                                                <Grid container item justify='center' lg={3} md={6} sm={12}>
                                                    <Box className={classes.smallBox}>
                                                        <Grid container direction='row'>
                                                            <Grid container item xs={6} justify='center'>
                                                                <SVGIcon name='ventes' width={'60px'} height={'60px'} />
                                                            </Grid>
                                                            <Grid container item xs={6} direction='column' justify='center'>
                                                                <Typography className={classes.influencerStatsTitles}>{t('INFLUENCER_AGENT.VIEWS.INFLUENCERS.POPUP_SALES_PLACEHOLDER')}</Typography>
                                                                <Typography className={classes.influencerStats}>{influencer.salesNumber}</Typography>
                                                            </Grid>
                                                        </Grid>
                                                    </Box>
                                                </Grid>
                                                <Grid container item justify='center' lg={3} md={6} xs={12}>
                                                    <Box className={classes.smallBox}>
                                                        <Grid container direction='row'>
                                                            <Grid container item xs={6} justify='center'>
                                                                <EuroSymbolIcon style={{ fontSize: '60px', color: '#ef7e3e' }} />
                                                            </Grid>
                                                            <Grid container item xs={6} direction='column' justify='center'>
                                                                <Typography className={classes.influencerStatsTitles}>{t('INFLUENCER_AGENT.VIEWS.INFLUENCERS.POPUP_TURNOVER_PLACEHOLDER')}</Typography>
                                                                <Typography className={classes.influencerStats}>{Math.round(influencer.amount * 100) / 100} €</Typography>
                                                            </Grid>
                                                        </Grid>
                                                    </Box>
                                                </Grid>
                                            </>
                                            : <>
                                                <Grid container item lg={6} md={8} sm={12} jutify='center'>
                                                    <Box className={classes.influencerImageBox}>
                                                        <img src={influencer.banner} alt={influencer.name} className={classes.influencerImage} />
                                                    </Box>
                                                </Grid>
                                                <Grid container item lg={6} md={4} sm={12} justify='center' alignItems='center' direction='column' className={classes.influencerInfosBox}>
                                                    <Typography className={classes.influencerName}>{influencer.name}</Typography>
                                                    {(influencer.socialMedias !== undefined && influencer.socialMedias.instagram !== undefined)
                                                        ? <Typography>
                                                            <a
                                                                className={classes.influencerInstagram}
                                                                href={influencer.socialMedias.instagram}
                                                                target='_blank'
                                                                rel='noopener noreferrer'>@instagram</a>
                                                        </Typography> : <></>}
                                                    {buttonToRender}
                                                </Grid>

                                            </>
                                    }
                                </Grid>
                            </Grid>
                        </Grid>
                        <Grid container item direction='row' xs={11} wrap='wrap'>
                            {influencerWishlists.map(wishlist => (
                                <Button
                                    key={wishlist.id}
                                    className={(wishlist.id === activeButtonId) ? classes.chipActiveButton : classes.chipButton}
                                    onClick={() => { handleWishlistClick(wishlist) }}>
                                    {wishlist.name}
                                </Button>
                            ))}
                        </Grid>
                        <Grid container item direction='row' justify='center' xs={11} className={classes.wishlistsBox}>
                            <Grid container item direction='row' justify='center' xs={11} className={classes.articlesContainer}>
                                <>
                                    {
                                        (!loadedWishlistsPerInfluencer)
                                            ? <TrendLoading />
                                            : <>
                                                {
                                                    (activeArticles.length === 0) ? (<Typography className={classes.influencerStats}>{t('INFLUENCER_AGENT.VIEWS.INFLUENCERS.EMPTY_WISHLIST_PLACEHOLDER')}</Typography>)
                                                        : activeArticles.map(article => (
                                                            <Box key={article.id} className={classes.articleBox}>
                                                                <a href={article.url} target='_blank' rel='noopener noreferrer'>
                                                                    <img alt={article.url} src={article.image} className={classes.articleImage} />
                                                                </a>
                                                            </Box>
                                                        ))
                                                }
                                            </>
                                    }
                                </>
                            </Grid>
                        </Grid>

                    </Grid>
                </Box>
            </Grid>
        </Grid>
    );
};

const mapStateToProps = (state, ownProps) => {
    const allInfluencerAgents = state.influencerAgentsState.influencerAgents;
    const loadedInfluencerAgents = state.influencerAgentsState.loadedInfluencerAgents;
    const loadingUpdateInfluencerAgentClaims = state.influencerAgentsState.loadingUpdateInfluencerAgentClaims;
    const loadedWishlistsPerInfluencer = state.wishlistsState.loadedWishlistsPerInfluencer;
    const wishlistsPerInfluencer = state.wishlistsState.wishlistsPerInfluencer;
    return {
        allInfluencerAgents,
        loadedInfluencerAgents,
        loadingUpdateInfluencerAgentClaims,
        loadedWishlistsPerInfluencer,
        wishlistsPerInfluencer
    }
}

const mapDispatchToProps = (dispatch) => {
    return {
        updateClaimsInfluencerAgent: (influencerAgentId, updates) => dispatch(updateClaimsInfluencerAgent(influencerAgentId, updates)),
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(InfluencerModal);
