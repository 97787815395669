import React, { useState, useEffect } from 'react';
import { LoadingScreen, Unauthorized } from "../ui";
import Template from "./template";
import Router from "./Router";

const Admin = (props) => {

    const [user, setUser] = useState(null);
    const [userClaims, setUserClaims] = useState(null);
    const [updatedClaims, setUpdatedClaims] = useState(false);
    const [loading, setLoading] = useState(true);
    const [brandNameTrend, setBrandNameTrend] = useState(null);
    const { history } = props;

    useEffect(() => {
        setUser(props.user);
        setLoading(true);
        if (props.user === undefined) setLoading(false);
    }, [props.user]);

    useEffect(() => {
        if (user) {
            user.getIdTokenResult().then(idTokenResult => {
                setUpdatedClaims(true);
                setUserClaims(idTokenResult.claims);
                setLoading(false);
            }).catch(err => console.log(err))
        } else {
            setLoading(false);
        }
    }, [user]);

    const redirectTrend = (brandName) => {
        setBrandNameTrend(brandName);
    }
    useEffect(() => {
        if (brandNameTrend !== null && brandNameTrend !== undefined) {
            history.push(`/trend/${brandNameTrend}`);
        }

    }, [history, brandNameTrend])


    const renderContent = () => {
        if (loading) {
            return <LoadingScreen />
        } else {
            if (user) {
                if (updatedClaims) {
                    if (userClaims && userClaims.admin) {
                        if ((props.location.pathname !== "/admin/influencers/export") && (props.location.pathname !== "/admin/brands/export")) {
                            return (
                                <Template >
                                    <Router redirectTrendAdmin={(brandName) => redirectTrend(brandName)} history={props.history} />
                                </Template>
                            )
                        } else {
                            return (
                                <Router redirectTrendAdmin={(brandName) => redirectTrend(brandName)} history={props.history} />
                            )
                        }
                    } else {
                        return <Unauthorized />;
                    }
                } else {
                    return <LoadingScreen />
                }
            } else {
                return <></>
            }
        }
    }

    return renderContent();
}

export default Admin;