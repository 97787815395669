import React from "react";
import { DualRing } from "./components";

const LoadingScreen = () => {
  return (
    <div
      style={{
        height: "100vh",
        width: "100vw",
        display: "flex",
        justifyContent: "center",
        justifyItems: "center",
        alignContent: "center",
        alignItems: "center",
      }}
    >
      <DualRing />
    </div>
  );
};

export default LoadingScreen;
