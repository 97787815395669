import React from 'react';

const Distributors = (props) => {

    return (
        <>
        DISTRIBUTORS
        </>
    )
}

export default Distributors;