import React,{ useEffect,useState } from "react";
import { useParams } from "react-router-dom";
/* Relative Dependencies */
import { dbManager } from "../managers";
/* UI Scope */
import { LoadingScreen,Unauthorized } from "../ui";
/* Component Scope */
import Template from "./template";
import Router from "./Router";
import { connect } from "react-redux";

const Trends = (props) => {
  /* Props */
  const {
    clearBrandWishlists,
    clearBrandConversions,
    clearBrandArticles,
    clearUsersPerOfferdId
  } = props;

  /* State */
  const [user,setUser] = useState(props.use);
  const [userClaims,setUserClaims] = useState();
  const [updatedClaims,setUpdatedClaims] = useState(false);
  const [brand,setBrand] = useState(null);
  const [loading,setLoading] = useState(true);
  const [brandLoading,setBrandLoading] = useState(true);
  const [activeButton,setActiveButton] = useState("active");

  /* Hooks */
  const { brandName } = useParams();
  useEffect(() => {
    setUser(props.user);
  },[props.user]);

  /* State hooks */
  useEffect(() => {
    if (user) {
      setLoading(true);
      user
        .getIdTokenResult()
        .then((idTokenResult) => {
          setUserClaims(idTokenResult.claims);
          setUpdatedClaims(true);
        }
        )
        .catch((err) => console.log(err));
    } else {
      setLoading(false);
    }
  },[user]);

  useEffect(() => {
    if (brandName && user) {
      setLoading(true);
      setBrandLoading(true);
      dbManager.fetchBrand(brandName).then((brd) => {
        setBrand(brd);
      });
    }
  },[brandName,user]);

  useEffect(() => {
    if (brand && updatedClaims) {
      setLoading(false);
      setBrandLoading(false);
    }
  },[updatedClaims,brand]);


  const valueActiveButton = (active) => {
    setActiveButton(active);
  }
  const redirectDashboardAdmin = () => {
    clearBrandWishlists();
    clearBrandConversions();
    clearBrandArticles();
    clearUsersPerOfferdId();
    props.history.push("/admin/partners");
  }
  //props.history.push("/admin");
  const renderContent = () => {
    if (loading) {
      return <LoadingScreen />
    } else {
      if (user) {
        if (brandLoading) {
          return <LoadingScreen />
        } else {
          if (userClaims && updatedClaims && (userClaims.admin || ((brand !== undefined) && (userClaims.isBrand || userClaims.brand) && userClaims.brandOfferId === brand.offerId) ||
            (userClaims.isBrandAgent && (userClaims.brandsOfferIds.indexOf(brand.offerId) !== -1)))) {
            return (
              <Template brand={ brand } admin={ userClaims.admin } activeButtonInfluencer={ activeButton } claims={ userClaims } redirectAdmin={ () => redirectDashboardAdmin() }>
                <Router brand={ brand } activeButtonTrend={ valueActiveButton } admin={ userClaims.admin } brandAdmin={ userClaims.brandAdmin } />
              </Template>
            )
          } else {
            return (
              <Unauthorized />
            );
          }
        }
      } else {
        return <></>
      }
    }
  };

  return renderContent();
};

const mapDispatchToState = (dispatch) => {
  return {
    clearBrandWishlists: () => dispatch({ type: 'CLEAR_BRAND_WISHLISTS' }),
    clearBrandConversions: () => dispatch({ type: 'CLEAR_BRAND_CONVERSIONS' }),
    clearBrandArticles: () => dispatch({ type: 'CLEAR_BRAND_ARTICLES' }),
    clearUsersPerOfferdId: () => dispatch({ type: 'CLEAR_BRAND_USER_BY_OFFERID' }),
  }
}

export default connect(undefined,mapDispatchToState)(Trends);
