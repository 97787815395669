import { Fab, makeStyles } from '@material-ui/core';
import DeleteOutlineIcon from '@material-ui/icons/DeleteOutline';
import EditIcon from '@material-ui/icons/Edit';
// import LinkIcon from '@material-ui/icons/Link';
import ShareIcon from '@material-ui/icons/Share';
import FilterNoneIcon from '@material-ui/icons/FilterNone';
import React from 'react';
import { useTranslation } from "react-i18next";

const useStyle = makeStyles(theme => ({
    smAppBarRow: {
        display: 'flex',
        flexDirection: 'row',
        justifyContent: 'center',
        alignItems: 'center',
        backgroundColor: "#eff9fe",
    },
    appBar: {
        width: '100%',
        height: '100%',
        borderRadius: '25px',
        display: 'flex',
        flexDirection: 'row',
        alignItems: 'flex-start',
        justifyContent: 'space-around',
        flexWrap: 'wrap'
    },
    actionButton: {
        margin: '10px',
        backgroundColor: '#fff',
        minWidth: '200px',
        color: '#00000082',
        fontSize: '14px',
        height: '40px',
        textTransform: 'none',
        border: 'solid 1px #00000029',
        fontFamily: 'Poppins',
        boxShadow: '0 7px 30px 0 rgb(88 165 211 / 36%)',
        '&:hover': {
            backgroundColor: '#fff'
        }
    }
}));

const ActionBar = (props) => {

    const classes = useStyle();
    const { t } = useTranslation("common");

    const deleteWishlist = () => {
        props.deleteWishlist();
    };

    const renameWishlist = () => {
        props.renameWishlist();
    };

    const copyUrl = () => {
        let inputc = document.body.appendChild(document.createElement("input"));
        inputc.value = window.location.href;
        inputc.focus();
        inputc.select();
        document.execCommand('copy');
        inputc.parentElement.removeChild(inputc);
        props.copyUrl();
    };

    // const exportLinks = () => {
    //     props.exportLinks();
    // };

    const shareWishlist = () => {
        props.shareWishlist();
    };

    return (
        <div className={classes.smAppBarRow}>
            <div className={classes.appBar}>
                <Fab variant="extended" className={classes.actionButton} onClick={deleteWishlist}>
                    <DeleteOutlineIcon style={{ margin: '5px' }} />
                    {t('INFLUENCER.ACTION_BAR.REMOVE_WISHLIST')}
                </Fab>
                <Fab variant="extended" className={classes.actionButton} onClick={renameWishlist}>
                    <EditIcon style={{ margin: '5px' }} />
                    {t('INFLUENCER.ACTION_BAR.RENAME_WISHLIST')}
                </Fab>
                <Fab variant="extended" className={classes.actionButton} onClick={copyUrl}>
                    <FilterNoneIcon style={{ margin: '5px' }} />
                    {t('INFLUENCER.ACTION_BAR.COPY_URL')}
                </Fab>
                {/* <Fab variant="extended" className={classes.actionButton} onClick={exportLinks}>
                    <LinkIcon style={{ margin: '5px' }} />
                    Exporter les liens
                </Fab> */}
                <Fab variant="extended" className={classes.actionButton} onClick={shareWishlist}>
                    <ShareIcon style={{ margin: '5px' }} />
                    {t('INFLUENCER.ACTION_BAR.SHARE_WISHLIST')}
                </Fab>

            </div>
        </div>
    );
};

export default ActionBar;