import React from "react";
import { Route,Switch,Redirect } from "react-router-dom";
import { connect } from "react-redux";
// Component Scope
import { auth } from "./managers";
import {
  Main,
  Admin,
  // Talent,
  Trend,
  // EShop,
  BrandsAgent,
  InfluencersAgent,
  Influencer,
  Gallery
} from ".";
import { Login } from "./login";
import { Register } from "./register";
import PrivateRoute from "./PrivateRoute";

const Router = (props) => {
  const { user,clearAuth } = props;
  return (
    <Switch>
      <PrivateRoute
        path="/trend/:brandName"
        component={ Trend }
        user={ user }
      />
      <PrivateRoute path="/brandsAgent" component={ BrandsAgent } user={ user } />
      <PrivateRoute
        path="/influencersAgent"
        component={ InfluencersAgent }
        user={ user }
      />
      <PrivateRoute path="/admin" component={ Admin } user={ user } history={ props.history } />
      <Route
        path="/logout"
        exact
        render={ () => {
          clearAuth();
          auth.signOut();
          return <Redirect to="/" />;
        } }
      />
      <Route
        path="/login"
        render={ (props) => <Login { ...props } user={ user } /> }
      />
      <Route path="/register" render={ (props) => <Register { ...props } /> } />
      <Route
        path={ ["/influencer/wishlists","/influencer/mywishlist","/influencer/embed"] }
        render={ (props) => <Influencer { ...props } user={ user } /> } />
      <Route
        path={ ["/wishlists","/mywishlist","/embed"] } render={ (props) => <Gallery { ...props } /> } />
      {/* <Route path="/talent" exact>
        <Talent user={user} translations={translations} />
      </Route>
      <Route path={["eshop/:brandId/:uid", "/eshop/:brandId"]}>
        <EShop user={user} />
      </Route> */}
      <Route path="/">
        <Main user={ user } />
      </Route>
    </Switch>
  );
};
const mapDispatchToProps = (dispatch) => {
  return {
    clearAuth: () => dispatch({ type: "CLEAR_AUTH" }),
  };
};

export default connect(null,mapDispatchToProps)(Router);
