import React, { useState, useEffect, useCallback } from "react";
import {
  Grid,
  Button,
  Box,
  Typography,
  Select,
  MenuItem,
  Card,
  CircularProgress,
} from "@material-ui/core";
import { makeStyles } from "@material-ui/core";
import { Pagination, PaginationItem } from "@material-ui/lab";
import { clicksManager } from "../../managers";
import SVGIcon from "../template/assets/SVGIcons";
import ExpandLessIcon from "@material-ui/icons/ExpandLess";
import ExpandMoreIcon from "@material-ui/icons/ExpandMore";
import SortIcon from "@material-ui/icons/Sort";
import InfluencerModal from "../template/components/influencerModal";
import TrendLoading from "../../ui/trendLoading/TrendLoading";
import BootstrapInput from "../template/assets/customComponents/BootstrapInput";
import PlaceholderInfluenceurs from "../assets/placeholder_influenceur.png";
import { useTranslation } from "react-i18next";
import { connect } from "react-redux";
import { fetchWishlistsNames, fetchWishlistsPerInfluencer } from "../../../store/actions/wishlistsActions";

const useStyles = makeStyles((theme) => {
  return {
    influenceursContent: {
      flexGrow: 1,
      display: "flex",
      flexDirection: "column",
      backgroundColor: "#eff9fe",
    },
    buttonsRow: {
      width: "100%",
      marginTop: "15px",
      marginBottom: "15px",
    },
    activeButtonStyle: {
      width: 300,
      margin: "15px",
      color: "#ffffff",
      borderRadius: "35px",
      backgroundColor: "#3eadef",
      fontSize: "16px",
      textTransform: "none",
      fontFamily: "Poppins",
      boxShadow: "0 7px 30px 0 rgba(88, 165, 211, 0.16)",
      "&:hover": {
        backgroundColor: "#3eadef",
      },
    },
    buttonStyle: {
      width: 300,
      margin: "15px",
      color: "#000",
      borderRadius: "35px",
      backgroundColor: "#ffffff",
      fontSize: "16px",
      textTransform: "none",
      fontFamily: "Poppins",
      boxShadow: "0 7px 30px 0 rgba(88, 165, 211, 0.16)",
    },
    influencerBoxContainer: {
      marginTop: "10px",
      marginBottom: "10px",
      minWidth: "1050px",
    },
    influencerBox: {
      display: "flex",
      width: "95%",
      backgroundColor: "white",
      height: "80px",
      borderRadius: "27px",
      boxShadow: "0 7px 30px 0 rgba(88, 165, 211, 0.16)",
    },
    influencerImage: {
      width: "160px",
      height: "80px",
      borderTopLeftRadius: "27px",
      borderBottomLeftRadius: "27px",
      objectFit: "fill",
    },
    paginationRow: {
      display: "flex",
      justifyContent: "center",
      marginTop: "10px",
      marginBottom: "10px",
    },
    tableHeader: {
      fontFamily: "Poppins",
      fontSize: "14px",
      textAlign: "center",
      "&:hover": {
        cursor: "pointer",
      },
    },
    tableHeaderIcon: {
      "&:hover": {
        cursor: "pointer",
      },
    },
    tableRowContent: {
      fontFamily: "Poppins",
    },
    influencerName: {
      fontFamily: "Poppins",
      textDecoration: "underline",
      "&:hover": {
        cursor: "pointer",
      },
    },
    paginationClass: {
      backgroundColor: "#ffffff !important",
      boxShadow: "0 7px 30px 0 rgba(88, 165, 211, 0.16)",
      borderRadius: "8px",
      fontFamily: "Poppins",
      height: "40px",
      width: "40px",
      display: "flex",
      justifyContent: "center",
      alignItems: "center",
    },
    selectedPaginationItem: {
      backgroundColor: "#3eadef !important",
      boxShadow: "0 7px 30px 0 rgba(88, 165, 211, 0.16)",
      borderRadius: "8px",
      fontFamily: "Poppins",
      height: "40px",
      width: "40px",
      display: "flex",
      justifyContent: "center",
      alignItems: "center",
      color: "#ffffff",
    },
    selectClass: {
      fontFamily: "Poppins",
    },
    influencerCard: {
      margin: "10px",
      width: "280px",
      height: "140px",
      borderRadius: "24px",
      boxShadow: "0 7px 30px 0 rgba(24, 61, 83, 0.16)",
      "&:hover": {
        cursor: "pointer",
      },
    },
    influencerCardImage: {
      objectFit: "contain",
      width: "280px",
      height: "140px",
      borderRadius: "24px",
    },
    textPlaceholder: {
      fontFamily: "Poppins",
      fontSize: "16px",
      margin: 10,
      color: "#1b4c61",
      textAlign: "center",
    },
  };
});

const Influenceurs = (props) => {
  const { t } = useTranslation("common");
  const influencerSearch = props.influencerSearch;
  const classes = useStyles();

  const [activeButton, setActiveButton] = useState("normal");
  const [brandInfluencersIds, setBrandInfluencersIds] = useState([]);
  const [allInfluencers, setAllInfluencers] = useState([]);
  const [myInfluencers, setMyInfluencers] = useState([]);

  const [influencersToRender, setInfluencersToRender] = useState([]);
  const [activeInfluencer, setActiveInfluencer] = useState(null);
  const [openPopover, setOpenPopver] = useState(false);

  const [brandInfluencers, setBrandInfluencers] = useState([]);

  const [brandInfluencersClicks, setBrandInfluencersClicks] = useState([]);
  const [loadedBrandInfluencersClicks, setLoadedBrandInfluencersClicks] = useState(false);

  const [myInfluencersUnknownWishlists, setMyInfluencersUnknownWishlists] = useState({});
  const [loadedMyInfluencersUnknownWishlists, setLoadedMyInfluencersUnknownWishlists] = useState(false);
  const [myInfluencersWishlists, setMyinfluencersWishlists] = useState({});
  const [loadedmyInfluencersWishlists, setLoadedMyInfluencersWishlists] = useState(false);
  const [wishlistsPerMyInfluencers, setWishlistsPerMyInfluncers] = useState({});
  const [loadedWishlistsPerMyInfluencers, setLoadedWishlistsPerMyInfluencers] = useState(false);
  const [myInfluencersCalculated, setMyInfluencersCalculated] = useState(false);
  const [loadedFilteredInfluencers, setLoadedFilteredInfluencers] = useState(false);

  const [page, setPage] = useState(1);
  const [size, setSize] = useState(10);
  const [count, setCount] = useState(0);
  const [sortHelper, setSortHelper] = useState([1, 0, 0, 0, 0]);
  const [sortEvent, setSortEvent] = useState(0);

  const {
    brand,
    brandConversions,
    influencers,
    wishlistsNames,
    articlesByOfferId,
    loadedBrandConversions,
    loadedInfluencers,
    loadedArticlesByOfferId,
    loadedWishlistsNames,
    appStateSortHelper,
    appStatePageNumber,
    appStatePageSize,
    changeSortHelper,
    changePageNumber,
    changePageSize,
    filterSearch,
    fetchWishlistsPerInfluencer,
    wishlistsPerInfluencerByIdRequest,
    fetchWishlistsNames,
    setWishlistsPerBrandInfluencers
  } = props;

  const handleActiveButton = (type) => {
    if (type === "all") {
      setActiveButton("active");
    } else {
      setActiveButton("normal");
    }
    changePageNumber(1);
  };

  const handleSearchOfInfluencer = (button) => {
    props.activeButtonInfluencer(button);
  };

  const handlePageChange = (event, value) => {
    changePageNumber(value);
  };

  const handlePageSizeChange = (event) => {
    changePageSize(event.target.value);
    changePageNumber(1);
  };

  const handleOpenPopover = useCallback(
    (influencer) => {
      const commonInfluencer = brandInfluencers.filter(
        (element) => element.key === influencer.key
      );
      commonInfluencer.length === 1
        ? setActiveInfluencer({
          ...commonInfluencer[0],
          influencerId: influencer.key,
          showStats: true,
        })
        : setActiveInfluencer({
          ...influencer,
          influencerId: influencer.key,
          showStats: false,
        });
      setOpenPopver(true);
    },
    [brandInfluencers]
  );

  const handleClosePopover = () => {
    setOpenPopver(false);
    setActiveInfluencer(null);
  };

  const getInfluencersWishlistsPerId = (influencerId) => {
    wishlistsPerInfluencerByIdRequest();
    fetchWishlistsPerInfluencer(influencerId);
  };

  useEffect(() => {
    setSortHelper(appStateSortHelper);
  }, [appStateSortHelper]);

  useEffect(() => {
    setPage(appStatePageNumber);
  }, [appStatePageNumber]);

  useEffect(() => {
    setSize(appStatePageSize);
  }, [appStatePageSize]);

  // Influencers Init EFFECT
  useEffect(() => {
    if (loadedInfluencers) {
      let influencersInfos = [];
      influencers.forEach((influencer) => {
        influencersInfos.push({
          ...influencer,
          amount: 0,
          commission: 0,
          salesNumber: 0,
          product: 0,
        });
      });
      setAllInfluencers(
        influencersInfos.filter(
          (element) =>
            element.name !== undefined &&
            element.name !== null &&
            element.name !== ""
        )
      );
    }
  }, [influencers, loadedInfluencers]);

  // Render EFFECT
  useEffect(() => {
    if (activeButton === "normal") {
      let filteredInfluencers = brandInfluencers.filter(influencer => {
        if (influencer.name !== undefined && filterSearch !== null) {
          return influencer.name.toLowerCase().indexOf(filterSearch.toLowerCase()) !== -1
        } else {
          return influencer;
        }
      });
      setInfluencersToRender(
        filteredInfluencers.slice((page - 1) * size, page * size)
      );
      setCount(
        brandInfluencers.length % size === 0
          ? Math.trunc(brandInfluencers.length / size)
          : Math.trunc(brandInfluencers.length / size) + 1
      );
    } else {
      setInfluencersToRender(
        allInfluencers.slice((page - 1) * size, page * size)
      );
      setCount(
        allInfluencers.length % size === 0
          ? Math.trunc(allInfluencers.length / size)
          : Math.trunc(allInfluencers.length / size) + 1
      );
    }
  }, [brandInfluencers, allInfluencers, activeButton, size, page, sortEvent, filterSearch]);

  // SORT EFFECT
  useEffect(() => {
    let indexAsc = sortHelper.indexOf(1);
    let indexDesc = sortHelper.indexOf(-1);
    if (indexAsc !== -1) {
      const sortTypeAsc = (indexAsc, tableToWorkOn) => {
        switch (indexAsc) {
          case 0:
            tableToWorkOn.sort((a, b) => {
              if (a.amount > b.amount) return -1;
              if (a.amount < b.amount) return 1;
              return 0;
            });
            break;
          case 1:
            tableToWorkOn.sort((a, b) => {
              if (a.salesNumber > b.salesNumber) return -1;
              if (a.salesNumber < b.salesNumber) return 1;
              return 0;
            });
            break;
          case 2:
            tableToWorkOn.sort((a, b) => {
              if (a.product > b.product) return -1;
              if (a.product < b.product) return 1;
              return 0;
            });
            break;
          case 3:
            tableToWorkOn.sort((a, b) => {
              if (a.commission > b.commission) return -1;
              if (a.commission < b.commission) return 1;
              return 0;
            });
            break;
          case 4:
            tableToWorkOn.sort((a, b) => {
              if (a.clicks === undefined && b.clicks !== undefined) return 1;
              if (a.clicks !== undefined && b.clicks === undefined) return -1;
              if (a.clicks === undefined && b.clicks === undefined) return 0;
              if (a.clicks > b.clicks) return -1;
              if (a.clicks < b.clicks) return 1;
              return 0;
            });
            break;
          default:
            break;
        }
      };
      if (activeButton === "normal") {
        sortTypeAsc(indexAsc, brandInfluencers);
      } else {
        sortTypeAsc(indexAsc, allInfluencers);
      }
    } else {
      const sortTypeDesc = (indexDesc, tableToWorkOn) => {
        switch (indexDesc) {
          case 0:
            tableToWorkOn.sort((a, b) => {
              if (a.amount > b.amount) return 1;
              if (a.amount < b.amount) return -1;
              return 0;
            });
            break;
          case 1:
            tableToWorkOn.sort((a, b) => {
              if (a.salesNumber > b.salesNumber) return 1;
              if (a.salesNumber < b.salesNumber) return -1;
              return 0;
            });
            break;
          case 2:
            tableToWorkOn.sort((a, b) => {
              if (a.product > b.product) return 1;
              if (a.product < b.product) return -1;
              return 0;
            });
            break;
          case 3:
            tableToWorkOn.sort((a, b) => {
              if (a.commission > b.commission) return 1;
              if (a.commission < b.commission) return -1;
              return 0;
            });
            break;
          case 4:
            tableToWorkOn.sort((a, b) => {
              if (a.clicks === undefined && b.clicks !== undefined) return 1;
              if (a.clicks !== undefined && b.clicks === undefined) return -1;
              if (a.clicks === undefined && b.clicks === undefined) return 0;
              if (a.clicks > b.clicks) return 1;
              if (a.clicks < b.clicks) return -1;
              return 0;
            });
            break;
          default:
            break;
        }
      };
      if (activeButton === "normal") {
        sortTypeDesc(indexDesc, brandInfluencers);
      } else {
        sortTypeDesc(indexDesc, allInfluencers);
      }
    }
    setSortEvent((s) => s + 1);
  }, [sortHelper, activeButton, allInfluencers, brandInfluencers]);

  // External Popover opening EFFECT
  useEffect(() => {
    if (influencerSearch !== null) {
      handleOpenPopover(influencerSearch);
    } else {
      handleClosePopover();
    }
  }, [influencerSearch, handleOpenPopover]);

  //////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////

  useEffect(() => {
    if (brand && brand.offerId) {
      setLoadedBrandInfluencersClicks(false);
      clicksManager.getClicksPerBrand(0, new Date().getTime(), brand.offerId, new Date().getTimezoneOffset()).then(result => {
        setBrandInfluencersClicks(result.data.influencersClicks);
        setLoadedBrandInfluencersClicks(true);
      })
    }
  }, [brand]);

  useEffect(() => {
    if (loadedBrandInfluencersClicks && loadedBrandConversions) {
      let brandInfluencersIdsFromClicks = Object.keys(brandInfluencersClicks);
      let missingInfluencersIds = brandConversions.filter(conversion => {
        return conversion.influencer !== undefined && brandInfluencersIdsFromClicks.indexOf(conversion.influencer) === -1
      }).map(conversion => conversion.influencer);
      setBrandInfluencersIds(brandInfluencersIdsFromClicks.concat(missingInfluencersIds));
    }
  }, [brandInfluencersClicks, loadedBrandInfluencersClicks, brandConversions, loadedBrandConversions]);

  useEffect(() => {
    if (loadedArticlesByOfferId) {
      setLoadedMyInfluencersUnknownWishlists(false);
      let myInfluencersWishlists = {};
      articlesByOfferId.forEach(article => {
        if (myInfluencersWishlists[article.wishlistId]) {
          myInfluencersWishlists[article.wishlistId].articles[article.key] = article;
        } else {
          myInfluencersWishlists[article.wishlistId] = { articles: {} };
          myInfluencersWishlists[article.wishlistId].articles[article.key] = article;
        }
      });
      setMyInfluencersUnknownWishlists(myInfluencersWishlists);
      setLoadedMyInfluencersUnknownWishlists(true)
    }
  }, [loadedArticlesByOfferId, articlesByOfferId]);

  useEffect(() => {
    if (loadedMyInfluencersUnknownWishlists && !loadedWishlistsNames) {
      fetchWishlistsNames(Object.keys(myInfluencersUnknownWishlists));
    }
  }, [myInfluencersUnknownWishlists, fetchWishlistsNames, loadedMyInfluencersUnknownWishlists, loadedWishlistsNames]);

  useEffect(() => {
    if (loadedWishlistsNames && loadedMyInfluencersUnknownWishlists) {
      let myInfluencersWishlistsToEdit = { ...myInfluencersUnknownWishlists };
      Object.keys(wishlistsNames).forEach((wishlistId) => {
        myInfluencersWishlistsToEdit[wishlistId]['uid'] = wishlistsNames[wishlistId];
        myInfluencersWishlistsToEdit[wishlistId]['name'] = wishlistsNames[wishlistId];
      });
      setMyinfluencersWishlists(myInfluencersWishlistsToEdit);
      setLoadedMyInfluencersWishlists(true);
    }
  }, [myInfluencersUnknownWishlists, wishlistsNames, loadedWishlistsNames, loadedMyInfluencersUnknownWishlists]);

  useEffect(() => {
    if (loadedmyInfluencersWishlists && loadedBrandInfluencersClicks) {
      setLoadedWishlistsPerMyInfluencers(false);
      let formattedWishlistArray = Object.values(myInfluencersWishlists).map((wishlist, index) => {
        const wishlistArticles = Object.values(wishlist.articles);
        let uid;
        for (let i = 0; i < wishlistArticles.length; i++) {
          if (wishlistArticles[i] && wishlistArticles[i].uid) {
            uid = wishlistArticles[i].uid;
            break;
          };
        };
        return {
          ...wishlist,
          uid,
          key: Object.keys(myInfluencersWishlists)[index]
        };
      });
      let formattedWishlistsPerMyInfluencers = {};
      formattedWishlistArray.forEach(wishlist => {
        if (wishlist.name) {
          if (formattedWishlistsPerMyInfluencers[wishlist.uid]) {
            formattedWishlistsPerMyInfluencers[wishlist.uid].push(wishlist);
          } else {
            formattedWishlistsPerMyInfluencers[wishlist.uid] = [wishlist];
          };
        };
      });
      setWishlistsPerMyInfluncers(formattedWishlistsPerMyInfluencers);
      setWishlistsPerBrandInfluencers(formattedWishlistsPerMyInfluencers);
      setLoadedWishlistsPerMyInfluencers(true);
    }
  }, [brandInfluencersIds, myInfluencersWishlists, loadedmyInfluencersWishlists, loadedBrandInfluencersClicks, setWishlistsPerBrandInfluencers]);

  useEffect(() => {
    if (loadedWishlistsPerMyInfluencers && loadedInfluencers) {
      setMyInfluencersCalculated(false);
      let myInfluencersIds = Object.keys(wishlistsPerMyInfluencers);
      let filteredMyInfluencers = influencers.filter(influencer => (myInfluencersIds.indexOf(influencer.key) !== -1) && (influencer.name !== "") && (influencer.banner !== ""));
      setMyInfluencers(filteredMyInfluencers);
      setMyInfluencersCalculated(true);
    }
  }, [wishlistsPerMyInfluencers, influencers, loadedWishlistsPerMyInfluencers, loadedInfluencers]);

  useEffect(() => {
    if (myInfluencersCalculated && loadedBrandConversions && loadedInfluencers && loadedBrandInfluencersClicks) {
      setLoadedFilteredInfluencers(false);
      let myInfluencersToRender = [];
      brandConversions.forEach(conversion => {
        let influencer = myInfluencers.filter(influencer => conversion.influencer === influencer.key)[0];
        if (influencer) {
          let indexToModify;
          let tempInfluencer = myInfluencersToRender.filter((influencer, i) => {
            if (influencer.key === conversion.influencer) {
              indexToModify = i;
              return true;
            } else {
              return false;
            }
          })[0];
          if (tempInfluencer) {
            myInfluencersToRender[indexToModify] = {
              ...tempInfluencer,
              influencerId: tempInfluencer.key,
              salesNumber: tempInfluencer.salesNumber + 1,
              amount: tempInfluencer.amount + Number(conversion.amount),
              commission: tempInfluencer.commission + Number(conversion.commission),
              clicks: (brandInfluencersClicks[tempInfluencer.key] !== undefined) ? brandInfluencersClicks[influencer.key] : 0,
            };
            if (myInfluencersToRender[indexToModify].product.indexOf(conversion.articleId) === -1)
              tempInfluencer.product.push(conversion.articleId);
          } else {
            myInfluencersToRender.push({
              ...influencer,
              influencerId: influencer.key,
              salesNumber: 1,
              amount: Number(conversion.amount),
              product: [conversion.articleId],
              commission: Number(conversion.commission),
              clicks: (brandInfluencersClicks[influencer.key] !== undefined) ? brandInfluencersClicks[influencer.key] : 0,
            });
          }
        };
      });
      let influencersAlreadyCalculated = myInfluencersToRender.map(inf => inf.key);
      let temp = myInfluencers.filter(influencer => influencersAlreadyCalculated.indexOf(influencer.key) === -1);
      myInfluencersToRender = myInfluencersToRender.concat(temp.map(influencer => ({
        ...influencer,
        influencerId: influencer.key,
        salesNumber: 0,
        amount: 0,
        product: [],
        commission: 0,
        clicks: (brandInfluencersClicks[influencer.key] !== undefined) ? brandInfluencersClicks[influencer.key] : 0,
      }))).map(inf => ({ ...inf, product: inf.product.length }));
      setBrandInfluencers(myInfluencersToRender)
      setLoadedFilteredInfluencers(true);
    }
  }, [allInfluencers, loadedInfluencers, myInfluencersCalculated, loadedBrandConversions, brandConversions, myInfluencers, loadedBrandInfluencersClicks, brandInfluencersClicks])

  return (
    <Grid className={classes.influenceursContent}>
      {!loadedFilteredInfluencers ? (
        <TrendLoading />
      ) : (
          <>
            {openPopover === true ? (
              <InfluencerModal {...{ activeInfluencer, activeButton }} close={handleClosePopover} />
            ) : (
                <div>
                  <Grid container direction="row" justify="center" className={classes.buttonsRow} >
                    <Button className={activeButton === "normal" ? classes.activeButtonStyle : classes.buttonStyle}
                      onClick={() => { handleActiveButton("my"); handleSearchOfInfluencer(activeButton) }}
                      disableRipple>
                      {t("TREND.VIEWS.INFLUENCERS.MY_INFLUENCERS")}
                    </Button>
                    <Button className={activeButton === "active" ? classes.activeButtonStyle : classes.buttonStyle}
                      onClick={() => { handleActiveButton("all"); handleSearchOfInfluencer(activeButton) }}
                      disableRipple>
                      {t("TREND.VIEWS.INFLUENCERS.ALL_INFLUENCERS")}
                    </Button>
                  </Grid>
                  <Grid container item direction="row" xs={12} justify="center">
                    {activeButton === "active" ? (
                      <>
                        <Grid container item direction="row" wrap="wrap" justify="center" >
                          {influencersToRender.map((influencer) => (
                            <Card key={influencer.key} className={classes.influencerCard} onClick={() => handleOpenPopover(influencer)}>
                              <img className={classes.influencerCardImage} src={influencer.banner} alt={influencer.name} onClick={() => getInfluencersWishlistsPerId(influencer.key)} />
                            </Card>
                          ))}
                        </Grid>
                        <Grid container item direction="row-reverse" className={classes.paginationRow} >
                          <Grid container alignItems="center" justify="flex-end" style={{ display: "flex", width: "95%" }} >
                            <Select value={size} onChange={handlePageSizeChange} input={<BootstrapInput />} IconComponent={() => <SVGIcon name="miniArrow" />} classes={{ root: classes.selectClass }}>
                              <MenuItem value={5}>5</MenuItem>
                              <MenuItem value={10}>10</MenuItem>
                              <MenuItem value={20}>20</MenuItem>
                            </Select>
                            <Typography className={classes.tableHeader} style={{ marginRight: "10px", marginLeft: "10px" }}>
                              {t("TREND.VIEWS.INFLUENCERS.PAGINATION_PLACEHOLDER") + " "}
                              {activeButton === "normal" ? myInfluencers.length : allInfluencers.length}
                            </Typography>
                            <Pagination count={count} page={page} onChange={handlePageChange}
                              renderItem={(item) => (
                                <PaginationItem classes={{ root: classes.paginationClass, selected: classes.selectedPaginationItem }} {...item} />
                              )}
                            />
                          </Grid>
                        </Grid>
                      </>
                    ) : brandInfluencers.length === 0 ? (
                      <Grid container direction="column" justify="space-around" alignItems="center" style={{ height: "50%", marginTop: "100px" }} >
                        <img alt="No influencers" src={PlaceholderInfluenceurs} height="75px" width="75px" />
                        <Typography className={classes.textPlaceholder}>
                          {t("TREND.VIEWS.INFLUENCERS.NO_INFLUENCER_GENERATING_SALES_PLACEHOLDER")}
                        </Typography>
                      </Grid>
                    ) : influencersToRender.length === 0 ? (
                      <Grid container direction="column" justify="space-around" alignItems="center" style={{ height: "50%", marginTop: "100px" }}>
                        <img alt="No influencers" src={PlaceholderInfluenceurs} height="75px" width="75px" />
                        <Typography className={classes.textPlaceholder}>
                          {t("TREND.VIEWS.INFLUENCERS.NO_INFLUENCERS_SEARCH")}
                        </Typography>
                      </Grid>
                    ) : (
                            <>
                              <div style={{ overflowX: "auto", width: "100%", display: "flex", justifyContent: "center", flexDirection: "column", alignItems: "center" }}>
                                <Grid container item direction="row-reverse" style={{ minWidth: "1050px", width: "95%" }}>
                                  <Grid container item justify="center" alignItems="center" style={{ width: "13%" }}
                                    onClick={() => { sortHelper[0] === 0 ? changeSortHelper([1, 0, 0, 0, 0]) : changeSortHelper([-sortHelper[0], 0, 0, 0, 0]); }}>
                                    <Typography className={classes.tableHeader}>
                                      {t("TREND.VIEWS.INFLUENCERS.TABLE_HEADER.TURNOVER")}
                                    </Typography>
                                    {sortHelper[0] === 1 ? (
                                      <ExpandLessIcon className={classes.tableHeaderIcon} />
                                    ) : sortHelper[0] === -1 ? (
                                      <ExpandMoreIcon className={classes.tableHeaderIcon} />
                                    ) : (<SortIcon className={classes.tableHeaderIcon} />)}
                                  </Grid>
                                  <Grid container item justify="center" alignItems="center" style={{ width: "13%" }}
                                    onClick={() => { sortHelper[3] === 0 ? changeSortHelper([0, 0, 0, 1, 0]) : changeSortHelper([0, 0, 0, -sortHelper[3], 0]) }}
                                  >
                                    <Typography className={classes.tableHeader}>
                                      {t("TREND.VIEWS.INFLUENCERS.TABLE_HEADER.COMMISSIONS")}
                                    </Typography>
                                    {sortHelper[3] === 1 ? (
                                      <ExpandLessIcon className={classes.tableHeaderIcon} />
                                    ) : sortHelper[3] === -1 ? (
                                      <ExpandMoreIcon className={classes.tableHeaderIcon} />
                                    ) : (<SortIcon className={classes.tableHeaderIcon} />)}
                                  </Grid>
                                  <Grid container item justify="center" alignItems="center" style={{ width: "13%" }}
                                    onClick={() => { sortHelper[2] === 0 ? changeSortHelper([0, 0, 1, 0, 0]) : changeSortHelper([0, 0, -sortHelper[2], 0, 0]); }} >
                                    <Typography className={classes.tableHeader}>
                                      {t("TREND.VIEWS.INFLUENCERS.TABLE_HEADER.PRODUCTS_NUMBER")}
                                    </Typography>
                                    {sortHelper[2] === 1 ? (
                                      <ExpandLessIcon className={classes.tableHeaderIcon} />
                                    ) : sortHelper[2] === -1 ? (
                                      <ExpandMoreIcon className={classes.tableHeaderIcon} />
                                    ) : (
                                          <SortIcon className={classes.tableHeaderIcon} />
                                        )}
                                  </Grid>
                                  <Grid container item justify="center" alignItems="center" style={{ width: "13%" }}
                                    onClick={() => { sortHelper[1] === 0 ? changeSortHelper([0, 1, 0, 0, 0]) : changeSortHelper([0, -sortHelper[1], 0, 0, 0]); }} >
                                    <Typography className={classes.tableHeader}>
                                      {t("TREND.VIEWS.INFLUENCERS.TABLE_HEADER.SALES_NUMBER")}
                                    </Typography>
                                    {sortHelper[1] === 1 ? (
                                      <ExpandLessIcon className={classes.tableHeaderIcon} />
                                    ) : sortHelper[1] === -1 ? (
                                      <ExpandMoreIcon className={classes.tableHeaderIcon} />
                                    ) : (
                                          <SortIcon className={classes.tableHeaderIcon} />
                                        )}
                                  </Grid>
                                  <Grid container item justify="center" alignItems="center" style={{ width: "13%" }}
                                    onClick={() => { sortHelper[4] === 0 ? changeSortHelper([0, 0, 0, 0, 1]) : changeSortHelper([0, 0, 0, 0, -sortHelper[4]]); }}>
                                    <Typography className={classes.tableHeader}>
                                      {t("TREND.VIEWS.INFLUENCERS.TABLE_HEADER.CLICKS")}
                                    </Typography>
                                    {sortHelper[4] === 1 ? (
                                      <ExpandLessIcon className={classes.tableHeaderIcon} />
                                    ) : sortHelper[4] === -1 ? (
                                      <ExpandMoreIcon className={classes.tableHeaderIcon} />
                                    ) : (
                                          <SortIcon className={classes.tableHeaderIcon} />
                                        )}
                                  </Grid>
                                </Grid>
                                {influencersToRender.map((influencer) => {
                                  return (
                                    <Grid container item direction="row" justify="center" className={classes.influencerBoxContainer} key={influencer.key} >
                                      <Box className={classes.influencerBox}>
                                        <Grid container item direction="row" xs={12}>
                                          <Grid container item justify="flex-start" alignItems="center" style={{ width: "17%" }}>
                                            <img src={influencer.banner} alt="" className={classes.influencerImage} />
                                          </Grid>
                                          <Grid container item justify="center" alignItems="center" style={{ width: "18%" }} >
                                            <Typography className={classes.influencerName} onClick={() => handleOpenPopover(influencer)}>
                                              {influencer.name}
                                            </Typography>
                                          </Grid>
                                          <Grid container item justify="center" alignItems="center" style={{ width: "13%" }} >
                                            {influencer.clicks === undefined ? (
                                              <CircularProgress color="secondary" style={{ width: "20px", height: "20px" }} />
                                            ) : (<Typography className={classes.tableRowContent} >
                                              {influencer.clicks}
                                            </Typography>)}
                                          </Grid>
                                          <Grid container item justify="center" alignItems="center" style={{ width: "13%" }} >
                                            <Typography className={classes.tableRowContent} >
                                              {influencer.salesNumber}
                                            </Typography>
                                          </Grid>
                                          <Grid container item justify="center" alignItems="center" style={{ width: "13%" }} >
                                            <Typography className={classes.tableRowContent}>
                                              {influencer.product}
                                            </Typography>
                                          </Grid>
                                          <Grid container item justify="center" alignItems="center" style={{ width: "13%" }} >
                                            <Typography className={classes.tableRowContent} >
                                              {Math.round(influencer.commission * 100) / 100}{" "}€
                                            </Typography>
                                          </Grid>
                                          <Grid container item justify="center" alignItems="center" style={{ width: "13%" }} >
                                            <Typography className={classes.tableRowContent} >
                                              {Math.round(influencer.amount * 100) / 100}{" "} €
                                            </Typography>
                                          </Grid>
                                        </Grid>
                                      </Box>
                                    </Grid>
                                  );
                                })}
                              </div>
                              <Grid container item direction="row-reverse" className={classes.paginationRow} >
                                <Grid container alignItems="center" justify="flex-end" style={{ display: "flex", width: "95%" }}>
                                  <Select value={size} onChange={handlePageSizeChange} input={<BootstrapInput />} IconComponent={() => <SVGIcon name="miniArrow" />} classes={{ root: classes.selectClass }}>
                                    <MenuItem value={5}>5</MenuItem>
                                    <MenuItem value={10}>10</MenuItem>
                                    <MenuItem value={20}>20</MenuItem>
                                  </Select>
                                  <Typography className={classes.tableHeader} style={{ marginRight: "10px", marginLeft: "10px" }} >
                                    {t("TREND.VIEWS.INFLUENCERS.PAGINATION_PLACEHOLDER") + " "}{" "}
                                    {activeButton === "normal" ? brandInfluencers.length : allInfluencers.length}
                                  </Typography>
                                  <Pagination count={count} page={page} onChange={handlePageChange} renderItem={(item) => (
                                    <PaginationItem classes={{ root: classes.paginationClass, selected: classes.selectedPaginationItem }} {...item} />
                                  )}
                                  />
                                </Grid>
                              </Grid>
                            </>
                          )}
                  </Grid>
                </div>
              )}
          </>
        )}
    </Grid>
  );
};

const mapStateToProps = (state, ownProps) => {
  const brandConversions = state.conversionsState.brandConversions;
  const loadedBrandConversions = state.conversionsState.loadedBrandConversions;
  const allConversions = state.conversionsState.allConversions;
  const loadedAllConversions = state.conversionsState.loadedAllConversions;
  const influencers = state.influencersState.influencers;
  const loadedInfluencers = state.influencersState.loadedInfluencers;
  const myInfluencers = state.influencersState.myInfluencers;
  const appStateSortHelper = state.appState.trendInfluencerState.influencerTableSortHelper;
  const appStatePageNumber = state.appState.trendInfluencerState.influencerTablePageNumber;
  const appStatePageSize = state.appState.trendInfluencerState.influencerTablePageSize;
  const articlesByOfferId = state.articlesState.articlesByOfferId;
  const loadedArticlesByOfferId = state.articlesState.loadedArticlesByOfferId;
  const wishlistsNames = state.wishlistsState.wishlistsNames;
  const loadedWishlistsNames = state.wishlistsState.loadedWishlistsNames;

  return {
    brandConversions,
    loadedBrandConversions,
    influencers,
    loadedInfluencers,
    allConversions,
    loadedAllConversions,
    appStateSortHelper,
    appStatePageNumber,
    appStatePageSize,
    myInfluencers,
    articlesByOfferId,
    loadedArticlesByOfferId,
    wishlistsNames,
    loadedWishlistsNames
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    wishlistsPerInfluencerByIdRequest: () => dispatch({ type: 'FETCH_WICHLISTS_PER_INFLUENCER_REQUEST' }),
    fetchWishlistsPerInfluencer: (influencerId) => dispatch(fetchWishlistsPerInfluencer(influencerId)),
    changeSortHelper: (sortHelper) =>
      dispatch({ type: 'UPDATE_TREND_INFLUENCERS_INFLUENCER_TABLE_SORT_HELPER', sortHelper }),
    changePageNumber: (pageNumber) =>
      dispatch({ type: 'UPDATE_TREND_INFLUENCERS_INFLUENCER_TABLE_PAGE_NUMBER', pageNumber }),
    changePageSize: (pageSize) =>
      dispatch({ type: 'UPDATE_TREND_INFLUENCERS_INFLUENCER_TABLE_PAGE_SIZE', pageSize }),
    fetchWishlistsNames: (wishlistsIds) =>
      dispatch(fetchWishlistsNames(wishlistsIds)),
    setWishlistsPerBrandInfluencers: (wishlists) =>
      dispatch({ type: 'SET_WISHLISTS_PER_BRAND_INFLUENCERS', payload: { wishlists } })
  }
};

export default connect(mapStateToProps, mapDispatchToProps)(Influenceurs);
