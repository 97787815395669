import { Grid, makeStyles, Typography, Select, MenuItem, CircularProgress } from '@material-ui/core';
import { Pagination, PaginationItem } from '@material-ui/lab';
import SVGIcon from '../../assets/SVGIcons'
import BootstrapInput from '../../assets/customComponents/BootstrapInput';
import React, { useState, useEffect } from 'react';
import { connect } from 'react-redux';
import ExpandLessIcon from '@material-ui/icons/ExpandLess';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import { useTranslation } from "react-i18next";
import SortIcon from '@material-ui/icons/Sort';
import placeholderImage from '../../assets/img/unavailable-image.png';
import placeholderBrand from '../../assets/img/brand.png';

const useStyles = makeStyles(() => ({
    tableHeader: {
        fontFamily: 'Poppins',
        cursor: 'pointer',
        marginTop: '10px',
        marginBottom: '10px'
    },
    tableRow: {
        backgroundColor: 'white',
        height: '130px',
        borderRadius: '27px',
        boxShadow: '0 7px 30px 0 rgba(88, 165, 211, 0.16)',
        marginTop: '10px',
        marginBottom: '10px'
    },
    brandImage: {
        height: '130px',
        borderTopLeftRadius: '27px',
        borderBottomLeftRadius: '27px',
        width: '130px',
    },
    tableCell: {
        fontFamily: 'Poppins',
    },

    paginationClass: {
        backgroundColor: '#ffffff !important',
        boxShadow: '0 7px 30px 0 rgba(88, 165, 211, 0.16)',
        borderRadius: '8px',
        fontFamily: 'Poppins',
        height: '40px',
        width: '40px',
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center'
    },
    selectedPaginationItem: {
        backgroundColor: '#3eadef !important',
        boxShadow: '0 7px 30px 0 rgba(88, 165, 211, 0.16)',
        borderRadius: '8px',
        fontFamily: 'Poppins',
        height: '40px',
        width: '40px',
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        color: '#ffffff'
    },
    paginationStyles: {
        fontFamily: 'Poppins'
    },
    placeholderStyles: {
        fontFamily: 'Poppins',
        color: '#1B4C61'
    }
}));

const BrandToValidateTable = (props) => {

    const { t } = useTranslation('common');
    const classes = useStyles();

    const {
        brandsToValidate,
        loadedBrandsToValidate,
        searchValue,
        appStatePageNumber,
        appStateSortHelper,
        appStatePageSize,
        changeSortHelper,
        changePageNumber,
        changePageSize
    } = props;

    const [brandsToValidateTable, setBrandsToValidateTable] = useState([]);
    const [tableBeforeRender, setTableBeforeRender] = useState([]);
    const [brandsTableToRender, setBrandsTableToRender] = useState([]);
    const [page, setPage] = useState(1);
    const [count, setCount] = useState(0);
    const [size, setSize] = useState(10);
    const [sortHelper, setSortHelper] = useState([1, 0])
    const [sortEvent, setSortEvent] = useState(0);
    const [loading, setLoading] = useState(true);

    useEffect(() => {
        setSortHelper(appStateSortHelper);
    }, [appStateSortHelper]);

    useEffect(() => {
        setPage(appStatePageNumber);
    }, [appStatePageNumber]);

    useEffect(() => {
        setSize(appStatePageSize);
    }, [appStatePageSize]);

    useEffect(() => {
        setLoading(true);
        if (loadedBrandsToValidate) {
            setBrandsToValidateTable(brandsToValidate);
            setLoading(false);
        }
    }, [loadedBrandsToValidate, brandsToValidate]);

    useEffect(() => {
        let tableAfterSearch = (searchValue !== '')
            ? brandsToValidateTable.filter(brand => brand.name.toLowerCase().includes(searchValue.toLowerCase()))
            : brandsToValidateTable;
        setBrandsTableToRender(tableAfterSearch.slice((page - 1) * size, page * size));
        setTableBeforeRender(tableAfterSearch);
        setCount(
            (tableAfterSearch.length % size === 0)
                ? Math.trunc(tableAfterSearch.length / size)
                : Math.trunc(tableAfterSearch.length / size) + 1
        );
    }, [brandsToValidateTable, size, page, sortEvent, searchValue]);

    useEffect(() => {
        let indexAsc = sortHelper.indexOf(1);
        let indexDesc = sortHelper.indexOf(-1);
        if (indexAsc !== -1) {
            const sortTypeAsc = (indexAsc, tableToWorkOn) => {
                switch (indexAsc) {
                    case 0:
                        tableToWorkOn.sort((a, b) => {
                            if (a.name > b.name) return 1;
                            if (a.name < b.name) return -1;
                            return 0;
                        });
                        break;
                    case 1:
                        tableToWorkOn.sort((a, b) => {
                            if (a.createdAt > b.createdAt) return -1;
                            if (a.createdAt < b.createdAt) return 1;
                            return 0;
                        });
                        break;
                    default:
                        break;
                }
            }
            sortTypeAsc(indexAsc, brandsToValidateTable);
        } else {
            const sortTypeDesc = (indexDesc, tableToWorkOn) => {
                switch (indexDesc) {
                    case 0:
                        tableToWorkOn.sort((a, b) => {
                            if (a.name > b.name) return -1;
                            if (a.name < b.name) return 1;
                            return 0;
                        });
                        break;
                    case 1:
                        tableToWorkOn.sort((a, b) => {
                            if (a.createdAt > b.createdAt) return 1;
                            if (a.createdAt < b.createdAt) return -1;
                            return 0;
                        });
                        break;
                    default:
                        break;
                }
            };
            sortTypeDesc(indexDesc, brandsToValidateTable);
        }
        setSortEvent(s => s + 1);
    }, [brandsToValidateTable, sortHelper]);

    const handlePageChange = (event, value) => {
        changePageNumber(value);
    };

    const handlePageSizeChange = (event) => {
        changePageSize(event.target.value);
        changePageNumber(1);
    };

    const handleValidateBrand = (brand) => {
        props.openValidateBrand(brand);
    }

    return (
        (loading)
            ? <div style={{ display: 'flex', flexDirection: 'column', flexGrow: 1, width: '100%', justifyContent: 'center', alignItems: 'center' }}>
                <CircularProgress size={36} color='secondary' />
            </div>
            :
            <Grid container item direction='column' alignItems='center' xs={12} style={{ flexGrow: 1 }}>
                {
                    (brandsTableToRender.length === 0)
                        ? <>
                            <Grid container item direction='row' xs={11} justify='center' alignItems='center' style={{ marginTop: '100px' }}>
                                <img
                                    alt="No Brands"
                                    src={placeholderBrand}
                                    height="75px"
                                    width="75px"
                                />
                            </Grid>
                            <Grid container item direction='row' xs={11} justify='center' alignItems='center' style={{ marginTop: '10px' }}>
                                <Typography className={classes.placeholderStyles}>
                                    {t('ADMIN.VIEWS.PARTNERS.BRANDS.NO_BRANDS_TO_VALIDATE_PLACEHOLDER')}
                                </Typography>
                            </Grid>
                        </>
                        : <>
                            <Grid container item direction='row' xs={11} alignItems='center' justify='center'>
                                <div style={{ overflowX: 'auto', width: '100%' }}>
                                    <Grid container item style={{ minWidth: '1050px' }} direction='row' xs={12}>
                                        <Grid container item direction='row' xs={12}>
                                            <Grid container justify='center' alignItems='center' style={{ width: '40%' }} onClick={() => {
                                                (sortHelper[0] === 0) ? changeSortHelper([1, 0]) : changeSortHelper([-sortHelper[0], 0]);
                                            }}>
                                                <Typography className={classes.tableHeader}>
                                                    {t('ADMIN.VIEWS.PARTNERS.BRANDS.BRANDS_TO_VALIDATE_TABLE_HEADERS.BRAND_NAME')}
                                                </Typography>
                                                {(sortHelper[0] === 1)
                                                    ? <ExpandLessIcon className={classes.tableHeaderIcon} />
                                                    : ((sortHelper[0] === -1)
                                                        ? <ExpandMoreIcon className={classes.tableHeaderIcon} />
                                                        : <SortIcon className={classes.tableHeaderIcon} />)}
                                            </Grid>
                                            <Grid container justify='center' alignItems='center' style={{ width: '20%' }} onClick={() => {
                                                (sortHelper[1] === 0) ? changeSortHelper([0, 1]) : changeSortHelper([0, -sortHelper[1]]);
                                            }}>
                                                <Typography className={classes.tableHeader}>
                                                    {t('ADMIN.VIEWS.PARTNERS.BRANDS.BRANDS_TO_VALIDATE_TABLE_HEADERS.REGISTRATION_DATE')}
                                                </Typography>
                                                {(sortHelper[1] === 1)
                                                    ? <ExpandLessIcon className={classes.tableHeaderIcon} />
                                                    : ((sortHelper[1] === -1)
                                                        ? <ExpandMoreIcon className={classes.tableHeaderIcon} />
                                                        : <SortIcon className={classes.tableHeaderIcon} />)}
                                            </Grid>
                                            <Grid container justify='center' alignItems='center' style={{ width: '20%' }}>
                                                <Typography className={classes.tableHeader}>
                                                    {t('ADMIN.VIEWS.PARTNERS.BRANDS.BRANDS_TO_VALIDATE_TABLE_HEADERS.AFFILIATE_PROGRAM')}
                                                </Typography>
                                            </Grid>
                                        </Grid>
                                        {
                                            brandsTableToRender.map(brand => (
                                                <Grid container item xs={12} direction='row' className={classes.tableRow} key={brand.key}>
                                                    <Grid container item direction='row' justify='flex-start' alignItems='center' style={{ width: '40%' }}>
                                                        <img src={(brand.pic === undefined) ? placeholderImage : brand.pic} alt='126475' className={classes.brandImage} />
                                                        <Grid container item direction='column' style={{ width: 'calc(100% - 130px)' }}>
                                                            <Typography className={classes.tableCell} style={{ marginLeft: '30px', wordBreak: 'keep-all' }}>{brand.name}</Typography>
                                                            <Typography className={classes.tableCell} style={{ marginLeft: '30px', color: '#4e4d4dc9' }}>{brand.brandUser.email}</Typography>
                                                        </Grid>
                                                    </Grid>
                                                    <Grid container item justify='center' alignItems='center' style={{ width: '20%' }}>
                                                        <Typography className={classes.tableCell}>{(new Date(brand.createdAt * 1000).toLocaleDateString())}</Typography>
                                                    </Grid>
                                                    <Grid container item justify='center' alignItems='center' style={{ width: '20%' }}>
                                                        <Typography className={classes.tableCell}>
                                                            {(brand.platform) ? brand.platform : t('ADMIN.VIEWS.PARTNERS.BRANDS.UNAVAILABLE_PLACEHOLDER')}
                                                        </Typography>
                                                    </Grid>
                                                    <Grid container item justify='center' alignItems='center' style={{ width: '20%' }}>
                                                        <Typography className={classes.tableCell} style={{ textDecoration: 'underline', cursor: 'pointer' }} onClick={() => handleValidateBrand(brand)}>
                                                            {t('ADMIN.VIEWS.PARTNERS.BRANDS.SEE_INFORMATION_PLACEHOLDER')}
                                                        </Typography>
                                                    </Grid>
                                                </Grid>
                                            ))
                                        }
                                    </Grid>
                                </div>
                            </Grid>
                            <Grid container item direction='row' xs={11} alignItems='center'>
                                <Grid container alignItems='center' justify='flex-end'>
                                    <Select
                                        value={size}
                                        onChange={handlePageSizeChange}
                                        input={<BootstrapInput />}
                                        IconComponent={() => (
                                            <SVGIcon name='miniArrow' />
                                        )}
                                        classes={{ root: classes.paginationStyles }}
                                    >
                                        <MenuItem value={10} style={{ fontFamily: 'Poppins' }}>10</MenuItem>
                                        <MenuItem value={25} style={{ fontFamily: 'Poppins' }}>25</MenuItem>
                                        <MenuItem value={50} style={{ fontFamily: 'Poppins' }}>50</MenuItem>
                                    </Select>
                                    <Typography className={classes.tableHeader} style={{ marginRight: '10px', marginLeft: '10px' }}>
                                        {t('ADMIN.VIEWS.PARTNERS.BRANDS.PAGINATION_PLACEHOLDER')} {tableBeforeRender.length}
                                    </Typography>
                                    <Pagination
                                        count={count}
                                        page={page}
                                        onChange={handlePageChange}
                                        renderItem={(item) => (
                                            <PaginationItem classes={{ root: classes.paginationClass, selected: classes.selectedPaginationItem }} {...item} />
                                        )} />
                                </Grid>
                            </Grid>
                        </>
                }
            </Grid>
    );
}

const mapStateToProps = (state, ownProps) => {
    const brandsToValidate = state.brandsState.brandsToValidate;
    const loadedBrandsToValidate = state.brandsState.loadedBrandsToValidate;
    const appStateSortHelper = state.appState.adminPartnerBrandState.brandToValidateTableSortHelper;
    const appStatePageNumber = state.appState.adminPartnerBrandState.brandToValidateTablePageNumber;
    const appStatePageSize = state.appState.adminPartnerBrandState.brandToValidateTablePageSize;
    return {
        brandsToValidate,
        loadedBrandsToValidate,
        appStateSortHelper,
        appStatePageNumber,
        appStatePageSize
    };
};

const mapDispatchToProps = (dispatch) => {
    return {
        changeSortHelper: (sortHelper) =>
            dispatch({ type: 'UPDATE_ADMIN_PARTNER_BRAND_TO_VALIDATE_TABLE_SORT_HELPER', sortHelper }),
        changePageNumber: (pageNumber) =>
            dispatch({ type: 'UPDATE_ADMIN_PARTNER_BRAND_TO_VALIDATE_TABLE_PAGE_NUMBER', pageNumber }),
        changePageSize: (pageSize) =>
            dispatch({ type: 'UPDATE_ADMIN_PARTNER_BRAND_TO_VALIDATE_TABLE_PAGE_SIZE', pageSize })
    }
};

export default connect(mapStateToProps, mapDispatchToProps)(BrandToValidateTable);