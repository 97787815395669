import React, { useState, useEffect } from "react";
import {
  makeStyles,
  Grid,
  Box,
  Typography,
  Button,
  IconButton,
} from "@material-ui/core";
import EuroSymbolIcon from "@material-ui/icons/EuroSymbol";
import SVGIcon from "../assets/SVGIcons/index";
import CloseIcon from "@material-ui/icons/Close";
import { useTranslation } from "react-i18next";
import { connect } from "react-redux";

const useStyles = makeStyles((theme) => {
  return {
    overallBoxContainer: {
      width: "95%",
      display: "flex",
      justifyContent: "center",
      marginBottom: "30px",
      marginTop: "30px",
    },
    overallBoxStyles: {
      backgroundColor: "white",
      borderRadius: "35px",
      boxShadow: "0 7px 30px 0 rgba(88, 165, 211, 0.16)",
      width: "100%",
    },
    influencerImageBox: {
      width: "inherit",
      margin: "10px",
      borderRadius: "35px",
      boxShadow: "0 7px 30px 0 rgba(88, 165, 211, 0.16)",
      display: "flex",
      justifyContent: "center",
      alignItems: "center",
    },
    influencerInfosBox: {
      height: "100px",
    },
    influencerImage: {
      objectFit: "cover",
      width: "90%",
      height: "200px",
      margin: "10px",
      borderRadius: "35px",
      boxShadow: "0 7px 30px 0 rgba(88, 165, 211, 0.16)",
    },
    smallBox: {
      boxShadow: "0 7px 30px 0 rgba(58, 111, 142, 0.25)",
      backgroundColor: "#fff",
      borderRadius: "41px",
      width: "184px",
      height: "150px",
      display: "flex",
      justifyContent: "center",
      alignItems: "center",
      margin: 10,
    },
    chipButton: {
      // width: 220,
      height: 40,
      marginRight: 5,
      marginLeft: 5,
      marginTop: 5,
      marginBottom: 5,
      paddingRight: 30,
      paddingLeft: 30,
      color: "#000",
      borderRadius: "35px",
      backgroundColor: "#ffffff",
      fontSize: "16px",
      textTransform: "none",
      fontFamily: "Poppins",
      boxShadow: "0 7px 30px 0 rgba(58, 111, 142, 0.16)",
    },
    chipActiveButton: {
      // width: 220,
      height: 40,
      marginRight: 5,
      marginLeft: 5,
      marginTop: 5,
      marginBottom: 5,
      paddingRight: 30,
      paddingLeft: 30,
      color: "#ffffff",
      borderRadius: "35px",
      backgroundColor: "#3eadef",
      fontSize: "16px",
      textTransform: "none",
      fontFamily: "Poppins",
      "&:hover": {
        backgroundColor: "#3eadef",
      },
    },
    wishlistsBox: {
      height: "100%",
      borderRadius: "41px",
      boxShadow: "0 2px 30px 0 rgba(58, 111, 142, 0.21)",
      marginTop: "15px",
      marginBottom: "30px",
    },
    articleBox: {
      margin: "15px",
      borderRadius: "41px",
      boxShadow: "0 2px 30px 0 rgba(58, 111, 142, 0.21)",
      width: "170px",
      height: "260px",
      display: "flex",
      justifyContent: "center",
      alignItems: "center",
    },
    articleImage: {
      objectFit: "contain",
      width: "150px",
      height: "220px",
    },
    articlesContainer: {
      display: "inline-flex",
      flexWrap: "wrap",
      marginTop: "20px",
      marginBottom: "20px",
      marginLeft: "-15px",
      marginRight: "-15px",
    },
    influencerName: {
      fontFamily: "Poppins",
      fontSize: "22px",
      fontWeight: "bold",
      color: "#000000",
      wordBreak: "break-word",
    },
    influencerEmail: {
      fontFamily: "Poppins",
      fontSize: "14px",
      fontWeight: 200,
      color: "#000000",
    },
    influencerInstagram: {
      fontFamily: "Poppins",
      fontSize: "20px",
      color: "#000000",
      textDecoration: "none",
    },
    influencerStatsTitles: {
      fontFamily: "Poppins",
      fontSize: "14px",
      fontWeight: 600,
      color: "#000000",
    },
    influencerStats: {
      fontFamily: "Poppins",
      fontSize: "16px",
      fontWeight: 900,
      color: "#000000",
    },
    spacement: {
      marginTop: "15px",
      marginBottom: "15px",
    },
  };
});

const InfluencerModal = (props) => {
  const { t } = useTranslation("common");

  const influencer = props.activeInfluencer;
  const classes = useStyles();

  const [activeButtonId, setActiveButtonId] = useState(null);
  // const [activeWishlist, setActiveWishlist] = useState(null);
  const [activeArticles, setActiveArticles] = useState([]);
  const [influencerWishlists, setInfluencerWishlists] = useState([]);

  const {
    loadedWishlistsPerInfluencer,
    wishlistsPerInfluencer
  } = props;

  useEffect(() => {
    if (influencer && loadedWishlistsPerInfluencer) {
      const wishlistInfluencer = wishlistsPerInfluencer[influencer.key];
      setInfluencerWishlists(wishlistInfluencer);
    }
  }, [influencer, loadedWishlistsPerInfluencer, wishlistsPerInfluencer]);

  useEffect(() => {
    if (influencerWishlists.length !== 0) {
      setActiveButtonId(influencerWishlists[0].key);
      if (influencerWishlists[0].articles !== null && influencerWishlists[0].articles !== undefined) {
        setActiveArticles(Object.values(influencerWishlists[0].articles));
      }
    };
  }, [influencerWishlists])

  const handleWishlistClick = (wishlist) => {
    setActiveButtonId(wishlist.id);
    // setActiveWishlist(wishlist);
    wishlist.articles !== undefined
      ? setActiveArticles(Object.values(wishlist.articles))
      : setActiveArticles([]);
  };

  const closePopup = () => {
    props.close(true);
  };

  return (
    <Grid container item direction="row" justify="center">
      <Grid
        container
        item
        direction="row"
        className={classes.overallBoxContainer}
      >
        <Box className={classes.overallBoxStyles}>
          <Grid
            container
            direction="column"
            alignItems="center"
            justify="center"
            style={{ display: "flex" }}
          >
            <Grid container item direction="row-reverse" sm={12}>
              <IconButton
                onClick={closePopup}
                style={{
                  marginTop: "30px",
                  marginRight: "30px",
                  color: "#000",
                }}
              >
                <CloseIcon fontSize="large" />
              </IconButton>
            </Grid>
            <Grid
              container
              item
              direction="row"
              justify="center"
              className={classes.spacement}
            >
              <Grid container item direction="row" sm={11} justify="center">
                <Grid
                  container
                  item
                  direction="row"
                  justify="center"
                  wrap="wrap"
                  alignItems="center"
                >
                  {influencer.showStats ? (
                    <>
                      <Grid container item lg={4} md={8} sm={12} justify="center" alignItems="center" direction="column">
                        <img src={influencer.banner} alt={influencer.name} className={classes.influencerImage} />

                        <Typography className={classes.influencerName} style={{ marginTop: "15px" }}>
                          {influencer.name}
                        </Typography>
                        {influencer.socialMedias !== undefined &&
                          influencer.socialMedias.instagram !== undefined ? (
                          <Typography style={{ marginBottom: "15px" }}>
                            <a
                              className={classes.influencerInstagram}
                              href={influencer.socialMedias.instagram}
                              target="_blank"
                              rel="noopener noreferrer"
                            >
                              @instagram
                            </a>
                          </Typography>
                        ) : (
                          <></>
                        )}
                      </Grid>

                      <Grid container item lg={8} md={12} sm={12} direction="row" wrap='wrap' justify="center">
                        <Box className={classes.smallBox}>
                          <Grid container direction="row">
                            <Grid container item xs={5} justify="center">
                              <SVGIcon
                                name="ventes"
                                width={"60px"}
                                height={"60px"}
                              />
                            </Grid>
                            <Grid
                              container
                              item
                              xs={6}
                              direction="column"
                              justify="center"
                            >
                              <Typography
                                className={classes.influencerStatsTitles}
                              >
                                {t(
                                  "ADMIN.VIEWS.INFLUENCERS.POPUP_COMMISSIONS_PLACEHOLDER"
                                )}
                              </Typography>
                              <Typography className={classes.influencerStats}>
                                {Math.round(influencer.commissions * 1000) /
                                  1000}{" "}
                                €
                              </Typography>
                            </Grid>
                          </Grid>
                        </Box>

                        <Box className={classes.smallBox}>
                          <Grid container direction="row">
                            <Grid container item xs={5} justify="center">
                              <EuroSymbolIcon
                                style={{
                                  fontSize: "60px",
                                  color: "#ef7e3e",
                                  margin: "auto",
                                }}
                              />
                            </Grid>
                            <Grid
                              container
                              item
                              xs={6}
                              direction="column"
                              justify="center"
                            >
                              <Typography
                                className={classes.influencerStatsTitles}
                              >
                                {t(
                                  "ADMIN.VIEWS.INFLUENCERS.POPUP_TURNOVER_PLACEHOLDER"
                                )}
                              </Typography>
                              <Typography className={classes.influencerStats}>
                                {Math.round(influencer.amount * 100) / 100} €
                              </Typography>
                            </Grid>
                          </Grid>
                        </Box>

                        <Box className={classes.smallBox}>
                          <Grid container direction="row">
                            <Grid container item xs={5} justify="center">
                              <SVGIcon
                                name="commissionPaye"
                                width={"60px"}
                                height={"60px"}
                                margin={"auto"}
                              />
                            </Grid>
                            <Grid
                              container
                              item
                              xs={6}
                              direction="column"
                              justify="center"
                            >
                              <Typography
                                className={classes.influencerStatsTitles}
                              >
                                {t(
                                  "ADMIN.VIEWS.INFLUENCERS.POPUP_COMMISSIONS_AFFILIATE_PLACEHOLDER"
                                )}
                              </Typography>
                              <Typography className={classes.influencerStats}>
                                {Math.round(influencer.commissionAffiliate * 100) / 100} €
                              </Typography>
                            </Grid>
                          </Grid>
                        </Box>

                      </Grid>

                    </>
                  ) : (
                    <>
                      <Grid
                        container
                        item
                        lg={6}
                        md={8}
                        sm={12}
                        jutify="center"
                      >
                        <Box className={classes.influencerImageBox}>
                          <img
                            src={influencer.banner}
                            alt={influencer.name}
                            className={classes.influencerImage}
                          />
                        </Box>
                      </Grid>
                      <Grid
                        container
                        item
                        lg={6}
                        md={4}
                        sm={12}
                        justify="center"
                        alignItems="center"
                        direction="column"
                        className={classes.influencerInfosBox}
                      >
                        <Typography className={classes.influencerName}>
                          {influencer.name}
                        </Typography>
                        {influencer.socialMedias !== undefined &&
                          influencer.socialMedias.instagram !== undefined ? (
                          <Typography>
                            <a
                              className={classes.influencerInstagram}
                              href={influencer.socialMedias.instagram}
                              target="_blank"
                              rel="noopener noreferrer"
                            >
                              @instagram
                            </a>
                          </Typography>
                        ) : (
                          <></>
                        )}
                      </Grid>
                    </>
                  )}
                </Grid>
              </Grid>
            </Grid>
            <Grid container item direction="row" xs={11} wrap="wrap">
              {influencerWishlists.map((wishlist) => (
                <Button
                  key={wishlist.id}
                  className={
                    wishlist.id === activeButtonId
                      ? classes.chipActiveButton
                      : classes.chipButton
                  }
                  onClick={() => {
                    handleWishlistClick(wishlist);
                  }}
                >
                  {wishlist.name}
                </Button>
              ))}
            </Grid>
            <Grid
              container
              item
              direction="row"
              justify="center"
              xs={11}
              className={classes.wishlistsBox}
            >
              <Grid
                container
                item
                direction="row"
                justify="center"
                xs={11}
                className={classes.articlesContainer}
              >
                <>
                  {activeArticles.length === 0 ? (
                    <Typography className={classes.influencerStats}>
                      {t(
                        "ADMIN.VIEWS.INFLUENCERS.EMPTY_WISHLIST_PLACEHOLDER"
                      )}
                    </Typography>
                  ) : (
                    activeArticles.map((article) => (
                      <Box key={article.id} className={classes.articleBox}>
                        <a
                          href={article.url}
                          target="_blank"
                          rel="noopener noreferrer"
                        >
                          <img
                            alt={article.url}
                            src={article.image}
                            className={classes.articleImage}
                          />
                        </a>
                      </Box>
                    ))
                  )}
                </>
              </Grid>
            </Grid>
          </Grid>
        </Box>
      </Grid>
    </Grid>
  );
};
const mapStateToProps = (state) => {
  const loadedWishlistsPerInfluencer = state.wishlistsState.loadedWishlistsPerInfluencer;
  const wishlistsPerInfluencer = state.wishlistsState.wishlistsPerInfluencer;
  return {
    loadedWishlistsPerInfluencer,
    wishlistsPerInfluencer
  }
}
export default connect(mapStateToProps, null)(InfluencerModal);
