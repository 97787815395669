import React, { useState, useEffect } from 'react';
import { dbManager } from "../managers";
import { LoadingScreen, Unauthorized } from "../ui";

import Template from "./template";
import Router from "./Router";

const InfluencersAgent = (props) => {

    const [user, setUser] = useState(null);
    const [influencerAgent, setInfluencerAgent] = useState(null);
    const [userClaims, setUserClaims] = useState(null);
    const [updatedClaims, setUpdatedClaims] = useState(false);
    const [loading, setLoading] = useState(true);

    useEffect(() => {
        setUser(props.user);
        setLoading(true);
        if (props.user === undefined) setLoading(false);
    }, [props.user]);

    useEffect(() => {
        if (user) {
            user.getIdTokenResult().then(idTokenResult => {
                if (idTokenResult.claims.isInfluencerAgent !== undefined && idTokenResult.claims.isInfluencerAgent !== null) {
                    if (idTokenResult.claims.isInfluencerAgent) {
                        dbManager.fetchInfluencerAgentById(user.uid).then(res => {
                            setInfluencerAgent(res);
                        }).catch(err => {
                            console.log(err);
                        });
                    } else if (idTokenResult.claims.admin) {
                    }
                }
                setUpdatedClaims(true);
                setUserClaims(idTokenResult.claims);
                setLoading(false);
            }).catch(err => console.log(err))
        } else {
            setLoading(false);
        }
    }, [user]);

    const renderContent = () => {
        if (loading) {
            return <LoadingScreen />
        } else {
            if (user) {
                if (updatedClaims) {
                    if (userClaims && (userClaims.isInfluencerAgent || userClaims.admin)) {
                        return (
                            <Template influencerAgent={influencerAgent}>
                                <Router influencerAgent={influencerAgent} />
                            </Template>
                        )
                    } else {
                        return <Unauthorized />
                    }
                } else {
                    return <LoadingScreen />
                }
            } else {
                return <></>
            }
        }
    }

    return renderContent();
}

export default InfluencersAgent;