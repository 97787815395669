import React, { useState, useEffect, useCallback } from "react";
import { connect } from "react-redux";
import { makeStyles } from "@material-ui/styles";
import {
  Grid,
  FormControl,
  InputBase,
  Typography,
  FormHelperText,
  useTheme,
  useMediaQuery,
} from "@material-ui/core";
import CropperDialog from "../../components/Cropper";
import { useTranslation } from "react-i18next";
import CheckCircleIcon from "@material-ui/icons/CheckCircle";

const useStyle = makeStyles(() => ({
  inputField: {
    fontFamily: "Poppins",
    borderRadius: "35px",
    boxShadow: "0 7px 30px 0 rgba(69, 128, 163, 0.16)",
    height: "50px",
    margin: "10px",
    paddingTop: "10px",
    paddingBottom: "10px",
    paddingLeft: "20px",
  },
  stepTitle: {
    fontFamily: "Poppins",
    margin: "10px",
    fontSize: "18px",
  },
  logoImageLabel: {
    fontFamily: "Poppins",
    width: "150px",
    height: "40px",
    textTransform: "none",
    backgroundColor: "#58a5d3",
    color: "#fff",
    borderRadius: "35px",
    "&:hover": {
      cursor: "pointer",
      backgroundColor: "#58a5d3",
    },
    "&:focus": {
      outlineColor: "rgba(69, 128, 163, 0)",
    },
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    position: "relative",
  },
  // inputField: {
  //     fontFamily: 'Poppins',
  //     borderRadius: '35px',
  //     boxShadow: '0 7px 30px 0 rgba(69, 128, 163, 0.16)',
  //     minHeight: '50px',
  //     paddingLeft: '20px',
  //     paddingRight: '5px'
  // },
  selectClass: {
    fontFamily: "Poppins",
    "&:focus": {
      backgroundColor: "white",
    },
  },
  logoPlaceholder: {
    fontFamily: "Poppins",
    color: "#717171",
    width: "calc(100% - 150px)",
    paddingLeft: "15px",
  },
  activeLogoPlaceholder: {
    fontFamily: "Poppins",
    width: "calc(100% - 150px)",
    paddingLeft: "15px",
  },
  logoPlaceholderMobile: {
    fontFamily: "Poppins",
    color: "#717171",
    width: "70%",
    margin: 5,
    textAlign: "center",
  },
  activeLogoPlaceholderMobile: {
    fontFamily: "Poppins",
    width: "70%",
    margin: 5,
    textAlign: "center",
  },
  errorMessage: {
    fontFamily: "Poppins",
    color: "red",
  },
  finishedRegistrationMessage: {
    textAlign: "center",
    fontSize: "20px",
    fontFamily: "Poppins",
  },
}));

const StepthreeBrandAgent = (props) => {
  const classes = useStyle();
  const { t } = useTranslation("common");
  const { registrationComplete } = props;
  const theme = useTheme();
  const xsDown = useMediaQuery(theme.breakpoints.down("xs"));
  const [initialValues, setInitialValues] = useState({});
  const [logoValue, setLogoValue] = useState("");
  const [base64Logo, setBase64Logo] = useState("");
  const [websiteValue, setWebsiteValue] = useState("");
  const [validationError, setValidationError] = useState(false);
  const [websiteValidationError, setWebsiteValidationError] = useState("");
  const [openDialog, setOpenDialog] = useState(false);
  const [imageToCrop, setImageToCrop] = useState(null);
  const stepValues = props.stepValues;
  const validStep = props.validStep;
  const checkValid = props.checkValid;

  const convertImage = async (event) => {
    const file = event.target.files[0];
    if (file !== undefined) {
      await convertToBase64(file).then((result) => {
        setImageToCrop(result);
        setOpenDialog(true);
      });
    } else {
      setBase64Logo("");
      setLogoValue("");
    }
  };

  const convertToBase64 = (file) => {
    return new Promise((resolve, reject) => {
      const fileReader = new FileReader();
      fileReader.readAsDataURL(file);
      fileReader.onload = () => {
        resolve(fileReader.result);
      };
      fileReader.onerror = (error) => {
        reject(error);
      };
    });
  };

  const handleValueChange = (id) => (event) => {
    if (id === "logoValue") {
      setLogoValue(event.target.value);
      convertImage(event);
    }
    // if (id === 'favBrands') setfavBrands(event.target.value);
    if (id === "website") setWebsiteValue(event.target.value);
  };

  const resetErrors = () => {
    // setBase64LogoValidationError();
    setWebsiteValidationError();
    // setBrandsValidationError();
  };

  const isValid = useCallback(() => {
    let valid = true;
    setValidationError(false);
    resetErrors();
    // if (base64Logo === '') {
    //     valid = false;
    //     setValidationError(true);
    //     setBase64LogoValidationError('* Ce champs est obligatoire');
    // }
    if (websiteValue === "") {
      valid = false;
      setValidationError(true);
      setWebsiteValidationError(
        "* " +
          t(
            "REGISTER.STEP_THREE.BRANDS_AGENT.VALIDATION_ERRORS.REQUIRED_MESSAGE"
          )
      );
    }
    // if (favBrands.length === 0) {
    //     valid = false;
    //     setValidationError(true);
    //     setBrandsValidationError('* Ce champs est obligatoire');
    // }
    return valid;
  }, [websiteValue, t]);

  const handleCancelDialog = () => {
    setOpenDialog(false);
    setLogoValue("");
    setBase64Logo("");
  };

  const handleSaveDialog = (croppedImage) => {
    setOpenDialog(false);
    setBase64Logo(croppedImage);
  };

  useEffect(() => {
    if (initialValues.logo !== undefined) setLogoValue(initialValues.logo);
    if (initialValues.base64Logo !== undefined)
      setBase64Logo(initialValues.base64Logo);
    // if (initialValues.favBrands !== undefined) setfavBrands(initialValues.favBrands);
    if (initialValues.website !== undefined)
      setWebsiteValue(initialValues.website);
  }, [initialValues]);

  useEffect(() => {
    if (props.valuesToPopulate !== undefined) {
      setInitialValues(props.valuesToPopulate);
    }
  }, [props.valuesToPopulate]);

  useEffect(() => {
    stepValues({
      logo: logoValue,
      base64Logo: base64Logo,
      // favBrands: favBrands,
      website: websiteValue,
    });
  }, [logoValue, base64Logo, websiteValue, stepValues]);

  useEffect(() => {
    if (checkValid) {
      if (isValid()) {
        validStep(true);
      } else validStep(false);
    }
  }, [checkValid, isValid, validStep]);
  return registrationComplete ? (
    <>
      <Grid
        container
        item
        direction="row"
        xs={12}
        alignItems="center"
        justify="center"
      >
        <Grid
          container
          item
          direction="row"
          xs={9}
          alignItems="center"
          justify="center"
        >
          <CheckCircleIcon
            style={{ width: 72, height: 72, margin: 15, color: "#20be80" }}
          />
        </Grid>
        <Grid
          container
          item
          direction="row"
          xs={9}
          alignItems="center"
          justify="center"
        >
          <Typography className={classes.finishedRegistrationMessage}>
            {t("REGISTER.SIGNUP_CONFIRMED_MESSAGE")}
          </Typography>
        </Grid>
      </Grid>
    </>
  ) : (
    <>
      <Grid container item direction="row" xs={12}>
        <Typography className={classes.stepTitle}>
          Etape 3 : Configuration du profil
        </Typography>
      </Grid>
      <Grid
        container
        item
        direction="row"
        justify="center"
        alignItems="center"
        xs={12}
      >
        <FormControl
          style={{ width: "100%", marginTop: "10px", marginBottom: "10px" }}
        >
          <InputBase
            id="website"
            placeholder={t(
              "REGISTER.STEP_THREE.BRANDS_AGENT.WEBSITE_PLACEHOLDER"
            )}
            type="text"
            className={classes.inputField}
            value={websiteValue}
            required
            onChange={handleValueChange("website")}
          />
          {validationError && (
            <FormHelperText className={classes.errorMessage}>
              {websiteValidationError}
            </FormHelperText>
          )}
        </FormControl>
        <FormControl style={{ width: "100%" }}>
          <CropperDialog
            open={openDialog}
            save={handleSaveDialog}
            cancel={handleCancelDialog}
            imageToCrop={imageToCrop}
            widthAspect={1}
            heightAspect={1}
          />
          <Grid
            container
            item
            justify={xsDown ? "center" : "flex-start"}
            alignItems="center"
            className={classes.inputField}
            style={{
              paddingRight: 5,
              paddingLeft: 5,
              marginTop: "10px",
              marginBottom: "10px",
            }}
          >
            <Typography
              className={
                logoValue === ""
                  ? xsDown
                    ? classes.logoPlaceholderMobile
                    : classes.logoPlaceholder
                  : xsDown
                  ? classes.activeLogoPlaceholderMobile
                  : classes.activeLogoPlaceholder
              }
            >
              {logoValue === ""
                ? t("REGISTER.STEP_THREE.BRANDS_AGENT.PICTURE_PLACEHOLDER")
                : logoValue.split("\\").pop()}
            </Typography>
            <label
              htmlFor="profileImage"
              tabIndex="0"
              className={classes.logoImageLabel}
              style={xsDown ? { margin: 5 } : {}}
            >
              {t("REGISTER.STEP_THREE.BRANDS_AGENT.PICTURE_UPLOAD_BUTTON")}
            </label>
          </Grid>
          <input
            type="file"
            id="profileImage"
            onChange={handleValueChange("logoValue")}
            hidden
            accept="image/x-png,image/gif,image/jpeg"
          />
          {/* {validationError && <FormHelperText className={classes.errorMessage}>{base64LogoValidationError}</FormHelperText>} */}
        </FormControl>
        {/* <FormControl style={{ width: '100%' }}>
                    <Select
                        multiple
                        displayEmpty
                        value={favBrands}
                        onChange={handleValueChange('favBrands')}
                        classes={{ root: classes.selectClass }}
                        input={<InputBase className={classes.inputField} style={{ marginTop: '10px', marginBottom: '10px' }} />}
                        renderValue={(selected) => {
                            if (selected.length === 0) {
                                return <Typography style={{ fontFamily: 'Poppins', color: '#717171' }}>Quelles sont vos marques ?</Typography>;
                            }
                            return selected.join(', ');
                        }}
                        IconComponent={() => (
                            <ExpandMoreIcon />
                        )}>
                        <MenuItem value="item1" >Item 1</MenuItem>
                        <MenuItem value="item2">Item 2</MenuItem>
                    </Select> */}
        {/* {validationError && <FormHelperText className={classes.errorMessage}>{brandsValidationError}</FormHelperText>} */}
        {/* </FormControl> */}
      </Grid>
    </>
  );
};
const mapStateToProps = (state) => {
  const registrationComplete = state.authState.registrationComplete;

  return { registrationComplete };
};
export default connect(mapStateToProps, null)(StepthreeBrandAgent);
