import { dbManager } from "../../components/managers"

export const fetchAllWishlists = () => {
    return (dispatch, getState) => {
        dbManager.fetchAllWishlists().then(wishlists => {
            dispatch({ type: 'FETCH_WISHLISTS_SUCCESS', wishlists })
        }).catch(error => {
            dispatch({ type: 'FETCH_WISHLISTS_ERROR', error })
        })
    }
};

export const addWishlist = (wishlistName, userId) => {
    return (dispatch, getState) => {
        dispatch({ type: "ADD_WISHLIST_REQUEST" })
        dbManager.addWishlist(wishlistName, userId).then((addedWishlist) => {
            dispatch({ type: "ADD_WISHLIST_SUCCESS", payload: { wishlist: addedWishlist } })
        }, error => {
            dispatch({ type: "ADD_WISHLIST_ERROR", error });
        });
    }
};

export const updateWishlist = (wishlist, updates) => {
    return (dispatch, getState) => {
        dispatch({ type: "UPDATE_WISHLIST_BY_ID_REQUEST" });
        dbManager.updateWishlist(wishlist, updates).then(() => {
            dispatch({ type: "UPDATE_WISHLIST_BY_ID_SUCCESS", payload: { wishlist, updates } });
        }, error => {
            dispatch({ type: "UPDATE_WISHLIST_BY_ID_ERROR", error });
        });
    }
};

export const deleteWishlist = (wishlist) => {
    return (dispatch, getState) => {
        dispatch({ type: "DELETE_WISHLIST_BY_ID_REQUEST" });
        dbManager.deleteWishlist(wishlist).then((wishlistToDelete) => {
            dispatch({ type: "DELETE_WISHLIST_BY_ID_SUCCESS", payload: { wishlist: wishlistToDelete } });
        }, error => {
            dispatch({ type: "DELETE_WISHLIST_BY_ID_ERROR", error });
        })
    }
};

export const fetchWishlistsPerInfluencer = (influencerUid) => {
    return (dispatch, getState) => {
        const { wishlistsState } = getState();
        const wishlistsPerInfluencer = wishlistsState.wishlistsPerInfluencer[influencerUid];
        if (wishlistsPerInfluencer) {
            dispatch({ type: 'FETCH_WISHLISTS_PER_INFLUENCER_SUCCESS', payload: { wishlistsPerInfluencer, influencerId: influencerUid} })
        } else {
            dbManager.fetchWishlistsByInfluencer(influencerUid).then(wishlistsPerInfluencer => {
                dispatch({ type: 'FETCH_WISHLISTS_PER_INFLUENCER_SUCCESS', payload: { wishlistsPerInfluencer, influencerId: influencerUid } });
            }, error => {
                dispatch({ type: 'FETCH_WISHLISTS_PER_INFLUENCER_ERROR', error })
            });
        }
    }
};

export const fetchWishlistsNames = (wishlistsIds) => {
    return async (dispatch, getState) => {
        dispatch({ type: 'FETCH_WISHLISTS_NAMES_REQUEST' });
        const { wishlistsState } = getState();
        const wishlists = wishlistsState.wishlists;
        let wishlistsNames = {};
        let wishlistsIdsErrors = {};
        for (let i = 0; i < wishlistsIds.length; i++) {
            const wishlist = wishlists.filter(wish => wish.key === wishlistsIds[i])[0];
            if (wishlist) {
                wishlistsNames[wishlistsIds[i]] = wishlist.name;
            } else {
                await dbManager.fetchWishlistName(wishlistsIds[i]).then(wishlistName => {
                    wishlistsNames[wishlistsIds[i]] = wishlistName;
                }, error => {
                    wishlistsIdsErrors[wishlistsIds[i]] = error;
                });
            }
        };
        if (Object.keys(wishlistsIdsErrors).length !== 0) {
            dispatch({ type: 'FETCH_WISHLISTS_NAMES_WITH_ERROR', payload: { wishlistsNames, wishlistsIdsErrors } })
        } else {
            dispatch({ type: 'FETCH_WISHLISTS_NAMES_SUCCESS', payload: { wishlistsNames } })
        }
    }
};

export const fetchWishlistById = (wishlistId) => {
    return (dispatch, getState) => {
        dispatch({ type: 'FETCH_WISHLIST_BY_ID_REQUEST' })
        const { wishlistsState } = getState();
        const wishlist = wishlistsState.wishlists.filter(wishlist => wishlist.key === wishlistId)[0];
        if (wishlist) {
            dispatch({ type: 'FETCH_WISHLIST_BY_ID_SUCCESS', payload: { wishlist } });
        } else {
            dbManager.fetchWishlistById(wishlistId).then(wishlist => {
                if (wishlist && Object.keys(wishlist).length !== 0) {
                    dispatch({ type: 'FETCH_WISHLIST_BY_ID_SUCCESS', payload: { wishlist } })
                } else {
                    dispatch({ type: 'FETCH_WISHLIST_BY_ID_NOT_FOUND'});
                }
            }, error => {
                dispatch({ type: 'FETCH_WISHLIST_BY_ID_ERROR', error });
            })
        }
    }
}