import React,{ useEffect,useState } from "react";
import { auth,dbManager } from "./../managers";
import { useRouteMatch,Route,Switch,Redirect } from "react-router-dom";
import NewDashboard from "./views/NewDashboard";
import Influenceurs from "./views/Influenceurs";
import Statistics from "./views/Statistics";
// import Factures from "./views/Factures";
import Parametres from "./views/Parametres";
import Users from "./views/Users";
import { fetchAllInfluencers } from "../../store/actions/influencersActions";
import {
  fetchBrandConversions,
} from "../../store/actions/conversionsActions";
import { fetchArticlesByOfferId } from "../../store/actions/articlesActions";
import { fetchUsersByOfferId } from "../../store/actions/usersActions";
import { connect } from "react-redux";

const Router = (props) => {
  const { url } = useRouteMatch();
  const [influencerToView,setInfluencerToView] = useState(null);
  const [activeButtonInfluencer,setActiveButtonInfluencer] = useState("active");

  const {
    brand,
    fetchAllInfluencers,
    fetchBrandConversions,
    loadedInfluencers,
    loadedBrandConversions,
    loadedAllConversions,
    loadedArticlesByOfferId,
    activeButtonTrend,
    filterValue,
    allConversions,
    admin,
    brandAdmin,
    setBrandConversions,
    fetchArticlesPerBrandOfferId,
    clearBrandWishlists,
    clearBrandArticles,
    clearBrandConversions,
    clearUsersPerOfferdId,
    fetchUsersByOfferId,
    loadedUserPerOfferId,
  } = props;

  const handleActiveInfluencer = (activeButton) => {
    setActiveButtonInfluencer(activeButton);
  }

  useEffect(() => {
    if (!loadedArticlesByOfferId) fetchArticlesPerBrandOfferId(brand.offerId);
  },[loadedArticlesByOfferId,fetchArticlesPerBrandOfferId,brand])

  useEffect(() => {
    if (!loadedInfluencers) fetchAllInfluencers();
  },[loadedInfluencers,fetchAllInfluencers]);

  useEffect(() => {
    if (!loadedUserPerOfferId) fetchUsersByOfferId(brand.offerId);
  },[loadedUserPerOfferId,fetchUsersByOfferId,brand])

  useEffect(() => {
    if (admin) {
      if (!loadedBrandConversions) {
        if (loadedAllConversions) {
          let brandConversions = allConversions.filter(conversion => conversion.offerId === brand.offerId);
          setBrandConversions(brandConversions);
        } else {
          fetchBrandConversions(brand.offerId);
        }
      }
    } else {
      if (!loadedBrandConversions) {
        fetchBrandConversions(brand.offerId);
      }
    }
  },[loadedBrandConversions,loadedAllConversions,brand,admin,fetchBrandConversions,allConversions,setBrandConversions]);

  useEffect(() => {
    setInfluencerToView(props.influencerSearch);
  },[props.influencerSearch]);

  useEffect(() => {
    activeButtonTrend(activeButtonInfluencer);
  },[activeButtonTrend,activeButtonInfluencer]);


  const handleInfluencerToView = (influencerId) => {
    dbManager.fetchInfluencerById(influencerId).then((influencer) => {
      setInfluencerToView(influencer);
    });
  };

  return (
    <Switch>
      <Route
        path={ `${url}/logout` }
        exact
        render={ () => {
          clearBrandConversions();
          clearBrandWishlists();
          clearBrandArticles();
          clearUsersPerOfferdId();
          auth.signOut();
          return <Redirect to="/" />;
        } }
      ></Route>
      <Route path={ `${url}/dashboard` } exact>
        <NewDashboard brand={ props.brand } />
      </Route>
      <Route path={ `${url}/influencers` } exact>
        <Influenceurs brand={ props.brand } filterSearch={ filterValue } influencerSearch={ influencerToView } activeButtonInfluencer={ (active) => handleActiveInfluencer(active) } />
      </Route>
      <Route path={ `${url}/statistiques` } exact>
        <Statistics
          handleInfluencerToView={ (influencerId) =>
            handleInfluencerToView(influencerId)
          }
          brand={ props.brand }
          url={ url }
        />
      </Route>
      { brandAdmin || admin ?
        <Route path={ `${url}/users` } exact>
          <Users offerId={ brand.offerId } />
        </Route> :
        <Route path={ `${url}` }>
          <Redirect to={ `${url}/dashboard` } />
        </Route> }
      {/*<Route path={`${url}/factures`} exact>
        <Factures brand={props.brand} />
      </Route>
        */}
      <Route path={ `${url}/params` } exact>
        <Parametres brand={ props.brand } />
      </Route>
      <Route path={ `${url}` }>
        <Redirect to={ `${url}/dashboard` } />
      </Route>
    </Switch>
  );
};

const mapDispatchToProps = (dispatch) => {
  return {
    fetchAllInfluencers: () => dispatch(fetchAllInfluencers()),
    fetchBrandConversions: (brandOfferId) =>
      dispatch(fetchBrandConversions(brandOfferId)),
    clearBrandWishlists: () => dispatch({ type: 'CLEAR_BRAND_WISHLISTS' }),
    clearBrandConversions: () => dispatch({ type: 'CLEAR_BRAND_CONVERSIONS' }),
    clearBrandArticles: () => dispatch({ type: 'CLEAR_BRAND_ARTICLES' }),
    clearUsersPerOfferdId: () => dispatch({ type: 'CLEAR_BRAND_USER_BY_OFFERID' }),
    setBrandConversions: (brandConversions) => dispatch({ type: "SET_BRAND_CONVERSIONS",payload: { brandConversions } }),
    fetchArticlesPerBrandOfferId: (offerId) =>
      dispatch(fetchArticlesByOfferId(offerId)),
    fetchUsersByOfferId: (brandOfferId) =>
      dispatch(fetchUsersByOfferId(brandOfferId)),
  };
};

const mapStateToProps = (state,ownProps) => {
  const loadedInfluencers = state.influencersState.loadedInfluencers;
  const loadedBrandConversions = state.conversionsState.loadedBrandConversions;
  const loadedAllConversions = state.conversionsState.loadedAllConversions;
  const allConversions = state.conversionsState.allConversions;
  const loadedArticlesByOfferId = state.articlesState.loadedArticlesByOfferId;
  const loadedUserPerOfferId = state.usersState.loadedUserPerOfferId;

  return {
    allConversions,
    loadedInfluencers,
    loadedBrandConversions,
    loadedAllConversions,
    loadedArticlesByOfferId,
    loadedUserPerOfferId
  };
};

export default connect(mapStateToProps,mapDispatchToProps)(Router);
