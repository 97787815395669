import React from "react";
import { ThemeProvider, CssBaseline, } from "@material-ui/core";

// UI Scope
import theme, {
  LoadingScreen,
}
  from "./components/ui";

// Component Scope
import Router from "./components/Router";
import { useAuth } from "./components/hooks";
// import { useParams } from "react-router-dom";

function App() {
  const {
    user,
    loadedAuth
  } = useAuth();

  const domain = window.location.href.split(
    "/"
  )[2];
  (domain === "www.shopmyinfluence.fr") &&
    (window.location.href = window.location.href.replace(
      "www.",
      ""
    ));

  return (
    <ThemeProvider theme={theme}>
      <CssBaseline />
      {
        loadedAuth
        ? <Router user={user} />
        : <LoadingScreen />
      }
    </ThemeProvider>

  );
}

export default App;
