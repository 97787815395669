import { dbManager, userManager } from "../../components/managers";
export const fetchbrandsByOfferId = (brandOfferId) => {
  return (dispatch) => {
    dbManager
      .fetchBrandByOfferId(brandOfferId)
      .then((brands) => {
        dispatch({
          type: "FETCH_BRAND_OFFERID_COMPLETE",
          payload: brands,
        });
      })
      .catch((error) => {
        dispatch({ type: "FETCH_BRAND_OFFERID_ERROR", error });
      });
  };
};

export const fetchAllAgency = () => {
  return (dispatch) => {
    dbManager
      .fetchAllAgency()
      .then((allBrandsAgent) => {
        dispatch({
          type: "FETCH_All_AGENCY",
          payload: allBrandsAgent,
        });
      })
      .catch((error) => {
        dispatch({ type: "FETCH_ALL_AGENCY_ERROR", error });
      });
  };
};

export const fetchAllAgencyToValidate = () => {
  return (dispatch) => {
    dbManager
      .fetchAllAgencyToValidate()
      .then((agencyToValidate) => {
        dispatch({
          type: "FETCH_All_AGENCY_TO_VALIDATE",
          payload: agencyToValidate,
        });
      })
      .catch((error) => {
        dispatch({ type: "FETCH_All_AGENCY_TO_VALIDATE_ERROR", error });
      });
  };
};

export const updateBrandAgentById = (brandAgentData, updates, userData) => {
  return (dispatch, getState) => {
    dispatch({ type: 'UPDATE_BRAND_AGENT_BY_ID_REQUEST' });
    userManager.addClaimWithValue(brandAgentData.userId, "brandsOfferIds", updates.brandsIds).then(result => {
      if (result.data.status === 'success') {
        dbManager.updateBrandAgentbyId(brandAgentData.key, updates).then(() => {
          dbManager.updateBrandAgentUsers(userData, brandAgentData.key, brandAgentData.userId).then(() => {
            dispatch({ type: 'UPDATE_BRAND_AGENT_BY_ID_SUCCESS', payload: { id: brandAgentData.key, updates } });
          }, error => {
            dispatch({ type: 'UPDATE_BRAND_AGENT_BY_ID_ERROR', error });
          });
        }, error => {
          dispatch({ type: 'UPDATE_BRAND_AGENT_BY_ID_ERROR', error });
        });
      }
      else {
        dispatch({ type: 'UPDATE_BRAND_AGENT_BY_ID_ERROR', error: { message: 'Error setting user claims' } });
      }
    });
  }
};

export const validateBrandAgentById = (brandAgentId, brandAgent, userData) => {
  return (dispatch, getState) => {
    userManager.addClaimWithValue(brandAgentId, 'isBrandAgent', true).then(result => {
      if (result.data.status === 'success') {
        userManager.addClaimWithValue(brandAgentId, "brandsIds", brandAgent.brandsIds).then(result => {
          if (result.data.status === 'success') {
            dbManager.addNewBrandAgent(brandAgent).then((brandAgent) => {
              dbManager.addNewBrandAgentUser(brandAgentId, brandAgent, userData).then(() => {
                dbManager.removeUnverifiedBrandAgent(brandAgentId).then(() => {
                  dispatch({ type: 'VALIDATE_BRAND_AGENT_BY_ID_SUCCESS', payload: { unverifiedBrandAgentId: brandAgentId, brandAgent } });
                }, error => {
                  dispatch({ type: 'VALIDATE_BRAND_AGENT_BY_ID_ERROR', error });
                })
              }, (error) => {
                dispatch({ type: 'VALIDATE_BRAND_AGENT_BY_ID_ERROR', error })
              });
            }, (error) => {
              dispatch({ type: 'VALIDATE_BRAND_AGENT_BY_ID_ERROR', error });
            });
          } else {
            dispatch({ type: 'VALIDATE_BRAND_AGENT_BY_ID_ERROR', error: 'Unable to set brand agent Ids claim' });
          }
        });
      } else {
        dispatch({ type: 'VALIDATE_BRAND_AGENT_BY_ID_ERROR', error: 'Unable to set brand Agent claim' });
      }
    });
  }
}

export const refuseBrandAgentById = (brandAgentId) => {
  return (dispatch, getState) => {
    dispatch({ type: 'REFUSE_BRAND_AGENT_BY_ID_REQUEST' });
    dbManager.removeUnverifiedBrandAgent(brandAgentId).then(() => {
      dispatch({ type: 'REFUSE_BRAND_AGENT_BY_ID_SUCCESS', payload: { unverifiedBrandAgentId: brandAgentId } });
    }, error => {
      dispatch({ type: 'REFUSE_BRAND_AGENT_BY_ID_ERROR', error });
    })
  }
};
