const initState = {
    articles: [],
    articlesByOfferId: [],
    loadedArticles: false,
    loadedUpdateArticle: false,
    loadedDeleteArticle: false,
    loadedArticlesByOfferId: false,
    articlesError: null,
    updateArticleError: null,
}

const articlesReducer = (state = initState, action) => {
    switch (action.type) {
        case 'FETCH_ALL_SOLD_ARTICLES_SUCCESS':
            return {
                ...state,
                loadedArticles: true,
                articles: action.payload.articles
            };
        case 'FETCH_ALL_SOLD_ARTICLES_ERROR':
            return {
                ...state,
                loadedArticles: true,
                articlesError: action.error
            };
        case 'UPDATE_SOLD_ARTICLE_BY_ID_REQUEST':
            return {
                ...state,
                loadedUpdateArticle: true,
                updateArticleError: null
            };
        case 'UPDATE_SOLD_ARTICLE_BY_ID_SUCCESS':
            {
                let newArticles = state.articles.map(article => {
                    if (article.key === action.payload.articleId) {
                        let updatedArticle = {
                            ...article
                        };
                        Object.entries(action.payload.updates).forEach(([key, value]) => {
                            updatedArticle[key] = value;
                        });
                        return updatedArticle;
                    } else {
                        return article;
                    }
                });
                return {
                    ...state,
                    loadedUpdateArticle: false,
                    updateArticleError: null,
                    articles: newArticles
                };
            };
        case 'UPDATE_SOLD_ARTICLE_BY_ID_ERROR':
            return {
                ...state,
                loadedUpdateArticle: false,
                updateArticleError: action.error
            };
        case 'UPDATE_SOLD_ARTICLES_CATEG_PROPERTY': {
            let newArticles = state.articles.map(article => {
                if (action.payload.updatedArticlesIds.indexOf(article.key) !== -1) {
                    let updatedArticle = {
                        ...article
                    };
                    updatedArticle.categ = null;
                    updatedArticle.subCateg = null;
                    return updatedArticle;
                } else {
                    return article
                }
            });
            return {
                ...state,
                articles: newArticles
            };
        };
        case 'UPDATE_SOLD_ARTICLES_SUB_CATEG_PROPERTY': {
            let newArticles = state.articles.map(article => {
                if (action.payload.updatedArticlesIds.indexOf(article.key) !== -1) {
                    let updatedArticle = {
                        ...article
                    };
                    updatedArticle.subCateg = null;
                    return updatedArticle;
                } else {
                    return article
                }
            });
            return {
                ...state,
                articles: newArticles
            };
        };
        case 'FETCH_ARTICLES_BY_OFFERID_REQUEST':
            return {
                ...state,
                loadedArticlesByOfferId: false
            };
        case 'FETCH_ARTICLES_BY_OFFERID_SUCCESS': {
            let newArticles = [...action.payload.articles];
            return {
                ...state,
                articlesByOfferId: newArticles,
                loadedArticlesByOfferId: true
            };
        };
        case 'FETCH_ARTICLES_BY_OFFERID_ERROR':
            return {
                ...state,
                articlesError: action.error,
                loadedArticlesByOfferId: true
            };
        case 'CLEAR_BRAND_ARTICLES':
            return {
                ...state,
                articlesError: action.error,
                loadedArticlesByOfferId: false,
                articlesByOfferId: [],
            };
        case 'DELETE_ARTICLE_BY_ID_REQUEST':
            return {
                ...state,
                loadedDeleteArticle: true,
            };
        case 'DELETE_ARTICLE_BY_ID_SUCCESS':
            const newArticles = [...state.articles.filter(article => article.key !== action.payload.articleId)];
            return {
                ...state,
                articles: newArticles,
                loadedDeleteArticle: false,
            };
        case 'DELETE_ARTICLE_BY_ID_ERROR':
            return {
                ...state,
                articlesError: action.payload.error
            };
        default:
            return state;
    }
}

export default articlesReducer;