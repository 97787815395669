import React from 'react';
import { Route, Redirect } from 'react-router-dom';


const PrivateRoute = ({ component: Component, user: currentUser, ...rest }) => {
    return <Route {...rest} render={(props) => {
        return (
            (currentUser)
                ? <Component {...rest} user={currentUser} history={props.history} />
                : <Redirect to={{
                    pathname: '/login',
                    state: { previousRoute: rest.location.pathname }
                }} />
        )
    }} />
}

export default PrivateRoute