import React, { useState, useEffect, useCallback } from "react";
import {
  Grid,
  Typography,
  TextField,
  Box,
  Button,
  Select,
  MenuItem,
  useTheme,
  useMediaQuery,
} from "@material-ui/core";
import { makeStyles } from "@material-ui/core";
import { MuiPickersUtilsProvider, DatePicker } from "@material-ui/pickers";
import ArrowRightAltIcon from "@material-ui/icons/ArrowRightAlt";
import ArrowDownwardIcon from "@material-ui/icons/ArrowDownward";
import EuroSymbolIcon from "@material-ui/icons/EuroSymbol";
import SVGIcon from "../template/assets/SVGIcons/index";
import TouchAppIcon from "@material-ui/icons/TouchApp";
import SearchIcon from "@material-ui/icons/Search";
import DateFnsUtils from "@date-io/date-fns";
import InfluencersTable from "../template/components/statistics/InfluencersTable";
import ProductsTable from "../template/components/statistics/ProductsTable";
import CountryTable from "../template/components/statistics/CountryTable";
import BootstrapInput from "../template/assets/customComponents/BootstrapInput";
import { MyDate } from "../../utils";
import ProductPieChart from "../template/components/ProductPieChart";
import ConversionsChart from "../template/components/ConversionsChart";
import InfluencerModal from "../template/components/statistics/modals/InfluencerModal";
import ProductModal from "../template/components/statistics/modals/ProductModal";
import CountryModal from "../template/components/statistics/modals/CountryModal";
import TrendLoading from "../../ui/trendLoading/TrendLoading";
import CircularProgress from "@material-ui/core/CircularProgress";
import PlaceholderImage from "../assets/placeholder_sales.png";
import { dbManager } from "../../managers";
import { clicksManager } from "../../managers";
import CountUp from "react-countup";
import { useTranslation } from "react-i18next";
import { connect } from "react-redux";

const useInputStyles = makeStyles((theme) => ({
  root: {
    overflow: "hidden",
    borderRadius: 24,
    backgroundColor: "#fff",
    textAlign: "center",
    width: "170px",
    height: "40px",
    boxShadow: "0 7px 30px 0 rgba(88, 165, 211, 0.16)",
    "&:hover": {
      backgroundColor: "#fff",
    },
    "&$focused": {
      backgroundColor: "#fff",
      borderColor: "#ff005f",
    },
    margin: "5px",
  },
  input: {
    textAlign: "center",
    fontFamily: "Poppins",
    fontWeight: 500,
  },
  focused: {},
}));

const useStyles = makeStyles((theme) => ({
  titles: {
    fontFamily: "Poppins",
    fontWeight: 500,
  },
  smallBox: {
    boxShadow: "0 7px 30px 0 rgba(58, 111, 142, 0.25)",
    backgroundColor: "#fff",
    borderRadius: "41px",
    width: "240px",
    height: "150px",
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    margin: 10,
  },
  smallBoxTitles: {
    fontFamily: "Poppins",
    fontSize: "16px",
    fontWeight: 500,
    overflow: "hidden",
    textOverflow: "ellipsis",
  },
  smallBoxInfos: {
    fontFamily: "Poppins",
    fontSize: "16px",
    fontWeight: 900,
    overflow: "hidden",
    textOverflow: "ellipsis",
  },
  rowSpacing: {
    marginTop: "10px",
    marginBottom: "10px",
  },
  conversionBox: {
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    backgroundColor: "white",
    height: "300px",
    width: "95%",
    borderRadius: "20px",
    boxShadow: "0 7px 30px 0 rgba(88, 165, 211, 0.16)",
  },
  pieBox: {
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    backgroundColor: "white",
    height: "300px",
    width: "95%",
    borderRadius: "20px",
    boxShadow: "0 7px 30px 0 rgba(88, 165, 211, 0.16)",
  },
  activeButtonStyle: {
    width: 250,
    margin: "5px",
    color: "#ffffff",
    borderRadius: "35px",
    backgroundColor: "#3eadef",
    fontSize: "14px",
    textTransform: "none",
    fontFamily: "Poppins",
    boxShadow: "0 7px 30px 0 rgba(88, 165, 211, 0.16)",
    "&:hover": {
      backgroundColor: "#3eadef",
    },
  },
  buttonStyle: {
    width: 250,
    margin: "5px",
    color: "#000",
    borderRadius: "35px",
    backgroundColor: "#ffffff",
    fontSize: "14px",
    textTransform: "none",
    fontFamily: "Poppins",
    boxShadow: "0 7px 30px 0 rgba(88, 165, 211, 0.16)",
  },
  selectClass: {
    fontFamily: "Poppins",
    borderRadius: "25px",
    width: "130px",
    // maxWidth: '120px',
    "&:focus": {
      borderRadius: "25px",
      backgroundColor: "#ffffff",
    },
    boxShadow: "0 7px 30px 0 rgba(88, 165, 211, 0.16)",
  },
  textPlaceholder: {
    fontFamily: "Poppins",
    fontSize: "16px",
    margin: 10,
    color: "#1b4c61",
    textAlign: "center",
  },
  buttonDateStyle: {
    width: "170px",
    height: "40px",
    margin: "5px",
    overflow: "hidden",
    boxShadow: "0 7px 30px 0 rgb(88 165 211 / 16%)",
    textAlign: "center",
    textTransform: "none",
    borderRadius: "24px",
    backgroundColor: "#3eadef",
    "&:hover": {
      backgroundColor: "#3eadef",
    },
  },
  textButtonDate: {
    textAlign: "center",
    fontFamily: "Poppins",
    fontWeight: 500,
    color: "#fff",
  },
}));

const Statistics = (props) => {
  const { t } = useTranslation("common");
  const {
    brand,
    brandConversions,
    loadedBrandConversions,
    influencers,
    loadedInfluencers,
  } = props;
  const inputClasses = useInputStyles();
  const classes = useStyles();
  const theme = useTheme();
  const xsDown = useMediaQuery(theme.breakpoints.down("xs"));
  const mdDown = useMediaQuery(theme.breakpoints.down("md"));

  const [activeFilter, setActiveFilter] = useState("influencers");
  const [periodSelector, setPeriodSelector] = useState("today");
  const [startPeriodDate, setStartPeriodDate] = useState(new MyDate().dayStart());
  const [endPeriodDate, setEndPeriodDate] = useState(new MyDate().dayEnd());
  const [startPeriodDateClick, setStartPeriodDateClick] = useState(
    new MyDate()
  );
  const [endPeriodDateClick, setEndPeriodDateClick] = useState(new MyDate());
  const [periodBrandConversions, setPeriodBrandConversions] = useState([]);
  const [periodBrandClicksLoading, setPeriodBrandClicksLoading] = useState(
    false
  );
  const [periodSalesAmount, setPeriodSalesAmount] = useState(0);
  const [periodUnpaidCommission, setPeriodUnpaidCommission] = useState(0);
  const [periodCommissionSMI, setPeriodCommissionSMI] = useState(0);
  const [periodInfluencers, setPeriodInfluencers] = useState([]);
  const [periodClickInfluencersIds, setPeriodClickInfluencersIds] = useState(
    {}
  );
  const [periodProducts, setPeriodProducts] = useState([]);
  const [periodCountries, setPeriodCountries] = useState([]);
  const [periodBrandClicks, setPeriodBrandClicks] = useState(0);
  const [popupMode, setPopupMode] = useState(false);
  const [popupType, setPopupType] = useState("influencer");
  const [popupData, setPopupData] = useState({});
  const [loading, setLoading] = useState(true);


  const filterInfluencer = useCallback(
    async (conversion, index, influencersIds) => {
      if (index === 0) {
        const data = influencers.filter(
          (element) => element.key === conversion.influencer
        )[0];
        influencersIds.push({
          influencerId: conversion.influencer,
          amount: conversion.amount,
          salesNumber: 1,
          products: [conversion.articleId],
          commission: Number(conversion.commission),
          ...data,
        });
      } else {
        let incremented = false;
        for (let j = 0; j < influencersIds.length; j++) {
          if (influencersIds[j].influencerId === conversion.influencer) {
            influencersIds[j].amount += conversion.amount;
            influencersIds[j].commission += Number(conversion.commission);
            influencersIds[j].salesNumber++;
            if (influencersIds[j].products.indexOf(conversion.articleId) === -1)
              influencersIds[j].products.push(conversion.articleId);
            incremented = true;
            break;
          }
        }
        if (!incremented) {
          const data = influencers.filter(
            (element) => element.key === conversion.influencer
          )[0];
          influencersIds.push({
            influencerId: conversion.influencer,
            amount: conversion.amount,
            commission: Number(conversion.commission),
            products: [conversion.articleId],
            salesNumber: 1,
            product: 0,
            ...data,
          });
        }
      }
    },
    [influencers]
  );

  const newFilterArticle = useCallback(async () => {
    let obj = {};
    periodBrandConversions.forEach((conversion) => {
      let found = false;
      Object.keys(obj).forEach((objConversionId) => {
        if (objConversionId === conversion.articleId) {
          obj[objConversionId].push(conversion);
          found = true;
        }
      });
      if (!found) obj[conversion.articleId] = [conversion];
    });
    let newArray = [];
    Object.values(obj).forEach((articleConversions) => {
      let amount = 0;
      let commissions = 0;
      let productPurchasers = [];
      let articleImgUrl = "";
      let articlePathUrl = "";
      let purchaseNumber = articleConversions.length;
      let articleId = "";
      let nameInfluencer = "";
      articleConversions.forEach((conversion) => {
        let data;
        influencers.filter(
          (element) => {
            if (element.key === conversion.influencer) {
              nameInfluencer = element.name;
            }
            return null
          });
        articleId = conversion.articleId;
        amount += conversion.amount;
        commissions += Number(conversion.commission);
        if (productPurchasers.indexOf(conversion.influencer) === -1)
          productPurchasers.push(conversion.influencer);
        if (articleImgUrl === "") {
          if (conversion.articleImgUrl === undefined) {
            dbManager
              .fetchSoldArticleById(conversion.articleId)
              .then((res) => {
                data = res;
              })
              .then(() => {
                articleImgUrl =
                  conversion.articleImgUrl === undefined
                    ? data
                      ? data.image
                      : ""
                    : conversion.articleImgUrl;
                articlePathUrl =
                  conversion.articlePathUrl === undefined
                    ? data
                      ? data.image
                      : ""
                    : conversion.articlePathUrl;
              });
          }
          articleImgUrl =
            conversion.articleImgUrl === undefined
              ? data
                ? data.image
                : ""
              : conversion.articleImgUrl;
          articlePathUrl =
            conversion.articlePathUrl === undefined
              ? data
                ? data.image
                : ""
              : conversion.articlePathUrl;
        }
      });
      newArray.push({
        articleId,
        purchaseNumber,
        salesNumber: articleConversions.length,
        amount,
        commissions,
        productPurchasers,
        articleImgUrl,
        articlePathUrl,
        nameInfluencer
      });
    });
    return newArray;
  }, [periodBrandConversions, influencers]);

  const filterCountry = (conversion, index, countryIds) => {
    if (index === 0) {
      countryIds.push({
        countryCode: conversion.countryCode,
        articleIds: [conversion.articleId],
        amount: conversion.amount,
        commissions: Number(conversion.commission),
        purchaseNumber: 1,
      });
    } else {
      let incremented = false;
      for (let j = 0; j < countryIds.length; j++) {
        if (countryIds[j].countryCode === conversion.countryCode) {
          countryIds[j].purchaseNumber++;
          countryIds[j].amount += conversion.amount;
          countryIds[j].commissions += Number(conversion.commission);
          if (countryIds[j].articleIds.indexOf(conversion.articleId) === -1)
            countryIds[j].articleIds.push(conversion.articleId);
          incremented = true;
          break;
        }
      }
      if (!incremented) {
        countryIds.push({
          countryCode: conversion.countryCode,
          articleIds: [conversion.articleId],
          amount: conversion.amount,
          commissions: Number(conversion.commission),
          purchaseNumber: 1,
        });
      }
    }
  };

  const filter = useCallback(async () => {
    let influencersIds = [];
    let productsIds = [];
    let countryIds = [];
    await newFilterArticle().then((result) => {
      productsIds = result;
    });

    for (let i = 0; i < periodBrandConversions.length; i++) {
      await filterInfluencer(periodBrandConversions[i], i, influencersIds);
      filterCountry(periodBrandConversions[i], i, countryIds);
    }

    influencersIds.sort((a, b) => {
      if (a.amount > b.amount) return -1;
      if (a.amount < b.amount) return 1;
      return 0;
    });

    productsIds.sort((a, b) => {
      if (a.amount > b.amount) return -1;
      if (a.amount < b.amount) return 1;
      return 0;
    });

    countryIds.sort((a, b) => {
      if (a.amount > b.amount) return -1;
      if (a.amount < b.amount) return 1;
      return 0;
    });

    const filteredInfluencers = influencersIds.filter(
      (element) =>
        element.name !== undefined &&
        element.name !== null &&
        element.name !== ""
    );
    filteredInfluencers.forEach((item) => {
      item["clicks"] =
        periodClickInfluencersIds[item.key] === undefined
          ? 0
          : periodClickInfluencersIds[item.key];
    });
    let temp = Object.entries(periodClickInfluencersIds).filter(
      ([uid, influencerClicksNumber]) => {
        let commonInfluencer = filteredInfluencers.filter(
          (item) => item.key === uid
        )[0];
        return commonInfluencer === undefined ? true : false;
      }
    );

    temp.forEach(([uid, influencerClicksNumber]) => {
      let influencerToAdd = influencers.filter((item) => item.key === uid)[0];
      if (influencerToAdd !== undefined) {
        if (
          influencerToAdd.name !== undefined &&
          influencerToAdd.banner !== undefined &&
          influencerToAdd.name !== ""
        )
          filteredInfluencers.push({
            ...influencerToAdd,
            influencerId: uid,
            amount: 0,
            commission: 0,
            products: [],
            salesNumber: 0,
            product: 0,
            clicks: influencerClicksNumber,
          });
      }
    });
    setPeriodInfluencers(filteredInfluencers);
    setPeriodProducts(productsIds);
    setPeriodCountries(countryIds);
  }, [
    newFilterArticle,
    filterInfluencer,
    periodBrandConversions,
    periodClickInfluencersIds,
    influencers,
  ]);

  const changeFilter = (filter) => {
    if (filter === "influencers") {
      setActiveFilter("influencers");
    } else if (filter === "products") {
      setActiveFilter("products");
    } else {
      setActiveFilter("country");
    }
  };

  const handlePeriodSelector = (event) => {
    setPeriodSelector(event.target.value);
  };

  const filterConversions = (startDate, endDate, conversions) => {
    const startTimestamp = Math.round(startDate.getTime() / 1000);
    const endTimestamp = Math.round(endDate.getTime() / 1000);
    return conversions
      .filter(
        (element) =>
          element.createdAt < endTimestamp && element.createdAt > startTimestamp
      )
      .sort((a, b) =>
        a.createdAt > b.createdAt ? -1 : a.createdAt < b.createdAt ? 1 : 0
      );
  };

  const openPopup = (popupType, data) => {
    setPopupMode(true);
    setPopupType(popupType);
    setPopupData(data);
  };

  const closePopup = () => {
    setPopupMode(false);
  };

  const getPreviousMonday = () => {
    let date = new MyDate();
    let day = date.getDay();
    let prevMonday;
    if (date.getDay() === 0) {
      prevMonday = new MyDate();
      prevMonday.setDate(date.getDate() - 7);
      return prevMonday;
    } else {
      prevMonday = new MyDate();
      prevMonday.setDate(date.getDate() - day + 1);
      return prevMonday;
    }
  };

  const handleViewInfluencer = (influencerId) => {
    props.handleInfluencerToView(influencerId);
  };

  //Effect for handling period change
  useEffect(() => {
    switch (periodSelector) {
      case "today":
        setStartPeriodDateClick(new MyDate().dayStart());
        setEndPeriodDateClick(new MyDate().dayEnd());
        break;
      case "week":
        {
          let prevMonday = getPreviousMonday().dayStart();
          let endOfWeek = getPreviousMonday().addWeeks(1).addDays(-1).dayEnd();
          setStartPeriodDateClick(prevMonday);
          setEndPeriodDateClick(endOfWeek);
        }
        break;
      case "month":
        {
          let startDate = new MyDate();
          startDate.setDate(1);
          startDate = startDate.dayStart();
          let endDate = new MyDate().addMonths(1);
          endDate.setDate(1);
          endDate = endDate.addDays(-1).dayEnd();
          setStartPeriodDateClick(startDate);
          setEndPeriodDateClick(endDate);
        }
        break;
      case "lastWeek":
        {
          let monday = getPreviousMonday().addWeeks(-1).dayStart();
          let endOfWeek = getPreviousMonday().addDays(-1).dayEnd();
          setStartPeriodDateClick(monday);
          setEndPeriodDateClick(endOfWeek);
        }
        break;
      case "lastMonth":
        {
          let startDate = new MyDate();
          startDate.setDate(1);
          startDate = startDate.addMonths(-1).dayStart();
          let endDate = new MyDate();
          endDate.setDate(1);
          endDate = endDate.addDays(-1).dayEnd();
          setStartPeriodDateClick(startDate);
          setEndPeriodDateClick(endDate);
        }
        break;
      case "customMode":
        break;
      default:
        break;
    }
  }, [periodSelector]);

  useEffect(() => {
    if (loadedBrandConversions) {
      setPeriodBrandClicksLoading(false);
      setPeriodBrandConversions(
        filterConversions(startPeriodDate, endPeriodDate, brandConversions)
      );
      clicksManager
        .getClicksPerBrand(
          startPeriodDate.getTime(),
          endPeriodDate.getTime(),
          brand.offerId,
          startPeriodDate.getTimezoneOffset()
        )
        .then((result) => {
          setPeriodBrandClicks(result.data.clicksNumber);
          setPeriodClickInfluencersIds(result.data.influencersClicks);
          setPeriodBrandClicksLoading(true);
        });
    }
  }, [
    startPeriodDate,
    endPeriodDate,
    brandConversions,
    brand.offerId,
    loadedBrandConversions,
  ]);

  const handleEndPeriodChange = (value) => {
    setEndPeriodDateClick(value);
  };
  const handleStartPeriodChange = (value) => {
    setStartPeriodDateClick(value);
  };

  // Effect for handling remaining data by conversions
  useEffect(() => {
    if (
      loadedInfluencers &&
      loadedBrandConversions &&
      periodBrandClicksLoading
    ) {
      let amount = 0;
      let unpaidCommission = 0;
      let commissionSMI = 0;
      setLoading(true);
      periodBrandConversions.forEach((conversion) => {
        amount += conversion.amount;
        unpaidCommission += Number(conversion.commission);
        commissionSMI += Number(conversion.commission) - Number(conversion.commissionAffiliate);
      });
      setPeriodUnpaidCommission(unpaidCommission);
      setPeriodSalesAmount(amount);
      setPeriodCommissionSMI(commissionSMI);

      filter().then(() => {
        setLoading(false);
      });
    }
  }, [
    periodBrandClicksLoading,
    periodBrandConversions,
    loadedInfluencers,
    loadedBrandConversions,
    filter,
  ]);

  return (
    <Grid container justify="center" style={{ marginTop: "20px" }}>
      {!popupMode ? (
        <>
          <Grid
            container
            item
            direction="row"
            wrap="wrap"
            lg={11}
            justify={mdDown ? "center" : "flex-end"}
            alignItems="center"
            className={classes.rowSpacing}
          >
            <Typography className={classes.titles}>
              {t("TREND.VIEWS.STATISTICS.PERIOD")}:{" "}
            </Typography>
            <Select
              value={periodSelector}
              onChange={handlePeriodSelector}
              style={{ margin: 10 }}
              input={<BootstrapInput />}
              IconComponent={() => <SVGIcon name="miniArrow" />}
              classes={{ root: classes.selectClass }}
            >
              <MenuItem value="month" style={{ fontFamily: "Poppins" }}>
                {t("TREND.VIEWS.STATISTICS.PERIOD_SELECTOR.CURRENT_MONTH")}
              </MenuItem>
              <MenuItem value="week" style={{ fontFamily: "Poppins" }}>
                {t("TREND.VIEWS.STATISTICS.PERIOD_SELECTOR.CURRENT_WEEK")}
              </MenuItem>
              <MenuItem value="today" style={{ fontFamily: "Poppins" }}>
                {t("TREND.VIEWS.STATISTICS.PERIOD_SELECTOR.TODAY")}
              </MenuItem>
              <MenuItem value="lastWeek" style={{ fontFamily: "Poppins" }}>
                {t("TREND.VIEWS.STATISTICS.PERIOD_SELECTOR.PREVIOUS_WEEK")}
              </MenuItem>
              <MenuItem value="lastMonth" style={{ fontFamily: "Poppins" }}>
                {t("TREND.VIEWS.STATISTICS.PERIOD_SELECTOR.PREVIOUS_MONTH")}
              </MenuItem>
              <MenuItem value="customMode" style={{ fontFamily: "Poppins" }}>
                {t("TREND.VIEWS.STATISTICS.PERIOD_SELECTOR.CUSTOMIZED")}
              </MenuItem>
            </Select>
            <div
              style={{
                display: "flex",
                flexDirection: xsDown ? "column" : "row",
                alignItems: xsDown ? "center" : "center",
                flexWrap: "wrap",
                justifyContent: xsDown ? "center" : "flex-start",
              }}
            >
              <MuiPickersUtilsProvider utils={DateFnsUtils}>
                <DatePicker
                  format="dd-MM-yyyy"
                  views={["year", "month", "date"]}
                  value={startPeriodDateClick}
                  onChange={handleStartPeriodChange}
                  onOpen={() => {
                    setPeriodSelector("customMode");
                  }}
                  TextFieldComponent={(props) => (
                    <TextField
                      {...props}
                      InputProps={{
                        classes: inputClasses,
                        disableUnderline: true,
                      }}
                    />
                  )}
                />
              </MuiPickersUtilsProvider>
              {!xsDown ? (
                <ArrowRightAltIcon fontSize="large" />
              ) : (
                <ArrowDownwardIcon fontSize="large" />
              )}

              <MuiPickersUtilsProvider utils={DateFnsUtils}>
                <DatePicker
                  format="dd-MM-yyyy"
                  views={["year", "month", "date"]}
                  value={endPeriodDateClick}
                  onChange={handleEndPeriodChange}
                  onOpen={() => setPeriodSelector("customMode")}
                  TextFieldComponent={(props) => (
                    <TextField
                      {...props}
                      InputProps={{
                        classes: inputClasses,
                        disableUnderline: true,
                      }}
                    />
                  )}
                />
              </MuiPickersUtilsProvider>
              <Button
                className={classes.buttonDateStyle}
                onClick={() => {
                  setStartPeriodDate(startPeriodDateClick);
                  setEndPeriodDate(endPeriodDateClick);
                }}
              >
                <SearchIcon style={{ color: "#fff", height: "20px" }} />
                <Typography className={classes.textButtonDate}>
                  {t("TREND.VIEWS.STATISTICS.BUTTON_DATE")}
                </Typography>
              </Button>
            </div>
          </Grid>
          <Grid
            container
            item
            direction="row"
            xs={11}
            className={classes.rowSpacing}
          >
            <Grid container item direction="row" wrap="wrap" justify="center">
              <Box className={classes.smallBox}>
                {loading ? (
                  <TrendLoading />
                ) : (
                  <Grid container direction="row">
                    <Grid container item xs={6} justify="center">
                      <EuroSymbolIcon
                        style={{ fontSize: "60px", color: "#ef7e3e" }}
                      />
                    </Grid>
                    <Grid
                      container
                      item
                      xs={6}
                      direction="column"
                      justify="center"
                    >
                      <Typography className={classes.smallBoxTitles}>
                        {t("TREND.VIEWS.STATISTICS.TURNOVER_BOX_TITLE")}
                      </Typography>
                      <Grid container item direction="row">
                        <CountUp
                          className={classes.smallBoxInfos}
                          start={0}
                          end={Math.round(periodSalesAmount * 100) / 100}
                          decimals={2}
                          decimal=","
                        />
                        <span className={classes.smallBoxInfos}> €</span>
                      </Grid>
                      {/* <Typography className={classes.smallBoxInfos}>{Math.round(periodSalesAmount * 100) / 100} €</Typography> */}
                    </Grid>
                  </Grid>
                )}
              </Box>
              <Box className={classes.smallBox}>
                {loading ? (
                  <TrendLoading />
                ) : (
                  <Grid container direction="row">
                    <Grid container item xs={6} justify="center">
                      <SVGIcon name="ventes" width={"60px"} height={"60px"} />
                    </Grid>
                    <Grid
                      container
                      item
                      xs={6}
                      direction="column"
                      justify="center"
                    >
                      <Typography className={classes.smallBoxTitles}>
                        {t("TREND.VIEWS.STATISTICS.SALES_BOX_TITLE")}
                      </Typography>
                      <Grid item>
                        <CountUp
                          className={classes.smallBoxInfos}
                          start={0}
                          end={periodBrandConversions.length}
                        />
                      </Grid>
                      {/* <Typography className={classes.smallBoxInfos}>{periodBrandConversions.length}</Typography> */}
                    </Grid>
                  </Grid>
                )}
              </Box>
              <Box className={classes.smallBox}>
                {loading ? (
                  <TrendLoading />
                ) : (
                  <Grid container direction="row">
                    <Grid container item xs={6} justify="center">
                      <TouchAppIcon
                        style={{ fontSize: "60px", color: "#3568eb" }}
                      />
                    </Grid>
                    <Grid
                      container
                      item
                      xs={6}
                      direction="column"
                      justify="center"
                    >
                      <Typography className={classes.smallBoxTitles}>
                        {t("TREND.VIEWS.STATISTICS.CLICKS_BOX_TITLE")}
                      </Typography>
                      <Grid item>
                        <CountUp
                          className={classes.smallBoxInfos}
                          start={0}
                          end={periodBrandClicks}
                        />
                      </Grid>
                      {/* <Typography className={classes.smallBoxInfos}>{periodBrandClicks}</Typography> */}
                    </Grid>
                  </Grid>
                )}
              </Box>
              <Box className={classes.smallBox}>
                {loading ? (
                  <TrendLoading />
                ) : (
                  <Grid container direction="row">
                    <Grid container item xs={6} justify="center">
                      <SVGIcon
                        name="commissionPaye"
                        width={"60px"}
                        height={"60px"}
                      />
                    </Grid>
                    <Grid
                      container
                      item
                      xs={6}
                      direction="column"
                      justify="center"
                    >
                      <Typography className={classes.smallBoxTitles}>
                        {t("TREND.VIEWS.STATISTICS.PAID_COMMISSIONS_BOX_TITLE")}
                      </Typography>
                      <Typography className={classes.smallBoxInfos}>
                        0 €
                      </Typography>
                    </Grid>
                  </Grid>
                )}
              </Box>
              <Box className={classes.smallBox}>
                {loading ? (
                  <TrendLoading />
                ) : (
                  <Grid container direction="row">
                    <Grid container item xs={6} justify="center">
                      <SVGIcon
                        name="commission"
                        width={"60px"}
                        height={"60px"}
                      />
                    </Grid>
                    <Grid container item direction="row" xs={6}>
                      <Typography className={classes.smallBoxTitles}>
                        {t(
                          "TREND.VIEWS.STATISTICS.UNPAID_COMMISSIONS_BOX_TITLE"
                        )}
                      </Typography>
                      <CountUp
                        className={classes.smallBoxInfos}
                        start={0}
                        end={Math.round(periodUnpaidCommission * 100) / 100}
                        decimals={2}
                        decimal=","
                      />
                      <span className={classes.smallBoxInfos}> €</span>
                    </Grid>
                  </Grid>
                )}
              </Box>
              <Box className={classes.smallBox}>
                {loading ? (
                  <TrendLoading />
                ) : (
                  <Grid container direction="row">
                    <Grid container item xs={6} justify="center">
                      <SVGIcon
                        name="commission"
                        width={"60px"}
                        height={"60px"}
                      />
                    </Grid>
                    <Grid container item direction="row" xs={6}>
                      <Typography className={classes.smallBoxTitles}>
                        Commissions SMI
                      </Typography>
                      <CountUp
                        className={classes.smallBoxInfos}
                        start={0}
                        end={Math.round(periodCommissionSMI * 100) / 100}
                        decimals={2}
                        decimal=","
                      />
                      <span className={classes.smallBoxInfos}> €</span>
                    </Grid>
                  </Grid>
                )}
              </Box>
            </Grid>
          </Grid>
          <Grid
            container
            direction="row"
            item
            xs={11}
            justify="center"
            alignItems="center"
            style={{ marginBottom: "15px" }}
          >
            <Grid
              container
              direction="row"
              item
              md={7}
              sm={12}
              justify="center"
            >
              <Grid
                container
                justify="center"
                style={{ marginTop: "10px", marginBottom: "10px" }}
              >
                <Typography className={classes.titles}>
                  {t("TREND.VIEWS.STATISTICS.SALES_STATISTICS_BOX_TITLE")}
                </Typography>
              </Grid>
              <Box className={classes.conversionBox}>
                {loading ? (
                  <TrendLoading />
                ) : periodBrandConversions.length !== 0 ? (
                  <ConversionsChart
                    {...{ customDurationConversions: periodBrandConversions }}
                  />
                ) : (
                  <Grid
                    container
                    direction="column"
                    justify="space-around"
                    alignItems="center"
                    style={{ height: "50%" }}
                  >
                    <img
                      alt="No sales"
                      src={PlaceholderImage}
                      height="75px"
                      width="75px"
                    />
                    <Typography className={classes.textPlaceholder}>
                      {t(
                        "TREND.VIEWS.STATISTICS.NO_PERIOD_GENERATED_SALES_PLACEHOLDER"
                      )}
                    </Typography>
                  </Grid>
                )}
              </Box>
            </Grid>
            <Grid
              container
              direction="row"
              item
              md={5}
              sm={12}
              justify="center"
            >
              <Grid
                container
                justify="center"
                style={{ marginTop: "10px", marginBottom: "10px" }}
              >
                <Typography className={classes.titles}>
                  {t(
                    "TREND.VIEWS.STATISTICS.SALES_PER_PRODUCT_NUMBER_BOX_TITLE"
                  )}
                </Typography>
              </Grid>
              <Box className={classes.pieBox}>
                {loading ? (
                  <TrendLoading />
                ) : periodBrandConversions.length !== 0 ? (
                  <ProductPieChart
                    {...{
                      articlesInfos: periodProducts,
                      startPeriodDate,
                      endPeriodDate,
                    }}
                  />
                ) : (
                  <Grid
                    container
                    direction="column"
                    justify="space-around"
                    alignItems="center"
                    style={{ height: "50%" }}
                  >
                    <img
                      alt="No sales"
                      src={PlaceholderImage}
                      height="75px"
                      width="75px"
                    />
                    <Typography className={classes.textPlaceholder}>
                      {t(
                        "TREND.VIEWS.STATISTICS.NO_PERIOD_GENERATED_SALES_PLACEHOLDER"
                      )}
                    </Typography>
                  </Grid>
                )}
              </Box>
            </Grid>
          </Grid>
          {periodInfluencers.length !== 0 ? (
            <Grid
              container
              item
              direction="row"
              xs={11}
              className={classes.rowSpacing}
            >
              <Grid container item xs={12} justify="center">
                <Grid container direction="column">
                  <Typography className={classes.titles} style={{ margin: 5 }}>
                    {t("TREND.VIEWS.STATISTICS.STATISTICS_FILTER_TITLE")}:
                  </Typography>
                  <Grid container direction="row" wrap="wrap">
                    <Button
                      className={
                        activeFilter === "influencers"
                          ? classes.activeButtonStyle
                          : classes.buttonStyle
                      }
                      onClick={() => changeFilter("influencers")}
                      disableRipple
                    >
                      {t("TREND.VIEWS.STATISTICS.FILTER_INFLUENCER_TITLE")}
                    </Button>
                    <Button
                      className={
                        activeFilter === "products"
                          ? classes.activeButtonStyle
                          : classes.buttonStyle
                      }
                      onClick={() => changeFilter("products")}
                      disableRipple
                    >
                      {t("TREND.VIEWS.STATISTICS.FILTER_PRODUCT_TITLE")}
                    </Button>
                    <Button
                      className={
                        activeFilter === "country"
                          ? classes.activeButtonStyle
                          : classes.buttonStyle
                      }
                      onClick={() => changeFilter("country")}
                      disableRipple
                    >
                      {t("TREND.VIEWS.STATISTICS.FILTER_COUNTRY_TITLE")}
                    </Button>
                  </Grid>
                </Grid>
              </Grid>
              {loading ? (
                <Grid container justify="center">
                  <CircularProgress color="secondary" />
                </Grid>
              ) : activeFilter === "influencers" ? (
                <InfluencersTable
                  {...{ periodInfluencers }}
                  openPopup={(data) => openPopup("influencer", data)}
                />
              ) : activeFilter === "products" ? (
                <ProductsTable
                  {...{ periodProducts }}
                  openPopup={(data) => openPopup("product", data)}
                />
              ) : (
                <CountryTable
                  {...{ periodCountries }}
                  openPopup={(data) => openPopup("country", data)}
                />
              )}
            </Grid>
          ) : (
            <></>
          )}
        </>
      ) : popupType === "influencer" ? (
        <InfluencerModal
          close={() => closePopup()}
          {...{
            data: popupData,
            periodBrandConversions,
            startPeriodDate,
            endPeriodDate,
            brand,
          }}
        />
      ) : popupType === "product" ? (
        <ProductModal
          close={() => closePopup()}
          {...{
            data: popupData,
            periodBrandConversions: periodBrandConversions,
            filteredInfluencers: periodInfluencers,
            url: props.url,
            startPeriodDate,
            endPeriodDate,
            brand,
          }}
          handleViewInfluencer={(influencerId) =>
            handleViewInfluencer(influencerId)
          }
        />
      ) : (
        <CountryModal
          close={() => closePopup()}
          {...{ data: popupData, periodBrandConversions }}
        />
      )}
    </Grid>
  );
};

const mapStateToProps = (state, ownProps) => {
  const brandConversions = state.conversionsState.brandConversions;
  const loadedBrandConversions = state.conversionsState.loadedBrandConversions;
  const influencers = state.influencersState.influencers;
  const loadedInfluencers = state.influencersState.loadedInfluencers;
  return {
    brandConversions,
    loadedBrandConversions,
    influencers,
    loadedInfluencers,
  };
};

export default connect(mapStateToProps, null)(Statistics);
