import React from "react";
import clsx from "clsx";

import "./DualRing.css";

const DualRing = ({ style }) => {
  return <div className={clsx("ldsDualRing", style && style)}></div>;
};

export default DualRing;
