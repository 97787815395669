import React, { useState, useEffect } from "react";
import { makeStyles } from "@material-ui/styles";
import { connect } from "react-redux";
import { Grid, Typography, Box, Select, MenuItem, Input } from "@material-ui/core";
import { Pagination, PaginationItem } from '@material-ui/lab';
import SVGIcon from '../../assets/SVGIcons/index';
import BootstrapInput from '../../assets/customComponents/BootstrapInput';
import ExpandLessIcon from '@material-ui/icons/ExpandLess';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import SortIcon from '@material-ui/icons/Sort';
import CircularProgress from '@material-ui/core/CircularProgress';
import { useTranslation } from "react-i18next";

const useStyles = makeStyles(theme => ({
    rowSpacing: {
        marginTop: '10px',
        marginBottom: '10px'
    },
    influencerBoxContainer: {
        marginTop: '10px',
        marginBottom: '10px',
        minWidth: '1050px'
    },
    influencerBox: {
        display: 'flex',
        width: '100%',
        backgroundColor: 'white',
        height: '80px',
        borderRadius: '27px',
        boxShadow: '0 7px 30px 0 rgba(88, 165, 211, 0.16)',
    },
    influencerImage: {
        width: '160px',
        height: '80px',
        borderTopLeftRadius: '27px',
        borderBottomLeftRadius: '27px',
        objectFit: 'fill'
    },
    tableHeader: {
        fontFamily: 'Poppins',
        fontSize: '14px',
        textAlign: 'center',
        '&:hover': {
            cursor: 'pointer',
        }
    },
    tableRowContent: {
        fontFamily: 'Poppins',
    },
    influencerName: {
        fontFamily: 'Poppins',
        textDecoration: 'underline',
        '&:hover': {
            cursor: 'pointer',
        }
    },
    paginationClass: {
        backgroundColor: '#ffffff !important',
        boxShadow: '0 7px 30px 0 rgba(88, 165, 211, 0.16)',
        borderRadius: '8px',
        fontFamily: 'Poppins',
        height: '40px',
        width: '40px',
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center'
    },
    selectedPaginationItem: {
        backgroundColor: '#3eadef !important',
        boxShadow: '0 7px 30px 0 rgba(88, 165, 211, 0.16)',
        borderRadius: '8px',
        fontFamily: 'Poppins',
        height: '40px',
        width: '40px',
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        color: '#ffffff'
    },
}));
const useSearchInputStyles = makeStyles(theme => ({
    root: {
        overflow: "hidden",
        borderRadius: 24,
        backgroundColor: "#fff",
        margin: 5,
        marginTop: 15,
        width: '95%',
        maxWidth: '400px',
        boxShadow: '0 7px 30px 0 rgba(88, 165, 211, 0.16)',
        "&:hover": {
            backgroundColor: "#fff"
        },
        "&$focused": {
            backgroundColor: "#fff",
            borderColor: "#ff005f"
        }
    },
    input: {
        fontFamily: 'Poppins',
        fontWeight: 500,
        paddingLeft: 15
    },
    focused: {}
}));

const InfluencersTable = (props) => {
    const { t } = useTranslation('common');

    const classes = useStyles();
    const searchInputClasses = useSearchInputStyles();

    const [page, setPage] = useState(1);
    const [size, setSize] = useState(10);
    const [count, setCount] = useState(0);
    const [sortHelper, setSortHelper] = useState([1, 0, 0, 0, 0, 0]);
    const [influencers, setInfluencers] = useState([]);
    const [influencersToRender, setInfluencersToRender] = useState([]);
    const [sortEvent, setSortEvent] = useState(0);

    const {
        appStateSortHelper,
        appStatePageNumber,
        appStatePageSize,
        changePageNumber,
        changeSortHelper,
        changePageSize
    } = props;

    useEffect(() => {
        setInfluencers(props.periodInfluencers);
    }, [props.periodInfluencers]);

    // Effect for handling pagination
    useEffect(() => {
        setInfluencersToRender(influencers.slice((page - 1) * size, page * size));
        setCount(
            (influencers.length % size === 0)
                ? Math.trunc(influencers.length / size)
                : Math.trunc(influencers.length / size) + 1
        );
    }, [influencers, page, size, sortEvent]);

    // Effect for handling sorting
    useEffect(() => {
        let indexAsc = sortHelper.indexOf(1);
        let indexDesc = sortHelper.indexOf(-1);
        if (indexAsc !== -1) {
            const sortTypeAsc = (indexAsc, tableToWorkOn) => {
                switch (indexAsc) {
                    case 0:
                        tableToWorkOn.sort((a, b) => {
                            if (a.amount > b.amount) return -1;
                            if (a.amount < b.amount) return 1;
                            return 0;
                        });
                        break;
                    case 1:
                        tableToWorkOn.sort((a, b) => {
                            if (a.salesNumber > b.salesNumber) return -1;
                            if (a.salesNumber < b.salesNumber) return 1;
                            return 0;
                        });
                        break;
                    case 2:
                        tableToWorkOn.sort((a, b) => {
                            if (a.products.length > b.products.length) return -1;
                            if (a.products.length < b.products.length) return 1;
                            return 0;
                        });
                        break;
                    case 3:
                        tableToWorkOn.sort((a, b) => {
                            if (a.commission > b.commission) return -1;
                            if (a.commission < b.commission) return 1;
                            return 0;
                        });
                        break;
                    case 4:
                        tableToWorkOn.sort((a, b) => {
                            if (a.clicks > b.clicks) return -1;
                            if (a.clicks < b.clicks) return 1;
                            return 0;
                        });
                        break;
                    case 5:
                        tableToWorkOn.sort((a, b) => {
                            if (a.name > b.name) return 1;
                            if (a.name < b.name) return -1;
                            return 0;
                        });
                        break;
                    default:
                        break;
                }
            }
            sortTypeAsc(indexAsc, influencers);
        } else {
            const sortTypeDesc = (indexDesc, tableToWorkOn) => {
                switch (indexDesc) {
                    case 0:
                        tableToWorkOn.sort((a, b) => {
                            if (a.amount > b.amount) return 1;
                            if (a.amount < b.amount) return -1;
                            return 0;
                        });
                        break;
                    case 1:
                        tableToWorkOn.sort((a, b) => {
                            if (a.salesNumber > b.salesNumber) return 1;
                            if (a.salesNumber < b.salesNumber) return -1;
                            return 0;
                        });
                        break;
                    case 2:
                        tableToWorkOn.sort((a, b) => {
                            if (a.products.length > b.products.length) return 1;
                            if (a.products.length < b.products.length) return -1;
                            return 0;
                        });
                        break;
                    case 3:
                        tableToWorkOn.sort((a, b) => {
                            if (a.commission > b.commission) return 1;
                            if (a.commission < b.commission) return -1;
                            return 0;
                        });
                        break;
                    case 4:
                        tableToWorkOn.sort((a, b) => {
                            if (a.clicks > b.clicks) return 1;
                            if (a.clicks < b.clicks) return -1;
                            return 0;
                        });
                        break;
                    case 5:
                        tableToWorkOn.sort((a, b) => {
                            if (a.name > b.name) return -1;
                            if (a.name < b.name) return 1;
                            return 0;
                        });
                        break;
                    default:
                        break;
                }
            };
            sortTypeDesc(indexDesc, influencers);
        }
        setSortEvent((s) => s + 1);
    }, [sortHelper, influencers]);

    useEffect(() => {
        setSortHelper(appStateSortHelper);
    }, [appStateSortHelper]);

    useEffect(() => {
        setPage(appStatePageNumber);
    }, [appStatePageNumber]);

    useEffect(() => {
        setSize(appStatePageSize);
    }, [appStatePageSize]);

    const handlePageChange = (event, value) => {
        changePageNumber(value);
    };

    const handlePageSizeChange = (event) => {
        changePageSize(event.target.value);
        changePageNumber(1);
    };

    const openPopup = (influencer) => {
        props.openPopup(influencer);
    };

    const inputChange = (event) => {
        if (event.target.value === '') {
            setSortEvent(sortEvent + 1)
        } else {
            setInfluencersToRender(influencers.filter(element => element.name.toLowerCase().includes(event.target.value.toLowerCase())))
        }
    }

    return (
        <Grid container item justify='center' xs={12} className={classes.rowSpacing}>
            <Grid container item xs={12} style={{ width: '100%', overflowX: 'auto' }}>
                <Input classes={searchInputClasses} disableUnderline placeholder={t('TREND.VIEWS.STATISTICS.INFLUENCER_TABLE.SEARCH_INPUT_PLACEHOLDER')} onChange={inputChange} />
                <Grid container item direction='row-reverse' style={{ minWidth: '1050px', marginTop: '20px' }}>
                    <Grid
                        container
                        item
                        justify='center'
                        alignItems='center'
                        style={{ width: '13%' }}
                        onClick={() => {
                            (sortHelper[4] === 0) ? changeSortHelper([0, 0, 0, 0, 1, 0]) : changeSortHelper([0, 0, 0, 0, -sortHelper[4], 0]);
                        }}>
                        <Typography className={classes.tableHeader}>{t('TREND.VIEWS.STATISTICS.INFLUENCER_TABLE.CLICKS_HEADER')}</Typography>
                        {(sortHelper[4] === 1) ? <ExpandLessIcon className={classes.tableHeaderIcon} /> : ((sortHelper[4] === -1) ? <ExpandMoreIcon className={classes.tableHeaderIcon} /> : <SortIcon className={classes.tableHeaderIcon} />)}
                    </Grid>
                    <Grid
                        container
                        item
                        justify='center'
                        alignItems='center'
                        style={{ width: '13%' }}
                        onClick={() => {
                            (sortHelper[3] === 0) ? changeSortHelper([0, 0, 0, 1, 0, 0]) : changeSortHelper([0, 0, 0, -sortHelper[3], 0, 0]);
                        }}>
                        <Typography className={classes.tableHeader}>{t('TREND.VIEWS.STATISTICS.INFLUENCER_TABLE.COMMISSIONS_HEADER')}</Typography>
                        {(sortHelper[3] === 1) ? <ExpandLessIcon className={classes.tableHeaderIcon} /> : ((sortHelper[3] === -1) ? <ExpandMoreIcon className={classes.tableHeaderIcon} /> : <SortIcon className={classes.tableHeaderIcon} />)}
                    </Grid>
                    <Grid
                        container
                        item
                        justify='center'
                        alignItems='center'
                        style={{ width: '13%' }}
                        onClick={() => {
                            (sortHelper[2] === 0) ? changeSortHelper([0, 0, 1, 0, 0, 0]) : changeSortHelper([0, 0, -sortHelper[2], 0, 0, 0]);
                        }}>
                        <Typography className={classes.tableHeader}>{t('TREND.VIEWS.STATISTICS.INFLUENCER_TABLE.PRODUCTS_HEADER')}</Typography>
                        {(sortHelper[2] === 1) ? <ExpandLessIcon className={classes.tableHeaderIcon} /> : ((sortHelper[2] === -1) ? <ExpandMoreIcon className={classes.tableHeaderIcon} /> : <SortIcon className={classes.tableHeaderIcon} />)}
                    </Grid>
                    <Grid
                        container
                        item
                        justify='center'
                        alignItems='center'
                        style={{ width: '13%' }}
                        onClick={() => {
                            (sortHelper[1] === 0) ? changeSortHelper([0, 1, 0, 0, 0, 0]) : changeSortHelper([0, -sortHelper[1], 0, 0, 0, 0]);
                        }}>
                        <Typography className={classes.tableHeader}>{t('TREND.VIEWS.STATISTICS.INFLUENCER_TABLE.SALES_HEADER')}</Typography>
                        {(sortHelper[1] === 1) ? <ExpandLessIcon className={classes.tableHeaderIcon} /> : ((sortHelper[1] === -1) ? <ExpandMoreIcon className={classes.tableHeaderIcon} /> : <SortIcon className={classes.tableHeaderIcon} />)}
                    </Grid>
                    <Grid
                        container
                        item justify='center'
                        alignItems='center'
                        style={{ width: '13%' }}
                        onClick={() => {
                            (sortHelper[0] === 0) ? changeSortHelper([1, 0, 0, 0, 0, 0]) : changeSortHelper([-sortHelper[0], 0, 0, 0, 0, 0]);
                        }}>
                        <Typography className={classes.tableHeader}>{t('TREND.VIEWS.STATISTICS.INFLUENCER_TABLE.TURNOVER_HEADER')}</Typography>
                        {(sortHelper[0] === 1) ? <ExpandLessIcon className={classes.tableHeaderIcon} /> : ((sortHelper[0] === -1) ? <ExpandMoreIcon className={classes.tableHeaderIcon} /> : <SortIcon className={classes.tableHeaderIcon} />)}
                    </Grid>
                    <Grid
                        container
                        item justify='center'
                        alignItems='center'
                        style={{ width: '33%' }}
                        onClick={() => {
                            (sortHelper[5] === 0) ? changeSortHelper([0, 0, 0, 0, 0, 1]) : changeSortHelper([0, 0, 0, 0, 0, -sortHelper[5]]);
                        }}>
                        <Typography className={classes.tableHeader}>{t('TREND.VIEWS.STATISTICS.INFLUENCER_TABLE.NAME_HEADER')}</Typography>
                        {(sortHelper[5] === 1) ? <ExpandLessIcon className={classes.tableHeaderIcon} /> : ((sortHelper[5] === -1) ? <ExpandMoreIcon className={classes.tableHeaderIcon} /> : <SortIcon className={classes.tableHeaderIcon} />)}
                    </Grid>
                </Grid>
                {influencersToRender.map(influencer => (
                    <Grid container
                        item
                        direction='row'
                        justify='center'
                        className={classes.influencerBoxContainer}
                        key={influencer.key}>
                        <Box className={classes.influencerBox}>
                            <Grid container item direction='row' style={{ width: '100%' }}>
                                <Grid container item justify='flex-start' alignItems='center' style={{ width: '15%' }}>
                                    <img src={influencer.banner} alt='' className={classes.influencerImage} />
                                </Grid>
                                <Grid container item justify='center' alignItems='center' style={{ width: '20%' }}>
                                    <Typography className={classes.influencerName} onClick={() => openPopup(influencer)}>
                                        {influencer.name}
                                    </Typography>
                                </Grid>
                                <Grid container item justify='center' alignItems='center' style={{ width: '13%' }}>
                                    <Typography className={classes.tableRowContent}>{Math.round(influencer.amount * 100) / 100} €</Typography>
                                </Grid>
                                <Grid container item justify='center' alignItems='center' style={{ width: '13%' }}>
                                    <Typography className={classes.tableRowContent}>{influencer.salesNumber}</Typography>
                                </Grid>
                                <Grid container item justify='center' alignItems='center' style={{ width: '13%' }}>
                                    <Typography className={classes.tableRowContent}>{influencer.products.length}</Typography>
                                </Grid>
                                <Grid container item justify='center' alignItems='center' style={{ width: '13%' }}>
                                    <Typography className={classes.tableRowContent}>{Math.round(influencer.commission * 100) / 100} €</Typography>
                                </Grid>
                                <Grid container item justify='center' alignItems='center' style={{ width: '13%' }}>
                                    {
                                        (influencer.clicks !== undefined)
                                            ? <Typography className={classes.tableRowContent}>{influencer.clicks}</Typography>
                                            : <CircularProgress color='secondary' style={{ width: '20px', height: '20px' }} />
                                    }

                                </Grid>
                            </Grid>
                        </Box>
                    </Grid>
                ))}
                <Grid container item direction='row-reverse' className={classes.paginationRow}>
                    <Grid container alignItems='center' justify='flex-end' style={{ display: 'flex', width: '95%' }}>
                        <Select
                            value={size}
                            onChange={handlePageSizeChange}
                            input={<BootstrapInput />}
                            IconComponent={() => (
                                <SVGIcon name='miniArrow' />
                            )}
                            classes={{ root: classes.selectClass }}
                        >
                            <MenuItem value={5}>5</MenuItem>
                            <MenuItem value={10}>10</MenuItem>
                            <MenuItem value={20}>20</MenuItem>
                        </Select>
                        <Typography className={classes.tableHeader} style={{ marginRight: '10px', marginLeft: '10px' }}>
                            {t('TREND.VIEWS.STATISTICS.PAGINATION_PLACEHOLDER') + ' '} {influencers.length}
                        </Typography>
                        <Pagination
                            count={count}
                            page={page}
                            onChange={handlePageChange}
                            renderItem={(item) => (
                                <PaginationItem classes={{ root: classes.paginationClass, selected: classes.selectedPaginationItem }} {...item} />
                            )} />
                    </Grid>
                </Grid>
            </Grid>
        </Grid>
    );
};

const mapStateToProps = (state) => {
    const appStateSortHelper = state.appState.trendStatisticsState.influencerTableSortHelper;
    const appStatePageNumber = state.appState.trendStatisticsState.influencerTablePageNumber;
    const appStatePageSize = state.appState.trendStatisticsState.influencerTablePageSize;
    return {
        appStateSortHelper,
        appStatePageNumber,
        appStatePageSize
    };
};

const mapDispatchToProps = (dispatch) => {
    return {
        changeSortHelper: (sortHelper) =>
            dispatch({ type: 'UPDATE_TREND_STATS_INFLUENCER_TABLE_SORT_HELPER', sortHelper }),
        changePageNumber: (pageNumber) =>
            dispatch({ type: 'UPDATE_TREND_STATS_INFLUENCER_TABLE_PAGE_NUMBER', pageNumber }),
        changePageSize: (pageSize) =>
            dispatch({ type: 'UPDATE_TREND_STATS_INFLUENCER_TABLE_PAGE_SIZE', pageSize })
    }
};

export default connect(mapStateToProps, mapDispatchToProps)(InfluencersTable);