import { userManager } from "../../components/managers";
export const Register = (userType, formValues, language) => {
  return (dispatch) => {
    dispatch({ type: 'REGISTRATION_REQUEST' });
    userManager
      .createUser(userType, formValues, language)
      .then((res) => {
        dispatch({ type: "REGISTRATION_USER_COMPLETE", res });
        /*if (userType === "influencer") {
          dispatch({ type: "ADD_NEW_INFLUENCER_TO_STATE", payload: { formValues } });
        }*/
      })
      .catch((error) => {
        dispatch({ type: "REGISTRATION_USER_ERROR", error });
      });
  };
};
