import React from "react";

import Commission from "./commission";
import CommissionPaye from "./commissions_payee";
import Ventes from "./ventes";
import NombreInfluenceurs from "./nombreInfluenceurs";
import Dashboard from "./dashboard";
import MiniArrow from "./select_arrow";
import Spinner from "./spinner";

const SVGIcon = props => {
    switch (props.name) {
        case "commission":
            return <Commission {...props} />;
        case "commissionPaye":
            return <CommissionPaye {...props} />;
        case "ventes":
            return <Ventes {...props} />;
        case "nombreInfluenceurs":
            return <NombreInfluenceurs {...props} />;
        case "dashborad":
            return <Dashboard {...props} />;
        case "miniArrow":
            return <MiniArrow {...props} />;
        case "spinner":
            return <Spinner {...props} />
        default:
            return;
    }
};

export default SVGIcon;
