import React from "react";
import { Paper, Typography } from "@material-ui/core";
import { makeStyles } from "@material-ui/styles";

const useStyles = makeStyles(theme => ({
  infoBoxContainer: {
    height: "100%",
    width: "100%",
    padding: theme.spacing(2),
  },
}));

const InfoBox = props => {
  const { children, title } = props;
  const classes = useStyles();
  
  return (
    <Paper className={classes.infoBoxContainer}>
      <Typography variant='h5'>{title}</Typography>
      {children}
    </Paper>
  );
};

InfoBox.propTypes = {};

export default InfoBox;
