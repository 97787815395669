import React, { useState, useEffect } from "react";
import { makeStyles } from "@material-ui/styles";
import { Grid, Typography, Box, Select, MenuItem } from "@material-ui/core";
import { Pagination, PaginationItem } from '@material-ui/lab';
import SVGIcon from '../../assets/SVGIcons/index';
import BootstrapInput from '../../assets/customComponents/BootstrapInput';
import ExpandLessIcon from '@material-ui/icons/ExpandLess';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import SortIcon from '@material-ui/icons/Sort';
import { useTranslation } from "react-i18next";
import { connect } from "react-redux";

const useStyles = makeStyles(theme => ({
    rowSpacing: {
        marginTop: '10px',
        marginBottom: '10px'
    },
    influencerBoxContainer: {
        marginTop: '10px',
        marginBottom: '10px',
        minWidth: '1050px'
    },
    influencerBox: {
        display: 'flex',
        width: '100%',
        backgroundColor: 'white',
        height: '160px',
        borderRadius: '27px',
        boxShadow: '0 7px 30px 0 rgba(88, 165, 211, 0.16)',
    },
    influencerImage: {
        width: '160px',
        height: '160px',
        objectFit: 'contain',
        marginLeft: '40px',
        '&:hover': {
            cursor: 'pointer'
        }
    },
    tableHeader: {
        fontFamily: 'Poppins',
        fontSize: '14px',
        textAlign: 'center',
        '&:hover': {
            cursor: 'pointer',
        }
    },
    tableRowContent: {
        fontFamily: 'Poppins',
    },
    influencerName: {
        fontFamily: 'Poppins',
        textDecoration: 'underline',
        '&:hover': {
            cursor: 'pointer',
        }
    },
    paginationClass: {
        backgroundColor: '#ffffff !important',
        boxShadow: '0 7px 30px 0 rgba(88, 165, 211, 0.16)',
        borderRadius: '8px',
        fontFamily: 'Poppins',
        height: '40px',
        width: '40px',
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center'
    },
    selectedPaginationItem: {
        backgroundColor: '#3eadef !important',
        boxShadow: '0 7px 30px 0 rgba(88, 165, 211, 0.16)',
        borderRadius: '8px',
        fontFamily: 'Poppins',
        height: '40px',
        width: '40px',
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        color: '#ffffff'
    },
}));

const ProductsTable = (props) => {
    const { t } = useTranslation('common');

    const classes = useStyles();

    const [products, setProducts] = useState([]);
    const [productsToRender, setProductsToRender] = useState([]);

    const [page, setPage] = useState(1);
    const [size, setSize] = useState(10);
    const [count, setCount] = useState(0);
    const [sortEvent, setSortEvent] = useState(0);
    const [sortHelper, setSortHelper] = useState([1, 0, 0, 0, 0]);

    const {
        appStateSortHelper,
        appStatePageNumber,
        appStatePageSize,
        changePageNumber,
        changeSortHelper,
        changePageSize
    } = props;

    useEffect(() => {
        setProducts(props.periodProducts);
    }, [props.periodProducts]);

    useEffect(() => {
        setSortHelper(appStateSortHelper);
    }, [appStateSortHelper]);

    useEffect(() => {
        setPage(appStatePageNumber);
    }, [appStatePageNumber]);

    useEffect(() => {
        setSize(appStatePageSize);
    }, [appStatePageSize]);

    useEffect(() => {
        setProductsToRender(products.slice((page - 1) * size, page * size));
        setCount(
            (products.length % size === 0)
                ? Math.trunc(products.length / size)
                : Math.trunc(products.length / size) + 1
        );
    }, [products, page, size, sortEvent]);

    // Effect for handling sorting
    useEffect(() => {
        let indexAsc = sortHelper.indexOf(1);
        let indexDesc = sortHelper.indexOf(-1);
        if (indexAsc !== -1) {
            const sortTypeAsc = (indexAsc, tableToWorkOn) => {
                switch (indexAsc) {
                    case 0:
                        tableToWorkOn.sort((a, b) => {
                            if (a.amount > b.amount) return -1;
                            if (a.amount < b.amount) return 1;
                            return 0;
                        });
                        break;
                    case 1:
                        tableToWorkOn.sort((a, b) => {
                            if (a.purchaseNumber > b.purchaseNumber) return -1;
                            if (a.purchaseNumber < b.purchaseNumber) return 1;
                            return 0;
                        });
                        break;
                    case 2:
                        tableToWorkOn.sort((a, b) => {
                            if (a.productPurchasers.length > b.productPurchasers.length) return -1;
                            if (a.productPurchasers.length < b.productPurchasers.length) return 1;
                            return 0;
                        });
                        break;
                    case 3:
                        tableToWorkOn.sort((a, b) => {
                            if (a.commissions > b.commissions) return -1;
                            if (a.commissions < b.commissions) return 1;
                            return 0;
                        });
                        break;
                    default:
                        break;
                }
            }
            sortTypeAsc(indexAsc, products);
        } else {
            const sortTypeDesc = (indexDesc, tableToWorkOn) => {
                switch (indexDesc) {
                    case 0:
                        tableToWorkOn.sort((a, b) => {
                            if (a.amount > b.amount) return 1;
                            if (a.amount < b.amount) return -1;
                            return 0;
                        });
                        break;
                    case 1:
                        tableToWorkOn.sort((a, b) => {
                            if (a.purchaseNumber > b.purchaseNumber) return 1;
                            if (a.purchaseNumber < b.purchaseNumber) return -1;
                            return 0;
                        });
                        break;
                    case 2:
                        tableToWorkOn.sort((a, b) => {
                            if (a.productPurchasers.length > b.productPurchasers.length) return 1;
                            if (a.productPurchasers.length < b.productPurchasers.length) return -1;
                            return 0;
                        });
                        break;
                    case 3:
                        tableToWorkOn.sort((a, b) => {
                            if (a.commissions > b.commissions) return 1;
                            if (a.commissions < b.commissions) return -1;
                            return 0;
                        });
                        break;
                    default:
                        break;
                }
            };
            sortTypeDesc(indexDesc, products);
        }
        setSortEvent((s) => s + 1);
    }, [sortHelper, products]);

    const handlePageChange = (event, value) => {
        changePageNumber(value);
    };

    const handlePageSizeChange = (event) => {
        changePageSize(event.target.value);
        changePageNumber(1);
    };

    const openPopup = (product) => {
        props.openPopup(product);
    }

    return (
        <Grid container item justify='center' xs={12} className={classes.rowSpacing}>
            <Grid container item xs={12} style={{ width: '100%', overflowX: 'auto' }}>
                <Grid container item direction='row-reverse' style={{ minWidth: '1050px' }}>
                    <Grid
                        container
                        item
                        justify='center'
                        alignItems='center'
                        style={{ width: '13%' }}
                        onClick={() => {
                            (sortHelper[3] === 0) ? changeSortHelper([0, 0, 0, 1, 0]) : changeSortHelper([0, 0, 0, -sortHelper[3], 0]);
                        }}>
                        <Typography className={classes.tableHeader}>{t('TREND.VIEWS.STATISTICS.PRODUCT_TABLE.COMMISSIONS_HEADER')}</Typography>
                        {(sortHelper[3] === 1) ? <ExpandLessIcon className={classes.tableHeaderIcon} /> : ((sortHelper[3] === -1) ? <ExpandMoreIcon className={classes.tableHeaderIcon} /> : <SortIcon className={classes.tableHeaderIcon} />)}
                    </Grid>
                    <Grid
                        container
                        item
                        justify='center'
                        alignItems='center'
                        style={{ width: '13%' }}
                        onClick={() => {
                            (sortHelper[2] === 0) ? changeSortHelper([0, 0, 1, 0, 0]) : changeSortHelper([0, 0, -sortHelper[2], 0, 0]);
                        }}>
                        <Typography className={classes.tableHeader}>{t('TREND.VIEWS.STATISTICS.PRODUCT_TABLE.INFLUENCERS_HEADER')}</Typography>
                        {(sortHelper[2] === 1) ? <ExpandLessIcon className={classes.tableHeaderIcon} /> : ((sortHelper[2] === -1) ? <ExpandMoreIcon className={classes.tableHeaderIcon} /> : <SortIcon className={classes.tableHeaderIcon} />)}
                    </Grid>
                    <Grid
                        container
                        item
                        justify='center'
                        alignItems='center'
                        style={{ width: '13%' }}
                        onClick={() => {
                            (sortHelper[1] === 0) ? changeSortHelper([0, 1, 0, 0, 0]) : changeSortHelper([0, -sortHelper[1], 0, 0, 0]);
                        }}>
                        <Typography className={classes.tableHeader}>{t('TREND.VIEWS.STATISTICS.PRODUCT_TABLE.SALES_HEADER')}</Typography>
                        {(sortHelper[1] === 1) ? <ExpandLessIcon className={classes.tableHeaderIcon} /> : ((sortHelper[1] === -1) ? <ExpandMoreIcon className={classes.tableHeaderIcon} /> : <SortIcon className={classes.tableHeaderIcon} />)}
                    </Grid>
                    <Grid
                        container
                        item
                        justify='center'
                        alignItems='center'
                        style={{ width: '13%' }}
                        onClick={() => {
                            (sortHelper[0] === 0) ? changeSortHelper([1, 0, 0, 0, 0]) : changeSortHelper([-sortHelper[0], 0, 0, 0, 0]);
                        }}>
                        <Typography className={classes.tableHeader}>{t('TREND.VIEWS.STATISTICS.PRODUCT_TABLE.TURNOVER_HEADER')}</Typography>
                        {(sortHelper[0] === 1) ? <ExpandLessIcon className={classes.tableHeaderIcon} /> : ((sortHelper[0] === -1) ? <ExpandMoreIcon className={classes.tableHeaderIcon} /> : <SortIcon className={classes.tableHeaderIcon} />)}
                    </Grid>
                </Grid>
                {
                    productsToRender.map(product => (
                        <Grid container
                            item
                            direction='row'
                            justify='center'
                            className={classes.influencerBoxContainer}
                            key={product.articleId}>
                            <Box className={classes.influencerBox}>
                                <Grid container item direction='row' style={{ width: '100%' }}>
                                    <Grid container item justify='flex-start' alignItems='center' style={{ width: '48%' }}>
                                        <img
                                            src={(product.articleImgUrl !== null) ? product.articleImgUrl : ''}
                                            alt=''
                                            onClick={() => openPopup(product)}
                                            className={classes.influencerImage} />
                                    </Grid>
                                    <Grid container item justify='center' alignItems='center' style={{ width: '13%' }}>
                                        <Typography className={classes.tableRowContent}>{Math.round(product.amount * 100) / 100} €</Typography>
                                    </Grid>
                                    <Grid container item justify='center' alignItems='center' style={{ width: '13%' }}>
                                        <Typography className={classes.tableRowContent}>{product.salesNumber}</Typography>
                                    </Grid>
                                    <Grid container item justify='center' alignItems='center' style={{ width: '13%' }}>
                                        <Typography className={classes.tableRowContent}>{product.productPurchasers.length}</Typography>
                                    </Grid>
                                    <Grid container item justify='center' alignItems='center' style={{ width: '13%' }}>
                                        <Typography className={classes.tableRowContent}>{Math.round(product.commissions * 100) / 100} €</Typography>
                                    </Grid>
                                </Grid>
                            </Box>
                        </Grid>
                    ))
                }
                <Grid container item direction='row-reverse' className={classes.paginationRow}>
                    <Grid container alignItems='center' justify='flex-end' style={{ display: 'flex', width: '95%' }}>
                        <Select
                            value={size}
                            onChange={handlePageSizeChange}
                            input={<BootstrapInput />}
                            IconComponent={() => (
                                <SVGIcon name='miniArrow' />
                            )}
                            classes={{ root: classes.selectClass }}
                        >
                            <MenuItem value={5}>5</MenuItem>
                            <MenuItem value={10}>10</MenuItem>
                            <MenuItem value={20}>20</MenuItem>
                        </Select>
                        <Typography className={classes.tableHeader} style={{ marginRight: '10px', marginLeft: '10px' }}>
                            {t('TREND.VIEWS.STATISTICS.PAGINATION_PLACEHOLDER') + ' '} {products.length}
                        </Typography>
                        <Pagination
                            count={count}
                            page={page}
                            onChange={handlePageChange}
                            renderItem={(item) => (
                                <PaginationItem classes={{ root: classes.paginationClass, selected: classes.selectedPaginationItem }} {...item} />
                            )} />
                    </Grid>
                </Grid>
            </Grid>
        </Grid>
    );
};

const mapStateToProps = (state) => {
    const appStateSortHelper = state.appState.trendStatisticsState.productTableSortHelper;
    const appStatePageNumber = state.appState.trendStatisticsState.productTablePageNumber;
    const appStatePageSize = state.appState.trendStatisticsState.productTablePageSize;
    return {
        appStateSortHelper,
        appStatePageNumber,
        appStatePageSize
    };
};

const mapDispatchToProps = (dispatch) => {
    return {
        changeSortHelper: (sortHelper) =>
            dispatch({ type: 'UPDATE_TREND_STATS_PRODUCT_TABLE_SORT_HELPER', sortHelper }),
        changePageNumber: (pageNumber) =>
            dispatch({ type: 'UPDATE_TREND_STATS_PRODUCT_TABLE_PAGE_NUMBER', pageNumber }),
        changePageSize: (pageSize) =>
            dispatch({ type: 'UPDATE_TREND_STATS_PRODUCT_TABLE_PAGE_SIZE', pageSize })
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(ProductsTable);