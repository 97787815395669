import React, { useState, useEffect } from "react";
import { connect } from "react-redux";
import { makeStyles, Grid, Typography } from "@material-ui/core";
import TrendLoading from "../../ui/trendLoading/TrendLoading";
import { useTranslation } from "react-i18next";

const useStyles = makeStyles(() => ({
  influenceursContent: {
    flexGrow: 1,
    display: "flex",
    flexDirection: "column",
    backgroundColor: "#eff9fe",
  },
  tabTitle: {
    fontFamily: "Poppins",
    fontSize: "60px",
    fontWeight: "bold",
    marginTop: "30px",
    marginBottom: "40px",
  },
  brandBox: {
    backgroundColor: "white",
    width: "340px",
    height: "152.5px",
    borderRadius: "25px",
    margin: "10px",
    "&:hover": {
      cursor: "pointer",
    },
    "&:before": {
      display: "block",
      paddingTop: "50%",
    },
  },
}));

const Brands = (props) => {
  const { t } = useTranslation("common");

  const classes = useStyles();
  const [brands, setBrands] = useState([]);
  const [loading, setLoading] = useState(true);
  const [brandsToRender, setBrandsToRender] = useState([]);
  const [filterValue, setFilterValue] = useState("");
  const { brandsInfos } = props;
  useEffect(() => {
    setFilterValue(props.filterValue);
  }, [props.filterValue]);

  useEffect(() => {
    setBrands(brandsInfos);
  }, [brandsInfos]);

  useEffect(() => {
    let filteredBrands =
      brands.length > 0
        ? brands.filter((item) => {
            if (item.name && item.displayName)
              return (
                item.name.toLowerCase().includes(filterValue.toLowerCase()) ||
                item.displayName
                  .toLowerCase()
                  .includes(filterValue.toLowerCase())
              );
            return false;
          })
        : [];
    setBrandsToRender(filteredBrands);
    setLoading(false);
  }, [brands, filterValue]);
  const redirectToBrand = (brand) => {
    window.location =
      window.location.origin + "/trend/" + brand.name + "/dashboard";
  };

  return (
    <Grid container item className={classes.influenceursContent}>
      {loading ? (
        <TrendLoading />
      ) : (
        <Grid container item justify="center" xs={12} direction="row">
          <Grid container item xs={11} direction="row">
            <Typography className={classes.tabTitle}>
              {t("BRAND_AGENT.VIEWS.MY_BRANDS.MY_BRANDS_TITLE")}
            </Typography>
          </Grid>
          <Grid
            item
            container
            direction="row"
            justify="center"
            wrap="wrap"
            xs={11}
          >
            {brandsToRender.map((brand) => {
              if (brand.offerId) {
                return (
                  <img
                    key={brand.offerId}
                    src={brand.src_premium || brand.pic}
                    alt={brand.name}
                    width={340}
                    className={classes.brandBox}
                    style={{ objectFit: "contain" }}
                    onClick={() => {
                      redirectToBrand(brand);
                    }}
                  />
                );
              } else {
                return <></>;
              }
            })}
          </Grid>
        </Grid>
      )}
    </Grid>
  );
};
const mapStateToProps = (state) => {
  const brandsArray = state.brandsAgentState.brandsArray;
  return {
    brandsArray,
  };
};

export default connect(mapStateToProps, null)(Brands);
