import { Grid, List, ListItem, ListItemIcon, ListItemText, makeStyles, Typography } from '@material-ui/core';
import React from 'react';
import FiberManualRecordIcon from '@material-ui/icons/FiberManualRecord';

const useStyles = makeStyles((theme) => ({
    dialogContent: {
    },
    warningTitle: {
        fontFamily: 'Poppins',
        margin: '10px',
        fontSize: '20px',
        textAlign: 'center'
    },
    itemList: {
        fontFamily: 'Poppins',
    }
}))

const WarningDialog = (props) => {

    const classes = useStyles();

    const unvalidatedFields = props.unvalidatedFields;
    const title = props.titleWarning;

    return (
        <Grid container item direction='column' alignItems='center' xs={12} className={classes.dialogContent}>
            <Grid container item direction='row' justify='center' alignItems='center' xs={12}>
                <Typography className={classes.warningTitle}>{title}</Typography>
            </Grid>
            <Grid container item direction='row' xs={10}>
                <List>
                    {unvalidatedFields.map(item => (
                        <ListItem key={item}>
                            <ListItemIcon>
                                <FiberManualRecordIcon fontSize='small' />
                            </ListItemIcon>
                            <ListItemText>
                                <Typography className={classes.itemList}>{item}</Typography>
                            </ListItemText>
                        </ListItem>
                    ))}
                </List>
            </Grid>
        </Grid>
    )
};

export default WarningDialog;