import { Button, Dialog } from '@material-ui/core';
import Cropper from 'react-easy-crop'
import Slider from '@material-ui/core/Slider';
import { makeStyles } from '@material-ui/core/styles';
import React, { useState, useCallback, useEffect } from 'react';
import { getCroppedImg } from '../utils/canvasUtils'

const useStyles = makeStyles(() => ({
    dialogPaper: {
        minHeight: '60vh',
        maxHeight: '60vh',
        minWidth: '80vw',
        maxWidth: '80vw',
        overflowY: 'hidden'
    },
    cropperContainer: {
        position: 'absolute',
        top: 0,
        left: 0,
        right: 0,
        bottom: '80px',
    },
    sliderContainer: {
        display: 'flex',
        position: 'absolute',
        bottom: 0,
        width: '100%',
        height: '80px',
        flexDirection: 'row',
        alignItems: 'center',
        justifyContent: 'center',
    },
    saveButton: {
        margin: '5px',
        color: '#ffffff',
        paddingLeft: '20px',
        paddingRight: '20px',
        borderRadius: '35px',
        backgroundColor: '#3eadef',
        fontSize: '14px',
        textTransform: 'none',
        fontFamily: 'Poppins',
        boxShadow: '0 7px 30px 0 rgba(88, 165, 211, 0.16)',
        '&:hover': {
            backgroundColor: '#3eadef',
        },
    },
    cancelButton: {
        margin: '5px',
        color: '#ffffff',
        paddingLeft: '20px',
        paddingRight: '20px',
        borderRadius: '35px',
        backgroundColor: '#717171',
        fontSize: '14px',
        textTransform: 'none',
        fontFamily: 'Poppins',
        boxShadow: '0 7px 30px 0 rgba(88, 165, 211, 0.16)',
        '&:hover': {
            backgroundColor: '#717171',
        },
    }
}));


const CropperDialog = (props) => {

    const classes = useStyles();

    const open = props.open;
    const imageToCrop = props.imageToCrop;
    const widthAspect = props.widthAspect;
    const heightAspect = props.heightAspect;


    const [crop, setCrop] = useState({ x: 0, y: 0 });
    const [zoom, setZoom] = useState(1);
    const [croppedAreaPixels, setCroppedAreaPixels] = useState(null);
    const [croppedImage, setCroppedImage] = useState(null);

    const saveImage = props.save;
    

    const handleCancel = () => {
        props.cancel();
    }

    const handleSave = useCallback((finalImage) => {
        saveImage(finalImage);
    }, [saveImage])

    const onCropComplete = useCallback((croppedArea, croppedAreaPixels) => {
        setCroppedAreaPixels(croppedAreaPixels);
    }, []);

    const showCroppedImage = useCallback(async () => {
        try {
            const croppedImage = await getCroppedImg(
                imageToCrop,
                croppedAreaPixels
            )
            setCroppedImage(croppedImage);
        } catch (e) {
            console.error(e)
        }
    }, [croppedAreaPixels, imageToCrop]);

    useEffect(() => {
        if (croppedImage) {
            handleSave(croppedImage);
        }
    }, [croppedImage, handleSave])

    return (
        <Dialog open={open} classes={{ paper: classes.dialogPaper }}>
            <div className={classes.cropperContainer}>
                <Cropper
                    image={imageToCrop}
                    crop={crop}
                    zoom={zoom}
                    aspect={widthAspect / heightAspect}
                    onCropChange={setCrop}
                    onCropComplete={onCropComplete}
                    onZoomChange={setZoom}
                />
            </div>
            <div className={classes.sliderContainer}>
                <Slider
                    style={{ width: '50%', margin: 10 }}
                    value={zoom}
                    min={1}
                    max={3}
                    step={0.1}
                    aria-labelledby="Zoom"
                    onChange={(e, zoom) => setZoom(zoom)}
                />
                <Button className={classes.cancelButton} onClick={handleCancel}>Annuler</Button>
                <Button className={classes.saveButton} onClick={showCroppedImage}>Enregistrer</Button>
            </div>
        </Dialog>
    )
};

export default CropperDialog;