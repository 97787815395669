import React from "react";
import PropTypes from "prop-types";
import {
  Grid,
  makeStyles,
} from "@material-ui/core";
// Component Scope
import { Panel } from "..";
// UI Scope
import { LeadForm } from "../..";
import background from "../../../assets/images/IRRESISTIBLE_GRIS.jpg";

const useStyles = makeStyles((theme) => ({
  leadLogo: {
    width: "100%",
    "& img": {
      maxWidth: "100%",
      maxHeight: "100%",
    },
  },
  background: {
    background: `url("${background}") top center/cover`,
  },
}));

const VideoLeadPanel = (props) => {
  const {
    // videoProps,
    logo,
    onLeadFormSubmit,
  } = props;
  const classes = useStyles();

  const content = () => (
    <>
      <Grid
        item
        xs={12}
        sm={12}
        md={8}
        lg={9}
        xl={10}
        container
        className={classes.background}
      >
        {/* <Video {...videoProps} /> */}
      </Grid>
      <Grid
        item
        xs={12}
        sm={12}
        md={4}
        lg={3}
        xl={2}
        container
        direction='column'
        justify='center'
        alignItems='center'
      >
        <Grid
          className={classes.leadLogo}
          item
          container
          justify='center'
          alignItems='center'
        >
          <img src={logo} alt='Brand logo' />
        </Grid>
        <Grid item container alignItems='center'>
          <LeadForm onSubmit={onLeadFormSubmit} />
        </Grid>
      </Grid>
    </>
  );
  return <Panel>{content()}</Panel>;
};

VideoLeadPanel.propTypes = {
  videoProps: PropTypes.object,
  logo: PropTypes.string,
};

export default VideoLeadPanel;
