import React from "react";
import PropTypes from "prop-types";
import { makeStyles } from "@material-ui/styles";
import { Button as MuiButton } from "@material-ui/core";

const useStyles = makeStyles(theme => ({
  root: {
    boxShadow: "none",
  },
  contained: {
    boxShadow: "none",
    "&:hover": {
      boxShadow: "none",
    },
  },
}));

const Button = props => {
  const classes = useStyles();
  return (
    <MuiButton
      classes={{
        root: classes.root,
        contained: classes.contained,
      }}
      color='primary'
      variant='contained'
      disableFocusRipple
      disableRipple
      {...props}
    />
  );
};

Button.propTypes = {
  color: PropTypes.string,
  variant: PropTypes.string,
  fullwidth: PropTypes.bool,
  disableFocusRipple: PropTypes.bool,
  disableRipple: PropTypes.bool,
};

export default Button;
