import { Grid, makeStyles } from '@material-ui/core';
import React from 'react';
import { useHistory } from 'react-router-dom';

const useStyles = makeStyles((theme) => ({
    header: {
        backgroundColor: '#fff',
        boxShadow: '0px 1px 6px 0 rgb(179 179 179 / 36%)',
        height: 80
    },
    logo: {
        width: 120,
        marginBottom: 10,
        cursor: 'pointer'
    }
}));

const HeaderBar = (props) => {

    const classes = useStyles();
    const history = useHistory();

    const goToHome = () => {
        history.push('/home');
    }

    return (
        <Grid container item xs={12} direction='row' justify='center' alignItems='center' className={classes.header}>
            <img 
            src={'https://firebasestorage.googleapis.com/v0/b/shopmyinfluens.appspot.com/o/shopmyinfluens%2FApp%20Data%2Flogo_shopmyinfluence-01.png?alt=media'} 
            alt={'Shop My Influence'}
            className={classes.logo}
            onClick={goToHome}
            />
        </Grid>
    )
};

export default HeaderBar;