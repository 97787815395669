import { Grid, makeStyles, Typography, CircularProgress, Backdrop } from '@material-ui/core';
import React, { useEffect, useState } from 'react';
import BrandTable from '../../template/components/BrandTables/BrandsTable';
import BrandToValidateTable from '../../template/components/BrandTables/BrandToValidateTable';
import BrandSuggestedTable from '../../template/components/BrandTables/BrandSuggestedTable';
import PrivateCampaign from '../../template/components/BrandTables/PrivateCampaign';
import EditBrandModal from '../../template/components/EditBrandModal';
import AssociateInfluencersToPrivateBrand from '../../template/components/AssociateInfluencersToPrivateBrand';
import { connect } from 'react-redux';
import { useTranslation } from "react-i18next";
import { removeBrandById } from '../../../../store/actions/brandsActions';


const useStyles = makeStyles((theme) => ({
    brandsContent: {
        flex: '1 1 auto',
        // flexGrow: 1,
        // display: 'flex',
        // flexDirection: 'column',
        backgroundColor: "#eff9fe",
    },
    brandSelectorBox: {
        width: '250px',
        padding: '20px',
        backgroundColor: '#ffffff',
        margin: '15px',
        boxShadow: '0 7px 30px 0 rgba(88, 165, 211, 0.16)',
        borderRadius: '35px',
        cursor: 'pointer'
    },
    brandSelectorText: {
        fontFamily: 'Poppins',
        fontWeight: 'bold'
    },
    activeBrandCategoryBox: {
        width: '250px',
        padding: '20px',
        backgroundColor: '#3eadef',
        margin: '15px',
        boxShadow: '0 7px 30px 0 rgba(88, 165, 211, 0.16)',
        borderRadius: '35px',
        cursor: 'pointer'
    },
    activeBrandSelectorText: {
        color: '#fff',
        fontFamily: 'Poppins',
        fontWeight: 'bold'
    },
    overlay: {
        zIndex: theme.zIndex.drawer + 1
    },
}));

const Brands = (props) => {

    const { t } = useTranslation('common');
    const classes = useStyles();
    const [selectedCategory, setSelectedCategory] = useState('brands');
    const [editMode, setEditMode] = useState(false);
    const [brandToEdit, setBrandToEdit] = useState(null);
    const [brandToValidate, setBrandToValidate] = useState(null);
    const [overlayState, setOverlayState] = useState(false);
    const [associateMode, setAssociateMode] = useState(false);
    const [brandToAssociate, setBrandToAssociate] = useState(null);

    const {
        loadingRemoveBrand,
        removeBrandById,
        filterValue,
    } = props;

    const handleCategory = (categoryType) => {
        setSelectedCategory(categoryType);
    }

    const handleEditBrand = (brand) => {
        setBrandToValidate(null);
        setBrandToEdit(brand);
        setEditMode(true);
    }

    const handleCloseModal = () => {
        setBrandToValidate(null);
        setBrandToEdit(null);
        setBrandToAssociate(null);
        setEditMode(false);
        setAssociateMode(false);
    }

    const handleValidateBrand = (brand) => {
        setBrandToEdit(null);
        setBrandToValidate(brand);
        setEditMode(true);
    }
    const handleAssociatePrivateBrand = (influencer) => {
        setBrandToAssociate(influencer);
        setAssociateMode(true);
    }

    const handleViewInfluencer = (influencer) => {
        props.viewInfluencer(influencer);
    }

    const removeInfluencer = (brand) => {
        const url = brand.pic;
        const index = url.indexOf('?');
        const extension = url.slice(index - 4, index);
        removeBrandById(brand.key, brand.displayName, brand.creatorUser, extension);
    }

    useEffect(() => {
        (loadingRemoveBrand) ? setOverlayState(true) : setOverlayState(false);
    }, [loadingRemoveBrand]);

    const redirectTrend = (brandName) => {
        props.redirectTrendPartners(brandName)
    }

    const getTable = (selectedCategory) => {
        switch (selectedCategory) {
            case 'brands':
                return <BrandTable searchValue={filterValue} openEditBrand={(brand) => handleEditBrand(brand)} deleteBrand={(brand) => removeInfluencer(brand)} history={props.history} redirectTrend={(brandName) => redirectTrend(brandName)} />;
            case 'brandsToValidate':
                return <BrandToValidateTable searchValue={filterValue} openValidateBrand={(brand) => handleValidateBrand(brand)} />;
            case 'suggestedBrands':
                return <BrandSuggestedTable searchValue={filterValue} viewInfluencer={(influencer) => handleViewInfluencer(influencer)} />;
            case 'privateCampaign':
                return <PrivateCampaign searchValue={filterValue} openEditBrand={(brand) => handleEditBrand(brand)} openAssociateInfluencerToBrand={(brand) => handleAssociatePrivateBrand(brand)} />
            default:
                return <></>
        }
    }

    const tableToRender = getTable(selectedCategory);

    return (
        <Grid className={classes.brandsContent}>
            <Backdrop className={classes.overlay} open={overlayState}>
                <CircularProgress color='secondary' />
            </Backdrop>
            <Grid container item direction='row' xs={12} justify='center' alignItems='flex-start' style={{ height: '100%' }}>
                <Grid container item direction='row' xs={11} alignItems='center' justify='flex-start'>
                    <Grid
                        className={(selectedCategory === 'brands') ? classes.activeBrandCategoryBox : classes.brandSelectorBox}
                        container
                        justify='center'
                        onClick={() => handleCategory('brands')}>
                        <Typography className={(selectedCategory === 'brands') ? classes.activeBrandSelectorText : classes.brandSelectorText}>
                            {t('ADMIN.VIEWS.PARTNERS.BRANDS.BRAND_BOX_TITLE')}
                        </Typography>
                    </Grid>
                    <Grid
                        className={(selectedCategory === 'brandsToValidate') ? classes.activeBrandCategoryBox : classes.brandSelectorBox}
                        container
                        justify='center'
                        onClick={() => handleCategory('brandsToValidate')}>
                        <Typography className={(selectedCategory === 'brandsToValidate') ? classes.activeBrandSelectorText : classes.brandSelectorText}>
                            {t('ADMIN.VIEWS.PARTNERS.BRANDS.BRAND_TO_VALIDATE_BOX_TITLE')}
                        </Typography>
                    </Grid>
                    <Grid
                        className={(selectedCategory === 'suggestedBrands') ? classes.activeBrandCategoryBox : classes.brandSelectorBox}
                        container
                        justify='center'
                        onClick={() => handleCategory('suggestedBrands')}>
                        <Typography className={(selectedCategory === 'suggestedBrands') ? classes.activeBrandSelectorText : classes.brandSelectorText}>
                            {t('ADMIN.VIEWS.PARTNERS.BRANDS.SUGGESTED_BRANDS_BOX_TITLE')}
                        </Typography>
                    </Grid>
                    <Grid
                        className={(selectedCategory === 'privateCampaign') ? classes.activeBrandCategoryBox : classes.brandSelectorBox}
                        container
                        justify='center'
                        onClick={() => handleCategory('privateCampaign')}>
                        <Typography className={(selectedCategory === 'privateCampaign') ? classes.activeBrandSelectorText : classes.brandSelectorText}>
                            {t('ADMIN.VIEWS.PARTNERS.BRANDS.PRIVATE_CAMPAIGN_BOX_TITLE')}
                        </Typography>
                    </Grid>
                </Grid>
                {
                    (editMode)
                        ? <EditBrandModal brandToEdit={brandToEdit} brandToValidate={brandToValidate} closeModal={handleCloseModal} /> : (associateMode) ?
                            <AssociateInfluencersToPrivateBrand influencersAssociate={brandToAssociate} closeModal={handleCloseModal} />
                            : tableToRender
                }
            </Grid>
        </Grid>
    )
}

const mapDispatchToProps = (dispatch) => {
    return {
        removeBrandById: (brandId, brandName, creatorUid, extension) => dispatch(removeBrandById(brandId, brandName, creatorUid, extension)),
    }
}

const mapStateToProps = (state, ownProps) => {
    const loadingRemoveBrand = state.brandsState.loadingRemoveBrand;
    return {
        loadingRemoveBrand,
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(Brands);