import React from "react";

import "./VideoTuto.css";

const HowItWorks = () => {
  return (
    <div className='videoContainer'>
      <iframe
        id='tutorial'
        title='tutorial'
        name='tuto'
        src='https://www.youtube.com/embed/Nsq1GOJE29Y?start=4'
        frameBorder='0'
        allow='accelerometer; autoplay; encrypted-media; gyroscope; picture-in-picture'
        allowFullScreen
      ></iframe>
    </div>
  );
};

export default HowItWorks;
