import React from "react";
import { makeStyles } from "@material-ui/styles";
import { Typography, DialogContent } from "@material-ui/core";

const useStyle = makeStyles(() => ({
  title: {
    fontWeight: "bold",
  },
  sousTitle: {
    fontWeight: "bold",
    fontSize: "15px",
    marginLeft: "15px",
  },
  text: {
    textAlign: "justify",
  },
}));
export const TermsOfSales = () => {
  const classes = useStyle();
  return (
    <DialogContent dividers>
      <Typography gutterBottom className={classes.text}>
        Ces conditions générales de vente font office de contrat pour
        l’utilisation de l’outil ShopMyInfluence, qui est un outil de suivi des
        campagnes d’influence, basé sur l’affiliation. ShopMyInfluence est un
        outil développé par la société Influens Network.
      </Typography>
      <Typography variant="h6" className={classes.title}>
        1. Parties
      </Typography>
      <Typography className={classes.text}>
        Influens Network SASU, agence de conseil en relations publiques et
        communication, au capital de 5000€, dont le siège social est situé 89
        rue Duhesme, 75018 Paris, immatriculée au RCS de PARIS 824 623 615 et
        représentée par Boris Deltell.
      </Typography>
      <Typography variant="h6" className={classes.title}>
        2. Préambule
      </Typography>
      <div className={classes.text}>
        <ol>
          <li>
            L’annonceur édite un site Internet et/ou une application mobile sur
            le(s)quel(s) il commercialise des produits et/ ou des services.
          </li>
          <li>
            L’annonceur souhaite optimiser ses campagnes d’influence, rémunérer
            les influenceurs à la performance et suivre les statistiques en
            temps réel.
          </li>
          <li>
            Pour ce faire, il envisage d’avoir recours à un programme
            d’affiliation via des technologies internet ou numériques.
          </li>
          <li>
            Influens Network dispose d’outils permettant à l’annonceur de mettre
            en œuvre un programme d’affiliation via des technologies internet ou
            numériques.
          </li>
          <li>
            Influens Network dispose pour ce faire d’une application mobile et
            une plateforme web, permettant à l’annonceur d’organiser son
            programme d’affiliation et ses campagnes d’influence via des
            technologies internet ou numériques
          </li>
          <li>
            L’annonceur déclare :
            <ul>
              <li>
                avoir pris connaissance des conditions dans lesquelles
                fonctionne la Plateforme et les services proposés par Influens
                Network.
              </li>
              <li>
                disposer de toutes les informations nécessaires pour considérer
                que l’outil ainsi proposé correspond à ses attentes, ses
                objectifs et aux performances recherchées
              </li>
              <li>
                disposer d’une compétence suffisante pour mettre en œuvre un
                programme d’affiliation
              </li>
              <li>
                disposer des compétences nécessaires sur un plan marketing et
                technique pour utiliser la Plateforme dans des conditions
                optimum
              </li>
              <li>
                s’être assuré du respect des prérequis techniques nécessaires
              </li>
              <li>
                avoir bien pris conscience que Influens Network intervient comme
                simple intermédiaire technique.
              </li>
            </ul>
          </li>
        </ol>
        <Typography variant="h6" className={classes.title}>
          3. Définitions
        </Typography>
        <ol start="7">
          <li>
            Les termes ci-dessous définis auront entre les parties la
            signification suivante :
            <ul>
              <li>
                « affiliation » : pratique marketing par laquelle un annonceur
                va voir ses offres commerciales (produits) diffusées auprès
                d’une force de promotion constituée d’un réseau d’influenceurs.
              </li>
              <li>
                « influenceur » : personne physique ou morale éditrice de
                services ou de contenus en ligne via un site internet et/ou une
                application mobile et/ou une base de donnée ou tout autre moyen
                de diffusion numérique inscrite sur la Plateforme, afin de
                présenter des liens pointant vers les channels des annonceurs et
                de recevoir à ce titre une rémunération.
              </li>
              <li>
                « annonceur » : personne physique ou morale éditrice de services
                ou de contenus en ligne sur Internet et/ou une application
                mobile et/ou une base de donnée inscrite sur la Plateforme et
                proposant un programme d’affiliation via des technologies
                internet ou numériques, en ce compris tout mandataire, personne
                physique ou morale, agence ou préposé agissant au nom et pour le
                compte de ladite personne physique ou morale éditrice de
                services ou de contenus en ligne.
              </li>
              <li>
                « commande » : désigne tout engagement contracté par l’annonceur
                auprès de Influens Network à partir de la Plateforme ou par tout
                autre moyen que ce soit et notamment par mél, télécopie ou bon
                de commande ou ordre d’insertion ou purchase order.
              </li>
              <li>
                « contrat » : ensemble des documents contractuels (y compris
                leurs annexes) liant l’annonceur à Influens Network, tels
                qu’énumérés à l’article 5 ; channel(s) : désigne un site
                Internet et/ou une application mobile et/ou une base de données
                ou tout autre moyen de diffusion numérique édité par l’annonceur
                pour vendre ses produits et/ou en promouvoir la
                commercialisation.
              </li>
              <li>
                « délai de validation » : désigne le délai qui est spécifié à
                l’article 14 (§86)
              </li>
              <li>
                « contrat » : ensemble des documents contractuels (y compris
                leurs annexes) liant l’annonceur à Influens Network, tels
                qu’énumérés à l’article 5 ; channel(s) : désigne un site
                Internet et/ou une application mobile et/ou une base de données
                ou tout autre moyen de diffusion numérique édité par l’annonceur
                pour vendre ses produits et/ou en promouvoir la
                commercialisation.
              </li>
              <li>
                « délai de validation » : désigne le délai qui est spécifié à
                l’article 14 (§86).
              </li>
              <li>
                « données » : données produites utilisées ou traitées pour
                exécuter les prestations contractuelles via Influens Network; à
                savoir notamment à titre d’exemple et sans s’y limiter : email,
                civilité, prénom, nom, pays, mot de passe, photo de profil,
                adresses, numéro(s) de téléphone, adresse IP, données de
                connexion et données de navigation lorsque l’internaute
                l’autorise et le cas échéant : historiques de commandes,
                transactions, réclamations, incidents, informations relatives à
                la livraison, correspondances etc… Certaines données sont
                collectées automatiquement du fait des actions de l’internaute
                sur le(s) channel(s) de l’annonceur via les Cookies
              </li>
              <li>
                « données à caractère personnel » : toute information se
                rapportant à une personne physique identifiée ou qui peut être
                identifiée, directement ou indirectement, notamment par
                référence à un identifiant, tel qu’un nom ou un numéro
                d’identification, ou à un ou plusieurs éléments spécifiques
                propres à son identité physique, physiologique, génétique,
                psychique, économique, culturelle ou sociale ; « droit de
                propriété intellectuelle » : tout élément protégé par une
                disposition du Code de la propriété intellectuelle.
              </li>
              <li>
                « événement » : tout élément ou toute action pouvant faire
                naître un droit à rémunération au profit de Influens Network
                et/ou de ses affiliés.
              </li>
              <li>
                « internaute » : personne physique ou morale connectée au réseau
                Internet afin de réaliser des opérations, interactives ou non,
                telles que la consultation de channels , l’échange
                d’informations ou la réalisation d’actes juridiques tels que des
                achats de produits ou de prestations de services.
              </li>
              <li>
                « Outil » : service en ligne de Influens Network permettant la
                définition, l’enregistrement et la diffusion de programmes
                d’affiliation pour le compte des annonceurs. Ce service en ligne
                comprend un logiciel qui comptabilise le nombre et la nature des
                événements réalisés entre le site internet et/ou une application
                mobile et/ou une base de donnée ou tout autre moyen de diffusion
                numérique des affiliés et le(s) channel(s) des annonceurs.
              </li>
              <li>
                « tracking » : ensemble des éléments techniques permettant de
                déterminer le chemin suivi et les actions effectuées par un
                internaute sur le(s) site internet et/ou une application mobile
                et/ou une base de donnée ou tout autre moyen de diffusion
                numérique ou channel(s).
              </li>
            </ul>
          </li>
        </ol>
        <Typography variant="h6" className={classes.title}>
          4. Objet
        </Typography>
        <ol start="8">
          <li>
            Les présentes conditions générales ont pour objet de définir les
            conditions dans lesquelles l’annonceur pourra utiliser l’outil
            Influens Network afin de mettre en place un programme d’affiliation.
          </li>
        </ol>
        <Typography variant="h6" className={classes.title}>
          5. Documents contractuels
        </Typography>
        <ol start="9">
          <li>
            Les documents contractuels qui lient l’annonceur à Influens Network
            sont, par ordre de priorité décroissant :
            <ul>
              <li>les présentes conditions générales </li>
              <li>
                les éventuelles conditions spécifiques et les éventuelles
                conditions particulières ou Avenants et Avenant relatif à la
                protection des Données (APD).
              </li>
              <li>les bons de commandes.</li>
              <li>
                les statistiques relatives au programme d’affiliation de
                l’annonceur présentes sur le compte de ce dernier.
              </li>
              <li>les informations présentes sur la Plateforme.</li>
              <li>
                les échanges de méls et d’informations entre Influens Network et
                l’annonceur relatifs à l’exécution des présentes conditions
                générales.
              </li>
            </ul>
          </li>
        </ol>
        <Typography variant="h6" className={classes.title}>
          6. Opposabilité
        </Typography>
        <ol start="10">
          <li>
            Les présentes conditions générales sont opposables à l’annonceur dès
            son acceptation par ce dernier, quelle qu’en soit la forme (papier
            ou numérique).
          </li>
          <li>
            Dans tous les cas, à la date de la création du compte de
            l’annonceur, les présentes conditions générales sont réputées avoir
            été lues et acceptées par l’annonceur.
          </li>
          <li>
            Influens Network se réserve le droit d’apporter aux présentes
            conditions générales toutes les modifications qu’elle jugera
            nécessaires et utiles.
          </li>
          <li>
            Influens Network s’engage à communiquer à l’annonceur les nouvelles
            conditions générales. Sauf résiliation formulée par l’annonceur dans
            un délai de 30 (trente) jours à compter de la mise à disposition
            d’une nouvelle version des conditions générales, celles-ci seront
            opposables à l’annonceur. De même les nouvelles conditions générales
            seront pleinement opposables à l’annonceur dès lors qu’il aura
            utilisé la Plateforme d’affiliation de Influens Network après mise à
            disposition des nouvelles conditions générales.
          </li>
          <li>
            Les conditions générales figurant en ligne sur la Plateforme de
            Influens Network prévalent sur toute version imprimée de date
            antérieure.
          </li>
        </ol>
        <Typography variant="h6" className={classes.title}>
          7. Durée – Entrée en vigueur
        </Typography>
        <ol start="15">
          <li>
            Les présentes conditions générales entrent en vigueur à compter de
            la signature par les parties.
          </li>
          <li>
            En cas de signature à des dates différentes, la dernière date est
            considérée entre les parties comme la date de signature effective
            des conditions générales.
          </li>
          <li>
            Les présentes conditions générales sont conclues pour une durée
            initiale d’une (1) année à compter de leur date d’entrée en vigueur.
          </li>
          <li>
            A l’arrivée du terme de cette durée initiale, les présentes
            conditions générales seront reconduites tacitement pour des périodes
            annuelles, sauf dénonciation par l’une ou l’autre des parties
            respectant un préavis de trois (3) mois avant chacune de ses
            échéances, notifiée par écrit (mail ou courrier).
          </li>
        </ol>
        <Typography variant="h6" className={classes.title}>
          8. Compte annonceur
        </Typography>
        <Typography className={classes.sousTitle}>
          8.1 Ouverture du compte annonceur
        </Typography>
        <ol start="19">
          <li>
            L’utilisation de l’outil Influens Network présuppose l’ouverture
            d’un compte par l’annonceur sur ladite Plateforme.
          </li>
          <li>
            Le compte annonceur pourra être ouvert par l’annonceur :<br />
            - soit en ligne par l’annonceur lui-même en suivant les
            prescriptions et informations figurant sur le site
            https://shopmyinfluence.fr. <br />- soit par Influens Network.
          </li>
          <li>
            La procédure d’ouverture d’un compte annonceur comprend différentes
            étapes.
          </li>
          <li>
            Lors de son inscription, l’annonceur doit fournir les informations
            requises par Influens Network. Ces informations doivent être exactes
            et justifiées à première demande de Influens Network. Ces
            informations doivent être mises à jour par l’annonceur.
          </li>
          <li>
            L’annonceur doit indiquer une adresse électronique valide qui
            permettra, notamment, l’envoi d’un courrier électronique de
            confirmation de son inscription.
          </li>
          <li>
            Lors de son inscription l’annonceur doit fournir un mot de passe
            pour pouvoir accéder à son compte sur l’outil Influens Network.
          </li>
          <li>
            L’annonceur est seul responsable de la préservation et de la
            confidentialité de son mot de passe et, par conséquent, des
            conséquences d’une divulgation involontaire à quiconque.
          </li>
          <li>
            Toute utilisation du compte annonceur à partir du mot de passe de
            l’annonceur ou modifié par lui est présumée émaner exclusivement de
            ce dernier, ce qu’il accepte expressément.
          </li>
          <li>
            Aucune opération ne peut être effectuée sans ce mot de passe ni
            l’adresse électronique correspondant à son compte annonceur.
          </li>
          <li>
            Le mot de passe et l’adresse électronique correspondant à son compte
            annonceur sont personnels et confidentiels.
          </li>
          <li>
            L’annonceur a l’obligation de notifier à Influens Network sans délai
            toute compromission de la confidentialité de son mot de passe ou
            toute utilisation par un tiers dont il aurait connaissance.
          </li>
          <li>
            A compter de la réception de cette notification, Influens Network
            procédera dans un délai raisonnable et au plus tard dans les deux
            (2) jours ouvrés à la suppression du mot de passe permettant l’accès
            au compte annonceur.
          </li>
          <li>
            Un nouveau mot de passe sera alors communiqué à l’annonceur par
            l’envoi d’un courrier électronique.
          </li>
        </ol>
        <Typography className={classes.sousTitle}>
          8.2 Accès au compte annonceur
        </Typography>
        <ol start="32">
          <li>
            L’accès au compte annonceur n’est possible qu’après identification
            de l’annonceur au moyen des codes d’accès : une adresse électronique
            correspondant au compte annonceur et un mot de passe.
          </li>
          <li>
            Le compte annonceur est normalement accessible 24 heures sur 24, 7
            jours sur 7.
          </li>
          <li>
            Influens Network se réserve le droit, sans préavis, ni indemnité, de
            fermer temporairement ou définitivement le compte annonceur pour
            effectuer une mise à jour, des modifications ou changement sur les
            méthodes opérationnelles, les serveurs et les heures
            d’accessibilité, sans que cette liste ne soit limitative.
          </li>
          <li>
            Influens Network se réserve le droit de compléter ou de modifier, à
            tout moment, ses services à distance et le compte annonceur en
            fonction de l’évolution de la technologie.
          </li>
          <li>
            Il appartiendra à l’annonceur de veiller aux possibilités
            d’évolution des moyens informatiques et de transmission à sa
            disposition pour que ces moyens puissent s’adapter aux évolutions du
            compte annonceur et des services à distance proposés par Influens
            Network.
          </li>
          <li>
            En cas d’interruption ou d’impossibilité d’utiliser le compte
            annonceur, l’annonceur peut toujours s’adresser au service client de
            Influens Network pour obtenir des informations.
          </li>
        </ol>
        <Typography className={classes.sousTitle}>
          8.3 Sécurité du compte Annonceur
        </Typography>
        <ol start="38">
          <li>
            Le compte annonceur est un système de traitement automatisé de
            données. Tout accès frauduleux à ce dernier est interdit et
            sanctionné pénalement.
          </li>
          <li>
            Influens Network met en œuvre toutes les mesures techniques et
            opérationnelles, conformément aux règles de l’art, pour sécuriser le
            compte annonceur en égard à la complexité de l’Internet. Elle ne
            saurait assurer une sécurité absolue.
          </li>
          <li>
            L’annonceur déclare accepter les caractéristiques et limites de
            l’Internet. Il déclare avoir conscience que les données circulant
            sur l’Internet ne sont pas nécessairement protégées, notamment
            contre les détournements éventuels.
          </li>
          <li>
            Il reconnaît avoir connaissance de la nature du réseau de
            l’Internet, et en particulier, de ses performances techniques et des
            temps de réponse pour consulter, interroger ou transférer les
            données.
          </li>
          <li>
            L’annonceur se doit d’informer Influens Network de toute défaillance
            du compte annonceur.
          </li>
        </ol>
        <Typography variant="h6" className={classes.title}>
          9. Prérequis
        </Typography>
        <ol start="43">
          <li>
            L’utilisation de la Plateforme de Influens Network et la mise en
            place d’un programme d’affiliation induisent des prérequis de
            différentes natures.
          </li>
        </ol>
        <Typography className={classes.sousTitle}>
          9.1 Prérequis technique
        </Typography>
        <ol start="44">
          <li>
            L’annonceur s’engage à mettre à disposition de Influens Network les
            informations et les éléments permettant la mise en place des
            programmes d’affiliation par Influens Network.
          </li>
          <li>
            L’annonceur s’engage à mettre en place sur son channel une rubrique
            « affiliation » dans laquelle il présente le programme mis en place
            sur l’outil et un lien de redirection vers la page de présentation
            de ce programme sur la Plateforme, page réalisée par Influens
            Network.
          </li>
          <li>
            L’annonceur mettra également en place sur son channel tous les
            éléments nécessaires à la mise en œuvre du programme d’affiliation.
          </li>
        </ol>
        <Typography className={classes.sousTitle}>
          9.2 Prérequis test
        </Typography>
        <ol start="47">
          <li>
            Afin de pouvoir mettre en place un programme d’affiliation pour un
            annonceur, Influens Network doit procéder à des tests techniques.
          </li>
          <li>
            Dans le cadre de ces tests, Influens Network peut effectuer un ou
            plusieurs achats sur le channel de l’annonceur. Dès la validation de
            cet achat test par Influens Network, l’annonceur s’engage à annuler
            cet achat, dès lors que cette annulation est techniquement possible,
            et en conséquence à ne pas en débiter le prix sur le compte de
            Influens Network, ni à lui envoyer de colis comprenant l’objet de
            l’achat.
          </li>
          <li>
            Dans le cas où l’annulation n’est pas réalisée par l’annonceur et/ou
            que le prix de cet achat serait débité du compte de Influens
            Network, le montant de ce prix d’achat sera facturé par Influens
            Network à l’annonceur et le colis renvoyé à l’annonceur à ses frais.
          </li>
        </ol>
        <Typography variant="h6" className={classes.title}>
          10. Services & commande
        </Typography>
        <ol start="50">
          <li>
            Les services proposés par Influens Network en matière d’affiliation
            et d’influence sont présentés à l’annonceur.
          </li>
          <li>
            L’annonceur choisit le ou les services les plus appropriés à ses
            objectifs promotionnels.
          </li>
          <li>
            L’annonceur est tenu par les engagements présents dans le ou les
            services qu’il a choisis et commandés.
          </li>
          <li>
            S’agissant d’un contrat sous forme électronique, il est fait
            dérogation expresse aux dispositions de l’article 1369-5 et 1° à 5°
            de l’article 1369-4 du Code civil.
          </li>
        </ol>
        <Typography variant="h6" className={classes.title}>
          11. Modalités financières
        </Typography>
        <ol start="56">
          <li>
            Les conditions et modalités de rémunération des affiliés au titre du
            programme d’affiliation mis en place par Influens Network sont
            définies sur la Plateforme.
          </li>
          <li>
            Influens Network assure le règlement des sommes dues aux affiliés.
          </li>
          <li>
            Les montants dus aux affiliés au titre du programme d’affiliation
            mis en place sur la Plateforme sont évalués à partir des données
            enregistrées par Influens Network (ci-après les « données » ou les «
            statistiques »).
          </li>
          <li>
            La Plateforme enregistre chaque événement intervenu par le biais du
            programme d’affiliation. Il est expressément convenu entre les
            parties que les enregistrements effectués par la Plateforme servent
            de référence pour le calcul des événements. Par conséquent, les
            enregistrements effectués par Influens Network servent de référence
            à l’établissement des données prises en compte pour la facturation
            des sommes dues par l’annonceur aux affiliés et à Influens Network.
          </li>
          <li>
            Influens Network est donc seule responsable du comptage des
            événements précités. L’annonceur reconnaît et accepte que les
            statistiques établies par Influens Network font foi et feront office
            de données officielles et définitives entre les parties.
          </li>
          <li>
            Le montant de la rémunération due par l’annonceur comprend la
            rémunération des affiliés au titre du programme d’affiliation
            enregistrée sur la Plateforme ainsi que la rémunération de Influens
            Network.
          </li>
          <li>
            En tant que de besoin, l’annonceur reconnaît et accepte que Influens
            Network applique un délai post-view de un (1) jour et un délai
            post-clic de trente (30) jours.
          </li>
          <li>
            Influens Network fait parvenir par principe une facture mensuelle
            unique à l’annonceur comprenant les montants dus par l’annonceur aux
            affiliés au titre des événements intervenus sur le channel de
            l’annonceur au cours du mois précédant le mois de facturation, que
            ces événements aient fait ou non l’objet d’une validation
            conformément aux dispositions de l’article 14( §86) « Délai de
            validation » des présentes conditions générales.
          </li>
          <li>
            Les factures de Influens Network sont payables dans les 30 jours de
            la fin du mois qui précède la réception de la facture Influens
            Network (date de l’email ou cachet de la poste faisant foi).
          </li>
          <li>
            Les frais bancaires liés au paiement des factures émises par
            Influens Network à l’annonceur sont à la charge de l’annonceur et
            seront facturés, le cas échéant ou déduits des versements effectués
            par l’annonceur sur son compte.
          </li>
          <li>
            En cas d’absence de paiement de l’annonceur de tout ou partie d’une
            facture émise par Influens Network dans le délai de 30 (trente)
            jours à compter de la réception de ladite facture par l’annonceur,
            Influens Network pourra à sa discrétion appliquer à l’annonceur la
            pénalité suivante au titre des intérêts moratoires, sans préjudice
            de son droit à réclamer l’indemnisation de son préjudice lié audit
            retard de paiement.
          </li>
          <li>
            Concernant les sommes facturées qui n’ont pas été payées à
            l’échéance prévue : Elles portent intérêt dès le 1er jour, à un taux
            égal à une fois et demi le taux d’intérêt légal (en application de
            la loi n°2001-420 du 15 mai 2001), les frais de recouvrement légaux
            étant à la charge du débiteur. Par ailleurs, en cas de non-respect
            des conditions de paiement et après notification par acte
            extra-judiciaire d’une mise en demeure de payer sous 48H restée
            infructueuse :
            <ul>
              <li>
                L’annonceur s’engage à payer à titre de réparation du préjudice
                subi une indemnité égale à trois pour cent (3%) du principal
                restant dû.
              </li>
              <li>
                En cas de recouvrement forcé des factures, le montant de
                celles-ci sera augmenté de douze pour cent (12%), à titre de
                clause pénale au sens des articles 1226 et suivants du Code
                civil, outre les intérêts légaux et les frais judiciaires.
              </li>
              <li>
                Influens Network se réserve le droit de refuser de vendre, de
                résilier sans préavis ni indemnité, tout ordre ou bon de
                commande en cours de l’annonceur, ce dernier étant alors
                redevable de la totalité du prix des campagnes déjà produites ou
                des Prestations déjà effectuées.
              </li>
              <li>
                La totalité des créances de Influens Network même non échues
                deviennent immédiatement exigibles.
              </li>
              <li>
                En outre, une indemnité forfaitaire de 40€ pour frais de
                recouvrement sera appliquée en sus des pénalités de retard sus
                visées.
              </li>
              En cas de défaillance d’un mandataire titulaire d’une attestation
              de mandat et même si le mandataire est "mandataire payeur",
              Influens Network poursuivra directement le recouvrement des
              créances dues, auprès du mandant.
            </ul>
          </li>
          <li>
            Cette pénalité court à compter du premier jour suivant la date
            d’échéance du paiement, et ce, jusqu’au jour du paiement et Influens
            Network pourra la facturer sans formalité préalable.
          </li>
          <li>
            Sauf accord particulier, les ventes à l’étranger doivent faire
            l’objet d’un paiement comptant en Euro à réception de la facture ou
            de l’ordre d’insertion (OI) ou du bon de commande. Les conditions de
            paiement accordées sont révisables sans préavis en cas de survenance
            d’un élément nouveau venant modifier notre appréciation du risque de
            défaut de paiement de l’annonceur. Notamment en cas de changement
            dans la situation de l’annonceur (décès, incapacité, dissolution ou
            modification de société, hypothèque de ses immeubles, nantissement
            de son fonds de commerce, location gérance, etc...), de refus
            d’acceptation d’une traite ou d’un incident de paiement quelconque
            ou pour tout dépassement d’encours tel qu’autorisé et estimé par
            Influens Network ; Influens Network se réserve le droit de demander
            des garanties, de refuser de vendre, de suspendre ou d’annuler les
            commandes en cours, même acceptées ou de ne les exécuter que
            moyennant paiement comptant sur facture ou ordre Client
          </li>
        </ol>
        <Typography variant="h6" className={classes.title}>
          12. Modification du channel de l’annonceur
        </Typography>
        <ol start="67">
          <li>
            Toute modification relative à la dénomination du channel de
            l’annonceur ainsi que toute modification relative à son lieu
            d’hébergement, sa taille, son objet, sa fréquence de mise à jour n’a
            aucun effet sur les présentes conditions générales qui s’appliquent
            de plein droit et automatiquement au channel modifié.
          </li>
          <li>
            Les présentes conditions générales s’appliquent à l’ensemble des
            lieux d’hébergement du channel de l’annonceur présents ou à venir
            ainsi qu’à tous les channels qui viendraient à être détenus par
            l’annonceur.
          </li>
          <li>
            Dans l’hypothèse où l’annonceur diviserait son channel en plusieurs
            channels différents, créerait ou viendrait à acquérir un nouveau
            channel, les présentes conditions générales s’appliqueront de plein
            droit à l’ensemble de ces nouveaux channels.
          </li>
          <li>
            L’annonceur s’engage à informer sans délais Influens Network en cas
            de changement notable affectant la nature et le contenu de son
            channel.
          </li>
          <li>
            L’annonceur s’engage à informer dans les plus brefs délais Influens
            Network de toute modification du channel qui pourrait entraîner une
            altération et/ou une perte des données de tracking enregistrées par
            la Plateforme permettant à Influens Network de calculer la
            rémunération qui lui est due.
          </li>
        </ol>
        <Typography variant="h6" className={classes.title}>
          13. Tracking
        </Typography>
        <ol start="72">
          <li>
            Influens Network met en place tous les moyens permettant d’assurer
            le tracking. Influens Network fournit à l’annonceur les éléments
            techniques que celui-ci devra installer sur ses channels et les
            éléments de son programme d’affiliation le cas échéant. L’annonceur
            s’interdit d’y porter atteinte de quelque manière que ce soit.
          </li>
          <li>
            En cas de dysfonctionnement du tracking imputable à l’annonceur,
            celui-ci est tenu d’y remédier dans les plus brefs délais.
          </li>
          <li>
            Si l’annonceur ne rétablit pas le tracking dans les soixante-douze
            (72) heures à compter de la survenance du dysfonctionnement,
            Influens Network se réserve la faculté de privatiser le programme
            d’affiliation.
          </li>
          <li>
            Dans cette hypothèse, l’annonceur versera à Influens Network la plus
            élevée des deux valeurs suivantes : l’équivalent par clic
            correspondant au montant des gains des affiliés sur la période d’un
            mois précédant l’altération et/ou la perte des dites données divisé
            par le nombre de clics réalisés sur la période considérée ; le prix
            du marché habituellement pratiqué pour des catégories de site
            internet et/ou d’application mobile et/ou de base de donnée ou tout
            autre moyen de diffusion numérique équivalentes.
          </li>
          <li>
            Influens Network se réserve la faculté de suspendre le programme
            d’affiliation si l’annonceur ne rétablit pas le tracking dans les
            sept (7) jours ouvrés à compter de la survenance du
            dysfonctionnement.
          </li>
        </ol>
        <Typography variant="h6" className={classes.title}>
          14. Délai de validation
        </Typography>
        <ol start="77">
          <li>
            L’Annonceur accepte de coopérer en toute transparence avec Influens
            Network sur les outils de déduplication qu’il met en place ; A ce
            titre, l’annonceur fournira à Influens Network toutes les
            informations que Influens Network pourrait demander afin de
            permettre à Influens Network de comptabiliser les ventes réellement
            générées via Influens Network.
            <br /> Notamment, l’Annonceur s’engage à communiquer à Influens
            Network de manière détaillée et préalablement à chaque lancement de
            programme ou campagne, les règles de déduplication mises en place
            (autrement dit : les règles et/ou outils avec lesquels l’annonceur
            déduplique ses partenaires en affiliation).
            <br /> Toute modification relative à ces règles doit être signalée à
            Influens Network au minimum 48h avant la mise en place effective de
            ces modifications. Les règles de déduplication de l’Annonceur
            doivent s’inscrire dans le cadre des bonnes pratiques éditées par le
            marché et les différents organismes ( Collectif des Plateformes
            d’Affiliation etc…). Dans ce cadre, l’annonceur s’engage à ne pas
            procéder à une déduplication sur les actions (ventes) comptabilisés
            par Influens Network avec les canaux ou leviers d’influence
            marketing.
            <br /> En tout état de cause, en l’absence d’indication par
            l’annonceur des règles et/ou outils avec lesquels l’annonceur
            déduplique ses partenaires en affiliation ; l’annonceur perdra le
            bénéfice des dispositions prévues ci-après concernant les
            annulations.
          </li>
          <li>
            L’annonceur s’engage à pré-qualifier les motifs pour lesquels il
            pourrait être amené à considérer un événement comme étant non valide
            et à les communiquer à Influens Network.
          </li>
          <li>
            Le délai de validation est fixé à 30 jours maximum et court à
            compter de la réalisation de l’événement.
          </li>
          <li>
            Pendant le délai de validation, l’annonceur devra faire connaître
            par tous moyens à Influens Network, sur la Plateforme ou via une
            procédure définie autrement entre les parties, le nombre, la nature
            et le montant des évènements à annuler et les motifs de cette
            annulation ; le montant des annulations étant en tout état de cause
            plafonné, pour chacun des programmes d’affiliation ou chacune des
            campagnes publicitaires produites via des technologies internet ou
            numériques , au pourcentage mensuel des évènements suivants : 10 %
            du montant des commissions facturées au titres des ventes générées.
          </li>
          <li>
            A défaut pour l’annonceur d’avoir porté à la connaissance de
            Influens Network l’annulation d’un événement ainsi que son motif
            valable, cet événement sera définitivement considéré comme valide,
            et le droit à rémunération de Influens Network sera définitivement
            acquis.
          </li>
          <li>
            En tout état de cause, en l’absence d’indication par l’annonceur
            d’un délai de validation au moment du remplissage du formulaire de
            saisie en ligne du programme d’affiliation, l’annonceur perdra le
            bénéfice des dispositions précédentes.
          </li>
        </ol>
        <Typography variant="h6" className={classes.title}>
          15. Collaboration
        </Typography>
        <ol start="83">
          <li>
            Les parties conviennent de collaborer étroitement dans le cadre de
            leurs relations.
          </li>
          <li>
            L’annonceur s’engage à maintenir une collaboration active et
            régulière en transmettant à Influens Network l’ensemble des éléments
            nécessaires à l’exécution des présentes conditions générales.
          </li>
          <li>
            L’annonceur s'engage à communiquer toutes les difficultés dont il
            pourrait prendre la mesure au fur et à mesure de l’exécution des
            présentes, afin de permettre leur prise en compte le plus rapidement
            possible par Influens Network.
          </li>
        </ol>
        <Typography variant="h6" className={classes.title}>
          16. Garantie
        </Typography>
        <ol start="86">
          <li>
            L’annonceur garantit à Influens Network que le contenu de son
            programme d’affiliation respecte en tous points les lois et
            réglementations en vigueur.
          </li>
          <li>
            L’annonceur garantit à Influens Network, exercer son activité en
            conformité avec l’ensemble des lois et réglementations en vigueur ;
            et Notamment pour les cax où il commercialisera des produits qui
            entrent dans le cadre de la réglementation mise en place par la loi
            n°2016-1691 du 9 décembre 2016 relative à la transparence, à la
            lutte contre la corruption et à la modernisation de la vie
            économique (dite « Loi Sapin 2 ») afin d’encadrer la communication à
            caractère promotionnel réalisée sur certains contrats financiers à
            risque et sur certains placements financiers risqués désignés comme
            les placements atypiques.
          </li>
          <li>
            Influens Network se réserve le droit de refuser tout ou partie du
            programme d’affiliation de l’annonceur si elle estime que cette
            campagne peut nuire à son image de marque ou à celle d’un de ses
            partenaires.
          </li>
          <li>
            L’annonceur garantit qu’il dispose des droits nécessaires, notamment
            des droits de propriété intellectuelle, à l’utilisation par Influens
            Network et les affiliés de l’ensemble des informations et éléments
            qu’il remet à Influens Network pour l’exécution des présentes
            conditions générales.
          </li>
          <li>
            L’annonceur garantit Influens Network de l’ensemble des conséquences
            dommageables pouvant résulter du manquement de l’annonceur à un des
            engagements listés dans les présentes conditions générales et
            notamment de tout recours amiable ou contentieux exercé par des
            tiers relatif à l’exécution des présentes conditions générales.
          </li>
        </ol>
        <Typography variant="h6" className={classes.title}>
          17. Notification par un tiers
        </Typography>
        <ol start="91">
          <li>
            En cas de notification par un tiers aux présentes conditions
            générales d’un manquement au programme d’affiliation d’un annonceur
            à toutes lois ou réglementations en vigueur, Influens Network pourra
            mettre fin audit programme sans autorisation de l’annonceur et en
            conservant et/ou facturant l’intégralité des sommes dues par
            l’annonceur.
          </li>
        </ol>
        <Typography variant="h6" className={classes.title}>
          18. Préjudice
        </Typography>
        <ol start="92">
          <li>
            La responsabilité de Influens Network dans le cadre des présentes
            conditions générales est strictement limitée au préjudice direct
            réellement subi et prouvé par l’annonceur, à l’exclusion de tous
            chefs de préjudice indirect tels que manque à gagner, perte de
            chiffres d’affaires ou autres et, en tout état de cause, ne saurait
            dépasser le montant des sommes perçues de l’annonceur par Influens
            Network au titre des présentes conditions générales durant les six
            mois précédant le fait générateur du dommage contractuel, déduction
            faite des sommes payées par Influens Network aux affiliés.
          </li>
        </ol>
        <Typography variant="h6" className={classes.title}>
          19. Convention de preuve
        </Typography>
        <ol start="93">
          <li>
            Les registres informatisés et conservés dans les systèmes
            informatiques seront conservés par Influens Network dans des
            conditions raisonnables de sécurité et considérés comme les preuves
            des communications intervenus entre les parties.
          </li>
          <li>
            L’archivage des documents contractuels est effectué sur un support
            fiable et durable pouvant être produit à titre de preuve.
          </li>
        </ol>
        <Typography variant="h6" className={classes.title}>
          20. Propriété intellectuelle, données et savoir-faire
        </Typography>
        <ol start="95">
          <li>
            Les présentes conditions générales n’emportent aucune cession
            d’aucune sorte de droits de propriété intellectuelle ou de données
            sur les éléments constitués via ShopMyInfluence et appartenant à
            Influens Network au bénéfice de l’annonceur.
          </li>
          <li>
            L’annonceur reconnaît et accepte que le contenu des site internet
            et/ou application mobile ou tout autre moyen de diffusion numérique
            et notamment mais non exclusivement les textes, données,
            photographies, vidéos, logiciels et programmes, sons, musiques, mise
            en page, charte graphique, logos, design ou toute autre information
            ou support présenté sur ShopMyInfluence, sont protégés par leurs
            droits d’auteurs, marque, brevet et tout autre droit de propriété
            intellectuelle ou industrielle qui leur sont reconnus selon les lois
            en vigueur.
          </li>
          <li>
            Toute reproduction et/ou représentation, totale ou partielle d’un de
            ces droits ou de ces données, sans l’autorisation expresse de
            Influens Network est interdite et constituerait une contrefaçon
            sanctionnée par les articles L. 335-2 et suivants du Code de la
            propriété intellectuelle.
          </li>
          <li>
            En conséquence, l’annonceur s’interdit tout agissement et tout acte
            susceptible de porter atteinte directement ou non aux droits de
            propriété intellectuelle ou sur les données de ShopMyInfluence sans
            son autorisation.
          </li>
          <li>
            L’annonceur reconnaît que les informations, les données et les bases
            de données accessibles sur les sites internet et/ou application
            mobile et/ou base de données ou tout autre moyen de diffusion
            numérique ou la Plateforme ShopMyInfluence sont la propriété de
            Influens Network.
          </li>
          <li>
            Influens Network conservera la propriété des méthodes et du
            savoir-faire ou des outils qui lui sont propres et des données
            produites, utilisées ou traitées ayant servi à exécuter les
            prestations contractuelles.
          </li>
        </ol>
        <Typography variant="h6" className={classes.title}>
          21. Références commerciales
        </Typography>
        <ol start="101">
          <li>
            Influens Network pourra citer les noms et logo de l’annonceur à
            titre de référence commerciale conformément aux usages commerciaux,
            et ce dernier concède le cas échéant une autorisation au titre de
            tout droit privatif, notamment de propriété industrielle, dont il
            bénéficierait sur lesdits nom et logo.
          </li>
          <li>
            En cas de rupture des présentes conditions générales, Influens
            Network conservera le droit d’utiliser les noms et logo de
            l’annonceur en tant que référence client, sauf demande explicite de
            l’annonceur.
          </li>
        </ol>
        <Typography variant="h6" className={classes.title}>
          22. Données à caractère personnel
        </Typography>
        <Typography className={classes.sousTitle}>22.1 Généralités</Typography>
        <ol start="103">
          <li>
            Chaque partie fera son affaire du respect des obligations qui lui
            incombent en vertu de règlementation en vigueur applicable au
            traitement de données à caractère personnel et, en particulier, le
            Règlement (UE) 2016/679 du Parlement européen et du Conseil du 27
            avril 2016 applicable à compter du 25 mai 2018 (ci-après, le « RGPD
            »).
          </li>
          <li>
            En particulier, chacune des Parties s’engage à prendre toutes les
            précautions utiles et appropriées pour préserver et assurer la
            protection des données à caractère personnel transmises à ou par
            l’autre Partie, et assurer la sécurisation des traitements de
            données à caractère personnel effectués pour le compte de celle-ci
            dans le cadre de l’exécution du présent Contrat, notamment pour
            empêcher que les données à caractère personnel soient déformées,
            endommagées, détruites ou que des tiers non autorisés y aient accès.
            <br />A cet effet, chacune des Parties s’engage à prendre des
            mesures raisonnables afin de s’assurer de la fiabilité de ses
            salariés, préposés, filiales, sous-traitants, ou agents (ci-après le
            « Personnel ») prenant part aux Traitements de Données à Caractère
            Personnel.
            <br />
            En particulier, l’annonceur confirme que toutes les Données à
            Caractère Personnel de ShopMyInfluence auxquelles il a accès ne
            seront accessibles qu’aux membres du Personnel qui : (i) ont besoin
            d’y avoir accès ; (ii) ont suivi une formation sur la gestion
            appropriée des Données à Caractère Personnel ; et (iii) sont soumis
            à des obligations contractuelles relatives au respect de la vie
            privée, à la sécurité et à la confidentialité concernant ces Données
            à Caractère Personnel.
          </li>
        </ol>
        <Typography className={classes.sousTitle}>
          22.2 Sous-traitance des traitements par Influens Network
        </Typography>
        <ol start="105">
          <li>
            Les présentes clauses ont pour objet de définir les conditions dans
            lesquelles Influens Network, en sa qualité de sous-traitant,
            s’engage à effectuer pour le compte de l’annonceur, responsable de
            traitement, les opérations de traitement de données à caractère
            personnel, dont l’objet, la durée, la nature et la finalité sont
            décrits et stockés de façon dématérialisée dans un registre de
            traitement approprié (comportant les rubriques décrites en ANNEXE 1)
            que Influens Network pourra mettre à disposition en cas de demande.
          </li>
          <li>
            Obligations de Influens Network vis-à-vis de l’annonceur. En sa
            qualité de sous-traitant, Influens Network s’engage à :
            <ol>
              <li>
                Traiter les données uniquement pour la ou les seule(s)
                finalité(s) qui fait/font l’objet de la sous-traitance et
                conformément aux instructions documentées de l’annonceur, telles
                que décrites dans le document «Registre des traitements ».{" "}
                <br />
                Il est à cet égard précisé que si Influens Network considère
                qu’une instruction constitue une violation du RGPD ou de toute
                autre disposition du droit de l’Union ou du droit des Etats
                membres relative à la protection des données, il en informe
                immédiatement l’annonceur. En outre, si Influens Network est
                tenu de procéder à un transfert de données vers un pays tiers ou
                à une organisation internationale, en vertu du droit de l’Union
                ou du droit de l’Etat membre auquel il est soumis, il doit
                informer l’annonceur de cette obligation juridique avant le
                traitement, sauf si le droit concerné interdit une telle
                information pour des motifs importants d’intérêt public.
              </li>
              <li>
                Garantir la confidentialité des données à caractère personnel
                traitées dans le cadre du présent Contrat et veiller à ce que
                les personnes autorisées à traiter les données à caractère
                personnel en vertu du présent Contrat s’engagent également à
                respecter cette confidentialité ou soient soumises à une
                obligation légale appropriée de confidentialité, et reçoivent la
                formation nécessaire en matière de protection des données à
                caractère personnel.
              </li>
              <li>
                Prendre en compte, s’agissant de ses outils, produits,
                applications ou services, les principes de protection des
                données dès la conception et de protection des données par
                défaut.
              </li>
            </ol>
          </li>
          <li>
            Obligations de l’annonceur vis-à-vis de Influens Network. En sa
            qualité de responsable des traitements, l’annonceur s’engage à :
            <ul>
              <li>
                Veiller, au préalable et pendant toute la durée du traitement,
                au respect des obligations prévues par le RGPD de la part de
                Influens Network. A cette fin, et s’il le souhaite, l’annonceur
                pourra réaliser, à ses frais, des audits et des inspections
                auprès de Influens Network, après en avoir avisé ce dernier par
                écrit avec un préavis minimum de 30 jours.
              </li>
              <li>
                Sécuriser le traitement et garantir auprès de Influens Network
                le bon respect des obligations qui lui incombent en vertu du
                RGPD. En particulier, l’annonceur veillera à s’assurer en tout
                temps du caractère licite des traitements de données confiés en
                sous-traitance à Influens Network, ainsi que du bon respect des
                exigences du RGPD en ce qui concerne l’exercice des droits de la
                personne concernée.
              </li>
              <li>
                Prendre toutes les précautions nécessaires dans la collecte de
                ces Données à Caractère Personnel auprès de ses Utilisateurs
                pour se conformer à la réglementation en vigueur en sa qualité
                de responsable du traitement et notamment les dispositions du
                RGPD.
              </li>
              <li>
                Recueillir, lorsque cela est nécessaire, le consentement des
                Utilisateurs sur la communication de leurs Données à Caractère
                Personnel, de manière claire, transparente et non équivoque, et
                notamment le transfert des données à Infleuns Network.
                <br />
                Par exemple, Influens Network recommande à l’annonceur d’insérer
                une case à cocher dans le formulaire de collecte de Données à
                Caractère Personnel des Utilisateurs par laquelle l’Utilisateur
                accepte que ses données soient communiquées à Influens Network
                et de conserver la preuve de chaque acceptation.
                <br />A défaut d’acceptation ou en cas de demande d’effacement
                des Données à Caractère Personnel, les Utilisateurs ne pourront
                plus accéder aux Services.
              </li>
              <li>
                Assumer la réparation de tout dommage matériel ou moral
                résultant d’une violation du RGPD, étant précisé que Influens
                Network, en tant que sous-traitant, ne pourra être tenu pour
                responsable du dommage causé par le traitement que s’il n’a pas
                respecté les obligations prévues par le RGPD qui incombent
                spécifiquement aux sous-traitants ou qu’il a agi en-dehors des
                instructions licites du responsable du traitement ou
                contrairement à celles-ci.
              </li>
              <li>
                L’annonceur à la responsabilité de s’assurer que toute donnée à
                caractère personnel transmise à Influens Network repose sur une
                base légale conforme au GDPR.
              </li>
            </ul>
          </li>
          <li>
            Droit d’information des personnes concernées. Influens Network,
            lorsqu’il collecte des données, devra fournir aux personnes
            concernées par les opérations de traitement l’information relative
            aux traitements de données qu’il réalise pour le compte de
            l’annonceur.
          </li>
          <li>
            Exercice des droits des personnes. Dans la mesure du possible,
            Influens Network devra aider l’annonceur à s’acquitter de son
            obligation de donner suite aux demandes d’exercice des droits des
            personnes concernées : droit d’accès, de rectification, d’effacement
            et d’opposition, droit à la limitation du traitement, droit à la
            portabilité des données, droit de ne pas faire l’objet d’une
            décision individuelle automatisée (y compris le profilage). A ce
            titre, il est convenu que Influens Network devra répondre, au nom et
            pour le compte de l’annonceur et dans les délais prévus par le RGPD
            aux demandes des personnes concernées en cas d’exercice de leurs
            droits, s’agissant des données faisant l’objet de la sous-traitance
            prévue par le présent Contrat. En outre, Influens Network s’engage à
            être disposé à démontrer la conformité des activités de traitement
            avec le RGPD, y compris l’efficacité des mesures. Ces mesures
            tiennent compte de la nature, de la portée, du contexte et des
            finalités du traitement ainsi que du risque que celui-ci présente
            pour les droits et libertés des personnes physiques.
          </li>
          <li>
            Notification des violations de données à caractère personnel.
            Influens Network notifiera, par écrit, à l’annonceur toute violation
            de données à caractère personnel dans les meilleurs délais après en
            avoir pris connaissance. Cette notification sera accompagnée de
            toute documentation utile afin de permettre à l’annonceur, si
            nécessaire, de notifier cette violation à l’autorité de contrôle
            compétente.
          </li>
          <li>
            Mesures de sécurité. Influens Network s’engage à mettre en œuvre des
            mesures techniques et organisationnelles garantissant un niveau de
            sécurité adapté pour assurer la sécurisation et la protection des
            données à caractère personnel traitées pour le compte de
            l’annonceur. Ces mesures sont notamment les suivantes :
            <ul>
              <li>
                Prendre toutes mesures de sécurité appropriées pour tous
                échanges de données ou de fichiers relatifs aux Données à
                Caractère Personnel réalisés non seulement entre Influens
                Network et l’Annonceur mais aussi avec des tiers autorisés et,
                notamment, utiliser des canaux de communication sécurisés et
                respecter les Mesures de Sécurité préconisées par l’état de
                l’art et les textes et règlements applicables.
              </li>
              <li>
                Conserver et archiver les données ou les fichiers informatiques
                relatifs aux Données à Caractère Personnel pendant la durée
                nécessaire exclusivement à la réalisation des Services objets
                des présentes et ce, conformément aux instructions communiquées
                par l’annonceur.
              </li>
              <li>
                Procéder à toute destruction, rectification, limitation ou, sur
                demande de l’annonceur, restitution intégrale des données,
                fichiers informatiques et éventuelles copies relatifs aux
                Données à Caractère Personnel au plus tard, à l’expiration des
                présentes, et fournir, le cas échéant et si l’Annonceur le
                demande, l’attestation de destruction et/ou d’effacement
                définitif correspondante.
              </li>
              <li>
                Restreindre l’accès aux informations et Données à Caractère
                Personnel confiées au seul personnel habilité à intervenir sur
                ces données de par ses fonctions à l’exclusion de toute autre
                personne et leur assurer une formation appropriée en matière de
                protection des Données à Caractère Personnel.
              </li>
              <li>
                Supprimer ou restituer à l’annonceur les Données à Caractère
                Personnel à l’issue de la durée de conservation indiquée aux
                présentes, et en tout état de cause, à la cessation des
                présentes.
              </li>
              <li>
                Informer sans délai l’Annonceur de tout événement, incident ou
                faille de sécurité, volontaire ou accidentel, relatif à la
                confidentialité, l’intégrité et à la sécurité des Données à
                Caractère Personnel, et notamment, toute atteinte, perte, vol,
                accès non autorisé, divulgation, destruction, altération des
                Données à Caractère Personnel et à fournir son concours à toute
                demande de collaboration de l’annonceur.
              </li>
              <li>
                Ne transférer aucune Donnée à Caractère Personnel vers un pays
                tiers n’offrant pas de garantie de protection des données
                personnelles adéquate au sens de la directive 95/46/CE, sans
                avoir mis en place des mécanismes contractuels et des processus
                juridiques contraignants pour légalement transférer ces Données
                au-delà des frontières de l’Union Européenne.
              </li>
              <li>
                Il est précisé que la notification à l’annonceur des incidents
                de sécurité et leur gestion par Influens Network font partie des
                Prestations confiées à Influens Network au titre du Contrat et
                des présentes CGV et, à ce titre, ne feront pas l’objet d’une
                facturation complémentaire.
              </li>
            </ul>
          </li>
          <li>
            Sort des données. Au terme de la prestation de services relatifs au
            traitement de ces données, Influens Network s’engage à détruire
            toutes les données à caractère personnel collectées ou traitées pour
            le compte de l’annonceur.
          </li>
          <li>
            Documentation. Influens Network mettra à la disposition de
            l’annonceur la documentation nécessaire pour démontrer le respect de
            toutes ses obligations et pour permettre la réalisation d’audits, y
            compris des inspections, par l’annonceur ou un autre auditeur qu’il
            a mandaté, et contribuer à ces audits.
          </li>
        </ol>
        <Typography className={classes.sousTitle}>
          22.3 Sous-traitance des traitements par l’annonceur
        </Typography>
        <Typography>
          Les présentes clauses ont pour objet de définir les conditions dans
          lesquelles l’annonceur, en sa qualité de Sous-traitant, peut être
          amené, en fonction des services souscrits et notamment dans le cadre
          de l’ « IP tracking system » afin de rattacher une vente à un
          publisher, à effectuer pour le compte de Influens Network ou de ses
          publishers, responsable(s) de traitement, les opérations de traitement
          de données à caractère personnel, dont l’objet, la durée, la nature et
          la finalité sont décrits et stockés de façon dématérialisée dans un
          registre de traitement approprié (comportant à titre informatif les
          rubriques décrites en ANNEXE 1).
        </Typography>
        <ol start="114">
          <li>
            Obligations de l’annonceur vis-à-vis de Influens Network. En sa
            qualité de Sous-traitant, l’annonceur s’engage à :
            <ol>
              <li>
                Traiter les Données à Caractère Personnel de Influens Network
                dans le cadre strict et nécessaire de ses obligations
                contractuelles et, en tout état de cause, à n’agir que sur
                instructions écrites et préalables de Influens Network, <br />
                Il est à cet égard précisé que si l’annonceur considère qu’une
                instruction constitue une violation du RGPD ou de toute autre
                disposition du droit de l’Union ou du droit des Etats membres
                relative à la protection des données, il en informe
                immédiatement Influens Network.. En outre, si l’annonceur est
                tenu de procéder à un transfert de données vers un pays tiers ou
                à une organisation internationale, en vertu du droit de l’Union
                ou du droit de l’Etat membre auquel il est soumis, il doit
                informer l’annonceur de cette obligation juridique avant le
                traitement, sauf si le droit concerné interdit une telle
                information pour des motifs importants d’intérêt public.
              </li>
              <li>
                Garantir la confidentialité des données à caractère personnel
                traitées dans le cadre du présent Contrat et veiller à ce que
                les personnes autorisées à traiter les données à caractère
                personnel en vertu du présent Contrat s’engagent également à
                respecter cette confidentialité ou soient soumises à une
                obligation légale appropriée de confidentialité, et reçoivent la
                formation nécessaire en matière de protection des données à
                caractère personnel.
              </li>
              <li>
                Prendre en compte, s’agissant de ses outils, produits,
                applications ou services, les principes de protection des
                données dès la conception et de protection des données par
                défaut.
              </li>
            </ol>
          </li>
          <li>
            Obligations de Influens Network vis-à-vis de l’annonceur. En sa
            qualité de responsable des traitements ou de sous-traitant initial
            réalisant des traitements de données pour le compte de ses
            publishers, Influens Network s’engage à :
            <ol>
              <li>
                Documenter par écrit toute instruction concernant le traitement
                des données confiées à l’annonceur. A ce titre, Influens Network
                veillera à transmettre à l’annonceur, une description de toutes
                les informations nécessaires à la réalisation du traitement de
                données envisagé, comprenant entre autres : l’objet, la durée,
                la nature et la ou les finalités du traitement de données à
                caractère personnel envisagé. Cette transmission pouvant se
                faire de façon dématérialisée, par le biais d’un registre de
                traitement approprié (comportant les rubriques décrites en
                ANNEXE-1).
              </li>
              <li>
                Superviser le traitement et garantir auprès de l’annonceur le
                bon respect des obligations qui lui incombent en vertu du RGPD.
                En particulier, Influens Network veillera à s’assurer en tout
                temps du caractère licite des traitements de données confiés en
                sous-traitance à l’annonceur, ainsi que du bon respect des
                exigences du RGPD en ce qui concerne la base légale du
                traitement (consentement, intérêt légitime…) et l’exercice des
                droits de la personne concernée.
              </li>
              <li>
                Assumer la réparation de tout dommage matériel ou moral
                résultant d’une violation du RGPD, étant précisé que
                l’annonceur, en tant que sous-traitant, ne pourra être tenu pour
                responsable du dommage causé par le traitement que s’il n’a pas
                respecté les obligations prévues par le RGPD qui incombent
                spécifiquement aux sous-traitants ou qu’il a agi en-dehors des
                instructions licites du responsable du traitement (ou du
                sous-traitant initial) ou contrairement à celles-ci.
              </li>
            </ol>
          </li>
          <li>
            Données à Caractère Personnel Sensibles <br />
            L’annonceur s’engage à ne partager, avec Influens Network, aucune
            Donnée à Caractère Personnel relevant de la catégorie des Données
            sensibles/particulières, telle que définie aux Articles 9 et 10 du
            RGPD, sauf accord exprès et écrit.
          </li>
          <li>
            Consentement <br />
            S’il est un fournisseur de données pour Influens Network (pour son
            propre compte et/ou pour le compte de ses Publishers), l’annonceur
            s’engage à conserver la preuve des consentements nécessaires (le cas
            échéant) obtenus de toutes Personnes Concernées dont vous partagez
            les Données à Caractère Personnel avec Influens Network, et s’assure
            qu’en tout état de cause lesdites Personnes Concernées se sont vues
            offrir un lien clair et non ambigu vers un mécanisme simple leur
            permettant de s’opposer aux Traitements concernés, en ce compris, le
            cas échéant, leur permettant de s’opposer à la Publicité Ciblée.{" "}
            <br />
            S’il exploite des actifs numériques (comprenant par exemple des
            channels web, landing pages, kit emails, tag de tracking, supports
            de diffusions, applications etc…) par le biais desquels Influens
            Network collecte ou traite des Données à Caractère Personnel pour
            son propre compte et/ou pour le compte de ses Publishers,
            l’annonceur a mis en place, et est en mesure d’en apporter la
            preuve, des mécanismes techniques permettant à Influens Network
            d’obtenir les consentements nécessaires auxdites collectes de
            Données à Caractère Personnel, selon les moyens et pour les
            finalités prévus au sein du Contrat avec Influens Network ; et
            qu’est intégré un lien clair et non ambigu vers un mécanisme simple
            permettant aux Personnes Concernées de s’opposer aux Traitements
            concernés, en ce compris, le cas échéant, leur permettant de
            s’opposer à la Publicité Ciblée. <br />
            L’annonceur confirme que s’il facilite la fourniture, à Influens
            Network (pour son propre compte et/ou pour le compte de ses
            Publishers), de Données à Caractère Personnel provenant d’actifs
            numériques exploités par des tiers, il devra avoir mis en place des
            obligations juridiquement contraignantes à la charge de ces tiers,
            les obligeant à obtenir tout consentement nécessaire et lui
            permettant d’en fournir la preuve à Influens Network, lesdits
            consentements devant couvrir les moyens et finalités correspondant
            aux utilisations des Données à Caractère Personnel par Influens
            Network, tels que prévus au sein du Contrat. Dans ce cadre,
            l’annonceur sera chargé de fournir à tout tiers les informations
            pertinentes contenues dans le Contrat et/ou autrement fournies par
            Influens Network par écrit.
          </li>
          <li>
            Sous-traitance ultérieure
            <br />
            Influens Network autorise l’annonceur à avoir recours à des
            Sous-traitants ultérieurs aux conditions prévues par cette section
            et par l’Article 28 du RGPD. L’annonceur peut continuer à avoir
            recours aux Sous-traitants ultérieurs avec lesquels il est en
            contrat à la date des présentes CGV, sous réserve, pour chaque cas,
            qu’il se conforme dès que possible aux obligations prévues par cette
            section et par l’Article 28 du RGPD. <br />
            L’annonceur devra informer Influens Network par écrit préalablement
            au recours à tout nouveau Sous-traitant ultérieur, cette information
            devant inclure les détails complets relatifs aux opérations de
            Traitement devant être assurées par le Sous-traitant ultérieur.
            <br />A l’égard de chaque Sous-traitant ultérieur, l’annonceur devra
            :
            <ol>
              <li>
                avant que le Sous-traitant ultérieur ne Traite pour la première
                fois des Données à Caractère Personnel de Influens Network,
                procéder à toute vérification utile afin de s’assurer que le
                Sous-traitant ultérieur est en mesure de fournir le niveau de
                protection des Données à Caractère Personnel de Influens Network
                tel que requis par la Loi Applicable et le Contrat.
              </li>
              <li>
                veiller à ce que le Sous-traitant ultérieur signe un contrat
                écrit comprenant des stipulations offrant au minimum le même
                niveau de protection des Données à Caractère Personnel de
                Influens Network que celui prévu par les présentes CGV, et que
                ce contrat soit conforme à la Loi Applicable.
              </li>
              <li>
                ur demande raisonnable, fournir à Influens Network, pour sa
                revue, les copies des contrats conclus avec les Sous-traitants
                ultérieurs (étant entendu que les passages contenant des
                informations confidentielles et non pertinentes pour le respect
                des conditions posées par les présentes CGV pourront être
                masqués), dont Influens Network pourra occasionnellement
                demander la communication, ce de manière raisonnable.
              </li>
              <li>
                assumer la responsabilité pour les actes et omissions des Sous
                -traitants ultérieurs dans la même mesure que si l’annonceur
                avait directement fourni les services pris en charge par ceux-ci
                et était responsable à ce titre, aux conditions prévues par les
                présentes CGV.
              </li>
            </ol>
          </li>
          <li>
            Droit d’information des personnes concernées <br />
            L’annonceur, lorsqu’il collecte des données, devra s’assurer que les
            personnes concernées par les opérations de traitement ont bien reçu
            l’information relative aux traitements de données réalisés par
            l’annonceur pour le compte de Influens Network ou de ses publishers.
          </li>
          <li>
            Exercice des droits des personnes <br />
            Dans la mesure du possible, l’annonceur devra aider Influens Network
            à s’acquitter de son obligation de donner suite aux demandes
            d’exercice des droits des personnes concernées : droit d’accès, de
            rectification, d’effacement et d’opposition, droit à la limitation
            du traitement, droit à la portabilité des données, droit de ne pas
            faire l’objet d’une décision individuelle automatisée (y compris le
            profilage). <br />A ce titre, il est convenu que l’annonceur devra
            répondre, au nom et pour le compte de Influens Network et dans les
            délais prévus par le RGPD aux demandes des personnes concernées en
            cas d’exercice de leurs droits, s’agissant des données faisant
            l’objet de la sous-traitance prévue par le présent Contrat. <br />
            En outre, l’annonceur s’engage à être disposé à démontrer la
            conformité des activités de traitement avec le RGPD, y compris
            l’efficacité des mesures. Ces mesures tiennent compte de la nature,
            de la portée, du contexte et des finalités du traitement ainsi que
            du risque que celui-ci présente pour les droits et libertés des
            personnes physiques.
          </li>
          <li>
            Notification des violations de données à caractère personnel <br />
            L’annonceur notifiera, par écrit, à Influens Network toute violation
            de données à caractère personnel sans délai après en avoir pris
            connaissance. Cette notification sera accompagnée de toute
            documentation utile afin de permettre à Influens Network, si
            nécessaire, de notifier cette violation au responsable du traitement
            ou à l’autorité de contrôle compétente. <br />
            En particulier, l’annonceur prendra :
            <ol>
              <li>
                promptement toute mesure corrective appropriée afin de remédier
                aux causes de la violation de Données à Caractère Personnel, et
                fera tous ses efforts raisonnables afin de veiller à ce que de
                telles violations de Données à Caractère Personnel ne se
                reproduisent pas
              </li>
              <li>
                toute mesure imposée par la Loi Applicable et/ou raisonnablement
                demandée par Influens Network.
              </li>
            </ol>
          </li>
          <li>
            Aide de l’annonceur dans le cadre du respect par Influens Network de
            ses obligations L’annonceur aidera Influens Network pour la
            réalisation d’analyses d’impact relative à la protection des
            données, ainsi que pour la réalisation de la consultation préalable
            de l’autorité de contrôle.
          </li>
          <li>
            Mesures de sécurité <br />
            L’annonceur s’engage à prendre toutes mesures de sécurité, notamment
            techniques, matérielles et organisationnelles de protection
            appropriées afin d’assurer la confidentialité, la conservation et
            l’intégrité des Données à Caractère Personnel de Influens Network
            traitées pendant la durée des présentes et ce, jusqu’à leur complète
            suppression ou leur restitution à Influens Network, ces mesures sont
            notamment les suivantes :
            <ul>
              <li>
                Prendre toutes mesures de sécurité appropriées pour tous
                échanges de données ou de fichiers relatifs aux Données à
                Caractère Personnel de Influens Network réalisés non seulement
                entre Influens Network et l’annonceur mais aussi avec des tiers
                autorisés et, notamment, utiliser des canaux de communication
                sécurisés et respecter les Mesures de Sécurité préconisées par
                l’état de l’art et les textes et règlements applicables.
              </li>
              <li>
                Conserver et archiver les données ou les fichiers informatiques
                relatifs aux Données à Caractère Personnel de Influens Network
                pendant la durée nécessaire exclusivement à la réalisation des
                Services objets des présentes et ce, conformément aux
                instructions communiquées par Influens Network.
              </li>
              <li>
                Procéder à toute destruction, rectification, limitation ou, sur
                demande de Influens Network, restitution intégrale des données,
                fichiers informatiques et éventuelles copies relatifs aux
                Données à Caractère Personnel de Influens Network au plus tard,
                à l’expiration des présentes, et fournir, le cas échéant et si
                Influens Network le demande, l’attestation de destruction et/ou
                d’effacement définitif correspondante.
              </li>
              <li>
                Restreindre l’accès aux informations et Données à Caractère
                Personnel de Influens Network confiées au seul personnel
                habilité à intervenir sur ces données de par ses fonctions à
                l’exclusion de toute autre personne et leur assurer une
                formation appropriée en matière de protection des Données à
                Caractère Personnel.
              </li>
              <li>
                Supprimer ou restituer à Influens Network les Données à
                Caractère Personnel de Influens Network à l’issue de la durée de
                conservation indiquée aux présentes, et en tout état de cause, à
                la cessation des présentes.
              </li>
              <li>
                Informer sans délai Influens Network de tout événement, incident
                ou faille de sécurité, volontaire ou accidentel, relatif à la
                confidentialité, l’intégrité et à la sécurité des Données à
                Caractère Personnel de Influens Network, et notamment, toute
                atteinte, perte, vol, accès non autorisé, divulgation,
                destruction, altération des Données à Caractère Personnel de
                Influens Network et à fournir son concours à toute demande de
                collaboration de Influens Network.
              </li>
              <li>
                Ne transférer aucune Donnée à Caractère Personnel de Influens
                Network vers un pays tiers n’offrant pas de garantie de
                protection des données personnelles adéquate au sens de la
                directive 95/46/CE, sans en avoir préalablement informé Influens
                Network et sans avoir mis en place des mécanismes contractuels
                et des processus juridiques contraignants (sur le modèle des
                Clauses Types de la Commission Européenne) pour légalement
                transférer ces Données au-delà des frontières de l’Union
                Européenne.
              </li>
            </ul>
          </li>
          <li>
            Sort des données
            <br />
            Au terme de la prestation de services relatifs au traitement de ces
            données, l’annonceur s’engage, selon le choix de Influens Network,
            tel qu’exprimé par écrit dans un délai de (8) huit jours, à
            supprimer toutes les Données à Caractère Personnel ou les renvoyer à
            Influens Network au terme de la relation contractuelle, et à
            détruire les copies existantes.
          </li>
          <li>
            Délégué à la protection des données (DPO) <br />
            L’annonceur communiquera à Influens Network le nom et les
            coordonnées de son délégué à la protection des données, ou à tout le
            moins, de la personne en charge des questions relatives à la
            protection des Données à Caractère Personnel.
          </li>
          <li>
            Registre des catégories d’activités de traitement
            <br />
            L’annonceur déclare tenir par écrit un registre de toutes les
            catégories d’activités de traitement effectuées pour le compte de
            Influens Network ou de ses publishers, et conforme aux exigences du
            RGPD.
          </li>
          <li>
            Documentation
            <br />
            L’annonceur mettra à la disposition de Influens Network la
            documentation nécessaire pour démontrer le respect de toutes ses
            obligations et pour permettre la réalisation d’audits, y compris des
            inspections, par Influens Network ou un autre auditeur qu’il a
            mandaté, et contribuer à ces audits.
          </li>
          <li>
            Politique de confidentialité
            <br />
            Si l’annonceur exploite des actifs numériques par le biais desquels
            il collecte et fournit à Influens Network des Données à Caractère
            Personnel, l’annonceur s’engage à disposer d’une politique de
            confidentialité conforme aux Lois Applicables. A chaque fois que
            cela est possible, l’annonceur mentionnera que Influens Network est
            un destinataire des Données à Caractère Personnel collectées par le
            biais de ses actifs numériques, et/ou que Influens Network réalise
            directement la collecte de ces Données à Caractère Personnel. <br />
            Si l’annonceur facilite la fourniture, à Influens Network, de
            Données à Caractère Personnel provenant d’actifs numériques
            exploités par des tiers, l’annonceur imposera à ses cocontractants
            de veiller à ce que chaque actif numérique concerné dispose d’une
            politique de confidentialité conforme aux Lois Applicables. A chaque
            fois que cela est possible, l’annonceur veillera à ce que ces tiers
            mentionnent que Influens Network est un destinataire des Données à
            Caractère Personnel collectées par le biais des actifs numériques de
            tiers, et/ou que Influens Network réalise directement la collecte de
            ces Données à Caractère Personnel.
            <br />
            Si l’annonceur Traite des Données à Caractère Personnel de Influens
            Network, il s’engage à disposer d’une politique de confidentialité
            accessible et à jour, expliquant les technologies utilisées et la
            manière dont il traite les Données à Caractère Personnel.
          </li>
        </ol>
        <Typography variant="h6" className={classes.title}>
          23. Confidentialité
        </Typography>
        <ol star="129">
          <li>
            Dans le cadre des présentes conditions générales, l’ensemble des
            informations est confidentiel et recouvre toutes informations ou
            toutes données communiquées par les parties par écrit ou oralement.
          </li>
          <li>
            Les parties s’engagent naturellement à :
            <ul>
              <li>
                traiter les informations confidentielles avec le même degré de
                protection qu’elles accordent à leurs propres informations
                confidentielles de même importance.
              </li>
              <li>
                garder les informations confidentielles et qu’elles ne soient
                pas divulguées ni susceptibles de l’être directement ou
                indirectement à tout tiers.
              </li>
              <li>
                ne pas porter atteinte, en aucune façon, aux droits de propriété
                portant sur les informations confidentielles.
              </li>
              <li>
                éviter que les informations confidentielles ne soient ni
                copiées, ni reproduites, ni dupliquées, en partie ou en
                totalité, lorsque de telles copies, reproductions ou
                duplications ne sont pas directement liées à l’exécution des
                présentes.
              </li>
            </ul>
          </li>
        </ol>
        <Typography variant="h6" className={classes.title}>
          25. Suspension
        </Typography>
        <ol start="133">
          <li>
            En cas de non-respect de ses obligations par l’annonceur, Influens
            Network se réserve le droit de suspendre de plein droit et sans
            préavis le programme d’affiliation de ce dernier. La suspension sera
            d’une durée indéterminée et prendra fin, après notification par
            l’annonceur à Influens Network par écrit de sa mise en conformité au
            regard des obligations qui lui incombent.
          </li>
          <li>
            Ce droit de suspension pourra notamment être mis en œuvre par
            Influens Network, sans que cette liste soit limitative :
            <ul>
              <li>
                en cas de non-paiement des factures à échéance par l’annonceur.
              </li>
              <li>
                en cas de survenance d’une difficulté technique rendant le
                programme d’affiliation inopérant.
              </li>
              <li>
                en cas d’utilisation du programme d’affiliation à des fins
                frauduleuses par l’annonceur.
              </li>
              <li>
                en cas de violation de la réglementation en vigueur par le
                programme d’affiliation choisi par l’annonceur.
              </li>
              <li>
                en cas de performance insuffisante du programme d’affiliation.
              </li>
              <li>
                dans les autres cas prévus aux présentes conditions générales.
              </li>
            </ul>
          </li>
        </ol>
        <Typography variant="h6" className={classes.title}>
          26. Résiliation-résolution
        </Typography>
        <Typography className={classes.sousTitle}>
          26.1 Résiliation pour absence de trafic
        </Typography>
        <ol start="135">
          <li>
            Dans l’hypothèse où le programme d’affiliation mis en place par
            l’annonceur ne génère aucun trafic sur son channel pendant une durée
            de six (6) mois à compter de la mise en place du programme
            d’affiliation, les conditions générales seront automatiquement
            résiliées, sans aucune formalité sur la base des seules données
            conservées par Influens Network.
          </li>
        </ol>
        <Typography className={classes.sousTitle}>
          26.2 Résiliation-résolution pour manquement
        </Typography>
        <ol start="136">
          <li>
            En cas de manquement par l’une des parties aux obligations des
            présentes conditions générales non réparé dans un délai de 8 (huit)
            jours à compter de sa notification par écrit, l’autre partie pourra
            prononcer de plein droit la résiliation ou la résolution des
            conditions générales sans préjudice de tous dommages et intérêts
            auxquels elle pourrait prétendre en vertu des présentes.
          </li>
        </ol>
        <Typography className={classes.sousTitle}>
          26.3 Résiliation-résolution par l’annonceur
        </Typography>
        <ol start="137">
          <li>
            L’annonceur pourra prononcer de plein droit la résiliation ou la
            résolution des conditions générales sans préjudice de tous dommages
            et intérêts auxquels il pourrait prétendre en vertu des présentes,
            en cas de :
            <ul>
              <li>
                d’épuisement du budget prévisionnel du programme d’affiliation
                fixé préalablement entre les parties.
              </li>
              <li>
                d’interruption du service pendant plus de deux jours ouvrés
                d’affilée.
              </li>
            </ul>
          </li>
        </ol>

        <Typography className={classes.sousTitle}>
          26.4 Résiliation-résolution par Influens Network
        </Typography>
        <ol start="138">
          <li>
            Influens Network pourra prononcer de plein droit la résiliation ou
            la résolution des conditions générales sans préjudice de tous
            dommages et intérêts auxquels elle pourrait prétendre en vertu des
            présentes en cas de :
            <ul>
              <li>de non-paiement des factures à échéance par l’annonceur.</li>
              <li>
                de survenance d’une difficulté technique rendant le programme
                d’affiliation inopérant
              </li>
              <li>
                d’utilisation du programme d’affiliation à des fins frauduleuses
                par l’annonceur.
              </li>
              <li>
                de violation de la réglementation en vigueur par le programme
                d’affiliation choisi par l’annonceur.
              </li>
              <li>
                de performance insuffisante du programme d’affiliation pendant,
                au moins, trois (3) mois consécutifs.
              </li>
            </ul>
          </li>
        </ol>
        <Typography variant="h6" className={classes.title}>
          27. Conséquence de la rupture des conditions générales sur les
          programmes d’affiliation
        </Typography>
        <Typography>
          Si les présentes conditions générales sont dénoncées ou résiliées :
          Par l’une des parties en l’absence de manquement, les programmes
          d’affiliation de l’annonceur en cours d’exécution prendront fin dans
          un délai de 30 jours à compter de la notification qui aura été faite
          par la partie la plus diligente par tous moyens. En raison d’un ou de
          plusieurs manquements de l’annonceur, les programmes d’affiliation de
          l’annonceur en cours d’exécution, devront prendre fin au plus tard au
          jour de la notification par écrit à l’autre partie du manquement en
          cause et au plus tard dans les deux jours. Dans les deux cas, les
          sommes dues par l’annonceur à Influens Network au titre de ces
          programmes seront déterminées à la date de fin d’exécution de ces
          dernières.
        </Typography>
        <ol start="139">
          <li>
            En cas de rupture, dénonciation ou résiliation des présentes
            conditions générales pour quelques raisons que ce soit, les noms des
            affiliés seront automatiquement masqués sur la Plateforme, dès
            réception de la lettre ou du mail de résiliation.
          </li>
          <li>
            Au jour effectif de la résiliation des présentes conditions
            générales augmenté le cas échéant du délai de validation, il sera
            procédé à une reddition de compte. S’il apparaît que le compte
            annonceur est débiteur, ce dernier doit, sans délai, régulariser sa
            situation et verser les sommes dues à Influens Network.
          </li>
        </ol>
        <Typography variant="h6" className={classes.title}>
          27. Responsabilité
        </Typography>
        <ol start="141">
          <li>
            Chaque partie est responsable de ses agissements, actes,
            engagements, produits ou services, ainsi que des obligations dont
            elle a la charge au titre des présentes conditions générales.
          </li>
        </ol>

        <Typography className={classes.sousTitle}>
          27.1 Responsabilité de Influens Network
        </Typography>
        <ol start="42">
          <li>
            Influens Network est seule responsable de la qualité des prestations
            de services qu’elle fournit dans le cadre des programmes
            d’affiliation.
          </li>
          <li>
            Influens Network est seule responsable de l’accès de l’annonceur aux
            données présentes sur la Plateforme d’affiliation.
          </li>
          <li>
            Influens Network ne saurait être tenue responsable des agissements
            de l’affilié participant au programme d’affiliation de l’annonceur
            ni des contenus présents sur le channel de ce dernier.
          </li>
          <li>
            Influens Network ne saurait être tenue responsable des rendements du
            programme d’affiliation de l’annonceur.
          </li>
        </ol>
        <Typography className={classes.sousTitle}>
          27.2 Responsabilité de l’Annonceur
        </Typography>
        <ol star="146">
          <li>
            L’annonceur reconnaît que, Influens Network intervenant comme simple
            intermédiaire technique, il est seul responsable de son channel et
            de son contenu et de celui de son programme d’affiliation.
          </li>
          <li>
            Il demeurera seul responsable des relations contractuelles et
            commerciales qui seront liées directement avec les clients qui
            passeront commande auprès de lui d’un ou de plusieurs de ses
            produits et/ou d’un ou de ses services.
          </li>
        </ol>

        <Typography variant="h6" className={classes.title}>
          28. Force majeure
        </Typography>
        <ol start="148">
          <li>
            Dans un premier temps, les cas de force majeure suspendront
            l’exécution des conditions générales.
          </li>
          <li>
            Si les cas de force majeure ont une durée d’existence supérieure à
            deux mois, les présentes conditions générales seront résiliées
            automatiquement, sauf accord contraire des parties.
          </li>
          <li>
            De façon expresse, sont considérés comme cas de force majeure ou cas
            fortuits, ceux habituellement retenus par la jurisprudence des cours
            et tribunaux français, ainsi que les événements suivants :<br />
            la guerre, l’émeute, l’incendie, les grèves internes ou externes,
            lock out, occupation des locaux de Influens Network, intempéries,
            tremblement de terre, inondation, dégât des eaux, restrictions
            légales ou gouvernementales, modifications légales ou réglementaires
            des formes de commercialisation, les accidents de toutes natures,
            épidémie, pandémie, maladie touchant plus de 10% du personnel de
            Influens Network dans un période de deux mois consécutifs, l’absence
            de fourniture d’énergie, l’arrêt partiel ou total du réseau Internet
            et, de manière plus générale, des réseaux de télécommunications
            privés ou publics, les blocage de routes et les impossibilités
            d’approvisionnement en fournitures et tout autre cas indépendant de
            la volonté expresse des parties empêchant l’exécution normale des
            présentes conditions générales.
          </li>
        </ol>
        <Typography variant="h6" className={classes.title}>
          29. Bonne foi
        </Typography>
        <ol start="151">
          <li>
            Les parties conviennent d’exécuter leurs obligations avec une
            parfaite bonne foi.
          </li>
        </ol>
        <Typography variant="h6" className={classes.title}>
          30. Autorisations légales
        </Typography>
        <ol start="152">
          <li>
            Les parties s’engagent à disposer de l’ensemble des autorisations
            légales et administratives nécessaires à l’exécution des présentes.
          </li>
          <li>
            Les parties s’engagent à collaborer pour la réalisation de nouvelles
            autorisations légales et administratives et pour toutes
            modifications d’autorisations déjà réalisées.
          </li>
        </ol>
        <Typography variant="h6" className={classes.title}>
          31. Évolutions légales
        </Typography>
        <ol start="154">
          <li>
            Les présentes conditions générales pourront être modifiées par voie
            d’avenant écrit, signé par les représentants de Influens Network et
            de l’annonceur habilités à cet effet, afin de prendre en compte
            toute évolution légale ayant des conséquences sur l’objet des
            présentes conditions générales.
          </li>
        </ol>
        <Typography variant="h6" className={classes.title}>
          32. Tolérance
        </Typography>
        <ol start="155">
          <li>
            Les parties conviennent réciproquement que le fait pour l’une des
            parties de tolérer une situation n’a pas pour effet d’accorder à
            l’autre partie des droits acquis.
          </li>
          <li>
            De plus, une telle tolérance ne peut être interprétée comme une
            renonciation à faire valoir les droits en cause.
          </li>
        </ol>

        <Typography variant="h6" className={classes.title}>
          33. Sincérité
        </Typography>
        <ol start="157">
          <li>Les parties déclarent sincères les présents engagements.</li>
          <li>
            A ce titre, elles déclarent ne disposer d’aucun élément à leur
            connaissance qui, s’il avait été communiqué, aurait modifié le
            consentement de l’autre partie.
          </li>
        </ol>
        <Typography variant="h6" className={classes.title}>
          34. Indépendance
        </Typography>
        <ol start="159">
          <li>
            Les parties reconnaissent agir chacune pour leur propre compte comme
            des commerçants indépendants et ne seront pas considérés agent l’une
            de l’autre.
          </li>
          <li>
            Les présentes conditions générales ne constituent ni une
            association, ni une franchise, ni un mandat donné par l’une des
            parties à l’autre partie.
          </li>
          <li>
            En outre, chacune des parties demeure seule responsable de ses
            actes, allégations, engagements, prestations, produits et
            personnels.
          </li>
        </ol>
        <Typography variant="h6" className={classes.title}>
          35. Cession des conditions générales
        </Typography>
        <ol start="162">
          <li>
            Les présentes conditions générales ne pourront faire l’objet d’une
            cession totale ou partielle, à titre onéreux ou gracieux, par l’une
            des parties, sans l’accord écrit et préalable de l’autre partie.
          </li>
        </ol>
        <Typography variant="h6" className={classes.title}>
          36. Avenant
        </Typography>
        <ol start="163">
          <li>
            Les présentes conditions générales ne pourront être modifiées que
            par voie d’avenant écrit, signé par les représentants de Influens
            Network et de l’annonceur habilités à cet effet.
          </li>
          <li>
            Cet avenant, après signature par les représentants de Influens
            Network et de l’annonceur, prévaudra sur les dispositions des
            présentes conditions générales.
          </li>
        </ol>
        <Typography variant="h6" className={classes.title}>
          37. Titres
        </Typography>
        <ol start="165">
          <li>
            En cas de difficultés d’interprétation résultant d’une contradiction
            entre l’un quelconque des titres figurant en tête des clauses et
            l’une quelconque des clauses, les titres seront déclarés
            inexistants.
          </li>
        </ol>
        <Typography variant="h6" className={classes.title}>
          38. Nullité
        </Typography>
        <ol start="166">
          <li>
            Si une ou plusieurs stipulations des présentes conditions générales
            sont tenues pour non valides ou déclarées comme telles en
            application d’une loi, d’un règlement ou à la suite d’une décision
            passée en force de chose jugée d’une juridiction compétente, les
            autres stipulations garderont toute leur force et leur porté.
          </li>
        </ol>
        <Typography variant="h6" className={classes.title}>
          39. Intégralité
        </Typography>
        <ol start="167">
          <li>
            Les présentes conditions générales expriment l’intégralité des
            obligations des parties.
          </li>
        </ol>

        <Typography variant="h6" className={classes.title}>
          40. Prescription
        </Typography>
        <ol start="168">
          <li>
            Toutes les actions judiciaires entre les parties sont prescrites,
            sauf dispositions contraires d’ordre public et sauf délai plus court
            prévu aux présentes, si elles n’ont été introduites dans un délai de
            deux ans à compter de la première réclamation notifiée par lettre
            recommandée avec avis de réception.
          </li>
        </ol>
        <Typography variant="h6" className={classes.title}>
          41. Survivance
        </Typography>
        <ol start="169">
          <li>
            Les clauses déclarées comme survivantes après la fin des conditions
            générales, quelles que soient les modalités de cessation, telles
            qu’arrivée du terme ou rupture contractuelle, continuent à
            s’appliquer jusqu’au terme de leur objet particulier. Il en est
            ainsi, notamment, des clauses de propriété et de confidentialité.
          </li>
        </ol>

        <Typography variant="h6" className={classes.title}>
          42. Domiciliation
        </Typography>
        <ol start="170">
          <li>
            Pour l’exécution de la présente convention et sauf dispositions
            particulières, les parties conviennent de s’adresser toute
            correspondance à leur siège social respectif.
          </li>
        </ol>
        <Typography variant="h6" className={classes.title}>
          43. Loi
        </Typography>
        <ol start="171">
          <li>
            Les présentes conditions générales sont régies par la loi française.
          </li>
          <li>
            Il en est ainsi pour les règles de fond et les règles de forme et
            ce, nonobstant les lieux d’exécution des obligations substantielles
            ou accessoires.
          </li>
        </ol>
        <Typography variant="h6" className={classes.title}>
          44. Juridiction
        </Typography>
        <ol start="173">
          <li>
            Les présentes conditions générales sont régies par la loi française.
          </li>
          <li>
            En cas de litige et à défaut de solution amiable, compétence
            expresse est attribuée au tribunal de commerce de Paris - France,
            nonobstant pluralité de défendeurs ou appel en garantie, même pour
            les procédures d’urgence ou pour les procédures conservatoires, en
            référé ou par requête.
          </li>
        </ol>
      </div>
    </DialogContent>
  );
};
export default TermsOfSales;
