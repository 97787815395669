import React, { useState, useEffect } from "react";
import { Grid, Box, Typography, Select, MenuItem, Collapse, List, ListItem } from "@material-ui/core";
import { makeStyles, withStyles } from "@material-ui/styles";
import PeopleIcon from '@material-ui/icons/People';
import PowerSettingsNewIcon from '@material-ui/icons/PowerSettingsNew';
import TimelineIcon from "@material-ui/icons/Timeline";
//import DescriptionOutlinedIcon from "@material-ui/icons/DescriptionOutlined";
import { NavLink, useHistory, useLocation, useRouteMatch } from "react-router-dom";
import { useTranslation } from "react-i18next";
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import ExpandLess from '@material-ui/icons/ExpandLess';
import ExpandMore from '@material-ui/icons/ExpandMore';
import CategoryIcon from '@material-ui/icons/Category';
import clsx from "clsx";

const useStyles = makeStyles(theme => {
  const drawerWidth = 280;
  return {
    drawer: {
      width: drawerWidth,
      flexShrink: 0,
      height: '100%',
      borderStyle: 'none'
    },
    drawerPaper: {
      width: drawerWidth,
      justifyContent: 'flex-start',
      borderStyle: 'none !important'
    },
    sideBarContent: {
      display: 'flex',
      flexDirection: 'column'
    },
    bannerContainer: {
      height: '160px',
    },
    logoBoxContent: {
      display: 'flex',
      alignItems: 'center',
      width: "50%",
      backgroundColor: "white",
      "& img": {
        maxWidth: "100%",
        maxHeight: "100%",
      },
    },
    navigationElement: {
      marginTop: '10px',
      marginBottom: '10px'
    },
    navigationLink: {
      display: 'flex',
      width: '100%',
      color: 'black',
      textDecoration: 'none',
      paddingLeft: '55px',
      paddingTop: '15px',
      paddingBottom: '15px',
      fontSize: '16px',
      '&:hover': {
        backgroundColor: '#fb689f17',
        color: '#ff005f'
      },
      borderTopRightRadius: '40px',
      borderBottomRightRadius: '40px',
      cursor: 'pointer'
    },
    navigationActiveLink: {
      color: '#ff005f',
      backgroundColor: '#fb689f40',
      paddingTop: '15px',
      paddingBottom: '15px',
      fontSize: '16px',
      borderTopRightRadius: '40px',
      borderBottomRightRadius: '40px',
    },
    drawerMenu: {
      height: '100%'
    },
    navigationSpacement: {
      marginLeft: '10px !important',
      fontFamily: 'Poppins !important',
    },
    selectClass: {
      backgroundColor: 'transparent',
      fontFamily: 'Poppins',
      fontSize: '16px',
      '&:focus': {
        backgroundColor: 'transparent',
      },
    },
    subItemContainer: {

    },
    subItem: {
      display: 'flex',
      width: '100%',
      color: 'black',
      textDecoration: 'none',
      paddingLeft: '80px',
      paddingTop: '15px',
      paddingBottom: '15px',
      fontSize: '16px',
      '&:hover': {
        color: '#ff005f'
      },
      borderTopRightRadius: '40px',
      borderBottomRightRadius: '40px',
      cursor: 'pointer'
    },
    activeSubItem: {
      color: '#ff005f',
      display: 'flex',
      width: '100%',
      textDecoration: 'none',
      paddingLeft: '80px',
      paddingTop: '15px',
      paddingBottom: '15px',
      fontSize: '16px',
      '&:hover': {
        color: '#ff005f'
      },
      borderTopRightRadius: '40px',
      borderBottomRightRadius: '40px',
      cursor: 'pointer'
    }
  };
});

const iconStyles = {
  selectIcon: {
    color: "black"
  }
};
const CustomExpandMore = withStyles(iconStyles)(
  ({ className, classes, ...rest }) => {
    return (
      <ExpandMoreIcon
        {...rest}
        className={clsx(className, classes.selectIcon)}
      />
    );
  }
);

const SideBar = (props) => {
  const { t, i18n } = useTranslation('common');
  const { url } = useRouteMatch();
  const location = useLocation();
  const history = useHistory();
  const classes = useStyles();
  const [mobileMode, setMobileMode] = useState(false);
  const [lang, setLang] = useState(i18n.language);
  const [openList, setOpenList] = useState((location.pathname) === '/admin/partners' ? true : false);
  const [partnerTypeToLoad, setPartnerTypeToLoad] = useState('brands');

  useEffect(() => {
    setMobileMode(props.mobileMode);
  }, [props.mobileMode]);

  useEffect(() => {
    i18n.changeLanguage(lang);
  }, [lang, i18n]);

  useEffect(() => {
    setLang((i18n.language !== 'fr') ? 'en' : 'fr');
  }, [i18n.language]);

  const handleDrawerEvent = () => {
    props.navEvent();
  }

  const handleOpenList = () => {
    if (location.pathname === '/admin/partners') {
      setOpenList(!openList)
    } else {
      if (openList && location.pathname !== '/admin/partners') return;
      if (!openList && location.pathname !== '/admin/partners') setOpenList(true);
    }
  }

  const handleSubItem = (type) => {
    setPartnerTypeToLoad(type);
    props.partnerTypeToLoad(type);
    if (location.pathname !== '/admin/partners') {
      history.push('/admin/partners');
    }
  };

  return (
    <Grid className={classes.sideBarContent}>
      <Grid
        className={classes.bannerContainer}
        container
        justify='center'
        alignItems='center'
        direction='row'
      >
        <Box
          className={classes.logoBoxContent}>
          <img alt="logo" src="https://firebasestorage.googleapis.com/v0/b/shopmyinfluens.appspot.com/o/shopmyinfluens%2FApp%20Data%2Flogo_shopmyinfluence-01.png?alt=media" />
        </Box>
      </Grid>
      <Grid container item direction='row' className={classes.navigationElement}>
        <Grid container item xs={10} justify='center'>
          <NavLink
            to={`${url}/dashboard`}
            className={classes.navigationLink}
            activeClassName={classes.navigationActiveLink}
            onClick={handleDrawerEvent}>
            <PeopleIcon />
            <Typography className={classes.navigationSpacement}>{t('ADMIN.SIDEBAR.DASHBOARD_LINK')} </Typography>
          </NavLink>
        </Grid>
      </Grid>
      <Grid container item direction='row' className={classes.navigationElement}>
        <Grid container item xs={10} justify='center'>
          <NavLink
            to={`${url}/influencers`}
            className={classes.navigationLink}
            activeClassName={classes.navigationActiveLink}
            onClick={handleDrawerEvent}>
            <PeopleIcon />
            <Typography className={classes.navigationSpacement}>{t('ADMIN.SIDEBAR.INFLUENCERS_LINK')} </Typography>
          </NavLink>
        </Grid>
      </Grid>
      <Grid container item direction='row' className={classes.navigationElement}>
        <Grid container item xs={10} justify='center'>
          <NavLink
            to={`${url}/partners`}
            className={classes.navigationLink}
            activeClassName={classes.navigationActiveLink}
            onClick={handleOpenList}>
            <PeopleIcon />
            <Typography className={classes.navigationSpacement}>{t('ADMIN.SIDEBAR.PARTNERS_LINK')} </Typography>
            {openList ? <ExpandLess style={{ marginLeft: '30px' }} /> : <ExpandMore style={{ marginLeft: '30px' }} />}
          </NavLink>
        </Grid>
      </Grid>
      <Collapse in={openList} timeout="auto" unmountOnExit>
        <List component="div" disablePadding>
          <Grid container item direction='row' xs={8}>
            <ListItem className={(partnerTypeToLoad === 'brands' && location.pathname === '/admin/partners') ? classes.activeSubItem : classes.subItem} onClick={() => handleSubItem('brands')}>
              <Typography className={classes.navigationSpacement}>{t('ADMIN.SIDEBAR.PARTNERS_SUB_LINKS.BRANDS_LINK')} </Typography>
            </ListItem>
          </Grid>
          <Grid container item direction='row' xs={8}>
            <ListItem className={(partnerTypeToLoad === 'agencies' && location.pathname === '/admin/partners') ? classes.activeSubItem : classes.subItem} onClick={() => handleSubItem('agencies')}>
              <Typography className={classes.navigationSpacement}>{t('ADMIN.SIDEBAR.PARTNERS_SUB_LINKS.AGENCIES_LINK')} </Typography>
            </ListItem>
          </Grid>
          <Grid container item direction='row' xs={8}>
            <ListItem className={(partnerTypeToLoad === 'agents' && location.pathname === '/admin/partners') ? classes.activeSubItem : classes.subItem} onClick={() => handleSubItem('agents')}>
              <Typography className={classes.navigationSpacement}>{t('ADMIN.SIDEBAR.PARTNERS_SUB_LINKS.AGENTS_LINK')} </Typography>
            </ListItem>
          </Grid>
          {/* <Grid container item direction='row' xs={8}>
            <ListItem className={(partnerTypeToLoad === 'distributors' && location.pathname === '/admin/partners') ? classes.activeSubItem :classes.subItem} onClick={() => handleSubItem('distributors')}>
              <Typography className={classes.navigationSpacement}>{t('ADMIN.SIDEBAR.PARTNERS_SUB_LINKS.DISTRIBUTORS_LINK')} </Typography>
            </ListItem>
          </Grid> */}
        </List>
      </Collapse>
      <Grid container item direction='row' className={classes.navigationElement}>
        <Grid container item xs={10} justify='center'>
          <NavLink
            to={`${url}/statistics`}
            className={classes.navigationLink}
            activeClassName={classes.navigationActiveLink}
            onClick={handleDrawerEvent}>
            <TimelineIcon />
            <Typography className={classes.navigationSpacement}>{t('ADMIN.SIDEBAR.STATISTICS_LINK')} </Typography>
          </NavLink>
        </Grid>
      </Grid>
      <Grid container item direction='row' className={classes.navigationElement}>
        <Grid container item xs={10} justify='center'>
          <NavLink
            to={`${url}/categories`}
            className={classes.navigationLink}
            activeClassName={classes.navigationActiveLink}
            onClick={handleDrawerEvent}>
            <CategoryIcon />
            <Typography className={classes.navigationSpacement}>{t('ADMIN.SIDEBAR.CATEGORIES_LINK')} </Typography>
          </NavLink>
        </Grid>
      </Grid>
      {/* <Grid container item direction='row' className={classes.navigationElement}>
        <Grid container item xs={10} justify='center'>
          <NavLink
            to={`${url}/factures`}
            className={classes.navigationLink}
            activeClassName={classes.navigationActiveLink}
            onClick={handleDrawerEvent}>
            <DescriptionOutlinedIcon />
            <Typography className={classes.navigationSpacement}>Bills</Typography>
          </NavLink>
        </Grid>
        </Grid>*/}
      <Grid container item direction='row' className={classes.navigationElement}>
        <Grid container item xs={10} justify='center'>
          <NavLink
            to={`${url}/logout`}
            className={classes.navigationLink}>
            <PowerSettingsNewIcon color='error' />
            <Typography className={classes.navigationSpacement} style={{ color: 'red' }}> {t('INFLUENCER_AGENT.SIDEBAR.LOGOUT_LINK')} </Typography>
          </NavLink>
        </Grid>
      </Grid>

      {
        mobileMode
          ? <Grid container item xs={12} direction='row-reverse' style={{ position: 'absolute', bottom: 5, right: 5 }}>
            <Select
              value={lang}
              disableUnderline
              onChange={(e) => setLang(e.target.value)}
              classes={{ root: classes.selectClass }}
              IconComponent={CustomExpandMore}>
              <MenuItem value='fr' style={{ fontFamily: 'Poppins' }}>FR</MenuItem>
              <MenuItem value='en' style={{ fontFamily: 'Poppins' }}>EN</MenuItem>
            </Select>
          </Grid>
          : <></>
      }
    </Grid>
  );
};

export default SideBar;
