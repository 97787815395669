import React from "react";
import { useRouteMatch, Route, Switch, Redirect } from "react-router-dom"
import { Embed, Wishlist } from "./components";

const Router = (props) => {
  const { url } = useRouteMatch();
  return (
    <Switch>
      <Route exact path={`${url}/wishlist/:uid/:wishlist`}>
        <Embed {...props} url={url} />
      </Route>
      <Route
        exact
        path={[
          `${url}/:name/:wishlist/:code33`,
          `${url}/:name/:wishlist`,
          `${url}/:name`,
        ]}
      >
        <Wishlist {...props} url={url} />
      </Route>
      <Redirect push to='/home' />
    </Switch>
  );
};
export default Router;
