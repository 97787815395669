var defaultState = {
  loading: false,
  brandsArray: [],
  error: null,
  loadedAllBrandAgent: false,
  allBrandAgent: [],
  loadedAllBrandAgentTovalidate: false,
  allBrandAgentTovalidate: [],
  loadingUpdateBrandAgent: false,
  loadingRefuseBrandAgent: false,
  loadingValidateBrandAgent: false,
};

const auth = (state = defaultState, action) => {
  switch (action.type) {
    case "FETCH_BRAND_OFFERID_COMPLETE":
      return {
        ...state,
        loading: true,
        brandsArray: state.brandsArray.concat(action.payload),
      };
    case "FETCH_BRAND_OFFERID_ERROR":
      return {
        ...state,
        loading: false,
        error: action.error,
      };
    case "FETCH_All_AGENCY":
      return {
        ...state,
        loadedAllBrandAgent: true,
        allBrandAgent: action.payload,
      };
    case "FETCH_ALL_AGENCY_ERROR":
      return {
        ...state,
        loadedAllBrandAgent: false,
        error: action.error,
      };
    case "FETCH_All_AGENCY_TO_VALIDATE":
      return {
        ...state,
        loadedAllBrandAgentTovalidate: true,
        allBrandAgentTovalidate: action.payload,
      };
    case "FETCH_All_AGENCY_TO_VALIDATE_ERROR":
      return {
        ...state,
        loadedAllBrandAgentTovalidate: false,
        error: action.error,
      };
    case "UPDATE_BRAND_AGENT_BY_ID_REQUEST":
      return {
        ...state,
        loadingUpdateBrandAgent: true,
      };
    case "UPDATE_BRAND_AGENT_BY_ID_SUCCESS": {
      let newBrandAgent = state.allBrandAgent.map((brand) => {
        if (brand.key === action.payload.id) {
          let updatedBrand = {
            ...brand,
          };
          Object.entries(action.payload.updates).forEach(
            ([keyToUpdate, newValue]) => {
              if (keyToUpdate.indexOf("/") !== -1) {
                let subObjectToUpdate = keyToUpdate.split("/");
                if (updatedBrand[subObjectToUpdate[0]] === undefined)
                  updatedBrand[subObjectToUpdate[0]] = {};
                updatedBrand[subObjectToUpdate[0]][
                  subObjectToUpdate[1]
                ] = newValue;
              } else {
                updatedBrand[keyToUpdate] = newValue;
              }
            }
          );
          return updatedBrand;
        } else {
          return brand;
        }
      });
      return {
        ...state,
        allBrandAgent: newBrandAgent,
        loadingUpdateBrandAgent: false,
      };
    }
    case "UPDATE_BRAND_AGENT_BY_ID_ERROR":
      return {
        ...state,
        errors: action.error,
        loadingUpdateBrand: false,
      };
    case "VALIDATE_BRAND_AGENT_BY_ID_REQUEST":
      return {
        ...state,
        loadingValidateBrandAgent: true,
      };
    case "VALIDATE_BRAND_AGENT_BY_ID_SUCCESS": {
      let newBrandAgent = state.allBrandAgent;
      newBrandAgent.push(action.payload.brandAgent);
      let newBrandAgentToValidate = state.allBrandAgentTovalidate;
      let indexToDelete = null;
      newBrandAgentToValidate.forEach((brandAgent, index) => {
        if (brandAgent.key === action.payload.unverifiedBrandAgentId)
          indexToDelete = index;
      });
      newBrandAgentToValidate.splice(indexToDelete, 1);
      return {
        ...state,
        allBrandAgent: newBrandAgent,
        allBrandAgentToValidate: newBrandAgentToValidate,
        loadingValidateBrandAgent: false,
      };
    }
    case "VALIDATE_BRAND_AGENT_BY_ID_ERROR":
      return {
        ...state,
        loadingValidateBrandAgent: false,
        error: action.error,
      };
    case "REFUSE_BRAND_AGENT_BY_ID_REQUEST":
      return {
        ...state,
        loadingRefuseBrandAgent: true,
      };
    case "REFUSE_BRAND_AGENT_BY_ID_SUCCESS": {
      let newBrandAgentToValidate = state.allBrandAgentTovalidate;
      let indexToDelete = null;
      newBrandAgentToValidate.forEach((brandAgent, index) => {
        if (brandAgent.key === action.payload.unverifiedBrandAgentId)
          indexToDelete = index;
      });
      newBrandAgentToValidate.splice(indexToDelete, 1);
      return {
        ...state,
        allBrandAgentToValidate: newBrandAgentToValidate,
        loadingRefuseBrandAgent: false,
      };
    }
    case "REFUSE_BRAND_AGENT_BY_ID_ERROR":
      return {
        ...state,
        loadingRefuseBrandAgent: false,
        error: action.error,
      };
    case "REMOVE_BRAND_BY_ID_REQUEST":
      return {
        ...state,
        loadingRemoveBrand: true,
      };
    case "CLEAR_BRAND_AGENT":
      return {
        ...state,
        loading: false,
        brandsArray: [],
        error: null,
        loadedAllBrandAgent: false,
        allBrandAgent: [],
        loadedAllBrandAgentTovalidate: false,
        allBrandAgentTovalidate: [],
        loadingUpdateBrandAgent: false,
        loadingRefuseBrandAgent: false,
        loadingValidateBrandAgent: false,
      };
    default:
      return state;
  }
};
export default auth;
