import { dbManager } from "../../components/managers"

export const getArticleByBrandEshop = () => {
    return (dispatch, getState) => {
        dbManager.getArticleByBrandEshop().then(brandEshop => {
            dispatch({ type: "GET_ARTICLE_BY_BRAND_ESHOP_SUCCESS", payload: { eshop: brandEshop } })
        }, error => {
            dispatch({ type: "GET_ARTICLE_BY_BRAND_ESHOP_ERROR", error });
        })
    }
}