import { dbManager, userManager } from "../../components/managers"

export const fetchAllInfluencerAgents = () => {
    return (dispatch, getState) => {
        dbManager.fetchAllInfluencerAgents().then(influencerAgents => {
            dispatch({ type: 'FETCH_ALL_INFLUENCER_AGENTS_SUCCESS', payload: { influencerAgents } });
        }, error => {
            dispatch({ type: 'FETCH_ALL_INFLUENCER_AGENTS_ERROR', error });
        });
    }
};

export const fetchInfluencerAgentsToValidate = () => {
    return (dispatch) => {
        dbManager
            .fetchInfluencerAgentsToValidate()
            .then((agentToValidate) => {
                dispatch({ type: "FETCH_INFLUENCER_AGENT_TO_VALIDATE", payload: { agentToValidate } });
            })
            .catch((error) => {
                dispatch({ type: "FETCH_INFLUENCER_AGENT_TO_VALIDATE_ERROR", error });
            });
    };
};

export const updateInfluencerAgentbyId = (influencerAgentData, updates, userData) => {
    return (dispatch, getState) => {
        dispatch({ type: 'UPDATE_INFLUENCER_AGENT_BY_ID_REQUEST' })
        dbManager.updateInfluencerAgentbyId(influencerAgentData.key, updates).then(() => {
            dbManager.updateInfluencerAgentUsers(userData, influencerAgentData.key, influencerAgentData.userId).then(() => {
                dispatch({ type: 'UPDATE_INFLUENCER_AGENT_BY_ID_SUCCESS', payload: { id: influencerAgentData.key, updates } });
            }).catch(error => {
                dispatch({ type: 'UPDATE_INFLUENCER_AGENT_BY_ID_ERROR', error });
            });
        }, error => {
            dispatch({ type: 'UPDATE_INFLUENCER_AGENT_BY_ID_ERROR', error });
        });
    }

};

export const updateClaimsInfluencerAgent = (influencerAgentData, updates) => {
    return (dispatch, getState) => {
        dispatch({ type: 'UPDATE_INFLUENCER_AGENT_CLAIMS_BY_ID_REQUEST' })
        dbManager.updateClaimsInfluencerAgent(influencerAgentData.key, updates).then(() => {
            dispatch({ type: 'UPDATE_INFLUENCER_AGENT_CLAIMS_BY_ID_SUCCESS', payload: { id: influencerAgentData.key, updates } });
        }, error => {
            dispatch({ type: 'UPDATE_INFLUENCER_AGENT_CLAIMS_BY_ID_ERROR', error });
        });
    }

};


export const validateInfluencerAgentById = (influencerId, influencerAgent, userData) => {
    return (dispatch, getState) => {
        userManager.addClaimWithValue(influencerId, 'isInfluencerAgent', true).then(result => {
            if (result.data.status === 'success') {
                dbManager.addNewInfluencerAgent(influencerAgent).then((influencerAgent) => {
                    dbManager.addNewInfluencerAgentUser(influencerId, influencerAgent, userData).then(() => {
                        dbManager.removeUnverifiedInfluencerAgent(influencerId).then(() => {
                            dispatch({ type: 'VALIDATE_INFLUENCER_AGENT_BY_ID_SUCCESS', payload: { unverifiedInfluencerAgentId: influencerId, influencerAgent } });
                        }, error => {
                            dispatch({ type: 'VALIDATE_INFLUENCER_AGENT_BY_ID_ERROR', error });
                        })
                    }, (error) => {
                        dispatch({ type: 'VALIDATE_INFLUENCER_AGENT_BY_ID_ERROR', error })
                    });
                }, (error) => {
                    dispatch({ type: 'VALIDATE_INFLUENCER_AGENT_BY_ID_ERROR', error });
                });

            } else {
                dispatch({ type: 'VALIDATE_INFLUENCER_AGENT_BY_ID_ERROR', error: 'Unable to set influencer Agent claim' });
            }
        });
    }
};

export const refuseInfluencerAgentById = (influencerAgentId) => {
    return (dispatch, getState) => {
        dispatch({ type: 'REFUSE_INFLUENCER_AGENT_BY_ID_REQUEST' });
        dbManager.removeUnverifiedInfluencerAgent(influencerAgentId).then(() => {
            dispatch({ type: 'REFUSE_INFLUENCER_AGENT_BY_ID_SUCCESS', payload: { unverifiedInfluencerAgentId: influencerAgentId } });
        }, error => {
            dispatch({ type: 'REFUSE_INFLUENCER_AGENT_BY_ID_ERROR', error });
        })
    }
};