import React, { useState, useEffect, useCallback } from "react";
import { connect } from "react-redux";
import {
  Grid,
  Typography,
  TextField,
  Select,
  Box,
  Button,
  MenuItem,
  useTheme,
  useMediaQuery,
  CircularProgress,
} from "@material-ui/core";
import BrandsTable from "../template/components/Statistics/brandsTable";
import BrandModal from "../template/components/Statistics/modals/BrandModal";
import ConversionsChart from "../template/components/ConversionsChart";
import TrendLoading from "../../ui/trendLoading/TrendLoading";
import ProductPieChart from "../template/components/ProductPieChart";
import { makeStyles } from "@material-ui/core";
import { MuiPickersUtilsProvider, DatePicker } from "@material-ui/pickers";
import ArrowRightAltIcon from "@material-ui/icons/ArrowRightAlt";
import ArrowDownwardIcon from "@material-ui/icons/ArrowDownward";
import EuroSymbolIcon from "@material-ui/icons/EuroSymbol";
import TouchAppIcon from "@material-ui/icons/TouchApp";
import BootstrapInput from "../template/assets/customComponents/BootstrapInput";
import DateFnsUtils from "@date-io/date-fns";
import { MyDate } from "../../utils";
import SVGIcon from "../template/assets/SVGIcons/index";
import { useTranslation } from "react-i18next";
import CountUp from "react-countup";
import placeholderSales from "../template/assets/image/placeholder_sales.png";
import { clicksManager, dbManager } from "../../managers";
import SearchIcon from "@material-ui/icons/Search";

const useInputStyles = makeStyles((theme) => ({
  root: {
    overflow: "hidden",
    borderRadius: 24,
    backgroundColor: "#fff",
    textAlign: "center",
    width: "170px",
    height: "40px",
    boxShadow: "0 7px 30px 0 rgba(88, 165, 211, 0.16)",
    "&:hover": {
      backgroundColor: "#fff",
    },
    "&$focused": {
      backgroundColor: "#fff",
      borderColor: "#ff005f",
    },
    margin: "5px",
  },
  input: {
    textAlign: "center",
    fontFamily: "Poppins",
    fontWeight: 500,
  },
  focused: {},
}));

const useStyles = makeStyles((theme) => {
  return {
    pageContent: {
      flexGrow: 1,
      display: "flex",
      flexDirection: "column",
      backgroundColor: "#eff9fe",
    },
    titles: {
      fontFamily: "Poppins",
      fontWeight: 500,
    },
    selectClass: {
      fontFamily: "Poppins",
      borderRadius: "25px",
      width: "130px",
      "&:focus": {
        borderRadius: "25px",
        backgroundColor: "#ffffff",
      },
      boxShadow: "0 7px 30px 0 rgba(88, 165, 211, 0.16)",
    },
    rowSpacing: {
      marginTop: "10px",
      marginBottom: "10px",
    },
    smallBox: {
      boxShadow: "0 7px 30px 0 rgba(58, 111, 142, 0.25)",
      backgroundColor: "#fff",
      borderRadius: "41px",
      width: "240px",
      height: "150px",
      display: "flex",
      justifyContent: "center",
      alignItems: "center",
      margin: 10,
    },
    smallBoxTitles: {
      fontFamily: "Poppins",
      fontSize: "16px",
      fontWeight: 500,
      overflow: "hidden",
      textOverflow: "ellipsis",
    },
    smallBoxInfos: {
      fontFamily: "Poppins",
      fontSize: "16px",
      fontWeight: 900,
      overflow: "hidden",
      textOverflow: "ellipsis",
    },
    activeButtonStyle: {
      width: 250,
      margin: "5px",
      color: "#ffffff",
      borderRadius: "35px",
      backgroundColor: "#3eadef",
      fontSize: "14px",
      textTransform: "none",
      fontFamily: "Poppins",
      boxShadow: "0 7px 30px 0 rgba(88, 165, 211, 0.16)",
      "&:hover": {
        backgroundColor: "#3eadef",
      },
    },
    buttonStyle: {
      width: 250,
      margin: "5px",
      color: "#000",
      borderRadius: "35px",
      backgroundColor: "#ffffff",
      fontSize: "14px",
      textTransform: "none",
      fontFamily: "Poppins",
      boxShadow: "0 7px 30px 0 rgba(88, 165, 211, 0.16)",
    },
    noInfluencerTextPlaceholder: {
      fontFamily: "Poppins",
      fontSize: "16px",
      margin: 10,
      color: "#1b4c61",
      textAlign: "center",
    },
    buttonDateStyle: {
      width: "170px",
      height: "40px",
      margin: "5px",
      overflow: "hidden",
      boxShadow: "0 7px 30px 0 rgb(88 165 211 / 16%)",
      textAlign: "center",
      textTransform: "none",
      borderRadius: "24px",
      padding: "10",
      backgroundColor: "#3eadef",
      "&:hover": {
        backgroundColor: "#3eadef",
      },
    },
    textButtonDate: {
      textAlign: "center",
      fontFamily: "Poppins",
      fontWeight: 500,
      color: "#fff",
    },
    pieBox: {
      display: "flex",
      justifyContent: "center",
      alignItems: "center",
      backgroundColor: "white",
      height: "300px",
      width: "95%",
      borderRadius: "20px",
      boxShadow: "0 7px 30px 0 rgba(88, 165, 211, 0.16)",
    },
    textPlaceholder: {
      fontFamily: "Poppins",
      fontSize: "16px",
      margin: 10,
      color: "#1b4c61",
      textAlign: "center",
    },
  };
});

const Statistics = (props) => {
  const { t } = useTranslation("common");
  const inputClasses = useInputStyles();
  const classes = useStyles();
  const theme = useTheme();
  const xsDown = useMediaQuery(theme.breakpoints.down("xs"));
  const mdDown = useMediaQuery(theme.breakpoints.down("md"));
  const { brandsInfos, allConversions, loadedAllConversions } = props;

  const [loading, setLoading] = useState(true);
  const [loadingChart, setLoadingChart] = useState(true);
  const [periodSelector, setPeriodSelector] = useState("today");
  const [startPeriodDate, setStartPeriodDate] = useState(new MyDate());
  const [endPeriodDate, setEndPeriodDate] = useState(new MyDate());
  const [brands, setBrands] = useState([]);
  const [filterValue, setFilterValue] = useState("");
  const [brandsToWork, setBrandsToWork] = useState([]);
  const [periodBrandConversions, setPeriodBrandConversions] = useState([]);
  const [conversionForChart, setConversionForChart] = useState([]);
  const [periodProducts, setPeriodProducts] = useState([]);
  const [brandDetails, setBrandDetails] = useState([]);
  const [salesPeriod, setSalesPeriod] = useState(0);
  const [salesNumberPeriod, setSalesNumberPeriod] = useState(0);
  const [clickPerInfluencer, setClickPerInfluencer] = useState(0);
  const [unPaidCommissionsPeriod, setUnpaidCommissionsPeriod] = useState(0);
  const [periodCommissionSMI, setPeriodCommissionSMI] = useState(0);
  const [sortEvent, setSortEvent] = useState(0);
  const [popupMode, setPopupMode] = useState(false);
  const [popupData, setPopupData] = useState({});

  const filterConversions = (startDate, endDate, conversions) => {
    const startTimestamp = Math.round(startDate.getTime() / 1000);
    const endTimestamp = Math.round(endDate.getTime() / 1000);
    return conversions.filter((element) => element.createdAt < endTimestamp && element.createdAt > startTimestamp).sort((a, b) =>
      a.createdAt > b.createdAt ? -1 : a.createdAt < b.createdAt ? 1 : 0
    );
  };

  const newFilterArticle = useCallback(async () => {
    let obj = {};
    conversionForChart.forEach((conversion) => {
      let found = false;
      Object.keys(obj).forEach((objConversionId) => {
        if (objConversionId === conversion.articleId) {
          obj[objConversionId].push(conversion);
          found = true;
        }
      });
      if (!found) obj[conversion.articleId] = [conversion];
    });
    let newArray = [];
    Object.values(obj).forEach((articleConversions) => {
      let amount = 0;
      let commissions = 0;
      let productPurchasers = [];
      let articleImgUrl = "";
      let articlePathUrl = "";
      let purchaseNumber = articleConversions.length;
      let articleId = "";
      articleConversions.forEach((conversion) => {
        let data;
        articleId = conversion.articleId;
        amount += conversion.amount;
        commissions += Number(conversion.commission);
        if (productPurchasers.indexOf(conversion.influencer) === -1)
          productPurchasers.push(conversion.influencer);
        if (articleImgUrl === "") {
          if (conversion.articleImgUrl === undefined) {
            dbManager.fetchSoldArticleById(conversion.articleId).then((res) => {
              data = res;
            }).then(() => {
              articleImgUrl = conversion.articleImgUrl === undefined ? data ? data.image : "" : conversion.articleImgUrl;
              articlePathUrl = conversion.articlePathUrl === undefined ? data ? data.image : "" : conversion.articlePathUrl;
            });
          }
          articleImgUrl = conversion.articleImgUrl === undefined ? data ? data.image : "" : conversion.articleImgUrl;
          articlePathUrl = conversion.articlePathUrl === undefined ? data ? data.image : "" : conversion.articlePathUrl;
        }
      });
      newArray.push({
        articleId,
        purchaseNumber,
        salesNumber: articleConversions.length,
        amount,
        commissions,
        productPurchasers,
        articleImgUrl,
        articlePathUrl,
      });
    });
    return newArray;
  }, [conversionForChart]);

  const filter = useCallback(async () => {
    let productsIds = [];
    await newFilterArticle().then((result) => {
      productsIds = result;
    });

    productsIds.sort((a, b) => {
      if (a.amount > b.amount) return -1;
      if (a.amount < b.amount) return 1;
      return 0;
    });
    setPeriodProducts(productsIds);
  }, [newFilterArticle]);

  const handlePeriodSelector = (event) => {
    setPeriodSelector(event.target.value);
  };

  const getPreviousMonday = () => {
    let date = new MyDate();
    let day = date.getDay();
    let prevMonday;
    if (date.getDay() === 0) {
      prevMonday = new MyDate();
      prevMonday.setDate(date.getDate() - 7);
      return prevMonday;
    } else {
      prevMonday = new MyDate();
      prevMonday.setDate(date.getDate() - day + 1);
      return prevMonday;
    }
  };

  const handleEndPeriodChange = (value) => {
    setEndPeriodDate(value);
  };

  const handleStartPeriodChange = (value) => {
    setStartPeriodDate(value);
  };

  const handleFilterConversions = () => {
    if (loadedAllConversions) {
      setPeriodBrandConversions(filterConversions(startPeriodDate, endPeriodDate, allConversions));
    }
  };

  const openPopup = (data) => {
    setPopupMode(true);
    setPopupData(data);
  };

  const closePopup = () => {
    setPopupMode(false);
  };

  useEffect(() => {
    setFilterValue(props.filterValue);
  }, [props.filterValue]);

  useEffect(() => {
    setBrands(brandsInfos);
  }, [brandsInfos]);

  useEffect(() => {
    let filteredBrands = brands.length > 0
      ? brands.filter((item) => {
        if (item.name && item.displayName)
          return (item.name.toLowerCase().includes(filterValue.toLowerCase()) || item.displayName.toLowerCase().includes(filterValue.toLowerCase()));
        return false;
      })
      : [];
    setBrandsToWork(filteredBrands);
  }, [brands, filterValue]);

  useEffect(() => {
    let array = [];
    let conversionForChart = [];
    brandsToWork.forEach((brand) => {
      let amount = 0;
      let commissions = 0;
      let salesNumber = 0;
      let product = [];
      let commissionSMI = 0;
      periodBrandConversions.filter((element) => element.offerId === brand.offerId).forEach((filteredBrand) => {
        conversionForChart.push(filteredBrand);
        amount += filteredBrand.amount;
        commissions += Number(filteredBrand.commission);
        commissionSMI += Number(filteredBrand.commission) - Number(filteredBrand.commissionAffiliate);
        salesNumber++;
        if (product.indexOf(filteredBrand.articleId) === -1)
          product.push(filteredBrand.articleId);
      });
      array.push({
        amount: amount,
        commissionsBrand: commissions,
        salesNumber: salesNumber,
        products: product,
        commissionSMI: commissionSMI,
        clicks: 0,
        ...brand,
      });
      clicksManager.getClicksPerBrand(startPeriodDate.getTime(), endPeriodDate.getTime(), brand.offerId, startPeriodDate.getTimezoneOffset()).then((result) => {
        array[array.map((e) => e.offerId).indexOf(brand.offerId)]["clicks"] = result.data.clicksNumber;
        setSortEvent((s) => s + 1);
      });
    });
    const filterFinalBrand = array.filter((element) => (element.clicks !== undefined && element.clicks !== 0) || element.amount !== 0);
    setConversionForChart(conversionForChart);
    setBrandDetails(filterFinalBrand);
    setLoading(false);
    // eslint-disable-next-line
  }, [brandsToWork, periodBrandConversions]);

  useEffect(() => {
    let amount = 0;
    let salesNumber = 0;
    let unpaidCommissions = 0;
    let commissionSMI = 0;
    let click = 0;
    if (brandDetails.length !== 0) {
      brandDetails.forEach((brand) => {
        amount += brand.amount;
        salesNumber += brand.salesNumber;
        unpaidCommissions += brand.commissionsBrand;
        commissionSMI += brand.commissionSMI;
        click += brand.clicks;
      });
    }
    setClickPerInfluencer(click);
    setPeriodCommissionSMI(commissionSMI);
    setSalesPeriod(amount);
    setSalesNumberPeriod(salesNumber);
    setUnpaidCommissionsPeriod(unpaidCommissions);
  }, [brandDetails, sortEvent]);

  useEffect(() => {
    filter().then(() => {
      setLoadingChart(false);
    });
  }, [filter])

  //Effect for handling period change
  useEffect(() => {
    switch (periodSelector) {
      case "today":
        setStartPeriodDate(new MyDate().dayStart());
        setEndPeriodDate(new MyDate().dayEnd());
        break;
      case "week":
        {
          let prevMonday = getPreviousMonday().dayStart();
          let endOfWeek = getPreviousMonday().addWeeks(1).addDays(-1).dayEnd();
          setStartPeriodDate(prevMonday);
          setEndPeriodDate(endOfWeek);
        }
        break;
      case "month":
        {
          let startDate = new MyDate();
          startDate.setDate(1);
          startDate = startDate.dayStart();
          let endDate = new MyDate().addMonths(1);
          endDate.setDate(1);
          endDate = endDate.addDays(-1).dayEnd();
          setStartPeriodDate(startDate);
          setEndPeriodDate(endDate);
        }
        break;
      case "lastWeek":
        {
          let monday = getPreviousMonday().addWeeks(-1).dayStart();
          let endOfWeek = getPreviousMonday().addDays(-1).dayEnd();
          setStartPeriodDate(monday);
          setEndPeriodDate(endOfWeek);
        }
        break;
      case "lastMonth":
        {
          let startDate = new MyDate();
          startDate.setDate(1);
          startDate = startDate.addMonths(-1).dayStart();
          let endDate = new MyDate();
          endDate.setDate(1);
          endDate = endDate.addDays(-1).dayEnd();
          setStartPeriodDate(startDate);
          setEndPeriodDate(endDate);
        }
        break;
      case "customMode":
        break;
      default:
        break;
    }
  }, [periodSelector]);



  return (
    <Grid container justify="center" style={{ marginTop: "20px" }}>
      {!popupMode ? (
        <>
          <Grid container item direction="row" wrap="wrap" lg={11} justify={mdDown ? "center" : "flex-end"} alignItems="center" className={classes.rowSpacing}>
            <Typography className={classes.titles}>
              {t("BRAND_AGENT.VIEWS.STATISTICS.PERIOD")}:{" "}
            </Typography>
            <Select
              value={periodSelector}
              onChange={handlePeriodSelector}
              style={{ margin: 10 }}
              input={<BootstrapInput />}
              IconComponent={() => <SVGIcon name="miniArrow" />}
              classes={{ root: classes.selectClass }}
            >
              <MenuItem value="month" style={{ fontFamily: "Poppins" }}>
                {t("BRAND_AGENT.VIEWS.STATISTICS.PERIOD_SELECTOR.CURRENT_MONTH")}
              </MenuItem>
              <MenuItem value="week" style={{ fontFamily: "Poppins" }}>
                {t("BRAND_AGENT.VIEWS.STATISTICS.PERIOD_SELECTOR.CURRENT_WEEK")}
              </MenuItem>
              <MenuItem value="today" style={{ fontFamily: "Poppins" }}>
                {t("BRAND_AGENT.VIEWS.STATISTICS.PERIOD_SELECTOR.TODAY")}
              </MenuItem>
              <MenuItem value="lastWeek" style={{ fontFamily: "Poppins" }}>
                {t("BRAND_AGENT.VIEWS.STATISTICS.PERIOD_SELECTOR.PREVIOUS_WEEK")}
              </MenuItem>
              <MenuItem value="lastMonth" style={{ fontFamily: "Poppins" }}>
                {t("BRAND_AGENT.VIEWS.STATISTICS.PERIOD_SELECTOR.PREVIOUS_MONTH")}
              </MenuItem>
              <MenuItem value="customMode" style={{ fontFamily: "Poppins" }}>
                {t("BRAND_AGENT.VIEWS.STATISTICS.PERIOD_SELECTOR.CUSTOMIZED")}
              </MenuItem>
            </Select>
            <div style={{ display: "flex", flexDirection: xsDown ? "column" : "row", alignItems: xsDown ? "center" : "center", flexWrap: "wrap", justifyContent: xsDown ? "center" : "flex-start", }}>
              <MuiPickersUtilsProvider utils={DateFnsUtils}>
                <DatePicker
                  format="dd-MM-yyyy"
                  views={["year", "month", "date"]}
                  value={startPeriodDate}
                  onChange={handleStartPeriodChange}
                  onOpen={() => {
                    setPeriodSelector("customMode");
                  }}
                  TextFieldComponent={(props) => (
                    <TextField
                      {...props}
                      InputProps={{
                        classes: inputClasses,
                        disableUnderline: true,
                      }}
                    />
                  )}
                />
              </MuiPickersUtilsProvider>
              {!xsDown ? (
                <ArrowRightAltIcon fontSize="large" />
              ) : (
                <ArrowDownwardIcon fontSize="large" />
              )}

              <MuiPickersUtilsProvider utils={DateFnsUtils}>
                <DatePicker
                  format="dd-MM-yyyy"
                  views={["year", "month", "date"]}
                  value={endPeriodDate}
                  onChange={handleEndPeriodChange}
                  onOpen={() => setPeriodSelector("customMode")}
                  TextFieldComponent={(props) => (
                    <TextField
                      {...props}
                      InputProps={{
                        classes: inputClasses,
                        disableUnderline: true,
                      }}
                    />
                  )}
                />
              </MuiPickersUtilsProvider>
              <Button className={classes.buttonDateStyle} onClick={handleFilterConversions}>
                <SearchIcon style={{ color: "#fff", height: "20px" }} />
                <Typography className={classes.textButtonDate}>
                  {t("BRAND_AGENT.VIEWS.STATISTICS.BUTTON_DATE")}
                </Typography>
              </Button>
            </div>
          </Grid>
          <Grid container item direction="row" xs={11} className={classes.rowSpacing}>
            <Grid container item direction="row" wrap="wrap" justify="center">
              <Box className={classes.smallBox}>
                <Grid container direction="row">
                  <Grid container item xs={6} justify="center">
                    <EuroSymbolIcon style={{ fontSize: "60px", color: "#ef7e3e" }} />
                  </Grid>
                  <Grid container item xs={6} direction="column" justify="center">
                    <Typography className={classes.smallBoxTitles}>
                      {t("BRAND_AGENT.VIEWS.STATISTICS.TURNOVER_BOX_TITLE")}
                    </Typography>
                    <Grid container item direction="row">
                      <CountUp className={classes.smallBoxInfos} start={0} end={Math.round(salesPeriod * 100) / 100} decimals={2} decimal="," />
                      <span className={classes.smallBoxInfos}> €</span>
                    </Grid>
                  </Grid>
                </Grid>
              </Box>
              <Box className={classes.smallBox}>
                <Grid container direction="row">
                  <Grid container item xs={6} justify="center">
                    <SVGIcon name="ventes" width={"60px"} height={"60px"} />
                  </Grid>
                  <Grid container item xs={6} direction="column" justify="center">
                    <Typography className={classes.smallBoxTitles}>
                      {t("BRAND_AGENT.VIEWS.STATISTICS.SALES_BOX_TITLE")}
                    </Typography>
                    <Grid item>
                      <CountUp className={classes.smallBoxInfos} start={0} end={salesNumberPeriod} />
                    </Grid>
                  </Grid>
                </Grid>
              </Box>
              <Box className={classes.smallBox}>
                <Grid container direction="row">
                  <Grid container item xs={6} justify="center">
                    <TouchAppIcon style={{ fontSize: "60px", color: "#3568eb" }} />
                  </Grid>
                  <Grid container item xs={6} direction="column" justify="center">
                    <Typography className={classes.smallBoxTitles}>
                      {t("BRAND_AGENT.VIEWS.STATISTICS.CLICKS_BOX_TITLE")}
                    </Typography>
                    <Grid item>
                      <CountUp className={classes.smallBoxInfos} start={0} end={clickPerInfluencer} />
                    </Grid>
                  </Grid>
                </Grid>
              </Box>
              <Box className={classes.smallBox}>
                <Grid container direction="row">
                  <Grid container item xs={6} justify="center">
                    <SVGIcon name="commissionPaye" width={"60px"} height={"60px"} />
                  </Grid>
                  <Grid container item xs={6} direction="column" justify="center">
                    <Typography className={classes.smallBoxTitles}>
                      {t("BRAND_AGENT.VIEWS.STATISTICS.PAID_COMMISSIONS_BOX_TITLE")}
                    </Typography>
                    <Grid item>
                      <CountUp className={classes.smallBoxInfos} start={0} end={0} />
                      <span className={classes.smallBoxInfos}> €</span>
                    </Grid>
                  </Grid>
                </Grid>
              </Box>
              <Box className={classes.smallBox}>
                <Grid container direction="row">
                  <Grid container item xs={6} justify="center">
                    <SVGIcon name="commission" width={"60px"} height={"60px"} />
                  </Grid>
                  <Grid container item xs={6} direction="column" justify="center">
                    <Typography className={classes.smallBoxTitles}>
                      {t("BRAND_AGENT.VIEWS.STATISTICS.UNPAID_COMMISSIONS_BOX_TITLE")}
                    </Typography>
                    <Grid item>
                      <CountUp className={classes.smallBoxInfos} start={0} end={Math.round(unPaidCommissionsPeriod * 100) / 100} decimals={2} decimal="," />
                      <span className={classes.smallBoxInfos}> €</span>
                    </Grid>
                  </Grid>
                </Grid>
              </Box>
              <Box className={classes.smallBox}>
                <Grid container direction="row">
                  <Grid container item xs={6} justify="center">
                    <SVGIcon name="commission" width={"60px"} height={"60px"} />
                  </Grid>
                  <Grid container item xs={6} direction="column" justify="center">
                    <Typography className={classes.smallBoxTitles}>
                      Commissions SMI
                    </Typography>
                    <Grid item>
                      <CountUp className={classes.smallBoxInfos} start={0} end={Math.round(periodCommissionSMI * 100) / 100} decimals={2} decimal="," />
                      <span className={classes.smallBoxInfos}> €</span>
                    </Grid>
                  </Grid>
                </Grid>
              </Box>
            </Grid>
          </Grid>

          <Grid container direction="row" item xs={11} justify="center" alignItems="center" style={{ marginBottom: "15px" }}>
            <Grid container direction="row" item md={7} sm={12} justify="center">
              <Grid container justify="center" style={{ marginTop: "10px", marginBottom: "10px" }}>
                <Typography className={classes.titles}>
                  {t("TREND.VIEWS.STATISTICS.SALES_STATISTICS_BOX_TITLE")}
                </Typography>
              </Grid>
              <Box className={classes.pieBox}>
                {loading ? (
                  <TrendLoading />
                ) : conversionForChart.length !== 0 ? (
                  <ConversionsChart
                    {...{ customDurationConversions: conversionForChart }}
                  />
                ) : (
                  <Grid container direction="column" justify="space-around" alignItems="center" style={{ height: "50%" }}>
                    <img alt="No sales" src={placeholderSales} height="75px" width="75px" />
                    <Typography className={classes.textPlaceholder}>
                      {t("TREND.VIEWS.STATISTICS.NO_PERIOD_GENERATED_SALES_PLACEHOLDER")}
                    </Typography>
                  </Grid>
                )}
              </Box>
            </Grid>
            <Grid container direction="row" item md={5} sm={12} justify="center">
              <Grid container justify="center" style={{ marginTop: "10px", marginBottom: "10px" }} >
                <Typography className={classes.titles}>
                  {t("TREND.VIEWS.STATISTICS.SALES_PER_PRODUCT_NUMBER_BOX_TITLE")}
                </Typography>
              </Grid>
              <Box className={classes.pieBox}>
                {loadingChart ? (
                  <TrendLoading />
                ) : periodProducts.length !== 0 ? (
                  <ProductPieChart
                    {...{
                      articlesInfos: periodProducts,
                      startPeriodDate,
                      endPeriodDate,
                    }}
                  />
                ) : (
                  <Grid container direction="column" justify="space-around" alignItems="center" style={{ height: "50%" }}>
                    <img alt="No sales" src={placeholderSales} height="75px" width="75px" />
                    <Typography className={classes.textPlaceholder}>
                      {t("TREND.VIEWS.STATISTICS.NO_PERIOD_GENERATED_SALES_PLACEHOLDER")}
                    </Typography>
                  </Grid>
                )}
              </Box>
            </Grid>
          </Grid>

          {brandDetails.length !== 0 ? (
            <Grid container item direction="row" xs={11} className={classes.rowSpacing}>
              <Grid container item xs={12} justify="center">
                <Grid container direction="column">
                  <Typography className={classes.titles} style={{ margin: 5 }}>
                    {t("BRAND_AGENT.VIEWS.STATISTICS.STATISTICS_FILTER_TITLE")}:
                  </Typography>
                  <Grid container direction="row" wrap="wrap">
                    <Button className={classes.activeButtonStyle} disableRipple>
                      {t("BRAND_AGENT.VIEWS.STATISTICS.FILTER_BRAND_TITLE")}
                    </Button>
                  </Grid>
                </Grid>
              </Grid>
              {loading ? (
                <Grid container justify="center">
                  <CircularProgress color="secondary" />
                </Grid>
              ) : (
                <BrandsTable brandToRender={brandDetails} brandToRenderLoading={loading} openPopup={(data) => openPopup(data)} />
              )}
            </Grid>
          ) : (
            <></>
          )}
        </>
      ) : (
        <BrandModal
          close={() => closePopup()}
          {...{
            data: popupData,
          }}
          periodBrandConversions={periodBrandConversions}
        />
      )}
    </Grid>
  );
};

const mapStateToProps = (state, ownProps) => {
  const brandConversions = state.conversionsState.brandConversions;
  const allConversions = state.conversionsState.allConversions;
  const loadedAllConversions = state.conversionsState.loadedAllConversions;
  return {
    brandConversions,
    allConversions,
    loadedAllConversions,
  };
};
export default connect(mapStateToProps, null)(Statistics);
