import axios from "axios";
import { formatDate, urlMaker } from "../utils";

export const url = "https://influens-api.herokuapp.com/api";
const smiCat = "5c7fd582df2b538e008b457d";
const affise = {
  /*   fetchClicks: (from = new Date(), to = new Date()) => {
    const url = `${urlMaker("api")}/clicks`;
    return axios
      .get(url, {
        params: {
          from: formatDate(from),
          to: formatDate(to),
        },
      })
      .then(res => {
        // return res.data.clicks.map(click => ({
        //   date: click.created_at,
        //   id: click.id,
        //   article: click.sub2,
        //   affiliate: click.partner_id || click.partner.id,
        // }));
        return res.data;
      })
      .catch(err => console.log(err));
  },
 */
  /**
   * @desc loop of request to get all the offers from affise throught firebase functions
   * @return {array} Array of offers from shopmyinfluens
   */
  fetchOffers: async () => {
    const url = `${urlMaker("api")}/offers`;
    let nextPage = true;
    let offers = [];
    for (let i = 1; nextPage; i++) {
      let res;
      try {
        res = await axios.get(url, {
          params: {
            page: i,
          },
        });
      } catch (error) {
        console.log("error action : ", error);
        nextPage = false;
        break;
      }
      const data = res.data.offers;
      offers.push(...data);
      nextPage = res.data.pagination.next_page;
    }
    return offers.filter(offer => offer.categories.includes(smiCat));
  },
  fetchConversions: (brandId, from = new Date(), to = new Date()) => {
    const url = `${urlMaker("api")}/conversions`;
    return axios
      .get(url, {
        params: {
          from: formatDate(from),
          to: formatDate(to),
          brand: brandId,
        },
      })
      .then(res => {
        return res.data.conversions
          ? res.data.conversions
              .map(conv => ({
                article: conv.sub2,
                affiliate: conv.partner_id || conv.partner.id,
                amount: conv.payouts,
                revenue: parseFloat(conv.custom_field_1),
                payouts: conv.payouts,
              }))
              .filter(conv => conv.article.length)
          : [];
      });
  },
  fetchStatistics: (affiliateId, from = new Date(), to = new Date()) => {
    const url = `${urlMaker("api")}/statistics`;
    return axios
      .get(url, {
        params: {
          from: formatDate(from),
          to: formatDate(to),
          affiliateId: affiliateId,
        },
      })
      .then(res => res.data);
  },
  createAffiliate: (email, password) => {
    const url = urlMaker("createAff");
    return axios
      .post(url, {
        email,
        password,
        status: "active",
      })
      .then(res => res.data.id);
  },
  editOfferCrossPostback: (offerId, crossPostback) => {
    const url = `${urlMaker("api")}/editOfferCrossPostback`;
    return axios
      .get(url, {
        params: {
          offerId: offerId,
          crossPostback: crossPostback,
        },
      })
      .then(res => res.data);
  },
  editAllCrossPostbacks: async crossPostback => {
    const offers = await affise.fetchOffers();
    let results = [];
    for (let i = 0; i < offers.length; i++) {
      const offer = offers[i];
      await affise
        .editOfferCrossPostback(offer.id, encodeURIComponent(crossPostback))
        .then(res => results.push(res));
    }
    return results;
  },
};

export default affise;
