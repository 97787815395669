const initState = {
    influencerAgents: [],
    agentsToValidate: [],
    loadedInfluencerAgents: false,
    loadedAgentsToValidate: false,
    loadingValidateInfluencerAgent: false,
    loadingRefuseInfluencerAgent: false,
    loadingUpdateInfluencerAgent: false,
    loadingUpdateInfluencerAgentClaims: false,
    error: null
};

const influencerAgentsReducer = (state = initState, action) => {
    switch (action.type) {
        case 'FETCH_ALL_INFLUENCER_AGENTS_SUCCESS':
            return {
                ...state,
                influencerAgents: action.payload.influencerAgents,
                loadedInfluencerAgents: true
            };
        case 'FETCH_ALL_INFLUENCER_AGENTS_ERROR':
            return {
                ...state,
                loadedInfluencerAgents: false,
                error: action.error
            };
        case 'FETCH_INFLUENCER_AGENT_TO_VALIDATE': {
            return {
                ...state,
                agentsToValidate: action.payload.agentToValidate,
                loadedAgentsToValidate: true
            }
        }
        case "FETCH_INFLUENCER_AGENT_TO_VALIDATE_ERROR": {
            return {
                ...state,
                loadedAgentsToValidate: false,
                error: action.error,
            }
        }
        case "VALIDATE_INFLUENCER_AGENT_BY_ID_REQUEST":
            return {
                ...state,
                loadingValidateInfluencerAgent: true,
            };
        case "VALIDATE_INFLUENCER_AGENT_BY_ID_SUCCESS": {
            let newInfluencerAgent = state.influencerAgents;
            newInfluencerAgent.push(action.payload.influencerAgent);
            let newInfluencerAgentToValidate = state.agentsToValidate;
            let indexToDelete = null;
            newInfluencerAgentToValidate.forEach((influencerAgent, index) => {
                if (influencerAgent.key === action.payload.unverifiedInfluencerAgentId)
                    indexToDelete = index;
            });
            newInfluencerAgentToValidate.splice(indexToDelete, 1);
            return {
                ...state,
                influencerAgents: newInfluencerAgent,
                agentsToValidate: newInfluencerAgentToValidate,
                loadingValidateInfluencerAgent: false,
            };
        }
        case "VALIDATE_INFLUENCER_AGENT_BY_ID_ERROR":
            return {
                ...state,
                loadingValidateInfluencerAgent: false,
                error: action.error,
            };
        case "REFUSE_INFLUENCER_AGENT_BY_ID_REQUEST":
            return {
                ...state,
                loadingRefuseInfluencerAgent: true,
            };
        case "REFUSE_INFLUENCER_AGENT_BY_ID_SUCCESS": {
            let newInfluencerAgentToValidate = state.agentsToValidate;
            let indexToDelete = null;
            newInfluencerAgentToValidate.forEach((brandAgent, index) => {
                if (brandAgent.key === action.payload.unverifiedInfluencerAgentId)
                    indexToDelete = index;
            });
            newInfluencerAgentToValidate.splice(indexToDelete, 1);
            return {
                ...state,
                agentsToValidate: newInfluencerAgentToValidate,
                loadingRefuseInfluencerAgent: false,
            };
        }
        case "REFUSE_INFLUENCER_AGENT_BY_ID_ERROR":
            return {
                ...state,
                loadingRefuseInfluencerAgent: false,
                error: action.error,
            };
        case "UPDATE_INFLUENCER_AGENT_BY_ID_REQUEST":
            return {
                ...state,
                loadingUpdateInfluencerAgent: true,
            };
        case "UPDATE_INFLUENCER_AGENT_BY_ID_SUCCESS": {
            let newInfluencerAgent = state.influencerAgents.map((agent) => {
                if (agent.key === action.payload.id) {
                    let updatedAgent = {
                        ...agent,
                    };
                    Object.entries(action.payload.updates).forEach(
                        ([keyToUpdate, newValue]) => {
                            if (keyToUpdate.indexOf("/") !== -1) {
                                let subObjectToUpdate = keyToUpdate.split("/");
                                if (updatedAgent[subObjectToUpdate[0]] === undefined)
                                    updatedAgent[subObjectToUpdate[0]] = {};
                                updatedAgent[subObjectToUpdate[0]][
                                    subObjectToUpdate[1]
                                ] = newValue;
                            } else {
                                updatedAgent[keyToUpdate] = newValue;
                            }
                        }
                    );
                    return updatedAgent;
                } else {
                    return agent;
                }
            });
            return {
                ...state,
                influencerAgents: newInfluencerAgent,
                loadingUpdateInfluencerAgent: false,
            };
        }
        case "UPDATE_INFLUENCER_AGENT_BY_ID_ERROR":
            return {
                ...state,
                errors: action.error,
                loadingUpdateInfluencerAgent: false,
            };
        case "UPDATE_INFLUENCER_AGENT_CLAIMS_BY_ID_REQUEST":
            return {
                ...state,
                loadingUpdateInfluencerAgentClaims: true,
            };
        case "UPDATE_INFLUENCER_AGENT_CLAIMS_BY_ID_SUCCESS": {
            let newInfluencerAgent = state.influencerAgents.map((agent) => {
                if (agent.key === action.payload.id) {
                    let updatedAgent = {
                        ...agent,
                    };
                    Object.entries(action.payload.updates).forEach(
                        ([keyToUpdate, newValue]) => {
                            if (keyToUpdate.indexOf("/") !== -1) {
                                let subObjectToUpdate = keyToUpdate.split("/");
                                if (updatedAgent[subObjectToUpdate[0]] === undefined)
                                    updatedAgent[subObjectToUpdate[0]] = {};
                                updatedAgent[subObjectToUpdate[0]][
                                    subObjectToUpdate[1]
                                ] = newValue;
                            } else {
                                updatedAgent[keyToUpdate] = newValue;
                            }
                        }
                    );
                    return updatedAgent;
                } else {
                    return agent;
                }
            });
            return {
                ...state,
                influencerAgents: newInfluencerAgent,
                loadingUpdateInfluencerAgentClaims: false,
            };
        }
        case "UPDATE_INFLUENCER_AGENT_CLAIMS_BY_ID_ERROR":
            return {
                ...state,
                errors: action.error,
                loadingUpdateInfluencerAgentClaims: false,
            };
        case "CLEAR_INFLUENCER_AGENT":
            return {
                ...state,
                influencerAgents: [],
                agentsToValidate: [],
                loadedInfluencerAgents: false,
                loadedAgentsToValidate: false,
                error: null
            };
        default:
            return state;
    }
};

export default influencerAgentsReducer;