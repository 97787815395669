import React, { useState, useEffect, useCallback } from "react";
import {
  Grid,
  Button,
  Box,
  Typography,
  Select,
  MenuItem,
  Card,
  CircularProgress,
} from "@material-ui/core";
import { makeStyles } from "@material-ui/core";
import { Pagination, PaginationItem } from "@material-ui/lab";
import SVGIcon from "../template/assets/SVGIcons";
import BootstrapInput from "../template/assets/customComponents/BootstrapInput";
import InfluencerModal from "../template/components/influencerModal";
import TrendLoading from "../../ui/trendLoading/TrendLoading";
import ExpandLessIcon from "@material-ui/icons/ExpandLess";
import ExpandMoreIcon from "@material-ui/icons/ExpandMore";
import SortIcon from "@material-ui/icons/Sort";
import { useTranslation } from "react-i18next";
import { connect } from "react-redux";
import { clicksManager } from "../../managers";
import { fetchWishlistsPerInfluencer } from "../../../store/actions/wishlistsActions";

const useStyles = makeStyles((theme) => {
  return {
    influenceursContent: {
      flexGrow: 1,
      display: "flex",
      flexDirection: "column",
      backgroundColor: "#eff9fe",
    },
    buttonsRow: {
      width: "100%",
      marginTop: "15px",
      marginBottom: "15px",
    },
    activeButtonStyle: {
      width: 300,
      margin: "15px",
      color: "#ffffff",
      borderRadius: "35px",
      backgroundColor: "#3eadef",
      fontSize: "16px",
      textTransform: "none",
      fontFamily: "Poppins",
      boxShadow: "0 7px 30px 0 rgba(88, 165, 211, 0.16)",
      "&:hover": {
        backgroundColor: "#3eadef",
      },
    },
    buttonStyle: {
      width: 300,
      margin: "15px",
      color: "#000",
      borderRadius: "35px",
      backgroundColor: "#ffffff",
      fontSize: "16px",
      textTransform: "none",
      fontFamily: "Poppins",
      boxShadow: "0 7px 30px 0 rgba(88, 165, 211, 0.16)",
    },
    influencerBoxContainer: {
      marginTop: "10px",
      marginBottom: "10px",
      minWidth: "1050px",
    },
    influencerBox: {
      display: "flex",
      width: "95%",
      backgroundColor: "white",
      height: "80px",
      borderRadius: "27px",
      boxShadow: "0 7px 30px 0 rgba(88, 165, 211, 0.16)",
    },
    influencerImage: {
      width: "160px",
      height: "80px",
      borderTopLeftRadius: "27px",
      borderBottomLeftRadius: "27px",
      objectFit: "fill",
    },
    paginationRow: {
      display: "flex",
      justifyContent: "center",
      marginTop: "10px",
      marginBottom: "10px",
    },
    tableHeader: {
      fontFamily: "Poppins",
      fontSize: "14px",
      textAlign: "center",
      "&:hover": {
        cursor: "pointer",
      },
    },
    tableHeaderIcon: {
      "&:hover": {
        cursor: "pointer",
      },
    },
    tableRowContent: {
      fontFamily: "Poppins",
    },
    influencerName: {
      fontFamily: "Poppins",
      textDecoration: "underline",
      "&:hover": {
        cursor: "pointer",
      },
    },
    paginationClass: {
      backgroundColor: "#ffffff !important",
      boxShadow: "0 7px 30px 0 rgba(88, 165, 211, 0.16)",
      borderRadius: "8px",
      fontFamily: "Poppins",
      height: "40px",
      width: "40px",
      display: "flex",
      justifyContent: "center",
      alignItems: "center",
    },
    selectedPaginationItem: {
      backgroundColor: "#3eadef !important",
      boxShadow: "0 7px 30px 0 rgba(88, 165, 211, 0.16)",
      borderRadius: "8px",
      fontFamily: "Poppins",
      height: "40px",
      width: "40px",
      display: "flex",
      justifyContent: "center",
      alignItems: "center",
      color: "#ffffff",
    },
    selectClass: {
      fontFamily: "Poppins",
    },
    influencerCard: {
      margin: "10px",
      width: "280px",
      height: "140px",
      borderRadius: "24px",
      boxShadow: "0 7px 30px 0 rgba(24, 61, 83, 0.16)",
      "&:hover": {
        cursor: "pointer",
      },
    },
    influencerCardImage: {
      objectFit: "contain",
      width: "280px",
      height: "140px",
      borderRadius: "24px",
    },
    textPlaceholder: {
      fontFamily: "Poppins",
      fontSize: "16px",
      margin: 10,
      color: "#1b4c61",
      textAlign: "center",
    },
  };
});

const Influenceurs = (props) => {
  const { t } = useTranslation("common");

  const classes = useStyles();

  const [user, setUser] = useState(null);
  const [activeButton, setActiveButton] = useState("normal");
  // const [allConversions, setAllConversions] = useState([]);
  const [allDbInfluencers, setAllDbInfluencers] = useState([]);
  const [myDbInfluencers, setMyDbInfluencers] = useState([]);
  const [allInfluencers, setAllInfluencers] = useState([]);
  const [myInfluencers, setMyInfluencers] = useState([]);
  const [influencersToRender, setInfluencersToRender] = useState([]);
  const [activeInfluencer, setActiveInfluencer] = useState(null);
  const [openPopover, setOpenPopver] = useState(false);
  // const [conversionsLoading, setConversionsLoading] = useState(true);
  const [allInfluencersLoading, setAllInfluencersLoading] = useState(true);
  const [myInfluencersLoading, setMyInfluencersLoading] = useState(true);
  const [loading, setLoading] = useState(true);

  const [page, setPage] = useState(1);
  const [size, setSize] = useState(10);
  const [count, setCount] = useState(0);
  const [sortHelper, setSortHelper] = useState([1, 0, 0, 0, 0]);
  const [sortEvent, setSortEvent] = useState(0);

  const influencerSearch = props.influencerSearch;

  const {
    allConversions,
    influencers,
    loadedAllConversions,
    loadedInfluencers,
    influencerAgent,
    loadedInfluencerAgents,
    allInfluencerAgents,
    fetchWishlistsPerInfluencer,
    wishlistsPerInfluencerByIdRequest,
  } = props;

  const filterData = (conversions, influencersArray) => {
    influencersArray.forEach((influencer) => {
      let influencerConversions = conversions.filter((conversion) => {
        return conversion.influencer === influencer.key;
      });

      let temp = [];
      influencerConversions.forEach((conversion) => {
        if (temp.indexOf(conversion.articleId) === -1)
          temp.push(conversion.articleId);
        influencer.amount += conversion.amount;
        influencer.commission += Number(conversion.commission);
        influencer.salesNumber++;
      });
      influencer.product = temp.length;
    });
    return influencersArray.filter(
      (element) =>
        element.name !== undefined &&
        element.name !== null &&
        element.name !== ""
    );
  };

  const handleActiveButton = (type) => {
    if (type === "all") {
      setActiveButton("active");
    } else {
      setActiveButton("normal");
    }
    setPage(1);
  };

  const handleOpenPopover = useCallback(
    (influencer) => {
      wishlistsPerInfluencerByIdRequest();
      fetchWishlistsPerInfluencer(influencer.key);
      let temp = myInfluencers;
      const commonInfluencer = temp.filter(
        (element) => element.key === influencer.key
      );
      commonInfluencer.length === 1
        ? setActiveInfluencer({
          ...commonInfluencer[0],
          influencerId: influencer.key,
          showStats: true,
        })
        : setActiveInfluencer({
          ...influencer,
          influencerId: influencer.key,
          showStats: false,
        });
      setOpenPopver(true);
    },
    [myInfluencers, wishlistsPerInfluencerByIdRequest, fetchWishlistsPerInfluencer]
  );

  const handleClosePopover = () => {
    setOpenPopver(false);
    setActiveInfluencer(null);
  };

  const handlePageChange = (event, value) => {
    setPage(value);
  };

  const handlePageSizeChange = (event) => {
    setSize(event.target.value);
    setPage(1);
  };

  useEffect(() => {
    if (influencerSearch) {
      handleOpenPopover(influencerSearch);
    } else {
      //
    }
  }, [influencerSearch, handleOpenPopover]);

  /*useEffect(() => {
    if (influencerAgent) {
      setUser(influencerAgent);
    }
  }, [influencerAgent]);*/

  useEffect(() => {
    if (loadedInfluencerAgents) {
      let agentToWorkOn = allInfluencerAgents.filter(element => element.userId === influencerAgent.userId);
      setUser(...agentToWorkOn);
    }
  }, [loadedInfluencerAgents, allInfluencerAgents, influencerAgent]);

  useEffect(() => {
    let influencersInfos = [];
    influencers.forEach((influencer) => {
      influencersInfos.push({
        ...influencer,
        amount: 0,
        commission: 0,
        salesNumber: 0,
        product: 0,
      });
    });
    setAllDbInfluencers(
      influencersInfos.filter(
        (element) =>
          element.name !== undefined &&
          element.banner !== null &&
          element.name !== "" &&
          !element.private
      )
    );
    setAllInfluencersLoading(false);
  }, [influencers]);

  useEffect(() => {
    if (user && loadedInfluencers) {
      let influencersArray = [];
      const fetchInfluencers = async () => {
        if (user.influencersIds !== undefined) {
          user.influencersIds.forEach((id) => {
            if (id.acceptedStatus) {
              let influencer =
                influencers.filter((element) => element.key === id.uid).length === 0
                  ? null
                  : influencers.filter((element) => element.key === id.uid)[0];
              if (influencer) {
                influencersArray.push({
                  ...influencer,
                  amount: 0,
                  commission: 0,
                  salesNumber: 0,
                  product: 0,
                  clicks: undefined,
                });
                clicksManager
                  .getClicksPerInfluencer(
                    influencer.createdAt,
                    new Date().getTime(),
                    influencer.key,
                    undefined,
                    new Date().getTimezoneOffset()
                  )
                  .then((result) => {
                    influencersArray[
                      influencersArray.map((e) => e.key).indexOf(influencer.key)
                    ]["clicks"] = result.data.clicksNumber;
                    setSortEvent((s) => s + 1);
                  });
              }
            }
          });
        }
      };
      fetchInfluencers().then(() => {
        setMyDbInfluencers(
          influencersArray.filter(
            (element) =>
              element.name !== undefined &&
              element.banner !== null &&
              element.name !== ""
          )
        );
        setMyInfluencersLoading(false);
      });
    }
  }, [user, loadedInfluencers, influencers]);

  // Filter all fetched data
  useEffect(() => {
    setAllInfluencers(filterData(allConversions, allDbInfluencers));
    setMyInfluencers(filterData(allConversions, myDbInfluencers));
  }, [allConversions, allDbInfluencers, myDbInfluencers]);

  // Pagination Effect
  useEffect(() => {
    if (activeButton === "normal") {
      setInfluencersToRender(
        myInfluencers.slice((page - 1) * size, page * size)
      );
      setCount(
        myInfluencers.length % size === 0
          ? Math.trunc(myInfluencers.length / size)
          : Math.trunc(myInfluencers.length / size) + 1
      );
    } else {
      setInfluencersToRender(
        allInfluencers.slice((page - 1) * size, page * size)
      );
      setCount(
        allInfluencers.length % size === 0
          ? Math.trunc(allInfluencers.length / size)
          : Math.trunc(allInfluencers.length / size) + 1
      );
    }
  }, [myInfluencers, allInfluencers, activeButton, size, page, sortEvent]);

  // Loading Spinner Effect
  useEffect(() => {
    // console.log(allInfluencersLoading, myInfluencersLoading, loadedAllConversions)
    if (
      !allInfluencersLoading &&
      !myInfluencersLoading &&
      loadedAllConversions
    ) {
      setLoading(false);
    }
  }, [allInfluencersLoading, myInfluencersLoading, loadedAllConversions]);

  useEffect(() => {
    let indexAsc = sortHelper.indexOf(1);
    let indexDesc = sortHelper.indexOf(-1);
    if (indexAsc !== -1) {
      const sortTypeAsc = (indexAsc, tableToWorkOn) => {
        switch (indexAsc) {
          case 0:
            tableToWorkOn.sort((a, b) => {
              if (a.amount > b.amount) return -1;
              if (a.amount < b.amount) return 1;
              return 0;
            });
            break;
          case 1:
            tableToWorkOn.sort((a, b) => {
              if (a.salesNumber > b.salesNumber) return -1;
              if (a.salesNumber < b.salesNumber) return 1;
              return 0;
            });
            break;
          case 2:
            tableToWorkOn.sort((a, b) => {
              if (a.product > b.product) return -1;
              if (a.product < b.product) return 1;
              return 0;
            });
            break;
          case 3:
            tableToWorkOn.sort((a, b) => {
              if (a.commission > b.commission) return -1;
              if (a.commission < b.commission) return 1;
              return 0;
            });
            break;
          case 4:
            tableToWorkOn.sort((a, b) => {
              if (a.clicks === undefined && b.clicks !== undefined) return 1;
              if (a.clicks !== undefined && b.clicks === undefined) return -1;
              if (a.clicks === undefined && b.clicks === undefined) return 0;
              if (a.clicks > b.clicks) return -1;
              if (a.clicks < b.clicks) return 1;
              return 0;
            });
            break;
          default:
            break;
        }
      };
      if (activeButton === "normal") {
        let tableToWorkOn = [];
        tableToWorkOn = myInfluencers;
        sortTypeAsc(indexAsc, myInfluencers);
        setMyInfluencers(tableToWorkOn);
      } else {
        let tableToWorkOn = [];
        tableToWorkOn = allInfluencers;
        sortTypeAsc(indexAsc, allInfluencers);
        setAllInfluencers(tableToWorkOn);
      }
    } else {
      const sortTypeDesc = (indexDesc, tableToWorkOn) => {
        switch (indexDesc) {
          case 0:
            tableToWorkOn.sort((a, b) => {
              if (a.amount > b.amount) return 1;
              if (a.amount < b.amount) return -1;
              return 0;
            });
            break;
          case 1:
            tableToWorkOn.sort((a, b) => {
              if (a.salesNumber > b.salesNumber) return 1;
              if (a.salesNumber < b.salesNumber) return -1;
              return 0;
            });
            break;
          case 2:
            tableToWorkOn.sort((a, b) => {
              if (a.product > b.product) return 1;
              if (a.product < b.product) return -1;
              return 0;
            });
            break;
          case 3:
            tableToWorkOn.sort((a, b) => {
              if (a.commission > b.commission) return 1;
              if (a.commission < b.commission) return -1;
              return 0;
            });
            break;
          case 4:
            tableToWorkOn.sort((a, b) => {
              if (a.clicks === undefined && b.clicks !== undefined) return 1;
              if (a.clicks !== undefined && b.clicks === undefined) return -1;
              if (a.clicks === undefined && b.clicks === undefined) return 0;
              if (a.clicks > b.clicks) return 1;
              if (a.clicks < b.clicks) return -1;
              return 0;
            });
            break;
          default:
            break;
        }
      };
      if (activeButton === "normal") {
        sortTypeDesc(indexDesc, myInfluencers);
      } else {
        sortTypeDesc(indexDesc, allInfluencers);
      }
    }
    setSortEvent((s) => s + 1);
  }, [sortHelper, activeButton, allInfluencers, myInfluencers]);

  return (
    <Grid className={classes.influenceursContent}>
      {loading ? (
        <TrendLoading />
      ) : (
        <>
          {openPopover === true ? (
            <InfluencerModal
              {...{ activeInfluencer }}
              influencerAgent={influencerAgent}
              close={handleClosePopover}
            />
          ) : (
            <>
              <Grid
                container
                direction="row"
                justify="center"
                className={classes.buttonsRow}
              >
                <Button
                  className={
                    activeButton === "normal"
                      ? classes.activeButtonStyle
                      : classes.buttonStyle
                  }
                  onClick={() => handleActiveButton("my")}
                  disableRipple
                >
                  {t("INFLUENCER_AGENT.VIEWS.INFLUENCERS.MY_INFLUENCERS")}
                </Button>
                <Button
                  className={
                    activeButton === "active"
                      ? classes.activeButtonStyle
                      : classes.buttonStyle
                  }
                  onClick={() => handleActiveButton("all")}
                  disableRipple
                >
                  {t("INFLUENCER_AGENT.VIEWS.INFLUENCERS.ALL_INFLUENCERS")}
                </Button>
              </Grid>
              {activeButton === "active" ? (
                <>
                  <Grid container item direction="row" xs={12} justify="center">
                    <Grid
                      container
                      item
                      direction="row"
                      wrap="wrap"
                      justify="center"
                    >
                      {influencersToRender.map((influencer) => (
                        <Card
                          key={influencer.key}
                          className={classes.influencerCard}
                          onClick={() => handleOpenPopover(influencer)}
                        >
                          <img
                            className={classes.influencerCardImage}
                            src={influencer.banner}
                            alt={influencer.name}
                          />
                        </Card>
                      ))}
                    </Grid>
                    <Grid
                      container
                      item
                      direction="row-reverse"
                      className={classes.paginationRow}
                    >
                      <Grid
                        container
                        alignItems="center"
                        justify="flex-end"
                        style={{ display: "flex", width: "95%" }}
                      >
                        <Select
                          value={size}
                          onChange={handlePageSizeChange}
                          input={<BootstrapInput />}
                          IconComponent={() => <SVGIcon name="miniArrow" />}
                          classes={{ root: classes.selectClass }}
                        >
                          <MenuItem value={5}>5</MenuItem>
                          <MenuItem value={10}>10</MenuItem>
                          <MenuItem value={20}>20</MenuItem>
                        </Select>
                        <Typography
                          className={classes.tableHeader}
                          style={{ marginRight: "10px", marginLeft: "10px" }}
                        >
                          {t(
                            "INFLUENCER_AGENT.VIEWS.INFLUENCERS.PAGINATION_PLACEHOLDER"
                          ) + " "}
                          {activeButton === "normal"
                            ? myInfluencers.length
                            : allInfluencers.length}
                        </Typography>
                        <Pagination
                          count={count}
                          page={page}
                          onChange={handlePageChange}
                          renderItem={(item) => (
                            <PaginationItem
                              classes={{
                                root: classes.paginationClass,
                                selected: classes.selectedPaginationItem,
                              }}
                              {...item}
                            />
                          )}
                        />
                      </Grid>
                    </Grid>
                  </Grid>
                </>
              ) : (
                <>
                  <div
                    style={{
                      overflowX: "auto",
                      width: "100%",
                      display: "flex",
                      justifyContent: "center",
                      flexDirection: "column",
                      alignItems: "center",
                    }}
                  >
                    <Grid
                      container
                      item
                      direction="row-reverse"
                      justify="center"
                      style={{ minWidth: "1050px", width: "100%" }}
                    >
                      <Grid
                        container
                        item
                        direction="row-reverse"
                        style={{ width: "95%" }}
                      >
                        <Grid
                          container
                          item
                          justify="center"
                          alignItems="center"
                          style={{ width: "13%" }}
                          onClick={() => {
                            sortHelper[4] === 0
                              ? setSortHelper([0, 0, 0, 0, 1])
                              : setSortHelper([0, 0, 0, 0, -sortHelper[4]]);
                          }}
                        >
                          <Typography className={classes.tableHeader}>
                            {t("TREND.VIEWS.INFLUENCERS.TABLE_HEADER.CLICKS")}
                          </Typography>
                          {sortHelper[4] === 1 ? (
                            <ExpandLessIcon
                              className={classes.tableHeaderIcon}
                            />
                          ) : sortHelper[4] === -1 ? (
                            <ExpandMoreIcon
                              className={classes.tableHeaderIcon}
                            />
                          ) : (
                            <SortIcon className={classes.tableHeaderIcon} />
                          )}
                        </Grid>
                        <Grid
                          container
                          item
                          justify="center"
                          alignItems="center"
                          style={{ width: "13%" }}
                          onClick={() => {
                            sortHelper[3] === 0
                              ? setSortHelper([0, 0, 0, 1, 0])
                              : setSortHelper([0, 0, 0, -sortHelper[3], 0]);
                          }}
                        >
                          <Typography className={classes.tableHeader}>
                            {t(
                              "TREND.VIEWS.INFLUENCERS.TABLE_HEADER.COMMISSIONS"
                            )}
                          </Typography>
                          {sortHelper[3] === 1 ? (
                            <ExpandLessIcon
                              className={classes.tableHeaderIcon}
                            />
                          ) : sortHelper[3] === -1 ? (
                            <ExpandMoreIcon
                              className={classes.tableHeaderIcon}
                            />
                          ) : (
                            <SortIcon className={classes.tableHeaderIcon} />
                          )}
                        </Grid>
                        <Grid
                          container
                          item
                          justify="center"
                          alignItems="center"
                          style={{ width: "13%" }}
                          onClick={() => {
                            sortHelper[2] === 0
                              ? setSortHelper([0, 0, 1, 0, 0])
                              : setSortHelper([0, 0, -sortHelper[2], 0, 0]);
                          }}
                        >
                          <Typography className={classes.tableHeader}>
                            {t(
                              "TREND.VIEWS.INFLUENCERS.TABLE_HEADER.PRODUCTS_NUMBER"
                            )}
                          </Typography>
                          {sortHelper[2] === 1 ? (
                            <ExpandLessIcon
                              className={classes.tableHeaderIcon}
                            />
                          ) : sortHelper[2] === -1 ? (
                            <ExpandMoreIcon
                              className={classes.tableHeaderIcon}
                            />
                          ) : (
                            <SortIcon className={classes.tableHeaderIcon} />
                          )}
                        </Grid>
                        <Grid
                          container
                          item
                          justify="center"
                          alignItems="center"
                          style={{ width: "13%" }}
                          onClick={() => {
                            sortHelper[1] === 0
                              ? setSortHelper([0, 1, 0, 0, 0])
                              : setSortHelper([0, -sortHelper[1], 0, 0, 0]);
                          }}
                        >
                          <Typography className={classes.tableHeader}>
                            {t(
                              "TREND.VIEWS.INFLUENCERS.TABLE_HEADER.SALES_NUMBER"
                            )}
                          </Typography>
                          {sortHelper[1] === 1 ? (
                            <ExpandLessIcon
                              className={classes.tableHeaderIcon}
                            />
                          ) : sortHelper[1] === -1 ? (
                            <ExpandMoreIcon
                              className={classes.tableHeaderIcon}
                            />
                          ) : (
                            <SortIcon className={classes.tableHeaderIcon} />
                          )}
                        </Grid>
                        <Grid
                          container
                          item
                          justify="center"
                          alignItems="center"
                          style={{ width: "13%" }}
                          onClick={() => {
                            sortHelper[0] === 0
                              ? setSortHelper([1, 0, 0, 0, 0])
                              : setSortHelper([-sortHelper[0], 0, 0, 0, 0]);
                          }}
                        >
                          <Typography className={classes.tableHeader}>
                            {t("TREND.VIEWS.INFLUENCERS.TABLE_HEADER.TURNOVER")}
                          </Typography>
                          {sortHelper[0] === 1 ? (
                            <ExpandLessIcon
                              className={classes.tableHeaderIcon}
                            />
                          ) : sortHelper[0] === -1 ? (
                            <ExpandMoreIcon
                              className={classes.tableHeaderIcon}
                            />
                          ) : (
                            <SortIcon className={classes.tableHeaderIcon} />
                          )}
                        </Grid>
                      </Grid>
                    </Grid>
                    {influencersToRender.map((influencer) => {
                      return (
                        <Grid
                          container
                          item
                          direction="row"
                          justify="center"
                          className={classes.influencerBoxContainer}
                          key={influencer.key}
                        >
                          <Box className={classes.influencerBox}>
                            <Grid container item direction="row" xs={12}>
                              <Grid
                                container
                                item
                                justify="flex-start"
                                alignItems="center"
                                style={{ width: "17%" }}
                              >
                                <img
                                  src={influencer.banner}
                                  alt=""
                                  className={classes.influencerImage}
                                />
                              </Grid>
                              <Grid
                                container
                                item
                                justify="center"
                                alignItems="center"
                                style={{ width: "18%" }}
                              >
                                <Typography
                                  className={classes.influencerName}
                                  onClick={() => handleOpenPopover(influencer)}
                                >
                                  {influencer.name}
                                </Typography>
                              </Grid>

                              <Grid
                                container
                                item
                                justify="center"
                                alignItems="center"
                                style={{ width: "13%" }}
                              >
                                <Typography className={classes.tableRowContent}>
                                  {Math.round(influencer.amount * 100) / 100} €
                                </Typography>
                              </Grid>
                              <Grid
                                container
                                item
                                justify="center"
                                alignItems="center"
                                style={{ width: "13%" }}
                              >
                                <Typography className={classes.tableRowContent}>
                                  {influencer.salesNumber}
                                </Typography>
                              </Grid>
                              <Grid
                                container
                                item
                                justify="center"
                                alignItems="center"
                                style={{ width: "13%" }}
                              >
                                <Typography className={classes.tableRowContent}>
                                  {influencer.product}
                                </Typography>
                              </Grid>
                              <Grid
                                container
                                item
                                justify="center"
                                alignItems="center"
                                style={{ width: "13%" }}
                              >
                                <Typography className={classes.tableRowContent}>
                                  {Math.round(influencer.commission * 100) /
                                    100}{" "}
                                  €
                                </Typography>
                              </Grid>
                              <Grid
                                container
                                item
                                justify="center"
                                alignItems="center"
                                style={{ width: "13%" }}
                              >
                                {influencer.clicks === undefined ? (
                                  <CircularProgress
                                    color="secondary"
                                    style={{ width: "20px", height: "20px" }}
                                  />
                                ) : (
                                  <Typography
                                    className={classes.tableRowContent}
                                  >
                                    {influencer.clicks}
                                  </Typography>
                                )}
                              </Grid>
                            </Grid>
                          </Box>
                        </Grid>
                      );
                    })}
                  </div>
                  <Grid
                    container
                    item
                    direction="row-reverse"
                    className={classes.paginationRow}
                  >
                    <Grid
                      container
                      alignItems="center"
                      justify="flex-end"
                      style={{ display: "flex", width: "95%" }}
                    >
                      <Select
                        value={size}
                        onChange={handlePageSizeChange}
                        input={<BootstrapInput />}
                        IconComponent={() => <SVGIcon name="miniArrow" />}
                        classes={{ root: classes.selectClass }}
                      >
                        <MenuItem value={5}>5</MenuItem>
                        <MenuItem value={10}>10</MenuItem>
                        <MenuItem value={20}>20</MenuItem>
                      </Select>
                      <Typography
                        className={classes.tableHeader}
                        style={{ marginRight: "10px", marginLeft: "10px" }}
                      >
                        {t(
                          "INFLUENCER_AGENT.VIEWS.INFLUENCERS.PAGINATION_PLACEHOLDER"
                        ) + " "}
                        {activeButton === "normal"
                          ? myInfluencers.length
                          : allInfluencers.length}
                      </Typography>
                      <Pagination
                        count={count}
                        page={page}
                        onChange={handlePageChange}
                        renderItem={(item) => (
                          <PaginationItem
                            classes={{
                              root: classes.paginationClass,
                              selected: classes.selectedPaginationItem,
                            }}
                            {...item}
                          />
                        )}
                      />
                    </Grid>
                  </Grid>
                </>
              )}
            </>
          )}
        </>
      )}
    </Grid>
  );
};

const mapStateToProps = (state, ownProps) => {
  const influencers = state.influencersState.influencers;
  const loadedInfluencers = state.influencersState.loadedInfluencers;
  const allConversions = state.conversionsState.allConversions;
  const loadedAllConversions = state.conversionsState.loadedAllConversions;
  const loadedInfluencerAgents = state.influencerAgentsState.loadedInfluencerAgents;
  const allInfluencerAgents = state.influencerAgentsState.influencerAgents;

  return {
    influencers,
    allConversions,
    loadedInfluencers,
    loadedAllConversions,
    loadedInfluencerAgents,
    allInfluencerAgents
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    wishlistsPerInfluencerByIdRequest: () => dispatch({ type: 'FETCH_WICHLISTS_PER_INFLUENCER_REQUEST' }),
    fetchWishlistsPerInfluencer: (influencerId) => dispatch(fetchWishlistsPerInfluencer(influencerId)),
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(Influenceurs);
