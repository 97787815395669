import React, { useLayoutEffect } from 'react';
import * as am4core from "@amcharts/amcharts4/core";
import * as am4maps from "@amcharts/amcharts4/maps";
import am4geodata_worldLow from "@amcharts/amcharts4-geodata/worldLow";
import am4geodata_lang_FR from "@amcharts/amcharts4-geodata/lang/FR";
import am4geodata_lang_EN from "@amcharts/amcharts4-geodata/lang/EN";
import am4themes_animated from "@amcharts/amcharts4/themes/animated";
import { useTranslation } from "react-i18next";

am4core.useTheme(am4themes_animated);

const MapChart = (props) => {
    const { t, i18n } = useTranslation('common');
    const brandConversions = props.brandConversions;

    const turnoverString = t('TREND.CHARTS.MAP_CHART.TURNOVER');
    const salesString = t('TREND.CHARTS.MAP_CHART.SALES');

    const filterData = (brandConversions) => {
        let objectData = {};
        brandConversions.forEach(conv => {
            if (objectData[conv.countryCode] === undefined) {
                objectData[conv.countryCode] = {
                    amount: conv.amount,
                    purchaseNumber: 1
                }
            } else {
                objectData[conv.countryCode].amount += conv.amount;
                objectData[conv.countryCode].purchaseNumber++;
            }
        });
        return objectData;
    }

    useLayoutEffect(() => {


        const objectData = filterData(brandConversions);
        let data = []
        Object.keys(objectData).forEach(id => {
            data.push({
                'id': id,
                'fill': am4core.color('#34c1ae'),
                'tooltip': `${turnoverString} : ` + 
                            ((objectData[id].amount === undefined) ? '0' : Math.round(objectData[id].amount * 100) / 100) + 
                           `€\n ${salesString} : ` + 
                           ((objectData[id].purchaseNumber === undefined) ? '0' : objectData[id].purchaseNumber),
            });
        });
        // console.log(data)
        // Create map instance
        var chart = am4core.create("mapdiv", am4maps.MapChart);

        // Set map definition
        chart.geodata = am4geodata_worldLow;
        (i18n.language === 'fr-FR' || i18n.language === 'fr')
        ? chart.geodataNames = am4geodata_lang_FR
        : chart.geodataNames = am4geodata_lang_EN

        // Set projection
        chart.projection = new am4maps.projections.Miller();



        // Series for World map
        var worldSeries = chart.series.push(new am4maps.MapPolygonSeries());
        worldSeries.data = data;
        worldSeries.exclude = ["AQ"];
        worldSeries.useGeodata = true;

        var polygonTemplate = worldSeries.mapPolygons.template;
        polygonTemplate.tooltipText = "{name} \n {tooltip}";
        polygonTemplate.nonScalingStroke = true;
        polygonTemplate.fill = am4core.color('#084c61');

        polygonTemplate.propertyFields.fill = "fill";

        // Hover state
        var hs = polygonTemplate.states.create("hover");
        hs.properties.fill = am4core.color("#367B25");

        return () => {
            chart.dispose();
        };
    }, [brandConversions, salesString, turnoverString, i18n.language]);

    return (
        <div id="mapdiv" style={{ width: "95%", height: '230px' }}></div>
    );
}

export default MapChart;