import React, { useState, useEffect } from "react";
import { connect } from "react-redux";
import { dbManager } from "../managers";
import { useRouteMatch, Route, Switch, Redirect } from "react-router-dom";
import { fetchAllSoldArticle } from "../../store/actions/articlesActions";
import { fetchAllBrands, fetchAllBrandsToValidate, fetchAllSuggestedBrands } from "../../store/actions/brandsActions";
import { fetchAllCategories, fetchAllSubCategories, fetchNewCategories } from "../../store/actions/categoriesActions";
import { fetchAllColors } from "../../store/actions/colorsActions";
import { fetchAllConversions } from "../../store/actions/conversionsActions";
import { fetchAllInfluencerAgents, fetchInfluencerAgentsToValidate } from "../../store/actions/influencerAgentsActions";
import { fetchAllInfluencers } from "../../store/actions/influencersActions";
import { fetchAllAgency, fetchAllAgencyToValidate } from "../../store/actions/brandsAgentActions";
import { getInfluencersBills, getBrandsBills } from "../../store/actions/billsActions";
import { auth } from '../managers/index';
import Categories from "./views/Categories";
import Dashboard from './views/Dashboard';
import Influenceurs from "./views/Influenceurs";
import Partners from "./views/Partners";
import Statistics from "./views/Statistics";
import ExporterInfluenceurs from "./views/ExporterInfluenceurs";
import ExportBrands from "./template/components/exportBrand/ExportBrands";
//import Factures from './views/Factures';

const Router = (props) => {

    const { url } = useRouteMatch();

    const {
        //filterValue,
        loadedInfluencers,
        loadedAllConversions,
        loadedBrands,
        loadedAllBrandsToValidate,
        loadedSuggestedBrands,
        loadedCategories,
        loadedNewCategories,
        loadedAllBrandAgent,
        loadedAllBrandAgentTovalidate,
        loadedInfluencerAgents,
        loadedSubCategories,
        loadedColors,
        loadedArticles,
        clearConversions,
        fetchAllConversions,
        fetchAllInfluencers,
        fetchAllBrands,
        fetchAllCategories,
        fetchNewCategories,
        fetchAllBrandsToValidate,
        fetchAllSuggestedBrands,
        fetchAllInfluencerAgents,
        fetchAllSubCategories,
        fetchAllColors,
        fetchAllSoldProducts,
        fetchAllAgency,
        fetchAllAgencyToValidate,
        clearBrandAgent,
        fetchInfluencerAgentsToValidate,
        loadedAgentsToValidate,
        clearInfluencerAgent,
        getInfluencersBills,
        loadedInfluencersBills,
        loadedBrandBills,
        getBrandsBills,
    } = props;

    const [influencerToView, setInfluencerToView] = useState(null);


    useEffect(() => {
        if (!loadedInfluencers) fetchAllInfluencers();
    }, [loadedInfluencers, fetchAllInfluencers]);

    useEffect(() => {
        if (!loadedBrands) fetchAllBrands();
    }, [loadedBrands, fetchAllBrands]);

    useEffect(() => {
        if (!loadedAllConversions) fetchAllConversions();
    }, [loadedAllConversions, fetchAllConversions]);

    useEffect(() => {
        if (!loadedAllBrandsToValidate) fetchAllBrandsToValidate();
    }, [loadedAllBrandsToValidate, fetchAllBrandsToValidate]);

    useEffect(() => {
        if (!loadedSuggestedBrands) fetchAllSuggestedBrands();
    }, [loadedSuggestedBrands, fetchAllSuggestedBrands]);

    useEffect(() => {
        if (!loadedCategories) fetchAllCategories();
    }, [loadedCategories, fetchAllCategories]);

    useEffect(() => {
        if (!loadedNewCategories) fetchNewCategories();
    }, [loadedNewCategories, fetchNewCategories]);

    useEffect(() => {
        if (!loadedAllBrandAgent) fetchAllAgency();
    }, [loadedAllBrandAgent, fetchAllAgency]);

    useEffect(() => {
        if (!loadedAllBrandAgentTovalidate) fetchAllAgencyToValidate();
    }, [loadedAllBrandAgentTovalidate, fetchAllAgencyToValidate]);

    useEffect(() => {
        if (!loadedInfluencerAgents) fetchAllInfluencerAgents();
    }, [loadedInfluencerAgents, fetchAllInfluencerAgents]);

    useEffect(() => {
        if (!loadedAgentsToValidate) fetchInfluencerAgentsToValidate();
    }, [loadedAgentsToValidate, fetchInfluencerAgentsToValidate]);

    useEffect(() => {
        if (!loadedSubCategories) fetchAllSubCategories()
    }, [loadedSubCategories, fetchAllSubCategories]);

    useEffect(() => {
        if (!loadedColors) fetchAllColors();
    }, [loadedColors, fetchAllColors]);

    useEffect(() => {
        if (!loadedArticles) fetchAllSoldProducts();
    }, [loadedArticles, fetchAllSoldProducts]);

    useEffect(() => {
        if (!loadedInfluencersBills) getInfluencersBills();
    }, [loadedInfluencersBills, getInfluencersBills]);

    useEffect(() => {
        if (!loadedBrandBills) getBrandsBills();
    }, [loadedBrandBills, getBrandsBills]);

    const handleViewInfluencer = (influencer) => {
        setInfluencerToView(influencer)
    }

    const handleInfluencerToView = (influencerId) => {
        dbManager.fetchInfluencerById(influencerId).then((influencer) => {
            setInfluencerToView(influencer);
        });
    };

    const redirectTrend = (brandName) => {
        props.redirectTrendAdmin(brandName);
    }

    return (
        <Switch>
            <Route
                path={`${url}/logout`}
                exact
                render={() => {
                    clearConversions();
                    clearBrandAgent();
                    clearInfluencerAgent();
                    auth.signOut();
                    return <Redirect to='/' />;
                }}>
            </Route>
            <Route path={`${url}/dashboard`} exact>
                <Dashboard />
            </Route>
            <Route path={`${url}/influencers`} exact>
                <Influenceurs {...props} influencerSearch={influencerToView} />
            </Route>
            <Route path={`${url}/partners`} exact>
                <Partners {...props} url={url} viewInfluencer={(influencer) => handleViewInfluencer(influencer)} redirectTrendRouter={(brandName) => redirectTrend(brandName)} />
            </Route>
            <Route path={`${url}/statistics`} exact>
                <Statistics {...props} handleInfluencerToView={(influencerId) => handleInfluencerToView(influencerId)} />
            </Route>
            <Route path={`${url}/categories`} exact>
                <Categories />
            </Route>
            {/*<Route path={`${url}/factures`} exact>
                <Factures searchValue={filterValue} />
            </Route>*/}
            <Route path={`${url}/influencers/export`} exact>
                <ExporterInfluenceurs />
            </Route>
            <Route path={`${url}/brands/export`} exact>
                <ExportBrands />
            </Route>
            <Route path={`${url}`}>
                <Redirect to={`${url}/dashboard`} />
            </Route>
        </Switch>
    );
};

const mapStateToProps = (state, ownProps) => {
    const loadedInfluencers = state.influencersState.loadedInfluencers;
    const loadedAllConversions = state.conversionsState.loadedAllConversions;
    const loadedBrands = state.brandsState.loadedBrands;
    const loadedAllBrandsToValidate = state.brandsState.loadedAllBrandsToValidate;
    const loadedSuggestedBrands = state.brandsState.loadedSuggestedBrands;
    const loadedCategories = state.categoriesState.loadedCategories;
    const loadedNewCategories = state.categoriesState.loadedNewCategories;
    const loadedAllBrandAgent = state.brandsAgentState.loadedAllBrandAgent;
    const loadedAllBrandAgentTovalidate = state.brandsAgentState.loadedAllBrandAgentTovalidate;
    const loadedInfluencerAgents = state.influencerAgentsState.loadedInfluencerAgents;
    const loadedAgentsToValidate = state.influencerAgentsState.loadedAgentsToValidate;
    const loadedSubCategories = state.categoriesState.loadedSubCategories;
    const loadedColors = state.colorsState.loadedColors;
    const loadedArticles = state.articlesState.loadedArticles;
    const loadedInfluencersBills = state.billsState.loadedInfluencersBills;
    const loadedBrandBills = state.billsState.loadedBrandBills;

    return {
        loadedInfluencers,
        loadedAllConversions,
        loadedBrands,
        loadedAllBrandsToValidate,
        loadedSuggestedBrands,
        loadedCategories,
        loadedNewCategories,
        loadedInfluencerAgents,
        loadedSubCategories,
        loadedColors,
        loadedArticles,
        loadedAllBrandAgent,
        loadedAllBrandAgentTovalidate,
        loadedAgentsToValidate,
        loadedInfluencersBills,
        loadedBrandBills,
    }
}

const mapDispatchToProps = (dispatch) => {
    return {
        fetchAllInfluencers: () => dispatch(fetchAllInfluencers()),
        fetchAllConversions: () => dispatch(fetchAllConversions()),
        fetchAllBrands: () => dispatch(fetchAllBrands()),
        fetchAllCategories: () => dispatch(fetchAllCategories()),
        fetchNewCategories: () => dispatch(fetchNewCategories()),
        clearConversions: () => dispatch({ type: 'CLEAR_CONVERSIONS' }),
        fetchAllBrandsToValidate: () => dispatch(fetchAllBrandsToValidate()),
        fetchAllSuggestedBrands: () => dispatch(fetchAllSuggestedBrands()),
        fetchAllInfluencerAgents: () => dispatch(fetchAllInfluencerAgents()),
        fetchAllSubCategories: () => dispatch(fetchAllSubCategories()),
        fetchAllColors: () => dispatch(fetchAllColors()),
        fetchAllSoldProducts: () => dispatch(fetchAllSoldArticle()),
        fetchAllAgency: () => dispatch(fetchAllAgency()),
        fetchAllAgencyToValidate: () => dispatch(fetchAllAgencyToValidate()),
        clearBrandAgent: () => dispatch({ type: 'CLEAR_BRAND_AGENT' }),
        fetchInfluencerAgentsToValidate: () => dispatch(fetchInfluencerAgentsToValidate()),
        clearInfluencerAgent: () => dispatch({ type: 'CLEAR_INFLUENCER_AGENT' }),
        getInfluencersBills: () => dispatch(getInfluencersBills()),
        getBrandsBills: () => dispatch(getBrandsBills()),

    }
}

export default connect(mapStateToProps, mapDispatchToProps)(Router);
