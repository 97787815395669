import React from "react";
import PropTypes from "prop-types";
import CloseIcon from "@material-ui/icons/Close";
import { makeStyles } from "@material-ui/core";

const useStyles = makeStyles(theme => ({
  wrapper: {
    zIndex: theme.zIndex.modal + 1,
    position: "absolute",
    top: 5,
    right: 5,
  },
  hoverPointer: {
    "&:hover": {
      cursor: "pointer",
    },
  },
}));

const Back = ({ onClick, style, size = "inherit", color = "inherit" }) => {
  const classes = useStyles();
  return (
    <div className={classes.wrapper} style={{ ...style, color: color }}>
      <CloseIcon
        className={classes.hoverPointer}
        onClick={onClick}
        fontSize={size}
        color='inherit'
      />
    </div>
  );
};

Back.propTypes = {
  onClick: PropTypes.func,
  style: PropTypes.object,
  size: PropTypes.string,
  color: PropTypes.string,
};

export default Back;
