import React, { useEffect, useState } from 'react';
import Agencies from './partners-sub-views/Agencies';
import Agents from './partners-sub-views/Agents';
import Brands from './partners-sub-views/Brands';
import Distributors from './partners-sub-views/Distributors';

const Partners = (props) => {

    const [partnerTypeToLoad, setPartnerTypeToLoad] = useState('brands');

    const filterValue = props.filterValue;

    useEffect(() => {
        setPartnerTypeToLoad(props.partnerTypeToLoad);
    }, [props.partnerTypeToLoad]);

    const handleViewInfluencer = (influencer) => {
        props.viewInfluencer(influencer);
    }

    const redirectTrend = (brandName) => {
        props.redirectTrendRouter(brandName);
    }

    const getPartnerComponent = (partnerTypeToLoad) => {
        switch (partnerTypeToLoad) {
            case 'brands':
                return <Brands viewInfluencer={(influencer) => handleViewInfluencer(influencer)} filterValue={filterValue} history={props.history} redirectTrendPartners={(brandName) => { redirectTrend(brandName) }} />
            case 'agents':
                return <Agents filterValue={filterValue} />
            case 'agencies':
                return <Agencies filterValue={filterValue} />
            case 'distributors':
                return <Distributors />
            default:
                return <></>
        }
    };

    const partnerComponentContent = getPartnerComponent(partnerTypeToLoad);

    return (
        partnerComponentContent
    )
}

export default Partners;