import React from "react";

const SVG = ({
    width = "100%",
    height = '50px',
    fill = 'black'
}) => (
        <svg xmlns="http://www.w3.org/2000/svg" xmlnsXlink="http://www.w3.org/1999/xlink" version="1.1" x="0px" y="0px" viewBox="0 0 36 45"
            style={{enableBackground: 'new 0 0 36 36'}} xmlSpace="preserve" width={width} height={height}>
            <g fill={fill}>
                <path d="M32.14,3.15l-3.39,3.39H17.65c-0.13,0-0.26,0.05-0.35,0.15L3.15,20.83c-0.2,0.2-0.2,0.51,0,0.71l11.31,11.31   c0.1,0.1,0.23,0.15,0.35,0.15s0.26-0.05,0.35-0.15l14.14-14.14c0.09-0.09,0.15-0.22,0.15-0.35V7.25l3.39-3.39   c0.2-0.2,0.2-0.51,0-0.71S32.34,2.96,32.14,3.15z M28.46,18.15L14.82,31.79L4.21,21.18L17.85,7.54h9.9l-3.07,3.07   c-0.97-0.64-2.29-0.54-3.15,0.32c-0.47,0.47-0.73,1.1-0.73,1.77c0,0.67,0.26,1.29,0.73,1.77c0.49,0.49,1.13,0.73,1.77,0.73   s1.28-0.24,1.77-0.73c0.47-0.47,0.73-1.1,0.73-1.77c0-0.5-0.15-0.97-0.41-1.38l3.07-3.07V18.15z M24.8,12.7   c0,0.4-0.16,0.78-0.44,1.06c-0.58,0.58-1.54,0.58-2.12,0c-0.28-0.28-0.44-0.66-0.44-1.06c0-0.4,0.16-0.78,0.44-1.06   c0.29-0.29,0.68-0.44,1.06-0.44s0.77,0.15,1.06,0.44C24.65,11.92,24.8,12.3,24.8,12.7z" />
            </g>
        </svg>
    );

export default SVG;
