import React,{ useState,useEffect } from "react";
import { Link,Redirect,useLocation,useHistory } from "react-router-dom";
import { makeStyles,useTheme,withStyles } from "@material-ui/styles";
import clsx from "clsx";
import {
  useMediaQuery,
  AppBar,
  Toolbar,
  Typography,
  Grid,
  IconButton,
  Button,
  InputBase,
  Select,
  MenuItem,
} from "@material-ui/core";
import SearchIcon from "@material-ui/icons/Search";
import ExpandMoreIcon from "@material-ui/icons/ExpandMore";
import { useTranslation } from "react-i18next";
import { database,dbManager } from "../../../managers";

const iconStyles = {
  selectIcon: {
    color: "black",
  },
};
const CustomExpandMore = withStyles(iconStyles)(
  ({ className,classes,...rest }) => {
    return (
      <ExpandMoreIcon
        { ...rest }
        className={ clsx(className,classes.selectIcon) }
      />
    );
  }
);

const useStyles = makeStyles((theme) => ({
  appBar: {},
  toolBar: {
    height: theme.appBar.height,
    display: "flex",
    flexDirection: "column",
  },
  menuButton: {
    height: "auto",
    width: "30px",
    cursor: "pointer",
  },
  logo: {
    width: `calc(${theme.appBar.height} + 10px)`,
    marginLeft: "15px",
  },
  hoverPointer: {
    "&:hover": {
      cursor: "pointer",
    },
  },
  searchInputRoot: {
    flexGrow: 1,
    fontFamily: "Poppins",
  },
  searchInputBase: {
    paddingTop: 0,
    paddingBottom: 0,
  },
  selectClass: {
    backgroundColor: "transparent",
    fontFamily: "Poppins",
    minWidth: 100,
    "&:focus": {
      backgroundColor: "transparent",
    },
  },
  unverifiedAccountBox: {
    fontFamily: "Poppins",
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    margin: 10,
    borderRadius: 25,
    padding: 20,
    backgroundColor: "#e69665",
    height: "50%",
    color: "#fff",
  },
}));

const Header = (props) => {
  const { t } = useTranslation("common");
  const { user,menuVisible,toggleMenu,toggleSearch } = props;
  const [redirect,setRedirect] = useState(null);
  const [filterType,setFilterType] = useState("registrationDate");
  const [userClaims,setUserClaims] = useState();
  const [influencerAccount,setInfluencerAccount] = useState();
  const [unverifiedAccount,setUnverifiedAccount] = useState(false);
  const [profileButtonText,setProfileButtonText] = useState(t('MAIN.HEADER.CONNECT_BUTTON_TEXT'));
  const location = useLocation();
  const history = useHistory();
  const theme = useTheme();
  const isMdDown = useMediaQuery(theme.breakpoints.down("sm"));
  const classes = useStyles();
  const propsFilterType = props.filterType;

  const goToMyProfile = async () => {
    if (userClaims && userClaims.admin) {
      history.push("/admin");
      return;
    }
    if (
      userClaims &&
      (userClaims.isBrand || userClaims.brand) &&
      userClaims.brandOfferId
    ) {
      let brand;
      await dbManager
        .fetchBrandByOfferId(userClaims.brandOfferId)
        .then((brd) => {
          brand = brd;
        });
      if (userClaims.brandOfferId === brand.offerId) {
        history.push(`/trend/${brand.name}`);
        return;
      }
    }
    if (userClaims && userClaims.isInfluencerAgent) {
      history.push("/influencersAgent");
      return;
    }
    if (userClaims && userClaims.isBrandAgent) {
      history.push("/brandsAgent");
      return;
    }
    if (influencerAccount) {
      setRedirect(
        influencerAccount.name
          ? `/wishlists/${influencerAccount.name}${influencerAccount.code33 ? "/" + influencerAccount.code33 : ""
          }`
          : `/wishlists/${user.uid}`
      );
      return;
    }
  };

  const goToLogin = () => {
    history.push("login",{ previousRoute: location.pathname });
  };

  const handleInputChange = (event) => {
    props.influencerSearch(event.target.value);
  };

  useEffect(() => {
    if (user) {
      user
        .getIdTokenResult()
        .then((idTokenResult) => {
          setUserClaims(idTokenResult.claims);
        })
        .catch((err) => console.log(err));
    }
  },[user]);

  useEffect(() => {
    if (userClaims) {
      if (
        userClaims.admin ||
        userClaims.admin ||
        userClaims.isBrand ||
        userClaims.brand ||
        userClaims.isInfluencerAgent ||
        userClaims.isBrandAgent
      ) {
        setProfileButtonText(t("MAIN.HEADER.CUSTUM_USER_BUTTON_TEXT"));
      } else {
        if (
          userClaims.admin === undefined ||
          userClaims.admin === undefined ||
          userClaims.isBrand === undefined ||
          userClaims.brand === undefined ||
          userClaims.isInfluencerAgent === undefined ||
          userClaims.isBrandAgent === undefined
        ) {
          setUnverifiedAccount(false);
          setProfileButtonText(t("MAIN.HEADER.INFLUENCER_PROFILE_BUTTON"));
          database
            .ref(`influencers/${userClaims.user_id}`)
            .once("value")
            .then((snap) => {
              const inf = snap.val();
              (inf) ? setUnverifiedAccount(false) : setUnverifiedAccount(true);
              setInfluencerAccount(inf);
            });
        } else {
          setUnverifiedAccount(true);
        }
      }
    }
  },[userClaims,t]);

  useEffect(() => {
    propsFilterType(filterType);
  },[filterType,propsFilterType]);

  return (
    <>
      { redirect && <Redirect push to={ redirect } /> }
      <AppBar
        position="fixed"
        style={
          location.pathname.toLowerCase() === "/display/allinfluencers"
            ? isMdDown
              ? {
                height: "140px",
                backgroundColor: theme.background.main,
                boxShadow: "none",
              }
              : {
                height: theme.appBar.height,
                backgroundColor: theme.background.main,
                boxShadow: "none",
              }
            : {
              height: theme.appBar.height,
              backgroundColor: theme.background.main,
              boxShadow: "none",
            }
        }
      >
        <Toolbar className={ classes.toolBar }>
          <Grid
            container
            item
            direction="row"
            alignItems="center"
            justify="center"
            xs={ 12 }
          >
            <Grid
              container
              justify="flex-start"
              style={ { flex: isMdDown ? "1" : "0",minWidth: "40px" } }
            >
              <img
                src={
                  menuVisible
                    ? "https://res.cloudinary.com/tellmedia/image/upload/v1568109039/shopmyinfluens/icons/Close.svg"
                    : "https://res.cloudinary.com/tellmedia/image/upload/v1566481795/shopmyinfluens/icons/menu_burger.svg"
                }
                alt="Ouvrir le menu"
                className={ classes.menuButton }
                onClick={ toggleMenu }
              />
            </Grid>
            <Link to="/home">
              <img
                src="https://firebasestorage.googleapis.com/v0/b/shopmyinfluens.appspot.com/o/shopmyinfluens%2FApp%20Data%2Flogo_shopmyinfluence-01.png?alt=media"
                alt="Logo"
                className={ classes.logo }
              />
            </Link>
            <Grid
              container
              justify="flex-end"
              alignItems="center"
              style={ { flex: 1,height: "100%" } }
            >
              { location.pathname === "/display/allinfluencers" ? (
                !isMdDown ? (
                  <div
                    style={ {
                      display: "flex",
                      flexGrow: 1,
                      justifyContent: "flex-end",
                      alignItems: "center",
                      height: "100%",
                    } }
                  >
                    <div
                      style={ {
                        border: "solid 2px #656363a6",
                        marginRight: 15,
                        marginLeft: 35,
                        paddingLeft: 10,
                        paddingRight: 10,
                        display: "flex",
                        alignItems: "center",
                        borderRadius: "20px",
                        height: "50%",
                      } }
                    >
                      <SearchIcon style={ { color: "#656363a6" } } />
                      <InputBase
                        className={ classes.searchInputRoot }
                        classes={ { input: classes.searchInputBase } }
                        onChange={ handleInputChange }
                        placeholder="Recherche"
                      />
                    </div>
                    <Select
                      classes={ { root: classes.selectClass } }
                      disableUnderline
                      value={ filterType }
                      onChange={ (e) => setFilterType(e.target.value) }
                      IconComponent={ CustomExpandMore }
                    >
                      <MenuItem
                        disabled
                        selected
                        value="default"
                        style={ { fontFamily: "Poppins" } }
                      >
                        Trier par
                      </MenuItem>
                      <MenuItem value="name" style={ { fontFamily: "Poppins" } }>
                        Nom
                      </MenuItem>
                      <MenuItem
                        value="registrationDate"
                        style={ { fontFamily: "Poppins" } }
                      >
                        Date d'inscription
                      </MenuItem>
                      <MenuItem value="sales" style={ { fontFamily: "Poppins" } }>
                        Pertinence
                      </MenuItem>
                    </Select>
                  </div>
                ) : (
                  <></>
                )
              ) : (
                <div
                  style={ {
                    color: "black",
                    display: "flex",
                    alignItems: "center",
                  } }
                >
                  <SearchIcon
                    color="inherit"
                    onClick={ toggleSearch }
                    className={ classes.hoverPointer }
                  />
                </div>
              ) }
              { isMdDown ? (
                unverifiedAccount ? (
                  <></>
                ) : (
                  <IconButton onClick={ user ? goToMyProfile : goToLogin }>
                    <img
                      src="https://res.cloudinary.com/tellmedia/image/upload/c_scale,w_50/v1545641905/man-user_2_y5leib.svg"
                      alt="connexion utilisateur"
                      style={ { width: "30px",height: "auto" } }
                    />
                  </IconButton>
                )
              ) : unverifiedAccount ? (
                <div className={ classes.unverifiedAccountBox }>
                  { t("MAIN.HEADER.UNVERIFIED_ACCOUNT") }
                </div>
              ) : (
                <Button
                  disableFocusRipple
                  disableRipple
                  onClick={ user ? goToMyProfile : goToLogin }
                >
                  <Typography style={ { fontWeight: "bold" } }>
                    { profileButtonText }
                  </Typography>
                </Button>
              ) }
            </Grid>
          </Grid>
          { location.pathname === "/display/allinfluencers" ? (
            isMdDown ? (
              <Grid container item justify="center" xs={ 12 }>
                <div
                  style={ {
                    border: "solid 2px #656363a6",
                    paddingLeft: 10,
                    paddingRight: 10,
                    display: "flex",
                    alignItems: "center",
                    borderRadius: "20px",
                    marginTop: 10,
                    marginBottom: 10,
                  } }
                >
                  <SearchIcon style={ { color: "#656363a6" } } />
                  <InputBase
                    className={ classes.searchInputRoot }
                    classes={ { input: classes.searchInputBase } }
                    onChange={ handleInputChange }
                    placeholder="Recherche"
                  />
                </div>
                <Select
                  classes={ { root: classes.selectClass } }
                  disableUnderline
                  value={ filterType }
                  onChange={ (e) => setFilterType(e.target.value) }
                  IconComponent={ CustomExpandMore }
                >
                  <MenuItem
                    disabled
                    selected
                    value="default"
                    style={ { fontFamily: "Poppins" } }
                  >
                    Trier par
                  </MenuItem>
                  <MenuItem value="name" style={ { fontFamily: "Poppins" } }>
                    Nom
                  </MenuItem>
                  <MenuItem
                    value="registrationDate"
                    style={ { fontFamily: "Poppins" } }
                  >
                    Date d'inscription
                  </MenuItem>
                  <MenuItem value="sales" style={ { fontFamily: "Poppins" } }>
                    Pertinence
                  </MenuItem>
                </Select>
              </Grid>
            ) : (
              <></>
            )
          ) : (
            <></>
          ) }
        </Toolbar>
      </AppBar>
    </>
  );
};

export default Header;
