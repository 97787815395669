import React,{
  useEffect,
  useState,
} from "react";

// UI Dependencies
import { LoadingScreen } from "../ui";

// Component Scope
import Router from "./Router";
import Template from "./template";
import { connect } from "react-redux";
import { fetchNonPrivateInfluencers,fetchAllInfluencers } from "../../store/actions/influencersActions";

const Main = (props) => {

  const {
    influencers,
    loadedInfluencers,
    fetchNonPrivateInfluencers,
    headerProps,
    user,
    fetchAllInfluencers,
    loadedAllInfluencers,
    allInfluencers,
  } = props;

  const [loading,setLoading] = useState(true);
  const [searchOpen,setSearchOpen] = useState(false);
  const [credentialsOpen,setCredentialsOpen] = useState(false);
  const [menuOpen,setMenuOpen] = useState(false);
  const [filterType,setFilterType] = useState('');
  const [searchValue,setSearchValue] = useState('');
  const [influencersToRenderOnDisplay,setInfluencersToRenderOnDisplay] = useState([]);
  const [influencersToRenderOnMansory,setInfluencersToRenderOnMansory] = useState([]);

  useEffect(() => {
    if (!loadedInfluencers) fetchNonPrivateInfluencers();
  },[loadedInfluencers,fetchNonPrivateInfluencers]);

  useEffect(() => {
    if (!loadedAllInfluencers) fetchAllInfluencers();
  },[loadedAllInfluencers,fetchAllInfluencers])

  useEffect(() => {
    if (loadedAllInfluencers) setInfluencersToRenderOnDisplay(allInfluencers.filter(inf => inf.name && inf.banner));
  },[allInfluencers,loadedAllInfluencers]);


  useEffect(() => {
    if (loadedInfluencers) {
      let temp = influencers.filter(influencer => (influencer.banner && influencer.name && !influencer.hasEmptyWishlist));
      setInfluencersToRenderOnMansory(temp)
    }
  },[influencers,loadedInfluencers]);

  useEffect(() => {
    setLoading((loadedInfluencers && loadedAllInfluencers) ? false : true);
  },[loadedInfluencers,loadedAllInfluencers]);

  useEffect(() => {
    if (allInfluencers !== undefined) {
      let temp = allInfluencers.filter(inf => inf.name && inf.banner).filter(inf => (
        inf.name.toLowerCase().includes(searchValue.toLowerCase())
      ));
      switch (filterType) {
        case 'name':
          temp.sort((a,b) => {
            if (a.name.toLowerCase() > b.name.toLowerCase()) {
              return 1;
            }
            if (b.name.toLowerCase() > a.name.toLowerCase()) {
              return -1
            }
            return 0;
          });
          setInfluencersToRenderOnDisplay([...temp]);
          break;
        case 'registrationDate':
          temp.sort((a,b) => {
            if (Number(a.createdAt) < Number(b.createdAt)) {
              return 1;
            }
            if (Number(b.createdAt) < Number(a.createdAt)) {
              return -1
            }
            return 0;
          });
          setInfluencersToRenderOnDisplay([...temp]);
          break;
        case 'sales':
          let influencerScore = temp.map(influencer => { if (influencer.score === undefined) { return { ...influencer,score: 0 } } else return influencer });
          influencerScore.sort((a,b) => {
            if (a.score > b.score) {
              return -1;
            }
            if (a.score < b.score) {
              return 1
            }
            return 0;
          });
          setInfluencersToRenderOnDisplay([...influencerScore]);
          break;
        default:
          setInfluencersToRenderOnDisplay([...temp])
          break;
      }
    }
  },[filterType,searchValue,allInfluencers]);

  /**
   * @desc Toggle the credentials screen
   */
  const handleToggleCredentials = () => {
    setCredentialsOpen((open) => !open);
  };
  /**
   * @desc Toggle search
   */
  const handleToggleSearch = () => {
    setSearchOpen((open) => !open);
  };
  /**
   * @desc Toggle menu
   */
  const handleToggleMenu = () => {
    setMenuOpen((open) => !open);
  };

  const handleFilterType = (filterValue) => {
    setFilterType(filterValue);
  };

  const handleInfluencerSearch = (searchValue) => {
    setSearchValue(searchValue);
  };

  return (
    <>
      { !loading ? (
        <Template
          filterType={ handleFilterType }
          incluencerSearch={ handleInfluencerSearch }
          credProps={ {
            visible: credentialsOpen,
            toggle: handleToggleCredentials,
          } }
          headerProps={ {
            user: user,
            menuVisible: menuOpen,
            toggleSearch: handleToggleSearch,
            toggleCredentials: handleToggleCredentials,
            toggleMenu: handleToggleMenu,
          } }
          menuProps={ {
            user: user,
            menuVisible: menuOpen,
            toggleMenu: handleToggleMenu,
          } }
          searchProps={ {
            open: searchOpen,
            toggle: handleToggleSearch,
            data: {
              influencers: influencersToRenderOnMansory,
            },
          } }
        >
          <Router
            headerProps={ headerProps }
            influencers={ influencersToRenderOnDisplay }
            allInfs={ influencersToRenderOnMansory }
            user={ user }
          />
        </Template>
      ) : (
        <LoadingScreen />
      ) }
    </>
  );
};

const mapStateToProps = (state,ownProps) => {
  const influencers = state.influencersState.nonPrivateInfluencers;
  const loadedInfluencers = state.influencersState.loadedNonPrivateInfluencers;
  const loadedAllInfluencers = state.influencersState.loadedInfluencers;
  const allInfluencers = state.influencersState.influencers;

  return {
    influencers,
    loadedInfluencers,
    loadedAllInfluencers,
    allInfluencers,
  }
}

const mapDispatchToProps = (dispatch) => {
  return {
    fetchNonPrivateInfluencers: () => dispatch(fetchNonPrivateInfluencers()),
    fetchAllInfluencers: () => dispatch(fetchAllInfluencers()),

  }
}

export default connect(mapStateToProps,mapDispatchToProps)(Main);
