import React,{ useState,useEffect,Children,cloneElement } from "react";
import { Drawer,Hidden,useTheme,useMediaQuery,makeStyles } from "@material-ui/core";
import Header from '../template/components/Header';
import { SideBar } from "./components";
import { useLocation,useRouteMatch } from "react-router-dom";

const useStyles = makeStyles((theme) => {
  const drawerWidth = '280px';
  return {
    drawer: {
      width: drawerWidth,
      flexShrink: 0,
      height: '100%',
      borderStyle: 'none'
    },
    drawerPaper: {
      width: drawerWidth,
      justifyContent: 'flex-start',
      borderStyle: 'none !important'
    },
    content: {
      minHeight: '100vh',
      width: '100%',
      backgroundColor: "#eff9fe",
      display: 'flex',
      flexDirection: 'column',
    },
    influencerContent: {
      minHeight: '100vh',
      width: '100%',
      backgroundColor: "#eff9fe",
      display: 'flex',
      flexDirection: 'column',
    }
  }
});



const Template = (props) => {
  const classes = useStyles();
  const { url } = useRouteMatch();
  const theme = useTheme();
  const xsDown = useMediaQuery(theme.breakpoints.down("xs"));
  const smUp = useMediaQuery(theme.breakpoints.up('sm'));
  const [mobileMode,setMobileMode] = useState(false);
  const [openDrawer,setOpenDrawer] = useState(false);
  const [influencerSearch,setInfluencerSearch] = useState(null);
  const [filterValue,setFilterValue] = useState('');
  let location = useLocation();
  const actualTab = location.pathname.split('/').pop();
  const { activeButtonInfluencer,admin,claims } = props;

  useEffect(() => {
    if (xsDown) {
      setMobileMode(true);
      setOpenDrawer(false);
    }
    if (smUp) setMobileMode(false);
  },[xsDown,smUp]);

  const handleCloseDrawer = () => {
    setOpenDrawer(false);
  }

  const navigationChange = () => {
    setOpenDrawer(false);
  }

  const handleInfluencerPopupRedirection = (influencer) => {
    setInfluencerSearch(influencer);
  }

  const handleInfluencerTableSearch = (searchValue) => {
    setFilterValue(searchValue)
  }
  const redirectAdminTrend = () => {
    props.redirectAdmin();
  }

  const childrenWithProps = Children.map(props.children,(child,index) => {
    return cloneElement(child,{ influencerSearch,filterValue });
  });
  return (
    <>
      <Hidden smUp={ smUp }>
        <Drawer
          className={ classes.drawer }
          variant='temporary'
          open={ openDrawer }
          classes={ { paper: classes.drawerPaper } }
          anchor='left'
          onClose={ handleCloseDrawer }
        >
          <SideBar mobileMode={ mobileMode } { ...props } navEvent={ navigationChange } userClaims={ claims } />
        </Drawer>
      </Hidden>
      <Hidden xsDown={ xsDown }>
        <Drawer
          className={ classes.drawer }
          variant='permanent'
          classes={ { paper: classes.drawerPaper } }
          anchor='left'
          open={ openDrawer }
        >
          <SideBar mobileMode={ mobileMode } { ...props } navEvent={ navigationChange } admin={ admin } userClaims={ claims } redirectAdmin={ () => { redirectAdminTrend() } } />
        </Drawer>
      </Hidden>
      <main className={ (actualTab === 'influencers') ? classes.influencerContent : classes.content }>
        <Header
          opendrawer={ () => { setOpenDrawer(true) } }
          { ...{ ...props,mobileMode,relativeUrl: url } }
          openInfluencerInfos={ (influencer) => handleInfluencerPopupRedirection(influencer) }
          influencerActiveButton={ activeButtonInfluencer }
          searchInfluencer={ (searchValue) => handleInfluencerTableSearch(searchValue) }
        />
        <>{ childrenWithProps }</>
      </main>
    </>
  );
};

export default Template;
