import React, { useState, useCallback, useEffect } from 'react';
import { connect } from "react-redux";
import { Grid, makeStyles, Typography, IconButton, FormControl, InputBase, useTheme, useMediaQuery, Dialog, Button, Box, CircularProgress, Backdrop } from "@material-ui/core";
import CropperDialog from './Cropper';
import { refuseInfluencerAgentById, validateInfluencerAgentById, updateInfluencerAgentbyId } from '../../../../store/actions/influencerAgentsActions';
import AssociateInfluencersToAgent from "./AssociateInfluencersToAgent";
import axios from "axios";
import WarningDialog from "./WarningDialog";
import CloseIcon from '@material-ui/icons/Close';
import { useTranslation } from 'react-i18next';
import AddIcon from '@material-ui/icons/Add';

const useStyles = makeStyles((theme) => ({
    overallBox: {
        backgroundColor: '#fff',
        borderRadius: '35px',
        boxShadow: '0 7px 30px 0 rgba(88, 165, 211, 0.16)',
        margin: '15px'
    },
    formBox: {
        marginTop: '10px',
        marginBottom: '10px'
    },
    formTitle: {
        fontFamily: 'Poppins',
        fontSize: '22px',
        margin: '10px',
        marginTop: '-50px'
    },
    formControlRow: {
        marginTop: '5px',
        marginBottom: '5px'
    },
    formLabel: {
        fontFamily: 'Poppins',
        marginLeft: '20px'
    },
    formControl: {
        width: '100%'
    },
    formInput: {
        fontFamily: 'Poppins',
        borderRadius: '35px',
        boxShadow: '0 7px 30px 0 rgba(69, 128, 163, 0.16)',
        height: '50px',
        width: '100%',
        paddingTop: '10px',
        paddingBottom: '10px',
        paddingLeft: '20px',
        margin: '5px'
    },
    selectClass: {
        fontFamily: 'Poppins',
        paddingRight: '0px !important',
        '&:focus': {
            backgroundColor: 'white'
        },
        display: 'flex',
        flexShrink: 1,
    },
    select: {
        marginTop: '10px',
        marginBottom: '10px',
    },
    selectedMenuItems: {
        backgroundColor: '#50bef3 !important',
        color: '#fff'
    },
    switchLabel: {
        fontFamily: 'Poppins',
    },
    cancelEditButton: {
        width: '200px',
        height: '60px',
        fontFamily: 'Poppins',
        margin: '5px',
        borderRadius: '30px',
        boxShadow: '0 7px 30px 0 rgba(88, 165, 211, 0.16)',
    },
    validateEditButton: {
        width: '200px',
        height: '60px',
        fontFamily: 'Poppins',
        margin: '5px',
        borderRadius: '30px',
        boxShadow: '0 7px 30px 0 rgba(88, 165, 211, 0.16)',
        color: '#fff',
        backgroundColor: '#d96e98',
        '&:hover': {
            backgroundColor: '#d96e98'
        }
    },
    profilePicturePlaceholder: {
        fontFamily: 'Poppins',
        color: '#717171',
        width: 'calc(100% - 150px)',
        paddingLeft: '15px'
    },
    activeProfilePicturePlaceholder: {
        fontFamily: 'Poppins',
        width: 'calc(100% - 150px)',
        paddingLeft: '15px'
    },
    profilePicturePlaceholderMobile: {
        fontFamily: 'Poppins',
        color: '#717171',
        width: '70%',
        margin: 5,
        textAlign: 'center',
    },
    activeProfilePicturePlaceholderMobile: {
        fontFamily: 'Poppins',
        width: '70%',
        margin: 5,
        textAlign: 'center',
    },
    profileImageLabel: {
        fontFamily: 'Poppins',
        width: '150px',
        height: '40px',
        textTransform: 'none',
        backgroundColor: '#58a5d3',
        color: '#fff',
        borderRadius: '35px',
        '&:hover': {
            cursor: 'pointer',
            backgroundColor: '#58a5d3',
        },
        '&:focus': {
            outlineColor: 'rgba(69, 128, 163, 0)'
        },
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        position: 'relative',
    },
    errorMessage: {
        fontFamily: "Poppins",
        color: "red",
    },
    influencerImage: {
        width: "160px",
        height: "80px",
        objectFit: "fill",
        borderRadius: "20px"
    },
    AllBrandBox: {
        borderRadius: "41px",
        boxShadow: "0 2px 30px 0 rgba(58, 111, 142, 0.21)",
        marginTop: "15px",
        marginBottom: "30px",
    },
    brandBox: {
        margin: "5px",
        borderRadius: "20px",
        boxShadow: "0 2px 30px 0 rgba(58, 111, 142, 0.21)",
        width: "160px",
        minHeight: "105px",
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
        flexDirection: "column"
    },
    overlay: {
        zIndex: theme.zIndex.drawer + 1
    },
    refuseButton: {
        width: '200px',
        height: '40px',
        fontFamily: 'Poppins',
        margin: '5px',
        borderRadius: '30px',
        backgroundColor: 'red',
        color: '#fff',
        boxShadow: '0 7px 30px 0 rgba(88, 165, 211, 0.16)',
        '&:hover': {
            backgroundColor: 'red'
        }
    },
    validateButton: {
        width: '200px',
        height: '40px',
        fontFamily: 'Poppins',
        margin: '5px',
        borderRadius: '30px',
        boxShadow: '0 7px 30px 0 rgba(88, 165, 211, 0.16)',
        color: '#fff',
        backgroundColor: '#d96e98',
        '&:hover': {
            backgroundColor: '#d96e98'
        }
    },
    placeholderStyles: {
        fontFamily: 'Poppins',
        color: '#1B4C61'
    },
    MyIconButton: {
        '& svg': {
            fontSize: "70px",
        }
    }
})
);


const EditAgentModal = (props) => {
    const classes = useStyles();
    const { t } = useTranslation("common");
    const theme = useTheme();
    const xsDown = useMediaQuery(theme.breakpoints.down('xs'));
    const mdDown = useMediaQuery(theme.breakpoints.down('md'));

    const [anchorEl, setAnchorEl] = useState(null);
    const [overlayState, setOverlayState] = useState(false);
    const [uploadBasicImageLoading, setUploadBasicImageLoading] = useState(false);
    const [basicBrandPictureValue, setBasicBrandPictureValue] = useState('');
    const [base64BasicLogo, setBase64BasicLogo] = useState('');
    const [imageWidthAspect, setImageWidthAspect] = useState(1);
    const [imageHeightAspect, setImageHeightAspect] = useState(1);
    const [imageToCrop, setImageToCrop] = useState(null);
    const [openBasicDialog, setOpenBasicDialog] = useState(false);
    const [agentNameTitle, setAgentNameTitle] = useState('');
    const [nameInputValue, setNameInputValue] = useState('')
    const [emailInputValue, setEmailInputValue] = useState('');
    const [agencyNameInputValue, setAgencyNameInputValue] = useState('');
    const [websiteInputValue, setWebSiteInputValue] = useState('');
    const [addressInputValue, setAddressInputValue] = useState('');
    const [siretInputValue, setSiretInputValue] = useState('');
    const [informationInfluencersIds, setInformationInfluencersIds] = useState([]);
    const [unvalidatedFields, setUnvalidatedFields] = useState([]);
    const [validatingWarningState, setValidatingWarningState] = useState(false);
    const [updateClicked, setUpdateClicked] = useState(false);
    const [titleWarning, setTitleWarning] = useState("");
    const [associateMode, setAssociateMode] = useState(false);
    const [newInfluencers, SetNewInfluencers] = useState();
    const [informationPicture, setInformationPicture] = useState([]);

    const {
        closeModal,
        agentToEdit,
        agentToValidate,
        loadingRefuseInfluencerAgent,
        refuseInfluencerAgentById,
        loadingValidateInfluencerAgent,
        validateInfluencerAgentByIdRequest,
        validateInfluencerAgentById,
        updateInfluencerAgentbyId,
        loadingUpdateInfluencerAgent,
        loadedInfluencers,
        influencers,
    } = props;

    const handleCloseModal = useCallback(() => {
        closeModal(true);
    }, [closeModal]);

    const handleCloseWarningDialog = () => {
        setValidatingWarningState(false);
        setUnvalidatedFields([]);
    };

    const uploadBasicImage = async (requestBody) => {
        return axios.post("https://us-central1-shopmyinfluens.cloudfunctions.net/firebaseStorage/storage/upload", requestBody)
    };

    const handleSaveBasicDialog = (croppedImage) => {
        setOpenBasicDialog(false);
        setBase64BasicLogo(croppedImage);
    };
    const handleCancelBasicDialog = () => {
        setOpenBasicDialog(false);
        setBasicBrandPictureValue('');
        setBase64BasicLogo('');
    };

    const convertToBase64 = (file) => {
        return new Promise((resolve, reject) => {
            const fileReader = new FileReader();
            fileReader.readAsDataURL(file);
            fileReader.onload = () => {
                resolve(fileReader.result)
            };
            fileReader.onerror = (error) => {
                reject(error);
            }
        });
    };

    const convertBasicImage = async (event) => {
        const file = event.target.files[0];
        if (file !== undefined) {
            await convertToBase64(file).then(result => {
                setImageToCrop(result);
                setOpenBasicDialog(true);
            });
        } else {
            setBase64BasicLogo('');
            setBasicBrandPictureValue('');
        }

    };

    const handleValueChange = id => event => {
        if (id === 'brandBasicPicture') {
            setImageWidthAspect(1);
            setImageHeightAspect(1);
            setBasicBrandPictureValue(event.target.value);
            convertBasicImage(event);
        }
        if (id === 'agencyName') setAgencyNameInputValue(event.target.value);
        if (id === 'agentName') setNameInputValue(event.target.value);
        if (id === 'agentEmail') setEmailInputValue(event.target.value);
        if (id === 'agentSiret') setSiretInputValue(event.target.value);
        if (id === 'agentAddress') setAddressInputValue(event.target.value);
        if (id === 'website') setWebSiteInputValue(event.target.value);
    };

    const validateInfluencerAgent = async () => {
        let validatingArray = [];
        if (nameInputValue === "") validatingArray.push(t('ADMIN.VIEWS.PARTNERS.AGENTS.INFLUENCER_AGENT_FORM.AGENT_NAME'));
        if (agencyNameInputValue === "") validatingArray.push(t('ADMIN.VIEWS.PARTNERS.AGENTS.INFLUENCER_AGENT_FORM.AGENCE_NAME'));
        if (addressInputValue === "") validatingArray.push(t('ADMIN.VIEWS.PARTNERS.AGENTS.INFLUENCER_AGENT_FORM.ADDRESS'));
        if (siretInputValue === "") validatingArray.push(t('ADMIN.VIEWS.PARTNERS.AGENTS.INFLUENCER_AGENT_FORM.SIRET'));
        if (websiteInputValue === "") validatingArray.push(t('ADMIN.VIEWS.PARTNERS.AGENTS.INFLUENCER_AGENT_FORM.WEBSITE'));
        if (agentToValidate.banner === undefined && base64BasicLogo === '') validatingArray.push(t('ADMIN.VIEWS.PARTNERS.AGENTS.INFLUENCER_AGENT_FORM.IMAGE_PLACEHOLDER'));

        if (validatingArray.length !== 0) {
            setValidatingWarningState(true);
            setUnvalidatedFields(validatingArray);
        } else {
            let influencerAgent = {};
            setUpdateClicked(true);
            validateInfluencerAgentByIdRequest();
            influencerAgent['agencyName'] = agencyNameInputValue;
            influencerAgent['name'] = nameInputValue;
            influencerAgent['email'] = emailInputValue;
            influencerAgent['address'] = addressInputValue;
            influencerAgent['siret'] = siretInputValue;
            influencerAgent['website'] = websiteInputValue;
            influencerAgent['createdAt'] = agentToValidate.createdAt;
            influencerAgent['userId'] = agentToValidate.key;

            if (base64BasicLogo === '') {
                influencerAgent['image'] = agentToValidate.banner;
            } else {
                // UPLOAD NEW BASIC IMAGE
                setUploadBasicImageLoading(true);
                const cloudinaryPostRequestBody = {
                    image: base64BasicLogo,
                    userType: "influencerAgent",
                    public_id: emailInputValue,
                };
                await uploadBasicImage(cloudinaryPostRequestBody).then(result => {
                    setUploadBasicImageLoading(false);
                    influencerAgent['image'] = result.data.url;
                }).catch(error => {
                    setUploadBasicImageLoading(false);
                });
            }
            let userData = {
                createdAt: agentToValidate.createdAt,
                email: emailInputValue,
                name: nameInputValue
            }
            validateInfluencerAgentById(agentToValidate.key, influencerAgent, userData);
        }
    };

    const refuseInfluencerAgent = async () => {
        setUpdateClicked(true);
        refuseInfluencerAgentById(agentToValidate.key);
    };

    const deleteImage = (e, data) => {
        setAnchorEl(e.currentTarget);
        let influencer = informationInfluencersIds.filter(element => element.key !== data.key)
        setInformationInfluencersIds(influencer);
    };
    const editInfluencerAgent = async () => {
        let validatingArray = [];
        let updates = {};
        if (nameInputValue === "") validatingArray.push(t('ADMIN.VIEWS.PARTNERS.AGENTS.INFLUENCER_AGENT_FORM.AGENT_NAME'));
        if (agencyNameInputValue === "") validatingArray.push(t('ADMIN.VIEWS.PARTNERS.AGENTS.INFLUENCER_AGENT_FORM.AGENCE_NAME'));
        if (addressInputValue === "") validatingArray.push(t('ADMIN.VIEWS.PARTNERS.AGENTS.INFLUENCER_AGENT_FORM.ADDRESS'));
        if (siretInputValue === "") validatingArray.push(t('ADMIN.VIEWS.PARTNERS.AGENTS.INFLUENCER_AGENT_FORM.SIRET'));
        if (websiteInputValue === "") validatingArray.push(t('ADMIN.VIEWS.PARTNERS.AGENTS.INFLUENCER_AGENT_FORM.WEBSITE'));

        if (validatingArray.length !== 0) {
            setValidatingWarningState(true);
            setUnvalidatedFields(validatingArray);
        } else {
            updates['agencyName'] = agencyNameInputValue;
            updates['name'] = nameInputValue;
            updates['address'] = addressInputValue;
            updates['siret'] = siretInputValue;
            updates['website'] = websiteInputValue;
            if (newInfluencers !== undefined) {
                updates['influencersIds'] = informationInfluencersIds.map(influencer => {
                    return {
                        uid: influencer.key,
                        acceptedStatus: true
                    };
                }).concat(newInfluencers.map(element => {
                    return {
                        ...element,
                        acceptedStatus: true
                    }
                }));
            } else {
                updates['influencersIds'] = informationInfluencersIds.map(influencer => {
                    return {
                        uid: influencer.key,
                        acceptedStatus: true
                    };
                });
            }


            if (base64BasicLogo) {
                setUploadBasicImageLoading(true);
                const cloudinaryPostRequestBody = {
                    image: base64BasicLogo,
                    userType: "influencerAgent",
                    public_id: emailInputValue,

                };
                await uploadBasicImage(cloudinaryPostRequestBody).then(result => {
                    setUploadBasicImageLoading(false);
                    updates['image'] = result.data.url;
                }).catch(error => {
                    setUploadBasicImageLoading(false);
                });
            }

            if (agentToEdit.influencerAgentData !== undefined) {
                let userData = {
                    createdAt: agentToEdit.influencerAgentData.createdAt,
                    email: emailInputValue,
                    name: nameInputValue
                }
                setUpdateClicked(true);
                updateInfluencerAgentbyId(agentToEdit.influencerAgentData, updates, userData);

            } else {
                let userData = {
                    createdAt: agentToEdit.createdAt,
                    email: emailInputValue,
                    name: nameInputValue
                }
                setUpdateClicked(true);
                updateInfluencerAgentbyId(agentToEdit, updates, userData);
            }
        }
    };
    useEffect(() => {
        let InfoPicture = [];
        if (newInfluencers) {
            InfoPicture = informationInfluencersIds.map(influencer => influencer.key

            ).concat(newInfluencers.map(element => element.uid));
        } else {
            InfoPicture = informationInfluencersIds.map(influencer => influencer.key);
        }
        setInformationPicture(InfoPicture)
    }, [newInfluencers, informationInfluencersIds])

    useEffect(() => {
        if (agentToEdit) {
            if (agentToEdit.influencerAgentData) {
                if (agentToEdit.influencerAgentData.agencyName) setAgencyNameInputValue(agentToEdit.influencerAgentData.agencyName);
                if (agentToEdit.influencerAgentData.name) setNameInputValue(agentToEdit.influencerAgentData.name);
                setAgentNameTitle(agentToEdit.influencerAgentData.name);
                if (agentToEdit.influencerAgentData.email) setEmailInputValue(agentToEdit.influencerAgentData.email);
                if (agentToEdit.influencerAgentData.address) setAddressInputValue(agentToEdit.influencerAgentData.address);
                if (agentToEdit.influencerAgentData.siret) setSiretInputValue(agentToEdit.influencerAgentData.siret);
                if (agentToEdit.influencerAgentData.website) setWebSiteInputValue(agentToEdit.influencerAgentData.website)
            } else {
                if (agentToEdit.agencyName) setAgencyNameInputValue(agentToEdit.agencyName);
                if (agentToEdit.name) setNameInputValue(agentToEdit.name);
                setAgentNameTitle(agentToEdit.name);
                if (agentToEdit.address) setAddressInputValue(agentToEdit.address);
                if (agentToEdit.siret) setSiretInputValue(agentToEdit.siret);
                if (agentToEdit.email) setEmailInputValue(agentToEdit.email);
                if (agentToEdit.website) setWebSiteInputValue(agentToEdit.website)
            }
        }

    }, [agentToEdit]);

    useEffect(() => {
        if (agentToValidate) {
            if (agentToValidate.agencyName) setAgencyNameInputValue(agentToValidate.agencyName);
            if (agentToValidate.name) setNameInputValue(agentToValidate.name);
            setAgentNameTitle(agentToValidate.name);
            if (agentToValidate.address) setAddressInputValue(agentToValidate.address);
            if (agentToValidate.siret) setSiretInputValue(agentToValidate.siret);
            if (agentToValidate.email) setEmailInputValue(agentToValidate.email);
            if (agentToValidate.website) setWebSiteInputValue(agentToValidate.website)
        }
    }, [agentToValidate]);

    useEffect(() => {
        let infosAgentPic = [];
        let idInfluencerAssociate = [];

        if (agentToEdit) {
            Object.values(agentToEdit).forEach((influencerAgent) => {
                if (influencerAgent.key !== undefined && influencerAgent.name !== agentToEdit.name) {

                    infosAgentPic.push({
                        name: influencerAgent.name,
                        banner: influencerAgent.banner,
                        key: influencerAgent.key,
                    });
                    idInfluencerAssociate.push(influencerAgent.key);
                }
            });
        }

        setInformationInfluencersIds(infosAgentPic);
    }, [agentToEdit]);

    useEffect(() => {
        let InfoPicture = [];
        let newInfluencer = [];
        if (newInfluencers && loadedInfluencers) {
            newInfluencers.forEach(element => {
                newInfluencer.push(...influencers.filter(influ => influ.key === element.uid));
            })
            InfoPicture = informationInfluencersIds.map(influencer => influencer
            ).concat(newInfluencer.map(element => element));
        } else {
            InfoPicture = informationInfluencersIds.map(influencer => influencer);
        }
        setInformationPicture(InfoPicture)
    }, [newInfluencers, informationInfluencersIds, influencers, loadedInfluencers])

    useEffect(() => {
        setTitleWarning(t('ADMIN.VIEWS.PARTNERS.AGENTS.WARNING_VALIDATION_MESSAGE'));
    }, [t]);
    const handleOpenModalAssociate = () => {
        setAssociateMode(true);
    }
    const handleCloseModalAssociate = () => {
        setAssociateMode(false);
    }
    const handleNewAssociate = (influencer) => {
        SetNewInfluencers(influencer);
    }

    useEffect(() => {
        if (loadingUpdateInfluencerAgent || loadingValidateInfluencerAgent || uploadBasicImageLoading || loadingRefuseInfluencerAgent) {
            setOverlayState(true);

        }
        if (!loadingUpdateInfluencerAgent && !loadingValidateInfluencerAgent && !uploadBasicImageLoading && updateClicked && !loadingRefuseInfluencerAgent) {
            setOverlayState(false);
            setUpdateClicked(false);
            handleCloseModal();
        }

    }, [loadingUpdateInfluencerAgent, loadingValidateInfluencerAgent, uploadBasicImageLoading, updateClicked, handleCloseModal, loadingRefuseInfluencerAgent]);

    return (
        <div>
            {associateMode ?
                <AssociateInfluencersToAgent influencersAssociate={agentToEdit} closeModal={handleCloseModalAssociate} newAssociate={(influencer) => handleNewAssociate(influencer)} />
                :
                <>
                    <Backdrop className={classes.overlay} open={overlayState}>
                        <CircularProgress color='secondary' />
                    </Backdrop>
                    <Dialog className={classes.warningDialog} open={validatingWarningState} onClose={handleCloseWarningDialog}>
                        <WarningDialog unvalidatedFields={unvalidatedFields} titleWarning={titleWarning} />
                    </Dialog>
                    <Grid container item direction='row' xs={12} justify='center' alignItems='center'>
                        <Grid container item direction='row' xs={11} justify='center' alignItems='center' className={classes.overallBox}>
                            <Grid container item direction='row' alignItems='center' justify='flex-end' xs={12}>
                                <IconButton style={{ marginTop: '10px', marginRight: '10px', color: '#000' }} onClick={handleCloseModal}>
                                    <CloseIcon fontSize='large' />
                                </IconButton>
                            </Grid>
                            <Grid container item direction='row' alignItems='center' justify='center' xs={12}>
                                <Typography className={classes.formTitle}>{agentNameTitle}</Typography>
                            </Grid>
                            <Grid container item direction='row' xs={11} className={classes.formBox}>
                                {
                                    (agentToEdit || agentToValidate)
                                        ? <>

                                            <Grid container item direction='column' justify='flex-start' alignItems='flex-start' className={classes.formControlRow} xs={12} >
                                                <Typography className={classes.formLabel}>
                                                    {t('ADMIN.VIEWS.PARTNERS.AGENTS.INFLUENCER_AGENT_FORM.AGENT_NAME')}*
                                        </Typography>
                                                <FormControl className={classes.formControl}>
                                                    <InputBase
                                                        className={classes.formInput}
                                                        placeholder={t('ADMIN.VIEWS.PARTNERS.AGENTS.INFLUENCER_AGENT_FORM.AGENT_NAME_PLACEHOLDER')}
                                                        id='agentName'
                                                        type='text'
                                                        value={nameInputValue}
                                                        onChange={handleValueChange('agentName')} />
                                                </FormControl>
                                            </Grid>
                                        </>
                                        : <></>
                                }
                                {
                                    (agentToEdit || agentToValidate)
                                        ? <>
                                            <Grid container item direction='column' justify='flex-start' alignItems='flex-start' className={classes.formControlRow} xs={12} >
                                                <Typography className={classes.formLabel}>
                                                    {t('ADMIN.VIEWS.PARTNERS.AGENTS.INFLUENCER_AGENT_FORM.AGENCE_NAME')}*
                                        </Typography>
                                                <FormControl className={classes.formControl}>
                                                    <InputBase
                                                        className={classes.formInput}
                                                        placeholder={t('ADMIN.VIEWS.PARTNERS.AGENTS.INFLUENCER_AGENT_FORM.AGENCE_NAME_PLACEHOLDER')}
                                                        id='agencyName'
                                                        type='text'
                                                        value={agencyNameInputValue}
                                                        onChange={handleValueChange('agencyName')} />
                                                </FormControl>
                                            </Grid>
                                        </>
                                        : <></>
                                }
                                {
                                    (agentToEdit || agentToValidate)
                                        ? <>
                                            <Grid container item direction='column' justify='flex-start' alignItems='flex-start' className={classes.formControlRow} xs={12} >
                                                <Typography className={classes.formLabel}>
                                                    {t('ADMIN.VIEWS.PARTNERS.AGENTS.INFLUENCER_AGENT_FORM.EMAIL')}
                                                </Typography>
                                                <FormControl className={classes.formControl}>
                                                    <InputBase
                                                        className={classes.formInput}
                                                        placeholder="Email Agent"
                                                        id='agentEmail'
                                                        type='text'
                                                        value={emailInputValue}
                                                        disabled={true}
                                                        onChange={handleValueChange('agentEmail')} />
                                                </FormControl>
                                            </Grid>
                                        </>
                                        : <></>
                                }
                                {
                                    (agentToValidate || agentToEdit)
                                        ? <>
                                            <Grid container item direction='column' justify='flex-start' alignItems='flex-start' className={classes.formControlRow} xs={12} >
                                                <Typography className={classes.formLabel}>
                                                    {t('ADMIN.VIEWS.PARTNERS.AGENTS.INFLUENCER_AGENT_FORM.ADDRESS')}
                                                </Typography>
                                                <FormControl className={classes.formControl}>
                                                    <InputBase
                                                        className={classes.formInput}
                                                        placeholder="Address"
                                                        id='agentAddress'
                                                        type='text'
                                                        value={addressInputValue}
                                                        onChange={handleValueChange('agentAddress')} />
                                                </FormControl>
                                            </Grid>
                                        </>
                                        : <></>
                                }
                                {
                                    (agentToValidate || agentToEdit)
                                        ? <>
                                            <Grid container item direction='column' justify='flex-start' alignItems='flex-start' className={classes.formControlRow} xs={12} >
                                                <Typography className={classes.formLabel}>
                                                    {t('ADMIN.VIEWS.PARTNERS.AGENTS.INFLUENCER_AGENT_FORM.SIRET')}
                                                </Typography>
                                                <FormControl className={classes.formControl}>
                                                    <InputBase
                                                        className={classes.formInput}
                                                        placeholder="Siret"
                                                        id='agentSiret'
                                                        type='text'
                                                        value={siretInputValue}
                                                        onChange={handleValueChange('agentSiret')} />
                                                </FormControl>
                                            </Grid>
                                        </>
                                        : <></>
                                }
                                {
                                    (agentToValidate || agentToEdit)
                                        ? <>
                                            <Grid container item direction='column' justify='flex-start' alignItems='flex-start' className={classes.formControlRow} xs={12} >
                                                <Typography className={classes.formLabel}>
                                                    {t('ADMIN.VIEWS.PARTNERS.AGENTS.INFLUENCER_AGENT_FORM.WEBSITE')}* (href)
                                        </Typography>
                                                <FormControl className={classes.formControl}>
                                                    <InputBase
                                                        className={classes.formInput}
                                                        placeholder="Web Site"
                                                        id='website'
                                                        type='text'
                                                        value={websiteInputValue}
                                                        onChange={handleValueChange('website')} />
                                                </FormControl>
                                            </Grid>
                                        </>
                                        : <></>
                                }
                                {agentToEdit ?
                                    <>
                                        <Grid container item direction='column' justify='flex-start' alignItems='flex-start' className={classes.formControlRow} xs={12} >
                                            <Typography className={classes.formLabel}>
                                                {t('ADMIN.VIEWS.PARTNERS.AGENTS.INFLUENCER_AGENT_FORM.AGENT_INFLUENCERS_ASSOCIATE')}
                                            </Typography>
                                            {informationPicture.length !== 0 ?
                                                <Grid container item direction="row" xs={12} >
                                                    <Grid container item direction="row" justify="center" xs={12} className={classes.AllBrandBox} style={{ padding: '15px' }}>
                                                        <Grid container item justify={(xsDown) ? 'center' : 'flex-start'} alignItems="center">
                                                            {informationPicture.map((picture, key) => {
                                                                return (
                                                                    <div key={key} style={{ display: "inline", position: "relative" }}>
                                                                        <Box className={classes.brandBox}>
                                                                            <img
                                                                                src={picture.banner}
                                                                                anchorel={anchorEl}
                                                                                alt={picture.name}
                                                                                className={classes.influencerImage}
                                                                            />
                                                                            <IconButton style={{ position: "absolute", right: 0, top: 0, backgroundColor: '#fff' }} onClick={(e) => deleteImage(e, picture)}>
                                                                                <CloseIcon style={{ position: "absolute", right: 0, top: 0, color: "red" }} />
                                                                            </IconButton>
                                                                            <Typography style={{ bottom: 10, fontFamily: 'Poppins', wordBreak: "break-all", justifyContent: "center" }}>{picture.name}</Typography>
                                                                        </Box>
                                                                    </div>
                                                                )
                                                            })
                                                            }
                                                            <Box className={classes.brandBox} onClick={handleOpenModalAssociate}>
                                                                <IconButton className={classes.MyIconButton}>
                                                                    <AddIcon style={{ color: "#a0d9f6" }} />
                                                                </IconButton>
                                                            </Box>
                                                        </Grid>
                                                    </Grid>
                                                </Grid>
                                                :
                                                <>
                                                    <Grid container item direction="row" xs={12} >
                                                        <Grid container item direction="row" justify="center" xs={12} className={classes.AllBrandBox} style={{ padding: '15px' }}>
                                                            <Grid container item justify={(xsDown) ? 'center' : 'flex-start'} alignItems="center">
                                                                <Box className={classes.brandBox} onClick={handleOpenModalAssociate}>
                                                                    <IconButton className={classes.MyIconButton}>
                                                                        <AddIcon style={{ color: "#a0d9f6" }} />
                                                                    </IconButton>
                                                                </Box>
                                                            </Grid>
                                                        </Grid>
                                                    </Grid>
                                                </>
                                            }
                                        </Grid>
                                    </> :
                                    <></>
                                }

                                <Grid container item direction='column' justify='flex-start' alignItems='flex-start' className={classes.formControlRow} xs={12}>
                                    <Grid container item direction='row' justify='flex-start' alignItems='flex-start' xs={12}>
                                        <Typography className={classes.formLabel}>
                                            {t('ADMIN.VIEWS.PARTNERS.AGENTS.INFLUENCER_AGENT_FORM.AGENT_IMAGE')}
                                        </Typography>
                                        <FormControl className={classes.formControl}>
                                            <Grid container item alignItems='center' justify={(xsDown) ? 'center' : 'flex-start'} className={classes.formInput} style={{ height: 'auto', padding: 5, marginTop: '10px', marginBottom: '10px', width: '100%' }}>
                                                <Typography
                                                    className={(basicBrandPictureValue === '')
                                                        ? ((xsDown) ? classes.profilePicturePlaceholderMobile : classes.profilePicturePlaceholder)
                                                        : ((xsDown) ? classes.activeProfilePicturePlaceholderMobile : classes.activeProfilePicturePlaceholder)}>
                                                    {(basicBrandPictureValue === '') ? t('ADMIN.VIEWS.PARTNERS.AGENTS.INFLUENCER_AGENT_FORM.IMAGE_PLACEHOLDER') : basicBrandPictureValue.split('\\').pop()}
                                                </Typography>
                                                <label htmlFor='profileImage' tabIndex='0' className={classes.profileImageLabel} style={(xsDown) ? { margin: 5 } : {}}>
                                                    {t('ADMIN.VIEWS.PARTNERS.AGENTS.INFLUENCER_AGENT_FORM.UPLOAD_BUTTON')}
                                                </label>
                                            </Grid>
                                            <input type='file' id='profileImage' onChange={handleValueChange('brandBasicPicture')} hidden accept="image/x-png,image/gif,image/jpeg" />
                                            <CropperDialog open={openBasicDialog} save={handleSaveBasicDialog} cancel={handleCancelBasicDialog} imageToCrop={imageToCrop} widthAspect={imageWidthAspect} heightAspect={imageHeightAspect} />
                                        </FormControl>
                                    </Grid>
                                </Grid>
                            </Grid>

                            {agentToEdit ?
                                <Grid container item direction='row' xs={11} className={classes.formBox} justify={(xsDown) ? 'center' : (mdDown) ? 'center' : 'space-between'}>
                                    <Button
                                        className={classes.cancelEditButton}
                                        onClick={handleCloseModal}
                                    >
                                        {t('ADMIN.VIEWS.PARTNERS.AGENTS.INFLUENCER_AGENT_FORM.CANCEL_EDITS_BUTTON')}
                                    </Button>
                                    <Button
                                        className={classes.validateEditButton}
                                        onClick={editInfluencerAgent}
                                    >
                                        {t('ADMIN.VIEWS.PARTNERS.AGENTS.INFLUENCER_AGENT_FORM.SAVE_EDITS_BUTTON')}
                                    </Button>
                                </Grid>
                                : <></>
                            }
                            {
                                (agentToValidate)
                                    ? <Grid container item direction='row' xs={11} className={classes.formBox} justify={(xsDown) ? 'center' : (mdDown) ? 'center' : 'space-between'}>
                                        <Button
                                            className={classes.refuseButton}
                                            onClick={refuseInfluencerAgent}
                                        >
                                            {t('ADMIN.VIEWS.PARTNERS.AGENTS.INFLUENCER_AGENT_FORM.REFUSE_BUTTON')}
                                        </Button>
                                        <Button
                                            className={classes.validateButton}
                                            onClick={validateInfluencerAgent}
                                        >
                                            {t('ADMIN.VIEWS.PARTNERS.AGENTS.INFLUENCER_AGENT_FORM.VALIDATE_BUTTON')}
                                        </Button>
                                    </Grid>
                                    : <></>
                            }
                        </Grid>
                    </Grid>
                </>
            }
        </div>
    )
}
const mapStateToProps = (state) => {
    const loadingRefuseInfluencerAgent = state.influencerAgentsState.loadingRefuseInfluencerAgent;
    const loadingValidateInfluencerAgent = state.influencerAgentsState.loadingValidateInfluencerAgent;
    const loadingUpdateInfluencerAgent = state.influencerAgentsState.loadingUpdateInfluencerAgent;
    const loadedInfluencers = state.influencersState.loadedInfluencers;
    const influencers = state.influencersState.influencers;

    return {
        loadingRefuseInfluencerAgent,
        loadingValidateInfluencerAgent,
        loadingUpdateInfluencerAgent,
        loadedInfluencers,
        influencers
    }
};
const mapDispatchToProps = (dispatch) => {
    return {
        updateInfluencerAgentbyId: (influencerAgentId, updates, userData) => dispatch(updateInfluencerAgentbyId(influencerAgentId, updates, userData)),
        refuseInfluencerAgentById: (influencerAgent) => dispatch(refuseInfluencerAgentById(influencerAgent)),
        validateInfluencerAgentByIdRequest: () => dispatch({ type: 'VALIDATE_INFLUENCER_AGENT_BY_ID_REQUEST' }),
        validateInfluencerAgentById: (influencerAgentId, influencerAgent, userData) => dispatch(validateInfluencerAgentById(influencerAgentId, influencerAgent, userData)),
    }
}
export default connect(mapStateToProps, mapDispatchToProps)(EditAgentModal);