import ReactDOM from "react-dom";
import "./Overlay.css";

const Overlay = () => {
  const appendOverlay = anchor => {
    const container = document.createElement("div"),
      overlay = document.createElement("div");

    container.className = "overlayContainer";
    overlay.className = "overlay";
    container.appendChild(overlay);
    anchor.appendChild(container);
    return {
      container,
      overlay,
    };
  };

  const createOverlay = (anchor, position = null, size = null) => {
    const overlay = appendOverlay(anchor);

    overlay.container.style.width = size.width + "px";
    overlay.container.style.height = size.height + "px";

    overlay.container.style.left = position.x + "px";
    overlay.container.style.top = position.y + "px";

    return overlay;
  };

  const appendContent = (anchor, contentData) => {
    const events = contentData.events;
    let htmlContent, index;

    htmlContent = ReactDOM.render(contentData.element, anchor);
    if (htmlContent) {
      htmlContent.className += " content";
      for (index in events) htmlContent.addEventListener(index, events[index]);
    }
  };

  return {
    //Content must be { reactElement, events }
    create: (anchor, content = null, position = null, size = null) => {
      let currentOverlay = null,
        deleted = false,
        alive = true;

      if (anchor && position && size)
        currentOverlay = createOverlay(anchor, position, size);
      else if (anchor) currentOverlay = appendOverlay(anchor);
      if (content && currentOverlay)
        appendContent(currentOverlay.overlay, content);
      return {
        delete: () => {
          if (currentOverlay && !deleted) {
            currentOverlay.overlay.className += " disapear";
            setTimeout(() => {
              alive = false;
              anchor.removeChild(currentOverlay.container);
            }, 400);
            deleted = true;
          }
        },
        getPosition: () => position,
        getSize: () => size,
        isAlive: () => alive,
        container: currentOverlay.container,
      };
    },
  };
};

export default Overlay;
