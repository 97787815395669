import { Drawer, Hidden, makeStyles, useTheme, useMediaQuery, Snackbar, CircularProgress, Backdrop } from '@material-ui/core';
import Alert from '@material-ui/lab/Alert';
import React, { useState, useEffect } from 'react';
import SideBar from './components/Sidebar';
import ProductsList from './components/ProductsList';
import InfluencerInfosBar from './components/InfluencerInfosBar';
import ActionBar from './components/ActionBar';
import { connect } from 'react-redux';
import { addWishlist, deleteWishlist, fetchAllWishlists, fetchWishlistsPerInfluencer, updateWishlist } from '../../store/actions/wishlistsActions';
import { DualRing } from '../ui';
import { useHistory, useParams } from 'react-router-dom';
import { fetchInfluencerByAffiliateId } from '../../store/actions/influencersActions';
import WishlistDialog from './components/WishlistDialog';
import { addMultipleShortLinks, fetchShortlinksPerInfluencer } from '../../store/actions/shortlinksActions';
import ExportBox from './components/ExportBox';
import ShareBox from './components/ShareBox';
import { useTranslation } from "react-i18next";

const useStyle = makeStyles(theme => {
    const drawerWidth = '400px';
    return {
        drawer: {
            width: drawerWidth,
            flexShrink: 0,
            height: '100vh',
            borderStyle: 'none'
        },
        drawerPaper: {
            width: drawerWidth,
            justifyContent: 'flex-start',
            height: '100vh',
            borderStyle: 'none !important'
        },
        drawerPaperMobile: {
            width: '280px',
            justifyContent: 'flex-start',
            borderStyle: 'none !important'
        },
        content: {
            minHeight: '100vh',
            width: '100%',
            backgroundColor: "#eff9fe",
            display: 'flex',
            flexDirection: 'column',
        },
        initContent: {
            minHeight: '100vh',
            width: '100%',
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'center'
        },
        overlay: {
            zIndex: theme.zIndex.drawer + 1
        },
    };
});

const Influencer = (props) => {

    const classes = useStyle();
    const { t } = useTranslation("common");
    const theme = useTheme();
    const {
        user,
        influencers,
        wishlistsPerInfluencer,
        brands,
        loadedWishlists,
        loadedInfluencer,
        loadedWishlistsPerInfluencer,
        loadedAddNewWishlist,
        loadedDeleteWishlist,
        loadedUpdateWishlist,
        getInfluencerByAffiliateId,
        getWishlistsPerInfluencer,
        addNewWishlist,
        deleteWishlist,
        updateWishlist,
        getShortlinksPerInfluencer,
        loadedShortlinksPerInfluencer,
        addShortlinks,
        shortlinksPerInfluencer,
        loadedAddMultipleShortlinks,
        resetAddMultipleWishlists
    } = props;
    const xsDown = useMediaQuery(theme.breakpoints.down("xs"));
    const history = useHistory();
    const [openDrawer, setOpenDrawer] = useState(false);
    const [userWishlists, setUserWishlists] = useState([]);
    const [influencer, setInfluencer] = useState(null);
    const [activeWishlist, setActiveWishlist] = useState(null);
    const [snackBarState, setSnackBarState] = useState(false);
    const [snackBarError, setSnackBarError] = useState();
    const [snackBarCopyState, setSnackBarCopyState] = useState(false);
    const [snackBarCopyInfo, setSnackBarCopyInfo] = useState();
    const [loading, setLoading] = useState(true);
    const [connectedUserMode, setConnectedUserMode] = useState(false);
    const [wishlistDialogState, setWishlistDialogState] = useState(false);
    const [wishlistDialogType, setWishlistDialogType] = useState();
    const [overlayState, setOverlayState] = useState(false);
    const [currentShortlinksCreated, setCurrentShortlinksCreated] = useState(false);
    const [shortlinksToExport, setShortlinksToExport] = useState([]);
    const [exportBoxState, setExportBoxState] = useState(false);
    const [loadingExport, setLoadingExport] = useState(false);
    const [shareBoxState, setShareBoxState] = useState(false);

    const {
        affiliateId,
        wishlistId
    } = useParams();

    useEffect(() => {
        setLoading(true);
        if (!loadedInfluencer && affiliateId !== undefined) {
            getInfluencerByAffiliateId(affiliateId);
        } else {
            let influencer = influencers.filter(influencer => influencer.affiliate_id === Number(affiliateId))[0];
            if (influencer) {
                setInfluencer(influencer);
                getWishlistsPerInfluencer(influencer.key);
            } else {
                history.push('/home');
            }
        }
    }, [loadedInfluencer, getInfluencerByAffiliateId, influencers, affiliateId, history, getWishlistsPerInfluencer]);

    useEffect(() => {
        if (loadedWishlistsPerInfluencer && influencer) {
            if (connectedUserMode) {
                if (loadedShortlinksPerInfluencer) {
                    let wishlists = Object.values(wishlistsPerInfluencer[influencer.key]);
                    if (wishlists) {
                        setUserWishlists(wishlists)
                        setLoading(false);
                    } else {
                        history.push('/home')
                    };
                }
            } else {
                let wishlists = Object.values(wishlistsPerInfluencer[influencer.key]);
                if (wishlists) {
                    setUserWishlists(wishlists)
                    setLoading(false);
                } else {
                    history.push('/home')
                };
            }
        }
    }, [loadedWishlistsPerInfluencer, wishlistsPerInfluencer, influencer, history, loadedShortlinksPerInfluencer, connectedUserMode]);

    useEffect(() => {
        if (!loading) {
            if (wishlistId) {
                let activeWishlist = userWishlists.filter(wishlist => wishlist.key === wishlistId)[0];
                if (activeWishlist) {
                    setActiveWishlist(activeWishlist)
                } else {
                    setSnackBarError(t('INFLUENCER.SNACKBAR_MESSAGES.WISHLSIT_DONT_EXIST'));
                    setSnackBarState(true);
                    setActiveWishlist(userWishlists[0]);
                }
            } else {
                setActiveWishlist(userWishlists[0]);
            };
        }
    }, [loading, userWishlists, wishlistId, t]);

    useEffect(() => {
        if (user && influencer) {
            if (user.uid === influencer.key) setConnectedUserMode(true);
        }
    }, [user, influencer]);

    useEffect(() => {
        if (connectedUserMode && influencer) getShortlinksPerInfluencer(influencer.affiliate_id);
    }, [connectedUserMode, influencer, getShortlinksPerInfluencer])

    useEffect(() => {
        if (loadedAddNewWishlist || loadedUpdateWishlist || loadingExport) {
            setOverlayState(true);
        } else {
            setOverlayState(false);
        }
    }, [loadedAddNewWishlist, loadedUpdateWishlist, loadingExport]);

    useEffect(() => {
        if (influencer) {
            if (loadedDeleteWishlist) {
                setOverlayState(true);
            } else {
                setOverlayState(false);
            }
        }
    }, [loadedDeleteWishlist, influencer, history])

    const handleChangeActiveWishlist = (wishlist) => {
        history.replace(`/influencer/wishlists/${influencer.affiliate_id}/${wishlist.key}`);
        setActiveWishlist(wishlist);
    };

    const handleCloseSnackbar = () => {
        setSnackBarState(false);
        setSnackBarCopyState(false);
    };

    const handleOpenAddWishlistDialog = () => {
        setWishlistDialogType('add');
        setWishlistDialogState(true);
    };

    const handleOpenDeleteWishlistDialog = () => {
        setWishlistDialogType('delete');
        setWishlistDialogState(true);
    };

    const handleOpenUpdateWishlistDialog = () => {
        setWishlistDialogType('update');
        setWishlistDialogState(true);
    };

    const handleCancelWishlistOperation = () => {
        setWishlistDialogState(false);
    };

    const handleSaveAddWishlist = (wishlistName) => {
        setWishlistDialogState(false);
        addNewWishlist(wishlistName, user.uid);
    };

    const handleDeleteWishlist = () => {
        setWishlistDialogState(false);
        deleteWishlist(activeWishlist);
    };

    const handleUpdateWishlist = (wishlistName) => {
        setWishlistDialogState(false);
        updateWishlist(activeWishlist, { name: wishlistName });
    };

    const handleCopyUrl = () => {
        setSnackBarCopyInfo(t('INFLUENCER.SNACKBAR_MESSAGES.COPY_URL_SUCCESS'));
        setSnackBarCopyState(true);
    };

    const handleCloseExportBox = () => {
        setExportBoxState(false);
    };

    const handleShareWishlist = () => {
        setShareBoxState(true);
    };

    const handleCloseShareBox = () => {
        setShareBoxState(false);
    };

    useEffect(() => {
        if (currentShortlinksCreated) {
            resetAddMultipleWishlists();
            setExportBoxState(true);
        }
    }, [currentShortlinksCreated, shortlinksToExport, resetAddMultipleWishlists]);

    useEffect(() => {
        if (loadedAddMultipleShortlinks) {
            const currentWishlistArticles = (activeWishlist.articles) ? Object.values(activeWishlist.articles) : [];
            let shortlinksWithImages = [];
            currentWishlistArticles.forEach(article => {
                const shortlinkForCurrentArticle = shortlinksPerInfluencer[influencer.affiliate_id].filter(shortlink => shortlink.productId === article.id)[0];
                const shortlinkWithImage = {
                    ...shortlinkForCurrentArticle,
                    image: article.image
                };
                shortlinksWithImages.push(shortlinkWithImage);
            });
            setShortlinksToExport(shortlinksWithImages);
            setCurrentShortlinksCreated(true);
            setLoadingExport(false);
        }
    }, [loadedAddMultipleShortlinks, activeWishlist, influencer, shortlinksPerInfluencer]);

    const handleExportLinks = () => {
        setLoadingExport(true);
        const currentWishlistArticles = (activeWishlist.articles) ? Object.values(activeWishlist.articles) : [];
        let articlesWithoutShortlinks = [];
        const articlesIdsWithShortlinks = shortlinksPerInfluencer[influencer.affiliate_id].map(shortlink => shortlink.productId);
        currentWishlistArticles.forEach(article => {
            if (articlesIdsWithShortlinks.indexOf(article.id) === -1) {
                articlesWithoutShortlinks.push(article);
            };
        });
        if (articlesWithoutShortlinks.length === 0) {
            let shortlinksWithImages = [];
            currentWishlistArticles.forEach(article => {
                const articleBrand = brands.filter(brand => brand.offerId === article.offerId)[0];
                if (articleBrand) {
                    const shortlinkForCurrentArticle = shortlinksPerInfluencer[influencer.affiliate_id].filter(shortlink => shortlink.productId === article.id)[0];
                    const shortlinkWithImage = {
                        ...shortlinkForCurrentArticle,
                        image: article.image,
                        brandName: articleBrand.displayName ||articleBrand.name,
                    };
                    shortlinksWithImages.push(shortlinkWithImage);
                }
            });
            setShortlinksToExport(shortlinksWithImages);
            setCurrentShortlinksCreated(true);
            setLoadingExport(false);
        } else {
            const date = new Date();
            let shortlinksToCreate = [];
            articlesWithoutShortlinks.forEach(article => {
                const newShortlink = {
                    createdBy: influencer.key,
                    productId: article.id,
                    trackingUrl: article.tracking_link,
                    timestamp: Math.round(date.getTime() / 1000),
                    displayDate: date.toLocaleDateString(),
                    newUrl: `https://smyi.me/${influencer.affiliate_id}/`
                };
                shortlinksToCreate.push(newShortlink);
            });
            addShortlinks(shortlinksToCreate, influencer.affiliate_id);
        };
    };

    return (
        (!loadedWishlists && loading)
            ? <div className={classes.initContent}><DualRing /></div>
            :
            <>
                <Hidden xsDown={xsDown}>
                    <Drawer
                        className={classes.drawer}
                        variant='permanent'
                        classes={{ paper: classes.drawerPaper }}
                        anchor='left'
                        open={openDrawer}
                    >
                        <SideBar
                            user={(connectedUserMode) ? user : null}
                            userWishlists={userWishlists}
                            influencer={influencer}
                            handleWishlistChange={(wishlist) => handleChangeActiveWishlist(wishlist)}
                            selectedWishlist={activeWishlist}
                            addWishlist={handleOpenAddWishlistDialog}
                            {...props} />
                    </Drawer>
                </Hidden>
                <Backdrop className={classes.overlay} open={overlayState}>
                    <CircularProgress color='secondary' />
                </Backdrop>
                <WishlistDialog
                    open={wishlistDialogState}
                    type={wishlistDialogType}
                    activeWishlist={activeWishlist}
                    cancelWishlistOperation={handleCancelWishlistOperation}
                    addWishlist={(wishlistName) => handleSaveAddWishlist(wishlistName)}
                    deleteWishlist={handleDeleteWishlist}
                    updateWishlist={(wishlistName) => handleUpdateWishlist(wishlistName)} />
                <ExportBox
                    exportData={shortlinksToExport}
                    open={exportBoxState}
                    closeExportBox={handleCloseExportBox} />
                <ShareBox open={shareBoxState} closeShareBox={handleCloseShareBox}/>
                <Snackbar open={snackBarState} onClose={handleCloseSnackbar} autoHideDuration={2000}>
                    <Alert severity="warning" variant='filled' style={{ fontFamily: 'Poppins' }}>
                        {snackBarError}
                    </Alert>
                </Snackbar>
                <Snackbar open={snackBarCopyState} onClose={handleCloseSnackbar} autoHideDuration={1000}>
                    <Alert severity="success" variant='filled' style={{ fontFamily: 'Poppins' }}>
                        {snackBarCopyInfo}
                    </Alert>
                </Snackbar>
                <main className={classes.content} style={(connectedUserMode) ? { flexDirection: 'column', justifyContent: 'space-between', height: '100vh' } : {}}>
                    {
                        (xsDown)
                            ? <InfluencerInfosBar
                                influencer={influencer}
                                userWishlists={userWishlists}
                                handleWishlistChange={(wishlist) => handleChangeActiveWishlist(wishlist)}
                                selectedWishlist={activeWishlist} />
                            : <></>
                    }
                    <ProductsList
                        user={connectedUserMode ? user : null}
                        influencer={influencer}
                        activeWishlist={activeWishlist}
                        opendrawer={() => setOpenDrawer(true)} />
                    {
                        (connectedUserMode)
                            ? <ActionBar
                                deleteWishlist={handleOpenDeleteWishlistDialog}
                                renameWishlist={handleOpenUpdateWishlistDialog}
                                copyUrl={handleCopyUrl}
                                exportLinks={handleExportLinks}
                                shareWishlist={handleShareWishlist} />
                            : <></>
                    }
                </main>
            </>
    );
};

const mapStateToProps = (state, ownProps) => {
    const influencers = state.influencersState.influencers;
    const wishlistsPerInfluencer = state.wishlistsState.wishlistsPerInfluencer;
    const wishlists = state.wishlistsState.wishlists;
    const brands = state.brandsState.allBrands;
    const loadedWishlists = state.wishlistsState.loadedWishlists;
    const loadedInfluencer = state.influencersState.loadedInfluencer;
    const loadedWishlistsPerInfluencer = state.wishlistsState.loadedWishlistsPerInfluencer;
    const loadedAddNewWishlist = state.wishlistsState.loadedAddNewWishlist;
    const loadedDeleteWishlist = state.wishlistsState.loadedDeleteWishlist;
    const loadedUpdateWishlist = state.wishlistsState.loadedUpdateWishlist;
    const loadedShortlinksPerInfluencer = state.shortlinksState.loadedShortlinksPerInfluencer;
    const shortlinksPerInfluencer = state.shortlinksState.shortlinksPerInfluencer;
    const loadedAddMultipleShortlinks = state.shortlinksState.loadedAddMultipleShortlinks;
    return {
        wishlists,
        brands,
        influencers,
        wishlistsPerInfluencer,
        loadedWishlists,
        loadedInfluencer,
        loadedWishlistsPerInfluencer,
        loadedAddNewWishlist,
        loadedDeleteWishlist,
        loadedUpdateWishlist,
        loadedShortlinksPerInfluencer,
        shortlinksPerInfluencer,
        loadedAddMultipleShortlinks
    }
};

const mapDispatchToProps = (dispatch) => {
    return {
        getAllWishlists: () => dispatch(fetchAllWishlists()),
        getInfluencerByAffiliateId: (affiliateId) => dispatch(fetchInfluencerByAffiliateId(affiliateId)),
        getWishlistsPerInfluencer: (influencerId) => {
            dispatch({ type: 'FETCH_WICHLISTS_PER_INFLUENCER_REQUEST' });
            dispatch(fetchWishlistsPerInfluencer(influencerId));
        },
        getShortlinksPerInfluencer: (influencerAffiliateId) => dispatch(fetchShortlinksPerInfluencer(influencerAffiliateId)),
        addNewWishlist: (wishlistName, userId) => dispatch(addWishlist(wishlistName, userId)),
        deleteWishlist: (wishlist) => dispatch(deleteWishlist(wishlist)),
        updateWishlist: (wishlist, updates) => dispatch(updateWishlist(wishlist, updates)),
        addShortlinks: (shortlinks, influencerAffiliateId) => dispatch(addMultipleShortLinks(shortlinks, influencerAffiliateId)),
        resetAddMultipleWishlists: () => dispatch({ type: 'SET_LOAD_ADD_MULTIPLE_SHORTLINKS' })
    };
}

export default connect(mapStateToProps, mapDispatchToProps)(Influencer);