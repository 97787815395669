import React,{ useState,useEffect } from "react";
import { Grid,Box,Typography,Select,MenuItem } from "@material-ui/core";
import { makeStyles,withStyles } from "@material-ui/styles";
import PeopleIcon from "@material-ui/icons/People";
import TimelineIcon from "@material-ui/icons/Timeline";
// import DescriptionOutlinedIcon from "@material-ui/icons/DescriptionOutlined";
import PowerSettingsNewIcon from "@material-ui/icons/PowerSettingsNew";
import { NavLink,useRouteMatch,useLocation } from "react-router-dom";
import SVGIcon from "../../template/assets/SVGIcons/index";
import { useTranslation } from "react-i18next";
import ExpandMoreIcon from "@material-ui/icons/ExpandMore";
import ChevronLeftIcon from '@material-ui/icons/ChevronLeft';
import PersonIcon from '@material-ui/icons/Person';
import clsx from "clsx";

const useStyles = makeStyles((theme) => {
  const drawerWidth = 280;
  return {
    drawer: {
      width: drawerWidth,
      flexShrink: 0,
      height: "100%",
      borderStyle: "none",
    },
    drawerPaper: {
      width: drawerWidth,
      justifyContent: "flex-start",
      borderStyle: "none !important",
    },
    bannerContainer: {
      height: "160px",
    },
    logoBoxContent: {
      display: "flex",
      alignItems: "center",
      width: "50%",
      backgroundColor: "white",
      "& img": {
        maxWidth: "100%",
        maxHeight: "100%",
      },
    },
    navigationLink: {
      display: "flex",
      width: "100%",
      color: "black",
      textDecoration: "none",
      paddingLeft: "55px",
      paddingTop: "15px",
      paddingBottom: "15px",
      fontSize: "16px",
      "&:hover": {
        backgroundColor: "#fb689f17",
        color: "#ff005f",
      },
      borderTopRightRadius: "40px",
      borderBottomRightRadius: "40px",
    },
    navigationActiveLink: {
      color: "#ff005f",
      backgroundColor: "#fb689f40",
      paddingTop: "15px",
      paddingBottom: "15px",
      fontSize: "16px",
      borderTopRightRadius: "40px",
      borderBottomRightRadius: "40px",
    },
    drawerMenu: {
      // height: '50%'   ADD WHEN ALL NAVIGATION IS DONE
      height: "35%",
    },
    navigationSpacement: {
      marginLeft: "10px !important",
      fontFamily: "Poppins !important",
    },
    selectClass: {
      backgroundColor: "transparent",
      fontFamily: "Poppins",
      fontSize: "16px",
      "&:focus": {
        backgroundColor: "transparent",
      },
    },
    placeholderRetourAdmin: {
      fontFamily: "Poppins",
      color: "#3eadef",
      padding: "20px",
      position: "absolute",
      bottom: "3px",
      left: "0",
      fontSize: "18px",
      "&:hover": {
        cursor: "pointer",
      },
    },
    placeholderRetourAdminMobile: {
      fontFamily: "Poppins",
      color: "#3eadef",
      padding: "20px",
      position: "absolute",
      bottom: "3px",
      left: "0",
      marginBottom: "10px",
      "&:hover": {
        cursor: "pointer",
      },
    }
  };
});

const iconStyles = {
  selectIcon: {
    color: "black",
  },
};
const CustomExpandMore = withStyles(iconStyles)(
  ({ className,classes,...rest }) => {
    return (
      <ExpandMoreIcon
        { ...rest }
        className={ clsx(className,classes.selectIcon) }
      />
    );
  }
);

const SideBar = (props) => {
  const { t,i18n } = useTranslation("common");
  const { url } = useRouteMatch();
  let location = useLocation();
  const actualTab = location.pathname.split("/").pop();
  const [hoverColor,setHoverColor] = useState("#000000");
  const { pic } = props.brand;
  const classes = useStyles();

  const [lang,setLang] = useState("en");
  const [mobileMode,setMobileMode] = useState(false);

  const { admin,userClaims } = props;

  useEffect(() => {
    setMobileMode(props.mobileMode);
  },[props.mobileMode]);

  useEffect(() => {
    i18n.changeLanguage(lang);
  },[lang,i18n]);

  useEffect(() => {
    setLang(i18n.language !== "fr" ? "en" : "fr");
  },[i18n.language]);

  const handleMouseEnterEvent = () => {
    setHoverColor("#ff005f");
  };
  const handleMouseLeaveEvent = () => {
    setHoverColor("#000000");
  };
  const handleDrawerEvent = () => {
    props.navEvent();
  };
  const retourAdmin = () => {
    props.redirectAdmin();
  }

  let dashboardIcon;
  if (actualTab === "dashboard") {
    dashboardIcon = (
      <SVGIcon name="dashborad" width={ "20px" } height={ "20px" } fill="#ff005f" />
    );
  } else {
    dashboardIcon = (
      <SVGIcon
        name="dashborad"
        width={ "20px" }
        height={ "20px" }
        fill={ hoverColor }
      />
    );
  }
  return (
    <>
      <Grid
        className={ classes.bannerContainer }
        container
        justify="center"
        alignContent="center"
      >
        {/* <Avatar className={classes.largeLogo} alt={name} src={pic} /> */ }
        <Box className={ classes.logoBoxContent }>
          { pic && (
            <img
              alt="logo"
              src="https://firebasestorage.googleapis.com/v0/b/shopmyinfluens.appspot.com/o/shopmyinfluens%2FApp%20Data%2Flogo_shopmyinfluence-01.png?alt=media"
            />
          ) }
        </Box>
      </Grid>
      <Grid
        container
        className={ classes.drawerMenu }
        direction="row"
        justify="center"
        alignItems="center"
      >
        <Grid container item xs={ 12 }>
          <Grid container item xs={ 10 }>
            <NavLink
              to={ `${url}/dashboard` }
              className={ classes.navigationLink }
              activeClassName={ classes.navigationActiveLink }
              onMouseEnter={ handleMouseEnterEvent }
              onMouseLeave={ handleMouseLeaveEvent }
              onClick={ handleDrawerEvent }
            >
              { dashboardIcon }
              <Typography className={ classes.navigationSpacement }>
                { t("TREND.SIDEBAR.DASHBOARD_LINK") }
              </Typography>
            </NavLink>
          </Grid>
        </Grid>
        <Grid container item xs={ 12 }>
          <Grid container item xs={ 10 } justify="center">
            <NavLink
              to={ `${url}/influencers` }
              className={ classes.navigationLink }
              activeClassName={ classes.navigationActiveLink }
              onClick={ handleDrawerEvent }
            >
              <PeopleIcon />
              <Typography className={ classes.navigationSpacement }>
                { " " }
                { t("TREND.SIDEBAR.INFLUENCERS_LINK") }{ " " }
              </Typography>
            </NavLink>
          </Grid>
        </Grid>
        <Grid container item xs={ 12 }>
          <Grid container item xs={ 10 } justify="center">
            <NavLink
              to={ `${url}/statistiques` }
              className={ classes.navigationLink }
              activeClassName={ classes.navigationActiveLink }
              onClick={ handleDrawerEvent }
            >
              <TimelineIcon />
              <Typography className={ classes.navigationSpacement }>
                { " " }
                { t("TREND.SIDEBAR.STATISTICS_LINK") }{ " " }
              </Typography>
            </NavLink>
          </Grid>
        </Grid>
        { userClaims && (userClaims.brandAdmin || userClaims.admin) ?
          <Grid container item xs={ 12 }>
            <Grid container item xs={ 10 } justify='center'>
              <NavLink
                to={ `${url}/users` }
                className={ classes.navigationLink }
                activeClassName={ classes.navigationActiveLink }>
                <PersonIcon />
                <Typography className={ classes.navigationSpacement }> { t('TREND.SIDEBAR.USERS_LINK') } </Typography>
              </NavLink>
            </Grid>
          </Grid>
          :
          <></>
        }
        {/*  <Grid container item xs={12}>
          <Grid container item xs={10} justify="center">
            <NavLink
              to={`${url}/factures`}
              className={classes.navigationLink}
              activeClassName={classes.navigationActiveLink}
            >
              <DescriptionOutlinedIcon />
              <Typography className={classes.navigationSpacement}>
                {" "}
                {t("TREND.SIDEBAR.BILLS_LINK")}{" "}
              </Typography>
            </NavLink>
          </Grid>
          </Grid>*/}
        {/* <Grid container item xs={12}>
          <Grid container item xs={10} justify='center'>
            <NavLink
              to={`${url}/params`}
              className={classes.navigationLink}
              activeClassName={classes.navigationActiveLink}>
              <TuneIcon />
              <Typography className={classes.navigationSpacement}> { t('TREND.SIDEBAR.SETTINGS_LINK')} </Typography>
            </NavLink>
          </Grid>
        </Grid> */}
        <Grid container item xs={ 12 }>
          <Grid container item xs={ 10 } justify="center">
            <NavLink to={ `${url}/logout` } className={ classes.navigationLink }>
              <PowerSettingsNewIcon color="error" />
              <Typography
                className={ classes.navigationSpacement }
                style={ { color: "red" } }
              >
                { " " }
                { t("TREND.SIDEBAR.LOGOUT_LINK") }{ " " }
              </Typography>
            </NavLink>
          </Grid>
        </Grid>
        { admin ?
          <Grid
            container
            item
            direction="row-reverse"
            justify="center"
          >
            <Grid container item xs={ 12 }>
              <Grid container item xs={ 10 } justify="center" alignItems="center" onClick={ retourAdmin }>

                <Typography className={ mobileMode ? classes.placeholderRetourAdminMobile : classes.placeholderRetourAdmin } >
                  <span style={ { display: "flex",alignItems: "center" } }><ChevronLeftIcon /> { t("TREND.SIDEBAR.BUTTON_RETURN") } </span>
                </Typography>
              </Grid>
            </Grid>
          </Grid>
          :
          <></>
        }
        { mobileMode ? (
          <Grid
            container
            item
            xs={ 12 }
            direction="row-reverse"
            style={ { position: "absolute",bottom: 5,right: 5 } }
          >
            <Select
              value={ lang }
              disableUnderline
              onChange={ (e) => setLang(e.target.value) }
              classes={ { root: classes.selectClass } }
              IconComponent={ CustomExpandMore }
            >
              <MenuItem value="fr" style={ { fontFamily: "Poppins" } }>
                FR
              </MenuItem>
              <MenuItem value="en" style={ { fontFamily: "Poppins" } }>
                EN
              </MenuItem>
            </Select>
          </Grid>
        ) : (
          <></>
        ) }
      </Grid>

    </>
  );
};

export default SideBar;
