import React, { useState, useEffect } from 'react';
import { makeStyles } from "@material-ui/styles";
import { clicksManager, dbManager } from '../../managers';
import { Grid, Box, Typography, Card } from '@material-ui/core';
import ConversionsChart from '../template/components/ConversionsChart';
import MapChart from '../template/components/MapChart';
import ProductPieChart from '../template/components/ProductPieChart';
import EuroSymbolIcon from '@material-ui/icons/EuroSymbol';
import TouchAppIcon from '@material-ui/icons/TouchApp';
import SVGIcon from '../template/assets/SVGIcons/index';
import TrendLoading from '../../ui/trendLoading/TrendLoading';
import PlaceholderImage from '../assets/placeholder_sales.png';
import PlaceholderInfluenceurs from '../assets/placeholder_influenceur.png';
import CountUp from 'react-countup';
import { useTranslation } from "react-i18next";
import { connect } from 'react-redux';
import { fetchSoldArticleById } from '../../../store/actions/articlesActions';
import { useCallback } from 'react';

const useStyles = makeStyles(theme => {
    return {
        dashboardContent: {
            // minHeight: '100vh',
            width: '100%',
            flexGrow: 1,
            display: 'flex',
            flexDirection: 'column'
        },
        box: {
            height: '150px',
            width: '260px',
            borderRadius: '20px',
            display: 'flex',
            justifyContent: 'space-around',
            alignItems: 'center',
            paddingTop: '30px',
            paddingBottom: '30px',
            boxShadow: '0 7px 30px 0 rgba(88, 165, 211, 0.16)',
            margin: 10,
        },
        boxTitle: {
            fontFamily: 'Poppins',
            fontSize: '16px',
            fontWeight: 500,
            overflow: 'hidden',
            textOverflow: 'ellipsis'
        },
        boxText: {
            fontFamily: 'Poppins',
            fontSize: '16px',
            fontWeight: 900,
            overflow: 'hidden',
            textOverflow: 'ellipsis'
        },
        conversionBox: {
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center',
            backgroundColor: 'white',
            height: '300px',
            width: '95%',
            borderRadius: '20px',
            boxShadow: '0 7px 30px 0 rgba(88, 165, 211, 0.16)',
        },
        pieBox: {
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center',
            backgroundColor: 'white',
            height: '300px',
            width: '95%',
            borderRadius: '20px',
            boxShadow: '0 7px 30px 0 rgba(88, 165, 211, 0.16)',
        },
        mapBox: {
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center',
            backgroundColor: 'white',
            height: '250px',
            width: '95%',
            borderRadius: '20px',
            boxShadow: '0 7px 30px 0 rgba(88, 165, 211, 0.16)',
        },
        influencerBoxContainer: {
            marginTop: '5px',
            marginBottom: '5px',
            display: 'flex',
            alignItems: 'center'
        },
        influencerBox: {
            display: 'flex',
            width: '95%',
            backgroundColor: 'white',
            height: '40px',
            borderRadius: '41px',
            justifyContent: 'center',
            boxShadow: '0 7px 30px 0 rgba(88, 165, 211, 0.16)',
        },
        rowBoxes: {
            marginBottom: '15px',
            marginTop: '15px'
        },
        chartsTitle: {
            fontFamily: 'Poppins',
            fontWeight: 500,
        },
        textPlaceholder: {
            fontFamily: 'Poppins',
            fontSize: '16px',
            margin: 10,
            color: '#1b4c61',
            textAlign: 'center'
        }
    };
});

const NewDashboard = (props) => {
    const { t } = useTranslation('common');
    const classes = useStyles();

    const [currentMonthConversions, setCurrentMonthConversions] = useState([]);
    const [previousMonthConversions, setPreviousMonthConversions] = useState([]);
    const [articlesInfos, setArticlesInfos] = useState([]);
    const [influencersInfos, setInfluencersInfos] = useState([]);
    const [influencerToRender, setInfluencersToRender] = useState([]);    
    const [brandClicks, setBrandClicks] = useState(0);
    const [totalAmount, setTotalAmount] = useState(0);
    const [commissionToPay, setCommissionToPay] = useState(0);
    const [loading, setLoading] = useState(true);
    const {
        brand,
        // articles,
        brandConversions,
        influencers,
        loadedBrandConversions,
        loadedInfluencers
    } = props;


    const getCurrentMonthConversions = (conversions) => {
        const date = new Date();
        const start = new Date(date.getFullYear(), date.getMonth(), 1);
        const end = new Date(date.getFullYear(), date.getMonth() + 1, 0);
        const startDate = Math.round(start.getTime() / 1000);
        const endDate = Math.round(end.getTime() / 1000);
        let dateFilteredConversion = [];
        conversions.forEach(conversion => {
            if (conversion.createdAt < endDate && conversion.createdAt > startDate) {
                dateFilteredConversion.push(conversion);
            }
        });
        return dateFilteredConversion;
    }

    const getPreviousMonthConversions = (conversions) => {
        const date = new Date();
        const start = new Date(date.getFullYear(), date.getMonth(), 0);
        start.setDate(1);
        const end = new Date(date.getFullYear(), date.getMonth(), 0);
        const startDate = Math.round(start.getTime() / 1000);
        const endDate = Math.round(end.getTime() / 1000);
        let dateFilteredConversion = [];
        conversions.forEach(conversion => {
            if (conversion.createdAt < endDate && conversion.createdAt > startDate) {
                dateFilteredConversion.push(conversion);
            }
        });
        return dateFilteredConversion;
    }

    const filterArticle = async (conversion, index, productsIds) => {
        if (index === 0) {
            // const data = await dbManager.fetchArticleById(conversion.articleId);
            let data = {};
            if (conversion.articleImgUrl === undefined) {
                await dbManager.fetchSoldArticleById(conversion.articleId).then(res => {
                    data = res;
                });
            }
            productsIds.push({
                articleId: conversion.articleId,
                salesNumber: 1,
                articleImgUrl: (conversion.articleImgUrl === undefined) ? (data) ? data.image : '' : conversion.articleImgUrl,
                articlePathUrl: (conversion.articlePathUrl === undefined) ? (data) ? data.image : '' : conversion.articlePathUrl,
                // data
            });
        } else {
            let incremented = false;
            for (let j = 0; j < productsIds.length; j++) {
                if (productsIds[j].articleId === conversion.articleId) {
                    productsIds[j].salesNumber++;
                    incremented = true;
                    break;
                }
            }
            if (!incremented) {
                // const data = await dbManager.fetchArticleById(conversion.articleId);
                let data = {};
                if (conversion.articleImgUrl === undefined) {
                    await dbManager.fetchSoldArticleById(conversion.articleId).then(res => {
                        data = res;
                    });
                }
                productsIds.push({
                    articleId: conversion.articleId,
                    salesNumber: 1,
                    // data,
                    articleImgUrl: (conversion.articleImgUrl === undefined) ? (data) ? data.image : '' : conversion.articleImgUrl,
                    articlePathUrl: (conversion.articlePathUrl === undefined) ? (data) ? data.image : '' : conversion.articlePathUrl,
                });
            }
        }
    };

    const filterInfluencer = useCallback( async (conversion, index, influencersIds) => {
        if (index === 0) {
            const data = influencers.filter(item => item.key === conversion.influencer)[0];
            influencersIds.push({
                influencerId: conversion.influencer,
                amount: conversion.amount,
                salesNumber: 1,
                ...data
            });
        } else {
            let incremented = false;
            for (let j = 0; j < influencersIds.length; j++) {
                if (influencersIds[j].influencerId === conversion.influencer) {
                    influencersIds[j].amount += conversion.amount;
                    influencersIds[j].salesNumber++;
                    incremented = true;
                    break;
                }
            }
            if (!incremented) {
                const data = influencers.filter(item => item.key === conversion.influencer)[0];
                influencersIds.push({
                    influencerId: conversion.influencer,
                    amount: conversion.amount,
                    salesNumber: 1,
                    ...data
                });
            }
        }
    }, [influencers]);

    const filter = useCallback(async () => {
        if (loadedInfluencers && loadedBrandConversions) {
            let productsIds = []
            let influencersIds = []
            let amount = 0;
            let commissions = 0;
            for (let i = 0; i < brandConversions.length; i++) {
                amount += brandConversions[i].amount;
                if (!isNaN(Number(brandConversions[i].commission))) {
                    commissions += Number(brandConversions[i].commission)
                }
                await filterArticle(brandConversions[i], i, productsIds);
                await filterInfluencer(brandConversions[i], i, influencersIds)
            }
            influencersIds.sort((a, b) => {
                if (a.amount > b.amount) return -1;
                if (a.amount < b.amount) return 1;
                return 0;
            });
            if (influencersIds.length !== 0) {
                setArticlesInfos(productsIds);
                setInfluencersInfos(influencersIds);
                setCommissionToPay(commissions);
            }
            setLoading(false);
            setTotalAmount(Math.round(amount * 100) / 100);
        }
    }, [brandConversions, loadedInfluencers, loadedBrandConversions, filterInfluencer]);

    useEffect(() => {
        if (loadedBrandConversions) {
            setCurrentMonthConversions(getCurrentMonthConversions(brandConversions));
            setPreviousMonthConversions(getPreviousMonthConversions(brandConversions));
        }
        clicksManager.getClicksPerBrand(1516596656000, (new Date()).getTime(), brand.offerId).then(result => {
            setBrandClicks(result.data.clicksNumber);
        })
    }, [brandConversions, loadedBrandConversions, brand]);

    useEffect(() => {
        filter();
    }, [filter]);

    useEffect(() => {
        let table = influencersInfos.filter(element =>
            element.name !== undefined
        );
        setInfluencersToRender(table);
    }, [influencersInfos]);


    return (
        <Grid className={classes.dashboardContent}>
            {loading ? (
                <TrendLoading />
            ) : (<>
                <Grid container item direction='row' xs={12} justify='center'>
                    <Grid container item direction='row' wrap='wrap' justify='space-around' alignItems='center' className={classes.rowBoxes}>
                        <Card className={classes.box}>
                            <Grid container item xs={12} alignItems='stretch'>
                                <Grid container item xs={4} justify='center'>
                                    <EuroSymbolIcon style={{ fontSize: '60px', color: '#ef7e3e' }} />
                                </Grid>
                                <Grid container item xs={7} justify='center' alignItems='center'>
                                    <Grid item xs={12}>
                                        <Typography className={classes.boxTitle}>
                                            {t('TREND.VIEWS.DASHBOARD.TURNOVER_BOX_TITLE')}
                                        </Typography>
                                    </Grid>
                                    <Grid item xs={12}>
                                        <Typography className={classes.boxText}>
                                            <CountUp duration={4} start={0} end={totalAmount} decimal="," decimals={2} /> €
                                            {/* {totalAmount}  */}
                                        </Typography>
                                    </Grid>
                                </Grid>
                            </Grid>
                        </Card>
                        <Card className={classes.box}>
                            <Grid container item xs={12} alignItems='stretch'>
                                <Grid container item xs={4} justify='center'>
                                    <SVGIcon name='ventes' width={'60px'} height={'60px'} />
                                </Grid>
                                <Grid container item xs={7} justify='center' alignItems='center'>
                                    <Grid item xs={12}>
                                        <Typography className={classes.boxTitle}>
                                            {t('TREND.VIEWS.DASHBOARD.SALES_BOX_TITLE')}
                                        </Typography>
                                    </Grid>
                                    <Grid item xs={12}>
                                        <Typography className={classes.boxText}>
                                            <CountUp duration={4} start={0} end={brandConversions.length} />
                                            {/* {brandConversions.length} */}
                                        </Typography>
                                    </Grid>
                                </Grid>
                            </Grid>
                        </Card>
                        <Card className={classes.box}>
                            <Grid container item xs={12} alignItems='stretch'>
                                <Grid container item xs={4} justify='center'>
                                    <SVGIcon name='nombreInfluenceurs' width={'55px'} height={'55px'} />
                                </Grid>
                                <Grid container item xs={7} justify='center' alignItems='center'>
                                    <Grid item xs={12}>
                                        <Typography className={classes.boxTitle}>
                                            {t('TREND.VIEWS.DASHBOARD.INFLUENCERS_BOX_TITLE')}
                                        </Typography>
                                    </Grid>
                                    <Grid item xs={12}>
                                        <Typography className={classes.boxText}>
                                            <CountUp duration={4} start={0} end={influencersInfos.length} />
                                            {/* {influencersInfos.length} */}
                                        </Typography>
                                    </Grid>
                                </Grid>
                            </Grid>
                        </Card>
                        <Card className={classes.box}>
                            <Grid container item xs={12} alignItems='stretch'>
                                <Grid container item xs={4} justify='center' alignItems='center'>
                                    <SVGIcon name='commissionPaye' width={'55px'} height={'55px'} />
                                </Grid>
                                <Grid container item xs={7} justify='center' alignItems='center'>
                                    <Grid item xs={12}>
                                        <Typography className={classes.boxTitle}>
                                            {t('TREND.VIEWS.DASHBOARD.PAID_COMMISSIONS_BOX_TITLE')}
                                        </Typography>
                                    </Grid>
                                    <Grid item xs={12}>
                                        <Typography className={classes.boxText}>
                                            0 €
                                    </Typography>
                                    </Grid>
                                </Grid>
                            </Grid>
                        </Card>
                        <Card className={classes.box}>
                            <Grid container item xs={12} alignItems='stretch'>
                                <Grid container item xs={4} justify='center' alignItems='center'>
                                    <SVGIcon name='commission' width={'55px'} height={'55px'} />
                                </Grid>
                                <Grid container item xs={7} justify='center' alignItems='center'>
                                    <Grid item xs={12}>
                                        <Typography className={classes.boxTitle}>
                                            {t('TREND.VIEWS.DASHBOARD.UNPAID_COMMISSIONS_BOX_TITLE')}
                                        </Typography>
                                    </Grid>
                                    <Grid item xs={12}>
                                        <Typography className={classes.boxText}>
                                            <CountUp duration={4} start={0} end={Math.round(commissionToPay * 100) / 100} decimals={2} decimal=',' /> €
                                            {/* {Math.round(commissionToPay * 100) / 100}  */}
                                        </Typography>
                                    </Grid>
                                </Grid>
                            </Grid>
                        </Card>
                        <Card className={classes.box}>
                            <Grid container item xs={12} alignItems='stretch'>
                                <Grid container item xs={4} justify='center' alignItems='center'>
                                    <TouchAppIcon style={{ fontSize: '60px', color: '#3568eb' }} />
                                </Grid>
                                <Grid container item xs={7} justify='center' alignItems='center'>
                                    <Grid item xs={12}>
                                        <Typography className={classes.boxTitle}>
                                            {t('TREND.VIEWS.DASHBOARD.CLICKS_BOX_TITLE')}
                                        </Typography>
                                    </Grid>
                                    <Grid item xs={12}>
                                        <Typography className={classes.boxText}>
                                            <CountUp duration={4} start={0} end={brandClicks} />
                                            {/* {Math.round(commissionToPay * 100) / 100}  */}
                                        </Typography>
                                    </Grid>
                                </Grid>
                            </Grid>
                        </Card>
                    </Grid>
                </Grid>
                <Grid container direction='row' item xs={12} justify='center' alignItems='center'>
                    <Grid container direction='row' item md={7} sm={12} justify='center'>
                        <Grid container justify='center'>
                            <Grid container justify='center' style={{ marginTop: '10px', marginBottom: '10px' }}>
                                <Typography className={classes.chartsTitle}>
                                    {t('TREND.VIEWS.DASHBOARD.SALES_STATISTICS_BOX_TITLE')}
                                </Typography>
                            </Grid>
                            <Box className={classes.conversionBox}>
                                <ConversionsChart {...{ currentMonthConversions, previousMonthConversions }} />
                            </Box>
                        </Grid>
                    </Grid>
                    <Grid container direction='row' item md={5} sm={12} justify='center'>
                        <Grid container justify='center' style={{ marginTop: '10px', marginBottom: '10px' }}>
                            <Typography className={classes.chartsTitle}>
                                {t('TREND.VIEWS.DASHBOARD.SALES_PER_PRODUCT_NUMBER_BOX_TITLE')}
                            </Typography>
                        </Grid>
                        <Box className={classes.pieBox}>
                            {
                                (articlesInfos.length !== 0)
                                    ? <ProductPieChart {...{ articlesInfos }} />
                                    : <Grid container direction='column' justify='space-around' alignItems='center' style={{ height: '50%' }}>
                                        <img alt='No Sales' src={PlaceholderImage} height='75px' width='75px' />
                                        <Typography className={classes.textPlaceholder}>
                                            {t('TREND.VIEWS.DASHBOARD.NO_GENERATED_SALES_PLACEHOLDER')}
                                        </Typography>
                                    </Grid>
                            }
                        </Box>
                    </Grid>
                </Grid>
                <Grid container direction='row' item xs={12} justify='space-around' className={classes.rowBoxes}>
                    {/* <Grid container item direction='row' xs={11}> */}
                    <Grid container item md={7} sm={12} justify='center' style={{ marginTop: '10px', marginBottom: '10px' }}>
                        <Grid container item direction='row' style={{ width: '95%' }} alignItems='center'>
                            <Grid container item xs={7} alignItems='flex-start'>
                                <Typography className={classes.chartsTitle}>
                                    {t('TREND.VIEWS.DASHBOARD.TOP_FIVE_INFUENCERS_TABLE_HEADER')}
                                </Typography>
                            </Grid>
                            <Grid item container xs={3}>
                                <Typography className={classes.chartsTitle}>
                                    {t('TREND.VIEWS.DASHBOARD.TURNOVER_TABLE_HEADER')}
                                </Typography>
                            </Grid>
                            <Grid item xs={2}>
                                <Typography className={classes.chartsTitle}>
                                    {t('TREND.VIEWS.DASHBOARD.SALES_TABLE_HEADER')}
                                </Typography>
                            </Grid>
                        </Grid>
                        {
                            (influencerToRender.length !== 0)
                                ? <Grid container item justify='flex-start' style={{ height: '250px' }}>
                                    {influencerToRender.slice(0, 5).map((influencer, i) => {
                                        return (
                                            <Grid container
                                                item
                                                direction='row'
                                                justify='center'
                                                className={classes.influencerBoxContainer}
                                                key={influencer.influencerId}>
                                                <Box className={classes.influencerBox}>
                                                    <Grid container item direction='row' xs={12}>
                                                        <Grid container item justify='flex-start' alignItems='center' xs={7}>
                                                            <Typography style={{ paddingLeft: 10 }}>
                                                                {i + 1 + '. ' + influencer.name}
                                                            </Typography>
                                                        </Grid>
                                                        <Grid container item justify='flex-start' alignItems='center' xs={3}>
                                                            {Math.round(influencer.amount * 100) / 100} €
                                                    </Grid>
                                                        <Grid container item justify='flex-start' alignItems='center' xs={2}>
                                                            {influencer.salesNumber}
                                                        </Grid>
                                                    </Grid>
                                                </Box>
                                            </Grid>
                                        );
                                    })}
                                </Grid>
                                : <Grid container direction='column' justify='space-around' alignItems='center' style={{ height: '50%' }}>
                                    <img alt='No influencers' src={PlaceholderInfluenceurs} height='75px' width='75px' />
                                    <Typography className={classes.textPlaceholder}>
                                        {t('TREND.VIEWS.DASHBOARD.NO_INFLUENCER_GENERATING_SALES_PLACEHOLDER')}
                                    </Typography>
                                </Grid>
                        }

                    </Grid>
                    <Grid container item md={5} sm={12} alignItems='center' justify='center'>
                        <Grid container justify='center' style={{ marginTop: '10px', marginBottom: '10px' }}>
                            <Typography className={classes.chartsTitle}>
                                {t('TREND.VIEWS.DASHBOARD.SALES_PER_COUNTRY_NUMBER_BOX_TITLE')}
                            </Typography>
                        </Grid>
                        <Box className={classes.mapBox}>
                            <MapChart {...{ brandConversions }} />
                        </Box>
                    </Grid>
                    {/* </Grid> */}
                </Grid>
            </>)}
        </Grid>
    )
}

const mapStateToProps = (state, ownProps) => {
    const brandConversions = state.conversionsState.brandConversions;
    const loadedBrandConversions = state.conversionsState.loadedBrandConversions;
    const influencers = state.influencersState.influencers;
    const loadedInfluencers = state.influencersState.loadedInfluencers;
    const articles = state.articlesState.articles;
    return {
        brandConversions,
        loadedBrandConversions,
        influencers,
        loadedInfluencers,
        articles
    }
}

const mapDispatchToProps = (dispatch) => {
    return {
        fetchSoldArticleById: (articleId) => dispatch(fetchSoldArticleById(articleId))
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(NewDashboard);