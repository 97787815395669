import React, { useState, useEffect } from "react";
import { makeStyles, Grid, Box, Typography, IconButton } from "@material-ui/core";
import EuroSymbolIcon from '@material-ui/icons/EuroSymbol';
import SVGIcon from '../../../assets/SVGIcons/index';
import CloseIcon from '@material-ui/icons/Close';
import ExpandLessIcon from '@material-ui/icons/ExpandLess';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import SortIcon from '@material-ui/icons/Sort';
import { useTranslation } from "react-i18next";

const useStyles = makeStyles(theme => {
    return {
        overallBoxContainer: {
            width: '95%',
            display: 'flex',
            justifyContent: 'center',
            marginBottom: '30px',
            marginTop: '30px'
        },
        overallBoxStyles: {
            backgroundColor: 'white',
            borderRadius: '35px',
            boxShadow: '0 7px 30px 0 rgba(88, 165, 211, 0.16)',
            width: '100%',
        },
        influencerImageBox: {
            width: 'inherit',
            margin: '10px',
            borderRadius: '35px',
            boxShadow: '0 7px 30px 0 rgba(88, 165, 211, 0.16)',
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center'
        },
        influencerInfosBox: {
            height: '200px',
        },
        influencerImage: {
            objectFit: 'cover',
            width: '90%',
            height: "200px",
            margin: "10px",
            borderRadius: '35px',
            boxShadow: '0 7px 30px 0 rgba(88, 165, 211, 0.16)',
        },
        smallBox: {
            boxShadow: '0 7px 30px 0 rgba(58, 111, 142, 0.05)',
            borderRadius: '41px',
            width: '200px',
            height: '150px',
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center',
            margin: 10,
        },
        tableRowContent: {
            fontFamily: 'Poppins',
        },
        influencerEmail: {
            fontFamily: 'Poppins',
            fontSize: '14px',
            fontWeight: 200,
            color: '#000000',
        },
        influencerInstagram: {
            fontFamily: 'Poppins',
            fontSize: '25px',
            color: '#000000',
            textDecoration: 'none',
        },
        influencerStatsTitles: {
            fontFamily: 'Poppins',
            fontSize: '16px',
            fontWeight: 500,
            color: '#000000'
        },
        influencerStats: {
            fontFamily: 'Poppins',
            fontSize: '18px',
            fontWeight: 900,
            color: '#000000'
        },
        spacement: {
            marginTop: '15px',
            marginBottom: '15px',
        },
        tableHeader: {
            fontFamily: 'Poppins',
            fontSize: '14px',
            textAlign: 'center',
            '&:hover': {
                cursor: 'pointer',
            }
        },
        influencerBoxContainer: {
            marginTop: '10px',
            marginBottom: '10px',
            minWidth: '1050px'
        },
        influencerBox: {
            display: 'flex',
            width: '100%',
            backgroundColor: 'white',
            height: '30px',
            borderRadius: '27px',
        },
        influencerName: {
            fontFamily: 'Poppins',
            fontSize: '25px',
            fontWeight: 'bold',
            color: '#000000',
            wordBreak: 'break-word',
        },
    }
});



const CountryModal = (props) => {
    const { t } = useTranslation('common');

    const classes = useStyles();

    const [country, setCountry] = useState({});
    const [periodConversions, setPeriodConversions] = useState([]);
    const [countryConversions, setCountryConversions] = useState([]);
    const [conversionsToRender, setConversionsToRender] = useState([]);
    const [sortHelper, setSortHelper] = useState([0, 0, 0]);
    const [sortEvent, setSortEvent] = useState(0);

    useEffect(() => {
        setCountry(props.data);
        setPeriodConversions(props.periodBrandConversions);
    }, [props.periodBrandConversions, props.data])

    useEffect(() => {
        setCountryConversions(periodConversions.filter(element => element.countryCode === country.countryCode));
    }, [periodConversions, country.countryCode]);

    useEffect(() => {
        setConversionsToRender(countryConversions);
    }, [countryConversions, sortEvent]);

    useEffect(() => {
        let indexAsc = sortHelper.indexOf(1);
        let indexDesc = sortHelper.indexOf(-1);
        if (indexAsc !== -1) {
            const sortTypeAsc = (indexAsc, tableToWorkOn) => {
                switch (indexAsc) {
                    case 0:
                        tableToWorkOn.sort((a, b) => {
                            if (a.amount > b.amount) return -1;
                            if (a.amount < b.amount) return 1;
                            return 0;
                        });
                        break;
                    case 1:
                        tableToWorkOn.sort((a, b) => {
                            if (a.commission > b.commission) return -1;
                            if (a.commission < b.commission) return 1;
                            return 0;
                        });
                        break;
                    case 2:
                        tableToWorkOn.sort((a, b) => {
                            if (a.countryCode > b.countryCode) return -1;
                            if (a.countryCode < b.countryCode) return 1;
                            return 0;
                        });
                        break;
                    default:
                        break;
                }
            }
            sortTypeAsc(indexAsc, countryConversions);
        } else {
            const sortTypeDesc = (indexDesc, tableToWorkOn) => {
                switch (indexDesc) {
                    case 0:
                        tableToWorkOn.sort((a, b) => {
                            if (a.amount > b.amount) return 1;
                            if (a.amount < b.amount) return -1;
                            return 0;
                        });
                        break;
                    case 1:
                        tableToWorkOn.sort((a, b) => {
                            if (a.commission > b.commission) return 1;
                            if (a.commission < b.commission) return -1;
                            return 0;
                        });
                        break;
                    case 2:
                        tableToWorkOn.sort((a, b) => {
                            if (a.countryCode > b.countryCode) return 1;
                            if (a.countryCode < b.countryCode) return -1;
                            return 0;
                        });
                        break;
                    default:
                        break;
                }
            };
            sortTypeDesc(indexDesc, countryConversions);
        }
        setSortEvent((s) => s + 1);
    }, [sortHelper, countryConversions]);

    const closePopup = () => {
        props.close(true);
    };

    return (
        <Grid container item direction='row' justify='center'>
            <Grid container item direction='row' className={classes.overallBoxContainer}>
                <Box className={classes.overallBoxStyles}>
                    <Grid container direction="column" alignItems="center" justify="center" style={{ display: "flex" }}>
                        <Grid container item direction='row-reverse' sm={12}>
                            <IconButton onClick={closePopup} style={{ marginTop: '30px', marginRight: '30px', color: '#000' }}>
                                <CloseIcon fontSize='large' />
                            </IconButton>
                        </Grid>
                        <Grid container item direction='row' justify='center' className={classes.spacement}>
                            <Grid container item direction='row' sm={11} justify='center'>
                                <Grid container item direction='row' justify='center' alignItems='center' wrap='wrap'>
                                    <Grid container item lg={4} md={12} sm={12} justify='center'>
                                        {/* <Box className={classes.influencerImageBox}> */}
                                        <img src={(country.countryCode !== undefined) ? "https://flagcdn.com/h240/" + country.countryCode.toLowerCase() + ".png" : ''} alt={country.countryCode} className={classes.influencerImage} />
                                        {/* </Box> */}
                                    </Grid>
                                    <Grid container item lg={8} md={12} sm={12} wrap='wrap' justify='center' alignItems='center'>
                                        <Box className={classes.smallBox}>
                                            <Grid container direction='row'>
                                                <Grid container item xs={6} justify='center'>
                                                    <SVGIcon name='ventes' width={'60px'} height={'60px'} />
                                                </Grid>
                                                <Grid container item xs={6} direction='column' justify='center'>
                                                    <Typography className={classes.influencerStatsTitles}>{t('TREND.VIEWS.STATISTICS.COUNTYRY_POPUP.SALES_TITLE_BOX')}</Typography>
                                                    <Typography className={classes.influencerStats}>{country.purchaseNumber}</Typography>
                                                </Grid>
                                            </Grid>
                                        </Box>
                                        <Box className={classes.smallBox}>
                                            <Grid container direction='row'>
                                                <Grid container item xs={6} justify='center'>
                                                    <EuroSymbolIcon style={{ fontSize: '60px', color: '#ef7e3e' }} />
                                                </Grid>
                                                <Grid container item xs={6} direction='column' justify='center'>
                                                    <Typography className={classes.influencerStatsTitles}>{t('TREND.VIEWS.STATISTICS.COUNTYRY_POPUP.TURNOVER_TITLE_BOX')}</Typography>
                                                    <Typography className={classes.influencerStats}>{Math.round(country.amount * 100) / 100} €</Typography>
                                                </Grid>
                                            </Grid>
                                        </Box>
                                        {/* <Box className={classes.smallBox}>
                                            <Grid container direction='row'>
                                                <Grid container item xs={6} justify='center'>
                                                    <TouchAppIcon style={{ fontSize: '60px', color: '#3568eb' }} />
                                                </Grid>
                                                <Grid container item xs={6} direction='column' justify='center'>
                                                    <Typography className={classes.influencerStatsTitles}>Cliques</Typography>
                                                    <Typography className={classes.influencerStats}>100</Typography>
                                                </Grid>
                                            </Grid>
                                        </Box> */}
                                    </Grid>
                                </Grid>
                                <Grid container item direction='row' style={{ overflowX: 'auto', width: '100%', marginTop: '50px' }}>
                                    <Grid container item direction='row-reverse' style={{ minWidth: '1050px' }}>
                                        <Grid
                                            container
                                            item
                                            justify='center'
                                            alignItems='center'
                                            style={{ width: '13%' }}
                                        >
                                            <Typography className={classes.tableHeader}>{t('TREND.VIEWS.STATISTICS.COUNTYRY_POPUP.DEVICE_TYPE_HEADER')}</Typography>
                                        </Grid>
                                        <Grid
                                            container
                                            item
                                            justify='center'
                                            alignItems='center'
                                            style={{ width: '13%' }}
                                            onClick={() => {
                                                (sortHelper[1] === 0) ? setSortHelper([0, 1, 0]) : setSortHelper([0, -sortHelper[1], 0]);
                                            }}
                                        >
                                            <Typography className={classes.tableHeader}>{t('TREND.VIEWS.STATISTICS.COUNTYRY_POPUP.COMMISSIONS_HEADER')}</Typography>
                                            {(sortHelper[1] === 1) ? <ExpandLessIcon className={classes.tableHeaderIcon} /> : ((sortHelper[1] === -1) ? <ExpandMoreIcon className={classes.tableHeaderIcon} /> : <SortIcon className={classes.tableHeaderIcon} />)}
                                        </Grid>
                                        <Grid
                                            container
                                            item justify='center'
                                            alignItems='center'
                                            style={{ width: '13%' }}
                                            onClick={() => {
                                                (sortHelper[0] === 0) ? setSortHelper([1, 0, 0]) : setSortHelper([-sortHelper[0], 0, 0]);
                                            }}
                                        >
                                            <Typography className={classes.tableHeader}>{t('TREND.VIEWS.STATISTICS.COUNTYRY_POPUP.AMOUNT_HEADER')}</Typography>
                                            {(sortHelper[0] === 1) ? <ExpandLessIcon className={classes.tableHeaderIcon} /> : ((sortHelper[0] === -1) ? <ExpandMoreIcon className={classes.tableHeaderIcon} /> : <SortIcon className={classes.tableHeaderIcon} />)}
                                        </Grid>
                                    </Grid>
                                    {conversionsToRender.map(conversion => (
                                        <Grid container
                                            item
                                            direction='row'
                                            justify='center'
                                            className={classes.influencerBoxContainer}
                                            key={conversion.key}>
                                            <Box className={classes.influencerBox}>
                                                <Grid container item direction='row' style={{ width: '100%' }}>
                                                    <Grid container item justify='flex-start' alignItems='center' style={{ width: '61%' }}>
                                                        <Typography className={classes.tableRowContent} style={{ paddingLeft: '20px' }}>
                                                            {new Date(conversion.createdAt * 1000).toLocaleDateString()} {new Date(conversion.createdAt * 1000).toLocaleTimeString({ hour12: false })}
                                                        </Typography>
                                                    </Grid>
                                                    <Grid container item justify='center' alignItems='center' style={{ width: '13%' }}>
                                                        <Typography className={classes.tableRowContent}>{Math.round(conversion.amount * 100) / 100} €</Typography>
                                                    </Grid>
                                                    <Grid container item justify='center' alignItems='center' style={{ width: '13%' }}>
                                                        <Typography className={classes.tableRowContent}>{Number(conversion.commission)} €</Typography>
                                                    </Grid>
                                                    <Grid container item justify='center' alignItems='center' style={{ width: '13%' }}>
                                                        {
                                                            (conversion.deviseType === undefined)
                                                                ? <Typography className={classes.tableRowContent}>{t('TREND.VIEWS.STATISTICS.COUNTRY_POPUP.UNSPECIFIED')}</Typography>
                                                                : <Typography className={classes.tableRowContent}>{conversion.deviseType} : {conversion.os}</Typography>
                                                        }
                                                    </Grid>
                                                </Grid>
                                            </Box>
                                        </Grid>
                                    ))}
                                </Grid>
                            </Grid>
                        </Grid>
                    </Grid>
                </Box>
            </Grid >
        </Grid >
    );
};

export default CountryModal;
