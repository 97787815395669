import { Button, Grid } from '@material-ui/core';
import { makeStyles } from '@material-ui/styles';
import React, { useState } from 'react';
import CategoriesSubView from './categories-sub-views/CategoriesSubView';
import SoldProductSubView from './categories-sub-views/SoldProductSubView';
import SubCategoriesSubView from './categories-sub-views/SubCategoriesSubView';
import { useTranslation } from "react-i18next";

const useStyles = makeStyles(theme => ({
    categoriesContent: {
        flexGrow: 1,
        display: 'flex',
        flexDirection: 'column',
        backgroundColor: "#eff9fe",
        alignItems: 'center'
    },
    categButtonStyle: {
        width: '170px',
        fontFamily: 'Poppins',
        backgroundColor: '#ffffff',
        margin: '10px',
        borderRadius: '27px',
        textTransform: 'none',
        '&:hover': {
            backgroundColor: '#ffffff',
        }
    },
    activeCategButtonStyle: {
        width: '170px',
        color: '#ffffff',
        fontFamily: 'Poppins',
        backgroundColor: '#3eadef',
        margin: '10px',
        borderRadius: '27px',
        textTransform: 'none',
        '&:hover': {
            backgroundColor: '#3eadef',
        }
    },
    tabButtonsRow: {
        marginTop: '20px',
        marginBottom: '20px'
    }
}));

const Categories = (props) => {

    const { t } = useTranslation("common");
    const classes = useStyles();

    const [activeTab, setActiveTab] = useState('categories');

    const handleTabChange = (tabType) => {
        setActiveTab(tabType);
    };

    return (
        <Grid className={classes.categoriesContent}>
            <div style={{ width: '100%', display: 'flex', flexDirection: 'column', alignItems: 'center', flexGrow: 1 }}>
                <Grid container item direction='row' justify='center' alignItems='center' xs={12} style={{ flexGrow: 1 }}>
                    <Grid container item direction='column' xs={11} style={{ height: '100%' }}>
                        <Grid container item direction='row' className={classes.tabButtonsRow}>
                            <Button className={(activeTab === 'categories') ? classes.activeCategButtonStyle : classes.categButtonStyle} onClick={() => handleTabChange('categories')}>
                                {t('ADMIN.VIEWS.CATEGORIES.CATEGORIES_TAB')}
                            </Button>
                            <Button className={(activeTab === 'subCategories') ? classes.activeCategButtonStyle : classes.categButtonStyle} onClick={() => handleTabChange('subCategories')}>
                            {t('ADMIN.VIEWS.CATEGORIES.SUB_CATEGORIES_TAB')}
                            </Button>
                            <Button className={(activeTab === 'soldProducts') ? classes.activeCategButtonStyle : classes.categButtonStyle} onClick={() => handleTabChange('soldProducts')}>
                            {t('ADMIN.VIEWS.CATEGORIES.SOLD_PRODUCTS_TAB')}
                            </Button>
                        </Grid>
                        {
                            (activeTab === 'categories')
                                ? <CategoriesSubView />
                                : (activeTab === 'subCategories')
                                    ? <SubCategoriesSubView />
                                    : <SoldProductSubView />
                        }
                    </Grid>
                </Grid>
            </div>
        </Grid>
    )
};

export default Categories;