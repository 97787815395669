export default
    [
        {
            "name": "World Wide",
            "code": "worldwide",
            "nameFr": "Tout le monde"
        },
        {
            "name": "Afghanistan",
            "code": "AF",
            "nameFr": "Afghanistan"
        },
        {
            "name": "Åland Islands",
            "code": "AX",
            "nameFr": "Îles Åland"
        },
        {
            "name": "Albania",
            "code": "AL",
            "nameFr": "Albanie"
        },
        {
            "name": "Algeria",
            "code": "DZ",
            "nameFr": "Algérie"
        },
        {
            "name": "American Samoa",
            "code": "AS",
            "nameFr": "Samoa Américaines"
        },
        {
            "name": "AndorrA",
            "code": "AD",
            "nameFr": "Andorre"
        },
        {
            "name": "Angola",
            "code": "AOO",
            "nameFr": "Angola"
        },
        {
            "name": "Anguilla",
            "code": "AI",
            "nameFr": "Anguilla"
        },
        {
            "name": "Antarctica",
            "code": "AQ",
            "nameFr": "Antarctique"
        },
        {
            "name": "Antigua and Barbuda",
            "code": "AG",
            "nameFr": "Antigua-Et-Barbuda"
        },
        {
            "name": "Argentina",
            "code": "AR",
            "nameFr": "Argentine"
        },
        {
            "name": "Armenia",
            "code": "AM",
            "nameFr": "Arménie"
        },
        {
            "name": "Aruba",
            "code": "AW",
            "nameFr": "Aruba"
        },
        {
            "name": "Australia",
            "code": "AU",
            "nameFr": "Australie"
        },
        {
            "name": "Austria",
            "code": "AT",
            "nameFr": "Autriche"
        },
        {
            "name": "Azerbaijan",
            "code": "AZ",
            "nameFr": "Azerbaïdjan"
        },
        {
            "name": "Bahamas",
            "code": "BS",
            "nameFr": "Bahamas"
        },
        {
            "name": "Bahrain",
            "code": "BH",
            "nameFr": "Bahreïn"
        },
        {
            "name": "Bangladesh",
            "code": "BD",
            "nameFr": "Bangladesh"
        },
        {
            "name": "Barbados",
            "code": "BB",
            "nameFr": "Barbad"
        },
        {
            "name": "Belarus",
            "code": "BY",
            "nameFr": "Biélorussie"
        },
        {
            "name": "Belgium",
            "code": "BE",
            "nameFr": "Belgique"
        },
        {
            "name": "Belize",
            "code": "BZ",
            "nameFr": "Belize"
        },
        {
            "name": "Benin",
            "code": "BJ",
            "nameFr": "Bénin"
        },
        {
            "name": "Bermuda",
            "code": "BM",
            "nameFr": "Bermudes"
        },
        {
            "name": "Bhutan",
            "code": "BT",
            "nameFr": "Bhoutan"
        },
        {
            "name": "Bolivia",
            "code": "BO",
            "nameFr": "État Plurinational De Bolivie"
        },
        {
            "name": "Bosnia and Herzegovina",
            "code": "BA",
            "nameFr": "Bosnie-Herzégovine"
        },
        {
            "name": "Botswana",
            "code": "BW",
            "nameFr": "Botswana"
        },
        {
            "name": "Bouvet Island",
            "code": "BV",
            "nameFr": "Île Bouvet"
        },
        {
            "name": "Brazil",
            "code": "BR",
            "nameFr": "Brésil"
        },
        {
            "name": "British Indian Ocean Territory",
            "code": "IO",
            "nameFr": "Territoire Britannique De L'océan Indien"
        },
        {
            "name": "Brunei Darussalam",
            "code": "BN",
            "nameFr": "Brunei Darussalam"
        },
        {
            "name": "Bulgaria",
            "code": "BG",
            "nameFr": "Bulgarie"
        },
        {
            "name": "Burkina Faso",
            "code": "BF",
            "nameFr": "Burkina Faso"
        },
        {
            "name": "Burundi",
            "code": "BI",
            "nameFr": "Burundi"
        },
        {
            "name": "Cambodia",
            "code": "KH",
            "nameFr": "Cambodge"
        },
        {
            "name": "Cameroon",
            "code": "CM",
            "nameFr": "Cameroun"
        },
        {
            "name": "Canada",
            "code": "CA",
            "nameFr": "Canada"
        },
        {
            "name": "Cape Verde",
            "code": "CV",
            "nameFr": "Cap-Vert"
        },
        {
            "name": "Cayman Islands",
            "code": "KY",
            "nameFr": "Îles Caïmans"
        },
        {
            "name": "Central African Republic",
            "code": "CF",
            "nameFr": "République Centrafricaine"
        },
        {
            "name": "Chad",
            "code": "TD",
            "nameFr": "Tchad"
        },
        {
            "name": "Chile",
            "code": "CL",
            "nameFr": "Chili"
        },
        {
            "name": "China",
            "code": "CN",
            "nameFr": "Chine"
        },
        {
            "name": "Christmas Island",
            "code": "CX",
            "nameFr": "Île Christmas"
        },
        {
            "name": "Cocos (Keeling) Islands",
            "code": "CC",
            "nameFr": "Îles Cocos"
        },
        {
            "name": "Colombia",
            "code": "CO",
            "nameFr": "Colombie"
        },
        {
            "name": "Comoros",
            "code": "KM",
            "nameFr": "Comores"
        },
        {
            "name": "Congo",
            "code": "CG",
            "nameFr": "Congo"
        },
        {
            "name": "Congo, The Democratic Republic of the",
            "code": "CD",
            "nameFr": "République Démocratique Du Congo"
        },
        {
            "name": "Cook Islands",
            "code": "CK",
            "nameFr": "Îles Cook"
        },
        {
            "name": "Costa Rica",
            "code": "CR",
            "nameFr": "Costa Rica"
        },
        {
            "name": "Cote D'Ivoire",
            "code": "CI",
            "nameFr": "Côte D'Ivoire"
        },
        {
            "name": "Croatia",
            "code": "HR",
            "nameFr": "Croatie"
        },
        {
            "name": "Cuba",
            "code": "CU",
            "nameFr": "Cuba"
        },
        {
            "name": "Cyprus",
            "code": "CY",
            "nameFr": "Chypre"
        },
        {
            "name": "Czech Republic",
            "code": "CZ",
            "nameFr": "République Tchèque"
        },
        {
            "name": "Denmark",
            "code": "DK",
            "nameFr": "Denmark"
        },
        {
            "name": "Djibouti",
            "code": "DJ",
            "nameFr": "Djibouti"
        },
        {
            "name": "Dominica",
            "code": "DM",
            "nameFr": "Dominique"
        },
        {
            "name": "Dominican Republic",
            "code": "DO",
            "nameFr": "République Dominicaine"
        },
        {
            "name": "Ecuador",
            "code": "EC",
            "nameFr": "Équateur"
        },
        {
            "name": "Egypt",
            "code": "EG",
            "nameFr": "Égypte"
        },
        {
            "name": "El Salvador",
            "code": "SV",
            "nameFr": "République Du Salvador"
        },
        {
            "name": "Equatorial Guinea",
            "code": "GQ",
            "nameFr": "Guinée Équatoriale"
        },
        {
            "name": "Eritrea",
            "code": "ER",
            "nameFr": "Érythrée"
        },
        {
            "name": "Estonia",
            "code": "EE",
            "nameFr": "Estonie"
        },
        {
            "name": "Ethiopia",
            "code": "ET",
            "nameFr": "Éthiopie"
        },
        {
            "name": "Falkland Islands (Malvinas)",
            "code": "FK",
            "nameFr": "Îles Malouines"
        },
        {
            "name": "Faroe Islands",
            "code": "FO",
            "nameFr": "Îles Féroé"
        },
        {
            "name": "Fiji",
            "code": "FJ",
            "nameFr": "Fidji"
        },
        {
            "name": "Finland",
            "code": "FI",
            "nameFr": "Finlande"
        },
        {
            "name": "France",
            "code": "FR",
            "nameFr": "France"
        },
        {
            "name": "French Guiana",
            "code": "GF",
            "nameFr": "Guyane"
        },
        {
            "name": "French Polynesia",
            "code": "PF",
            "nameFr": "Polynésie Française"
        },
        {
            "name": "French Southern Territories",
            "code": "TF",
            "nameFr": "Terres Australes Françaises"
        },
        {
            "name": "Gabon",
            "code": "GA",
            "nameFr": "Gabon"
        },
        {
            "name": "Gambia",
            "code": "GM",
            "nameFr": "Gambie"
        },
        {
            "name": "Georgia",
            "code": "GE",
            "nameFr": "Géorgie"
        },
        {
            "name": "Germany",
            "code": "DE",
            "nameFr": "Allemagne"
        },
        {
            "name": "Ghana",
            "code": "GH",
            "nameFr": "Ghana"
        },
        {
            "name": "Gibraltar",
            "code": "GI",
            "nameFr": "Gibraltar"
        },
        {
            "name": "Greece",
            "code": "GR",
            "nameFr": "Grèce"
        },
        {
            "name": "Greenland",
            "code": "GL",
            "nameFr": "Groenland"
        },
        {
            "name": "Grenada",
            "code": "GD",
            "nameFr": "Grenade"
        },
        {
            "name": "Guadeloupe",
            "code": "GP",
            "nameFr": "Guadeloupe"
        },
        {
            "name": "Guam",
            "code": "GU",
            "nameFr": "Guam"
        },
        {
            "name": "Guatemala",
            "code": "GT",
            "nameFr": "Guatemala"
        },
        {
            "name": "Guernsey",
            "code": "GG",
            "nameFr": "Guernesey"
        },
        {
            "name": "Guinea",
            "code": "GN",
            "nameFr": "Guinée"
        },
        {
            "name": "Guinea-Bissau",
            "code": "GW",
            "nameFr": "Guinée-Bissau"
        },
        {
            "name": "Guyana",
            "code": "GY",
            "nameFr": "Guyana"
        },
        {
            "name": "Haiti",
            "code": "HT",
            "nameFr": "Haïti"
        },
        {
            "name": "Heard Island and Mcdonald Islands",
            "code": "HM",
            "nameFr": "Îles Heard-Et-MacDonald"
        },
        {
            "name": "Holy See (Vatican City State)",
            "code": "VA",
            "nameFr": "Saint-Siège (État De La Cité Du Vatican)"
        },
        {
            "name": "Honduras",
            "code": "HN",
            "nameFr": "Honduras"
        },
        {
            "name": "Hong Kong",
            "code": "HK",
            "nameFr": "Hong Kong"
        },
        {
            "name": "Hungary",
            "code": "HU",
            "nameFr": "Hongrie"
        },
        {
            "name": "Iceland",
            "code": "IS",
            "nameFr": "Islande"
        },
        {
            "name": "India",
            "code": "IN",
            "nameFr": "Inde"
        },
        {
            "name": "Indonesia",
            "code": "ID",
            "nameFr": "Indonésie"
        },
        {
            "name": "Iran, Islamic Republic Of",
            "code": "IR",
            "nameFr": "République Islamique D'Iran"
        },
        {
            "name": "Iraq",
            "code": "IQ",
            "nameFr": "Irak"
        },
        {
            "name": "Ireland",
            "code": "IE",
            "nameFr": "Irlande"
        },
        {
            "name": "Isle of Man",
            "code": "IM",
            "nameFr": "Île De Man"
        },
        {
            "name": "Israel",
            "code": "IL",
            "nameFr": "Israël"
        },
        {
            "name": "Italy",
            "code": "IT",
            "nameFr": "Italie"
        },
        {
            "name": "Jamaica",
            "code": "JM",
            "nameFr": "Jamaïque"
        },
        {
            "name": "Japan",
            "code": "JP",
            "nameFr": "Japon"
        },
        {
            "name": "Jersey",
            "code": "JE",
            "nameFr": "Jersey"
        },
        {
            "name": "Jordan",
            "code": "JO",
            "nameFr": "Jordanie"
        },
        {
            "name": "Kazakhstan",
            "code": "KZ",
            "nameFr": "Kazakhstan"
        },
        {
            "name": "Kenya",
            "code": "KE",
            "nameFr": "Kenya"
        },
        {
            "name": "Kiribati",
            "code": "KI",
            "nameFr": "Kiribati"
        },
        {
            "name": "Korea, Democratic People'S Republic of",
            "code": "KP",
            "nameFr": "République Populaire Démocratique De Corée"
        },
        {
            "name": "Korea, Republic of",
            "code": "KR",
            "nameFr": "République De Corée"
        },
        {
            "name": "Kuwait",
            "code": "KW",
            "nameFr": "Koweït"
        },
        {
            "name": "Kyrgyzstan",
            "code": "KG",
            "nameFr": "Kirghizistan"
        },
        {
            "name": "Lao People'S Democratic Republic",
            "code": "LA",
            "nameFr": "République Démocratique Populaire Lao"
        },
        {
            "name": "Latvia",
            "code": "LV",
            "nameFr": "Lettonie"
        },
        {
            "name": "Lebanon",
            "code": "LB",
            "nameFr": "Liban"
        },
        {
            "name": "Lesotho",
            "code": "LS",
            "nameFr": "Lesotho"
        },
        {
            "name": "Liberia",
            "code": "LR",
            "nameFr": "Liberia"
        },
        {
            "name": "Libyan Arab Jamahiriya",
            "code": "LY",
            "nameFr": "Libye"
        },
        {
            "name": "Liechtenstein",
            "code": "LI",
            "nameFr": "Liechtenstein"
        },
        {
            "name": "Lithuania",
            "code": "LT",
            "nameFr": "Lituanie"
        },
        {
            "name": "Luxembourg",
            "code": "LU",
            "nameFr": "Luxembourg"
        },
        {
            "name": "Macao",
            "code": "MO",
            "nameFr": "Macao"
        },
        {
            "name": "Macedonia, The Former Yugoslav Republic of",
            "code": "MK",
            "nameFr": "Macédoine"
        },
        {
            "name": "Madagascar",
            "code": "MG",
            "nameFr": "Madagascar"
        },
        {
            "name": "Malawi",
            "code": "MW",
            "nameFr": "Malawi"
        },
        {
            "name": "Malaysia",
            "code": "MY",
            "nameFr": "Malaisie"
        },
        {
            "name": "Maldives",
            "code": "MV",
            "nameFr": "Maldives"
        },
        {
            "name": "Mali",
            "code": "ML",
            "nameFr": "Mali"
        },
        {
            "name": "Malta",
            "code": "MT",
            "nameFr": "Malte"
        },
        {
            "name": "Marshall Islands",
            "code": "MH",
            "nameFr": "Îles Marshall"
        },
        {
            "name": "Martinique",
            "code": "MQ",
            "nameFr": "Martinique"
        },
        {
            "name": "Mauritania",
            "code": "MR",
            "nameFr": "Mauritanie"
        },
        {
            "name": "Mauritius",
            "code": "MU",
            "nameFr": "Maurice"
        },
        {
            "name": "Mayotte",
            "code": "YT",
            "nameFr": "Mayotte"
        },
        {
            "name": "Mexico",
            "code": "MX",
            "nameFr": "Mexique"
        },
        {
            "name": "Micronesia, Federated States of",
            "code": "FM",
            "nameFr": "États Fédérés De Micronésie"
        },
        {
            "name": "Moldova, Republic of",
            "code": "MD",
            "nameFr": "République De Moldavie"
        },
        {
            "name": "Monaco",
            "code": "MC",
            "nameFr": "Monaco"
        },
        {
            "name": "Mongolia",
            "code": "MN",
            "nameFr": "Mongolie"
        },
        {
            "name": "Montserrat",
            "code": "MS",
            "nameFr": "Montserrat"
        },
        {
            "name": "Morocco",
            "code": "MA",
            "nameFr": "Maroc"
        },
        {
            "name": "Mozambique",
            "code": "MZ",
            "nameFr": "Mozambique"
        },
        {
            "name": "Myanmar",
            "code": "MM",
            "nameFr": "Birmanie"
        },
        {
            "name": "Namibia",
            "code": "NA",
            "nameFr": "Namibie"
        },
        {
            "name": "Nauru",
            "code": "NR",
            "nameFr": "Nauru"
        },
        {
            "name": "Nepal",
            "code": "NP",
            "nameFr": "Népal"
        },
        {
            "name": "Netherlands",
            "code": "NL",
            "nameFr": "Pays-Bas"
        },
        {
            "name": "Netherlands Antilles",
            "code": "AN",
            "nameFr": "Antilles néerlandaises"
        },
        {
            "name": "New Caledonia",
            "code": "NC",
            "nameFr": "Nouvelle-Calédonie"
        },
        {
            "name": "New Zealand",
            "code": "NZ",
            "nameFr": "Nouvelle-Zélande"
        },
        {
            "name": "Nicaragua",
            "code": "NI",
            "nameFr": "Nicaragua"
        },
        {
            "name": "Niger",
            "code": "NE",
            "nameFr": "Niger"
        },
        {
            "name": "Nigeria",
            "code": "NG",
            "nameFr": "Nigéria"
        },
        {
            "name": "Niue",
            "code": "NU",
            "nameFr": "Niue"
        },
        {
            "name": "Norfolk Island",
            "code": "NF",
            "nameFr": "Île Norfolk"
        },
        {
            "name": "Northern Mariana Islands",
            "code": "MP",
            "nameFr": "Îles Mariannes Du Nord"
        },
        {
            "name": "Norway",
            "code": "NO",
            "nameFr": "Norvège"
        },
        {
            "name": "Oman",
            "code": "OM",
            "nameFr": "Oman"
        },
        {
            "name": "Pakistan",
            "code": "PK",
            "nameFr": "Pakistan"
        },
        {
            "name": "Palau",
            "code": "PW",
            "nameFr": "Palaos"
        },
        {
            "name": "Palestinian Territory, Occupied",
            "code": "PS",
            "nameFr": "Territoires Palestiniens Occupés"
        },
        {
            "name": "Panama",
            "code": "PA",
            "nameFr": "Panama"
        },
        {
            "name": "Papua New Guinea",
            "code": "PG",
            "nameFr": "Papouasie-Nouvelle-Guinée"
        },
        {
            "name": "Paraguay",
            "code": "PY",
            "nameFr": "Paraguay"
        },
        {
            "name": "Peru",
            "code": "PE",
            "nameFr": "Pérou"
        },
        {
            "name": "Philippines",
            "code": "PH",
            "nameFr": "Philippines"
        },
        {
            "name": "Pitcairn",
            "code": "PN",
            "nameFr": "Pitcairn"
        },
        {
            "name": "Poland",
            "code": "PL",
            "nameFr": "Pologne"
        },
        {
            "name": "Portugal",
            "code": "PT",
            "nameFr": "Portugal"
        },
        {
            "name": "Puerto Rico",
            "code": "PR",
            "nameFr": "Porto Rico"
        },
        {
            "name": "Qatar",
            "code": "QA",
            "nameFr": "Qatar"
        },
        {
            "name": "Reunion",
            "code": "RE",
            "nameFr": "Réunion"
        },
        {
            "name": "Romania",
            "code": "RO",
            "nameFr": "Roumanie"
        },
        {
            "name": "Russian Federation",
            "code": "RU",
            "nameFr": "Fédération De Russie"
        },
        {
            "name": "RWANDA",
            "code": "RW",
            "nameFr": "Rwanda"
        },
        {
            "name": "Saint Helena",
            "code": "SH",
            "nameFr": "Sainte-Hélène"
        },
        {
            "name": "Saint Kitts and Nevis",
            "code": "KN",
            "nameFr": "Saint-Christophe-et-Niévès"
        },
        {
            "name": "Saint Lucia",
            "code": "LC",
            "nameFr": "Sainte-Lucie"
        },
        {
            "name": "Saint Pierre and Miquelon",
            "code": "PM",
            "nameFr": "Saint-Pierre-Et-Miquelon"
        },
        {
            "name": "Saint Vincent and the Grenadines",
            "code": "VC",
            "nameFr": "Saint-Vincent-Et-Les Grenadines"
        },
        {
            "name": "Samoa",
            "code": "WS",
            "nameFr": "Samoa"
        },
        {
            "name": "San Marino",
            "code": "SM",
            "nameFr": "Saint-Marin"
        },
        {
            "name": "Sao Tome and Principe",
            "code": "ST",
            "nameFr": "Sao Tomé-Et-Principe"
        },
        {
            "name": "Saudi Arabia",
            "code": "SA",
            "nameFr": "Arabie Saoudite"
        },
        {
            "name": "Senegal",
            "code": "SN",
            "nameFr": "Sénégal"
        },
        {
            "name": "Serbia and Montenegro",
            "code": "CS",
            "nameFr": "Serbie-et-Monténégro"
        },
        {
            "name": "Seychelles",
            "code": "SC",
            "nameFr": "Seychelles"
        },
        {
            "name": "Sierra Leone",
            "code": "SL",
            "nameFr": "Sierra Leone"
        },
        {
            "name": "Singapore",
            "code": "SG",
            "nameFr": "Singapour"
        },
        {
            "name": "Slovakia",
            "code": "SK",
            "nameFr": "Slovaquie"
        },
        {
            "name": "Slovenia",
            "code": "SI",
            "nameFr": "Slovénie"
        },
        {
            "name": "Solomon Islands",
            "code": "SB",
            "nameFr": "Îles Salomon"
        },
        {
            "name": "Somalia",
            "code": "SO",
            "nameFr": "Somalie"
        },
        {
            "name": "South Africa",
            "code": "ZA",
            "nameFr": "Afrique Du Sud"
        },
        {
            "name": "South Georgia and the South Sandwich Islands",
            "code": "GS",
            "nameFr": "Géorgie Du Sud-Et-Les Îles Sandwich Du Sud"
        },
        {
            "name": "Spain",
            "code": "ES",
            "nameFr": "Espagne"
        },
        {
            "name": "Sri Lanka",
            "code": "LK",
            "nameFr": "Sri Lanka"
        },
        {
            "name": "Sudan",
            "code": "SD",
            "nameFr": "Soudan"
        },
        {
            "name": "Suriname",
            "code": "SR",
            "nameFr": "Suriname"
        },
        {
            "name": "Svalbard and Jan Mayen",
            "code": "SJ",
            "nameFr": "Svalbard Et Jan Mayen"
        },
        {
            "name": "Swaziland",
            "code": "SZ",
            "nameFr": "Swaziland"
        },
        {
            "name": "Sweden",
            "code": "SE",
            "nameFr": "Suède"
        },
        {
            "name": "Switzerland",
            "code": "CH",
            "nameFr": "Suisse"
        },
        {
            "name": "Syrian Arab Republic",
            "code": "SY",
            "nameFr": "République Arabe Syrienne"
        },
        {
            "name": "Taiwan, Province of China",
            "code": "TW",
            "nameFr": "Taïwan"
        },
        {
            "name": "Tajikistan",
            "code": "TJ",
            "nameFr": "Tadjikistan"
        },
        {
            "name": "Tanzania, United Republic of",
            "code": "TZ",
            "nameFr": "République-Unie De Tanzanie"
        },
        {
            "name": "Thailand",
            "code": "TH",
            "nameFr": "Thaïlande"
        },
        {
            "name": "Timor-Leste",
            "code": "TL",
            "nameFr": "Timor-Leste"
        },
        {
            "name": "Togo",
            "code": "TG",
            "nameFr": "Togo"
        },
        {
            "name": "Tokelau",
            "code": "TK",
            "nameFr": "Tokelau"
        },
        {
            "name": "Tonga",
            "code": "TO",
            "nameFr": "Tonga"
        },
        {
            "name": "Trinidad and Tobago",
            "code": "TT",
            "nameFr": "Trinité-Et-Tobago"
        },
        {
            "name": "Tunisia",
            "code": "TN",
            "nameFr": "Tunisie"
        },
        {
            "name": "Turkey",
            "code": "TR",
            "nameFr": "Turquie"
        },
        {
            "name": "Turkmenistan",
            "code": "TM",
            "nameFr": "Turkménistan"
        },
        {
            "name": "Turks and Caicos Islands",
            "code": "TC",
            "nameFr": "Îles Turks-Et-Caïcos"
        },
        {
            "name": "Tuvalu",
            "code": "TV",
            "nameFr": "Tuvalu"
        },
        {
            "name": "Uganda",
            "code": "UG",
            "nameFr": "Ouganda"
        },
        {
            "name": "Ukraine",
            "code": "UA",
            "nameFr": "Ukraine"
        },
        {
            "name": "United Arab Emirates",
            "code": "AE",
            "nameFr": "Émirats Arabes Unis"
        },
        {
            "name": "United Kingdom",
            "code": "GB",
            "nameFr": "Royaume-Uni"
        },
        {
            "name": "United States",
            "code": "US",
            "nameFr": "États-Unis"
        },
        {
            "name": "United States Minor Outlying Islands",
            "code": "UM",
            "nameFr": "Îles Mineures Éloignées Des États-Unis"
        },
        {
            "name": "Uruguay",
            "code": "UY",
            "nameFr": "Uruguay"
        },
        {
            "name": "Uzbekistan",
            "code": "UZ",
            "nameFr": "Ouzbékistan"
        },
        {
            "name": "Vanuatu",
            "code": "VU",
            "nameFr": "Vanuatu"
        },
        {
            "name": "Venezuela",
            "code": "VE",
            "nameFr": "Venezuela"
        },
        {
            "name": "Viet Nam",
            "code": "VN",
            "nameFr": "Viet Nam"
        },
        {
            "name": "Virgin Islands, British",
            "code": "VG",
            "nameFr": "Îles Vierges Britanniques"
        },
        {
            "name": "Virgin Islands, U.S.",
            "code": "VI",
            "nameFr": "Îles Vierges Des États-Unis"
        },
        {
            "name": "Wallis and Futuna",
            "code": "WF",
            "nameFr": "Wallis Et Futuna"
        },
        {
            "name": "Western Sahara",
            "code": "EH",
            "nameFr": "Sahara Occidental"
        },
        {
            "name": "Yemen",
            "code": "YE",
            "nameFr": "Yémen"
        },
        {
            "name": "Zambia",
            "code": "ZM",
            "nameFr": "Zambie"
        },
        {
            "name": "Zimbabwe",
            "code": "ZW",
            "nameFr": "Zimbabwe"
        }
    ]