import React, { useState, useEffect } from "react";
import { Link } from "react-router-dom";
import {
  makeStyles,
  Grid,
  Typography,
  Box,
  useMediaQuery,
  FormControl,
  InputBase,
} from "@material-ui/core";

// Relative Dependencies
import { Modal, Back } from "../../../ui";
import { shuffle } from "../../../utils"; // TODO

const useStyles = makeStyles(theme => ({
  hidden: { display: "none" },
  searchWrapper: {
    position: "absolute",
    width: 250,
    top: 90,
    right: 10,
    zIndex: 10000,
    backgroundColor: "white",
  },
  searchFieldWrapper: {
    width: "100%",
    position: "relative",
    "&:after": {
      content: "''",
      position: "absolute",
      left: 0,
      right: 0,
      bottom: 0,
      borderBottom: "1px solid white",
    },
  },
  searchField: {
    ...theme.typography.h3,
    margin: "5px 0px 5px",
  },
  searchInput: {
    color: "white",
    "&::placeholder": {
      textAlign: "center",
    },
  },
  hoverPointer: {
    "&:hover": {
      cursor: "pointer",
    },
  },
  flexTransition: {
    transition: theme.transitions.create(["flex-grow", "height"], {
      easing: theme.transitions.easing.easeInOut,
      duration: theme.transitions.duration.long,
    }),
  },
  link: {
    width: "100%",
    textDecoration: "none",
  },
}));

const SearchBox = ({ open, toggle, data }) => {
  const [searchVal, setSearchVal] = useState("");
  const [filteredData, setFilteredData] = useState([]);

  useEffect(() => {
    shuffle(data.influencers);
  }, [data.influencers]);

  useEffect(() => {
    setFilteredData(
      searchVal.length > 0
        ? data.influencers.filter(inf => {
          return (
            inf.name &&
            inf.name.toLowerCase().includes(searchVal.toLowerCase())
          );
        })
        : []
    );
  }, [searchVal, data.influencers]);

  const smUp = useMediaQuery(theme => theme.breakpoints.up("sm"));
  const classes = useStyles();

  const handleSearchInfluencers = e => {
    setSearchVal(e.target.value);
  };

  const searching = searchVal.length > 0;
  const noResults = searchVal.length > 0 && filteredData.length < 1;

  return (
    <Modal>
      <Back onClick={toggle} color='white' size='large' />
      <Grid
        style={{ height: "100%" }}
        container
        direction='column'
        justify='flex-start'
        wrap='nowrap'
      >
        <Box
          display='flex'
          className={classes.flexTransition}
          flexGrow={searching ? 0.001 : 1}
          justifyContent='center'
          alignItems='center'
          alignContent='center'
        >
          <Grid item xs={9} sm={7} md={5} style={{ padding: "15px 0" }}>
            <FormControl style={{ width: "100%" }}>
              <Grid item container>
                <div className={classes.searchFieldWrapper}>
                  <InputBase
                    className={classes.searchField}
                    type='text'
                    fullWidth
                    autoFocus
                    value={searchVal.toUpperCase()}
                    placeholder='Rechercher'
                    onChange={handleSearchInfluencers}
                    inputProps={{ className: classes.searchInput }}
                  />
                </div>
              </Grid>
            </FormControl>
          </Grid>
        </Box>
        <Box
          className={classes.flexTransition}
          display='flex'
          flexGrow={searching ? 1 : 0.001}
          justifyContent='center'
          style={{
            overflow: searching ? "auto" : "hidden",
            height: searching ? "100%" : 0,
          }}
        >
          <Grid
            item
            xs={11}
            sm={10}
            md={9}
            lg={8}
            xl={7}
            container
            justify='center'
            alignItems={noResults ? "center" : "flex-start"}
            alignContent={noResults ? "center" : "flex-start"}
            style={{
              height: "100%",
              padding: "10px 0",
            }}
          >
            {noResults ? (
              <div style={{ color: "white", margin: "0 0 50px 0" }}>
                <Typography variant='h4' color='inherit'>
                  0 résultats correspondants
                </Typography>
              </div>
            ) : (
              filteredData.map(inf => {
                return (
                  <Link
                    to={`/wishlists/${inf.name.replace(/ /g, "_")}`}
                    className={classes.link}
                    style={{ margin: smUp ? "10px 0" : "2.5px 0" }}
                    key={inf.key}
                  >
                    <Grid container className={classes.hoverPointer}>
                      <Grid item style={{ padding: "5px" }} xs={12} sm={4}>
                        <div
                          style={{
                            position: "relative",
                            width: "100%",
                            paddingTop: "52.25%",
                            background: `url(${inf.banner}) center/cover no-repeat`,
                            borderRadius: "13px",
                            overflow: "hidden",
                          }}
                        >
                          {!smUp && (
                            <div
                              style={{
                                position: "absolute",
                                top: 0,
                                right: 0,
                                bottom: 0,
                                left: 0,
                                backgroundColor: "rgba(0,0,0,0.5)",
                                color: "white",
                              }}
                            >
                              <Grid
                                item
                                container
                                alignItems='center'
                                justify='center'
                                style={{ height: "100%" }}
                              >
                                <Typography
                                  align='center'
                                  variant='h3'
                                  color='inherit'
                                >
                                  {inf.name.toUpperCase()}
                                </Typography>
                              </Grid>
                            </div>
                          )}
                        </div>
                      </Grid>
                      <Grid
                        item
                        container
                        style={{ padding: "5px" }}
                        xs={12}
                        sm={8}
                      >
                        {smUp && (
                          <Grid
                            item
                            container
                            direction='column'
                            wrap='nowrap'
                            style={{
                              padding: "15px",
                              borderRadius: "13px",
                              backgroundColor: "rgba(255, 255, 255, .75)",
                            }}
                          >
                            <Grid
                              item
                              xs
                              zeroMinWidth
                              container
                              alignItems='center'
                              justify='center'
                            >
                              <Typography
                                align='center'
                                variant='h3'
                                color='primary'
                                noWrap
                              >
                                {inf.name.toUpperCase()}
                              </Typography>
                            </Grid>
                          </Grid>
                        )}
                      </Grid>
                    </Grid>
                  </Link>
                );
              })
            )}
          </Grid>
        </Box>
      </Grid>
    </Modal>
  );
};

export default SearchBox;
