import { Button, Dialog, DialogActions, DialogContent, DialogTitle, makeStyles, InputBase, Typography } from '@material-ui/core';
import React, { useEffect, useState } from 'react';
import { useTranslation } from "react-i18next";

const useStyle = makeStyles(theme => ({
    dialogTitle: {
        fontFamily: 'Poppins',
        fontWeight: 600
    },
    dialogContentText: {
        fontFamily: 'Poppins'
    },
    wishlistNameFormControl: {
        fontFamily: 'Poppins'
    },
    cancelButton: {
        textTransform: 'none',
        backgroundColor: '#888888',
        fontFamily: 'Poppins',
        color: '#fff',
        borderRadius: '24px',
        paddingLeft: '15px',
        paddingRight: '15px',
        '&:hover': {
            backgroundColor: '#888888',
        }
    },
    addButton: {
        textTransform: 'none',
        backgroundColor: '#00ADEF',
        fontFamily: 'Poppins',
        color: '#fff',
        borderRadius: '24px',
        paddingLeft: '15px',
        paddingRight: '15px',
        '&:hover': {
            backgroundColor: '#00ADEF',
        }
    },
    deleteButton: {
        textTransform: 'none',
        backgroundColor: 'red',
        fontFamily: 'Poppins',
        color: '#fff',
        borderRadius: '24px',
        paddingLeft: '15px',
        paddingRight: '15px',
        '&:hover': {
            backgroundColor: 'red',
        }
    },
    inputField: {
        width: '100%',
        borderRadius: '35px',
        boxShadow: '0 7px 30px 0 rgba(69, 128, 163, 0.16)',
        marginTop: '10px',
        marginBottom: '10px',
        paddingLeft: '20px',
        fontFamily: 'Poppins'
    },
    validationError: {
        fontFamily: 'Poppins',
        color: 'red',
        fontSize: '12px'
    }
}));


const WishlistDialog = (props) => {

    const {
        open,
        type,
        activeWishlist
    } = props;

    const classes = useStyle();
    const { t } = useTranslation("common");
    const [wishlistName, setWishlistName] = useState('');
    const [validationError, setValidationError] = useState();

    useEffect(() => {
        if (activeWishlist && type === 'update') {
            setWishlistName(activeWishlist.name)
        } else {
            setWishlistName('')
        };
    }, [activeWishlist, type])

    const cancelAddWishlist = () => {
        props.cancelWishlistOperation();
    };

    const handleInputChange = id => e => {
        if (id === 'wishlist-name') setWishlistName(e.target.value);
    };

    const addWishlist = () => {
        if (wishlistName !== '') {
            setValidationError();
            setWishlistName('');
            props.addWishlist(wishlistName);
        } else {
            setValidationError('* Le nom de la wishlist ne peut pas être vide')
        }
    };

    const deleteWishlist = () => {
        props.deleteWishlist();
    };

    const renameWishlist = () => {
        if (wishlistName !== '') {
            setValidationError();
            setWishlistName('');
            props.updateWishlist(wishlistName);
        } else {
            setValidationError('* Le nom de la wishlist ne peut pas être vide')
        }
    };


    return (
        <Dialog open={open}>
            <DialogTitle >
                {
                    (type === 'add')
                        ? <Typography className={classes.dialogTitle}>{t('INFLUENCER.ADD_WISHLIST_BOX.ADD_WISHLIST_BOX_TITLE')}</Typography>
                        : (type === 'delete')
                            ? <Typography className={classes.dialogTitle}>{t('INFLUENCER.DELETE_WISHLIST_BOX.DELETE_WISHLIST_BOX_TITLE')}</Typography>
                            : <Typography className={classes.dialogTitle}>{t('INFLUENCER.RENAME_WISHLIST_BOX.RENAME_WISHLIST_BOX_TITLE')}</Typography>
                }
            </DialogTitle>
            <DialogContent>
                {
                    (type === 'add')
                        ? <>
                            <Typography className={classes.dialogContentText}>{t('INFLUENCER.ADD_WISHLIST_BOX.ADD_WISHLIST_DESCRIPTION')}</Typography>
                            <InputBase
                                id='name'
                                placeholder={t('INFLUENCER.ADD_WISHLIST_BOX.ADD_WISHLIST_INPUT_PLACEHOLDER')}
                                type='text'
                                className={classes.inputField}
                                value={wishlistName}
                                onChange={handleInputChange('wishlist-name')} />
                        </>
                        : (type === 'update')
                            ? <>
                                <Typography className={classes.dialogContentText}>{t('INFLUENCER.RENAME_WISHLIST_BOX.RENAME_WISHLIST_BOX_DESCRIPTION')}</Typography>
                                <InputBase
                                    id='name'
                                    placeholder={t('INFLUENCER.ADD_WISHLIST_BOX.ADD_WISHLIST_INPUT_PLACEHOLDER')}
                                    type='text'
                                    className={classes.inputField}
                                    value={wishlistName}
                                    onChange={handleInputChange('wishlist-name')} />
                            </>
                            : <Typography className={classes.dialogContentText}>{t('INFLUENCER.DELETE_WISHLIST_BOX.DELETE_WISHLIST_DESCRIPTION')}</Typography>
                }

                {
                    (validationError)
                        ? <Typography className={classes.validationError}>{t('INFLUENCER.ADD_WISHLIST_BOX.ADD_WISHLIST_ERROR_MESSAGE')}</Typography>
                        : <></>
                }
                <DialogActions>
                    <Button className={classes.cancelButton} onClick={cancelAddWishlist}>{t('INFLUENCER.ADD_WISHLIST_BOX.ADD_WISHLIST_CANCEL_BUTTON')}</Button>
                    {
                        (type === 'add')
                            ? <Button className={classes.addButton} onClick={addWishlist}>{t('INFLUENCER.ADD_WISHLIST_BOX.ADD_WISHLIST_SAVE_BUTTON')}</Button>
                            : (type === 'delete')
                                ? <Button className={classes.deleteButton} onClick={deleteWishlist}>{t('INFLUENCER.DELETE_WISHLIST_BOX.DELETE_WISHLIST_DELETE_BUTTON')}</Button>
                                : <Button className={classes.addButton} onClick={renameWishlist}>{t('INFLUENCER.RENAME_WISHLIST_BOX.RENAME_WISHLIST_BOX_RENAME_BUTTON')}</Button>
                    }
                </DialogActions>
            </DialogContent>
        </Dialog>
    );
};


export default WishlistDialog;