import React, { useState, useEffect } from "react";
import PropTypes from "prop-types";

import Field from "./Field";

/* Initialisation du State pour les champs cointrolés */
const Form = ({
  name,
  fields,
  onSubmit,
  credErrors,
  style,
  children,
  direction,
  container = false,
}) => {
  const [errors, setErrors] = useState({});
  const [values, setValues] = useState({});

  useEffect(() => {
    return setErrors(err => ({ ...err, ...credErrors }));
  }, [credErrors]);

  function isValid() {
    let valid = true;
    let newErrors;
    for (const field of fields) {
      const fieldVal = values[field.id];
      if (field.required && (!fieldVal || !fieldVal.length)) {
        newErrors = { ...newErrors, [field.id]: "Ce champs est obligatoire" };
        valid = false;
      }
    }
    setErrors({ ...newErrors });
    return valid;
  }

  const handleValueChange = name => event => {
    setValues({ ...values, [name]: event.target.value });
  };
  const handleSubmit = event => {
    event.preventDefault();
    isValid() && onSubmit({ event, ...values });
  };

  return (
    <form
      id={`${name}Form`}
      onSubmit={handleSubmit}
      style={{
        ...style,
        display: container ? "flex" : "block",
        flexDirection: direction === "column" ? "column" : null,
      }}
    >
      {fields.map((field, i) => {
        return (
          <Field
            key={i}
            id={field.id}
            type={field.type}
            name={field.name}
            error={errors[field.id]}
            value={values[field.id]}
            handleValueChange={handleValueChange}
          />
        );
      })}
      {children}
    </form>
  );
};

Form.propTypes = {
  name: PropTypes.string.isRequired,
  fields: PropTypes.arrayOf(PropTypes.object).isRequired,
  onSubmit: PropTypes.func.isRequired,
  variant: PropTypes.string,
  credErrors: PropTypes.objectOf(PropTypes.string),
  style: PropTypes.object,
  container: PropTypes.bool,
  direction: PropTypes.string,
  children: PropTypes.element,
};

export default Form;
