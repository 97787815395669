import React from "react";
import { makeStyles } from "@material-ui/styles";
import { Grid,Typography } from "@material-ui/core";

/* UI Scope */
import { Button } from "../../../../ui";
import { useTranslation } from "react-i18next";

const useStyles = makeStyles(theme => ({
  welcomeContent: {
    position: "absolute",
    zIndex: "11",
    top: 0,
    bottom: 0,
    left: 0,
    right: 0,
    background: theme.background.transparent,
    overflow: "auto",
  },
  welcomeWrapper: {
    padding: "15px 0",
  },
  title: {
    fontWeight: theme.typography.fontWeightLight,
    textAlign: "center",
  },
  text: {
    fontWeight: theme.typography.fontWeightLight,
    textAlign: "center",
  },
  buttonContainer: {
    marginTop: "5%",
  },
  button: {
    boxShadow: "none",
  },
  faIcon: {
    marginRight: "10px",
  },
}));

function Welcome({ hide }) {
  const styles = useStyles();
  const { t } = useTranslation('common');
  return (
    <Grid className={ styles.welcomeContent } container justify='center'>
      <Grid
        className={ styles.welcomeWrapper }
        xs={ 10 }
        sm={ 8 }
        md={ 6 }
        lg={ 5 }
        item
        container
        justify='center'
        alignContent='center'
      >
        <Typography className={ styles.title } variant='h2' paragraph>
          { t('PAGE_HOME_WEBSITE.TITLE') }
        </Typography>
        <Typography className={ styles.text } variant='h3' paragraph>
          <strong style={ { fontWeight: "inherit" } }>
            { t('PAGE_HOME_WEBSITE.DESC') }
          </strong>
        </Typography>
        <Grid container item className={ styles.buttonContainer }>
          <Grid item container justify='center' style={ { padding: "10px" } }>
            <Button color='secondary' onClick={ hide }>
              <i className={ `${styles.faIcon} fas fa-eye fa-lg` }></i>
              { t('PAGE_HOME_WEBSITE.BUTTON_WISHLIST').toUpperCase() }
            </Button>
          </Grid>
        </Grid>
      </Grid>
    </Grid>
  );
}

export default Welcome;
