import React, { useState, useEffect } from "react";
import {
  makeStyles,
  Grid,
  Box,
  Typography,
  IconButton,
} from "@material-ui/core";
import EuroSymbolIcon from "@material-ui/icons/EuroSymbol";
import SVGIcon from "../../../assets/SVGIcons/index";
import CloseIcon from "@material-ui/icons/Close";
import TouchAppIcon from "@material-ui/icons/TouchApp";
import countriesDB from "../../../assets/utils/countries";
import ExpandLessIcon from "@material-ui/icons/ExpandLess";
import ExpandMoreIcon from "@material-ui/icons/ExpandMore";
import SortIcon from "@material-ui/icons/Sort";
import TrendLoading from "../../../../../ui/trendLoading/TrendLoading";
import placeholder from "../../../assets/image/placeholder.png";
import { useTranslation } from "react-i18next";

const useStyles = makeStyles((theme) => {
  return {
    overallBoxContainer: {
      width: "95%",
      display: "flex",
      justifyContent: "center",
      marginBottom: "30px",
      marginTop: "30px",
    },
    overallBoxStyles: {
      backgroundColor: "white",
      borderRadius: "35px",
      boxShadow: "0 7px 30px 0 rgba(88, 165, 211, 0.16)",
      width: "100%",
    },
    influencerInfosBox: {
      height: "200px",
    },
    influencerImage: {
      objectFit: "cover",
      width: "90%",
      height: "200px",
      margin: "10px",
      borderRadius: "35px",
      boxShadow: "0 7px 30px 0 rgba(88, 165, 211, 0.16)",
    },
    smallBox: {
      boxShadow: "0 7px 30px 0 rgba(58, 111, 142, 0.05)",
      borderRadius: "41px",
      width: "300px",
      height: "150px",
      display: "flex",
      justifyContent: "center",
      alignItems: "center",
      margin: 10,
    },
    tableRowContent: {
      fontFamily: "Poppins",
    },
    influencerInstagram: {
      fontFamily: "Poppins",
      fontSize: "25px",
      color: "#000000",
      textDecoration: "none",
    },
    influencerStatsTitles: {
      fontFamily: "Poppins",
      fontSize: "14px",
      fontWeight: 500,
      color: "#000000",
    },
    influencerStats: {
      fontFamily: "Poppins",
      fontSize: "16px",
      fontWeight: 900,
      color: "#000000",
    },
    spacement: {
      marginTop: "15px",
      marginBottom: "15px",
    },
    tableHeader: {
      fontFamily: "Poppins",
      fontSize: "14px",
      textAlign: "center",
      "&:hover": {
        cursor: "pointer",
      },
    },
    influencerBoxContainer: {
      marginTop: "10px",
      marginBottom: "10px",
      //minWidth: "1050px",
      minHeight: "60px",
    },
    influencerBox: {
      display: "flex",
      width: "100%",
      backgroundColor: "white",
      height: "100px",
      justify: "center",
      borderRadius: "27px",
      boxShadow: "1px 2px 4px 2px rgb(88 165 211 / 16%)",
    },
    influencerName: {
      fontFamily: "Poppins",
      fontSize: "22px",
      fontWeight: "bold",
      color: "#000000",
      wordBreak: "break-word",
    },
    tableHeaderIcon: {
      "&:hover": {
        cursor: "pointer",
      },
    },
    productImage: {
      height: "100px",
      objectFit: "contain",
      "&:hover": {
        cursor: "pointer",
      },
    },
  };
});

const BrandModalInfluencer = (props) => {
  const { t, i18n } = useTranslation("common");
  const classes = useStyles();
  const [sortHelper, setSortHelper] = useState([1, 0, 0, 0]);
  const [sortEvent, setSortEvent] = useState(0);
  const [influencer, setInfluencer] = useState({});
  const [brand, setBrand] = useState({});
  const [periodBrandConversions, setPeriodBrandConversions] = useState([]);
  const [detailProduct, setDetailProduct] = useState([]);
  const [detailProductLoading, setDetailProductLoading] = useState(true);
  const [productToRender, setProductToRender] = useState([]);
  useEffect(() => {
    setInfluencer(props.dataInfluencer);
    setBrand(props.data);
    setPeriodBrandConversions(props.periodBrandConversions);
  }, [props.dataInfluencer, props.data, props.periodBrandConversions]);

  useEffect(() => {
    let detailProduct = [];
    periodBrandConversions.forEach((conversion) => {
      if (
        conversion.offerId === brand.offerId &&
        conversion.influencer === influencer.key
      ) {
        detailProduct.push({
          createdAt: conversion.createdAt,
          amount: conversion.amount,
          articleImgUrl: conversion.articleImgUrl,
          articlePathUrl: conversion.articlePathUrl,
          commissions: Number(conversion.commission),
          countryCode: conversion.countryCode,
        });
      }
    });
    setDetailProduct(detailProduct);
    setDetailProductLoading(false);
  }, [periodBrandConversions, brand.offerId, influencer.key]);

  useEffect(() => {
    let indexAsc = sortHelper.indexOf(1);
    let indexDesc = sortHelper.indexOf(-1);
    if (indexAsc !== -1) {
      const sortTypeAsc = (indexAsc, tableToWorkOn) => {
        switch (indexAsc) {
          case 0:
            tableToWorkOn.sort((a, b) => {
              if (a.createdAt > b.createdAt) return -1;
              if (a.createdAt < b.createdAt) return 1;
              return 0;
            });
            break;
          case 1:
            tableToWorkOn.sort((a, b) => {
              if (a.amount > b.amount) return -1;
              if (a.amount < b.amount) return 1;
              return 0;
            });
            break;
          case 2:
            tableToWorkOn.sort((a, b) => {
              if (a.commissions > b.commissions) return -1;
              if (a.commissions < b.commissions) return 1;
              return 0;
            });
            break;
          case 3:
            tableToWorkOn.sort((a, b) => {
              if (a.countryCode > b.countryCode) return -1;
              if (a.countryCode < b.countryCode) return 1;
              return 0;
            });
            break;
          default:
            break;
        }
      };
      sortTypeAsc(indexAsc, detailProduct);
    } else {
      const sortTypeDesc = (indexDesc, tableToWorkOn) => {
        switch (indexDesc) {
          case 0:
            tableToWorkOn.sort((a, b) => {
              if (a.createdAt > b.createdAt) return 1;
              if (a.createdAt < b.createdAt) return -1;
              return 0;
            });
            break;
          case 1:
            tableToWorkOn.sort((a, b) => {
              if (a.amount > b.amount) return 1;
              if (a.amount < b.amount) return -1;
              return 0;
            });
            break;
          case 2:
            tableToWorkOn.sort((a, b) => {
              if (a.commissions > b.commissions) return 1;
              if (a.commissions < b.commissions) return -1;
              return 0;
            });
            break;
          case 3:
            tableToWorkOn.sort((a, b) => {
              if (a.countryCode > b.countryCode) return 1;
              if (a.countryCode < b.countryCode) return -1;
              return 0;
            });
            break;
          default:
            break;
        }
      };
      sortTypeDesc(indexDesc, detailProduct);
    }
    setSortEvent((s) => s + 1);
  }, [detailProduct, sortHelper]);

  useEffect(() => {
    if (detailProductLoading === false) {
      setProductToRender(detailProduct);
    }
  }, [detailProduct, detailProductLoading, sortEvent]);
  const closePopup = () => {
    props.close(true);
  };

  return (
    <Grid container item direction="row" justify="center">
      <Grid container item direction="row" className={classes.overallBoxContainer}>
        <Box className={classes.overallBoxStyles}>
          <Grid container direction="column" alignItems="center" justify="center" style={{ display: "flex" }} >
            <Grid container item direction="row-reverse" sm={12}>
              <IconButton onClick={closePopup} style={{ marginTop: "30px", marginRight: "30px", color: "#000", }}>
                <CloseIcon fontSize="large" />
              </IconButton>
            </Grid>
            <Grid container item direction="row" justify="center" className={classes.spacement} >
              <Grid container item direction="row" sm={11} justify="center">
                <Grid container item direction="row" justify="center" alignItems="center" wrap="wrap" >
                  <Grid container item lg={4} md={8} sm={12} justify="center">
                    <img src={influencer.banner} alt={influencer.name} className={classes.influencerImage} />
                  </Grid>
                  <Grid container item lg={2} md={4} sm={12} justify="center" alignItems="center" direction="column" className={classes.influencerInfosBox}>
                    <Typography className={classes.influencerName}>
                      {influencer.name}
                    </Typography>
                    {influencer.socialMedias !== undefined &&
                      influencer.socialMedias.instagram !== undefined ? (
                        <Typography>
                          <a className={classes.influencerInstagram} href={influencer.socialMedias.instagram} target="_blank" rel="noopener noreferrer" >
                            @instagram
                        </a>
                        </Typography>
                      ) : (
                        <></>
                      )}
                  </Grid>
                  <Grid container item justify="center" lg={2} md={4} sm={12}>
                    <Box className={classes.smallBox}>
                      <Grid container direction="row">
                        <Grid container item xs={6} justify="center">
                          <SVGIcon name="ventes" width={"60px"} height={"60px"} />
                        </Grid>
                        <Grid container item xs={6} direction="column" justify="center" >
                          <Typography className={classes.influencerStatsTitles}>
                            {t("TREND.VIEWS.STATISTICS.INFLUENCER_POPUP.SALES_TITLE_BOX")}
                          </Typography>
                          <Typography className={classes.influencerStats}>
                            {influencer.salesNumber}
                          </Typography>
                        </Grid>
                      </Grid>
                    </Box>
                  </Grid>
                  <Grid container item justify="center" lg={2} md={4} sm={12}>
                    <Box className={classes.smallBox}>
                      <Grid container direction="row">
                        <Grid container item xs={6} justify="center">
                          <EuroSymbolIcon style={{ fontSize: "60px", color: "#ef7e3e" }} />
                        </Grid>
                        <Grid container item xs={6} direction="column" justify="center">
                          <Typography className={classes.influencerStatsTitles}>
                            {t("TREND.VIEWS.STATISTICS.INFLUENCER_POPUP.TURNOVER_TITLE_BOX")}
                          </Typography>
                          <Typography className={classes.influencerStats}>
                            {Math.round(influencer.amount * 100) / 100} €
                          </Typography>
                        </Grid>
                      </Grid>
                    </Box>
                  </Grid>
                  <Grid container item justify="center" lg={2} md={4} sm={12}>
                    <Box className={classes.smallBox}>
                      <Grid container direction="row">
                        <Grid container item xs={6} justify="center">
                          <TouchAppIcon style={{ fontSize: "60px", color: "#3568eb" }}
                          />
                        </Grid>
                        <Grid container item xs={6} direction="column" justify="center">
                          {influencer.clicks === undefined ? (
                            <TrendLoading />
                          ) : (
                              <>
                                <Typography className={classes.influencerStatsTitles}>
                                  {t("TREND.VIEWS.STATISTICS.INFLUENCER_POPUP.CLICKS_TITLE_BOX")}
                                </Typography>
                                <Typography className={classes.influencerStats}>
                                  {influencer.clicks}
                                </Typography>
                              </>
                            )}
                        </Grid>
                      </Grid>
                    </Box>
                  </Grid>
                </Grid>
                <Grid container item direction="row" xs={12} alignItems="center" justify="center">
                  <div style={{ overflowX: "auto", width: "100%", marginTop: "30px", }}>
                    <Grid container item direction="row" xs={12} style={{ minWidth: "1050px" }}>
                      <Grid container item direction="row">
                        <Grid container item alignItems="center" justify="center" style={{ width: "40%" }} onClick={() => {
                          sortHelper[0] === 0
                            ? setSortHelper([1, 0, 0, 0])
                            : setSortHelper([-sortHelper[0], 0, 0, 0]);
                        }}>
                          <Typography className={classes.tableHeader}>
                            {t("INFLUENCER_AGENT.VIEWS.STATISTICS.SECOND_POPUP_INFLUENCER.DATE_PRODUCT")}
                          </Typography>
                          {sortHelper[0] === 1 ? (
                            <ExpandLessIcon className={classes.tableHeaderIcon} />
                          ) : sortHelper[0] === -1 ? (
                            <ExpandMoreIcon className={classes.tableHeaderIcon} />
                          ) : (
                                <SortIcon className={classes.tableHeaderIcon} />
                              )}
                        </Grid>
                        <Grid container justify="center" alignItems="center" style={{ width: "15%" }} onClick={() => {
                          sortHelper[1] === 0
                            ? setSortHelper([0, 1, 0, 0])
                            : setSortHelper([0, -sortHelper[1], 0, 0]);
                        }}>
                          <Typography className={classes.tableHeader}>
                            {t("INFLUENCER_AGENT.VIEWS.STATISTICS.SECOND_POPUP_INFLUENCER.AMOUNT_PRODUCT")}
                          </Typography>
                          {sortHelper[1] === 1 ? (
                            <ExpandLessIcon className={classes.tableHeaderIcon} />
                          ) : sortHelper[1] === -1 ? (
                            <ExpandMoreIcon className={classes.tableHeaderIcon} />
                          ) : (
                                <SortIcon className={classes.tableHeaderIcon} />
                              )}
                        </Grid>
                        <Grid container justify="center" alignItems="center" style={{ width: "15%" }}>
                          <Typography className={classes.tableHeader}>
                            {t("INFLUENCER_AGENT.VIEWS.STATISTICS.SECOND_POPUP_INFLUENCER.IMAGE_PRODUCT")}
                          </Typography>
                        </Grid>
                        <Grid container justify="center" alignItems="center" style={{ width: "15%" }} onClick={() => {
                          sortHelper[2] === 0
                            ? setSortHelper([0, 0, 1, 0])
                            : setSortHelper([0, 0, -sortHelper[2], 0]);
                        }}>
                          <Typography className={classes.tableHeader}>
                            {t("INFLUENCER_AGENT.VIEWS.STATISTICS.SECOND_POPUP_INFLUENCER.COMMISSIONS_PRODUCT")}
                          </Typography>
                          {sortHelper[2] === 1 ? (
                            <ExpandLessIcon className={classes.tableHeaderIcon} />
                          ) : sortHelper[2] === -1 ? (
                            <ExpandMoreIcon className={classes.tableHeaderIcon} />
                          ) : (
                                <SortIcon className={classes.tableHeaderIcon} />
                              )}
                        </Grid>
                        <Grid container justify="center" alignItems="center" style={{ width: "15%" }} onClick={() => {
                          sortHelper[3] === 0 ? setSortHelper([0, 0, 0, 1]) : setSortHelper([0, 0, 0, -sortHelper[3]]);
                        }}>
                          <Typography className={classes.tableHeader}>
                            {t("INFLUENCER_AGENT.VIEWS.STATISTICS.SECOND_POPUP_INFLUENCER.COUNTRY_PRODUCT")}
                          </Typography>
                          {sortHelper[3] === 1 ? (
                            <ExpandLessIcon className={classes.tableHeaderIcon} />
                          ) : sortHelper[3] === -1 ? (
                            <ExpandMoreIcon className={classes.tableHeaderIcon} />
                          ) : (
                                <SortIcon className={classes.tableHeaderIcon} />
                              )}
                        </Grid>
                      </Grid>
                      {productToRender.map((conversion, key) => {
                        return (
                          <Grid container item direction="row" justify="center" className={classes.influencerBoxContainer} key={key}>
                            <Box className={classes.influencerBox}>
                              <Grid container item direction="row" style={{ width: "100%" }}>
                                <Grid container item justify="flex-start" alignItems="center" style={{ width: "40%" }}>
                                  <Typography className={classes.tableRowContent} style={{ paddingLeft: "20px" }}>
                                    {new Date(conversion.createdAt * 1000).toLocaleDateString()}{" "}
                                    {new Date(conversion.createdAt * 1000).toLocaleTimeString({ hour12: false })}
                                  </Typography>
                                </Grid>
                                <Grid container item justify="center" alignItems="center" style={{ width: "15%" }}>
                                  <Typography className={classes.tableRowContent}>
                                    {Math.round(conversion.amount * 100) / 100}{" "}€
                                  </Typography>
                                </Grid>
                                <Grid container item justify="center" alignItems="center" style={{ width: "15%" }}>
                                  {conversion.articleImgUrl === "" || conversion.articleImgUrl === undefined || conversion.articleImgUrl === null
                                    ? (
                                      <img className={classes.productImage} src={placeholder} alt="" />
                                    ) : (
                                      <a target="_blank" href={conversion.articlePathUrl} rel="noopener noreferrer">
                                        <img className={classes.productImage} src={conversion.articleImgUrl} alt={conversion.articlePathUrl} />
                                      </a>
                                    )}
                                </Grid>
                                <Grid container item justify="center" alignItems="center" style={{ width: "15%" }}>
                                  <Typography className={classes.tableRowContent}>
                                    {Math.round(conversion.commissions * 100) / 100}{" "}€
                                  </Typography>
                                </Grid>
                                <Grid container item justify="center" alignItems="center" style={{ width: "15%" }} >
                                  <Typography className={classes.tableRowContent} >
                                    {
                                      (i18n.language === 'fr-FR' || i18n.language === 'fr')
                                      ? countriesDB.filter((element) => conversion.countryCode === element.code)[0].nameFr
                                      : countriesDB.filter((element) => conversion.countryCode === element.code)[0].name
                                    }
                                  </Typography>
                                </Grid>
                              </Grid>
                            </Box>
                          </Grid>
                        );
                      })}
                    </Grid>
                  </div>
                </Grid>
              </Grid>
            </Grid>
          </Grid>
        </Box>
      </Grid>
    </Grid>
  );
};

export default BrandModalInfluencer;
