import { makeStyles, Typography, Grid, InputBase, Select, withStyles, Button, Menu, MenuItem, IconButton, Divider, useTheme, useMediaQuery, CircularProgress, Backdrop, Snackbar, Input, Dialog, DialogTitle, DialogContent, DialogActions } from '@material-ui/core';
import Alert from '@material-ui/lab/Alert';
import MoreVertIcon from "@material-ui/icons/MoreVert";
import FilterListIcon from '@material-ui/icons/FilterList';
import React, { useState, useEffect } from 'react';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import clsx from "clsx";
import { connect } from 'react-redux';
import { addSubCategory, removeSubCategory, updateSubCategory } from '../../../../store/actions/categoriesActions';
import { useTranslation } from "react-i18next";

const useStyle = makeStyles(theme => ({
    addSubCateogryLabelContainer: {
        marginTop: '20px',
        marginBottom: '30px'
    },
    addSubCategoryLabel: {
        fontFamily: 'Poppins',
    },
    addSubCategoryContainer: {
        minHeight: '100px',
        backgroundColor: '#fff',
        boxShadow: '0 7px 30px 0 rgba(88, 165, 211, 0.16)',
        borderRadius: '27px',
    },
    addSubCategoryInput: {
        fontFamily: 'Poppins',
        marginLeft: '50px',
        width: '95%'
    },
    addSubCategoryButton: {
        marginRight: '50px',
        marginTop: '10px',
        marginBottom: '10px',
        width: '140px',
        fontFamily: 'Poppins',
        color: '#fff',
        backgroundColor: '#3eadef',
        borderRadius: '27px',
        textTransform: 'none',
        '&:hover': {
            backgroundColor: '#3eadef',
        }
    },
    affectCategorySelect: {
        width: '95%',
    },
    selectClass: {
        fontFamily: 'Poppins',
        paddingRight: '0px !important',
        '&:focus': {
            backgroundColor: 'white'
        }
    },
    categTableHeader: {
        fontFamily: 'Poppins',
        padding: '10px'
    },
    categTableRow: {
        minHeight: '100px',
        backgroundColor: '#fff',
        boxShadow: '0 7px 30px 0 rgba(88, 165, 211, 0.16)',
        borderRadius: '27px',
        marginTop: '15px',
        marginBottom: '15px'
    },
    categTableCell: {
        fontFamily: 'Poppins',
        padding: '10px'
    },
    spacement: {
        marginTop: '20px',
        marginBottom: '20px'
    },
    menu: {
        boxShadow: "0 7px 7px 0 #d0e6f130",
    },
    overlay: {
        zIndex: theme.zIndex.drawer + 1
    },
    validationField: {
        fontFamily: 'Poppins',
        color: 'red',
        fontSize: '14px'
    },
    selectedMenuItems: {
        backgroundColor: '#50bef3 !important',
        color: '#fff'
    },
    paperDialog: {
        width: '600px',
        minHeight: '200px',
        borderRadius: '35px',
        backgroundColor: '#eff9fe'
    },
    dialogTitle: {
        fontFamily: 'Poppins',
        fontSize: '20px',
        color: '#ff005fa1'
    },
    dialogInputRow: {
        backgroundColor: '#fff',
        borderRadius: '25px',
        boxShadow: '0 7px 30px 0 rgba(88, 165, 211, 0.16)',
        padding: '5px',
        margin: '5px'
    },
    dialogUpdateInput: {
        fontFamily: 'Poppins'
    },
    dialogUpdateButton: {
        margin: '5px',
        color: '#ffffff',
        paddingLeft: '20px',
        paddingRight: '20px',
        borderRadius: '35px',
        backgroundColor: '#3eadef',
        fontSize: '14px',
        textTransform: 'none',
        fontFamily: 'Poppins',
        boxShadow: '0 7px 30px 0 rgba(88, 165, 211, 0.16)',
        '&:hover': {
            backgroundColor: '#3eadef',
        },
    },
    dialogCancelButton: {
        margin: '5px',
        color: '#ffffff',
        paddingLeft: '20px',
        paddingRight: '20px',
        borderRadius: '35px',
        backgroundColor: '#717171',
        fontSize: '14px',
        textTransform: 'none',
        fontFamily: 'Poppins',
        boxShadow: '0 7px 30px 0 rgba(88, 165, 211, 0.16)',
        '&:hover': {
            backgroundColor: '#a9a9a9',
        },
    },
    menuDialog: {
        width: "500px",
        height: "170px",
        borderRadius: "20px",
    },
    cancelButton: {
        marginTop: '15px',
        padding: '6px 8px',
        width: "200px",
        height: "40px",
        color: '#ffffff',
        fontFamily: 'Poppins',
        fontSize: "16px",
        textTransform: "capitalize",
        borderRadius: "20px",
        backgroundColor: 'gray',
        '&:hover': {
            backgroundColor: 'gray'
        }
    },
    deleteButton: {
        marginTop: '15px',
        padding: '6px 8px',
        width: "180px",
        height: "40px",
        color: '#fff',
        fontFamily: 'Poppins',
        fontSize: "16px",
        textTransform: "capitalize",
        boxShadow: "0px 0px 0px 0px #00aeef",
        borderRadius: "20px",
        backgroundColor: 'red',
        '&:hover': {
            backgroundColor: 'red'
        }
    },
    dialogTitleDelete: {
        fontFamily: 'Poppins',
        fontSize: '21px',
        textAlign: 'center'
    },
}));

const iconStyles = {
    selectIcon: {
        color: "black"
    },
    loaderStyles: {
        color: 'red'
    }
};

const CustomExpandMore = withStyles(iconStyles)(
    ({ className, classes, ...rest }) => {
        return (
            <ExpandMoreIcon
                {...rest}
                className={clsx(className, classes.selectIcon)}
            />
        );
    }
);

const equalsIgnoreOrder = (a, b) => {
    if (a.length !== b.length) return false;
    const uniqueValues = new Set([...a, ...b]);
    for (const v of uniqueValues) {
        const aCount = a.filter(e => e === v).length;
        const bCount = b.filter(e => e === v).length;
        if (aCount !== bCount) return false;
    }
    return true;
};

const SubCategoriesSubView = (props) => {

    const { t, i18n } = useTranslation("common");
    const classes = useStyle();
    const [anchorEl, setAnchorEl] = useState(null);
    const theme = useTheme();
    const smDown = useMediaQuery(theme.breakpoints.down('sm'));
    const xsDown = useMediaQuery(theme.breakpoints.down('xs'));

    const [selectInputValue, setSelectInputValue] = useState([]);
    const [subCategoryFrenchName, setSubCategoryFrenchName] = useState('');
    const [subCategoryEnglishName, setSubCategoryEnglishName] = useState('');
    const [subCategoryFrenchNameValidationField, setSubCategoryFrenchNameValidationField] = useState(null);
    const [subCategoryEnglishNameValidationField, setSubCategoryEnglishNameValidationField] = useState(null);
    const [subCategoryParentCategoryValidationField, setSubCategoryParentCategoryValidationField] = useState(null);

    const [updateSelectInputValue, setUpdateSelectInputValue] = useState([]);
    const [subCategoryToUpdateFrenchName, setSubCategoryToUpdateFrenchName] = useState('');
    const [subCategoryToUpdateEnglishName, SetSubCategoryToUpdateEnglishName] = useState('');
    const [subCategoryToUpdateFrenchNameValidationField, setSubCategoryToUpdateFrenchNameValidationField] = useState(null);
    const [subCategoryToUpdateEnglishNameValidationField, setSubCategoryToUpdateEnglishNameValidationField] = useState(null);
    const [subCategoryToUpdateParentCategoryValidationField, setSubCategoryToUpdateParentCategoryValidationField] = useState(null);

    const [updateDialogState, setUpdateDialogState] = useState(false);
    const [subCategoryToUpdate, setSubCategoryToUpdate] = useState(null);

    const [requestAddSubCategoryMode, setRequestAddSubCategoryMode] = useState(false);
    const [requestUpdateSubCategoryMode, setRequestUpdateSubCategoryMode] = useState(false);
    const [requestRemoveSubCategoryMode, setRequestRemoveSubCategoryMode] = useState(false);

    const [snackBarAddSubCategorySuccessState, setSnackBarAddSubCategorySuccessState] = useState(false);
    const [snackBarAddSubCategoryAffectErrorState, setSnackBarAddSubCategoryAffectErrorState] = useState(false);
    const [snackBarAddSubCategoryErrorState, setSnackBarAddSubCategoryErrorState] = useState(false);

    const [snackBarUpdateSubCategorySuccessState, setSnackBarUpdateSubCategorySuccessState] = useState(false);
    const [snackBarUpdateSubCategoryAffectErrorState, setSnackBarUpdateSubCategoryAffectErrorState] = useState(false);
    const [snackBarUpdateSubCategoryErrorState, setSnackBarUpdateSubCategoryErrorState] = useState(false);

    const [snackBarRemoveSubCategorySuccessState, setSnackBarRemoveSubCategorySuccessState] = useState(false);
    const [snackBarRemoveSubCategoryAffectErrorState, setSnackBarRemoveSubCategoryAffectErrorState] = useState(false);
    const [snackBarRemoveSubCategoryErrorState, setSnackBarRemoveSubCategoryErrorState] = useState(false);
    const [openDialogDelete, setOpenDialogDelete] = useState(false);
    const {
        categories,
        subCategories,
        loadedNewCategories,
        loadedSubCategories,
        loadedAddSubCategory,
        loadedUpdateSubCategory,
        loadedRemoveSubCategory,
        addSubCategoryError,
        updateSubCategoryError,
        removeSubCategoryError,
        addSubCategoryRequest,
        updateSubCategoryRequest,
        removeSubCategoryRequest,
    } = props;

    const handleOpenMenu = (event, subCategory) => {
        setAnchorEl(event.currentTarget);
        setSubCategoryToUpdate(subCategory)
    };

    const handleCloseMenu = () => {
        setAnchorEl(null);
    };

    const handleInputsValueChange = id => e => {
        if (id === 'categories') setSelectInputValue(e.target.value);
        if (id === 'sub-categ-name-fr') setSubCategoryFrenchName(e.target.value);
        if (id === 'sub-categ-name-en') setSubCategoryEnglishName(e.target.value);
        if (id === 'update-categories') setUpdateSelectInputValue(e.target.value);
        if (id === 'update-sub-categ-name-fr') setSubCategoryToUpdateFrenchName(e.target.value);
        if (id === 'update-sub-categ-name-en') SetSubCategoryToUpdateEnglishName(e.target.value);
    };

    const handleOpenUpdateDialog = () => {
        setUpdateDialogState(true);
        setAnchorEl(null);
    };

    const handleCloseUpdateDialog = () => {
        setSubCategoryToUpdate(null);
        setUpdateDialogState(false);
    };

    const addSubCategory = () => {
        if (subCategoryEnglishName === '' || subCategoryFrenchName === '' || selectInputValue.length === 0) {
            (subCategoryFrenchName === '')
                ? setSubCategoryFrenchNameValidationField(t('ADMIN.VIEWS.CATEGORIES.SUB_CATEGORIES_TAB_CONTENT.VALIDATION.FRENCH_NAME'))
                : setSubCategoryFrenchNameValidationField(null);
            (subCategoryEnglishName === '')
                ? setSubCategoryEnglishNameValidationField(t('ADMIN.VIEWS.CATEGORIES.SUB_CATEGORIES_TAB_CONTENT.VALIDATION.ENGLISH_NAME'))
                : setSubCategoryEnglishNameValidationField(null);
            (selectInputValue.length === 0)
                ? setSubCategoryParentCategoryValidationField(t('ADMIN.VIEWS.CATEGORIES.SUB_CATEGORIES_TAB_CONTENT.VALIDATION.CATEGORIES'))
                : setSubCategoryParentCategoryValidationField(null);
        } else {
            setSubCategoryEnglishNameValidationField(null);
            setSubCategoryFrenchNameValidationField(null);
            setSubCategoryParentCategoryValidationField(null);
            setRequestAddSubCategoryMode(true);
            let categoryToAdd = {};
            categoryToAdd['text-fr'] = subCategoryFrenchName;
            categoryToAdd['text-en'] = subCategoryEnglishName;
            categoryToAdd['categ'] = selectInputValue.map(categ => categ.key);
            addSubCategoryRequest(categoryToAdd, selectInputValue.map(categ => categ.key));
        }
    };

    const updateSubCategory = () => {
        if (subCategoryToUpdateEnglishName === '' || subCategoryToUpdateFrenchName === '' || updateSelectInputValue.length === 0) {
            (subCategoryToUpdateFrenchName === '')
                ? setSubCategoryToUpdateFrenchNameValidationField(t('ADMIN.VIEWS.CATEGORIES.SUB_CATEGORIES_TAB_CONTENT.VALIDATION.FRENCH_NAME'))
                : setSubCategoryToUpdateFrenchNameValidationField(null);
            (subCategoryToUpdateEnglishName === '')
                ? setSubCategoryToUpdateEnglishNameValidationField(t('ADMIN.VIEWS.CATEGORIES.SUB_CATEGORIES_TAB_CONTENT.VALIDATION.ENGLISH_NAME'))
                : setSubCategoryToUpdateEnglishNameValidationField(null);
            (updateSelectInputValue.length === 0)
                ? setSubCategoryToUpdateParentCategoryValidationField(t('ADMIN.VIEWS.CATEGORIES.SUB_CATEGORIES_TAB_CONTENT.VALIDATION.CATEGORIES'))
                : setSubCategoryToUpdateParentCategoryValidationField(null);
        } else {
            setSubCategoryToUpdateFrenchNameValidationField(null);
            setSubCategoryToUpdateEnglishNameValidationField(null);
            setSubCategoryToUpdateParentCategoryValidationField(null);
            setRequestUpdateSubCategoryMode(true);
            let updates = {};
            updates['text-fr'] = subCategoryToUpdateFrenchName;
            updates['text-en'] = subCategoryToUpdateEnglishName;
            updates['categ'] = updateSelectInputValue.map(cat => cat.key);
            if (!equalsIgnoreOrder(updateSelectInputValue.map(item => item.key), (subCategoryToUpdate.categ) ? subCategoryToUpdate.categ : [])) {
                updates['newAddedParents'] = updateSelectInputValue.filter(item => subCategoryToUpdate.categ.indexOf(item.key) === -1).map(categ => categ.key);
                updates['removedParents'] = subCategoryToUpdate.categ.filter(item => updateSelectInputValue.map(cat => cat.key).indexOf(item) === -1);
            } else {
                updates['newAddedParents'] = [];
                updates['removedParents'] = [];
            };
            updateSubCategoryRequest(subCategoryToUpdate, updates);
            setUpdateDialogState(false);
        }
    };

    const handleOpenDialogDelete = () => {
        setOpenDialogDelete(true);
    }
    const handleCloseDialogDelete = () => {
        setAnchorEl(null);
        setOpenDialogDelete(false);
    }


    const removeSubCategory = () => {
        setAnchorEl(null);
        setRequestRemoveSubCategoryMode(true);
        removeSubCategoryRequest(subCategoryToUpdate);
        setOpenDialogDelete(false);
    };

    useEffect(() => {
        if (subCategoryToUpdate) {
            setSubCategoryToUpdateFrenchName(subCategoryToUpdate['text-fr']);
            SetSubCategoryToUpdateEnglishName(subCategoryToUpdate['text-en']);
            setUpdateSelectInputValue(categories.filter(item => (subCategoryToUpdate['categ'].indexOf(item.key)) !== -1));
        }
    }, [subCategoryToUpdate, categories]);

    useEffect(() => {
        if (!loadedAddSubCategory && requestAddSubCategoryMode) {
            if (!addSubCategoryError) {
                setSubCategoryFrenchName('');
                setSubCategoryEnglishName('');
                setSelectInputValue([]);
                setSnackBarAddSubCategorySuccessState(true);
                setRequestAddSubCategoryMode(false);
            } else {
                setRequestAddSubCategoryMode(false);
                if (addSubCategoryError.status && addSubCategoryError.status === 400) {
                    setSnackBarAddSubCategoryAffectErrorState(true);
                    setSnackBarAddSubCategorySuccessState(true);
                    setSelectInputValue([]);
                    setSubCategoryFrenchName('');
                    setSubCategoryEnglishName('');
                } else {
                    setSnackBarAddSubCategoryErrorState(true);
                }
            }
        }
    }, [loadedAddSubCategory, addSubCategoryError, requestAddSubCategoryMode]);

    useEffect(() => {
        if (!loadedUpdateSubCategory && requestUpdateSubCategoryMode) {
            if (!updateSubCategoryError) {
                setSubCategoryToUpdateFrenchName('');
                SetSubCategoryToUpdateEnglishName('');
                setSnackBarUpdateSubCategorySuccessState(true);
                setRequestUpdateSubCategoryMode(false);
            } else {
                setRequestUpdateSubCategoryMode(false);
                if (updateSubCategoryError.status && updateSubCategoryError.status === 400) {
                    setSnackBarUpdateSubCategoryAffectErrorState(true);
                    setSnackBarUpdateSubCategorySuccessState(true);
                    setSelectInputValue([]);
                    setSubCategoryToUpdateFrenchName('');
                    SetSubCategoryToUpdateEnglishName('');
                } else {
                    setSnackBarUpdateSubCategoryErrorState(true);
                }
            }
        }
    }, [loadedUpdateSubCategory, updateSubCategoryError, requestUpdateSubCategoryMode]);

    useEffect(() => {
        if (!loadedRemoveSubCategory && requestRemoveSubCategoryMode) {
            if (!removeSubCategoryError) {
                setSnackBarRemoveSubCategorySuccessState(true);
                setRequestRemoveSubCategoryMode(false);
            } else {
                setRequestRemoveSubCategoryMode(false);
                if (removeSubCategoryError.status && removeSubCategoryError.status === 400) {
                    setSnackBarRemoveSubCategoryAffectErrorState(true);
                    setSnackBarRemoveSubCategorySuccessState(true);
                } else {
                    setSnackBarRemoveSubCategoryErrorState(true);
                }
            }
        }
    }, [loadedRemoveSubCategory, removeSubCategoryError, requestRemoveSubCategoryMode]);

    return (
        (loadedNewCategories && loadedSubCategories)
            ?
            <Grid container item direction='column'>
                <Backdrop className={classes.overlay} open={loadedAddSubCategory || loadedUpdateSubCategory || loadedRemoveSubCategory}>
                    <CircularProgress color='secondary' />
                </Backdrop>
                <Dialog open={updateDialogState} onClose={handleCloseUpdateDialog} classes={{ paper: classes.paperDialog }}>
                    <DialogTitle style={{ display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
                        <Typography className={classes.dialogTitle}>
                            {t('ADMIN.VIEWS.CATEGORIES.SUB_CATEGORIES_TAB_CONTENT.UPDATE_SUB_CATEGORY_DIALOG.UPDATE_DIALOG_TITLE')}
                        </Typography>
                    </DialogTitle>
                    <DialogContent>
                        <Grid container item direction='column' alignItems='center'>
                            <Grid container item direction='row' justify='space-around' alignItems='center' xs={10} className={classes.dialogInputRow}>
                                <Typography className={classes.dialogUpdateInput} style={{ color: '#5d5d5db0' }}>
                                    {t('ADMIN.VIEWS.CATEGORIES.SUB_CATEGORIES_TAB_CONTENT.UPDATE_SUB_CATEGORY_DIALOG.SUB_CATEGORY_FRENCH_NAME_LABEL')}
                                </Typography>
                                <Input
                                    className={classes.dialogUpdateInput}
                                    disableUnderline
                                    value={subCategoryToUpdateFrenchName}
                                    onChange={handleInputsValueChange('update-sub-categ-name-fr')}
                                />
                            </Grid>
                            <Grid container item direction='row' justify='space-around' alignItems='center' xs={10}>
                                <Typography className={classes.validationField}>{subCategoryToUpdateFrenchNameValidationField}</Typography>
                            </Grid>
                            <Grid container item direction='row' justify='space-around' alignItems='center' xs={10} className={classes.dialogInputRow}>
                                <Typography className={classes.dialogUpdateInput} style={{ color: '#5d5d5db0' }}>
                                    {t('ADMIN.VIEWS.CATEGORIES.SUB_CATEGORIES_TAB_CONTENT.UPDATE_SUB_CATEGORY_DIALOG.SUB_CATEGORY_ENGLISH_NAME_LABEL')}
                                </Typography>
                                <Input
                                    className={classes.dialogUpdateInput}
                                    disableUnderline
                                    value={subCategoryToUpdateEnglishName}
                                    onChange={handleInputsValueChange('update-sub-categ-name-en')}
                                />
                            </Grid>
                            <Grid container item direction='row' justify='space-around' alignItems='center' xs={10}>
                                <Typography className={classes.validationField}>{subCategoryToUpdateEnglishNameValidationField}</Typography>
                            </Grid>
                            <Grid container item direction='row' alignItems='center' xs={10} className={classes.dialogInputRow}>
                                <Select
                                    multiple
                                    disableUnderline
                                    displayEmpty
                                    className={classes.affectCategorySelect}
                                    style={{ width: '100%' }}
                                    classes={{ root: classes.selectClass }}
                                    value={updateSelectInputValue}
                                    onChange={handleInputsValueChange('update-categories')}
                                    renderValue={(selected) => {
                                        if (selected.length === 0) {
                                            return <Typography style={{ fontFamily: 'Poppins', color: '#717171', paddingLeft: '10px' }}>
                                                {t('ADMIN.VIEWS.CATEGORIES.SUB_CATEGORIES_TAB_CONTENT.UPDATE_SUB_CATEGORY_DIALOG.AFFECTED_CATEGORIES_PLACEHOLDER')}
                                            </Typography>;
                                        }
                                        return selected.map(categ => categ['text-fr']).join(', ');
                                    }}
                                    IconComponent={CustomExpandMore}>
                                    {
                                        categories.map(category => (
                                            <MenuItem
                                                value={category}
                                                key={category.key}
                                                style={{ fontFamily: 'Poppins' }}
                                                classes={{ selected: classes.selectedMenuItems }}>{category['text-fr']}</MenuItem>
                                        ))
                                    }
                                </Select>
                            </Grid>
                            <Grid container item direction='row' justify='space-around' alignItems='center' xs={10}>
                                <Typography className={classes.validationField}>{subCategoryToUpdateParentCategoryValidationField}</Typography>
                            </Grid>
                        </Grid>
                    </DialogContent>
                    <DialogActions style={{ paddingRight: '30px' }}>
                        <Button className={classes.dialogCancelButton} onClick={handleCloseUpdateDialog}>
                            {t('ADMIN.VIEWS.CATEGORIES.SUB_CATEGORIES_TAB_CONTENT.UPDATE_SUB_CATEGORY_DIALOG.CANCEL_BUTTON')}
                        </Button>
                        <Button className={classes.dialogUpdateButton} onClick={updateSubCategory}>
                            {t('ADMIN.VIEWS.CATEGORIES.SUB_CATEGORIES_TAB_CONTENT.UPDATE_SUB_CATEGORY_DIALOG.UPDATE_BUTTON')}
                        </Button>
                    </DialogActions>
                </Dialog>
                <Dialog classes={{ paper: classes.menuDialog }} open={openDialogDelete} onClose={handleCloseDialogDelete} aria-labelledby="form-dialog-title">
                    <Grid container item direction='column' xs={12} alignItems='center' style={{ marginTop: '20px' }}>
                        <Typography className={classes.dialogTitleDelete}>  {t('ADMIN.VIEWS.CATEGORIES.SUB_CATEGORIES_TAB_CONTENT.DELETE_SUB_CATEGORY_DIALOG.DELETE_DIALOG_TITLE')}</Typography>
                    </Grid>
                    <Grid container item direction='row' xs={12} justify='center'>
                        <Button className={classes.cancelButton} onClick={handleCloseDialogDelete} style={{ marginRight: (xsDown) ? '0px' : '15px' }}>   {t('ADMIN.VIEWS.CATEGORIES.SUB_CATEGORIES_TAB_CONTENT.DELETE_SUB_CATEGORY_DIALOG.CANCEL_BUTTON')}</Button>
                        <Button className={classes.deleteButton} onClick={removeSubCategory} style={(xsDown) ? { marginBottom: '10px' } : { marginBottom: '0' }}> {t('ADMIN.VIEWS.CATEGORIES.SUB_CATEGORIES_TAB_CONTENT.DELETE_SUB_CATEGORY_DIALOG.DELETE_BUTTON')}</Button>
                    </Grid>
                </Dialog>
                <Snackbar open={snackBarAddSubCategorySuccessState} onClose={() => setSnackBarAddSubCategorySuccessState(false)} autoHideDuration={2000}>
                    <Alert severity="success" variant='filled' style={{ fontFamily: 'Poppins' }}>
                        {t('ADMIN.VIEWS.CATEGORIES.SUB_CATEGORIES_TAB_CONTENT.SNACKBARS.ADD_SUCCESS')}
                    </Alert>
                </Snackbar>
                <Snackbar open={snackBarAddSubCategoryErrorState} onClose={() => setSnackBarAddSubCategoryErrorState(false)} autoHideDuration={2000}>
                    <Alert severity="error" variant='filled' style={{ fontFamily: 'Poppins' }}>
                        {t('ADMIN.VIEWS.CATEGORIES.SUB_CATEGORIES_TAB_CONTENT.SNACKBARS.ADD_ERROR')}
                    </Alert>
                </Snackbar>
                <Snackbar open={snackBarAddSubCategoryAffectErrorState} onClose={() => setSnackBarAddSubCategoryAffectErrorState(false)} autoHideDuration={2000}>
                    <Alert severity="error" variant='filled' style={{ fontFamily: 'Poppins' }}>
                        {t('ADMIN.VIEWS.CATEGORIES.SUB_CATEGORIES_TAB_CONTENT.SNACKBARS.ADD_AFFECT_ERROR')}
                    </Alert>
                </Snackbar>
                <Snackbar open={snackBarUpdateSubCategorySuccessState} onClose={() => setSnackBarUpdateSubCategorySuccessState(false)} autoHideDuration={2000}>
                    <Alert severity="success" variant='filled' style={{ fontFamily: 'Poppins' }}>
                        {t('ADMIN.VIEWS.CATEGORIES.SUB_CATEGORIES_TAB_CONTENT.SNACKBARS.UPDATE_SUCCESS')}
                    </Alert>
                </Snackbar>
                <Snackbar open={snackBarUpdateSubCategoryErrorState} onClose={() => setSnackBarUpdateSubCategoryErrorState(false)} autoHideDuration={2000}>
                    <Alert severity="error" variant='filled' style={{ fontFamily: 'Poppins' }}>
                        {t('ADMIN.VIEWS.CATEGORIES.SUB_CATEGORIES_TAB_CONTENT.SNACKBARS.UPDATE_ERROR')}
                    </Alert>
                </Snackbar>
                <Snackbar open={snackBarUpdateSubCategoryAffectErrorState} onClose={() => setSnackBarUpdateSubCategoryAffectErrorState(false)} autoHideDuration={2000}>
                    <Alert severity="error" variant='filled' style={{ fontFamily: 'Poppins' }}>
                        {t('ADMIN.VIEWS.CATEGORIES.SUB_CATEGORIES_TAB_CONTENT.SNACKBARS.UPDATE_AFFECT_ERROR')}
                    </Alert>
                </Snackbar>
                <Snackbar open={snackBarRemoveSubCategorySuccessState} onClose={() => setSnackBarRemoveSubCategorySuccessState(false)} autoHideDuration={2000}>
                    <Alert severity="success" variant='filled' style={{ fontFamily: 'Poppins' }}>
                        {t('ADMIN.VIEWS.CATEGORIES.SUB_CATEGORIES_TAB_CONTENT.SNACKBARS.REMOVE_SUCCESS')}
                    </Alert>
                </Snackbar>
                <Snackbar open={snackBarRemoveSubCategoryErrorState} onClose={() => setSnackBarRemoveSubCategoryErrorState(false)} autoHideDuration={2000}>
                    <Alert severity="error" variant='filled' style={{ fontFamily: 'Poppins' }}>
                        {t('ADMIN.VIEWS.CATEGORIES.SUB_CATEGORIES_TAB_CONTENT.SNACKBARS.REMOVE_ERROR')}
                    </Alert>
                </Snackbar>
                <Snackbar open={snackBarRemoveSubCategoryAffectErrorState} onClose={() => setSnackBarRemoveSubCategoryAffectErrorState(false)} autoHideDuration={2000}>
                    <Alert severity="error" variant='filled' style={{ fontFamily: 'Poppins' }}>
                        {t('ADMIN.VIEWS.CATEGORIES.SUB_CATEGORIES_TAB_CONTENT.SNACKBARS.REMOVE_AFFECT_ERROR')}
                    </Alert>
                </Snackbar>
                <Grid container item direction='row' xs={12} className={classes.addSubCateogryLabelContainer}>
                    <Typography className={classes.addSubCategoryLabel}>
                        {t('ADMIN.VIEWS.CATEGORIES.SUB_CATEGORIES_TAB_CONTENT.ADD_SUB_CATEGORY_LABEL')}
                    </Typography>
                </Grid>
                <Grid container item direction='row' justify='center' alignItems='center' className={classes.addSubCategoryContainer}>
                    <Grid container item direction='column' justify='space-evenly' style={{ width: '100%', height: '125px' }}>
                        <InputBase
                            value={subCategoryFrenchName}
                            className={classes.addSubCategoryInput}
                            style={(smDown) ? { marginLeft: '0px', paddingLeft: '20px', paddingRight: '20px' } : {}}
                            placeholder={t('ADMIN.VIEWS.CATEGORIES.SUB_CATEGORIES_TAB_CONTENT.SUB_CATEGORY_FRENCH_NAME_PLACEHOLDER')}
                            onChange={handleInputsValueChange('sub-categ-name-fr')} />
                        <Divider light style={{ marginRight: '20px', marginLeft: '20px' }} />
                        <InputBase
                            value={subCategoryEnglishName}
                            className={classes.addSubCategoryInput}
                            style={(smDown) ? { marginLeft: '0px', paddingLeft: '20px', paddingRight: '20px' } : {}}
                            placeholder={t('ADMIN.VIEWS.CATEGORIES.SUB_CATEGORIES_TAB_CONTENT.SUB_CATEGORY_ENGLISH_NAME_PLACEHOLDER')}
                            onChange={handleInputsValueChange('sub-categ-name-en')} />
                    </Grid>
                </Grid>
                <Grid container item direction='row' xs={12} className={classes.addSubCateogryLabelContainer}>
                    <Typography className={classes.addSubCategoryLabel}>
                        {t('ADMIN.VIEWS.CATEGORIES.SUB_CATEGORIES_TAB_CONTENT.AFFECT_TO_CATEGORY_LABEL')}
                    </Typography>
                </Grid>
                <Grid container item direction='row' justify='center' xs={12} alignItems='center' className={classes.addSubCategoryContainer}>
                    <Select
                        multiple
                        disableUnderline
                        displayEmpty
                        className={classes.affectCategorySelect}
                        classes={{ root: classes.selectClass }}
                        value={selectInputValue}
                        onChange={handleInputsValueChange('categories')}
                        renderValue={(selected) => {
                            if (selected.length === 0) {
                                return <Typography style={{ fontFamily: 'Poppins', color: '#717171', paddingLeft: '10px' }}>
                                    {t('ADMIN.VIEWS.CATEGORIES.SUB_CATEGORIES_TAB_CONTENT.CATEGORIES_PLACEHOLDER')}
                                </Typography>;
                            }
                            return selected.map(categ => { return (i18n.language === 'fr-FR' || i18n.language === 'fr') ? categ['text-fr'] : categ['text-en'] }).join(', ');
                        }}
                        IconComponent={CustomExpandMore}>
                        {
                            categories.map(category => (
                                <MenuItem
                                    value={category}
                                    key={category.key}
                                    style={{ fontFamily: 'Poppins' }}
                                    classes={{ selected: classes.selectedMenuItems }}>{(i18n.language === 'fr-FR' || i18n.language === 'fr') ? category['text-fr'] : category['text-en']}</MenuItem>
                            ))
                        }
                    </Select>
                </Grid>
                <Grid container item direction='row' className={classes.spacement}>
                    <Button className={classes.addSubCategoryButton} onClick={addSubCategory}>
                        {t('ADMIN.VIEWS.CATEGORIES.SUB_CATEGORIES_TAB_CONTENT.ADD_SUB_CATEGORY_BUTTON')}
                    </Button>
                </Grid>

                <Grid container item direction='column'>
                    <Typography className={classes.validationField}>{subCategoryFrenchNameValidationField}</Typography>
                    <Typography className={classes.validationField}>{subCategoryEnglishNameValidationField}</Typography>
                    <Typography className={classes.validationField}>{subCategoryParentCategoryValidationField}</Typography>
                </Grid>
                <Grid container item direction='row' xs={12} style={{ overflowX: "auto" }}>
                    <div style={{ width: '100%', minWidth: '1050px' }}>
                        <Grid container item direction='row' alignItems='center' className={classes.spacement}>
                            <Grid container item direction='row' alignItems='center' style={{ width: '47%' }}>
                                <Typography className={classes.categTableHeader}>
                                    {t('ADMIN.VIEWS.CATEGORIES.SUB_CATEGORIES_TAB_CONTENT.SUB_CATEGORIES_TABLE_HEADERS.SUB_CATEGORIES_HEADER')}
                                </Typography>
                                <FilterListIcon />
                            </Grid>
                            <Grid container item direction='row' alignItems='center' style={{ width: '47%' }}>
                                <Typography className={classes.categTableHeader}>
                                    {t('ADMIN.VIEWS.CATEGORIES.SUB_CATEGORIES_TAB_CONTENT.SUB_CATEGORIES_TABLE_HEADERS.CATEGORIES_HEADER')}
                                </Typography>
                            </Grid>
                        </Grid>
                        {
                            subCategories.map(subCategory => (
                                <Grid container item direction='row' alignItems='center' className={classes.categTableRow} key={subCategory.key}>
                                    <Grid container item direction='row' style={{ width: '47%' }}>
                                        <Typography className={classes.categTableCell}>{(i18n.language === 'fr-FR' || i18n.language === 'fr') ? subCategory['text-fr'] : subCategory['text-en']}</Typography>
                                    </Grid>
                                    <Grid container item direction='row' style={{ width: '47%' }}>
                                        <Typography className={classes.categTableCell} style={{ textDecoration: 'underline', cursor: 'pointer' }}>
                                            {t('ADMIN.VIEWS.CATEGORIES.SUB_CATEGORIES_TAB_CONTENT.SUB_CATEGORIES_TABLE_HEADERS.CATEGORIES_HEADER')}
                                        </Typography>
                                    </Grid>
                                    <Grid container item direction='row' style={{ width: '6%' }}>
                                        <IconButton onClick={(e) => handleOpenMenu(e, subCategory)}>
                                            <MoreVertIcon />
                                        </IconButton>
                                    </Grid>
                                    <Menu classes={{ paper: classes.menu }} anchorEl={anchorEl} open={Boolean(anchorEl)} onClose={handleCloseMenu} >
                                        <MenuItem className={classes.categTableCell} onClick={handleOpenUpdateDialog}>
                                            {t('ADMIN.VIEWS.CATEGORIES.SUB_CATEGORIES_TAB_CONTENT.SUB_CATEGORIES_TABLE_MENU.EDIT_LINK')}
                                        </MenuItem>
                                        <MenuItem className={classes.categTableCell} onClick={handleOpenDialogDelete}>
                                            {t('ADMIN.VIEWS.CATEGORIES.SUB_CATEGORIES_TAB_CONTENT.SUB_CATEGORIES_TABLE_MENU.DELETE_LINK')}
                                        </MenuItem>
                                    </Menu>
                                </Grid>
                            ))
                        }
                    </div>
                </Grid>
            </Grid>
            :
            <Grid container item direction='column' justify='center' alignItems='center' style={{ flexGrow: 1 }}>
                <CircularProgress color='secondary' />
            </Grid>
    )
};

const mapStateToProps = (state, ownProps) => {
    const categories = state.categoriesState.newCategories;
    const subCategories = state.categoriesState.subCategories;
    const loadedNewCategories = state.categoriesState.loadedNewCategories;
    const loadedSubCategories = state.categoriesState.loadedSubCategories;
    const loadedAddSubCategory = state.categoriesState.loadedAddSubCategory;
    const loadedUpdateSubCategory = state.categoriesState.loadedUpdateSubCategory;
    const loadedRemoveSubCategory = state.categoriesState.loadedRemoveSubCategory;
    const addSubCategoryError = state.categoriesState.addSubCategoryError;
    const updateSubCategoryError = state.categoriesState.updateSubCategoryError;
    const removeSubCategoryError = state.categoriesState.removeSubCategoryError;
    return {
        categories,
        subCategories,
        loadedNewCategories,
        loadedSubCategories,
        loadedAddSubCategory,
        loadedUpdateSubCategory,
        loadedRemoveSubCategory,
        addSubCategoryError,
        updateSubCategoryError,
        removeSubCategoryError
    };
};

const mapDispatchToProps = (dispatch) => {
    return {
        addSubCategoryRequest: (subCategory, parentCategoriesIds) => {
            dispatch({ type: 'ADD_SUB_CATEGORY_REQUEST' });
            dispatch(addSubCategory(subCategory, parentCategoriesIds));
        },
        updateSubCategoryRequest: (subCategoryToUpdate, updates) => {
            dispatch({ type: 'UPDATE_SUB_CATEGORY_REQUEST' });
            dispatch(updateSubCategory(subCategoryToUpdate, updates));
        },
        removeSubCategoryRequest: (subCategoryToRemove) => {
            dispatch({ type: 'REMOVE_SUB_CATEGORY_REQUEST' });
            dispatch(removeSubCategory(subCategoryToRemove));
        }
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(SubCategoriesSubView);