const initState = {
    agentTableSortHelper: [1, 0, 0, 0, 0],
    agentTablePageNumber: 1,
    agentTablePageSize: 25,
    agentToValidateTableSortHelper: [1, 0],
    agentToValidateTablePageNumber: 1,
    agentToValidateTablePageSize: 25
};

const adminPartnerAgentReducer = (state = initState, action) => {
    switch (action.type) {
        case 'UPDATE_ADMIN_PARTNER_AGENT_TABLE_SORT_HELPER':
            return {
                ...state,
                agentTableSortHelper: action.sortHelper
            };
        case 'UPDATE_ADMIN_PARTNER_AGENT_TABLE_PAGE_NUMBER':
            return {
                ...state,
                agentTablePageNumber: action.pageNumber
            };

        case 'UPDATE_ADMIN_PARTNER_AGENT_TABLE_PAGE_SIZE':
            return {
                ...state,
                agentTablePageSize: action.pageSize
            };
        case 'UPDATE_ADMIN_PARTNER_AGENT_TO_VALIDATE_TABLE_SORT_HELPER':
            return {
                ...state,
                agentToValidateTableSortHelper: action.sortHelper
            };
        case 'UPDATE_ADMIN_PARTNER_AGENT_TO_VALIDATE_TABLE_PAGE_NUMBER':
            return {
                ...state,
                agentToValidateTablePageNumber: action.pageNumber
            };
        case 'UPDATE_ADMIN_PARTNER_AGENT_TO_VALIDATE_TABLE_PAGE_SIZE':
            return {
                ...state,
                agentToValidateTablePageSize: action.pageSize
            };
        default:
            return state;
    };
};

export default adminPartnerAgentReducer;