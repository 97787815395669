import React, { useState, useEffect, useCallback } from "react";
import { connect } from "react-redux";
import {
  Grid,
  Typography,
  TextField,
  Select,
  Box,
  Button,
  MenuItem,
  useTheme,
  useMediaQuery,
  CircularProgress,
} from "@material-ui/core";
import InfluencerModal from "../template/components/statistics/modals/InfluencerModal";
import BrandModal from "../template/components/statistics/modals/BrandModel";
import ConversionsChart from "../template/components/ConversionsChart";
import TrendLoading from "../../ui/trendLoading/TrendLoading";
import ProductPieChart from "../template/components/ProductPieChart";
import { makeStyles } from "@material-ui/core";
import InfluencersTable from "../template/components/statistics/InfluencersTable";
import BrandTable from "../template/components/statistics/BrandTable";
import { MuiPickersUtilsProvider, DatePicker } from "@material-ui/pickers";
import ArrowRightAltIcon from "@material-ui/icons/ArrowRightAlt";
import ArrowDownwardIcon from "@material-ui/icons/ArrowDownward";
import EuroSymbolIcon from "@material-ui/icons/EuroSymbol";
import TouchAppIcon from "@material-ui/icons/TouchApp";
import BootstrapInput from "../template/assets/customComponents/BootstrapInput";
import DateFnsUtils from "@date-io/date-fns";
import { MyDate } from "../../utils";
import SVGIcon from "../template/assets/SVGIcons/index";
import { useTranslation } from "react-i18next";
import CountUp from "react-countup";
import { clicksManager, dbManager } from "../../managers";
import SearchIcon from "@material-ui/icons/Search";
import placeholderSales from "../template/assets/image/placeholder_sales.png";

const useInputStyles = makeStyles((theme) => ({
  root: {
    overflow: "hidden",
    borderRadius: 24,
    backgroundColor: "#fff",
    textAlign: "center",
    width: "170px",
    height: "40px",
    boxShadow: "0 7px 30px 0 rgba(88, 165, 211, 0.16)",
    "&:hover": {
      backgroundColor: "#fff",
    },
    "&$focused": {
      backgroundColor: "#fff",
      borderColor: "#ff005f",
    },
    margin: "5px",
  },
  input: {
    textAlign: "center",
    fontFamily: "Poppins",
    fontWeight: 500,
  },
  focused: {},
}));

const useStyles = makeStyles((theme) => {
  return {
    pageContent: {
      flexGrow: 1,
      display: "flex",
      flexDirection: "column",
      backgroundColor: "#eff9fe",
    },
    titles: {
      fontFamily: "Poppins",
      fontWeight: 500,
    },
    selectClass: {
      fontFamily: "Poppins",
      borderRadius: "25px",
      width: "130px",
      "&:focus": {
        borderRadius: "25px",
        backgroundColor: "#ffffff",
      },
      boxShadow: "0 7px 30px 0 rgba(88, 165, 211, 0.16)",
    },
    rowSpacing: {
      marginTop: "10px",
      marginBottom: "10px",
    },
    smallBox: {
      boxShadow: "0 7px 30px 0 rgba(58, 111, 142, 0.25)",
      backgroundColor: "#fff",
      borderRadius: "41px",
      width: "240px",
      height: "150px",
      display: "flex",
      justifyContent: "center",
      alignItems: "center",
      margin: 10,
    },
    smallBoxTitles: {
      fontFamily: "Poppins",
      fontSize: "16px",
      fontWeight: 500,
      overflow: "hidden",
      textOverflow: "ellipsis",
    },
    smallBoxInfos: {
      fontFamily: "Poppins",
      fontSize: "16px",
      fontWeight: 900,
      overflow: "hidden",
      textOverflow: "ellipsis",
    },
    activeButtonStyle: {
      width: 250,
      margin: "5px",
      color: "#ffffff",
      borderRadius: "35px",
      backgroundColor: "#3eadef",
      fontSize: "14px",
      textTransform: "none",
      fontFamily: "Poppins",
      boxShadow: "0 7px 30px 0 rgba(88, 165, 211, 0.16)",
      "&:hover": {
        backgroundColor: "#3eadef",
      },
    },
    buttonStyle: {
      width: 250,
      margin: "5px",
      color: "#000",
      borderRadius: "35px",
      backgroundColor: "#ffffff",
      fontSize: "14px",
      textTransform: "none",
      fontFamily: "Poppins",
      boxShadow: "0 7px 30px 0 rgba(88, 165, 211, 0.16)",
    },
    noInfluencerTextPlaceholder: {
      fontFamily: "Poppins",
      fontSize: "16px",
      margin: 10,
      color: "#1b4c61",
      textAlign: "center",
    },
    buttonDateStyle: {
      width: "170px",
      height: "40px",
      margin: "5px",
      overflow: "hidden",
      boxShadow: "0 7px 30px 0 rgb(88 165 211 / 16%)",
      textAlign: "center",
      textTransform: "none",
      borderRadius: "24px",
      padding: "10",
      backgroundColor: "#3eadef",
      "&:hover": {
        backgroundColor: "#3eadef",
      },
    },
    textButtonDate: {
      textAlign: "center",
      fontFamily: "Poppins",
      fontWeight: 500,
      color: "#fff",
    },
    pieBox: {
      display: "flex",
      justifyContent: "center",
      alignItems: "center",
      backgroundColor: "white",
      height: "300px",
      width: "95%",
      borderRadius: "20px",
      boxShadow: "0 7px 30px 0 rgba(88, 165, 211, 0.16)",
    },
    textPlaceholder: {
      fontFamily: "Poppins",
      fontSize: "16px",
      margin: 10,
      color: "#1b4c61",
      textAlign: "center",
    },
  };
});

const Statistics = (props) => {
  const { t } = useTranslation("common");
  const inputClasses = useInputStyles();
  const classes = useStyles();
  const theme = useTheme();
  const xsDown = useMediaQuery(theme.breakpoints.down("xs"));
  const mdDown = useMediaQuery(theme.breakpoints.down("md"));

  const [loading, setLoading] = useState(false);
  const [user, setUser] = useState(null);
  const [periodSelector, setPeriodSelector] = useState("today");
  const [startPeriodDate, setStartPeriodDate] = useState(new MyDate());
  const [endPeriodDate, setEndPeriodDate] = useState(new MyDate());
  const [startPeriodDateClick, setStartPeriodDateClick] = useState(
    new MyDate()
  );
  const [endPeriodDateClick, setEndPeriodDateClick] = useState(new MyDate());
  const [activeFilter, setActiveFilter] = useState("influencers");
  const [myDbInfluencers, setMyDbInfluencers] = useState([]);
  const [periodBrandConversions, setPeriodBrandConversions] = useState([]);
  const [periodInfluencers, setPeriodInfluencers] = useState([]);
  const [conversionForChart, setConversionForChart] = useState([]);
  const [periodProducts, setPeriodProducts] = useState([]);
  const [salesPeriod, setSalesPeriod] = useState(0);
  const [salesNumberPeriod, setSalesNumberPeriod] = useState(0);
  const [paidCommissionPeriod, setPaidCommissionPeriod] = useState(0);
  const [unPaidCommissionsPeriod, setUnpaidCommissionsPeriod] = useState(0);
  const [keyInfluencers, setKeyInfluencers] = useState([]);
  const [sortEvent, setSortEvent] = useState(0);
  const [influencerInfos, setInfluencerInfos] = useState([]);
  const [clickPerInfluencer, setClickPerInfluencer] = useState(0);
  const [brandOfferId, setBrandOfferId] = useState([]);
  const [brandInformation, setBrandInformation] = useState([]);
  const [offerIdBrandLoading, setOfferIdBrandLoading] = useState(true);
  const [brandInformationLoading, setBrandInformationLoading] = useState(true);
  const [brandToRenderLoading, setBrandToRenderLoading] = useState(true);
  const [brandInfos, setBrandInfos] = useState([]);
  const [brandToRender, setBrandToRender] = useState([]);
  const [popupMode, setPopupMode] = useState(false);
  const [popupType, setPopupType] = useState("influencer");
  const [popupData, setPopupData] = useState({});
  const [periodCommissionSMI, setPeriodCommissionSMI] = useState(0);

  const {
    allConversions,
    influencers,
    loadedAllConversions,
    loadedInfluencers,
    brand,
    allInfluencerAgents,
    loadedInfluencerAgents
  } = props;
  const { influencerAgent } = props;

  const filterInfluencer = useCallback(
    async (conversions, influencerArray) => {
      let Amount = 0;
      let Commissions = 0;
      let SalesNumber = 0;
      let commissionSMI = 0;
      let product = [];
      let conversionForChart = [];
      myDbInfluencers.forEach((influencer) => {
        conversions.forEach((conversion) => {
          if (influencer.key === conversion.influencer) {
            conversionForChart.push(conversion);
            Amount += conversion.amount;
            Commissions += Number(conversion.commission);
            commissionSMI += Number(conversion.commission) - Number(conversion.commissionAffiliate);
            SalesNumber++;
            if (product.indexOf(conversion.articleId) === -1) {
              product.push(conversion.articleId);
            }
          }
        });
        influencerArray.push({
          amount: Amount,
          commissions: Commissions,
          salesNumber: SalesNumber,
          commissionSMI: commissionSMI,
          products: product,
          ...influencer,
        });
        Amount = 0;
        Commissions = 0;
        SalesNumber = 0;
        product = [];
      });
      setConversionForChart(conversionForChart);
      return influencerArray;
    },
    [myDbInfluencers]
  );

  const newFilterArticle = useCallback(async () => {
    let obj = {};
    let newArray = [];
    if (conversionForChart.length !== 0) {

      conversionForChart.forEach((conversion) => {
        let found = false;
        Object.keys(obj).forEach((objConversionId) => {
          if (objConversionId === conversion.articleId) {
            obj[objConversionId].push(conversion);
            found = true;
          }
        });
        if (!found) obj[conversion.articleId] = [conversion];
      });
      Object.values(obj).forEach((articleConversions) => {
        let amount = 0;
        let commissions = 0;
        let productPurchasers = [];
        let articleImgUrl = "";
        let articlePathUrl = "";
        let purchaseNumber = articleConversions.length;
        let articleId = "";
        articleConversions.forEach((conversion) => {
          let data;
          articleId = conversion.articleId;
          amount += conversion.amount;
          commissions += Number(conversion.commission);
          if (productPurchasers.indexOf(conversion.influencer) === -1)
            productPurchasers.push(conversion.influencer);
          if (articleImgUrl === "") {
            if (conversion.articleImgUrl === undefined) {
              dbManager
                .fetchSoldArticleById(conversion.articleId)
                .then((res) => {
                  data = res;
                })
                .then(() => {
                  articleImgUrl =
                    conversion.articleImgUrl === undefined
                      ? data
                        ? data.image
                        : ""
                      : conversion.articleImgUrl;
                  articlePathUrl =
                    conversion.articlePathUrl === undefined
                      ? data
                        ? data.image
                        : ""
                      : conversion.articlePathUrl;
                });
            }
            articleImgUrl =
              conversion.articleImgUrl === undefined
                ? data
                  ? data.image
                  : ""
                : conversion.articleImgUrl;
            articlePathUrl =
              conversion.articlePathUrl === undefined
                ? data
                  ? data.image
                  : ""
                : conversion.articlePathUrl;
          }
        });
        newArray.push({
          articleId,
          purchaseNumber,
          salesNumber: articleConversions.length,
          amount,
          commissions,
          productPurchasers,
          articleImgUrl,
          articlePathUrl,
        });
      });
    }
    return newArray;
  }, [conversionForChart]);


  const filterConversions = (startDate, endDate, conversions) => {
    const startTimestamp = Math.round(startDate.getTime() / 1000);
    const endTimestamp = Math.round(endDate.getTime() / 1000);
    return conversions
      .filter(
        (element) =>
          element.createdAt < endTimestamp && element.createdAt > startTimestamp
      )
      .sort((a, b) =>
        a.createdAt > b.createdAt ? -1 : a.createdAt < b.createdAt ? 1 : 0
      );
  };
  const filter = useCallback(async () => {
    let influencerArray = [];
    await filterInfluencer(periodBrandConversions, influencerArray);

    influencerArray.sort((a, b) => {
      if (a.amount > b.amount) return -1;
      if (a.amount < b.amount) return 1;
      return 0;
    });

    const filteredInfluencers = influencerArray.filter(
      (element) =>
        element.name !== undefined &&
        element.name !== null &&
        element.name !== ""
    );

    filteredInfluencers.map((influencer) => {
      clicksManager
        .getClicksPerInfluencer(
          startPeriodDate.getTime(),
          endPeriodDate.getTime(),
          influencer.key,
          undefined,
          startPeriodDate.getTimezoneOffset()
        )
        .then((result) => {
          filteredInfluencers[
            filteredInfluencers.map((e) => e.key).indexOf(influencer.key)
          ]["clicks"] = result.data.clicksNumber;
          setSortEvent((s) => s + 1);
        });
      return null;
    });
    setInfluencerInfos(filteredInfluencers);
  }, [
    periodBrandConversions,
    filterInfluencer,
    startPeriodDate,
    endPeriodDate,
  ]);

  const filterProduct = useCallback(async () => {
    let productsIds = [];
    await newFilterArticle().then((result) => {
      productsIds = result;
    });

    productsIds.sort((a, b) => {
      if (a.amount > b.amount) return -1;
      if (a.amount < b.amount) return 1;
      return 0;
    });
    setPeriodProducts(productsIds);
  }, [
    newFilterArticle
  ]);

  const handlePeriodSelector = (event) => {
    setPeriodSelector(event.target.value);
  };
  const getPreviousMonday = () => {
    let date = new MyDate();
    let day = date.getDay();
    let prevMonday;
    if (date.getDay() === 0) {
      prevMonday = new MyDate();
      prevMonday.setDate(date.getDate() - 7);
      return prevMonday;
    } else {
      prevMonday = new MyDate();
      prevMonday.setDate(date.getDate() - day + 1);
      return prevMonday;
    }
  };

  useEffect(() => {
    if (loadedAllConversions) {
      setPeriodBrandConversions(
        filterConversions(startPeriodDate, endPeriodDate, allConversions)
      );
    }
  }, [startPeriodDate, endPeriodDate, allConversions, loadedAllConversions]);
  /**
   * Array have list of influencers work with this Agent
   */
  useEffect(() => {
    let influencersArray = [];
    let keyInfluencers = [];
    if (user && loadedInfluencers) {
      const fetchInfluencers = async () => {
        if (user.influencersIds !== undefined) {
          user.influencersIds.forEach((id) => {
            if (id.acceptedStatus) {
              let influencer =
                influencers.filter((element) => element.key === id.uid).length === 0
                  ? null
                  : influencers.filter((element) => element.key === id.uid)[0];

              if (influencer) {
                influencersArray.push({
                  ...influencer,
                });
                keyInfluencers.push(influencer.key);
              }
            }
          });
        }
      };

      fetchInfluencers().then(() => {
        setMyDbInfluencers(influencersArray);
        setKeyInfluencers(keyInfluencers);
      });
    }
  }, [user, loadedInfluencers, influencers]);
  // Return BrandTable
  useEffect(() => {
    let obj = [];
    if (keyInfluencers.length !== 0 && periodBrandConversions.length !== 0) {
      keyInfluencers.forEach((key) => {
        periodBrandConversions.forEach((brand) => {
          if (key === brand.influencer) {
            if (obj.indexOf(brand.offerId) === -1) {
              obj.push(brand.offerId);
            }
          }
        });
      });
    }
    setBrandOfferId(obj);
    setOfferIdBrandLoading(false);
  }, [periodBrandConversions, startPeriodDate, endPeriodDate, keyInfluencers]);

  useEffect(() => {
    let BrandsArray = [];
    let Amount = 0;
    let Commissions = 0;
    let SalesNumber = 0;
    let product = [];
    let keyInfluencer = "";
    if (offerIdBrandLoading === false) {
      brandOfferId.forEach((offerId) => {
        keyInfluencers.forEach((key) => {
          periodBrandConversions.forEach((conversion) => {
            if (
              offerId === conversion.offerId &&
              key === conversion.influencer
            ) {
              Amount += conversion.amount;
              Commissions += Number(conversion.commission);
              SalesNumber++;
              keyInfluencer = key;
              if (product.indexOf(conversion.articleId) === -1) {
                product.push(conversion.articleId);
              }
            }
          });
        });

        BrandsArray.push({
          offerId: offerId,
          amount: Amount,
          commissions: Commissions,
          salesNumber: SalesNumber,
          keyInfluencer: keyInfluencer,
          product: product,
          clicks: undefined,
        });
        Amount = 0;
        Commissions = 0;
        SalesNumber = 0;
        product = [];
        keyInfluencer = "";
      });
    }

    setBrandInformation(BrandsArray);
    setBrandInformationLoading(false);
  }, [
    brandOfferId,
    periodBrandConversions,
    offerIdBrandLoading,
    keyInfluencers,
  ]);

  const brandToRenderCallBack = useCallback(async () => {
    let brandInfos = [];
    let sum = 0;
    if (brandInformationLoading === false && offerIdBrandLoading === false) {
      brandInformation.forEach((information) => {
        brand.forEach((brand) => {
          if (information.offerId === brand.offerId) {
            brandInfos.push({
              ...information,
              pic: brand.pic,
              name: brand.name,
            });
          }
        });
        clicksManager
          .getClicksPerBrand(
            startPeriodDate.getTime(),
            endPeriodDate.getTime(),
            information.offerId,
            startPeriodDate.getTimezoneOffset()
          )
          .then((result) => {
            keyInfluencers.map((influencer) => {
              for (const [key, value] of Object.entries(
                result.data.influencersClicks
              )) {
                if (key === influencer) {
                  sum += value;
                }
              }
              brandInfos[
                brandInfos.map((e) => e.offerId).indexOf(information.offerId)
              ]["clicks"] = sum;
              setSortEvent((s) => s + 1);
              return null;
            });
            sum = 0;
          });
      });
    }
    setBrandInfos(brandInfos);
    //return brandInfos;
  }, [
    brandInformation,
    brand,
    keyInfluencers,
    brandInformationLoading,
    offerIdBrandLoading,
    startPeriodDate,
    endPeriodDate,
  ]);

  /*useEffect(() => {
    if (influencerAgent) {
      setUser(influencerAgent);
    }
  }, [influencerAgent]);*/

  //récupérer Agent Information
  useEffect(() => {
    if (loadedInfluencerAgents) {
      let agentToWorkOn = allInfluencerAgents.filter(element => element.userId === influencerAgent.userId);
      setUser(...agentToWorkOn);
    }
  }, [loadedInfluencerAgents, allInfluencerAgents, influencerAgent]);

  useEffect(() => {
    if (loadedInfluencers && loadedAllConversions) {
      setLoading(true);

      filter().then(() => {
        setLoading(false);
      });
    }
  }, [loadedInfluencers, loadedAllConversions, filter]);

  // influencer to render
  useEffect(() => {
    const filter = influencerInfos.filter(
      (element) =>
        (element.clicks !== undefined && element.clicks !== 0) ||
        element.amount !== 0
    );

    setPeriodInfluencers(filter);
  }, [influencerInfos, sortEvent]);


  //Brand To Render
  useEffect(() => {
    brandToRenderCallBack().then(() => {
      setBrandToRenderLoading(false);
    });
  }, [brandToRenderCallBack]);

  useEffect(() => {
    setBrandToRender(brandInfos);
  }, [brandInfos, sortEvent]);

  useEffect(() => {
    filterProduct();
  }, [filterProduct])

  useEffect(() => {
    let amount = 0;
    let salesNumber = 0;
    let unpaidCommissions = 0;
    let commissionSMI = 0;
    let click = 0;
    if (periodInfluencers.length !== 0) {
      periodInfluencers.map((influencer) => {
        amount += influencer.amount;
        salesNumber += influencer.salesNumber;
        unpaidCommissions += influencer.commissions;
        commissionSMI += influencer.commissionSMI;
        click += influencer.clicks;
        return null;
      });
    }
    setClickPerInfluencer(click);
    setSalesPeriod(amount);
    setPeriodCommissionSMI(commissionSMI);
    setSalesNumberPeriod(salesNumber);
    setPaidCommissionPeriod(0);
    setUnpaidCommissionsPeriod(unpaidCommissions);
  }, [periodInfluencers, sortEvent]);

  /**
   *
   * Change Filter
   */
  const changeFilter = (filter) => {
    if (filter === "influencers") {
      setActiveFilter("influencers");
    } else if (filter === "brand") {
      setActiveFilter("brand");
    }
  };

  const openPopup = (popupType, data) => {
    setPopupMode(true);
    setPopupType(popupType);
    setPopupData(data);
  };

  const closePopup = () => {
    setPopupMode(false);
  };

  //Effect for handling period change
  useEffect(() => {
    switch (periodSelector) {
      case "today":
        setStartPeriodDateClick(new MyDate().dayStart());
        setEndPeriodDateClick(new MyDate().dayEnd());
        break;
      case "week":
        {
          let prevMonday = getPreviousMonday().dayStart();
          let endOfWeek = getPreviousMonday().addWeeks(1).addDays(-1).dayEnd();
          setStartPeriodDateClick(prevMonday);
          setEndPeriodDateClick(endOfWeek);
        }
        break;
      case "month":
        {
          let startDate = new MyDate();
          startDate.setDate(1);
          startDate = startDate.dayStart();
          let endDate = new MyDate().addMonths(1);
          endDate.setDate(1);
          endDate = endDate.addDays(-1).dayEnd();
          setStartPeriodDateClick(startDate);
          setEndPeriodDateClick(endDate);
        }
        break;
      case "lastWeek":
        {
          let monday = getPreviousMonday().addWeeks(-1).dayStart();
          let endOfWeek = getPreviousMonday().addDays(-1).dayEnd();
          setStartPeriodDateClick(monday);
          setEndPeriodDateClick(endOfWeek);
        }
        break;
      case "lastMonth":
        {
          let startDate = new MyDate();
          startDate.setDate(1);
          startDate = startDate.addMonths(-1).dayStart();
          let endDate = new MyDate();
          endDate.setDate(1);
          endDate = endDate.addDays(-1).dayEnd();
          setStartPeriodDateClick(startDate);
          setEndPeriodDateClick(endDate);
        }
        break;
      case "customMode":
        break;
      default:
        break;
    }
  }, [periodSelector]);
  const handleEndPeriodChange = (value) => {
    setEndPeriodDateClick(value);
  };
  const handleStartPeriodChange = (value) => {
    setStartPeriodDateClick(value);
  };
  return (
    <Grid container justify="center" style={{ marginTop: "20px" }}>
      {!popupMode ? (
        <>
          <Grid
            container
            item
            direction="row"
            wrap="wrap"
            lg={11}
            justify={mdDown ? "center" : "flex-end"}
            alignItems="center"
            className={classes.rowSpacing}
          >
            <Typography className={classes.titles}>
              {t("INFLUENCER_AGENT.VIEWS.STATISTICS.PERIOD")}:{" "}
            </Typography>
            <Select
              value={periodSelector}
              onChange={handlePeriodSelector}
              style={{ margin: 10 }}
              input={<BootstrapInput />}
              IconComponent={() => <SVGIcon name="miniArrow" />}
              classes={{ root: classes.selectClass }}
            >
              <MenuItem value="month" style={{ fontFamily: "Poppins" }}>
                {t(
                  "INFLUENCER_AGENT.VIEWS.STATISTICS.PERIOD_SELECTOR.CURRENT_MONTH"
                )}
              </MenuItem>
              <MenuItem value="week" style={{ fontFamily: "Poppins" }}>
                {t(
                  "INFLUENCER_AGENT.VIEWS.STATISTICS.PERIOD_SELECTOR.CURRENT_WEEK"
                )}
              </MenuItem>
              <MenuItem value="today" style={{ fontFamily: "Poppins" }}>
                {t("INFLUENCER_AGENT.VIEWS.STATISTICS.PERIOD_SELECTOR.TODAY")}
              </MenuItem>
              <MenuItem value="lastWeek" style={{ fontFamily: "Poppins" }}>
                {t(
                  "INFLUENCER_AGENT.VIEWS.STATISTICS.PERIOD_SELECTOR.PREVIOUS_WEEK"
                )}
              </MenuItem>
              <MenuItem value="lastMonth" style={{ fontFamily: "Poppins" }}>
                {t(
                  "INFLUENCER_AGENT.VIEWS.STATISTICS.PERIOD_SELECTOR.PREVIOUS_MONTH"
                )}
              </MenuItem>
              <MenuItem value="customMode" style={{ fontFamily: "Poppins" }}>
                {t(
                  "INFLUENCER_AGENT.VIEWS.STATISTICS.PERIOD_SELECTOR.CUSTOMIZED"
                )}
              </MenuItem>
            </Select>
            <div
              style={{
                display: "flex",
                flexDirection: xsDown ? "column" : "row",
                alignItems: xsDown ? "center" : "center",
                flexWrap: "wrap",
                justifyContent: xsDown ? "center" : "flex-start",
              }}
            >
              <MuiPickersUtilsProvider utils={DateFnsUtils}>
                <DatePicker
                  format="dd-MM-yyyy"
                  views={["year", "month", "date"]}
                  value={startPeriodDateClick}
                  onChange={handleStartPeriodChange}
                  onOpen={() => {
                    setPeriodSelector("customMode");
                  }}
                  TextFieldComponent={(props) => (
                    <TextField
                      {...props}
                      InputProps={{
                        classes: inputClasses,
                        disableUnderline: true,
                      }}
                    />
                  )}
                />
              </MuiPickersUtilsProvider>
              {!xsDown ? (
                <ArrowRightAltIcon fontSize="large" />
              ) : (
                <ArrowDownwardIcon fontSize="large" />
              )}

              <MuiPickersUtilsProvider utils={DateFnsUtils}>
                <DatePicker
                  format="dd-MM-yyyy"
                  views={["year", "month", "date"]}
                  value={endPeriodDateClick}
                  onChange={handleEndPeriodChange}
                  onOpen={() => setPeriodSelector("customMode")}
                  TextFieldComponent={(props) => (
                    <TextField
                      {...props}
                      InputProps={{
                        classes: inputClasses,
                        disableUnderline: true,
                      }}
                    />
                  )}
                />
              </MuiPickersUtilsProvider>
              <Button
                className={classes.buttonDateStyle}
                onClick={() => {
                  setStartPeriodDate(startPeriodDateClick);
                  setEndPeriodDate(endPeriodDateClick);
                }}
              >
                <SearchIcon style={{ color: "#fff", height: "20px" }} />
                <Typography className={classes.textButtonDate}>
                  {t("INFLUENCER_AGENT.VIEWS.STATISTICS.BUTTON_DATE")}
                </Typography>
              </Button>
            </div>
          </Grid>
          <Grid
            container
            item
            direction="row"
            xs={11}
            className={classes.rowSpacing}
          >
            <Grid container item direction="row" wrap="wrap" justify="center">
              <Box className={classes.smallBox}>
                <Grid container direction="row">
                  <Grid container item xs={6} justify="center">
                    <EuroSymbolIcon
                      style={{ fontSize: "60px", color: "#ef7e3e" }}
                    />
                  </Grid>
                  <Grid
                    container
                    item
                    xs={6}
                    direction="column"
                    justify="center"
                  >
                    <Typography className={classes.smallBoxTitles}>
                      {t(
                        "INFLUENCER_AGENT.VIEWS.STATISTICS.TURNOVER_BOX_TITLE"
                      )}
                    </Typography>
                    <Grid container item direction="row">
                      <CountUp
                        className={classes.smallBoxInfos}
                        start={0}
                        end={Math.round(salesPeriod * 100) / 100}
                        decimals={2}
                        decimal=","
                      />
                      <span className={classes.smallBoxInfos}> €</span>
                    </Grid>
                  </Grid>
                </Grid>
              </Box>
              <Box className={classes.smallBox}>
                <Grid container direction="row">
                  <Grid container item xs={6} justify="center">
                    <SVGIcon name="ventes" width={"60px"} height={"60px"} />
                  </Grid>
                  <Grid
                    container
                    item
                    xs={6}
                    direction="column"
                    justify="center"
                  >
                    <Typography className={classes.smallBoxTitles}>
                      {t("INFLUENCER_AGENT.VIEWS.STATISTICS.SALES_BOX_TITLE")}
                    </Typography>
                    <Grid item>
                      <CountUp
                        className={classes.smallBoxInfos}
                        start={0}
                        end={salesNumberPeriod}
                      />
                    </Grid>
                  </Grid>
                </Grid>
              </Box>

              <Box className={classes.smallBox}>
                <Grid container direction="row">
                  <Grid container item xs={6} justify="center">
                    <TouchAppIcon
                      style={{ fontSize: "60px", color: "#3568eb" }}
                    />
                  </Grid>
                  <Grid
                    container
                    item
                    xs={6}
                    direction="column"
                    justify="center"
                  >
                    <Typography className={classes.smallBoxTitles}>
                      {t("INFLUENCER_AGENT.VIEWS.STATISTICS.CLICKS_BOX_TITLE")}
                    </Typography>
                    <Grid item>
                      <CountUp
                        className={classes.smallBoxInfos}
                        start={0}
                        end={clickPerInfluencer}
                      />
                    </Grid>
                  </Grid>
                </Grid>
              </Box>

              <Box className={classes.smallBox}>
                <Grid container direction="row">
                  <Grid container item xs={6} justify="center">
                    <SVGIcon
                      name="commissionPaye"
                      width={"60px"}
                      height={"60px"}
                    />
                  </Grid>
                  <Grid
                    container
                    item
                    xs={6}
                    direction="column"
                    justify="center"
                  >
                    <Typography className={classes.smallBoxTitles}>
                      {t(
                        "INFLUENCER_AGENT.VIEWS.STATISTICS.PAID_COMMISSIONS_BOX_TITLE"
                      )}
                    </Typography>
                    <Grid item>
                      <CountUp
                        className={classes.smallBoxInfos}
                        start={0}
                        end={paidCommissionPeriod}
                      />
                      <span className={classes.smallBoxInfos}> €</span>
                    </Grid>
                  </Grid>
                </Grid>
              </Box>
              <Box className={classes.smallBox}>
                <Grid container direction="row">
                  <Grid container item xs={6} justify="center">
                    <SVGIcon name="commission" width={"60px"} height={"60px"} />
                  </Grid>
                  <Grid
                    container
                    item
                    xs={6}
                    direction="column"
                    justify="center"
                  >
                    <Typography className={classes.smallBoxTitles}>
                      {t(
                        "INFLUENCER_AGENT.VIEWS.STATISTICS.UNPAID_COMMISSIONS_BOX_TITLE"
                      )}
                    </Typography>
                    <Grid item>
                      <CountUp
                        className={classes.smallBoxInfos}
                        start={0}
                        end={Math.round(unPaidCommissionsPeriod * 100) / 100}
                        decimals={2}
                        decimal=","
                      />
                      <span className={classes.smallBoxInfos}> €</span>
                    </Grid>
                  </Grid>
                </Grid>
              </Box>
              <Box className={classes.smallBox}>
                <Grid container direction="row">
                  <Grid container item xs={6} justify="center">
                    <SVGIcon name="commission" width={"60px"} height={"60px"} />
                  </Grid>
                  <Grid
                    container
                    item
                    xs={6}
                    direction="column"
                    justify="center"
                  >
                    <Typography className={classes.smallBoxTitles}>
                      Commissions SMI
                    </Typography>
                    <Grid item>
                      <CountUp
                        className={classes.smallBoxInfos}
                        start={0}
                        end={Math.round(periodCommissionSMI * 100) / 100}
                        decimals={2}
                        decimal=","
                      />
                      <span className={classes.smallBoxInfos}> €</span>
                    </Grid>
                  </Grid>
                </Grid>
              </Box>
            </Grid>
          </Grid>
          <Grid
            container
            direction="row"
            item
            xs={11}
            justify="center"
            alignItems="center"
            style={{ marginBottom: "15px" }}
          >
            <Grid
              container
              direction="row"
              item
              md={7}
              sm={12}
              justify="center"
            >
              <Grid
                container
                justify="center"
                style={{ marginTop: "10px", marginBottom: "10px" }}
              >
                <Typography className={classes.titles}>
                  {t("TREND.VIEWS.STATISTICS.SALES_STATISTICS_BOX_TITLE")}
                </Typography>
              </Grid>
              <Box className={classes.pieBox}>
                {loading ? (
                  <TrendLoading />
                ) : periodProducts.length !== 0 ? (
                  <ConversionsChart
                    {...{ customDurationConversions: conversionForChart }}
                  />
                ) : (
                  <Grid
                    container
                    direction="column"
                    justify="space-around"
                    alignItems="center"
                    style={{ height: "50%" }}
                  >
                    <img
                      alt="No sales"
                      src={placeholderSales}
                      height="75px"
                      width="75px"
                    />
                    <Typography className={classes.textPlaceholder}>
                      {t(
                        "TREND.VIEWS.STATISTICS.NO_PERIOD_GENERATED_SALES_PLACEHOLDER"
                      )}
                    </Typography>
                  </Grid>
                )}
              </Box>
            </Grid>
            <Grid
              container
              direction="row"
              item
              md={5}
              sm={12}
              justify="center"
            >
              <Grid
                container
                justify="center"
                style={{ marginTop: "10px", marginBottom: "10px" }}
              >
                <Typography className={classes.titles}>
                  {t(
                    "TREND.VIEWS.STATISTICS.SALES_PER_PRODUCT_NUMBER_BOX_TITLE"
                  )}
                </Typography>
              </Grid>
              <Box className={classes.pieBox}>
                {loading ? (
                  <TrendLoading />
                ) : periodProducts.length !== 0 ? (
                  <ProductPieChart
                    {...{
                      articlesInfos: periodProducts,
                      startPeriodDate,
                      endPeriodDate,
                    }}
                  />
                ) : (
                  <Grid
                    container
                    direction="column"
                    justify="space-around"
                    alignItems="center"
                    style={{ height: "50%" }}
                  >
                    <img
                      alt="No sales"
                      src={placeholderSales}
                      height="75px"
                      width="75px"
                    />
                    <Typography className={classes.textPlaceholder}>
                      {t(
                        "TREND.VIEWS.STATISTICS.NO_PERIOD_GENERATED_SALES_PLACEHOLDER"
                      )}
                    </Typography>
                  </Grid>
                )}
              </Box>
            </Grid>
          </Grid>
          {periodInfluencers.length !== 0 ? (
            <Grid
              container
              item
              direction="row"
              xs={11}
              className={classes.rowSpacing}
            >
              <Grid container item xs={12} justify="center">
                <Grid container direction="column">
                  <Typography className={classes.titles} style={{ margin: 5 }}>
                    {t(
                      "INFLUENCER_AGENT.VIEWS.STATISTICS.STATISTICS_FILTER_TITLE"
                    )}
                    :
                  </Typography>
                  <Grid container direction="row" wrap="wrap">
                    <Button
                      className={
                        activeFilter === "influencers"
                          ? classes.activeButtonStyle
                          : classes.buttonStyle
                      }
                      onClick={() => changeFilter("influencers")}
                      disableRipple
                    >
                      {t(
                        "INFLUENCER_AGENT.VIEWS.STATISTICS.FILTER_INFLUENCER_TITLE"
                      )}
                    </Button>
                    <Button
                      className={
                        activeFilter === "brand"
                          ? classes.activeButtonStyle
                          : classes.buttonStyle
                      }
                      onClick={() => changeFilter("brand")}
                      disableRipple
                    >
                      {t(
                        "INFLUENCER_AGENT.VIEWS.STATISTICS.FILTER_BRAND_TITLE"
                      )}
                    </Button>
                  </Grid>
                </Grid>
              </Grid>

              {loading ? (
                <Grid container justify="center">
                  <CircularProgress color="secondary" />
                </Grid>
              ) : activeFilter === "influencers" ? (
                <InfluencersTable
                  influencerToRender={periodInfluencers}
                  openPopup={(data) => openPopup("influencer", data)}
                />
              ) : (
                <BrandTable
                  brandToRender={brandToRender}
                  brandToRenderLoading={brandToRenderLoading}
                  openPopup={(data) => openPopup("brand", data)}
                />
              )}
            </Grid>
          ) : (
            <></>
          )}
        </>
      ) : popupType === "influencer" ? (
        <InfluencerModal
          close={() => closePopup()}
          {...{
            data: popupData,
            periodBrandConversions,
            brand,
          }}
        />
      ) : (
        <BrandModal
          close={() => closePopup()}
          {...{
            data: popupData,
            periodBrandConversions,
            keyInfluencers,
          }}
        />
      )}
    </Grid>
  );
};
const mapStateToProps = (state, ownProps) => {
  const influencers = state.influencersState.influencers;
  const loadedInfluencers = state.influencersState.loadedInfluencers;
  const allConversions = state.conversionsState.allConversions;
  const loadedAllConversions = state.conversionsState.loadedAllConversions;
  const brand = state.brandsState.allBrands;
  const loadedInfluencerAgents = state.influencerAgentsState.loadedInfluencerAgents;
  const allInfluencerAgents = state.influencerAgentsState.influencerAgents;
  return {
    influencers,
    allConversions,
    loadedInfluencers,
    loadedAllConversions,
    brand,
    allInfluencerAgents,
    loadedInfluencerAgents
  };
};
export default connect(mapStateToProps, null)(Statistics);
