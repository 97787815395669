const	SceneEvents = (scenePtr) => {
	return {
		mouseDown: (event) => {
			const	pageX = event.changedTouches ? event.changedTouches[0].pageX : event.pageX,
					pageY = event.changedTouches ? event.changedTouches[0].pageY : event.pageY,
					{ distance, position, mousePosition } = scenePtr.vectors

			// event.preventDefault()
			distance.set(0, 0)
			position.set(pageX, pageY)
			mousePosition.set(pageX, pageY)
			scenePtr.clicked = true
			scenePtr.dragged = false
		},
		mouseMove: (event) => {
			const	pageX = event.changedTouches ? event.changedTouches[0].pageX : event.pageX,
					pageY = event.changedTouches ? event.changedTouches[0].pageY : event.pageY,
					{ distance, position, mousePosition } = scenePtr.vectors

			distance.set(pageX - position.x, pageY - position.y)
			mousePosition.set(pageX, pageY)
			scenePtr.dragged = true

			if (distance.y)
				event.preventDefault()
		},
		mouseUp: (event) => {
			scenePtr.clicked = false
			event.preventDefault()
		}
	}

}

export default	SceneEvents