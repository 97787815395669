import React, {
  useState,
  useEffect,
} from "react";
import PropTypes from "prop-types";
import clsx from "clsx";
// import { useParams } from "react-router-dom";
/* Framework */
import {
  lighten,
  makeStyles,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TablePagination,
  TableRow,
  TableSortLabel,
  Toolbar,
  Typography,
  IconButton,
  Tooltip,
} from "@material-ui/core";
/* Icons */
import DeleteIcon from "@material-ui/icons/Delete";
import FilterListIcon from "@material-ui/icons/FilterList";
/* App Scope */
import { database } from "../../../managers";

function createData(email, date) {
  return { email, date };
}

function desc(a, b, orderBy) {
  if (b[orderBy] < a[orderBy]) {
    return -1;
  }
  if (b[orderBy] > a[orderBy]) {
    return 1;
  }
  return 0;
}

function stableSort(array, cmp) {
  const stabilizedThis = array.map(
    (el, index) => [el, index]
  );
  stabilizedThis.sort((a, b) => {
    const order = cmp(a[0], b[0]);
    if (order !== 0) return order;
    return a[1] - b[1];
  });
  return stabilizedThis.map((el) => el[0]);
}

function getSorting(order, orderBy) {
  return order === "desc"
    ? (a, b) => desc(a, b, orderBy)
    : (a, b) => -desc(a, b, orderBy);
}

const headCells = [
  {
    id: "email",
    numeric: false,
    disablePadding: true,
    label: "E-Mail",
  },
  {
    id: "date",
    numeric: false,
    disablePadding: true,
    label: "Date d'inscription",
  },
];

function EnhancedTableHead(props) {
  const {
    classes,
    order,
    orderBy,
    onRequestSort,
  } = props;
  const createSortHandler = (property) => (
    event
  ) => {
    onRequestSort(event, property);
  };

  return (
    <TableHead>
      <TableRow>
        {headCells.map((headCell) => (
          <TableCell
            key={headCell.id}
            align={
              headCell.numeric ? "right" : "left"
            }
            padding={
              headCell.disablePadding
                ? "none"
                : "default"
            }
            sortDirection={
              orderBy === headCell.id
                ? order
                : false
            }
          >
            <TableSortLabel
              active={orderBy === headCell.id}
              direction={
                orderBy === headCell.id
                  ? order
                  : "asc"
              }
              onClick={createSortHandler(
                headCell.id
              )}
            >
              {headCell.label}
              {orderBy === headCell.id ? (
                <span
                  className={
                    classes.visuallyHidden
                  }
                >
                  {order === "desc"
                    ? "sorted descending"
                    : "sorted ascending"}
                </span>
              ) : null}
            </TableSortLabel>
          </TableCell>
        ))}
      </TableRow>
    </TableHead>
  );
}

EnhancedTableHead.propTypes = {
  classes: PropTypes.object.isRequired,
  numSelected: PropTypes.number.isRequired,
  onRequestSort: PropTypes.func.isRequired,
  order: PropTypes.oneOf(["asc", "desc"])
    .isRequired,
  orderBy: PropTypes.string.isRequired,
  rowCount: PropTypes.number.isRequired,
};

const useToolbarStyles = makeStyles((theme) => ({
  root: {
    paddingLeft: theme.spacing(2),
    paddingRight: theme.spacing(1),
  },
  highlight:
    theme.palette.type === "light"
      ? {
          color: theme.palette.secondary.main,
          backgroundColor: lighten(
            theme.palette.secondary.light,
            0.85
          ),
        }
      : {
          color: theme.palette.text.primary,
          backgroundColor:
            theme.palette.secondary.dark,
        },
  title: {
    flex: "1 1 100%",
  },
}));

const EnhancedTableToolbar = (props) => {
  const classes = useToolbarStyles();
  const { numSelected } = props;

  return (
    <Toolbar
      className={clsx(classes.root, {
        [classes.highlight]: numSelected > 0,
      })}
    >
      {numSelected > 0 ? (
        <Typography
          className={classes.title}
          color='inherit'
          variant='subtitle1'
        >
          {numSelected} selected
        </Typography>
      ) : (
        <Typography
          className={classes.title}
          variant='h6'
          id='tableTitle'
        >
          Emails inscrits
        </Typography>
      )}

      {numSelected > 0 ? (
        <Tooltip title='Delete'>
          <IconButton aria-label='delete'>
            <DeleteIcon />
          </IconButton>
        </Tooltip>
      ) : (
        <Tooltip title='Filter list'>
          <IconButton aria-label='filter list'>
            <FilterListIcon />
          </IconButton>
        </Tooltip>
      )}
    </Toolbar>
  );
};

EnhancedTableToolbar.propTypes = {
  numSelected: PropTypes.number.isRequired,
};

const useStyles = makeStyles((theme) => ({
  root: {
    width: "100%",
  },
  paper: {
    width: "100%",
    marginBottom: theme.spacing(2),
  },
  table: {
    minWidth: 750,
  },
  visuallyHidden: {
    border: 0,
    clip: "rect(0 0 0 0)",
    height: 1,
    margin: -1,
    overflow: "hidden",
    padding: 0,
    position: "absolute",
    top: 20,
    width: 1,
  },
}));

export default function EmailsList() {
  const [order, setOrder] = useState("desc");
  const [orderBy, setOrderBy] = useState(
    "creation"
  );
  const [selected, setSelected] = useState([]);
  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(
    25
  );
  const [rows, setRows] = useState([]);
  const [emails, setEmails] = useState([]);

  // const { brandUrl } = useParams();
  const classes = useStyles();

  useEffect(() => {
    database
      .ref("eshop/burgbad/emails")
      .once("value")
      .then((snap) => {
        const data = snap.val();
        data
          ? setEmails(
              Object.values(data).map(
                (email, i) => ({
                  ...email,
                  key: Object.keys(data)[i],
                })
              )
            )
          : setEmails([]);
      });
  }, []);

  useEffect(() => {
    emails &&
      setRows(
        emails.map((email) => {
          return createData(
            email.email,
            email.date
          );
        })
      );
  }, [emails]);

  const handleRequestSort = (event, property) => {
    const isAsc =
      orderBy === property && order === "asc";
    setOrder(isAsc ? "desc" : "asc");
    setOrderBy(property);
  };

  const handleClick = (event, name) => {
    const selectedIndex = selected.indexOf(name);
    let newSelected = [];

    if (selectedIndex === -1) {
      newSelected = newSelected.concat(
        selected,
        name
      );
    } else if (selectedIndex === 0) {
      newSelected = newSelected.concat(
        selected.slice(1)
      );
    } else if (
      selectedIndex ===
      selected.length - 1
    ) {
      newSelected = newSelected.concat(
        selected.slice(0, -1)
      );
    } else if (selectedIndex > 0) {
      newSelected = newSelected.concat(
        selected.slice(0, selectedIndex),
        selected.slice(selectedIndex + 1)
      );
    }

    setSelected(newSelected);
  };

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(
      parseInt(event.target.value, 10)
    );
    setPage(0);
  };

  const isSelected = (name) =>
    selected.indexOf(name) !== -1;

  const emptyRows =
    rowsPerPage -
    Math.min(
      rowsPerPage,
      rows.length - page * rowsPerPage
    );

  return (
    <>
      <EnhancedTableToolbar
        numSelected={selected.length}
      />
      <TableContainer>
        <Table
          className={classes.table}
          aria-labelledby='tableTitle'
          size={"small"}
          aria-label='enhanced table'
        >
          <EnhancedTableHead
            classes={classes}
            numSelected={selected.length}
            order={order}
            orderBy={orderBy}
            onRequestSort={handleRequestSort}
            rowCount={rows.length}
          />
          <TableBody>
            {stableSort(
              rows,
              getSorting(order, orderBy)
            )
              .slice(
                page * rowsPerPage,
                page * rowsPerPage + rowsPerPage
              )
              .map((row, index) => {
                const isItemSelected = isSelected(
                  row.name
                );
                return (
                  <TableRow
                    hover
                    onClick={(event) =>
                      handleClick(event, row.name)
                    }
                    role='checkbox'
                    aria-checked={isItemSelected}
                    tabIndex={-1}
                    key={row.date}
                    selected={isItemSelected}
                  >
                    {/* <TableCell padding='checkbox'>
                      <Checkbox
                        checked={isItemSelected}
                        inputProps={{ "aria-labelledby": labelId }}
                      />
                    </TableCell> */}
                    <TableCell>
                      {row.email}
                    </TableCell>
                    <TableCell>
                      {row.date &&
                        new Date(row.date)
                          .toISOString()
                          .split("T")[0]}
                    </TableCell>
                  </TableRow>
                );
              })}
            {emptyRows > 0 && (
              <TableRow
                style={{ height: 33 * emptyRows }}
              >
                <TableCell colSpan={6} />
              </TableRow>
            )}
          </TableBody>
        </Table>
      </TableContainer>
      <TablePagination
        rowsPerPageOptions={[10, 25, 50, 100]}
        component='div'
        count={rows.length}
        rowsPerPage={rowsPerPage}
        page={page}
        onChangePage={handleChangePage}
        onChangeRowsPerPage={
          handleChangeRowsPerPage
        }
      />
    </>
  );
}
