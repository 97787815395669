import { dbManager } from "../../components/managers";

export const fetchShortlinksPerInfluencer = (influencerAffiliateId) => {
    return (dispatch, getState) => {
        dispatch({ type: 'FETCH_SHORTLINKS_PER_INFLUENCER_REQUEST' });
        const { shortlinksState } = getState();
        const shortlinksPerInfluencer = shortlinksState.shortlinksPerInfluencer[influencerAffiliateId];
        if (shortlinksPerInfluencer) {
            dispatch({ type: 'FETCH_SHORTLINKS_PER_INFLUENCER_SUCCESS', payload: { influencerAffiliateId, shortlinks: shortlinksPerInfluencer } });
        } else {
            dbManager.fetchShortlinksPerInfluencer(influencerAffiliateId).then(shortlinks => {
                dispatch({ type: 'FETCH_SHORTLINKS_PER_INFLUENCER_SUCCESS', payload: { influencerAffiliateId, shortlinks } });
            }, error => {
                dispatch({ type: 'FETCH_SHORTLINKS_PER_INFLUENCER_ERROR', error });
            });
        }
    };
};

export const addShortlink = (shortlink, influencerAffiliateId) => {
    return (dispatch, getState) => {
        dispatch({ type: 'ADD_SHORTLINK_REQUEST' });
        dbManager.addShortlink(shortlink).then(added_shortlink => {
            dispatch({ type: 'ADD_SHORTLINK_SUCCESS', payload: { shortlink: added_shortlink, influencerAffiliateId } });
        }, error => {
            dispatch({ type: 'ADD_SHORTLINK_ERROR', error });
        })
    }
};

export const addMultipleShortLinks = (shortlinks, influencerAffiliateId) => {
    return (dispatch, getState) => {
        dispatch({ type: 'ADD_MULTIPLE_SHORTLINKS_REQUEST' });
        dbManager.addMultipleShortlinks(shortlinks).then(shortlinks => {
            dispatch({ type: 'ADD_MULTIPLE_SHORTLINKS_SUCCESS', payload: { shortlinks, influencerAffiliateId } });
        }, errorObject => {
            dispatch({ type: 'ADD_MULTIPLE_SHORTLINKS_ERROR', errorObject})
        })
    }
}