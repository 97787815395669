const initState = {
  allBrands: [],
  brandsToValidate: [],
  suggestedBrands: [],
  brandsError: null,
  loadedBrands: false,
  loadedBrandsToValidate: false,
  loadedSuggestedBrands: false,
  loadingUpdateBrandToValidate: false,
  loadingValidateBrand: false,
  loadingRefuseBrand: false,
  loadingUpdateBrand: false,
  loadingUpdateInfluencersClaimsBrands: false,
  loadingDeleteSuggestedBrand: false,
  loadingAddNewBrand: false,
};

const brandsReducer = (state = initState, action) => {
  switch (action.type) {
    case "FETCH_ALL_BRANDS_SUCCESS":
      return {
        ...state,
        allBrands: action.brands,
        loadedBrands: true,
      };
    case "FETCH_BRANDS_ERROR":
      return {
        ...state,
        brandsError: action.error,
      };
    case "FETCH_ALL_BRANDS_TO_VALIDATE_SUCCESS":
      return {
        ...state,
        brandsToValidate: action.payload.brandsToValidate,
        loadedBrandsToValidate: true,
      };
    case "FETCH_ALL_BRANDS_TO_VALIDATE_ERROR":
      return {
        ...state,
        brandsError: action.error,
      };
    case "UPDATE_BRAND_BY_ID_REQUEST":
      return {
        ...state,
        loadingUpdateBrand: true,
      };
    case "UPDATE_BRAND_BY_ID_SUCCESS": {
      let newBrands = state.allBrands.map((brand) => {
        if (brand.key === action.payload.id) {
          let updatedBrand = {
            ...brand,
          };
          Object.entries(action.payload.updates).forEach(
            ([keyToUpdate, newValue]) => {
              if (keyToUpdate.indexOf("/") !== -1) {
                let subObjectToUpdate = keyToUpdate.split("/");
                if (updatedBrand[subObjectToUpdate[0]] === undefined)
                  updatedBrand[subObjectToUpdate[0]] = {};
                updatedBrand[subObjectToUpdate[0]][
                  subObjectToUpdate[1]
                ] = newValue;
              } else {
                updatedBrand[keyToUpdate] = newValue;
              }
            }
          );
          return updatedBrand;
        } else {
          return brand;
        }
      });
      return {
        ...state,
        allBrands: newBrands,
        loadingUpdateBrand: false,
      };
    };
    case "UPDATE_BRAND_BY_ID_ERROR":
      return {
        ...state,
        brandsError: action.error,
        loadingUpdateBrand: false,
      };
    case "FETCH_ALL_SUGGESTED_BRANDS_SUCCESS":
      return {
        ...state,
        suggestedBrands: action.payload.suggestedBrands,
        loadedSuggestedBrands: true,
      };
    case "FETCH_ALL_SUGGESTED_BRANDS_ERROR":
      return {
        ...state,
        brandsError: action.error,
      };
    case "UPDATE_BRAND_TO_VALIDATE_BY_ID_REQUEST":
      return {
        ...state,
        loadingUpdateBrandToValidate: true,
      };
    case "UPDATE_BRAND_TO_VALIDATE_BY_ID_SUCCESS": {
      let newBrands = state.brandsToValidate.map((brand) => {
        if (brand.key === action.payload.id) {
          let updatedBrand = {
            ...brand,
          };
          Object.entries(action.payload.updates).forEach(
            ([keyToUpdate, newValue]) => {
              if (keyToUpdate.indexOf("/") !== -1) {
                let subObjectToUpdate = keyToUpdate.split("/");
                if (updatedBrand[subObjectToUpdate[0]] === undefined)
                  updatedBrand[subObjectToUpdate[0]] = {};
                updatedBrand[subObjectToUpdate[0]][
                  subObjectToUpdate[1]
                ] = newValue;
              } else {
                updatedBrand[keyToUpdate] = newValue;
              }
            }
          );
          return updatedBrand;
        } else {
          return brand;
        }
      });
      return {
        ...state,
        brandsToValidate: newBrands,
        loadingUpdateBrandToValidate: false,
      };
    };
    case "UPDATE_BRAND_TO_VALIDATE_BY_ID_ERROR":
      return {
        ...state,
        brandsError: action.error,
        loadingUpdateBrandToValidate: false,
      };
    case "VALIDATE_BRAND_BY_ID_REQUEST":
      return {
        ...state,
        loadingValidateBrand: true,
      };
    case "VALIDATE_BRAND_BY_ID_SUCCESS": {
      let newBrands = state.allBrands;
      newBrands.push(action.payload.brand);
      let newBrandsToValidate = state.brandsToValidate;
      let indexToDelete = null;
      newBrandsToValidate.forEach((brand, index) => {
        if (brand.key === action.payload.unverifiedBrandId)
          indexToDelete = index;
      });
      newBrandsToValidate = newBrandsToValidate.filter(
        (item, index) => indexToDelete !== index
      );
      return {
        ...state,
        allBrands: newBrands,
        brandsToValidate: newBrandsToValidate,
        loadingValidateBrand: false,
      };
    };
    case "VALIDATE_BRAND_BY_ID_ERROR":
      return {
        ...state,
        loadingValidateBrand: false,
        brandsError: action.error,
      };
    case "REFUSE_BRAND_BY_ID_REQUEST":
      return {
        ...state,
        loadingRefuseBrand: true,
      };
    case "REFUSE_BRAND_BY_ID_SUCCESS": {
      let newBrandsToValidate = state.brandsToValidate;
      let indexToDelete = null;
      newBrandsToValidate.forEach((brand, index) => {
        if (brand.key === action.payload.unverifiedBrandId)
          indexToDelete = index;
      });
      newBrandsToValidate.splice(indexToDelete, 1);
      return {
        ...state,
        brandsToValidate: newBrandsToValidate,
        loadingRefuseBrand: false,
      };
    };
    case "REFUSE_BRAND_BY_ID_ERROR":
      return {
        ...state,
        loadingRefuseBrand: false,
        brandsError: action.error,
      };
    case "REMOVE_BRAND_BY_ID_REQUEST":
      return {
        ...state,
        loadingRemoveBrand: true,
      };
    case "REMOVE_BRAND_BY_ID_SUCCESS": {
      let newBrands = state.allBrands;
      let indexToDelete = null;
      newBrands.forEach((brand, index) => {
        if (brand.key === action.payload.brandId) indexToDelete = index;
      });
      newBrands = newBrands.filter((item, index) => indexToDelete !== index);
      return {
        ...state,
        allBrands: newBrands,
        loadingRemoveBrand: false,
      };
    };
    case "REMOVE_BRAND_BY_ID_ERROR":
      return {
        ...state,
        loadingRemoveBrand: false,
        brandsError: action.error,
      };
    case "UPDATE_BRANDS_CATEG_PROPERTY": {
      let newBrands = state.allBrands.map(brand => {
        if (action.payload.updatedBrandsIds.indexOf(brand.key) !== -1) {
          let previousCategories = [...brand.categ];
          let updatedBrand = {
            ...brand,
            categ: [...previousCategories]
          };
          updatedBrand.categ.splice(previousCategories.indexOf(action.payload.removedCategory.key), 1);
          return updatedBrand;
        } else {
          return brand;
        }
      });
      let newBrandsToValidate = state.brandsToValidate.map(brandToValidate => {
        if (action.payload.updatedBrandsToValidateIds.indexOf(brandToValidate.key) !== -1) {
          let previousCategories = [...brandToValidate.categorie];
          let updatedBrandToValidate = {
            ...brandToValidate,
            categorie: [...previousCategories]
          };
          updatedBrandToValidate.categorie.splice(previousCategories.indexOf(action.payload.removedCategory.key, 1));
          return updatedBrandToValidate;
        } else {
          return brandToValidate;
        }
      });
      return {
        ...state,
        allBrands: newBrands,
        brandsToValidate: newBrandsToValidate
      };
    }
    case "REFUSE_SUGGESTED_BRAND_BY_ID_REQUEST":
      return {
        ...state,
        loadingDeleteSuggestedBrand: true,
      };
    case "REFUSE_SUGGESTED_BRAND_BY_ID_SUCCESS": {
      let newSuggestedBrand = state.suggestedBrands;
      let indexToDelete = null;
      newSuggestedBrand.forEach((brand, index) => {
        if (brand.key === action.payload.idSuggestedBrand)
          indexToDelete = index;
      });
      newSuggestedBrand = newSuggestedBrand.filter((item, index) => indexToDelete !== index);
      return {
        ...state,
        suggestedBrands: newSuggestedBrand,
        loadingDeleteSuggestedBrand: false,
      };
    };
    case "REFUSE_SUGGESTED_BRAND_BY_ID_ERROR":
      return {
        ...state,
        loadingDeleteSuggestedBrand: false,
        brandsError: action.error,
      };
    case "ADD_BRAND_BY_ID_REQUEST":
      return {
        ...state,
        loadingAddNewBrand: true,
      };
    case "ADD_BRAND_BY_ID_SUCCESS": {
      let newBrands = [...state.allBrands];
      newBrands.push(action.payload.brand);
      return {
        ...state,
        allBrands: newBrands,
        loadingAddNewBrand: false,
      };
    };
    case "ADD_BRAND_BY_ID_ERROR":
      return {
        ...state,
        loadingAddNewBrand: false,
        brandsError: action.error,
      };
    default:
      return state;
  }
};

export default brandsReducer;
