import { useState, useEffect } from "react";

// Relative Dependencies
import { auth } from "../managers";

export const useAuth = () => {
  const [user, setUser] = useState(auth.currentUser);
  const [loadedAuth, setLoadedAuth] = useState(false);

  useEffect(() => {
    const unsubscribe = auth.onAuthStateChanged(onChange);
    return () => unsubscribe();
  }, []);

  const onChange = userData => {
    setLoadedAuth(true);
    setUser(userData);
  };

  return { user, loadedAuth };
};
