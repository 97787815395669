import React from "react";
import { useRouteMatch, Route, Switch, Redirect } from "react-router-dom";

import { Influencer } from ".";
import { ProductsList } from "./components";

const Router = (props) => {
    const { url } = useRouteMatch();
    return (
        <>
            <Switch>
                <Route exact path={`/influencer/embed/wishlist/:affiliateId/:wishlistId`}>
                    <ProductsList embed />
                </Route>
                <Route
                    exact
                    path={[
                        `${url}/:affiliateId/:wishlistId`,
                        `${url}/:affiliateId`
                    ]}
                >
                    <Influencer {...props} />
                </Route>
                <Redirect push to='/home' />
            </Switch>
        </>
    );
};
export default Router;
