import React,{ useEffect } from "react";
import { connect } from "react-redux";
import { fetchAllBrands } from "../../store/actions/brandsActions";
import {
  Route,
  Switch,
  Redirect,
} from "react-router-dom";

// Component Scope
import {
  //Statistics,
  HowItWorks,
  Home,
  Brands,
  Contact,
  InfsGrid,
  CollectionBrand,
} from "./views";

const Router = (props) => {
  const {
    //user,
    allInfs,
    loadedBrands,
    fetchAllBrands,
    influencers
  } = props;


  useEffect(() => {
    if (!loadedBrands) fetchAllBrands();
  },[loadedBrands,fetchAllBrands]);

  return (
    <Switch>
      <Route path='/' exact>
        <Home
          welcome={ true }
          influencers={ allInfs }
        />
      </Route>
      <Route path='/home' exact>
        <Home
          influencers={ allInfs }
          welcome={ false }
        />
      </Route>
      {/*<Route path='/statistics' exact>
        <Statistics
          user={ user }
          translations={ translations }
        />
      </Route> */}
      <Route path='/how_it_works' exact>
        <HowItWorks />
      </Route>
      <Route path='/brands' exact>
        <Brands />
      </Route>
      <Route path='/contact' exact>
        <Contact />
      </Route>
      <Route path='/display/allinfluencers' exact>
        <InfsGrid influencers={ influencers } />
      </Route>
      <Route path={ "/collection/:brandName/:uid?" } exact>
        <CollectionBrand />
      </Route>
      <Route path='/display/allInfluencers' exact>
        <Redirect to='/display/allinfluencers' push />
      </Route>
      <Redirect to='/home' />
    </Switch>
  );
};

const mapStateToProps = (state) => {
  const loadedBrands = state.brandsState.loadedBrands;
  return {
    loadedBrands,
  }
}

const mapDispatchToProps = (dispatch) => {
  return {
    fetchAllBrands: () => dispatch(fetchAllBrands()),
  }
}

export default connect(mapStateToProps,mapDispatchToProps)(Router);
