import React, { useState, useEffect } from "react";
import { makeStyles } from "@material-ui/styles";
import { Grid, Button, Typography } from "@material-ui/core";
import { useTranslation } from "react-i18next";

const useStyle = makeStyles(() => ({
  registerTypeButton: {
    margin: "10px",
    height: "60px",
    width: "100%",
    fontFamily: "Poppins",
    borderRadius: "20px",
    boxShadow: "0 2px 20px 0 rgba(54, 98, 124, 0.16)",
    textTransform: "none",
  },
  activeButton: {
    margin: "10px",
    height: "60px",
    width: "100%",
    fontFamily: "Poppins",
    borderRadius: "20px",
    boxShadow: "0 2px 20px 0 rgba(54, 98, 124, 0.16)",
    backgroundColor: "#58a5d3",
    color: "white",
    "&:hover": {
      backgroundColor: "#58a5d3",
    },
    textTransform: "none",
  },
  stepTitle: {
    fontFamily: "Poppins",
    margin: "10px",
    fontSize: "18px",
  },
}));

const Stepone = (props) => {
  const { t } = useTranslation("common");

  const classes = useStyle();

  const [activeUserType, setActiveUserType] = useState(null);
  const [checkValid, setCheckValid] = useState(false);

  const validStep = props.validStep;

  const handleUserType = (type) => {
    setActiveUserType(type);
    props.activeUser(type);
  };

  useEffect(() => {
    setCheckValid(props.checkValid);
  }, [props.checkValid]);

  useEffect(() => {
    if (props.userType) setActiveUserType(props.userType);
  }, [props.userType]);

  useEffect(() => {
    if (checkValid) {
      validStep(true);
    }
  }, [checkValid, validStep]);

  return (
    <>
      <Grid container item direction="row" xs={12}>
        <Typography className={classes.stepTitle}>
          {t("REGISTER.STEP_ONE.STEP_TITLE")}
        </Typography>
      </Grid>
      <Grid container item justify="center" alignItems="center" xs={12}>
        <Grid container item justify="space-between" xs={12}>
          <Grid container item xs={12} md={6} justify="center">
            <Button
              className={
                activeUserType === "influencer"
                  ? classes.activeButton
                  : classes.registerTypeButton
              }
              disableRipple
              onClick={() => handleUserType("influencer")}
            >
              {t("REGISTER.STEP_ONE.INFLUENCER_BOX_TITLE")}
            </Button>
          </Grid>
          <Grid container item xs={12} md={6} justify="center">
            <Button
              className={
                activeUserType === "brand"
                  ? classes.activeButton
                  : classes.registerTypeButton
              }
              disableRipple
              onClick={() => handleUserType("brand")}
            >
              {t("REGISTER.STEP_ONE.BRAND_BOX_TITLE")}
            </Button>
          </Grid>
        </Grid>
        <Grid container item justify="space-between">
          <Grid container item xs={12} md={6} justify="center">
            <Button
              className={
                activeUserType === "influencerAgent"
                  ? classes.activeButton
                  : classes.registerTypeButton
              }
              disableRipple
              onClick={() => handleUserType("influencerAgent")}
            >
              {t("REGISTER.STEP_ONE.INFLUENCERS_AGENT_BOX_TITLE")}
            </Button>
          </Grid>
          <Grid container item xs={12} md={6} justify="center">
            <Button
              className={
                activeUserType === "brandAgent"
                  ? classes.activeButton
                  : classes.registerTypeButton
              }
              disableRipple
              onClick={() => handleUserType("brandAgent")}
            >
              {t("REGISTER.STEP_ONE.BRANDS_AGENT_BOX_TITLE")}
            </Button>
          </Grid>
        </Grid>
      </Grid>
    </>
  );
};

export default Stepone;
