import React, { useState, useEffect } from "react";
import {
    makeStyles,
    Grid,
    Box,
    Typography,
    IconButton,
} from "@material-ui/core";
import EuroSymbolIcon from "@material-ui/icons/EuroSymbol";
import SVGIcon from "../../assets/SVGIcons/index";
import CloseIcon from "@material-ui/icons/Close";
import TouchAppIcon from "@material-ui/icons/TouchApp";
import ExpandLessIcon from "@material-ui/icons/ExpandLess";
import ExpandMoreIcon from "@material-ui/icons/ExpandMore";
import SortIcon from "@material-ui/icons/Sort";
import { useHistory } from "react-router-dom";
import countriesDB from "../../assets/utils/countries";
import placeholder from "../../assets/img/placeholder.png";
import { useTranslation } from "react-i18next";
import TrendLoading from "../../../../ui/trendLoading/TrendLoading";
import { connect } from "react-redux";

const useStyles = makeStyles((theme) => ({
    overallBoxContainer: {
        width: "95%",
        display: "flex",
        justifyContent: "center",
        marginBottom: "30px",
        marginTop: "30px",
    },
    overallBoxStyles: {
        backgroundColor: "white",
        borderRadius: "35px",
        boxShadow: "0 7px 30px 0 rgba(88, 165, 211, 0.16)",
        width: "100%",
    },
    influencerImageBox: {
        width: "inherit",
        // height: 'inherit',
        margin: "10px",
        borderRadius: "35px",
        boxShadow: "0 7px 30px 0 rgba(88, 165, 211, 0.16)",
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
    },
    influencerInfosBox: {
        height: "200px",
    },
    smallBox: {
        boxShadow: "0 7px 30px 0 rgba(58, 111, 142, 0.05)",
        borderRadius: "41px",
        width: "300px",
        height: "150px",
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
        margin: 10,
    },
    tableRowContent: {
        fontFamily: "Poppins",
    },
    influencerEmail: {
        fontFamily: "Poppins",
        fontSize: "14px",
        fontWeight: 200,
        color: "#000000",
    },
    influencerInstagram: {
        fontFamily: "Poppins",
        fontSize: "25px",
        color: "#000000",
        textDecoration: "none",
    },
    influencerStatsTitles: {
        fontFamily: "Poppins",
        fontSize: "14px",
        fontWeight: 500,
        color: "#000000",
    },
    influencerStats: {
        fontFamily: "Poppins",
        fontSize: "16px",
        fontWeight: 900,
        color: "#000000",
    },
    spacement: {
        marginTop: "15px",
        marginBottom: "15px",
    },
    tableHeader: {
        fontFamily: "Poppins",
        fontSize: "14px",
        textAlign: "center",
        "&:hover": {
            cursor: "pointer",
        },
    },
    BrandImage: {
        objectFit: "cover",
        width: "200px",
        height: "200px",
        margin: "10px",
        borderRadius: "35px",
        boxShadow: "0 7px 30px 0 rgba(88, 165, 211, 0.16)",
    },
    influencerBoxContainer: {
        marginTop: "10px",
        marginBottom: "10px",
        //minWidth: "1050px",
        minHeight: "60px",
    },
    influencerBox: {
        display: "flex",
        width: "95%",
        backgroundColor: "white",
        height: "80px",
        borderRadius: "27px",
        boxShadow: "0 7px 30px 0 rgba(88, 165, 211, 0.16)",
    },
    brandName: {
        fontFamily: "Poppins",
        fontSize: "22px",
        fontWeight: "bold",
        color: "#000000",
        wordBreak: "break-word",
    },
    influencerName: {
        fontFamily: "Poppins",
    },
    influencerImageTable: {
        width: "100px",
        height: "100px",
        borderTopLeftRadius: "27px",
        borderBottomLeftRadius: "27px",
        objectFit: "fill",
    },
    textDetail: {
        color: "#c8c8c8",
        fontFamily: "Poppins",
        fontSize: "15px",
        "&:hover": {
            cursor: "pointer",
        },
    },
    tableHeaderIcon: {
        "&:hover": {
            cursor: "pointer",
        },
    },
    productImage: {
        height: "100px",
        objectFit: "contain",
        "&:hover": {
            cursor: "pointer",
        },
    },
    noSalesTextPlaceholder: {
        fontFamily: "Poppins",
        fontSize: "16px",
        margin: 10,
        color: "#1b4c61",
        textAlign: "center",
    },
    influencerImage: {
        width: "160px",
        height: "80px",
        borderTopLeftRadius: "27px",
        borderBottomLeftRadius: "27px",
        objectFit: "fill",
    },
}));

const BrandsStatsModal = (props) => {
    const { t, i18n } = useTranslation("common");
    const classes = useStyles();
    const history = useHistory();
    const [brand, setBrand] = useState(null);
    const [sortHelper, setSortHelper] = useState([1, 0, 0, 0, 0]);
    const [sortHelperInfluencer, setSortHelperInfluencer] = useState([0, 0, 0, 1]);
    const [sortEvent, setSortEvent] = useState(0);
    const [conversionsToRender, setConversionsToRender] = useState([]);
    const [conversionsFinalToRender, setConversionsFinalToRender] = useState([]);
    const [sortedConversions, setSortedConversions] = useState([]);
    const [influencerToRender, setInfluencerToRender] = useState([]);

    const {
        popupData,
        periodConversions,
        influencers,
        loadedInfluencers,
        url
    } = props;
    const closePopup = () => {
        props.close(true);
    };

    useEffect(() => {
        if (popupData) {
            setBrand(popupData);
        }
    }, [popupData]);

    useEffect(() => {
        if (periodConversions && brand) {
            setConversionsToRender(periodConversions.filter(item => item.offerId === brand.brandData.offerId));
        }
    }, [periodConversions, brand]);
    //get Influencers who do Clicks without sales

    useEffect(() => {
        let ArrayInfluencers = [];
        if (brand && loadedInfluencers) {
            Object.entries(brand.influencersClicks).forEach(([key, value]) => {
                ArrayInfluencers.push({ ...influencers.filter(influencer => influencer.key === key), clicks: value });
            });
        }
        console.log(ArrayInfluencers);
        setInfluencerToRender(ArrayInfluencers);
    }, [brand, influencers, loadedInfluencers])

    useEffect(() => {
        let conversionFinalToRender = [];
        if (loadedInfluencers) {
            conversionsToRender.forEach(element => {
                let name = "";
                influencers.filter(ele => {
                    if (ele.key === element.influencer) {
                        name = ele.name;
                    }
                    return null;
                });
                conversionFinalToRender.push({
                    ...element,
                    nameInfluencer: name
                })
            })
        }
        setConversionsFinalToRender(conversionFinalToRender)
    }, [loadedInfluencers, influencers, conversionsToRender]);

    useEffect(() => {
        let indexAsc = sortHelper.indexOf(1);
        let indexDesc = sortHelper.indexOf(-1);
        if (indexAsc !== -1) {
            const sortTypeAsc = (indexAsc, tableToWorkOn) => {
                switch (indexAsc) {
                    case 0:
                        tableToWorkOn.sort((a, b) => {
                            if (a.createdAt > b.createdAt) return -1;
                            if (a.createdAt < b.createdAt) return 1;
                            return 0;
                        });
                        break;
                    case 1:
                        tableToWorkOn.sort((a, b) => {
                            if (a.nameInfluencer > b.nameInfluencer) return -1;
                            if (a.nameInfluencer < b.nameInfluencer) return 1;
                            return 0;
                        });
                        break;
                    case 2:
                        tableToWorkOn.sort((a, b) => {
                            if (a.amount > b.amount) return -1;
                            if (a.amount < b.amount) return 1;
                            return 0;
                        });
                        break;
                    case 3:
                        tableToWorkOn.sort((a, b) => {
                            if (a.commission > b.commission) return -1;
                            if (a.commission < b.commission) return 1;
                            return 0;
                        });
                        break;
                    case 4:
                        tableToWorkOn.sort((a, b) => {
                            if (a.countryCode > b.countryCode) return -1;
                            if (a.countryCode < b.countryCode) return 1;
                            return 0;
                        });
                        break;
                    default:
                        break;
                }
            };
            sortTypeAsc(indexAsc, conversionsFinalToRender);
        } else {
            const sortTypeDesc = (indexDesc, tableToWorkOn) => {
                switch (indexDesc) {
                    case 0:
                        tableToWorkOn.sort((a, b) => {
                            if (a.createdAt > b.createdAt) return 1;
                            if (a.createdAt < b.createdAt) return -1;
                            return 0;
                        });
                        break;
                    case 1:
                        tableToWorkOn.sort((a, b) => {
                            if (a.nameInfluencer > b.nameInfluencer) return 1;
                            if (a.nameInfluencer < b.nameInfluencer) return -1;
                            return 0;
                        });
                        break;
                    case 2:
                        tableToWorkOn.sort((a, b) => {
                            if (a.amount > b.amount) return 1;
                            if (a.amount < b.amount) return -1;
                            return 0;
                        });
                        break;
                    case 3:
                        tableToWorkOn.sort((a, b) => {
                            if (a.commission > b.commission) return 1;
                            if (a.commission < b.commission) return -1;
                            return 0;
                        });
                        break;
                    case 4:
                        tableToWorkOn.sort((a, b) => {
                            if (a.countryCode > b.countryCode) return 1;
                            if (a.countryCode < b.countryCode) return -1;
                            return 0;
                        });
                        break;
                    default:
                        break;
                }
            };
            sortTypeDesc(indexDesc, conversionsFinalToRender);
        }
        setSortEvent((s) => s + 1);
    }, [conversionsFinalToRender, sortHelper]);
    useEffect(() => {
        setSortedConversions(conversionsFinalToRender);
    }, [sortEvent, conversionsFinalToRender]);

    useEffect(() => {
        let indexAsc = sortHelperInfluencer.indexOf(1);
        let indexDesc = sortHelperInfluencer.indexOf(-1);
        if (indexAsc !== -1) {
            const sortTypeAsc = (indexAsc, tableToWorkOn) => {
                switch (indexAsc) {
                    case 0:
                        tableToWorkOn.sort((a, b) => {
                            if (a[0].name > b[0].name) return -1;
                            if (a[0].name < b[0].name) return 1;
                            return 0;
                        });
                        break;
                    case 1:
                        tableToWorkOn.sort((a, b) => {
                            if (a[0].email > b[0].email) return -1;
                            if (a[0].email < b[0].email) return 1;
                            return 0;
                        });
                        break;
                    case 2:
                        tableToWorkOn.sort((a, b) => {
                            if (a[0].affiliate_id > b[0].affiliate_id) return -1;
                            if (a[0].affiliate_id < b[0].affiliate_id) return 1;
                            return 0;
                        });
                        break;
                    case 3:
                        tableToWorkOn.sort((a, b) => {
                            if (a.clicks > b.clicks) return -1;
                            if (a.clicks < b.clicks) return 1;
                            return 0;
                        });
                        break;
                    default:
                        break;
                }
            };
            sortTypeAsc(indexAsc, influencerToRender);
        } else {
            const sortTypeDesc = (indexDesc, tableToWorkOn) => {
                switch (indexDesc) {
                    case 0:
                        tableToWorkOn.sort((a, b) => {
                            if (a[0].name > b[0].name) return 1;
                            if (a[0].name < b[0].name) return -1;
                            return 0;
                        });
                        break;
                    case 1:
                        tableToWorkOn.sort((a, b) => {
                            if (a[0].email > b[0].email) return 1;
                            if (a[0].email < b[0].email) return -1;
                            return 0;
                        });
                        break;
                    case 2:
                        tableToWorkOn.sort((a, b) => {
                            if (a[0].affiliate_id > b[0].affiliate_id) return 1;
                            if (a[0].affiliate_id < b[0].affiliate_id) return -1;
                            return 0;
                        });
                        break;
                    case 3:
                        tableToWorkOn.sort((a, b) => {
                            if (a.clicks > b.clicks) return 1;
                            if (a.clicks < b.clicks) return -1;
                            return 0;
                        });
                        break;
                    default:
                        break;
                }
            };
            sortTypeDesc(indexDesc, influencerToRender);
        }
        setSortEvent((s) => s + 1);
    }, [influencerToRender, sortHelperInfluencer]);
    useEffect(() => {
        setSortedConversions(conversionsFinalToRender);
    }, [sortEvent, conversionsFinalToRender]);

    const handleViewInfluencer = (conversion) => {
        props.handleViewInfluencer(conversion.influencer);
        history.push(`${url}/influencers`);
    }

    return (
        <>
            {
                brand
                    ? <Grid container item direction="row" justify="center">
                        <Grid container item direction="row" className={classes.overallBoxContainer}>
                            <Box className={classes.overallBoxStyles}>
                                <Grid container direction="column" alignItems="center" justify="center" style={{ display: "flex" }}>
                                    <Grid container item direction="row-reverse" sm={12}>
                                        <IconButton onClick={closePopup} style={{ marginTop: "30px", marginRight: "30px", color: "#000" }}>
                                            <CloseIcon fontSize="large" />
                                        </IconButton>
                                    </Grid>
                                    <Grid container item direction="row" justify="center" className={classes.spacement} >
                                        <Grid container item direction="row" sm={11} justify="center">
                                            <Grid container item direction="row" justify="center" alignItems="center" wrap="wrap">
                                                <Grid container item lg={4} md={8} sm={12} justify="center" >
                                                    <img src={brand.brandData.pic} alt={brand.name} className={classes.BrandImage} />
                                                </Grid>
                                                <Grid container item lg={2} md={4} sm={12} justify="center" alignItems="center" direction="column" className={classes.influencerInfosBox}>
                                                    <Typography className={classes.brandName}>
                                                        {brand.name}
                                                    </Typography>
                                                </Grid>
                                                <Grid container item justify="center" lg={2} md={4} sm={12} >
                                                    <Box className={classes.smallBox}>
                                                        <Grid container direction="row">
                                                            <Grid container item xs={6} justify="center">
                                                                <SVGIcon name="ventes" width={"60px"} height={"60px"} />
                                                            </Grid>
                                                            <Grid container item xs={6} direction="column" justify="center" >
                                                                <Typography className={classes.influencerStatsTitles} >
                                                                    {t("ADMIN.VIEWS.STATISTICS.INFLUENCER_POPUP.SALES_TITLE_BOX")}
                                                                </Typography>
                                                                <Typography className={classes.influencerStats}>
                                                                    {brand.salesNumber}
                                                                </Typography>
                                                            </Grid>
                                                        </Grid>
                                                    </Box>
                                                </Grid>
                                                <Grid container item justify="center" lg={2} md={4} sm={12}>
                                                    <Box className={classes.smallBox}>
                                                        <Grid container direction="row">
                                                            <Grid container item xs={6} justify="center">
                                                                <EuroSymbolIcon style={{ fontSize: "60px", color: "#ef7e3e" }} />
                                                            </Grid>
                                                            <Grid container item xs={6} direction="column" justify="center">
                                                                <Typography className={classes.influencerStatsTitles}>
                                                                    {t("ADMIN.VIEWS.STATISTICS.INFLUENCER_POPUP.TURNOVER_TITLE_BOX")}
                                                                </Typography>
                                                                <Typography className={classes.influencerStats}>
                                                                    {Math.round(brand.amount * 100) / 100} €
                                                    </Typography>
                                                            </Grid>
                                                        </Grid>
                                                    </Box>
                                                </Grid>
                                                <Grid container item justify="center" lg={2} md={4} sm={12}>
                                                    <Box className={classes.smallBox}>
                                                        <Grid container direction="row">
                                                            <Grid container item xs={6} justify="center">
                                                                <TouchAppIcon style={{ fontSize: "60px", color: "#3568eb" }} />
                                                            </Grid>
                                                            <Grid container item xs={6} direction="column" justify="center">
                                                                {brand.clicks === undefined ? (
                                                                    <TrendLoading />
                                                                ) : (
                                                                    <>
                                                                        <Typography className={classes.influencerStatsTitles}>
                                                                            {t("ADMIN.VIEWS.STATISTICS.INFLUENCER_POPUP.CLICKS_TITLE_BOX")}
                                                                        </Typography>
                                                                        <Typography className={classes.influencerStats}>
                                                                            {brand.clicks}
                                                                        </Typography>
                                                                    </>
                                                                )}
                                                            </Grid>
                                                        </Grid>
                                                    </Box>
                                                </Grid>
                                            </Grid>
                                            {(brand && brand.amount === 0 && brand.clicks !== 0) ?
                                                (
                                                    <Grid container item direction="row" xs={12} alignItems="center" justify="center">
                                                        <div style={{ overflowX: "auto", width: "100%", marginTop: "30px", }}>
                                                            <Grid container item direction="row" style={{ minWidth: "1050px" }}>
                                                                <Grid container item direction="row">
                                                                    <Grid container item alignItems="center" justify="center" style={{ width: "40%" }} onClick={() => {
                                                                        sortHelperInfluencer[0] === 0
                                                                            ? setSortHelperInfluencer([1, 0, 0, 0])
                                                                            : setSortHelperInfluencer([-sortHelper[0], 0, 0, 0]);
                                                                    }}>
                                                                        <Typography className={classes.tableHeader}>
                                                                            {t("ADMIN.VIEWS.STATISTICS.INFLUENCER_POPUP.NAME_INFLUENCER")}
                                                                        </Typography>
                                                                        {sortHelperInfluencer[0] === 1 ? (
                                                                            <ExpandLessIcon className={classes.tableHeaderIcon} />
                                                                        ) : sortHelperInfluencer[0] === -1 ? (
                                                                            <ExpandMoreIcon className={classes.tableHeaderIcon} />
                                                                        ) : (
                                                                            <SortIcon className={classes.tableHeaderIcon} />
                                                                        )}
                                                                    </Grid>
                                                                    <Grid container justify="center" alignItems="center" style={{ width: "28%" }} onClick={() => {
                                                                        sortHelperInfluencer[1] === 0 ? setSortHelperInfluencer([0, 1, 0, 0]) : setSortHelperInfluencer([0, -sortHelperInfluencer[1], 0, 0]);
                                                                    }}>
                                                                        <Typography className={classes.tableHeader}>
                                                                            {t("ADMIN.VIEWS.STATISTICS.INFLUENCER_POPUP.EMAIL_INFLUENCER")}
                                                                        </Typography>
                                                                        {sortHelperInfluencer[1] === 1 ? (
                                                                            <ExpandLessIcon className={classes.tableHeaderIcon} />
                                                                        ) : sortHelperInfluencer[1] === -1 ? (
                                                                            <ExpandMoreIcon className={classes.tableHeaderIcon} />
                                                                        ) : (
                                                                            <SortIcon className={classes.tableHeaderIcon} />
                                                                        )}
                                                                    </Grid>
                                                                    <Grid container justify="center" alignItems="center" style={{ width: "15%" }} onClick={() => {
                                                                        sortHelperInfluencer[2] === 0
                                                                            ? setSortHelperInfluencer([0, 0, 1, 0])
                                                                            : setSortHelperInfluencer([0, 0, -sortHelperInfluencer[2], 0]);
                                                                    }}>
                                                                        <Typography className={classes.tableHeader}>
                                                                            {t("ADMIN.VIEWS.STATISTICS.INFLUENCER_POPUP.AFFILIATE_ID")}
                                                                        </Typography>
                                                                        {sortHelperInfluencer[2] === 1 ? (
                                                                            <ExpandLessIcon className={classes.tableHeaderIcon} />
                                                                        ) : sortHelperInfluencer[2] === -1 ? (
                                                                            <ExpandMoreIcon className={classes.tableHeaderIcon} />
                                                                        ) : (
                                                                            <SortIcon className={classes.tableHeaderIcon} />
                                                                        )}
                                                                    </Grid>
                                                                    <Grid container justify="center" alignItems="center" style={{ width: "15%" }} onClick={() => {
                                                                        sortHelperInfluencer[2] === 0
                                                                            ? setSortHelperInfluencer([0, 0, 1, 0])
                                                                            : setSortHelperInfluencer([0, 0, -sortHelperInfluencer[2], 0]);
                                                                    }}>
                                                                        <Typography className={classes.tableHeader}>
                                                                            {t("ADMIN.VIEWS.STATISTICS.INFLUENCER_POPUP.CLICKS")}
                                                                        </Typography>
                                                                        {sortHelperInfluencer[2] === 1 ? (
                                                                            <ExpandLessIcon className={classes.tableHeaderIcon} />
                                                                        ) : sortHelperInfluencer[2] === -1 ? (
                                                                            <ExpandMoreIcon className={classes.tableHeaderIcon} />
                                                                        ) : (
                                                                            <SortIcon className={classes.tableHeaderIcon} />
                                                                        )}
                                                                    </Grid>
                                                                </Grid>
                                                                {influencerToRender.map((influencer) => {
                                                                    return (
                                                                        <Grid container item direction="row" justify="center" className={classes.influencerBoxContainer} key={influencer[0].key}>
                                                                            <Box className={classes.influencerBox}>
                                                                                <Grid container item direction="row" style={{ width: "100%" }}>
                                                                                    <Grid container item justify="flex-start" alignItems="center" style={{ width: "40%" }}>
                                                                                        <img src={influencer[0].banner} alt="" className={classes.influencerImage} />
                                                                                        <Typography className={classes.influencerName} style={{ marginLeft: "25px" }}>
                                                                                            {influencer[0].name}
                                                                                        </Typography>
                                                                                    </Grid>
                                                                                    <Grid container item justify="center" alignItems="center" style={{ width: "29%" }}>
                                                                                        <Typography className={classes.tableRowContent}>
                                                                                            {influencer[0].email}
                                                                                        </Typography>
                                                                                    </Grid>
                                                                                    <Grid container item justify="center" alignItems="center" style={{ width: "15%" }}>
                                                                                        <Typography className={classes.tableRowContent}>
                                                                                            {influencer[0].affiliate_id}
                                                                                        </Typography>
                                                                                    </Grid>
                                                                                    <Grid container item justify="center" alignItems="center" style={{ width: "15%" }}>
                                                                                        <Typography className={classes.tableRowContent}>
                                                                                            {influencer.clicks}
                                                                                        </Typography>
                                                                                    </Grid>
                                                                                </Grid>
                                                                            </Box>
                                                                        </Grid>
                                                                    );
                                                                })}
                                                            </Grid>
                                                        </div>
                                                    </Grid>
                                                )
                                                :

                                                <Grid container item direction="row" xs={12} alignItems="center" justify="center">
                                                    <div style={{ overflowX: "auto", width: "100%", marginTop: "30px", }}>
                                                        <Grid container item direction="row" xs={12} style={{ minWidth: "1050px" }}>
                                                            <Grid container item direction="row">
                                                                <Grid container item alignItems="center" justify="center" style={{ width: "20%" }} onClick={() => {
                                                                    sortHelper[0] === 0
                                                                        ? setSortHelper([1, 0, 0, 0, 0])
                                                                        : setSortHelper([-sortHelper[0], 0, 0, 0, 0]);
                                                                }}>
                                                                    <Typography className={classes.tableHeader}>
                                                                        {t("ADMIN.VIEWS.STATISTICS.BRAND_POPUP.DATE_HEADER")}
                                                                    </Typography>
                                                                    {sortHelper[0] === 1 ? (
                                                                        <ExpandLessIcon className={classes.tableHeaderIcon} />
                                                                    ) : sortHelper[0] === -1 ? (
                                                                        <ExpandMoreIcon className={classes.tableHeaderIcon} />
                                                                    ) : (
                                                                        <SortIcon className={classes.tableHeaderIcon} />
                                                                    )}
                                                                </Grid>
                                                                <Grid container justify="center" alignItems="center" style={{ width: "20%" }} onClick={() => {
                                                                    sortHelper[1] === 0 ? setSortHelper([0, 1, 0, 0, 0]) : setSortHelper([0, -sortHelper[1], 0, 0, 0]);
                                                                }}>
                                                                    <Typography className={classes.tableHeader}>
                                                                        {t("ADMIN.VIEWS.STATISTICS.BRAND_POPUP.INFLUENCER_HEADER")}
                                                                    </Typography>
                                                                    {sortHelper[1] === 1 ? (
                                                                        <ExpandLessIcon className={classes.tableHeaderIcon} />
                                                                    ) : sortHelper[1] === -1 ? (
                                                                        <ExpandMoreIcon className={classes.tableHeaderIcon} />
                                                                    ) : (
                                                                        <SortIcon className={classes.tableHeaderIcon} />
                                                                    )}
                                                                </Grid>
                                                                <Grid container justify="center" alignItems="center" style={{ width: "15%" }} onClick={() => {
                                                                    sortHelper[2] === 0
                                                                        ? setSortHelper([0, 0, 1, 0, 0])
                                                                        : setSortHelper([0, 0, -sortHelper[2], 0, 0]);
                                                                }}>
                                                                    <Typography className={classes.tableHeader}>
                                                                        {t("ADMIN.VIEWS.STATISTICS.BRAND_POPUP.AMOUNT_HEADER")}
                                                                    </Typography>
                                                                    {sortHelper[2] === 1 ? (
                                                                        <ExpandLessIcon className={classes.tableHeaderIcon} />
                                                                    ) : sortHelper[2] === -1 ? (
                                                                        <ExpandMoreIcon className={classes.tableHeaderIcon} />
                                                                    ) : (
                                                                        <SortIcon className={classes.tableHeaderIcon} />
                                                                    )}
                                                                </Grid>
                                                                <Grid container justify="center" alignItems="center" style={{ width: "15%" }}>
                                                                    <Typography className={classes.tableHeader}>
                                                                        {t("ADMIN.VIEWS.STATISTICS.BRAND_POPUP.PRODUCT_HEADER")}
                                                                    </Typography>
                                                                </Grid>
                                                                <Grid container justify="center" alignItems="center" style={{ width: "15%" }} onClick={() => {
                                                                    sortHelper[3] === 0
                                                                        ? setSortHelper([0, 0, 0, 1, 0])
                                                                        : setSortHelper([0, 0, 0, -sortHelper[3], 0]);
                                                                }}>
                                                                    <Typography className={classes.tableHeader}>
                                                                        {t("ADMIN.VIEWS.STATISTICS.BRAND_POPUP.COMMISSIONS_HEADER")}
                                                                    </Typography>
                                                                    {sortHelper[3] === 1 ? (
                                                                        <ExpandLessIcon className={classes.tableHeaderIcon} />
                                                                    ) : sortHelper[3] === -1 ? (
                                                                        <ExpandMoreIcon className={classes.tableHeaderIcon} />
                                                                    ) : (
                                                                        <SortIcon className={classes.tableHeaderIcon} />
                                                                    )}
                                                                </Grid>
                                                                <Grid container justify="center" alignItems="center" style={{ width: "15%" }} onClick={() => {
                                                                    sortHelper[4] === 0 ? setSortHelper([0, 0, 0, 0, 1]) : setSortHelper([0, 0, 0, 0, -sortHelper[3]]);
                                                                }}>
                                                                    <Typography className={classes.tableHeader}>
                                                                        {t("ADMIN.VIEWS.STATISTICS.BRAND_POPUP.COUNTRY_HEADER")}
                                                                    </Typography>
                                                                    {sortHelper[4] === 1 ? (
                                                                        <ExpandLessIcon className={classes.tableHeaderIcon} />
                                                                    ) : sortHelper[4] === -1 ? (
                                                                        <ExpandMoreIcon className={classes.tableHeaderIcon} />
                                                                    ) : (
                                                                        <SortIcon className={classes.tableHeaderIcon} />
                                                                    )}
                                                                </Grid>
                                                            </Grid>
                                                            {sortedConversions.map((conversion) => {
                                                                return (
                                                                    <Grid container item direction="row" justify="center" className={classes.influencerBoxContainer} key={conversion.key}>
                                                                        <Box className={classes.influencerBox}>
                                                                            <Grid container item direction="row" style={{ width: "100%" }}>
                                                                                <Grid container item justify="flex-start" alignItems="center" style={{ width: "20%" }}>
                                                                                    <Typography className={classes.tableRowContent} style={{ paddingLeft: "20px" }}>
                                                                                        {new Date(conversion.createdAt * 1000).toLocaleDateString()}{" "}
                                                                                        {new Date(conversion.createdAt * 1000).toLocaleTimeString({ hour12: false })}
                                                                                    </Typography>
                                                                                </Grid>
                                                                                <Grid container item justify="center" alignItems="center" style={{ width: "20%" }}>
                                                                                    <Typography
                                                                                        className={classes.tableRowContent}
                                                                                        style={{ cursor: 'pointer', textDecoration: 'underline' }}
                                                                                        onClick={() => handleViewInfluencer(conversion)}>
                                                                                        {conversion.nameInfluencer}
                                                                                    </Typography>
                                                                                </Grid>
                                                                                <Grid container item justify="center" alignItems="center" style={{ width: "15%" }}>
                                                                                    <Typography className={classes.tableRowContent}>
                                                                                        {Math.round(conversion.amount * 100) / 100}{" "}€
                                                                                </Typography>
                                                                                </Grid>
                                                                                <Grid container item justify="center" alignItems="center" style={{ width: "15%" }}>
                                                                                    {conversion.articleImgUrl === "" || conversion.articleImgUrl === undefined || conversion.articleImgUrl === null
                                                                                        ? (
                                                                                            <img className={classes.productImage} src={placeholder} alt="" />
                                                                                        ) : (
                                                                                            <a target="_blank" href={conversion.articlePathUrl} rel="noopener noreferrer">
                                                                                                <img className={classes.productImage} src={conversion.articleImgUrl} alt={conversion.articlePathUrl} />
                                                                                            </a>
                                                                                        )}
                                                                                </Grid>
                                                                                <Grid container item justify="center" alignItems="center" style={{ width: "15%" }}>
                                                                                    <Typography className={classes.tableRowContent}>
                                                                                        {Math.round(conversion.commission * 100) / 100}{" "}€
                                                                                </Typography>
                                                                                </Grid>
                                                                                <Grid container item justify="center" alignItems="center" style={{ width: "15%" }} >
                                                                                    <Typography className={classes.tableRowContent} >
                                                                                        {conversion.countryCode === undefined ? '' :
                                                                                            (i18n.language === 'fr-FR' || i18n.language === 'fr')
                                                                                                ? countriesDB.filter((element) => conversion.countryCode === element.code)[0].nameFr
                                                                                                : countriesDB.filter((element) => conversion.countryCode === element.code)[0].name
                                                                                        }
                                                                                    </Typography>
                                                                                </Grid>
                                                                            </Grid>
                                                                        </Box>
                                                                    </Grid>
                                                                );
                                                            })}
                                                        </Grid>
                                                    </div>
                                                </Grid>
                                            }
                                        </Grid>
                                    </Grid>
                                </Grid>
                            </Box>
                        </Grid>
                    </Grid>
                    : <></>
            }
        </>
    )
};
const mapStateToProps = (state) => {
    const influencers = state.influencersState.influencers;
    const loadedInfluencers = state.influencersState.loadedInfluencers;
    return {
        influencers,
        loadedInfluencers
    }
}
export default connect(mapStateToProps, null)(BrandsStatsModal);