import React, { useState, useEffect } from "react";
import { Switch, Route } from "react-router-dom";

import { firebase } from "../managers";
import Landing from "./Landing";

const SmartLinks = ({ props }) => {
  const [smartLinks, setSmartLinks] = useState(null);

  useEffect(() => {
    fetchSmartLinks();
  }, []);

  const fetchSmartLinks = async () => {
    firebase
      .database()
      .ref("/smartlinks")
      .once("value")
      .then(snap => setSmartLinks(snap.val()));
  };

  return (
    <Switch>
      {smartLinks &&
        Object.values(smartLinks).map((smartLink, i) => {
          return (
            <Route
              key={i}
              path={`/influens/display/smartlink/${smartLink.url}`}
            >
              <Landing
                name={smartLink.name}
                logo={smartLink.logo}
                smartlink={smartLink.smartlink}
              />
            </Route>
          );
        })}
    </Switch>
  );
};

export default SmartLinks;
