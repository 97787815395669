import React from "react";
import { Button } from "@material-ui/core";

const Landing = ({ name, logo, smartlink }) => {
  return (
    <div
      style={{
        height: "100vh",
        width: "100vw",
        display: "flex",
        alignContent: "center",
        alignItems: "center",
        justifyContent: "center",
      }}
    >
      <div
        style={{
          margin: "auto",
          display: "flex",
          flexDirection: "column",
          alignContent: "center",
          alignItems: "center",
          justifyContent: "center",
        }}
      >
        <img
          style={{ margin: "15px", maxHeight: "70%", maxWidth: "70%" }}
          src={logo}
          alt={name}
        />
        <Button
          variant='contained'
          color='primary'
          onClick={() => {
            window.location = smartlink;
          }}
        >
          Télécharger
        </Button>
      </div>
    </div>
  );
};

export default Landing;
