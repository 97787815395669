import React from "react";
import { makeStyles, Grid, ClickAwayListener } from "@material-ui/core";

const useStyles = makeStyles(theme => ({
  container: {
    zIndex: theme.zIndex.modal,
    position: "fixed",
    height: "100vh",
    width: "100vw",
    backgroundColor: theme.palette.background.modal,
    overflow: "auto",
  },
  modal: {
    overflow: "hidden",
    padding: theme.spacing(0),
    maxWidth: "80%",
  },
}));

const Modal = ({ children, center, clickAway = () => {} }) => {
  const classes = useStyles();
  return (
    <Grid
      className={classes.container}
      container
      justify={center ? "center" : "flex-start"}
      alignContent={center ? "center" : "stretch"}
      alignItems={center ? "center" : "stretch"}
    >
      <ClickAwayListener onClickAway={clickAway}>
        <>{children}</>
      </ClickAwayListener>
    </Grid>
  );
};

export default Modal;
