const initState = {
  brandConversions: [],
  allConversions: [],
  conversionsError: null,
  loadedBrandConversions: false,
  loadedAllConversions: false,
};

const conversionsReducer = (state = initState, action) => {
  switch (action.type) {
    case "FETCH_CONVERSIONS_SUCCESS":
      return {
        ...state,
        allConversions: action.conversions,
        loadedAllConversions: true,
      };
    case "FETCH_BRAND_CONVERSIONS_SUCCESS":
      return {
        ...state,
        brandConversions: action.brandConversions,
        loadedBrandConversions: true,
      };
    case "FETCH_CONVERSIONS_ERROR":
      return {
        ...state,
        conversionsError: action.error,
      };
    case "CLEAR_CONVERSIONS":
      return {
        brandConversions: [],
        allConversions: [],
        loadedAllConversions: false,
        loadedBrandConversions: false,
        conversionsError: null,
      };
    case "SET_BRAND_CONVERSIONS":
      return {
        ...state,
        brandConversions: action.payload.brandConversions,
        loadedBrandConversions: true,
      };
    case "CLEAR_BRAND_CONVERSIONS":
      return {
        ...state,
        brandConversions: [],
        loadedBrandConversions: false
      }
    default:
      return state;
  }
};

export default conversionsReducer;
