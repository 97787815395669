import React from "react";
import { makeStyles } from "@material-ui/styles";
import clsx from "clsx";

const useStyles = makeStyles(theme => ({
  button: {
    padding: ".5rem 1rem",
    // margin: '.5rem .5rem .5rem 0',
    border: "1px solid #9B9B9B",
    borderRadius: "6.5px",
    color: "#4A4A4A",
    backgroundColor: "white",
    cursor: "pointer",
    outline: "none",
    marginRight: theme.spacing(1),
    marginBottom: theme.spacing(1),
  },
  buttonActive: {
    borderColor: "#00AEEF",
    color: "white",
    backgroundColor: "#00AEEF",
    cursor: "default",
  },
}));

const WishlistButton = ({ content, active = false, onClick }) => {
  const classes = useStyles();
  return (
    <button
      className={clsx(classes.button, active && classes.buttonActive)}
      onClick={onClick}
    >
      {content.toUpperCase()}
    </button>
  );
};

export default WishlistButton;
