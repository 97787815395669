import React, { useState, useEffect } from "react";
import axios from "axios";
import { Redirect } from "react-router-dom";
import { makeStyles } from "@material-ui/styles";
import Grid from "@material-ui/core/Grid";
import Typography from "@material-ui/core/Typography";

// UI Scope
import { DualRing } from "../../ui";

// Relative Dependencies
import { database } from "../../managers";
import { urlMaker } from "../../utils";

const useStyles = makeStyles(theme => ({
  statsContainer: {
    textAlign: "center",
  },
  configImage: {
    maxHeight: "150px",
  },
  calendarsWrap: {
    marginTop: theme.spacing(5),
    marginBottom: theme.spacing(5),
    "& label": {
      margin: theme.spacing(1),
    },
  },
  calendarWrap: {
    padding: "10px",
  },
  dateInput: {
    textAlign: "center",
    width: "80%",
  },
  dataTitle: {
    fontWeight: "bold",
  },
  value: {
    fontSize: "3rem",
    color: theme.palette.primary.main,
  },
}));

const Statistics = ({ user, translations }) => {
  const [affiliateId, setAffiliateId] = useState(undefined);
  const [enddate, setEnddate] = useState(
    new Date(new Date().setDate(new Date().getDate()))
      .toISOString()
      .split("T")[0]
  );
  const [startdate, setStartdate] = useState(
    new Date(new Date().setDate(new Date().getDate() - 30))
      .toISOString()
      .split("T")[0]
  );
  const [loading, setLoading] = useState(false);
  const [datas, setDatas] = useState([]);

  const styles = useStyles();

  useEffect(() => {
    database
      .ref(`Influencers/${user.uid}/Profil/affiliate_id`)
      .once("value")
      .then(snap => {
        return setAffiliateId(snap.val());
      });
  }, [user]);

  useEffect(() => {
    setLoading(true);
    affiliateId &&
      axios
        .get(`${urlMaker("api")}/statistics`, {
          params: {
            from: startdate,
            to: enddate,
            affiliateId: affiliateId,
          },
        })
        .then(res => {
          const dataDates = res.data;
          handleDatas(dataDates);
          setLoading(false);
        })
        .catch(err => {
          console.log(err);
        });
  }, [affiliateId, enddate, startdate]);

  const changeStartDate = () => {
    setStartdate(document.getElementById("startdate").value);
  };
  const changeEndDate = () => {
    setEnddate(document.getElementById("enddate").value);
  };
  const handleDatas = param => {
    setDatas({
      conversions: param.conversions,
      clicks: param.clicks,
      sales: param.sales,
    });
  };
  return (
    <>
      {user ? (
        <Grid container justify='center' className={styles.statsContainer}>
          <Grid
            xs={12}
            sm={10}
            md={8}
            container
            item
            justify='center'
            alignContent='center'
          >
            <Typography variant='h2'>{translations.Stats.title}</Typography>
            <Grid container item direction='column'>
              <Grid container item className={styles.calendarsWrap}>
                <Grid xs={12} sm={6} item className={styles.calendarWrap}>
                  <div className={styles.topCalendar}>
                    <label>{translations.Stats.start}</label>
                    <i className='fas fa-calendar-alt'></i>
                  </div>
                  <span className={styles.select_date}>
                    <input
                      type='date'
                      id='startdate'
                      onChange={changeStartDate}
                      max={enddate}
                      value={startdate}
                      className={styles.dateInput}
                    />
                  </span>
                </Grid>
                <Grid xs={12} sm={6} item className={styles.calendarWrap}>
                  <div className={styles.topCalendar}>
                    <label>{translations.Stats.end}</label>
                    <i className='fas fa-calendar-alt'></i>
                  </div>
                  <span className={styles.select_date}>
                    <input
                      type='date'
                      id='enddate'
                      onChange={changeEndDate}
                      max={enddate}
                      value={enddate}
                      className={styles.dateInput}
                    />
                  </span>
                </Grid>
              </Grid>
            </Grid>
            <Grid container item className={styles.dataWrap}>
              <Grid xs={12} sm={4} item>
                <img
                  src='https://res.cloudinary.com/tellmedia/image/upload/c_scale,w_100/v1545298982/shopmyinfluens/hand.svg'
                  className={styles.configImage}
                  alt='icône de statistique'
                />
                <Typography variant='h5' className={styles.dataTitle}>
                  {translations.Stats.turnover}
                </Typography>
                <span className={styles.value}>
                  {loading ? <DualRing /> : datas.conversions}
                </span>
              </Grid>
              <Grid xs={12} sm={4} item>
                <img
                  src='https://res.cloudinary.com/tellmedia/image/upload/c_scale,w_100/v1545298982/shopmyinfluens/shopping-cart.svg'
                  className={styles.configImage}
                  alt='icône de statistique'
                />
                <Typography variant='h5' className={styles.dataTitle}>
                  {translations.Stats.sales}
                </Typography>
                <span className={styles.value}>
                  {loading ? <DualRing /> : `${datas.sales}€`}
                </span>
              </Grid>
              <Grid xs={12} sm={4} item>
                <img
                  src='https://res.cloudinary.com/tellmedia/image/upload/c_scale,w_100/v1545298935/shopmyinfluens/one-finger-click.svg'
                  className={styles.configImage}
                  alt='icône de statistique'
                />
                <Typography variant='h5' className={styles.dataTitle}>
                  {translations.Stats.clicks}
                </Typography>
                <span className={styles.value}>
                  {loading ? <DualRing /> : datas.clicks}
                </span>
              </Grid>
            </Grid>
          </Grid>
        </Grid>
      ) : (
        <Redirect to='/home' />
      )}
    </>
  );
};

export default Statistics;
