import React, { useEffect, useCallback, useState } from "react";
import { connect } from "react-redux";
import { Pagination, PaginationItem } from '@material-ui/lab';
import { Grid, makeStyles, Typography, IconButton, Select, MenuItem, Menu, CircularProgress } from '@material-ui/core';
import MoreVertIcon from '@material-ui/icons/MoreVert';
import ExpandLessIcon from '@material-ui/icons/ExpandLess';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import SortIcon from '@material-ui/icons/Sort';
import SVGIcon from '../../assets/SVGIcons'
import BootstrapInput from '../../assets/customComponents/BootstrapInput';
import { useTranslation } from "react-i18next";
import unavailableImage from '../../assets/img/unavailable-image.png';
import placeholderInfluencer from '../../assets/img/placeholder_influenceur.png';


const useStyles = makeStyles((theme) => ({
    tableHeader: {
        fontFamily: 'Poppins',
        cursor: 'pointer',
        marginTop: '10px',
        marginBottom: '10px'
    },
    tableRow: {
        backgroundColor: 'white',
        height: '130px',
        borderRadius: '27px',
        boxShadow: '0 7px 30px 0 rgba(88, 165, 211, 0.16)',
        marginTop: '10px',
        marginBottom: '10px'
    },
    brandImage: {
        height: '130px',
        borderTopLeftRadius: '27px',
        borderBottomLeftRadius: '27px',
        width: '130px',
    },
    tableCell: {
        fontFamily: 'Poppins',
    },
    paginationClass: {
        backgroundColor: '#ffffff !important',
        boxShadow: '0 7px 30px 0 rgba(88, 165, 211, 0.16)',
        borderRadius: '8px',
        fontFamily: 'Poppins',
        height: '40px',
        width: '40px',
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center'
    },
    selectedPaginationItem: {
        backgroundColor: '#3eadef !important',
        boxShadow: '0 7px 30px 0 rgba(88, 165, 211, 0.16)',
        borderRadius: '8px',
        fontFamily: 'Poppins',
        height: '40px',
        width: '40px',
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        color: '#ffffff'
    },
    paginationStyles: {
        fontFamily: 'Poppins'
    },
    menu: {
        boxShadow: '0 7px 7px 0 #d0e6f130',
    },
    placeholderStyles: {
        fontFamily: 'Poppins',
        color: '#1B4C61'
    },
}));

const AgentTable = (props) => {

    const { t } = useTranslation('common');
    const classes = useStyles();

    const [loading, setLoading] = useState(true);
    const [anchorEl, setAnchorEl] = useState(null);
    const [page, setPage] = useState(1);
    const [count, setCount] = useState(0);
    const [size, setSize] = useState(10);
    const [sortHelper, setSortHelper] = useState([1, 0, 0, 0, 0])
    const [sortEvent, setSortEvent] = useState(0);
    const [influencerAgentArray, setInfluencerAgentArray] = useState([]);
    const [agentTableToRender, setAgentTableToRender] = useState([]);
    const [tableBeforeRender, setTableBeforeRender] = useState([]);
    const [agentToOperateOn, setAgentToOperateOn] = useState(null);


    const {
        searchValue,
        loadedInfluencers,
        loadedAllConversions,
        loadedInfluencerAgents,
        influencers,
        allConversions,
        influencerAgents,
        appStateSortHelper,
        appStatePageNumber,
        appStatePageSize,
        changePageNumber,
        changeSortHelper,
        changePageSize
    } = props;

    const newFilterInfluencerAgent = useCallback(async () => {
        let object = {};
        let newArray = [];

        if (loadedInfluencers && loadedAllConversions && loadedInfluencerAgents) {
            for (let i = 0; i < influencerAgents.length; i++) {
                if (influencerAgents[i].influencersIds !== undefined) {
                    for (let j = 0; j < influencerAgents[i].influencersIds.length; j++) {
                        if (influencerAgents[i].influencersIds[j].acceptedStatus) {
                            influencers.filter(element => element.key === influencerAgents[i].influencersIds[j].uid).map((filteredInfluencers) => {
                                let found = false;
                                Object.keys(object).forEach((key) => {

                                    if (key === influencerAgents[i].key) {
                                        object[key][filteredInfluencers.key] = filteredInfluencers
                                        found = true;
                                    }
                                });
                                if (!found) {
                                    object[influencerAgents[i].key] = {};
                                    object[influencerAgents[i].key][filteredInfluencers.key] = {
                                        ...filteredInfluencers
                                    };
                                    object[influencerAgents[i].key]['influencerAgentData'] = {
                                        ...influencerAgents[i]
                                    }
                                };
                                return null;
                            });
                        }

                    }
                }
            }


            for (let i = 0; i < influencerAgents.length; i++) {
                if (influencerAgents[i].influencersIds === undefined || influencerAgents[i].influencersIds.length === 0) {
                    object[influencerAgents[i].key] = { ...influencerAgents[i] };
                }
            }

            Object.values(object).forEach((influencerAgentArray) => {
                let amount = 0;
                let commission = 0;
                let influencer = 0;
                let commissionSMI = 0;
                let commissionAffiliate = 0;
                let name = "";
                let image = "";
                if (influencerAgentArray.influencerAgentData !== undefined) {
                    name = influencerAgentArray.influencerAgentData.name;
                    image = influencerAgentArray.influencerAgentData.image;
                    influencer = (Object.keys(influencerAgentArray).length - 1);
                    Object.values(influencerAgentArray).forEach((influencers) => {
                        allConversions.filter(element => element.influencer === influencers.key).map((conversion) => {
                            amount += conversion.amount;
                            commission += Number(conversion.commission);
                            commissionAffiliate += Number(conversion.commissionAffiliate);
                            return null;
                        });
                    });
                    commissionSMI = commission - commissionAffiliate;
                    newArray.push({
                        amount,
                        commission,
                        influencer,
                        commissionSMI,
                        name,
                        image,
                        ...influencerAgentArray
                    });
                } else {
                    name = influencerAgentArray.name;
                    image = influencerAgentArray.image
                    influencer = 0;
                    newArray.push({
                        amount,
                        commission,
                        influencer,
                        commissionSMI,
                        name,
                        image,
                        ...influencerAgentArray
                    })
                }
            });
        }
        return newArray;
    }, [loadedInfluencerAgents, loadedAllConversions, loadedInfluencers, influencerAgents, allConversions, influencers]);

    const filter = useCallback(async () => {
        let influencerAgentIds = [];
        await newFilterInfluencerAgent().then((result) => {
            influencerAgentIds = result;
        });

        setInfluencerAgentArray(influencerAgentIds);
    }, [newFilterInfluencerAgent]);

    useEffect(() => {
        filter().then(() => {
            setLoading(false);
        });
    }, [filter]);


    useEffect(() => {
        let indexAsc = sortHelper.indexOf(1);
        let indexDesc = sortHelper.indexOf(-1);
        if (indexAsc !== -1) {
            const sortTypeAsc = (indexAsc, tableToWorkOn) => {
                switch (indexAsc) {
                    case 0:
                        tableToWorkOn.sort((a, b) => {
                            if (a.amount > b.amount) return -1;
                            if (a.amount < b.amount) return 1;
                            return 0;
                        });
                        break;
                    case 1:
                        tableToWorkOn.sort((a, b) => {
                            if (a.influencer > b.influencer) return -1;
                            if (a.influencer < b.influencer) return 1;
                            return 0;
                        });
                        break;
                    case 2:
                        tableToWorkOn.sort((a, b) => {
                            if (a.commission > b.commission) return -1;
                            if (a.commission < b.commission) return 1;
                            return 0;
                        });
                        break;
                    case 3:
                        tableToWorkOn.sort((a, b) => {
                            if (a.commissionSMI > b.commissionSMI) return -1;
                            if (a.commissionSMI < b.commissionSMI) return 1;
                            return 0;
                        });
                        break;
                    case 4:
                        tableToWorkOn.sort((a, b) => {
                            if (a.name > b.name) return -1;
                            if (a.name < b.name) return 1;
                            return 0;
                        });
                        break;
                    default:
                        break;
                }
            }
            sortTypeAsc(indexAsc, influencerAgentArray);
        } else {
            const sortTypeDesc = (indexDesc, tableToWorkOn) => {
                switch (indexDesc) {
                    case 0:
                        tableToWorkOn.sort((a, b) => {
                            if (a.amount > b.amount) return 1;
                            if (a.amount < b.amount) return -1;
                            return 0;
                        });
                        break;
                    case 1:
                        tableToWorkOn.sort((a, b) => {
                            if (a.influencer > b.influencer) return 1;
                            if (a.influencer < b.influencer) return -1;
                            return 0;
                        });
                        break;
                    case 2:
                        tableToWorkOn.sort((a, b) => {
                            if (a.commission > b.commission) return 1;
                            if (a.commission < b.commission) return -1;
                            return 0;
                        });
                        break;
                    case 3:
                        tableToWorkOn.sort((a, b) => {
                            if (a.commissionSMI > b.commissionSMI) return 1;
                            if (a.commissionSMI < b.commissionSMI) return -1;
                            return 0;
                        });
                        break;
                    case 4:
                        tableToWorkOn.sort((a, b) => {
                            if (a.name > b.name) return 1;
                            if (a.name < b.name) return -1;
                            return 0;
                        });
                        break;
                    default:
                        break;
                }
            };
            sortTypeDesc(indexDesc, influencerAgentArray);
        }
        setSortEvent(s => s + 1);
    }, [influencerAgentArray, sortHelper]);

    useEffect(() => {
        let tableAfterSearch = (searchValue !== '')
            ? influencerAgentArray.filter(agent => agent.name.toLowerCase().includes(searchValue.toLowerCase()))
            : influencerAgentArray;

        setAgentTableToRender(tableAfterSearch.slice((page - 1) * size, page * size));
        setTableBeforeRender(tableAfterSearch);
        setCount(
            (tableAfterSearch.length % size === 0)
                ? Math.trunc(tableAfterSearch.length / size)
                : Math.trunc(tableAfterSearch.length / size) + 1
        );
    }, [influencerAgentArray, size, page, sortEvent, searchValue]);

    const handlePageChange = (event, value) => {
        changePageNumber(value);
    };

    const handlePageSizeChange = (event) => {
        changePageSize(event.target.value);
        changePageNumber(1);
    };

    const handleOpenMenu = (event, agentToOperateOn) => {
        setAnchorEl(event.currentTarget);
        setAgentToOperateOn(agentToOperateOn);
    };

    const handleCloseMenu = () => {
        setAnchorEl(null);
    };

    useEffect(() => {
        setSortHelper(appStateSortHelper);
    }, [appStateSortHelper]);

    useEffect(() => {
        setPage(appStatePageNumber);
    }, [appStatePageNumber]);

    useEffect(() => {
        setSize(appStatePageSize);
    }, [appStatePageSize]);

    const handleEditAgent = (e) => {
        setAnchorEl(null);
        props.openEditAgent(agentToOperateOn);
    }


    console.log(tableBeforeRender.length);

    return (
        (loading)
            ? <div style={{ display: 'flex', flexDirection: 'column', flexGrow: 1, width: '100%', justifyContent: 'center', alignItems: 'center' }}>
                <CircularProgress size={36} color='secondary' />
            </div>
            :
            (agentTableToRender.length === 0)
                ? <>
                    <Grid container item direction='row' xs={11} justify='center' alignItems='center' style={{ marginTop: '100px' }}>
                        <img
                            alt="No Influencer Agents"
                            src={placeholderInfluencer}
                            height="75px"
                            width="75px"
                        />
                    </Grid>
                    <Grid container item direction='row' xs={11} justify='center' alignItems='center' style={{ marginTop: '10px' }}>
                        <Typography className={classes.placeholderStyles}>
                            {t('ADMIN.VIEWS.PARTNERS.AGENTS.NO_INFLUENCERS_AGENT_PLACEHOLDER')}
                        </Typography>
                    </Grid>
                </>
                :
                <>
                    <Grid container item direction='row' xs={11} alignItems='center' justify='center'>
                        <div style={{ overflowX: 'auto', width: '100%' }}>
                            <Grid container item style={{ minWidth: '1050px' }} direction='row' xs={12}>
                                <Grid container item direction='row' xs={12}>
                                    <Grid container item xs={11}>
                                        <Grid container justify='center' alignItems='center' style={{ width: '35%' }} onClick={() => {
                                            (sortHelper[4] === 0) ? changeSortHelper([0, 0, 0, 0, 1]) : changeSortHelper([0, 0, 0, 0, -sortHelper[4]]);
                                        }}>
                                            <Typography className={classes.tableHeader}>
                                                {t('ADMIN.VIEWS.PARTNERS.AGENTS.AGENT_TABLE_HEADERS.INFLUENCER_AGENT_NAME')}
                                            </Typography>
                                            {(sortHelper[4] === 1)
                                                ? <ExpandLessIcon className={classes.tableHeaderIcon} />
                                                : ((sortHelper[4] === -1)
                                                    ? <ExpandMoreIcon className={classes.tableHeaderIcon} />
                                                    : <SortIcon className={classes.tableHeaderIcon} />)}
                                        </Grid>
                                        <Grid container justify='center' alignItems='center' style={{ width: '14%' }} onClick={() => {
                                            (sortHelper[0] === 0) ? changeSortHelper([1, 0, 0, 0, 0]) : changeSortHelper([-sortHelper[0], 0, 0, 0, 0]);
                                        }}>
                                            <Typography className={classes.tableHeader}>
                                                {t('ADMIN.VIEWS.PARTNERS.AGENTS.AGENT_TABLE_HEADERS.TURNOVER')}
                                            </Typography>
                                            {(sortHelper[0] === 1)
                                                ? <ExpandLessIcon className={classes.tableHeaderIcon} />
                                                : ((sortHelper[0] === -1)
                                                    ? <ExpandMoreIcon className={classes.tableHeaderIcon} />
                                                    : <SortIcon className={classes.tableHeaderIcon} />)}
                                        </Grid>
                                        <Grid container justify='center' alignItems='center' style={{ width: '17%' }} onClick={() => {
                                            (sortHelper[1] === 0) ? changeSortHelper([0, 1, 0, 0, 0]) : changeSortHelper([0, -sortHelper[1], 0, 0, 0]);
                                        }}>
                                            <Typography className={classes.tableHeader}>
                                                {t('ADMIN.VIEWS.PARTNERS.AGENTS.AGENT_TABLE_HEADERS.INFLUENCERS')}
                                            </Typography>
                                            {(sortHelper[1] === 1)
                                                ? <ExpandLessIcon className={classes.tableHeaderIcon} />
                                                : ((sortHelper[1] === -1)
                                                    ? <ExpandMoreIcon className={classes.tableHeaderIcon} />
                                                    : <SortIcon className={classes.tableHeaderIcon} />)}
                                        </Grid>
                                        <Grid container justify='center' alignItems='center' style={{ width: '17%' }} onClick={() => {
                                            (sortHelper[2] === 0) ? changeSortHelper([0, 0, 1, 0, 0]) : changeSortHelper([0, 0, -sortHelper[2], 0, 0]);
                                        }}>
                                            <Typography className={classes.tableHeader}>
                                                {t('ADMIN.VIEWS.PARTNERS.AGENTS.AGENT_TABLE_HEADERS.COMMISSIONS')}
                                            </Typography>
                                            {(sortHelper[2] === 1)
                                                ? <ExpandLessIcon className={classes.tableHeaderIcon} />
                                                : ((sortHelper[2] === -1)
                                                    ? <ExpandMoreIcon className={classes.tableHeaderIcon} />
                                                    : <SortIcon className={classes.tableHeaderIcon} />)}
                                        </Grid>
                                        <Grid container justify='center' alignItems='center' style={{ width: '17%' }} onClick={() => {
                                            (sortHelper[3] === 0) ? changeSortHelper([0, 0, 0, 1, 0]) : changeSortHelper([0, 0, 0, -sortHelper[3], 0]);
                                        }}>
                                            <Typography className={classes.tableHeader}>
                                                {t('ADMIN.VIEWS.PARTNERS.AGENTS.AGENT_TABLE_HEADERS.SMI_COMMISSIONS')}
                                            </Typography>
                                            {(sortHelper[3] === 1)
                                                ? <ExpandLessIcon className={classes.tableHeaderIcon} />
                                                : ((sortHelper[3] === -1)
                                                    ? <ExpandMoreIcon className={classes.tableHeaderIcon} />
                                                    : <SortIcon className={classes.tableHeaderIcon} />)}
                                        </Grid>
                                    </Grid>
                                </Grid>
                                {agentTableToRender.map((influencerAgent, key) => {
                                    return (
                                        <Grid container item xs={12} direction='row' className={classes.tableRow} key={key}>
                                            <Grid container item xs={11}>
                                                <Grid container item alignItems='center' style={{ width: '35%' }}>
                                                    {influencerAgent.image === undefined || influencerAgent.image === "" || influencerAgent.image === "influencerAgent.image" ?
                                                        <img src={unavailableImage} alt={influencerAgent.name} className={classes.brandImage} /> :
                                                        <img src={influencerAgent.image} alt={influencerAgent.name} className={classes.brandImage} />
                                                    }

                                                    <Typography className={classes.tableCell} style={{ marginLeft: '30px' }}>{influencerAgent.name}</Typography>
                                                </Grid>
                                                <Grid container item justify='center' alignItems='center' style={{ width: '14%' }}>
                                                    <Typography className={classes.tableCell}>{Math.round(influencerAgent.amount * 100) / 100} €</Typography>
                                                </Grid>
                                                <Grid container item justify='center' alignItems='center' style={{ width: '17%' }}>
                                                    <Typography className={classes.tableCell}>{influencerAgent.influencer}</Typography>
                                                </Grid>
                                                <Grid container item justify='center' alignItems='center' style={{ width: '17%' }}>
                                                    <Typography className={classes.tableCell}>{Math.round(influencerAgent.commission * 100) / 100} €</Typography>
                                                </Grid>
                                                <Grid container item justify='center' alignItems='center' style={{ width: '17%' }}>
                                                    <Typography className={classes.tableCell}>{Math.round(influencerAgent.commissionSMI * 100) / 100} €</Typography>
                                                </Grid>
                                            </Grid>
                                            <Grid container item justify='center' alignItems='center' xs={1}>
                                                <IconButton onClick={(e) => handleOpenMenu(e, influencerAgent)}>
                                                    <MoreVertIcon />
                                                </IconButton>
                                                <Menu
                                                    classes={{ paper: classes.menu }}
                                                    anchorEl={anchorEl}
                                                    open={Boolean(anchorEl)}
                                                    onClose={handleCloseMenu}

                                                >
                                                    <MenuItem
                                                        className={classes.tableHeader}
                                                        onClick={() => handleEditAgent()}>
                                                        {t('ADMIN.VIEWS.PARTNERS.AGENTS.INFLUENCER_AGENT_MENU.EDIT_ITEM')}
                                                    </MenuItem>
                                                </Menu>
                                            </Grid>
                                        </Grid>
                                    )
                                })}
                            </Grid>
                        </div>

                    </Grid>
                    <Grid container item direction='row' xs={11} alignItems='center' style={{ marginBottom: '20px' }}>
                        <Grid container alignItems='center' justify='flex-end'>
                            <Select
                                value={size}
                                onChange={handlePageSizeChange}
                                input={<BootstrapInput />}
                                IconComponent={() => (
                                    <SVGIcon name='miniArrow' />
                                )}
                                classes={{ root: classes.paginationStyles }}
                            >
                                <MenuItem value={10} style={{ fontFamily: 'Poppins' }}>10</MenuItem>
                                <MenuItem value={25} style={{ fontFamily: 'Poppins' }}>25</MenuItem>
                                <MenuItem value={50} style={{ fontFamily: 'Poppins' }}>50</MenuItem>
                            </Select>
                            <Typography className={classes.tableHeader} style={{ marginRight: '10px', marginLeft: '10px' }}>
                                {t('ADMIN.VIEWS.PARTNERS.AGENTS.PAGINATION_PLACEHOLDER')}  {tableBeforeRender.length}
                            </Typography>
                            <Pagination
                                count={count}
                                page={page}
                                onChange={handlePageChange}
                                renderItem={(item) => (
                                    <PaginationItem classes={{ root: classes.paginationClass, selected: classes.selectedPaginationItem }} {...item} />
                                )} />
                        </Grid>
                    </Grid>
                </>
    )
}
const mapStateToProps = (state) => {
    const loadedInfluencers = state.influencersState.loadedInfluencers;
    const loadedAllConversions = state.conversionsState.loadedAllConversions;
    const loadedInfluencerAgents = state.influencerAgentsState.loadedInfluencerAgents;
    const influencers = state.influencersState.influencers;
    const allConversions = state.conversionsState.allConversions;
    const influencerAgents = state.influencerAgentsState.influencerAgents;
    const appStateSortHelper = state.appState.adminPartnerAgentState.agentTableSortHelper;
    const appStatePageNumber = state.appState.adminPartnerAgentState.agentTablePageNumber;
    const appStatePageSize = state.appState.adminPartnerAgentState.agentTablePageSize;
    return {
        loadedInfluencers,
        loadedAllConversions,
        loadedInfluencerAgents,
        influencers,
        allConversions,
        influencerAgents,
        appStateSortHelper,
        appStatePageNumber,
        appStatePageSize
    }
}
const mapDispatchToProps = (dispatch) => {
    return {
        changeSortHelper: (sortHelper) =>
            dispatch({ type: 'UPDATE_ADMIN_PARTNER_AGENT_TABLE_SORT_HELPER', sortHelper }),
        changePageNumber: (pageNumber) =>
            dispatch({ type: 'UPDATE_ADMIN_PARTNER_AGENT_TABLE_PAGE_NUMBER', pageNumber }),
        changePageSize: (pageSize) =>
            dispatch({ type: 'UPDATE_ADMIN_PARTNER_AGENT_TABLE_PAGE_SIZE', pageSize })
    }
};
export default connect(mapStateToProps, mapDispatchToProps)(AgentTable);