import { userManager } from '../../components/managers';
import dbManager from '../../components/managers/dbManager';

export const fetchAllInfluencers = () => {
    return (dispatch, getState) => {
        dbManager.fetchAllInfluencers().then((influencers) => {
            dispatch({ type: 'FETCH_INFLUENCERS_SUCCESS', influencers });
        }).catch((error) => {
            dispatch({ type: 'FETCH_INFLUENCERS_ERROR', error });
        })
    }
};

export const fetchInfluencerById = (id) => {
    return (dispatch, getState) => {
        dispatch({ type: 'FETCH_INFLUENCER_BY_ID_REQUEST' });
        const { influencersState } = getState();
        const allInfluencers = influencersState.influencers;
        const nonPrivateInfluencers = influencersState.nonPrivateInfluencers;
        let filteredAllInfluencer, filteredNonPrivateInfluencers, influencer;
        if (allInfluencers.length !== 0) {
            filteredAllInfluencer = allInfluencers.filter(influencer => {
                if (influencer.key) {
                    return (influencer.key === id) ? true : false;
                } else {
                    return false;
                };
            });
            influencer = filteredAllInfluencer[0];
        } else {
            if (nonPrivateInfluencers.length !== 0) {
                filteredNonPrivateInfluencers = nonPrivateInfluencers.filter(influencer => {
                    if (influencer.key) {
                        return (influencer.key === id) ? true : false;
                    } else {
                        return false;
                    };
                })
                influencer = filteredNonPrivateInfluencers[0];
            }
        }
        if (influencer) {
            dispatch({ type: 'FETCH_INFLUENCER_BY_ID_SUCCESS', payload: { influencer } })
        } else {
            dbManager.fetchInfluencerById(id).then(influencer => {
                if (influencer && Object.keys(influencer).length !== 0) {
                    dispatch({ type: 'FETCH_INFLUENCER_BY_ID_SUCCESS', payload: { influencer } });
                } else {
                    dispatch({ type: 'FETCH_INFLUENCER_BY_ID_NOT_FOUND', payload: {} });
                }
            }, error => {
                dispatch({ type: 'FETCH_INFLUENCER_BY_ID_ERROR', error })
            });
        }
    }
};

export const updateInfluencerById = (id, updates) => {
    return (dispatch, getState) => {
        dbManager.updateInfluencerById(id, updates).then(result => {
            dispatch({ type: 'UPDATE_INFLUENCER_BY_ID_SUCCESS', id, updates });
        }).catch(error => {
            dispatch({ type: 'UPDATE_INFLUENCER_BY_ID_ERROR', error });
        })
    }
};

export const deleteInfluencerById = (influencer, extension) => {
    return (dispatch, getState) => {
        dispatch({ type: 'DELETE_INFLUENCER_BY_ID_REQUEST' });
        userManager.deleteInfluencer(influencer).then((result) => {
            dispatch({ type: 'DELETE_INFLUENCER_BY_ID_SUCCESS', payload: { influencerId: influencer.key } });
        }, (error) => {
            dispatch({ type: 'DELETE_INFLUENCER_BY_ID_ERROR', error });
        });
    }
};

export const fetchInfluencerByCode33 = (code33) => {
    return (dispatch, getState) => {
        dispatch({ type: 'FETCH_INFLUENCER_BY_CODE_33_REQUEST' });
        const { influencersState } = getState();
        const allInfluencers = influencersState.influencers;
        const nonPrivateInfluencers = influencersState.nonPrivateInfluencers;
        let filteredAllInfluencer, filteredNonPrivateInfluencers, influencer;
        if (allInfluencers.length !== 0) {
            filteredAllInfluencer = allInfluencers.filter(influencer => {
                if (influencer.code33) {
                    return (influencer.code33 === code33) ? true : false;
                } else {
                    return false;
                };
            });
            influencer = filteredAllInfluencer[0];
        } else {
            if (nonPrivateInfluencers.length !== 0) {
                filteredNonPrivateInfluencers = nonPrivateInfluencers.filter(influencer => {
                    if (influencer.code33) {
                        return (influencer.code33 === code33) ? true : false;
                    } else {
                        return false;
                    };
                })
                influencer = filteredNonPrivateInfluencers[0];
            }
        }
        if (influencer) {
            dispatch({ type: 'FETCH_INFLUENCER_BY_CODE_33_SUCCESS', payload: { influencer, code33 } });
        } else {
            dbManager.fetchInfluencerByCode33(code33).then(influencer => {
                if (influencer && Object.keys(influencer).length === 0) {
                    dispatch({ type: 'FETCH_INFLUENCER_BY_CODE_33_NOT_FOUND', payload: { influencer: null } });
                } else {
                    dispatch({ type: 'FETCH_INFLUENCER_BY_CODE_33_SUCCESS', payload: { influencer, code33 } });
                }
            }, error => {
                dispatch({ type: 'FETCH_INFLUENCER_BY_CODE_33_ERROR', error });
            })
        };
    }
};

export const fetchInfluencerByName = (name) => {
    return (dispatch, getState) => {
        dispatch({ type: 'FETCH_INFLUENCER_BY_NAME_REQUEST' });
        const { influencersState } = getState();
        const allInfluencers = influencersState.influencers;
        const nonPrivateInfluencers = influencersState.nonPrivateInfluencers;
        let filteredAllInfluencer, filteredNonPrivateInfluencers, influencer;
        if (allInfluencers.length !== 0) {
            filteredAllInfluencer = allInfluencers.filter(influencer => {
                if (influencer.name) {
                    return (influencer.name === name) ? true : false;
                } else {
                    return false;
                };
            });
            influencer = filteredAllInfluencer[0];
        } else {
            if (nonPrivateInfluencers.length !== 0) {
                filteredNonPrivateInfluencers = nonPrivateInfluencers.filter(influencer => {
                    if (influencer.name) {
                        return (influencer.name === name) ? true : false;
                    } else {
                        return false;
                    };
                })
                influencer = filteredNonPrivateInfluencers[0];
            }
        }
        if (influencer) {
            dispatch({ type: 'FETCH_INFLUENCER_BY_NAME_SUCCESS', payload: { influencer } });
        } else {
            dbManager.fetchInfluencerByName(name).then(influencer => {
                if (influencer && Object.keys(influencer).length !== 0) {
                    dispatch({ type: 'FETCH_INFLUENCER_BY_NAME_SUCCESS', payload: { influencer } });
                } else {
                    dispatch({ type: 'FETCH_INFLUENCER_BY_NAME_NOT_FOUND', payload: { influencer: null } });
                };
            }, error => {
                dispatch({ type: 'FETCH_INFLUENCER_BY_NAME_ERROR' });
            });
        }
    }
};

export const fetchNonPrivateInfluencers = () => {
    return (dispatch, getState) => {
        dispatch({ type: 'FETCH_NON_PRIVATE_INFLUENCERS_REQUEST' });
        const { influencersState } = getState();
        const loadedInfluencers = influencersState.loadedInfluencers;
        if (loadedInfluencers) {
            const allInfluencers = influencersState.influencers;
            const nonPrivateInfluencers = allInfluencers.filter(influencer => !influencer.private);
            dispatch({ type: 'FETCH_NON_PRIVATE_INFLUENCERS_SUCCESS', payload: { influencers: nonPrivateInfluencers } });
        } else {
            dbManager.fetchNonPrivateInfluencers().then(nonPrivateInfluencers => {
                dispatch({ type: 'FETCH_NON_PRIVATE_INFLUENCERS_SUCCESS', payload: { influencers: nonPrivateInfluencers } });
            }, error => {
                dispatch({ type: 'FETCH_NON_PRIVATE_INFLUENCERS_ERROR', error });
            })
        }
    }
};

export const getInfluencerByIdEshop = (uid) => {
    return (dispatch, getState) => {
        dbManager.getInfluencerByIdEshop(uid).then((influencers) => {
            dispatch({ type: 'FETCH_INFLUENCERS_BY_ID_ESHOP_SUCCESS', payload: { influencersById: influencers } });
        }).catch((error) => {
            dispatch({ type: 'FETCH_INFLUENCERS_BY_ID_ESHOP_ERROR', payload: { error: error } });
        })
    }
}
export const fetchInfluencerByAffiliateId = (affiliateId) => {
    return (dispatch, getState) => {
        dispatch({ type: 'FETCH_INFLUENCER_BY_AFFILIATE_ID_REQUEST' });
        const { influencersState } = getState();
        const allInfluencers = influencersState.influencers;
        const nonPrivateInfluencers = influencersState.nonPrivateInfluencers;
        let filteredAllInfluencer, filteredNonPrivateInfluencers, influencer;
        if (allInfluencers.length !== 0) {
            filteredAllInfluencer = allInfluencers.filter(influencer => {
                if (influencer.affiliate_id) {
                    return (influencer.affiliate_id === Number(affiliateId)) ? true : false;
                } else {
                    return false;
                };
            });
            influencer = filteredAllInfluencer[0];
        } else {
            if (nonPrivateInfluencers.length !== 0) {
                filteredNonPrivateInfluencers = nonPrivateInfluencers.filter(influencer => {
                    if (influencer.affiliate_id) {
                        return (influencer.affiliate_id === Number(affiliateId)) ? true : false;
                    } else {
                        return false;
                    };
                })
                influencer = filteredNonPrivateInfluencers[0];
            }
        }
        if (influencer) {
            dispatch({ type: 'FETCH_INFLUENCER_BY_AFFILIATE_ID_SUCCESS', payload: { influencer } });
        } else {
            dbManager.fetchInfluencerByAffiliateId(Number(affiliateId)).then(influencer => {
                if (influencer && Object.keys(influencer).length !== 0) {
                    dispatch({ type: 'FETCH_INFLUENCER_BY_AFFILIATE_ID_SUCCESS', payload: { influencer } });
                } else {
                    dispatch({ type: 'FETCH_INFLUENCER_BY_AFFILIATE_ID_NOT_FOUND', payload: { influencer: null } });
                };
            }, error => {
                dispatch({ type: 'FETCH_INFLUENCER_BY_AFFILIATE_ID_ERROR', error });
            });
        };
    }
};
