import React from "react";

import Commission from "./commission";
import CommissionPaye from "./commissions_payee";
import BrandsIcon from "./brandsIcon";
import NombreInfluenceurs from "./nombreInfluenceurs";
import MiniArrow from "./select_arrow";
import Ventes from "./ventes";

const SVGIcon = props => {
    switch (props.name) {
        case "commission":
            return <Commission {...props} />;
        case "commissionPaye":
            return <CommissionPaye {...props} />;
        case "brandsIcon":
            return <BrandsIcon {...props} />;
        case "miniArrow":
            return <MiniArrow {...props} />;
        case "ventes":
            return <Ventes {...props} />;
        case "nombreInfluenceurs":
            return <NombreInfluenceurs {...props} />;
        default:
            return;
    }
};

export default SVGIcon;
