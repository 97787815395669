import { Avatar, Button, Dialog, DialogActions, DialogContent, Divider, Grid, IconButton, List, ListItem, ListItemAvatar, ListItemText, makeStyles, Typography } from '@material-ui/core';
import CloseIcon from '@material-ui/icons/Close';
import React, { useEffect, useState } from 'react';
// import { useTranslation } from "react-i18next";

const useStyle = makeStyles(theme => ({
    root: {
        width: '100%',
        // maxWidth: '36ch',
    },
    dialogTitle: {
        display: 'flex',
        flexDirection: 'row',
        justifyContent: 'space-between',
        alignItems: 'center',
        width: '100%',
        padding: 10
    },
    shortlinkTitle: {
        fontFamily: 'Poppins',
        color: '#ee3889',
        paddingLeft: '15px'
    },
    primaryText: {
        fontFamily: 'Poppins',
        color: '#00ADEF',
        fontWeight: 400
    },
    secondaryText: {
        fontFamily: 'Poppins',
        color: '#888888',
        fontWeight: 200
    },
    sendButton: {
        fontFamily: 'Poppins',
        textTransform: 'none',
        textDecoration: 'none',
        borderRadius: '15px',
        backgroundColor: '#00ADEF',
        color: '#fff',
        '&:hover': {
            backgroundColor: '#00ADEF',
            color: '#fff',
        }
    }
}));

const ExportBox = (props) => {

    const classes = useStyle();
    // const { t } = useTranslation("common");
    const [bodyToInsert, setBodyToInsert] = useState('');

    const {
        open,
        exportData
    } = props;

    const closeBox = () => {
        props.closeExportBox();
    };

    useEffect(() => {
        let shortlinksDivsToExport = '';
        exportData.forEach(shortlink => {
            shortlinksDivsToExport += `
            <div class='table-row'>
                <div class='table-cell' style='width: 30%; border-right: solid #e0e0e06b 1px;'>
                    <img width="90%" height="90%" style='object-fit: contain;'
                        src=${shortlink.image} />
                </div>
                <p class='table-cell' style='width: 20%; border-right: solid #e0e0e06b 1px;'>${shortlink.brandName}</p>
                <p class='table-cell' style='width: 50%;'>${shortlink.newUrl}</p>
            </div>`
        });

        const data = `
        <!DOCTYPE html>
<head>
    <style>
        body {
            background-color: #eff9fe;
            display: flex;
            justify-content: center;
        }
        .table-wrapper {
            width: 95%;
            overflow-x: auto;
        }
        .table-header {
            background-color: #ffffff;
            width: 100%;
            color: #000000;
            min-width: 900px;
            height: 50px;
            box-shadow: 0px 5px 9px 0 rgb(88 165 211 / 36%);
            border-radius: 15px;
            display: flex;
            flex-direction: row;
            margin-top: 15px;
        }
        .header {
            display: flex;
            flex-direction: row;
            justify-content: center;
            align-items: center;
            height: 100%;
            margin: 0px;
        }
        .table-row {
            background-color: #ffffff;
            width: 100%;
            min-width: 900px;
            box-shadow: 0px 5px 9px 0 rgb(88 165 211 / 36%);
            border-radius: 15px;
            height: 150px;
            display: flex;
            flex-direction: row;
            margin-top: 15px;
        }
        .table-cell {
            height: 100%;
            display: flex;
            margin: 0;
            color: #000000;
            justify-content: center;
            align-items: center;
            word-break: break-all;
            padding-right: 10px;
            padding-left: 10px;
        }
    </style>
</head>
<body>
    <div class='table-wrapper'>
        <div class='table-header'>
            <p class='header' style='width: 30%; border-right: solid #e0e0e06b 1px;'>Produit</p>
            <p class='header' style='width: 20%; border-right: solid #e0e0e06b 1px;'>Marque</p>
            <p class='header' style='width: 50%;'>Lien</p>
        </div>
        ${shortlinksDivsToExport}
    </div>
</body>
`
        setBodyToInsert(data);
    }, [exportData])

    return (
        <Dialog open={open}>
            <Grid className={classes.dialogTitle} container item direction='row' xs={12}>
                <Typography className={classes.shortlinkTitle}>Liste des liens trackés</Typography>
                <IconButton size='small' onClick={closeBox}>
                    <CloseIcon />
                </IconButton>
            </Grid>
            <DialogContent >
                {
                    exportData.map((articleWithShortlink, i) => (
                        <>
                            <List className={classes.root} key={articleWithShortlink.productId}>
                                <ListItem alignItems="flex-start">
                                    <ListItemAvatar>
                                        <Avatar alt="Remy Sharp" src={articleWithShortlink.image} />
                                    </ListItemAvatar>
                                    <ListItemText
                                        classes={{
                                            primary: classes.primaryText,
                                            secondary: classes.secondaryText
                                        }}
                                        primary="Lien tracké :"
                                        secondary={
                                            <React.Fragment>
                                                {articleWithShortlink.newUrl}
                                            </React.Fragment>
                                        }
                                    />
                                </ListItem>
                            </List>
                            {
                                (exportData.length - 1 === i)
                                    ? <></>
                                    :
                                    <Divider />
                            }
                        </>
                    ))
                }
            </DialogContent>
            <DialogActions style={{ display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
                {
                    (exportData.length === 0)
                        ? <Button disabled className={classes.sendButton} >Exporter via mail</Button>
                        : <a href={'mailto:?subject=Links&body=' + bodyToInsert} style={{ textDecoration: 'none' }}>
                            <Button className={classes.sendButton} >Exporter via mail</Button>
                        </a>
                }
            </DialogActions>
        </Dialog>
    )
};

export default ExportBox;