export const env = process.env.REACT_APP_STAGE;
export const firebaseConfig = {
  apiKey: "AIzaSyDFpc4hKOKbwBx0_wcNjrJIZNxw91ysQ6A",
  authDomain: "shopmyinfluens.firebaseapp.com",
  databaseURL: (env === 'prod') ? "https://shopmyinfluens.firebaseio.com" : "http://localhost:9000/?ns=shopmyinfluens",
  projectId: "shopmyinfluens",
  storageBucket: "shopmyinfluens.appspot.com",
  messagingSenderId: "1007757627682",
};
export const menuOptions = [
  { name: "Home", link: "/", cred: "all" },
  { name: "Les wishlists", link: "/home", cred: "all" },
  //{ name: "Mes Statistiques", link: "/statistics", cred: "user" },
  { name: "Comment ça marche", link: "/how_it_works", cred: "all" },
  { name: "Nos marques partenaires", link: "/brands", cred: "all" },
  { name: "Contactez nous !", link: "/contact", cred: "all" },
  { name: "Administration", link: "/admin", cred: "admin" },
  { name: "Déconnexion", link: "/logout", cred: "user" },
];
export const socialMediasImages = {
  instagram:
    "https://res.cloudinary.com/tellmedia/image/upload/v1566481795/shopmyinfluens/icons/instagram.svg",
  twitter:
    "https://res.cloudinary.com/tellmedia/image/upload/v1566481795/shopmyinfluens/icons/twitter.svg",
  facebook:
    "https://res.cloudinary.com/tellmedia/image/upload/v1566481799/shopmyinfluens/icons/facebook.svg",
  youtube:
    "https://res.cloudinary.com/tellmedia/image/upload/v1566811998/shopmyinfluens/icons/youtube.svg",
  pinterest:
    "https://res.cloudinary.com/tellmedia/image/upload/v1566811998/shopmyinfluens/icons/pinterest.svg",
  site:
    "https://res.cloudinary.com/tellmedia/image/upload/v1566481795/shopmyinfluens/icons/web.svg",
  snapchat:
    "https://res.cloudinary.com/tellmedia/image/upload/v1566811998/shopmyinfluens/icons/snapchat.svg",
};
