import React, { useEffect, useState } from 'react';
import { Grid, makeStyles, useTheme, useMediaQuery, Typography, CircularProgress, Backdrop } from "@material-ui/core";
import backgroundImage from "../../assets/background.png";
import placeholderProduct from '../../assets/noProduct.png';
import placeholderBrand from '../../assets/brand.png';
import { useParams } from "react-router-dom";
import { connect } from 'react-redux';
import { getArticleByBrandEshop } from '../../../../store/actions/eshopActions';
import { clicksManager } from '../../../managers';
//import { useTranslation } from "react-i18next";


const useStyles = makeStyles((theme) => ({
    brandContent: {
        minHeight: `calc(100vh - ${theme.appBar.height})`,
        width: "100%",
        flexGrow: 1,
        display: "flex",
        flexDirection: "column",
        backgroundImage: `url(${backgroundImage})`,
        boxShadow: "inset 0 0 0 100vh rgba(236, 247, 253, 0.5)",
    },
    espace: {
        marginTop: "50px"
    },
    brandImage: {
        width: "200px",
        height: "200px",
        borderRadius: "35px",
    },
    blockProduct: {
        width: "95%",
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
        marginBottom: "30px",
        marginTop: "50px",
    },
    overallBoxStyles: {
        backgroundColor: "white",
        borderRadius: "35px",
        boxShadow: "0 7px 30px 0 rgba(88, 165, 211, 0.16)",
        width: "95%",
    },
    productImage: {
        height: 'auto',
        width: '180px',
        borderRadius: "30px",
        objectFit: "contain",
    },
    container: {
        width: "180px",
        height: "auto",
        position: "relative",
        margin: "15px 40px 15px 10px",
    },
    overlayImage: {
        position: "absolute",
        top: 0,
        bottom: 0,
        left: 0,
        right: 0,
        height: "auto",
        width: "180px",
        opacity: 0,
        borderRadius: "30px",
        transition: ".5s ease",
        backgroundColor: "rgba(62, 173, 239)",
        '&:hover': {
            opacity: 0.2,
            cursor: "pointer",
        },
    },
    overlay: {
        zIndex: theme.zIndex.drawer + 1
    },
    placeholderStyles: {
        fontFamily: 'Poppins',
        color: '#1B4C61',
        fontSize: "20px",
        fontWeight: "bold"
    },
}))

const BrandCollections = (props) => {
    const classes = useStyles();
    const theme = useTheme();
    //const { t } = useTranslation('common');
    const xsDown = useMediaQuery(theme.breakpoints.down("xs"));
    const [loading, setLoading] = useState(false);
    const [affiliateId, setAffiliateId] = useState(null);
    const [influencerName, setInfluencerName] = useState('');
    const [offerId, setOfferId] = useState('');
    const [brand, setBrand] = useState([]);
    const [articleEshop, setArticleEshop] = useState([]);
    const [productCollection, setProductCollection] = useState([]);
    const [influencer, setInfluencer] = useState();

    const { brandName, uid } = useParams();
    const {
        getArticleByBrandEshop,
        eshopArticles,
        loadedEshopArticles,
        influencers,
        loadedInfluencers,
        allBrands,
        loadedBrands, } = props;

    useEffect(() => {
        if (uid && loadedInfluencers) {
            const inf = influencers.filter(ele => ele.key === uid);
            setInfluencer(inf)
        }
    }, [uid, loadedInfluencers, influencers]);

    useEffect(() => {
        if (!loadedEshopArticles) getArticleByBrandEshop();
    }, [loadedEshopArticles, getArticleByBrandEshop])

    useEffect(() => {
        if (influencer && loadedBrands) {
            const nameInfluencer = influencer[0].name;
            const affiliateId = influencer[0].affiliate_id;

            let newName = nameInfluencer;
            // remove accentens
            newName = newName.normalize("NFD").replace(/[\u0300-\u036f]/g, "")
            // capitilate first letter
            newName = newName.charAt(0).toUpperCase() + newName.slice(1);
            //remove special charts
            newName = newName.replace(/[^a-zA-Z0-9]/g, '')
            setInfluencerName(newName);
            setAffiliateId(affiliateId);
        }
    }, [influencer, loadedBrands, allBrands, brandName]);

    useEffect(() => {

        if (loadedEshopArticles) {
            setArticleEshop(eshopArticles);
        }

    }, [loadedEshopArticles, eshopArticles])



    useEffect(() => {
        if (articleEshop && loadedBrands) {
            let array = [];
            const brand = allBrands.filter(brand => brand.name === brandName)[0];
            const brandEshop = articleEshop.filter((article, i) => {
                return article.brandName === brandName
            });

            if (brandEshop) {
                brandEshop.forEach(element => {

                    Object.values(element.collections).forEach(articleInformation => {
                        array.push({ ...articleInformation });
                    })

                })
                setProductCollection(array)
                if (brand) {
                    setOfferId(brand.offerId)
                }
                setBrand(brand);
            }
        }
    }, [articleEshop, loadedBrands, allBrands, brandName])

    const handleCreateTrackingLink = async (article) => {
        if (influencerName && affiliateId) {
            let clickId = "";
            //add Click for this product
            setLoading(true);
            await clicksManager.addClick(uid, article.id, offerId).then(ref => {
                clickId = ref.data.data.key;
                setLoading(false)
            });
            //creation tracking link for this product
            let trackingDomain = "https://influens.go2affise.com/"
            const trackingLink = trackingDomain + "click?pid=" + affiliateId +
                "&offer_id=" + offerId +
                "&sub1=" + encodeURIComponent(influencerName) +
                "&sub2=" + article.id +
                "&sub3=" + encodeURIComponent(article.image) +
                "&sub4=" + encodeURIComponent(article.url) + //Util.encodeUrl(produit.getUrl())+
                "&sub5=" + uid +
                "&sub6=" + clickId +
                "&path=" + encodeURIComponent(article.url);
            return window.location === window.parent.location
                ? (window.location = trackingLink)
                : (window.parent.location = trackingLink);
        }

    }

    return (
        <Grid className={classes.brandContent}>
            {brand === undefined ?
                <>
                    <Grid container item direction='row' xs={12} justify='center' alignItems='center' style={{ marginTop: "100px" }}>
                        <Grid container item xs={11} justify='center' alignItems='center'>
                            <img
                                alt="No Brands"
                                src={placeholderBrand}
                                height={(xsDown) ? "100px" : "150px"}
                                width={(xsDown) ? "100px" : "150px"}
                            />
                        </Grid>
                        <Grid container item xs={11} justify='center' alignItems='center'>
                            <Typography className={classes.placeholderStyles}>
                                This Brand Doesn't Exist !
                            </Typography>
                        </Grid>
                    </Grid>
                </>

                :
                <>
                    <Backdrop className={classes.overlay} open={loading}>
                        <CircularProgress color='secondary' />
                    </Backdrop>
                    <Grid container item xs={12} direction="row" justify="center" alignItems="center">
                        <Grid container item xs={10} direction="row" justify="center" alignItems="center" className={classes.espace}>
                            <Grid container item xs={12} direction="row" justify="center" alignItems="center" style={{ display: "flex" }}>
                                <Grid container item justify="center" alignItems="center" lg={4} md={4} sm={12}>
                                    <img
                                        src={brand.pic}
                                        alt=""
                                        className={classes.brandImage}
                                    />
                                </Grid>
                                <Grid container item direction="column" justify="flex-start" alignItems="flex-start" lg={6} md={8} sm={12} style={xsDown ? { marginTop: "20px" } : { marginTop: "20px" }}>
                                    <Grid container direction="column" justify="flex-start" alignItems="flex-start">
                                        <Typography style={{ fontFamily: "poppins", fontWeight: "bold", fontSize: "20px" }}>
                                            {brand.displayName}
                                        </Typography>
                                        <Typography style={{ fontFamily: "poppins", fontWeight: "bold", fontSize: "15px", marginTop: "10px" }}>
                                            {brand.description}
                                        </Typography>
                                    </Grid>
                                </Grid>
                            </Grid>
                        </Grid>
                        {!productCollection ?
                            <>
                                <Grid container item direction='row' xs={11} justify='center' alignItems='center' style={{ marginTop: '10px', marginBottom: '5px' }}>
                                    <img
                                        alt="No Brands"
                                        src={placeholderProduct}
                                        height={(xsDown) ? "250px" : "300px"}
                                        width={(xsDown) ? "250px" : "300px"}
                                    />
                                </Grid>
                                <Grid container item direction='row' xs={11} justify='center' alignItems='center' style={{ marginTop: '5px', marginBottom: '50px' }}>
                                    <Typography className={classes.placeholderStyles}>
                                        There is no product exist for This Brand !
                                    </Typography>
                                </Grid>
                            </>
                            :
                            <Grid container item direction="row" justify="center" alignItems="center" className={classes.blockProduct}>
                                <Grid container item justify='center' alignItems='center'>
                                    {productCollection.map(article => {
                                        return uid ?
                                            <div className={classes.container} key={article.id} onClick={() => handleCreateTrackingLink(article)}>
                                                <img src={article.image} alt="123" className={classes.productImage} />
                                                <div className={classes.overlayImage}></div>
                                            </div>
                                            :
                                            <>
                                                <div className={classes.container} key={article.id}>
                                                    <a href={article.url} target="_blank" rel="noopener noreferrer" >
                                                        <img src={article.image} alt="123" className={classes.productImage} />
                                                        <div className={classes.overlayImage}></div>
                                                    </a>
                                                </div>
                                            </>
                                    })
                                    }
                                </Grid>
                            </Grid>
                        }
                    </Grid>
                </>
            }

        </Grid>
    )
}
const mapStateToProps = (state) => {
    const influencers = state.influencersState.influencers;
    const loadedInfluencers = state.influencersState.loadedInfluencers;
    const allBrands = state.brandsState.allBrands;
    const loadedBrands = state.brandsState.loadedBrands;
    const eshopArticles = state.eshopState.eshopArticles;
    const loadedEshopArticles = state.eshopState.loadedEshopArticles;
    return {
        influencers,
        loadedInfluencers,
        allBrands,
        loadedBrands,
        eshopArticles,
        loadedEshopArticles,
    }
};

const mapDispatchToProps = (dispatch) => {
    return {
        getArticleByBrandEshop: () => dispatch(getArticleByBrandEshop()),
    }
}
export default connect(mapStateToProps, mapDispatchToProps)(BrandCollections);
