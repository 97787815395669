const initState = {
    influencerTableSortHelper: [0, 1],
    influencerTablePageNumber: 1,
    influencerTablePageSize: 25
}

const adminInfluencerReducer = (state = initState, action) => {
    switch (action.type) {
        case 'UPDATE_ADMIN_INFLUENCER_TABLE_SORT_HELPER':
            return {
                ...state,
                influencerTableSortHelper: action.sortHelper
            };
        case 'UPDATE_ADMIN_INFLUENCER_TABLE_PAGE_NUMBER':
            return {
                ...state,
                influencerTablePageNumber: action.pageNumber
            };
        case 'UPDATE_ADMIN_INFLUENCER_TABLE_PAGE_SIZE':
            return {
                ...state,
                influencerTablePageSize: action.pageSize
            };
        default:
            return state
    }
};

export default adminInfluencerReducer;