import { Grid, makeStyles, Typography, IconButton, FormControl, InputBase, Switch, useTheme, useMediaQuery, Button, Select, MenuItem, CircularProgress, Backdrop, Dialog } from "@material-ui/core";
import { withStyles } from "@material-ui/styles";
import CloseIcon from '@material-ui/icons/Close';
import CropperDialog from './Cropper';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import React, { useCallback, useEffect, useState } from "react";
import { connect } from "react-redux";
import clsx from "clsx";
import countriesDB from '../assets/countries';
import AddIcon from "@material-ui/icons/Add";
import DeleteOutlineIcon from "@material-ui/icons/DeleteOutline";
import { refuseBrandById, updateBrandById, updateBrandToValidateById, validateBrandById } from "../../../../store/actions/brandsActions";
import { useTranslation } from "react-i18next";
import axios from "axios";
import WarningDialog from "./WarningDialog";

const useStyles = makeStyles((theme) => ({
    overallBox: {
        backgroundColor: '#fff',
        borderRadius: '35px',
        boxShadow: '0 7px 30px 0 rgba(88, 165, 211, 0.16)',
        margin: '15px'
    },
    formBox: {
        marginTop: '20px',
        marginBottom: '20px'
    },
    formTitle: {
        fontFamily: 'Poppins',
        fontSize: '22px',
        margin: '10px',
        marginTop: '-50px'
    },
    formControlRow: {
        marginTop: '5px',
        marginBottom: '5px'
    },
    formLabel: {
        fontFamily: 'Poppins',
        marginLeft: '20px'
    },
    formControl: {
        width: '100%'
    },
    formInput: {
        fontFamily: 'Poppins',
        borderRadius: '35px',
        boxShadow: '0 7px 30px 0 rgba(69, 128, 163, 0.16)',
        height: '50px',
        width: '100%',
        paddingTop: '10px',
        paddingBottom: '10px',
        paddingLeft: '20px',
        margin: '5px'
    },
    switchLabel: {
        fontFamily: 'Poppins',
    },
    profileImageLabel: {
        fontFamily: 'Poppins',
        width: '150px',
        height: '40px',
        textTransform: 'none',
        backgroundColor: '#58a5d3',
        color: '#fff',
        borderRadius: '35px',
        '&:hover': {
            cursor: 'pointer',
            backgroundColor: '#58a5d3',
        },
        '&:focus': {
            outlineColor: 'rgba(69, 128, 163, 0)'
        },
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        position: 'relative',
    },
    profilePicturePlaceholder: {
        fontFamily: 'Poppins',
        color: '#717171',
        width: 'calc(100% - 150px)',
        paddingLeft: '15px'
    },
    activeProfilePicturePlaceholder: {
        fontFamily: 'Poppins',
        width: 'calc(100% - 150px)',
        paddingLeft: '15px'
    },
    profilePicturePlaceholderMobile: {
        fontFamily: 'Poppins',
        color: '#717171',
        width: '70%',
        margin: 5,
        textAlign: 'center',
    },
    activeProfilePicturePlaceholderMobile: {
        fontFamily: 'Poppins',
        width: '70%',
        margin: 5,
        textAlign: 'center',
    },
    refuseButton: {
        width: '200px',
        height: '40px',
        fontFamily: 'Poppins',
        margin: '5px',
        borderRadius: '30px',
        backgroundColor: 'red',
        color: '#fff',
        boxShadow: '0 7px 30px 0 rgba(88, 165, 211, 0.16)',
        '&:hover': {
            backgroundColor: 'red'
        }
    },
    validateButton: {
        width: '200px',
        height: '40px',
        fontFamily: 'Poppins',
        margin: '5px',
        borderRadius: '30px',
        boxShadow: '0 7px 30px 0 rgba(88, 165, 211, 0.16)',
        color: '#fff',
        backgroundColor: '#d96e98',
        '&:hover': {
            backgroundColor: '#d96e98'
        }
    },
    validateLaterButton: {
        width: '200px',
        height: '40px',
        fontFamily: 'Poppins',
        margin: '5px',
        borderRadius: '30px',
        boxShadow: '0 7px 30px 0 rgba(88, 165, 211, 0.16)',
    },
    validateEditButton: {
        width: '200px',
        height: '60px',
        fontFamily: 'Poppins',
        margin: '5px',
        borderRadius: '30px',
        boxShadow: '0 7px 30px 0 rgba(88, 165, 211, 0.16)',
        color: '#fff',
        backgroundColor: '#d96e98',
        '&:hover': {
            backgroundColor: '#d96e98'
        }
    },
    cancelEditButton: {
        width: '200px',
        height: '60px',
        fontFamily: 'Poppins',
        margin: '5px',
        borderRadius: '30px',
        boxShadow: '0 7px 30px 0 rgba(88, 165, 211, 0.16)',
    },
    selectClass: {
        fontFamily: 'Poppins',
        paddingRight: '0px !important',
        '&:focus': {
            backgroundColor: 'white'
        },
        display: 'flex',
        flexShrink: 1,
    },
    select: {
        marginTop: '10px',
        marginBottom: '10px',
    },
    selectedMenuItems: {
        backgroundColor: '#50bef3 !important',
        color: '#fff'
    },
    optionalCommission: {
        fontFamily: 'Poppins',
        fontSize: '14px',
        marginBottom: '10px'
    },
    optionalCommissionRow: {
        marginTop: '10px',
        marginBottom: '10px'
    },
    overlay: {
        zIndex: theme.zIndex.drawer + 1
    },
    warningDialog: {

    },
    logoHrefLink: {
        marginLeft: 20,
        fontFamily: 'Poppins',
        textDecoration: 'underline',
        color: '#3eadef',
        cursor: 'pointer',
        fontSize: '14px'
    }
}));

const iconStyles = {
    selectIcon: {
        color: "black"
    },
    loaderStyles: {
        color: 'red'
    }
};

const CustomExpandMore = withStyles(iconStyles)(
    ({ className, classes, ...rest }) => {
        return (
            <ExpandMoreIcon
                {...rest}
                className={clsx(className, classes.selectIcon)}
            />
        );
    }
);

const CustomLoader = withStyles(iconStyles)(
    ({ className, classes, ...rest }) => {
        return (
            <CircularProgress
                size='20px'
                style={{ marginRight: '10px' }}
                {...rest}
                className={clsx(className, classes.loaderStyles)} />
        );
    }
);

const EditBrandModal = (props) => {

    const classes = useStyles();
    const { t, i18n } = useTranslation("common");
    const theme = useTheme();
    const xsDown = useMediaQuery(theme.breakpoints.down('xs'));
    const mdDown = useMediaQuery(theme.breakpoints.down('md'));
    const {
        brandToEdit,
        brandToValidate,
        loadedCategories,
        newCategories,
        updateBrandToValidateById,
        closeModal,
        loadingUpdateBrandToValidate,
        validateBrandById,
        loadingValidateBrand,
        refuseBrandById,
        loadingRefuseBrand,
        updateBrandById,
        loadingUpdateBrand,
        validateBrandByIdRequest,
        updateBrandToValidateByIdRequest
    } = props
    const [brandNameTitle, setBrandNameTitle] = useState('');
    const [openBasicDialog, setOpenBasicDialog] = useState(false);
    const [openPremiumDialog, setOpenPremiumDialog] = useState(false);
    const [base64BasicLogo, setBase64BasicLogo] = useState('');
    const [basicBrandPictureValue, setBasicBrandPictureValue] = useState('');
    const [base64PremiumLogo, setBase64PremiumLogo] = useState('');
    const [premiumBrandPictureValue, setPremiumBrandPictureValue] = useState('');
    const [imageToCrop, setImageToCrop] = useState(null);
    const [premiumImageToCrop, setPremiumImageToCrop] = useState(null);
    const [imageWidthAspect, setImageWidthAspect] = useState(1);
    const [imageHeightAspect, setImageHeightAspect] = useState(1);
    const [prenomInputValue, setPrenomInputValue] = useState('');
    const [nomInputValue, setNomInputValue] = useState('');
    const [brandNameInputValue, setBrandNameInputValue] = useState('');
    const [emailInputValue, setEmailInputValue] = useState('');
    const [websiteInputValue, setWebsiteInputValue] = useState('');
    const [siretNumberInputValue, setSiretNumberInputValue] = useState('');
    const [addressInputValue, setAddressInputValue] = useState('');
    const [frenchDescInputValue, setFrenchDescInputValue] = useState('');
    const [englishDescInputValue, setEnglishDescInputValue] = useState('');
    const [advertiserInputValue, setAdvertiserInputValue] = useState('');
    const [offerIdInputValue, setOfferIdInputValue] = useState('');
    const [basicCommissionsInputValue, setBasicCommissionsInputValue] = useState('');
    const [generalCommissionsInputValue, setGeneralCommissionsInputValue] = useState('');
    const [smiCommissionsInputValue, setSmiCommissionsInputValue] = useState('');
    const [privateValue, setPrivateValue] = useState(false);
    const [premiumValue, setPremiumValue] = useState(false);
    const [calculateCommissionValue, setCalculateCommissionValue] = useState(false);
    const [defaultDomainTrackingValue, setDefaultDomainTrackingValue] = useState(false);
    const [isPrivateAndroidAppValue, setIsPrivateAndroidAppValue] = useState(false);
    const [isPrivateIOSAppValue, setIsPrivateIOSAppValue] = useState(false);
    const [hasAdvertiser, setHasAdvertiser] = useState(false);
    const [hasGifting, setHasGifting] = useState(false);
    const [isPrivateCampaign, setIsPrivateCampaign] = useState(false);
    const [categoriesValue, setCategoriesValue] = useState([]);
    const [countryDeliveryValue, setCountryDeliveryValue] = useState([]);
    const [optionalCommissionsList, setOptionalCommissionsList] = useState([
        {
            commissionFr: '',
            commissionEn: '',
            commissionValue: ''
        }
    ]);
    const [uploadBasicImageLoading, setUploadBasicImageLoading] = useState(false);
    const [uploadPremiumImageLoading, setUploadPremiumImageLoading] = useState(false);
    const [overlayState, setOverlayState] = useState(false);
    const [updateClicked, setUpdateClicked] = useState(false);
    const [unvalidatedFields, setUnvalidatedFields] = useState([]);
    const [validatingWarningState, setValidatingWarningState] = useState(false);
    const [titleWarning, setTitleWarning] = useState("");

    const [provideImageLinkMode, setProvideImageLinkMode] = useState(false);
    const [providePremiumImageLinkMode, setProvidePremiumImageLinkMode] = useState(false);
    const [imageLinkInputValue, setImageLinkInputValue] = useState('');
    const [imagePremiumLinkInputValue, setImagePremiumLinkInputValue] = useState('');

    const handleCloseModal = useCallback(() => {
        closeModal(true);
    }, [closeModal]);

    const handleCancelBasicDialog = () => {
        setOpenBasicDialog(false);
        setBasicBrandPictureValue('');
        setBase64BasicLogo('');
    };

    const handleSaveBasicDialog = (croppedImage) => {
        setOpenBasicDialog(false);
        setBase64BasicLogo(croppedImage);
    };

    const handleCancelPremiumDialog = () => {
        setOpenPremiumDialog(false);
        setPremiumBrandPictureValue('');
        setBase64PremiumLogo('');
    };

    const handleSavePremiumDialog = (croppedImage) => {
        setOpenPremiumDialog(false);
        setBase64PremiumLogo(croppedImage);
    };

    const convertBasicImage = async (event) => {
        const file = event.target.files[0];
        if (file !== undefined) {
            await convertToBase64(file).then(result => {
                setImageToCrop(result);
                setOpenBasicDialog(true);
            });
        } else {
            setBase64BasicLogo('');
            setBasicBrandPictureValue('');
        }
    };

    const convertPremiumImage = async (event) => {
        const file = event.target.files[0];
        if (file !== undefined) {
            await convertToBase64(file).then(result => {
                setPremiumImageToCrop(result);
                setOpenPremiumDialog(true);
            });
        } else {
            setBase64PremiumLogo('');
            setPremiumBrandPictureValue('');
        }
    };

    const convertToBase64 = (file) => {
        return new Promise((resolve, reject) => {
            const fileReader = new FileReader();
            fileReader.readAsDataURL(file);
            fileReader.onload = () => {
                resolve(fileReader.result)
            };
            fileReader.onerror = (error) => {
                reject(error);
            }
        });
    };

    const handleValueChange = id => event => {
        if (id === 'brandBasicPicture') {
            setImageWidthAspect(1);
            setImageHeightAspect(1);
            setBasicBrandPictureValue(event.target.value);
            convertBasicImage(event);
        }
        if (id === 'brandPremiumPicture') {
            setImageWidthAspect(2);
            setImageHeightAspect(1);
            setPremiumBrandPictureValue(event.target.value);
            convertPremiumImage(event);
        }
        if (id === 'prenom') setPrenomInputValue(event.target.value);
        if (id === 'nom') setNomInputValue(event.target.value);
        if (id === 'brandName') setBrandNameInputValue(event.target.value);
        if (id === 'website') setWebsiteInputValue(event.target.value);
        if (id === 'siret-number') setSiretNumberInputValue(event.target.value);
        if (id === 'address') setAddressInputValue(event.target.value);
        if (id === 'desc-fr') setFrenchDescInputValue(event.target.value);
        if (id === 'desc-en') setEnglishDescInputValue(event.target.value);
        if (id === 'advertiser') setAdvertiserInputValue(event.target.value);
        if (id === 'offerId') setOfferIdInputValue(event.target.value);
        if (id === 'basic-commissions') setBasicCommissionsInputValue(event.target.value);
        if (id === 'general-commissions') setGeneralCommissionsInputValue(event.target.value);
        if (id === 'smi-commissions') setSmiCommissionsInputValue(event.target.value);
        if (id === 'categories') setCategoriesValue(event.target.value);
        if (id === 'countryDelivery') setCountryDeliveryValue(event.target.value);
        if (id === 'email') setEmailInputValue(event.target.value);
        if (id === 'image-href-link') setImageLinkInputValue(event.target.value);
        if (id === 'image-premium-href-link') setImagePremiumLinkInputValue(event.target.value);
    };

    const handleOptCommissionChange = (id, index) => (event) => {
        const list = [...optionalCommissionsList];
        if (id === 'frenchCommissionTitle') list[index].commissionFr = event.target.value;
        if (id === 'englishCommissionTitle') list[index].commissionEn = event.target.value;
        if (id === 'commissionValue') list[index].commissionValue = event.target.value;
        setOptionalCommissionsList(list);
    };

    const handleRemoveOptCommission = (index) => {
        const list = [...optionalCommissionsList];
        list.splice(index, 1);
        if (list.length === 0) list.push({
            commissionFr: '',
            commissionEn: '',
            commissionValue: ''
        })
        setOptionalCommissionsList(list);
    };

    const handleAddOptCommission = () => {
        const list = [...optionalCommissionsList];
        list.push({
            commissionFr: '',
            commissionEn: '',
            commissionValue: ''
        });
        setOptionalCommissionsList(list);
    };

    const uploadBasicImage = async (requestBody) => {
        return axios.post("https://us-central1-shopmyinfluens.cloudfunctions.net/firebaseStorage/storage/upload", requestBody)
    };

    const handleCloseWarningDialog = () => {
        setValidatingWarningState(false);
        setUnvalidatedFields([]);
    };

    const toggleImageLinkMode = () => {
        if (!provideImageLinkMode) {
            setBase64BasicLogo(null);
            setBasicBrandPictureValue('');
        }
        setProvideImageLinkMode((prevState) => !prevState);
    };

    const toggleImagePremiumLinkMode = () => {
        if (!providePremiumImageLinkMode) {
            setBase64PremiumLogo(null);
            setPremiumBrandPictureValue('');
        }
        setProvidePremiumImageLinkMode((prevState) => !prevState);
    };

    const saveEditsWithoutValidation = async () => {
        updateBrandToValidateByIdRequest()
        setUpdateClicked(true);
        let updates = {};
        if (provideImageLinkMode) {
            updates['pic'] = imageLinkInputValue
        } else {
            if (base64BasicLogo) {
                setUploadBasicImageLoading(true);
                const cloudinaryPostRequestBody = {
                    image: base64BasicLogo,
                    userType: "brandBasic",
                    public_id: brandToValidate.name,
                };
                await uploadBasicImage(cloudinaryPostRequestBody).then(result => {
                    setUploadBasicImageLoading(false);
                    updates['pic'] = result.data.url;
                }).catch(error => {
                    setUploadBasicImageLoading(false);
                });
            };
        }

        if (providePremiumImageLinkMode) {
            updates['src_premium'] = imagePremiumLinkInputValue;
        } else {
            if (base64PremiumLogo) {
                setUploadPremiumImageLoading(true);
                const cloudinaryPostRequestBody = {
                    image: base64PremiumLogo,
                    userType: "brandPremium",
                    public_id: brandToValidate.brandUser.name,
                };
                await uploadBasicImage(cloudinaryPostRequestBody).then(result => {
                    setUploadPremiumImageLoading(false);
                    updates['src_premium'] = result.data.url;
                }).catch(error => {
                    setUploadPremiumImageLoading(false);
                });
            }
        }

        updates['brandUser/firstName'] = prenomInputValue;
        updates['brandUser/lastName'] = nomInputValue;
        updates['brandUser/email'] = emailInputValue;
        updates['name'] = brandNameInputValue;
        updates['website'] = websiteInputValue;
        updates['siret'] = siretNumberInputValue;
        updates['address'] = addressInputValue;
        updates['categorie'] = categoriesValue.map(cat => cat.key);
        updates['description'] = frenchDescInputValue;
        updates['description-en'] = englishDescInputValue;
        if (advertiserInputValue !== '') {
            updates['platform'] = advertiserInputValue;
        } else {
            updates['platform'] = null;
        }
        updates['offerId'] = offerIdInputValue;
        if (generalCommissionsInputValue !== '' && generalCommissionsInputValue.toString().indexOf('%') === -1) {
            updates['allCommissions/generalCommission'] = generalCommissionsInputValue + '%';
            updates['commissions/commission générale'] = generalCommissionsInputValue + '%';
            updates['commissions-en/general commission'] = generalCommissionsInputValue + '%';
        } else if (generalCommissionsInputValue !== '' && generalCommissionsInputValue.toString().indexOf('%') !== -1) {
            updates['allCommissions/generalCommission'] = generalCommissionsInputValue;
            updates['commissions/commission générale'] = generalCommissionsInputValue;
            updates['commissions-en/general commission'] = generalCommissionsInputValue;
        }

        if (basicCommissionsInputValue !== '' && basicCommissionsInputValue.toString().indexOf('%') === -1) {
            updates['allCommissions/basicCommission'] = basicCommissionsInputValue + '%';
            updates['basicCommission'] = basicCommissionsInputValue + '%';
        } else if (basicCommissionsInputValue !== '' && basicCommissionsInputValue.toString().indexOf('%') !== -1) {
            updates['allCommissions/basicCommission'] = basicCommissionsInputValue;
            updates['basicCommission'] = basicCommissionsInputValue;
        }

        if (smiCommissionsInputValue !== '' && smiCommissionsInputValue.toString().indexOf('%') === -1) {
            updates['allCommissions/agencyCommission'] = smiCommissionsInputValue + '%';
            updates['agencyCommission'] = smiCommissionsInputValue + '%';
        } else if (smiCommissionsInputValue !== '' && smiCommissionsInputValue.toString().indexOf('%') !== -1) {
            updates['allCommissions/agencyCommission'] = smiCommissionsInputValue;
            updates['agencyCommission'] = smiCommissionsInputValue;
        }

        updates['commissions/optionalCommissions'] = optionalCommissionsList;
        updates['private'] = privateValue;
        updates['premium'] = premiumValue;
        updates['calculateCommisison'] = calculateCommissionValue;
        updates['defaultTrackingDomain'] = defaultDomainTrackingValue;
        updates['privateIOSApp'] = isPrivateIOSAppValue;
        updates['privateAndroidApp'] = isPrivateAndroidAppValue;
        updates['hasGifting'] = hasGifting;
        updates['hasAdvertiser'] = hasAdvertiser;
        updates['isPrivateCampaign'] = isPrivateCampaign;
        updates['deliveryCountry'] = countryDeliveryValue.map(item => item.code).join(' ');
        updates['createdAt'] = brandToValidate.createdAt;
        updateBrandToValidateById(brandToValidate.key, updates);
    };

    const validateBrand = async () => {
        let validatingArray = [];
        if (nomInputValue === '') validatingArray.push(t('ADMIN.VIEWS.PARTNERS.BRANDS.BRAND_FORM.LAST_NAME'));
        if (prenomInputValue === '') validatingArray.push(t('ADMIN.VIEWS.PARTNERS.BRANDS.BRAND_FORM.FIRST_NAME'));
        if (brandNameInputValue === '') validatingArray.push(t('ADMIN.VIEWS.PARTNERS.BRANDS.BRAND_FORM.BRAND_NAME'));
        if (emailInputValue === '') validatingArray.push(t('ADMIN.VIEWS.PARTNERS.BRANDS.BRAND_FORM.EMAIL'));
        if (websiteInputValue === '') validatingArray.push(t('ADMIN.VIEWS.PARTNERS.BRANDS.BRAND_FORM.WEBSITE'));
        if (siretNumberInputValue === '') validatingArray.push(t('ADMIN.VIEWS.PARTNERS.BRANDS.BRAND_FORM.SIRET_NUMBER'));
        if (categoriesValue.length === 0) validatingArray.push(t('ADMIN.VIEWS.PARTNERS.BRANDS.BRAND_FORM.CATEGORIES'));
        if (frenchDescInputValue === '') validatingArray.push(t('ADMIN.VIEWS.PARTNERS.BRANDS.BRAND_FORM.FRENCH_DESCRIPTION'));
        if (englishDescInputValue === '') validatingArray.push(t('ADMIN.VIEWS.PARTNERS.BRANDS.BRAND_FORM.ENGLISH_DESCRIPTION'));
        if (offerIdInputValue === '' || isNaN(offerIdInputValue)) validatingArray.push(t('ADMIN.VIEWS.PARTNERS.BRANDS.BRAND_FORM.OFFER_ID'));
        if (generalCommissionsInputValue === '') validatingArray.push(t('ADMIN.VIEWS.PARTNERS.BRANDS.BRAND_FORM.GENERAL_COMMISSION'));
        if (premiumValue === true && brandToValidate.src_premium === undefined && base64PremiumLogo === '')
            validatingArray.push(t('ADMIN.VIEWS.PARTNERS.BRANDS.BRAND_FORM.IMAGE_PREMIUM'));
        optionalCommissionsList.forEach((commission, index) => {
            let notEmpty = 0;
            if (optionalCommissionsList.length === 1) {
                Object.values(commission).forEach(value => {
                    if (value !== "") notEmpty++;
                });
                if (notEmpty !== 3 && notEmpty !== 0) validatingArray.push(t('ADMIN.VIEWS.PARTNERS.BRANDS.BRAND_FORM.OPTIONAL_COMMISSION') + ' ' + (index + 1));
            } else {
                Object.values(commission).forEach(value => {
                    if (value !== "") notEmpty++;
                });
                if (notEmpty !== 3) validatingArray.push(t('ADMIN.VIEWS.PARTNERS.BRANDS.BRAND_FORM.OPTIONAL_COMMISSION') + ' ' + (index + 1));
            }
        })
        if (brandToValidate.pic === undefined && base64BasicLogo === '' && imageLinkInputValue) validatingArray.push(t('ADMIN.VIEWS.PARTNERS.BRANDS.BRAND_FORM.IMAGE'));
        if (countryDeliveryValue.map(item => item.code).join(' ') === '') validatingArray.push(t('ADMIN.VIEWS.PARTNERS.BRANDS.BRAND_FORM.DELIVERY_COUNTRY'));
        if (validatingArray.length !== 0) {
            setValidatingWarningState(true);
            setUnvalidatedFields(validatingArray);
        } else {
            setUpdateClicked(true);
            validateBrandByIdRequest();
            let brand = {
                allCommissions: {
                    generalCommission: generalCommissionsInputValue.toString().indexOf('%') === -1 ? generalCommissionsInputValue + '%' : generalCommissionsInputValue,
                    basicCommission: basicCommissionsInputValue === '' ? null : basicCommissionsInputValue.toString().indexOf('%') === -1 ? basicCommissionsInputValue + '%' : basicCommissionsInputValue,
                    agencyCommission: smiCommissionsInputValue === '' ? null : smiCommissionsInputValue.toString().indexOf('%') === -1 ? smiCommissionsInputValue + '%' : smiCommissionsInputValue,
                },
                basicCommission: basicCommissionsInputValue === '' ? null : basicCommissionsInputValue.toString().indexOf('%') === -1 ? basicCommissionsInputValue + '%' : basicCommissionsInputValue,
                agencyCommission: smiCommissionsInputValue === '' ? null : smiCommissionsInputValue.toString().indexOf('%') === -1 ? smiCommissionsInputValue + '%' : smiCommissionsInputValue,
                description: frenchDescInputValue,
                displayName: brandNameInputValue,
                href: websiteInputValue,
                localisation: countryDeliveryValue.map(item => item.code).join(' '),
                premium: premiumValue,
                private: privateValue,
                defaultTrackingDomain: defaultDomainTrackingValue,
                privateAndroidApp: isPrivateAndroidAppValue,
                privateIOSAppValue: isPrivateIOSAppValue,
                hasGifting: hasGifting,
                hasAdvertiser: hasAdvertiser,
                isPrivateCampaign: isPrivateCampaign,
                calcCommissionManually: calculateCommissionValue,
                name: brandNameInputValue.replace(/[^\w]/g, "").toLowerCase(),
                offerId: Number(offerIdInputValue),
                createdAt: brandToValidate.createdAt,
                siret: siretNumberInputValue,
                creatorUser: brandToValidate.brandUser.uid,
                creatorUserEmail: emailInputValue,
                categ: categoriesValue.map(cat => cat.key),
            }
            brand['description-en'] = englishDescInputValue;
            brand['commissions'] = {};
            if (generalCommissionsInputValue !== '' && generalCommissionsInputValue.toString().indexOf('%') === -1) {
                brand['commissions']['commission générale'] = generalCommissionsInputValue + '%';
            } else if (generalCommissionsInputValue !== '' && generalCommissionsInputValue.toString().indexOf('%') !== -1) {
                brand['commissions']['commission générale'] = generalCommissionsInputValue;
            } else {
                brand['commissions']['commission générale'] = '0%';
            };

            brand['commissions-en'] = {};
            if (generalCommissionsInputValue !== '' && generalCommissionsInputValue.toString().indexOf('%') === -1) {
                brand['commissions-en']['general commission'] = generalCommissionsInputValue + '%';
            } else if (generalCommissionsInputValue !== '' && generalCommissionsInputValue.toString().indexOf('%') !== -1) {
                brand['commissions-en']['general commission'] = generalCommissionsInputValue;
            } else {
                brand['commissions-en']['general commission'] = '0%';
            };
            if (addressInputValue !== '') brand['address'] = addressInputValue;
            if (advertiserInputValue !== '') brand['advertiser'] = advertiserInputValue;
            let categoriesFr = {};
            let categoriesEn = {};
            categoriesValue.forEach(cat => {
                categoriesFr[cat['text-fr']] = 1;
                categoriesEn[cat['text-en']] = 1;
            })
            brand['categories'] = categoriesFr;
            brand['categories-en'] = categoriesEn;
            if (optionalCommissionsList.length === 1) {
                let notEmpty = false;
                Object.values(optionalCommissionsList[0]).forEach(value => {
                    if (value !== "") notEmpty = true;
                });
                if (notEmpty) {
                    brand.allCommissions['optionalCommissions'] = optionalCommissionsList;
                    brand.commissions[optionalCommissionsList[0].commissionFr] = optionalCommissionsList[0].commissionValue;
                    brand['commissions-en'][optionalCommissionsList[0].commissionEn] = optionalCommissionsList[0].commissionValue;
                };
            } else {
                optionalCommissionsList.forEach(comm => {
                    brand.commissions[comm.commissionFr] = comm.commissionValue;
                    brand['commissions-en'][comm.commissionEn] = comm.commissionValue;
                })
            };
            if (base64BasicLogo === '') {
                brand['pic'] = brandToValidate.pic;
            } else {
                if (provideImageLinkMode) {
                    brand['pic'] = imageLinkInputValue
                } else {
                    // UPLOAD NEW BASIC IMAGE
                    setUploadBasicImageLoading(true);
                    const cloudinaryPostRequestBody = {
                        image: base64BasicLogo,
                        userType: "brandBasic",
                        public_id: brandToValidate.name,
                    };
                    await uploadBasicImage(cloudinaryPostRequestBody).then(result => {
                        setUploadBasicImageLoading(false);
                        brand['pic'] = result.data.url;
                    }).catch(error => {
                        setUploadBasicImageLoading(false);
                    });
                }
            }
            if (brandToValidate.src_premium === undefined) {
                if (base64PremiumLogo !== '') {
                    if (providePremiumImageLinkMode) {
                        brand['src_premium'] = imagePremiumLinkInputValue;
                    } else {
                        // UPLOAD NEW PREMIUM IMAGE
                        setUploadPremiumImageLoading(true);
                        const cloudinaryPostRequestBody = {
                            image: base64PremiumLogo,
                            userType: "brandPremium",
                            public_id: brandToValidate.name,
                        };
                        await uploadBasicImage(cloudinaryPostRequestBody).then(result => {
                            setUploadPremiumImageLoading(false);
                            brand['src_premium'] = result.data.url;
                        }).catch(error => {
                            setUploadPremiumImageLoading(false);
                        });
                    }
                }
            } else {
                if (base64PremiumLogo !== '') {
                    if (providePremiumImageLinkMode) {
                        brand['src_premium'] = imagePremiumLinkInputValue
                    } else {
                        // UPLOAD NEW PREMIUM IMAGE
                        const cloudinaryPostRequestBody = {
                            image: base64PremiumLogo,
                            userType: "brandPremium",
                            public_id: brandToValidate.name,
                        };
                        await uploadBasicImage(cloudinaryPostRequestBody).then(result => {
                            setUploadPremiumImageLoading(false);
                            brand['src_premium'] = result.data.url;
                        }).catch(error => {
                            setUploadPremiumImageLoading(false);
                        });
                    }
                } else {
                    brand['src_premium'] = brandToValidate.src_premium;
                }
            }
            let userData = {
                firstName: prenomInputValue,
                lastName: nomInputValue,
                uid: brandToValidate.brandUser.uid,
                email: brandToValidate.brandUser.email,
                offerId: offerIdInputValue
            }
            validateBrandById(brandToValidate.key, brand, brandToValidate.brandUser.uid, userData);
        }
    };

    const refuseBrand = async () => {
        setUpdateClicked(true);
        refuseBrandById(brandToValidate.key);
    };

    const editBrand = async () => {
        let validatingArray = [];
        let updates = {};
        if (websiteInputValue === '') validatingArray.push(t('ADMIN.VIEWS.PARTNERS.BRANDS.BRAND_FORM.WEBSITE'));
        if (generalCommissionsInputValue === '') validatingArray.push('general commission')
        optionalCommissionsList.forEach((commission, index) => {
            let notEmpty = 0;
            if (optionalCommissionsList.length === 1) {
                Object.values(commission).forEach(value => {
                    if (value !== "") notEmpty++;
                });
                if (notEmpty !== 3 && notEmpty !== 0) validatingArray.push(t('ADMIN.VIEWS.PARTNERS.BRANDS.BRAND_FORM.OPTIONAL_COMMISSION') + ' ' + (index + 1));
            } else {
                Object.values(commission).forEach(value => {
                    if (value !== "") notEmpty++;
                });
                if (notEmpty !== 3) validatingArray.push(t('ADMIN.VIEWS.PARTNERS.BRANDS.BRAND_FORM.OPTIONAL_COMMISSION') + ' ' + (index + 1));
            }
        });

        if (brandNameInputValue !== '') {
            updates['name'] = brandNameInputValue.replace(/[^\w]/g, "").toLowerCase();
            updates['displayName'] = brandNameInputValue;
        }
        if (websiteInputValue !== '') {
            updates['href'] = websiteInputValue;
        }
        if (siretNumberInputValue !== '') {
            updates['siret'] = siretNumberInputValue;
        }
        if (addressInputValue !== '') {
            updates['address'] = addressInputValue;
        }
        if (frenchDescInputValue !== '') {
            updates['description'] = frenchDescInputValue;
        }
        if (englishDescInputValue !== '') {
            updates['description-en'] = englishDescInputValue;
        }
        if (advertiserInputValue !== '') {
            updates['advertiser'] = advertiserInputValue;
        }
        if (offerIdInputValue !== '') {
            updates['offerId'] = offerIdInputValue;
        }

        if (generalCommissionsInputValue !== '' && generalCommissionsInputValue.toString().indexOf('%') === -1) {
            updates['allCommissions/generalCommission'] = generalCommissionsInputValue + '%';
            updates['commissions/commission générale'] = generalCommissionsInputValue + '%';
            updates['commissions-en/general commission'] = generalCommissionsInputValue + '%';
        } else if (generalCommissionsInputValue !== '' && generalCommissionsInputValue.toString().indexOf('%') !== -1) {
            updates['allCommissions/generalCommission'] = generalCommissionsInputValue;
            updates['commissions/commission générale'] = generalCommissionsInputValue;
            updates['commissions-en/general commission'] = generalCommissionsInputValue;
        }

        if (basicCommissionsInputValue !== '' && basicCommissionsInputValue.toString().indexOf('%') === -1) {
            updates['allCommissions/basicCommission'] = basicCommissionsInputValue + '%';
            updates['basicCommission'] = basicCommissionsInputValue + '%';
        } else if (basicCommissionsInputValue !== '' && basicCommissionsInputValue.toString().indexOf('%') !== -1) {
            updates['allCommissions/basicCommission'] = basicCommissionsInputValue;
            updates['basicCommission'] = basicCommissionsInputValue;
        }

        if (smiCommissionsInputValue !== '' && smiCommissionsInputValue.toString().indexOf('%') === -1) {
            updates['allCommissions/agencyCommission'] = smiCommissionsInputValue + '%';
            updates['agencyCommission'] = smiCommissionsInputValue + '%';
        } else if (smiCommissionsInputValue !== '' && smiCommissionsInputValue.toString().indexOf('%') !== -1) {
            updates['allCommissions/agencyCommission'] = smiCommissionsInputValue;
            updates['agencyCommission'] = smiCommissionsInputValue;
        }

        if (optionalCommissionsList.length === 1) {
            let notEmpty = false;
            Object.values(optionalCommissionsList[0]).forEach(value => {
                if (value !== "") notEmpty = true;
            });
            if (notEmpty) {
                updates['allCommissions/optionalCommissions'] = optionalCommissionsList;
                updates['commissions/' + optionalCommissionsList[0].commissionFr] = optionalCommissionsList[0].commissionValue;
                updates['commissions-en/' + optionalCommissionsList[0].commissionEn] = optionalCommissionsList[0].commissionValue;
            } else {
                updates['allCommissions/optionalCommissions'] = null;
            };
        } else {
            optionalCommissionsList.forEach(comm => {
                updates['commissions/' + comm.commissionFr] = comm.commissionValue;
                updates['commissions-en/' + comm.commissionEn] = comm.commissionValue;
            })
        };

        updates['private'] = privateValue;
        updates['premium'] = premiumValue;
        updates['calcCommissionManually'] = calculateCommissionValue;
        updates['defaultTrackingDomain'] = defaultDomainTrackingValue;
        updates['privateIOSApp'] = isPrivateIOSAppValue;
        updates['privateAndroidApp'] = isPrivateAndroidAppValue;
        updates['hasGifting'] = hasGifting;
        updates['hasAdvertiser'] = hasAdvertiser;
        updates['isPrivateCampaign'] = isPrivateCampaign;
        updates['localisation'] = countryDeliveryValue.map(item => item.code).join(' ');
        updates['categ'] = categoriesValue.map(cat => cat.key);

        if (provideImageLinkMode) {
            updates['pic'] = imageLinkInputValue
        } else {
            if (base64BasicLogo) {
                setUploadBasicImageLoading(true);
                const cloudinaryPostRequestBody = {
                    image: base64BasicLogo,
                    userType: "brandBasic",
                    public_id: brandToEdit.name,
                };
                await uploadBasicImage(cloudinaryPostRequestBody).then(result => {
                    setUploadBasicImageLoading(false);
                    updates['pic'] = result.data.url;
                }).catch(error => {
                    setUploadBasicImageLoading(false);
                });
            };
        }

        if (providePremiumImageLinkMode) {
            updates['src_premium'] = imagePremiumLinkInputValue;
        } else {
            if (base64PremiumLogo) {
                setUploadPremiumImageLoading(true);
                const cloudinaryPostRequestBody = {
                    image: base64PremiumLogo,
                    userType: "brandPremium",
                    public_id: brandToEdit.name,
                };
                await uploadBasicImage(cloudinaryPostRequestBody).then(result => {
                    setUploadPremiumImageLoading(false);
                    updates['src_premium'] = result.data.url;
                }).catch(error => {
                    setUploadPremiumImageLoading(false);
                });
            };
        }

        let categoriesFr = {};
        let categoriesEn = {};
        categoriesValue.forEach(cat => {
            categoriesFr[cat['text-fr']] = 1;
            categoriesEn[cat['text-en']] = 1;
        })
        updates['categories'] = categoriesFr;
        updates['categories-en'] = categoriesEn;

        if (validatingArray.length !== 0) {
            setValidatingWarningState(true);
            setUnvalidatedFields(validatingArray);
        } else {
            setUpdateClicked(true);
            updateBrandById(brandToEdit.key, updates);
        }

    };

    useEffect(() => {
        setTitleWarning(t('ADMIN.VIEWS.PARTNERS.BRANDS.WARNING_VALIDATION_MESSAGE'));
    }, [t])
    useEffect(() => {
        if (privateValue) {
            setIsPrivateAndroidAppValue(true);
            setIsPrivateIOSAppValue(true);
        }
    }, [privateValue])

    useEffect(() => {
        if (!isPrivateIOSAppValue || !isPrivateAndroidAppValue) {
            setPrivateValue(false)
        }
    }, [isPrivateIOSAppValue, isPrivateAndroidAppValue])

    useEffect(() => {
        if (uploadBasicImageLoading || uploadPremiumImageLoading || loadingUpdateBrandToValidate || loadingValidateBrand || loadingRefuseBrand || loadingUpdateBrand) {
            setOverlayState(true);
        }
        if (!uploadBasicImageLoading && !uploadPremiumImageLoading && updateClicked && !loadingUpdateBrandToValidate && !loadingValidateBrand && !loadingRefuseBrand && !loadingUpdateBrand) {
            setOverlayState(false);
            setUpdateClicked(false);
            handleCloseModal();
        }
    }, [uploadPremiumImageLoading, uploadBasicImageLoading, updateClicked, handleCloseModal, loadingUpdateBrandToValidate, loadingValidateBrand, loadingRefuseBrand, loadingUpdateBrand])

    // Prepoulate Edit Values
    useEffect(() => {
        if (brandToEdit) {
            // if (brandToEdit.firstName) setPrenomInputValue(brandToEdit.firstName);
            // if (brandToEdit.lastName) setNomInputValue(brandToEdit.lastName);
            setBrandNameTitle(brandToEdit.displayName);
            setBrandNameInputValue(brandToEdit.displayName);
            setWebsiteInputValue(brandToEdit.href);
            if (brandToEdit.creatorUserEmail) setEmailInputValue(brandToEdit.creatorUserEmail);
            if (brandToEdit.siret) setSiretNumberInputValue(brandToEdit.siret);
            if (brandToEdit.address) setAddressInputValue(brandToEdit.address);
            if (brandToEdit.advertiser) setAdvertiserInputValue(brandToEdit.advertiser);
            if (brandToEdit.description) setFrenchDescInputValue(brandToEdit.description);
            if (brandToEdit['description-en']) setEnglishDescInputValue(brandToEdit['description-en']);
            setOfferIdInputValue(brandToEdit.offerId);
            if (brandToEdit.commissions['commission générale']) setGeneralCommissionsInputValue(brandToEdit.commissions['commission générale']);
            if (brandToEdit.basicCommission) setBasicCommissionsInputValue(brandToEdit.basicCommission);
            if (brandToEdit.agencyCommission) setSmiCommissionsInputValue(brandToEdit.agencyCommission);
            if (brandToEdit.allCommissions && brandToEdit.allCommissions.optionalCommissions) setOptionalCommissionsList(brandToEdit.allCommissions.optionalCommissions);
            if (brandToEdit.private) setPrivateValue(brandToEdit.private);
            if (brandToEdit.premium) setPremiumValue(brandToEdit.premium);
            if (brandToEdit.calcCommissionManually) setCalculateCommissionValue(brandToEdit.calcCommissionManually);
            if (brandToEdit.defaultTrackingDomain) setDefaultDomainTrackingValue(brandToEdit.defaultTrackingDomain);
            if (brandToEdit.privateIOSApp) setIsPrivateIOSAppValue(brandToEdit.privateIOSApp);
            if (brandToEdit.privateAndroidApp) setIsPrivateAndroidAppValue(brandToEdit.privateAndroidApp);
            if (brandToEdit.hasAdvertiser) setHasAdvertiser(brandToEdit.hasAdvertiser);
            if (brandToEdit.hasGifting) setHasGifting(brandToEdit.hasGifting);
            if (brandToEdit.isPrivateCampagne) setIsPrivateCampaign(brandToEdit.isPrivateCampagne);
            let localisationValue = [];
            if (brandToEdit.localisation) {
                brandToEdit.localisation.split(' ').forEach(countryCode => {
                    let country = countriesDB.filter(country => country.code === countryCode)[0];
                    localisationValue.push(country)
                });
            }
            setCountryDeliveryValue(localisationValue);
            if (brandToEdit.categ) {
                let categoriesValue = [];
                brandToEdit.categ.forEach(categoryKey => {
                    categoriesValue.push(newCategories.filter(cat => cat.key === categoryKey)[0]);
                })
                setCategoriesValue(categoriesValue);
            };
            if (brandToEdit.pic) {
                setProvideImageLinkMode(true);
                setImageLinkInputValue(brandToEdit.pic);
            };
            if (brandToEdit.src_premium) {
                setProvidePremiumImageLinkMode(true);
                setImagePremiumLinkInputValue(brandToEdit.src_premium);
            };
        }
    }, [brandToEdit, newCategories]);

    useEffect(() => {
        if (brandToValidate) {
            if (brandToValidate.brandUser.firstName) setPrenomInputValue(brandToValidate.brandUser.firstName);
            if (brandToValidate.brandUser.lastName) setNomInputValue(brandToValidate.brandUser.lastName);
            if (brandToValidate.brandUser.email) setEmailInputValue(brandToValidate.brandUser.email);
            if (brandToValidate.platform) setAdvertiserInputValue(brandToValidate.platform);
            if (brandToValidate.description) setFrenchDescInputValue(brandToValidate.description);
            if (brandToValidate['description-en']) setEnglishDescInputValue(brandToValidate['description-en']);
            if (brandToValidate.private !== undefined) setPrivateValue(brandToValidate.private);
            if (brandToValidate.commissions) {
                if (brandToValidate.commissions.basicCommission && brandToValidate.commissions.basicCommission !== "%") {
                    setBasicCommissionsInputValue(parseFloat(brandToValidate.commissions.basicCommission));
                }
                if (brandToValidate.commissions.generalCommission && brandToValidate.commissions.generalCommission !== "%")
                    setGeneralCommissionsInputValue(parseFloat(brandToValidate.commissions.generalCommission));
                if (brandToValidate.commissions.optionalCommissions) setOptionalCommissionsList(brandToValidate.commissions.optionalCommissions);
                if (brandToValidate.commissions.SMICommission && brandToValidate.commissions.SMICommission !== "%")
                    setSmiCommissionsInputValue(parseFloat(brandToValidate.commissions.SMICommission));
            };
            if (brandToValidate.premium !== undefined) setPremiumValue(brandToValidate.premium);
            if (brandToValidate.calculateCommisison !== undefined) setCalculateCommissionValue(brandToValidate.calculateCommisison);
            if (brandToValidate.defaultTrackingDomain !== undefined) setDefaultDomainTrackingValue(brandToValidate.defaultTrackingDomain);
            if (brandToValidate.privateAndroidApp !== undefined) setIsPrivateAndroidAppValue(brandToValidate.privateAndroidApp);
            if (brandToValidate.privateIOSApp !== undefined) setIsPrivateIOSAppValue(brandToValidate.privateIOSApp);
            if (brandToValidate.hasAdvertiser !== undefined) setHasAdvertiser(brandToValidate.hasAdvertiser);
            if (brandToValidate.hasGifting !== undefined) setHasGifting(brandToValidate.hasGifting);
            if (brandToValidate.isPrivateCampagne !== undefined) setIsPrivateCampaign(brandToValidate.isPrivateCampagne);
            if (brandToValidate.address) setAddressInputValue(brandToValidate.address);
            if (brandToValidate.offerId) setOfferIdInputValue(brandToValidate.offerId);
            if (brandToValidate.pic) {
                setProvideImageLinkMode(true);
                setImageLinkInputValue(brandToValidate.pic);
            };
            if (brandToValidate.src_premium) {
                setProvidePremiumImageLinkMode(true);
                setImagePremiumLinkInputValue(brandToValidate.src_premium);
            };
            setBrandNameTitle(brandToValidate.name);
            setBrandNameInputValue(brandToValidate.name);
            setWebsiteInputValue(brandToValidate.website);
            setSiretNumberInputValue(brandToValidate.siret);
            let localisationValue = [];
            brandToValidate.deliveryCountry.split(' ').forEach(countryCode => {
                let country = countriesDB.filter(country => country.code === countryCode)[0];
                localisationValue.push(country)
            });
            setCountryDeliveryValue(localisationValue);
            let categoriesValue = [];
            brandToValidate.categorie.forEach(categoryKey => {
                categoriesValue.push(newCategories.filter(cat => cat.key === categoryKey)[0]);
            })
            setCategoriesValue(categoriesValue);
        }
    }, [brandToValidate, newCategories]);

    return (
        <div>
            <Backdrop className={classes.overlay} open={overlayState}>
                <CircularProgress color='secondary' />
            </Backdrop>
            <Dialog className={classes.warningDialog} open={validatingWarningState} onClose={handleCloseWarningDialog}>
                <WarningDialog unvalidatedFields={unvalidatedFields} titleWarning={titleWarning} />
            </Dialog>
            <Grid container item direction='row' xs={12} justify='center' alignItems='center'>
                <Grid container item direction='row' xs={11} justify='center' alignItems='center' className={classes.overallBox}>
                    <Grid container item direction='row' alignItems='center' justify='flex-end' xs={12}>
                        <IconButton style={{ marginTop: '10px', marginRight: '10px', color: '#000' }} onClick={handleCloseModal}>
                            <CloseIcon fontSize='large' />
                        </IconButton>
                    </Grid>
                    <Grid container item direction='row' alignItems='center' justify='center' xs={12}>
                        <Typography className={classes.formTitle}>{brandNameTitle}</Typography>
                    </Grid>
                    <Grid container item direction='row' xs={11} className={classes.formBox}>
                        {
                            (brandToEdit)
                                ? <></>
                                : <>
                                    <Grid container item direction='column' justify='flex-start' alignItems='flex-start' className={classes.formControlRow} xs={12} >
                                        <Typography className={classes.formLabel}>
                                            {t('ADMIN.VIEWS.PARTNERS.BRANDS.BRAND_FORM.LAST_NAME')}
                                        </Typography>
                                        <FormControl className={classes.formControl}>
                                            <InputBase
                                                className={classes.formInput}
                                                placeholder={t('ADMIN.VIEWS.PARTNERS.BRANDS.BRAND_FORM.LAST_NAME')}
                                                id='prenom'
                                                type='text'
                                                value={prenomInputValue}
                                                onChange={handleValueChange('prenom')} />
                                        </FormControl>
                                    </Grid>
                                    <Grid container item direction='column' justify='flex-start' alignItems='flex-start' className={classes.formControlRow} xs={12} >
                                        <Typography className={classes.formLabel}>
                                            {t('ADMIN.VIEWS.PARTNERS.BRANDS.BRAND_FORM.FIRST_NAME')}
                                        </Typography>
                                        <FormControl className={classes.formControl}>
                                            <InputBase
                                                className={classes.formInput}
                                                placeholder={t('ADMIN.VIEWS.PARTNERS.BRANDS.BRAND_FORM.FIRST_NAME')}
                                                id='prenom'
                                                type='nom'
                                                value={nomInputValue}
                                                onChange={handleValueChange('nom')} />
                                        </FormControl>
                                    </Grid>
                                </>
                        }
                        {
                            ((brandToEdit && brandToEdit.creatorUserEmail) || brandToValidate)
                                ? <>
                                    <Grid container item direction='column' justify='flex-start' alignItems='flex-start' className={classes.formControlRow} xs={12}>
                                        <Typography className={classes.formLabel}>
                                            {t('ADMIN.VIEWS.PARTNERS.BRANDS.BRAND_FORM.EMAIL')}
                                        </Typography>
                                        <FormControl className={classes.formControl}>
                                            <InputBase
                                                className={classes.formInput}
                                                placeholder={t('ADMIN.VIEWS.PARTNERS.BRANDS.BRAND_FORM.EMAIL')}
                                                id='email'
                                                type='text'
                                                value={emailInputValue}
                                                onChange={handleValueChange('email')} />
                                        </FormControl>
                                    </Grid>
                                </>
                                : <></>
                        }
                        <Grid container item direction='column' justify='flex-start' alignItems='flex-start' className={classes.formControlRow} xs={12} >
                            <Typography className={classes.formLabel}>
                                {t('ADMIN.VIEWS.PARTNERS.BRANDS.BRAND_FORM.BRAND_NAME')}
                            </Typography>
                            <FormControl className={classes.formControl}>
                                <InputBase
                                    className={classes.formInput}
                                    placeholder={t('ADMIN.VIEWS.PARTNERS.BRANDS.BRAND_FORM.BRAND_NAME')}
                                    id='brandName'
                                    type='text'
                                    value={brandNameInputValue}
                                    onChange={handleValueChange('brandName')} />
                            </FormControl>
                        </Grid>
                        <Grid container item direction='column' justify='flex-start' alignItems='flex-start' className={classes.formControlRow} xs={12} >
                            <Typography className={classes.formLabel}>
                                {t('ADMIN.VIEWS.PARTNERS.BRANDS.BRAND_FORM.WEBSITE')}* (href)
                            </Typography>
                            <FormControl className={classes.formControl}>
                                <InputBase
                                    className={classes.formInput}
                                    placeholder={t('ADMIN.VIEWS.PARTNERS.BRANDS.BRAND_FORM.WEBSITE')}
                                    id='website'
                                    type='text'
                                    value={websiteInputValue}
                                    onChange={handleValueChange('website')} />
                            </FormControl>
                        </Grid>
                        <Grid container item direction='column' justify='flex-start' alignItems='flex-start' className={classes.formControlRow} xs={12} >
                            <Typography className={classes.formLabel}>
                                {t('ADMIN.VIEWS.PARTNERS.BRANDS.BRAND_FORM.SIRET_NUMBER')}
                            </Typography>
                            <FormControl className={classes.formControl}>
                                <InputBase
                                    className={classes.formInput}
                                    placeholder={t('ADMIN.VIEWS.PARTNERS.BRANDS.BRAND_FORM.SIRET_NUMBER')}
                                    id='siret-number'
                                    type='text'
                                    value={siretNumberInputValue}
                                    onChange={handleValueChange('siret-number')} />
                            </FormControl>
                        </Grid>
                        <Grid container item direction='column' justify='flex-start' alignItems='flex-start' className={classes.formControlRow} xs={12} >
                            <Typography className={classes.formLabel}>
                                {t('ADMIN.VIEWS.PARTNERS.BRANDS.BRAND_FORM.ADDRESS')}
                            </Typography>
                            <FormControl className={classes.formControl}>
                                <InputBase
                                    className={classes.formInput}
                                    placeholder={t('ADMIN.VIEWS.PARTNERS.BRANDS.BRAND_FORM.ADDRESS')}
                                    id='address'
                                    type='text'
                                    value={addressInputValue}
                                    onChange={handleValueChange('address')} />
                            </FormControl>
                        </Grid>
                        <Grid container item direction='column' justify='flex-start' alignItems='flex-start' className={classes.formControlRow} xs={12} >
                            <Typography className={classes.formLabel}>
                                {t('ADMIN.VIEWS.PARTNERS.BRANDS.BRAND_FORM.CATEGORIES')}*
                            </Typography>
                            <FormControl className={classes.formControl}>
                                <Select
                                    multiple
                                    displayEmpty
                                    value={categoriesValue}
                                    onChange={handleValueChange('categories')}
                                    classes={{ root: classes.selectClass }}
                                    input={<InputBase className={classes.formInput} style={{ marginTop: '10px', marginBottom: '10px' }} />}
                                    renderValue={(selected) => {
                                        if (selected.length === 0) {
                                            return <Typography style={{ fontFamily: 'Poppins', color: '#717171' }}>
                                                {t('ADMIN.VIEWS.PARTNERS.BRANDS.BRAND_FORM.CATEGORIES')}
                                            </Typography>;
                                        }
                                        return selected.map(cat => (i18n.language === 'fr-FR' || i18n.language === 'fr') ? cat['text-fr'] : cat['text-en']).join(", ");
                                    }}
                                    IconComponent={(loadedCategories) ? CustomExpandMore : CustomLoader}>
                                    {newCategories.map(category => (
                                        <MenuItem
                                            value={category}
                                            style={{ fontFamily: "Poppins" }}
                                            classes={{ selected: classes.selectedMenuItems }}
                                            key={category.key}
                                        >
                                            {(i18n.language === 'fr-FR' || i18n.language === 'fr') ? category['text-fr'] : category['text-en']}
                                        </MenuItem>
                                    ))}
                                </Select>
                            </FormControl>
                        </Grid>
                        <Grid container item direction='column' justify='flex-start' alignItems='flex-start' className={classes.formControlRow} xs={12} >
                            <Typography className={classes.formLabel}>{t('ADMIN.VIEWS.PARTNERS.BRANDS.BRAND_FORM.FRENCH_DESCRIPTION')}*</Typography>
                            <FormControl className={classes.formControl}>
                                <InputBase
                                    className={classes.formInput}
                                    placeholder={t('ADMIN.VIEWS.PARTNERS.BRANDS.BRAND_FORM.FRENCH_DESCRIPTION')}
                                    id='desc-fr'
                                    type='text'
                                    value={frenchDescInputValue}
                                    onChange={handleValueChange('desc-fr')} />
                            </FormControl>
                        </Grid>
                        <Grid container item direction='column' justify='flex-start' alignItems='flex-start' className={classes.formControlRow} xs={12} >
                            <Typography className={classes.formLabel}>
                                {t('ADMIN.VIEWS.PARTNERS.BRANDS.BRAND_FORM.ENGLISH_DESCRIPTION')}*
                            </Typography>
                            <FormControl className={classes.formControl}>
                                <InputBase
                                    className={classes.formInput}
                                    placeholder={t('ADMIN.VIEWS.PARTNERS.BRANDS.BRAND_FORM.ENGLISH_DESCRIPTION')}
                                    id='desc-en'
                                    type='text'
                                    value={englishDescInputValue}
                                    onChange={handleValueChange('desc-en')} />
                            </FormControl>
                        </Grid>
                        <Grid container item direction='column' justify='flex-start' alignItems='flex-start' className={classes.formControlRow} xs={12} >
                            <Typography className={classes.formLabel}>
                                {t('ADMIN.VIEWS.PARTNERS.BRANDS.BRAND_FORM.ADVERTISER')}
                            </Typography>
                            <FormControl className={classes.formControl}>
                                <InputBase
                                    className={classes.formInput}
                                    placeholder={t('ADMIN.VIEWS.PARTNERS.BRANDS.BRAND_FORM.ADVERTISER')}
                                    id='advertiser'
                                    type='text'
                                    value={advertiserInputValue}
                                    onChange={handleValueChange('advertiser')} />
                            </FormControl>
                        </Grid>
                        <Grid container item direction='column' justify='flex-start' alignItems='flex-start' className={classes.formControlRow} xs={12} >
                            <Typography className={classes.formLabel}>
                                {t('ADMIN.VIEWS.PARTNERS.BRANDS.BRAND_FORM.OFFER_ID')}*
                            </Typography>
                            <FormControl className={classes.formControl}>
                                <InputBase
                                    className={classes.formInput}
                                    placeholder={t('ADMIN.VIEWS.PARTNERS.BRANDS.BRAND_FORM.OFFER_ID')}
                                    id='offerId'
                                    type='number'
                                    style={{ paddingRight: '10px' }}
                                    value={offerIdInputValue}
                                    onChange={handleValueChange('offerId')} />
                            </FormControl>
                        </Grid>
                        <Grid container item direction='column' justify='flex-start' alignItems='flex-start' className={classes.formControlRow} xs={12} >
                            <Typography className={classes.formLabel}>{t('ADMIN.VIEWS.PARTNERS.BRANDS.BRAND_FORM.GENERAL_COMMISSION')}*</Typography>
                            <FormControl className={classes.formControl}>
                                <InputBase
                                    className={classes.formInput}
                                    placeholder={t('ADMIN.VIEWS.PARTNERS.BRANDS.BRAND_FORM.GENERAL_COMMISSION')}
                                    id='general-commissions'
                                    type='text'
                                    value={generalCommissionsInputValue}
                                    onChange={handleValueChange('general-commissions')} />
                            </FormControl>
                        </Grid>
                        <Grid container item direction='column' justify='flex-start' alignItems='flex-start' className={classes.formControlRow} xs={12} >
                            <Typography className={classes.formLabel}>
                                {t('ADMIN.VIEWS.PARTNERS.BRANDS.BRAND_FORM.BASIC_COMMISSION')}
                            </Typography>
                            <FormControl className={classes.formControl}>
                                <InputBase
                                    className={classes.formInput}
                                    placeholder={t('ADMIN.VIEWS.PARTNERS.BRANDS.BRAND_FORM.BASIC_COMMISSION')}
                                    id='basic-commissions'
                                    type='text'
                                    value={basicCommissionsInputValue}
                                    onChange={handleValueChange('basic-commissions')} />
                            </FormControl>
                        </Grid>
                        <Grid container item direction='column' justify='flex-start' alignItems='flex-start' className={classes.formControlRow} xs={12} >
                            <Typography className={classes.formLabel}>
                                {t('ADMIN.VIEWS.PARTNERS.BRANDS.BRAND_FORM.SMI_COMMISSION')}
                            </Typography>
                            <FormControl className={classes.formControl}>
                                <InputBase
                                    className={classes.formInput}
                                    placeholder={t('ADMIN.VIEWS.PARTNERS.BRANDS.BRAND_FORM.SMI_COMMISSION')}
                                    id='smi-commissions'
                                    type='text'
                                    value={smiCommissionsInputValue}
                                    onChange={handleValueChange('smi-commissions')} />
                            </FormControl>
                        </Grid>
                        <Grid container item direction='row' xs={12} justify='flex-start' alignItems='flex-start' className={classes.formControlRow}>
                            <Typography className={classes.formLabel} style={{ display: "flex", flexDirection: "row", width: "100%" }}>
                                {t('ADMIN.VIEWS.PARTNERS.BRANDS.BRAND_FORM.OPTIONAL_COMMISSIONS')}
                            </Typography>
                            <Grid container item direction='column' justify='flex-start' alignItems='flex-start' xs={12} style={{ width: "100%" }}>
                                {
                                    optionalCommissionsList.map((commission, index) => (
                                        <Grid container item direction='column' justify='center' alignItems='center' xs={12} key={index} className={classes.optionalCommissionRow}>
                                            <Grid container item direction='row' justify='center' alignItems='center' xs={12}>
                                                <Typography className={classes.optionalCommission}>{t('ADMIN.VIEWS.PARTNERS.BRANDS.BRAND_FORM.OPTIONAL_COMMISSION')} {index + 1}</Typography>
                                            </Grid>
                                            <Grid container item direction='row' justify='center' alignItems='center' xs={12} key={index}>
                                                <Grid container item direction='row' justify='center' alignItems='center' lg={10} xs={10}>
                                                    <Grid container item direction='row' justify='center' alignItems='flex-start' lg={4} xs={12}>
                                                        <FormControl className={classes.formControl}>
                                                            <InputBase
                                                                className={classes.formInput}
                                                                placeholder={t('ADMIN.VIEWS.PARTNERS.BRANDS.BRAND_FORM.FRENCH_COMMISSION_TEXT_PLACEHOLDER')}
                                                                type='text'
                                                                value={commission.commissionFr}
                                                                onChange={handleOptCommissionChange('frenchCommissionTitle', index)}
                                                            />
                                                        </FormControl>
                                                    </Grid>
                                                    <Grid container item direction='row' justify='center' alignItems='flex-start' lg={4} xs={12}>
                                                        <FormControl className={classes.formControl}>
                                                            <InputBase
                                                                className={classes.formInput}
                                                                placeholder={t('ADMIN.VIEWS.PARTNERS.BRANDS.BRAND_FORM.ENGLISH_COMMISSION_TEXT_PLACEHOLDER')}
                                                                type='text'
                                                                value={commission.commissionEn}
                                                                onChange={handleOptCommissionChange('englishCommissionTitle', index)}
                                                            />
                                                        </FormControl>
                                                    </Grid>
                                                    <Grid container item direction='row' justify='center' alignItems='flex-start' lg={4} xs={12}>
                                                        <FormControl className={classes.formControl}>
                                                            <InputBase
                                                                className={classes.formInput}
                                                                placeholder={t('ADMIN.VIEWS.PARTNERS.BRANDS.BRAND_FORM.COMMISSION_VALUE_PLACEHOLDER')}
                                                                type='text'
                                                                value={commission.commissionValue}
                                                                onChange={handleOptCommissionChange('commissionValue', index)}
                                                            />
                                                        </FormControl>
                                                    </Grid>
                                                </Grid>
                                                <Grid container item direction='row' justify='center' alignItems='center' lg={2} xs={2}>
                                                    <IconButton
                                                        style={{ boxShadow: '0 7px 30px 0 rgba(69, 128, 163, 0.16)', backgroundColor: '#fff', marginLeft: '10px', marginRight: '10px' }}
                                                        onClick={() => handleRemoveOptCommission(index)}>
                                                        <DeleteOutlineIcon style={{ color: "red" }} />
                                                    </IconButton>
                                                    {
                                                        (index === optionalCommissionsList.length - 1)
                                                            ?
                                                            <IconButton
                                                                style={{ boxShadow: '0 7px 30px 0 rgba(69, 128, 163, 0.16)', backgroundColor: '#fff', marginLeft: '10px', marginRight: '10px' }}
                                                                onClick={handleAddOptCommission} >
                                                                <AddIcon style={{ color: "#58a5d3" }} />
                                                            </IconButton>
                                                            : <></>
                                                    }
                                                </Grid>
                                            </Grid>
                                        </Grid>
                                    ))
                                }
                            </Grid>
                        </Grid>
                        <Grid container item direction='row' justify='space-around' alignItems='center' className={classes.formControlRow}>
                            <Grid container item direction='column' style={{ width: '250px' }} alignItems='center'>
                                <Typography className={classes.switchLabel}>{t('ADMIN.VIEWS.PARTNERS.BRANDS.BRAND_FORM.PRIVATE')}*</Typography>
                                <Switch
                                    checked={privateValue}
                                    onChange={() => setPrivateValue(!privateValue)}
                                    name="private"
                                />
                            </Grid>
                            <Grid container item direction='column' style={{ width: '250px' }} alignItems='center'>
                                <Typography className={classes.switchLabel}>{t('ADMIN.VIEWS.PARTNERS.BRANDS.BRAND_FORM.PREMIUM')}*</Typography>
                                <Switch
                                    checked={premiumValue}
                                    onChange={() => setPremiumValue(!premiumValue)}
                                    name="premium"
                                />
                            </Grid>
                        </Grid>
                        <Grid container item direction='row' justify='space-around' alignItems='center' className={classes.formControlRow}>
                            <Grid container item direction='column' style={{ width: '250px' }} alignItems='center'>
                                <Typography className={classes.switchLabel}>
                                    {t('ADMIN.VIEWS.PARTNERS.BRANDS.BRAND_FORM.CALC_COMM')}
                                </Typography>
                                <Switch
                                    checked={calculateCommissionValue}
                                    onChange={() => setCalculateCommissionValue(!calculateCommissionValue)}
                                    name="calc-comm"
                                />
                            </Grid>
                            <Grid container item direction='column' style={{ width: '250px' }} alignItems='center'>
                                <Typography className={classes.switchLabel}>
                                    {t('ADMIN.VIEWS.PARTNERS.BRANDS.BRAND_FORM.DEFAULT_TRACKING_DOMAIN')}
                                </Typography>
                                <Switch
                                    checked={defaultDomainTrackingValue}
                                    onChange={() => setDefaultDomainTrackingValue(!defaultDomainTrackingValue)}
                                    name="default-tracking-demain"
                                />
                            </Grid>
                            <Grid container item direction='column' style={{ width: '250px' }} alignItems='center'>
                                <Typography className={classes.switchLabel}>
                                    privateIOSApp
                                </Typography>
                                <Switch
                                    checked={isPrivateIOSAppValue}
                                    onChange={() => setIsPrivateIOSAppValue(!isPrivateIOSAppValue)}
                                    name="is-private-IOSApp"
                                />
                            </Grid>
                            <Grid container item direction='column' style={{ width: '250px' }} alignItems='center'>
                                <Typography className={classes.switchLabel}>
                                    privateAndroidApp
                                </Typography>
                                <Switch
                                    checked={isPrivateAndroidAppValue}
                                    onChange={() => setIsPrivateAndroidAppValue(!isPrivateAndroidAppValue)}
                                    name="is-private-AndroidApp"
                                />
                            </Grid>
                            <Grid container item direction='column' style={{ width: '250px' }} alignItems='center'>
                                <Typography className={classes.switchLabel}>
                                    hasAdvertiser
                                </Typography>
                                <Switch
                                    checked={hasAdvertiser}
                                    onChange={() => setHasAdvertiser(!hasAdvertiser)}
                                    name="has-Advertiser"
                                />
                            </Grid>
                            <Grid container item direction='column' style={{ width: '250px' }} alignItems='center'>
                                <Typography className={classes.switchLabel}>
                                    hasGifting
                                </Typography>
                                <Switch
                                    checked={hasGifting}
                                    onChange={() => setHasGifting(!hasGifting)}
                                    name="has-Gifting"
                                />
                            </Grid>
                            <Grid container item direction='column' style={{ width: '250px' }} alignItems='center'>
                                <Typography className={classes.switchLabel}>
                                    Private Campagne
                                </Typography>
                                <Switch
                                    checked={isPrivateCampaign}
                                    onChange={() => setIsPrivateCampaign(!isPrivateCampaign)}
                                    name="is-Private-Campagne"
                                />
                            </Grid>
                        </Grid>
                        <Grid container item direction='column' justify='flex-start' alignItems='flex-start' className={classes.formControlRow} xs={12}>
                            <Grid container item direction='row' justify='flex-start' alignItems='flex-start' xs={12}>
                                <Typography className={classes.formLabel}>
                                    {t('ADMIN.VIEWS.PARTNERS.BRANDS.BRAND_FORM.IMAGE')}
                                </Typography>
                                {
                                    (provideImageLinkMode)
                                        ? <FormControl className={classes.formControl}>
                                            <InputBase
                                                className={classes.formInput}
                                                placeholder={t('ADMIN.VIEWS.PARTNERS.BRANDS.BRAND_FORM.IMAGE_HREF_PLACEHOLDER')}
                                                id='image-href-link'
                                                type='text'
                                                value={imageLinkInputValue}
                                                onChange={handleValueChange('image-href-link')} />
                                        </FormControl>
                                        : <FormControl className={classes.formControl}>
                                            <Grid container item alignItems='center' justify={(xsDown) ? 'center' : 'flex-start'} className={classes.formInput} style={{ height: 'auto', padding: 5, marginTop: '10px', marginBottom: '10px', width: '100%' }}>
                                                <Typography
                                                    className={(basicBrandPictureValue === '')
                                                        ? ((xsDown) ? classes.profilePicturePlaceholderMobile : classes.profilePicturePlaceholder)
                                                        : ((xsDown) ? classes.activeProfilePicturePlaceholderMobile : classes.activeProfilePicturePlaceholder)}>
                                                    {(basicBrandPictureValue === '') ? t('ADMIN.VIEWS.PARTNERS.BRANDS.BRAND_FORM.IMAGE_PLACEHOLDER') : basicBrandPictureValue.split('\\').pop()}
                                                </Typography>
                                                <label htmlFor='profileImage' tabIndex='0' className={classes.profileImageLabel} style={(xsDown) ? { margin: 5 } : {}}>
                                                    {t('ADMIN.VIEWS.PARTNERS.BRANDS.BRAND_FORM.UPLOAD_BUTTON')}
                                                </label>
                                            </Grid>
                                            <input type='file' id='profileImage' onChange={handleValueChange('brandBasicPicture')} hidden accept="image/x-png,image/gif,image/jpeg" />
                                            <CropperDialog open={openBasicDialog} save={handleSaveBasicDialog} cancel={handleCancelBasicDialog} imageToCrop={imageToCrop} widthAspect={imageWidthAspect} heightAspect={imageHeightAspect} />
                                        </FormControl>
                                }

                                <Typography onClick={toggleImageLinkMode} className={classes.logoHrefLink}>
                                    {
                                        (provideImageLinkMode)
                                            ? t('ADMIN.VIEWS.PARTNERS.BRANDS.BRAND_FORM.UPLOAD_IMAGE_LINK')
                                            : t('ADMIN.VIEWS.PARTNERS.BRANDS.BRAND_FORM.PROVIDE_IMAGE_LINK')
                                    }
                                </Typography>
                            </Grid>
                        </Grid>
                        <Grid container item direction='column' justify='flex-start' alignItems='flex-start' className={classes.formControlRow} xs={12}>
                            <Grid container item direction='row' justify='flex-start' alignItems='flex-start' xs={12}>
                                <Typography className={classes.formLabel}>{t('ADMIN.VIEWS.PARTNERS.BRANDS.BRAND_FORM.IMAGE_PREMIUM')}</Typography>
                                {
                                    (providePremiumImageLinkMode)
                                        ? <FormControl className={classes.formControl}>
                                            <InputBase
                                                className={classes.formInput}
                                                placeholder={t('ADMIN.VIEWS.PARTNERS.BRANDS.BRAND_FORM.IMAGE_PREMIUM_HREF_PLACEHOLDER')}
                                                id='image-premium-href-link'
                                                type='text'
                                                value={imagePremiumLinkInputValue}
                                                onChange={handleValueChange('image-premium-href-link')} />
                                        </FormControl>
                                        : <FormControl className={classes.formControl}>
                                            <Grid container item alignItems='center' justify={(xsDown) ? 'center' : 'flex-start'} className={classes.formInput} style={{ height: 'auto', padding: 5, marginTop: '10px', marginBottom: '10px', width: '100%' }}>
                                                <Typography
                                                    className={(premiumBrandPictureValue === '')
                                                        ? ((xsDown) ? classes.profilePicturePlaceholderMobile : classes.profilePicturePlaceholder)
                                                        : ((xsDown) ? classes.activeProfilePicturePlaceholderMobile : classes.activeProfilePicturePlaceholder)}>
                                                    {(premiumBrandPictureValue === '') ? t('ADMIN.VIEWS.PARTNERS.BRANDS.BRAND_FORM.IMAGE_PREMIUM_PLACEHOLDER') : premiumBrandPictureValue.split('\\').pop()}
                                                </Typography>
                                                <label htmlFor='premiumProfileImage' tabIndex='0' className={classes.profileImageLabel} style={(xsDown) ? { margin: 5 } : {}}>
                                                    {t('ADMIN.VIEWS.PARTNERS.BRANDS.BRAND_FORM.UPLOAD_BUTTON')}
                                                </label>
                                            </Grid>
                                            <input type='file' id='premiumProfileImage' onChange={handleValueChange('brandPremiumPicture')} hidden accept="image/x-png,image/gif,image/jpeg" />
                                            <CropperDialog open={openPremiumDialog} save={handleSavePremiumDialog} cancel={handleCancelPremiumDialog} imageToCrop={premiumImageToCrop} widthAspect={imageWidthAspect} heightAspect={imageHeightAspect} />
                                        </FormControl>
                                }
                                <Typography onClick={toggleImagePremiumLinkMode} className={classes.logoHrefLink}>
                                    {
                                        (providePremiumImageLinkMode)
                                            ? t('ADMIN.VIEWS.PARTNERS.BRANDS.BRAND_FORM.UPLOAD_IMAGE_LINK')
                                            : t('ADMIN.VIEWS.PARTNERS.BRANDS.BRAND_FORM.PROVIDE_IMAGE_PREMIUM_LINK')
                                    }
                                </Typography>
                            </Grid>
                        </Grid>
                        <Grid container item direction='column' justify='flex-start' alignItems='flex-start' className={classes.formControlRow} xs={12} >
                            <Typography className={classes.formLabel}>
                                {t('ADMIN.VIEWS.PARTNERS.BRANDS.BRAND_FORM.DELIVERY_COUNTRY')}
                            </Typography>
                            <FormControl className={classes.formControl}>
                                <Select
                                    displayEmpty
                                    multiple
                                    value={countryDeliveryValue}
                                    onChange={handleValueChange('countryDelivery')}
                                    classes={{ root: classes.selectClass }}
                                    input={<InputBase className={classes.formInput} />}
                                    renderValue={(selected) => {
                                        if (selected.length === 0) {
                                            return <Typography style={{ fontFamily: 'Poppins', color: '#717171' }}>
                                                {t('ADMIN.VIEWS.PARTNERS.BRANDS.BRAND_FORM.DELIVERY_COUNTRY')}
                                            </Typography>;
                                        }
                                        return selected.map(item => {
                                            return (i18n.language === 'fr-FR' || i18n.language === 'fr') ? item.nameFr : item.name
                                        }).join(', ');
                                    }}
                                    IconComponent={CustomExpandMore}>
                                    {countriesDB.map(country => (
                                        <MenuItem key={country.code} value={country} style={{ fontFamily: 'Poppins' }} classes={{ selected: classes.selectedMenuItems }}>
                                            {(i18n.language === 'fr-FR' || i18n.language === 'fr') ? country.nameFr : country.name}
                                        </MenuItem>
                                    ))}
                                </Select>
                            </FormControl>
                        </Grid>
                    </Grid>
                    {
                        (brandToEdit)
                            ? <Grid container item direction='row' xs={11} className={classes.formBox} justify={(xsDown) ? 'center' : 'space-between'}>
                                <Button
                                    className={classes.cancelEditButton}
                                    onClick={handleCloseModal}>
                                    {t('ADMIN.VIEWS.PARTNERS.BRANDS.BRAND_FORM.CANCEL_EDITS_BUTTON')}
                                </Button>
                                <Button
                                    className={classes.validateEditButton}
                                    onClick={editBrand}>
                                    {t('ADMIN.VIEWS.PARTNERS.BRANDS.BRAND_FORM.SAVE_EDITS_BUTTON')}
                                </Button>
                            </Grid>
                            : <></>
                    }
                    {
                        (brandToValidate)
                            ? <Grid container item direction='row' xs={11} className={classes.formBox}>
                                <Grid container item direction='row' justify={(!mdDown) ? 'flex-start' : 'center'} alignItems='center' md={6}>
                                    <Button
                                        className={classes.refuseButton}
                                        onClick={refuseBrand}>
                                        {t('ADMIN.VIEWS.PARTNERS.BRANDS.BRAND_FORM.REFUSE_BUTTON')}
                                    </Button>
                                </Grid>
                                <Grid container item direction='row' justify={(!mdDown) ? 'flex-end' : 'center'} alignItems='center' wrap='wrap' md={6}>
                                    <Button
                                        className={classes.validateLaterButton}
                                        onClick={saveEditsWithoutValidation}>
                                        {t('ADMIN.VIEWS.PARTNERS.BRANDS.BRAND_FORM.VALIDATE_LATER_BUTTON')}
                                    </Button>
                                    <Button
                                        className={classes.validateButton}
                                        onClick={validateBrand}>
                                        {t('ADMIN.VIEWS.PARTNERS.BRANDS.BRAND_FORM.VALIDATE_BUTTON')}
                                    </Button>
                                </Grid>
                            </Grid>
                            : <></>
                    }
                </Grid>
            </Grid>
        </div>
    )
};

const mapStateToProps = (state, ownProps) => {
    const newCategories = state.categoriesState.newCategories;
    const loadedNewCategories = state.categoriesState.loadedNewCategories;
    const loadedCategories = state.categoriesState.loadedCategories;
    const loadingUpdateBrandToValidate = state.brandsState.loadingUpdateBrandToValidate;
    const loadingValidateBrand = state.brandsState.loadingValidateBrand;
    const loadingRefuseBrand = state.brandsState.loadingRefuseBrand;
    const loadingUpdateBrand = state.brandsState.loadingUpdateBrand;
    return {
        newCategories,
        loadedNewCategories,
        loadedCategories,
        loadingUpdateBrandToValidate,
        loadingValidateBrand,
        loadingRefuseBrand,
        loadingUpdateBrand
    }
}

const mapDispatchToProps = (dispatch) => {
    return {
        updateBrandToValidateById: (brandId, updates) => dispatch(updateBrandToValidateById(brandId, updates)),
        validateBrandById: (brandId, brand, brandUid, userData) => dispatch(validateBrandById(brandId, brand, brandUid, userData)),
        refuseBrandById: (brandId) => dispatch(refuseBrandById(brandId)),
        updateBrandById: (brandId, updates) => dispatch(updateBrandById(brandId, updates)),
        validateBrandByIdRequest: () => dispatch({ type: 'VALIDATE_BRAND_BY_ID_REQUEST' }),
        updateBrandToValidateByIdRequest: () => dispatch({ type: 'UPDATE_BRAND_TO_VALIDATE_BY_ID_REQUEST' })
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(EditBrandModal);