const initState = {
    usersPerOfferdId: [],
    userError: null,
    loadedUserPerOfferId: false,
    loadingAddNewUser: false,
    loadingRemoveUser: false,
}
const usersReducer = (state = initState,action) => {
    switch (action.type) {
        case 'FETCH_USERS_BY_OFFERID_REQUEST':
            return {
                ...state,
                loadedUserPerOfferId: false
            };
        case "FETCH_USERS_BY_OFFERDID_SUCCESS":
            return {
                ...state,
                usersPerOfferdId: action.payload.users,
                loadedUserPerOfferId: true,
            };
        case "FETCH_USERS_BY_OFFERDID_ERROR":
            return {
                ...state,
                userError: action.error,
                loadedUserPerOfferId: true,
            }
        case "ADD_USER_BY_ID_REQUEST":
            return {
                ...state,
                loadingAddNewUser: true,
            };
        case "ADD_USER_BY_ID_SUCCESS": {
            let newUser = [...state.usersPerOfferdId];
            newUser.push(action.payload.user);
            return {
                ...state,
                usersPerOfferdId: newUser,
                loadingAddNewUser: false,
            };
        };
        case "ADD_USER_BY_ID_ERROR":
            return {
                ...state,
                loadingAddNewUser: false,
                userError: action.error,
            };
        case "REMOVE_USER_BY_ID_REQUEST":
            return {
                ...state,
                loadingRemoveUser: true,
            };
        case "REMOVE_USER_BY_ID_SUCCESS": {
            let newUsers = state.usersPerOfferdId;
            let indexToDelete = null;
            newUsers.forEach((user,index) => {
                if (user.uid === action.payload.uid) indexToDelete = index;
            });
            newUsers = newUsers.filter((item,index) => indexToDelete !== index);
            return {
                ...state,
                usersPerOfferdId: newUsers,
                loadingRemoveUser: false,
            };
        };
        case "REMOVE_USER_BY_ID_ERROR":
            return {
                ...state,
                loadingRemoveUser: false,
                userError: action.error,
            };
        case "CLEAR_BRAND_USER_BY_OFFERID":
            return {
                ...state,
                usersPerOfferdId: [],
                loadedUserPerOfferId: false
            }
        default:
            return state;
    }
};
export default usersReducer;