import { env as prod } from "../config/config";

export class Vector2D {
  constructor(x, y) {
    this.x = x;
    this.y = y;
  }

  get() {
    return {
      x: this.x,
      y: this.y,
    };
  }

  set(x, y) {
    this.x = x;
    this.y = y;
  }

  add(vector) {
    this.x += vector.x;
    this.y += vector.y;
  }

  sub(vector) {
    this.x -= vector.x;
    this.y -= vector.y;
  }

  clone() {
    return new Vector2D(this.x, this.y);
  }
}

export class MyDate extends Date {
  addDays(days) {
    this.setDate(this.getDate() + days);
    return this;
  }
  addWeeks(weeks) {
    this.setDate(this.getDate() + weeks * 7);
    return this;
  }
  addMonths(months) {
    this.setMonth(this.getMonth() + months);
    return this;
  }
  addYears(years) {
    this.setFullYear(this.getFullYear() + years);
    return this;
  }
  dayStart() {
    this.setHours(0);
    this.setMinutes(0);
    this.setSeconds(0);
    return this;
  }
  dayEnd() {
    this.setHours(23);
    this.setMinutes(59);
    this.setSeconds(59);
    return this;
  }
}

export const preventEvent = event => {
  const e = event || window.event;

  e.preventDefault && e.preventDefault();
  e.stopPropagation && e.stopPropagation();
  e.cancelBubble = true;
  e.returnValue = false;
  return false;
};
export const trimSiteName = siteName => {
  return siteName.replace(/http:\/\/|https:\/\/|www\.|\.fr|\.es|\.com/gi, "");
};
export const extractURLParam = (url, key) => {
  const params = url.split("&");
  let pName, i;

  for (i = 0; i < params.length; i++) {
    pName = params[i].substr(0, params[i].indexOf("="));
    if (pName === key) return params[i].substr(params[i].indexOf("=") + 1);
  }
  return "";
};
export const shuffle = array => {
  var j, x, i;
  for (i = array.length - 1; i > 0; i--) {
    j = Math.floor(Math.random() * (i + 1));
    x = array[i];
    array[i] = array[j];
    array[j] = x;
  }
  return array;
};
export const formatDate = date => {
  return `${date.getFullYear()}-${
    date.getMonth().toString().length > 1
      ? date.getMonth()
      : "0" + (date.getMonth() + 1).toString()
  }-${
    date.getDate().toString().length > 1
      ? date.getDate()
      : "0" + date.getDate().toString()
  }`;
};
export const orderArray = (array, param) => {
  return array.sort(function(a, b) {
    if (a[param] < b[param]) {
      return -1;
    }
    if (a[param] > b[param]) {
      return 1;
    }
    return 0;
  });
};
export const urlMaker = source => {
  let url;
  url = prod
    ? "https://us-central1-shopmyinfluens.cloudfunctions.net"
    : "http://localhost:5001/shopmyinfluens/us-central1";
  switch (source) {
    case "userManager":
      url = `${url}/userManager`;
      break;
    case "server":
      url = `${url}/serv`;
      break;
    case "api":
      url = `${url}/api`;
      break;
    case "createAff":
      url =
        "https://us-central1-shopmyinfluens.cloudfunctions.net/globalApi/affiliate/create";
      break;
    case "clicks":
      url = `${url}/clicks`;
      break;
    default:
      break;
  }
  return url;
};
export const phoneNumberValidation = number => {
  if (number) {
    let regexp = /^(?:(?:\+|00)33|0)\s*[1-9](?:[\s.-]*\d{2}){4}$/g;

    return number.match(regexp) ? true : false;
  }
  return false;
};
export const emailValidation = email => {
  if (email) {
    /* eslint-disable no-control-regex */ 
    let regexp = /(?:[a-z0-9!#$%&'*+/=?^_`{|}~-]+(?:\.[a-z0-9!#$%&'*+/=?^_`{|}~-]+)*|"(?:[\x01-\x08\x0b\x0c\x0e-\x1f\x21\x23-\x5b\x5d-\x7f]|\\[\x01-\x09\x0b\x0c\x0e-\x7f])*")@(?:(?:[a-z0-9](?:[a-z0-9-]*[a-z0-9])?\.)+[a-z0-9](?:[a-z0-9-]*[a-z0-9])?|\[(?:(?:(2(5[0-5]|[0-4][0-9])|1[0-9][0-9]|[1-9]?[0-9]))\.){3}(?:(2(5[0-5]|[0-4][0-9])|1[0-9][0-9]|[1-9]?[0-9])|[a-z0-9-]*[a-z0-9]:(?:[\x01-\x08\x0b\x0c\x0e-\x1f\x21-\x5a\x53-\x7f]|\\[\x01-\x09\x0b\x0c\x0e-\x7f])+)\])/g;
    /* eslint-enable no-control-regex */
    return email.match(regexp) ? true : false;
  }
  return false;
};
