import React, { useState, useEffect } from 'react';
import backgroundImage from './assets/background.png';
import { makeStyles } from '@material-ui/styles';
import { Grid, Typography, InputBase, Checkbox, Button, useTheme, useMediaQuery, FormControl, FormHelperText, IconButton } from '@material-ui/core';
import * as utils from '../utils';
import { auth } from '../managers';
import TrendLoading from '../ui/trendLoading/TrendLoading';
import { Redirect, withRouter } from "react-router-dom";
import { useTranslation } from "react-i18next";
import VisibilityIcon from '@material-ui/icons/Visibility';
import VisibilityOffIcon from '@material-ui/icons/VisibilityOff';


const useStyle = makeStyles(() => ({
    loginContent: {
        minHeight: '100vh',
        minWidth: '100vw',
        backgroundImage: `url(${backgroundImage})`,
        boxShadow: 'inset 0 0 0 100vh rgba(236, 247, 253, 0.5)'
    },
    logoImage: {
        marginTop: '10px',
        marginBottom: '50px',
        width: '240px',
        height: '120px',
        cursor: 'pointer'
    },
    loginBox: {
        backgroundColor: 'white',
        borderRadius: '40px',
        // minHeight: '400px',
        boxShadow: '0 7px 30px 0 rgba(88, 165, 211, 0.16)',

    },
    connectPlaceholder: {
        fontFamily: 'Poppins',
        fontSize: '22px',
        marginTop: '30px',
        marginBottom: '30px',
        textAlign: 'center'
    },
    inputField: {
        width: '100%',
        borderRadius: '35px',
        boxShadow: '0 7px 30px 0 rgba(69, 128, 163, 0.16)',
        marginTop: '10px',
        marginBottom: '10px',
        paddingTop: '10px',
        paddingBottom: '10px',
        paddingLeft: '20px',
        fontFamily: 'Poppins'
    },
    passwordField: {
        width: '100%',
        borderRadius: '35px',
        boxShadow: '0 7px 30px 0 rgba(69, 128, 163, 0.16)',
        marginTop: '10px',
        marginBottom: '10px',
        display: 'flex',
        flexDirection: 'row',
        alignItems: 'center'
    },
    inputPasswordField: {
        fontFamily: 'Poppins',
        paddingTop: '10px',
        paddingBottom: '10px',
        paddingLeft: '20px',
        flexGrow: 1
    },
    spacement: {
        marginTop: '10px',
        marginBottom: '10px'
    },
    connectionButton: {
        height: '50px',
        width: '200px',
        backgroundColor: '#ed659f',
        color: '#ffffff',
        borderRadius: '25px',
        fontFamily: 'Poppins',
        textTransform: 'none',
        fontWeight: 510,
        boxShadow: '0 7px 30px 0 rgba(88, 165, 211, 0.16)',
        '&:hover': {
            backgroundColor: '#ed659f',
            color: '#ffffff',
        },
        margin: '10px'
    },
    cancelButton: {
        fontFamily: 'Poppins',
        textTransform: 'none',
        height: '50px',
        borderRadius: '25px',
        margin: '10px',
        width: '100px'
    },
    stayConnectedPlaceholder: {
        fontFamily: 'Poppins'
    },
    forgottenPasswordPlaceholder: {
        fontSize: '14px',
        fontFamily: 'Poppins',
        textDecoration: 'underline',
        color: 'blue',
        '&:hover': {
            cursor: 'pointer'
        }
    },
    registerPlaceholder: {
        fontFamily: 'Poppins',
        marginTop: '10px',
        marginBottom: '10px'
    },
    registerLink: {
        color: 'blue',
        '&:hover': {
            cursor: 'pointer'
        }
    },
    errorMessage: {
        fontFamily: 'Poppins',
        color: 'red'
    }
}))

const Login = (props) => {
    const { t } = useTranslation('common');

    const classes = useStyle();
    const theme = useTheme();
    const xsDown = useMediaQuery(theme.breakpoints.down("xs"));
    const smUp = useMediaQuery(theme.breakpoints.up('sm'));
    const [emailInputValue, setEmailInputValue] = useState('');
    const [passwordValue, setPasswordValue] = useState('');
    const [emailRequestValue, setEmailRequestValue] = useState('');
    const [connectionMethod, setConnectionMethod] = useState('session');
    const [validationError, setValidationError] = useState(false);
    const [mailValidationError, setMailValidationError] = useState();
    const [mailRequestValidationError, setMailRequestValidationError] = useState();
    const [passwordValidationError, setPasswordValidationError] = useState();
    const [emailRequestSent, setEmailRequestSent] = useState(false);
    const [loading, setLoading] = useState(false);
    const [forgottenPasswordMode, setForgottenPasswordMode] = useState(false);
    const [loggedIn, setLoggedIn] = useState((props.user) ? true : false);
    const [showPassword, setShowPassword] = useState(false);

    useEffect(() => {
        (props.user) ? setLoggedIn(true) : setLoggedIn(false);
    }, [props.user]);

    const isValid = () => {
        if (!forgottenPasswordMode) {
            setValidationError(false);
            setMailValidationError();
            setPasswordValidationError();
            let errorsFound = false;
            if (!utils.emailValidation(emailInputValue)) {
                setValidationError(true);
                setMailValidationError('* ' + t('LOGIN.INPUT_ERRORS.VALIDATION_ERRORS.INVALID_EMAIL_ADDRESS_ERROR'));
                errorsFound = true;
            }
            if (passwordValue.length < 6) {
                setValidationError(true);
                setPasswordValidationError('* ' + t('LOGIN.INPUT_ERRORS.VALIDATION_ERRORS.INVALID_PASSWORD_LENGTH_ERROR'));
                errorsFound = true;
            }
            return !errorsFound;
        } else {
            setValidationError(false);
            setMailRequestValidationError();
            let errorsFound = false;
            if (!utils.emailValidation(emailRequestValue)) {
                setValidationError(true);
                setMailRequestValidationError('* ' + t('LOGIN.FORGOTTEN_PASSWORD.INPUT_ERRORS.VALIDATION_ERRORS.INVALID_EMAIL_ADDRESS_ERROR'))
                errorsFound = true;
            }
            return !errorsFound;
        }
    };

    const handleSubmit = event => {
        event.preventDefault();
        if (isValid()) {
            setLoading(true);
            auth.setPersistence(connectionMethod).then(() => {
                auth.signInWithEmailAndPassword(emailInputValue, passwordValue).then(() => {
                    setLoading(false);
                    setLoggedIn(true);
                }).catch(err => {
                    if (err.code === "auth/invalid-email") {
                        setMailValidationError("* " + t('LOGIN.INPUT_ERRORS.RESPONSE_ERRORS.INVALID_EMAIL'));
                        setValidationError(true);
                    }
                    else if (err.code === "auth/wrong-password") {
                        setPasswordValidationError("* " + t('LOGIN.INPUT_ERRORS.RESPONSE_ERRORS.WRONG_PASSWORD'));
                        setValidationError(true);
                    }
                    else if (err.code === "auth/user-not-found") {
                        setMailValidationError('* ' + t('LOGIN.INPUT_ERRORS.RESPONSE_ERRORS.USER_NOT_FOUND'));
                        setValidationError(true);
                    } else if (err.code === "auth/too-many-requests") {
                        setMailValidationError("* " + t('LOGIN.INPUT_ERRORS.RESPONSE_ERRORS.TOO_MANY_REQUESTS'));
                        setValidationError(true);
                    }
                    else {
                        console.log(err);
                    }
                    setLoading(false);
                });
            }).catch(err => {
                console.log(err);
                setLoading(false);
            })
        }
    };

    const handleValueChange = id => event => {
        if (id === 'email') {
            setEmailInputValue(event.target.value);
        }
        if (id === 'password') {
            setPasswordValue(event.target.value);
        }
        if (id === 'emailRequest') {
            setEmailRequestValue(event.target.value);
        }
        if (id === 'connectionMethod') {
            (event.target.checked) ? setConnectionMethod('local') : setConnectionMethod('session');
        }
    };

    const cancelLogin = () => {
        let toPreviousRoute = (props.location.state) ? props.location.state.previousRoute : '/';
        if (toPreviousRoute.includes('/trend') || toPreviousRoute.includes('/influencersAgent') || toPreviousRoute.includes('brandsAgent')) {
            toPreviousRoute = '/';
        }
        props.history.push(toPreviousRoute);
    };

    const redirectToRegister = () => {
        let toPreviousRoute = (props.location.state) ? props.location.state.previousRoute : '/';
        props.history.push('/register', { previousRoute: toPreviousRoute });
    }

    const handleForgottenPassword = () => {
        if (isValid()) {
            setLoading(true);
            auth.sendPasswordResetEmail(emailRequestValue).then(() => {
                setLoading(false);
                setEmailRequestSent(true);
            }).catch(err => {
                if (err.code === "auth/invalid-email") {
                    setMailRequestValidationError("* " + t('LOGIN.FORGOTTEN_PASSWORD.INPUT_ERRORS.RESPONSE_ERRORS.INVALID_EMAIL'));
                    setValidationError(true);
                }
                else if (err.code === "auth/user-not-found") {
                    setMailRequestValidationError('* ' + t('LOGIN.FORGOTTEN_PASSWORD.INPUT_ERRORS.RESPONSE_ERRORS.USER_NOT_FOUND'));
                    setValidationError(true);
                } else {
                    console.log(err);
                }
                setLoading(false);
            });
        }
    }

    const closeForgottenPassword = () => {
        setMailRequestValidationError();
        setValidationError();
        setEmailRequestValue('');
        setForgottenPasswordMode(false);
    }

    const togglePasswordVisibility = () => {
        setShowPassword(!showPassword);
    }

    const goToHome = () => {
        props.history.push('/');
    }

    const renderForm = () => (
        <form onSubmit={handleSubmit}>
            <Grid container item justify='center' alignItems='center' className={classes.loginContent}>
                <Grid container item justify='center' lg={7} md={8} xs={10} direction='row'>
                    {
                        (loading)
                            ? <TrendLoading />
                            : <>
                                <img
                                    src="https://firebasestorage.googleapis.com/v0/b/shopmyinfluens.appspot.com/o/shopmyinfluens%2FApp%20Data%2Flogo_shopmyinfluence-01.png?alt=media"
                                    alt="influence"
                                    className={classes.logoImage}
                                    onClick={goToHome} />
                                <Grid container item justify='center' alignItems='flex-start' className={classes.loginBox} direction='row'>
                                    {
                                        (forgottenPasswordMode)
                                            ? <>
                                                <Grid container item justify='center' alignItems='center' xs={12}>
                                                    <Typography className={classes.connectPlaceholder}>{t('LOGIN.FORGOTTEN_PASSWORD.CHANGE_PASSWORD_REQUEST_PLACEHOLDER')}</Typography>
                                                </Grid>
                                                <Grid container item justify='center' alignItems='center' xs={11}>
                                                    <FormControl style={{ width: '100%' }}>
                                                        <InputBase
                                                            id='email'
                                                            placeholder={t('LOGIN.FORGOTTEN_PASSWORD.EMAIL_INPUT_PLACEHOLDER')}
                                                            type='email'
                                                            className={classes.inputField}
                                                            value={emailRequestValue}
                                                            onChange={handleValueChange('emailRequest')} />
                                                        {validationError && <FormHelperText className={classes.errorMessage}>{mailRequestValidationError}</FormHelperText>}
                                                    </FormControl>
                                                </Grid>
                                                {
                                                    (emailRequestSent)
                                                        ? <>
                                                            <Grid container item direction='row' justify='center' xs={11}>
                                                                <Typography className={classes.stayConnectedPlaceholder} style={{ color: '#f77158', fontSize: '17px' }}>{t('LOGIN.FORGOTTEN_PASSWORD.SUCCESS_MESSAGE')}</Typography>
                                                            </Grid>
                                                            <Grid container item direction='row' justify='center' alignItems='center'>
                                                                <Button className={classes.cancelButton} onClick={closeForgottenPassword}>{t('LOGIN.FORGOTTEN_PASSWORD.GO_BACK_BUTTON')}</Button>
                                                                <Button type='submit' className={classes.connectionButton} onClick={handleForgottenPassword}>{t('LOGIN.FORGOTTEN_PASSWORD.RESEND_BUTTON')}</Button>
                                                            </Grid>
                                                        </>
                                                        : <Grid container item direction='row-reverse' justify={(smUp) ? 'flex-start' : (xsDown) ? 'center' : 'flex-start'} alignItems='center' xs={11} className={classes.spacement}>
                                                            <Button type='submit' className={classes.connectionButton} onClick={handleForgottenPassword}>{t('LOGIN.FORGOTTEN_PASSWORD.RESET_PASSWORD_BUTTON')}</Button>
                                                            <Button className={classes.cancelButton} onClick={closeForgottenPassword}>{t('LOGIN.FORGOTTEN_PASSWORD.GO_BACK_BUTTON')}</Button>
                                                        </Grid>
                                                }

                                            </>
                                            : <>
                                                <Grid container item justify='center' alignItems='flex-start' xs={12}>
                                                    <Typography className={classes.connectPlaceholder}>{t('LOGIN.LOGIN_HEADER')}</Typography>
                                                </Grid>
                                                <Grid container item justify='center' alignItems='flex-start' xs={11}>
                                                    <FormControl style={{ width: '100%' }}>
                                                        <InputBase
                                                            id='email'
                                                            placeholder={t('LOGIN.EMAIL_INPUT_PLACEHOLDER')}
                                                            type='email'
                                                            className={classes.inputField}
                                                            value={emailInputValue}
                                                            onChange={handleValueChange('email')} />
                                                        {validationError && <FormHelperText className={classes.errorMessage}>{mailValidationError}</FormHelperText>}
                                                    </FormControl>
                                                    <FormControl style={{ width: '100%' }}>
                                                        <div className={classes.passwordField}>
                                                            <InputBase
                                                                id='password'
                                                                placeholder={t('LOGIN.PASSWORD_INPUT_PLACEHOLDER')}
                                                                type={(showPassword) ? 'text' : 'password'}
                                                                className={classes.inputPasswordField}
                                                                value={passwordValue}
                                                                onChange={handleValueChange('password')} />
                                                            <IconButton onClick={togglePasswordVisibility}>
                                                                {
                                                                    (showPassword)
                                                                        ? <VisibilityOffIcon />
                                                                        : <VisibilityIcon />
                                                                }
                                                            </IconButton>
                                                        </div>

                                                        {validationError && <FormHelperText className={classes.errorMessage}>{passwordValidationError}</FormHelperText>}
                                                    </FormControl>
                                                </Grid>
                                                <Grid container item justify='space-between' alignItems='center' xs={11}>
                                                    <Grid container item justify={(smUp) ? 'flex-start' : (xsDown) ? 'center' : 'flex-start'} alignItems='center' xs={12} sm={6} md={6} lg={6} className={classes.spacement}>
                                                        <Checkbox onChange={handleValueChange('connectionMethod')} />
                                                        <Typography className={classes.stayConnectedPlaceholder}>{t('LOGIN.STAY_CONNECTED_BUTTON')}</Typography>
                                                    </Grid>
                                                    <Grid container item justify={(smUp) ? 'flex-end' : (xsDown) ? 'center' : 'flex-end'} alignItems='center' xs={12} sm={6} md={6} lg={6} className={classes.spacement}>
                                                        <Typography onClick={() => setForgottenPasswordMode(true)} className={classes.forgottenPasswordPlaceholder}>{t('LOGIN.FORGOTTEN_PASSWORD_LINK')}</Typography>
                                                    </Grid>
                                                </Grid>
                                                <Grid container item direction='row-reverse' justify={(smUp) ? 'flex-start' : (xsDown) ? 'center' : 'flex-start'} alignItems='center' xs={11} className={classes.spacement}>
                                                    <Button type='submit' className={classes.connectionButton} onClick={handleSubmit}>{t('LOGIN.CONNECT_BUTTON')}</Button>
                                                    <Button className={classes.cancelButton} onClick={cancelLogin}>{t('LOGIN.CANCEl_BUTTON')}</Button>
                                                </Grid>
                                            </>
                                    }
                                </Grid>
                                <Typography className={classes.registerPlaceholder}>
                                    {t('LOGIN.NO_ACCOUNT_PLACEHOLDER')}
                                    <span className={classes.registerLink} onClick={redirectToRegister}> {t('LOGIN.REGISTER_LINK')}</span>
                                </Typography>
                            </>
                    }
                </Grid>
            </Grid>
        </form>
    );

    const redirectTag = () => {
        return <Redirect to={(props.location.state) ? props.location.state.previousRoute : '/'} />
    }

    return (
        (loggedIn) ? redirectTag() : renderForm()
    );

}

export default withRouter(Login);