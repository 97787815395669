import { makeStyles, Input } from '@material-ui/core';
import React, { useState, useEffect } from 'react';
import {
    Grid,
    Box,
    Typography,
    Select,
    MenuItem
} from "@material-ui/core";
import { Pagination, PaginationItem } from "@material-ui/lab";
import BootstrapInput from "../../assets/customComponents/BootstrapInput";
import SVGIcon from "../../assets/SVGIcons";
import ExpandLessIcon from "@material-ui/icons/ExpandLess";
import ExpandMoreIcon from "@material-ui/icons/ExpandMore";
import SortIcon from "@material-ui/icons/Sort";
import { useTranslation } from "react-i18next";
import PlaceholderImage from "../../assets/img/placeholder_sales.png";
import { connect } from 'react-redux';

const useStyles = makeStyles(theme => ({
    influencerBoxContainer: {
        marginTop: "10px",
        marginBottom: "10px",
        minWidth: "1050px",
    },
    influencerBox: {
        display: "flex",
        width: "95%",
        backgroundColor: "white",
        height: "120px",
        borderRadius: "27px",
        boxShadow: "0 7px 30px 0 rgba(88, 165, 211, 0.16)",
    },
    influencerImage: {
        width: "120px",
        height: "120px",
        borderTopLeftRadius: "27px",
        borderBottomLeftRadius: "27px",
        objectFit: "fill",
    },
    paginationRow: {
        display: "flex",
        justifyContent: "center",
        marginTop: "10px",
        marginBottom: "10px",
    },
    tableHeader: {
        fontFamily: "Poppins",
        fontSize: "14px",
        textAlign: "center",
        "&:hover": {
            cursor: "pointer",
        },
    },
    tableHeaderIcon: {
        "&:hover": {
            cursor: "pointer",
        },
    },
    tableRowContent: {
        fontFamily: "Poppins",
    },
    influencerName: {
        fontFamily: "Poppins",
        textDecoration: "underline",
        "&:hover": {
            cursor: "pointer",
        },
    },
    paginationClass: {
        backgroundColor: "#ffffff !important",
        boxShadow: "0 7px 30px 0 rgba(88, 165, 211, 0.16)",
        borderRadius: "8px",
        fontFamily: "Poppins",
        height: "40px",
        width: "40px",
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
    },
    selectedPaginationItem: {
        backgroundColor: "#3eadef !important",
        boxShadow: "0 7px 30px 0 rgba(88, 165, 211, 0.16)",
        borderRadius: "8px",
        fontFamily: "Poppins",
        height: "40px",
        width: "40px",
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
        color: "#ffffff",
    },
    selectClass: {
        fontFamily: "Poppins",
    },
    influencerCard: {
        margin: "10px",
        width: "280px",
        height: "140px",
        borderRadius: "24px",
        boxShadow: "0 7px 30px 0 rgba(24, 61, 83, 0.16)",
        "&:hover": {
            cursor: "pointer",
        },
    },
    influencerCardImage: {
        objectFit: "contain",
        width: "280px",
        height: "140px",
        borderRadius: "24px",
    },
    textPlaceholder: {
        fontFamily: "Poppins",
        fontSize: "16px",
        margin: 10,
        color: "#1b4c61",
        textAlign: "center",
    },
}));
const useSearchInputStyles = makeStyles(theme => ({
    root: {
        overflow: "hidden",
        borderRadius: 24,
        backgroundColor: "#fff",
        margin: 5,
        marginTop: 15,
        width: '95%',
        maxWidth: '400px',
        boxShadow: '0 7px 30px 0 rgba(88, 165, 211, 0.16)',
        "&:hover": {
            backgroundColor: "#fff"
        },
        "&$focused": {
            backgroundColor: "#fff",
            borderColor: "#ff005f"
        }
    },
    input: {
        fontFamily: 'Poppins',
        fontWeight: 500,
        paddingLeft: 15
    },
    focused: {}
}));

const InfluencerAgentsStatsTable = (props) => {

    const { t } = useTranslation("common");
    const classes = useStyles();
    const searchInputClasses = useSearchInputStyles();
    const [influencerAgentsToRenderFinal, setInfluencerAgentsToRenderFinal] = useState([]);
    const [sortHelper, setSortHelper] = useState([1, 0, 0, 0, 0, 0]);
    const [sortEvent, setSortEvent] = useState(0);
    const [page, setPage] = useState(1);
    const [count, setCount] = useState(1);
    const [size, setSize] = useState(10);
    const influencersList = props.conversions;

    const {
        appStateSortHelper,
        appStatePageNumber,
        appStatePageSize,
        changeSortHelper,
        changePageNumber,
        changePageSize
    } = props;

    const handlePageSizeChange = (event) => {
        changePageSize(event.target.value);
        changePageNumber(1);
    };

    const handlePageChange = (event, value) => {
        changePageNumber(value);
    };

    const openPopup = (influencer) => {
        props.openPopup(influencer);
    };

    useEffect(() => {
        setSortHelper(appStateSortHelper);
    }, [appStateSortHelper]);

    useEffect(() => {
        setPage(appStatePageNumber);
    }, [appStatePageNumber]);

    useEffect(() => {
        setSize(appStatePageSize);
    }, [appStatePageSize]);

    useEffect(() => {
        let indexAsc = sortHelper.indexOf(1);
        let indexDesc = sortHelper.indexOf(-1);
        if (indexAsc !== -1) {
            const sortTypeAsc = (indexAsc, tableToWorkOn) => {
                switch (indexAsc) {
                    case 0:
                        tableToWorkOn.sort((a, b) => {
                            if (a.influencerAgentData.name.toLowerCase() > b.influencerAgentData.name.toLowerCase()) return -1;
                            if (a.influencerAgentData.name.toLowerCase() < b.influencerAgentData.name.toLowerCase()) return 1;
                            return 0;
                        });
                        break;
                    case 1:
                        tableToWorkOn.sort((a, b) => {
                            if (a.influencerAgentData.amount > b.influencerAgentData.amount) return -1;
                            if (a.influencerAgentData.amount < b.influencerAgentData.amount) return 1;
                            return 0;
                        });
                        break;
                    case 2:
                        tableToWorkOn.sort((a, b) => {
                            if (a.influencerAgentData.salesNumber > b.influencerAgentData.salesNumber) return -1;
                            if (a.influencerAgentData.salesNumber < b.influencerAgentData.salesNumber) return 1;
                            return 0;
                        });
                        break;
                    case 3:
                        tableToWorkOn.sort((a, b) => {
                            if (a.influencerAgentData.commission > b.influencerAgentData.commission) return -1;
                            if (a.influencerAgentData.commission < b.influencerAgentData.commission) return 1;
                            return 0;
                        });
                        break;
                    case 4:
                        tableToWorkOn.sort((a, b) => {
                            if (a.influencerAgentData.products.length > b.influencerAgentData.products.length) return -1;
                            if (a.influencerAgentData.products.length < b.influencerAgentData.products.length) return 1;
                            return 0;
                        });
                        break;
                    default:
                        break;
                }
            };
            sortTypeAsc(indexAsc, influencersList);
        } else {
            const sortTypeDesc = (indexDesc, tableToWorkOn) => {
                switch (indexDesc) {
                    case 0:
                        tableToWorkOn.sort((a, b) => {
                            if (a.influencerAgentData.name.toLowerCase() > b.influencerAgentData.name.toLowerCase()) return 1;
                            if (a.influencerAgentData.name.toLowerCase() < b.influencerAgentData.name.toLowerCase()) return -1;
                            return 0;
                        });
                        break;
                    case 1:
                        tableToWorkOn.sort((a, b) => {
                            if (a.influencerAgentData.amount > b.influencerAgentData.amount) return 1;
                            if (a.influencerAgentData.amount < b.influencerAgentData.amount) return -1;
                            return 0;
                        });
                        break;
                    case 2:
                        tableToWorkOn.sort((a, b) => {
                            if (a.influencerAgentData.salesNumber > b.influencerAgentData.salesNumber) return 1;
                            if (a.influencerAgentData.salesNumber < b.influencerAgentData.salesNumber) return -1;
                            return 0;
                        });
                        break;
                    case 3:
                        tableToWorkOn.sort((a, b) => {
                            if (a.influencerAgentData.commission > b.influencerAgentData.commission) return 1;
                            if (a.influencerAgentData.commission < b.influencerAgentData.commission) return -1;
                            return 0;
                        });
                        break;
                    case 4:
                        tableToWorkOn.sort((a, b) => {
                            if (a.influencerAgentData.products.length > b.influencerAgentData.products.length) return 1;
                            if (a.influencerAgentData.products.length < b.influencerAgentData.products.length) return -1;
                            return 0;
                        });
                        break;
                    default:
                        break;
                }
            };
            sortTypeDesc(indexDesc, influencersList);
        }
        setSortEvent((s) => s + 1);
    }, [influencersList, sortHelper]);

    useEffect(() => {
        setInfluencerAgentsToRenderFinal(
            influencersList.slice((page - 1) * size, page * size)
        );
        setCount(
            influencersList.length % size === 0
                ? Math.trunc(influencersList.length / size)
                : Math.trunc(influencersList.length / size) + 1
        );
        // setLoading(false);
    }, [influencersList, size, page, sortEvent]);

    const inputChange = (event) => {
        if (event.target.value === '') {
            setSortEvent(sortEvent + 1)
        } else {
            setInfluencerAgentsToRenderFinal(influencersList.filter(element => element.influencerAgentData.name.toLowerCase().includes(event.target.value.toLowerCase())));
        }
    }

    return (
        (influencersList.length === 0)
            ? <Grid container item direction='row' justify='center' alignItems='center' xs={12} style={{ marginTop: '30px', marginBottom: '30px' }}>
                <Grid container direction="column" justify="space-around" alignItems="center">
                    <img alt="No sales" src={PlaceholderImage} height="75px" width="75px" />
                    <Typography className={classes.textPlaceholder}>
                        {t("ADMIN.VIEWS.STATISTICS.NO_PERIOD_GENERATED_SALES_PLACEHOLDER")}
                    </Typography>
                </Grid>
            </Grid>
            :
            <>
                <Input classes={searchInputClasses} disableUnderline placeholder={t('ADMIN.VIEWS.STATISTICS.INFLUENCER_AGENT_TABLE.SEARCH_INPUT_PLACEHOLDER')} onChange={inputChange} />
                <Grid container item direction="row" xs={12} alignItems="center" justify="center">
                    <div style={{ overflowX: "auto", width: "100%", marginTop: "30px" }}>
                        <Grid container item style={{ minWidth: "1050px" }} direction="row" xs={12}>
                            <Grid container item direction="row" justify="center">
                                <Grid container item style={{ width: "95%" }}>
                                    <Grid container item alignItems="center" justify="center" style={{ width: "48%" }}
                                        onClick={() => {
                                            sortHelper[0] === 0
                                                ? changeSortHelper([1, 0, 0, 0, 0, 0])
                                                : changeSortHelper([-sortHelper[0], 0, 0, 0, 0, 0]);
                                        }}
                                    >
                                        <Typography className={classes.tableHeader} style={{ marginLeft: "50px" }}>
                                            {t("ADMIN.VIEWS.STATISTICS.INFLUENCER_AGENT_TABLE.NAME_HEADER")}
                                        </Typography>
                                        {sortHelper[0] === 1 ? (
                                            <ExpandLessIcon className={classes.tableHeaderIcon} />
                                        ) : sortHelper[0] === -1 ? (
                                            <ExpandMoreIcon className={classes.tableHeaderIcon} />
                                        ) : (
                                            <SortIcon className={classes.tableHeaderIcon} />
                                        )}
                                    </Grid>
                                    <Grid container justify="center" alignItems="center" style={{ width: "13%" }}
                                        onClick={() => {
                                            sortHelper[1] === 0
                                                ? changeSortHelper([0, 1, 0, 0, 0, 0])
                                                : changeSortHelper([0, -sortHelper[1], 0, 0, 0, 0]);
                                        }}
                                    >
                                        <Typography className={classes.tableHeader}>
                                            {t("ADMIN.VIEWS.STATISTICS.INFLUENCER_AGENT_TABLE.TURNOVER_HEADER")}
                                        </Typography>
                                        {sortHelper[1] === 1 ? (
                                            <ExpandLessIcon className={classes.tableHeaderIcon} />
                                        ) : sortHelper[1] === -1 ? (
                                            <ExpandMoreIcon className={classes.tableHeaderIcon} />
                                        ) : (
                                            <SortIcon className={classes.tableHeaderIcon} />
                                        )}
                                    </Grid>
                                    <Grid container justify="center" alignItems="center" style={{ width: "13%" }}
                                        onClick={() => {
                                            sortHelper[2] === 0
                                                ? changeSortHelper([0, 0, 1, 0, 0, 0])
                                                : changeSortHelper([0, 0, -sortHelper[2], 0, 0, 0]);
                                        }}
                                    >
                                        <Typography className={classes.tableHeader}>
                                            {t("ADMIN.VIEWS.STATISTICS.INFLUENCER_AGENT_TABLE.SALES_HEADER")}
                                        </Typography>
                                        {sortHelper[2] === 1 ? (
                                            <ExpandLessIcon className={classes.tableHeaderIcon} />
                                        ) : sortHelper[2] === -1 ? (
                                            <ExpandMoreIcon className={classes.tableHeaderIcon} />
                                        ) : (
                                            <SortIcon className={classes.tableHeaderIcon} />
                                        )}
                                    </Grid>
                                    <Grid container justify="center" alignItems="center" style={{ width: "13%" }}
                                        onClick={() => {
                                            sortHelper[3] === 0
                                                ? changeSortHelper([0, 0, 0, 1, 0, 0])
                                                : changeSortHelper([0, 0, 0, -sortHelper[3], 0, 0]);
                                        }}
                                    >
                                        <Typography className={classes.tableHeader}>
                                            {t("ADMIN.VIEWS.STATISTICS.INFLUENCER_AGENT_TABLE.COMMISSIONS_HEADER")}
                                        </Typography>
                                        {sortHelper[3] === 1 ? (
                                            <ExpandLessIcon className={classes.tableHeaderIcon} />
                                        ) : sortHelper[3] === -1 ? (
                                            <ExpandMoreIcon className={classes.tableHeaderIcon} />
                                        ) : (
                                            <SortIcon className={classes.tableHeaderIcon} />
                                        )}
                                    </Grid>
                                    <Grid container justify="center" alignItems="center" style={{ width: "13%" }}
                                        onClick={() => {
                                            sortHelper[4] === 0
                                                ? changeSortHelper([0, 0, 0, 0, 1, 0])
                                                : changeSortHelper([0, 0, 0, 0, -sortHelper[4], 0]);
                                        }}
                                    >
                                        <Typography className={classes.tableHeader}>
                                            {t("ADMIN.VIEWS.STATISTICS.INFLUENCER_AGENT_TABLE.PRODUCTS_HEADER")}
                                        </Typography>
                                        {sortHelper[4] === 1 ? (
                                            <ExpandLessIcon className={classes.tableHeaderIcon} />
                                        ) : sortHelper[4] === -1 ? (
                                            <ExpandMoreIcon className={classes.tableHeaderIcon} />
                                        ) : (
                                            <SortIcon className={classes.tableHeaderIcon} />
                                        )}
                                    </Grid>
                                    {/* <Grid container justify="center" alignItems="center" style={{ width: "13%" }}
                                        onClick={() => {
                                            sortHelper[4] === 0
                                                ? changeSortHelper([0, 0, 0, 0, 1, 0])
                                                : changeSortHelper([0, 0, 0, 0, -sortHelper[4], 0]);
                                        }}
                                    >
                                        <Typography className={classes.tableHeader}>
                                            clicks
                                        </Typography>
                                        {sortHelper[4] === 1 ? (
                                            <ExpandLessIcon className={classes.tableHeaderIcon} />
                                        ) : sortHelper[4] === -1 ? (
                                            <ExpandMoreIcon className={classes.tableHeaderIcon} />
                                        ) : (
                                            <SortIcon className={classes.tableHeaderIcon} />
                                        )}
                                        </Grid>*/}
                                </Grid>
                            </Grid>
                            {influencerAgentsToRenderFinal.map((influencerAgent) => {
                                return (
                                    <Grid container item direction="row" justify="center" className={classes.influencerBoxContainer} key={influencerAgent.influencerAgentData.key}>
                                        <Box className={classes.influencerBox}>
                                            <Grid container item direction="row" xs={12}>
                                                <Grid container item justify="flex-start" alignItems="center" style={{ width: "48%" }}>
                                                    <img src={influencerAgent.influencerAgentData.image} alt="" className={classes.influencerImage} />
                                                    <Typography className={classes.influencerName} style={{ marginLeft: "50px" }} onClick={() => openPopup(influencerAgent)}>
                                                        {influencerAgent.influencerAgentData.name}
                                                    </Typography>
                                                </Grid>
                                                <Grid container item justify="center" alignItems="center" style={{ width: "13%" }}>
                                                    <Typography className={classes.tableRowContent}>
                                                        {Math.round(influencerAgent.influencerAgentData.amount * 100) / 100} €
                                                </Typography>
                                                </Grid>
                                                <Grid container item justify="center" alignItems="center" style={{ width: "13%" }}>
                                                    <Typography className={classes.tableRowContent}>
                                                        {influencerAgent.influencerAgentData.salesNumber}
                                                    </Typography>
                                                </Grid>
                                                <Grid container item justify="center" alignItems="center" style={{ width: "13%" }}>
                                                    <Typography className={classes.tableRowContent}>
                                                        {Math.round(influencerAgent.influencerAgentData.commission * 100) / 100} €
                                                </Typography>
                                                </Grid>
                                                <Grid container item justify="center" alignItems="center" style={{ width: "13%" }}>
                                                    <Typography className={classes.tableRowContent}>
                                                        {influencerAgent.influencerAgentData.products.length}
                                                    </Typography>
                                                </Grid>
                                                {/*<Grid container item justify="center" alignItems="center" style={{ width: "13%" }}>
                                                    <Typography className={classes.tableRowContent}>
                                                        {influencerAgent.influencerAgentData.clicks}
                                                    </Typography>
                                                </Grid>*/}
                                            </Grid>
                                        </Box>
                                    </Grid>
                                );
                            })}
                        </Grid>
                    </div>
                </Grid>
                <Grid container item direction="row" xs={12} justify='center' alignItems="center" style={{ marginTop: "10px", marginBottom: "10px" }}>
                    <Grid container alignItems="center" justify="flex-end" style={{ width: '95%' }}>
                        <Select value={size} onChange={handlePageSizeChange} input={<BootstrapInput />} IconComponent={() => <SVGIcon name="miniArrow" />} classes={{ root: classes.paginationStyles }}>
                            <MenuItem value={10} style={{ fontFamily: "Poppins" }}>
                                10
                        </MenuItem>
                            <MenuItem value={25} style={{ fontFamily: "Poppins" }}>
                                25
                        </MenuItem>
                            <MenuItem value={50} style={{ fontFamily: "Poppins" }}>
                                50
                        </MenuItem>
                        </Select>
                        <Typography className={classes.tableHeader} style={{ marginRight: "10px", marginLeft: "10px" }}>
                            {t('ADMIN.VIEWS.INFLUENCERS.PAGINATION_PLACEHOLDER')} {influencersList.length}
                        </Typography>
                        <Pagination count={count} page={page} onChange={handlePageChange}
                            renderItem={(item) => (
                                <PaginationItem
                                    classes={{
                                        root: classes.paginationClass,
                                        selected: classes.selectedPaginationItem,
                                    }}
                                    {...item}
                                />
                            )}
                        />
                    </Grid>
                </Grid>
            </>
    );
};

const mapStateToProps = (state, ownProps) => {
    const appStateSortHelper = state.appState.adminStatisticsState.influencerAgentTableSortHelper;
    const appStatePageNumber = state.appState.adminStatisticsState.influencerAgentTablePageNumber;
    const appStatePageSize = state.appState.adminStatisticsState.influencerAgentTablePageSize;
    return {
        appStateSortHelper,
        appStatePageNumber,
        appStatePageSize
    };
};

const mapDispatchToState = (dispatch) => {
    return {
        changeSortHelper: (sortHelper) => {
            dispatch({ type: 'UPDATE_ADMIN_STATS_INFLUENCER_AGENT_TABLE_SORT_HELPER', sortHelper })
        },
        changePageNumber: (pageNumber) => {
            dispatch({ type: 'UPDATE_ADMIN_STATS_INFLUENCER_AGENT_TABLE_PAGE_NUMBER', pageNumber })
        },
        changePageSize: (pageSize) => {
            dispatch({ type: 'UPDATE_ADMIN_STATS_INFLUENCER_AGENT_TABLE_PAGE_SIZE', pageSize })
        }
    }
};

export default connect(mapStateToProps, mapDispatchToState)(InfluencerAgentsStatsTable);