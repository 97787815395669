import { Grid, InputBase, makeStyles, Typography, Button, useTheme, useMediaQuery, IconButton, MenuItem, Menu, Divider, CircularProgress, Backdrop, Snackbar, Dialog, DialogTitle, DialogContent, DialogActions, Input } from '@material-ui/core';
import Alert from '@material-ui/lab/Alert';
import MoreVertIcon from "@material-ui/icons/MoreVert";
import FilterListIcon from '@material-ui/icons/FilterList';
import React, { useEffect, useState } from 'react';
import { connect } from 'react-redux';
import { addNewCategory, removeNewCategory, updateCategoryById } from '../../../../store/actions/categoriesActions';
import { useTranslation } from "react-i18next";

const useStyle = makeStyles(theme => ({
    addCateogryLabelContainer: {
        marginTop: '20px',
        marginBottom: '30px'
    },
    addCategoryLabel: {
        fontFamily: 'Poppins',
    },
    addCategoryContainer: {
        minHeight: '125px',
        backgroundColor: '#fff',
        boxShadow: '0 7px 30px 0 rgba(88, 165, 211, 0.16)',
        borderRadius: '27px',
    },
    addCategoryInput: {
        marginLeft: '50px',
        fontFamily: 'Poppins'
    },
    addCategoryButton: {
        marginRight: '50px',
        marginTop: '10px',
        marginBottom: '10px',
        width: '140px',
        fontFamily: 'Poppins',
        color: '#fff',
        backgroundColor: '#3eadef',
        borderRadius: '27px',
        textTransform: 'none',
        '&:hover': {
            backgroundColor: '#3eadef',
        }
    },
    categTableHeader: {
        fontFamily: 'Poppins',
        padding: '10px'
    },
    categTableRow: {
        minHeight: '100px',
        backgroundColor: '#fff',
        boxShadow: '0 7px 30px 0 rgba(88, 165, 211, 0.16)',
        borderRadius: '27px',
        marginTop: '15px',
        marginBottom: '15px'
    },
    categTableCell: {
        fontFamily: 'Poppins',
        padding: '10px'
    },
    spacement: {
        marginTop: '20px',
        marginBottom: '20px'
    },
    menu: {
        boxShadow: "0 7px 7px 0 #d0e6f130",
    },
    validationField: {
        fontFamily: 'Poppins',
        color: 'red',
        fontSize: '14px'
    },
    overlay: {
        zIndex: theme.zIndex.drawer + 1
    },
    paperDialog: {
        width: '600px',
        minHeight: '200px',
        borderRadius: '35px',
        backgroundColor: '#eff9fe'
    },
    dialogTitle: {
        fontFamily: 'Poppins',
        fontSize: '20px',
        color: '#ff005fa1'
    },
    dialogInputRow: {
        backgroundColor: '#fff',
        borderRadius: '25px',
        boxShadow: '0 7px 30px 0 rgba(88, 165, 211, 0.16)',
        padding: '5px',
        margin: '5px'
    },
    dialogUpdateInput: {
        fontFamily: 'Poppins'
    },
    dialogUpdateButton: {
        margin: '5px',
        color: '#ffffff',
        paddingLeft: '20px',
        paddingRight: '20px',
        borderRadius: '35px',
        backgroundColor: '#3eadef',
        fontSize: '14px',
        textTransform: 'none',
        fontFamily: 'Poppins',
        boxShadow: '0 7px 30px 0 rgba(88, 165, 211, 0.16)',
        '&:hover': {
            backgroundColor: '#3eadef',
        },
    },
    dialogCancelButton: {
        margin: '5px',
        color: '#ffffff',
        paddingLeft: '20px',
        paddingRight: '20px',
        borderRadius: '35px',
        backgroundColor: '#717171',
        fontSize: '14px',
        textTransform: 'none',
        fontFamily: 'Poppins',
        boxShadow: '0 7px 30px 0 rgba(88, 165, 211, 0.16)',
        '&:hover': {
            backgroundColor: '#a9a9a9',
        },
    },
    menuDialog: {
        width: "500px",
        height: "170px",
        borderRadius: "20px",
    },
    cancelButton: {
        marginTop: '15px',
        padding: '6px 8px',
        width: "200px",
        height: "40px",
        color: '#ffffff',
        fontFamily: 'Poppins',
        fontSize: "16px",
        textTransform: "capitalize",
        borderRadius: "20px",
        backgroundColor: 'gray',
        '&:hover': {
            backgroundColor: 'gray'
        }
    },
    deleteButton: {
        marginTop: '15px',
        padding: '6px 8px',
        width: "180px",
        height: "40px",
        color: '#fff',
        fontFamily: 'Poppins',
        fontSize: "16px",
        textTransform: "capitalize",
        boxShadow: "0px 0px 0px 0px #00aeef",
        borderRadius: "20px",
        backgroundColor: 'red',
        '&:hover': {
            backgroundColor: 'red'
        }
    },
    dialogTitleDelete: {
        fontFamily: 'Poppins',
        fontSize: '21px',
        textAlign: 'center'
    },

}));

const CategoriesSubView = (props) => {
    const { t, i18n } = useTranslation("common");
    const classes = useStyle();
    const theme = useTheme();
    const smDown = useMediaQuery(theme.breakpoints.down('sm'));
    const xsDown = useMediaQuery(theme.breakpoints.down('xs'));
    const [anchorEl, setAnchorEl] = useState(null);

    const [categoryFrenchName, setCategoryFrenchName] = useState('');
    const [categoryEnglishName, setCategoryEnglishName] = useState('');
    const [categoryFrenchNameValidationField, setCategoryFrenchNameValidationField] = useState(null);
    const [categoryEnglishNameValidationField, setCategoryEnglishNameValidationField] = useState(null);
    const [categoryToUpdateFrenchName, setCategoryToUpdateFrenchName] = useState('');
    const [categoryToUpdateEnglishName, SetCategoryToUpdateEnglishName] = useState('');
    const [categoryToUpdateFrenchNameValidationField, setCategoryToUpdateFrenchNameValidationField] = useState(null);
    const [categoryToUpdateEnglishNameValidationField, setCategoryToUpdateEnglishNameValidationField] = useState(null);

    const [requestAddCategoryMode, setRequestAddCategoryMode] = useState(false);
    const [requestUpdateCategoryMode, setRequestUpdateCategoryMode] = useState(false);
    const [requestRemoveCategoryMode, setRequestRemoveCategoryMode] = useState(false);

    const [snackBarAddCategoryErrorState, setSnackBarAddCategoryErrorState] = useState(false);
    const [snackBarAddCategorySuccessState, setSnackBarAddCategorySuccessState] = useState(false);

    const [snackBarUpdateCategoryErrorState, setSnackBarUpdateCategoryErrorState] = useState(false);
    const [snackBarUpdateCategorySuccessState, setSnackBarUpdateCategorySuccessState] = useState(false);

    const [snackBarRemoveCategorySuccessState, setSnackBarRemoveCategorySuccessState] = useState(false);
    const [snackBarRemoveCategoryErrorState, setSnackBarRemoveCategoryErrorState] = useState(false);
    const [snackBarRemoveCategoryAffectErrorState, setSnackBarRemoveCategoryAffectErrorState] = useState(false);

    const [updateDialogState, setUpdateDialogState] = useState(false);
    const [categoryToUpdate, setCategoryToUpdate] = useState(null);
    const [openDialogDelete, setOpenDialogDelete] = useState(false);

    const {
        categories,
        loadedNewCategories,
        loadedAddCategory,
        loadedUpdateCategory,
        loadedRemoveCategory,
        addNewCategoryRequest,
        addNewCategoryError,
        updateCategoryRequest,
        updateCategoryError,
        removeCategoryRequest,
        removeCategoryError
    } = props;

    const handleOpenMenu = (event, category) => {
        setAnchorEl(event.currentTarget);
        setCategoryToUpdate(category);
    };

    const handleCloseMenu = () => {
        setAnchorEl(null);
    };

    const handleInputsValueChange = id => e => {
        if (id === 'categ-name-fr') setCategoryFrenchName(e.target.value);
        if (id === 'categ-name-en') setCategoryEnglishName(e.target.value);
        if (id === 'update-categ-name-fr') setCategoryToUpdateFrenchName(e.target.value);
        if (id === 'update-categ-name-en') SetCategoryToUpdateEnglishName(e.target.value);
    };

    const handleOpenUpdateDialog = () => {
        setUpdateDialogState(true);
        setAnchorEl(null);
    };

    const handleCloseUpdateDialog = () => {
        setCategoryToUpdate(null);
        setUpdateDialogState(false);
    };

    const addCategory = () => {
        if (categoryEnglishName === '' || categoryFrenchName === '') {
            (categoryFrenchName === '')
                ? setCategoryFrenchNameValidationField(t('ADMIN.VIEWS.CATEGORIES.CATEGORIES_TAB_CONTENT.VALIDATION.FRENCH_NAME'))
                : setCategoryFrenchNameValidationField(null);
            (categoryEnglishName === '')
                ? setCategoryEnglishNameValidationField(t('ADMIN.VIEWS.CATEGORIES.CATEGORIES_TAB_CONTENT.VALIDATION.ENGLISH_NAME'))
                : setCategoryEnglishNameValidationField(null);
        } else {
            setCategoryFrenchNameValidationField(null);
            setCategoryEnglishNameValidationField(null);
            setRequestAddCategoryMode(true);
            let categoryToAdd = {};
            categoryToAdd['text-fr'] = categoryFrenchName;
            categoryToAdd['text-en'] = categoryEnglishName;
            addNewCategoryRequest(categoryToAdd);
        }
    };

    const updateCategory = () => {
        if (categoryToUpdateEnglishName === '' || categoryToUpdateFrenchName === '') {
            (categoryToUpdateFrenchName === '')
                ? setCategoryToUpdateFrenchNameValidationField(t('ADMIN.VIEWS.CATEGORIES.CATEGORIES_TAB_CONTENT.VALIDATION.FRENCH_NAME'))
                : setCategoryToUpdateFrenchNameValidationField(null);
            (categoryToUpdateEnglishName === '')
                ? setCategoryToUpdateEnglishNameValidationField(t('ADMIN.VIEWS.CATEGORIES.CATEGORIES_TAB_CONTENT.VALIDATION.ENGLISH_NAME'))
                : setCategoryToUpdateEnglishNameValidationField(null);
        } else {
            setCategoryToUpdateFrenchNameValidationField(null);
            setCategoryToUpdateEnglishNameValidationField(null);
            setRequestUpdateCategoryMode(true);
            let updates = {};
            updates['text-fr'] = categoryToUpdateFrenchName;
            updates['text-en'] = categoryToUpdateEnglishName;
            updateCategoryRequest(categoryToUpdate, updates);
            setUpdateDialogState(false);
        }
    };

    const handleOpenDialogDelete = () => {
        setOpenDialogDelete(true);
    }
    const handleCloseDialogDelete = () => {
        setAnchorEl(null);
        setOpenDialogDelete(false);
    }

    const removeCategory = () => {
        setAnchorEl(null);
        setRequestRemoveCategoryMode(true);
        removeCategoryRequest(categoryToUpdate);
        setOpenDialogDelete(false);
    };

    useEffect(() => {
        if (!loadedAddCategory && requestAddCategoryMode) {
            if (!addNewCategoryError) {
                setCategoryFrenchName('');
                setCategoryEnglishName('');
                setSnackBarAddCategorySuccessState(true);
                setRequestAddCategoryMode(false);
            } else {
                setSnackBarAddCategoryErrorState(true);
                setRequestAddCategoryMode(false);
            }
        }
    }, [loadedAddCategory, addNewCategoryError, requestAddCategoryMode]);

    useEffect(() => {
        if (!loadedUpdateCategory && requestUpdateCategoryMode) {
            if (!updateCategoryError) {
                setCategoryToUpdateFrenchName('');
                SetCategoryToUpdateEnglishName('');
                setSnackBarUpdateCategorySuccessState(true);
                setRequestUpdateCategoryMode(false);
            } else {
                setSnackBarUpdateCategoryErrorState(true);
                setRequestUpdateCategoryMode(false);
            }
        }
    }, [loadedUpdateCategory, updateCategoryError, requestUpdateCategoryMode]);


    useEffect(() => {
        if (!loadedRemoveCategory && requestRemoveCategoryMode) {
            if (!removeCategoryError) {
                setSnackBarRemoveCategorySuccessState(true);
                setRequestRemoveCategoryMode(false);
            } else {
                setRequestRemoveCategoryMode(false);
                if (removeCategoryError.status && removeCategoryError.status === 400) {
                    setSnackBarRemoveCategoryAffectErrorState(true);
                    setSnackBarRemoveCategorySuccessState(true);
                } else {
                    setSnackBarRemoveCategoryErrorState(true);
                }
            }
        }
    }, [loadedRemoveCategory, removeCategoryError, requestRemoveCategoryMode]);

    useEffect(() => {
        if (categoryToUpdate) {
            setCategoryToUpdateFrenchName(categoryToUpdate['text-fr']);
            SetCategoryToUpdateEnglishName(categoryToUpdate['text-en']);
        }
    }, [categoryToUpdate]);

    return (
        (loadedNewCategories)
            ? <Grid container item direction='column'>
                <Dialog open={updateDialogState} onClose={handleCloseUpdateDialog} classes={{ paper: classes.paperDialog }}>
                    <DialogTitle style={{ display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
                        <Typography className={classes.dialogTitle}>
                            {t('ADMIN.VIEWS.CATEGORIES.CATEGORIES_TAB_CONTENT.UPDATE_CATEGORY_DIALOG.UPDATE_DIALOG_TITLE')}
                        </Typography>
                    </DialogTitle>
                    <DialogContent>
                        <Grid container item direction='column' alignItems='center'>
                            <Grid container item direction='row' justify='space-around' alignItems='center' xs={10} className={classes.dialogInputRow}>
                                <Typography className={classes.dialogUpdateInput} style={{ color: '#5d5d5db0' }}>
                                    {t('ADMIN.VIEWS.CATEGORIES.CATEGORIES_TAB_CONTENT.UPDATE_CATEGORY_DIALOG.CATEGORY_FRENCH_NAME_LABEL')}:
                                    </Typography>
                                <Input
                                    className={classes.dialogUpdateInput}
                                    disableUnderline
                                    value={categoryToUpdateFrenchName}
                                    onChange={handleInputsValueChange('update-categ-name-fr')}
                                />
                            </Grid>
                            <Grid container item direction='row' justify='space-around' alignItems='center' xs={10}>
                                <Typography className={classes.validationField}>{categoryToUpdateFrenchNameValidationField}</Typography>
                            </Grid>
                            <Grid container item direction='row' justify='space-around' alignItems='center' xs={10} className={classes.dialogInputRow}>
                                <Typography className={classes.dialogUpdateInput} style={{ color: '#5d5d5db0' }}>
                                    {t('ADMIN.VIEWS.CATEGORIES.CATEGORIES_TAB_CONTENT.UPDATE_CATEGORY_DIALOG.CATEGORY_ENGLISH_NAME_LABEL')}:
                                </Typography>
                                <Input
                                    className={classes.dialogUpdateInput}
                                    disableUnderline
                                    value={categoryToUpdateEnglishName}
                                    onChange={handleInputsValueChange('update-categ-name-en')}
                                />
                            </Grid>
                            <Grid container item direction='row' justify='space-around' alignItems='center' xs={10}>
                                <Typography className={classes.validationField}>{categoryToUpdateEnglishNameValidationField}</Typography>
                            </Grid>
                        </Grid>
                    </DialogContent>
                    <DialogActions style={{ paddingRight: '30px' }}>
                        <Button className={classes.dialogCancelButton} onClick={handleCloseUpdateDialog}>
                            {t('ADMIN.VIEWS.CATEGORIES.CATEGORIES_TAB_CONTENT.UPDATE_CATEGORY_DIALOG.CANCEL_BUTTON')}:
                        </Button>
                        <Button className={classes.dialogUpdateButton} onClick={updateCategory}>
                            {t('ADMIN.VIEWS.CATEGORIES.CATEGORIES_TAB_CONTENT.UPDATE_CATEGORY_DIALOG.UPDATE_BUTTON')}:
                        </Button>
                    </DialogActions>
                </Dialog>
                <Dialog classes={{ paper: classes.menuDialog }} open={openDialogDelete} onClose={handleCloseDialogDelete} aria-labelledby="form-dialog-title">
                    <Grid container item direction='column' xs={12} alignItems='center' style={{ marginTop: '20px' }}>
                        <Typography className={classes.dialogTitleDelete}> {t('ADMIN.VIEWS.CATEGORIES.CATEGORIES_TAB_CONTENT.DELETE_CATEGORY_DIALOG.DELETE_DIALOG_TITLE')}</Typography>
                    </Grid>
                    <Grid container item direction='row' xs={12} justify='center'>
                        <Button className={classes.cancelButton} onClick={handleCloseDialogDelete} style={{ marginRight: (xsDown) ? '0px' : '15px' }}> {t('ADMIN.VIEWS.CATEGORIES.CATEGORIES_TAB_CONTENT.DELETE_CATEGORY_DIALOG.CANCEL_BUTTON')}</Button>
                        <Button className={classes.deleteButton} onClick={removeCategory} style={(xsDown) ? { marginBottom: '10px' } : { marginBottom: '0' }}>{t('ADMIN.VIEWS.CATEGORIES.CATEGORIES_TAB_CONTENT.DELETE_CATEGORY_DIALOG.DELETE_BUTTON')}</Button>
                    </Grid>
                </Dialog>
                <Backdrop className={classes.overlay} open={loadedAddCategory || loadedUpdateCategory || loadedRemoveCategory}>
                    <CircularProgress color='secondary' />
                </Backdrop>
                <Snackbar open={snackBarAddCategorySuccessState} onClose={() => setSnackBarAddCategorySuccessState(false)} autoHideDuration={2000}>
                    <Alert severity="success" variant='filled' style={{ fontFamily: 'Poppins' }}>
                        {t('ADMIN.VIEWS.CATEGORIES.CATEGORIES_TAB_CONTENT.SNACKBARS.ADD_SUCCESS')}
                    </Alert>
                </Snackbar>
                <Snackbar open={snackBarAddCategoryErrorState} onClose={() => setSnackBarAddCategoryErrorState(false)} autoHideDuration={2000}>
                    <Alert severity="error" variant='filled' style={{ fontFamily: 'Poppins' }}>
                        {t('ADMIN.VIEWS.CATEGORIES.CATEGORIES_TAB_CONTENT.SNACKBARS.ADD_ERROR')}
                    </Alert>
                </Snackbar>
                <Snackbar open={snackBarUpdateCategorySuccessState} onClose={() => setSnackBarUpdateCategorySuccessState(false)} autoHideDuration={2000}>
                    <Alert severity="success" variant='filled' style={{ fontFamily: 'Poppins' }}>
                        {t('ADMIN.VIEWS.CATEGORIES.CATEGORIES_TAB_CONTENT.SNACKBARS.UPDATE_SUCCESS')}
                    </Alert>
                </Snackbar>
                <Snackbar open={snackBarUpdateCategoryErrorState} onClose={() => setSnackBarUpdateCategoryErrorState(false)} autoHideDuration={2000}>
                    <Alert severity="error" variant='filled' style={{ fontFamily: 'Poppins' }}>
                        {t('ADMIN.VIEWS.CATEGORIES.CATEGORIES_TAB_CONTENT.SNACKBARS.UPDATE_ERROR')}
                    </Alert>
                </Snackbar>
                <Snackbar open={snackBarRemoveCategorySuccessState} onClose={() => setSnackBarRemoveCategorySuccessState(false)} autoHideDuration={2000}>
                    <Alert severity="success" variant='filled' style={{ fontFamily: 'Poppins' }}>
                        {t('ADMIN.VIEWS.CATEGORIES.CATEGORIES_TAB_CONTENT.SNACKBARS.REMOVE_SUCCESS')}
                    </Alert>
                </Snackbar>
                <Snackbar open={snackBarRemoveCategoryErrorState} onClose={() => setSnackBarRemoveCategoryErrorState(false)} autoHideDuration={2000}>
                    <Alert severity="error" variant='filled'>
                        {t('ADMIN.VIEWS.CATEGORIES.CATEGORIES_TAB_CONTENT.SNACKBARS.REMOVE_ERROR')}
                    </Alert>
                </Snackbar>
                <Snackbar open={snackBarRemoveCategoryAffectErrorState} onClose={() => setSnackBarRemoveCategoryAffectErrorState(false)} autoHideDuration={2000}>
                    <Alert severity="error" variant='filled' style={{ fontFamily: 'Poppins' }}>
                        {t('ADMIN.VIEWS.CATEGORIES.CATEGORIES_TAB_CONTENT.SNACKBARS.REMOVE_AFFECT_ERROR')}
                    </Alert>
                </Snackbar>
                <Grid container item direction='row' xs={12} className={classes.addCateogryLabelContainer}>
                    <Typography className={classes.addCategoryLabel}>{t('ADMIN.VIEWS.CATEGORIES.CATEGORIES_TAB_CONTENT.ADD_CATEGORY_LABEL')}</Typography>
                </Grid>
                <Grid container item direction='row' xs={12} justify={(smDown) ? 'center' : 'space-between'} alignItems='center' className={classes.addCategoryContainer}>
                    <Grid container item direction='column' justify='space-evenly' style={{ width: (smDown) ? '100%' : 'calc(100% - 190px)', height: '125px' }}>
                        <InputBase
                            value={categoryFrenchName}
                            className={classes.addCategoryInput}
                            style={(smDown) ? { marginLeft: '0px', paddingLeft: '20px', paddingRight: '20px' } : {}}
                            placeholder={t('ADMIN.VIEWS.CATEGORIES.CATEGORIES_TAB_CONTENT.CATEGORY_FRENCH_NAME_PLACEHOLDER')}
                            onChange={handleInputsValueChange('categ-name-fr')} />
                        <Divider light style={(smDown) ? { marginRight: '20px', marginLeft: '20px' } : { marginRight: '50px', marginLeft: '20px' }} />
                        <InputBase
                            value={categoryEnglishName}
                            className={classes.addCategoryInput}
                            style={(smDown) ? { marginLeft: '0px', paddingLeft: '20px', paddingRight: '20px' } : {}}
                            placeholder={t('ADMIN.VIEWS.CATEGORIES.CATEGORIES_TAB_CONTENT.CATEGORY_ENGLISH_NAME_PLACEHOLDER')}
                            onChange={handleInputsValueChange('categ-name-en')} />
                    </Grid>
                    <Button className={classes.addCategoryButton} style={(smDown) ? { marginRight: 0 } : {}} onClick={addCategory}>
                        {t('ADMIN.VIEWS.CATEGORIES.CATEGORIES_TAB_CONTENT.ADD_CATEGORY_BUTTON')}
                    </Button>
                </Grid>
                <Grid container item direction='column' style={{ marginTop: '15px' }}>
                    <Typography className={classes.validationField}>{categoryFrenchNameValidationField}</Typography>
                    <Typography className={classes.validationField}>{categoryEnglishNameValidationField}</Typography>
                </Grid>
                <Grid container item direction='row' xs={12} style={{ overflowX: "auto" }}>
                    <div style={{ width: '100%', minWidth: '1050px' }}>
                        <Grid container item direction='row' alignItems='center' className={classes.spacement}>
                            <Grid container item direction='row' alignItems='center' style={{ width: '47%' }}>
                                <Typography className={classes.categTableHeader}>
                                    {t('ADMIN.VIEWS.CATEGORIES.CATEGORIES_TAB_CONTENT.CATEGORIES_TABLE_HEADERS.CATEGORIES_HEADER')}
                                </Typography>
                                <FilterListIcon />
                            </Grid>
                            <Grid container item direction='row' alignItems='center' style={{ width: '47%' }}>
                                <Typography className={classes.categTableHeader}>
                                    {t('ADMIN.VIEWS.CATEGORIES.CATEGORIES_TAB_CONTENT.CATEGORIES_TABLE_HEADERS.SUB_CATEGORIES_HEADER')}
                                </Typography>
                            </Grid>
                        </Grid>
                        {
                            categories.map(category => (
                                <Grid container item direction='row' alignItems='center' className={classes.categTableRow} key={category.key}>
                                    <Grid container item direction='row' style={{ width: '47%' }}>
                                        <Typography className={classes.categTableCell}>{(i18n.language === 'fr-FR' || i18n.language === 'fr') ? category['text-fr'] : category['text-en']}</Typography>
                                    </Grid>
                                    <Grid container item direction='row' style={{ width: '47%' }}>
                                        <Typography className={classes.categTableCell} style={{ textDecoration: 'underline', cursor: 'pointer' }}>
                                            {t('ADMIN.VIEWS.CATEGORIES.CATEGORIES_TAB_CONTENT.CATEGORIES_TABLE_HEADERS.SUB_CATEGORIES_HEADER')}
                                        </Typography>
                                    </Grid>
                                    <Grid container item direction='row' style={{ width: '6%' }}>
                                        <IconButton onClick={(e) => handleOpenMenu(e, category)}>
                                            <MoreVertIcon />
                                        </IconButton>
                                    </Grid>
                                    <Menu classes={{ paper: classes.menu }} anchorEl={anchorEl} open={Boolean(anchorEl)} onClose={handleCloseMenu} >
                                        <MenuItem className={classes.categTableCell} onClick={handleOpenUpdateDialog}>
                                            {t('ADMIN.VIEWS.CATEGORIES.CATEGORIES_TAB_CONTENT.CATEGORIES_TABLE_MENU.EDIT_LINK')}
                                        </MenuItem>
                                        <MenuItem className={classes.categTableCell} onClick={handleOpenDialogDelete}>
                                            {t('ADMIN.VIEWS.CATEGORIES.CATEGORIES_TAB_CONTENT.CATEGORIES_TABLE_MENU.DELETE_LINK')}
                                        </MenuItem>
                                    </Menu>
                                </Grid>
                            ))
                        }
                    </div>
                </Grid>
            </Grid>
            : <Grid container item direction='column' justify='center' alignItems='center' style={{ flexGrow: 1 }}>
                <CircularProgress color='secondary' />
            </Grid>
    )
};

const mapStateToProps = (state, ownProps) => {
    const categories = state.categoriesState.newCategories;
    const loadedNewCategories = state.categoriesState.loadedNewCategories;
    const loadedAddCategory = state.categoriesState.loadedAddCategory;
    const loadedUpdateCategory = state.categoriesState.loadedUpdateCategory;
    const loadedRemoveCategory = state.categoriesState.loadedRemoveCategory;
    const addNewCategoryError = state.categoriesState.addNewCategoryError;
    const updateCategoryError = state.categoriesState.updateCategoryError;
    const removeCategoryError = state.categoriesState.removeCategoryError;
    return {
        categories,
        loadedNewCategories,
        loadedAddCategory,
        loadedUpdateCategory,
        loadedRemoveCategory,
        updateCategoryError,
        addNewCategoryError,
        removeCategoryError
    };
};

const mapDispatchToProps = (dispatch) => {
    return {
        addNewCategoryRequest: (category) => {
            dispatch({ type: 'ADD_NEW_CATEGORY_REQUEST' });
            dispatch(addNewCategory(category))
        },
        updateCategoryRequest: (category, updates) => {
            dispatch({ type: 'UPDATE_CATEGORY_REQUEST' });
            dispatch(updateCategoryById(category, updates));
        },
        removeCategoryRequest: (category) => {
            dispatch({ type: 'REMOVE_CATEGORY_REQUEST' });
            dispatch(removeNewCategory(category));
        }
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(CategoriesSubView);