import ReactDOM from "react-dom";

import { Vector2D } from "../../utils";

import "./Overlay.css";

/*
 *** Status
 ***	0 : No overlay
 *** 1 : Overlay
 *** 2 : Content
 *** 3 : Finished
 */

class Overlay {
  constructor() {
    this.id = 0;
    this._status = 0;
    this._currentOverlay = null;
    this._deletedStack = [];
    this._timers = {
      apparition: 400,
      disparition: 400,
      content: 150,
    };
  }

  _growEffect = (overlay, width, height, position, opacity = 1) => {
    // overlay.style.left = position.x - 1 + 'px'
    // overlay.style.top = position.y - 1 + 'px'
    overlay.style.margin = `${height / 2}px 0 0 ${width / 2}px`;
    overlay.style.width = "0px";
    overlay.style.height = "0px";
    overlay.style.transition = `opacity ${this._timers.apparition / 1000}s ease-out, margin ${this._timers.apparition / 1000 / 2}s ease-in, width ${this._timers.apparition / 1000 / 2}s ease-in, height ${this._timers.apparition / 1000 / 2}s ease-in` ;
    setTimeout(() => {
      overlay.style.opacity = opacity.toString();
      overlay.style.margin = "0 0 0 0";
      overlay.style.width =  `${width + 2}px`;
      overlay.style.height = `${height + 2}px`;
    }, 100);
  };

  create = (target, content, width, height, position = new Vector2D(0, 0)) => {
    const overlay = document.createElement("div"),
      wrapper = document.createElement("div");
    let htmlContent = null;

    wrapper.className = "wrapper cursorTrigger";
    wrapper.style.left = position.x - 1 + "px";
    wrapper.style.top = position.y - 1 + "px";
    wrapper.style.width = width + "px";
    wrapper.style.height = height + "px";

    overlay.className = "overlay";
    this._status = 1;
    this._currentOverlay = {
      id: this.id++,
      parent: target,
      element: wrapper,
      position: position,
      size: {
        width,
        height,
      },
      contentPop: null,
    };
    if (this.id > 99) this.id = 0;
    wrapper.appendChild(overlay);
    target.appendChild(wrapper);

    if (content) {
      const events = content.events;
      let index;

      htmlContent = ReactDOM.render(content.element, overlay);
      if (htmlContent) {
        this._currentOverlay.contentPop = setTimeout(() => {
          this._status = 2;
          for (index in events)
            htmlContent.addEventListener(index, events[index]);
          htmlContent.style.transition =
            "opacity " + this._timers.content / 1000 + "s linear";
          htmlContent.style.opacity = 1;
          setTimeout(() => {
            this._status = 3;
          }, this._timers.content / 1000);
        }, this._timers.apparition);
      }
    }

    this._growEffect(overlay, width, height, position);
  };

  removeCurrent = () => {
    if (this._currentOverlay) {
      const { parent, element, contentPop } = this._currentOverlay;

      clearTimeout(contentPop);
      this._deletedStack.push(element);
      this._currentOverlay = null;
      element.style.opacity = "0";
      setTimeout(() => {
        parent.removeChild(element);
        this._deletedStack.splice(this._deletedStack.indexOf(element), 1);
      }, this._timers.disparition);
    }
  };

  getCurrent = () => {
    if (this._currentOverlay)
      return {
        ...this._currentOverlay,
      };
    return null;
  };

  getStatus = () => {
    const ret = this._status;

    return ret;
  };

  getDeleted = () => {
    return [...this._deletedStack];
  };

  getTimers = () => {
    return {
      ...this._timers,
    };
  };
}

export default Overlay;
