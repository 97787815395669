import { Dialog, Divider, Grid, IconButton, Typography } from '@material-ui/core';
import { makeStyles } from '@material-ui/styles';
import React, { useEffect, useState } from 'react';
import CloseIcon from '@material-ui/icons/Close';
import { useTranslation } from "react-i18next";

const useStyles = makeStyles(() => ({
    paperDialog: {
        width: '600px',
        minHeight: '400px',
        borderRadius: '35px'
    },
    dialogTitle: {
        fontFamily: 'Poppins',
        fontWeight: 'bolder',
        color: '#de78a2',
        fontSize: '28px',
        marginTop: '-10px'
    },
    influencerPicture: {
        width: '240px',
        height: '120px',
        borderRadius: '21px',
        objectFit: 'fill',
        margin: '10px'
    },
    influencerName: {
        fontFamily: 'Poppins',
        fontSize: '18px',
        fontWeight: 'bold'
    },
    statsTitles: {
        fontFamily: 'Poppins',
        fontSize: '18px',
    },
    stats: {
        fontFamily: 'Poppins'
    },
    productPicture: {
        width: '100px',
        height: '100px',
        objectFit: 'fill'
    },
    brandName: {
        fontFamily: 'Poppins',
        color: '#55aeec',
        fontSize: '18px',
        padding: 10
    }
}))

const NotificationDialog = (props) => {
    const { t } = useTranslation('common');

    const classes = useStyles();
    const [open, setOpen] = useState(false);
    const [conversionInfos, setConversionInfos] = useState(null);

    const handleClose = () => {
        setOpen(false);
    }

    useEffect(() => {
        if (props.notificationInfos) {
            console.log(props.notificationInfos);
            setConversionInfos(props.notificationInfos);
            setOpen(true);
            setTimeout(() => {
                handleClose();
            }, 10000)
        }
    }, [props.notificationInfos]);

    return (
        <Dialog
            open={open}
            onClose={handleClose}
            classes={{ paper: classes.paperDialog }}>
            <Grid container item direction='row-reverse'>
                <IconButton onClick={handleClose} style={{ marginTop: 10, marginRight: 10 }}>
                    <CloseIcon />
                </IconButton>
            </Grid>
            <Grid container item direction='column' justify='center' alignItems='center'>
                <Typography className={classes.dialogTitle}>{t('ADMIN.VIEWS.DASHBOARD.NEW_SALE_POPUP.NEW_SALE')}</Typography>
                <img alt={''} src={(conversionInfos) ? conversionInfos.influencerInfos.banner : ''} className={classes.influencerPicture} />
                <Typography className={classes.influencerName} >{(conversionInfos) ? conversionInfos.influencerInfos.name : ''}</Typography>
                <Divider style={{ width: '35%', border: 'solid 0.5px', margin: 10 }} />
            </Grid>
            <Grid container item direction='row' justify='space-evenly' alignItems='center' xs={12} style={{ padding: '10px' }}>
                <Grid container item direction='column' justify='center' alignItems='center' xs={12} md={4}>
                    <Typography className={classes.statsTitles}>{t('ADMIN.VIEWS.DASHBOARD.NEW_SALE_POPUP.AMOUNT')}</Typography>
                    <Typography className={classes.stats}>{(conversionInfos) ? conversionInfos.amount : 0} €</Typography>
                </Grid>
                <Grid container item direction='column' justify='center' alignItems='center' xs={12} md={4}>
                    <img alt={''} src={(conversionInfos) ? conversionInfos.articleImgUrl : ''} className={classes.productPicture} />
                    <Typography className={classes.brandName}> {(conversionInfos) ? conversionInfos.brandInfos.displayName : ''} </Typography>
                </Grid>
                <Grid container item direction='column' justify='center' alignItems='center' xs={12} md={4}>
                    <Typography className={classes.statsTitles}>{t('ADMIN.VIEWS.DASHBOARD.NEW_SALE_POPUP.COMMISSION')}</Typography>
                    <Typography className={classes.stats}>{(conversionInfos) ? conversionInfos.commission : 0} €</Typography>
                </Grid>
            </Grid>
        </Dialog>
    )
};

export default NotificationDialog;