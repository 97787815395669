const initState = {
    categories: [],
    newCategories: [],
    subCategories: [],
    categoriesError: null,
    subCategoriesError: null,
    loadedCategories: false,
    loadedNewCategories: false,
    loadedAddCategory: false,
    loadedUpdateCategory: false,
    loadedRemoveCategory: false,
    loadedSubCategories: false,
    loadedAddSubCategory: false,
    loadedUpdateSubCategory: false,
    loadedRemoveSubCategory: false,
    addNewCategoryError: null,
    updateCategoryError: null,
    removeCategoryError: null,
    addSubCategoryError: null,
    updateSubCategoryError: null,
    removeSubCategoryError: null
}

const categoriesReducer = (state = initState, action) => {
    switch (action.type) {
        case 'FETCH_ALL_CATEGORIES_SUCCESS':
            return {
                ...state,
                categories: action.categories,
                loadedCategories: true,
            };
        case 'FETCH_ALL_CATEGORIES_ERROR':
            return {
                ...state,
                categoriesError: action.error,
                loadedCategories: false
            };
        case 'FETCH_NEW_CATEGORIES_SUCCESS':
            return {
                ...state,
                newCategories: action.categories,
                loadedNewCategories: true,
            };
        case 'FETCH_NEW_CATEGORIES_ERROR':
            return {
                ...state,
                categoriesError: action.error,
                loadedNewCategories: false
            };
        case 'ADD_NEW_CATEGORY_REQUEST':
            return {
                ...state,
                loadedAddCategory: true,
                addNewCategoryError: null,
            };
        case 'ADD_NEW_CATEGORY_SUCCESS':
            let categories = state.newCategories;
            categories.push(action.payload.category);
            return {
                ...state,
                newCategories: categories,
                loadedAddCategory: false,
                addNewCategoryError: null,
            };
        case 'ADD_NEW_CATEGORY_ERROR':
            return {
                ...state,
                addNewCategoryError: action.error,
                loadedAddCategory: false
            };
        case 'UPDATE_CATEGORY_REQUEST':
            return {
                ...state,
                loadedUpdateCategory: true
            };
        case 'UPDATE_CATEGORY_SUCCESS':
            let updatedCategories = state.newCategories.map(category => {
                if (category.key === action.payload.categoryId) {
                    let updatedCategory = {
                        ...category
                    };
                    Object.entries(action.payload.updates).forEach(([key, value]) => {
                        updatedCategory[key] = value;
                    })
                    return updatedCategory;
                } else {
                    return category;
                }
            });
            return {
                ...state,
                loadedUpdateCategory: false,
                newCategories: updatedCategories
            };
        case 'UPDATE_CATEGORY_ERROR':
            return {
                ...state,
                loadedUpdateCategory: false,
                updateCategoryError: action.error
            };
        case 'REMOVE_CATEGORY_REQUEST':
            return {
                ...state,
                loadedRemoveCategory: true,
                removeCategoryError: null
            };
        case 'REMOVE_CATEGORY_SUCCESS_WITH_DEPENDENCE':
            {
                let newSubCategories = state.subCategories.map(subCategory => {
                    if (subCategory.categ && subCategory.categ.indexOf(action.payload.removedCategory.key) !== -1) {
                        let updatedSubCategory = {
                            ...subCategory
                        };
                        updatedSubCategory.categ = updatedSubCategory.categ.filter(catKey => catKey !== action.payload.removedCategory.key);
                        if (updatedSubCategory.categ.length === 0) {
                            return null
                        };
                        return updatedSubCategory;
                    } else {
                        return subCategory;
                    }
                });
                newSubCategories = newSubCategories.filter(item => item !== null);
                let newCategories = state.newCategories.filter(categ => categ.key !== action.payload.removedCategory.key);
                return {
                    ...state,
                    subCategories: newSubCategories,
                    newCategories: newCategories,
                    loadedRemoveCategory: false,
                    removeCategoryError: null
                };
            };
        case 'REMOVE_CATEGORY_WITHOUT_DEPENDENCE':
            {
                let newCategories = state.newCategories.filter(categ => categ.key !== action.payload.removedCateg.key);
                return {
                    ...state,
                    newCategories: newCategories,
                    loadedRemoveCategory: false,
                    removeCategoryError: {
                        status: 400,
                        message: 'Remove from child error'
                    }
                };
            };
        case 'FETCH_SUB_CATEGORIES_SUCCESS':
            return {
                ...state,
                subCategories: action.payload.subCategories,
                loadedSubCategories: true
            };
        case 'FETCH_SUB_CATEGORIES_ERROR':
            return {
                ...state,
                loadedSubCategories: false,
                subCategoriesError: action.error
            };
        case 'ADD_SUB_CATEGORY_REQUEST':
            return {
                ...state,
                loadedAddSubCategory: true,
                addSubCategoryError: null
            };
        case 'ADD_SUB_CATEGORY_SUCCESS_WITH_AFFECTATION':
            {
                let subCategories = state.subCategories;
                let newCategories = state.newCategories.map(category => {
                    if (action.payload.parentCategoriesIds.indexOf(category.key) !== -1) {
                        (category.subCateg)
                            ? category.subCateg.push(action.payload.subCategory.key)
                            : category['subCateg'] = [action.payload.subCategory.key];
                        return category;
                    } else {
                        return category;
                    }
                });
                subCategories.push(action.payload.subCategory);
                return {
                    ...state,
                    newCategories: newCategories,
                    subCategories: subCategories,
                    loadedAddSubCategory: false,
                    addSubCategoryError: null
                };
            };
        case 'ADD_SUB_CATEGORY_SUCCESS_WITHOUT_AFFECTATION':
            {
                let subCategories = state.subCategories;
                subCategories.push(action.payload.subCategory);
                return {
                    ...state,
                    subCategories: subCategories,
                    loadedAddSubCategory: false,
                    addSubCategoryError: {
                        status: 400,
                        message: 'Affectation Error'
                    }
                };
            };
        case 'ADD_SUB_CATEGORY_ERROR':
            return {
                ...state,
                loadedAddSubCategory: false,
                addSubCategoryError: action.error
            };
        case 'UPDATE_SUB_CATEGORY_REQUEST':
            return {
                ...state,
                updateSubCategoryError: null,
                loadedUpdateSubCategory: true
            };
        case 'UPDATE_SUB_CATEGORY_SUCCESS_WITH_AFFECTATION':
            {
                let updatedCategories = state.newCategories.map(category => {
                    if (action.payload.updates.newAddedParents.length !== 0 || action.payload.updates.removedParents.length !== 0) {
                        let updatedCategory = {
                            ...category
                        };
                        let newCategArray = (category.subCateg) ? category.subCateg : [];
                        if (action.payload.updates.newAddedParents.indexOf(category.key) !== -1) {
                            newCategArray.push(action.payload.subCategoryId);
                        };
                        if (action.payload.updates.removedParents.indexOf(category.key) !== -1) {
                            newCategArray = newCategArray.filter(item => item.key !== action.payload.subCategoryId)
                        };
                        if (newCategArray.length !== 0) updatedCategory['subCateg'] = newCategArray;
                        return updatedCategory;
                    } else {
                        return category;
                    }
                });
                let updatesWithoutParams = action.payload.updates;
                delete updatesWithoutParams.newAddedParents;
                delete updatesWithoutParams.removedParents;
                let updatedSubCategies = state.subCategories.map(subCategory => {
                    if (subCategory.key === action.payload.subCategoryId) {
                        let updatedSubCategory = {
                            ...subCategory
                        };
                        Object.entries(updatesWithoutParams).forEach(([key, value]) => {
                            updatedSubCategory[key] = value
                        });
                        return updatedSubCategory;
                    } else {
                        return subCategory;
                    }
                });
                return {
                    ...state,
                    newCategories: updatedCategories,
                    subCategories: updatedSubCategies,
                    loadedUpdateSubCategory: false,
                    updateSubCategoryError: null,
                };
            };
        case 'UPDATE_SUB_CATEGORY_SUCCESS_WITHOUT_AFFECTATION':
            {
                let updatedSubCategies = state.subCategories.map(subCategory => {
                    if (subCategory.key === action.payload.subCategoryId) {
                        let updatedSubCategory = {
                            ...subCategory
                        };
                        Object.entries(action.payload.updates).forEach(([key, value]) => {
                            updatedSubCategory[key] = value
                        });
                        return updatedSubCategory;
                    } else {
                        return subCategory;
                    }
                });
                return {
                    ...state,
                    subCategories: updatedSubCategies,
                    loadedUpdateSubCategory: false,
                    addSubCategoryError: {
                        status: 400,
                        message: 'Affectation Error'
                    }
                }
            };
        case 'REMOVE_SUB_CATEGORY_REQUEST':
            return {
                ...state,
                loadedRemoveSubCategory: true,
                removeSubCategoryError: null
            };
        case 'REMOVE_SUB_CATEGORY_SUCCESS_WITH_DEPENDENCE':
            {
                let newCategories = state.newCategories.map(category => {
                    if (category.subCateg && category.subCateg.indexOf(action.payload.removedSubCategory.key)) {
                        let updatedCategory = {
                            ...category
                        };
                        updatedCategory.subCateg = updatedCategory.subCateg.filter(catKey => catKey === action.payload.removedSubCategory.key);
                        return updatedCategory;
                    } else {
                        return category;
                    }
                });
                let newSubCategories = state.subCategories.filter(subCateg => subCateg.key !== action.payload.removedSubCategory.key);
                return {
                    ...state,
                    subCategories: newSubCategories,
                    newCategories: newCategories,
                    loadedRemoveSubCategory: false,
                    removeSubCategoryError: null
                };
            };
        case 'REMOVE_SUB_CATEGORY_SUCCESS_WITHOUT_DEPENDENCE':
            {
                let newSubCategories = state.subCategories.filter(subCateg => subCateg.key !== action.payload.removedSubCategory.key);
                return {
                    ...state,
                    subCategories: newSubCategories,
                    loadedRemoveSubCategory: false,
                    removeSubCategoryError: {
                        status: 400,
                        message: 'Remove from parent Error'
                    }
                };
            };
        case 'REMOVE_SUB_CATEGORY_ERROR':
            return {
                ...state,
                removeSubCategoryError: action.error,
                loadedRemoveSubCategory: false
            }
        default:
            return state;
    }
}

export default categoriesReducer;