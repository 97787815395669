import * as THREE from "three";

// Component Scope
import SceneEvents from "./Events/Events";

// UI Scope
import { Overlay, OverlayV2 } from "../overlay";

// Relative Dependencies
import {
  Vector2D,
  getIndexById,
  pointInElement,
} from "./utils";

const renderer = new THREE.WebGLRenderer({
  antialias: true,
});

/********************************  TEST POUR WEBGL ********************************************* */
export default class Scene2D {
  constructor(
    rootElement,
    isMobile,
    infinite,
    redirectFunction = null
  ) {
    this.viewFPS = false;

    this.infinite = infinite;
    this.root = rootElement;
    this._deleted = false;

    this._sceneObjects = {
      camera: null,
      scene: null,
      renderer: null,
      raycaster: null,
      overlayHandle: new Overlay(),
    };
    this._sceneData = {
      totalBlocks: new Vector2D(0, 0),
      stopCamera: new Vector2D(0, 0),
      row: 0,

      //Need to be replaced by block own size
      wBlock: 0,
      hBlock: 0,

      step: 0,
      friction: 1.075,

      mats: [],
      batchedMats: [],
      isMobile: null,
    };
    this._animation = {
      leftBlocks: {
        block: null,
        x: 0,
        y: 0,
        rot: 30,
      },
      rightBlocks: {
        block: null,
        x: 0,
        y: 0,
        rot: 15,
      },
      timers: {
        apparition: 125,
        disparition: this._sceneObjects.overlayHandle.getTimers()
          .disparition,
      },
    };

    //New way to do things, old way must be replaced
    this.clicked = false;
    this.dragged = false;
    this.mobile = isMobile;
    this.vectors = {
      mousePosition: new Vector2D(0, 0),
      distance: new Vector2D(0, 0),
      position: new Vector2D(0, 0),
      slide: new Vector2D(0, 0),
    };
    this.overlay = {
      current: null,
      deleted: [],
    };

    //Get the events from another file
    this.events = SceneEvents(this);
  }

  _init = () => {
    const rect = this.root.getBoundingClientRect();
    let camera, scene, width, height;

    width = rect.width;
    height = rect.height;

    this.width = width;
    this.height = height;
    this.offsets = {
      x: rect.left,
      y: rect.top,
    };

    camera = new THREE.OrthographicCamera(
      -width / 2,
      width / 2,
      height / 2,
      -height / 2,
      1,
      2000
    );
    camera.position.y = 2;
    scene = new THREE.Scene();
    scene.background = new THREE.Color("white");
    camera.lookAt(scene.position);
    renderer.setSize(width, height);

    this._sceneObjects.camera = camera;
    this._sceneObjects.scene = scene;
    this._sceneObjects.raycaster = new THREE.Raycaster();
    this.root.appendChild(renderer.domElement);

    this.root.onmousedown = this.events.mouseDown;
    this.root.ontouchstart = this.events.mouseDown;
    this.root.onmousemove = this.events.mouseMove;
    this.root.ontouchmove = this.events.mouseMove;
    this.root.onmouseup = this.events.mouseUp;
    this.root.ontouchend = this.events.mouseUp;
    // this.root.onmouseleave = this._mouseUp
    window.onresize = this._resize;

    renderer.render(scene, camera);
  };

  _resize = () => {
    const rect = this.root.getBoundingClientRect(),
      width = rect.width,
      height = rect.height;

    //Removing overlay for now, but it would be better to resize / displace them.
    this._removeOverlay();

    this.width = width;
    this.height = height;
    this.offsets = {
      x: rect.left,
      y: rect.top,
    };

    this._resizeScene();

    //Reset the size of the camera and the render size to fit perfectly.
    this._sceneObjects.camera.left = -width / 2;
    this._sceneObjects.camera.right = width / 2;
    this._sceneObjects.camera.top = height / 2;
    this._sceneObjects.camera.bottom =
      -height / 2;
    this._sceneObjects.camera.updateProjectionMatrix();
    renderer.setSize(width, height);

    this.startAnimation();
  };

  _resizeScene = () => {
    //Need to better handle this, conception is weird
    this._calculateBlockSize();
    this._deleteScene();
  };

  _createOverlay = (object) => {
    const overlay = this.overlay;

    const { camera } = this._sceneObjects,
      { wBlock } = this._sceneData,
      blockHeight =
        object.geometry.parameters.depth;

    overlay.current = OverlayV2().create(
      this.root,
      object.overlayContent,
      new Vector2D(
        this.width / 2 +
        (object.position.x -
          wBlock / 2 -
          camera.position.x),
        this.height / 2 +
        (object.position.z -
          blockHeight / 2 -
          camera.position.z)
      ),
      {
        width: wBlock,
        height: blockHeight,
      }
    );
  };

  _removeOverlay = () => {
    const overlay = this.overlay;

    if (overlay.current) {
      overlay.deleted.push(overlay.current);
      overlay.current.delete();
      overlay.current = null;
    }
  };

  _moveOverlay = (distance) => {
    const overlay = this.overlay.current,
      overlays = this.overlay.deleted;
    let cleanedArray = [],
      i;

    if (overlay) {
      overlay.container.style.left =
        parseInt(overlay.container.style.left) +
        distance.x +
        "px";
      overlay.container.style.top =
        parseInt(overlay.container.style.top) +
        distance.y +
        "px";
    }
    for (i = 0; i < overlays.length; i++) {
      if (overlays[i].isAlive()) {
        overlays[i].container.style.left =
          parseInt(
            overlays[i].container.style.left
          ) +
          distance.x +
          "px";
        overlays[i].container.style.top =
          parseInt(
            overlays[i].container.style.top
          ) +
          distance.y +
          "px";
        cleanedArray.push(overlays[i]);
      }
    }
    this.overlay.deleted = cleanedArray;
  };

  _changeObjectsVisibility = (objects, bool) => {
    let i;

    for (i = 0; i < objects.length; i++) {
      objects[i].traverse((child) => {
        if (child instanceof THREE.Mesh)
          child.visible = bool;
      });
    }
  };

  _animationInScene = () => {
    const { scene } = this._sceneObjects;

    if (
      scene.getObjectByName("leftAnimation") &&
      scene.getObjectByName("rightAnimation")
    )
      return true;
    return false;
  };

  _createAnimationBlock = (
    widthTarget,
    heightTarget
  ) => {
    if (!this._animationInScene()) {
      const { scene } = this._sceneObjects,
        // { wBlock, hBlock } = this._sceneData,
        {
          leftBlocks,
          rightBlocks,
        } = this._animation;

      if (!leftBlocks.block) {
        leftBlocks.block = new THREE.Mesh(
          new THREE.BoxGeometry(
            widthTarget / 2,
            0,
            heightTarget / 2
          ),
          new THREE.MeshBasicMaterial({
            color: 0xfff77a,
          })
        );
        leftBlocks.block.name = "leftAnimation";
        leftBlocks.block.position.y = -2;
      }
      if (!rightBlocks.block) {
        rightBlocks.block = new THREE.Mesh(
          new THREE.BoxGeometry(
            widthTarget / 2,
            0,
            heightTarget / 2
          ),
          new THREE.MeshBasicMaterial({
            color: 0xec5b34,
          })
        );
        rightBlocks.block.name = "rightAnimation";
        rightBlocks.block.position.y = -2;
      }

      this._changeObjectsVisibility(
        [leftBlocks.block, rightBlocks.block],
        false
      );
      scene.add(leftBlocks.block);
      scene.add(rightBlocks.block);
    }
  };

  _createAnimation = (object, id) => () => {
    const {
      overlays,
      leftBlocks,
      rightBlocks,
    } = this._animation;
    let index = getIndexById(overlays, id);

    if (index != null) {
      this._createAnimationBlock(
        object.geometry.parameters.width,
        object.geometry.parameters.depth
      );

      const lb = leftBlocks.block,
        rb = rightBlocks.block;

      lb.position.x =
        object.position.x -
        object.geometry.parameters.width / 2 +
        lb.geometry.parameters.width / 2;
      lb.position.z =
        object.position.z -
        object.geometry.parameters.depth / 2 +
        lb.geometry.parameters.depth / 2;
      lb.rotation.y = 0;

      rb.position.x =
        object.position.x +
        object.geometry.parameters.width / 2 -
        rb.geometry.parameters.width / 2;
      rb.position.z =
        object.position.z +
        object.geometry.parameters.depth / 2 -
        rb.geometry.parameters.depth / 2;
      rb.rotation.y = 0;

      overlays[index].started = true;
      overlays[index].direction = 1;
      this._changeObjectsVisibility(
        [lb, rb],
        true
      );
    }
  };

  _moveAnimation = (delta) => {
    const {
      overlays,
      rightBlocks,
      leftBlocks,
      timers,
    } = this._animation;
    let overlay, i;

    for (i = 0; i < overlays.length; i++) {
      overlay = overlays[i];

      if (overlay.direction !== 0) {
        const lb = leftBlocks.block,
          rb = rightBlocks.block;

        if (overlay.direction > 0) {
          lb.position.x -=
            delta *
            (leftBlocks.x *
              (1000 / timers.apparition));
          lb.position.z -=
            delta *
            (leftBlocks.y *
              (1000 / timers.apparition));
          lb.rotation.y +=
            delta *
            THREE.Math.degToRad(
              leftBlocks.rot *
              (1000 / timers.apparition)
            );

          rb.position.x +=
            delta *
            (rightBlocks.x *
              (1000 / timers.apparition));
          rb.position.z +=
            delta *
            (rightBlocks.y *
              (1000 / timers.apparition));
          rb.rotation.y -=
            delta *
            THREE.Math.degToRad(
              rightBlocks.rot *
              (1000 / timers.apparition)
            );
        } else if (overlay.direction < 0) {
          lb.position.x +=
            delta *
            (leftBlocks.x *
              (1000 / timers.disparition));
          lb.position.z +=
            delta *
            (leftBlocks.y *
              (1000 / timers.disparition));
          lb.rotation.y -=
            delta *
            THREE.Math.degToRad(
              leftBlocks.rot *
              (1000 / timers.disparition)
            );
          lb.rotation.y =
            lb.rotation.y < 0 ? 0 : lb.rotation.y;

          rb.position.x -=
            delta *
            (rightBlocks.x *
              (1000 / timers.disparition));
          rb.position.z -=
            delta *
            (rightBlocks.y *
              (1000 / timers.disparition));
          rb.rotation.y +=
            delta *
            THREE.Math.degToRad(
              rightBlocks.rot *
              (1000 / timers.disparition)
            );
          rb.rotation.y =
            rb.rotation.y > 0 ? 0 : rb.rotation.y;
        }
      }
    }
  };

  _calculateBlockSize = () => {
    const {
      // mats,
      row
    } = this._sceneData;
    let w,
      // h,
      step = this._sceneData.step;

    // let i,
    // totalWidth = 0,
    // totalHeight = 0;

    step = Math.round(this.width / 30);
    this._sceneData.step = step;

    w = Math.round(this.width / row - step);
    this._sceneData.wBlock = w;
  };

  _infiniteScene = () => {
    const { scene } = this._sceneObjects,
      {
        mats,
        wBlock,
        step,
        row,
      } = this._sceneData;
    let i,
      itX,
      posX,
      xTotalLength,
      xLength,
      j,
      itY,
      // tmp,
      posY,
      yTotalLength,
      yLength,
      ratio,
      mWidth,
      mHeight,
      block,
      mat;

    itX = 0;
    xLength = 0;
    while (
      itX < mats.length ||
      xLength < this.width / 2
    ) {
      xLength += wBlock + step;
      itX++;
    }
    xTotalLength = xLength;
    while (
      xTotalLength - xLength <
      this.width / 2
    ) {
      xTotalLength += wBlock + step;
      itX++;
    }
    itX *= 2;
    itX++;

    posX = -xTotalLength;
    for (i = 0; i < itX; i++) {
      posX += i ? wBlock / 2 : 0;

      itY = 0;
      yLength = 0;
      while (
        itY < mats.length ||
        yLength < this.height / 2
      ) {
        mat = mats[(i + itY) % mats.length];
        mWidth = mat.mesh.map.image.width;
        mHeight = mat.mesh.map.image.height;
        ratio = mWidth / mHeight;
        mHeight -= (mWidth - wBlock) / ratio;

        yLength += mHeight + step;
        itY++;
      }

      if (!i) {
        this._sceneData.stopCamera.set(
          xLength,
          yLength
        );
      }

      yTotalLength = yLength;
      while (
        yTotalLength - yLength <
        this.height / 2
      ) {
        mat =
          mats[
          (i + itY * Math.ceil(row)) %
          mats.length
          ];
        mWidth = mat.mesh.map.image.width;
        mHeight = mat.mesh.map.image.height;
        ratio = mWidth / mHeight;
        mHeight -= (mWidth - wBlock) / ratio;

        yTotalLength += mHeight + step;
        itY++;
      }
      itY *= 2;
      itY++;

      posY = -yTotalLength;
      for (j = 0; j < itY; j++) {
        mat =
          mats[
          (i + j * Math.ceil(row)) % mats.length
          ];
        //Calcutating ratio and height
        mWidth = mat.mesh.map.image.width;
        mHeight = mat.mesh.map.image.height;
        ratio = mWidth / mHeight;
        mHeight -= (mWidth - wBlock) / ratio;
        //Creating block with right dimensions
        block = new THREE.Mesh(
          new THREE.BoxGeometry(
            wBlock,
            0,
            mHeight
          ),
          mat.mesh
        );
        //Set position of Block
        posY += j ? mHeight / 2 : 0;

        block.position.x = posX;
        block.position.y = -1;
        block.position.z = posY;

        posY += mHeight / 2 + step;
        //Add additionals data
        block.triggerOverlay = true;
        block.overlayContent = mat.overlayContent;
        //Add to scene
        scene.add(block);
      }
      posX += wBlock / 2 + step;
    }
  };

  _finiteScene = () => {
    const { camera, scene } = this._sceneObjects,
      {
        mats,
        wBlock,
        step,
        row,
      } = this._sceneData,
      totalX =
        row <= mats.length
          ? Math.floor(row)
          : mats.length,
      totalY = Math.floor(mats.length / totalX);

    let i,
      j,
      inc,
      draw,
      posX,
      posY,
      block,
      mat,
      mHeight,
      mWidth,
      ratio,
      leftover = mats.length % totalX,
      maxY = 0;

    // eslint-disable-next-line
    let offset = 0;

    camera.minY = 0;
    camera.maxY = maxY >= 0 ? maxY : 0;

    inc = 0;
    posX = -(this.width / 2) + step / 2 + wBlock / 2;

    for (i = 0; i < totalX; i++) {
      posX += i ? wBlock / 2 : 0;
      posY = -(this.height / 2) + step / 2;

      draw = totalY;
      if (leftover > 0) {
        draw++;
        leftover--;
      }

      maxY = -this.height + step / 2;

      for (j = 0; j < draw; j++) {
        mat = mats[inc];
        mWidth = mat.mesh.map.image.width;
        mHeight = mat.mesh.map.image.height;
        ratio = mWidth / mHeight;
        mHeight -= (mWidth - wBlock) / ratio;

        posY += mHeight / 2;

        block = new THREE.Mesh(
          new THREE.BoxGeometry(
            wBlock,
            0,
            mHeight
          ),
          mat.mesh
        );
        block.position.x = posX;
        block.position.y = -1;
        block.position.z = posY;
        block.triggerOverlay = true;
        block.overlayContent =
          mats[i].overlayContent;
        scene.add(block);

        posY += mHeight / 2 + step;
        maxY += mHeight + step;
        inc++;
      }
      offset += draw;
      posX += wBlock / 2 + step;
      if (maxY > camera.maxY) camera.maxY = maxY;
    }

    if (camera.maxY <= 0)
      this._sceneData.canMove = false;
    else {
      camera.maxY -= step / 2;
      this._sceneData.canMove = true;
    }
  };

  _buildScene = () => {
    const
      // { camera, scene } = this._sceneObjects,
      { mats } = this._sceneData;

    this._calculateBlockSize();
    if (mats.length)
      this.infinite
        ? this._infiniteScene()
        : this._finiteScene();
  };

  _animate = () => {
    const {
      scene,
      camera,
      raycaster,
    } = this._sceneObjects,
      { friction, stopCamera } = this._sceneData,
      {
        mousePosition,
        position,
        distance,
        slide,
      } = this.vectors;
    // let stats = null,
    let intersect;

    const animate = () => {
      /*
       *** This block permit to drag the camera and the overlays when the click is pressed and the mouse is moved.
       */

      if (this.clicked) {
        if (distance.x || distance.y) {
          camera.position.x -= distance.x;
          camera.position.z -= distance.y;
          position.add(distance);
          slide.set(
            Math.trunc(distance.x / 1.75),
            Math.trunc(distance.y / 1.75)
          );
          this._removeOverlay();
          this._moveOverlay(distance);
          distance.set(0, 0);
        } else slide.set(0, 0);
      } else {
        /*
         *** Apply a force when the click is released to make the camera and the overlays slide a little.
         */
        if (slide.x || slide.y) {
          camera.position.x -= slide.x;
          camera.position.z -= slide.y;
          this._moveOverlay(slide);
          slide.set(
            Math.trunc(slide.x / friction),
            Math.trunc(slide.y / friction)
          );
        } else {
          if (
            pointInElement(
              this.root,
              mousePosition
            )
          ) {
            const worldPosition = new Vector2D(
              mousePosition.x + this.offsets.x,
              mousePosition.y - this.offsets.y
            );

            if (
              this.overlay.current &&
              !pointInElement(
                this.overlay.current.container,
                mousePosition
              )
            )
              this._removeOverlay();
            if (
              !this.overlay.current &&
              (!this.mobile ||
                (this.mobile && !this.dragged))
            ) {
              raycaster.setFromCamera(
                {
                  x:
                    (worldPosition.x /
                      this.width) *
                    2 -
                    1,
                  y:
                    -(
                      worldPosition.y /
                      this.height
                    ) *
                    2 +
                    1,
                },
                camera
              );
              intersect = raycaster.intersectObjects(
                scene.children
              );
              if (
                intersect[0] &&
                intersect[0].object.triggerOverlay
              )
                this._createOverlay(
                  intersect[0].object
                );
            }
          }
        }
      }

      /*
       *** If the camera make an entiere loop, it reset near 0, making it seems infinite !
       *** Works only if the number of images displayed is good.
       */
      if (this.infinite) {
        if (
          Math.abs(camera.position.x) /
          stopCamera.x >=
          1
        )
          camera.position.x %= stopCamera.x;
        if (
          Math.abs(camera.position.z) /
          stopCamera.y >=
          1
        )
          camera.position.z %= stopCamera.y;
      }
      renderer.render(scene, camera);
      this._animationId = requestAnimationFrame(
        animate
      );
    };
    animate();
  };

  _deleteScene = () => {
    const scene = this._sceneObjects.scene;
    let child;

    cancelAnimationFrame(this._animationId);
    this._animationId = null;
    while (scene.children.length > 0) {
      child = scene.children[0];
      this._sceneObjects.scene.remove(child);
      if (child.material.map)
        child.material.map.dispose();
      child.material.dispose();
      child.geometry.dispose();
    }
  };

  /*
   *** "Public" functions
   */

  //Create a promise once everything has been initialized. The scene won't work if you don't use function inside the promise.
  init = () => {
    return new Promise((resolve, reject) => {
      if (document.readyState === "complete") {
        this._init();
        resolve();
      } else
        document.onreadystatechange = () => {
          if (
            document.readyState === "complete"
          ) {
            this._init();
            resolve();
          }
        };
    });
  };

  setStep = (value) => {
    this._sceneData.step = value;
  };

  imagesPerRow = (value) => {
    this._sceneData.row = value;
  };

  addMaterial = async (
    image,
    cors,
    overlayContent = null
  ) => {
    //Test batched promises to load the whole scene at once, need more reflection
    this._sceneData.batchedMats.push(
      new Promise((resolve) => {
        const loader = new THREE.TextureLoader();
        let mesh, tex;

        tex = loader.load(
          (cors
            ? "https://cors-anywhere.herokuapp.com/"
            : "") + image,
          () => {
            mesh = new THREE.MeshBasicMaterial({
              map: tex,
            });
            mesh.map.minFilter =
              THREE.LinearFilter;
            this._sceneData.mats.push({
              mesh,
              overlayContent,
            });
            resolve("Texture loaded !");
          },
          undefined,
          (err) => {
            resolve("Texture not loaded !");
          }
        );
      })
    );
  };

  load = () => {
    return Promise.all(
      this._sceneData.batchedMats
    ).then((res) => {
      this.loaded = true;
    });
  };

  startAnimation = () => {
    if (!this._deleted && this.loaded) {
      // console.log('Batched result : ', res)
      this._buildScene();
      this._animate();
    }
  };

  deleteScene = () => {
    this._sceneData.mats = [];
    this._deleteScene();
    //Permit to stop the rendering of the scene if the whole things has been deleted
    this._deleted = true;
  };
}

/*
 *** Think about double buffering scene, rendering one scene while modifying another and then swap them
 */
