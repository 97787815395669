import { makeStyles, Typography, useTheme, useMediaQuery, IconButton, Backdrop, Snackbar } from '@material-ui/core';
import Skeleton from '@material-ui/lab/Skeleton';
import Alert from '@material-ui/lab/Alert';
import React, { useEffect, useState } from 'react';
import ProductPlaceholder from './../assets/img/product_placeholder.png';
import { connect } from 'react-redux';
import AspectRatioBox from "./AspectRatioBox";
import DeleteIcon from '@material-ui/icons/Delete';
import LinkIcon from '@material-ui/icons/Link';
import FullscreenIcon from '@material-ui/icons/Fullscreen';
import { useTranslation } from "react-i18next";

const useStyles = makeStyles((theme) => ({
    content: {
        '&:hover': {
            '& $overlay': {
                background: 'rgba(62, 173, 239, .4)',
                display: 'flex',
                justifyContent: 'center',
                alignItems: 'center',
            },
            '& $clickLink': {
                opacity: 1
            },
            '& $deleteButton': {
                opacity: 1,
                backgroundColor: 'red',
            },
            '& $shortLinkButton': {
                opacity: 1,
                backgroundColor: '#3eadef',
            },
            '& $fullScreenButton': {
                opacity: 1,
                backgroundColor: '#b9b9b9',
            }
        },
        display: 'flex',
        position: 'relative',
        marginBottom: 20,
        cursor: 'pointer'
    },
    skeleton: {
        width: '100%',
        borderRadius: 24,
    },
    articleImage: {
        width: '100%',
        objectFit: 'contain',
        borderRadius: 24,
        boxShadow: '0 7px 30px 0 rgb(88 165 211 / 36%)',
        backgroundColor: '#fff',
    },
    overlay: {
        position: 'absolute',
        width: '100%',
        background: 'rgba(0,0,0,0)',
        transition: 'background 0.5s ease',
        borderRadius: 24,
        alignSelf: 'stretch',
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
    },
    clickLink: {
        transition: 'opacity 0.5s ease',
        opacity: 0,
        fontSize: '20px',
        fontFamily: 'Poppins',
        color: '#fff',
        fontWeight: 600,
        textAlign: 'center',
        wordBreak: 'break'
    },
    deleteButton: {
        transition: 'opacity 0.5s ease',
        position: 'absolute',
        opacity: 0,
        width: '25%',
        height: '25%',
        bottom: -5,
        right: -5,
        color: '#fff'
    },
    shortLinkButton: {
        transition: 'opacity 0.5s ease',
        position: 'absolute',
        opacity: 0,
        width: '25%',
        height: '25%',
        bottom: -5,
        left: -5,
        color: '#fff'
    },
    fullScreenButton: {
        transition: 'opacity 0.5s ease',
        position: 'absolute',
        opacity: 0,
        width: '25%',
        height: '25%',
        top: -5,
        right: -5,
        color: '#fff'
    },
    backdropImage: {
        zIndex: theme.zIndex.drawer + 1
    },
}));

const ArticleImage = (props) => {

    const { t } = useTranslation("common");
    const classes = useStyles();
    const {
        user,
        article,
        brands,
        loadedBrands
    } = props;
    const [loaded, setLoaded] = useState(false);
    const [loadedImageWithError, setLoadedImageWithError] = useState(false);
    const [articleBrandName, setArticleBrandName] = useState();
    const [fullScreenMode, setFullScreenMode] = useState(false);
    const [snackBarState, setSnackBarState] = useState(false);
    const theme = useTheme();
    const xsDown = useMediaQuery(theme.breakpoints.down("xs"));

    const onLoad = (e) => {
        setLoaded(true);
        setLoadedImageWithError(false);
        document.getElementById(article.id).style.display = 'block';
    };

    const onError = (e) => {
        setLoaded(true);
        setLoadedImageWithError(true);
        document.getElementById(article.id).style.display = 'none';
    };

    useEffect(() => {
        if (article && loadedBrands) {
            let brand = brands.filter(brand => brand.offerId === article.offerId)[0];
            if (brand) {
                (brand.displayName) ? setArticleBrandName(brand.displayName) : setArticleBrandName(brand.name);
            }
        }
    }, [article, loadedBrands, brands]);

    const handleAddClick = () => {
        props.handleClickProduct(article);
    };

    const handleDeleteArticle = () => {
        props.handleDeleteArticle(article);
    };

    const handleGenerateShortLink = () => {
        props.handleGenerateShortLink(article);
    };

    const handleViewFullScreen = () => {
        if (loadedImageWithError) {
            setSnackBarState(true);
        } else {
            setFullScreenMode(true);
        }
    };

    const handleCloseFullScreen = () => {
        setFullScreenMode(false);
    };

    const handleCloseSnackbar = () => {
        setSnackBarState(false);
    }

    return (
        <>
            <Backdrop className={classes.backdropImage} open={fullScreenMode} onClick={handleCloseFullScreen}>
                <img alt={article.id} src={article.image} style={{ maxHeight: '90vh' }} />
            </Backdrop>
            <Snackbar open={snackBarState} onClose={handleCloseSnackbar} autoHideDuration={2000}>
                <Alert severity="warning" variant='filled' style={{ fontFamily: 'Poppins' }}>
                    {t('INFLUENCER.SNACKBAR_MESSAGES.UNAVAILABLE_PRODUCT_IMAGE')}
                </Alert>
            </Snackbar>
            <div className={classes.content} style={(articleBrandName) ? { width: (xsDown) ? '33.33%' : '20%', padding: (xsDown) ? '10px' : '10px' } : { display: 'none' }}>
                <AspectRatioBox ratio={1}>
                    <div onClick={handleAddClick} className={classes.overlay} style={(xsDown) ? {} : {}} >
                        <Typography className={classes.clickLink} style={(xsDown) ? { fontSize: '14px' } : {}}>{articleBrandName || ""}</Typography>
                    </div>
                    {
                        (user)
                            ? <>

                                <IconButton className={classes.deleteButton} onClick={handleDeleteArticle}>
                                    <DeleteIcon />
                                </IconButton>
                                <IconButton className={classes.shortLinkButton} onClick={handleGenerateShortLink}>
                                    <LinkIcon />
                                </IconButton>
                            </>
                            : <></>
                    }
                    <IconButton className={classes.fullScreenButton} onClick={handleViewFullScreen}>
                        <FullscreenIcon />
                    </IconButton>
                    {
                        (!loaded)
                            ? <Skeleton key={article.id} animation='wave' variant='rect' className={classes.skeleton} style={(xsDown) ? {} : {}} />
                            : <></>
                    }
                    {
                        (!loadedImageWithError)
                            ? <img id={article.id} alt={article.id} src={article.image} className={classes.articleImage} onLoad={onLoad} onError={onError} style={(xsDown) ? { display: 'none' } : { display: 'none' }} />
                            : <img src={ProductPlaceholder} alt='No article img provided' className={classes.articleImage} style={(xsDown) ? {} : {}} />
                    }
                </AspectRatioBox>
            </div>
        </>
    )
};

const mapStateToProps = (state, ownProps) => {
    const brands = state.brandsState.allBrands;
    const loadedBrands = state.brandsState.loadedBrands;
    return {
        brands,
        loadedBrands
    };
}

export default connect(mapStateToProps)(ArticleImage);