import React, {
  useState
} from "react";
import {
  Grid,
  Typography,
  List,
  ListItem,
  ListItemIcon,
  Divider,
  Collapse,
} from "@material-ui/core";
import { makeStyles } from "@material-ui/styles";

// UI Scope
import { DualRing } from ".";

import BuyIcon from "../../assets/icons/buy.svg";
import ProductIcon from "../../assets/icons/product.svg";
import TapIcon from "../../assets/icons/tap.svg";
import WishIcon from "../../assets/icons/wishlist.svg";

const useStyles = makeStyles((theme) => ({
  list: {
    width: "100%",
    paddingTop: 0,
  },
  legendItem: {
    padding: theme.spacing(1),
  },
}));

const InfluencersList = ({
  influencers,
  wishlists,
  conversions,
  clicks,
  loading,
}) => {
  const [listDisplay, setListDisplay] = useState(
    {}
  );

  const classes = useStyles();
  const handleInfluencerClick = (id) => {
    setListDisplay((disp) => {
      if (!disp.influencer)
        return {
          ...disp,
          wishlist: false,
          influencer: id,
        };
      if (disp.influencer === id)
        return {
          ...disp,
          wishlist: false,
          influencer: null,
        };
      if (disp.influencer && disp.inf !== id)
        return {
          ...disp,
          wishlist: false,
          influencer: id,
        };
    });
  };
  const handleWishlistClick = (id) => {
    setListDisplay((disp) => {
      if (!disp.wishlist)
        return { ...disp, wishlist: id };
      if (disp.wishlist === id)
        return { ...disp, wishlist: null };
      if (disp.wishlist && disp.inf !== id)
        return { ...disp, wishlist: id };
    });
  };
  const countInfArts = (infKey) => {
    let count = 0;
    wishlists &&
      wishlists
        .filter(
          (wish) => wish.influencer === infKey
        )
        .forEach((wish) => {
          if (
            wish.articles &&
            wish.influencer === infKey
          ) {
            wish.articles.forEach(
              (art) => count++
            );
          }
        });
    return count;
  };
  const calcRevenue = (inf) => {
    let revenue = 0;
    const convs = conversions.filter(
      (conv) => conv.influencer === inf.uid
    );
    convs.length &&
      convs.forEach(
        (conv) =>
          (revenue =
            revenue + parseFloat(conv.commission))
      );
    return revenue.toFixed(2);
  };
  const calcPayout = (inf) => {
    let payout = 0;
    const convs = conversions.filter(
      (conv) => conv.influencer === inf.uid
    );
    convs.length &&
      convs.forEach(
        (conv) =>
          (payout =
            payout + parseFloat(conv.amount))
      );
    return payout.toFixed(2);
  };
  const calcWishRevenue = (inf, wish) => {
    let revenue = 0;
    const convs = conversions.filter((conv) =>
      wish.articles.find(
        (art) => conv.articleId === art.key
      )
    );
    convs.length &&
      convs.forEach(
        (conv) =>
          (revenue =
            revenue + parseFloat(conv.commission))
      );
    return revenue.toFixed(2);
  };
  const calcWishPayout = (inf, wish) => {
    let payout = 0;
    const convs = conversions.filter((conv) =>
      wish.articles.find(
        (art) => conv.articleId === art.key
      )
    );
    convs.length &&
      convs.forEach(
        (conv) =>
          (payout =
            payout + parseFloat(conv.amount))
      );
    return payout.toFixed(2);
  };
  const calcArtRevenue = (inf, art) => {
    let revenue = 0;
    const convs = conversions.filter(
      (conv) => conv.articleId === art.key
    );
    convs.length &&
      convs.forEach(
        (conv) =>
          (revenue =
            revenue + parseFloat(conv.commission))
      );
    return revenue.toFixed(2);
  };
  const calcArtPayout = (inf, art) => {
    let payout = 0;
    const convs = conversions.filter(
      (conv) => conv.articleId === art.key
    );
    convs.length &&
      convs.forEach(
        (conv) =>
          (payout =
            payout + parseFloat(conv.amount))
      );
    return payout.toFixed(2);
  };
  const openInNewTab = (e) => {
    e.preventDefault();
    window.open(e.target.href, "_blank");
  };

  return (
    <>
      <Grid item container justify='center'>
        <Grid
          className={classes.legendItem}
          item
          xs={4}
          md={2}
          container
          direction='column'
          justify='center'
          alignItems='center'
        >
          <img
            style={{ height: 20 }}
            src={WishIcon}
            alt='Product Icon'
          />
          <Typography>Wishlists</Typography>
        </Grid>
        <Grid
          className={classes.legendItem}
          item
          xs={4}
          md={2}
          container
          direction='column'
          justify='center'
          alignItems='center'
        >
          <img
            style={{ height: 20 }}
            src={ProductIcon}
            alt='Product Icon'
          />
          <Typography>Articles</Typography>
        </Grid>
        <Grid
          className={classes.legendItem}
          item
          xs={4}
          md={2}
          container
          direction='column'
          justify='center'
          alignItems='center'
        >
          <img
            style={{ height: 20 }}
            src={TapIcon}
            alt='Product Icon'
          />
          <Typography>Clicks</Typography>
        </Grid>
        <Grid
          className={classes.legendItem}
          item
          xs={4}
          md={2}
          container
          direction='column'
          justify='center'
          alignItems='center'
        >
          <img
            style={{ height: 20 }}
            src={BuyIcon}
            alt='Product Icon'
          />
          <Typography>Conversions</Typography>
        </Grid>
        <Grid
          className={classes.legendItem}
          item
          xs={4}
          md={2}
          container
          direction='column'
          justify='center'
          alignItems='center'
        >
          <Typography
            style={{
              fontSize: 20,
              lineHeight: 1,
            }}
            color='secondary'
          >
            €
          </Typography>
          <Typography>Commissions</Typography>
        </Grid>
        <Grid
          className={classes.legendItem}
          item
          xs={4}
          md={2}
          container
          direction='column'
          justify='center'
          alignItems='center'
        >
          <Typography
            style={{
              fontSize: 20,
              lineHeight: 1,
            }}
            color='secondary'
          >
            €
          </Typography>
          <Typography>CA</Typography>
        </Grid>
      </Grid>
      <Grid
        style={{ overflow: "auto" }}
        item
        container
      >
        <Divider />
        <List className={classes.list}>
          {influencers &&
            influencers.map((inf) => (
              <React.Fragment key={inf.uid}>
                <ListItem
                  button
                  onClick={() =>
                    handleInfluencerClick(inf.uid)
                  }
                >
                  <ListItemIcon
                    style={{ marginRight: 5 }}
                  >
                    <img
                      src={
                        inf.Profil.banner &&
                        inf.Profil.banner
                      }
                      alt='Avatar'
                      style={{ height: "30px" }}
                    />
                  </ListItemIcon>
                  <Grid item container justify='space-between' alignItems='center'>
                    <Grid item>
                      <Typography>
                        {inf.Profil.name
                          ? inf.Profil.name.toUpperCase()
                          : "Influencer"}
                      </Typography>
                    </Grid>
                    <Grid xs={5} item container justify='center'>
                      <Grid xs={6} sm={4} lg={2} item>
                        <Grid
                          container
                          justify='flex-end'
                          alignContent='center'
                          alignItems='center'
                        >
                          <Typography className={classes.totalCount}>
                            {loading.wishlists ? (<DualRing />) : (
                              wishlists.filter(
                                (wish) =>
                                  wish.influencer ===
                                  inf.uid
                              ).length
                            )}
                          </Typography>
                          <img
                            style={{ height: 20 }}
                            src={WishIcon}
                            alt='Wishlist Icon'
                          />
                        </Grid>
                      </Grid>
                      <Grid xs={6} sm={4} lg={2} item>
                        <Grid
                          container
                          justify='flex-end'
                          alignContent='center'
                          alignItems='center'
                        >
                          <Typography
                            className={
                              classes.totalCount
                            }
                          >
                            {countInfArts(
                              inf.uid
                            )}
                          </Typography>
                          <img
                            style={{ height: 20 }}
                            src={ProductIcon}
                            alt='Product Icon'
                          />
                        </Grid>
                      </Grid>
                      <Grid xs={6} sm={4} lg={2} item>
                        <Grid
                          container
                          justify='flex-end'
                          alignContent='center'
                          alignItems='center'
                        >
                          <Typography
                            className={
                              classes.totalCount
                            }
                          >
                            {loading.clicks ? (
                              <DualRing />
                            ) : (
                                clicks.filter(
                                  (cli) =>
                                    wishlists.find(
                                      (wish) =>
                                        wish.influencer ===
                                        inf.uid &&
                                        wish.articles.find(
                                          (art) =>
                                            art.key ===
                                            cli.article
                                        )
                                    )
                                ).length
                              )}
                          </Typography>
                          <img
                            style={{ height: 20 }}
                            src={TapIcon}
                            alt='Click Icon'
                          />
                        </Grid>
                      </Grid>
                      <Grid xs={6} sm={4} lg={2} item>
                        <Grid
                          container
                          justify='flex-end'
                          alignContent='center'
                          alignItems='center'
                        >
                          <Typography
                            className={
                              classes.totalCount
                            }
                          >
                            {
                              conversions.filter(
                                (conv) =>
                                  conv.influencer ===
                                  inf.uid
                              ).length
                            }
                          </Typography>
                          <img
                            style={{ height: 20 }}
                            src={BuyIcon}
                            alt='Product Icon'
                          />
                        </Grid>
                      </Grid>
                      <Grid xs={6} sm={4} lg={2} item>
                        <Grid
                          container
                          justify='flex-end'
                          alignContent='center'
                          alignItems='center'
                        >
                          <Typography
                            className={
                              classes.totalCount
                            }
                          >
                            {calcRevenue(inf)}{" "}
                          </Typography>
                          <Typography
                            style={{
                              fontSize: 20,
                            }}
                            color='secondary'
                          >
                            €
                          </Typography>
                          {/* <img
                        style={{ height: 20 }}
                        src={BuyIcon}
                        alt='Conversions Icon'
                      /> */}
                        </Grid>
                      </Grid>
                      <Grid xs={6} sm={4} lg={2} item>
                        <Grid
                          container
                          justify='flex-end'
                          alignContent='center'
                          alignItems='center'
                        >
                          <Typography
                            className={
                              classes.totalCount
                            }
                          >
                            {calcPayout(inf)}{" "}
                          </Typography>
                          <Typography
                            style={{
                              fontSize: 20,
                            }}
                            color='secondary'
                          >
                            €
                          </Typography>
                          {/* <img
                        style={{ height: 20 }}
                        src={BuyIcon}
                        alt='Conversions Icon'
                      /> */}
                        </Grid>
                      </Grid>
                    </Grid>
                  </Grid>
                </ListItem>
                <Divider />
                <Collapse
                  in={
                    listDisplay.influencer &&
                    listDisplay.influencer ===
                    inf.uid
                  }
                >
                  <List
                    component='div'
                    disablePadding
                  >
                    {wishlists
                      .filter(
                        (wish) =>
                          wish.influencer ===
                          inf.uid
                      )
                      .map((wish, i) => {
                        return (
                          <React.Fragment key={i}>
                            <ListItem
                              button
                              onClick={() =>
                                handleWishlistClick(
                                  wish.name
                                )
                              }
                            >
                              <Grid
                                item
                                container
                                justify='space-between'
                                alignItems='center'
                              >
                                <Grid item>
                                  <img
                                    style={{
                                      height: 20,
                                    }}
                                    src={WishIcon}
                                    alt='Wishlist Icon'
                                  />

                                  <Typography display='inline'>
                                    {(
                                      wish.name ||
                                      "no name"
                                    ).toUpperCase()}
                                  </Typography>
                                </Grid>
                                <Grid xs={5} item container justify='center'>
                                  <Grid xs={6} sm={4} lg={2} item /> {/* Pourquoi ?*/}
                                  <Grid xs={6} sm={4} lg={2} item>
                                    <Grid
                                      container
                                      justify='flex-end'
                                      alignContent='center'
                                      alignItems='center'
                                    >
                                      <Typography>
                                        {
                                          wish
                                            .articles
                                            .length
                                        }
                                      </Typography>
                                    </Grid>
                                  </Grid>
                                  <Grid xs={6} sm={4} lg={2} item>
                                    <Grid
                                      container
                                      justify='flex-end'
                                      alignContent='center'
                                      alignItems='center'
                                    >
                                      <Typography>
                                        {
                                          clicks.filter(
                                            (
                                              cli
                                            ) =>
                                              wish.articles.find(
                                                (
                                                  art
                                                ) =>
                                                  cli.article ===
                                                  art.key
                                              )
                                          ).length
                                        }
                                      </Typography>
                                    </Grid>
                                  </Grid>
                                  <Grid xs={6} sm={4} lg={2} item>
                                    <Grid
                                      container
                                      justify='flex-end'
                                      alignContent='center'
                                      alignItems='center'
                                    >
                                      <Typography>
                                        {
                                          conversions.filter(
                                            (
                                              conv
                                            ) =>
                                              wish.articles.find(
                                                (
                                                  art
                                                ) =>
                                                  conv.articleId ===
                                                  art.key
                                              )
                                          ).length
                                        }
                                      </Typography>
                                    </Grid>
                                  </Grid>
                                  <Grid xs={6} sm={4} lg={2} item>
                                    <Grid
                                      container
                                      justify='flex-end'
                                      alignContent='center'
                                      alignItems='center'
                                    >
                                      <Typography>
                                        {calcWishRevenue(
                                          inf,
                                          wish
                                        )}{" "}
                                      </Typography>
                                    </Grid>
                                  </Grid>
                                  <Grid xs={6} sm={4} lg={2} item>
                                    <Grid
                                      container
                                      justify='flex-end'
                                      alignContent='center'
                                      alignItems='center'
                                    >
                                      <Typography>
                                        {calcWishPayout(
                                          inf,
                                          wish
                                        )}{" "}
                                      </Typography>
                                    </Grid>
                                  </Grid>
                                </Grid>
                              </Grid>
                            </ListItem>
                            <Divider />
                            <Collapse
                              in={
                                listDisplay.wishlist &&
                                listDisplay.wishlist ===
                                wish.name
                              }
                            >
                              <List
                                component='div'
                                disablePadding
                              >
                                {wish.articles.map(
                                  (
                                    art,
                                    wishIndex
                                  ) => {
                                    return (
                                      <React.Fragment
                                        key={
                                          wishIndex
                                        }
                                      >
                                        <ListItem>
                                          <Grid
                                            item
                                            container
                                            justify='space-between'
                                            alignContent='center'
                                            alignItems='center'
                                            direction='row'
                                          >
                                            <Grid
                                              item
                                              xs={
                                                7
                                              }
                                            >
                                              <img
                                                style={{
                                                  height: 20,
                                                }}
                                                src={
                                                  ProductIcon
                                                }
                                                alt='Product Icon'
                                              />
                                              <ListItemIcon
                                                style={{
                                                  marginRight: 5,
                                                }}
                                              >
                                                <img
                                                  src={
                                                    art.image
                                                  }
                                                  alt='Article'
                                                  style={{
                                                    height:
                                                      "30px",
                                                  }}
                                                />
                                              </ListItemIcon>
                                              <Typography display='inline'>
                                                {inf.Profil.name && (
                                                  <a onClick={openInNewTab}
                                                     href={`https://www.shopmyinfluence.fr/wishlists/${inf.Profil.name.replace(
                                                      / /g,
                                                      "_"
                                                    )}/${wish.name.replace(
                                                      / /g,
                                                      "_"
                                                    )}${
                                                      inf
                                                        .Profil
                                                        .code33
                                                        ? "/" +
                                                        inf
                                                          .Profil
                                                          .code33
                                                        : ""
                                                      }`}
                                                  >
                                                    Voir la wishlist
                                                  </a>
                                                )}
                                                {inf
                                                  .Profil
                                                  .name &&
                                                  " ■ "}
                                                <a
                                                  onClick={
                                                    openInNewTab
                                                  }
                                                  href={
                                                    art.url
                                                  }
                                                >
                                                  Voir le produit
                                                </a>
                                              </Typography>
                                            </Grid>
                                            <Grid xs={5} item container justify='center'>
                                              <Grid xs={12} sm={8} lg={4} item />
                                              <Grid xs={6} sm={4} lg={2} item>
                                                <Grid
                                                  container
                                                  justify='flex-end'
                                                  alignContent='center'
                                                  alignItems='center'
                                                >
                                                  <Typography>
                                                    {loading.clicks ? (
                                                      <DualRing />
                                                    ) : (
                                                        clicks.filter(
                                                          (
                                                            cli
                                                          ) =>
                                                            cli.article ===
                                                            art.key
                                                        )
                                                          .length
                                                      )}
                                                  </Typography>
                                                </Grid>
                                              </Grid>
                                              <Grid xs={6} sm={4} lg={2} item>
                                                <Grid
                                                  container
                                                  justify='flex-end'
                                                  alignContent='center'
                                                  alignItems='center'
                                                >
                                                  <Typography>
                                                    {loading.conversions ? (
                                                      <DualRing />
                                                    ) : (
                                                        conversions.filter(
                                                          (
                                                            conv
                                                          ) =>
                                                            conv.articleId ===
                                                            art.key
                                                        )
                                                          .length
                                                      )}
                                                  </Typography>
                                                </Grid>
                                              </Grid>
                                              <Grid xs={6} sm={4} lg={2} item>
                                                <Grid
                                                  container
                                                  justify='flex-end'
                                                  alignContent='center'
                                                  alignItems='center'
                                                >
                                                  <Typography>
                                                    {loading.conversions ? (
                                                      <DualRing />
                                                    ) : (
                                                        calcArtRevenue(
                                                          inf,
                                                          art
                                                        )
                                                      )}
                                                  </Typography>
                                                </Grid>
                                              </Grid>
                                              <Grid xs={6} sm={4} lg={2} item>
                                                <Grid
                                                  container
                                                  justify='flex-end'
                                                  alignContent='center'
                                                  alignItems='center'
                                                >
                                                  <Typography>
                                                    {loading.conversions ? (
                                                      <DualRing />
                                                    ) : (
                                                        calcArtPayout(
                                                          inf,
                                                          art
                                                        )
                                                      )}
                                                  </Typography>
                                                </Grid>
                                              </Grid>
                                            </Grid>
                                          </Grid>
                                        </ListItem>
                                        <Divider />
                                      </React.Fragment>
                                    );
                                  }
                                )}
                              </List>
                            </Collapse>
                          </React.Fragment>
                        );
                      })}
                  </List>
                </Collapse>
              </React.Fragment>
            ))}
        </List>
      </Grid>
    </>
  );
};

export default InfluencersList;
