import React, { useState, useEffect } from "react";
import { Grid, Box, Typography, Select, MenuItem } from "@material-ui/core";
import { makeStyles, withStyles } from "@material-ui/styles";
// import PeopleIcon from '@material-ui/icons/People';
import PowerSettingsNewIcon from "@material-ui/icons/PowerSettingsNew";
import { NavLink, useRouteMatch, useLocation } from "react-router-dom";
import SVGIcon from "../../template/assets/SVGIcons/index";
import { useTranslation } from "react-i18next";
import TimelineIcon from "@material-ui/icons/Timeline";
import ExpandMoreIcon from "@material-ui/icons/ExpandMore";
import clsx from "clsx";

const useStyles = makeStyles((theme) => {
  const drawerWidth = 280;
  return {
    drawer: {
      width: drawerWidth,
      flexShrink: 0,
      height: "100%",
      borderStyle: "none",
    },
    drawerPaper: {
      width: drawerWidth,
      justifyContent: "flex-start",
      borderStyle: "none !important",
    },
    bannerContainer: {
      height: "160px",
    },
    logoBoxContent: {
      display: "flex",
      alignItems: "center",
      width: "50%",
      backgroundColor: "white",
      "& img": {
        maxWidth: "100%",
        maxHeight: "100%",
      },
    },
    navigationLink: {
      display: "flex",
      width: "100%",
      color: "black",
      textDecoration: "none",
      paddingLeft: "55px",
      paddingTop: "15px",
      paddingBottom: "15px",
      fontSize: "16px",
      "&:hover": {
        backgroundColor: "#fb689f17",
        color: "#ff005f",
      },
      borderTopRightRadius: "40px",
      borderBottomRightRadius: "40px",
    },
    navigationActiveLink: {
      color: "#ff005f",
      backgroundColor: "#fb689f40",
      paddingTop: "15px",
      paddingBottom: "15px",
      fontSize: "16px",
      borderTopRightRadius: "40px",
      borderBottomRightRadius: "40px",
    },
    drawerMenu: {
      // height: '50%'   ADD WHEN ALL NAVIGATION IS DONE
      height: "15%",
    },
    navigationSpacement: {
      marginLeft: "10px !important",
      fontFamily: "Poppins !important",
    },
    selectClass: {
      backgroundColor: "transparent",
      fontFamily: "Poppins",
      fontSize: "16px",
      "&:focus": {
        backgroundColor: "transparent",
      },
    },
  };
});

const iconStyles = {
  selectIcon: {
    color: "black",
  },
};
const CustomExpandMore = withStyles(iconStyles)(
  ({ className, classes, ...rest }) => {
    return (
      <ExpandMoreIcon
        {...rest}
        className={clsx(className, classes.selectIcon)}
      />
    );
  }
);

const SideBar = (props) => {
  const { t, i18n } = useTranslation("common");
  const { url } = useRouteMatch();
  let location = useLocation();
  const actualTab = location.pathname.split("/").pop();
  const [hoverColor, setHoverColor] = useState("#000000");
  const [mobileMode, setMobileMode] = useState(false);
  const [lang, setLang] = useState("en");
  const classes = useStyles();

  useEffect(() => {
    setMobileMode(props.mobileMode);
  }, [props.mobileMode]);

  useEffect(() => {
    i18n.changeLanguage(lang);
  }, [lang, i18n]);

  useEffect(() => {
    setLang(i18n.language !== "fr" ? "en" : "fr");
  }, [i18n.language]);

  const handleDrawerEvent = () => {
    props.navEvent();
  };

  const handleMouseEnterEvent = () => {
    setHoverColor("#ff005f");
  };
  const handleMouseLeaveEvent = () => {
    setHoverColor("#000000");
  };

  let brandsIcon;
  if (actualTab === "brands") {
    brandsIcon = (
      <SVGIcon
        name="brandsIcon"
        width={"24px"}
        height={"24px"}
        fill="#ff005f"
      />
    );
  } else {
    brandsIcon = (
      <SVGIcon
        name="brandsIcon"
        width={"24px"}
        height={"24px"}
        fill={hoverColor}
      />
    );
  }

  return (
    <>
      <Grid
        className={classes.bannerContainer}
        container
        justify="center"
        alignContent="center"
      >
        {/* <Avatar className={classes.largeLogo} alt={name} src={pic} /> */}
        <Box className={classes.logoBoxContent}>
          <img
            alt="logo"
            src="https://firebasestorage.googleapis.com/v0/b/shopmyinfluens.appspot.com/o/shopmyinfluens%2FApp%20Data%2Flogo_shopmyinfluence-01.png?alt=media"
          />
        </Box>
      </Grid>
      <Grid
        container
        className={classes.drawerMenu}
        direction="row"
        justify="center"
        alignItems="center"
      >
        <Grid container item xs={12}>
          <Grid container item xs={10} justify="center">
            <NavLink
              to={`${url}/brands`}
              className={classes.navigationLink}
              activeClassName={classes.navigationActiveLink}
              onMouseEnter={handleMouseEnterEvent}
              onMouseLeave={handleMouseLeaveEvent}
              onClick={handleDrawerEvent}
            >
              {brandsIcon}
              <Typography className={classes.navigationSpacement}>
                {" "}
                {t("BRAND_AGENT.SIDEBAR.MY_BRANDS_LINK")}{" "}
              </Typography>
            </NavLink>
          </Grid>
        </Grid>
        <Grid container item xs={12}>
          <Grid container item xs={10} justify="center">
            <NavLink
              to={`${url}/statistiques`}
              className={classes.navigationLink}
              activeClassName={classes.navigationActiveLink}
            >
              <TimelineIcon />
              <Typography className={classes.navigationSpacement}>
                {t("BRAND_AGENT.SIDEBAR.STATISTICS_LINK")}
              </Typography>
            </NavLink>
          </Grid>
        </Grid>
        {/* <Grid container item xs={12}>
          <Grid container item xs={10} justify='center'>
            <NavLink
              to={`${url}/influencers`}
              className={classes.navigationLink}
              activeClassName={classes.navigationActiveLink}
              onClick={handleDrawerEvent}>
              <PeopleIcon />
              <Typography className={classes.navigationSpacement}> Influenceurs </Typography>
            </NavLink>
          </Grid>
        </Grid> */}
        <Grid container item xs={12}>
          <Grid container item xs={10} justify="center">
            <NavLink to={`${url}/logout`} className={classes.navigationLink}>
              <PowerSettingsNewIcon color="error" />
              <Typography
                className={classes.navigationSpacement}
                style={{ color: "red" }}
              >
                {" "}
                {t("BRAND_AGENT.SIDEBAR.LOGOUT_LINK")}{" "}
              </Typography>
            </NavLink>
          </Grid>
        </Grid>
        {mobileMode ? (
          <Grid
            container
            item
            xs={12}
            direction="row-reverse"
            style={{ position: "absolute", bottom: 5, right: 5 }}
          >
            <Select
              value={lang}
              disableUnderline
              onChange={(e) => setLang(e.target.value)}
              classes={{ root: classes.selectClass }}
              IconComponent={CustomExpandMore}
            >
              <MenuItem value="fr" style={{ fontFamily: "Poppins" }}>
                FR
              </MenuItem>
              <MenuItem value="en" style={{ fontFamily: "Poppins" }}>
                EN
              </MenuItem>
            </Select>
          </Grid>
        ) : (
          <></>
        )}
      </Grid>
    </>
  );
};

export default SideBar;
