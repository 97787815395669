import React, { useState, useEffect, useCallback } from "react";
import { connect } from "react-redux";
import { updateBrandAgentById, refuseBrandAgentById, validateBrandAgentById } from "../../../../store/actions/brandsAgentActions";
import { Grid, makeStyles, Typography, IconButton, FormControl, InputBase, useTheme, useMediaQuery, Dialog, Button, Box, MenuItem, Select, CircularProgress, Backdrop } from "@material-ui/core";
import CropperDialog from './Cropper';
import WarningDialog from "./WarningDialog";
import { withStyles } from "@material-ui/styles";
import CloseIcon from '@material-ui/icons/Close';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import clsx from "clsx";
import axios from "axios";
import placeholderBrand from "../assets/img/brand.png";
import { useTranslation } from "react-i18next";


const useStyles = makeStyles((theme) => ({
    overallBox: {
        backgroundColor: '#fff',
        borderRadius: '35px',
        boxShadow: '0 7px 30px 0 rgba(88, 165, 211, 0.16)',
        margin: '15px'
    },
    formBox: {
        marginTop: '10px',
        marginBottom: '10px'
    },
    formTitle: {
        fontFamily: 'Poppins',
        fontSize: '22px',
        margin: '10px',
        marginTop: '-50px'
    },
    formControlRow: {
        marginTop: '5px',
        marginBottom: '5px'
    },
    formLabel: {
        fontFamily: 'Poppins',
        marginLeft: '20px'
    },
    formControl: {
        width: '100%'
    },
    formInput: {
        fontFamily: 'Poppins',
        borderRadius: '35px',
        boxShadow: '0 7px 30px 0 rgba(69, 128, 163, 0.16)',
        height: '50px',
        width: '100%',
        paddingTop: '10px',
        paddingBottom: '10px',
        paddingLeft: '20px',
        margin: '5px'
    },
    selectClass: {
        fontFamily: 'Poppins',
        paddingRight: '0px !important',
        '&:focus': {
            backgroundColor: 'white'
        },
        display: 'flex',
        flexShrink: 1,
    },
    select: {
        marginTop: '10px',
        marginBottom: '10px',
    },
    selectedMenuItems: {
        backgroundColor: '#50bef3 !important',
        color: '#fff'
    },
    switchLabel: {
        fontFamily: 'Poppins',
    },
    cancelEditButton: {
        width: '200px',
        height: '60px',
        fontFamily: 'Poppins',
        margin: '5px',
        borderRadius: '30px',
        boxShadow: '0 7px 30px 0 rgba(88, 165, 211, 0.16)',
    },
    validateEditButton: {
        width: '200px',
        height: '60px',
        fontFamily: 'Poppins',
        margin: '5px',
        borderRadius: '30px',
        boxShadow: '0 7px 30px 0 rgba(88, 165, 211, 0.16)',
        color: '#fff',
        backgroundColor: '#d96e98',
        '&:hover': {
            backgroundColor: '#d96e98'
        }
    },
    profilePicturePlaceholder: {
        fontFamily: 'Poppins',
        color: '#717171',
        width: 'calc(100% - 150px)',
        paddingLeft: '15px'
    },
    activeProfilePicturePlaceholder: {
        fontFamily: 'Poppins',
        width: 'calc(100% - 150px)',
        paddingLeft: '15px'
    },
    profilePicturePlaceholderMobile: {
        fontFamily: 'Poppins',
        color: '#717171',
        width: '70%',
        margin: 5,
        textAlign: 'center',
    },
    activeProfilePicturePlaceholderMobile: {
        fontFamily: 'Poppins',
        width: '70%',
        margin: 5,
        textAlign: 'center',
    },
    profileImageLabel: {
        fontFamily: 'Poppins',
        width: '150px',
        height: '40px',
        textTransform: 'none',
        backgroundColor: '#58a5d3',
        color: '#fff',
        borderRadius: '35px',
        '&:hover': {
            cursor: 'pointer',
            backgroundColor: '#58a5d3',
        },
        '&:focus': {
            outlineColor: 'rgba(69, 128, 163, 0)'
        },
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        position: 'relative',
    },
    errorMessage: {
        fontFamily: "Poppins",
        color: "red",
    },
    brandImage: {
        width: "100px",
        height: "100px",
        objectFit: "fill",
        borderRadius: "20px"
    },
    AllBrandBox: {
        borderRadius: "41px",
        boxShadow: "0 2px 30px 0 rgba(58, 111, 142, 0.21)",
        marginTop: "15px",
        marginBottom: "30px",
    },
    brandBox: {
        margin: "5px",
        borderRadius: "20px",
        boxShadow: "0 2px 30px 0 rgba(58, 111, 142, 0.21)",
        width: "110px",
        height: "110px",
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
    },
    overlay: {
        zIndex: theme.zIndex.drawer + 1
    },
    refuseButton: {
        width: '200px',
        height: '40px',
        fontFamily: 'Poppins',
        margin: '5px',
        borderRadius: '30px',
        backgroundColor: 'red',
        color: '#fff',
        boxShadow: '0 7px 30px 0 rgba(88, 165, 211, 0.16)',
        '&:hover': {
            backgroundColor: 'red'
        }
    },
    validateButton: {
        width: '200px',
        height: '40px',
        fontFamily: 'Poppins',
        margin: '5px',
        borderRadius: '30px',
        boxShadow: '0 7px 30px 0 rgba(88, 165, 211, 0.16)',
        color: '#fff',
        backgroundColor: '#d96e98',
        '&:hover': {
            backgroundColor: '#d96e98'
        }
    },
    placeholderStyles: {
        fontFamily: 'Poppins',
        color: '#1B4C61'
    }
})
);
const iconStyles = {
    selectIcon: {
        color: "black"
    },
    loaderStyles: {
        color: 'red'
    }
};

const CustomExpandMore = withStyles(iconStyles)(
    ({ className, classes, ...rest }) => {
        return (
            <ExpandMoreIcon
                {...rest}
                className={clsx(className, classes.selectIcon)}
            />
        );
    }
);


const AgenceEditModal = (props) => {
    const classes = useStyles();
    const { t } = useTranslation("common");
    const theme = useTheme();
    const xsDown = useMediaQuery(theme.breakpoints.down('xs'));
    const mdDown = useMediaQuery(theme.breakpoints.down('md'));

    const [anchorEl, setAnchorEl] = useState(null);
    const [agentNameTitle, setAgentNameTitle] = useState('');
    const [uploadBasicImageLoading, setUploadBasicImageLoading] = useState(false);
    const [basicBrandPictureValue, setBasicBrandPictureValue] = useState('');
    const [base64BasicLogo, setBase64BasicLogo] = useState('');
    const [imageWidthAspect, setImageWidthAspect] = useState(1);
    const [imageHeightAspect, setImageHeightAspect] = useState(1);
    const [imageToCrop, setImageToCrop] = useState(null);
    const [openBasicDialog, setOpenBasicDialog] = useState(false);
    const [nameInputValue, setNameInputValue] = useState('')
    const [emailInputValue, setEmailInputValue] = useState('');
    const [unvalidatedFields, setUnvalidatedFields] = useState([]);
    const [validatingWarningState, setValidatingWarningState] = useState(false);
    const [agencyNameInputValue, setAgencyNameInputValue] = useState("");
    const [websiteInputValue, setWebSiteInputValue] = useState("");
    const [overlayState, setOverlayState] = useState(false);
    const [informationBrandIds, setInformationBrandIds] = useState([]);
    const [brandSelect, setBrandSelect] = useState([]);
    const [allNotAssociateBrands, setAllNotAssociateBrands] = useState([]);
    const [updateClicked, setUpdateClicked] = useState(false);
    const [titleWarning, setTitleWarning] = useState("");


    const {
        closeModal,
        AgentToEdit,
        AgentToValidate,
        brands,
        loadedBrands,
        updateBrandAgentById,
        loadingUpdateBrandAgent,
        refuseBrandAgentById,
        loadingRefuseBrandAgent,
        validateBrandAgentByIdRequest,
        validateBrandAgentById,
        loadingValidateBrandAgent,
    } = props;

    const handleCloseModal = useCallback(() => {
        closeModal(true);
    }, [closeModal]);

    const handleCloseWarningDialog = () => {
        setValidatingWarningState(false);
        setUnvalidatedFields([]);
    };

    const uploadBasicImage = async (requestBody) => {
        return axios.post("https://us-central1-shopmyinfluens.cloudfunctions.net/firebaseStorage/storage/upload", requestBody)
    };

    const handleSaveBasicDialog = (croppedImage) => {
        setOpenBasicDialog(false);
        setBase64BasicLogo(croppedImage);
    };
    const handleCancelBasicDialog = () => {
        setOpenBasicDialog(false);
        setBasicBrandPictureValue('');
        setBase64BasicLogo('');
    };

    const convertToBase64 = (file) => {
        return new Promise((resolve, reject) => {
            const fileReader = new FileReader();
            fileReader.readAsDataURL(file);
            fileReader.onload = () => {
                resolve(fileReader.result)
            };
            fileReader.onerror = (error) => {
                reject(error);
            }
        });
    };

    const convertBasicImage = async (event) => {
        const file = event.target.files[0];
        if (file !== undefined) {
            await convertToBase64(file).then(result => {
                setImageToCrop(result);
                setOpenBasicDialog(true);
            });
        } else {
            setBase64BasicLogo('');
            setBasicBrandPictureValue('');
        }

    };


    const handleValueChange = id => event => {
        if (id === 'brandBasicPicture') {
            setImageWidthAspect(1);
            setImageHeightAspect(1);
            setBasicBrandPictureValue(event.target.value);
            convertBasicImage(event);
        }

        if (id === 'agentName') setNameInputValue(event.target.value);
        if (id === 'agentEmail') setEmailInputValue(event.target.value);
        if (id === 'brand') setBrandSelect(event.target.value);
        if (id === 'agencyName') setAgencyNameInputValue(event.target.value);
        if (id === 'website') setWebSiteInputValue(event.target.value);
    };

    const validateBrandAgent = async () => {
        let validatingArray = [];
        if (agencyNameInputValue === "") validatingArray.push(t('ADMIN.VIEWS.PARTNERS.AGENCES.BRAND_AGENT_FORM.AGENCE_NAME'));
        if (nameInputValue === "") validatingArray.push(t('ADMIN.VIEWS.PARTNERS.AGENCES.BRAND_AGENT_FORM.AGENT_NAME'));
        if (websiteInputValue === "") validatingArray.push(t('ADMIN.VIEWS.PARTNERS.AGENCES.BRAND_AGENT_FORM.WEBSITE'));
        if (AgentToValidate.banner === undefined && base64BasicLogo === '') validatingArray.push(t('ADMIN.VIEWS.PARTNERS.AGENCES.BRAND_AGENT_FORM.IMAGE_PLACEHOLDER'));

        if (validatingArray.length !== 0) {
            setValidatingWarningState(true);
            setUnvalidatedFields(validatingArray);
        } else {
            let brandAgent = {};
            setUpdateClicked(true);
            validateBrandAgentByIdRequest();
            brandAgent['agenceName'] = agencyNameInputValue;
            brandAgent['name'] = nameInputValue;
            brandAgent['email'] = emailInputValue;
            brandAgent['brandsIds'] = brandSelect.map(brand => brand.offerId);
            brandAgent['website'] = websiteInputValue;
            brandAgent['createdAt'] = AgentToValidate.createdAt;
            brandAgent['userId'] = AgentToValidate.key;

            if (base64BasicLogo === '') {
                brandAgent['image'] = AgentToValidate.banner;
            } else {
                // UPLOAD NEW BASIC IMAGE
                setUploadBasicImageLoading(true);
                const cloudinaryPostRequestBody = {
                    image: base64BasicLogo,
                    userType: "brandAgent",
                    public_id: emailInputValue,
                };
                await uploadBasicImage(cloudinaryPostRequestBody).then(result => {
                    setUploadBasicImageLoading(false);
                    brandAgent['image'] = result.data.url;
                }).catch(error => {
                    setUploadBasicImageLoading(false);
                });
            }
            let userData = {
                createdAt: AgentToValidate.createdAt,
                email: emailInputValue,
                name: nameInputValue
            }
            validateBrandAgentById(AgentToValidate.key, brandAgent, userData);
        }
    };

    const refuseBrandAgent = async () => {
        setUpdateClicked(true);
        refuseBrandAgentById(AgentToValidate.key);
    };

    const editBrandAgent = async () => {
        let validatingArray = [];
        let updates = {};
        if (nameInputValue === "") validatingArray.push(t('ADMIN.VIEWS.PARTNERS.AGENCES.BRAND_AGENT_FORM.AGENT_NAME'));
        if (agencyNameInputValue === "") validatingArray.push(t('ADMIN.VIEWS.PARTNERS.AGENCES.BRAND_AGENT_FORM.AGENCE_NAME'));
        if (websiteInputValue === "") validatingArray.push(t('ADMIN.VIEWS.PARTNERS.AGENCES.BRAND_AGENT_FORM.WEBSITE'));

        if (validatingArray.length !== 0) {
            setValidatingWarningState(true);
            setUnvalidatedFields(validatingArray);
        } else {
            updates['agenceName'] = agencyNameInputValue;
            updates['name'] = nameInputValue;
            updates['brandsIds'] = informationBrandIds.map(brand => brand.offerId).concat(brandSelect.map(brand => brand.offerId));
            updates['website'] = websiteInputValue;
            if (base64BasicLogo) {
                setUploadBasicImageLoading(true);
                const cloudinaryPostRequestBody = {
                    image: base64BasicLogo,
                    userType: "brandAgent",
                    public_id: emailInputValue,

                };
                await uploadBasicImage(cloudinaryPostRequestBody).then(result => {
                    setUploadBasicImageLoading(false);
                    updates['image'] = result.data.url;
                }).catch(error => {
                    setUploadBasicImageLoading(false);
                });
            }

            if (AgentToEdit.brandAgentData !== undefined) {
                let userData = {
                    createdAt: AgentToEdit.brandAgentData.createdAt,
                    email: emailInputValue,
                    name: nameInputValue
                }
                setUpdateClicked(true);
                updateBrandAgentById(AgentToEdit.brandAgentData, updates, userData);
            } else {
                let userData = {
                    createdAt: AgentToEdit.createdAt,
                    email: emailInputValue,
                    name: nameInputValue
                }
                setUpdateClicked(true);
                updateBrandAgentById(AgentToEdit, updates, userData);
            }
        }
    };

    const deleteImage = (e, data) => {
        setAnchorEl(e.currentTarget);
        let brand = informationBrandIds.filter(element => element.offerId !== data.offerId)
        setInformationBrandIds(brand);
    };

    useEffect(() => {
        if (AgentToEdit) {
            if (AgentToEdit.brandAgentData) {
                if (AgentToEdit.brandAgentData.agenceName) setAgencyNameInputValue(AgentToEdit.brandAgentData.agenceName);
                if (AgentToEdit.brandAgentData.name) setNameInputValue(AgentToEdit.brandAgentData.name);
                setAgentNameTitle(AgentToEdit.brandAgentData.name);
                if (AgentToEdit.brandAgentData.email) setEmailInputValue(AgentToEdit.brandAgentData.email);
                if (AgentToEdit.brandAgentData.website) setWebSiteInputValue(AgentToEdit.brandAgentData.website)
            } else {
                if (AgentToEdit.agenceName) setAgencyNameInputValue(AgentToEdit.agenceName);
                if (AgentToEdit.name) setNameInputValue(AgentToEdit.name);
                setAgentNameTitle(AgentToEdit.name);
                if (AgentToEdit.email) setEmailInputValue(AgentToEdit.email);
                if (AgentToEdit.website) setWebSiteInputValue(AgentToEdit.website)
            }
        }

    }, [AgentToEdit])

    useEffect(() => {

        if (AgentToValidate) {
            if (AgentToValidate.agencyName) setAgencyNameInputValue(AgentToValidate.agencyName);
            setAgentNameTitle(AgentToValidate.name);
            if (AgentToValidate.name) setNameInputValue(AgentToValidate.name);
            if (AgentToValidate.email) setEmailInputValue(AgentToValidate.email);
            if (AgentToValidate.website) setWebSiteInputValue(AgentToValidate.website)
        }
    }, [AgentToValidate])

    useEffect(() => {
        let infosBrandPic = [];
        let offerIdBrandAssociate = [];
        let brandNotAssociate = [];

        if (AgentToEdit) {
            Object.values(AgentToEdit).forEach((brandAgent) => {
                if (brandAgent.offerId !== undefined) {
                    infosBrandPic.push({
                        name: brandAgent.name,
                        image: brandAgent.pic,
                        offerId: brandAgent.offerId,
                    });
                    offerIdBrandAssociate.push(brandAgent.offerId);
                }
            });
        }

        if (loadedBrands) {
            brands.map(item => {
                if (offerIdBrandAssociate.indexOf(item.offerId) === -1) brandNotAssociate.push(item);
                return null;
            });
        }
        setAllNotAssociateBrands(brandNotAssociate);
        setInformationBrandIds(infosBrandPic);
    }, [AgentToEdit, loadedBrands, brands]);

    useEffect(() => {
        setTitleWarning(t('ADMIN.VIEWS.PARTNERS.AGENCES.WARNING_VALIDATION_MESSAGE'));
    }, [t])

    useEffect(() => {
        if (loadingUpdateBrandAgent || uploadBasicImageLoading || loadingRefuseBrandAgent || loadingValidateBrandAgent) {
            setOverlayState(true);

        }
        if (!loadingUpdateBrandAgent && !uploadBasicImageLoading && updateClicked && !loadingRefuseBrandAgent && !loadingValidateBrandAgent) {
            setOverlayState(false);
            setUpdateClicked(false);
            handleCloseModal();
        }

    }, [loadingUpdateBrandAgent, uploadBasicImageLoading, updateClicked, handleCloseModal, loadingRefuseBrandAgent, loadingValidateBrandAgent])


    return (
        <div>
            <Backdrop className={classes.overlay} open={overlayState}>
                <CircularProgress color='secondary' />
            </Backdrop>
            <Dialog className={classes.warningDialog} open={validatingWarningState} onClose={handleCloseWarningDialog}>
                <WarningDialog unvalidatedFields={unvalidatedFields} titleWarning={titleWarning} />
            </Dialog>
            <Grid container item direction='row' xs={12} justify='center' alignItems='center'>
                <Grid container item direction='row' xs={11} justify='center' alignItems='center' className={classes.overallBox}>
                    <Grid container item direction='row' alignItems='center' justify='flex-end' xs={12}>
                        <IconButton style={{ marginTop: '10px', marginRight: '10px', color: '#000' }} onClick={handleCloseModal}>
                            <CloseIcon fontSize='large' />
                        </IconButton>
                    </Grid>
                    <Grid container item direction='row' alignItems='center' justify='center' xs={12}>
                        <Typography className={classes.formTitle}>{agentNameTitle}</Typography>
                    </Grid>
                    <Grid container item direction='row' xs={11} className={classes.formBox}>
                        {
                            (AgentToEdit || AgentToValidate)
                                ? <>

                                    <Grid container item direction='column' justify='flex-start' alignItems='flex-start' className={classes.formControlRow} xs={12} >
                                        <Typography className={classes.formLabel}>
                                            {t('ADMIN.VIEWS.PARTNERS.AGENCES.BRAND_AGENT_FORM.AGENT_NAME')}*
                                        </Typography>
                                        <FormControl className={classes.formControl}>
                                            <InputBase
                                                className={classes.formInput}
                                                placeholder={t('ADMIN.VIEWS.PARTNERS.AGENCES.BRAND_AGENT_FORM.AGENT_NAME_PLACEHOLDER')}
                                                id='agentName'
                                                type='text'
                                                value={nameInputValue}
                                                onChange={handleValueChange('agentName')} />
                                        </FormControl>
                                    </Grid>
                                </>
                                : <></>
                        }
                        {
                            (AgentToEdit || AgentToValidate)
                                ? <>
                                    <Grid container item direction='column' justify='flex-start' alignItems='flex-start' className={classes.formControlRow} xs={12} >
                                        <Typography className={classes.formLabel}>
                                            {t('ADMIN.VIEWS.PARTNERS.AGENCES.BRAND_AGENT_FORM.AGENCE_NAME')}*
                                        </Typography>
                                        <FormControl className={classes.formControl}>
                                            <InputBase
                                                className={classes.formInput}
                                                placeholder={t('ADMIN.VIEWS.PARTNERS.AGENCES.BRAND_AGENT_FORM.AGENCE_NAME_PLACEHOLDER')}
                                                id='agencyName'
                                                type='text'
                                                value={agencyNameInputValue}
                                                onChange={handleValueChange('agencyName')} />
                                        </FormControl>
                                    </Grid>
                                </>
                                : <></>
                        }
                        {
                            (AgentToEdit || AgentToValidate)
                                ? <>
                                    <Grid container item direction='column' justify='flex-start' alignItems='flex-start' className={classes.formControlRow} xs={12} >
                                        <Typography className={classes.formLabel}>
                                            {t('ADMIN.VIEWS.PARTNERS.AGENCES.BRAND_AGENT_FORM.EMAIL')}
                                        </Typography>
                                        <FormControl className={classes.formControl}>
                                            <InputBase
                                                className={classes.formInput}
                                                placeholder="Email Agent"
                                                id='agentEmail'
                                                type='text'
                                                value={emailInputValue}
                                                disabled={true}
                                                onChange={handleValueChange('agentEmail')} />
                                        </FormControl>
                                    </Grid>
                                </>
                                : <></>
                        }

                        {
                            (AgentToValidate || AgentToEdit)
                                ? <>
                                    <Grid container item direction='column' justify='flex-start' alignItems='flex-start' className={classes.formControlRow} xs={12} >
                                        <Typography className={classes.formLabel}>
                                            {t('ADMIN.VIEWS.PARTNERS.AGENCES.BRAND_AGENT_FORM.WEBSITE')}* (href)
                                        </Typography>
                                        <FormControl className={classes.formControl}>
                                            <InputBase
                                                className={classes.formInput}
                                                placeholder="Web Site"
                                                id='website'
                                                type='text'
                                                value={websiteInputValue}
                                                onChange={handleValueChange('website')} />
                                        </FormControl>
                                    </Grid>
                                </>
                                : <></>
                        }
                        {AgentToEdit ?
                            <>
                                <Grid container item direction='column' justify='flex-start' alignItems='flex-start' className={classes.formControlRow} xs={12} >
                                    <Typography className={classes.formLabel}>
                                        {t('ADMIN.VIEWS.PARTNERS.AGENCES.BRAND_AGENT_FORM.AGENT_BRAND_ASSOCIATE')}
                                    </Typography>
                                    {informationBrandIds.length !== 0 ?
                                        <Grid container item direction="row" xs={12} >
                                            <Grid container item direction="row" justify="center" xs={12} className={classes.AllBrandBox} style={{ padding: '15px' }}>
                                                <Grid container item justify={(xsDown) ? 'center' : 'flex-start'} alignItems="center">
                                                    {informationBrandIds.map((picture, key) => {
                                                        return (
                                                            <div key={key} style={{ display: "inline", position: "relative" }}>
                                                                <Box className={classes.brandBox}>
                                                                    <img
                                                                        src={picture.image}
                                                                        anchorel={anchorEl}
                                                                        alt={picture.name}
                                                                        className={classes.brandImage}
                                                                    />
                                                                    <IconButton style={{ position: "absolute", right: 0, top: 0, backgroundColor: '#fff' }} onClick={(e) => deleteImage(e, picture)}>
                                                                        <CloseIcon style={{ position: "absolute", right: 0, top: 0, color: "red" }} />
                                                                    </IconButton>
                                                                </Box>
                                                            </div>
                                                        )
                                                    })
                                                    }
                                                </Grid>
                                            </Grid>
                                        </Grid>
                                        :
                                        <>
                                            <Grid container item direction='row' xs={11} justify='center' alignItems='center' style={{ marginTop: '30px' }}>
                                                <img
                                                    alt="No BrandAgents"
                                                    src={placeholderBrand}
                                                    height="75px"
                                                    width="75px"
                                                />
                                            </Grid>
                                            <Grid container item direction='row' xs={11} justify='center' alignItems='center' style={{ marginTop: '10px' }}>
                                                <Typography className={classes.placeholderStyles}>
                                                    {t('ADMIN.VIEWS.PARTNERS.AGENCES.PLACEHOLDER_NO_BRANDS')}
                                                </Typography>
                                            </Grid>
                                        </>
                                    }
                                </Grid>
                            </> :
                            <></>
                        }
                        {AgentToEdit || AgentToValidate ?
                            <>
                                <Grid container item direction='column' justify='flex-start' alignItems='flex-start' className={classes.formControlRow} xs={12} >
                                    <Typography className={classes.formLabel}>
                                        {t('ADMIN.VIEWS.PARTNERS.AGENCES.BRAND_AGENT_FORM.AGENT_BRAND_NO_ASSOCIATE')}
                                    </Typography>
                                    <FormControl className={classes.formControl}>
                                        <Select
                                            displayEmpty
                                            multiple
                                            value={brandSelect}
                                            onChange={handleValueChange('brand')}
                                            classes={{ root: classes.selectClass }}
                                            input={<InputBase className={classes.formInput} />}
                                            renderValue={(selected) => {
                                                if (selected.length === 0) {
                                                    return <Typography style={{ fontFamily: 'Poppins', color: '#717171' }}>
                                                        {t('ADMIN.VIEWS.PARTNERS.AGENCES.BRAND_AGENT_FORM.AGENT_BRAND_NO_ASSOCIATE_PLACEHOLDER')}
                                                    </Typography>;
                                                }
                                                return brandSelect.map(item => {
                                                    return item.displayName;
                                                }).join(", ");
                                            }}
                                            IconComponent={CustomExpandMore}>
                                            {allNotAssociateBrands.map((brands, key) => (
                                                <MenuItem key={key} value={brands} style={{ fontFamily: 'Poppins' }} classes={{ selected: classes.selectedMenuItems }}>{brands.displayName}</MenuItem>
                                            ))}
                                        </Select>
                                    </FormControl>
                                </Grid>
                            </>
                            :
                            <></>
                        }

                        <Grid container item direction='column' justify='flex-start' alignItems='flex-start' className={classes.formControlRow} xs={12}>
                            <Grid container item direction='row' justify='flex-start' alignItems='flex-start' xs={12}>
                                <Typography className={classes.formLabel}>
                                    {t('ADMIN.VIEWS.PARTNERS.AGENCES.BRAND_AGENT_FORM.AGENT_IMAGE')}
                                </Typography>
                                <FormControl className={classes.formControl}>
                                    <Grid container item alignItems='center' justify={(xsDown) ? 'center' : 'flex-start'} className={classes.formInput} style={{ height: 'auto', padding: 5, marginTop: '10px', marginBottom: '10px', width: '100%' }}>
                                        <Typography
                                            className={(basicBrandPictureValue === '')
                                                ? ((xsDown) ? classes.profilePicturePlaceholderMobile : classes.profilePicturePlaceholder)
                                                : ((xsDown) ? classes.activeProfilePicturePlaceholderMobile : classes.activeProfilePicturePlaceholder)}>
                                            {(basicBrandPictureValue === '') ? t('ADMIN.VIEWS.PARTNERS.AGENCES.BRAND_AGENT_FORM.IMAGE_PLACEHOLDER') : basicBrandPictureValue.split('\\').pop()}
                                        </Typography>
                                        <label htmlFor='profileImage' tabIndex='0' className={classes.profileImageLabel} style={(xsDown) ? { margin: 5 } : {}}>
                                            {t('ADMIN.VIEWS.PARTNERS.AGENCES.BRAND_AGENT_FORM.UPLOAD_BUTTON')}
                                        </label>
                                    </Grid>
                                    <input type='file' id='profileImage' onChange={handleValueChange('brandBasicPicture')} hidden accept="image/x-png,image/gif,image/jpeg" />
                                    <CropperDialog open={openBasicDialog} save={handleSaveBasicDialog} cancel={handleCancelBasicDialog} imageToCrop={imageToCrop} widthAspect={imageWidthAspect} heightAspect={imageHeightAspect} />
                                </FormControl>
                            </Grid>
                        </Grid>

                    </Grid>
                    {AgentToEdit ?
                        <Grid container item direction='row' xs={11} className={classes.formBox} justify={(xsDown) ? 'center' : (mdDown) ? 'center' : 'space-between'}>
                            <Button
                                className={classes.cancelEditButton}
                                onClick={handleCloseModal}
                            >
                                {t('ADMIN.VIEWS.PARTNERS.AGENCES.BRAND_AGENT_FORM.CANCEL_EDITS_BUTTON')}
                            </Button>
                            <Button
                                className={classes.validateEditButton}
                                onClick={editBrandAgent}
                            >
                                {t('ADMIN.VIEWS.PARTNERS.AGENCES.BRAND_AGENT_FORM.SAVE_EDITS_BUTTON')}
                            </Button>
                        </Grid>
                        : <></>
                    }
                    {
                        (AgentToValidate)
                            ? <Grid container item direction='row' xs={11} className={classes.formBox} justify={(xsDown) ? 'center' : (mdDown) ? 'center' : 'space-between'}>
                                <Button
                                    className={classes.refuseButton}
                                    onClick={refuseBrandAgent}
                                >
                                    {t('ADMIN.VIEWS.PARTNERS.AGENCES.BRAND_AGENT_FORM.REFUSE_BUTTON')}
                                </Button>
                                <Button
                                    className={classes.validateButton}
                                    onClick={validateBrandAgent}
                                >
                                    {t('ADMIN.VIEWS.PARTNERS.AGENCES.BRAND_AGENT_FORM.VALIDATE_BUTTON')}
                                </Button>
                            </Grid>
                            : <></>
                    }
                </Grid>
            </Grid >
        </div>
    )
}
const mapStateToProps = (state) => {
    const brands = state.brandsState.allBrands;
    const loadedBrands = state.brandsState.loadedBrands;
    const loadingUpdateBrandAgent = state.brandsAgentState.loadingUpdateBrandAgent;
    const loadingRefuseBrandAgent = state.brandsAgentState.loadingRefuseBrandAgent;
    const loadingValidateBrandAgent = state.brandsAgentState.loadingValidateBrandAgent;
    return {
        brands,
        loadedBrands,
        loadingUpdateBrandAgent,
        loadingRefuseBrandAgent,
        loadingValidateBrandAgent,
    }
}

const mapDispatchToProps = (dispatch) => {
    return {
        updateBrandAgentById: (brandAgentId, updates, userData) => dispatch(updateBrandAgentById(brandAgentId, updates, userData)),
        refuseBrandAgentById: (brandAgentId) => dispatch(refuseBrandAgentById(brandAgentId)),
        validateBrandAgentByIdRequest: () => dispatch({ type: 'VALIDATE_BRAND_AGENT_BY_ID_REQUEST' }),
        validateBrandAgentById: (brandAgentId, brandAgent, userData) => dispatch(validateBrandAgentById(brandAgentId, brandAgent, userData)),
    }
}
export default connect(mapStateToProps, mapDispatchToProps)(AgenceEditModal);