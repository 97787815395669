export { Vector2D } from "../../utils";
export const getIndexById = (array, id) => {
  let i;

  for (i = 0; i < array.length; i++) {
    if (array[i].id === id) return i;
  }
  return null;
};
export const pointInElement = (element, point) => {
  const boundaries = element.getBoundingClientRect();

  if (
    point.x < boundaries.width + boundaries.left &&
    point.x > boundaries.left &&
    point.y < boundaries.height + boundaries.top &&
    point.y > boundaries.top
  )
    return true;
  return false;
};
