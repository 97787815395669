import { DialogTitle,DialogContent,DialogActions,DialogContentText,Typography,Button,makeStyles } from "@material-ui/core";
import { useTranslation } from "react-i18next";
import React from "react";

const useStyles = makeStyles((theme) => ({
    dialogTitle: {
        fontFamily: 'Poppins',
        fontSize: '20px'
    },
    dialogContentText: {
        fontFamily: 'Poppins'
    },
    confirmButton: {
        backgroundColor: 'red',
        fontFamily: 'Poppins',
        color: '#ffffff',
        borderRadius: '10px',
        paddingLeft: '15px',
        paddingRight: '15px',
        marginRight: '10px',
        marginLeft: '20px',
        '&:hover': {
            backgroundColor: 'red'
        }
    },
    cancelButton: {
        backgroundColor: 'gray',
        fontFamily: 'Poppins',
        color: '#ffffff',
        borderRadius: '10px',
        paddingLeft: '15px',
        paddingRight: '15px',
        marginRight: '10px',
        marginLeft: '20px',
        '&:hover': {
            backgroundColor: 'gray'
        }
    }
}));

const ConfirmDeleteUserDialog = (props) => {
    const { t } = useTranslation('common');

    const classes = useStyles();

    const handleCancel = () => {
        props.onClose(false);
    };

    const handleDelete = () => {
        props.onClose(true);
    };

    return (
        <>
            <DialogTitle>
                <Typography className={ classes.dialogTitle }> { t("TREND.VIEWS.USERS.DELETE_USER.DELETE_TITLE") }</Typography>
            </DialogTitle>
            <DialogContent>
                <DialogContentText className={ classes.dialogContentText }>
                    { t("TREND.VIEWS.USERS.DELETE_USER.DELETE_DESCRIPTION") }
                </DialogContentText>
            </DialogContent>
            <DialogActions>
                <Button onClick={ handleCancel } className={ classes.cancelButton }>{ t("TREND.VIEWS.USERS.DELETE_USER.BUTTON_CANCEL") }</Button>
                <Button onClick={ handleDelete } className={ classes.confirmButton }>{ t("TREND.VIEWS.USERS.DELETE_USER.BUTTON_CONFIRM") }</Button>
            </DialogActions>
        </>
    );
}

export default ConfirmDeleteUserDialog;