import React from "react";
import DateFnsUtils from "@date-io/date-fns";
import frLocale from "date-fns/locale/fr";
import {
  MuiPickersUtilsProvider,
  KeyboardDatePicker,
} from "@material-ui/pickers";
import { Grid, Typography, makeStyles, Divider } from "@material-ui/core";

import { ConversionsChart, DualRing } from ".";
import { MyDate } from "../utils";

const useStyle = makeStyles(theme => ({
  adminTopBar: {
    // height: theme.admin.topBar.height,
  },
  titleWrap: {
    borderBottom: theme.border,
  },
  titleLeft: {
    borderRight: theme.border,
  },
  logo: {
    margin: theme.spacing(1),
    height: "58px",
  },
  logoTitle: {
    margin: `${theme.spacing(3)}, 0`,
  },
  topBarTitle: {
    margin: theme.spacing(3),
  },
  statsContainer: {
    flexGrow: 1,
  },
  infoDisplayWrap: {
    padding: theme.spacing(1),
  },
  infoDisplay: {
    marginBottom: theme.spacing(1),
    textAlign: "center",
    "&:last-child": { marginBottom: 0 },
  },
  infoDisplayTitle: {},
  infoDisplayValue: {
    fontSize: "2rem",
  },
}));

const InfluencersStats = props => {
  const { searchPeriod, handleSetPeriod, conversions, clicks, loading } = props;
  const classes = useStyle();

  const onPeriodFromChange = date => {
    date !== null &&
      date.getTime() &&
      handleSetPeriod({ ...searchPeriod, start: new MyDate(date).dayStart() });
  };
  const onPeriodToChange = date => {
    date !== null &&
      date.getTime() &&
      handleSetPeriod({ ...searchPeriod, end: new MyDate(date).dayEnd() });
  };

  return (
    <Grid
      className={classes.adminTopBar}
      item
      container
      direction='column'
      wrap='nowrap'
    >
      <Grid className={classes.titleWrap} item container>
        <Grid
          className={classes.titleLeft}
          xs={8}
          item
          container
          alignContent='center'
        >
          <Grid
            xs={6}
            item
            container
            justify='flex-start'
            alignContent='center'
            alignItems='center'
          >
            <img
              className={classes.logo}
              src='http://res.cloudinary.com/tellmedia/image/upload/v1523975847/shopmyinfluens/logo.png'
              alt='logo'
            />
            <Typography className={classes.logoTitle}>ADMIN</Typography>
          </Grid>
          <Grid
            xs={6}
            item
            container
            justify='flex-end'
            alignContent='center'
            alignItems='center'
          >
            <Typography className={classes.topBarTitle}>PERIODE</Typography>
          </Grid>
        </Grid>
        <MuiPickersUtilsProvider utils={DateFnsUtils} locale={frLocale}>
          <Grid
            xs={4}
            item
            container
            justify='space-around'
            alignContent='center'
          >
            <Grid xs={5} item container justify='center' alignContent='center'>
              <KeyboardDatePicker
                disableFuture
                variant='inline'
                format='dd/MM/yyyy'
                id='start-date'
                value={searchPeriod.start}
                onChange={onPeriodFromChange}
                style={{ margin: 0 }}
                InputAdornmentProps={{
                  position: "start",
                }}
                KeyboardButtonProps={{
                  color: "primary",
                  "aria-label": "change date",
                  position: "start",
                }}
              />
            </Grid>
            <Divider orientation='vertical' />
            <Grid xs={5} item container justify='center' alignContent='center'>
              <KeyboardDatePicker
                disableFuture
                variant='inline'
                format='dd/MM/yyyy'
                id='end-date'
                value={searchPeriod.end}
                onChange={onPeriodToChange}
                style={{ margin: 0 }}
                InputAdornmentProps={{
                  position: "start",
                }}
                KeyboardButtonProps={{
                  color: "primary",
                  "aria-label": "change date",
                  position: "start",
                }}
              />
            </Grid>
          </Grid>
        </MuiPickersUtilsProvider>
      </Grid>
      <Grid className={classes.statsContainer} item container>
        <Grid xs={8} item container alignContent='flex-start'>
          <ConversionsChart
            clicks={clicks}
            conversions={conversions}
            period={searchPeriod}
          />
          <Divider orientation='vertical' />
        </Grid>
        <Grid className={classes.infoDisplayWrap} xs={4} item container>
          <Grid
            className={classes.infoDisplay}
            xs={12}
            item
            container
            justify='center'
            alignContent='center'
            direction='column'
          >
            <Typography className={classes.infoDisplayTitle} color='primary'>
              Total clics
            </Typography>
            <Typography className={classes.infoDisplayValue} color='primary'>
              {loading.clicks ? <DualRing /> : clicks.length}
            </Typography>
          </Grid>
          <Grid
            className={classes.infoDisplay}
            xs={12}
            item
            container
            justify='center'
            alignContent='center'
            direction='column'
          >
            <Typography className={classes.infoDisplayTitle} color='secondary'>
              Total conversions
            </Typography>
            <Typography className={classes.infoDisplayValue} color='secondary'>
              {loading.conversions ? <DualRing /> : conversions.length}
            </Typography>
          </Grid>
        </Grid>
      </Grid>
      <Divider />
    </Grid>
  );
};

export default InfluencersStats;
