import { dbManager } from "../../components/managers"

export const fetchAllConversions = () => {
    return (dispatch, getState) => {
        dbManager.fetchAllConversions().then(conversions => {
            dispatch({ type: 'FETCH_CONVERSIONS_SUCCESS', conversions });
        }).catch(error => {
            dispatch({ type: 'FETCH_CONVERSIONS_ERROR', error });
        });
    };
}

export const fetchBrandConversions = (brandOfferId) => {
    return (dispatch, getState) => {
        dbManager.fetchBrandConversionsByOfferId(brandOfferId).then(brandConversions => {
            dispatch({ type: 'FETCH_BRAND_CONVERSIONS_SUCCESS', brandConversions });
        }).catch(error => {
            dispatch({ type: 'FETCH_CONVERSIONS_ERROR', error });
        })
    };
}
