const initState = {
    shortlinks: [],
    shortlinksPerInfluencer: {},
    loadedShortlinks: false,
    loadedShortlinksPerInfluencer: false,
    loadedAddShortlink: false,
    loadedAddMultipleShortlinks: false,
    shortlinkError: null
};

const shortlinksReducer = (state = initState, action) => {
    switch (action.type) {
        case 'FETCH_SHORTLINKS_PER_INFLUENCER_REQUEST':
            return {
                ...state,
                loadedShortlinksPerInfluencer: false,
            };
        case 'FETCH_SHORTLINKS_PER_INFLUENCER_SUCCESS':
            if (action.payload.shortlinks) {
                let newShortlinksPerInfluencer = {
                    ...state.shortlinksPerInfluencer
                };
                newShortlinksPerInfluencer[action.payload.influencerAffiliateId] = [];
                if (action.payload.shortlinks)
                    newShortlinksPerInfluencer[action.payload.influencerAffiliateId] = action.payload.shortlinks;
                return {
                    ...state,
                    loadedShortlinksPerInfluencer: true,
                    shortlinksPerInfluencer: newShortlinksPerInfluencer
                }
            } else {
                let newShortlinksPerInfluencer = {
                    ...state.shortlinksPerInfluencer
                };
                newShortlinksPerInfluencer[action.payload.influencerAffiliateId] = [];
                return {
                    ...state,
                    loadedShortlinksPerInfluencer: true,
                    shortlinksPerInfluencer: newShortlinksPerInfluencer
                }
            };
        case 'FETCH_SHORTLINKS_PER_INFLUENCER_ERROR':
            return {
                ...state,
                loadedShortlinksPerInfluencer: true,
                shortlinksError: action.error
            };
        case 'ADD_SHORTLINK_REQUEST':
            return {
                ...state,
                loadedAddShortlink: false
            };
        case 'ADD_SHORTLINK_SUCCESS':
            let newShortlinksPerInfluencer = {
                ...state.shortlinksPerInfluencer
            };
            const newUserShortlinks = [...state.shortlinksPerInfluencer[action.payload.influencerAffiliateId], action.payload.shortlink];
            newShortlinksPerInfluencer[action.payload.influencerAffiliateId] = newUserShortlinks;
            return {
                ...state,
                shortlinksPerInfluencer: newShortlinksPerInfluencer,
                loadedAddShortlink: true,
            };
        case 'ADD_SHORTLINK_ERROR':
            return {
                ...state,
                shortlinksError: action.error
            };
        case 'ADD_MULTIPLE_SHORTLINKS_REQUEST':
            return {
                ...state,
                loadedAddMultipleShortlinks: false
            };
        case 'ADD_MULTIPLE_SHORTLINKS_SUCCESS':
            {
                let newShortlinksPerInfluencer = {
                    ...state.shortlinksPerInfluencer
                };
                const newUserShortlinks = [...state.shortlinksPerInfluencer[action.payload.influencerAffiliateId], ...action.payload.shortlinks];
                newShortlinksPerInfluencer[action.payload.influencerAffiliateId] = newUserShortlinks;
                return {
                    ...state,
                    shortlinksPerInfluencer: newShortlinksPerInfluencer,
                    loadedAddMultipleShortlinks: true,
                }
            };
        case 'ADD_MULTIPLE_SHORTLINKS_ERROR':
            {
                let newShortlinksPerInfluencer = {
                    ...state.shortlinksPerInfluencer
                };
                const newUserShortlinks = [...state.shortlinksPerInfluencer[action.errorObject.influencerAffiliateId], ...action.errorObject.shortlinks];
                newShortlinksPerInfluencer[action.payload.influencerAffiliateId] = newUserShortlinks;
                return {
                    ...state,
                    shortlinksPerInfluencer: newShortlinksPerInfluencer,
                    loadedAddMultipleShortlinks: true,
                    shortlinkError: action.errorObject.error
                };
            };
        case 'SET_LOAD_ADD_MULTIPLE_SHORTLINKS': 
            return {
                ...state,
                loadedAddMultipleShortlinks: false
            };
        default:
            return state;
    }
}

export default shortlinksReducer;