import React, { useState } from "react";
// import PropTypes from 'prop-types';
import {
  Grid,
  RadioGroup,
  FormControlLabel,
  Radio,
  makeStyles,
  Typography,
} from "@material-ui/core";
/* Component Scope */
import { TextField } from "..";
/* UI Scope */
import { SharpFlatButton } from "../..";
import { useTheme } from "@material-ui/styles";
import clsx from "clsx";
import { isValidMail } from "../../utils";
import Form from "..";

const fields = [
  {
    id: "surname",
    label: "Nom",
  },
  {
    id: "firstName",
    label: "Prénom",
  },
  {
    id: "email",
    label: "E-Mail",
    required: true,
    type: "email",
  },
];

const useStyles = makeStyles((theme) => ({
  form: {
    display: "flex",
    height: "100%",
    width: "100%",
    padding: theme.spacing(3),
  },
  field: {
    margin: theme.spacing(1),
  },
  catch: {
    margin: theme.spacing(1),
  },
}));

const LeadForm = (props) => {
  const { onSubmit } = props;
  const initialDataState = () => {
    let dataSet = {};
    fields.forEach((field) => {
      dataSet[field.id] = "";
    });
    return dataSet;
  };
  const classes = useStyles();
  const theme = useTheme();
  const [data, setData] = useState({
    ...initialDataState(),
    sex: "",
  });
  const [errors, setErrors] = useState([]);

  const handleSubmit = (e) => {
    e.preventDefault();
    let err = [];
    fields.forEach((field) => {
      field.required &&
        (!data[field.id] ||
          data[field.id].length < 1) &&
        err.push({
          field: field.id,
          message: "Ce champs est obligatoire",
        });
      field.type === "email" &&
        !isValidMail(data[field.id]) &&
        err.push({
          field: field.id,
          message:
            "L'adresse mail n'est pas correcte",
        });
    });
    if (err.length) {
      setErrors(err);
    } else {
      onSubmit(data);
      setData({ ...initialDataState(), sex: "" });
      setErrors([]);
    }
  };
  const handleFieldChange = (fieldId) => (e) => {
    e.preventDefault();
    setData({
      ...data,
      [fieldId]: e.target.value,
    });
  };

  return (
    <Form
      classes={classes.form}
      autoComplete='off'
      onSubmit={handleSubmit}
    >
      <Grid
        item
        container
        direction='column'
        justify='center'
      >
        <Typography
          className={classes.catch}
          variant='h4'
          align='center'
        >
          Vous souhaitez recevoir plus
          d'informations ?
        </Typography>
        <RadioGroup
          row
          style={{ alignSelf: "center" }}
          onChange={handleFieldChange("sex")}
        >
          <FormControlLabel
            value='male'
            control={<Radio />}
            label='Mr'
          />
          <FormControlLabel
            value='female'
            control={<Radio />}
            label='Mme'
          />
        </RadioGroup>
        {fields.map((field) => {
          const { id, label, required } = field;
          const err = errors.find(
            (error) => error.field === field.id
          );
          return (
            <TextField
              key={id}
              className={clsx(
                classes.field,
                errors[id] && classes.fieldError
              )}
              label={`${label}${
                required ? " *" : ""
              }`}
              value={data[field.id]}
              onChange={handleFieldChange(
                field.id
              )}
              helperText={
                err ? err.message : undefined
              }
              error={err ? true : false}
            />
          );
        })}
        <Grid item container justify='center'>
          <SharpFlatButton
            style={{ margin: theme.spacing(1) }}
            variant='contained'
            type='submit'
          >
            Envoyer
          </SharpFlatButton>
        </Grid>
      </Grid>
    </Form>
  );
};

LeadForm.propTypes = {};

export default LeadForm;
