import { createMuiTheme, responsiveFontSizes } from "@material-ui/core/styles";

let burgbadTheme = createMuiTheme({
  typography: {
    fontFamily: "Montserrat, sans-serif",
  },
  palette: {
    primary: {
      main: "#6e6e70",
      contrastText: "#FFF",
    },
    secondary: {
      main: "#6e6e70",
      contrastText: "#FFFFFF",
    },
  },
});

burgbadTheme = responsiveFontSizes(burgbadTheme);

export default burgbadTheme;
