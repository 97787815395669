import React from "react";
import CircularProgress from '@material-ui/core/CircularProgress';

const TrendLoading = () => {
    return (
        <div
            style={{
                height:'100%',
                display: 'flex',
                justifyContent: 'center',
                alignItems:'center',
                flexGrow: 1
            }}
        >
            <CircularProgress color="secondary" />
        </div>
    );
};

export default TrendLoading;
