const initState = {
    colors: [],
    loadedColors: false,
    fetchColorsError: null
}

const colorsReducer = (state = initState, action) => {
    switch (action.type) {
        case 'FETCH_ALL_COLORS_SUCCESS':
            return {
                colors: action.payload.colors,
                loadedColors: true
            };
        case 'FETCH_ALL_COLORS_ERROR':
            return {
                ...state,
                loadedColors: true,
                fetchColorsError: action.error
            };
        default:
            return state
    }
}

export default colorsReducer;