import { dbManager } from "../../components/managers";
export const fetchBillsByBrand = (offerId) => {
  return (dispatch) => {
    dbManager
      .fetchBillsByBrand(offerId)
      .then((bills) => {
        dispatch({ type: "FETCH_BILLS", bills });
      })
      .catch((error) => {
        dispatch({ type: "FETCH_BILLS_ERROR", error });
      });
  };
};

export const getInfluencersBills = () => {
  return (dispatch) => {
    dbManager.getInfluencersBills().then((bills) => {
      dispatch({ type: "FETCH_INFLUENCERS_BILLS", bills });
    }).catch((error) => {
      dispatch({ type: "FETCH_INFLUENCERS_BILLS_ERROR", error })
    });
  }
}

export const getBrandsBills = () => {
  return (dispatch) => {
    dbManager.getBrandsBills().then((bills) => {
      dispatch({ type: "FETCH_BRANDS_BILLS", bills });
    }).catch((error) => {
      dispatch({ type: "FETCH_BRANDS_BILLS_ERROR", error })
    });
  }
}

export const updateInfluencersBillsbyId = (influencerId, billsId, updates) => {
  return (dispatch, getState) => {
    dispatch({ type: 'UPDATE_INFLUENCER_BILLS_BY_ID_REQUEST' })
    dbManager.updateInfluencersBillsbyId(influencerId, billsId, updates).then(() => {
      dispatch({ type: 'UPDATE_INFLUENCERS_BILLS_BY_ID_SUCCESS', payload: { id: influencerId, idbill: billsId, updates } });
    }, error => {
      dispatch({ type: 'UPDATE_INFLUENCERS_BILLS_BY_ID_ERROR', error });
    });
  }
}