import React from "react";
import ReactDOM from "react-dom";
import { BrowserRouter, Switch, Route } from "react-router-dom";

import * as serviceWorker from "./serviceWorker";

import App from "./App";
import SmartLinks from "./components/smartlinks/SmartLinks";
import SmartlinksRouter from "./components/smartlinks/Router";
import ShortlinksRouter from "./components/shortlinks/Router";

import { createStore, applyMiddleware } from "redux";
import { Provider } from "react-redux";
import { composeWithDevTools } from "redux-devtools-extension";
import thunk from "redux-thunk";
import rootReducer from "./store/rootReducer";

import "./App.css";
import { I18nextProvider } from "react-i18next";
import { initReactI18next } from "react-i18next";
import i18next from "i18next";
import LanguageDetector from "i18next-browser-languagedetector";
import common_fr from "./translation/fr.json";
import common_en from "./translation/en.json";

const store = createStore(
  rootReducer,
  composeWithDevTools(applyMiddleware(thunk))
);

const DETECTION_OPTIONS = {
  order: ["navigator"],
  caches: ["localStorage"],
};

i18next
  .use(initReactI18next)
  .use(LanguageDetector)
  .init({
    detection: DETECTION_OPTIONS,
    interpolation: { escapeValue: false },
    resources: {
      fr: {
        common: common_fr,
      },
      en: {
        common: common_en,
      },
    },
    fallbackLng: ["common", "en"],
  });

const shortlink =
  window.location.href.includes("reduc.top") ||
  window.location.href.includes("smyi.fr");

ReactDOM.render(
  <BrowserRouter basename="/">
    <I18nextProvider i18n={i18next}>
      <Provider store={store}>
        {shortlink ? (
          <ShortlinksRouter />
        ) : (
          <Switch>
            <Route path="/influens/display/smartlink">
              <SmartlinksRouter />
            </Route>
            <Route
              path="/influens/display/smartlink/"
              render={(props) => <SmartLinks {...props} />}
            />
            <Route path="/">
              <App />
            </Route>
          </Switch>
        )}
      </Provider>
    </I18nextProvider>
  </BrowserRouter>,
  document.getElementById("root")
);

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister();
