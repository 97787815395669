import React from "react";

const SVG = ({
    height = '50px',
    width = "100%",
    fill = 'black'
}) => (
        <svg version="1.1" id="Layer_1" xmlns="http://www.w3.org/2000/svg" xmlnsXlink="http://www.w3.org/1999/xlink" x="0px" y="0px"
            viewBox="0 0 512 512" style={{enableBackgound: "new 0 0 512 512"}} xmlSpace="preserve" width={width} height={height}>
            <g fill={fill}>
                <g>
                    <g>
                        <path d="M256,409.6c-4.719,0-8.533,3.823-8.533,8.533v22.067l-57.233,57.233c-3.336,3.337-3.336,8.73,0,12.066
               c1.664,1.664,3.849,2.5,6.033,2.5c2.185,0,4.369-0.836,6.033-2.5l59.733-59.733c1.596-1.596,2.5-3.772,2.5-6.033v-25.6
               C264.533,413.423,260.719,409.6,256,409.6z"/>
                        <path d="M503.467,17.067H264.533V8.533C264.533,3.823,260.719,0,256,0s-8.533,3.823-8.533,8.533v8.533H8.533
               C3.814,17.067,0,20.89,0,25.6v34.133c0,4.71,3.814,8.533,8.533,8.533h443.733c4.719,0,8.533-3.823,8.533-8.533
               s-3.814-8.533-8.533-8.533h-435.2V34.133h477.867V51.2H486.4c-4.719,0-8.533,3.823-8.533,8.533v290.133
               c0,14.114-11.486,25.6-25.6,25.6H59.733c-14.114,0-25.6-11.486-25.6-25.6v-256c0-4.71-3.814-8.533-8.533-8.533
               s-8.533,3.823-8.533,8.533v256c0,23.526,19.14,42.667,42.667,42.667h392.533c23.526,0,42.667-19.14,42.667-42.667v-281.6h8.533
               c4.719,0,8.533-3.823,8.533-8.533V25.6C512,20.89,508.186,17.067,503.467,17.067z"/>
                        <path d="M287.633,463.3c-3.337-3.337-8.73-3.337-12.066,0c-3.337,3.336-3.337,8.73,0,12.066L309.7,509.5
               c1.664,1.664,3.849,2.5,6.033,2.5c2.185,0,4.369-0.836,6.033-2.5c3.337-3.336,3.337-8.73,0-12.066L287.633,463.3z"/>
                        <path d="M247.467,102.4c-61.167,0-110.933,49.766-110.933,110.933S186.3,324.267,247.467,324.267
               c4.719,0,8.533-3.823,8.533-8.533v-93.867h93.867c0.068,0.008,0.128,0,0.162,0c4.719,0,8.533-3.823,8.533-8.533
               c0-0.606-0.051-1.203-0.179-1.775C357.436,151.211,308.045,102.4,247.467,102.4z M247.467,204.8
               c-4.719,0-8.533,3.823-8.533,8.533v93.483c-47.778-4.326-85.333-44.595-85.333-93.483c0-51.755,42.112-93.867,93.867-93.867
               c48.879,0,89.156,37.555,93.483,85.333H247.467z"/>
                        <path d="M273.067,247.467v83.797c0,2.517,1.109,4.898,3.021,6.519c1.553,1.314,3.516,2.014,5.513,2.014
               c0.469,0,0.939-0.034,1.417-0.111c46.49-7.825,82.978-44.314,90.803-90.803c0.41-2.475-0.29-5.009-1.903-6.929
               c-1.621-1.92-4.011-3.021-6.519-3.021H281.6C276.881,238.933,273.067,242.756,273.067,247.467z M290.133,256h64.614
               c-8.832,31.258-33.357,55.782-64.614,64.614V256z"/>
                    </g>
                </g>
            </g>
        </svg>
    );

export default SVG;
