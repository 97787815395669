import React, { useState } from 'react';
import { Grid, makeStyles, Typography, CircularProgress, Backdrop } from '@material-ui/core';
import AgencyTable from "../../template/components/AgencyTables/AgencyTable";
import AgencyToValidateTable from "../../template/components/AgencyTables/AgencyToValidateTable";
import AgenceEditModal from "../../template/components/AgenceEditModal";
import { useTranslation } from "react-i18next";


const useStyles = makeStyles((theme) => ({
    brandsContent: {
        flex: '1 1 auto',
        backgroundColor: "#eff9fe",
    },
    brandSelectorBox: {
        width: '300px',
        padding: '20px',
        backgroundColor: '#ffffff',
        margin: '15px',
        boxShadow: '0 7px 30px 0 rgba(88, 165, 211, 0.16)',
        borderRadius: '35px',
        cursor: 'pointer'
    },
    brandSelectorText: {
        fontFamily: 'Poppins',
        fontWeight: 'bold'
    },
    activeBrandCategoryBox: {
        width: '300px',
        padding: '20px',
        backgroundColor: '#3eadef',
        margin: '15px',
        boxShadow: '0 7px 30px 0 rgba(88, 165, 211, 0.16)',
        borderRadius: '35px',
        cursor: 'pointer'
    },
    activeBrandSelectorText: {
        color: '#fff',
        fontFamily: 'Poppins',
        fontWeight: 'bold'
    },
    overlay: {
        zIndex: theme.zIndex.drawer + 1
    },
}));


const Agencies = (props) => {

    const { t } = useTranslation('common');
    const classes = useStyles();
    const [selectedCategory, setSelectedCategory] = useState('agency');
    const [editMode, setEditMode] = useState(false);
    const [agentToEdit, setAgentToEdit] = useState(null);
    const [agentToValidate, setAgentToValidate] = useState(null);
    //const [overlayState, setOverlayState] = useState(false);

    const { filterValue } = props;

    const handleCategory = (categoryType) => {
        setSelectedCategory(categoryType);
    }

    const handleEditAgent = (brand) => {
        setAgentToValidate(null);
        setAgentToEdit(brand);
        setEditMode(true);
    }

    const handleValidateBrandAgent = (agent) => {
        setAgentToEdit(null);
        setAgentToValidate(agent);
        setEditMode(true);
    }

    const handleCloseModal = () => {
        setAgentToValidate(null);
        setAgentToEdit(null);
        setEditMode(false);
    }

    const getTable = (selectedCategory) => {
        switch (selectedCategory) {
            case 'agency':
                return <AgencyTable searchValue={filterValue} openEditAgent={(agent) => handleEditAgent(agent)} />;
            case 'agencyToValidate':
                return <AgencyToValidateTable searchValue={filterValue} openValidateBrandAgent={(agent) => handleValidateBrandAgent(agent)} />;
            default:
                return <></>
        }
    }
    const tableToRender = getTable(selectedCategory);
    return (
        <Grid className={classes.brandsContent}>
            <Backdrop className={classes.overlay} open={false}>
                <CircularProgress color='secondary' />
            </Backdrop>
            <Grid container item direction='row' xs={12} justify='center' alignItems='flex-start' style={{ height: '100%' }}>
                <Grid container item direction='row' xs={11} alignItems='center' justify='flex-start'>
                    <Grid
                        className={(selectedCategory === 'agency') ? classes.activeBrandCategoryBox : classes.brandSelectorBox}
                        container
                        justify='center'
                        onClick={() => handleCategory('agency')}>
                        <Typography className={(selectedCategory === 'agency') ? classes.activeBrandSelectorText : classes.brandSelectorText}>
                            {t('ADMIN.VIEWS.PARTNERS.AGENCES.AGENCE_BOX_TITLE')}
                        </Typography>
                    </Grid>
                    <Grid
                        className={(selectedCategory === 'agencyToValidate') ? classes.activeBrandCategoryBox : classes.brandSelectorBox}
                        container
                        justify='center'
                        onClick={() => handleCategory('agencyToValidate')}>
                        <Typography className={(selectedCategory === 'agencyToValidate') ? classes.activeBrandSelectorText : classes.brandSelectorText}>
                            {t('ADMIN.VIEWS.PARTNERS.AGENCES.AGENCE_TO_VALIDATE_BOX_TITLE')}
                        </Typography>
                    </Grid>
                </Grid>
                {
                    (editMode)
                        ? <AgenceEditModal AgentToEdit={agentToEdit} AgentToValidate={agentToValidate} closeModal={handleCloseModal} />
                        : tableToRender
                }
            </Grid>
        </Grid>
    )
}

export default Agencies;