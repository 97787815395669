import { makeStyles, Grid, Backdrop, CircularProgress, Snackbar, useTheme, useMediaQuery } from '@material-ui/core';
import Alert from '@material-ui/lab/Alert';
import React, { useEffect, useState } from 'react';
import ArticleImage from './ArticleImage';
import { useHistory, useParams } from 'react-router-dom';
import { connect } from 'react-redux';
import { fetchWishlistById, updateWishlist } from '../../../store/actions/wishlistsActions';
import { fetchInfluencerByAffiliateId } from '../../../store/actions/influencersActions';
import { fetchAllBrands } from '../../../store/actions/brandsActions';
import { DualRing } from '../../ui';
import { clicksManager } from '../../managers';
import { useTranslation } from "react-i18next";
import { deleteArticleById } from '../../../store/actions/articlesActions';
import { addShortlink } from '../../../store/actions/shortlinksActions';
import ShortlinkBox from './ShortlinkBox';

const useStyle = makeStyles(theme => ({
    wishlistsContent: {
        width: '100%',
        backgroundColor: "#eff9fe",
        flex: 1,

    },
    embedWishlistContent: {
        minHeight: '100vh',
        width: '100%',
        backgroundColor: "#eff9fe",
    },
    menuButton: {
        marginTop: '15px',
        marginLeft: '15px'
    },
    myMansoryGrid: {
        display: 'flex',
        marginLeft: '-20px',
        width: '100%'
    },
    myMasonryGridColumn: {
        paddingLeft: '30px',
        backgroundClip: 'padding-box',
    },
    loaderContainer: {
        minHeight: '100vh',
        width: '100%',
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center'
    },
    overlay: {
        zIndex: theme.zIndex.drawer + 1
    },
}));

const ProductsList = (props) => {

    const {
        user,
        activeWishlist,
        embed,
        influencer,
        influencers,
        brands,
        wishlists,
        shortlinksPerInfluencer,
        loadedInfluencer,
        loadedWishlist,
        loadedBrands,
        loadedUpdateWishlist,
        loadedAddShortlink,
        wishlistsError,
        influencerError,
        getInfluencerByAffiliateId,
        getWishlistById,
        getAllBrands,
        updateWishlist,
        deleteArticle,
        addShortlink
    } = props;

    const { t } = useTranslation("common");
    const classes = useStyle();
    const theme = useTheme();
    const xsDown = useMediaQuery(theme.breakpoints.down('xs'));

    const {
        affiliateId,
        wishlistId
    } = useParams();

    const history = useHistory();
    const [embedWishlist, setEmbedWishlist] = useState();
    const [embedInfluencer, setEmbedInfluencer] = useState();
    const [loading, setLoading] = useState(true);
    const [overlayState, setOverlayState] = useState(false);
    const [snackBarState, setSnackBarState] = useState(false);
    const [currentShortLink, setCurrentShortlink] = useState('');
    const [articleGeneratingShortlink, setArticleGeneratingShortlink] = useState(undefined);
    const [shortlinkBoxState, setShortlinkBoxState] = useState(true);

    useEffect(() => {
        if (!loadedBrands) getAllBrands();
    }, [loadedBrands, getAllBrands]);

    useEffect(() => {
        if (embed) {
            setLoading(true);
        }
    }, [embed]);

    useEffect(() => {
        if (embed && affiliateId) {
            if (affiliateId) {
                if (!loadedInfluencer) getInfluencerByAffiliateId(affiliateId);
            } else {
                history.push('/home');
            }
        }
    }, [embed, affiliateId, getInfluencerByAffiliateId, loadedInfluencer, history]);

    useEffect(() => {
        if (embed) {
            if (wishlistId) {
                if (!loadedWishlist) getWishlistById(wishlistId);
            } else {
                history.push('/home');
            }
        }
    }, [embed, wishlistId, getWishlistById, loadedWishlist, history]);

    useEffect(() => {
        if (loadedWishlist) {
            if (wishlistsError && wishlistsError.status && wishlistsError.status === 404) {
                history.push('/home');
            } else {
                const wishlist = wishlists.filter(wishlist => wishlist.key === wishlistId)[0];
                setEmbedWishlist(wishlist);
            }
        }
    }, [loadedWishlist, wishlists, wishlistsError, history, wishlistId]);

    useEffect(() => {
        if (loadedInfluencer) {
            if (influencerError && influencerError.status && influencerError.status === 404) {
                history.push('/home');
            } else {
                const influencer = influencers.filter(influencer => influencer.affiliate_id === Number(affiliateId))[0];
                setEmbedInfluencer(influencer);
            }
        }
    }, [loadedInfluencer, influencers, influencerError, history, affiliateId]);

    useEffect(() => {
        setLoading(false);
    }, [embedInfluencer, embedInfluencer]);

    useEffect(() => {
        if (embed) {
            if (embedWishlist) {
                clicksManager.addWishlistClick(embedWishlist.key);
            }
        } else {
            if (activeWishlist) {
                clicksManager.addWishlistClick(activeWishlist.key)
            }
        }
    }, [embed, embedWishlist, activeWishlist]);

    useEffect(() => {
        if (loadedUpdateWishlist) {
            setOverlayState(true);
        } else {
            setOverlayState(false);
        }
    }, [loadedUpdateWishlist]);

    useEffect(() => {
        if (loadedAddShortlink && articleGeneratingShortlink) {
            const addedShortlink = shortlinksPerInfluencer[influencer.affiliate_id].filter(shortlink => shortlink.productId === articleGeneratingShortlink.id)[0];
            setCurrentShortlink(addedShortlink.newUrl);
        }
    }, [loadedAddShortlink, articleGeneratingShortlink, influencer, shortlinksPerInfluencer]);

    useEffect(() => {
        if (currentShortLink !== '') {
            setShortlinkBoxState(true);
            setOverlayState(false);
        } else {
            setShortlinkBoxState(false);
        }
    }, [currentShortLink]);

    const handleClickProduct = (article, offerId, influencer, wishlistId) => {
        if (loadedBrands) {
            setOverlayState(true);
            const brand = brands.filter(brand => brand.offerId === offerId)[0];
            if (brand) {
                if (brand.private) {
                    setSnackBarState(true);
                    setOverlayState(false);
                } else {
                    clicksManager.addClick(influencer.key, article.id, offerId).then(ref => {
                        const link = `${article.tracking_link}&sub6=${ref.data.data.key}&sub7=${wishlistId}`;
                        window.location === window.parent.location ? (window.location = link) : (window.parent.location = link);
                    }).catch(err => {
                        setOverlayState(false);
                    });
                }
            }
        };
    };

    const handleDeleteArticle = (article) => {
        const newWishlistArticles = { ...activeWishlist.articles };
        delete newWishlistArticles[article.id];
        updateWishlist(activeWishlist, { articles: newWishlistArticles });
        deleteArticle(article.id);
    };

    const handleGenerateShortLink = (article) => {
        setOverlayState(true);
        setArticleGeneratingShortlink(undefined);
        const previousShortlink = shortlinksPerInfluencer[influencer.affiliate_id].filter(shortlink => shortlink.productId === article.id)[0];
        if (previousShortlink) {
            setCurrentShortlink(previousShortlink.newUrl);
        } else {
            const date = new Date();
            const newShortlink = {
                createdBy: influencer.key,
                productId: article.id,
                trackingUrl: article.tracking_link,
                timestamp: Math.round(date.getTime() / 1000),
                displayDate: date.toLocaleDateString(),
                newUrl: `https://smyi.me/${influencer.affiliate_id}/`
            };
            addShortlink(newShortlink, influencer.affiliate_id);
            setArticleGeneratingShortlink(article);
        };
    };

    const handleCloseSnackbar = () => {
        setSnackBarState(false);
    };

    const handleCloseShortlinkBox = () => {
        setShortlinkBoxState(false);
        setCurrentShortlink('');
    };

    return (
        <>
            <Backdrop className={classes.overlay} open={overlayState}>
                <CircularProgress color='secondary' />
            </Backdrop>
            <Snackbar open={snackBarState} onClose={handleCloseSnackbar} autoHideDuration={2000}>
                <Alert severity="info" variant='filled' style={{ fontFamily: 'Poppins' }}>
                    {t('WISHLISTS.ALERT_CLICK_PRIVATE_BRAND')}
                </Alert>
            </Snackbar>
            <ShortlinkBox open={shortlinkBoxState} shortlinkUrl={currentShortLink} closeShortlinkBox={handleCloseShortlinkBox}/>
            {
                !embed
                    ? <div className={classes.wishlistsContent} style={(xsDown) ? { overflowX: 'initial', overflowY: 'inherit' } : { overflowX: 'hidden', overflowY: 'auto', }}>
                        <Grid container item direction='row' justify='flex-start' alignItems='flex-start' style={{ padding: '10px' }}>
                            {
                                (activeWishlist && activeWishlist.articles && Object.values(activeWishlist.articles).map(article => (
                                    <ArticleImage
                                        key={article.id}
                                        user={user}
                                        article={article}
                                        handleClickProduct={(product) => handleClickProduct(product, product.offerId, influencer, activeWishlist.key)}
                                        handleDeleteArticle={(article) => handleDeleteArticle(article)}
                                        handleGenerateShortLink={(article) => handleGenerateShortLink(article)} />
                                )))
                            }
                        </Grid>
                    </div>
                    :
                    (loading)
                        ? <div className={classes.loaderContainer}>
                            <DualRing />
                        </div>
                        : <div className={classes.embedWishlistContent}>
                            <Grid container item direction='row' justify='flex-start' alignItems='flex-start' style={{ padding: 10 }}>
                                {/* <Masonry breakpointCols={breakpointColumnsObh} className={classes.myMansoryGrid} columnClassName={classes.myMasonryGridColumn} > */}
                                {
                                    (embedWishlist && embedWishlist.articles && Object.values(embedWishlist.articles).map(article => (
                                        <ArticleImage key={article.id} article={article} handleClickProduct={(product) => handleClickProduct(product, product.offerId, embedInfluencer, embedWishlist.key)} />
                                    )))
                                }
                                {/* </Masonry> */}
                            </Grid>
                        </div>
            }
        </>
    );
};

const mapStateToProps = (state, ownProps) => {
    const influencers = state.influencersState.influencers;
    const wishlists = state.wishlistsState.wishlists;
    const brands = state.brandsState.allBrands;
    const shortlinksPerInfluencer = state.shortlinksState.shortlinksPerInfluencer;
    const loadedWishlist = state.wishlistsState.loadedWishlist;
    const loadedUpdateWishlist = state.wishlistsState.loadedUpdateWishlist;
    const loadedInfluencer = state.influencersState.loadedInfluencer;
    const loadedBrands = state.brandsState.loadedBrands;
    const loadedAddShortlink = state.shortlinksState.loadedAddShortlink;
    const wishlistsError = state.wishlistsState.wishlistsError;
    const influencerError = state.influencersState.influencerError;
    return {
        influencers,
        wishlists,
        brands,
        shortlinksPerInfluencer,
        loadedInfluencer,
        loadedWishlist,
        wishlistsError,
        influencerError,
        loadedBrands,
        loadedUpdateWishlist,
        loadedAddShortlink
    };
};

const mapDispatchToProps = (dispatch) => {
    return {
        getInfluencerByAffiliateId: (affiliateId) => dispatch(fetchInfluencerByAffiliateId(affiliateId)),
        getWishlistById: (wishlistId) => dispatch(fetchWishlistById(wishlistId)),
        getAllBrands: () => dispatch(fetchAllBrands()),
        updateWishlist: (wishlist, updates) => dispatch(updateWishlist(wishlist, updates)),
        deleteArticle: (articleId) => dispatch(deleteArticleById(articleId)),
        addShortlink: (shortlink, influencerAffiliateId) => dispatch(addShortlink(shortlink, influencerAffiliateId))
    }
};

export default connect(mapStateToProps, mapDispatchToProps)(ProductsList);