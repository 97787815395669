import React from "react";

const SVG = ({
    width = "100%",
    height = '50px'
}) => (
        <svg xmlns="http://www.w3.org/2000/svg" width={width} height={height} viewBox="0 0 97 97">
            <g id="shopping_bag-24px" transform="translate(0.045 0.385)">
                <rect id="Rectangle_22" data-name="Rectangle 22" width="97" height="97" transform="translate(-0.045 -0.385)" fill="none" />
                <path id="Tracé_206" data-name="Tracé 206" d="M59.3,17.8H51.4a15.8,15.8,0,0,0-31.6,0H11.9A7.923,7.923,0,0,0,4,25.7V73.1A7.923,7.923,0,0,0,11.9,81H59.3a7.923,7.923,0,0,0,7.9-7.9V25.7A7.923,7.923,0,0,0,59.3,17.8ZM27.7,33.6a3.95,3.95,0,1,1-7.9,0V25.7h7.9ZM35.6,9.9a7.923,7.923,0,0,1,7.9,7.9H27.7A7.923,7.923,0,0,1,35.6,9.9ZM51.4,33.6a3.95,3.95,0,1,1-7.9,0V25.7h7.9Z" transform="translate(12.906 6.618)" fill="#34c1ae" />
            </g>
        </svg>
    );

export default SVG;
