import React, { useState, useEffect } from 'react';
import { connect } from 'react-redux';
import { useHistory, useParams } from 'react-router-dom';
import { fetchInfluencerByCode33, fetchInfluencerByName } from '../../../store/actions/influencersActions';
import { fetchWishlistsPerInfluencer } from '../../../store/actions/wishlistsActions';
import { DualRing } from '../../ui';

const Wishlist = (props) => {

    const {
        name,
        wishlist,
        code33
    } = useParams();

    const {
        url,
        influencers,
        loadedInfluencer,
        influencerError,
        wishlistsPerInfluencer,
        loadedWishlistsPerInfluencer,
        fetchInfluencerByName,
        fetchInfluencerByCode33,
        fetchWishlistsPerInfluencer,
        setLoadInfluencer,
        setLoadWishlistsPerInfluencer
    } = props;

    const history = useHistory();

    const [influencer, setInfluencer] = useState();
    const [influencerWishlists, setInfluencerWishlists] = useState([]);
    const [loadedWishlists, setLoadedWishlists] = useState(false);
    const [redirectUrl, setRedirectUrl] = useState();

    useEffect(() => {
        if (!loadedInfluencer) {
            if (code33) {
                fetchInfluencerByCode33(code33);
            } else if (name) {
                fetchInfluencerByName(name.replace(/_/g, " "));
            }
        } else {
            if (code33) {
                if (influencerError && influencerError.status && influencerError.status === 404) {
                    fetchInfluencerByName(name.replace(/_/g, " "));
                } else {
                    const influencerToView = influencers.filter(influencer => influencer.code33 === code33)[0];
                    setInfluencer(influencerToView);
                }
            } else if (name) {
                const influencerToView = influencers.filter(influencer => influencer.name === name)[0];
                setInfluencer(influencerToView);
            }
        }
    }, [loadedInfluencer, influencerError, code33, name, influencers, fetchInfluencerByCode33, fetchInfluencerByName]);

    useEffect(() => {
        if (loadedInfluencer && influencer) {
            fetchWishlistsPerInfluencer(influencer.key);
        };
    }, [loadedInfluencer, influencer, fetchWishlistsPerInfluencer]);

    useEffect(() => {
        if (loadedWishlistsPerInfluencer && wishlistsPerInfluencer && wishlistsPerInfluencer[influencer.key]) {
            const wishlists = wishlistsPerInfluencer[influencer.key];
            setInfluencerWishlists(wishlists);
            setLoadedWishlists(true);
        }
    }, [loadedWishlistsPerInfluencer, wishlistsPerInfluencer, influencer]);

    useEffect(() => {
        if (loadedWishlists) {
            if (wishlist) {
                const selectedWishlist = influencerWishlists.filter(wish => wish.name.includes(wishlist.replace(/_/g, " ")))[0];
                if (selectedWishlist) {
                    setRedirectUrl(`/influencer${url}/${influencer.affiliate_id}/${selectedWishlist.key}`)
                } else {
                    setRedirectUrl(`/influencer${url}/${influencer.affiliate_id}`)
                }
            } else {
                setRedirectUrl(`/influencer${url}/${influencer.affiliate_id}`)
            }
        }
    }, [loadedWishlists, wishlist, influencerWishlists, influencer, url]);

    useEffect(() => {
        if (redirectUrl) {
            setLoadInfluencer();
            setLoadWishlistsPerInfluencer();
            history.push(redirectUrl);
        }
    }, [redirectUrl, history, setLoadInfluencer, setLoadWishlistsPerInfluencer])

    return (
        <div style={{ minHeight: '100vh', width: '100%', display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
            <DualRing />
        </div>
    );
};

const mapStateToProps = (state, ownProps) => {
    const influencers = state.influencersState.influencers;
    const loadedInfluencer = state.influencersState.loadedInfluencer;
    const influencerError = state.influencersState.influencersError;
    const wishlistsPerInfluencer = state.wishlistsState.wishlistsPerInfluencer;
    const loadedWishlistsPerInfluencer = state.wishlistsState.loadedWishlistsPerInfluencer;
    return {
        influencers,
        loadedInfluencer,
        wishlistsPerInfluencer,
        loadedWishlistsPerInfluencer,
        influencerError
    }
};

const mapDispatchToProps = (dispatch) => {
    return {
        fetchInfluencerByName: (name) => dispatch(fetchInfluencerByName(name)),
        fetchInfluencerByCode33: (code33) => dispatch(fetchInfluencerByCode33(code33)),
        fetchWishlistsPerInfluencer: (influencerId) => dispatch(fetchWishlistsPerInfluencer(influencerId)),
        setLoadInfluencer: () => dispatch({ type: 'SET_LOAD_INFLUENCER' }),
        setLoadWishlistsPerInfluencer: () => dispatch({ type: 'SET_LOAD_WISHLISTS_PER_INFLUENCER' })
    };
}

export default connect(mapStateToProps, mapDispatchToProps)(Wishlist);