import React, { useLayoutEffect, useEffect, useState } from 'react';
import * as am4core from "@amcharts/amcharts4/core";
import * as am4charts from "@amcharts/amcharts4/charts";
import am4themes_animated from "@amcharts/amcharts4/themes/animated";
import { clicksManager } from '../../../managers';
import { useTranslation } from "react-i18next";

am4core.useTheme(am4themes_animated);

const ProductPieChart = (props) => {
    const { t } = useTranslation('common');

    const productsInfos = props.articlesInfos;

    const [period, setPeriod] = useState(null);

    const salesNumberString = t('TREND.CHARTS.PIE_CHART.SALES_NUMBER');
    const clicksNumberString = t('TREND.CHARTS.PIE_CHART.CLICKS_NUMBER');

    useEffect(() => {
        if (props.startPeriodDate && props.endPeriodDate) {
            setPeriod({
                start: props.startPeriodDate,
                end: props.endPeriodDate
            })
        }
    }, [props.startPeriodDate, props.endPeriodDate])


    //TODO:  Find another solution for saving products in database !!!
    // Redondance à éviter
    const repareProducts = (products) => {
        let newProducts = [];
        products.forEach((prod, i) => {
            if (prod) {
                if (i === 0) {
                    newProducts.push(prod);
                } else {
                    let incremented = false;
                    for (let j = 0; j < newProducts.length; j++) {
                        if (newProducts[j].articleImgUrl.localeCompare(prod.articleImgUrl) === 0) {
                            newProducts[j].purchaseNumber += prod.purchaseNumber;
                            incremented = true;
                            break;
                        }
                    }
                    if (!incremented) {
                        newProducts.push(prod);
                    }
                }
            }
        });
        return newProducts;
    }

    useLayoutEffect(() => {

        var chart = am4core.create("piediv", am4charts.PieChart);

        let data = [];
        if (productsInfos) {
            const reparedProducts = repareProducts(productsInfos);
            reparedProducts.forEach(product => {
                data.push({
                    "ID": product.articleId,
                    "purchaseNumber": product.salesNumber,
                    "articleImgUrl": product.articleImgUrl,
                    "articlePathUrl": product.articlePathUrl
                });
            });

            chart.data = data;

            var pieSeries = chart.series.push(new am4charts.PieSeries());
            pieSeries.dataFields.value = "purchaseNumber";
            pieSeries.dataFields.category = "ID";

            pieSeries.labels.template.disabled = true;
            pieSeries.ticks.template.disabled = true;

            pieSeries.slices.template.tooltipText = "";
            pieSeries.slices.template.alwaysShowTooltip = true;
            pieSeries.tooltip.interactionsEnabled = true;
            pieSeries.tooltip.keepTargetHover = true;

            let currentSlice;

            pieSeries.slices.template.events.on("hit", function (ev) {
                var series = ev.target.dataItem.component;
                currentSlice = ev.target;
                series.slices.each(function (item) {
                    item.tooltip.hide();
                    if (item.isActive && item !== ev.target) {
                        item.isActive = false;
                    }
                    if (item.isActive && item === ev.target) {
                        let clicks = 0
                        // let product = {};
                        let date = new Date();
                        const getClicksPerProduct = async (start, end) => {
                            start = start || 0;
                            end = end || date.getTime();
                            await clicksManager.getClicksPerProduct(start, end, currentSlice.dataItem.dataContext.ID).then((res) => {
                                clicks = res.data.clicksNumber
                            })
                            // await dbManager.fetchSoldArticleById(currentSlice.dataItem.dataContext.ID).then((res) => product = res);
                        }
                        currentSlice.tooltipHTML = `
                        <svg xmlns="http://www.w3.org/2000/svg" xmlnsXlink="http://www.w3.org/1999/xlink" style="margin: auto; background: #00000000; display: block; shape-rendering: auto;" width=120 height=120 viewBox="0 0 100 100" preserveAspectRatio="xMidYMid">
                            <g transform="rotate(0 50 50)">
                                <rect x="47" y="24" rx="3" ry="6" width="6" height="12" fill="#000000">
                                    <animate attributeName="opacity" values="1;0" keyTimes="0;1" dur="1s" begin="-0.9166666666666666s" repeatCount="indefinite"></animate>
                                </rect>
                            </g><g transform="rotate(30 50 50)">
                                <rect x="47" y="24" rx="3" ry="6" width="6" height="12" fill="#000000">
                                    <animate attributeName="opacity" values="1;0" keyTimes="0;1" dur="1s" begin="-0.8333333333333334s" repeatCount="indefinite"></animate>
                                </rect>
                            </g><g transform="rotate(60 50 50)">
                                <rect x="47" y="24" rx="3" ry="6" width="6" height="12" fill="#000000">
                                    <animate attributeName="opacity" values="1;0" keyTimes="0;1" dur="1s" begin="-0.75s" repeatCount="indefinite"></animate>
                                </rect>
                            </g><g transform="rotate(90 50 50)">
                                <rect x="47" y="24" rx="3" ry="6" width="6" height="12" fill="#000000">
                                    <animate attributeName="opacity" values="1;0" keyTimes="0;1" dur="1s" begin="-0.6666666666666666s" repeatCount="indefinite"></animate>
                                </rect>
                            </g><g transform="rotate(120 50 50)">
                                <rect x="47" y="24" rx="3" ry="6" width="6" height="12" fill="#000000">
                                    <animate attributeName="opacity" values="1;0" keyTimes="0;1" dur="1s" begin="-0.5833333333333334s" repeatCount="indefinite"></animate>
                                </rect>
                            </g><g transform="rotate(150 50 50)">
                                <rect x="47" y="24" rx="3" ry="6" width="6" height="12" fill="#000000">
                                    <animate attributeName="opacity" values="1;0" keyTimes="0;1" dur="1s" begin="-0.5s" repeatCount="indefinite"></animate>
                                </rect>
                            </g><g transform="rotate(180 50 50)">
                                <rect x="47" y="24" rx="3" ry="6" width="6" height="12" fill="#000000">
                                    <animate attributeName="opacity" values="1;0" keyTimes="0;1" dur="1s" begin="-0.4166666666666667s" repeatCount="indefinite"></animate>
                                </rect>
                            </g><g transform="rotate(210 50 50)">
                                <rect x="47" y="24" rx="3" ry="6" width="6" height="12" fill="#000000">
                                    <animate attributeName="opacity" values="1;0" keyTimes="0;1" dur="1s" begin="-0.3333333333333333s" repeatCount="indefinite"></animate>
                                </rect>
                            </g><g transform="rotate(240 50 50)">
                                <rect x="47" y="24" rx="3" ry="6" width="6" height="12" fill="#000000">
                                    <animate attributeName="opacity" values="1;0" keyTimes="0;1" dur="1s" begin="-0.25s" repeatCount="indefinite"></animate>
                                </rect>
                            </g><g transform="rotate(270 50 50)">
                                <rect x="47" y="24" rx="3" ry="6" width="6" height="12" fill="#000000">
                                    <animate attributeName="opacity" values="1;0" keyTimes="0;1" dur="1s" begin="-0.16666666666666666s" repeatCount="indefinite"></animate>
                                </rect>
                            </g><g transform="rotate(300 50 50)">
                                <rect x="47" y="24" rx="3" ry="6" width="6" height="12" fill="#000000">
                                    <animate attributeName="opacity" values="1;0" keyTimes="0;1" dur="1s" begin="-0.08333333333333333s" repeatCount="indefinite"></animate>
                                </rect>
                            </g><g transform="rotate(330 50 50)">
                                <rect x="47" y="24" rx="3" ry="6" width="6" height="12" fill="#000000">
                                    <animate attributeName="opacity" values="1;0" keyTimes="0;1" dur="1s" begin="0s" repeatCount="indefinite"></animate>
                                </rect>
                            </g>
                        </svg>`;
                        currentSlice.showTooltip();
                        if (period !== null) {
                            getClicksPerProduct(period.start.getTime(), period.end.getTime()).then(() => {
                                currentSlice.tooltipHTML = `
                            <div style='background-color: "white" !important'>
                                <div style='display: flex; justify-content: center; pointer-events: auto !important'>
                                    <a href=${currentSlice.dataItem.dataContext.articlePathUrl} target="_blank">
                                        <img src=${(currentSlice.dataItem.dataContext.articleImgUrl) ? currentSlice.dataItem.dataContext.articleImgUrl : ''} width='96' height='96' style='object-fit: contain'/>
                                    </a>
                                </div>
                                <p>${salesNumberString} : ${currentSlice.dataItem.dataContext.purchaseNumber}</p>
                                <p>${clicksNumberString} : ${clicks}<p>
                            </div>`;
                                currentSlice.showTooltip();
                            });
                        } else {
                            getClicksPerProduct().then(() => {
                                currentSlice.tooltipHTML = `
                            <div style='background-color: "white" !important'>
                                <div style='display: flex; justify-content: center; pointer-events: auto !important'>
                                    <a href=${currentSlice.dataItem.dataContext.articlePathUrl} target="_blank">
                                        <img src=${(currentSlice.dataItem.dataContext.articleImgUrl) ? currentSlice.dataItem.dataContext.articleImgUrl : ''} width='96' height='96' style='object-fit: contain'/>
                                    </a>
                                </div>
                                <p>${salesNumberString} : ${currentSlice.dataItem.dataContext.purchaseNumber}</p>
                                <p>${clicksNumberString} : ${clicks}<p>
                            </div>`;
                                currentSlice.showTooltip();
                            });
                        }


                    }
                })
            });
        }
        // <div style='display: flex; justify-content: center'>
        //     <a target='_blank' href=${currentSlice.dataItem.dataContext.url}>Voir Produit</a
        // </div>

        return () => {
            chart.dispose();
        };

    }, [productsInfos, period, clicksNumberString, salesNumberString]);

    return (
        <div id="piediv" style={{ width: "95%", height: "290px" }}></div>
    );
};

export default ProductPieChart;