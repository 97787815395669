import React from "react";
const SVG = ({ height = "50px", width = "100%" }) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width={width}
    height={height}
    viewBox="0 0 79 79"
  >
    <g id="Outline" transform="translate(-15.999 -15.999)">
      <path
        id="Tracé_207"
        data-name="Tracé 207"
        d="M218.487,208v2.883h-1.442a5.046,5.046,0,1,0,0,10.092h5.767a2.162,2.162,0,1,1,0,4.325h-9.371v2.883h5.046v2.883h2.883v-2.883h1.442a5.046,5.046,0,1,0,0-10.092h-5.767a2.162,2.162,0,0,1,0-4.325h9.371v-2.883h-5.046V208Z"
        transform="translate(-161.623 -161.491)"
        fill="#b93488"
      />
      <path
        id="Tracé_208"
        data-name="Tracé 208"
        d="M113.255,145.016H100.575l-1.409-1.922V125.118a19.311,19.311,0,0,0-13.742-18.629l2.416-8.651a1.469,1.469,0,0,0-.23-1.264A1.4,1.4,0,0,0,86.485,96H78.031v2.883h6.587l-2.013,7.208H78.031v2.883h5.493a16.407,16.407,0,0,1,12.823,16.143v18.457a1.467,1.467,0,0,0,.145.631,20.221,20.221,0,0,0-19.319.811H66.76a5.6,5.6,0,0,0-4.475,2.265,5.88,5.88,0,0,0-.967,5.012l-5.609-2.629a2.9,2.9,0,0,1,.343-.611l3.382-4.613a1.464,1.464,0,0,0,.282-.865V127.716H56.9v15.378l-3.1,4.229a5.8,5.8,0,0,0-.649,1.14l-4.641-2.175a5.946,5.946,0,0,0-7.8,2.673,6.808,6.808,0,0,0-.722,3.235,6.138,6.138,0,0,0,3.324,5.5l16.429,8.405a18.075,18.075,0,0,0,8.19,1.978h45.323a1.426,1.426,0,0,0,1.409-1.442V146.458A1.426,1.426,0,0,0,113.255,145.016ZM96.348,165.2H67.933A15.3,15.3,0,0,1,61,163.526l-16.428-8.4a3.263,3.263,0,0,1-1.767-2.926,4.055,4.055,0,0,1,.385-1.867,3.151,3.151,0,0,1,4.141-1.419l16.022,7.509a1.382,1.382,0,0,0,.586.132h15.5v-2.883H66.76a2.884,2.884,0,0,1,0-5.767h10.8a1.385,1.385,0,0,0,.725-.206l.132-.081a17.467,17.467,0,0,1,17.132-.673l.8.409Zm15.5,0H99.166V147.9h12.681Z"
        transform="translate(-19.665 -73.083)"
        fill="#b93488"
      />
      <circle
        id="Ellipse_11"
        data-name="Ellipse 11"
        cx="3"
        cy="3"
        r="3"
        transform="translate(82.266 83.499)"
        fill="#b93488"
      />
      <path
        id="Tracé_209"
        data-name="Tracé 209"
        d="M26.124,55.411a1.442,1.442,0,0,0,1.846,1.066L33,54.874l3.557,3.9a1.442,1.442,0,0,0,2.131,0l3.557-3.9,5.032,1.6a1.442,1.442,0,0,0,1.846-1.066l1.128-5.159,5.159-1.128a1.442,1.442,0,0,0,1.066-1.846l-1.6-5.032,3.9-3.557a1.442,1.442,0,0,0,0-2.131L54.875,33l1.6-5.032a1.442,1.442,0,0,0-1.066-1.846L50.252,25l-1.128-5.158a1.442,1.442,0,0,0-1.846-1.066l-5.032,1.6-3.557-3.9a1.442,1.442,0,0,0-2.131,0L33,20.374l-5.032-1.6a1.442,1.442,0,0,0-1.846,1.066L25,25l-5.158,1.128a1.442,1.442,0,0,0-1.066,1.846L20.375,33l-3.9,3.557a1.442,1.442,0,0,0,0,2.131l3.9,3.557-1.6,5.032a1.442,1.442,0,0,0,1.066,1.846L25,50.253ZM23,40.738l-3.417-3.113L23,34.511a1.442,1.442,0,0,0,.4-1.5L22,28.6l4.516-.988a1.442,1.442,0,0,0,1.1-1.1L28.6,22l4.4,1.4a1.443,1.443,0,0,0,1.5-.4l3.113-3.417L40.738,23a1.442,1.442,0,0,0,1.5.4l4.4-1.4.988,4.516a1.442,1.442,0,0,0,1.1,1.1l4.516.988-1.4,4.4a1.442,1.442,0,0,0,.4,1.5l3.417,3.113L52.25,40.738a1.442,1.442,0,0,0-.4,1.5l1.4,4.4-4.516.988a1.442,1.442,0,0,0-1.1,1.1l-.988,4.516-4.4-1.4a1.442,1.442,0,0,0-1.5.4l-3.113,3.417L34.511,52.25a1.442,1.442,0,0,0-1.5-.4l-4.4,1.4-.988-4.516a1.442,1.442,0,0,0-1.1-1.1L22,46.646l1.4-4.4A1.442,1.442,0,0,0,23,40.738Z"
        fill="#b93488"
      />
      <rect
        id="Rectangle_23"
        data-name="Rectangle 23"
        width="20.388"
        height="2.883"
        transform="translate(29.036 42.379) rotate(-45)"
        fill="#b93488"
      />
      <path
        id="Tracé_210"
        data-name="Tracé 210"
        d="M84.325,88.65A4.325,4.325,0,1,0,80,84.325,4.325,4.325,0,0,0,84.325,88.65Zm0-5.767a1.442,1.442,0,1,1-1.442,1.442A1.442,1.442,0,0,1,84.325,82.883Z"
        transform="translate(-52.467 -52.467)"
        fill="#b93488"
      />
      <path
        id="Tracé_211"
        data-name="Tracé 211"
        d="M152,140.325A4.325,4.325,0,1,0,156.325,136,4.325,4.325,0,0,0,152,140.325Zm5.767,0a1.442,1.442,0,1,1-1.442-1.442,1.442,1.442,0,0,1,1.442,1.442Z"
        transform="translate(-112.081 -100.459)"
        fill="#b93488"
      />
    </g>
  </svg>
);

export default SVG;
