import React from 'react';
import { Button, Grid, makeStyles, Typography } from '@material-ui/core';
import backgroundImage from './background.png';
import unauthorizedImage from './unauthorized.png';
import { useHistory } from 'react-router-dom';
import { useTranslation } from "react-i18next";
import { auth } from '../../managers';

const useStyle = makeStyles(theme => ({
    unauthorizedContent: {
        minHeight: '100vh',
        minWidth: '100vw',
        backgroundImage: `url(${backgroundImage})`,
        boxShadow: 'inset 0 0 0 100vh rgba(236, 247, 253, 0.5)'
    },
    errorBox: {
        backgroundColor: '#fff',
        borderRadius: '40px',
        minHeight: '400px',
        boxShadow: '0 7px 30px 0 rgba(88, 165, 211, 0.16)',
    },
    errorText: {
        fontFamily: 'Poppins',
        fontSize: '18px',
        textAlign: 'center'
    },
    connectButton: {
        backgroundColor: '#a7569d',
        width: '200px',
        color: '#fff',
        textTransform: 'none',
        margin: '10px',
        fontFamily: 'Poppins',
        borderRadius: '27px',
        minHeight: '60px',
        paddingRight: '15px',
        paddingLeft: '15px',
        "&:hover": {
            backgroundColor: '#a7569d',
            color: '#fff',
        }
    },
    returnButton: {
        width: '200px',
        margin: '10px',
        color: '#a7569d',
        textTransform: 'none',
        fontFamily: 'Poppins',
        border: 'solid #a7569d 1px',
        borderRadius: '27px',
        minHeight: '60px',
        paddingRight: '15px',
        paddingLeft: '15px',
        "&:hover": {
            color: '#a7569d',
        }
    }
}));

const Unauthorized = (props) => {

    const { t } = useTranslation('common');
    const classes = useStyle();
    const history = useHistory();

    const goToHome = () => {
        history.push('/');
    };

    const goToLogin = () => {
        auth.signOut();
    };

    return (
        <Grid container item justify='center' alignItems='center' className={classes.unauthorizedContent}>
            <Grid container item justify='center' lg={7} md={8} xs={10} direction='row'>
                <Grid container item justify='space-around' alignItems='center' className={classes.errorBox} direction='column'>
                    <Grid container item direction='row' justify='center' alignItems='center' xs={9}>
                        <img src={unauthorizedImage} alt='unauthozied error img' width={'60%'} />
                    </Grid>
                    <Grid container item direction='row' justify='center' alignItems='center' xs={9} >
                        <Typography className={classes.errorText}>{t('ERROR_PAGES.UNAUTHORIZED_ERROR.ERROR_MESSAGE')}</Typography>
                    </Grid>
                    <Grid container item direction='row' justify='center' alignItems='center' xs={9} style={{ marginTop: '30px', marginBottom: '30px' }}>
                        <Grid container item direction='row' justify='center' alignItems='center' xs={12} md={6}>
                            <Button className={classes.connectButton} onClick={goToLogin}>{t('ERROR_PAGES.UNAUTHORIZED_ERROR.CHANGE_ACCOUNT_BUTTON')}</Button>
                        </Grid>
                        <Grid container item direction='row' justify='center' alignItems='center' xs={12} md={6}>
                            <Button className={classes.returnButton} onClick={goToHome}>{t('ERROR_PAGES.UNAUTHORIZED_ERROR.RETURN_HOME_BUTTON')}</Button>
                        </Grid>
                    </Grid>
                </Grid>
            </Grid>
        </Grid>
    );
};

export default Unauthorized;