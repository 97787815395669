import React, { useState, useEffect } from 'react';
import { connect } from 'react-redux';
import { useHistory, useParams } from 'react-router-dom';
import { fetchInfluencerById } from '../../../store/actions/influencersActions';
import { fetchWishlistsPerInfluencer } from '../../../store/actions/wishlistsActions';
import { DualRing } from '../../ui';

const Embed = (props) => {

    const {
        uid,
        wishlist
    } = useParams();

    const {
        url,
        influencers,
        loadedInfluencer,
        // influencerError,
        wishlistsPerInfluencer,
        loadedWishlistsPerInfluencer,
        fetchInfluencerById,
        fetchWishlistsPerInfluencer
    } = props;

    const history = useHistory();

    const [influencer, setInfluencer] = useState();
    const [influencerWishlists, setInfluencerWishlists] = useState([]);
    const [loadedWishlists, setLoadedWishlists] = useState(false);
    const [wishlistId, setWishlistId] = useState();
    const [redirectUrl, setRedirectUrl] = useState();

    useEffect(() => {
        if (uid) fetchInfluencerById(uid);
    }, [uid, fetchInfluencerById]);

    useEffect(() => {
        if (loadedInfluencer) {
            const inf = influencers.filter(infl => infl.key === uid)[0];
            setInfluencer(inf);
        }
    }, [loadedInfluencer, influencers, uid]);
    
    useEffect(() => {
        if (loadedInfluencer && influencer) {
            setLoadedWishlists(false);
            fetchWishlistsPerInfluencer(influencer.key);
        }
    }, [loadedInfluencer, influencer, fetchWishlistsPerInfluencer]);

    useEffect(() => {
        if (loadedWishlistsPerInfluencer) {
            const wishlists = wishlistsPerInfluencer[influencer.key]
            setInfluencerWishlists(wishlists);
            setLoadedWishlists(true);
        }
    }, [loadedWishlistsPerInfluencer, wishlistsPerInfluencer, influencer]);

    useEffect(() => {
        if (loadedWishlists) {
            let wishlistIdToView = influencerWishlists.filter(wish => wish.name.includes(wishlist.replace(/_/g, " ")))[0];
            if (wishlistIdToView) {
                setWishlistId(wishlistIdToView.key);
            } else {
                history.push('/home');
            }
        }
    }, [loadedWishlists, influencerWishlists, history, wishlist])

    useEffect(() => {
        if (influencer && loadedInfluencer && wishlistId) {
            setRedirectUrl(`/influencer${url}/wishlist/${influencer.affiliate_id}/${wishlistId}`)
        }
    }, [influencer, loadedInfluencer, url, wishlistId]);


    useEffect(() => {
        if (redirectUrl) {
            history.push(redirectUrl);
        }
    }, [redirectUrl, history])

    return (
        <div style={{ minHeight: '100vh', width: '100%', display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
            <DualRing />
        </div>
    );
};

const mapStateToProps = (state, ownProps) => {
    const influencers = state.influencersState.influencers;
    const loadedInfluencer = state.influencersState.loadedInfluencer;
    const influencerError = state.influencersState.influencersError;
    const wishlistsPerInfluencer = state.wishlistsState.wishlistsPerInfluencer;
    const loadedWishlistsPerInfluencer = state.wishlistsState.loadedWishlistsPerInfluencer;
    return {
        influencers,
        loadedInfluencer,
        influencerError,
        wishlistsPerInfluencer,
        loadedWishlistsPerInfluencer
    }
};

const mapDispatchToProps = (dispatch) => {
    return {
        fetchInfluencerById: (influencerId) => dispatch(fetchInfluencerById(influencerId)),
        fetchWishlistsPerInfluencer: (influencerId) => dispatch(fetchWishlistsPerInfluencer(influencerId)),
    };
};

export default connect(mapStateToProps, mapDispatchToProps)(Embed);