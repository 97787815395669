import React, { useState, useEffect, useCallback } from "react";
import { connect } from "react-redux";
import { makeStyles } from "@material-ui/styles";
import {
  Grid,
  InputBase,
  FormHelperText,
  FormControl,
  Typography,
  IconButton,
} from "@material-ui/core";
import * as utils from "../../../utils";
import { useTranslation } from "react-i18next";
import VisibilityIcon from "@material-ui/icons/Visibility";
import VisibilityOffIcon from "@material-ui/icons/VisibilityOff";
import publicIp from 'public-ip';
import ipLocation from 'iplocation';

const useStyle = makeStyles(() => ({
  inputField: {
    fontFamily: "Poppins",
    borderRadius: "35px",
    boxShadow: "0 7px 30px 0 rgba(69, 128, 163, 0.16)",
    height: "50px",
    margin: "10px",
    paddingTop: "10px",
    paddingBottom: "10px",
    paddingLeft: "20px",
  },
  passwordField: {
    borderRadius: "35px",
    boxShadow: "0 7px 30px 0 rgba(69, 128, 163, 0.16)",
    margin: 10,
    display: "flex",
    flexDirection: "row",
    alignItems: "center",
  },
  inputPasswordField: {
    fontFamily: "Poppins",
    paddingTop: "10px",
    paddingBottom: "10px",
    paddingLeft: "20px",
    flexGrow: 1,
  },
  stepTitle: {
    fontFamily: "Poppins",
    margin: "10px",
    fontSize: "18px",
  },
  errorMessage: {
    fontFamily: "Poppins",
    color: "red",
  },
}));

const SteptwoInfluencer = (props) => {
  const { t } = useTranslation("common");
  const classes = useStyle();

  const { registrationResponseErrors } = props;

  const [initialValues, setInitialValues] = useState({});

  const [prenomInputValue, setPrenomInputValue] = useState(
    initialValues.prenom !== undefined ? initialValues.prenom : ""
  );
  const [nomInputValue, setNomInputValue] = useState("");
  const [usernameInputValue, setUsernameInputValue] = useState("");
  const [emailInputValue, setEmailInputValue] = useState("");
  const [passwordInputValue, setPasswordInputValue] = useState("");
  const [passwordConfirmInputValue, setPasswordConfirmInputValue] = useState(
    ""
  );

  const [validationError, setValidationError] = useState(false);
  const [prenomValidationError, setPrenomValidationError] = useState("");
  const [nomValidationError, setNomValidationError] = useState("");
  const [usernameValidationError, setUsernameValidationError] = useState("");
  const [emailValidationError, setEmailValidationError] = useState("");
  const [passwordValidationError, setPasswordValidationError] = useState("");
  const [
    passwordConfirmValidationError,
    setPasswordConfirmValidationError,
  ] = useState("");
  const [errors, setErrors] = useState();
  const [showPassword, setShowPassword] = useState(false);
  const [ip, setIp] = useState(null);
  const [information, setInformation] = useState(null);
  const [countryCode, setCountryCode] = useState(null);

  // const [checkValid, setCheckValid] = useState(false);

  const stepValues = props.stepValues;
  const validStep = props.validStep;
  const checkValid = props.checkValid;

  const handleValueChange = (id) => (event) => {
    if (id === "prenom") {
      setPrenomInputValue(event.target.value);
    }
    if (id === "nom") {
      setNomInputValue(event.target.value);
    }
    if (id === "username") {
      setUsernameInputValue(event.target.value);
    }
    if (id === "email") {
      setEmailInputValue(event.target.value);
    }
    if (id === "password") {
      setPasswordInputValue(event.target.value);
    }
    if (id === "passwordConfirm") {
      setPasswordConfirmInputValue(event.target.value);
    }
  };

  const adressIP = useCallback(async () => {
    return await publicIp.v4();
  }, []);

  useEffect(() => {
    adressIP().then((value) => {
      setIp(value);
    })
  }, [adressIP])

  const InformationAboutIpAdress = useCallback(async () => {
    if (ip === null) return null;
    return ipLocation(ip);
  }, [ip]);

  useEffect(() => {
    InformationAboutIpAdress().then((value) => {
      setInformation(value);
    })
  }, [InformationAboutIpAdress]);

  useEffect(() => {
    if (information === null) {
    } else {
      setCountryCode(information.country.code);
    }
  }, [information]);

  const handleSubmit = (event) => {
    event.preventDefault();
  };

  const isValid = useCallback(() => {
    let valid = true;
    resetErrors();
    setValidationError(false);
    if (prenomInputValue === "") {
      valid = false;
      setValidationError(true);
      setPrenomValidationError(
        "* " +
        t("REGISTER.STEP_TWO.INFLUENCER.VALIDATION_ERRORS.REQUIRED_MESSAGE")
      );
    }
    if (nomInputValue === "") {
      valid = false;
      setValidationError(true);
      setNomValidationError(
        "* " +
        t("REGISTER.STEP_TWO.INFLUENCER.VALIDATION_ERRORS.REQUIRED_MESSAGE")
      );
    }
    if (usernameInputValue === "") {
      valid = false;
      setValidationError(true);
      setUsernameValidationError(
        "* " +
        t("REGISTER.STEP_TWO.INFLUENCER.VALIDATION_ERRORS.REQUIRED_MESSAGE")
      );
    }
    if (emailInputValue === "") {
      valid = false;
      setValidationError(true);
      setEmailValidationError(
        "* " +
        t("REGISTER.STEP_TWO.INFLUENCER.VALIDATION_ERRORS.REQUIRED_MESSAGE")
      );
    }
    if (emailInputValue !== "" && !utils.emailValidation(emailInputValue)) {
      valid = false;
      setValidationError(true);
      setEmailValidationError(
        "* " +
        t(
          "REGISTER.STEP_TWO.INFLUENCER.VALIDATION_ERRORS.INVALID_EMAIL_MESSAGE"
        )
      );
    }
    if (passwordInputValue.length < 6) {
      valid = false;
      setValidationError(true);
      setPasswordValidationError(
        "* " +
        t(
          "REGISTER.STEP_TWO.INFLUENCER.VALIDATION_ERRORS.INVALID_PASSWORD_LENGTH_MESSAGE"
        )
      );
    }
    if (passwordConfirmInputValue !== passwordInputValue) {
      valid = false;
      setValidationError(true);
      setPasswordConfirmValidationError(
        "* " +
        t(
          "REGISTER.STEP_TWO.INFLUENCER.VALIDATION_ERRORS.NON_IDENTICAL_PASSWORDS_MESSAGE"
        )
      );
    }

    return valid;
  }, [
    emailInputValue,
    nomInputValue,
    prenomInputValue,
    passwordConfirmInputValue,
    passwordInputValue,
    t,
    usernameInputValue,
  ]);

  const resetErrors = () => {
    setPrenomValidationError();
    setNomValidationError();
    setUsernameValidationError();
    setEmailValidationError();
    setPasswordConfirmValidationError();
    setPasswordValidationError();
  };

  const togglePasswordVisibility = () => {
    setShowPassword(!showPassword);
  };

  useEffect(() => {
    if (initialValues.prenom !== undefined)
      setPrenomInputValue(initialValues.prenom);
    if (initialValues.nom !== undefined) setNomInputValue(initialValues.nom);
    if (initialValues.username !== undefined)
      setUsernameInputValue(initialValues.username);
    if (initialValues.email !== undefined)
      setEmailInputValue(initialValues.email);
    if (initialValues.password !== undefined)
      setPasswordInputValue(initialValues.password);
    if (initialValues.passwordConfirm !== undefined)
      setPasswordConfirmInputValue(initialValues.passwordConfirm);
  }, [initialValues]);

  useEffect(() => {
    if (props.valuesToPopulate !== undefined) {
      setInitialValues(props.valuesToPopulate);
    }
  }, [props.valuesToPopulate]);

  useEffect(() => {
    stepValues({
      prenom: prenomInputValue,
      nom: nomInputValue,
      username: usernameInputValue,
      email: emailInputValue,
      password: passwordInputValue,
      passwordConfirm: passwordConfirmInputValue,
      country: countryCode,
    });
  }, [
    prenomInputValue,
    nomInputValue,
    usernameInputValue,
    emailInputValue,
    passwordInputValue,
    passwordConfirmInputValue,
    countryCode,
    stepValues,
  ]);

  useEffect(() => {
    if (registrationResponseErrors !== null) {
      setErrors(registrationResponseErrors);
    }
  }, [registrationResponseErrors]);

  useEffect(() => {
    if (errors) {
      setValidationError(true);
      if (errors.code === "auth/invalid-email") {
        setEmailValidationError(
          "* " + t("REGISTER.RESPONSE_ERRORS.INVALID_EMAIL")
        );
      } else if (errors.code === "auth/email-already-in-use") {
        setEmailValidationError(
          "* " + t("REGISTER.RESPONSE_ERRORS.EMAIL_ALREADY_IN_USE")
        );
      }
    }
  }, [errors, t]);

  useEffect(() => {
    if (checkValid) {
      if (isValid()) {
        validStep(true);
      } else validStep(false);
    }
  }, [checkValid, isValid, validStep]);

  return (
    <form onSubmit={handleSubmit}>
      <Grid container item direction="row" xs={12}>
        <Typography className={classes.stepTitle}>
          {t("REGISTER.STEP_TWO.STEP_TITLE")}
        </Typography>
      </Grid>
      <Grid
        container
        item
        direction="row"
        justify="center"
        alignItems="center"
        xs={12}
      >
        <FormControl style={{ width: "100%" }}>
          <InputBase
            id="prenom"
            placeholder={t("REGISTER.STEP_TWO.INFLUENCER.SURNAME_PLACEHOLDER")}
            type="text"
            className={classes.inputField}
            value={prenomInputValue}
            onChange={handleValueChange("prenom")}
          />
          {validationError && (
            <FormHelperText className={classes.errorMessage}>
              {prenomValidationError}
            </FormHelperText>
          )}
        </FormControl>
        <FormControl style={{ width: "100%" }}>
          <InputBase
            id="nom"
            placeholder={t("REGISTER.STEP_TWO.INFLUENCER.NAME_PLACEHOLDER")}
            type="text"
            className={classes.inputField}
            value={nomInputValue}
            required
            onChange={handleValueChange("nom")}
          />
          {validationError && (
            <FormHelperText className={classes.errorMessage}>
              {nomValidationError}
            </FormHelperText>
          )}
        </FormControl>
        <FormControl style={{ width: "100%" }}>
          <InputBase
            id="username"
            placeholder={t("REGISTER.STEP_TWO.INFLUENCER.USERNAME_PLACEHOLDER")}
            type="text"
            className={classes.inputField}
            value={usernameInputValue}
            required
            onChange={handleValueChange("username")}
          />
          {validationError && (
            <FormHelperText className={classes.errorMessage}>
              {usernameValidationError}
            </FormHelperText>
          )}
        </FormControl>
        <FormControl style={{ width: "100%" }}>
          <InputBase
            id="email"
            placeholder={t("REGISTER.STEP_TWO.INFLUENCER.EMAIL_PLACEHOLDER")}
            type="email"
            className={classes.inputField}
            value={emailInputValue}
            required
            onChange={handleValueChange("email")}
          />
          {validationError && (
            <FormHelperText className={classes.errorMessage}>
              {emailValidationError}
            </FormHelperText>
          )}
        </FormControl>
        <FormControl style={{ width: "100%" }}>
          <div className={classes.passwordField}>
            <InputBase
              id="password"
              placeholder={t(
                "REGISTER.STEP_TWO.INFLUENCER.PASSWORD_PLACEHOLDER"
              )}
              type={showPassword ? "text" : "password"}
              className={classes.inputPasswordField}
              value={passwordInputValue}
              onChange={handleValueChange("password")}
            />
            <IconButton onClick={togglePasswordVisibility}>
              {showPassword ? <VisibilityOffIcon /> : <VisibilityIcon />}
            </IconButton>
          </div>
          {validationError && (
            <FormHelperText className={classes.errorMessage}>
              {passwordValidationError}
            </FormHelperText>
          )}
        </FormControl>
        <FormControl style={{ width: "100%" }}>
          <InputBase
            id="passwordConfirm"
            placeholder={t(
              "REGISTER.STEP_TWO.INFLUENCER.CONFIRM_PASSWORD_PLACEHOLDER"
            )}
            type={showPassword ? "text" : "password"}
            className={classes.inputField}
            value={passwordConfirmInputValue}
            required
            onChange={handleValueChange("passwordConfirm")}
          />
          {validationError && (
            <FormHelperText className={classes.errorMessage}>
              {passwordConfirmValidationError}
            </FormHelperText>
          )}
        </FormControl>
      </Grid>
    </form>
  );
};

const mapStateToProps = (state) => {
  const registrationResponseErrors = state.authState.registrationResponseErrors;
  return { registrationResponseErrors };
};

export default connect(mapStateToProps, null)(SteptwoInfluencer);
