import { dbManager,userManager } from "../../components/managers"

export const fetchAllBrands = () => {
    return (dispatch,getState) => {
        dbManager.fetchAllBrands().then(brands => {
            dispatch({ type: 'FETCH_ALL_BRANDS_SUCCESS',brands });
        }).catch(error => {
            dispatch({ type: 'FETCH_BRANDS_ERROR',error })
        })
    };
}

export const fetchAllBrandsToValidate = () => {
    return (dispatch,getState) => {
        dbManager.fetchAllBrandsToValidate().then((brandsToValidate) => {
            dispatch({ type: 'FETCH_ALL_BRANDS_TO_VALIDATE_SUCCESS',payload: { brandsToValidate } });
        }).catch(error => {
            dispatch({ type: 'FETCH_ALL_BRANDS_TO_VALIDATE_ERROR',error });
        });
    }
}

export const fetchAllSuggestedBrands = () => {
    return (dispatch,getState) => {
        dbManager.fetchAllSuggestedBrands().then(suggestedBrands => {
            dispatch({ type: 'FETCH_ALL_SUGGESTED_BRANDS_SUCCESS',payload: { suggestedBrands } })
        }).catch(error => {
            dispatch({ type: 'FETCH_ALL_SUGGESTED_BRANDS_ERROR',error });
        })
    }
}

export const updateBrandToValidateById = (brandId,updates) => {
    return (dispatch,getState) => {
        dbManager.updateBrandToValidateById(brandId,updates).then(() => {
            dispatch({ type: 'UPDATE_BRAND_TO_VALIDATE_BY_ID_SUCCESS',payload: { id: brandId,updates } });
        },error => {
            dispatch({ type: 'UPDATE_BRAND_TO_VALIDATE_BY_ID_ERROR',error });
        });
    }
}

export const validateBrandById = (brandId,brand,brandUid,userData) => {
    return (dispatch,getState) => {
        userManager.addClaimWithValue(brandUid,'brand',true).then(result => {
            if (result.data.status === 'success') {
                userManager.addClaimWithValue(brandUid,'brandOfferId',brand.offerId).then(result => {
                    if (result.data.status === 'success') {
                        userManager.addClaimWithValue(brandUid,'brandAdmin',true).then(result => {
                            if (result.data.status === 'success') {
                                dbManager.addNewBrand(brand).then((brand) => {
                                    dbManager.addNewBrandUser(brand.offerId,brandUid,userData).then((userData) => {
                                        dbManager.removeUnverifiedBrand(brandId).then(() => {
                                            dispatch({ type: 'VALIDATE_BRAND_BY_ID_SUCCESS',payload: { unverifiedBrandId: brandId,brand } });
                                        },error => {
                                            dispatch({ type: 'VALIDATE_BRAND_BY_ID_ERROR',error });
                                        })
                                    },(error) => {
                                        dispatch({ type: 'VALIDATE_BRAND_BY_ID_ERROR',error })
                                    });
                                },(error) => {
                                    dispatch({ type: 'VALIDATE_BRAND_BY_ID_ERROR',error });
                                });
                            } else {
                                dispatch({ type: 'VALIDATE_BRAND_BY_ID_ERROR',error: 'Unable to set brandAdmin claim' });
                            }
                        });
                    } else {
                        dispatch({ type: 'VALIDATE_BRAND_BY_ID_ERROR',error: 'Unable to set brandOfferId claim' });
                    }
                });
            } else {
                dispatch({ type: 'VALIDATE_BRAND_BY_ID_ERROR',error: 'Unable to set brand claim' });
            }
        });
    }
}

export const refuseBrandById = (brandId) => {
    return (dispatch,getState) => {
        dispatch({ type: 'REFUSE_BRAND_BY_ID_REQUEST' });
        dbManager.removeUnverifiedBrand(brandId).then(() => {
            dispatch({ type: 'REFUSE_BRAND_BY_ID_SUCCESS',payload: { unverifiedBrandId: brandId } });
        },error => {
            dispatch({ type: 'REFUSE_BRAND_BY_ID_ERROR',error });
        })
    }
}

export const updateBrandById = (brandId,updates) => {
    return (dispatch,getState) => {
        dispatch({ type: 'UPDATE_BRAND_BY_ID_REQUEST' })
        dbManager.updateBrandbyId(brandId,updates).then(() => {
            dispatch({ type: 'UPDATE_BRAND_BY_ID_SUCCESS',payload: { id: brandId,updates } });
        },error => {
            dispatch({ type: 'UPDATE_BRAND_BY_ID_ERROR',error });
        });
    }
}

export const removeBrandById = (brandId,brandName,uid,extension) => {
    return (dispatch,getState) => {
        dispatch({ type: 'REMOVE_BRAND_BY_ID_REQUEST' });
        userManager.deleteBrand(brandId,brandName,uid,extension).then(() => {
            dispatch({ type: 'REMOVE_BRAND_BY_ID_SUCCESS',payload: { brandId } });
        },error => {
            dispatch({ type: 'REMOVE_BRAND_BY_ID_ERROR',error });
        });
    }
}

export const deleteSuggestedBrand = (brandId) => {
    return (dispatch,getState) => {
        dispatch({ type: 'REFUSE_SUGGESTED_BRAND_BY_ID_REQUEST' });
        dbManager.deleteSuggestedBrand(brandId).then(() => {
            dispatch({ type: 'REFUSE_SUGGESTED_BRAND_BY_ID_SUCCESS',payload: { idSuggestedBrand: brandId } });
        },error => {
            dispatch({ type: 'REFUSE_SUGGESTED_BRAND_BY_ID_ERROR',error });
        })
    }
}

export const addNewBrand = (brand,userCredentials) => {
    return (dispatch,getState) => {
        dispatch({ type: 'ADD_BRAND_BY_ID_REQUEST' })
        userManager.createBrandUserFromAdmin(brand,userCredentials).then((brand) => {
            let userData = {
                firstName: brand.name,
                lastName: "Admin",
                email: userCredentials.email,
                offerId: brand.offerId,
                uid: brand.creatorUser
            }
            userManager.addClaimWithValue(brand.creatorUser,'brand',true).then(result => {
                if (result.data.status === 'success') {
                    userManager.addClaimWithValue(brand.creatorUser,'brandOfferId',brand.offerId).then(result => {
                        if (result.data.status === 'success') {
                            userManager.addClaimWithValue(brand.creatorUser,'brandAdmin',true).then(result => {
                                if (result.data.status === 'success') {
                                    dbManager.addNewBrandUser(brand.offerId,brand.creatorUser,userData).then(() => {
                                        dispatch({ type: 'ADD_BRAND_BY_ID_SUCCESS',payload: { brand: brand } });
                                    },(error) => {
                                        dispatch({ type: 'ADD_BRAND_BY_ID_ERROR',error })
                                    });
                                } else {
                                    dispatch({ type: 'ADD_BRAND_BY_ID_ERROR',error: 'Unable to set brandAdmin claim' });
                                }
                            });
                        } else {
                            dispatch({ type: 'ADD_BRAND_BY_ID_ERROR',error: 'Unable to set brandOfferId claim' });
                        }
                    });
                } else {
                    dispatch({ type: 'ADD_BRAND_BY_ID_ERROR',error: 'Unable to set brandOfferId claim' });
                }
            });
        },error => {
            dispatch({ type: 'ADD_BRAND_BY_ID_ERROR',error });
        });
    }
}
