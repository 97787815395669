import React, { useState, useEffect, useCallback } from "react";
import { connect } from "react-redux";
import { makeStyles } from "@material-ui/styles";
import TermsOfSales from "./TermsOfSales";
import {
  Grid,
  FormControl,
  InputBase,
  Typography,
  Select,
  MenuItem,
  FormHelperText,
  Checkbox,
  Button,
  Dialog,
  DialogTitle,
  DialogActions,
  IconButton,
} from "@material-ui/core";
import ExpandMoreIcon from "@material-ui/icons/ExpandMore";
import countriesDB from "../../assets/countries";
import { useTranslation } from "react-i18next";
import CheckCircleIcon from "@material-ui/icons/CheckCircle";
import CloseIcon from "@material-ui/icons/Close";

const useStyle = makeStyles((theme) => ({
  // inputField: {
  //     fontFamily: 'Poppins',
  //     borderRadius: '35px',
  //     boxShadow: '0 7px 30px 0 rgba(69, 128, 163, 0.16)',
  //     height: '50px',
  //     margin: '10px',
  //     paddingTop: '10px',
  //     paddingBottom: '10px',
  //     paddingLeft: '20px'
  // },
  stepTitle: {
    fontFamily: "Poppins",
    margin: "10px",
    fontSize: "18px",
  },
  logoImageLabel: {
    fontFamily: "Poppins",
    width: "150px",
    height: "40px",
    textTransform: "none",
    backgroundColor: "#58a5d3",
    color: "#fff",
    borderRadius: "35px",
    "&:hover": {
      cursor: "pointer",
      backgroundColor: "#58a5d3",
    },
    "&:focus": {
      outlineColor: "rgba(69, 128, 163, 0)",
    },
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    position: "relative",
  },
  inputField: {
    fontFamily: "Poppins",
    borderRadius: "35px",
    boxShadow: "0 7px 30px 0 rgba(69, 128, 163, 0.16)",
    minHeight: "50px",
    paddingLeft: "20px",
    paddingRight: "5px",
  },
  selectClass: {
    fontFamily: "Poppins",
    "&:focus": {
      backgroundColor: "white",
    },
  },
  logoPlaceholder: {
    fontFamily: "Poppins",
    color: "#717171",
  },
  activeLogoPlaceholder: {
    fontFamily: "Poppins",
  },
  popupSearch: {
    marginTop: "3px",
    width: "100%",
  },
  smallIcon: {
    paddingRight: 10,
    fontFamily: "Poppins",
  },
  errorMessage: {
    fontFamily: "Poppins",
    color: "red",
  },
  finishedRegistrationMessage: {
    textAlign: "center",
    fontSize: "20px",
    fontFamily: "Poppins",
  },
  selectedMenuItems: {
    backgroundColor: "#50bef3 !important",
    color: "#fff",
  },
  label: {
    fontSize: "15px",
    fontFamily: "Poppins",
    width: '100%',
    color: 'blue',
    textDecoration: 'underline',
    cursor: 'pointer'
  },
  closeButton: {
    position: "absolute",
    right: theme.spacing(1),
    top: theme.spacing(1),
    color: theme.palette.grey[500],
  },
}));

const StepthreeBrand = (props) => {
  const classes = useStyle();
  const { t, i18n } = useTranslation("common");

  const [initialValues, setInitialValues] = useState({});

  // const [logoValue, setLogoValue] = useState('');
  // const [base64Logo, setBase64Logo] = useState('');
  const [websiteValue, setWebsiteValue] = useState("");
  const [descriptionValue, setDescriptionValue] = useState("");
  const [countryDelivery, setCountryDelivery] = useState([]);
  const [categories, setCategories] = useState([]);
  const [siretValue, setSiretValue] = useState("");
  const [addressValue, setAddressValue] = useState("");
  const [isAffProgValue, setIsAffProgValue] = useState("");
  const [platformValue, setPlatformValue] = useState("");
  const [validationError, setValidationError] = useState(false);
  // const [base64LogoValidationError, setBase64LogoValidationError] = useState('');
  const [websiteValidationError, setWebsiteValidationError] = useState("");
  const [descriptionValidationError, setDescriptionValidationError] = useState(
    ""
  );
  const [
    countryDeliveryValidationError,
    setCountryDeliveryValidationError,
  ] = useState("");
  const [categoriesValidationError, setCategoriesValidationError] = useState(
    ""
  );
  const [siretValidationError, setSiretValidationError] = useState("");
  const [conditionValidationError, setConditionValidationError] = useState("");

  // const [addressValidationError, setAddressValidationError] = useState('');
  // const [isAffProgValidationError, setIsAffProgValidationError] = useState('');
  // const [platformValidationError, setPlatformValidationError] = useState('');
  const [checked, setChecked] = useState(false);
  const [open, setOpen] = useState(false);
  const [bottom, setBottom] = useState(false);
  const [activateButton, setActivateButton] = useState(true);
  const stepValues = props.stepValues;
  const validStep = props.validStep;
  const checkValid = props.checkValid;
  // const theme = useTheme();
  // const xsDown = useMediaQuery(theme.breakpoints.down("xs"));

  const { registrationComplete, newCategories } = props;

  const handleChangeCheckedTrue = () => {
    setChecked(true);
  };
  const handleChangeCheckedFalse = () => {
    setChecked(false);
  };
  const handleClickOpenDialog = () => {
    setOpen(true);
  };

  const handleCloseDialog = () => {
    setOpen(false);
    setActivateButton(true);
    setBottom(false);
  };

  const handleScroll = (e) => {
    const bottom =
      e.target.scrollHeight - Math.round(e.target.scrollTop) ===
      e.target.clientHeight;
    setBottom(bottom);
  };
  const handleValueChange = (id) => (event) => {
    if (id === "website") setWebsiteValue(event.target.value);
    if (id === "countryDelivery") setCountryDelivery(event.target.value);
    if (id === "descriptionValue" && event.target.value.length < 151)
      setDescriptionValue(event.target.value);
    if (id === "categories") setCategories(event.target.value);
    if (id === "siret") setSiretValue(event.target.value);
    if (id === "address") setAddressValue(event.target.value);
    if (id === "isAffProg") setIsAffProgValue(event.target.value);
    if (id === "platform") setPlatformValue(event.target.value);
    // if (id === 'logoValue') {
    //     setLogoValue(event.target.value);
    //     convertImage(event);
    // };
  };

  // const convertImage = async (event) => {
  //     const file = event.target.files[0];
  //     await convertToBase64(file).then(result => {
  //         setBase64Logo(result);
  //     });
  // }

  // const convertToBase64 = (file) => {
  //     return new Promise((resolve, reject) => {
  //         const fileReader = new FileReader();
  //         fileReader.readAsDataURL(file);
  //         fileReader.onload = () => {
  //             resolve(fileReader.result)
  //         };
  //         fileReader.onerror = (error) => {
  //             reject(error);
  //         }
  //     });
  // }

  const isValid = useCallback(() => {
    let valid = true;
    setValidationError(false);
    resetErrors();
    // if (base64Logo === '') {
    //     valid = false;
    //     setValidationError(true);
    //     setBase64LogoValidationError('* Ce champs est obligatoire');
    // }
    if (websiteValue === "") {
      valid = false;
      setValidationError(true);
      setWebsiteValidationError(
        "* " + t("REGISTER.STEP_THREE.BRAND.VALIDATION_ERRORS.REQUIRED_MESSAGE")
      );
    }
    if (descriptionValue === "") {
      valid = false;
      setValidationError(true);
      setDescriptionValidationError(
        "* " + t("REGISTER.STEP_THREE.BRAND.VALIDATION_ERRORS.REQUIRED_MESSAGE")
      );
    }
    if (countryDelivery.length === 0) {
      valid = false;
      setValidationError(true);
      setCountryDeliveryValidationError(
        "* " + t("REGISTER.STEP_THREE.BRAND.VALIDATION_ERRORS.REQUIRED_MESSAGE")
      );
    }
    if (categories.length === 0) {
      valid = false;
      setValidationError(true);
      setCategoriesValidationError(
        "* " + t("REGISTER.STEP_THREE.BRAND.VALIDATION_ERRORS.REQUIRED_MESSAGE")
      );
    }
    if (siretValue === "") {
      valid = false;
      setValidationError(true);
      setSiretValidationError(
        "* " + t("REGISTER.STEP_THREE.BRAND.VALIDATION_ERRORS.REQUIRED_MESSAGE")
      );
    }
    if (checked === false) {
      valid = false;
      setValidationError(true);
      setConditionValidationError(
        "* " +
        t("REGISTER.STEP_THREE.BRAND.VALIDATION_ERRORS.CONDITION_MESSAGE")
      );
    }
    // if (addressValue === '') {
    //     valid = false;
    //     setValidationError(true);
    //     setAddressValidationError('* Ce champs est obligatoire')
    // }
    // if (isAffProgValue === '') {
    //     valid = false;
    //     setValidationError(true);
    //     setIsAffProgValidationError('* Ce champs est obligatoire');
    // }
    // if (isAffProgValue === 'Oui' && platformValue === '') {
    //     valid = false;
    //     setValidationError(true);
    //     setPlatformValidationError('* Ce champs est obligatoire');
    // }
    return valid;
  }, [
    categories.length,
    countryDelivery.length,
    descriptionValue,
    siretValue,
    t,
    websiteValue,
    checked,
  ]);
  const resetErrors = () => {
    // setBase64LogoValidationError();s
    setWebsiteValidationError();
    setDescriptionValidationError();
    setCountryDeliveryValidationError();
    setCategoriesValidationError();
    setSiretValidationError();
    setConditionValidationError();
    // setAddressValidationError();
    // setIsAffProgValidationError();
    // setPlatformValidationError();
  };

  useEffect(() => {
    // if (initialValues.logo !== undefined) setLogoValue(initialValues.logo);
    // if (initialValues.base64Logo !== undefined) setBase64Logo(initialValues.base64Logo);
    if (initialValues.website !== undefined)
      setWebsiteValue(initialValues.website);
    if (initialValues.description !== undefined)
      setDescriptionValue(initialValues.description);
    if (initialValues.countryDelivery !== undefined)
      setCountryDelivery(initialValues.countryDelivery);
    if (initialValues.categories !== undefined) {
      let categoryMap = [];
      initialValues.categories.forEach(cat => {
        categoryMap.push(newCategories.filter(categoryObject => categoryObject.key === cat)[0]);
      });
      setCategories(categoryMap);
    }
    if (initialValues.siret !== undefined) setSiretValue(initialValues.siret);
    if (initialValues.address !== undefined)
      setAddressValue(initialValues.address);
    if (initialValues.isAffProg !== undefined)
      setIsAffProgValue(initialValues.isAffProg);
    if (initialValues.platform !== undefined)
      setPlatformValue(initialValues.platform);
  }, [initialValues, newCategories]);

  useEffect(() => {
    if (props.valuesToPopulate !== undefined) {
      setInitialValues(props.valuesToPopulate);
    }
  }, [props.valuesToPopulate]);

  useEffect(() => {
    stepValues({
      // logo: logoValue,
      // base64Logo: base64Logo,
      website: websiteValue,
      description: descriptionValue,
      countryDelivery: countryDelivery,
      categories: categories.map(cat => cat.key),
      siret: siretValue,
      address: addressValue,
      conditionAndTerms: checked,
      isAffProg: isAffProgValue,
      platform: platformValue,
    });
  }, [
    websiteValue,
    descriptionValue,
    countryDelivery,
    categories,
    siretValue,
    addressValue,
    isAffProgValue,
    platformValue,
    stepValues,
    checked,
  ]);

  useEffect(() => {
    if (checkValid) {
      if (isValid()) {
        validStep(true);
      } else validStep(false);
    }
  }, [checkValid, isValid, validStep]);

  return registrationComplete ? (
    <>
      <Grid
        container
        item
        direction="row"
        xs={12}
        alignItems="center"
        justify="center"
      >
        <Grid
          container
          item
          direction="row"
          xs={9}
          alignItems="center"
          justify="center"
        >
          <CheckCircleIcon
            style={{ width: 72, height: 72, margin: 15, color: "#20be80" }}
          />
        </Grid>
        <Grid
          container
          item
          direction="row"
          xs={9}
          alignItems="center"
          justify="center"
        >
          <Typography className={classes.finishedRegistrationMessage}>
            {t("REGISTER.SIGNUP_CONFIRMED_MESSAGE")}
          </Typography>
        </Grid>
      </Grid>
    </>
  ) : (
      <>
        <Grid container item direction="row" xs={12}>
          <Typography className={classes.stepTitle}>
            Etape 3 : Configuration du profil
        </Typography>
        </Grid>
        <Grid
          container
          item
          direction="row"
          justify="center"
          alignItems="center"
          xs={12}
        >
          <FormControl style={{ width: "100%" }}>
            {/* <Grid container item justify='space-between' alignItems='center' className={classes.inputField} style={{ marginTop: '10px', marginBottom: '10px' }}>
                    <Typography className={(logoValue === '') ? classes.logoPlaceholder : classes.activeLogoPlaceholder}>
                        {(logoValue === '') ? 'Logo' : logoValue.split('\\').pop()}
                    </Typography>
                    <label htmlFor='profileImage' tabIndex='0' className={classes.logoImageLabel}>
                        Télécharger
                    </label>
                </Grid>
                <input type='file' id='profileImage' onChange={handleValueChange('logoValue')} hidden accept="image/x-png,image/gif,image/jpeg" />
                {validationError && <FormHelperText className={classes.errorMessage}>{base64LogoValidationError}</FormHelperText>} */}
          </FormControl>
          <FormControl
            style={{ width: "100%", marginTop: "10px", marginBottom: "10px" }}
          >
            <InputBase
              placeholder={t("REGISTER.STEP_THREE.BRAND.WEBSITE_PLACEHOLDER")}
              type="text"
              className={classes.inputField}
              value={websiteValue}
              required
              onChange={handleValueChange("website")}
            />
            {validationError && (
              <FormHelperText className={classes.errorMessage}>
                {websiteValidationError}
              </FormHelperText>
            )}
          </FormControl>
          <FormControl
            style={{ width: "100%", marginTop: "10px", marginBottom: "10px" }}
          >
            <Grid
              container
              item
              direction="row"
              className={classes.inputField}
              alignItems="center"
            >
              <InputBase
                placeholder={t(
                  "REGISTER.STEP_THREE.BRAND.DESCRIPTION_PLACEHOLDER"
                )}
                type="text"
                value={descriptionValue}
                required
                style={{ width: "90%", fontFamily: "Poppins" }}
                onChange={handleValueChange("descriptionValue")}
              />
              <span>{descriptionValue.length} / 150</span>
            </Grid>
            {validationError && (
              <FormHelperText className={classes.errorMessage}>
                {descriptionValidationError}
              </FormHelperText>
            )}
          </FormControl>
          <FormControl
            style={{ width: "100%", marginTop: "10px", marginBottom: "10px" }}
          >
            <Select
              displayEmpty
              multiple
              value={countryDelivery}
              onChange={handleValueChange("countryDelivery")}
              classes={{ root: classes.selectClass }}
              input={<InputBase className={classes.inputField} />}
              renderValue={(selected) => {
                if (selected.length === 0) {
                  return (
                    <Typography
                      style={{ fontFamily: "Poppins", color: "#717171" }}
                    >
                      {t(
                        "REGISTER.STEP_THREE.BRAND.COUNTRY_DELIVERY_PLACEHOLDER"
                      )}
                    </Typography>
                  );
                }
                return selected.map((item) => {
                  return (i18n.language === 'fr-FR' || i18n.language === 'fr') ? item.nameFr : item.name
                }).join(", ");
              }}
              IconComponent={() => <ExpandMoreIcon />}
            >
              {countriesDB.map((country) => (
                <MenuItem
                  key={country.code}
                  value={country}
                  style={{ fontFamily: "Poppins" }}
                  classes={{ selected: classes.selectedMenuItems }}
                >
                  {(i18n.language === 'fr-FR' || i18n.language === 'fr') ? country.nameFr : country.name}
                </MenuItem>
              ))}
            </Select>
            {validationError && (
              <FormHelperText className={classes.errorMessage}>
                {countryDeliveryValidationError}
              </FormHelperText>
            )}
          </FormControl>
          <FormControl style={{ width: "100%" }}>
            <Select
              multiple
              displayEmpty
              value={categories}
              onChange={handleValueChange("categories")}
              classes={{ root: classes.selectClass }}
              input={
                <InputBase
                  className={classes.inputField}
                  style={{ marginTop: "10px", marginBottom: "10px" }}
                />
              }
              renderValue={(selected) => {
                if (selected.length === 0) {
                  return (
                    <Typography
                      style={{ fontFamily: "Poppins", color: "#717171" }}
                    >
                      {t("REGISTER.STEP_THREE.BRAND.CATEGORIES_PLACEHOLDER")}
                    </Typography>
                  );
                }
                return selected.map(cat => (i18n.language === 'fr-FR' || i18n.language === 'fr') ? cat['text-fr'] : cat['text-en']).join(", ");
              }}
              IconComponent={() => <ExpandMoreIcon />}
            >
              {newCategories.map(category => (
                <MenuItem
                  value={category}
                  style={{ fontFamily: "Poppins" }}
                  classes={{ selected: classes.selectedMenuItems }}
                  key={category.key}
                >
                  {(i18n.language === 'fr-FR' || i18n.language === 'fr') ? category['text-fr'] : category['text-en']}
                </MenuItem>
              ))}
            </Select>
            {validationError && (
              <FormHelperText className={classes.errorMessage}>
                {categoriesValidationError}
              </FormHelperText>
            )}
          </FormControl>
          <FormControl
            style={{ width: "100%", marginTop: "10px", marginBottom: "10px" }}
          >
            <InputBase
              placeholder={t(
                "REGISTER.STEP_THREE.BRAND.SIRET_NUMBER_PLACEHOLDER"
              )}
              type="text"
              className={classes.inputField}
              value={siretValue}
              required
              onChange={handleValueChange("siret")}
            />
            {validationError && (
              <FormHelperText className={classes.errorMessage}>
                {siretValidationError}
              </FormHelperText>
            )}
          </FormControl>
          <FormControl
            style={{ width: "100%", marginTop: "10px", marginBottom: "10px" }}
          >
            <InputBase
              placeholder={t("REGISTER.STEP_THREE.BRAND.ADDRESS_PLACEHOLDER")}
              type="text"
              className={classes.inputField}
              value={addressValue}
              required
              onChange={handleValueChange("address")}
            />
            {/* {validationError && <FormHelperText className={classes.errorMessage}>{addressValidationError}</FormHelperText>} */}
          </FormControl>
          <FormControl style={{ width: "100%" }}>
            <Select
              displayEmpty
              value={isAffProgValue}
              onChange={handleValueChange("isAffProg")}
              classes={{ root: classes.selectClass }}
              input={
                <InputBase
                  className={classes.inputField}
                  style={{ marginTop: "10px", marginBottom: "10px" }}
                />
              }
              renderValue={selected => {
                if (selected === "") {
                  return (
                    <Typography
                      style={{ fontFamily: "Poppins", color: "#717171" }}
                    >
                      {t(
                        "REGISTER.STEP_THREE.BRAND.AFFILIATE_PROGRAM_PLACEHOLDER"
                      )}
                    </Typography>
                  );
                }
                return selected
                  ? t("REGISTER.STEP_THREE.BRAND.AFFILIATE_PROGRAM_OPTION_YES")
                  : t("REGISTER.STEP_THREE.BRAND.AFFILIATE_PROGRAM_OPTION_NO");
              }}
              IconComponent={() => <ExpandMoreIcon />}
            >
              <MenuItem
                value={true}
                style={{ fontFamily: "Poppins" }}
                classes={{ selected: classes.selectedMenuItems }}
              >
                {t("REGISTER.STEP_THREE.BRAND.AFFILIATE_PROGRAM_OPTION_YES")}
              </MenuItem>
              <MenuItem
                value={false}
                style={{ fontFamily: "Poppins" }}
                classes={{ selected: classes.selectedMenuItems }}
              >
                {t("REGISTER.STEP_THREE.BRAND.AFFILIATE_PROGRAM_OPTION_NO")}
              </MenuItem>
            </Select>
            {/* {validationError && <FormHelperText className={classes.errorMessage}>{isAffProgValidationError}</FormHelperText>} */}
          </FormControl>
          <FormControl
            style={{ width: "100%", marginTop: "10px", marginBottom: "10px" }}
          >
            <InputBase
              placeholder={t("REGISTER.STEP_THREE.BRAND.PLATFORM_PLACEHOLDER")}
              type="text"
              disabled={!isAffProgValue}
              className={classes.inputField}
              value={platformValue}
              required
              onChange={handleValueChange("platform")}
            />
            {/* {validationError && <FormHelperText className={classes.errorMessage}>{platformValidationError}</FormHelperText>} */}
          </FormControl>
          <FormControl style={{ display: 'flex', flexDirection: 'row', flexGrow: 2, width: "100%", marginTop: "10px", marginBottom: "10px" }}>
            {
              checked === false ? (
                <Checkbox checked={checked} onClick={handleClickOpenDialog} />
              ) : (
                  <Checkbox
                    checked={checked}
                    onChange={handleChangeCheckedFalse}
                  />
                )
            }
            <Typography style={{ fontFamily: 'Poppins' }}>
              {t("REGISTER.STEP_THREE.BRAND.CONDITION_TERMS_FIRST_PART")}
              <span className={classes.label} onClick={handleClickOpenDialog}>{t("REGISTER.STEP_THREE.BRAND.CONDITION_TERMS_TITLE")}</span>
              {t("REGISTER.STEP_THREE.BRAND.CONDITION_TERMS_SECOND_PART")}
            </Typography>
            <Dialog
              onClose={handleCloseDialog}
              aria-labelledby="customized-dialog-title"
              open={open}
              onScroll={handleScroll}
            >
              <DialogTitle
                id="customized-dialog-title"
                onClose={handleCloseDialog}
                style={{ height: '60px', display: 'flex', alignItems: 'center' }}
              >
                <Typography style={{ font: "icon" }}>
                  {t("REGISTER.STEP_THREE.BRAND.CONDITION_TERMS_TITLE")}
                </Typography>
                <IconButton
                  aria-label="close"
                  className={classes.closeButton}
                  onClick={handleCloseDialog}
                >
                  <CloseIcon />
                </IconButton>
              </DialogTitle>
              <TermsOfSales />
              <DialogActions>
                {bottom === false ? (
                  <Button
                    autoFocus
                    variant="contained"
                    color="primary"
                    style={{ fontWeight: "800" }}
                    disabled={activateButton}
                  >
                    {t("REGISTER.STEP_THREE.BRAND.BUTTON_ACCEPT_TERMS")}
                  </Button>
                ) : (
                    <Button
                      autoFocus
                      variant="contained"
                      onClick={() => {
                        handleChangeCheckedTrue();
                        handleCloseDialog();
                      }}
                      color="primary"
                      style={{ fontWeight: "800" }}
                    >
                      {t("REGISTER.STEP_THREE.BRAND.BUTTON_ACCEPT_TERMS")}
                    </Button>
                  )}
              </DialogActions>
            </Dialog>

            {validationError && (
              <FormHelperText className={classes.errorMessage}>
                {conditionValidationError}
              </FormHelperText>
            )}
          </FormControl>
        </Grid>
      </>
    );
};
const mapStateToProps = (state) => {
  const registrationComplete = state.authState.registrationComplete;
  const newCategories = state.categoriesState.newCategories;

  return { registrationComplete, newCategories };
};
export default connect(mapStateToProps, null)(StepthreeBrand);
