import React, { useState, useEffect, useCallback } from "react";
import { makeStyles } from "@material-ui/styles";
import { connect } from "react-redux";
import {
  Grid,
  FormControl,
  InputBase,
  Typography,
  FormHelperText,
  IconButton,
} from "@material-ui/core";
import * as utils from "../../../utils";
import { useTranslation } from "react-i18next";
import VisibilityIcon from "@material-ui/icons/Visibility";
import VisibilityOffIcon from "@material-ui/icons/VisibilityOff";

const useStyle = makeStyles(() => ({
  inputField: {
    fontFamily: "Poppins",
    borderRadius: "35px",
    boxShadow: "0 7px 30px 0 rgba(69, 128, 163, 0.16)",
    height: "50px",
    margin: "10px",
    paddingTop: "10px",
    paddingBottom: "10px",
    paddingLeft: "20px",
  },
  passwordField: {
    borderRadius: "35px",
    boxShadow: "0 7px 30px 0 rgba(69, 128, 163, 0.16)",
    margin: 10,
    display: "flex",
    flexDirection: "row",
    alignItems: "center",
  },
  inputPasswordField: {
    fontFamily: "Poppins",
    paddingTop: "10px",
    paddingBottom: "10px",
    paddingLeft: "20px",
    flexGrow: 1,
  },
  stepTitle: {
    fontFamily: "Poppins",
    margin: "10px",
    fontSize: "18px",
  },
  errorMessage: {
    fontFamily: "Poppins",
    color: "red",
  },
}));

const SteptwoBrand = (props) => {
  const classes = useStyle();
  const { t } = useTranslation("common");

  const [initialValues, setInitialValues] = useState({});

  const [prenomInputValue, setPrenomInputValue] = useState("");
  const [nomInputValue, setNomInputValue] = useState("");
  const [brandNameInputValue, setBrandNameInputValue] = useState("");
  const [emailInputValue, setEmailInputValue] = useState("");
  const [passwordInputValue, setPasswordInputValue] = useState("");
  const [passwordConfirmInputValue, setPasswordConfirmInputValue] = useState(
    ""
  );
  const [showPassword, setShowPassword] = useState(false);

  const [validationError, setValidationError] = useState(false);
  const [prenomValidationError, setPrenomValidationError] = useState("");
  const [nomValidationError, setNomValidationError] = useState("");
  const [brandNameValidationError, setBrandNameValidationError] = useState("");
  const [emailValidationError, setEmailValidationError] = useState("");
  const [passwordValidationError, setPasswordValidationError] = useState("");
  const [
    passwordConfirmValidationError,
    setPasswordConfirmValidationError,
  ] = useState("");
  const [registrationErrors, setRegistrationErrors] = useState();
  const { registrationResponseErrors } = props;
  const stepValues = props.stepValues;
  const validStep = props.validStep;
  const checkValid = props.checkValid;

  const handleValueChange = (id) => (event) => {
    if (id === "prenom") {
      setPrenomInputValue(event.target.value);
    }
    if (id === "nom") {
      setNomInputValue(event.target.value);
    }
    if (id === "brandName") {
      setBrandNameInputValue(event.target.value);
    }
    if (id === "email") {
      setEmailInputValue(event.target.value);
    }
    if (id === "password") {
      setPasswordInputValue(event.target.value);
    }
    if (id === "passwordConfirm") {
      setPasswordConfirmInputValue(event.target.value);
    }
  };

  const isValid = useCallback(() => {
    let valid = true;
    resetErrors();
    setValidationError(false);
    if (prenomInputValue === "") {
      valid = false;
      setValidationError(true);
      setPrenomValidationError(
        "* " + t("REGISTER.STEP_TWO.BRAND.VALIDATION_ERRORS.REQUIRED_MESSAGE")
      );
    }
    if (nomInputValue === "") {
      valid = false;
      setValidationError(true);
      setNomValidationError(
        "* " + t("REGISTER.STEP_TWO.BRAND.VALIDATION_ERRORS.REQUIRED_MESSAGE")
      );
    }
    if (brandNameInputValue === "") {
      valid = false;
      setValidationError(true);
      setBrandNameValidationError(
        "* " + t("REGISTER.STEP_TWO.BRAND.VALIDATION_ERRORS.REQUIRED_MESSAGE")
      );
    }
    if (emailInputValue === "") {
      valid = false;
      setValidationError(true);
      setEmailValidationError(
        "* " + t("REGISTER.STEP_TWO.BRAND.VALIDATION_ERRORS.REQUIRED_MESSAGE")
      );
    }
    if (emailInputValue !== "" && !utils.emailValidation(emailInputValue)) {
      valid = false;
      setValidationError(true);
      setEmailValidationError(
        "* " +
          t("REGISTER.STEP_TWO.BRAND.VALIDATION_ERRORS.INVALID_EMAIL_MESSAGE")
      );
    }
    if (passwordInputValue.length < 6) {
      valid = false;
      setValidationError(true);
      setPasswordValidationError(
        "* " +
          t(
            "REGISTER.STEP_TWO.BRAND.VALIDATION_ERRORS.INVALID_PASSWORD_LENGTH_MESSAGE"
          )
      );
    }
    if (passwordConfirmInputValue !== passwordInputValue) {
      valid = false;
      setValidationError(true);
      setPasswordConfirmValidationError(
        "* " +
          t(
            "REGISTER.STEP_TWO.BRAND.VALIDATION_ERRORS.NON_IDENTICAL_PASSWORDS_MESSAGE"
          )
      );
    }

    return valid;
  }, [
    brandNameInputValue,
    emailInputValue,
    nomInputValue,
    passwordInputValue,
    passwordConfirmInputValue,
    prenomInputValue,
    t,
  ]);

  const resetErrors = () => {
    setPrenomValidationError();
    setNomValidationError();
    setBrandNameValidationError();
    setEmailValidationError();
    setPasswordConfirmValidationError();
    setPasswordValidationError();
  };

  const togglePasswordVisibility = () => {
    setShowPassword(!showPassword);
  };

  useEffect(() => {
    if (initialValues.prenom !== undefined)
      setPrenomInputValue(initialValues.prenom);
    if (initialValues.nom !== undefined) setNomInputValue(initialValues.nom);
    if (initialValues.brandName !== undefined)
      setBrandNameInputValue(initialValues.brandName);
    if (initialValues.email !== undefined)
      setEmailInputValue(initialValues.email);
    if (initialValues.password !== undefined)
      setPasswordInputValue(initialValues.password);
    if (initialValues.passwordConfirm !== undefined)
      setPasswordConfirmInputValue(initialValues.passwordConfirm);
  }, [initialValues]);

  useEffect(() => {
    if (props.valuesToPopulate !== undefined) {
      setInitialValues(props.valuesToPopulate);
    }
  }, [props.valuesToPopulate]);

  useEffect(() => {
    stepValues({
      prenom: prenomInputValue,
      nom: nomInputValue,
      brandName: brandNameInputValue,
      email: emailInputValue,
      password: passwordInputValue,
      passwordConfirm: passwordConfirmInputValue,
    });
  }, [
    prenomInputValue,
    nomInputValue,
    brandNameInputValue,
    emailInputValue,
    passwordInputValue,
    passwordConfirmInputValue,
    stepValues,
  ]);

  useEffect(() => {
    if (registrationResponseErrors) {
      setRegistrationErrors(registrationResponseErrors);
    }
  }, [registrationResponseErrors]);
  useEffect(() => {
    if (registrationErrors) {
      setValidationError(true);
      if (registrationErrors.code === "auth/invalid-email") {
        setEmailValidationError(
          "* " + t("REGISTER.RESPONSE_ERRORS.INVALID_EMAIL")
        );
      } else if (registrationErrors.code === "auth/email-already-in-use") {
        setEmailValidationError(
          "* " + t("REGISTER.RESPONSE_ERRORS.EMAIL_ALREADY_IN_USE")
        );
      }
    }
  }, [registrationErrors, t]);

  useEffect(() => {
    if (checkValid) {
      if (isValid()) validStep(true);
      else validStep(false);
    }
  }, [checkValid, isValid, validStep]);

  return (
    <>
      <Grid container item direction="row" xs={12}>
        <Typography className={classes.stepTitle}>
          {t("REGISTER.STEP_TWO.STEP_TITLE")}
        </Typography>
      </Grid>
      <Grid container item justify="center" alignItems="center" xs={12}>
        <FormControl style={{ width: "100%" }}>
          <InputBase
            id="prenom"
            placeholder={t("REGISTER.STEP_TWO.BRAND.SURNAME_PLACEHOLDER")}
            type="text"
            className={classes.inputField}
            value={prenomInputValue}
            onChange={handleValueChange("prenom")}
          />
          {validationError && (
            <FormHelperText className={classes.errorMessage}>
              {prenomValidationError}
            </FormHelperText>
          )}
        </FormControl>
        <FormControl style={{ width: "100%" }}>
          <InputBase
            id="nom"
            placeholder={t("REGISTER.STEP_TWO.BRAND.NAME_PLACEHOLDER")}
            type="text"
            className={classes.inputField}
            value={nomInputValue}
            required
            onChange={handleValueChange("nom")}
          />
          {validationError && (
            <FormHelperText className={classes.errorMessage}>
              {nomValidationError}
            </FormHelperText>
          )}
        </FormControl>
        <FormControl style={{ width: "100%" }}>
          <InputBase
            id="brandName"
            placeholder={t("REGISTER.STEP_TWO.BRAND.BRANDNAME_PLACEHOLDER")}
            type="text"
            className={classes.inputField}
            value={brandNameInputValue}
            required
            onChange={handleValueChange("brandName")}
          />
          {validationError && (
            <FormHelperText className={classes.errorMessage}>
              {brandNameValidationError}
            </FormHelperText>
          )}
        </FormControl>
        <FormControl style={{ width: "100%" }}>
          <InputBase
            id="email"
            placeholder={t("REGISTER.STEP_TWO.BRAND.EMAIL_PLACEHOLDER")}
            type="email"
            className={classes.inputField}
            value={emailInputValue}
            required
            onChange={handleValueChange("email")}
          />
          {validationError && (
            <FormHelperText className={classes.errorMessage}>
              {emailValidationError}
            </FormHelperText>
          )}
        </FormControl>
        <FormControl style={{ width: "100%" }}>
          <div className={classes.passwordField}>
            <InputBase
              id="password"
              placeholder={t(
                "REGISTER.STEP_TWO.INFLUENCER.PASSWORD_PLACEHOLDER"
              )}
              type={showPassword ? "text" : "password"}
              className={classes.inputPasswordField}
              value={passwordInputValue}
              onChange={handleValueChange("password")}
            />
            <IconButton onClick={togglePasswordVisibility}>
              {showPassword ? <VisibilityOffIcon /> : <VisibilityIcon />}
            </IconButton>
          </div>
          {validationError && (
            <FormHelperText className={classes.errorMessage}>
              {passwordValidationError}
            </FormHelperText>
          )}
        </FormControl>
        <FormControl style={{ width: "100%" }}>
          <InputBase
            id="passwordConfirm"
            placeholder={t(
              "REGISTER.STEP_TWO.BRAND.CONFIRM_PASSWORD_PLACEHOLDER"
            )}
            type={showPassword ? "text" : "password"}
            className={classes.inputField}
            value={passwordConfirmInputValue}
            required
            onChange={handleValueChange("passwordConfirm")}
          />
          {validationError && (
            <FormHelperText className={classes.errorMessage}>
              {passwordConfirmValidationError}
            </FormHelperText>
          )}
        </FormControl>
      </Grid>
    </>
  );
};
const mapStateToProps = (state) => {
  const registrationResponseErrors = state.authState.registrationResponseErrors;
  return { registrationResponseErrors };
};
export default connect(mapStateToProps, null)(SteptwoBrand);
