import React, { useEffect, useState } from "react";
import { Pagination, PaginationItem } from '@material-ui/lab';
// import PropTypes from 'prop-types';
import {
  Grid,
  makeStyles,
  Typography,
  useMediaQuery,
  useTheme
} from "@material-ui/core";
// import Overlay from "../../../ui/masonry/Overlay";
import { HoverEffect } from "../../../ui";
import { Redirect } from "react-router-dom";

const useStyles = makeStyles((theme) => ({
  container: { paddingTop: '60px' },
  infContainer: {
    position: "relative",
  },
  link: {
    width: "100%",
    height: "100%",
    display: "flex",
    textAlign: "center",
    justifyContent: "center",
    alignItems: "center",
    textDecoration: "none",
    color: "white",
  },
  effect: {
    backgroundColor: "rgba(35, 201, 255, 0.85)",
  },
  paginationContainer: {
    margin: '20px'
  }
}));

const InfsGrid = (props) => {

  const theme = useTheme();
  const isMdDown = useMediaQuery(theme.breakpoints.down("sm"));

  /* State */
  const [redirect, setRedirect] = useState(
    undefined
  );
  const [lastTouched, setLastTouched] = useState(
    undefined
  );
  const [influencers, setInfluencers] = useState([]);
  const [influencersToRender, setInfluencersToRender] = useState([]);
  const [page, setPage] = useState(1);
  const [count, setCount] = useState(0);
  // const [size, setSize] = useState(40);

  /* Hooks */
  const defaultClasses = useStyles();

  const handlePageChange = (event, value) => {
    setPage(value);
  };

  useEffect(() => {
    setInfluencers(props.influencers);
    setPage(1);
  }, [props.influencers]);

  useEffect(() => {
    setCount(
      (influencers.length % 40 === 0)
        ? Math.trunc(influencers.length / 40)
        : Math.trunc(influencers.length / 40) + 1
    );
    setInfluencersToRender(influencers.slice((page - 1) * 40, page * 40));
  }, [influencers, page]);

  const handleInfluencerClick = (inf) => (e) => {
    setRedirect(
      `/wishlists/${inf.name.replace(/ /, "_")}`
    );
  };
  const handleElementTouched = (inf) => (e) => {
    setLastTouched(inf.key);
  };

  return (
    <Grid
      className={defaultClasses.container}
      style={(isMdDown) ? { paddingTop: 60 } : { paddingTop: 0 }}
      item
      container
    >
      <Grid container item direction='row' wrap="wrap">
        {redirect && (
          <Redirect to={redirect} push />
        )}
        {
          influencersToRender.map((inf) => (
            <Grid key={inf.key} className={defaultClasses.infContainer} item xs={6} sm={4} md={3} lg={3} style={{ background: `url(${inf.banner}) center/cover` }}>
              <span style={{ display: "inline-block", paddingTop: "50%", width: 0, }} />
              <div style={{ position: "absolute", top: 0, right: 0, bottom: 0, left: 0, }} onTouchStart={handleElementTouched(inf)}>
                <HoverEffect classes={{ effect: defaultClasses.effect }} visible={lastTouched === inf.key ? true : undefined}>
                  <div style={{ height: "100%", width: "100%", display: "flex", alignItems: "center", justifyContent: "center" }} onClick={handleInfluencerClick(inf)}>
                    <Typography variant='h4' style={{ color: "white" }} align='center'>
                      {inf.name}
                    </Typography>
                  </div>
                </HoverEffect>
              </div>
            </Grid>
          ))
        }
      </Grid>
      <Grid container item xs={12} direction='row' justify='center' alignItems='center' className={defaultClasses.paginationContainer}>
        <Pagination
          variant="outlined"
          shape="rounded"
          count={count}
          page={page}
          onChange={handlePageChange}
          renderItem={(item) => (
            <PaginationItem {...item} />
          )} />
      </Grid>
    </Grid >
  );
};

InfsGrid.propTypes = {};

export default InfsGrid;
