import { dbManager } from "../../components/managers"

export const fetchAllColors = () => {
    return (dispatch, getState) => {
        dbManager.fetchAllColors().then(colors => {
            dispatch({ type: 'FETCH_ALL_COLORS_SUCCESS', payload: { colors } });
        }, error => {
            dispatch({ type: 'FETCH_ALL_COLORS_ERROR', error});
        })
    }
}