import { createMuiTheme, responsiveFontSizes } from "@material-ui/core/styles";

let theme = createMuiTheme({
  border: "1px solid rgba(0,0,0,.12)",
  admin: {
    drawer: {
      width: 240,
    },
    topBar: {
      height: 240,
    },
    logoDrawer: {
      height: 160,
    }
  },
  appBar: {
    height: "80px",
  },
  background: {
    main: "#FFFFFF",
    transparent: "rgba(255,255,255,.9)",
  },
  mixins: {
    toolbar: {
      minHeight: 56,
      "@media (min-width:0px) and (orientation: landscape)": {
        minHeight: 48,
      },
      "@media (min-width:600px)": {
        minHeight: 64,
      },
    },
  },
  typography: {
    fontFamily: "Montserrat, Arial, sans-serif",
    h1: {
      color: "#00ADEF",
      fontWeight: "300",
    },
    h2: {
      color: "#30bef3",
    },
  },
  palette: {
    primary: {
      main: "#00ADEF",
      contrastText: "#FFFFFF",
    },
    secondary: {
      main: "#FB689F",
      contrastText: "#FFFFFF",
    },
    text: {
      primary: "#000000",
      secondary: "#4A4A4A",
    },
    background: {
      paper: "#fff",
      default: "#fff",
      modal: "rgba(0, 0, 0,.8)",
    },
  },
  transitions: {
    easing: {
      easeInOut: "cubic-bezier(0.4, 0, 0.2, 1)",
      easeOut: "cubic-bezier(0.0, 0, 0.2, 1)",
      easeIn: "cubic-bezier(0.4, 0, 1, 1)",
      sharp: "cubic-bezier(0.4, 0, 0.6, 1)",
    },
    duration: {
      shortest: 150,
      shorter: 200,
      short: 250,
      standard: 300,
      complex: 375,
      long: 1500,
      enteringScreen: 225,
      leavingScreen: 195,
    },
    delay: {
      none: 0,
    },
  },
  zIndex: {
    mobileStepper: 1000,
    appBar: 1100,
    drawer: 1200,
    modal: 1300,
    snackbar: 1400,
    tooltip: 1500,
  },
  overrides: {
    MuiIconButton: {
      root: {
        // padding: "0px !important",
      },
    },
  },
});
theme = responsiveFontSizes(theme);

export default theme;
