const initState = {
    influencerTableSortHelper: [1, 0, 0, 0, 0, 0],
    influencerTablePageNumber: 1,
    influencerTablePageSize: 10,
    productTableSortHelper: [1, 0, 0, 0, 0],
    productTablePageNumber: 1,
    productTablePageSize: 10,
    countryTableSortHelper: [1, 0, 0, 0],
    countryTablePageNumber: 1,
    countryTablePageSize: 10
};

const trendStatisticsReducer = (state = initState, action) => {
    switch (action.type) {
        case 'UPDATE_TREND_STATS_INFLUENCER_TABLE_SORT_HELPER':
            return {
                ...state,
                influencerTableSortHelper: action.sortHelper
            };
        case 'UPDATE_TREND_STATS_PRODUCT_TABLE_SORT_HELPER':
            return {
                ...state,
                productTableSortHelper: action.sortHelper
            };
        case 'UPDATE_TREND_STATS_COUNTRY_TABLE_SORT_HELPER':
            return {
                ...state,
                countryTableSortHelper: action.sortHelper
            };
        case 'UPDATE_TREND_STATS_INFLUENCER_TABLE_PAGE_NUMBER':
            return {
                ...state,
                influencerTablePageNumber: action.pageNumber
            };
        case 'UPDATE_TREND_STATS_PRODUCT_TABLE_PAGE_NUMBER':
            return {
                ...state,
                productTablePageNumber: action.pageNumber
            };
        case 'UPDATE_TREND_STATS_COUNTRY_TABLE_PAGE_NUMBER':
            return {
                ...state,
                countryTablePageNumber: action.pageNumber
            };
        case 'UPDATE_TREND_STATS_INFLUENCER_TABLE_PAGE_SIZE':
            return {
                ...state,
                influencerTablePageSize: action.pageSize
            };
        case 'UPDATE_TREND_STATS_PRODUCT_TABLE_PAGE_SIZE':
            return {
                ...state,
                productTablePageSize: action.pageSize
            };
        case 'UPDATE_TREND_STATS_COUNTRY_TABLE_PAGE_SIZE':
            return {
                ...state,
                countryTablePageSize: action.pageSize
            };
        default:
            return state;
    }
};

export default trendStatisticsReducer;