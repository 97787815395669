import React, { useState, useEffect } from "react";
import SwipeableViews from "react-swipeable-views";
import PropTypes from "prop-types";
import { Redirect } from "react-router-dom";
import {
  makeStyles,
  Grid,
  Button,
  AppBar,
  Tabs,
  Tab,
  CircularProgress,
  Paper,
} from "@material-ui/core";
import { useTheme } from "@material-ui/core/styles";

// UI Scope
import { LoadingScreen, Modal, Back } from ".";
import TabPanel from "./TabPanel";
import Form from "./Form";

// App Scope
import { firebase, auth } from "../managers";
import affise from "../apis/affise";

function a11yProps(index) {
  return {
    id: `full-width-tab-${index}`,
    "aria-controls": `full-width-tabpanel-${index}`,
  };
}

const useStyles = makeStyles(theme => ({
  buttonContainer: {
    marginTop: theme.spacing(2),
    height: "38px",
  },
  modal: {
    overflow: "hidden",
    padding: theme.spacing(0),
    maxWidth: "80%",
  },
}));

const Credentials = props => {
  const {
    toggle,
    // fetch,
    translations
  } = props;

  const [tab, setTab] = useState(0);
  const [errors, setErrors] = useState({});
  const [loading, setLoading] = useState(true);
  const [redirect, setRedirect] = useState(false);

  const theme = useTheme();
  const classes = useStyles();

  useEffect(() => {
    translations && setLoading(false);
  }, [translations]);

  const handleChange = (event, newValue) => {
    setTab(newValue);
  };
  const handleChangeIndex = index => {
    setTab(index);
  };
  const signInSubmit = ({ login, password }) => {
    setLoading(true);
    let newErrors;
    auth
      .signInWithEmailAndPassword(login, password)
      .then(user => {
        setLoading(false);
        toggle();
      })
      .catch(err => {
        if (err.code === "auth/invalid-email")
          newErrors = {
            ...newErrors,
            login: "Format de l'email incorrect",
          };
        else if (err.code === "auth/wrong-password")
          newErrors = {
            ...newErrors,
            login: "Email ou mot de passe incorrect",
            password: "Mot de passe ou email incorrect",
          };
        else if (err.code === "auth/user-not-found")
          newErrors = {
            ...newErrors,
            login: "Email ou mot de passe incorrect",
            password: "Mot de passe ou email incorrect",
          };
        else {
          console.log(err);
        }
        setLoading(false);
        return setErrors({ ...newErrors });
      });
  };
  const registerSubmit = async ({ regLogin, regPassword }) => {
    setLoading(true);
    const affiliate_id = await affise.createAffiliate(regLogin, regPassword);
    let newErrors;
    auth
      .createUserWithEmailAndPassword(regLogin, regPassword)
      .then(authUser => {
        firebase
          .database()
          .ref("Influencers/" + authUser.user.uid + "/Profil")
          .set({
            email: regLogin,
            new: true,
            warned: false,
            private: true,
            created: new Date().toJSON(),
            affiliate_id,
          })
          .then(setLoading(false), setRedirect("/wishlists"), toggle());
      })
      .catch(err => {
        if (err.code === "auth/invalid-email")
          newErrors = {
            ...newErrors,
            regLogin: "Format de l'email incorrect",
          };
        else if (err.code === "auth/argument-error")
          newErrors = {
            ...newErrors,
            regLogin: "Format de l'email incorrect",
          };
        else if (err.code === "auth/weak-password")
          newErrors = {
            ...newErrors,
            regPassword: "6 caractères minimum",
          };
        else if (err.code === "auth/email-already-in-use")
          newErrors = {
            ...newErrors,
            regLogin: "Cette adresse mail est déjà utilisée",
          };
        else {
          console.log(err);
        }
        setLoading(false);
        return setErrors({ ...newErrors });
      });
  };

  let signInFormFields;
  let registerFormFields;

  if (translations) {
    signInFormFields = [
      {
        id: "login",
        type: "login",
        name: translations.Form.fields.email,
        required: true,
      },
      {
        id: "password",
        type: "password",
        name: translations.Form.fields.password,
        required: true,
      },
    ];
    registerFormFields = [
      {
        id: "regLogin",
        type: "login",
        name: translations.Form.fields.email,
        required: true,
      },
      {
        id: "regPassword",
        type: "password",
        name: translations.Form.fields.password,
        required: true,
      },
    ];
  }

  return (
    <>
      {redirect && <Redirect push to={redirect} />}
      <Modal center>
        <Back onClick={toggle} color='white' size='large' />
        {!loading ? (
          <Paper elevation={1} className={classes.modal}>
            <AppBar position='static' color='primary'>
              <Tabs
                value={tab}
                onChange={handleChange}
                indicatorColor='secondary'
                textColor='inherit'
                variant='fullWidth'
                aria-label='Credentials'
              >
                <Tab
                  label={translations.Form.buttons.login}
                  {...a11yProps(0)}
                />
                <Tab
                  label={translations.Credentials.Register.register}
                  {...a11yProps(1)}
                />
              </Tabs>
            </AppBar>
            <SwipeableViews
              axis={theme.direction === "rtl" ? "x-reverse" : "x"}
              index={tab}
              onChangeIndex={handleChangeIndex}
            >
              <TabPanel value={tab} index={0} dir={theme.direction}>
                <Form
                  name='signIn'
                  variant='signIn'
                  onSubmit={signInSubmit}
                  fields={signInFormFields}
                  credErrors={errors}
                  container
                  direction='column'
                  style={{
                    height: "100%",
                    width: "100%",
                    alignContent: "center",
                    justifyContent: "center",
                  }}
                >
                  <Grid
                    container
                    justify='center'
                    alignItems='center'
                    className={classes.buttonContainer}
                  >
                    {loading ? (
                      <CircularProgress size={35} />
                    ) : (
                        <Button
                          fullWidth
                          type='submit'
                          variant='contained'
                          color='primary'
                        >
                          {translations.Form.buttons.login}
                        </Button>
                      )}
                  </Grid>
                </Form>
                <Grid
                  container
                  justify='center'
                  alignItems='center'
                  className={classes.buttonContainer}
                >
                  <Button
                    fullWidth
                    variant='contained'
                    color='primary'
                    onClick={toggle}
                  >
                    {translations.Form.buttons.cancel}
                  </Button>
                </Grid>
              </TabPanel>
              <TabPanel value={tab} index={1} dir={theme.direction}>
                <Form
                  name='register'
                  variant='register'
                  onSubmit={registerSubmit}
                  fields={registerFormFields}
                  credErrors={errors}
                  container
                  direction='column'
                  style={{
                    height: "100%",
                    width: "100%",
                    alignContent: "center",
                    justifyContent: "center",
                  }}
                >
                  <Grid
                    container
                    justify='center'
                    alignItems='center'
                    className={classes.buttonContainer}
                  >
                    {loading ? (
                      <CircularProgress size={35} />
                    ) : (
                        <Button
                          type='submit'
                          fullWidth
                          variant='contained'
                          color='primary'
                          className={classes.formButton}
                        >
                          {translations.Form.buttons.next}
                        </Button>
                      )}
                  </Grid>
                </Form>
                <Grid
                  container
                  justify='center'
                  alignItems='center'
                  className={classes.buttonContainer}
                >
                  <Button
                    fullWidth
                    variant='contained'
                    color='primary'
                    onClick={toggle}
                  >
                    {translations.Form.buttons.cancel}
                  </Button>
                </Grid>
              </TabPanel>
            </SwipeableViews>
          </Paper>
        ) : (
            <LoadingScreen />
          )}
      </Modal>
    </>
  );
};

Credentials.propTypes = {
  toggle: PropTypes.func.isRequired,
};

export default Credentials;
