import React, { useState, useEffect } from "react";
import {
    makeStyles,
    Grid,
    Box,
    Typography
} from "@material-ui/core";
import countriesDB from "../../assets/utils/countries";
import ExpandLessIcon from "@material-ui/icons/ExpandLess";
import ExpandMoreIcon from "@material-ui/icons/ExpandMore";
import SortIcon from "@material-ui/icons/Sort";
import placeholder from "../../assets/img/placeholder.png";
import { useTranslation } from "react-i18next";

const useStyles = makeStyles((theme) => {
    return {
        overallBoxContainer: {
            width: "95%",
            display: "flex",
            justifyContent: "center",
            marginBottom: "30px",
            marginTop: "30px",
        },
        overallBoxStyles: {
            backgroundColor: "white",
            borderRadius: "35px",
            boxShadow: "0 7px 30px 0 rgba(88, 165, 211, 0.16)",
            width: "100%",
        },
        influencerInfosBox: {
            height: "200px",
        },
        influencerImage: {
            objectFit: "cover",
            width: "90%",
            height: "200px",
            margin: "10px",
            borderRadius: "35px",
            boxShadow: "0 7px 30px 0 rgba(88, 165, 211, 0.16)",
        },
        smallBox: {
            boxShadow: "0 7px 30px 0 rgba(58, 111, 142, 0.05)",
            borderRadius: "41px",
            width: "300px",
            height: "150px",
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            margin: 10,
        },
        tableRowContent: {
            fontFamily: "Poppins",
        },
        influencerInstagram: {
            fontFamily: "Poppins",
            fontSize: "25px",
            color: "#000000",
            textDecoration: "none",
        },
        influencerStatsTitles: {
            fontFamily: "Poppins",
            fontSize: "14px",
            fontWeight: 500,
            color: "#000000",
        },
        influencerStats: {
            fontFamily: "Poppins",
            fontSize: "16px",
            fontWeight: 900,
            color: "#000000",
        },
        spacement: {
            marginTop: "15px",
            marginBottom: "15px",
        },
        tableHeader: {
            fontFamily: "Poppins",
            fontSize: "14px",
            textAlign: "center",
            "&:hover": {
                cursor: "pointer",
            },
        },
        influencerBoxContainer: {
            marginTop: "10px",
            marginBottom: "10px",
            //minWidth: "1050px",
            minHeight: "60px",
        },
        influencerBox: {
            display: "flex",
            width: "100%",
            backgroundColor: "white",
            height: "100px",
            justify: "center",
            borderRadius: "27px",
            boxShadow: "1px 2px 4px 2px rgb(88 165 211 / 16%)",
        },
        influencerName: {
            fontFamily: "Poppins",
            fontSize: "22px",
            fontWeight: "bold",
            color: "#000000",
            wordBreak: "break-word",
        },
        tableHeaderIcon: {
            "&:hover": {
                cursor: "pointer",
            },
        },
        productImage: {
            height: "100px",
            objectFit: "contain",
            "&:hover": {
                cursor: "pointer",
            },
        },
    };
});

const InfluencerConversionStats = (props) => {
    const { t, i18n } = useTranslation("common");
    const classes = useStyles();
    const [sortHelper, setSortHelper] = useState([1, 0, 0, 0]);
    const [sortEvent, setSortEvent] = useState(0);
    const [conversionsToRender, setConversionsToRender] = useState([]);
    const [sortedConversions, setSortedConversions] = useState([]);

    const {
        periodConversions,
        influencer,
        brandOfferId
    } = props;

    useEffect(() => {
        if (periodConversions && influencer) {
            if (brandOfferId) {
                setConversionsToRender(periodConversions.filter(item => (item.offerId === brandOfferId && item.influencer === influencer.key)));
            } else {
                setConversionsToRender(periodConversions.filter(item => item.influencer === influencer.influencerData.key));
            }
        }
    }, [periodConversions, influencer, brandOfferId]);

    useEffect(() => {
        let indexAsc = sortHelper.indexOf(1);
        let indexDesc = sortHelper.indexOf(-1);
        if (indexAsc !== -1) {
            const sortTypeAsc = (indexAsc, tableToWorkOn) => {
                switch (indexAsc) {
                    case 0:
                        tableToWorkOn.sort((a, b) => {
                            if (a.createdAt > b.createdAt) return -1;
                            if (a.createdAt < b.createdAt) return 1;
                            return 0;
                        });
                        break;
                    case 1:
                        tableToWorkOn.sort((a, b) => {
                            if (a.amount > b.amount) return -1;
                            if (a.amount < b.amount) return 1;
                            return 0;
                        });
                        break;
                    case 2:
                        tableToWorkOn.sort((a, b) => {
                            if (a.commission > b.commission) return -1;
                            if (a.commission < b.commission) return 1;
                            return 0;
                        });
                        break;
                    case 3:
                        tableToWorkOn.sort((a, b) => {
                            if (a.countryCode > b.countryCode) return -1;
                            if (a.countryCode < b.countryCode) return 1;
                            return 0;
                        });
                        break;
                    default:
                        break;
                }
            };
            sortTypeAsc(indexAsc, conversionsToRender);
        } else {
            const sortTypeDesc = (indexDesc, tableToWorkOn) => {
                switch (indexDesc) {
                    case 0:
                        tableToWorkOn.sort((a, b) => {
                            if (a.createdAt > b.createdAt) return 1;
                            if (a.createdAt < b.createdAt) return -1;
                            return 0;
                        });
                        break;
                    case 1:
                        tableToWorkOn.sort((a, b) => {
                            if (a.amount > b.amount) return 1;
                            if (a.amount < b.amount) return -1;
                            return 0;
                        });
                        break;
                    case 2:
                        tableToWorkOn.sort((a, b) => {
                            if (a.commission > b.commission) return 1;
                            if (a.commission < b.commission) return -1;
                            return 0;
                        });
                        break;
                    case 3:
                        tableToWorkOn.sort((a, b) => {
                            if (a.countryCode > b.countryCode) return 1;
                            if (a.countryCode < b.countryCode) return -1;
                            return 0;
                        });
                        break;
                    default:
                        break;
                }
            };
            sortTypeDesc(indexDesc, conversionsToRender);
        }
        setSortEvent((s) => s + 1);
    }, [conversionsToRender, sortHelper]);

    useEffect(() => {
        setSortedConversions(conversionsToRender)
    }, [conversionsToRender, sortEvent])

    return (
        <Grid container item direction="row" xs={12} alignItems="center" justify="center">
            <div style={{ overflowX: "auto", width: "100%", marginTop: "30px", }}>
                <Grid container item direction="row" xs={12} style={{ minWidth: "1050px" }}>
                    <Grid container item direction="row">
                        <Grid container item alignItems="center" justify="center" style={{ width: "40%" }} onClick={() => {
                            sortHelper[0] === 0
                                ? setSortHelper([1, 0, 0, 0])
                                : setSortHelper([-sortHelper[0], 0, 0, 0]);
                        }}>
                            <Typography className={classes.tableHeader}>
                                {t("ADMIN.VIEWS.STATISTICS.INFLUENCER_POPUP.CONVERSIONS_TABLE.DATE_HEADER")}
                            </Typography>
                            {sortHelper[0] === 1 ? (
                                <ExpandLessIcon className={classes.tableHeaderIcon} />
                            ) : sortHelper[0] === -1 ? (
                                <ExpandMoreIcon className={classes.tableHeaderIcon} />
                            ) : (
                                        <SortIcon className={classes.tableHeaderIcon} />
                                    )}
                        </Grid>
                        <Grid container justify="center" alignItems="center" style={{ width: "15%" }} onClick={() => {
                            sortHelper[1] === 0
                                ? setSortHelper([0, 1, 0, 0])
                                : setSortHelper([0, -sortHelper[1], 0, 0]);
                        }}>
                            <Typography className={classes.tableHeader}>
                                {t("ADMIN.VIEWS.STATISTICS.INFLUENCER_POPUP.CONVERSIONS_TABLE.TURNOVER_HEADER")}
                            </Typography>
                            {sortHelper[1] === 1 ? (
                                <ExpandLessIcon className={classes.tableHeaderIcon} />
                            ) : sortHelper[1] === -1 ? (
                                <ExpandMoreIcon className={classes.tableHeaderIcon} />
                            ) : (
                                        <SortIcon className={classes.tableHeaderIcon} />
                                    )}
                        </Grid>
                        <Grid container justify="center" alignItems="center" style={{ width: "15%" }}>
                            <Typography className={classes.tableHeader}>
                                {t("ADMIN.VIEWS.STATISTICS.INFLUENCER_POPUP.CONVERSIONS_TABLE.PRODUCT_HEADER")}
                            </Typography>
                        </Grid>
                        <Grid container justify="center" alignItems="center" style={{ width: "15%" }} onClick={() => {
                            sortHelper[2] === 0
                                ? setSortHelper([0, 0, 1, 0])
                                : setSortHelper([0, 0, -sortHelper[2], 0]);
                        }}>
                            <Typography className={classes.tableHeader}>
                                {t("ADMIN.VIEWS.STATISTICS.INFLUENCER_POPUP.CONVERSIONS_TABLE.COMMISSIONS_HEADER")}
                            </Typography>
                            {sortHelper[2] === 1 ? (
                                <ExpandLessIcon className={classes.tableHeaderIcon} />
                            ) : sortHelper[2] === -1 ? (
                                <ExpandMoreIcon className={classes.tableHeaderIcon} />
                            ) : (
                                        <SortIcon className={classes.tableHeaderIcon} />
                                    )}
                        </Grid>
                        <Grid container justify="center" alignItems="center" style={{ width: "15%" }} onClick={() => {
                            sortHelper[3] === 0 ? setSortHelper([0, 0, 0, 1]) : setSortHelper([0, 0, 0, -sortHelper[3]]);
                        }}>
                            <Typography className={classes.tableHeader}>
                                {t("ADMIN.VIEWS.STATISTICS.INFLUENCER_POPUP.CONVERSIONS_TABLE.COUNTRY_HEADER")}
                            </Typography>
                            {sortHelper[3] === 1 ? (
                                <ExpandLessIcon className={classes.tableHeaderIcon} />
                            ) : sortHelper[3] === -1 ? (
                                <ExpandMoreIcon className={classes.tableHeaderIcon} />
                            ) : (
                                        <SortIcon className={classes.tableHeaderIcon} />
                                    )}
                        </Grid>
                    </Grid>
                    {sortedConversions.map((conversion) => {
                        return (
                            <Grid container item direction="row" justify="center" className={classes.influencerBoxContainer} key={conversion.key}>
                                <Box className={classes.influencerBox}>
                                    <Grid container item direction="row" style={{ width: "100%" }}>
                                        <Grid container item justify="flex-start" alignItems="center" style={{ width: "40%" }}>
                                            <Typography className={classes.tableRowContent} style={{ paddingLeft: "20px" }}>
                                                {new Date(conversion.createdAt * 1000).toLocaleDateString()}{" "}
                                                {new Date(conversion.createdAt * 1000).toLocaleTimeString({ hour12: false })}
                                            </Typography>
                                        </Grid>
                                        <Grid container item justify="center" alignItems="center" style={{ width: "15%" }}>
                                            <Typography className={classes.tableRowContent}>
                                                {Math.round(conversion.amount * 100) / 100}{" "}€
                                            </Typography>
                                        </Grid>
                                        <Grid container item justify="center" alignItems="center" style={{ width: "15%" }}>
                                            {conversion.articleImgUrl === "" || conversion.articleImgUrl === undefined || conversion.articleImgUrl === null
                                                ? (
                                                    <img className={classes.productImage} src={placeholder} alt="" />
                                                ) : (
                                                    <a target="_blank" href={conversion.articlePathUrl} rel="noopener noreferrer">
                                                        <img className={classes.productImage} src={conversion.articleImgUrl} alt={conversion.articlePathUrl} />
                                                    </a>
                                                )}
                                        </Grid>
                                        <Grid container item justify="center" alignItems="center" style={{ width: "15%" }}>
                                            <Typography className={classes.tableRowContent}>
                                                {Math.round(conversion.commission * 100) / 100}{" "}€
                                            </Typography>
                                        </Grid>
                                        <Grid container item justify="center" alignItems="center" style={{ width: "15%" }} >
                                            <Typography className={classes.tableRowContent} >
                                                {
                                                    (i18n.language === 'fr-FR' || i18n.language === 'fr')
                                                        ? countriesDB.filter((element) => conversion.countryCode === element.code)[0].nameFr
                                                        : countriesDB.filter((element) => conversion.countryCode === element.code)[0].name
                                                }
                                            </Typography>
                                        </Grid>
                                    </Grid>
                                </Box>
                            </Grid>
                        );
                    })}
                </Grid>
            </div>
        </Grid>
    );
};

export default InfluencerConversionStats;
