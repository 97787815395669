import { makeStyles } from "@material-ui/styles";

const useStyles = makeStyles((theme) => {
  return {
    content: {
      flexGrow: 1,
      display: "flex",
    },
    wrapper: {
      position: "relative",
      minHeight: "100%",
    },
    back: {
      width: "30px",
      position: "absolute",
      top: "10px",
      right: "0px",
      color: "black",
    },
    masonryContainer: {
      height: "100%",
      width: "100%",
    },
    pointerHover: {
      "&:hover": {
        cursor: "pointer",
      },
    },
  };
});

export const useHoverStyles = makeStyles(
  (theme) => ({
    root: {
      display: "flex",
      height: "100%",
      width: "100%",
    },
    effect: {
      margin: "auto",
      height: "0%",
      width: "0%",
      overflow: "hidden",
      backgroundColor: "blue",
      transition: "height 0.3s, width 0.3s",
      flexWrap: "nowrap",
    },
    effectHover: {
      height: "100%",
      width: "100%",
      cursor: "pointer",
      transition: "height 0.4s, width 0.4s",
    },
  })
);

export default useStyles;
