import React, { useMemo, useState, useEffect } from "react";
import { Chart } from "react-charts";
import { makeStyles } from "@material-ui/core";

// App Scope
import { MyDate } from "../utils";

const useStyles = makeStyles(theme => ({
  chartContainer: {
    height: "100%",
    width: "100%",
    padding: "0 10px",
    "& table": {
      backgroundColor: "transparent",
    },
  },
}));

const ConversionsChart = props => {
  const { conversions, clicks, period } = props;

  const [data, setData] = useState([]);
  const [ordinals, setOrdinals] = useState([]);

  const classes = useStyles();

  useEffect(() => {
    const days = (period.end.getTime() - period.start.getTime()) / 86400000;
    let ords = [];
    for (let i = 0; i < days; i++) {
      ords[i] = new MyDate(period.start).addDays(i);
    }
    setOrdinals(ords);
  }, [period]);

  useEffect(() => {
    let clis = [];
    let cons = [];
    for (let i = 0; i < ordinals.length; i++) {
      const ord = `${
        ordinals[i].getDate() > 9
          ? ordinals[i].getDate()
          : `0${ordinals[i].getDate()}`
      }/${
        ordinals[i].getMonth() > 9
          ? ordinals[i].getMonth()
          : `0${ordinals[i].getMonth() + 1}`
      }`;
      cons.push({
        x: ord,
        y: conversions.filter(con => {
          const createdAt = con.createdAt * 1000;
          return (
            new MyDate(ordinals[i]).dayStart().getTime() < createdAt &&
            createdAt < new MyDate(ordinals[i]).dayEnd().getTime()
          );
        }).length,
      });
      clis.push({
        x: ord,
        y: clicks.filter(cli => {
          return (
            new MyDate(ordinals[i]).dayStart().getTime() < cli.createdAt &&
            cli.createdAt < new MyDate(ordinals[i]).dayEnd().getTime()
          );
        }).length,
      });
    }
    setData([
      {
        label: "Clicks",
        data: clis,
      },
      {
        label: "Conversions",
        data: cons,
      },
    ]);
  }, [clicks, conversions, ordinals]);


  const series = useMemo(() => ({ type: "line" }), []);
  const axes = useMemo(() => {
    return [
      { primary: true, position: "bottom", type: "ordinal", show: false },
      { position: "left", type: "linear", stacked: false },
    ];
  }, []);

  return (
    <div className={classes.chartContainer}>
      <Chart
        data={data}
        series={series}
        axes={axes}
        primaryCursor
        secondaryCursor
        tooltip
      />
    </div>
  );
};

export default ConversionsChart;
