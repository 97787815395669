import axios from 'axios';
import { database } from './firebase';

const url = (process.env.REACT_APP_STAGE === 'dev') ? 'http://localhost:5001/shopmyinfluens/us-central1' : 'https://us-central1-shopmyinfluens.cloudfunctions.net';

const clicksManager = {
  getClicksPerProduct: (from, to, articleId, timeZoneOffset = 0) => {
    return axios.get(`${url}/api/clicksPerProduct?from=${from}&to=${to}&articleId=${articleId}&timezoneOffset=${timeZoneOffset}`);
  },
  getClicksPerBrand: (from, to, offerId, timeZoneOffset = 0) => {
    return axios.get(`${url}/api/clicksPerBrand?from=${from}&to=${to}&offerId=${offerId}&timezoneOffset=${timeZoneOffset}`);
  },
  getClicksPerInfluencer: (from, to, influencerId, offerId = undefined, timeZoneOffset = 0) => {
    return axios.get(`${url}/api/clicksPerInfluencer?from=${from}&to=${to}&influencerId=${influencerId}&offerId=${offerId}&timezoneOffset=${timeZoneOffset}`);
  },
  getClicksPerPeriod: (from, to, timeZoneOffset = 0) => {
    return axios.get(`${url}/api/clicksPerPeriod?from=${from}&to=${to}&timezoneOffset=${timeZoneOffset}`)
  },

  addClick: (influencerId, articleId, offerId) => {
    return axios.post(`${url}/clicks/click`, {
      influencerId,
      articleId,
      offerId
    });
  },

  addWishlistClick: async (wishlistId) => {
    let date = new Date();
    let previousWishlistClickValues;
    let updates = {};
    await database.ref(`wishlists/${wishlistId}/stats/visites`).once('value').then(snap => {
      previousWishlistClickValues = snap.val();
      if (previousWishlistClickValues === null) previousWishlistClickValues = {};
    });
    updates[`wishlists/${wishlistId}/stats/visites/totalClicks/clicksNumber`] =
      (previousWishlistClickValues['totalClicks'] === undefined)
        ? 1
        : previousWishlistClickValues['totalClicks']['clicksNumber'] + 1;
    ////

    updates[`wishlists/${wishlistId}/stats/visites/${date.getFullYear()}/yearlySum/clicksNumber`] =
      (previousWishlistClickValues[date.getFullYear()] === undefined)
        ? 1
        : previousWishlistClickValues[date.getFullYear()]['yearlySum']['clicksNumber'] + 1;
    ///

    updates[`wishlists/${wishlistId}/stats/visites/${date.getFullYear()}/${date.getMonth()}/monthlySum/clicksNumber`] =
      (previousWishlistClickValues[date.getFullYear()] === undefined)
        ? 1
        : (previousWishlistClickValues[date.getFullYear()][date.getMonth()] === undefined)
          ? 1
          : previousWishlistClickValues[date.getFullYear()][date.getMonth()]['monthlySum']['clicksNumber'] + 1;

    updates[`wishlists/${wishlistId}/stats/visites/${date.getFullYear()}/${date.getMonth()}/${date.getDate()}/clicksNumber`] =
      (previousWishlistClickValues[date.getFullYear()] === undefined)
        ? 1
        : (previousWishlistClickValues[date.getFullYear()][date.getMonth()] === undefined)
          ? 1
          : (previousWishlistClickValues[date.getFullYear()][date.getMonth()][date.getDate()] === undefined)
            ? 1
            : (previousWishlistClickValues[date.getFullYear()][date.getMonth()][date.getDate()]['clicksNumber'] === undefined)
              ? 1
              : previousWishlistClickValues[date.getFullYear()][date.getMonth()][date.getDate()]['clicksNumber'] + 1;

    return database.ref().update(updates).catch(err => console.log(err));
  }
}

export default clicksManager