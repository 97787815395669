const initState = {
    brandTableSortHelper: [1, 0, 0, 0, 0, 0],
    brandTablePageNumber: 1,
    brandTablePageSize: 10
};

const agencyStatisticsReducer = (state = initState, action) => {
    switch (action.type) {
        case 'UPDATE_AGENCY_STATS_BRAND_TABLE_SORT_HELPER':
            return {
                ...state,
                brandTableSortHelper: action.sortHelper
            };
        case 'UPDATE_AGENCY_STATS_BRAND_TABLE_PAGE_NUMBER':
            return {
                ...state,
                brandTablePageNumber: action.pageNumber
            };
        case 'UPDATE_AGENCY_STATS_BRAND_TABLE_PAGE_SIZE':
            return {
                ...state,
                brandTablePageSize: action.pageSize
            };
        default:
            return state;
    }
};

export default agencyStatisticsReducer;