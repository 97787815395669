import React, { useState, useEffect } from "react";
import {
    makeStyles,
    Grid,
    Box,
    Typography,
    IconButton,
} from "@material-ui/core";
import placeholderSales from "../../assets/img/placeholder_sales.png";
import EuroSymbolIcon from "@material-ui/icons/EuroSymbol";
import SVGIcon from "../../assets/SVGIcons/index";
import CloseIcon from "@material-ui/icons/Close";
import ArrowRightIcon from "@material-ui/icons/ArrowRight";
import ExpandLessIcon from "@material-ui/icons/ExpandLess";
import ExpandMoreIcon from "@material-ui/icons/ExpandMore";
import SortIcon from "@material-ui/icons/Sort";
import NavigateBeforeIcon from '@material-ui/icons/NavigateBefore';
import { useTranslation } from "react-i18next";
import InfluencerConversionStats from "./InfluencerConversionStats";

const useStyles = makeStyles((theme) => {
    return {
        overallBoxContainer: {
            width: "95%",
            display: "flex",
            justifyContent: "center",
            marginBottom: "30px",
            marginTop: "30px",
        },
        overallBoxStyles: {
            backgroundColor: "white",
            borderRadius: "35px",
            boxShadow: "0 7px 30px 0 rgba(88, 165, 211, 0.16)",
            width: "100%",
        },
        influencerImageBox: {
            width: "inherit",
            // height: 'inherit',
            margin: "10px",
            borderRadius: "35px",
            boxShadow: "0 7px 30px 0 rgba(88, 165, 211, 0.16)",
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
        },
        influencerInfosBox: {
            height: "200px",
        },
        influencerImage: {
            objectFit: "cover",
            width: "200px",
            height: "200px",
            margin: "10px",
            borderRadius: "35px",
            boxShadow: "0 7px 30px 0 rgba(88, 165, 211, 0.16)",
        },
        smallBox: {
            boxShadow: "0 7px 30px 0 rgba(58, 111, 142, 0.05)",
            borderRadius: "41px",
            width: "300px",
            height: "150px",
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            margin: 10,
        },
        tableRowContent: {
            fontFamily: "Poppins",
        },
        influencerEmail: {
            fontFamily: "Poppins",
            fontSize: "14px",
            fontWeight: 200,
            color: "#000000",
        },
        influencerInstagram: {
            fontFamily: "Poppins",
            fontSize: "25px",
            color: "#000000",
            textDecoration: "none",
        },
        influencerStatsTitles: {
            fontFamily: "Poppins",
            fontSize: "14px",
            fontWeight: 500,
            color: "#000000",
        },
        influencerStats: {
            fontFamily: "Poppins",
            fontSize: "16px",
            fontWeight: 900,
            color: "#000000",
        },
        spacement: {
            marginTop: "15px",
            marginBottom: "15px",
        },
        tableHeader: {
            fontFamily: "Poppins",
            fontSize: "14px",
            textAlign: "center",
            "&:hover": {
                cursor: "pointer",
            },
        },
        influencerBoxContainer: {
            marginTop: "10px",
            marginBottom: "10px",
            //minWidth: "1050px",
            minHeight: "60px",
        },
        influencerBox: {
            display: "flex",
            width: "100%",
            backgroundColor: "white",
            height: "100px",
            justify: "center",
            borderRadius: "27px",
            boxShadow: "1px 2px 4px 2px rgb(88 165 211 / 16%)",
        },
        influencerName: {
            fontFamily: "Poppins",
            fontSize: "22px",
            fontWeight: "bold",
            color: "#000000",
            wordBreak: "break-word",
        },
        influencerImageTable: {
            width: "200px",
            height: "100px",
            borderTopLeftRadius: "27px",
            borderBottomLeftRadius: "27px",
            objectFit: "fill",
        },
        textDetail: {
            color: "#c8c8c8",
            fontFamily: "Poppins",
            fontSize: "15px",
            "&:hover": {
                cursor: "pointer",
            },
        },
        tableHeaderIcon: {
            "&:hover": {
                cursor: "pointer",
            },
        },
        noSalesTextPlaceholder: {
            fontFamily: "Poppins",
            fontSize: "16px",
            margin: 10,
            color: "#1b4c61",
            textAlign: "center",
        },
    };
});

const InfluencerAgentsStatsModal = (props) => {
    const { t } = useTranslation("common");
    const classes = useStyles();
    const [sortHelper, setSortHelper] = useState([1, 0, 0, 0]);
    const [sortEvent, setSortEvent] = useState(0);
    const [influencerAgent, setInfluencerAgent] = useState({});
    const [influencers, setInfluencers] = useState([]);
    const [sortedInfluencers, setSortedInfluencers] = useState([]);
    const [influencerStatsToView, setInfluencerStatsToView] = useState(null);
    const [conversionsStatsMode, setConversionsStatsMode] = useState(false);

    const {
        popupData,
        periodConversions
    } = props;

    const returnBack = () => {
        setConversionsStatsMode(false);
    }

    const openPopup = (data) => {
        setInfluencerStatsToView(data);
        setConversionsStatsMode(true);
    };

    const closePopup = () => {
        props.close(true);
    };

    useEffect(() => {
        if (popupData) {
            setInfluencerAgent(popupData.influencerAgentData);
            let influencers = [];
            Object.entries(popupData).forEach(([key, value]) => {
                if (key !== 'influencerAgentData') influencers.push(value);
            });
            setInfluencers(influencers);
        }
    }, [popupData]);

    useEffect(() => {
        let indexAsc = sortHelper.indexOf(1);
        let indexDesc = sortHelper.indexOf(-1);
        if (indexAsc !== -1) {
            const sortTypeAsc = (indexAsc, tableToWorkOn) => {
                switch (indexAsc) {
                    case 0:
                        tableToWorkOn.sort((a, b) => {
                            if (a.influencerData.name > b.influencerData.name) return -1;
                            if (a.influencerData.name < b.influencerData.name) return 1;
                            return 0;
                        });
                        break;
                    case 1:
                        tableToWorkOn.sort((a, b) => {
                            if (a.amount > b.amount) return -1;
                            if (a.amount < b.amount) return 1;
                            return 0;
                        });
                        break;
                    case 2:
                        tableToWorkOn.sort((a, b) => {
                            if (a.salesNumber > b.salesNumber) return -1;
                            if (a.salesNumber < b.salesNumber) return 1;
                            return 0;
                        });
                        break;
                    case 3:
                        tableToWorkOn.sort((a, b) => {
                            if (a.commission > b.commission) return -1;
                            if (a.commission < b.commission) return 1;
                            return 0;
                        });
                        break;
                    default:
                        break;
                }
            };
            sortTypeAsc(indexAsc, influencers);
        } else {
            const sortTypeDesc = (indexDesc, tableToWorkOn) => {
                switch (indexDesc) {
                    case 0:
                        tableToWorkOn.sort((a, b) => {
                            if (a.influencerData.name > b.influencerData.name) return 1;
                            if (a.influencerData.name < b.influencerData.name) return -1;
                            return 0;
                        });
                        break;
                    case 1:
                        tableToWorkOn.sort((a, b) => {
                            if (a.amount > b.amount) return 1;
                            if (a.amount < b.amount) return -1;
                            return 0;
                        });
                        break;
                    case 2:
                        tableToWorkOn.sort((a, b) => {
                            if (a.salesNumber > b.salesNumber) return 1;
                            if (a.salesNumber < b.salesNumber) return -1;
                            return 0;
                        });
                        break;
                    case 3:
                        tableToWorkOn.sort((a, b) => {
                            if (a.commission > b.commission) return 1;
                            if (a.commission < b.commission) return -1;
                            return 0;
                        });
                        break;
                    default:
                        break;
                }
            };
            sortTypeDesc(indexDesc, influencers);
        }
        setSortEvent((s) => s + 1);
    }, [influencers, sortHelper]);

    useEffect(() => {
        setSortedInfluencers(influencers);
    }, [influencers, sortEvent])

    return (
        <Grid container item direction="row" justify="center">
            <Grid container item direction="row" className={classes.overallBoxContainer}>
                <Box className={classes.overallBoxStyles}>
                    <Grid container direction="column" alignItems="center" justify="center" style={{ display: "flex" }}>
                        <Grid container item direction="row" justify={(conversionsStatsMode) ? 'space-between' : 'flex-end'} sm={12}>
                            {
                                (conversionsStatsMode)
                                    ? <IconButton onClick={returnBack} style={{ marginTop: "30px", marginLeft: "30px", color: "#000" }}>
                                        <NavigateBeforeIcon fontSize="large" />
                                    </IconButton>
                                    : <></>
                            }
                            <IconButton onClick={closePopup} style={{ marginTop: "30px", marginRight: "30px", color: "#000" }}>
                                <CloseIcon fontSize="large" />
                            </IconButton>
                        </Grid>
                        <Grid container item direction="row" justify="center" className={classes.spacement} >
                            <Grid container item direction="row" sm={11} justify="center">
                                <Grid container item direction="row" justify="center" alignItems="center" wrap="wrap">
                                    <Grid container item lg={4} md={8} sm={12} justify="center" >
                                        <img src={influencerAgent.image} alt={influencerAgent.name} className={classes.influencerImage} />
                                    </Grid>
                                    <Grid container item lg={2} md={4} sm={12} justify="center" alignItems="center" direction="column" className={classes.influencerInfosBox}>
                                        <Typography className={classes.influencerName}>
                                            {influencerAgent.name}
                                        </Typography>
                                    </Grid>
                                    <Grid container item justify="center" lg={2} md={4} sm={12} >
                                        <Box className={classes.smallBox}>
                                            <Grid container direction="row">
                                                <Grid container item xs={6} justify="center">
                                                    <SVGIcon name="ventes" width={"60px"} height={"60px"} />
                                                </Grid>
                                                <Grid container item xs={6} direction="column" justify="center" >
                                                    <Typography className={classes.influencerStatsTitles} >
                                                        {t("ADMIN.VIEWS.STATISTICS.INFLUENCER_AGENT_POPUP.SALES_TITLE_BOX")}
                                                    </Typography>
                                                    <Typography className={classes.influencerStats}>
                                                        {influencerAgent.salesNumber}
                                                    </Typography>
                                                </Grid>
                                            </Grid>
                                        </Box>
                                    </Grid>
                                    <Grid container item justify="center" lg={2} md={4} sm={12}>
                                        <Box className={classes.smallBox}>
                                            <Grid container direction="row">
                                                <Grid container item xs={6} justify="center">
                                                    <EuroSymbolIcon style={{ fontSize: "60px", color: "#ef7e3e" }} />
                                                </Grid>
                                                <Grid container item xs={6} direction="column" justify="center">
                                                    <Typography className={classes.influencerStatsTitles}>
                                                        {t("ADMIN.VIEWS.STATISTICS.INFLUENCER_POPUP.TURNOVER_TITLE_BOX")}
                                                    </Typography>
                                                    <Typography className={classes.influencerStats}>
                                                        {Math.round(influencerAgent.amount * 100) / 100} €
                                                    </Typography>
                                                </Grid>
                                            </Grid>
                                        </Box>
                                    </Grid>
                                    {/* <Grid container item justify="center" lg={2} md={4} sm={12}>
                                        <Box className={classes.smallBox}>
                                            <Grid container direction="row">
                                                <Grid container item xs={6} justify="center">
                                                    <TouchAppIcon style={{ fontSize: "60px", color: "#3568eb" }} />
                                                </Grid>
                                                <Grid container item xs={6} direction="column" justify="center">
                                                    {influencer.clicks === undefined ? (
                                                        <TrendLoading />
                                                    ) : (
                                                            <>
                                                                <Typography className={classes.influencerStatsTitles}>
                                                                    {t("TREND.VIEWS.STATISTICS.INFLUENCER_POPUP.CLICKS_TITLE_BOX")}
                                                                </Typography>
                                                                <Typography className={classes.influencerStats}>
                                                                    {influencer.clicks}
                                                                </Typography>
                                                            </>
                                                        )}
                                                </Grid>
                                            </Grid>
                                        </Box>
                                    </Grid> */}
                                </Grid>
                                {
                                    (conversionsStatsMode)
                                        ? <InfluencerConversionStats periodConversions={periodConversions} influencer={influencerStatsToView} />
                                        : sortedInfluencers.length !== 0 ? (
                                            <Grid container item direction="row" xs={12} alignItems="center" justify="center" >
                                                <div style={{ overflowX: "auto", width: "100%", marginTop: "30px" }}>
                                                    <Grid container item direction="row" xs={12} style={{ minWidth: "1050px" }} >
                                                        <Grid container item direction="row">
                                                            <Grid container item alignItems="center" justify="center" style={{ width: "40%" }} onClick={() => {
                                                                sortHelper[0] === 0
                                                                    ? setSortHelper([1, 0, 0, 0])
                                                                    : setSortHelper([-sortHelper[0], 0, 0, 0]);
                                                            }}>
                                                                <Typography className={classes.tableHeader}>
                                                                    {t("ADMIN.VIEWS.STATISTICS.INFLUENCER_AGENT_POPUP.NAME_HEADER")}
                                                                </Typography>
                                                                {sortHelper[0] === 1 ? (
                                                                    <ExpandLessIcon className={classes.tableHeaderIcon} />
                                                                ) : sortHelper[0] === -1 ? (
                                                                    <ExpandMoreIcon className={classes.tableHeaderIcon} />
                                                                ) : (
                                                                            <SortIcon className={classes.tableHeaderIcon} />
                                                                        )}
                                                            </Grid>
                                                            <Grid container justify="center" alignItems="center" style={{ width: "15%" }} onClick={() => {
                                                                sortHelper[1] === 0
                                                                    ? setSortHelper([0, 1, 0, 0])
                                                                    : setSortHelper([0, -sortHelper[1], 0, 0]);
                                                            }}>
                                                                <Typography className={classes.tableHeader}>
                                                                    {t("ADMIN.VIEWS.STATISTICS.INFLUENCER_AGENT_POPUP.AMOUNT_HEADER")}
                                                                </Typography>
                                                                {sortHelper[1] === 1 ? (
                                                                    <ExpandLessIcon className={classes.tableHeaderIcon} />
                                                                ) : sortHelper[1] === -1 ? (
                                                                    <ExpandMoreIcon className={classes.tableHeaderIcon} />
                                                                ) : (
                                                                            <SortIcon className={classes.tableHeaderIcon} />
                                                                        )}
                                                            </Grid>
                                                            <Grid container justify="center" alignItems="center" style={{ width: "15%" }} onClick={() => {
                                                                sortHelper[2] === 0
                                                                    ? setSortHelper([0, 0, 1, 0])
                                                                    : setSortHelper([0, 0, -sortHelper[2], 0]);
                                                            }}>
                                                                <Typography className={classes.tableHeader}>
                                                                    {t("ADMIN.VIEWS.STATISTICS.INFLUENCER_AGENT_POPUP.SALES_HEADER")}
                                                                </Typography>
                                                                {sortHelper[2] === 1 ? (
                                                                    <ExpandLessIcon className={classes.tableHeaderIcon} />
                                                                ) : sortHelper[2] === -1 ? (
                                                                    <ExpandMoreIcon className={classes.tableHeaderIcon} />
                                                                ) : (
                                                                            <SortIcon className={classes.tableHeaderIcon} />
                                                                        )}
                                                            </Grid>
                                                            <Grid container justify="center" alignItems="center" style={{ width: "15%" }} onClick={() => {
                                                                sortHelper[3] === 0
                                                                    ? setSortHelper([0, 0, 0, 1])
                                                                    : setSortHelper([0, 0, 0, -sortHelper[3]]);
                                                            }} >
                                                                <Typography className={classes.tableHeader}>
                                                                    {t("ADMIN.VIEWS.STATISTICS.INFLUENCER_AGENT_POPUP.COMMISSIONS_HEADER")}
                                                                </Typography>
                                                                {sortHelper[3] === 1 ? (
                                                                    <ExpandLessIcon className={classes.tableHeaderIcon} />
                                                                ) : sortHelper[3] === -1 ? (
                                                                    <ExpandMoreIcon className={classes.tableHeaderIcon} />
                                                                ) : (
                                                                            <SortIcon className={classes.tableHeaderIcon} />
                                                                        )}
                                                            </Grid>
                                                        </Grid>
                                                        {sortedInfluencers.map((influencer) => {
                                                            return (
                                                                <Grid container item direction="row" justify="center" className={classes.influencerBoxContainer} key={influencer.influencerData.key}>
                                                                    <Box className={classes.influencerBox}>
                                                                        <Grid container item direction="row" style={{ width: "100%" }} >
                                                                            <Grid container item justify="flex-start" alignItems="center" style={{ width: "40%" }}>
                                                                                <img src={influencer.influencerData.banner} alt="" className={classes.influencerImageTable} />
                                                                                <Typography className={classes.tableRowContent} style={{ paddingLeft: "20px" }} >
                                                                                    {influencer.influencerData.name}
                                                                                </Typography>
                                                                            </Grid>
                                                                            <Grid container item justify="center" alignItems="center" style={{ width: "15%" }} >
                                                                                <Typography className={classes.tableRowContent}>
                                                                                    {Math.round(influencer.amount * 100) / 100}{" "}€
                                                                                </Typography>
                                                                            </Grid>
                                                                            <Grid container item justify="center" alignItems="center" style={{ width: "15%" }}>
                                                                                <Typography className={classes.tableRowContent}>
                                                                                    {influencer.salesNumber}
                                                                                </Typography>
                                                                            </Grid>
                                                                            <Grid container item justify="center" alignItems="center" style={{ width: "15%" }}>
                                                                                <Typography className={classes.tableRowContent} >
                                                                                    {Math.round(influencer.commission * 100) / 100}{" "}€
                                          </Typography>
                                                                            </Grid>
                                                                            <Grid container item justify="center" alignItems="center" style={{ width: "15%" }}>
                                                                                <Typography className={classes.textDetail} onClick={() => openPopup(influencer)}>
                                                                                    {t("INFLUENCER_AGENT.VIEWS.STATISTICS.FIRST_POPUP_INFLUENCER.DETAILS")}
                                                                                </Typography>
                                                                                <ArrowRightIcon className={classes.tableHeaderIcon} style={{ color: "#c8c8c8" }} />
                                                                            </Grid>
                                                                        </Grid>
                                                                    </Box>
                                                                </Grid>
                                                            );
                                                        })}
                                                    </Grid>
                                                </div>
                                            </Grid>
                                        ) : (
                                                <Grid container direction="column" justify="center" alignItems="center" style={{ width: "95%", marginTop: "50px", marginBottom: "30px" }}>
                                                    <img alt="No Influencer" src={placeholderSales} height="100px" width="100px" />
                                                    <Typography className={classes.noSalesTextPlaceholder}>
                                                        {t("INFLUENCER_AGENT.VIEWS.STATISTICS.FIRST_POPUP_INFLUENCER.TEXT_PLACEHOLDER")}
                                                    </Typography>
                                                </Grid>
                                            )
                                }
                            </Grid>
                        </Grid>
                    </Grid>
                </Box>
            </Grid>
        </Grid>
    )
};

export default InfluencerAgentsStatsModal;