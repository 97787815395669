const initState = {
  influencers: [],
  nonPrivateInfluencers: [],
  influencersError: null,
  influencersPreparedToRender: [],
  influencersById: [],
  loadedinfluencerById: false,
  loadedInfluencer: false,
  loadedInfluencers: false,
  loadedNonPrivateInfluencers: false,
  calculatedInfluencers: false,
  loadingDeleteInfluencer: false,
}

const influencersReducer = (state = initState, action) => {
  switch (action.type) {
    case "FETCH_INFLUENCERS_SUCCESS":
      return {
        ...state,
        influencers: action.influencers,
        loadedInfluencers: true,
      };

    case "FETCH_INFLUENCERS_ERROR":
      return {
        ...state,
        influencersError: action.error,
      };

    case "UPDATE_INFLUENCER_BY_ID_SUCCESS":
      let newInfluencers = state.influencers.map((influencer) => {
        if (influencer.key === action.id) {
          let updatedInfluencer = {
            ...influencer,
          };
          Object.entries(action.updates).forEach(([keyToUpdate, newValue]) => {
            updatedInfluencer[keyToUpdate] = newValue;
          });
          return updatedInfluencer;
        } else {
          return influencer;
        }
      });
      let newInfluencersPreparedToRender = state.influencersPreparedToRender.map(
        (influencer) => {
          if (influencer.key === action.id) {
            let updatedInfluencer = {
              ...influencer,
            };
            Object.entries(action.updates).forEach(
              ([keyToUpdate, newValue]) => {
                updatedInfluencer[keyToUpdate] = newValue;
              }
            );
            return updatedInfluencer;
          } else {
            return influencer;
          }
        }
      );
      return {
        ...state,
        influencers: newInfluencers,
        influencersPreparedToRender: newInfluencersPreparedToRender,
      };

    case "UPDATE_INFLUENCER_BY_ID_ERROR":
      return {
        ...state,
        influencerError: action.error,
      };

    case "DELETE_INFLUENCER_BY_ID_REQUEST":
      return {
        ...state,
        loadingDeleteInfluencer: true,
      };

    case "DELETE_INFLUENCER_BY_ID_SUCCESS":
      {
        let newInfluencers = state.influencers;
        let indexToDelete = null;
        newInfluencers.forEach((influencer, index) => {
          if (influencer.key === action.payload.influencerId) indexToDelete = index;
        });
        newInfluencers = newInfluencers.filter((item, index) => indexToDelete !== index);
        return {
          ...state,
          influencers: newInfluencers,
          loadingDeleteInfluencer: false,
          calculatedInfluencers: false
        };
      };

    case "DELETE_INFLUENCER_BY_ID_ERROR":
      return {
        ...state,
        loadingDeleteInfluencer: false,
        influencerError: action.error
      };

    case "SORT_INFLUENCERS":
      return {
        ...state,
        influencersPreparedToRender: action.sortedInfluencers,
      };

    case 'SET_INFLUENCERS_FOR_RENDER':
      return {
        ...state,
        influencersPreparedToRender: action.preparedInfluencers,
        calculatedInfluencers: true,
      };

    case 'SET_LOAD_INFLUENCER':
      return {
        ...state,
        loadedInfluencer: false
      };

    case 'FETCH_INFLUENCER_BY_ID_REQUEST':
      return {
        ...state,
        loadedInfluencer: false
      };

    case 'FETCH_INFLUENCER_BY_ID_SUCCESS':
      if (state.influencers.filter(influencer => action.payload.influencer.key === influencer.key).length !== 0) {
        if (state.nonPrivateInfluencers.filter(influencer => action.payload.influencer.key === influencer.key).length !== 0) {
          return {
            ...state,
            loadedInfluencer: true
          }
        } else {
          let newInfluencers = [
            ...state.nonPrivateInfluencers,
            action.payload.influencer
          ];
          return {
            ...state,
            loadedInfluencer: true,
            nonPrivateInfluencers: newInfluencers
          };
        }
      } else {
        if (state.nonPrivateInfluencers.filter(influencer => action.payload.influencer.key === influencer.key).length !== 0) {
          let newInfluencers = [
            ...state.influencers,
            action.payload.influencer
          ];
          return {
            ...state,
            loadedInfluencer: true,
            influencers: newInfluencers
          };
        } else {
          let newInfluencers = [
            ...state.influencers,
            action.payload.influencer
          ];
          let newNonPrivateInfluencers = [
            ...state.nonPrivateInfluencers,
            action.payload.influencer
          ]
          return {
            ...state,
            loadedInfluencer: true,
            nonPrivateInfluencers: newNonPrivateInfluencers,
            influencers: newInfluencers
          };
        }
      };

    case 'FETCH_INFLUENCER_BY_ID_NOT_FOUND':
      return {
        ...state,
        loadedInfluencer: true,
        influencerError: {
          status: 404,
          message: 'Influencer not found !'
        }
      };

    case 'FETCH_INFLUENCER_BY_ID_ERROR':
      return {
        ...state,
        loadedInfluencer: true,
        influencerError: action.error
      };

    case 'FETCH_INFLUENCER_BY_NAME_REQUEST':
      return {
        ...state,
        loadedInfluencer: false
      };

    case 'FETCH_INFLUENCER_BY_NAME_SUCCESS':
      if (state.influencers.filter(influencer => action.payload.influencer.name === influencer.name).length !== 0) {
        if (state.nonPrivateInfluencers.filter(influencer => action.payload.influencer.name === influencer.name).length !== 0) {
          return {
            ...state,
            loadedInfluencer: true
          }
        } else {
          let newInfluencers = [
            ...state.nonPrivateInfluencers,
            action.payload.influencer
          ];
          return {
            ...state,
            loadedInfluencer: true,
            nonPrivateInfluencers: newInfluencers
          };
        }
      } else {
        if (state.nonPrivateInfluencers.filter(influencer => action.payload.influencer.name === influencer.name).length !== 0) {
          let newInfluencers = [
            ...state.influencers,
            action.payload.influencer
          ];
          return {
            ...state,
            loadedInfluencer: true,
            influencers: newInfluencers
          };
        } else {
          let newInfluencers = [
            ...state.influencers,
            action.payload.influencer
          ];
          let newNonPrivateInfluencers = [
            ...state.nonPrivateInfluencers,
            action.payload.influencer
          ]
          return {
            ...state,
            loadedInfluencer: true,
            nonPrivateInfluencers: newNonPrivateInfluencers,
            influencers: newInfluencers
          };
        }
      }

    case 'FETCH_INFLUENCER_BY_NAME_NOT_FOUND':
      return {
        ...state,
        loadedInfluencer: true,
        influencerError: {
          status: 404,
          message: 'Influencer Not Found'
        }
      };

    case 'FETCH_INFLUENCER_BY_NAME_ERROR':
      return {
        ...state,
        loadedInfluencer: true,
        influencerError: action.error
      };

    case 'FETCH_INFLUENCER_BY_CODE_33_REQUEST':
      return {
        ...state,
        // influencerError: null,
        loadedInfluencer: false
      };

    case 'FETCH_INFLUENCER_BY_CODE_33_SUCCESS':
      if (state.influencers.filter(influencer => action.payload.influencer.code33 === influencer.code33).length !== 0) {
        if (state.nonPrivateInfluencers.filter(influencer => action.payload.influencer.code33 === influencer.code33).length !== 0) {
          return {
            ...state,
            loadedInfluencer: true
          }
        } else {
          let newInfluencers = [
            ...state.nonPrivateInfluencers,
            action.payload.influencer
          ];
          return {
            ...state,
            loadedInfluencer: true,
            nonPrivateInfluencers: newInfluencers
          };
        }
      } else {
        if (state.nonPrivateInfluencers.filter(influencer => action.payload.influencer.code33 === influencer.code33).length !== 0) {
          let newInfluencers = [
            ...state.influencers,
            action.payload.influencer
          ];
          return {
            ...state,
            loadedInfluencer: true,
            influencers: newInfluencers
          };
        } else {
          let newInfluencers = [
            ...state.influencers,
            action.payload.influencer
          ];
          let newNonPrivateInfluencers = [
            ...state.nonPrivateInfluencers,
            action.payload.influencer
          ]
          return {
            ...state,
            loadedInfluencer: true,
            nonPrivateInfluencers: newNonPrivateInfluencers,
            influencers: newInfluencers
          };
        }
      };

    case 'FETCH_INFLUENCER_BY_CODE_33_NOT_FOUND':
      return {
        ...state,
        loadedInfluencer: true,
        influencerError: {
          status: 404,
          message: 'Influencer Not Found'
        }
      };

    case 'FETCH_INFLUENCER_BY_CODE_33_ERROR':
      return {
        ...state,
        loadedInfluencer: true,
        influencerError: action.error
      };

    case 'FETCH_NON_PRIVATE_INFLUENCERS_REQUEST':
      return {
        ...state,
        influencerError: null,
        loadedNonPrivateInfluencers: false,
      };

    case 'FETCH_NON_PRIVATE_INFLUENCERS_SUCCESS':
      return {
        ...state,
        nonPrivateInfluencers: action.payload.influencers,
        loadedNonPrivateInfluencers: true,
      };

    case 'FETCH_NON_PRIVATE_INFLUENCERS_ERROR':
      return {
        ...state,
        influencerError: action.payload.error
      };
    case 'FETCH_INFLUENCERS_BY_ID_ESHOP_SUCCESS':
      return {
        influencersById: action.payload.influencersById,
        loadedinfluencerById: true
      }
    case 'FETCH_INFLUENCERS_BY_ID_ESHOP_ERROR':
      return {
        ...state,
        influencerError: action.payload.error,
        loadedinfluencerById: false,
      }

    case 'FETCH_INFLUENCER_BY_AFFILIATE_ID_REQUEST':
      return {
        ...state,
        loadedInfluencer: false
      };

    case 'FETCH_INFLUENCER_BY_AFFILIATE_ID_SUCCESS':
      if (state.influencers.filter(influencer => action.payload.influencer.affiliate_id === influencer.affiliate_id).length !== 0) {
        if (state.nonPrivateInfluencers.filter(influencer => action.payload.influencer.affiliate_id === influencer.affiliate_id).length !== 0) {
          return {
            ...state,
            loadedInfluencer: true
          }
        } else {
          let newInfluencers = [
            ...state.nonPrivateInfluencers,
            action.payload.influencer
          ];
          return {
            ...state,
            loadedInfluencer: true,
            nonPrivateInfluencers: newInfluencers
          };
        }
      } else {
        if (state.nonPrivateInfluencers.filter(influencer => action.payload.influencer.affiliate_id === influencer.affiliate_id).length !== 0) {
          let newInfluencers = [
            ...state.influencers,
            action.payload.influencer
          ];
          return {
            ...state,
            loadedInfluencer: true,
            influencers: newInfluencers
          };
        } else {
          let newInfluencers = [
            ...state.influencers,
            action.payload.influencer
          ];
          let newNonPrivateInfluencers = [
            ...state.nonPrivateInfluencers,
            action.payload.influencer
          ]
          return {
            ...state,
            loadedInfluencer: true,
            nonPrivateInfluencers: newNonPrivateInfluencers,
            influencers: newInfluencers
          };
        }
      };

    case 'FETCH_INFLUENCER_BY_AFFILIATE_ID_NOT_FOUND':
      return {
        ...state,
        loadedInfluencer: true,
        influencerError: {
          status: 404,
          message: 'Influencer Not Found'
        }
      };

    case 'FETCH_INFLUENCER_BY_AFFILIATE_ID_ERROR':
      return {
        ...state,
        loadedInfluencer: true,
        influencerError: action.error
      };

    default:
      return state;
  }
};

export default influencersReducer;
