import { dbManager } from "../../components/managers"

export const fetchAllSoldArticle = () => {
    return (dispatch, getState) => {
        dbManager.fetchAllSoldArticle().then(articles => {
            dispatch({ type: 'FETCH_ALL_SOLD_ARTICLES_SUCCESS', payload: { articles } });
        }, error => {
            dispatch({ type: 'FETCH_ALL_SOLD_ARTICLES_ERROR', error })
        });
    };
};

export const updateSoldArticleById = (articleId, updates) => {
    return (dispatch, getState) => {
        dbManager.updateSoldArticleById(articleId, updates).then(updates => {
            dispatch({ type: 'UPDATE_SOLD_ARTICLE_BY_ID_SUCCESS', payload: { articleId, updates } });
        }, error => {
            dispatch({ type: 'UPDATE_SOLD_ARTICLE_BY_ID_ERROR', error });
        });
    };
};

export const fetchSoldArticleById = (articleId) => {
    return (dispatch, getState) => {
        dbManager.fetchSoldArticleById(articleId).then(article => {
            dispatch({ type: 'FETCH_ARTICLE_BY_ID_SUCCESS', article });
        }).catch(error => {
            dispatch({ type: 'FETCH_ARTICLE_BY_ID_ERROR', error });
        });
    }
};

export const fetchArticlesByOfferId = (offerId) => {
    return (dispatch, getState) => {
        dispatch({ type: 'FETCH_ARTICLES_BY_OFFERID_REQUEST' });
        dbManager.fetchArticlesPerBrandOfferId(offerId).then(articles => {
            dispatch({ type: 'FETCH_ARTICLES_BY_OFFERID_SUCCESS', payload: { articles } });
        }, error => {
            dispatch({ type: 'FETCH_ARTICLES_BY_OFFERID_ERROR', error });
        });
    }
};

export const deleteArticleById = (articleId) => {
    return (dispatch, getState) => {
        dispatch({ type: 'DELETE_ARTICLE_BY_ID_REQUEST' });
        dbManager.deleteArticleById(articleId).then(articleId => {
            dispatch({ type: 'DELETE_ARTICLE_BY_ID_SUCCESS', payload: { articleId } })
        }, error => {
            dispatch({ type: 'DELETE_ARTICLE_BY_ID_ERROR', error })
        })
    }
}