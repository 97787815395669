import React, { useState, useEffect, useCallback } from "react";
import { connect } from "react-redux";
import { makeStyles } from "@material-ui/styles";
import {
  Grid,
  FormControl,
  FormHelperText,
  InputBase,
  Typography,
  Select,
  MenuItem,
  Icon,
  useTheme,
  useMediaQuery,
  IconButton,
} from "@material-ui/core";
import ExpandMoreIcon from "@material-ui/icons/ExpandMore";
import FacebookIcon from "@material-ui/icons/Facebook";
import InstagramIcon from "@material-ui/icons/Instagram";
import PinterestIcon from "@material-ui/icons/Pinterest";
import YouTubeIcon from "@material-ui/icons/YouTube";
import TwitterIcon from "@material-ui/icons/Twitter";
import LanguageIcon from "@material-ui/icons/Language";
import CropperDialog from "../../components/Cropper";
import AddIcon from "@material-ui/icons/Add";
import DeleteOutlineIcon from "@material-ui/icons/DeleteOutline";
import { useTranslation } from "react-i18next";

const useStyle = makeStyles(() => ({
  stepTitle: {
    fontFamily: "Poppins",
    margin: "10px",
    fontSize: "18px",
  },
  profileImageLabel: {
    fontFamily: "Poppins",
    width: "150px",
    height: "40px",
    textTransform: "none",
    backgroundColor: "#58a5d3",
    color: "#fff",
    borderRadius: "35px",
    "&:hover": {
      cursor: "pointer",
      backgroundColor: "#58a5d3",
    },
    "&:focus": {
      outlineColor: "rgba(69, 128, 163, 0)",
    },
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    position: "relative",
  },
  inputField: {
    fontFamily: "Poppins",
    borderRadius: "35px",
    boxShadow: "0 7px 30px 0 rgba(69, 128, 163, 0.16)",
    minHeight: "50px",
    paddingLeft: "20px",
    paddingRight: "5px",
  },
  selectClass: {
    fontFamily: "Poppins",
    paddingRight: "0px !important",
    "&:focus": {
      backgroundColor: "white",
    },
    display: "flex",
    flexShrink: 1,
  },
  profilePicturePlaceholder: {
    fontFamily: "Poppins",
    color: "#717171",
    width: "calc(100% - 150px)",
    paddingLeft: "15px",
  },
  activeProfilePicturePlaceholder: {
    fontFamily: "Poppins",
    width: "calc(100% - 150px)",
    paddingLeft: "15px",
  },
  profilePicturePlaceholderMobile: {
    fontFamily: "Poppins",
    color: "#717171",
    width: "70%",
    margin: 5,
    textAlign: "center",
  },
  activeProfilePicturePlaceholderMobile: {
    fontFamily: "Poppins",
    width: "70%",
    margin: 5,
    textAlign: "center",
  },
  select: {
    marginTop: "10px",
    marginBottom: "10px",
  },
  errorMessage: {
    fontFamily: "Poppins",
    color: "red",
  },
  selectedMenuItems: {
    backgroundColor: "#50bef3 !important",
    color: "#fff",
  },
}));

const StepthreeInfluencer = (props) => {
  const theme = useTheme();
  const xsDown = useMediaQuery(theme.breakpoints.down("xs"));
  const { t, i18n } = useTranslation("common");
  const classes = useStyle();

  const [initialValues, setInitialValues] = useState({});
  const [profilePictureValue, setProfilePictureValue] = useState("");
  const [base64Logo, setBase64Logo] = useState("");
  const [favBrands, setfavBrands] = useState([]);
  const [univers, setUnivers] = useState([]);
  const [allBrands, setAllBrands] = useState([]);
  const [socialNetworkList, setSocialNetworkList] = useState([
    {
      socialNetworkType: "instagram",
      socialNetworkValue: "",
      socialNetworkPlaceholder: "https://www.instagram.com/",
    },
  ]);
  const [validationError, setValidationError] = useState(false);
  const [base64LogoValidationError, setBase64LogoValidationError] = useState("");
  const [socialNetworksValidationErrors, setSocialNetworksValidationErrors] = useState({});
  const [openDialog, setOpenDialog] = useState(false);
  const [imageToCrop, setImageToCrop] = useState(null);
  const stepValues = props.stepValues;
  const validStep = props.validStep;
  const {
    newCategories,
    checkValid
  } = props;

  const handleValueChange = (id) => (event) => {
    if (id === "profilePicture") {
      setProfilePictureValue(event.target.value);
      convertImage(event);
    }
    if (id === "favBrands") setfavBrands(event.target.value);
    if (id === "univers") setUnivers(event.target.value);
  };

  const handleSocialNetworkListValueChange = (e, index) => {
    const socialNetworkNewValue = e.target.value;
    const list = [...socialNetworkList];
    list[index]["socialNetworkValue"] = socialNetworkNewValue;
    setSocialNetworkList(list);
  };

  const handleSocialNetworkListTypeChange = (e, index) => {
    const socialNetworkNewType = e.target.value;
    const list = [...socialNetworkList];
    list[index]["socialNetworkType"] = socialNetworkNewType;
    list[index]["socialNetworkPlaceholder"] = handleSocialNetworkPlaceholder(
      socialNetworkNewType
    );
    setSocialNetworkList(list);
  };

  const handleSocialNetworkPlaceholder = (newType) => {
    switch (newType) {
      case "instagram":
        return "https://www.instagram.com/";
      case "facebook":
        return "https://www.facebook.com/";
      case "youtube":
        return "https://www.youtube.com/channel/";
      case "twitter":
        return "https://www.twitter.com/";
      case "pinterest":
        return "https://www.pinterest.com";
      case "snapchat":
        return "Pseudo snapchat";
      case "website":
        return "www.website.com";
      default:
        return "";
    }
  };

  const handleRemoveSocialNetwork = (index) => {
    const list = [...socialNetworkList];
    list.splice(index, 1);
    setSocialNetworkList(list);
  };

  const handleAddSocialNetwork = () => {
    setSocialNetworkList([
      ...socialNetworkList,
      {
        socialNetworkType: "instagram",
        socialNetworkValue: "",
        socialNetworkPlaceholder: "https://www.instagram.com/",
      },
    ]);
  };

  const convertImage = async (event) => {
    const file = event.target.files[0];
    if (file !== undefined) {
      await convertToBase64(file).then((result) => {
        setImageToCrop(result);
        setOpenDialog(true);
      });
    } else {
      setBase64Logo("");
      setProfilePictureValue("");
    }
  };

  const convertToBase64 = (file) => {
    return new Promise((resolve, reject) => {
      const fileReader = new FileReader();
      fileReader.readAsDataURL(file);
      fileReader.onload = () => {
        resolve(fileReader.result);
      };
      fileReader.onerror = (error) => {
        reject(error);
      };
    });
  };

  const resetErrors = () => {
    setBase64LogoValidationError();
    setSocialNetworksValidationErrors({});
  };
  const validateSocialNetworks = useCallback(() => {
    let errors = {};
    socialNetworkList.forEach((socialNetwork, index) => {
      switch (socialNetwork.socialNetworkType) {
        case "instagram": {
          if (
            !socialNetwork.socialNetworkValue.startsWith(
              "https://www.instagram.com/"
            )
          ) {
            errors[index] =
              "* " +
              t(
                "REGISTER.STEP_THREE.INFLUENCER.VALIDATION_ERRORS.SOCIAL_MEDIA_MESSAGES.INSTAGRAM"
              );
          }
          break;
        }
        case "facebook": {
          if (
            !socialNetwork.socialNetworkValue.startsWith(
              "https://www.facebook.com/"
            )
          ) {
            errors[index] =
              "* " +
              t(
                "REGISTER.STEP_THREE.INFLUENCER.VALIDATION_ERRORS.SOCIAL_MEDIA_MESSAGES.FACEBOOK"
              );
          }
          break;
        }
        case "youtube": {
          if (
            !socialNetwork.socialNetworkValue.startsWith(
              "https://www.youtube.com/channel/"
            )
          ) {
            errors[index] =
              "* " +
              t(
                "REGISTER.STEP_THREE.INFLUENCER.VALIDATION_ERRORS.SOCIAL_MEDIA_MESSAGES.YOUTUBE"
              );
          }
          break;
        }
        case "twitter": {
          if (
            !socialNetwork.socialNetworkValue.startsWith(
              "https://www.twitter.com/"
            )
          ) {
            errors[index] =
              "* " +
              t(
                "REGISTER.STEP_THREE.INFLUENCER.VALIDATION_ERRORS.SOCIAL_MEDIA_MESSAGES.TWITTER"
              );
          }
          break;
        }
        case "pinterest": {
          if (
            !socialNetwork.socialNetworkValue.startsWith(
              "https://www.pinterest.com"
            )
          ) {
            errors[index] =
              "* " +
              t(
                "REGISTER.STEP_THREE.INFLUENCER.VALIDATION_ERRORS.SOCIAL_MEDIA_MESSAGES.PINTEREST"
              );
          }
          break;
        }
        case "website": {
          if (socialNetwork.socialNetworkValue === "") {
            errors[index] =
              "* " +
              t(
                "REGISTER.STEP_THREE.INFLUENCER.VALIDATION_ERRORS.SOCIAL_MEDIA_MESSAGES.WEBSITE"
              );
          }
          break;
        }
        case "snapchat": {
          if (socialNetwork.socialNetworkValue === "") {
            errors[index] =
              "* " +
              t(
                "REGISTER.STEP_THREE.INFLUENCER.VALIDATION_ERRORS.SOCIAL_MEDIA_MESSAGES.SNAPCHAT"
              );
          }
          break;
        }
        default:
          break;
      }
    });
    if (Object.keys(errors).length !== 0) {
      setValidationError(true);
      setSocialNetworksValidationErrors(errors);
      return true;
    } else {
      return false;
    }
  }, [socialNetworkList, t]);

  const isValid = useCallback(() => {
    let valid = true;
    setValidationError(false);
    resetErrors();
    if (base64Logo === "") {
      valid = false;
      setValidationError(true);
      setBase64LogoValidationError(
        "* " +
        t("REGISTER.STEP_THREE.INFLUENCER.VALIDATION_ERRORS.REQUIRED_MESSAGE")
      );
    }
    if (validateSocialNetworks()) {
      valid = false;
    }
    return valid;
  }, [base64Logo, validateSocialNetworks, t]);

  const handleCancelDialog = () => {
    setOpenDialog(false);
    setProfilePictureValue("");
    setBase64Logo("");
  };

  const handleSaveDialog = (croppedImage) => {
    setOpenDialog(false);
    setBase64Logo(croppedImage);
  };

  useEffect(() => {
    if (initialValues.profilePicture !== undefined)
      setProfilePictureValue(initialValues.profilePicture);
    if (initialValues.base64Logo !== undefined)
      setBase64Logo(initialValues.base64Logo);
    if (initialValues.favBrands !== undefined)
      setfavBrands(initialValues.favBrands);
    if (initialValues.univers !== undefined) {
      let categoryMap = [];
      initialValues.univers.forEach(cat => {
        categoryMap.push(newCategories.filter(categoryObject => categoryObject.key === cat)[0]);
      });
      setUnivers(categoryMap);
    }
    if (initialValues.socialNetworks !== undefined)
      setSocialNetworkList(initialValues.socialNetworks);
  }, [initialValues, newCategories]);

  useEffect(() => {
    if (props.valuesToPopulate !== undefined) {
      setInitialValues(props.valuesToPopulate);
    }
  }, [props.valuesToPopulate]);

  useEffect(() => {
    setAllBrands(props.brands);
  }, [props.brands]);

  useEffect(() => {
    stepValues({
      profilePicture: profilePictureValue,
      base64Logo: base64Logo,
      favBrands: favBrands,
      univers: univers.map(categ => categ.key),
      socialNetworks: socialNetworkList,
    });
  }, [
    profilePictureValue,
    base64Logo,
    favBrands,
    univers,
    socialNetworkList,
    stepValues,
  ]);
  

  useEffect(() => {
    if (checkValid) {
      if (isValid()) {
        validStep(true);
      } else validStep(false);
    }
  }, [checkValid, isValid, validStep]);

  return (
    <>
      <Grid container item direction="row" xs={12}>
        <Typography className={classes.stepTitle}>
          {t("REGISTER.STEP_THREE.STEP_TITLE")}
        </Typography>
      </Grid>
      <Grid
        container
        item
        direction="row"
        justify="center"
        alignItems="center"
        xs={12}
      >
        <FormControl style={{ width: "100%" }}>
          <CropperDialog
            open={openDialog}
            save={handleSaveDialog}
            cancel={handleCancelDialog}
            imageToCrop={imageToCrop}
            widthAspect={2}
            heightAspect={1}
          />
          <Grid
            container
            item
            alignItems="center"
            justify={xsDown ? "center" : "flex-start"}
            className={classes.inputField}
            style={{
              paddingRight: 5,
              paddingLeft: 5,
              marginTop: "10px",
              marginBottom: "10px",
              width: "100%",
            }}
          >
            <Typography
              className={
                profilePictureValue === ""
                  ? xsDown
                    ? classes.profilePicturePlaceholderMobile
                    : classes.profilePicturePlaceholder
                  : xsDown
                    ? classes.activeProfilePicturePlaceholderMobile
                    : classes.activeProfilePicturePlaceholder
              }
            >
              {profilePictureValue === ""
                ? t("REGISTER.STEP_THREE.INFLUENCER.PICTURE_PLACEHOLDER")
                : profilePictureValue.split("\\").pop()}
            </Typography>
            <label
              htmlFor="profileImage"
              tabIndex="0"
              className={classes.profileImageLabel}
              style={xsDown ? { margin: 5 } : {}}
            >
              {t("REGISTER.STEP_THREE.INFLUENCER.PICTURE_UPLOAD_BUTTON")}
            </label>
          </Grid>
          <input
            type="file"
            id="profileImage"
            onChange={handleValueChange("profilePicture")}
            hidden
            accept="image/x-png,image/gif,image/jpeg"
          />
          {validationError && (
            <FormHelperText className={classes.errorMessage}>
              {base64LogoValidationError}
            </FormHelperText>
          )}
        </FormControl>
        {socialNetworkList.map((socialNetwork, index) => (
          <Grid
            container
            item
            xs={12}
            style={{ marginTop: "10px", marginBottom: "10px" }}
            key={index}
          >
            <div
              className={classes.inputField}
              style={{
                width: "100%",
                display: "flex",
                flexDirection: "row",
                paddingRight: "0px",
              }}
            >
              <Select
                displayEmpty
                value={socialNetwork.socialNetworkType}
                onChange={(e) => handleSocialNetworkListTypeChange(e, index)}
                classes={{ root: classes.selectClass }}
                input={
                  <InputBase
                    className={classes.inputField}
                    style={{ marginLeft: "-20px", width: "75px" }}
                  />
                }
                IconComponent={() => <ExpandMoreIcon />}
              >
                <MenuItem value="instagram">
                  <InstagramIcon
                    style={{
                      color: "#fff",
                      borderRadius: "5px",
                      background:
                        "linear-gradient(45deg, #f09433 0%,#e6683c 25%,#dc2743 50%,#cc2366 75%,#bc1888 100%)",
                    }}
                  />
                </MenuItem>
                <MenuItem value="facebook">
                  <FacebookIcon style={{ color: "#3773e6" }} />
                </MenuItem>
                <MenuItem value="youtube">
                  <YouTubeIcon style={{ color: "#ef2702" }} />
                </MenuItem>
                <MenuItem value="twitter">
                  <TwitterIcon
                    style={{
                      color: "#fff",
                      backgroundColor: "#46a1f2",
                      borderRadius: "5px",
                    }}
                  />
                </MenuItem>
                <MenuItem value="pinterest">
                  <PinterestIcon style={{ color: "#e62521" }} />
                </MenuItem>
                <MenuItem value="snapchat">
                  <Icon
                    className="fab fa-snapchat"
                    style={{ color: "#f4f40a" }}
                  />
                </MenuItem>
                <MenuItem value="website">
                  <LanguageIcon />
                </MenuItem>
              </Select>
              <InputBase
                placeholder={socialNetwork.socialNetworkPlaceholder}
                style={{
                  paddingLeft: "15px",
                  fontFamily: "Poppins",
                  width: "calc(100% - 75px)",
                }}
                type="text"
                value={socialNetwork.socialNetworkValue}
                required
                onChange={(e) => handleSocialNetworkListValueChange(e, index)}
              />
              {socialNetworkList.length - 1 === index && (
                <IconButton
                  disableRipple
                  style={
                    socialNetworkList.length !== 1
                      ? {
                        borderTopRightRadius: "0px",
                        borderBottomRightRadius: "0px",
                      }
                      : {}
                  }
                  onClick={handleAddSocialNetwork}
                >
                  <AddIcon style={{ color: "#58a5d3" }} />
                </IconButton>
              )}
              {socialNetworkList.length !== 1 && (
                <IconButton
                  disableRipple
                  style={{
                    borderTopLeftRadius: "0px",
                    borderBottomLeftRadius: "0px",
                  }}
                  onClick={() => handleRemoveSocialNetwork(index)}
                >
                  <DeleteOutlineIcon style={{ color: "red" }} />
                </IconButton>
              )}
            </div>
            {validationError &&
              socialNetworksValidationErrors[index] !== undefined ? (
                <FormHelperText className={classes.errorMessage}>
                  {socialNetworksValidationErrors[index]}
                </FormHelperText>
              ) : (
                <></>
              )}
          </Grid>
        ))}
        <FormControl style={{ width: "100%" }}>
          <Select
            multiple
            displayEmpty
            value={favBrands}
            onChange={handleValueChange("favBrands")}
            classes={{ root: classes.selectClass }}
            input={
              <InputBase
                className={classes.inputField}
                style={{ marginTop: "10px", marginBottom: "10px" }}
              />
            }
            renderValue={(selected) => {
              if (selected.length === 0) {
                return (
                  <Typography
                    style={{ fontFamily: "Poppins", color: "#717171" }}
                  >
                    {t(
                      "REGISTER.STEP_THREE.INFLUENCER.FAVOURITE_BRANDS_PLACEHOLDER"
                    )}
                  </Typography>
                );
              }
              return selected
                .map((brand) => {
                  return brand.name;
                })
                .join(", ");
            }}
            IconComponent={() => <ExpandMoreIcon />}
          >
            {allBrands.map((brand, key) => (
              <MenuItem
                key={key}
                value={brand}
                style={{ fontFamily: "Poppins" }}
                classes={{ selected: classes.selectedMenuItems }}
              >
                {brand.name}
              </MenuItem>
            ))}
          </Select>
          {/* {validationError && <FormHelperText className={classes.errorMessage}>{brandsValidationError}</FormHelperText>} */}
        </FormControl>
        <FormControl style={{ width: "100%" }}>
          <Select
            multiple
            displayEmpty
            value={univers}
            onChange={handleValueChange("univers")}
            classes={{ root: classes.selectClass }}
            input={
              <InputBase
                className={classes.inputField}
                style={{ marginTop: "10px", marginBottom: "10px" }}
              />
            }
            renderValue={(selected) => {
              if (selected.length === 0) {
                return (
                  <Typography
                    style={{ fontFamily: "Poppins", color: "#717171" }}
                  >
                    {t("REGISTER.STEP_THREE.INFLUENCER.UNIVERS_PLACEHOLDER")}
                  </Typography>
                );
              }
              return selected.map(cat => (i18n.language === 'fr-FR' || i18n.language === 'fr') ? cat['text-fr'] : cat['text-en']).join(", ");
            }}
            IconComponent={() => <ExpandMoreIcon />}
          >
            {newCategories.map(category => (
              <MenuItem
                value={category}
                style={{ fontFamily: "Poppins" }}
                classes={{ selected: classes.selectedMenuItems }}
                key={category.key}
              >
                {(i18n.language === 'fr-FR' || i18n.language === 'fr') ? category['text-fr'] : category['text-en']}
              </MenuItem>
            ))}
          </Select>
          {/* {validationError && <FormHelperText className={classes.errorMessage}>{universValidationError}</FormHelperText>} */}
        </FormControl>
      </Grid>
    </>
  );
};
const mapStateToProps = (state) => {
  const registrationComplete = state.authState.registrationComplete;
  const newCategories = state.categoriesState.newCategories;
  return { registrationComplete, newCategories };
};

export default connect(mapStateToProps, null)(StepthreeInfluencer);
