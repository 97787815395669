import { Grid, makeStyles, useTheme, useMediaQuery, Typography, Select, Menu, MenuItem, Button, IconButton, Backdrop, CircularProgress, Dialog } from '@material-ui/core';
import { Pagination, PaginationItem } from '@material-ui/lab';
import SVGIcon from '../../assets/SVGIcons'
import BootstrapInput from '../../assets/customComponents/BootstrapInput';
import React, { useState, useEffect } from 'react';
import { connect } from 'react-redux';
import { deleteSuggestedBrand } from '../../../../../store/actions/brandsActions';
import { useHistory } from 'react-router-dom';
import { useTranslation } from "react-i18next";
import MoreVertIcon from "@material-ui/icons/MoreVert";
import ExpandLessIcon from '@material-ui/icons/ExpandLess';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import SortIcon from '@material-ui/icons/Sort';
import placeholderBrand from '../../assets/img/brand.png';

const useStyles = makeStyles(() => ({
    tableHeader: {
        fontFamily: 'Poppins',
        cursor: 'pointer',
        marginTop: '10px',
        marginBottom: '10px'
    },
    tableRow: {
        backgroundColor: 'white',
        height: '80px',
        borderRadius: '27px',
        boxShadow: '0 7px 30px 0 rgba(88, 165, 211, 0.16)',
        marginTop: '10px',
        marginBottom: '10px'
    },
    brandImage: {
        height: '80px',
        borderTopLeftRadius: '27px',
        borderBottomLeftRadius: '27px',
        width: '160px',
    },
    tableCell: {
        fontFamily: 'Poppins',
    },

    paginationClass: {
        backgroundColor: '#ffffff !important',
        boxShadow: '0 7px 30px 0 rgba(88, 165, 211, 0.16)',
        borderRadius: '8px',
        fontFamily: 'Poppins',
        height: '40px',
        width: '40px',
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center'
    },
    selectedPaginationItem: {
        backgroundColor: '#3eadef !important',
        boxShadow: '0 7px 30px 0 rgba(88, 165, 211, 0.16)',
        borderRadius: '8px',
        fontFamily: 'Poppins',
        height: '40px',
        width: '40px',
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        color: '#ffffff'
    },
    paginationStyles: {
        fontFamily: 'Poppins'
    },
    placeholderStyles: {
        fontFamily: 'Poppins',
        color: '#1B4C61'
    },
    menu: {
        boxShadow: '0 7px 7px 0 #d0e6f130',
    },
    menuDialog: {
        width: "500px",
        height: "170px",
        borderRadius: "20px",
    },
    dialogTitle: {
        fontFamily: 'Poppins',
        fontSize: '21px',
        textAlign: 'center'
    },
    cancelButton: {
        marginTop: '15px',
        padding: '6px 8px',
        width: "200px",
        height: "40px",
        color: '#ffffff',
        fontFamily: 'Poppins',
        fontSize: "16px",
        textTransform: "capitalize",
        borderRadius: "20px",
        backgroundColor: 'gray',
        '&:hover': {
            backgroundColor: 'gray'
        }
    },
    deleteButton: {
        marginTop: '15px',
        padding: '6px 8px',
        width: "180px",
        height: "40px",
        color: '#fff',
        fontFamily: 'Poppins',
        fontSize: "16px",
        textTransform: "capitalize",
        boxShadow: "0px 0px 0px 0px #00aeef",
        borderRadius: "20px",
        backgroundColor: 'red',
        '&:hover': {
            backgroundColor: 'red'
        }
    }
}));

const BrandSuggestedTable = (props) => {

    const { t } = useTranslation('common');
    const classes = useStyles();
    const history = useHistory();
    const theme = useTheme();
    const xsDown = useMediaQuery(theme.breakpoints.down('xs'));

    const {
        suggestedBrands,
        influencers,
        loadedSuggestedBrands,
        loadedInfluencers,
        searchValue,
        appStatePageNumber,
        appStateSortHelper,
        appStatePageSize,
        changeSortHelper,
        changePageNumber,
        changePageSize,
        deleteSuggestedBrand,
        loadingDeleteSuggestedBrand
    } = props;

    const [suggestedBrandsTable, setSuggestedBrandsTable] = useState([]);
    const [brandsTableToRender, setBrandsTableToRender] = useState([]);
    const [tableBeforeRender, setTableBeforeRender] = useState([]);
    const [page, setPage] = useState(1);
    const [count, setCount] = useState(0);
    const [size, setSize] = useState(10);
    const [sortHelper, setSortHelper] = useState([1, 0, 0, 0])
    const [sortEvent, setSortEvent] = useState(0);
    const [anchorEl, setAnchorEl] = useState(null);
    const [openDialog, setOpenDialog] = useState(false);
    const [suggestedToDelete, setSuggestedToDelete] = useState(null);
    const [overlayState, setOverlayState] = useState(false);
    const [updateClicked, setUpdateClicked] = useState(false);
    const [loading, setLoading] = useState(true);

    const handleOpenMenu = (event, brand) => {
        setAnchorEl(event.currentTarget);
        setSuggestedToDelete(brand);
    };

    const handleCloseMenu = () => {
        setAnchorEl(null);
    };

    const handleOpenDialog = () => {
        setOpenDialog(true);
    };
    const handleCloseDialog = () => {
        setOpenDialog(false);
        setAnchorEl(null);
    };

    const deleteBrand = async () => {
        setUpdateClicked(true);
        deleteSuggestedBrand(suggestedToDelete.key);
    }

    useEffect(() => {
        setSortHelper(appStateSortHelper);
    }, [appStateSortHelper]);

    useEffect(() => {
        setPage(appStatePageNumber);
    }, [appStatePageNumber]);

    useEffect(() => {
        setSize(appStatePageSize);
    }, [appStatePageSize]);

    useEffect(() => {
        setLoading(true);
        if (loadedSuggestedBrands && loadedInfluencers) {
            let brandTable = [];
            suggestedBrands.forEach(brand => {
                let influencerData = influencers.filter(inf => inf.key === brand.uid)[0];
                brandTable.push({
                    ...brand,
                    influencer: influencerData
                });
            });
            setSuggestedBrandsTable(brandTable);
            setLoading(false);
        }
    }, [loadedSuggestedBrands, suggestedBrands, loadedInfluencers, influencers]);

    useEffect(() => {
        let tableAfterSearch = (searchValue !== '')
            ? suggestedBrandsTable.filter(brand => brand.brandName.toLowerCase().includes(searchValue.toLowerCase()))
            : suggestedBrandsTable;
        setBrandsTableToRender(tableAfterSearch.slice((page - 1) * size, page * size));
        setTableBeforeRender(tableAfterSearch);
        setCount(
            (tableAfterSearch.length % size === 0)
                ? Math.trunc(tableAfterSearch.length / size)
                : Math.trunc(tableAfterSearch.length / size) + 1
        );
    }, [suggestedBrandsTable, size, page, sortEvent, searchValue]);

    useEffect(() => {
        let indexAsc = sortHelper.indexOf(1);
        let indexDesc = sortHelper.indexOf(-1);
        if (indexAsc !== -1) {
            const sortTypeAsc = (indexAsc, tableToWorkOn) => {
                switch (indexAsc) {
                    case 0:
                        tableToWorkOn.sort((a, b) => {
                            if (a.influencer.name.toLowerCase() > b.influencer.name.toLowerCase()) return 1;
                            if (a.influencer.name.toLowerCase() < b.influencer.name.toLowerCase()) return -1;
                            return 0;
                        });
                        break;
                    case 1:
                        tableToWorkOn.sort((a, b) => {
                            if (a.timestamp > b.timestamp) return -1;
                            if (a.timestamp < b.timestamp) return 1;
                            return 0;
                        });
                        break;
                    case 2:
                        tableToWorkOn.sort((a, b) => {
                            if (a.brandName > b.brandName) return 1;
                            if (a.brandName < b.brandName) return -1;
                            return 0;
                        });
                        break;
                    case 3:
                        tableToWorkOn.sort((a, b) => {
                            if (a.website > b.website) return 1;
                            if (a.website < b.website) return -1;
                            return 0;
                        });
                        break;
                    default:
                        break;
                }
            }
            sortTypeAsc(indexAsc, suggestedBrandsTable);
        } else {
            const sortTypeDesc = (indexDesc, tableToWorkOn) => {
                switch (indexDesc) {
                    case 0:
                        tableToWorkOn.sort((a, b) => {
                            if (a.influencer.name.toLowerCase() > b.influencer.name.toLowerCase()) return -1;
                            if (a.influencer.name.toLowerCase() < b.influencer.name.toLowerCase()) return 1;
                            return 0;
                        });
                        break;
                    case 1:
                        tableToWorkOn.sort((a, b) => {
                            if (a.timestamp > b.timestamp) return 1;
                            if (a.timestamp < b.timestamp) return -1;
                            return 0;
                        });
                        break;
                    case 2:
                        tableToWorkOn.sort((a, b) => {
                            if (a.brandName > b.brandName) return -1;
                            if (a.brandName < b.brandName) return 1;
                            return 0;
                        });
                        break;
                    case 3:
                        tableToWorkOn.sort((a, b) => {
                            if (a.website > b.website) return -1;
                            if (a.website < b.website) return 1;
                            return 0;
                        });
                        break;
                    default:
                        break;
                }
            };
            sortTypeDesc(indexDesc, suggestedBrandsTable);
        }
        setSortEvent(s => s + 1);
    }, [suggestedBrandsTable, sortHelper]);

    const handlePageChange = (event, value) => {
        changePageNumber(value);
    };

    const handlePageSizeChange = (event) => {
        changePageSize(event.target.value);
        changePageNumber(1);
    };

    const handleViewInfluencer = (influencer) => {
        props.viewInfluencer(influencer);
        history.push('/admin/influencers');
    }

    useEffect(() => {
        if (loadingDeleteSuggestedBrand) {
            setOverlayState(true);

        }
        if (!loadingDeleteSuggestedBrand && updateClicked) {
            setOverlayState(false);
            setUpdateClicked(false);
            handleCloseDialog();
        }

    }, [loadingDeleteSuggestedBrand, updateClicked])

    return (
        (loading)
            ? <div style={{ display: 'flex', flexDirection: 'column', flexGrow: 1, width: '100%', justifyContent: 'center', alignItems: 'center' }}>
                <CircularProgress size={36} color='secondary' />
            </div>
            :
            <Grid container item direction='column' alignItems='center' xs={12} style={{ flexGrow: 1 }}>
                {
                    (brandsTableToRender.length === 0)
                        ? <>
                            <Grid container item direction='row' xs={11} justify='center' alignItems='center' style={{ marginTop: '100px' }}>
                                <img
                                    alt="No Brands"
                                    src={placeholderBrand}
                                    height="75px"
                                    width="75px"
                                />
                            </Grid>
                            <Grid container item direction='row' xs={11} justify='center' alignItems='center' style={{ marginTop: '10px' }}>
                                <Typography className={classes.placeholderStyles}>
                                    {t('ADMIN.VIEWS.PARTNERS.BRANDS.NO_SUGGESTED_BRANDS_PLACEHOLDER')}
                                </Typography>
                            </Grid>
                        </>
                        : <>
                            <Backdrop className={classes.overlay} open={overlayState}>
                                <CircularProgress color='secondary' />
                            </Backdrop>
                            <Dialog classes={{ paper: classes.menuDialog }} open={openDialog} onClose={handleCloseDialog} aria-labelledby="form-dialog-title">
                                <Grid container item direction='column' xs={12} alignItems='center' style={{ marginTop: '20px' }}>
                                    <Typography className={classes.dialogTitle}> {t('ADMIN.VIEWS.PARTNERS.BRANDS.SUGGESTED_BRANDS_MENU_DIALOG.DIALOG_SUGGESTED_BRAND.DIALOG_TITLE_SUGGESTED')}</Typography>
                                </Grid>
                                <Grid container item direction='row' xs={12} justify='center'>
                                    <Button className={classes.cancelButton} onClick={handleCloseDialog} style={{ marginRight: (xsDown) ? '0px' : '15px' }}> {t('ADMIN.VIEWS.PARTNERS.BRANDS.SUGGESTED_BRANDS_MENU_DIALOG.DIALOG_SUGGESTED_BRAND.CANCEL_BUTTON')}</Button>
                                    <Button className={classes.deleteButton} onClick={deleteBrand} style={(xsDown) ? { marginBottom: '10px' } : { marginBottom: '0' }}>{t('ADMIN.VIEWS.PARTNERS.BRANDS.SUGGESTED_BRANDS_MENU_DIALOG.DIALOG_SUGGESTED_BRAND.DELETE_BUTTON')}</Button>
                                </Grid>
                            </Dialog>
                            <Grid container item direction='row' xs={11} alignItems='center' justify='center'>
                                <div style={{ overflowX: 'auto', width: '100%' }}>
                                    <Grid container item style={{ minWidth: '1050px' }} direction='row' xs={12}>
                                        <Grid container item direction='row' xs={11}>
                                            <Grid container justify='center' alignItems='center' style={{ width: '40%' }} onClick={() => {
                                                (sortHelper[0] === 0) ? changeSortHelper([1, 0, 0, 0]) : changeSortHelper([-sortHelper[0], 0, 0, 0]);
                                            }}>
                                                <Typography className={classes.tableHeader}>
                                                    {t('ADMIN.VIEWS.PARTNERS.BRANDS.SUGGESTED_BRANDS_TABLE_HEADERS.INFLUENCER')}
                                                </Typography>
                                                {(sortHelper[0] === 1)
                                                    ? <ExpandLessIcon className={classes.tableHeaderIcon} />
                                                    : ((sortHelper[0] === -1)
                                                        ? <ExpandMoreIcon className={classes.tableHeaderIcon} />
                                                        : <SortIcon className={classes.tableHeaderIcon} />)}
                                            </Grid>
                                            <Grid container justify='center' alignItems='center' style={{ width: '15%' }} onClick={() => {
                                                (sortHelper[1] === 0) ? changeSortHelper([0, 1, 0, 0]) : changeSortHelper([0, -sortHelper[1], 0, 0]);
                                            }}>
                                                <Typography className={classes.tableHeader}>
                                                    {t('ADMIN.VIEWS.PARTNERS.BRANDS.SUGGESTED_BRANDS_TABLE_HEADERS.DATE')}
                                                </Typography>
                                                {(sortHelper[1] === 1)
                                                    ? <ExpandLessIcon className={classes.tableHeaderIcon} />
                                                    : ((sortHelper[1] === -1)
                                                        ? <ExpandMoreIcon className={classes.tableHeaderIcon} />
                                                        : <SortIcon className={classes.tableHeaderIcon} />)}
                                            </Grid>
                                            <Grid container justify='center' alignItems='center' style={{ width: '15%' }} onClick={() => {
                                                (sortHelper[2] === 0) ? changeSortHelper([0, 0, 1, 0]) : changeSortHelper([0, 0, -sortHelper[2], 0]);
                                            }}>
                                                <Typography className={classes.tableHeader}>
                                                    {t('ADMIN.VIEWS.PARTNERS.BRANDS.SUGGESTED_BRANDS_TABLE_HEADERS.BRAND_NAME')}
                                                </Typography>
                                                {(sortHelper[2] === 1)
                                                    ? <ExpandLessIcon className={classes.tableHeaderIcon} />
                                                    : ((sortHelper[2] === -1)
                                                        ? <ExpandMoreIcon className={classes.tableHeaderIcon} />
                                                        : <SortIcon className={classes.tableHeaderIcon} />)}
                                            </Grid>
                                            <Grid container justify='center' alignItems='center' style={{ width: '30%' }} onClick={() => {
                                                (sortHelper[3] === 0) ? changeSortHelper([0, 0, 0, 1]) : changeSortHelper([0, 0, 0, -sortHelper[3]]);
                                            }}>
                                                <Typography className={classes.tableHeader}>
                                                    {t('ADMIN.VIEWS.PARTNERS.BRANDS.SUGGESTED_BRANDS_TABLE_HEADERS.URL')}
                                                </Typography>
                                                {(sortHelper[3] === 1)
                                                    ? <ExpandLessIcon className={classes.tableHeaderIcon} />
                                                    : ((sortHelper[3] === -1)
                                                        ? <ExpandMoreIcon className={classes.tableHeaderIcon} />
                                                        : <SortIcon className={classes.tableHeaderIcon} />)}
                                            </Grid>
                                        </Grid>
                                        {
                                            brandsTableToRender.map(brand => (
                                                <Grid container item xs={12} direction='row' className={classes.tableRow} key={brand.key}>
                                                    <Grid container item xs={11}>
                                                        <Grid container item alignItems='center' style={{ width: '40%' }}>
                                                            <img src={brand.influencer.banner} alt={brand.influencer.name} className={classes.brandImage} />
                                                            <Typography
                                                                className={classes.tableCell}
                                                                style={{ marginLeft: '30px', cursor: 'pointer', textDecoration: 'underline' }}
                                                                onClick={() => handleViewInfluencer(brand.influencer)}>{brand.influencer.name}</Typography>
                                                        </Grid>
                                                        <Grid container item justify='center' alignItems='center' style={{ width: '15%' }}>
                                                            <Typography className={classes.tableCell}>{(new Date(brand.timestamp * 1000).toLocaleDateString())}</Typography>
                                                        </Grid>
                                                        <Grid container item justify='center' alignItems='center' style={{ width: '15%' }}>
                                                            <Typography className={classes.tableCell}>{brand.brandName}</Typography>
                                                        </Grid>
                                                        <Grid container item justify='center' alignItems='center' style={{ width: '30%' }}>
                                                            <a
                                                                className={classes.tableCell}
                                                                style={{ textDecoration: 'underline', cursor: 'pointer', color: '#000' }}
                                                                href={(brand.website.startsWith('http')) ? (brand.website) : 'http://' + brand.website}
                                                                rel="noopener noreferrer"
                                                                target='_blank'>{brand.website}</a>
                                                        </Grid>
                                                    </Grid>
                                                    <Grid container item justify='center' alignItems='center' xs={1}>
                                                        <IconButton onClick={(e) => handleOpenMenu(e, brand)}>
                                                            <MoreVertIcon />
                                                        </IconButton>
                                                        <Menu
                                                            classes={{ paper: classes.menu }}
                                                            anchorEl={anchorEl}
                                                            open={Boolean(anchorEl)}
                                                            onClose={handleCloseMenu}
                                                        >
                                                            <MenuItem
                                                                className={classes.tableHeader}
                                                                onClick={handleOpenDialog}
                                                            >
                                                                Delete
                                                            </MenuItem>
                                                        </Menu>
                                                    </Grid>
                                                </Grid>
                                            ))
                                        }
                                    </Grid>
                                </div>
                            </Grid>
                            <Grid container item direction='row' xs={11} alignItems='center'>
                                <Grid container alignItems='center' justify='flex-end'>
                                    <Select
                                        value={size}
                                        onChange={handlePageSizeChange}
                                        input={<BootstrapInput />}
                                        IconComponent={() => (
                                            <SVGIcon name='miniArrow' />
                                        )}
                                        classes={{ root: classes.paginationStyles }}
                                    >
                                        <MenuItem value={10} style={{ fontFamily: 'Poppins' }}>10</MenuItem>
                                        <MenuItem value={25} style={{ fontFamily: 'Poppins' }}>25</MenuItem>
                                        <MenuItem value={50} style={{ fontFamily: 'Poppins' }}>50</MenuItem>
                                    </Select>
                                    <Typography className={classes.tableHeader} style={{ marginRight: '10px', marginLeft: '10px' }}>
                                        {t('ADMIN.VIEWS.PARTNERS.BRANDS.PAGINATION_PLACEHOLDER')} {tableBeforeRender.length}
                                    </Typography>
                                    <Pagination
                                        count={count}
                                        page={page}
                                        onChange={handlePageChange}
                                        renderItem={(item) => (
                                            <PaginationItem classes={{ root: classes.paginationClass, selected: classes.selectedPaginationItem }} {...item} />
                                        )} />
                                </Grid>
                            </Grid>
                        </>
                }
            </Grid>
    );
}

const mapStateToProps = (state, ownProps) => {
    const suggestedBrands = state.brandsState.suggestedBrands;
    const loadedSuggestedBrands = state.brandsState.loadedSuggestedBrands;
    const loadingDeleteSuggestedBrand = state.brandsState.loadingDeleteSuggestedBrand;
    const loadedInfluencers = state.influencersState.loadedInfluencers;
    const influencers = state.influencersState.influencers;
    const appStateSortHelper = state.appState.adminPartnerBrandState.suggestedBrandTableSortHelper;
    const appStatePageNumber = state.appState.adminPartnerBrandState.suggestedBrandTablePageNumber;
    const appStatePageSize = state.appState.adminPartnerBrandState.suggestedBrandTablePageSize;
    return {
        suggestedBrands,
        influencers,
        loadingDeleteSuggestedBrand,
        loadedInfluencers,
        loadedSuggestedBrands,
        appStateSortHelper,
        appStatePageNumber,
        appStatePageSize
    };
};

const mapDispatchToProps = (dispatch) => {
    return {
        changeSortHelper: (sortHelper) =>
            dispatch({ type: 'UPDATE_ADMIN_PARTNER_SUGGESTED_BRAND_TABLE_SORT_HELPER', sortHelper }),
        changePageNumber: (pageNumber) =>
            dispatch({ type: 'UPDATE_ADMIN_PARTNER_SUGGESTED_BRAND_TABLE_PAGE_NUMBER', pageNumber }),
        changePageSize: (pageSize) =>
            dispatch({ type: 'UPDATE_ADMIN_PARTNER_SUGGESTED_BRAND_TABLE_PAGE_SIZE', pageSize }),
        deleteSuggestedBrand: (brandId) => dispatch(deleteSuggestedBrand(brandId)),
    }
};

export default connect(mapStateToProps, mapDispatchToProps)(BrandSuggestedTable);