import React, { useEffect, useState, useCallback } from "react";
import { connect } from "react-redux";
import { Grid, useMediaQuery, useTheme, makeStyles, Button, Checkbox, IconButton, Backdrop, CircularProgress, Typography, Select, MenuItem, Paper, InputBase } from "@material-ui/core";
import ExpandLessIcon from '@material-ui/icons/ExpandLess';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import SortIcon from '@material-ui/icons/Sort';
import { Pagination, PaginationItem } from '@material-ui/lab';
import { updateBrandById } from '../../../../store/actions/brandsActions';
import BootstrapInput from '../assets/customComponents/BootstrapInput';
import { useTranslation } from "react-i18next";
import SVGIcon from '../assets/SVGIcons'
import SearchIcon from '@material-ui/icons/Search';
import CloseIcon from '@material-ui/icons/Close';
import placeholderInfluencer from '../assets/img/placeholder_influenceur.png';

const useStyles = makeStyles((theme) => ({
    margin: {
        margin: theme.spacing(1),
    },
    overallBox: {
        backgroundColor: '#fff',
        borderRadius: '35px',
        boxShadow: '0 7px 30px 0 rgba(88, 165, 211, 0.16)',
        margin: '15px',

    },
    root: {
        width: '100%',
        maxWidth: 360,
        backgroundColor: theme.palette.background.paper,
        overflowY: 'auto',
        overflowX: 'hidden',
        maxHeight: 300,
        position: 'relative',
    },
    search: {
        display: "flex",
        alignItems: "center",
        width: '50%',
        borderRadius: '40px',
        boxShadow: '0 7px 30px 0 rgba(88, 165, 211, 0.16)',
    },
    searchInputInfluenceTab: {
        width: '100%',
        fontFamily: 'Poppins'
    },
    iconButton: {
        padding: 10
    },
    formBox: {
        marginTop: '30px',
        marginBottom: '30px'
    },
    tableRow: {
        backgroundColor: 'white',
        height: '80px',
        borderRadius: '27px',
        boxShadow: '0 7px 30px 0 rgba(88, 165, 211, 5%)',
        marginTop: '10px',
        marginBottom: '10px'
    },
    tableCell: {
        fontFamily: 'Poppins',
    },
    tableHeader: {
        fontFamily: 'Poppins',
        cursor: 'pointer',
        marginTop: '10px',
        marginBottom: '10px'
    },
    influencerImage: {
        width: "160px",
        height: "80px",
        borderTopLeftRadius: "27px",
        borderBottomLeftRadius: "27px",
        objectFit: "fill",
    },
    refuseButton: {
        width: '200px',
        height: '40px',
        fontFamily: 'Poppins',
        margin: '5px',
        borderRadius: '10px',
        boxShadow: '0 7px 30px 0 rgba(88, 165, 211, 0.16)',
        color: '#fff',
        backgroundColor: '#00aeef',
        '&:hover': {
            backgroundColor: '#00aeef'
        }
    },
    validateButton: {
        width: '200px',
        height: '40px',
        fontFamily: 'Poppins',
        margin: '5px',
        borderRadius: '10px',
        boxShadow: '0 7px 30px 0 rgba(88, 165, 211, 0.16)',
        color: '#2f2f2f',
        backgroundColor: '#ffffff',
        '&:hover': {
            backgroundColor: '#ffffff'
        }
    },
    overlay: {
        zIndex: theme.zIndex.drawer + 1
    },
    paginationClass: {
        backgroundColor: '#ffffff !important',
        boxShadow: '0 7px 30px 0 rgba(88, 165, 211, 0.16)',
        borderRadius: '8px',
        fontFamily: 'Poppins',
        height: '40px',
        width: '40px',
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center'
    },
    selectedPaginationItem: {
        backgroundColor: '#3eadef !important',
        boxShadow: '0 7px 30px 0 rgba(88, 165, 211, 0.16)',
        borderRadius: '8px',
        fontFamily: 'Poppins',
        height: '40px',
        width: '40px',
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        color: '#ffffff'
    },
    paginationStyles: {
        fontFamily: 'Poppins'
    },
    placeholderStyles: {
        fontFamily: 'Poppins',
        color: '#1B4C61'
    },
}));
const AssociateInfluencersToPrivateBrand = (props) => {
    const { t } = useTranslation('common');
    const classes = useStyles();
    const theme = useTheme();
    const xsDown = useMediaQuery(theme.breakpoints.down('xs'));
    const mdDown = useMediaQuery(theme.breakpoints.down('md'));

    const [page, setPage] = useState(1);
    const [count, setCount] = useState(0);
    const [size, setSize] = useState(10);
    const [checked, setChecked] = useState([]);
    const [overlayState, setOverlayState] = useState(false);
    const [sortHelper, setSortHelper] = useState([1, 0, 0,])
    const [sortEvent, setSortEvent] = useState(0);
    const [influencersNotAssociate, setInfluencersNotAssociated] = useState([]);
    const [influencersTableToRender, setInfluencersTableToRender] = useState([]);
    const [influencersBeforeRender, setInfluencersBeforeRender] = useState([]);
    const [searchValue, setSearchValue] = useState(null);
    const [updateClicked, setUpdateClicked] = useState(false);

    const {
        influencersAssociate,
        influencers,
        loadedInfluencers,
        closeModal,
        updateBrandById,
        loadingUpdateBrand,
    } = props;

    const handleCloseModal = useCallback(() => {
        closeModal(true);
    }, [closeModal]);

    const handleValueChange = event => {
        setSearchValue(event.target.value);
    };

    const handleToggle = (value) => () => {
        const currentIndex = checked.indexOf(value);
        const newChecked = [...checked];

        if (currentIndex === -1) {
            newChecked.push(value);
        } else {
            newChecked.splice(currentIndex, 1);
        }
        setChecked(newChecked);
    };

    useEffect(() => {
        let idInfluencerAssociate = [];
        let influencerNotAssociate = [];
        if (influencersAssociate) {
            for (let i = 0; i < influencersAssociate.influencers.length; i++)
                idInfluencerAssociate.push(influencersAssociate.influencers[i]);

        }
        if (loadedInfluencers) {
            influencers.map(item => {
                influencerNotAssociate.push(item);
                return null;
            });
        }
        setChecked(idInfluencerAssociate)
        setInfluencersNotAssociated(influencerNotAssociate);

    }, [influencersAssociate, influencers, loadedInfluencers]);

    const editBrand = async () => {
        let updates = {};
        updates['influencers'] = checked.map(element => element);
        setUpdateClicked(true);
        updateBrandById(influencersAssociate.key, updates);
    };


    useEffect(() => {
        let filteredInfluencers = influencersNotAssociate.filter(influencer => {
            if (influencer.name !== undefined && searchValue !== null) {
                return influencer.name.toLowerCase().indexOf(searchValue.toLowerCase()) !== -1
            } else {
                return influencer;
            }
        });
        const filtered = filteredInfluencers.filter(
            (element) =>
                element.name !== undefined &&
                element.name !== null &&
                element.name !== "" &&
                element.banner !== undefined &&
                element.banner !== ""
        );
        setInfluencersTableToRender(filtered.slice((page - 1) * size, page * size));
        setInfluencersBeforeRender(filteredInfluencers);
        setCount(
            (filtered.length % size === 0)
                ? Math.trunc(filtered.length / size)
                : Math.trunc(filtered.length / size) + 1
        );



    }, [influencersNotAssociate, searchValue, page, size, sortEvent]);

    useEffect(() => {
        let indexAsc = sortHelper.indexOf(1);
        let indexDesc = sortHelper.indexOf(-1);
        if (indexAsc !== -1) {
            const sortTypeAsc = (indexAsc, tableToWorkOn) => {
                switch (indexAsc) {
                    case 0:
                        tableToWorkOn.sort((a, b) => {
                            if (a.name > b.name) return -1;
                            if (a.name < b.name) return 1;
                            return 0;
                        });
                        break;
                    case 1:
                        tableToWorkOn.sort((a, b) => {
                            if (a.email > b.email) return -1;
                            if (a.email < b.email) return 1;
                            return 0;
                        });
                        break;
                    case 2:
                        tableToWorkOn.sort((a, b) => {
                            if (a.affiliate_id > b.affiliate_id) return -1;
                            if (a.affiliate_id < b.affiliate_id) return 1;
                            return 0;
                        });
                        break;
                    default:
                        break;
                }
            }
            sortTypeAsc(indexAsc, influencersNotAssociate);
        } else {
            const sortTypeDesc = (indexDesc, tableToWorkOn) => {
                switch (indexDesc) {
                    case 0:
                        tableToWorkOn.sort((a, b) => {
                            if (a.name > b.name) return 1;
                            if (a.name < b.name) return -1;
                            return 0;
                        });
                        break;
                    case 1:
                        tableToWorkOn.sort((a, b) => {
                            if (a.email > b.email) return 1;
                            if (a.email < b.email) return -1;
                            return 0;
                        });
                        break;
                    case 2:
                        tableToWorkOn.sort((a, b) => {
                            if (a.affiliate_id > b.affiliate_id) return 1;
                            if (a.affiliate_id < b.affiliate_id) return -1;
                            return 0;
                        });
                        break;
                    default:
                        break;
                }
            };
            sortTypeDesc(indexDesc, influencersNotAssociate);
        }
        setSortEvent(s => s + 1);
    }, [influencersNotAssociate, sortHelper]);

    useEffect(() => {
        if (loadingUpdateBrand) {
            setOverlayState(true);
        }
        if (!loadingUpdateBrand && updateClicked) {
            setOverlayState(false);
            setUpdateClicked(false);
            handleCloseModal();
        }
    }, [loadingUpdateBrand, updateClicked, handleCloseModal]);

    const handlePageChange = (event, value) => {
        setPage(value);
    };

    const handlePageSizeChange = (event) => {
        setSize(event.target.value);
        setPage(1);
    };
    return (
        <Grid container item direction='column' xs={12} justify='center' alignItems='center'>
            <Backdrop className={classes.overlay} open={overlayState}>
                <CircularProgress color='secondary' />
            </Backdrop>
            <Grid container item direction='row' xs={11} justify='center' alignItems='center' className={classes.overallBox}>

                <Grid container item direction='row' alignItems='center' justify='flex-end' xs={12}>
                    <IconButton style={{ marginTop: '10px', marginRight: '10px', color: '#000' }} onClick={handleCloseModal}>
                        <CloseIcon fontSize='large' />
                    </IconButton>
                    <Grid container item direction='column' justify='center' alignItems='center' xs={12}>
                        <Grid container item direction='row' justify='center' alignItems='center' xs={12}>
                            <Paper component="form" className={classes.search}>
                                <IconButton
                                    className={classes.iconButton}
                                    aria-label="search"
                                >
                                    <SearchIcon />
                                </IconButton>
                                <InputBase
                                    className={classes.searchInputInfluenceTab}
                                    type='text'
                                    placeholder={t('ADMIN.VIEWS.PARTNERS.AGENTS.ASSOCIATE_INFLUENCER.PLACEHOLDER_SEARCH')}
                                    onChange={handleValueChange} />
                            </Paper>
                        </Grid>
                        {influencersTableToRender.length === 0 ?
                            <>
                                <Grid container item direction='row' xs={11} justify='center' alignItems='center' style={{ marginTop: '20px' }}>
                                    <img
                                        alt="No Influencers"
                                        src={placeholderInfluencer}
                                        height="75px"
                                        width="75px"
                                    />
                                </Grid>
                                <Grid container item direction='row' xs={11} justify='center' alignItems='center' style={{ marginTop: '10px', marginBottom: ' 30px' }}>
                                    <Typography className={classes.placeholderStyles}>
                                        {t('ADMIN.VIEWS.PARTNERS.AGENTS.ASSOCIATE_INFLUENCER.PLACEHOLDER_NO_INFLUENCERS')}
                                    </Typography>
                                </Grid>
                            </>
                            : <>
                                <Grid container item direction='row' xs={11} alignItems='center' justify='center' style={{ marginTop: "40px" }}>
                                    <div style={{ overflowX: 'auto', width: '100%' }}>
                                        <Grid container item style={{ minWidth: '1000px' }} direction='row' xs={12}>
                                            <Grid container item direction='row' xs={12}>
                                                <Grid container item xs={11}>
                                                    <Grid container justify='center' alignItems='center' style={{ width: '50%' }} onClick={() => {
                                                        (sortHelper[0] === 0) ? setSortHelper([1, 0, 0]) : setSortHelper([-sortHelper[0], 0, 0]);
                                                    }}>
                                                        <Typography className={classes.tableHeader}>
                                                            {t('ADMIN.VIEWS.PARTNERS.AGENTS.ASSOCIATE_INFLUENCER.NAME_INFLUENCER')}
                                                        </Typography>
                                                        {(sortHelper[0] === 1)
                                                            ? <ExpandLessIcon className={classes.tableHeaderIcon} />
                                                            : ((sortHelper[0] === -1)
                                                                ? <ExpandMoreIcon className={classes.tableHeaderIcon} />
                                                                : <SortIcon className={classes.tableHeaderIcon} />)}
                                                    </Grid>
                                                    <Grid container justify='center' alignItems='center' style={{ width: '25%' }} onClick={() => {
                                                        (sortHelper[1] === 0) ? setSortHelper([0, 1, 0]) : setSortHelper([0, -sortHelper[1], 0]);
                                                    }}>
                                                        <Typography className={classes.tableHeader}>
                                                            {t('ADMIN.VIEWS.PARTNERS.AGENTS.ASSOCIATE_INFLUENCER.EMAIL_INFLUENCER')}
                                                        </Typography>
                                                        {(sortHelper[1] === 1)
                                                            ? <ExpandLessIcon className={classes.tableHeaderIcon} />
                                                            : ((sortHelper[1] === -1)
                                                                ? <ExpandMoreIcon className={classes.tableHeaderIcon} />
                                                                : <SortIcon className={classes.tableHeaderIcon} />)}
                                                    </Grid>
                                                    <Grid container justify='center' alignItems='center' style={{ width: '25%' }} onClick={() => {
                                                        (sortHelper[2] === 0) ? setSortHelper([0, 0, 1]) : setSortHelper([0, 0, -sortHelper[2]]);
                                                    }}>
                                                        <Typography className={classes.tableHeader}>
                                                            {t('ADMIN.VIEWS.PARTNERS.AGENTS.ASSOCIATE_INFLUENCER.AFFILIATE_ID_INFLUENCER')}
                                                        </Typography>
                                                        {(sortHelper[2] === 1)
                                                            ? <ExpandLessIcon className={classes.tableHeaderIcon} />
                                                            : ((sortHelper[2] === -1)
                                                                ? <ExpandMoreIcon className={classes.tableHeaderIcon} />
                                                                : <SortIcon className={classes.tableHeaderIcon} />)}
                                                    </Grid>
                                                </Grid>
                                            </Grid>
                                            {influencersTableToRender.map((influencer, key) => {
                                                return (
                                                    <Grid container item xs={12} direction='row' className={classes.tableRow} key={key}>
                                                        <Grid container item xs={11}>
                                                            <Grid container item alignItems='center' style={{ width: '50%' }}>
                                                                <Checkbox
                                                                    edge="end"
                                                                    onChange={handleToggle(influencer.key)}
                                                                    checked={checked.indexOf(influencer.key) !== -1}
                                                                    inputProps={{ 'aria-labelledby': influencer.affiliate_id }}
                                                                />
                                                                <img src={influencer.banner} alt={influencer.name} className={classes.influencerImage} style={{ marginLeft: '30px' }} />
                                                                <Typography className={classes.tableCell} style={{ marginLeft: '30px' }}>{influencer.name}</Typography>

                                                            </Grid>
                                                            <Grid
                                                                container
                                                                item
                                                                justify="center"
                                                                alignItems="center"
                                                                style={{ width: "25%" }}
                                                            >
                                                                <Typography className={classes.tableCell}>{influencer.email}</Typography>
                                                            </Grid>
                                                            <Grid
                                                                container
                                                                item
                                                                justify="center"
                                                                alignItems="center"
                                                                style={{ width: "25%" }}
                                                            >
                                                                <Typography className={classes.tableCell}>{influencer.affiliate_id}</Typography>
                                                            </Grid>

                                                        </Grid>
                                                    </Grid>
                                                )
                                            })}

                                        </Grid>
                                    </div>
                                </Grid>
                                <Grid container item direction='row' xs={11} className={classes.formBox} justify={(xsDown) ? 'center' : (mdDown) ? 'center' : 'space-between'}>
                                    <Button
                                        className={classes.refuseButton}
                                        onClick={handleCloseModal}
                                    >
                                        {t('ADMIN.VIEWS.PARTNERS.AGENTS.ASSOCIATE_INFLUENCER.CANCEL_BUTTON')}
                                    </Button>
                                    <Button
                                        className={classes.validateButton}
                                        onClick={editBrand}
                                    >
                                        {t('ADMIN.VIEWS.PARTNERS.AGENTS.ASSOCIATE_INFLUENCER.VALIDATE_BUTTON')}
                                    </Button>
                                </Grid>


                                <Grid container item direction='row' xs={11} alignItems='center' style={{ marginBottom: '20px' }}>
                                    <Grid container alignItems='center' justify='flex-end'>
                                        <Select
                                            value={size}
                                            onChange={handlePageSizeChange}
                                            input={<BootstrapInput />}
                                            IconComponent={() => (
                                                <SVGIcon name='miniArrow' />
                                            )}
                                            classes={{ root: classes.paginationStyles }}
                                        >
                                            <MenuItem value={5} style={{ fontFamily: 'Poppins' }}>5</MenuItem>
                                            <MenuItem value={10} style={{ fontFamily: 'Poppins' }}>10</MenuItem>
                                            <MenuItem value={20} style={{ fontFamily: 'Poppins' }}>20</MenuItem>
                                        </Select>
                                        <Typography className={classes.tableHeader} style={{ marginRight: '10px', marginLeft: '10px' }}>
                                            {t('ADMIN.VIEWS.PARTNERS.AGENTS.PAGINATION_PLACEHOLDER')}  {influencersBeforeRender.length}
                                        </Typography>
                                        <Pagination
                                            count={count}
                                            page={page}
                                            onChange={handlePageChange}
                                            renderItem={(item) => (
                                                <PaginationItem classes={{ root: classes.paginationClass, selected: classes.selectedPaginationItem }} {...item} />
                                            )} />
                                    </Grid>
                                </Grid>
                            </>}
                    </Grid>
                </Grid>
            </Grid>
        </Grid>


    )

}

const mapStateToProps = (state) => {
    const loadedInfluencers = state.influencersState.loadedInfluencers;
    const influencers = state.influencersState.influencers;
    const loadingUpdateBrand = state.brandsState.loadingUpdateBrand;
    return {
        loadedInfluencers,
        influencers,
        loadingUpdateBrand

    }
}
const mapDispatchToProps = (dispatch) => {
    return {
        updateBrandById: (brandId, updates) => dispatch(updateBrandById(brandId, updates)),
    }
}
export default connect(mapStateToProps, mapDispatchToProps)(AssociateInfluencersToPrivateBrand);
