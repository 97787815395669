import { makeStyles } from "@material-ui/core";
import React, { useState, useEffect, useCallback } from "react";
import { useTranslation } from "react-i18next";
import {
    Grid,
    Box,
    Typography,
    TextField,
    Select,
    Button,
    MenuItem,
    useTheme,
    useMediaQuery,
} from "@material-ui/core";
import { MuiPickersUtilsProvider, DatePicker } from "@material-ui/pickers";
import DateFnsUtils from "@date-io/date-fns";
import SearchIcon from "@material-ui/icons/Search";
import ArrowRightAltIcon from "@material-ui/icons/ArrowRightAlt";
import ArrowDownwardIcon from "@material-ui/icons/ArrowDownward";
import SVGIcon from "../template/assets/SVGIcons/index";
import BootstrapInput from "../template/assets/customComponents/BootstrapInput";
import { MyDate } from "../../utils";
import TrendLoading from "../../ui/trendLoading/TrendLoading";
import EuroSymbolIcon from "@material-ui/icons/EuroSymbol";
import CountUp from "react-countup";
import TouchAppIcon from "@material-ui/icons/TouchApp";
import ProductPieChart from "../template/components/ProductPieChart";
import PlaceholderImage from "../template/assets/img/placeholder_sales.png";
import ConversionsChart from "../template/components/ConversionsChart";
import { connect } from "react-redux";
import { clicksManager, dbManager } from "../../managers";
import InfluencersStatsTable from "../template/components/StatisticsTables/InfluencersStatsTable";
import BrandsStatsTable from "../template/components/StatisticsTables/BrandsStatsTable";
import InfluencerAgentsStatsTable from "../template/components/StatisticsTables/InfluencerAgentsStatsTable";
import InfluencersStatsModal from "../template/components/StatisticsModals/InfluencersStatsModal";
import BrandsStatsModal from "../template/components/StatisticsModals/BrandsStatsModal";
import InfluencerAgentsStatsModal from "../template/components/StatisticsModals/InfluencerAgentsStatsModal";

const useInputStyles = makeStyles((theme) => ({
    root: {
        overflow: "hidden",
        borderRadius: 24,
        backgroundColor: "#fff",
        textAlign: "center",
        width: "170px",
        height: "40px",
        boxShadow: "0 7px 30px 0 rgba(88, 165, 211, 0.16)",
        "&:hover": {
            backgroundColor: "#fff",
        },
        "&$focused": {
            backgroundColor: "#fff",
            borderColor: "#ff005f",
        },
        margin: "5px",
    },
    input: {
        textAlign: "center",
        fontFamily: "Poppins",
        fontWeight: 500,
    },
    focused: {},
}));

const useStyles = makeStyles(theme => ({
    statisticsContent: {
        flexGrow: 1,
        display: 'flex',
        flexDirection: 'column',
        backgroundColor: "#eff9fe",
        alignItems: 'center'
    },
    titles: {
        fontFamily: "Poppins",
        fontWeight: 500,
    },
    smallBox: {
        boxShadow: "0 7px 30px 0 rgba(58, 111, 142, 0.25)",
        backgroundColor: "#fff",
        borderRadius: "41px",
        width: "240px",
        height: "150px",
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
        margin: 10,
    },
    smallBoxTitles: {
        fontFamily: "Poppins",
        fontSize: "16px",
        fontWeight: 500,
        overflow: "hidden",
        textOverflow: "ellipsis",
    },
    smallBoxInfos: {
        fontFamily: "Poppins",
        fontSize: "16px",
        fontWeight: 900,
        overflow: "hidden",
        textOverflow: "ellipsis",
    },
    selectClass: {
        fontFamily: "Poppins",
        borderRadius: "25px",
        width: "130px",
        "&:focus": {
            borderRadius: "25px",
            backgroundColor: "#ffffff",
        },
        boxShadow: "0 7px 30px 0 rgba(88, 165, 211, 0.16)",
    },
    rowSpacing: {
        marginTop: "10px",
        marginBottom: "10px",
    },
    conversionBox: {
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
        backgroundColor: "white",
        height: "300px",
        width: "95%",
        borderRadius: "20px",
        boxShadow: "0 7px 30px 0 rgba(88, 165, 211, 0.16)",
    },
    pieBox: {
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
        backgroundColor: "white",
        height: "300px",
        width: "95%",
        borderRadius: "20px",
        boxShadow: "0 7px 30px 0 rgba(88, 165, 211, 0.16)",
    },
    buttonDateStyle: {
        width: "170px",
        height: "40px",
        margin: "5px",
        overflow: "hidden",
        boxShadow: "0 7px 30px 0 rgb(88 165 211 / 16%)",
        textAlign: "center",
        textTransform: "none",
        borderRadius: "24px",
        padding: "10",
        backgroundColor: "#3eadef",
        "&:hover": {
            backgroundColor: "#3eadef",
        },
    },
    textButtonDate: {
        textAlign: "center",
        fontFamily: "Poppins",
        fontWeight: 500,
        color: "#fff",
    },
    activeButtonStyle: {
        width: 250,
        margin: "5px",
        color: "#ffffff",
        borderRadius: "35px",
        backgroundColor: "#3eadef",
        fontSize: "14px",
        textTransform: "none",
        fontFamily: "Poppins",
        boxShadow: "0 7px 30px 0 rgba(88, 165, 211, 0.16)",
        "&:hover": {
            backgroundColor: "#3eadef",
        },
    },
    buttonStyle: {
        width: 250,
        margin: "5px",
        color: "#000",
        borderRadius: "35px",
        backgroundColor: "#ffffff",
        fontSize: "14px",
        textTransform: "none",
        fontFamily: "Poppins",
        boxShadow: "0 7px 30px 0 rgba(88, 165, 211, 0.16)",
    },
    textPlaceholder: {
        fontFamily: 'Poppins',
        color: '#1b4b61'
    }
}));

const Statistics = (props) => {

    const { t } = useTranslation("common");
    const inputClasses = useInputStyles();
    const classes = useStyles();
    const theme = useTheme();
    const xsDown = useMediaQuery(theme.breakpoints.down("xs"));
    const mdDown = useMediaQuery(theme.breakpoints.down("md"));

    const [loading, setLoading] = useState(false);
    const [periodConversions, setPeriodConversions] = useState([]);
    const [loadedPeriodConversions, setLoadedPeriodConversions] = useState(false);
    const [periodProducts, setPeriodProducts] = useState([]);
    const [periodSalesAmount, setPeriodSalesAmount] = useState(0);
    const [periodUnpaidCommission, setPeriodUnpaidCommission] = useState(0);
    const [periodCommissionSMI, setPeriodCommissionSMI] = useState(0);
    const [periodClicksNumber, setPeriodClicksNumber] = useState(0);
    const [influencersClicksNumber, setInfluencersClicksNumber] = useState({});
    const [brandsClicksNumber, setBrandsClicksNumber] = useState({});
    const [loadedClicksNumber, setLoadedClicksNumber] = useState(false);

    const [finalConversionsPerInfluencerPerBrand, setFinalConversionsPerInfluencerPerBrand] = useState([]);
    const [finalConversionPerBrands, setFinalConversionsPerBrand] = useState([]);
    const [newfinalConversionPerBrands, setNewFinalConversionsPerBrand] = useState([]);

    const [conversionsPerInfluencerPerBrand, setConversionsPerInfluencerperBrand] = useState([]);
    const [conversionsPerInfluencerAgentPerInfluencer, setConversionsPerInfluencerAgentPerInfluencer] = useState([]);
    const [conversionsPerBrand, setConversionsPerBrand] = useState([]);

    const [activeFilter, setActiveFilter] = useState('influencers');

    const [periodSelector, setPeriodSelector] = useState("today");
    const [startPeriodDate, setStartPeriodDate] = useState(new MyDate().dayStart());
    const [endPeriodDate, setEndPeriodDate] = useState(new MyDate().dayEnd());

    const [popupMode, setPopupMode] = useState(false);
    const [popupType, setPopupType] = useState('influencers');
    const [popupData, setPopupData] = useState(null);

    const [filteredData, setFilteredData] = useState(false);

    const {
        allConversions,
        loadedAllConversions,
        allBrands,
        loadedBrands,
        influencers,
        loadedInfluencers,
        influencerAgents,
        loadedInfluencerAgents
    } = props;

    useEffect(() => {
        if (filteredData && loadedClicksNumber) {
            let finalConvPerInfluencerperBrand = [
                ...conversionsPerInfluencerPerBrand
            ];
            finalConvPerInfluencerperBrand.forEach((item) => {
                let tempIndex = Object.keys(influencersClicksNumber).indexOf(item.influencerData.key);
                if (tempIndex !== -1) {
                    item.influencerData['clicks'] = influencersClicksNumber[Object.keys(influencersClicksNumber)[tempIndex]]
                } else {
                    item.influencerData['clicks'] = 0;
                }
            });
            setFinalConversionsPerInfluencerPerBrand(finalConvPerInfluencerperBrand);

            let finalConvPerBrand = [
                ...conversionsPerBrand
            ];
            finalConvPerBrand.forEach((item) => {
                let tempIndex = Object.keys(brandsClicksNumber).indexOf(item.brandData.offerId.toString());
                if (tempIndex !== -1) {
                    item['clicks'] = brandsClicksNumber[Object.keys(brandsClicksNumber)[tempIndex]]
                } else {
                    item['clicks'] = 0;
                }
            });

            Object.keys(brandsClicksNumber).map(element => Number(element)).filter(item => {
                return finalConvPerBrand.map(item => item.brandData.offerId).indexOf(item) === -1
            }).forEach(offerId => {
                let brandToPush = allBrands.filter(brand => brand.offerId === offerId)[0];
                if (brandToPush) {
                    finalConvPerBrand.push({
                        brandData: {
                            ...brandToPush
                        },
                        amount: 0,
                        clicks: brandsClicksNumber[offerId],
                        commission: 0,
                        products: [],
                        salesNumber: 0
                    });
                }
            });
            setFinalConversionsPerBrand(finalConvPerBrand);
            setLoading(false);
        }
    }, [filteredData, influencersClicksNumber, brandsClicksNumber, loadedClicksNumber, conversionsPerBrand, conversionsPerInfluencerPerBrand, allBrands])

    const handlePeriodSelector = (event) => {
        setPeriodSelector(event.target.value);
    };

    const handleEndPeriodChange = (value) => {
        setEndPeriodDate(value);
    };

    const handleStartPeriodChange = (value) => {
        setStartPeriodDate(value);
    };

    const getPreviousMonday = () => {
        let date = new MyDate();
        let day = date.getDay();
        let prevMonday;
        if (date.getDay() === 0) {
            prevMonday = new MyDate();
            prevMonday.setDate(date.getDate() - 7);
            return prevMonday;
        } else {
            prevMonday = new MyDate();
            prevMonday.setDate(date.getDate() - day + 1);
            return prevMonday;
        }
    };

    const handleFilterPeriod = () => {
        setFilteredData(false);
        if (loadedAllConversions) {
            setLoadedPeriodConversions(false);
            setLoadedClicksNumber(false);
            clicksManager.getClicksPerPeriod(startPeriodDate.getTime(), endPeriodDate.getTime(), startPeriodDate.getTimezoneOffset()).then(result => {
                setPeriodClicksNumber(result.data.clicksNumber);
                setInfluencersClicksNumber(result.data.influencersClicks);
                setBrandsClicksNumber(result.data.brandsClicks);
                setLoadedClicksNumber(true);
            });
            const startTimestamp = Math.round(startPeriodDate.getTime() / 1000);
            const endTimestamp = Math.round(endPeriodDate.getTime() / 1000);
            let tempConvArr = allConversions.filter((element) =>
                element.createdAt < endTimestamp && element.createdAt > startTimestamp
            ).sort((a, b) =>
                a.createdAt > b.createdAt ? -1 : a.createdAt < b.createdAt ? 1 : 0
            );
            setPeriodConversions(tempConvArr);
            setLoadedPeriodConversions(true)
        };
    };

    const changeFilter = (filterType) => {
        setActiveFilter(filterType);
    };

    const openPopup = (popupType, data) => {
        setPopupMode(true);
        setPopupType(popupType);
        setPopupData(data);
    };

    const closePopup = () => {
        setPopupMode(false);
        setPopupData(null);
    }

    const handleViewInfluencer = (influencerId) => {
        props.handleInfluencerToView(influencerId);
    };


    const filterArticle = useCallback(async () => {
        let obj = {};
        periodConversions.forEach((conversion) => {
            let found = false;
            Object.keys(obj).forEach((objConversionId) => {
                if (objConversionId === conversion.articleId) {
                    obj[objConversionId].push(conversion);
                    found = true;
                }
            });
            if (!found) obj[conversion.articleId] = [conversion];
        });
        let newArray = [];
        Object.values(obj).forEach((articleConversions) => {
            let amount = 0;
            let commissions = 0;
            let productPurchasers = [];
            let articleImgUrl = "";
            let articlePathUrl = "";
            let purchaseNumber = articleConversions.length;
            let articleId = "";
            articleConversions.forEach((conversion) => {
                let data;
                articleId = conversion.articleId;
                amount += conversion.amount;
                commissions += Number(conversion.commission);
                if (productPurchasers.indexOf(conversion.influencer) === -1)
                    productPurchasers.push(conversion.influencer);
                if (articleImgUrl === "") {
                    if (conversion.articleImgUrl === undefined) {
                        dbManager
                            .fetchSoldArticleById(conversion.articleId)
                            .then((res) => {
                                data = res;
                            })
                            .then(() => {
                                articleImgUrl =
                                    conversion.articleImgUrl === undefined
                                        ? data
                                            ? data.image
                                            : ""
                                        : conversion.articleImgUrl;
                                articlePathUrl =
                                    conversion.articlePathUrl === undefined
                                        ? data
                                            ? data.image
                                            : ""
                                        : conversion.articlePathUrl;
                            });
                    }
                    articleImgUrl =
                        conversion.articleImgUrl === undefined
                            ? data
                                ? data.image
                                : ""
                            : conversion.articleImgUrl;
                    articlePathUrl =
                        conversion.articlePathUrl === undefined
                            ? data
                                ? data.image
                                : ""
                            : conversion.articlePathUrl;
                }
            });
            newArray.push({
                articleId,
                purchaseNumber,
                salesNumber: articleConversions.length,
                amount,
                commissions,
                productPurchasers,
                articleImgUrl,
                articlePathUrl,
            });
        });
        setPeriodProducts(newArray);
    }, [periodConversions]);

    const filterData = useCallback(async () => {
        if (loadedBrands && loadedInfluencers && loadedPeriodConversions && loadedInfluencerAgents) {
            let conversionsPerInfluencerAgentsPerInfluencers = [];
            let conversionPerInfluencersPerBrands = [];
            let conversionsPerBrands = [];
            let amount = 0;
            let commissions = 0;
            let commission_smi = 0;
            periodConversions.forEach(conversion => {
                let indexInArrayToReturn;
                let indexInBrandToReturn;
                amount += Number(conversion.amount);
                if (conversion.commission !== undefined && conversion.commissionAffiliate !== undefined) {
                    commissions += Number(conversion.commission);
                    commission_smi += Number(conversion.commission) - Number(conversion.commissionAffiliate);
                }
                // Filter for conversions per influencers per brand
                conversionPerInfluencersPerBrands.forEach((element, index) => {
                    if (element.influencerData.influencerId === conversion.influencer)
                        indexInArrayToReturn = index;
                });
                if (indexInArrayToReturn !== undefined) {
                    if (conversionPerInfluencersPerBrands[indexInArrayToReturn][conversion.offerId] !== undefined) {
                        conversionPerInfluencersPerBrands[indexInArrayToReturn][conversion.offerId].amount += Number(conversion.amount);
                        conversionPerInfluencersPerBrands[indexInArrayToReturn][conversion.offerId].commission += Number(conversion.commission);
                        conversionPerInfluencersPerBrands[indexInArrayToReturn][conversion.offerId].salesNumber++;
                        conversionPerInfluencersPerBrands[indexInArrayToReturn].influencerData.salesNumber++;
                        conversionPerInfluencersPerBrands[indexInArrayToReturn].influencerData.amount += Number(conversion.amount);
                        conversionPerInfluencersPerBrands[indexInArrayToReturn].influencerData.commission += Number(conversion.commission);
                        conversionPerInfluencersPerBrands[indexInArrayToReturn].influencerData.commission_affiliate += Number(conversion.commissionAffiliate);
                        if (conversionPerInfluencersPerBrands[indexInArrayToReturn].influencerData.products.indexOf(conversion.articleId) !== -1)
                            conversionPerInfluencersPerBrands[indexInArrayToReturn].influencerData.products.push(conversion.articleId);
                    } else {
                        let brandData = allBrands.filter(brand => brand.offerId === conversion.offerId)[0];
                        conversionPerInfluencersPerBrands[indexInArrayToReturn][conversion.offerId] = {
                            brandData: {
                                ...brandData
                            },
                            amount: Number(conversion.amount),
                            commission: Number(conversion.commission),
                            salesNumber: 1,
                        }
                        conversionPerInfluencersPerBrands[indexInArrayToReturn].influencerData.salesNumber++;
                        conversionPerInfluencersPerBrands[indexInArrayToReturn].influencerData.amount += Number(conversion.amount);
                        conversionPerInfluencersPerBrands[indexInArrayToReturn].influencerData.commission += Number(conversion.commission);
                        conversionPerInfluencersPerBrands[indexInArrayToReturn].influencerData.products.push(conversion.articleId);
                    }
                } else {
                    let influencerData = influencers.filter(influencer => influencer.key === conversion.influencer)[0];
                    let brandData = allBrands.filter(brand => brand.offerId === conversion.offerId)[0];
                    if (influencerData && brandData) {
                        let obj = {};
                        obj = {
                            influencerData: {
                                amount: Number(conversion.amount),
                                commission: Number(conversion.commission),
                                commission_affiliate: Number(conversion.commissionAffiliate),
                                products: [conversion.articleId],
                                salesNumber: 1,
                                influencerId: conversion.influencer,
                                ...influencerData
                            }
                        };
                        obj[conversion.offerId] = {
                            brandData: {
                                ...brandData
                            },
                            amount: Number(conversion.amount),
                            commission: Number(conversion.commission),
                            salesNumber: 1,
                        }
                        conversionPerInfluencersPerBrands.push(obj);
                    }
                }

                // Filter for Conversions per influencer agents per influencer
                influencerAgents.forEach((influencerAgent) => {
                    if (influencerAgent.influencersIds) {
                        influencerAgent.influencersIds.forEach(element => {
                            if (element.uid !== undefined) {
                                if (element.uid.indexOf(conversion.influencer) !== -1) {
                                    let indexInFinalTable;
                                    conversionsPerInfluencerAgentsPerInfluencers.forEach((item, index) => {
                                        if (item.influencerAgentData.userId === influencerAgent.userId) indexInFinalTable = index;
                                    });
                                    if (indexInFinalTable !== undefined) {
                                        if (conversionsPerInfluencerAgentsPerInfluencers[indexInFinalTable][conversion.influencer] === undefined) {
                                            let influencerData = influencers.filter(influencer => influencer.key === conversion.influencer)[0];
                                            if (influencerData) {
                                                conversionsPerInfluencerAgentsPerInfluencers[indexInFinalTable].influencerAgentData.amount += Number(conversion.amount);
                                                conversionsPerInfluencerAgentsPerInfluencers[indexInFinalTable].influencerAgentData.commission += Number(conversion.commission);
                                                conversionsPerInfluencerAgentsPerInfluencers[indexInFinalTable].influencerAgentData.salesNumber++
                                                if (conversionsPerInfluencerAgentsPerInfluencers[indexInFinalTable].influencerAgentData.products.indexOf(conversion.articleId) === -1)
                                                    conversionsPerInfluencerAgentsPerInfluencers[indexInFinalTable].influencerAgentData.products.push(conversion.articleId);
                                                conversionsPerInfluencerAgentsPerInfluencers[indexInFinalTable][conversion.influencer] = {
                                                    influencerData: {
                                                        ...influencerData
                                                    },
                                                    amount: Number(conversion.amount),
                                                    commission: Number(conversion.commission),
                                                    salesNumber: 1,
                                                    products: [conversion.articleId]
                                                };
                                            }
                                        } else {
                                            conversionsPerInfluencerAgentsPerInfluencers[indexInFinalTable].influencerAgentData.amount += Number(conversion.amount);
                                            conversionsPerInfluencerAgentsPerInfluencers[indexInFinalTable].influencerAgentData.commission += Number(conversion.commission);
                                            conversionsPerInfluencerAgentsPerInfluencers[indexInFinalTable].influencerAgentData.salesNumber++
                                            if (conversionsPerInfluencerAgentsPerInfluencers[indexInFinalTable].influencerAgentData.products.indexOf(conversion.articleId) === -1)
                                                conversionsPerInfluencerAgentsPerInfluencers[indexInFinalTable].influencerAgentData.products.push(conversion.articleId);
                                            conversionsPerInfluencerAgentsPerInfluencers[indexInFinalTable][conversion.influencer].amount += Number(conversion.amount);
                                            conversionsPerInfluencerAgentsPerInfluencers[indexInFinalTable][conversion.influencer].commission += Number(conversion.commission);
                                            conversionsPerInfluencerAgentsPerInfluencers[indexInFinalTable][conversion.influencer].salesNumber++;
                                            if (conversionsPerInfluencerAgentsPerInfluencers[indexInFinalTable][conversion.influencer].products.indexOf(conversion.articleId) === -1)
                                                conversionsPerInfluencerAgentsPerInfluencers[indexInFinalTable][conversion.influencer].products.push(conversion.articleId);
                                        }
                                    } else {
                                        let influencerData = influencers.filter(influencer => influencer.key === conversion.influencer)[0];
                                        if (influencerData) {
                                            let obj = {
                                                influencerAgentData: {
                                                    ...influencerAgent,
                                                    amount: Number(conversion.amount),
                                                    commission: Number(conversion.commission),
                                                    salesNumber: 1,
                                                    products: [conversion.articleId]
                                                },
                                            };
                                            obj[influencerData.key] = {
                                                influencerData: {
                                                    ...influencerData
                                                },
                                                amount: Number(conversion.amount),
                                                commission: Number(conversion.commission),
                                                salesNumber: 1,
                                                products: [conversion.articleId]
                                            }
                                            conversionsPerInfluencerAgentsPerInfluencers.push(obj);
                                        }
                                    }
                                }
                            }
                        });
                    }
                });

                // Filter for Conversions per brands
                conversionsPerBrands.forEach((element, index) => {
                    if (element.brandData.offerId === conversion.offerId)
                        indexInBrandToReturn = index
                });
                if (indexInBrandToReturn !== undefined) {
                    conversionsPerBrands[indexInBrandToReturn].amount += Number(conversion.amount);
                    conversionsPerBrands[indexInBrandToReturn].commission += Number(conversion.commission);
                    conversionsPerBrands[indexInBrandToReturn].salesNumber++;
                    if (conversionsPerBrands[indexInBrandToReturn].products.indexOf(conversion.articleId) === -1)
                        conversionsPerBrands[indexInBrandToReturn].products.push(conversion.articleId);
                } else {
                    let brandData = allBrands.filter(item => item.offerId === conversion.offerId)[0]
                    if (brandData !== undefined) {
                        let obj = {
                            brandData: {
                                ...brandData
                            },
                            amount: Number(conversion.amount),
                            commission: Number(conversion.commission),
                            salesNumber: 1,
                            products: [conversion.articleId]
                        };
                        conversionsPerBrands.push(obj)
                    }
                }
            });

            // Setting filtered Data for component usage
            setPeriodSalesAmount(amount);
            setPeriodUnpaidCommission(commissions);
            setPeriodCommissionSMI(commission_smi);
            setConversionsPerInfluencerperBrand(conversionPerInfluencersPerBrands);
            setConversionsPerInfluencerAgentPerInfluencer(conversionsPerInfluencerAgentsPerInfluencers);
            setConversionsPerBrand(conversionsPerBrands);
            setFilteredData(true);
        }
        // eslint-disable-next-line
    }, [periodConversions, influencers, allBrands, loadedBrands, loadedInfluencers, loadedPeriodConversions, loadedInfluencerAgents, influencerAgents]);

    useEffect(() => {
        let convBrand = [];
        if (finalConversionPerBrands) {
            finalConversionPerBrands.forEach((brand, i) => {
                clicksManager.getClicksPerBrand(
                    startPeriodDate.getTime(),
                    endPeriodDate.getTime(),
                    brand.brandData.offerId,
                    startPeriodDate.getTimezoneOffset()
                )
                    .then((result) => {
                        convBrand.push({ ...brand, influencersClicks: { ...result.data.influencersClicks } })
                    });
            });
        }
        setNewFinalConversionsPerBrand(convBrand);
        // eslint-disable-next-line
    }, [finalConversionPerBrands]);

    //Effect for handling period change
    useEffect(() => {
        switch (periodSelector) {
            case "today":
                setStartPeriodDate(new MyDate().dayStart());
                setEndPeriodDate(new MyDate().dayEnd());
                break;
            case "week":
                {
                    let prevMonday = getPreviousMonday().dayStart();
                    let endOfWeek = getPreviousMonday().addWeeks(1).addDays(-1).dayEnd();
                    setStartPeriodDate(prevMonday);
                    setEndPeriodDate(endOfWeek);
                }
                break;
            case "month":
                {
                    let startDate = new MyDate();
                    startDate.setDate(1);
                    startDate = startDate.dayStart();
                    let endDate = new MyDate().addMonths(1);
                    endDate.setDate(1);
                    endDate = endDate.addDays(-1).dayEnd();
                    setStartPeriodDate(startDate);
                    setEndPeriodDate(endDate);
                }
                break;
            case "lastWeek":
                {
                    let monday = getPreviousMonday().addWeeks(-1).dayStart();
                    let endOfWeek = getPreviousMonday().addDays(-1).dayEnd();
                    setStartPeriodDate(monday);
                    setEndPeriodDate(endOfWeek);
                }
                break;
            case "lastMonth":
                {
                    let startDate = new MyDate();
                    startDate.setDate(1);
                    startDate = startDate.addMonths(-1).dayStart();
                    let endDate = new MyDate();
                    endDate.setDate(1);
                    endDate = endDate.addDays(-1).dayEnd();
                    setStartPeriodDate(startDate);
                    setEndPeriodDate(endDate);
                }
                break;
            case "customMode":
                break;
            default:
                break;
        }
    }, [periodSelector]);

    //Set Period Conversions
    useEffect(() => {
        if (loadedAllConversions) {
            setLoadedPeriodConversions(false);
            const startTimestamp = Math.round((new MyDate()).dayStart().getTime() / 1000);
            const endTimestamp = Math.round((new MyDate()).dayEnd().getTime() / 1000);
            let tempConvArr = allConversions.filter((element) =>
                element.createdAt < endTimestamp && element.createdAt > startTimestamp
            ).sort((a, b) =>
                a.createdAt > b.createdAt ? -1 : a.createdAt < b.createdAt ? 1 : 0
            );
            clicksManager.getClicksPerPeriod((new MyDate()).dayStart().getTime(), (new MyDate()).dayEnd().getTime(), (new MyDate()).getTimezoneOffset()).then(result => {
                setPeriodClicksNumber(result.data.clicksNumber);
                setInfluencersClicksNumber(result.data.influencersClicks);
                setBrandsClicksNumber(result.data.brandsClicks);
                setLoadedClicksNumber(true);
            });
            setPeriodConversions(tempConvArr);
            setLoadedPeriodConversions(true);
        };
    }, [loadedAllConversions, allConversions]);

    useEffect(() => {
        setLoading(true);
        filterData();
    }, [filterData]);

    useEffect(() => {
        filterArticle();
    }, [filterArticle]);


    return (
        <Grid className={classes.statisticsContent}>
            <div style={{ width: '100%', display: 'flex', flexDirection: 'column', alignItems: 'center' }}>
                {
                    (popupMode)
                        ? (popupType === 'influencers')
                            ? <InfluencersStatsModal popupData={popupData} periodConversions={periodConversions} close={closePopup} />
                            : (popupType === 'brand')
                                ? <BrandsStatsModal popupData={popupData} url={props.url} periodConversions={periodConversions} close={closePopup} handleViewInfluencer={(influencerId) => handleViewInfluencer(influencerId)} />
                                : <InfluencerAgentsStatsModal popupData={popupData} periodConversions={periodConversions} close={closePopup} />
                        : <>
                            <Grid container item direction="row" wrap="wrap" lg={11} justify={mdDown ? "center" : "flex-end"} alignItems="center" className={classes.rowSpacing}>
                                <Typography className={classes.titles}>
                                    {t("ADMIN.VIEWS.STATISTICS.PERIOD")}:{" "}
                                </Typography>
                                <Select
                                    value={periodSelector}
                                    onChange={handlePeriodSelector}
                                    style={{ margin: 10 }}
                                    input={<BootstrapInput />}
                                    IconComponent={() => <SVGIcon name="miniArrow" />}
                                    classes={{ root: classes.selectClass }}
                                >
                                    <MenuItem value="month" style={{ fontFamily: "Poppins" }}>
                                        {t(
                                            "ADMIN.VIEWS.STATISTICS.PERIOD_SELECTOR.CURRENT_MONTH"
                                        )}
                                    </MenuItem>
                                    <MenuItem value="week" style={{ fontFamily: "Poppins" }}>
                                        {t(
                                            "ADMIN.VIEWS.STATISTICS.PERIOD_SELECTOR.CURRENT_WEEK"
                                        )}
                                    </MenuItem>
                                    <MenuItem value="today" style={{ fontFamily: "Poppins" }}>
                                        {t("ADMIN.VIEWS.STATISTICS.PERIOD_SELECTOR.TODAY")}
                                    </MenuItem>
                                    <MenuItem value="lastWeek" style={{ fontFamily: "Poppins" }}>
                                        {t(
                                            "ADMIN.VIEWS.STATISTICS.PERIOD_SELECTOR.PREVIOUS_WEEK"
                                        )}
                                    </MenuItem>
                                    <MenuItem value="lastMonth" style={{ fontFamily: "Poppins" }}>
                                        {t(
                                            "ADMIN.VIEWS.STATISTICS.PERIOD_SELECTOR.PREVIOUS_MONTH"
                                        )}
                                    </MenuItem>
                                    <MenuItem value="customMode" style={{ fontFamily: "Poppins" }}>
                                        {t(
                                            "ADMIN.VIEWS.STATISTICS.PERIOD_SELECTOR.CUSTOMIZED"
                                        )}
                                    </MenuItem>
                                </Select>
                                <div
                                    style={{
                                        display: "flex",
                                        flexDirection: xsDown ? "column" : "row",
                                        alignItems: xsDown ? "center" : "center",
                                        flexWrap: "wrap",
                                        justifyContent: xsDown ? "center" : "flex-start",
                                    }}
                                >
                                    <MuiPickersUtilsProvider utils={DateFnsUtils}>
                                        <DatePicker
                                            format="dd-MM-yyyy"
                                            views={["year", "month", "date"]}
                                            value={startPeriodDate}
                                            onChange={handleStartPeriodChange}
                                            onOpen={() => {
                                                setPeriodSelector("customMode");
                                            }}
                                            TextFieldComponent={(props) => (
                                                <TextField
                                                    {...props}
                                                    InputProps={{
                                                        classes: inputClasses,
                                                        disableUnderline: true,
                                                    }}
                                                />
                                            )}
                                        />
                                    </MuiPickersUtilsProvider>
                                    {!xsDown ? (
                                        <ArrowRightAltIcon fontSize="large" />
                                    ) : (
                                        <ArrowDownwardIcon fontSize="large" />
                                    )}

                                    <MuiPickersUtilsProvider utils={DateFnsUtils}>
                                        <DatePicker
                                            format="dd-MM-yyyy"
                                            views={["year", "month", "date"]}
                                            value={endPeriodDate}
                                            onChange={handleEndPeriodChange}
                                            onOpen={() => setPeriodSelector("customMode")}
                                            TextFieldComponent={(props) => (
                                                <TextField
                                                    {...props}
                                                    InputProps={{
                                                        classes: inputClasses,
                                                        disableUnderline: true,
                                                    }}
                                                />
                                            )}
                                        />
                                    </MuiPickersUtilsProvider>
                                    <Button
                                        className={classes.buttonDateStyle}
                                        onClick={handleFilterPeriod}
                                    >
                                        <SearchIcon style={{ color: "#fff", height: "20px" }} />
                                        <Typography className={classes.textButtonDate}>
                                            {t("ADMIN.VIEWS.STATISTICS.BUTTON_DATE")}
                                        </Typography>
                                    </Button>
                                </div>
                            </Grid>
                            <Grid container item direction="row" xs={11} className={classes.rowSpacing}>
                                <Grid container item direction="row" wrap="wrap" justify="center">
                                    <Box className={classes.smallBox}>
                                        {loading ? (
                                            <TrendLoading />
                                        ) : (
                                            <Grid container direction="row">
                                                <Grid container item xs={6} justify="center">
                                                    <EuroSymbolIcon style={{ fontSize: "60px", color: "#ef7e3e" }} />
                                                </Grid>
                                                <Grid container item xs={6} direction="column" justify="center">
                                                    <Typography className={classes.smallBoxTitles}>
                                                        {t("ADMIN.VIEWS.STATISTICS.TURNOVER_BOX_TITLE")}
                                                    </Typography>
                                                    <Grid container item direction="row">
                                                        <CountUp className={classes.smallBoxInfos} start={0} end={Math.round(periodSalesAmount * 100) / 100} decimals={2} decimal="," />
                                                        <span className={classes.smallBoxInfos}> €</span>
                                                    </Grid>
                                                </Grid>
                                            </Grid>
                                        )}
                                    </Box>
                                    <Box className={classes.smallBox}>
                                        {loading ? (
                                            <TrendLoading />
                                        ) : (
                                            <Grid container direction="row">
                                                <Grid container item xs={6} justify="center">
                                                    <SVGIcon name="ventes" width={"60px"} height={"60px"} />
                                                </Grid>
                                                <Grid container item xs={6} direction="column" justify="center">
                                                    <Typography className={classes.smallBoxTitles}>
                                                        {t("ADMIN.VIEWS.STATISTICS.SALES_BOX_TITLE")}
                                                    </Typography>
                                                    <Grid item>
                                                        <CountUp className={classes.smallBoxInfos} start={0} end={periodConversions.length} />
                                                    </Grid>
                                                </Grid>
                                            </Grid>
                                        )}
                                    </Box>
                                    <Box className={classes.smallBox}>
                                        {!loadedClicksNumber ? (
                                            <TrendLoading />
                                        ) : (
                                            <Grid container direction="row">
                                                <Grid container item xs={6} justify="center">
                                                    <TouchAppIcon style={{ fontSize: "60px", color: "#3568eb" }} />
                                                </Grid>
                                                <Grid container item xs={6} direction="column" justify="center">
                                                    <Typography className={classes.smallBoxTitles}>
                                                        {t("ADMIN.VIEWS.STATISTICS.CLICKS_BOX_TITLE")}
                                                    </Typography>
                                                    <Grid item>
                                                        <CountUp className={classes.smallBoxInfos} start={0} end={periodClicksNumber} />
                                                    </Grid>
                                                </Grid>
                                            </Grid>
                                        )}
                                    </Box>
                                    <Box className={classes.smallBox}>
                                        {loading ? (
                                            <TrendLoading />
                                        ) : (
                                            <Grid container direction="row">
                                                <Grid container item xs={6} justify="center">
                                                    <SVGIcon name="commissionPaye" width={"60px"} height={"60px"} />
                                                </Grid>
                                                <Grid container item xs={6} direction="column" justify="center">
                                                    <Typography className={classes.smallBoxTitles}>
                                                        {t("ADMIN.VIEWS.STATISTICS.PAID_COMMISSIONS_BOX_TITLE")}
                                                    </Typography>
                                                    <Typography className={classes.smallBoxInfos}>
                                                        0 €
                                        </Typography>
                                                </Grid>
                                            </Grid>
                                        )}
                                    </Box>
                                    <Box className={classes.smallBox}>
                                        {loading ? (
                                            <TrendLoading />
                                        ) : (
                                            <Grid container direction="row">
                                                <Grid container item xs={6} justify="center">
                                                    <SVGIcon name="commission" width={"60px"} height={"60px"} />
                                                </Grid>
                                                <Grid container item direction="row" xs={6}>
                                                    <Typography className={classes.smallBoxTitles}>
                                                        {t("ADMIN.VIEWS.STATISTICS.UNPAID_COMMISSIONS_BOX_TITLE")}
                                                    </Typography>
                                                    <CountUp className={classes.smallBoxInfos} start={0} end={Math.round(periodUnpaidCommission * 100) / 100} decimals={2} decimal="," />
                                                    <span className={classes.smallBoxInfos}> €</span>
                                                </Grid>
                                            </Grid>
                                        )}
                                    </Box>
                                    <Box className={classes.smallBox}>
                                        {loading ? (
                                            <TrendLoading />
                                        ) : (
                                            <Grid container direction="row">
                                                <Grid container item xs={6} justify="center">
                                                    <SVGIcon name="commission" width={"60px"} height={"60px"} />
                                                </Grid>
                                                <Grid container item direction="row" xs={6}>
                                                    <Typography className={classes.smallBoxTitles}>
                                                        Commissions SMI
                                                    </Typography>
                                                    <CountUp className={classes.smallBoxInfos} start={0} end={Math.round(periodCommissionSMI * 100) / 100} decimals={2} decimal="," />
                                                    <span className={classes.smallBoxInfos}> €</span>
                                                </Grid>
                                            </Grid>
                                        )}
                                    </Box>
                                </Grid>
                            </Grid>
                            <Grid container direction="row" item xs={11} justify="center" alignItems="center" style={{ marginBottom: "15px" }}>
                                <Grid container direction="row" item md={7} sm={12} justify="center">
                                    <Grid container justify="center" style={{ marginTop: "10px", marginBottom: "10px" }}>
                                        <Typography className={classes.titles}>
                                            {t("ADMIN.VIEWS.STATISTICS.SALES_STATISTICS_BOX_TITLE")}
                                        </Typography>
                                    </Grid>
                                    <Box className={classes.conversionBox}>
                                        {loading ? (
                                            <TrendLoading />
                                        ) : periodConversions.length !== 0 ? (
                                            <ConversionsChart
                                                {...{ customDurationConversions: periodConversions }}
                                            />
                                        ) : (
                                            <Grid container direction="column" justify="space-around" alignItems="center" style={{ height: "50%" }}>
                                                <img alt="No sales" src={PlaceholderImage} height="75px" width="75px" />
                                                <Typography className={classes.textPlaceholder}>
                                                    {t("ADMIN.VIEWS.STATISTICS.NO_PERIOD_GENERATED_SALES_PLACEHOLDER")}
                                                </Typography>
                                            </Grid>
                                        )
                                        }
                                    </Box>
                                </Grid>
                                <Grid container direction="row" item md={5} sm={12} justify="center">
                                    <Grid container justify="center" style={{ marginTop: "10px", marginBottom: "10px" }}>
                                        <Typography className={classes.titles}>
                                            {t("ADMIN.VIEWS.STATISTICS.SALES_PER_PRODUCT_NUMBER_BOX_TITLE")}
                                        </Typography>
                                    </Grid>
                                    <Box className={classes.pieBox}>
                                        {loading ? (
                                            <TrendLoading />
                                        ) :
                                            periodConversions.length !== 0 ? (
                                                <ProductPieChart
                                                    {...{
                                                        articlesInfos: periodProducts,
                                                        startPeriodDate,
                                                        endPeriodDate,
                                                    }}
                                                />
                                            ) : (
                                                <Grid container direction="column" justify="space-around" alignItems="center" style={{ height: "50%" }}>
                                                    <img alt="No sales" src={PlaceholderImage} height="75px" width="75px" />
                                                    <Typography className={classes.textPlaceholder}>
                                                        {t("ADMIN.VIEWS.STATISTICS.NO_PERIOD_GENERATED_SALES_PLACEHOLDER")}
                                                    </Typography>
                                                </Grid>
                                            )
                                        }
                                    </Box>
                                </Grid>
                            </Grid>
                            <Grid container item direction="row" xs={11} className={classes.rowSpacing}>
                                <Grid container item xs={12} justify="center">
                                    <Grid container direction="column">
                                        <Typography className={classes.titles} style={{ margin: 5 }}>
                                            {t("ADMIN.VIEWS.STATISTICS.STATISTICS_FILTER_TITLE")} :
                                        </Typography>
                                        <Grid container direction="row" wrap="wrap">
                                            <Button className={activeFilter === "influencers" ? classes.activeButtonStyle : classes.buttonStyle} onClick={() => changeFilter("influencers")} disableRipple>
                                                {t("ADMIN.VIEWS.STATISTICS.FILTER_INFLUENCER_TITLE")}
                                            </Button>
                                            <Button className={activeFilter === "brand" ? classes.activeButtonStyle : classes.buttonStyle} onClick={() => changeFilter("brand")} disableRipple>
                                                {t("ADMIN.VIEWS.STATISTICS.FILTER_BRAND_TITLE")}
                                            </Button>
                                            <Button className={activeFilter === "influencerAgents" ? classes.activeButtonStyle : classes.buttonStyle} onClick={() => changeFilter("influencerAgents")} disableRipple>
                                                {t("ADMIN.VIEWS.STATISTICS.FILTER_INFLUENCER_AGENT_TITLE")}
                                            </Button>
                                        </Grid>
                                    </Grid>
                                </Grid>
                                {
                                    (activeFilter === "influencers")
                                        ? <InfluencersStatsTable conversions={finalConversionsPerInfluencerPerBrand} openPopup={(data) => openPopup('influencers', data)} />
                                        : (activeFilter === "brand")
                                            ? <BrandsStatsTable conversions={newfinalConversionPerBrands} openPopup={(data) => openPopup('brand', data)} />
                                            : <InfluencerAgentsStatsTable conversions={conversionsPerInfluencerAgentPerInfluencer} openPopup={(data) => openPopup('influencerAgent', data)} />
                                }
                            </Grid>
                        </>
                }
            </div>
        </Grid>
    )
};

const mapStateToProps = (state, ownProps) => {
    const allConversions = state.conversionsState.allConversions;
    const allBrands = state.brandsState.allBrands;
    const influencers = state.influencersState.influencers;
    const influencerAgents = state.influencerAgentsState.influencerAgents;
    const loadedAllConversions = state.conversionsState.loadedAllConversions;
    const loadedBrands = state.brandsState.loadedBrands;
    const loadedInfluencers = state.influencersState.loadedInfluencers;
    const loadedInfluencerAgents = state.influencerAgentsState.loadedInfluencerAgents;
    return {
        allConversions,
        influencers,
        allBrands,
        influencerAgents,
        loadedAllConversions,
        loadedInfluencers,
        loadedBrands,
        loadedInfluencerAgents
    };
}

export default connect(mapStateToProps)(Statistics);