import React, { useState, useEffect } from "react";
import {
  Grid,
  Box,
  Typography,
  Select,
  MenuItem,
  CircularProgress,
} from "@material-ui/core";
import { makeStyles } from "@material-ui/core";
import TrendLoading from "../../../../ui/trendLoading/TrendLoading";
import { Pagination, PaginationItem } from "@material-ui/lab";
import BootstrapInput from "../../assets/customComponents/BootstrapInput";
import SVGIcon from "../../assets/SVGIcons";
import ExpandLessIcon from "@material-ui/icons/ExpandLess";
import ExpandMoreIcon from "@material-ui/icons/ExpandMore";
import SortIcon from "@material-ui/icons/Sort";
import { useTranslation } from "react-i18next";
//import { clicksManager } from "../../../../managers";
const useStyles = makeStyles((theme) => {
  return {
    influenceursContent: {
      flexGrow: 1,
      display: "flex",
      flexDirection: "column",
      backgroundColor: "#eff9fe",
    },
    buttonsRow: {
      width: "100%",
      marginTop: "15px",
      marginBottom: "15px",
    },
    activeButtonStyle: {
      width: 300,
      margin: "15px",
      color: "#ffffff",
      borderRadius: "35px",
      backgroundColor: "#3eadef",
      fontSize: "16px",
      textTransform: "none",
      fontFamily: "Poppins",
      boxShadow: "0 7px 30px 0 rgba(88, 165, 211, 0.16)",
      "&:hover": {
        backgroundColor: "#3eadef",
      },
    },
    buttonStyle: {
      width: 300,
      margin: "15px",
      color: "#000",
      borderRadius: "35px",
      backgroundColor: "#ffffff",
      fontSize: "16px",
      textTransform: "none",
      fontFamily: "Poppins",
      boxShadow: "0 7px 30px 0 rgba(88, 165, 211, 0.16)",
    },
    influencerBoxContainer: {
      marginTop: "10px",
      marginBottom: "10px",
      minWidth: "1050px",
    },
    influencerBox: {
      display: "flex",
      width: "95%",
      backgroundColor: "white",
      height: "80px",
      borderRadius: "27px",
      boxShadow: "0 7px 30px 0 rgba(88, 165, 211, 0.16)",
    },
    influencerImage: {
      width: "160px",
      height: "80px",
      borderTopLeftRadius: "27px",
      borderBottomLeftRadius: "27px",
      objectFit: "fill",
    },
    paginationRow: {
      display: "flex",
      justifyContent: "center",
      marginTop: "10px",
      marginBottom: "10px",
    },
    tableHeader: {
      fontFamily: "Poppins",
      fontSize: "14px",
      textAlign: "center",
      "&:hover": {
        cursor: "pointer",
      },
    },
    tableHeaderIcon: {
      "&:hover": {
        cursor: "pointer",
      },
    },
    tableRowContent: {
      fontFamily: "Poppins",
    },
    influencerName: {
      fontFamily: "Poppins",
      textDecoration: "underline",
      "&:hover": {
        cursor: "pointer",
      },
    },
    paginationClass: {
      backgroundColor: "#ffffff !important",
      boxShadow: "0 7px 30px 0 rgba(88, 165, 211, 0.16)",
      borderRadius: "8px",
      fontFamily: "Poppins",
      height: "40px",
      width: "40px",
      display: "flex",
      justifyContent: "center",
      alignItems: "center",
    },
    selectedPaginationItem: {
      backgroundColor: "#3eadef !important",
      boxShadow: "0 7px 30px 0 rgba(88, 165, 211, 0.16)",
      borderRadius: "8px",
      fontFamily: "Poppins",
      height: "40px",
      width: "40px",
      display: "flex",
      justifyContent: "center",
      alignItems: "center",
      color: "#ffffff",
    },
    selectClass: {
      fontFamily: "Poppins",
    },
    influencerCard: {
      margin: "10px",
      width: "280px",
      height: "140px",
      borderRadius: "24px",
      boxShadow: "0 7px 30px 0 rgba(24, 61, 83, 0.16)",
      "&:hover": {
        cursor: "pointer",
      },
    },
    influencerCardImage: {
      objectFit: "contain",
      width: "280px",
      height: "140px",
      borderRadius: "24px",
    },
    textPlaceholder: {
      fontFamily: "Poppins",
      fontSize: "16px",
      margin: 10,
      color: "#1b4c61",
      textAlign: "center",
    },
  };
});
const InfluencersTable = (props) => {
  const { t } = useTranslation("common");
  const classes = useStyles();
  const [loading, setLoading] = useState(true);
  const [sortHelper, setSortHelper] = useState([1, 0, 0, 0, 0, 0]);
  const [sortEvent, setSortEvent] = useState(0);
  const [page, setPage] = useState(1);
  const [count, setCount] = useState(1);
  const [size, setSize] = useState(10);
  const [influencerToRenderFinal, setInfluencerToRenderFinal] = useState([]);
  const { influencerToRender } = props;

  const handlePageSizeChange = (event) => {
    setSize(event.target.value);
    setPage(1);
  };
  const handlePageChange = (event, value) => {
    setPage(value);
  };

  useEffect(() => {
    let indexAsc = sortHelper.indexOf(1);
    let indexDesc = sortHelper.indexOf(-1);
    if (indexAsc !== -1) {
      const sortTypeAsc = (indexAsc, tableToWorkOn) => {
        switch (indexAsc) {
          case 0:
            tableToWorkOn.sort((a, b) => {
              if (a.name > b.name) return -1;
              if (a.name < b.name) return 1;
              return 0;
            });
            break;
          case 1:
            tableToWorkOn.sort((a, b) => {
              if (a.amount > b.amount) return -1;
              if (a.amount < b.amount) return 1;
              return 0;
            });
            break;
          case 2:
            tableToWorkOn.sort((a, b) => {
              if (a.salesNumber > b.salesNumber) return -1;
              if (a.salesNumber < b.salesNumber) return 1;
              return 0;
            });
            break;
          case 3:
            tableToWorkOn.sort((a, b) => {
              if (a.commission > b.commission) return -1;
              if (a.commission < b.commission) return 1;
              return 0;
            });
            break;
          case 4:
            tableToWorkOn.sort((a, b) => {
              if (a.products > b.products) return -1;
              if (a.products < b.products) return 1;
              return 0;
            });
            break;
          case 5:
            tableToWorkOn.sort((a, b) => {
              if (a.clicks > b.clicks) return -1;
              if (a.clicks < b.clicks) return 1;
              return 0;
            });
            break;
          default:
            break;
        }
      };
      sortTypeAsc(indexAsc, influencerToRender);
    } else {
      const sortTypeDesc = (indexDesc, tableToWorkOn) => {
        switch (indexDesc) {
          case 0:
            tableToWorkOn.sort((a, b) => {
              if (a.name > b.name) return 1;
              if (a.name < b.name) return -1;
              return 0;
            });
            break;
          case 1:
            tableToWorkOn.sort((a, b) => {
              if (a.amount > b.amount) return 1;
              if (a.amount < b.amount) return -1;
              return 0;
            });
            break;
          case 2:
            tableToWorkOn.sort((a, b) => {
              if (a.salesNumber > b.salesNumber) return 1;
              if (a.salesNumber < b.salesNumber) return -1;
              return 0;
            });
            break;
          case 3:
            tableToWorkOn.sort((a, b) => {
              if (a.commission > b.commission) return 1;
              if (a.commission < b.commission) return -1;
              return 0;
            });
            break;
          case 4:
            tableToWorkOn.sort((a, b) => {
              if (a.products > b.products) return 1;
              if (a.products < b.products) return -1;
              return 0;
            });
            break;
          case 5:
            tableToWorkOn.sort((a, b) => {
              if (a.clicks > b.clicks) return 1;
              if (a.clicks < b.clicks) return -1;
              return 0;
            });
            break;
          default:
            break;
        }
      };
      sortTypeDesc(indexDesc, influencerToRender);
    }
    setSortEvent((s) => s + 1);
  }, [influencerToRender, sortHelper]);

  useEffect(() => {
    setInfluencerToRenderFinal(
      influencerToRender.slice((page - 1) * size, page * size)
    );
    setCount(
      influencerToRender.length % size === 0
        ? Math.trunc(influencerToRender.length / size)
        : Math.trunc(influencerToRender.length / size) + 1
    );
    setLoading(false);
  }, [influencerToRender, size, page, sortEvent]);

  const openPopup = (influencer) => {
    props.openPopup(influencer);
  };

  return loading ? (
    <TrendLoading />
  ) : (
    <>
      <Grid
        container
        item
        direction="row"
        xs={12}
        alignItems="center"
        justify="center"
      >
        <div style={{ overflowX: "auto", width: "100%", marginTop: "30px" }}>
          <Grid
            container
            item
            style={{ minWidth: "1050px" }}
            direction="row"
            xs={12}
          >
            <Grid container item direction="row" justify="center">
              <Grid container item style={{ width: "95%" }}>
                <Grid
                  container
                  item
                  alignItems="center"
                  justify="center"
                  style={{ width: "35%" }}
                  onClick={() => {
                    sortHelper[0] === 0
                      ? setSortHelper([1, 0, 0, 0, 0, 0])
                      : setSortHelper([-sortHelper[0], 0, 0, 0, 0, 0]);
                  }}
                >
                  <Typography
                    className={classes.tableHeader}
                    style={{ marginLeft: "50px" }}
                  >
                    {t("INFLUENCER_AGENT.VIEWS.STATISTICS.TABLE_HEADER.NAME")}
                  </Typography>
                  {sortHelper[0] === 1 ? (
                    <ExpandLessIcon className={classes.tableHeaderIcon} />
                  ) : sortHelper[0] === -1 ? (
                    <ExpandMoreIcon className={classes.tableHeaderIcon} />
                  ) : (
                    <SortIcon className={classes.tableHeaderIcon} />
                  )}
                </Grid>
                <Grid
                  container
                  justify="center"
                  alignItems="center"
                  style={{ width: "13%" }}
                  onClick={() => {
                    sortHelper[1] === 0
                      ? setSortHelper([0, 1, 0, 0, 0, 0])
                      : setSortHelper([0, -sortHelper[1], 0, 0, 0, 0]);
                  }}
                >
                  <Typography className={classes.tableHeader}>
                    {t("INFLUENCER_AGENT.VIEWS.STATISTICS.TABLE_HEADER.AMOUNT")}
                  </Typography>
                  {sortHelper[1] === 1 ? (
                    <ExpandLessIcon className={classes.tableHeaderIcon} />
                  ) : sortHelper[1] === -1 ? (
                    <ExpandMoreIcon className={classes.tableHeaderIcon} />
                  ) : (
                    <SortIcon className={classes.tableHeaderIcon} />
                  )}
                </Grid>
                <Grid
                  container
                  justify="center"
                  alignItems="center"
                  style={{ width: "13%" }}
                  onClick={() => {
                    sortHelper[2] === 0
                      ? setSortHelper([0, 0, 1, 0, 0, 0])
                      : setSortHelper([0, 0, -sortHelper[2], 0, 0, 0]);
                  }}
                >
                  <Typography className={classes.tableHeader}>
                    {t(
                      "INFLUENCER_AGENT.VIEWS.STATISTICS.TABLE_HEADER.SALES_NUMBER"
                    )}
                  </Typography>
                  {sortHelper[2] === 1 ? (
                    <ExpandLessIcon className={classes.tableHeaderIcon} />
                  ) : sortHelper[2] === -1 ? (
                    <ExpandMoreIcon className={classes.tableHeaderIcon} />
                  ) : (
                    <SortIcon className={classes.tableHeaderIcon} />
                  )}
                </Grid>
                <Grid
                  container
                  justify="center"
                  alignItems="center"
                  style={{ width: "13%" }}
                  onClick={() => {
                    sortHelper[3] === 0
                      ? setSortHelper([0, 0, 0, 1, 0, 0])
                      : setSortHelper([0, 0, 0, -sortHelper[3], 0, 0]);
                  }}
                >
                  <Typography className={classes.tableHeader}>
                    {t(
                      "INFLUENCER_AGENT.VIEWS.STATISTICS.TABLE_HEADER.COMMISSIONS"
                    )}
                  </Typography>
                  {sortHelper[3] === 1 ? (
                    <ExpandLessIcon className={classes.tableHeaderIcon} />
                  ) : sortHelper[3] === -1 ? (
                    <ExpandMoreIcon className={classes.tableHeaderIcon} />
                  ) : (
                    <SortIcon className={classes.tableHeaderIcon} />
                  )}
                </Grid>
                <Grid
                  container
                  justify="center"
                  alignItems="center"
                  style={{ width: "13%" }}
                  onClick={() => {
                    sortHelper[4] === 0
                      ? setSortHelper([0, 0, 0, 0, 1, 0])
                      : setSortHelper([0, 0, 0, 0, -sortHelper[4], 0]);
                  }}
                >
                  <Typography className={classes.tableHeader}>
                    {t(
                      "INFLUENCER_AGENT.VIEWS.STATISTICS.TABLE_HEADER.PRODUCTS_NUMBER"
                    )}
                  </Typography>
                  {sortHelper[4] === 1 ? (
                    <ExpandLessIcon className={classes.tableHeaderIcon} />
                  ) : sortHelper[4] === -1 ? (
                    <ExpandMoreIcon className={classes.tableHeaderIcon} />
                  ) : (
                    <SortIcon className={classes.tableHeaderIcon} />
                  )}
                </Grid>
                <Grid
                  container
                  justify="center"
                  alignItems="center"
                  style={{ width: "13%" }}
                  onClick={() => {
                    sortHelper[5] === 0
                      ? setSortHelper([0, 0, 0, 0, 0, 1])
                      : setSortHelper([0, 0, 0, 0, 0, -sortHelper[5]]);
                  }}
                >
                  <Typography className={classes.tableHeader}>
                    {t("INFLUENCER_AGENT.VIEWS.STATISTICS.TABLE_HEADER.CLICKS")}
                  </Typography>
                  {sortHelper[5] === 1 ? (
                    <ExpandLessIcon className={classes.tableHeaderIcon} />
                  ) : sortHelper[5] === -1 ? (
                    <ExpandMoreIcon className={classes.tableHeaderIcon} />
                  ) : (
                    <SortIcon className={classes.tableHeaderIcon} />
                  )}
                </Grid>
              </Grid>
            </Grid>
            {influencerToRenderFinal.map((influencer) => {
              return (
                <Grid
                  container
                  item
                  direction="row"
                  justify="center"
                  className={classes.influencerBoxContainer}
                  key={influencer.key}
                >
                  <Box className={classes.influencerBox}>
                    <Grid container item direction="row" xs={12}>
                      <Grid
                        container
                        item
                        justify="flex-start"
                        alignItems="center"
                        style={{ width: "35%" }}
                      >
                        <img
                          src={influencer.banner}
                          alt=""
                          className={classes.influencerImage}
                        />

                        <Typography
                          className={classes.influencerName}
                          style={{ marginLeft: "50px" }}
                          onClick={() => openPopup(influencer)}
                        >
                          {influencer.name}
                        </Typography>
                      </Grid>

                      <Grid
                        container
                        item
                        justify="center"
                        alignItems="center"
                        style={{ width: "13%" }}
                      >
                        <Typography className={classes.tableRowContent}>
                          {Math.round(influencer.amount * 100) / 100} €
                        </Typography>
                      </Grid>
                      <Grid
                        container
                        item
                        justify="center"
                        alignItems="center"
                        style={{ width: "13%" }}
                      >
                        <Typography className={classes.tableRowContent}>
                          {influencer.salesNumber}
                        </Typography>
                      </Grid>
                      <Grid
                        container
                        item
                        justify="center"
                        alignItems="center"
                        style={{ width: "13%" }}
                      >
                        <Typography className={classes.tableRowContent}>
                          {Math.round(influencer.commissions * 100) / 100} €
                        </Typography>
                      </Grid>
                      <Grid
                        container
                        item
                        justify="center"
                        alignItems="center"
                        style={{ width: "13%" }}
                      >
                        <Typography className={classes.tableRowContent}>
                          {influencer.products.length}
                        </Typography>
                      </Grid>
                      <Grid
                        container
                        item
                        justify="center"
                        alignItems="center"
                        style={{ width: "13%" }}
                      >
                        {influencer.clicks === undefined ? (
                          <CircularProgress
                            color="secondary"
                            style={{ width: "20px", height: "20px" }}
                          />
                        ) : (
                          <Typography className={classes.tableRowContent}>
                            {influencer.clicks}
                          </Typography>
                        )}
                      </Grid>
                    </Grid>
                  </Box>
                </Grid>
              );
            })}
          </Grid>
        </div>
      </Grid>
      <Grid
        container
        item
        direction="row"
        xs={11}
        alignItems="center"
        style={{ marginTop: "10px", marginBottom: "10px" }}
      >
        <Grid container alignItems="center" justify="flex-end">
          <Select
            value={size}
            onChange={handlePageSizeChange}
            input={<BootstrapInput />}
            IconComponent={() => <SVGIcon name="miniArrow" />}
            classes={{ root: classes.paginationStyles }}
          >
            <MenuItem value={5} style={{ fontFamily: "Poppins" }}>
              5
            </MenuItem>
            <MenuItem value={10} style={{ fontFamily: "Poppins" }}>
              10
            </MenuItem>
            <MenuItem value={20} style={{ fontFamily: "Poppins" }}>
              20
            </MenuItem>
          </Select>
          <Typography
            className={classes.tableHeader}
            style={{ marginRight: "10px", marginLeft: "10px" }}
          >
            {/* {t('ADMIN.VIEWS.INFLUENCERS.PAGINATION_PLACEHOLDER')}  */}
            Sur {influencerToRenderFinal.length}
          </Typography>
          <Pagination
            count={count}
            page={page}
            onChange={handlePageChange}
            renderItem={(item) => (
              <PaginationItem
                classes={{
                  root: classes.paginationClass,
                  selected: classes.selectedPaginationItem,
                }}
                {...item}
              />
            )}
          />
        </Grid>
      </Grid>
    </>
  );
};
export default InfluencersTable;
