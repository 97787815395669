import React, { useState } from "react";

import { Grid, Typography, InputBase, InputAdornment } from "@material-ui/core";
import { makeStyles } from "@material-ui/styles";

// UI Scope
import Button from "../button";

// Component Scope
import Modal from "./Modal";

const useStyles = makeStyles(theme => ({
  container: {
    padding: theme.spacing(1),
    backgroundColor: "white",
  },
  input: {
    marginTop: theme.spacing(1),
    marginBottom: theme.spacing(1),
  },
}));

const InputFieldModal = ({
  open,
  title,
  field,
  placeholder,
  initialValue = "",
  onValidate,
  onCancel,
  startAdorn = false,
}) => {
  const [value, setValue] = useState(initialValue);

  const classes = useStyles();

  const handleValueChange = event => {
    setValue(event.target.value);
  };
  const handleSubmit = event => {
    event.preventDefault();
    onValidate(field, value);
  };

  return (
    open && (
      <Modal center>
        <Grid
          className={classes.container}
          item
          xs={12}
          sm={10}
          md={8}
          container
        >
          <Grid xs={12} item container justify='center'>
            <Typography variant='h4'>{title}</Typography>
          </Grid>
          <form
            id={`${field}Form`}
            onSubmit={handleSubmit}
            style={{ width: "100%" }}
          >
            <Grid
              className={classes.input}
              xs={12}
              item
              container
              justify='center'
            >
              <InputBase
                id={`${field}Input`}
                autoFocus
                placeholder={placeholder}
                value={value}
                onChange={handleValueChange}
                startAdornment={
                  startAdorn ? (
                    <InputAdornment position='start'>
                      {startAdorn}
                    </InputAdornment>
                  ) : null
                }
                style={{ width: "100%" }}
                inputProps={{
                  style: {
                    textAlign: startAdorn ? "inherit" : "center",
                    width: "100%",
                  },
                }}
              />
            </Grid>
            <Grid xs={12} item container>
              <Grid xs={6} item container>
                <Button
                  fullWidth
                  color='secondary'
                  type='button'
                  onClick={onCancel}
                >
                  ANNULER
                </Button>
              </Grid>
              <Grid xs={6} item container>
                <Button fullWidth color='primary' type='submit'>
                  VALIDER
                </Button>
              </Grid>
            </Grid>
          </form>
        </Grid>
      </Modal>
    )
  );
};

export default InputFieldModal;
