import React from "react";
// import PropTypes from "prop-types";
import {
  Switch,
  Route,
  useRouteMatch,
  // useParams,
} from "react-router-dom";
import Burgbad from "./Burgbad";
import Admin, { EmailsList } from "./Admin";

const EShop = (props) => {
  /* Props */
  const { user } = props;

  /* Hooks */
  const { path } = useRouteMatch();
  // const { brandId, uid } = useParams();
  
  return (
    <Switch>
      <Route path={`${path}`}>
        <Burgbad />
      </Route>
      <Route path={`${path}/admin`}>
        <Admin user={user}>
          <EmailsList />
        </Admin>
      </Route>
    </Switch>
  );
};

EShop.propTypes = {};

export default EShop;
