import { Button, Dialog, DialogActions, DialogContent, Grid, IconButton, InputBase, makeStyles, Typography } from '@material-ui/core';
import CloseIcon from '@material-ui/icons/Close';
import FilterNoneIcon from '@material-ui/icons/FilterNone';
import React, { useState } from 'react';
import { useTranslation } from "react-i18next";

const useStyle = makeStyles(theme => ({
    dialogTitle: {
        display: 'flex',
        flexDirection: 'row',
        justifyContent: 'space-between',
        alignItems: 'center',
        width: '100%',
        padding: 10
    },
    shortlinkTitle: {
        fontFamily: 'Poppins',
        color: '#ee3889',
        paddingLeft: '15px'
    },
    shortlinkInputBox: {
        border: '1.5px #cad3d854 solid',
        borderRadius: '15px',
        boxShadow: '0px 3px 9px 0 #58a5d35c',
        backgroundColor: '#fff'
    },
    copyButton: {
        marginLeft: '10px',
        '&:hover': {
            borderTopRightRadius: '15px',
            borderBottomRightRadius: '15px',
            borderTopLeftRadius: 0,
            borderBottomLeftRadius: 0,
        },
    },
    shortlinkField: {
        fontFamily: 'Poppins',
        color: '#767676',
        paddingLeft: '10px'
    },
    copyMessage: {
        fontFamily: 'Poppins',
        fontSize: '12px',
        color: 'green',
        marginTop: '10px'
    },
    testLinkButton: {
        textTransform: 'none',
        fontFamily: 'Poppins',
        backgroundColor: '#00ADEF',
        color: '#fff',
        borderRadius: '15px',
        paddingLeft: '10px',
        paddingRight: '10px',
        '&:hover': {
            backgroundColor: '#00ADEF',
            color: '#fff',
        }
    }
}));

const ShortlinkBox = (props) => {

    const { t } = useTranslation("common");
    const classes = useStyle();
    const [copyState, setCopyState] = useState(false);

    const {
        open,
        shortlinkUrl,
    } = props;

    const closeBox = () => {
        setCopyState(false);
        props.closeShortlinkBox();
    };

    const copyShortLink = () => {
        navigator.clipboard.writeText(shortlinkUrl);
        setCopyState(true);
    };

    return (
        <Dialog open={open}>
            <Grid className={classes.dialogTitle} container item direction='row' xs={12}>
                <Typography className={classes.shortlinkTitle}>{t('INFLUENCER.SHOTLINK_BOX.SHOTLINK_BOX_TITLE')}</Typography>
                <IconButton size='small' onClick={closeBox}>
                    <CloseIcon />
                </IconButton>
            </Grid>
            <DialogContent style={{ backgroundColor: '#eff9fe' }}>
                <Grid container item direction='row' xs={12} justify='center' alignItems='center' className={classes.shortlinkInputBox}>
                    <Grid container item direction='row' xs={12}>
                        <InputBase className={classes.shortlinkField} value={shortlinkUrl} />
                        <IconButton className={classes.copyButton} onClick={copyShortLink}>
                            <FilterNoneIcon />
                        </IconButton>
                    </Grid>
                </Grid>
                {
                    (copyState)
                        ? <Typography className={classes.copyMessage}>* {t('INFLUENCER.SHOTLINK_BOX.COPY_LINK_MESSAGE')} </Typography>
                        : <></>
                }
            </DialogContent>
            <DialogActions style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', backgroundColor: '#eff9fe' }}>
                <Button className={classes.testLinkButton}>
                    <a href={shortlinkUrl} rel="noopener noreferrer" target='_blank' style={{ color: 'inherit', textDecoration: 'inherit' }}>{t('INFLUENCER.SHOTLINK_BOX.SHOTLINK_BUTTON')}</a>
                </Button>
            </DialogActions>
        </Dialog>
    )
};

export default ShortlinkBox;